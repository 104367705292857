import { createSelector } from '@reduxjs/toolkit';
import { nanoid } from 'nanoid';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { depositTransaction, pensionTransaction } from '@monestry-dev/schema';
import * as globals from '..';
import calculatePV from './calculatePV';
import i18n from '../../../../i18n';

dayjs.extend(utc);

// create purchase transactions based on account object and baseDate
export function createPurchaseTransactions(accounts, baselineView, inflationRate) {
  const { current: baseDate, baseline, referenceBaseline } = baselineView;
  const returnTransactions = [];

  // for each account construct purchase transactions with quantity representing Present Value at different dates since the account.productPurchaseDate
  // (purchase day, every month 1st since the account.productPurchaseDate until baseDate + baseline + referenceBaseline
  accounts.forEach((account) => {
    // STEP 1: PREPARE DATES
    const dates = [];
    // purchase date
    dates.push(dayjs.utc(account.productPurchaseDate).startOf('day').valueOf());
    // all month firsts until baseDate
    let iterDate = dayjs.utc(account.productPurchaseDate).startOf('month').add(1, 'month').startOf('month');
    while (iterDate <= baseDate) {
      dates.push(iterDate.valueOf());
      iterDate = iterDate.add(1, 'month').startOf('month');
    }
    // base date + baseline + referenceBaseline (no need, it will calculate based on quantity existing before these dates)
    dates.push(baseDate, baseline, referenceBaseline);

    // STEP 2: GENERATE TRANSACTIONS
    let previousLoopQuantity = 0;
    dates
      .sort((a, b) => a - b)
      .forEach((_date) => {
        const thisLoopQuantity = calculatePV(account, _date, inflationRate);

        const purchaseTransaction = {
          id: nanoid(),
          accountId: account.id,
          assetId: account.id,
          category: 'pension',
          date: _date,
          quantity: thisLoopQuantity - previousLoopQuantity, // calculate the present value factor for the account (based on the account object and the current date
          quantityOpen: 0, // we don't do FIFO on pension accounts
          upac: (account.initialQuotes || []).find((q) => q.type === account.useSpecialQuote)?.quote || 0, // get the initial price of the user-selected type from account
          accountCurrency: account.currency,
          assetCurrency: account.currency,
          upbc: (account.initialQuotes || []).find((q) => q.type === account.useSpecialQuote)?.quoteBaseCurrency || 0,
          uptc: null, // we don't do fx on pension accounts
          transactionCurrency: null, // we don't do fx on pension accounts
          isSimulated: false,
          projectId: null,
          linkedTransactions: [],
          tags: null,
          sortingOrderWithinMonth: null,
          description: `Pension purchase on ${dayjs.utc(_date).format('YYYY-MM-DD')}`,
          label: 'pension-purchase',
          isNotKpiRelevant: false,
        };

        previousLoopQuantity = thisLoopQuantity;

        returnTransactions.push(pensionTransaction.cast(purchaseTransaction));
      });
  });
  return returnTransactions;
}

// creates simulated contributions up to endDate or payoutPhaseStart
// creates simulated payouts after payoutPhaseStart up to endDate or payoutPhaseStart + payoutsDuration in months
// TODO inflationRate to index the contributions and payouts
export function addSimulatedContributionsAndPayouts(accounts, quotes, endDate, inflationRate, pensionMode = true) {
  const returnTransactions = [];

  // for each account construct a purchase transaction with quantity representing Present Value
  accounts.forEach((account) => {
    // get upbc from quotes (current)
    const currentQBC = quotes[account.currency]?.current?.quoteBaseCurrency;
    // get from quotes, if not available, then from account.initialQuotes
    const upbc = (currentQBC && currentQBC !== 0 ? (1 / currentQBC) : undefined) || account.initialQuotes.find((q) => q.type === account.useSpecialQuote)?.quoteBaseCurrency || 0;

    // generate no transactions if this is for deposit accounts and there are no connectedDepositAccounts defined
    if (!pensionMode && account.connectedDepositAccounts.length === 0) {
      console.warn('addSimulatedContributionsAndPayouts: no connectedDepositAccounts defined for account', account.name);
      return;
    }

    const templateTransaction = {
      id: nanoid(),
      accountId: pensionMode ? account.id : account.connectedDepositAccounts[0],
      assetId: pensionMode ? account.id : account.currency,
      category: pensionMode ? 'pension' : 'deposits',
      upac: 1, // in account currency
      accountCurrency: account.currency,
      assetCurrency: account.currency,
      upbc,
      uptc: null, // we don't do fx on pension accounts
      transactionCurrency: null, // we don't do fx on pension accounts
      isSimulated: true,
      projectId: null,
      linkedTransactions: [],
      tags: null,
      sortingOrderWithinMonth: null,
      ...(pensionMode && { isNotKpiRelevant: true }),
      ...(!pensionMode && { otherParty: account.name }),
    };

    // contribution transactions
    if (account.contributionsSelfPaid) {
      let iterDate = dayjs.utc().add(1, 'month').startOf('month').valueOf(); // start today
      const sortedContributions = account.contributionsAmounts.sort((a, b) => b.date - a.date); // DESC
      while (iterDate < account.payoutsPhaseStart) {
        const contributionTransaction = {
          ...templateTransaction,
          date: iterDate,
          // eslint-disable-next-line no-loop-func
          quantity: Math.abs(sortedContributions.filter((c) => c.effectiveDate <= iterDate)[0].value) * (-1), // contributions always negative
          description: `${i18n.language === 'de' ? 'Beitrag' : 'Contribution'} ${dayjs.utc(iterDate).format('YYYY-MM')}`,
          label: pensionMode ? 'pension-contribution' : 'investment-contribution',
        };

        returnTransactions.push(pensionMode ? pensionTransaction.cast(contributionTransaction) : depositTransaction.cast(contributionTransaction));
        iterDate = dayjs.utc(iterDate).add(account.contributionsFrequency, 'month').startOf('month').valueOf();
      }
    }

    // payout transactions
    // TODO this can be indexed
    if (account.payoutsPhaseStart < endDate) {
      let iterDate = dayjs.utc(account.payoutsPhaseStart).startOf('month').valueOf();
      // payoutsDuration of 0 is indefinite
      const payoutsDuration = account.payoutsDuration === 0 ? Number.POSITIVE_INFINITY : dayjs.utc(account.payoutsPhaseStart).add(account.payoutsDuration, 'month').startOf('month').valueOf();
      // incl. endDate, because when user moves the slider, we want to show the payout for the month of the slider date
      // excl. payoutDuration, because the user expects 12 payments with payment duration of 12 months, and we start in month 0
      while (iterDate <= endDate && iterDate < payoutsDuration) {
        const payoutTransaction = {
          ...templateTransaction,
          date: iterDate,
          quantity: account.payoutsAmount,
          description: `${i18n.language === 'de' ? 'Auszahlung' : 'Payout'} ${dayjs.utc(iterDate).format('YYYY-MM')}`,
          label: pensionMode ? 'pension-payout' : 'investment-pension',
        };

        returnTransactions.push(pensionMode ? pensionTransaction.cast(payoutTransaction) : depositTransaction.cast(payoutTransaction));
        iterDate = dayjs.utc(iterDate).add(account.payoutsFrequency, 'month').startOf('month').valueOf();
      }
    }
  });

  return returnTransactions;
}

// returns an array with all transactions (actual or actual+simulated) up to the Slider Date (baseDate) or to today (depending on dashboardMode in state)
// adds a purchase transaction for each account with correctly calculated quantity (representing Present Value)
// calculates quantityOpen with applyFIFO  (applyFIFO only works with sold items, which we don't really expect here)
// makes all transactions have isNotKpiRelevant set to true (expcept the purchase transaction)
// no isolated transactions are included
export const pensionTransactions = createSelector(
  (state) => state.simulation?.dashboardMode,
  (state) => state.data?.pension.transactions,
  (state) => state.data?.pension.simulatedTransactions,
  (state) => state.data?.pension.accounts,
  globals.globalBaselineView,
  (state) => state.projects,
  globals.globalQuotesView,
  (state) => state.user.profile?.settings?.inflationRate,
  (dashboardMode, transactions, simulatedTransactions, accounts, baselineView, projects, quotes, inflationRate) => {
    const { current: baseDate } = baselineView;
    let returnTransactions;
    if (dashboardMode === 'projects') {
      const isolatedProjects = projects?.filter((project) => project.settings?.isIsolated).map((project) => project.id);

      const simulatedTransactionsWithoutIsolated = simulatedTransactions.filter((txn) => !isolatedProjects.includes(txn.projectId));
      const simulatedContributionsAndPayouts = addSimulatedContributionsAndPayouts(accounts, quotes, baseDate);

      returnTransactions = (transactions || [])
        .concat(simulatedTransactionsWithoutIsolated)
        .concat(simulatedContributionsAndPayouts)
        .filter((transaction) => transaction.date <= baseDate);
    // if dashboardMode === 'dashboard'
    } else {
      returnTransactions = transactions || [];
    }

    // (!) if the entire point of applyFIFOOnCategory is to add quantityOpen, then it does not make sense here because there are no sale transactions

    // return applyFIFOOnCategory(returnTransactions
    //   .map((t) => ({ ...t, isNotKpiRelevant: true })) // apply isNotKpiRelevant to all transactions from database
    //   .concat(createPurchaseTransactions(accounts, baseDate, inflationRate))); // purchaseTransactions do not need isNotKpiRelevant
    return returnTransactions
      .map((t) => ({ ...t, isNotKpiRelevant: true })) // apply isNotKpiRelevant to all transactions from database
      .concat(createPurchaseTransactions(accounts, baselineView, inflationRate));
  },
);

// returns an array with all transactions (actual or actual+simulated) up to the Simulation End Date (slider end)
// adds a purchase transaction for each account with correctly calculated quantity (representing Present Value)
// calculates quantityOpen with applyFIFO  (applyFIFO only works with sold items, which we don't really expect here)
// makes all transactions have isNotKpiRelevant set to true (expcept the purchase transaction)
// including all isolated transactions
export const pensionTransactionsProjectView = createSelector(
  (state) => state.simulation?.dashboardMode,
  (state) => state.data?.pension.transactions,
  (state) => state.data?.pension.simulatedTransactions,
  (state) => state.data?.pension.accounts,
  globals.globalBaselineView,
  globals.globalQuotesView,
  (state) => state.user.profile?.settings?.sliderEndDate,
  (state) => state.user.profile?.settings?.inflationRate,
  (dashboardMode, transactions, simulatedTransactions, accounts, baselineView, quotes, sliderEndDate, inflationRate) => {
    const { current: baseDate } = baselineView;
    let returnTransactions;
    if (dashboardMode === 'projects') {
      returnTransactions = (transactions || [])
        .concat(simulatedTransactions)
        .concat(addSimulatedContributionsAndPayouts(accounts, quotes, sliderEndDate)); // return all transactions up to the slider end date
    } else {
      returnTransactions = transactions || [];
    }

    // (!) if the entire point of applyFIFOOnCategory is to add quantityOpen, then it does not make sense here because there are no sale transactions

    // return applyFIFOOnCategory(returnTransactions
    //   .map((t) => ({ ...t, isNotKpiRelevant: true })) // apply isNotKpiRelevant to all transactions from database
    //   .concat(createPurchaseTransactions(accounts, baseDate, inflationRate))); // purchaseTransactions do not need isNotKpiRelevant
    return returnTransactions
      .map((t) => ({ ...t, isNotKpiRelevant: true })) // apply isNotKpiRelevant to all transactions from database
      .concat(createPurchaseTransactions(accounts, baselineView, inflationRate)); // purchaseTransactions do not need isNotKpiRelevant
  },
);
