/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Dropdown from '../../elements/Dropdown';
import { currencyCodes } from '../../misc/currencyCodes';
import { convertCurrency } from '../../misc/ecbCurrencyRates';
import MiniSpinner from '../../misc/MiniSpinner';
import NewAccountButton from './NewAccountButton';

const utc = require('dayjs/plugin/utc');

dayjs.extend(utc);

export default function InflowOutflowTransfer({ accountList, control, register, mode, errors, watch, setValue, unregister }) {
  const account = watch('account');
  const { t } = useTranslation('app', { keyPrefix: 'projects' });

  const selectBaseCurrency = useSelector((state) => state.user.profile.settings.baseCurrency) || 'EUR';
  const baseCurrency = useMemo(() => selectBaseCurrency, [selectBaseCurrency]);

  const [showFxRate, setShowFxRate] = useState(true);
  const [fxRateLoading, setFxRateLoading] = useState(false);

  const currency = watch('currency');
  const amount = watch('amount');
  const exchangeRate = watch('exchangeRate');
  const baseCurrencyAmount = watch('baseCurrencyAmount');

  // BuySell has a very similar function, remember to change it there as well if changing here
  function handleFxRateFieldSwitch(e) {
    if (showFxRate === true) {
      setValue('baseCurrencyAmount', (Number(amount) * Number(exchangeRate)).toFixed(2), { shouldValidate: true, shouldTouch: true, shouldDirty: true });
    }
    if (showFxRate === false) {
      setValue('exchangeRate', (Number(baseCurrencyAmount) / Number(amount)).toFixed(4), { shouldValidate: true, shouldTouch: true, shouldDirty: true });
    }
    setShowFxRate(!showFxRate);
    // recalculate FX rate or assetUnitPriceBaseCurrency based on which field was changed
    // if showFxRate is true, then fxRate is being changed to assetUnitPriceBaseCurrency
  }

  const [signalNewAccount, setSignalNewAccount] = useState(undefined); // will be set to an object of { accountFieldName, accountId } by NewAccountButton when a new account is created

  // this is done with useEffect instead of a direct callback function, because callback function does not receive updates in accountListFiltered
  useEffect(() => {
    if (signalNewAccount) {
      const newAccount = accountList.find((a) => a.id === signalNewAccount.newAccountId);
      if (newAccount) setValue(signalNewAccount.accountFieldName, newAccount);
    }
    setSignalNewAccount(undefined);
  }, [signalNewAccount]);

  useEffect(() => {
    async function updateFxRate() {
      if (currency !== baseCurrency) {
        setFxRateLoading(true);
        let newRate;
        try {
          newRate = await convertCurrency(1, baseCurrency, currency, dayjs.utc().startOf('day').valueOf());
          setValue('exchangeRate', (1 / newRate).toFixed(4), { shouldValidate: true });
          setFxRateLoading(false);
        } catch (err) {
          setFxRateLoading(false);
        }
      }
    }
    updateFxRate();
  }, [currency]);

  return (
    <div className="space-y-8">
      {/* amount */}
      <div className="grid grid-cols-3 gap-3">
        <div>
          <label htmlFor="email" className="block text-sm font-medium text-gray-700">
            {t('amount.label')}
          </label>
          <div className="mt-1">
            <input
              type="number"
              name="amount"
              id="amount"
              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-brandBlue-500 focus:ring-brandBlue-600 sm:text-sm"
              placeholder={mode === 'outlfow' ? -500 : 2000}
              {...register('amount', {
                validate: (value) => {
                  if (mode === 'inflow' && value < 0) {
                    return t('amount.inflowsCanOnlyBePositive');
                  }
                  if (mode === 'outflow' && value > 0) {
                    return t('amount.outflowsCanOnlyBeNegative');
                  }
                  if (mode === 'transfer' && value < 0) {
                    return t('amount.transfersCanOnlyBePositive');
                  }
                  return true;
                },
              })}
            />
          </div>
        </div>
        {/* currency dropdown */}
        <div className="z-[70]" id="project-details-add-transaction-currency">
          <Controller
            name="currency"
            control={control}
            render={({ field }) => <Dropdown label={t('currency')} list={currencyCodes} value={field.value} onChange={field.onChange} onBlur={field.onBlur} optional={false} />}
          />
        </div>
        {/* exchange rate */}
        <div>
          {showFxRate === true ? (
            <>
              <label htmlFor="exchangeRate" className={`block text-sm font-medium ${currency === baseCurrency ? 'text-gray-300' : 'text-gray-700'}`}>
                {`${t('exchangeRate.label')} ${baseCurrency}`}
              </label>
              <div className="mt-1 relative">
                <input
                  type="number"
                  step="any"
                  name="exchangeRate"
                  id="exchangeRate"
                  disabled={currency === baseCurrency}
                  // eslint-disable-next-line max-len
                  className={`block w-full rounded-md border-gray-300 shadow-sm focus:border-brandBlue-500 focus:ring-brandBlue-600 sm:text-sm ${
                    currency === baseCurrency ? 'text-gray-300' : 'text-gray-700'
                  }`}
                  placeholder={1}
                  {...register('exchangeRate', {
                    validate: (value) => {
                      if (value <= 0) {
                        return t('exchangeRate.mustBeGreaterThanZero');
                      }
                      return true;
                    },
                  })}
                />
                {fxRateLoading && (
                  <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                    <MiniSpinner className="h-4 w-4 animate-spin text-gray-600" />
                  </div>
                )}
              </div>
              <button
                className={`${currency !== baseCurrency ? '' : 'hidden'} text-xs underline text-gray-400 hover:text-brandBlue-400`}
                onClick={handleFxRateFieldSwitch}
                type="button"
                id="project-details-add-transaction-switch-fx-rate"
              >
                {showFxRate ? `${t('switchToAmount')}` : `${t('switchToFxRate')}`}
              </button>
            </>
          ) : (
            <>
              <label htmlFor="baseCurrencyAmount" className={`block text-sm font-medium ${currency === baseCurrency ? 'text-gray-300' : 'text-gray-700'}`}>
                {`${t('baseCurrencyAmount.label')} ${baseCurrency}`}
              </label>
              <div className="mt-1">
                <input
                  type="number"
                  name="baseCurrencyAmount"
                  id="baseCurrencyAmount"
                  disabled={currency === baseCurrency}
                  // eslint-disable-next-line max-len
                  className={`block w-full rounded-md border-gray-300 shadow-sm focus:border-brandBlue-500 focus:ring-brandBlue-600 sm:text-sm ${
                    currency === baseCurrency ? 'text-gray-300' : 'text-gray-700'
                  }`}
                  placeholder={2000}
                  {...register('baseCurrencyAmount', {
                    validate: (value) => {
                      if (value <= 0) {
                        return t('baseCurrencyAmount.mustBeGreaterThanZero');
                      }
                      return true;
                    },
                  })}
                />
              </div>
              <button
                className={`${currency !== baseCurrency ? '' : 'hidden'} text-xs underline text-gray-400 hover:text-brandBlue-400`}
                onClick={handleFxRateFieldSwitch}
                type="button"
                id="project-details-add-transaction-switch-fx-rate"
              >
                {showFxRate ? `${t('switchToAmount')}` : `${t('switchToFxRate')}`}
              </button>
            </>
          )}
        </div>
        {errors.amount && (
          <div className="col-span-3 pt-2 sm:text-sm text-brandRed-500 max-w-xs">
            <span>{errors.amount?.message}</span>
          </div>
        )}
      </div>

      {/* account dropdown */}
      {mode !== 'inflow' && (
        <div id="project-details-add-transaction-account" className="z-50 grid grid-cols-4 gap-2">
          <div className="col-span-3">
            <Controller
              name="account"
              id="account"
              control={control}
              defaultValue={accountList.filter((a) => a.category === 'deposits')[0]}
              render={({ field, ref }) => (
                <Dropdown
                  label={t('account.label')}
                  list={accountList.filter((a) => a.category === 'deposits')}
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  inputRef={ref}
                  extraWide
                  optional={false}
                />
              )}
            />
          </div>
          <NewAccountButton baseCurrency={baseCurrency} setSignalNewAccount={setSignalNewAccount} accountFieldName="account" />
        </div>
      )}
      {mode !== 'outflow' && (
        <div id="project-details-add-transaction-accountTo" className="z-50 grid grid-cols-4 gap-2">
          <div className="col-span-3">
            <Controller
              id="accountTo"
              name="accountTo"
              control={control}
              defaultValue={accountList.filter((a) => a.category === 'deposits')[1] || accountList.filter((a) => a.category === 'deposits')[0]}
              render={({ field, ref }) => (
                <Dropdown
                  label={t('accountTo.label')}
                  list={accountList.filter((a) => a.category === 'deposits' && a.id !== account)}
                  value={field.value}
                  onBlur={field.onBlur}
                  onChange={field.onChange}
                  inputRef={ref}
                  errorMessage={errors.accountTo?.message}
                  extraWide
                  optional={false}
                />
              )}
            />
          </div>
          <NewAccountButton baseCurrency={baseCurrency} setSignalNewAccount={setSignalNewAccount} accountFieldName="accountTo" />
          {/* <div className="pt-2 sm:text-sm text-brandRed-500">
            <span>{errors.accountTo?.message}</span>
          </div> */}
        </div>
      )}
      {/* description */}
      <div>
        <label htmlFor="description" className="block text-sm font-medium text-gray-700">
          {t('transactionDescription.label')}
        </label>
        <div className="mt-1">
          <input
            type="text"
            name="description"
            id="description"
            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-brandBlue-500 focus:ring-brandBlue-600 sm:text-sm placeholder:italic"
            placeholder={t('transactionDescription.placeholder')}
            {...register('description', {
              required: t('transactionDescription.required'),
              minLength: { value: 2, message: t('transactionDescription.length') },
            })}
          />
        </div>
        <div className="pt-2 sm:text-sm text-brandRed-500">
          <span>{errors.description?.message}</span>
        </div>
      </div>
    </div>
  );
}
InflowOutflowTransfer.propTypes = {
  accountList: PropTypes.arrayOf(PropTypes.object).isRequired,
  control: PropTypes.objectOf(PropTypes.any).isRequired,
  register: PropTypes.func.isRequired,
  unregister: PropTypes.func.isRequired,
  mode: PropTypes.string.isRequired,
  errors: PropTypes.objectOf(PropTypes.any).isRequired,
  watch: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
};
