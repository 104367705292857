export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const REGISTER_CONFIRMATION_SUCCESS = 'REGISTER_CONFIRMATION_SUCCESS';
export const REGISTER_CONFIRMATION_FAIL = 'REGISTER_CONFIRMATION_FAIL';
export const RESEND_CONFIRMATION_EMAIL_SUCCESS = 'RESEND_CONFIRMATION_EMAIL_SUCCESS';
export const RESEND_CONFIRMATION_EMAIL_FAIL = 'RESEND_CONFIRMATION_EMAIL_FAIL';

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const UPDATE_TOURS_SUCCESS = 'UPDATE_TOURS_SUCCESS';
export const UPDATE_TOURS_FAIL = 'UPDATE_TOURS_FAIL';
export const SET_GLOBAL_TOUR_LOCK = 'SET_GLOBAL_TOUR_LOCK';
export const CLEAR_GLOBAL_TOUR_LOCK = 'CLEAR_GLOBAL_TOUR_LOCK';
export const UPDATE_COGNITO_SETTINGS_SUCCESS = 'UPDATE_COGNITO_SETTINGS_SUCCESS';
export const UPDATE_COGNITO_SETTINGS_FAIL = 'UPDATE_COGNITO_SETTINGS_FAIL';
export const UPDATE_USER_ATTRIBUTES_SUCCESS = 'UPDATE_USER_ATTRIBUTES_SUCCESS';
export const UPDATE_USER_ATTRIBUTES_FAIL = 'UPDATE_USER_ATTRIBUTES_FAIL';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAIL = 'CHANGE_PASSWORD_FAIL';

export const UPDATE_CATEGORY_DATA = 'UPDATE_CATEGORY_DATA';
export const UPDATE_CATEGORY_DATA_ERROR = 'UPDATE_CATEGORY_DATA_ERROR';
export const SET_CATEGORY_LOADING_STATE = 'SET_CATEGORY_LOADING_STATE';
export const CLEAR_CATEGORY_LOADING_STATE = 'CLEAR_CATEGORY_LOADING_STATE';
export const UPDATE_ACCOUNT_IMPORT_FILE_INITIATED = 'UPDATE_ACCOUNT_IMPORT_FILE_INITIATED';
export const UPDATE_ACCOUNT_IMPORT_FILE_SUCCESS = 'UPDATE_ACCOUNT_IMPORT_FILE_SUCCESS';
export const UPDATE_ACCOUNT_IMPORT_FILE_CLEAR_QUEUE = 'UPDATE_ACCOUNT_IMPORT_FILE_CLEAR_QUEUE';

export const PUT_QUOTES_SUCCESS = 'PUT_QUOTES_SUCCESS';
export const DELETE_QUOTES_SUCCESS = 'DELETE_QUOTES_SUCCESS';
export const GET_QUOTES_SUCCESS = 'GET_QUOTES_SUCCESS';

export const UPDATE_ACCOUNT_DATA = 'UPDATE_ACCOUNT_DATA';
export const UPDATE_ACCOUNT_DATA_ERROR = 'UPDATE_ACCOUNT_DATA_ERROR';
export const SET_ACCOUNT_LOADING_STATE = 'SET_ACCOUNT_LOADING_STATE';
export const CLEAR_ACCOUNT_LOADING_STATE = 'CLEAR_ACCOUNT_LOADING_STATE';
export const SET_ACCOUNT_FAILED_STATE = 'SET_ACCOUNT_FAILED_STATE';

export const SET_MESSAGE = 'SET_MESSAGE';
export const SET_ALERT = 'SET_ALERT';
export const CLEAR_ALERT = 'CLEAR_ALERT';
export const UPDATE_ALERT_RESPONSE = 'UPDATE_ALERT_RESPONSE';
export const SET_APP_SIGNAL = 'SET_APP_SIGNAL';
export const CLEAR_APP_SIGNAL = 'CLEAR_APP_SIGNAL';
export const SET_FULLSCREEN_REPORT = 'SET_FULLSCREEN_REPORT';
export const CLEAR_FULLSCREEN_REPORT = 'CLEAR_FULLSCREEN_REPORT';

export const CLEAR_STORED_DATA = 'CLEAR_STORED_DATA';

export const GET_DATA_FAIL = 'GET_DATA_FAIL';
export const GET_DATA_SUCCESS = 'GET_DATA_SUCCESS';

export const GET_MOCK_DATA_FAIL = 'GET_MOCK_DATA_FAIL';
export const GET_MOCK_DATA_SUCCESS = 'GET_MOCK_DATA_SUCCESS';

// states are:  'idle' | 'loading' | 'noerrors' | 'errors'
export const UPDATE_STOCKS_SETTINGS_SUCCESS = 'UPDATE_STOCKS_SETTINGS_SUCCESS';
export const UPDATE_STOCKS_SETTINGS_FAIL = 'UPDATE_STOCKS_SETTINGS_FAIL';

export const SURVEY_SENT = 'SURVEY_SENT';
export const SURVEY_SENDING_ERROR = 'SURVEY_SENDING_ERROR';

export const PAYMENT_ERROR = 'PAYMENT_ERROR';

export const PREUPDATE_PROJECT_ORDER_SEQUENCE = 'PREUPDATE_PROJECT_ORDER_SEQUENCE';
export const UPDATE_PROJECT_ORDER_SEQUENCE_SUCCESS = 'UPDATE_PROJECT_ORDER_SEQUENCE_SUCCESS';
export const UPDATE_PROJECT_ORDER_SEQUENCE_FAIL = 'UPDATE_PROJECT_ORDER_SEQUENCE_FAIL';

export const GET_PROJECTS_SUCCESS = 'GET_PROJECTS_SUCCESS';
export const GET_PROJECTS_FAIL = 'GET_PROJECTS_FAIL';
export const POST_PROJECT_SUCCESS = 'POST_PROJECT_SUCCESS';
export const POST_PROJECT_FAIL = 'POST_PROJECT_FAIL';
export const UPDATE_PROJECT_SUCCESS = 'UPDATE_PROJECT_SUCCESS';
export const DELETE_PROJECT_SUCCESS = 'DELETE_PROJECT_SUCCESS';

export const GET_REPORTS_SUCCESS = 'GET_REPORTS_SUCCESS';
export const GET_REPORTS_FAIL = 'GET_REPORTS_FAIL';
export const POST_REPORT_SUCCESS = 'POST_REPORT_SUCCESS';
export const POST_REPORT_FAIL = 'POST_REPORT_FAIL';
export const UPDATE_REPORT_SUCCESS = 'UPDATE_REPORT_SUCCESS';
export const DELETE_REPORT_SUCCESS = 'DELETE_REPORT_SUCCESS';

export const SET_MOBILE_APP_MODE = 'SET_MOBILE_APP_MODE';

export const UPDATE_CONSENT = 'UPDATE_CONSENT';
