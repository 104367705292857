// this is a patch for jspreadsheet to add formula support without using eval() or new Function()
/* eslint-disable no-redeclare */
/* eslint-disable block-scoped-var */
/* eslint-disable no-var */
/* eslint-disable vars-on-top */
/* eslint-disable no-undef */
/* eslint-disable prefer-destructuring */
/* eslint-disable eqeqeq */
/* eslint-disable consistent-return */
/* eslint-disable func-names */
/* eslint-disable no-useless-escape */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-use-before-define */
/* eslint-disable no-multi-assign */
/* eslint-disable radix */
/* eslint-disable no-shadow */
/* eslint-disable no-plusplus */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-param-reassign */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
// eslint-disable-next-line import/no-unresolved
const formulajs = require('@formulajs/formulajs');

(function (global, factory) {
  typeof exports === 'object' && typeof module !== 'undefined' ? module.exports = factory()
    : typeof define === 'function' && define.amd ? define(factory)
      : global.formula = factory();
}(this, (() => {
  const Formula = function (scope) {
    function getValue(obj, path) {
      const keys = path.split('.');
      let current = obj;

      for (const key of keys) {
        if (current === undefined || current === null) {
          return undefined;
        }
        current = current[key];
      }

      return current;
    }

    for (let i = 0; i < Object.keys(formulajs).length; i++) {
      const method = Object.keys(formulajs)[i];
      let keys = [];
      let values;
      if (typeof formulajs[method] === 'object') {
        keys = Object.keys(formulajs[method]);
        values = Object.values(formulajs[method]);
        for (let a = 0; a < values.length; a++) {
          if (typeof values[a] === 'object') {
            const subMethod = keys[a];
            if (formulajs[method][subMethod]) {
              keys = [
                ...keys,
                ...Object.keys(formulajs[method][subMethod]).map((a) => `${subMethod}.${a}`),
              ]; // Line too heavy, need refactor
              keys.splice(keys.indexOf(subMethod), 1);
            }
          }
        }
      }

      if (keys.length < 1) {
        scope[method] = formulajs[method];
      } else {
        for (let j = 0; j < keys.length; j++) {
          if (typeof getValue(formulajs[method], keys[j]) === 'function') {
            scope[method] = getValue(formulajs[method], keys[j]);
          }
        }
      }
    }

    const parseNumber = function (number) {
      if (typeof (number) === 'number') {
        number = parseInt(number);
      }
      return number;
    };

    /**
         * Instance execution helpers
         */
    let x = null;
    let y = null;
    let instance = null;

    scope.TABLE = function () {
      return instance;
    };
    scope.COLUMN = scope.COL = function () {
      if (instance.tracking) {
        instance.tracking.push(F.getColumnNameFromCoords(parseNumber(x), parseNumber(y)));
      }

      return parseNumber(x) + 1;
    };
    scope.ROW = function () {
      if (instance.tracking) {
        instance.tracking.push(F.getColumnNameFromCoords(parseNumber(x), parseNumber(y)));
      }

      return parseNumber(y) + 1;
    };
    scope.CELL = function () {
      return F.getColumnNameFromCoords(x, y);
    };
    scope.VALUE = function (col, row, processed) {
      return instance.getValueFromCoords(parseNumber(col) - 1, parseNumber(row) - 1, processed);
    };
    scope.THISROWCELL = function (col) {
      return instance.getValueFromCoords(parseNumber(col) - 1, parseNumber(y));
    };

    // Secure formula
    const secureFormula = function (oldValue, runtime) {
      let newValue = '';
      let inside = 0;
      const special = ['=', '!', '>', '<'];

      for (let i = 0; i < oldValue.length; i++) {
        if (oldValue[i] === '"') {
          if (inside === 0) {
            inside = 1;
          } else {
            inside = 0;
          }
        }

        if (inside === 1) {
          newValue += oldValue[i];
        } else {
          newValue += oldValue[i].toUpperCase();

          if (runtime === true) {
            if (i > 0 && oldValue[i] === '=' && special.indexOf(oldValue[i - 1]) === -1 && special.indexOf(oldValue[i + 1]) === -1) {
              newValue += '=';
            }
          }
        }
      }

      // Adapt to JS
      newValue = newValue.replace(/\^/g, '**');
      newValue = newValue.replace(/<>/g, '!=');
      newValue = newValue.replace(/&/g, '+');
      newValue = newValue.replace(/\$/g, '');

      return newValue;
    };

    // Convert range tokens
    const tokensUpdate = function (tokens, e) {
      for (let index = 0; index < tokens.length; index++) {
        const f = F.getTokensFromRange(tokens[index]);
        e = e.replace(tokens[index], `[${f.join(',')}]`);
      }
      return e;
    };

    const isNumeric = function (num) {
      if (typeof (num) === 'string') {
        num = num.trim();
      }
      return !isNaN(num) && num !== null && num !== '';
    };

    const F = function (expression, variables, i, j, obj) {
      // Global helpers
      instance = obj;
      x = i;
      y = j;
      // String
      let s = '';
      const parent = {};
      if (variables) {
        if (variables.size) {
          let tokens = null;
          let t;
          variables.forEach((v, k) => {
            // Replace ! per dot
            t = k.replace(/!/g, '.');
            // Exists parent
            if (t.indexOf('.') !== -1) {
              t = t.split('.');
              parent[t[0]] = true;
            }
          });
          t = Object.keys(parent);
          for (let i = 0; i < t.length; i++) {
            s += `var ${t[i]} = {};`;
          }

          variables.forEach((v, k) => {
            // Replace ! per dot
            t = k.replace(/!/g, '.');
            if (v !== null && !isNumeric(v)) {
              tokens = v.match(/(('.*?'!)|(\w*!))?(\$?[A-Z]+\$?[0-9]*):(\$?[A-Z]+\$?[0-9]*)?/g);
              if (tokens && tokens.length) {
                // eslint-disable-next-line no-undef
                v = updateRanges(tokens, v);
              }
            }

            if (t.indexOf('.') > 0) {
              s += `${t} = ${variables.get(k)};\n`;
            } else {
              s += `var ${t} = ${v};\n`;
            }
          });
        } else {
          const keys = Object.keys(variables);
          if (keys.length) {
            const parent = {};
            let t;
            for (let i = 0; i < keys.length; i++) {
              // Replace ! per dot
              t = keys[i].replace(/\!/g, '.');
              // Exists parent
              if (t.indexOf('.') > 0) {
                const t = t.split('.');
                parent[t[0]] = {};
              }
            }
            t = Object.keys(parent);
            for (let i = 0; i < t.length; i++) {
              s += `var ${t[i]} = {};`;
            }

            for (let i = 0; i < keys.length; i++) {
              // Replace ! per dot
              t = keys[i].replace(/!/g, '.');
              // Update range
              if (variables[keys[i]] !== null && !isNumeric(variables[keys[i]])) {
                const tokens = variables[keys[i]].match(/(('.*?'!)|(\w*!))?(\$?[A-Z]+\$?[0-9]*):(\$?[A-Z]+\$?[0-9]*)?/g);
                if (tokens && tokens.length) {
                  variables[keys[i]] = tokensUpdate(tokens, variables[keys[i]]);
                }
              }

              if (t.indexOf('.') > 0) {
                s += `${t} = ${variables[keys[i]]};\n`;
              } else {
                s += `var ${t} = ${variables[keys[i]]};\n`;
              }
            }
          }
        }
      }
      // Remove $
      expression = expression.replace(/\$/g, '');
      // Replace ! per dot
      expression = expression.replace(/!/g, '.');
      // Adapt to JS
      expression = secureFormula(expression, true);
      // Update range
      const tokens = expression.match(/(('.*?'!)|(\w*!))?(\$?[A-Z]+\$?[0-9]*):(\$?[A-Z]+\$?[0-9]*)?/g);
      if (tokens && tokens.length) {
        expression = tokensUpdate(tokens, expression);
      }
      // Calculate
      // let result = new Function(`${s}; return ${expression}`)();

      // s splits the used cells into variables (one variable per cell) 'var E13 = 2345; var E14 = 234;'
      // expression executes a mathematical expression using the variables 'E13 + E14'

      function createFunction(s, expression) {
        const variables = {};
        s.split(';').filter(Boolean).forEach((item) => {
          const [name, value] = item.trim().replace('var ', '').split(' = ');
          variables[name] = Number(value);
        });

        const [fn, args] = expression.split('(');
        const fnArgs = args.replace(')', '').split(',').map((a) => variables[a]);

        const functionMap = { SUM, AVERAGE };

        return function () {
          return functionMap[fn](...fnArgs);
        };
      }

      const result = createFunction(s, expression)();

      return result;
    };

    /**
         * Get letter based on a number
         * @param {number} i
         * @return {string}
         */
    const getColumnName = function (i) {
      let letter = '';
      if (i > 701) {
        letter += String.fromCharCode(64 + parseInt(i / 676));
        letter += String.fromCharCode(64 + parseInt((i % 676) / 26));
      } else if (i > 25) {
        letter += String.fromCharCode(64 + parseInt(i / 26));
      }
      letter += String.fromCharCode(65 + (i % 26));

      return letter;
    };

    /**
         * Get column name from coords
         */
    F.getColumnNameFromCoords = function (x, y) {
      return getColumnName(parseInt(x)) + (parseInt(y) + 1);
    };

    F.getCoordsFromColumnName = function (columnName) {
      // Get the letters
      const t = /^[a-zA-Z]+/.exec(columnName);

      if (t) {
        // Base 26 calculation
        let code = 0;
        for (let i = 0; i < t[0].length; i++) {
          code += parseInt(t[0].charCodeAt(i) - 64) * 26 ** (t[0].length - 1 - i);
        }
        code--;
        // Make sure jspreadsheet starts on zero
        if (code < 0) {
          code = 0;
        }

        // Number
        let number = parseInt(/[0-9]+$/.exec(columnName)) || null;
        if (number > 0) {
          number--;
        }

        return [code, number];
      }
    };

    F.getRangeFromTokens = function (tokens) {
      tokens = tokens.filter((v) => v != '#REF!');

      let d = '';
      let t = '';
      for (let i = 0; i < tokens.length; i++) {
        if (tokens[i].indexOf('.') >= 0) {
          d = '.';
        } else if (tokens[i].indexOf('!') >= 0) {
          d = '!';
        }
        if (d) {
          t = tokens[i].split(d);
          tokens[i] = t[1];
          t = t[0] + d;
        }
      }

      tokens.sort((a, b) => {
        const t1 = Helpers.getCoordsFromColumnName(a);
        const t2 = Helpers.getCoordsFromColumnName(b);
        if (t1[1] > t2[1]) {
          return 1;
        } if (t1[1] < t2[1]) {
          return -1;
        } if (t1[0] > t2[0]) {
          return 1;
        } if (t1[0] < t2[0]) {
          return -1;
        }
        return 0;
      });

      if (!tokens.length) {
        return '#REF!';
      }
      return `${t}${tokens[0]}:${tokens[tokens.length - 1]}`;
    };

    F.getTokensFromRange = function (range) {
      if (range.indexOf('.') > 0) {
        var t = range.split('.');
        range = t[1];
        t = `${t[0]}.`;
      } else if (range.indexOf('!') > 0) {
        var t = range.split('!');
        range = t[1];
        t = `${t[0]}!`;
      } else {
        var t = '';
      }

      var range = range.split(':');
      const e1 = F.getCoordsFromColumnName(range[0]);
      const e2 = F.getCoordsFromColumnName(range[1]);

      if (e1[0] <= e2[0]) {
        var x1 = e1[0];
        var x2 = e2[0];
      } else {
        var x1 = e2[0];
        var x2 = e1[0];
      }

      if (e1[1] === null && e2[1] == null) {
        var y1 = null;
        var y2 = null;

        const k = Object.keys(vars);
        for (var i = 0; i < k.length; i++) {
          const tmp = F.getCoordsFromColumnName(k[i]);
          if (tmp[0] === e1[0]) {
            if (y1 === null || tmp[1] < y1) {
              y1 = tmp[1];
            }
          }
          if (tmp[0] === e2[0]) {
            if (y2 === null || tmp[1] > y2) {
              y2 = tmp[1];
            }
          }
        }
      } else if (e1[1] <= e2[1]) {
        var y1 = e1[1];
        var y2 = e2[1];
      } else {
        var y1 = e2[1];
        var y2 = e1[1];
      }

      const f = [];
      for (let j = y1; j <= y2; j++) {
        const line = [];
        for (var i = x1; i <= x2; i++) {
          line.push(t + F.getColumnNameFromCoords(i, j));
        }
        f.push(line);
      }

      return f;
    };

    F.setFormula = function (o) {
      const k = Object.keys(o);
      for (let i = 0; i < k.length; i++) {
        if (typeof o[k[i]] === 'function') {
          scope[k[i]] = o[k[i]];
        }
      }
    };

    F.basic = true;

    return F;
  };

  return Formula(typeof window === 'undefined' ? global : window);
})));
