/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Controller, useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { InformationCircleIcon, PlusIcon, XMarkIcon } from '@heroicons/react/20/solid';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { getProjects } from '../../../redux/actions/projects';
import ToolTip from '../../../elements/ToolTip';
import InputField from './AccountDataInputField';
import Dropdown from './Dropdown'; // import wrapper for the common Dropdown component
import Button from '../../../elements/Button';
import SwitchComponentYesNo from './SwitchComponent';

dayjs.extend(utc);

function SwitchComponentGivenTaken({ id, idx, onChange, onBlur, label, tooltip, optional, value, errors }) {
  SwitchComponentGivenTaken.propTypes = {
    id: PropTypes.string.isRequired,
    idx: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func,
    label: PropTypes.string.isRequired,
    tooltip: PropTypes.string.isRequired,
    optional: PropTypes.bool,
    value: PropTypes.string.isRequired,
    errors: PropTypes.array,
  };
  SwitchComponentGivenTaken.defaultProps = {
    onBlur: () => {},
    optional: true,
    errors: [],
  };

  const { t } = useTranslation('app', { keyPrefix: 'addAccount.loans' });

  let displayedText;
  switch (value) {
    case 'granted':
      displayedText = t('given');
      break;
    case 'received':
      displayedText = t('taken');
      break;
    default:
      displayedText = '?';
  }

  function handleClick() {
    if (value === 'received') onChange('granted');
    if (value === 'granted') onChange(undefined);
    if (!value && value !== false) onChange('received');
  }

  return (
    <div className="col-span-1">
      <div className="flex justify-between text-sm">
        <div className="flex">
          <label htmlFor="switch-button" className="text-gray-700 font-medium">
            {label}
          </label>
          <ToolTip info={tooltip} />
        </div>
        {optional && (
          <span className="text-sm text-gray-400" id="email-optional">
            Optional
          </span>
        )}
      </div>
      <div className="flex items-center w-full mt-1">
        <div className="flex gap-2 items-center w-full">
          <button
            type="button"
            // eslint-disable-next-line max-len
            className={`${optional} rounded-md w-full px-4 py-2 text-sm font-bold shadow-sm border border-gray-300 bg-white text-gray-900 hover:bg-gray-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brandBlue-500`}
            onClick={handleClick}
          >
            {displayedText}
          </button>
        </div>
      </div>
      <p className="mt-2 text-sm text-red-600" id={`${id}-error`}>
        {errors.accountArray?.[idx]?.[id] && <span>{errors.accountArray[idx][id].message}</span>}
      </p>
    </div>
  );
}

/**
 *
 * Displayed on AccountData as part of the Stock account object (for each account)
 *
 * @param {number} idx - index of the account in the accountArray (and therefore also in the AccountData display)
 * @param {object} formState - formState object from react-hook-form of the entire AddAccount workflow
 * @param {function} register - register function from react-hook-form
 * @param {object} userInput - userInput object from the entire AddAccount workflow
 * @param {array} selectDepositAccounts - array of deposit accounts to be displayed in the dropdown
 *
 * @returns AccountDataLoanFields component; parent is a grid-cols-1 md:-3 xl:-4 grid
 */
export default function AccountDataLoanFields({ idx, formState, control, register, watch, getValues, setValue, userInput, selectDepositAccounts, refreshUserInputAndWorkflowStatus }) {
  const { t } = useTranslation('app', { keyPrefix: 'addAccount.loans' });
  const selectedProjects = useSelector((state) => state.projects)?.map((p) => ({ id: p.id, name: p.name }));

  const dispatch = useDispatch();

  console.log('DEBUG AccountDataLoanFields', idx, formState, userInput, selectDepositAccounts);

  useEffect(() => {
    refreshUserInputAndWorkflowStatus();
  }, [formState]);

  useEffect(() => {
    // if there are no projects, fetch them
    if (selectedProjects.length === 0) {
      dispatch(getProjects());
    }

    // pre-set default values for both checkboxes
    if (userInput.accountArray && userInput.accountArray[idx]?.calculateUntilEnd === undefined) setValue(`accountArray.${idx}.calculateUntilEnd`, true);
    if (userInput.accountArray && userInput.accountArray[idx]?.projectCreateNew === undefined) setValue(`accountArray.${idx}.projectCreateNew`, true);
  }, []);

  const { fields, append, remove } = useFieldArray({
    name: `accountArray.${idx}.interestRate`,
    control,
  });

  const watchCalculateUntilEnd = watch(`accountArray.${idx}.calculateUntilEnd`);
  const watchType = watch(`accountArray.${idx}.type`);
  const watchAnnuitySubtype = watch(`accountArray.${idx}.annuitySubtype`);
  const watchFixedPrincipalSubtype = watch(`accountArray.${idx}.fixedPrincipalSubtype`);
  const watchInterestRateSimple = watch(`accountArray.${idx}.interestRateSimple`); // the actual interest rate as number
  const watchInterestRateAdvancedView = watch(`accountArray.${idx}.interestRateAdvancedView`); // whether or not to show the advanced view
  const watchConnectedDepositMode = watch(`accountArray.${idx}.connectedDepositMode`); // whether to use an existing account or create a new one
  const watchProjectCreateNew = watch(`accountArray.${idx}.projectCreateNew`);

  return (
    <>
      {/* ROW 1 */}

      {/* TITLE + INFO */}
      <div className="md:col-start-1 text-sm font-medium text-gray-400">
        <p>{t('provideSomeParameters')}</p>
      </div>

      {/* AMOUNT, DIRECTION, PERIOD */}
      <div className="md:col-span-2 xl:col-span-3 grid grid-cols-3 gap-6">
        <InputField
          id="loanAmount"
          myIndex={idx}
          type="number"
          autoComplete="off"
          label={t('loanAmount.label')}
          toolTip={t('loanAmount.tooltip')}
          placeholder="250000"
          register={register}
          setValue={setValue}
          validationRules={{ required: { value: true, message: t('loanAmount.error') } }}
          errors={formState.errors}
        />
        <Controller
          name={`accountArray.${idx}.direction`}
          control={control}
          rules={{ required: { value: true, message: t('direction.error') } }}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <SwitchComponentGivenTaken
              id="direction"
              idx={idx}
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              inputRef={ref}
              label={t('direction.label')}
              toolTip={t('direction.tooltip')}
              optional={false}
              errors={formState.errors}
            />
          )}
        />
        <Dropdown
          id={`accountArray.${idx}.period`}
          list={[
            { id: 'month', name: t('period.month') },
            { id: 'quarter', name: t('period.quarter') },
            { id: 'year', name: t('period.year') },
          ]}
          label={t('period.label')}
          placeholder={t('period.placeholder')}
          toolTip={t('period.tooltip')}
          register={register}
          validationRules={{ required: { value: true, message: t('period.error') } }}
          errors={formState.errors}
          control={control}
          optional={false}
        />
      </div>

      {/* ROW 2 */}

      <div className="md:col-start-1 text-sm font-medium text-gray-400">
        <p>{t('interestRate.text')}</p>
      </div>

      {/* INTEREST RATE */}

      <div className="grid grid-cols-3 gap-2 content-baseline col-span-2 lg:col-span-1 ">
        {!watchInterestRateAdvancedView ? (
          <>
            <InputField
              formatting="col-span-2"
              id="interestRateSimple"
              myIndex={idx}
              type="number"
              autoComplete="off"
              label={t('interestRate.label')}
              toolTip={t('interestRate.tooltip')}
              placeholder="4"
              register={register}
              setValue={setValue}
              validationRules={{ required: { value: true, message: t('interestRate.error') } }}
              errors={formState.errors}
            />
            <Button
              formatting="self-start mt-6"
              text={t('advanced')}
              size="xl"
              onClick={() => {
                append({ date: dayjs.utc().format('YYYY-MM-DD'), value: watchInterestRateSimple });
                setValue(`accountArray.${idx}.interestRateAdvancedView`, true);
              }}
            />
          </>
        ) : (
          <div className="col-span-full grid grid-cols-6 gap-3 text-sm xs:text-base items-start text-gray-700">
            <p className="col-span-6">{t('interestRate.label')}</p>
            <span className="ml-1 -mb-1 text-sm text-left col-span-3">Effective from</span>
            <span className="ml-1 -mb-1 text-sm text-left col-span-2">Value</span>
            <div />
            {fields.map((item, index) => (
              <>
                <div className="col-span-3">
                  <input
                    type="date"
                    id={`date-${index}`}
                    name={`date-${index}`}
                    className="block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-gray-900 focus:outline-none focus:ring-gray-900 sm:text-sm"
                    {...register(`accountArray.${idx}.interestRate..${index}.date`, { required: true })}
                  />
                  {formState.errors.interestRate?.[index]?.date && <span className="text-xs text-red-500">{t('errorFieldRequired')}</span>}
                </div>

                <div className="col-span-2">
                  <input
                    type="number"
                    step={3}
                    name={`value-${index}`}
                    id={`value-${index}`}
                    className="block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-gray-900 focus:outline-none focus:ring-gray-900 sm:text-sm"
                    {...register(`accountArray.${idx}.interestRate.${index}.value`, { required: true })}
                  />
                  {formState.errors.interestRate?.[index]?.value && <span className="text-xs text-red-500">{t('errorFieldRequired')}</span>}
                </div>
                {index !== 0 && <Button Icon={XMarkIcon} onClick={() => remove(index)} size="lg" />}
              </>
            ))}
            <Button Icon={PlusIcon} onClick={() => append()} formatting="w-full" />
          </div>
        )}
      </div>

      {/* ROW 3 */}

      <div className="md:col-start-1 text-sm font-medium text-gray-400">
        <p>{t('endDate.text')}</p>
      </div>

      {/* loan contract end date */}

      <InputField
        id="endDate"
        myIndex={idx}
        type="date"
        autoComplete="off"
        label={t('endDate.label')}
        toolTip={t('endDate.tooltip')}
        placeholder="1"
        enabled={!watchCalculateUntilEnd}
        optional
        setValue={setValue}
        // calculateUntilEnd must be true and the date is same or after today; otherwise throw error
        validationRules={{
          validate: (value) => watchCalculateUntilEnd || dayjs(value).isSame(dayjs(), 'day') || dayjs(value).isAfter(dayjs()) || t('endDate.error'),
        }}
        register={register}
        errors={formState.errors}
      />
      <div className="flex space-x-2 text-gray-700 pt-8">
        <input className="text-gray-400" type="checkbox" {...register(`accountArray.${idx}.calculateUntilEnd`)} />
        <label htmlFor={`accountArray.${idx}.calculateUntilEnd`} className="flex text-sm font-medium">
          {t('calculateUntilEnd.label')}
        </label>
      </div>

      {/* ROW 4 */}
      <div className="md:col-start-1 text-sm font-medium text-gray-400">
        <p>{t('installmentCalc')}</p>
      </div>

      {/* INSTALLMENT CALC */}
      <div className="md:col-span-2 xl:col-span-3 grid grid-cols-1 xl:grid-cols-3 gap-6">
        <Dropdown
          id={`accountArray.${idx}.type`}
          list={[
            { id: 'annuity', name: t('type.annuity') },
            { id: 'fixedPrincipal', name: t('type.fixedPrincipal') },
            { id: 'interestOnly', name: t('type.interestOnly') },
          ]}
          label={t('type.label')}
          toolTip={t('type.tooltip')}
          register={register}
          validationRules={{ required: { value: true, message: t('type.error') } }}
          errors={formState.errors}
          control={control}
          optional={false}
        />

        {/* SUBMENU FOR ANNUITY */}

        {watchType === 'annuity' && (
          <>
            <div className="mt-3 flex flex-col space-y-4">
              <div className="flex space-x-2 items-center">
                <input
                  id="byInstallmentAmount"
                  name="byInstallmentAmount"
                  type="radio"
                  value="byInstallmentAmount"
                  className="h-4 w-4 border-gray-300 text-brandBlue-500 focus:ring-brandBlue-400"
                  {...register(`accountArray.${idx}.annuitySubtype`, { required: { value: true, message: t('annuitySubtype.error') } })}
                />
                <label htmlFor="byInstallmentAmount" className="ml-3 block text-sm font-medium text-gray-700">
                  {t('byInstallentAmount')}
                </label>
              </div>
              <div className="flex space-x-2 items-center">
                <input
                  id="byTilgungsrate"
                  name="byTilgungsrate"
                  type="radio"
                  value="byTilgungsrate"
                  className="h-4 w-4 border-gray-300 text-brandBlue-500 focus:ring-brandBlue-400"
                  {...register(`accountArray.${idx}.annuitySubtype`, { required: { value: true, message: t('annuitySubtype.error') } })}
                />
                <label htmlFor="byTilgungsrate" className="ml-3 block text-sm font-medium text-gray-700">
                  {t('byTilgungsrate')}
                </label>
              </div>
              <p className="mt-2 text-sm text-red-500" id="connectedDepositMode-error">
                {formState.errors.accountArray?.[idx]?.annuitySubtype && <span>{formState.errors.accountArray[idx].annuitySubtype.message}</span>}
              </p>
            </div>

            {/* conditionally display one of the fields if annuity subtype is selected */}

            {watchAnnuitySubtype === 'byInstallmentAmount' && (
              <InputField
                id="annuitySubtypeAmount"
                myIndex={idx}
                type="number"
                autoComplete="off"
                label={t('annuitySubtypeAmount.label')}
                toolTip={t('annuitySubtypeAmount.tooltip')}
                placeholder="1000"
                register={register}
                setValue={setValue}
                validationRules={{ required: { value: true, message: t('annuitySubtypeAmount.error') } }}
                errors={formState.errors}
              />
            )}
            {watchAnnuitySubtype === 'byTilgungsrate' && (
              <InputField
                id="annuitySubtypeTilgung"
                myIndex={idx}
                type="number"
                autoComplete="off"
                label={t('annuitySubtypeTilgung.label')}
                toolTip={t('annuitySubtypeTilgunginterestRate.tooltip')}
                placeholder="2"
                register={register}
                setValue={setValue}
                validationRules={{ required: { value: true, message: t('annuitySubtypeAmount.error') } }}
                errors={formState.errors}
              />
            )}
          </>
        )}

        {/* SUBMENU FOR FIXED PRINCIPAL */}

        {watchType === 'fixedPrincipal' && (
          <>
            <div className="mt-3 flex flex-col space-y-4">
              <div className="flex space-x-2 items-center">
                <input
                  id="byPrincipalRepaid"
                  name="byPrincipalRepaid"
                  type="radio"
                  value="byPrincipalRepaid"
                  className="h-4 w-4 border-gray-300 text-brandBlue-500 focus:ring-brandBlue-400"
                  {...register(`accountArray.${idx}.fixedPrincipalSubtype`, { required: { value: true, message: t('fixedPrincipalSubtype.error') } })}
                />
                <label htmlFor="byPrincipalRepaid" className="ml-3 block text-sm font-medium text-gray-700">
                  {t('byPrincipalRepaid')}
                </label>
              </div>
              <div className="flex space-x-2 items-center">
                <input
                  id="byTilgungsrate"
                  name="byTilgungsrate"
                  type="radio"
                  value="byTilgungsrate"
                  className="h-4 w-4 border-gray-300 text-brandBlue-500 focus:ring-brandBlue-400"
                  {...register(`accountArray.${idx}.fixedPrincipalSubtype`, { required: { value: true, message: t('fixedPrincipalSubtype.error') } })}
                />
                <label htmlFor="byTilgungsrate" className="ml-3 block text-sm font-medium text-gray-700">
                  {t('byTilgungsrate')}
                </label>
              </div>
              <p className="mt-2 text-sm text-red-500" id="fixedPrincipalSubtype-error">
                {formState.errors.accountArray?.[idx]?.fixedPrincipalSubtype && <span>{formState.errors.accountArray[idx].fixedPrincipalSubtype.message}</span>}
              </p>
            </div>

            {/* conditionally display one of the fields if 'fixed principal' subtype is selected */}

            {watchFixedPrincipalSubtype === 'byPrincipalRepaid' && (
              <InputField
                id="fixedPrincipalSubtypeAmount"
                myIndex={idx}
                type="number"
                autoComplete="off"
                label={t('fixedPrincipalSubtypeAmount.label')}
                toolTip={t('fixedPrincipalSubtypeAmount.tooltip')}
                placeholder="1000"
                register={register}
                setValue={setValue}
                validationRules={{ required: { value: true, message: t('fixedPrincipalSubtypeAmount.error') } }}
                errors={formState.errors}
              />
            )}
            {watchFixedPrincipalSubtype === 'byTilgungsrate' && (
              <InputField
                id="fixedPrincipalSubtypeTilgung"
                myIndex={idx}
                type="number"
                autoComplete="off"
                label={t('fixedPrincipalSubtypeTilgung.label')}
                toolTip={t('fixedPrincipalSubtypeTilgung.tooltip')}
                placeholder="2"
                register={register}
                setValue={setValue}
                validationRules={{ required: { value: true, message: t('fixedPrincipalSubtypeAmount.error') } }}
                errors={formState.errors}
              />
            )}
          </>
        )}
      </div>

      {/* ROW 5 */}

      <div className="md:col-start-1 text-sm font-medium text-gray-400">
        <p>{t('connectedDepositAccount')}</p>
      </div>

      {/* connectedDepositId component */}

      {/* AFAIK fieldset is required to treat the options as one field */}
      <fieldset className="md:col-span-2 xl:col-span-3 grid md:grid-cols-2 xl:grid-cols-3 justify-items-start items-center gap-4 text-sm text-gray-400">
        <div className="flex items-center">
          <input
            id="existingAccount"
            name="existingAccount"
            type="radio"
            value="existingAccount"
            className="h-4 w-4 border-gray-300 text-brandBlue-500 focus:ring-brandBlue-400"
            {...register(`accountArray.${idx}.connectedDepositMode`, { required: { value: true, message: t('connectedDepositMode.error') } })}
          />
          <label htmlFor="existingAccount" className="ml-3 block text-sm font-medium text-gray-700">
            {t('existingAccount')}
          </label>
          {/* if this radio button is selected, display the deposit accounts dropdown */}
        </div>
        {watchConnectedDepositMode === 'existingAccount' && (
          <div className="xl:flex xl:items-center">
            <label htmlFor="connectedDepositAccountObject" className="flex text-sm font-medium text-gray-700">
              {t('existingAccountSelect')}
            </label>
            <div className="mt-1 ml-2">
              <select
                id="connectedDepositAccountObject"
                name="connectedDepositAccountObject"
                className="text-gray-500 shadow-sm focus:ring-brandBlue-400 focus:border-brandBlue-400 block w-full text-sm border-gray-300 rounded-md"
                {...register(`accountArray.${idx}.connectedDepositAccountObject`)}
              >
                {selectDepositAccounts.map((a) => (
                  <option key={a.id} value={a.id}>
                    {a.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        )}

        <div className="flex items-center col-start-1">
          <input
            id="createAccountLater"
            name="createAccountLater"
            type="radio"
            value="createAccountLater"
            className="h-4 w-4 border-gray-300 text-brandBlue-500 focus:ring-brandBlue-400"
            {...register(`accountArray.${idx}.connectedDepositMode`, { required: { value: true, message: t('connectedDepositMode.error') } })}
          />
          <label htmlFor="createAccountLater" className="ml-3 block text-sm font-medium text-gray-700">
            {t('createAccountLater')}
          </label>
        </div>
        {watchConnectedDepositMode === 'createAccountLater' && (
          <div className="flex gap-2">
            <InformationCircleIcon className="h-6 w-6 text-brandBlue-500 self-start" aria-hidden="true" />
            {t('createAccountLaterDescription')}
          </div>
        )}
        <p className="col-start-1 mt-2 text-sm text-red-500" id="connectedDepositMode-error">
          {formState.errors.accountArray?.[idx]?.connectedDepositMode && <span>{formState.errors.accountArray[idx].connectedDepositMode.message}</span>}
        </p>
      </fieldset>

      {/* ROW 6 */}

      <div className="md:col-start-1 text-sm font-medium text-gray-400">
        <p>{t('loanProject')}</p>
      </div>

      {/* projects component */}

      <div className="md:col-span-2 xl:col-span-3 grid grid-cols-2 xl:grid-cols-3 gap-6 items-center">
        <Dropdown
          id={`accountArray.${idx}.connectedProjectId`}
          list={selectedProjects}
          label={t('connectedProjectId.label')}
          toolTip={t('connectedProjectId.tooltip')}
          register={register}
          errors={formState.errors}
          control={control}
          enabled={!watchProjectCreateNew}
          validationRules={{
            validate: (value) => {
              const projectCreateNew = getValues(`accountArray.${idx}.projectCreateNew`);
              // if `projectCreateNew` is false, then `connectedProjectId` must be filled out
              if (!projectCreateNew && !value) {
                return 'This field is required when "Create New Project" is not checked.';
              }
              return true; // No error
            },
          }}
          optional={false}
        />
        <div className="flex space-x-2 mt-4 text-gray-700">
          <input className="text-gray-400" type="checkbox" {...register(`accountArray.${idx}.projectCreateNew`)} />
          <label htmlFor={`accountArray.${idx}.projectCreateNew`} className="flex text-sm font-medium">
            {t('projectCreateNew')}
          </label>
        </div>
      </div>

      {/* ADVANCED SECTION */}

      <h2 className="text-lg font-bold text-gray-700">{t('advanced')}</h2>

      {/* ROW 7 */}

      <div className="md:col-start-1 text-sm font-medium text-gray-400">
        <p>{t('bereitstellungszinsen.text')}</p>
      </div>

      {/* BEREITSSTELLUNGSZINSEN */}

      <div className="md:col-span-2 xl:col-span-3 grid grid-cols-2 xl:grid-cols-3 gap-6 items-center">
        <InputField
          id="bereitstellungszinsen"
          myIndex={idx}
          type="number"
          autoComplete="off"
          label={t('bereitstellungszinsen.label')}
          toolTip={t('bereitstellungszinsen.tooltip')}
          placeholder="1"
          optional
          register={register}
          setValue={setValue}
          errors={formState.errors}
        />
        <InputField
          id="bereitstellungszinsenSinceDate"
          myIndex={idx}
          type="date"
          autoComplete="off"
          label={t('bereitstellungszinsenSinceDate.label')}
          toolTip={t('bereitstellungszinsenSinceDate.tooltip')}
          placeholder="1"
          optional
          register={register}
          setValue={setValue}
          errors={formState.errors}
        />
      </div>

      {/* ROW 8 */}

      <div className="md:col-start-1 text-sm font-medium text-gray-400">
        <p>{t('interestAfterTerm.text')}</p>
      </div>

      {/* POST-TERM INTEREST */}

      <div className="md:col-span-2 xl:col-span-3 grid grid-cols-2 xl:grid-cols-3 gap-6 items-center">
        <InputField
          id="interestAfterTerm"
          myIndex={idx}
          type="number"
          autoComplete="off"
          label={t('interestAfterTerm.label')}
          toolTip={t('interestAfterTerm.tooltip')}
          placeholder="1"
          optional
          register={register}
          setValue={setValue}
          errors={formState.errors}
        />
        <InputField
          id="interestAfterTermSinceDate"
          myIndex={idx}
          type="date"
          autoComplete="off"
          label={t('interestAfterTermSinceDate.label')}
          toolTip={t('interestAfterTermSinceDate.tooltip')}
          placeholder="1"
          optional
          register={register}
          setValue={setValue}
          errors={formState.errors}
        />
      </div>

      {/* ROW 9 */}

      <div className="md:col-start-1 text-sm font-medium text-gray-400">
        <p>{t('interestCalculationCutoff')}</p>
      </div>

      {/* INTEREST CALC CUTOFF DAY */}

      <Controller
        name={`accountArray.${idx}.interestCalcMonthEnd`}
        control={control}
        render={({ field: { onChange, onBlur, value, ref } }) => (
          <SwitchComponentYesNo
            id="interestCalcMonthEnd"
            idx={idx}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            inputRef={ref}
            label={t('interestCalcMonthEnd.label')}
            toolTip={t('interestCalcMonthEnd.tooltip')}
            optional
            errors={formState.errors}
          />
        )}
      />
    </>
  );
}
AccountDataLoanFields.propTypes = {
  idx: PropTypes.number.isRequired,
  formState: PropTypes.object.isRequired,
  register: PropTypes.func.isRequired,
  control: PropTypes.object.isRequired,
  watch: PropTypes.func.isRequired,
  getValues: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  userInput: PropTypes.object.isRequired,
  selectDepositAccounts: PropTypes.array.isRequired,
  refreshUserInputAndWorkflowStatus: PropTypes.func.isRequired,
};
