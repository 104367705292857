[
  {
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5SYB8",
    "date": 1701508602330,
    "displayName": "BASF (DE000BASF111)",
    "purchaseValue": 3604.9999999999995,
    "currentValue": 2162,
    "currency": "EUR",
    "payoutsSincePurchase": 361.5,
    "feesSincePurchase": -19.9,
    "roi": "-30,55 %",
    "roiAnnualData": [
      {
        "roiAnnual": -0.07635740974504368,
        "weight": 50
      }
    ],
    "roiAnnual": "-7,64 %",
    "formattingLevel": null
  },
  {
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5N8V8",
    "date": 1701508602330,
    "displayName": "Apple (US0378331005)",
    "purchaseValue": 60282.64599999999,
    "currentValue": 65021.600000000006,
    "currency": "USD",
    "payoutsSincePurchase": 307.27500000000003,
    "feesSincePurchase": -101.66,
    "roi": "8,20 %",
    "roiAnnualData": [
      {
        "roiAnnual": 0.015612206060211076,
        "weight": 340
      }
    ],
    "roiAnnual": "1,56 %",
    "formattingLevel": null
  },
  {
    "category": "realEstate",
    "accountId": "0LBYVRlkkfRWVNk0y2c4a",
    "accountName": "Real estate asset",
    "assetId": "0LBYVRlkkfRWVNk0y2c4a",
    "date": 1701508602330,
    "displayName": "Real estate asset",
    "purchaseValue": 400000,
    "currentValue": 495000,
    "currency": "EUR",
    "payoutsSincePurchase": 0,
    "feesSincePurchase": 0,
    "roi": "23,75 %",
    "roiAnnualData": [
      {
        "roiAnnual": 0.01910741560370588,
        "weight": 1
      }
    ],
    "roiAnnual": "1,91 %",
    "formattingLevel": null
  },
  {
    "category": "deposits",
    "accountId": "-b3jqm5IvL6kobgCcdICR",
    "accountName": "Bank for real estate",
    "assetId": "-b3jqm5IvL6kobgCcdICR",
    "date": 1701508602330,
    "displayName": "Bank for real estate",
    "purchaseValue": 0,
    "currentValue": 549456.75,
    "currency": "EUR",
    "payoutsSincePurchase": 0,
    "feesSincePurchase": 0,
    "roi": "∞ %",
    "roiAnnualData": [
      {
        "roiAnnual": null,
        "weight": -543.25
      },
      {
        "roiAnnual": null,
        "weight": 550000
      }
    ],
    "roiAnnual": "NaN %",
    "formattingLevel": null
  },
  {
    "category": "deposits",
    "accountId": "AUAMQVhQaeLIHriycINeL",
    "accountName": "Bank for brokerage",
    "assetId": "AUAMQVhQaeLIHriycINeL",
    "date": 1701508602330,
    "displayName": "Bank for brokerage",
    "purchaseValue": 0,
    "currentValue": 107749.58510000001,
    "currency": "EUR",
    "payoutsSincePurchase": 0,
    "feesSincePurchase": 0,
    "roi": "-∞ %",
    "roiAnnualData": [
      {
        "roiAnnual": null,
        "weight": 69
      },
      {
        "roiAnnual": null,
        "weight": -10
      },
      {
        "roiAnnual": null,
        "weight": -15
      },
      {
        "roiAnnual": null,
        "weight": 9083.4473
      },
      {
        "roiAnnual": null,
        "weight": -15.5622
      },
      {
        "roiAnnual": null,
        "weight": 162.5
      },
      {
        "roiAnnual": null,
        "weight": -3605
      },
      {
        "roiAnnual": null,
        "weight": -29.9
      },
      {
        "roiAnnual": null,
        "weight": 2000
      },
      {
        "roiAnnual": null,
        "weight": 130
      },
      {
        "roiAnnual": null,
        "weight": 100000
      },
      {
        "roiAnnual": null,
        "weight": -19.9
      }
    ],
    "roiAnnual": "NaN %",
    "formattingLevel": null
  },
  {
    "category": "deposits",
    "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
    "accountName": "Bank for cost of living",
    "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
    "date": 1701508602332,
    "displayName": "Bank for cost of living",
    "purchaseValue": 0,
    "currentValue": 90943.79000000001,
    "currency": "EUR",
    "payoutsSincePurchase": 0,
    "feesSincePurchase": 0,
    "roi": "-∞ %",
    "roiAnnualData": [
      {
        "roiAnnual": null,
        "weight": -1500
      },
      {
        "roiAnnual": null,
        "weight": 6750
      },
      {
        "roiAnnual": null,
        "weight": -1500
      },
      {
        "roiAnnual": null,
        "weight": -1500
      },
      {
        "roiAnnual": null,
        "weight": -1500
      },
      {
        "roiAnnual": null,
        "weight": -1500
      },
      {
        "roiAnnual": null,
        "weight": 6500
      },
      {
        "roiAnnual": null,
        "weight": -1500
      },
      {
        "roiAnnual": null,
        "weight": -2850
      },
      {
        "roiAnnual": null,
        "weight": -2500
      },
      {
        "roiAnnual": null,
        "weight": -1500
      },
      {
        "roiAnnual": null,
        "weight": -2850
      },
      {
        "roiAnnual": null,
        "weight": -1500
      },
      {
        "roiAnnual": null,
        "weight": -1500
      },
      {
        "roiAnnual": null,
        "weight": 6750
      },
      {
        "roiAnnual": null,
        "weight": -2500
      },
      {
        "roiAnnual": null,
        "weight": -2850
      },
      {
        "roiAnnual": null,
        "weight": 5000
      },
      {
        "roiAnnual": null,
        "weight": 6750
      },
      {
        "roiAnnual": null,
        "weight": -1500
      },
      {
        "roiAnnual": null,
        "weight": -317
      },
      {
        "roiAnnual": null,
        "weight": -253
      },
      {
        "roiAnnual": null,
        "weight": 6500
      },
      {
        "roiAnnual": null,
        "weight": -1500
      },
      {
        "roiAnnual": null,
        "weight": -1500
      },
      {
        "roiAnnual": null,
        "weight": 6500
      },
      {
        "roiAnnual": null,
        "weight": -1500
      },
      {
        "roiAnnual": null,
        "weight": 6750
      },
      {
        "roiAnnual": null,
        "weight": -1500
      },
      {
        "roiAnnual": null,
        "weight": 4850
      },
      {
        "roiAnnual": null,
        "weight": -2500
      },
      {
        "roiAnnual": null,
        "weight": -2850
      },
      {
        "roiAnnual": null,
        "weight": -2000
      },
      {
        "roiAnnual": null,
        "weight": 100
      },
      {
        "roiAnnual": null,
        "weight": -2500
      },
      {
        "roiAnnual": null,
        "weight": -1500
      },
      {
        "roiAnnual": null,
        "weight": 100
      },
      {
        "roiAnnual": null,
        "weight": -2850
      },
      {
        "roiAnnual": null,
        "weight": -1500
      },
      {
        "roiAnnual": null,
        "weight": 6250
      },
      {
        "roiAnnual": null,
        "weight": -1500
      },
      {
        "roiAnnual": null,
        "weight": 6750
      },
      {
        "roiAnnual": null,
        "weight": -2850
      },
      {
        "roiAnnual": null,
        "weight": 6500
      },
      {
        "roiAnnual": null,
        "weight": -2500
      },
      {
        "roiAnnual": null,
        "weight": 6500
      },
      {
        "roiAnnual": null,
        "weight": -500
      },
      {
        "roiAnnual": null,
        "weight": -2500
      },
      {
        "roiAnnual": null,
        "weight": -2500
      },
      {
        "roiAnnual": null,
        "weight": -1500
      },
      {
        "roiAnnual": null,
        "weight": 6750
      },
      {
        "roiAnnual": null,
        "weight": -1500
      },
      {
        "roiAnnual": null,
        "weight": -1500
      },
      {
        "roiAnnual": null,
        "weight": 6500
      },
      {
        "roiAnnual": null,
        "weight": -1500
      },
      {
        "roiAnnual": null,
        "weight": 400
      },
      {
        "roiAnnual": null,
        "weight": 6750
      },
      {
        "roiAnnual": null,
        "weight": 6500
      },
      {
        "roiAnnual": null,
        "weight": -2350
      },
      {
        "roiAnnual": null,
        "weight": -1500
      },
      {
        "roiAnnual": null,
        "weight": -1500
      },
      {
        "roiAnnual": null,
        "weight": -2500
      },
      {
        "roiAnnual": null,
        "weight": 7700
      },
      {
        "roiAnnual": null,
        "weight": 10000
      },
      {
        "roiAnnual": null,
        "weight": 6500
      },
      {
        "roiAnnual": null,
        "weight": -1500
      },
      {
        "roiAnnual": null,
        "weight": -2500
      },
      {
        "roiAnnual": null,
        "weight": -1500
      },
      {
        "roiAnnual": null,
        "weight": -1500
      },
      {
        "roiAnnual": null,
        "weight": -2850
      },
      {
        "roiAnnual": null,
        "weight": -2850
      },
      {
        "roiAnnual": null,
        "weight": 6500
      },
      {
        "roiAnnual": null,
        "weight": -1500
      },
      {
        "roiAnnual": null,
        "weight": -1500
      },
      {
        "roiAnnual": null,
        "weight": -2850
      },
      {
        "roiAnnual": null,
        "weight": 6750
      },
      {
        "roiAnnual": null,
        "weight": -2850
      },
      {
        "roiAnnual": null,
        "weight": -1500
      },
      {
        "roiAnnual": null,
        "weight": 6750
      },
      {
        "roiAnnual": null,
        "weight": -1500
      },
      {
        "roiAnnual": null,
        "weight": -2500
      },
      {
        "roiAnnual": null,
        "weight": 6500
      },
      {
        "roiAnnual": null,
        "weight": -1500
      },
      {
        "roiAnnual": null,
        "weight": -2850
      },
      {
        "roiAnnual": null,
        "weight": -2500
      },
      {
        "roiAnnual": null,
        "weight": 6750
      },
      {
        "roiAnnual": null,
        "weight": -4500
      },
      {
        "roiAnnual": null,
        "weight": -2500
      },
      {
        "roiAnnual": null,
        "weight": -1500
      },
      {
        "roiAnnual": null,
        "weight": -546.21
      },
      {
        "roiAnnual": null,
        "weight": 20000
      },
      {
        "roiAnnual": null,
        "weight": -2850
      },
      {
        "roiAnnual": null,
        "weight": -3890
      },
      {
        "roiAnnual": null,
        "weight": -2850
      },
      {
        "roiAnnual": null,
        "weight": 6500
      },
      {
        "roiAnnual": null,
        "weight": 6750
      },
      {
        "roiAnnual": null,
        "weight": -2500
      },
      {
        "roiAnnual": null,
        "weight": 6500
      },
      {
        "roiAnnual": null,
        "weight": 6500
      },
      {
        "roiAnnual": null,
        "weight": 6500
      },
      {
        "roiAnnual": null,
        "weight": 6500
      },
      {
        "roiAnnual": null,
        "weight": 6750
      },
      {
        "roiAnnual": null,
        "weight": -2850
      },
      {
        "roiAnnual": null,
        "weight": -1500
      },
      {
        "roiAnnual": null,
        "weight": -2850
      },
      {
        "roiAnnual": null,
        "weight": -2850
      }
    ],
    "roiAnnual": "NaN %",
    "formattingLevel": null
  },
  {
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "date": 1701508602341,
    "displayName": "Bank for deposit",
    "purchaseValue": 358420020,
    "currentValue": 20010,
    "currency": "EUR",
    "payoutsSincePurchase": 10.000999800059978,
    "feesSincePurchase": 0,
    "roi": "-50,00 %",
    "roiAnnualData": [
      {
        "roiAnnual": -0.12530456364704712,
        "weight": 2
      },
      {
        "roiAnnual": -0.6883341934874894,
        "weight": 1100
      },
      {
        "roiAnnual": -0.15293742440995417,
        "weight": 2
      },
      {
        "roiAnnual": -0.2732660974292125,
        "weight": 2
      },
      {
        "roiAnnual": -0.8058808485599509,
        "weight": 18900
      },
      {
        "roiAnnual": -0.19627672735661306,
        "weight": 2
      },
      {
        "roiAnnual": -0.4467147205806653,
        "weight": 2
      }
    ],
    "roiAnnual": "-79,91 %",
    "formattingLevel": null
  },
  {
    "category": "deposits",
    "accountId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "accountName": "Bank for deposit 2",
    "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "date": 1701508602341,
    "displayName": "Bank for deposit 2",
    "purchaseValue": 1600000000,
    "currentValue": 40000,
    "currency": "EUR",
    "payoutsSincePurchase": 0,
    "feesSincePurchase": 0,
    "roi": "-100,00 %",
    "roiAnnualData": [
      {
        "roiAnnual": -0.987391633831109,
        "weight": 40000
      }
    ],
    "roiAnnual": "-98,74 %",
    "formattingLevel": null
  },
  {
    "category": "deposits",
    "accountId": "lyFDhscwJibqb-sf3pocN",
    "accountName": "Bank for fx deposit",
    "assetId": "lyFDhscwJibqb-sf3pocN",
    "date": 1701508602342,
    "displayName": "Bank for fx deposit",
    "purchaseValue": 0,
    "currentValue": 10137.8751,
    "currency": "CHF",
    "payoutsSincePurchase": 0,
    "feesSincePurchase": 0,
    "roi": "∞ %",
    "roiAnnualData": [
      {
        "roiAnnual": null,
        "weight": 10137.8751
      }
    ],
    "roiAnnual": "∞ %",
    "formattingLevel": null
  },
  {
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "date": 1701508602342,
    "displayName": "Σ",
    "purchaseValue": 63887.64599999999,
    "currentValue": 67183.6,
    "payoutsSincePurchase": 668.7750000000001,
    "feesSincePurchase": -121.56,
    "roi": "6,02 %",
    "roiAnnual": "1,56 %",
    "formattingLevel": 1
  },
  {
    "category": "stocks",
    "accountName": "Σ",
    "date": 1701508602342,
    "displayName": "Σ",
    "purchaseValue": 63887.64599999999,
    "currentValue": 67183.6,
    "payoutsSincePurchase": 668.7750000000001,
    "feesSincePurchase": -121.56,
    "roi": "6,02 %",
    "roiAnnual": "1,56 %",
    "formattingLevel": 0
  }
]