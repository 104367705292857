// used in AssetGrowthRate form as well as in putStockSettings redux
export const settingsStocksAndCrypto = {
  growthRate: 6.02,
  growthRateAdvancedMode: false,
  growthRates: [],
  dividendYield: 2.12,
  dividendYieldAdvancedMode: false,
  dividendYieldRates: [],
  crypto: {
    growthRate: 10,
    growthRateAdvancedMode: false,
    growthRates: [],
  },
};

// temporary, to satisfy the linter
export const dummy = 1;
