// ACCOUNTDETAILS ----------------------------------------------------------------------------------------------------

export const colWidths = {
  date: 100, otherParty: 150, description: 450, amount: 100, // FIXME: stocks (cols)
};

// these are initialisation values for the fields with changeable contents
export const attrs = {
  name: 'accountName',
  // bankName: 'bankName',
  // iban: 'IBAN',
  // bic: 'BIC',
};

// calculates total value of all realEstate transactions, by account, according to the latest valuation (by account)
// pass all transactions and valuations as parameters
// pass maximum date for the valuation (e.g. today for actual data, slider date for simulated data)
// also used to calculate project sum in ProjectDetails
export function realEstateTotal(transactionArray, valuationArray, maxDate) {
  function getMostRecentValuation(accountId) {
    return ((valuationArray
      .filter((item) => item.assetId === accountId && item.date <= maxDate)
      .sort((a, b) => b.date - a.date)[0]) || []) // sort by date descending, take first element
      .quoteBaseCurrency;
  }

  // group transactions by account and sum up the amounts
  const amountByAccountId = transactionArray
    ?.reduce((prev, curr) => ({ ...prev, [curr.accountId]: (prev[curr.accountId] || 0) + curr.amount }), {});
  // result is: { accountId1: 1, accountId2: 0, accountId3: 1, ... }

  // for each resulting account, multiply the sum of amount by the latest valuation for that amount
  // sum up all the resulting amounts
  // 'curr' is the key of amountByAccountId, which is the accountId
  return Object.keys(amountByAccountId || [])
    .reduce((prev, curr) => prev + (amountByAccountId[curr] * getMostRecentValuation(curr)), 0);
}
