/* eslint-disable no-restricted-syntax */
const jsrsasign = require('jsrsasign');

// eslint-disable-next-line import/prefer-default-export
export const verifyToken = async (accessToken, awsconfig) => {
  // returns true if the token can be verified, otherwise false
  // takes JWT as parameter

  // CAUTION: the verifyToken in the utils-library DOES NOT WORK in the browser environment

  // get public key from Cognito public endpoint
  function fetchPublicKeys() {
    return new Promise((resolve, reject) => {
      // check sessionStorage if the keys are there already, if not - download and put them there
      const keys = sessionStorage.getItem('cognitoPublicKeys');
      if (keys) resolve(JSON.parse(keys));
      else {
        fetch(`https://cognito-idp.${awsconfig.Auth.region}.amazonaws.com/${awsconfig.Auth.userPoolId}/.well-known/jwks.json`)
          .then((response) => {
            const responseJson = response.json(); // returns a promise
            responseJson.then((json) => {
              sessionStorage.setItem('cognitoPublicKeys', JSON.stringify(json));
            });
            resolve(responseJson); // returns a promise
          })
          .catch((error) => reject(error));
      }
    });
  }

  let publicKeysObject;
  try {
    publicKeysObject = await fetchPublicKeys();
    // console.info('public keys fetched --> ', JSON.stringify(publicKeysObject));
  } catch (err) {
    if (err.name === 'AbortError') {
      // request was aborted
      throw new Error(`Fetch request was aborted. Details:${err}`);
    } else {
      throw new Error(`Fetch request error. Details:${err}`);
    }
  }

  // find out which of the two keys in the file above matches the one in the token
  // attempt to verify token with both keys, if one returns true, return true, otherwise return false
  let returnObject = false;
  try {
    // eslint-disable-next-line guard-for-in
    publicKeysObject.keys.forEach((publicKey) => {
      const result = jsrsasign.KJUR.jws.JWS.verifyJWT(
        accessToken,
        jsrsasign.KEYUTIL.getKey(publicKey),
        // if this fails again for both keys, check the difference between local clock and the server clock
        // and ggf. adjust grace period here
        // more info: https://kjur.github.io/jsrsasign/api/symbols/KJUR.jws.JWS.html#.verifyJWT
        { alg: ['RS256'], gracePeriod: 60 },
      );
      if (result) returnObject = true;
      // console.info('testing key ', publicKey, 'result', result);
    });
  } catch (error) {
    throw new Error(`Error while trying to validate JWT. Details: ${error}`);
  }
  return returnObject;
};
