/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-restricted-syntax */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { createSelector } from '@reduxjs/toolkit';
import '../../css/pivottable.css';
import { nanoid } from 'nanoid';
import PivotTable from 'react-pivottable/PivotTable';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/20/solid';
import getReportingDataSelector from './reportingSelectors';
import HeaderApp from '../../elements/HeaderApp';
import Settings from '../settings/Settings';
import RecommendUs from '../../elements/RecommendUs';
import ReportEdit from './ReportEdit';
import FullScreenReport from '../../elements/FullScreenReport';
import { getProjects } from '../../redux/actions/projects';
import { getReports, postReport, deleteReport } from '../../redux/reducers/reports';
import * as selectors from '../../redux/reducers/data';
import TourWrapper from '../../elements/TourWrapper';
import ToolTipNoIcon from '../../elements/ToolTipNoIcon';
import { SearchResults } from '../../elements/SearchComponent';
import renderers from './renderers';
import MiniSpinner from '../../misc/MiniSpinner';
import Button from '../../elements/Button';
import { getArticleLink } from '../../blog';
import ReportsList from './ReportsList';
import { enabledCategories } from '../../misc/globalSettings';
import getTour from '../../tours/getTour';

// Dashboard component is used to display the primary dashboard (mode === 'dashboard')
// as well as to display the projects page (mode === 'projects')
// which displays additionally the slider, project list and project details
// but disables Tiletip (recent transaction preview)

export default function Reporting() {
  const [displayedReportDef, setDisplayedReportDef] = useState(undefined); // which report is displayed in the current view
  const [report, setReport] = useState(undefined); // undefined means initialised, null means new report, report obj means existing report
  const [calendar, setCalendarState] = useState({
    visible: false,
    selectedDate: null,
    callback: null,
  }); // calendar used to set the baseline for comparison to current net worth
  const [settings, setSettings] = useState(false); // truthy or true for settings visible or name of tab; false or falsey for settings hidden
  const [recommendUsVisible, setRecommendUsVisible] = useState(false); // truthy or true for recommendUs visible or name of tab; false or falsey for recommendUs hidden
  const [searchControl, setSearchControl] = useState({
    text: '',
    searching: false,
    focus: null,
  }); // search results for the search bar;

  // this memoizes data and makes it not get refreshed on every state update
  const selectCategoryStatus = createSelector(
    (state) => state.data,
    (data) => Object.entries(data).reduce((prev, [category, value]) => ({ ...prev, [category]: value.status }), {}),
  );

  const categoryStatus = useSelector(selectCategoryStatus);
  const dashboardMode = useSelector((state) => state.simulation.dashboardMode);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { t, i18n } = useTranslation();

  // settings === true: call Settings with no openOnTab (i.e. default tab)
  // settings is string: call Settings with the string
  // settings is falsey: do not call Settings
  const SettingsComponent = settings ? (
    <Settings closeCallback={() => setSettings(false)} openOnTab={settings === true ? null : settings} calendarState={calendar} setCalendarState={setCalendarState} setSettings={setSettings} />
  ) : null;

  // if no data loaded, dispatch loading and suspend
  useEffect(() => {
    // get fx data from the separate service
    // quotes should be retrieved in the reducer by getData
    dispatch(getProjects());
    dispatch(getReports());

    // get data if there is none (store initialises the category with status: 'idle')
    enabledCategories.forEach((category) => {
      if (categoryStatus[category] === 'idle') dispatch(selectors.getDataByCategory(category));
    });
  }, []);

  const reportDefs = useSelector((state) => state.reports);
  const fullscreenReport = useSelector((state) => state.message.fullscreenReport);

  // as soon as the reportDefs are loaded, set the displayed report to the first one
  const prevReportDefs = useRef([]);
  useEffect(() => {
    if (reportDefs?.length > 0 && prevReportDefs.current?.length === 0) {
      // display sunburst if you can find it, otherwise display the first one
      setDisplayedReportDef(reportDefs.find((r) => r.id === 'allAssetsSunburstxxxx') || reportDefs[0]);
    }
    prevReportDefs.current = reportDefs;
  }, [reportDefs]);

  const standardPivotTableProps = {
    onChange: (s) => setDisplayedReportDef(s),
    renderers,
  };

  function handleNewReport() {
    // create new report from template
    setReport(null);
    // run postReport and wait for data to be refreshed and then set the new report as current in setReportId
  }

  function handleDeleteReport(e) {
    dispatch(deleteReport(displayedReportDef));
    setDisplayedReportDef(undefined);
  }

  function handleDuplicateReport(e) {
    dispatch(
      postReport({
        ...displayedReportDef,
        id: nanoid(),
        name: `${displayedReportDef.name} (copy)`,
      }),
    );
  }

  const dataSettings = useSelector((state) => getReportingDataSelector(state, displayedReportDef?.dataScope));
  dataSettings.data = dataSettings.data.filter(
    (x) => x.date > (displayedReportDef?.dateFilter?.from || new Date(1902, 0, 1).valueOf()) && x.date <= (displayedReportDef?.dateFilter?.to || new Date(2200, 0, 1).valueOf()),
  );
  // CAUTION: this function is also used in ReportEdit, ChartWrapper and PivotTableUI, if changing it here - change it there as well

  function setAccount(account) {
    navigate(`/${i18n.language}/app/dashboard`, { state: { account } });
  }

  function handleModeSwitch() {
    if (dashboardMode === 'dashboard') {
      dispatch({
        type: 'simulation/setDashboardMode',
        payload: { dashboardMode: 'projects' },
      });
    }
    if (dashboardMode === 'projects') {
      dispatch({
        type: 'simulation/setDashboardMode',
        payload: { dashboardMode: 'dashboard' },
      });
    }
  }

  return (
    <div className="overflow-scroll h-screen">
      <div className="relative xl:static">
        {(report || report === null) && <ReportEdit report={report} setReport={setReport} setDisplayedReportDef={setDisplayedReportDef} />}
        {SettingsComponent}
        {recommendUsVisible && <RecommendUs setRecommendUsVisible={setRecommendUsVisible} />}
        {fullscreenReport && <FullScreenReport reportProps={fullscreenReport} />}

        <div className="relative min-h-full bg-gray-50">
          <HeaderApp
            mode="reports"
            openSettings={() => setSettings(true)}
            openAccount={() => setSettings('account')}
            searchControl={searchControl}
            setSearchControl={setSearchControl}
            setRecommendUsVisible={setRecommendUsVisible}
          />
          {/* mt corresponds to the height of background padding set in HeaderApp */}
          <main className="pb-8 lg:-mt-36">
            <div className="relative max-w-3xl mx-auto px-4 sm:px-6 lg:max-w-[100rem] lg:px-8">
              {searchControl.text && <SearchResults searchControl={searchControl} setSearchControl={setSearchControl} setAccount={setAccount} />}
              <div className="relative lg:pl-4 pt-6 lg:pt-10 pb-2 lg:pb-8">
                <h2 className="text-2xl sm:text-3xl md:text-4xl lg:text-3xl xl:text-4xl font-bold text-gray-900 lg:text-white">{t('app:reporting.yourReports')}</h2>
              </div>
              {/* main report container */}
              <div className="lg:bg-white lg:rounded-md lg:shadow flex flex-col gap-4">
                <div className="divide-y divide-gray-200 lg:grid lg:grid-cols-12 lg:divide-y-0 lg:divide-x">
                  <aside className="pt-4 pb-6 lg:col-span-3">
                    <nav className="space-y-1">
                      <div className="text-sm lg:font-semibold lg:text-base lg:ml-4 mb-1 lg:mb-4">{t('app:reporting.selectReport')}</div>
                      <ReportsList reportDefs={reportDefs} displayedReportDef={displayedReportDef} setDisplayedReportDef={setDisplayedReportDef} setReport={setReport} />
                    </nav>
                  </aside>

                  <div className="divide-y divide-gray-200 lg:col-span-9">
                    <div className="hidden lg:flex py-6 px-4 sm:p-6 lg:pb-6 gap-2 items-center justify-between">
                      <div className="flex items-center gap-2">
                        <Button onClick={handleNewReport} text={t('app:reporting.new')} withAccent size="xl" />
                        {/* don't show buttons only for read-only reports */}
                        {displayedReportDef && !displayedReportDef.readOnly && (
                          <>
                            <ToolTipNoIcon info={t('app:reporting.tooltips.edit')}>
                              <Button onClick={() => setReport(displayedReportDef)} text={t('app:reporting.edit')} size="xl" />
                            </ToolTipNoIcon>
                            <ToolTipNoIcon info={t('app:reporting.tooltips.duplicate')}>
                              <Button onClick={handleDuplicateReport} text={t('app:reporting.duplicate')} size="xl" />
                            </ToolTipNoIcon>
                            <ToolTipNoIcon info={t('app:reporting.tooltips.delete')}>
                              <Button onClick={handleDeleteReport} text={t('app:reporting.delete')} size="xl" />
                            </ToolTipNoIcon>
                          </>
                        )}
                        <a
                          id="report-edit-help-button"
                          href={`/${i18n.language}/blog/${getArticleLink('reporting', i18n.language)}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="ml-4 cursor-pointer group"
                        >
                          <ToolTipNoIcon info={t('accountDetails.help')}>
                            <span className="text-brandBlue-400 text-sm font-semibold group-hover:text-brandBlue-500">How to use reports?</span>
                            <ArrowTopRightOnSquareIcon className="w-4 h-4 ml-1 text-brandBlue-400 group-hover:text-brandBlue-500" />
                          </ToolTipNoIcon>
                        </a>
                      </div>
                      <Button
                        onClick={handleModeSwitch}
                        text={dashboardMode === 'dashboard' ? t('app:reporting.switchToProjects') : t('app:reporting.switchToDashboard')}
                        formatting="justify-self-end"
                        size="xl"
                      />
                    </div>
                    {/* if it is a List, remove the fixed height which is necessary for Nivo responsive components */}
                    <div className={`py-6 px-4 sm:p-6 lg:pb-8 ${displayedReportDef?.rendererName === 'List' ? '' : 'min-h-[60vh] grid'}`}>
                      {Object.values(categoryStatus).every((status) => status !== 'loading') ? (
                        <PivotTable {...standardPivotTableProps} {...dataSettings} {...displayedReportDef} />
                      ) : (
                        <div className="w-full flex justify-center">
                          <MiniSpinner className="text-gray-300 w-10 h-10 animate-spin" />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
          <TourWrapper localTours={getTour('reporting')} />
        </div>
      </div>
    </div>
  );
}
