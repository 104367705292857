/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// import dayjs from 'dayjs';
// import { utc } from 'dayjs/plugin/utc';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { TableCellsIcon } from '@heroicons/react/24/outline';
import { incomeFromCapitalLabels } from '../redux/reducers/data';
import * as store from '../redux/reducers/data';

// dayjs.extend(utc);

const options = { style: 'currency', currency: 'EUR' };

function Table({ data, footnote, label, selector, showInternalNames }) {
  // this is a dumb component, it just renders the data into a table
  // first row contains headers
  if (!data) return <div />;

  const [open, setOpen] = useState(false);

  return (
    <div className="col-span-6 text-right">
      {open ? (
        <>
          <div className="grid grid-cols-2 gap-6 text-right pb-2 items-center">
            <div className="justify-self-end">{label}</div>
            <button type="button" onClick={() => setOpen(!open)} className="justify-self-start border rounded-md border-gray-300 py-1.5 px-2.5 bg-white hover:bg-gray-50 text-gray-500 text-sm">
              Close
            </button>
          </div>
          <div className="shadow ring-1 ring-black ring-opacity-5 md:rounded-lg max-h-96 overflow-auto">
            <table className="min-w-full divide-y divide-gray-300">
              {data.map((row, idx) => (idx === 0 ? (
                <tr key={row.id || idx}>
                  {Object.keys(row).map((cell) => (
                    <td key={row[cell]} className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                      {row[cell] > 999999999999 ? new Date(row[cell]).toLocaleDateString('de', { timeZone: 'UTC' }) : row[cell]}
                    </td>
                  ))}
                </tr>
              ) : (
                <tr>
                  {Object.keys(row).map((cell) => (
                    <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-6">
                      {row[cell] > 999999999999 ? new Date(row[cell]).toLocaleDateString('de', { timeZone: 'UTC' }) : row[cell]}
                    </td>
                  ))}
                </tr>
              )))}
            </table>
          </div>
          <p className="mt-2 mx-1 text-gray-400 italic text-sm text-left">{footnote}</p>
        </>
      ) : (
        <div className="grid grid-cols-2 gap-6 justify-items-end items-center text-right text-gray-500">
          <div>
            <p>{label}</p>
            {showInternalNames && <p className="text-sm text-gray-400">{selector}</p>}
          </div>
          <button type="button" onClick={() => setOpen(!open)} className="justify-self-start border rounded-md border-gray-300 py-1.5 px-1.5 bg-white hover:bg-gray-50">
            <TableCellsIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </button>
        </div>
      )}
    </div>
  );
}
Table.propTypes = {
  data: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)), PropTypes.object]).isRequired,
  footnote: PropTypes.string,
  label: PropTypes.string,
  selector: PropTypes.string,
  showInternalNames: PropTypes.bool,
};
Table.defaultProps = {
  footnote: '',
  label: '',
  selector: '',
  showInternalNames: false,
};

export default function KPIReportUnisel({ setKpiReportVisible }) {
  const dispatch = useDispatch();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  // Access the query parameters using the get method
  const showInternalNames = queryParams.get('internalNames');

  const selectGlobalAccounts = useSelector(store.globalAccountsView);
  function getAccountNameById(accountId) {
    return selectGlobalAccounts.find((account) => account.id === accountId)?.name;
  }

  const dashboardMode = useSelector((state) => state.simulation.dashboardMode);
  const { baseline, referenceBaseline } = useSelector(store.globalBaselineView);

  const sliderDate = new Date(useSelector((state) => state.simulation.baseDate.valueOf()));
  const quotes = useSelector(store.globalQuotesView);

  function handleButton() {
    if (dashboardMode === 'dashboard') {
      dispatch({ type: 'simulation/setDashboardMode', payload: { dashboardMode: 'projects' } });
    } else {
      dispatch({ type: 'simulation/setDashboardMode', payload: { dashboardMode: 'dashboard' } });
    }
  }

  const navigate = useNavigate();

  function handleClose() {
    navigate('/app/', { replace: false });
  }

  const formatHeader2 = 'text-right font-bold text-2xl';

  const selectAssetBalancesArray = useSelector(store.assetBalancesArray);

  console.log(
    useSelector(store.stockTransactions)
      .filter((t) => t.assetId === 'user_TEST')
      .sort((a, b) => a.date - b.date)
      .map((t) => ({ account: getAccountNameById(t.accountId), date: new Date(t.date).toISOString(), quantity: t.quantity, upbc: t.upbc, uptc: t.updc, value: t.quantity * t.upbc })),
  );

  return (
    <div className="bg-white -mt-6 rounded-md max-w-3xl mx-auto px-4 sm:px-6 py-6 lg:max-w-[80rem] xl:h-[85vh] lg:px-8 text-gray-900 overflow-y-scroll">
      <div className="mt-4 grid grid-cols-12 gap-6">
        <h1 className="font-bold text-3xl col-span-8 -mb-4">Key Performance Indicators - Detailed Report</h1>
        <div className="col-span-12 font-medium text-base">
          <p>{`Baseline date is: ${new Date(baseline).toLocaleDateString('de')}`}</p>
          <p>{`Reference baseline date is: ${new Date(useSelector(store.referenceBaseline)).toLocaleDateString('de', { timeZone: 'UTC' }, { timeZone: 'UTC' })}`}</p>
          {dashboardMode === 'projects' && <p>{`Slider date is ${sliderDate.toLocaleDateString('de', { timeZone: 'UTC' }, { timeZone: 'UTC' })}`}</p>}
        </div>

        <div className="col-span-3 text-right">
          <h2 className={formatHeader2}>Current net worth</h2>
          {showInternalNames && <p className="text-sm text-gray-400">currentNetWorth2</p>}
        </div>
        <h2 className="font-bold text-2xl  col-span-3">{useSelector(store.currentNetWorth2).toLocaleString('de', options)}</h2>

        <div className="col-span-3 text-right">
          <h2 className="text-right font-bold text-2xl">Baseline net worth</h2>
          {showInternalNames && <p className="text-sm text-gray-400">baselineNetWorth</p>}
        </div>
        <h2 className="font-bold text-2xl col-span-3">{useSelector(store.baselineNetWorth).toLocaleString('de', options)}</h2>

        <div className="col-span-3 text-right">
          <h3 className="text-right font-medium text-xl">Deposits total</h3>
          {showInternalNames && <p className="text-sm text-gray-400">assetBalancesArray (current, deposits only)</p>}
        </div>
        <h3 className="font-medium text-xl col-span-3">
          {selectAssetBalancesArray
            .filter((x) => x.category === 'deposits')
            .reduce((acc, curr) => acc + curr.current.valueBaseCurrency, 0)
            .toLocaleString('de', options)}
        </h3>

        <div className="col-span-3 text-right">
          <h3 className="text-right font-medium text-xl  col-span-3">Deposits total</h3>
          {showInternalNames && <p className="text-sm text-gray-400">assetBalancesArray (baseline, deposits only)</p>}
        </div>
        <h3 className="font-medium text-xl col-span-3">
          {selectAssetBalancesArray
            .filter((x) => x.category === 'deposits')
            .reduce((acc, curr) => acc + curr.baseline.valueBaseCurrency, 0)
            .toLocaleString('de', options)}
        </h3>

        <Table
          label="Account balances"
          selector="assetBalancesArray (current, deposits only)"
          showInternalNames={showInternalNames}
          data={[
            {
              col1: 'Account',
              col2: 'Value (base currency)',
            },
          ].concat(
            selectAssetBalancesArray
              .filter((x) => x.category === 'deposits')
              .map((asset) => ({
                symbol: asset.displayName, // there is always only one currency (assetId) per account
                value: asset.current.valueBaseCurrency.toLocaleString('de', options),
              })),
          )}
        />

        <Table
          label="Account balances (at baseline)"
          selector="assetBalancesArray (baseline, deposits only)"
          showInternalNames={showInternalNames}
          data={[
            {
              col1: 'Account',
              col2: 'Value (base currency)',
            },
          ].concat(
            selectAssetBalancesArray
              .filter((x) => x.category === 'deposits')
              .map((asset) => ({
                symbol: asset.displayName, // there is always only one currency (assetId) per account
                value: asset.baseline.valueBaseCurrency.toLocaleString('de', options),
              })),
          )}
        />

        <Table
          label="Labelled deposit transactions "
          selector="depositTransactions (current, with label)"
          showInternalNames={showInternalNames}
          data={[
            {
              col1: 'Account',
              col2: 'Date',
              col3: 'Label',
              col4: 'Id',
              col5: 'Value',
            },
          ].concat(
            useSelector(store.depositTransactions)
              .filter((item) => item.label && item.date >= baseline)
              .map((item) => ({
                accountName: getAccountNameById(item.accountId),
                date: item.date,
                label: item.label.split('-')[1],
                id: item.id,
                value: (item.quantity * item.upbc).toLocaleString('de', options),
              })),
          )}
        />

        <div />
        <div />
        <div />
        <div />

        <Table
          label="Orphaned transfer transactions"
          selector="depositTransactions (current, with label)"
          showInternalNames={showInternalNames}
          data={[
            {
              col1: 'Account',
              col2: 'Date',
              col3: 'Label',
              col4: 'Id',
              col5: 'Value',
            },
          ].concat(
            useSelector(store.depositTransactions)
              .filter((item) => item.label && item.date >= baseline)
              .filter((item) => item.label === 'investment-transfer' && item.tags?.linkedTransactions.filter((t) => t.tags.linkType === 'transfer').length === 0)
              .map((item) => ({
                accountName: getAccountNameById(item.accountId),
                date: item.date,
                label: item.label.split('-')[1],
                id: item.id,
                value: (item.quantity * item.upbc).toLocaleString('de', options),
              })),
          )}
        />

        <Table
          label="Orphaned transfer transactions"
          selector="depositTransactions (baseline, with label)"
          showInternalNames={showInternalNames}
          data={[
            {
              col1: 'Account',
              col2: 'Date',
              col3: 'Label',
              col4: 'Id',
              col5: 'Value',
            },
          ].concat(
            useSelector(store.depositTransactions)
              .filter((item) => item.label && item.date >= referenceBaseline && item.date < baseline)
              .filter((item) => item.label === 'investment-transfer' && item.tags?.linkedTransactions.filter((t) => t.tags.linkType === 'transfer').length === 0)
              .map((item) => ({
                accountName: getAccountNameById(item.accountId),
                date: item.date,
                label: item.label.split('-')[1],
                id: item.id,
                value: (item.quantity * item.upbc).toLocaleString('de', options),
              })),
          )}
        />

        <div className="col-span-3 text-right">
          <h3 className="text-right font-medium text-xl  col-span-3">Stocks total</h3>
          {showInternalNames && <p className="text-sm text-gray-400">assetBalancesArray (current, stocks only)</p>}
        </div>
        <h3 className="font-medium text-xl col-span-3">
          {selectAssetBalancesArray
            .filter((x) => x.category === 'stocks')
            .reduce((acc, curr) => acc + curr.current.valueBaseCurrency, 0)
            .toLocaleString('de', options)}
        </h3>

        <div className="col-span-3 text-right">
          <h3 className="text-right font-medium text-xl  col-span-3">Stocks total</h3>
          {showInternalNames && <p className="text-sm text-gray-400">assetBalancesArray (baseline, stocks only)</p>}
        </div>
        <h3 className="font-medium text-xl col-span-3">
          {selectAssetBalancesArray
            .filter((x) => x.category === 'stocks')
            .reduce((acc, curr) => acc + curr.baseline.valueBaseCurrency, 0)
            .toLocaleString('de', options)}
        </h3>

        <Table
          label="Currently held securities"
          selector="assetBalancesArray (current, stocks only)"
          showInternalNames={showInternalNames}
          data={[
            {
              col1: 'Symbol',
              col2: 'Quantity',
              col3: 'Unit price (base currency)',
              col4: 'Value (base currency)',
            },
          ].concat(
            selectAssetBalancesArray
              .filter((x) => x.category === 'stocks')
              .map((asset) => ({
                symbol: asset.assetId,
                quantity: asset.current.quantity,
                upbc: asset.current.quoteBaseCurrency.toLocaleString('de', options),
                value: asset.current.valueBaseCurrency.toLocaleString('de', options),
              })),
          )}
        />

        <Table
          label="Securities held on baseline date"
          selector="assetBalancesArray (baseline, stocks only)"
          showInternalNames={showInternalNames}
          data={[
            {
              col1: 'Symbol',
              col2: 'Quantity',
              col3: 'Unit price (base currency)',
              col4: 'Value (base currency)',
            },
          ].concat(
            selectAssetBalancesArray
              .filter((x) => x.category === 'stocks')
              .map((asset) => ({
                symbol: asset.assetId,
                quantity: asset.baseline.quantity,
                upbc: asset.baseline.quoteBaseCurrency.toLocaleString('de', options),
                value: asset.baseline.valueBaseCurrency.toLocaleString('de', options),
              })),
          )}
        />

        <Table
          label="Current securities quotes"
          selector="assetBalancesArray (current, stocks only)"
          showInternalNames={showInternalNames}
          data={[{ col0: 'Symbol', col2: 'Quote', col3: 'Quote (original)' }].concat(
            selectAssetBalancesArray
              .filter((x) => x.category === 'stocks')
              .map((asset) => ({
                symbol: asset.assetId,
                quote: asset.current.quoteBaseCurrency.toLocaleString('de', options),
                quoteOriginal: asset.current.quoteTransactionCurrency.toLocaleString('de', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
              })),
          )}
        />

        <Table
          label="Securities quotes on baseline date"
          selector="assetBalancesArray (baseline, stocks only)"
          showInternalNames={showInternalNames}
          data={[{ col0: 'Symbol', col2: 'Quote', col3: 'Quote (original)' }].concat(
            selectAssetBalancesArray
              .filter((x) => x.category === 'stocks')
              .map((asset) => ({
                symbol: asset.assetId,
                quote: asset.baseline.quoteBaseCurrency.toLocaleString('de', options),
                quoteOriginal: asset.baseline.quoteTransactionCurrency.toLocaleString('de', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
              })),
          )}
        />

        <div className="col-span-3 text-right">
          <h3 className="text-right font-medium text-xl  col-span-3">Real Estate - current value</h3>
          {showInternalNames && <p className="text-sm text-gray-400">assetBalancesArray (current, real estate only)</p>}
        </div>
        <h3 className="font-medium text-xl col-span-3">
          {selectAssetBalancesArray
            .filter((x) => x.category === 'realEstate')
            .reduce((acc, curr) => acc + curr.current.valueBaseCurrency, 0)
            .toLocaleString('de', options)}
        </h3>

        <div className="col-span-3 text-right">
          <h3 className="text-right font-medium text-xl  col-span-3">Real Estate - value on baseline date</h3>
          {showInternalNames && <p className="text-sm text-gray-400">assetBalancesArray (baseline, real estate only)</p>}
        </div>
        <h3 className="font-medium text-xl col-span-3">
          {selectAssetBalancesArray
            .filter((x) => x.category === 'realEstate')
            .reduce((acc, curr) => acc + curr.baseline.valueBaseCurrency, 0)
            .toLocaleString('de', options)}
        </h3>

        <Table
          label="Real Estate value"
          selector="assetBalances (current, real estate only)"
          showInternalNames={showInternalNames}
          data={[
            {
              col1: 'Account',
              col2: 'Value (base currency)',
            },
          ].concat(
            selectAssetBalancesArray
              .filter((x) => x.category === 'realEstate')
              .map((asset) => ({
                symbol: asset.displayName, // there is always only one currency (assetId) per account
                value: asset.current.valueBaseCurrency.toLocaleString('de', options),
              })),
          )}
        />

        <Table
          label="Real Estate value on baseline date"
          selector="assetBalances (baseline, real estate only)"
          showInternalNames={showInternalNames}
          data={[
            {
              col1: 'Account',
              col2: 'Value (base currency)',
            },
          ].concat(
            selectAssetBalancesArray
              .filter((x) => x.category === 'realEstate')
              .map((asset) => ({
                symbol: asset.displayName, // there is always only one currency (assetId) per account
                value: asset.baseline.valueBaseCurrency.toLocaleString('de', options),
              })),
          )}
        />

        <div className="col-span-3 text-right">
          <h3 className="text-right font-medium text-xl  col-span-3">Loans - current value</h3>
          {showInternalNames && <p className="text-sm text-gray-400">assetBalancesArray (current, loans only)</p>}
        </div>
        <h3 className="font-medium text-xl col-span-3">
          {selectAssetBalancesArray
            .filter((x) => x.category === 'loans')
            .reduce((acc, curr) => acc + curr.current.valueBaseCurrency, 0)
            .toLocaleString('de', options)}
        </h3>

        <div className="col-span-3 text-right">
          <h3 className="text-right font-medium text-xl  col-span-3">Loans - value on baseline date</h3>
          {showInternalNames && <p className="text-sm text-gray-400">assetBalancesArray (baseline, loans only)</p>}
        </div>
        <h3 className="font-medium text-xl col-span-3">
          {selectAssetBalancesArray
            .filter((x) => x.category === 'loans')
            .reduce((acc, curr) => acc + curr.baseline.valueBaseCurrency, 0)
            .toLocaleString('de', options)}
        </h3>

        <div className="mt-8 col-span-3 text-right">
          <h2 className="font-bold text-2xl">Deposit inflows</h2>
          <p className="text-lg">since baseline date</p>
          {showInternalNames && <p className="text-sm text-gray-400">inflowsCurrent</p>}
        </div>
        <h2 className="mt-8 font-bold text-2xl  col-span-3">{useSelector(store.inflowsCurrent).toLocaleString('de', options)}</h2>

        <div className="mt-8 col-span-3 text-right">
          <h2 className="font-bold text-2xl">Deposit inflows</h2>
          <p className="text-lg leading-6">between beginning of reference period and baseline date</p>
          {showInternalNames && <p className="text-sm text-gray-400">inflowsBaseline</p>}
        </div>
        <h2 className="mt-8 font-bold text-2xl col-span-3">{useSelector(store.inflowsBaseline).toLocaleString('de', options)}</h2>

        <Table
          label="Asset transactions without linked deposit transactions "
          selector="allTransactions (current)"
          showInternalNames={showInternalNames}
          data={[
            {
              col1: 'Category',
              col2: 'Account',
              col3: 'Date',
              col4: 'AssetId',
              col5: 'Value',
            },
          ].concat(
            useSelector(store.orphanedSaleTransactions).map((item) => ({
              category: item.category,
              accountName: getAccountNameById(item.accountId),
              // date: dayjs.utc(item.date).format('DD.MM.YYYY'),
              date: item.date,
              assetId: item.assetId,
              value: (item.quantity * item.upbc).toLocaleString('de', options),
            })),
          )}
        />

        <div className="col-span-6" />

        <div className="mt-8 col-span-3 text-right">
          <h2 className="font-bold text-2xl">Deposit outflows</h2>
          <p className="text-lg">since baseline date</p>
          {showInternalNames && <p className="text-sm text-gray-400">outflowsCurrent</p>}
        </div>
        <h2 className="mt-8 font-bold text-2xl  col-span-3">{useSelector(store.outflowsCurrent).toLocaleString('de', options)}</h2>

        <div className="mt-8 col-span-3 text-right">
          <h2 className="font-bold text-2xl">Deposit outflows</h2>
          <p className="text-lg leading-6">between beginning of reference period and baseline date</p>
          {showInternalNames && <p className="text-sm text-gray-400">outflowsBaseline</p>}
        </div>
        <h2 className="mt-8 font-bold text-2xl col-span-3">{useSelector(store.outflowsBaseline).toLocaleString('de', options)}</h2>

        <div className="mt-8 col-span-3 text-right">
          <h2 className="font-bold text-2xl">Income from capital</h2>
          <p className="text-lg">since baseline date</p>
          {showInternalNames && <p className="text-sm text-gray-400">incomeFromCapitalCurrent</p>}
        </div>
        <h2 className="mt-8 font-bold text-2xl  col-span-3">{useSelector(store.incomeFromCapitalCurrent).toLocaleString('de', options)}</h2>

        <div className="mt-8 col-span-3 text-right">
          <h2 className="font-bold text-2xl">Income from capital</h2>
          <p className="text-lg leading-6">between beginning of reference period and baseline date</p>
          {showInternalNames && <p className="text-sm text-gray-400">incomeFromCapitalBaseline</p>}
        </div>
        <h2 className="mt-8 font-bold text-2xl col-span-3">{useSelector(store.incomeFromCapitalBaseline).toLocaleString('de', options)}</h2>

        <div className="text-right col-span-3">
          <h3 className="text-right font-medium text-xl">Interest, dividends, costs and fees</h3>
          {showInternalNames && <p className="text-sm text-gray-400">incomeFromCapitalPayoutsAndFeesCurrent</p>}
        </div>
        <h3 className="font-medium text-xl col-span-3">{useSelector(store.incomeFromCapitalPayoutsAndFeesCurrent).toLocaleString('de', options)}</h3>

        <div className="text-right col-span-3">
          <h3 className="text-right font-medium text-xl">Interest, dividends, costs and fees</h3>
          {showInternalNames && <p className="text-sm text-gray-400">incomeFromCapitalPayoutsAndFeesBaseline</p>}
        </div>
        <h3 className="font-medium text-xl col-span-3">{useSelector(store.incomeFromCapitalPayoutsAndFeesBaseline).toLocaleString('de', options)}</h3>

        <Table
          label="Interest, dividends, costs and fees"
          selector="depositTransactions (current)"
          showInternalNames={showInternalNames}
          data={[
            {
              col1: 'Date',
              col2: 'Account',
              col3: 'Label',
              col4: 'Amount',
            },
          ].concat(
            useSelector(store.depositTransactions)
              .filter((curr) => incomeFromCapitalLabels.includes(curr.label) && curr.date >= baseline)
              .map((item) => ({
                date: item.date,
                accountName: getAccountNameById(item.accountId),
                label: item.label,
                amount: item.quantity,
              })),
          )}
        />

        <Table
          label="Interest, dividends, costs and fees"
          selector="depositTransactions (baseline)"
          showInternalNames={showInternalNames}
          data={[
            {
              col1: 'Date',
              col2: 'Account',
              col3: 'Label',
              col4: 'Amount',
            },
          ].concat(
            useSelector(store.depositTransactions)
              .filter((curr) => incomeFromCapitalLabels.includes(curr.label) && curr.date >= referenceBaseline && curr.date < baseline)
              .map((item) => ({
                date: item.date,
                accountName: getAccountNameById(item.accountId),
                label: item.label,
                amount: item.quantity,
              })),
          )}
        />

        <div className="text-right col-span-3">
          <h3 className="text-right font-medium text-xl col-span-3">Income / loss from sold assets</h3>
          {showInternalNames && <p className="text-sm text-gray-400">incomeFromCapitalSaleTransactionCurrent</p>}
        </div>
        <h3 className="font-medium text-xl col-span-3">{useSelector(store.incomeFromCapitalSaleTransactionsCurrent).toLocaleString('de', options)}</h3>

        <div className="text-right col-span-3">
          <h3 className="text-right font-medium text-xl  col-span-3">Income / loss from sold assets</h3>
          {showInternalNames && <p className="text-sm text-gray-400">incomeFromCapitalSaleTransactionsBaseline</p>}
        </div>
        <h3 className="font-medium text-xl col-span-3">{useSelector(store.incomeFromCapitalSaleTransactionsBaseline).toLocaleString('de', options)}</h3>

        <Table
          label="Asset sale transactions"
          selector="assetBalances (current)"
          showInternalNames={showInternalNames}
          data={[
            {
              col0: 'Account',
              col1: 'Date',
              col2: 'Symbol',
              col3: 'Amount',
              col4: 'Price (base currency)',
              col5: 'Value',
              col6: 'Linked purchase transactions (FIFO)',
            },
          ].concat(
            useSelector(store.assetBalances)
              .assetSaleTransactions.filter((item) => item.transactionType === 'sale' && item.date >= baseline)
              .map((item) => ({
                account: getAccountNameById(item.accountId),
                date: item.date,
                assetId: item.assetId,
                quantity: item.quantity,
                price: item.upbc.toLocaleString('de', options),
                value: (item.quantity * item.upbc).toLocaleString('de', options),
                linkedTransactions: item.linkedTransactions
                  .filter((t) => t.tags.type === 'purchase')
                  .sort((a, b) => a.tags.date - b.tags.date)
                  .map((lt) => (
                    <div>
                      {new Date(lt.tags.date).toLocaleDateString('de', { timeZone: 'UTC' })}
                      {' | '}
                      {lt.tags.quantity}
                      {' | '}
                      {quotes[item.assetId] && (lt.tags.date < baseline ? quotes[item.assetId]?.baseline.quoteBaseCurrency : lt.tags.upbc).toLocaleString('de', options)}
                    </div>
                  )),
              })),
          )}
          footnote="Baseline is your net worth on a past date. One of its components are the Asset Value Changes which are unrealised gains/losses as of the baseline date. Income from Capital uses the price on baseline date as purchase price, because the price changes before baseline date are already included in those Asset Value Changes. Read more in our blog."
        />

        <Table
          label="Asset sale transactions after reference period start until baseline date"
          selector="assetBalances (baseline)"
          showInternalNames={showInternalNames}
          data={[
            {
              col0: 'Account',
              col1: 'Date',
              col2: 'Symbol',
              col3: 'Amount',
              col4: 'Price (base currency)',
              col5: 'Value',
              col6: 'Linked purchase transactions (FIFO)',
            },
          ].concat(
            useSelector(store.assetBalances)
              .assetSaleTransactions.filter((item) => item.transactionType === 'sale' && item.date < baseline && item.date >= referenceBaseline)
              .map((item) => ({
                account: getAccountNameById(item.accountId),
                date: item.date,
                assetId: item.assetId,
                quantity: item.quantity,
                price: item.upbc.toLocaleString('de', options),
                value: (item.quantity * item.upbc).toLocaleString('de', options),
                linkedTransactions: item.linkedTransactions
                  .filter((t) => t.tags.type === 'purchase')
                  .sort((a, b) => a.tags.date - b.tags.date)
                  .map((lt) => (
                    <div>
                      {new Date(lt.tags.date).toLocaleDateString('de', { timeZone: 'UTC' })}
                      {' | '}
                      {lt.tags.quantity}
                      {' | '}
                      {quotes[item.assetId] && (lt.tags.date < referenceBaseline ? quotes[item.assetId]?.referenceBaseline.quoteBaseCurrency : lt.tags.upbc).toLocaleString('de', options)}
                    </div>
                  )),
              })),
          )}
          footnote="Baseline is your net worth on a past date. One of its components are the Asset Value Changes which are unrealised gains/losses as of the baseline date. Income from Capital uses the price on baseline date as purchase price, because the price changes before baseline date are already included in those Asset Value Changes. Read more in our blog."
        />

        <div className="mt-8 col-span-3 text-right">
          <h2 className="font-bold text-2xl">Assets Value Change</h2>
          <p className="text-lg leading-6">since baseline date</p>
          {showInternalNames && <p className="text-sm text-gray-400">currentAssetValueChange</p>}
        </div>
        <h2 className="mt-8 font-bold text-2xl  col-span-3">{useSelector(store.currentAssetValueChange).toLocaleString('de', options)}</h2>

        <div className="mt-8 col-span-3 text-right">
          <h2 className="font-bold text-2xl">Assets Value Change</h2>
          <p className="text-lg leading-6">between beginning of reference period and baseline date</p>
          {showInternalNames && <p className="text-sm text-gray-400">baselineAssetValueChange</p>}
        </div>
        <h2 className="mt-8 font-bold text-2xl  col-span-3">{useSelector(store.baselineAssetValueChange).toLocaleString('de', options)}</h2>

        <div className="col-span-3 text-right">
          <h3 className="text-right font-medium text-xl col-span-3">Deposits</h3>
          {showInternalNames && <p className="text-sm text-gray-400">assetBalances (baseline, deposits only)</p>}
        </div>
        <h3 className="font-medium text-xl col-span-3">
          {store
            .calculateCurrentAssetValueChange(
              useSelector(store.assetBalancesArray).filter((a) => a.category === 'deposits'),
              useSelector(store.globalBaselineView),
            )
            .toLocaleString('de', options)}
        </h3>

        <div className="col-span-3 text-right">
          <h3 className="text-right font-medium text-xl  col-span-3">Deposits</h3>
          {showInternalNames && <p className="text-sm text-gray-400">assetBalances (baseline, deposits only)</p>}
        </div>
        <h3 className="font-medium text-xl col-span-3">
          {store
            .calculateBaselineAssetValueChange(
              useSelector(store.assetBalancesArray).filter((a) => a.category === 'deposits'),
              useSelector(store.globalBaselineView),
            )
            .toLocaleString('de', options)}
        </h3>

        <Table
          label="Current deposit currency positions by assetId in baseline / purchase value vs current value"
          selector="assetBalances (current, deposits only)"
          showInternalNames={showInternalNames}
          data={[
            {
              col: 'Account name',
              col0: 'AssetId',
              col1: 'Value in original currency',
              col2: 'Baseline (purchase) value',
              col3: 'Current value',
            },
          ].concat(
            store
              .displayCurrentAssetValueChangeCalculation(
                useSelector(store.assetBalancesArray).filter((b) => b.category === 'deposits'),
                useSelector(store.globalBaselineView),
              )
              .map((asset) => ({
                name: asset.displayName,
                assetId: asset.assetId,
                currentQuantity: asset.currentQuantity.toLocaleString('de', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                baselineValue: asset.baselineOrPurchaseValueBaseCurrency.toLocaleString('de', options),
                currentValue: asset.currentValueBaseCurrency.toLocaleString('de', options),
              })),
          )}
        />

        <Table
          label="Deposit currency positions at baseline by assetId in reference / purchase value vs baseline value"
          selector="assetBalances (baseline, deposits only)"
          showInternalNames={showInternalNames}
          data={[
            {
              col: 'Account name',
              col0: 'AssetId',
              col1: 'Value in original currency',
              col2: 'Reference (purchase) value',
              col3: 'Baseline value',
            },
          ].concat(
            store
              .displayBaselineAssetValueChangeCalculation(
                useSelector(store.assetBalancesArray).filter((b) => b.category === 'deposits'),
                useSelector(store.globalBaselineView),
              )
              .map((asset) => ({
                displayName: asset.displayName,
                assetId: asset.assetId,
                baselineQuantity: asset.baselineQuantity.toLocaleString('de', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                referenceValue: asset.referenceOrPurchaseValueBaseCurrency.toLocaleString('de', options),
                baselineValue: asset.baselineValueBaseCurrency.toLocaleString('de', options),
              })),
          )}
        />

        <div className="col-span-3 text-right">
          <h3 className="text-right font-medium text-xl col-span-3">Securities</h3>
          {showInternalNames && <p className="text-sm text-gray-400">assetBalances (current, stocks only)</p>}
        </div>
        <h3 className="font-medium text-xl col-span-3">
          {store
            .calculateCurrentAssetValueChange(
              useSelector(store.assetBalancesArray).filter((a) => a.category === 'stocks'),
              useSelector(store.globalBaselineView),
            )
            .toLocaleString('de', options)}
        </h3>

        <div className="col-span-3 text-right">
          <h3 className="text-right font-medium text-xl  col-span-3">Securities</h3>
          {showInternalNames && <p className="text-sm text-gray-400">assetBalances (baseline, stocks only)</p>}
        </div>
        <h3 className="font-medium text-xl col-span-3">
          {store
            .calculateBaselineAssetValueChange(
              useSelector(store.assetBalancesArray).filter((a) => a.category === 'stocks'),
              useSelector(store.globalBaselineView),
            )
            .toLocaleString('de', options)}
        </h3>

        <Table
          label="Current stocks positions by assetId in baseline / purchase value vs current value"
          selector="assetBalances (current, stocks only)"
          showInternalNames={showInternalNames}
          data={[
            {
              col0: 'Account',
              col1: 'AssetId',
              col2: 'Current quantity',
              col3: 'Baseline (purchase) value',
              col4: 'Current value',
            },
          ].concat(
            store
              .displayCurrentAssetValueChangeCalculation(
                useSelector(store.assetBalancesArray).filter((b) => b.category === 'stocks'),
                useSelector(store.globalBaselineView),
              )
              .map((asset) => ({
                accountName: getAccountNameById(asset.accountId),
                assetId: asset.assetId,
                currentQuantity: asset.currentQuantity.toLocaleString('de', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                baselineValue: asset.baselineOrPurchaseValueBaseCurrency.toLocaleString('de', options),
                currentValue: asset.currentValueBaseCurrency.toLocaleString('de', options),
              })),
          )}
        />

        <Table
          label="Stocks owned at baseline by assetId in reference / purchase value vs baseline value"
          selector="assetBalances (baseline, stocks only)"
          showInternalNames={showInternalNames}
          data={[
            {
              col0: 'Account',
              col1: 'AssetId',
              col2: 'Baseline quantity',
              col3: 'Reference / purchase value',
              col4: 'Baseline value',
            },
          ].concat(
            store
              .displayBaselineAssetValueChangeCalculation(
                useSelector(store.assetBalancesArray).filter((b) => b.category === 'stocks'),
                useSelector(store.globalBaselineView),
              )
              .map((asset) => ({
                accountName: getAccountNameById(asset.accountId),
                assetId: asset.assetId,
                baselineQuantity: asset.baselineQuantity.toLocaleString('de', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                referenceValue: asset.referenceOrPurchaseValueBaseCurrency.toLocaleString('de', options),
                baselineValue: asset.baselineValueBaseCurrency.toLocaleString('de', options),
              })),
          )}
        />

        <Table
          label="Stocks purchase / sale transactions"
          selector="assetBalances (current, stocks only)"
          showInternalNames={showInternalNames}
          data={[
            {
              col0: 'Account',
              col1: 'AssetId',
              col2: 'Quantity',
              col3: 'Date',
              col4: 'Transaction value',
            },
          ].concat(
            useSelector(store.allTransactions)
              .filter((b) => b.category === 'stocks' && b.date >= baseline)
              .map((asset) => ({
                accountName: getAccountNameById(asset.accountId),
                assetId: asset.assetId,
                date: asset.date,
                quantity: asset.quantity.toLocaleString('de', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                value: (asset.quantity * asset.upbc).toLocaleString('de', options),
              })),
          )}
        />

        <Table
          label="Stocks purchase / sale transactions"
          selector="assetBalances (baseline, stocks only)"
          showInternalNames={showInternalNames}
          data={[
            {
              col0: 'Account',
              col1: 'AssetId',
              col2: 'Quantity',
              col3: 'Date',
              col4: 'Transaction value',
            },
          ].concat(
            useSelector(store.allTransactions)
              .filter((b) => b.category === 'stocks' && b.date >= referenceBaseline && b.date < baseline)
              .map((asset) => ({
                accountName: getAccountNameById(asset.accountId),
                assetId: asset.assetId,
                date: asset.date,
                quantity: asset.quantity.toLocaleString('de', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                value: (asset.quantity * asset.upbc).toLocaleString('de', options),
              })),
          )}
        />

        <div className="col-span-3 text-right">
          <h3 className="text-right font-medium text-xl col-span-3">Real estate assets</h3>
          {showInternalNames && <p className="text-sm text-gray-400">assetBalances (current, real estate only)</p>}
        </div>
        <h3 className="font-medium text-xl col-span-3">
          {store
            .calculateCurrentAssetValueChange(
              useSelector(store.assetBalancesArray).filter((a) => a.category === 'realEstate'),
              useSelector(store.globalBaselineView),
            )
            .toLocaleString('de', options)}
        </h3>

        <div className="col-span-3 text-right">
          <h3 className="text-right font-medium text-xl  col-span-3">Real estate assets</h3>
          {showInternalNames && <p className="text-sm text-gray-400">assetBalances (baseline, real estate only)</p>}
        </div>
        <h3 className="font-medium text-xl col-span-3">
          {store
            .calculateBaselineAssetValueChange(
              useSelector(store.assetBalancesArray).filter((a) => a.category === 'realEstate'),
              useSelector(store.globalBaselineView),
            )
            .toLocaleString('de', options)}
        </h3>

        <Table
          label="Current real estate holdings by assetId in baseline / purchase value vs current value"
          selector="assetBalances (current, real estate only)"
          showInternalNames={showInternalNames}
          data={[
            {
              col0: 'AssetId',
              col1: 'Baseline (purchase) value',
              col2: 'Current value',
            },
          ].concat(
            store
              .displayCurrentAssetValueChangeCalculation(
                useSelector(store.assetBalancesArray).filter((b) => b.category === 'realEstate'),
                useSelector(store.globalBaselineView),
              )
              .map((asset) => ({
                assetId: asset.assetId,
                baselineValue: asset.baselineOrPurchaseValueBaseCurrency.toLocaleString('de', options),
                currentValue: asset.currentValueBaseCurrency.toLocaleString('de', options),
              })),
          )}
        />

        <Table
          label="Real estate owned at baseline by assetId in reference / purchase value vs baseline value"
          selector="assetBalances (baseline, real estate only)"
          showInternalNames={showInternalNames}
          data={[
            {
              col0: 'AssetId',
              col1: 'Reference (purchase) value',
              col2: 'Baseline value',
            },
          ].concat(
            store
              .displayBaselineAssetValueChangeCalculation(
                useSelector(store.assetBalancesArray).filter((b) => b.category === 'realEstate'),
                useSelector(store.globalBaselineView),
              )
              .map((asset) => ({
                assetId: asset.assetId,
                referenceValue: asset.referenceOrPurchaseValueBaseCurrency.toLocaleString('de', options),
                baselineValue: asset.baselineValueBaseCurrency.toLocaleString('de', options),
              })),
          )}
        />

        <div className="col-span-3 text-right">
          <h3 className="text-right font-medium text-xl col-span-3">Loan assets</h3>
          {showInternalNames && <p className="text-sm text-gray-400">assetBalances (current, real estate only)</p>}
        </div>
        <h3 className="font-medium text-xl col-span-3">
          {store
            .calculateCurrentAssetValueChange(
              useSelector(store.assetBalancesArray).filter((a) => a.category === 'loans'),
              useSelector(store.globalBaselineView),
            )
            .toLocaleString('de', options)}
        </h3>

        <div className="col-span-3 text-right">
          <h3 className="text-right font-medium text-xl  col-span-3">Loan assets</h3>
          {showInternalNames && <p className="text-sm text-gray-400">assetBalances (baseline, real estate only)</p>}
        </div>
        <h3 className="font-medium text-xl col-span-3">
          {store
            .calculateBaselineAssetValueChange(
              useSelector(store.assetBalancesArray).filter((a) => a.category === 'loans'),
              useSelector(store.globalBaselineView),
            )
            .toLocaleString('de', options)}
        </h3>

        <Table
          label="Current loans by assetId in baseline / purchase value vs current value"
          selector="assetBalances (current, loans only)"
          showInternalNames={showInternalNames}
          data={[
            {
              col0: 'AssetId',
              col1: 'Baseline (purchase) value',
              col2: 'Current value',
            },
          ].concat(
            store
              .displayCurrentAssetValueChangeCalculation(
                useSelector(store.assetBalancesArray).filter((b) => b.category === 'loans'),
                useSelector(store.globalBaselineView),
              )
              .map((asset) => ({
                assetId: asset.assetId,
                baselineValue: asset.baselineOrPurchaseValueBaseCurrency.toLocaleString('de', options),
                currentValue: asset.currentValueBaseCurrency.toLocaleString('de', options),
              })),
          )}
        />

        <Table
          label="Loans at baseline by assetId in reference / purchase value vs baseline value"
          selector="assetBalances (baseline, loans only)"
          showInternalNames={showInternalNames}
          data={[
            {
              col0: 'AssetId',
              col1: 'Reference (purchase) value',
              col2: 'Baseline value',
            },
          ].concat(
            store
              .displayBaselineAssetValueChangeCalculation(
                useSelector(store.assetBalancesArray).filter((b) => b.category === 'loans'),
                useSelector(store.globalBaselineView),
              )
              .map((asset) => ({
                assetId: asset.assetId,
                referenceValue: asset.referenceOrPurchaseValueBaseCurrency.toLocaleString('de', options),
                baselineValue: asset.baselineValueBaseCurrency.toLocaleString('de', options),
              })),
          )}
        />

        <div className="col-span-6" />
        <div className="mt-8 col-span-3 text-right">
          <h2 className="font-bold text-2xl">Reference net worth</h2>
          {showInternalNames && <p className="text-sm text-gray-400">assetsBalances (reference)</p>}
        </div>
        <h2 className="mt-8 font-bold text-2xl  col-span-3">
          {useSelector(store.assetBalancesArray)
            .reduce((acc, curr) => {
              const { referenceBaseline: obj } = curr;
              return acc + obj.valueBaseCurrency;
            }, 0)
            .toLocaleString('de', options)}
        </h2>
      </div>
    </div>
    // </div>
  );
}
KPIReportUnisel.propTypes = {
  setKpiReportVisible: PropTypes.bool.isRequired,
};
