[
  {
    "key": "blog",
    "en": {
      "displayKey": "blog"
    },
    "de": {
      "displayKey": "blog"
    },
    "name": "{t('site:blog.title')}",
    "href": "#",
    "subheader": "{t('site:blog.subtitle')}",
    "filter-visible": true
  },
  {
    "key": "guide",
    "en": {
      "displayKey": "guide"
    },
    "de": {
      "displayKey": "anleitung"
    },
    "name": "{t('site:blog.guide.title')}",
    "href": "#",
    "subheader": "{t('site:blog.guide.subtitle')}",
    "filter-visible": true
  },
  {
    "key": "new-features",
    "en": {
      "displayKey": "new-features"
    },
    "de": {
      "displayKey": "neue-features"
    },
    "name": "{t('site:blog.new-features.title')}",
    "href": "#",
    "subheader": "{t('site:blog.new-features.subtitle')}",
    "filter-visible": true
  },
  {
    "key": "point-of-view",
    "en": {
      "displayKey": "point-of-view"
    },
    "de": {
      "displayKey": "standpunkte"
    },
    "name": "{t('site:blog.point-of-view.title')}",
    "href": "#",
    "subheader": "{t('site:blog.point-of-views.subtitle')}",
    "filter-visible": true
  },
  {
    "key": "market-overview",
    "en": {
      "displayKey": "market-overview"
    },
    "de": {
      "displayKey": "marktübersicht"
    },
    "name": "{t('site:blog.market-overview.title')}",
    "href": "#",
    "subheader": "{t('site:blog.market-overview.subtitle')}",
    "filter-visible": true
  }
]