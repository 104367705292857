/* eslint-disable jsx-a11y/label-has-associated-control */
import {
  React, Fragment, useState, useEffect,
} from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { useLocation } from 'react-router-dom';
import { API, Auth } from 'aws-amplify';
import { setMessage } from '../redux/actions/message';
// FIXME replace alertdialog with the dialog from headlessui above
import AlertDialog from './Alert';
// import Spinner from '../monestry-spinner.svg';
import { SpinnerRight } from '../assets/mdiIcons';

export default function FeedbackOverlay() {
  const closeDialogInitial = {
    visible: false,
    text: 'Sample text',
    title: 'Sample title',
    buttonText: 'OK',
  };
  const [open, setOpen] = useState(false);
  const [feedbackField, setFeedbackField] = useState('');
  const [buttonPressed, setButton] = useState('Problem');
  const [checkBoxMarked, setCheckBox] = useState(false);
  const [closeDialog, setCloseDialog] = useState(closeDialogInitial);
  const [isLoading, setIsLoading] = useState(false);
  const [fileArray, setFileArray] = useState([]); // expects an array, convert FileList to an array before updating the state
  const [fileLoading, setFileLoading] = useState(false);

  const { t } = useTranslation('site');
  const dispatch = useDispatch();

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }

  const currentRoute = useLocation();

  async function postData(payload, _fileArray) {
    // get session details from Amplify.Auth
    const session = await Auth.currentSession();
    // insert all files into the body
    const body = payload;
    // attach base64 encoded files to the body
    if (_fileArray.length > 0) {
      body.attachments = [];
      await Promise.all(
        _fileArray.map((file) => new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = () => {
            body.attachments.push({
              name: file.name,
              type: file.type,
              data: reader.result.split(',')[1], // Remove the data:image/png;base64, part
            });
            resolve();
          };
          reader.onerror = (error) => {
            reject(error);
          };
          reader.readAsDataURL(file);
        })),
      );
    }

    const myInit = {
      body,
      headers: {
        'Content-Type': 'application/json',
        Authorization: session.idToken.jwtToken,
      },
    };

    let response;
    try {
      response = await API.post('myAPI', 'misc/feedback', myInit);
    } catch (error) {
      console.log(error.response);
      throw new Error(error);
    }

    return response;
  }

  async function handleSubmit() {
    // package all the relevant information into a payload object

    const jiraPayloadObject = {
      message: {
        type: buttonPressed,
        // make an actual (deep) copy of the state in memory to avoid circular reference
        // when running postData
        text: feedbackField,
        followUpRequested: checkBoxMarked,
      },
      context: {
        currentRoute,
        componentState: {},
      },
    };

    // dispatch payload to lambda
    let response;
    try {
      setIsLoading(true);
      response = await postData(jiraPayloadObject, fileArray);

      setIsLoading(false);
      setOpen(false);
      setFileArray([]);
      setCloseDialog({
        visible: true,
        text: `${t('site:feedback.completed.success')} ${response.key}`,
        title: t('site:general.success'),
        buttonText: t('site:general.ok'),
      });
    } catch (error) {
      setIsLoading(false);
      setOpen(false);
      setFileArray([]);
      console.log(`Jira API returned error --> ${error}`);
      setCloseDialog({
        visible: true,
        text: t('site:feedback.completed.failure'),
        title: t('site:general.error'),
        buttonText: t('site:general.ok'),
      });
    }
  }

  // handle file upload
  useEffect(() => {
    if (fileArray) {
      setFileLoading(true);
      // accept multiple files and read them in parallel
      if (fileArray.length > 0) {
        // lambda can only handle 6MB, so the limit is set to 5.5MB
        const totalSize = fileArray.reduce((acc, file) => acc + file.size, 0);
        if (totalSize > 5500000) {
          dispatch(setMessage('fileTooLarge'));
          setFileLoading(false);
          setFileArray(null);
        }
      }
    }
  }, [fileArray]);

  return (
    <>
      <Transition
        show={open}
        as={Fragment}
        enter="transform transition ease-in-out duration-500 sm:duration-700"
        enterFrom="translate-x-full"
        enterTo="translate-x-0"
        leave="transform transition ease-in-out duration-500 sm:duration-700"
        leaveFrom="translate-x-0"
        leaveTo="translate-x-full"
      >
        <Dialog
          as="div"
          className="fixed inset-0 overflow-hidden z-[100]"
          onClose={setOpen}
        >
          <div className="absolute inset-0 overflow-hidden">
            <Dialog.Overlay className="absolute inset-0" />

            <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex">

              <div className="w-screen max-w-md">
                <div className="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl">
                  {isLoading
                    ? (
                      <div id="loading" className="absolute w-full h-full bg-blend-overlay bg-gray-200 z-50 bg-opacity-50 flex justify-center items-center" data-testid="feedback-overlay-gray">
                        <div className="flex flex-col gap-3 text-sm text-gray-700 items-center">
                          <SpinnerRight className="h-12 w-12 text-gray-500 animate-spin" />
                          <p className="pt-2">{t('feedback.beingUploaded')}</p>
                        </div>
                      </div>
                    )
                    : null}
                  <div className="min-h-0 flex-1 flex flex-col py-6 overflow-y-scroll">
                    <div className="px-4 sm:px-6">
                      <div className="flex items-start justify-between">
                        <Dialog.Title className="text-2xl font-bold text-gray-900">
                          {t('site:feedback.title')}
                        </Dialog.Title>
                        <div className="ml-3 h-7 flex items-center">
                          <button
                            type="button"
                            className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-brandBlue-500"
                            onClick={() => setOpen(false)}
                          >
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="mt-6 relative flex-1 space-y-6 px-4 sm:px-6">
                      <div className="w-auto">
                        <div className="relative z-0 flex shadow-sm rounded-md">
                          <button
                            type="button"
                            id="problem"
                            // eslint-disable-next-line max-len
                            className={classNames(
                              (buttonPressed === 'Problem')
                                ? 'z-10 outline-none ring-1 ring-brandBlue-500 border-brandBlue-500'
                                : 'border-gray-300',
                              'relative flex-1 items-center px-4 py-2 rounded-l-md border  bg-white text-sm font-medium text-gray-700 hover:bg-gray-50',
                            )}
                            onClick={() => setButton('Problem')}
                          >
                            {t('site:feedback.problem')}
                          </button>
                          <button
                            type="button"
                            id="idea"
                            className={classNames(
                              (buttonPressed === 'Idea')
                                ? 'z-10 outline-none ring-1 ring-brandBlue-500 border-brandBlue-500'
                                : 'border-gray-300',
                              '-ml-px relative flex-1 items-center px-4 py-2 border bg-white text-sm font-medium text-gray-700 hover:bg-gray-50',
                            )}
                            onClick={() => setButton('Idea')}
                          >
                            {t('site:feedback.idea')}
                          </button>
                          <button
                            type="button"
                            id="question"
                            className={classNames(
                              (buttonPressed === 'Question')
                                ? 'z-10 outline-none ring-1 ring-brandBlue-500 border-brandBlue-500'
                                : 'border-gray-300',
                              '-ml-px relative flex-1 items-center px-4 py-2 rounded-r-md border  bg-white text-sm font-medium text-gray-700 hover:bg-gray-50',
                            )}
                            onClick={() => setButton('Question')}
                          >
                            {t('site:feedback.question')}
                          </button>
                        </div>
                      </div>
                      <div className="mt-6">
                        { /* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                        <label htmlFor="comment" className="block text-sm font-medium text-gray-700">
                          {t('site:feedback.header')}
                        </label>
                        <div className="mt-1">
                          <textarea
                            onChange={(e) => setFeedbackField(e.target.value)}
                            rows={8}
                            name="comment"
                            id="comment"
                            className="shadow-sm focus:ring-brandBlue focus:border-brandBlue block w-full sm:text-sm border-gray-300 rounded-md"
                            value={feedbackField.value}
                          />
                        </div>
                      </div>
                      {/* Send me an email once tackled */}
                      <fieldset>
                        <legend className="sr-only">Notifications</legend>
                        <div className="relative flex items-start">
                          <div className="flex items-center h-5">
                            <input
                              id="send-email"
                              aria-describedby="comments-description"
                              name="send-email"
                              type="checkbox"
                              className="focus:ring-brandBlue-500 h-4 w-4 text-brandBlue-600 border-gray-300 rounded"
                              checked={checkBoxMarked}
                              onChange={() => setCheckBox(!checkBoxMarked)}
                            />
                          </div>
                          <div className="ml-3 text-sm">
                            <label htmlFor="send-email" className="font-medium text-gray-700">
                              {t('site:feedback.email')}
                            </label>
                          </div>
                        </div>
                      </fieldset>
                      {/* /End replace */}
                      {/* Attachment */}
                      <form>
                        <div className="w-full p-6 border-2 border-gray-300 border-dashed rounded-md ">
                          <div className="mb-4">
                            {(fileArray || []).length > 0 && (
                              <div className="flex gap-2">
                                {fileArray.map((file) => (
                                  <div key={file.name} className="flex gap-2">
                                    <div className="flex items-center border py-2 px-2 rounded-sm shadow-sm border-gray-300 text-sm text-gray-500">
                                      {file.name}
                                      <XMarkIcon className="ml-2 w-4 h-4 text-gray-400 cursor-pointer" onClick={() => setFileArray(fileArray.filter((f) => f.name !== file.name))} />
                                    </div>
                                  </div>
                                ))}
                              </div>
                            )}
                          </div>
                          <div className="space-y-1 flex flex-col items-center">
                            <svg
                              className="h-12 w-12 text-gray-400"
                              stroke="currentColor"
                              fill="none"
                              viewBox="0 0 48 48"
                              aria-hidden="true"
                            >
                              <path
                                d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                            <div className="flex text-sm text-gray-600">
                              <label
                                htmlFor="file-upload"
                                className="relative cursor-pointer rounded-md font-medium text-brandBlue-500 hover:text-brandBlue-400"
                              >
                                <span>{t('feedback.uploadAttachment')}</span>
                                <input
                                  id="file-upload"
                                  name="file-upload"
                                  type="file"
                                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, application/pdf, image/*"
                                  multiple
                                  className="sr-only"
                                  onChange={(e) => setFileArray(Array.from(e.target.files))}
                                />
                              </label>
                              {/* <p className="pl-1">or drag and drop</p> */}
                            </div>
                            <p className="text-xs text-gray-500 pb-4">{t('feedback.uploadSizeLimit')}</p>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="flex-shrink-0 px-4 py-4 flex justify-end">
                    <button
                      type="button"
                      id="cancel"
                      className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brandBlue-500"
                      onClick={() => setOpen(false)}
                    >
                      {t('site:general.cancel')}
                    </button>
                    <button
                      type="submit"
                      id="submit"
                      onClick={() => handleSubmit()}
                      className="ml-4 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-brandBlue-500 hover:bg-brandBlue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brandBlue-500"
                    >
                      {t('site:general.send')}
                    </button>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </Dialog>
      </Transition>
      {/* permanent "feedback?" button to be displayed  */}
      <Transition
        show={!open}
        as={Fragment}
        enter="transform transition ease-in-out duration-500 sm:duration-700"
        enterFrom="translate-x-full"
        enterTo="translate-x-0"
        leave="transform transition ease-in-out duration-500 sm:duration-700"
        leaveFrom="translate-x-0"
        leaveTo="translate-x-full"
      >
        <div className="fixed z-[100] bottom-0 right-0 overflow-hidden" data-testid="feedback-overlay-button">
          {!open
            ? (
              <button
                type="submit"
                id="feedback"
                className="m-4 inline-flex justify-center py-2 px-4 border border-brandBlue-500 shadow-lg text-sm font-medium rounded-md text-brandBlue-500 bg-white hover:bg-brandBlue-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brandBlue-400"
                onClick={() => setOpen(true)}
              >
                Feedback?
              </button>
            )
            : null}
        </div>
      </Transition>
      {/* close dialog alert message to confirm success / failure of feedback being sent */}
      {closeDialog.visible
        ? (
          <div id="alert-dialog" className="fixed w-full z-50 overflow-hidden grid place-items-center h-screen bg-gray-400 bg-opacity-70">
            <AlertDialog
              text={closeDialog.text}
              title={closeDialog.title}
              buttonText={closeDialog.buttonText}
              dialogStateCallback={setCloseDialog}
            />
          </div>
        )
        : null}
    </>
  );
}
