/* eslint-disable max-len */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-no-bind */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { DndContext, DragOverlay } from '@dnd-kit/core';
import { XMarkIcon, CheckIcon, DocumentArrowDownIcon, ForwardIcon, ViewColumnsIcon } from '@heroicons/react/24/outline';
import { nanoid } from 'nanoid';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { InformationCircleIcon, QuestionMarkCircleIcon } from '@heroicons/react/24/solid';
import { getSchemaByCategory, quote, quotes, customQuoteTypesPension } from '@monestry-dev/schema';
import ColumnMatcherErrorPopover from './ColumnMatcherErrorPopover';
import { putAccount } from '../../redux/reducers/data';
import { Reset } from '../../assets/mdiIcons';
import ToolTipNoIcon from '../../elements/ToolTipNoIcon';
import { setMessage } from '../../redux/actions/message';
import DataModelColumn from './DataModelColumn';
import FileColumn from './FileColumn';
import FilePreview from './FilePreview';
import CombinePane from './CombinePane';
import Button from '../../elements/Button';
import ColumnMatcherRVUebersicht from './ColumnMatcherRVUebersicht';
import ColumnMatcherAddQBCToPension from './ColumnMatcherAddQBCToPension';

dayjs.extend(utc);

const debugLevel = process.env.REACT_APP_MDEBUG || 0;

const colours = ['bg-brandGreen-500', 'bg-brandBlue-500', 'bg-brandRed-500', 'bg-brandDarkBlue-500', 'bg-brandViolet-500', 'bg-brandYellow-500'];

export function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

function convertStringToNumber(inputString) {
  let num = Number(inputString);

  // Check if the initial conversion is successful
  if (!Number.isNaN(num)) {
    return num;
  }

  // Replace dots with nothing and commas with dots
  const modifiedString = inputString.replace(/\./g, '').replace(/,/g, '.');

  // Try to convert the modified string to a number
  num = Number(modifiedString);
  if (!Number.isNaN(num)) {
    return num;
  }

  // If all conversions fail, return an error
  return new Error('Could not convert input to a number');
}

export default function ColumnMatcher({ displayedComponent, setDisplayedComponent, displayedComponentMode, alert, setAlert, account }) {
  // the draggable FileColumn components are either shown in the "pool" on the bottom,
  // or at their target spots among the columns on top
  // dropState has key-value pairs which tell us which FileColumn objects are located in the columns
  // if the FileColumn id is not in the dropState object, it is not dropped anywhere and should be shown at the bottom
  const dispatch = useDispatch();
  const { t } = useTranslation(['app'], { keyPrefix: 'accountDetails.buttons' });

  let arraySchema;
  let objectSchema;

  if (displayedComponentMode === 'transactions') {
    arraySchema = getSchemaByCategory(account.category, 'transaction', true);
    objectSchema = getSchemaByCategory(account.category, 'transaction', false);
  } else if (displayedComponentMode === 'quotes') {
    arraySchema = quotes;
    objectSchema = quote;
  }

  // -----------------------------------
  // HELPER FUNCTIONS
  // -----------------------------------

  // returns the index of the column where the headers should be based on the file input data
  // only runs once during initialisation
  function detectHeaders(parsedArray, fileColumnStats) {
    // get the maximum number of columns in the file as column count
    // try to detect headers: try to find a row with the same amount of columns as the last row and all strings
    let j = 0;
    let suggestedHeaderRow; // undefined
    while (parsedArray[j].length === fileColumnStats.maxCols && j < parsedArray.length && suggestedHeaderRow === undefined) {
      if (parsedArray[j].every((item) => /[0-9]*[,.][0-9]*$/.test(item) || typeof new Date(item) === 'object')) suggestedHeaderRow = j;
      j += 1;
    }
    return suggestedHeaderRow;
  }

  // converts the current file (in state) into an object with headers and rows
  // returns fileObject { headers: [], rows: [] }
  function transformInputObject(inputArray, suggestedHeaderRow, fileColumnStats) {
    // input is an array of arrays, each array is a row
    // prepare output: { headers: { id ('column1'), label ('name from file or default'),
    // description (default "column 1 of your file"), bgColour }, rows: [[val1, val2, ... valN], [val1, val2, ... valN],...]}
    const output = {
      headers: [],
      rows: [],
    };
    // if there is a header row, use it to create the headers; if there is no header row, create default headers based on the detected column numbers
    const headerArray = inputArray[suggestedHeaderRow === undefined ? fileColumnStats.maxColsRow : suggestedHeaderRow];
    const longestRowLength = fileColumnStats.maxCols;
    for (let i = 0; i < headerArray.length; i += 1) {
      output.headers.push({
        id: `column${i + 1}`,
        label: suggestedHeaderRow === undefined ? `column${i + 1}` : headerArray[i] || 'empty',
        description: `Column ${i + 1} of your file`,
        bgColour: colours[i % colours.length],
      });
    }
    // if there is a header row, start at the next row, otherwise start at the first row
    for (let i = suggestedHeaderRow === undefined ? 0 : suggestedHeaderRow + 1; i < inputArray.length; i += 1) {
      if (inputArray[i].length < longestRowLength) {
        // if the row is shorter than the longest row, fill it up with nulls
        const updatedRow = new Array(longestRowLength - inputArray[i].length).fill(null);
        output.rows.push(inputArray[i].concat(updatedRow));
      } else output.rows.push(inputArray[i]);
    }
    return output;
  }

  // -----------------------------------
  // SELECTORS + STATE
  // -----------------------------------

  const importFiles = useSelector((state) => state.data[account.category].importFiles);
  const baseCurrency = useSelector((state) => state.user.profile.settings.baseCurrency);
  // ↓↓ if this is set to a number, then it has been set by previous iteration of ColumnMatcher, so initialise currentFileIndex with it;
  const fileIndexFromStore = importFiles?.nextFileIndex || 0;

  const [currentFileIndex, setCurrentFile] = useState(fileIndexFromStore); // index of the file currently being processed (handles user uploads multiple files)
  const inputFileObject = importFiles?.ingested?.[currentFileIndex] || [];

  // finds a row with the most number of columns and stores its index as maxColsRow and the number of columns as maxCols
  const [fileColumnStats, setFileColumnStats] = useState(() => {
    const initialState = inputFileObject.reduce((acc, cur, idx) => (cur.length > acc.maxCols ? { maxCols: cur.length, maxColsRow: idx } : acc), { maxCols: 0, maxColsRow: 0 });
    return initialState;
  });

  const [headerRow, setHeaderRow] = useState(() => detectHeaders(inputFileObject, fileColumnStats)); // index of the row where the headers are
  const [currentFileObject, setCurrentFileObject] = useState(() => transformInputObject(inputFileObject, headerRow, fileColumnStats)); // changes are handled in useEffect to handle custom columns
  const [activeFileColumn, setActiveFileColumn] = useState(null); // handles appearance of the DragOverlay component
  const [allFieldsAssigned, setAllFieldsAssigned] = useState(false); // true if all mandatory fields are assigned (there can be validation errors)
  const [formReadyToSubmit, setFormReadyToSubmit] = useState(false); // true if all mandatory fields are filled out and there are no validation errors

  // contains mappings, initialise empty; fill out with values from the mapping in redux / in category service in useEffect, once custom columns are added
  const [dropState, setDropState] = useState({});
  // in which columns are filecolumns by id: { fileCol2: 'col2', fileCol3: 'col4', fileCol5: null ...}
  // should be: { dataModelCol1: fileCol1, dataModelCol2: fileCol2, ...}

  const [previewState, setPreviewState] = useState(null); // used to show the preview of the file column in the column
  const [validationErrors, setValidationErrors] = useState([]); // contains errors from the validation of the form (after each drop)
  const [loading, setLoading] = useState(false); // loading state for the submit button
  const [combinePane, setCombinePane] = useState(false); // true if the user wants to combine multiple columns into one
  const [useOnlyThisRow, setUseOnlyThisRow] = useState(undefined); // for pensions users may want to import their RV app file which contains data for several accounts (not just this one)

  const componentRef = useRef();

  function calculateNewCellValue(definition, row) {
    // definition is an array of objects {columnIndex, operationId, constant}
    // row is an array of values from the file
    return definition.reduce((acc, cur) => {
      switch (cur.operationId) {
        case undefined:
        case null:
          return row[cur.columnIndex];
        case 'addIfEmpty': // only this is enabled for now
          return !acc || acc === ' ' ? Number(row[cur.columnIndex]) : acc;
        case 'concatenate':
          return `${acc} ${row[cur.columnIndex]}`;
        case 'add':
          return acc + Number(row[cur.columnIndex]);
        case 'subtract':
          return acc - Number(row[cur.columnIndex]);
        case 'multiply':
          return acc * Number(row[cur.columnIndex]);
        case 'divide':
          return acc / Number(row[cur.columnIndex]);
        case 'divideBy100':
          return acc / 100;
        case 'addConstant':
          return acc + Number(row[cur.constant]);
        case 'subtractConstant':
          return acc - Number(row[cur.constant]);
        case 'multiplyByConstant':
          return acc * Number(row[cur.constant]);
        case 'divideByConstant':
          return acc / Number(row[cur.constant]);
        default:
          return acc;
      }
    }, 0);
  }

  // ------------------------
  // HANDLE RV APP IMPORT
  // ------------------------

  function callback(row) {
    setUseOnlyThisRow(row);

    // the file columns will be numbered through (column01, column02, ... column99), so - in case the number of columns in the file has changed - we need to find them by name and get their index
    setDropState({
      date: `column${(inputFileObject[0].findIndex((col) => col === 'Stichtag Wertangaben') + 1).toString().padStart(2, '0')}`,
      payoutOnlyContributionsToDate: `column${(inputFileObject[0].findIndex((col) => col === 'Garantiert erreichte Rente') + 1).toString().padStart(2, '0')}`,
      payoutOnlyContributionsToDateIndexed: `column${(inputFileObject[0].findIndex((col) => col === 'Prognostiziert erreichte Rente') + 1).toString().padStart(2, '0')}`,
      payoutAllPlannedContributions: `column${(inputFileObject[0].findIndex((col) => col === 'Garantiert erreichbare Rente') + 1).toString().padStart(2, '0')}`,
      payoutAllPlannedContributionsIndexed: `column${(inputFileObject[0].findIndex((col) => col === 'Prognostiziert erreichbare Rente') + 1).toString().padStart(2, '0')}`,
    });
  }

  useEffect(() => {
    if (useOnlyThisRow === undefined) {
      // we try to detect the file type based on the first 7 rows
      const targetFirstRows = ['Kontakt-Straße', 'Kontakt-Hausnummer', 'Kontakt-Ort', 'Kontakt-PLZ', 'Kontakt-Postfach', 'Kontakt-Staat', 'Kontakt-URL'];
      // if there is more than one pension product in the input file (+ header)
      if (targetFirstRows.every((target, index) => inputFileObject[0][index] === target)) {
        if (inputFileObject.length > 2) {
          window.dispatchEvent(
            new CustomEvent('setDialog', {
              detail: {
                Component: ColumnMatcherRVUebersicht,
                props: { visible: true, inputFileObject, callback },
              },
            }),
          );
        } else if (inputFileObject.length === 2) {
          setUseOnlyThisRow(1);
        }
      }
    }
  }, []);

  // ------------------------
  // (RE)CALCULATE COLUMNS
  // ------------------------

  // add custom columns from importFileMappings to the schema when importFiles or account object change
  // every time importFiles or account object changes, the entire fileObject is recalculated from scratch (to handle custom column changes)
  useEffect(() => {
    // calculate the columns, their metainformation and their values (in rows)
    // case 1: calculate custom columns (if any)
    if (account.importFileMappings && account.importFileMappings.customColumns && account.importFileMappings.customColumns.length > 0) {
      const { customColumns } = account.importFileMappings;
      // for each customColumn in importFileMappings
      customColumns.forEach((customColumn, index) => {
        // check if all source columns are in the currentFileObject.headers (which contains { id, label, description, bgColour })
        const neededFileColumnIndices = customColumn.definition.map((definition) => Number(definition.columnIndex));
        // the highest index of needed column must be lower or equal to the number of columns in the file
        if (Math.max(...neededFileColumnIndices) < currentFileObject.headers.length) {
          // if yes, add the custom column to the file columns object
          setCurrentFileObject(() => {
            const regularColumns = transformInputObject(inputFileObject, headerRow, fileColumnStats);
            // add one header for the new column
            const newHeaders = regularColumns.headers.concat({
              id: `column${regularColumns.headers.length + index + 1}`, // column for users start at 1, so length + index (starts at 0) + 1
              label: customColumn.name,
              description: t('customColumnDescription'),
              bgColour: colours[regularColumns.headers.length % colours.length],
              definition: customColumn.definition,
            });
            // add this column to each row
            const newRows = regularColumns.rows.map((row) => {
              const newRow = row.concat(calculateNewCellValue(customColumn.definition, row));
              return newRow;
            });
            return { headers: newHeaders, rows: newRows };
          });
        }
      });
    } else {
      // case 2: if there are no custom column
      setCurrentFileObject(transformInputObject(inputFileObject, headerRow, fileColumnStats));
    }

    // once custom columns have been added, set dropState to the mappings from the account object (if dropState is still empty - otherwise let it be, because that means it is not the first run)
    setDropState((prev) => {
      if (Object.keys(prev).length === 0) {
        const mappings = account.importFileMappings?.mappings || {};
        // skip this check if there are no mappings
        if (Object.keys(mappings).length > 0) {
          // check if all data model columns are still there (in case there has been a change in the data model)
          const missingColumns = Object.keys(mappings).filter((key) => !Object.keys(objectSchema.describe().fields).includes(key));
          if (missingColumns.length > 0) {
            // if there are missing columns, remove them from the mappings
            const newMappings = Object.entries(mappings).filter(([key, value]) => !missingColumns.includes(key));
            return Object.fromEntries(newMappings);
          }

          // check if imported mappings use any columns with indices exceeding the current highest column index (because user had imported a different file before)
          const highestIndex = Math.max(...Object.values(mappings).map((value) => Number(value.split('column')[1])));
          if (highestIndex > currentFileObject.headers.length) {
            // if yes, remove them from the mappings
            const newMappings = Object.entries(mappings).filter(([key, value]) => Number(value.split('column')[1]) <= currentFileObject.headers.length);
            return Object.fromEntries(newMappings);
          }
        }
        return account.importFileMappings?.mappings || {};
      }
      return prev;
    });
  }, [importFiles, account]);

  // -------------------------------
  // GET TARGET COLUMNS FROM SCHEMA
  // -------------------------------

  // handle pensions, which need to display special quotes instead of quote | quoteBaseCurrency
  // for all other categories, return unaltered schema object
  function getSchemaEntries(category) {
    const originalSchema = Object.entries(objectSchema.describe().fields); // originalSchema returns an array of arrays [ fieldName, fieldObject ]

    // for all categories except pension, return the original schema without "specialQuotes", which have no relevance there
    if (category !== 'pension') return originalSchema.filter(([fieldName, fieldObject]) => !['specialQuotes'].includes(fieldName));

    return originalSchema
      .filter(([fieldName, fieldObject]) => !['currency', 'quote', 'quoteBaseCurrency', 'specialQuotes'].includes(fieldName))
      .concat(customQuoteTypesPension.map((x) => ([x, { meta: { importOptional: true } }])));
  }

  // .describe() returns .fields: { id: { name, type, label, meta, ...}, date: { ... }, ... };
  // fields in schema can have a hideFromImport meta-property, which is used to hide columns from the import process
  // 'columns' needs to be [{ id, optional }, ...]
  const columns = getSchemaEntries(account.category)
    .filter(([key, value]) => !value.meta?.hideFromImport)
    .map(([key, value]) => ({ id: key, optional: value.meta?.importOptional }));

  // -------------------------------
  // PREPARE OUTPUT / HANDLE SAVE
  // -------------------------------

  // used to test if the form is ready as well as before submitting
  // fileObject is the object with headers and rows (transformed input file + custom columns)
  // this takes rows from fileObject and maps them to the data model columns according to what is in dropState
  // FIXME this needs to be adjusted to handle the special case in pension
  // get rid of special quotes that have not been provided by user or in the file
  async function transformAndValidateOutput(fileObject, alertOnError = true) {
    // for each row in fileObject get only the columns of the indices present in dropState
    // (so the columns that the user mapped to the data model columns)
    // and form a transaction object
    const output = fileObject.rows
      .filter((row, index) => useOnlyThisRow === undefined || index === (useOnlyThisRow - 1)) // if useOnlyThisRow is defined, only use that row
      .map((row) => {
        const entity = {};
        Object.entries(dropState).forEach(([key, value]) => {
          const index = value.split('column')[1] - 1;
          entity[key] = row[index] ?? null;
        });

        entity.date = dayjs.utc(entity.date).valueOf();
        if (displayedComponentMode === 'transactions') {
        // add id
          entity.id = nanoid();
          // add attributes derived from account
          if (account.category === 'deposits' && !entity.currency) entity.currency = account.currency; // realEstate only allows quotes
          if (account.category === 'stocks') entity.transactionType = entity.transactionAmount >= 0 ? 'purchase' : 'sale'; // FIXME this assumes there will never be 'split' transactions etc.
          if (account.category !== 'realEstate') entity.accountId = account.id; // realEstate only allows quotes, and quotes do not need account
        }
        if (displayedComponentMode === 'quotes') {
          entity.source = 'manual';
          if (!entity.currency) entity.currency = account.currency; // realEstate only allows quotes
          entity.assetId = account.id;
          entity.projectId = null;
          entity.importFlag = 'post'; // was 'put', not sure why?

          // special routine for pension category quotes
          if (account.category === 'pension') {
            entity.quoteBaseCurrency = 0;
            entity.quote = 0;
            entity.currency = account.currency;
            entity.assetId = account.id;
            entity.source = 'manual';
            entity.specialQuotes = [];
            if (entity.payoutOnlyContributionsToDate) entity.specialQuotes.push({ type: 'payoutOnlyContributionsToDate', quote: convertStringToNumber(entity.payoutOnlyContributionsToDate) });
            if (entity.payoutOnlyContributionsToDateIndexed) entity.specialQuotes.push({ type: 'payoutOnlyContributionsToDateIndexed', quote: convertStringToNumber(entity.payoutOnlyContributionsToDateIndexed) });
            if (entity.payoutAllPlannedContributions) entity.specialQuotes.push({ type: 'payoutAllPlannedContributions', quote: convertStringToNumber(entity.payoutAllPlannedContributions) });
            if (entity.payoutAllPlannedContributionsIndexed) entity.specialQuotes.push({ type: 'payoutAllPlannedContributionsIndexed', quote: convertStringToNumber(entity.payoutAllPlannedContributionsIndexed) });

            if (account.currency === baseCurrency) {
              entity.specialQuotes.forEach((q) => {
              // eslint-disable-next-line no-param-reassign
                q.quoteBaseCurrency = q.quote;
              });
            }
            // the case where account.currency is NOT base currency is handled in handleSave, because this runs every time anything changes in the form
          }
        }

        return entity;
      });
    if (debugLevel > 2) console.log('transformed output', output);

    // validation by casting the file data through yum.cast
    let validatedOutput;
    try {
      validatedOutput = arraySchema.validateSync(output, { stripUnknown: true, abortEarly: false });
      // if no errors, set validationErrors to empty
      setValidationErrors([]);
    } catch (err) {
      console.error('validation error', err, err.inner);
      setValidationErrors(err.inner);
      if (alertOnError) {
        setAlert({
          visible: true,
          id: err.message,
          type: 'error',
        });
      }
    }
    return validatedOutput;
  }

  async function handleSave() {
    const validatedOutput = await transformAndValidateOutput(currentFileObject);

    // PENSION QUOTES ONLY:
    // in pensions we need to check if all specialQuotes have quoteBaseCurrency; if they don't, we need to ask the user to provide them
    if (displayedComponentMode === 'quotes' && account.category === 'pension' && account.currency !== baseCurrency) {
      const missingQuoteBaseCurrency = validatedOutput.some((Q) => Q.specialQuotes.length > 0 && Q.specialQuotes.some((q) => q.quoteBaseCurrency === 0));
      if (missingQuoteBaseCurrency) {
        try {
          const fxRate = await new Promise((resolve, reject) => {
            window.dispatchEvent(
              new CustomEvent('setDialog', {
                detail: {
                  Component: ColumnMatcherAddQBCToPension,
                  props: { visible: true,
                    specialQuotes: validatedOutput.filter((Q) => Q.specialQuotes.length > 0).map((Q) => Q.specialQuotes)[0],
                    accountCurrency: account.currency,
                    baseCurrency,
                    callback: (_fxRate) => {
                      resolve(_fxRate);
                    },
                    reject },
                },
              }),
            );
          });

          // apply fxRate to generate quoteBaseCurrency in all specialQuotes for all rows of validatedOutput
          validatedOutput.forEach((Q) => {
            Q.specialQuotes.forEach((q) => {
              // eslint-disable-next-line no-param-reassign
              q.quoteBaseCurrency = q.quote * fxRate;
            });
          });
        } catch (err) {
          // if the user cancels, do nothing
          return;
        }
      }
    }

    if (validatedOutput) {
      console.log('DEBUG', validatedOutput);
      setLoading(true);

      // wait for dispatches to return something
      Promise.all([
        // send mappings to backend (update Account object) -- this will set a signal { [depositsPostAccount]: true }
        dispatch(
          putAccount({
            data: {
              ...account,
              importFileMappings: {
                ...account.importFileMappings,
                mappings: dropState,
              },
            },
            category: account.category,
          }),
        ),
        // send transactions to backend (new Transactions array) -- this will update Account status to LOADING
        dispatch({
          type: 'data/fileTransformedSuccessfully',
          payload: {
            category: account.category,
            data: validatedOutput,
            nextFileIndex: currentFileIndex < (importFiles.fileNames?.length || 0) - 1 ? currentFileIndex + 1 : null,
          },
        }),
      ]).then(
        () => {
          setDisplayedComponent('duplicate');
        },
        (err) => {
          // negative case
          console.error('error', err);
          dispatch(setMessage('fileUploadFailed'));
        },
      );
    }
  }

  // react to the button pressed by the user in response to aboutToSave modal triggered above in handleSaveGrid
  useEffect(() => {
    if (alert.id === 'abandonUpload') {
      if (alert.buttonClicked === 'ok') {
        // if the user pressed ok, go back to table screen
        setAlert({ visible: false }); // reset the alert modal
        setDisplayedComponent('table');
        dispatch(setMessage('fileUploadAbortedSuccessfully')); // shows a toast message confirming that file upload cancelled
      }
    }
  }, [alert.buttonClicked]);

  // -------------------------------
  // ONGOING VALIDATION
  // -------------------------------

  // after every mapping change this validates existing state of mappings; enables the submit button if there are no errors
  // validation must react to dropState change, because doing it in handleDrop is too early (dropState is not updated yet)
  useEffect(() => {
    async function ongoingValidation() {
      // validate the form and - if it passes - set the formReadyCondition to true
      let validationResult;
      try {
        validationResult = await transformAndValidateOutput(currentFileObject, false); // no alert on error; this updates validationErrors
        console.info('validation result', validationResult);
        setFormReadyToSubmit(validationResult);
      } catch (err) {
        // do nothing, we are just checking if it is ready to unblock the button
      }
      // determine if all the required fields are filled out
      setAllFieldsAssigned(columns.filter((column) => !column.optional).every((column) => Object.keys(dropState).includes(column.id)));
    }

    ongoingValidation();
  }, [dropState]);

  // -------------------------------
  // HANDLE DRAG + DROP
  // -------------------------------

  function handleDragStart(e) {
    console.log('drag start', e);
    // the FileColumn component needs an entire object a prop, so we need to retrieve it
    setActiveFileColumn(currentFileObject.headers.find((header) => header.id === e.active.id));
  }

  function handleDrop(e) {
    // disable DropOverlay
    setActiveFileColumn(null);

    // when dropped on a droppable element:
    // - e.over.id --> which droppable column id are we over (null if none)
    // - e.active.id --> id of the component being dragged

    // check if trying to drop inside an already occupied column
    // if e.over.id exists in dropState, it means this column is occupied
    // if (e.over && !Object.entries(dropState).some(([key, value]) => value === e.over.id)) {
    if (e.over && !Object.keys(dropState).includes(e.over.id)) {
      // if dropped outside a droppable element, go through dropState and remove the dropped element
      console.log('dropped on', e.over.id, 'active', e.active.id);
      setDropState({ ...dropState, [e.over.id]: e.active.id });
    }

    // if dropped outside a droppable element (e.over.id is null), go through dropState and remove the dropped element
    if (!e.over) {
      console.log('dropped outside', e.active.id, 'dropState', dropState);
      setDropState(
        Object.entries(dropState)
          .filter(([key, value]) => value !== e.active.id)
          .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {}),
      );
    }
  }

  function handleHeaderRowChange(newValue) {
    setHeaderRow(Number(newValue) - 1);
    // recalculate headers + rows
    setCurrentFileObject(transformInputObject(inputFileObject, Number(newValue) - 1, fileColumnStats));
  }

  const columnsForCombinePane = currentFileObject.headers.map((header) => ({ id: header.id, name: header.label, isCustomColumn: header.definition ? header.definition : false }));

  console.log('ColumnMatcher fileObject', currentFileObject);
  console.log('ColumnMatcher dropState', dropState);

  // -------------------------------
  // RENDER THE COMPONENT
  // -------------------------------

  return (
    // eslint-disable-next-line react/jsx-no-bind
    <>
      <section className="mt-2 sm:mt-0 rounded-md bg-white px-4 py-2 flex items-center space-x-2">
        <DocumentArrowDownIcon className="h-6 w-6 text-gray-500" aria-hidden="true" />
        <h2 className="text-2xl font-bold text-gray-700">{importFiles?.fileNames[currentFileIndex]}</h2>
      </section>
      {combinePane ? (
        <CombinePane setCombinePane={setCombinePane} account={account} columns={columnsForCombinePane} />
      ) : (
        <>
          <section className="ml-1 flex flex-rows gap-2 sm:gap-4">
            {/* reset columns button, only display above xs (otherwise no space) */}
            <ToolTipNoIcon info={t('resetColumns.tooltip')} classNameOwn="hidden xs:block">
              <Button
                text={t('resetColumns.label')}
                Icon={Reset}
                onClick={() => {
                  setDropState({});
                }}
                size="lg"
              />
            </ToolTipNoIcon>
            {/* this only works if the file has more than 2 columns, but then it needs at least 4 columns to pass validation... */}
            {fileColumnStats.maxCols > 1 && (
              <ToolTipNoIcon info={t('customColumns.tooltip')}>
                <Button
                  text={t('customColumns.label')}
                  Icon={ViewColumnsIcon}
                  onClick={() => {
                    setCombinePane(true);
                  }}
                  size="lg"
                />
              </ToolTipNoIcon>
            )}
            <ToolTipNoIcon info={t('cancelFileUpload.tooltip')}>
              <Button
                text={t('cancelFileUpload.label')}
                Icon={XMarkIcon}
                onClick={() => {
                  setAlert({ visible: true, id: 'abandonUpload', buttons: ['ok', 'cancel'] });
                  // do not send out a cleanup action to category service, because a process to clean up stuck files must be existing anyway
                  // for the case that the user closes the app before the upload process is finished
                }}
                size="lg"
              />
            </ToolTipNoIcon>
            {importFiles.fileNames?.length > 1 && currentFileIndex < (importFiles.fileNames?.length || 0) - 1 && (
              <ToolTipNoIcon info={t('skipThisFile.tooltip')}>
                <Button
                  text={t('skipThisFile.label')}
                  Icon={ForwardIcon}
                  onClick={() => {
                    setCurrentFile((prev) => (prev < importFiles.fileNames.length - 1 ? prev + 1 : prev));
                  }}
                  size="lg"
                />
              </ToolTipNoIcon>
            )}
            <ToolTipNoIcon info={t('confirmAndContinue.tooltip')}>
              <Button text={t('confirmAndContinue.label')} Icon={CheckIcon} onClick={handleSave} spinnerOn={loading} enabled={formReadyToSubmit} withAccent size="lg" />
            </ToolTipNoIcon>
            {Object.keys(dropState).length === 0 ? (
              // first upload message
              <div className="rounded-md bg-brandBlue-50 px-3 py-2" id="info-message">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <InformationCircleIcon className="h-5 w-5 text-brandBlue-400" aria-hidden="true" />
                  </div>
                  <div className="ml-3 flex-1 md:flex md:justify-between">
                    <p className="text-sm text-brandBlue-600">{t('firstUpload')}</p>
                  </div>
                </div>
              </div>
            ) : (
              // headers in row field (above LG)
              <div className="hidden lg:inline-flex gap-2 items-center">
                <label htmlFor="headerRow" className="block text-sm font-medium text-gray-700">
                  {t('headersInRow.label')}
                </label>
                <input
                  type="number"
                  name="headerRow"
                  id="headerRow"
                  className="block w-14 rounded-md border-gray-300 pr-2 focus:border-brandBlue-500 focus:ring-brandBlue-500 sm:text-sm"
                  value={headerRow + 1 || 1}
                  onChange={(e) => handleHeaderRowChange(e.target.value)}
                />
                <ToolTipNoIcon info={t('headersInRow.tooltip')}>
                  <QuestionMarkCircleIcon className="h-5 w-5 text-gray-300" aria-hidden="true" />
                </ToolTipNoIcon>
              </div>
            )}
            {allFieldsAssigned && validationErrors.length > 0 && <ColumnMatcherErrorPopover validationErrorArray={validationErrors} />}
          </section>
          {/* headers in row field (below LG) */}
          <div className="inline-flex lg:hidden gap-2 items-center">
            <label htmlFor="headerRow" className="block text-sm font-medium text-gray-700">
              {t('headersInRow.label')}
            </label>
            <input
              type="number"
              name="headerRow"
              id="headerRow"
              className="block w-14 rounded-md border-gray-300 pr-2 focus:border-brandBlue-500 focus:ring-brandBlue-500 sm:text-sm"
              value={headerRow + 1 || 1}
              onChange={(e) => handleHeaderRowChange(e.target.value)}
            />
            <ToolTipNoIcon info={t('headersInRow.tooltip')}>
              <QuestionMarkCircleIcon className="h-5 w-5 text-gray-300" aria-hidden="true" />
            </ToolTipNoIcon>
          </div>
          <DndContext onDragStart={handleDragStart} onDragEnd={handleDrop} id="dnd-area">
            <div className="relative w-full h-full" ref={componentRef}>
              {/* ^^ parent container for keeping the div with fields in place while the columns scroll */}
              {previewState && <FilePreview previewState={previewState} setPreviewState={setPreviewState} fileObject={currentFileObject} />}
              <div className="bg-gray-50 w-full h-full flex flex-col justify-between">
                {/* ^^ parent container for horizontal scrolling */}
                <div className="w-full bg-gray-50">
                  <div className="flex gap-px divide-x divide-gray-200 overflow-x-auto" id="data-model-columns-container">
                    {columns.map((col, idx) => (
                      <DataModelColumn col={col.id} idx={idx} key={col.id} lastInRow={idx === columns.length - 1} category={account.category}>
                        {/* if this column's id 'droppable-Date' is a value in any of the properties of dropState,
                  return the list of keys that this column's id is a value for */}
                        {Object.entries(dropState)
                          .filter(([key, value]) => key === col.id)
                          .map(([key, value]) => (
                            <FileColumn obj={currentFileObject.headers.filter((item) => item.id === value)[0]} key={value} previewState={previewState} setPreviewState={setPreviewState} />
                          ))}
                      </DataModelColumn>
                    ))}
                  </div>
                </div>
                <div className="mx-auto text-center text-xs text-gray-500 py-8">
                  <p className="text-lg font-bold pb-1">{t('columnMatcherHeader')}</p>
                  <p className="prose text-center text-xs text-gray-400 mx-auto">{t('columnMatcherInfo')}</p>
                </div>
                <div className="overflow-x-scroll">
                  <div className="shadow-sm rounded-lg inset-x-0 bottom-8 mx-auto bg-white overflow-x-scroll">
                    <div className="mt-3 px-6 py-3 flex gap-5" id="file-columns-container">
                      {currentFileObject.headers
                        // show only those fileColumns, for which there are no keys in the dropState object or for which the value has been set to null before
                        .filter((x) => !Object.values(dropState).includes(x.id))
                        .map((obj, idx) => (
                          <FileColumn key={idx} obj={obj} setPreviewState={setPreviewState} />
                        ))}
                    </div>
                  </div>
                </div>
              </div>
              <DragOverlay>{activeFileColumn ? <FileColumn obj={activeFileColumn} previewState={previewState} setPreviewState={setPreviewState} /> : null}</DragOverlay>
            </div>
          </DndContext>
        </>
      )}
    </>
  );
}
ColumnMatcher.propTypes = {
  displayedComponent: PropTypes.arrayOf(PropTypes.string).isRequired,
  setDisplayedComponent: PropTypes.func.isRequired,
  displayedComponentMode: PropTypes.string.isRequired,
  alert: PropTypes.objectOf(PropTypes.any).isRequired,
  setAlert: PropTypes.func.isRequired,
  account: PropTypes.objectOf(PropTypes.any).isRequired,
};
