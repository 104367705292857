// this is also hard-coded in the Quotes API getAllFx function
export const currencyCodes = [
  'EUR',
  'AUD',
  'BGN',
  'BRL',
  'CAD',
  'CHF',
  'CNY',
  'CZK',
  'DKK',
  'GBP',
  'HKD',
  'HUF',
  'ILS',
  'INR',
  'ISK',
  'JPY',
  'KRW',
  'MXN',
  'MYR',
  'NOK',
  'NZD',
  'PHP',
  'PLN',
  'RON',
  'SEK',
  'SGD',
  'THB',
  'TRY',
  'USD',
  'ZAR',
];
// until there is a mechanism in place which allows the user to provide exchange rates, we limit the currencies to those available in ECB (see PRD-1414)
// export const currencyCodes = [
//   'EUR',
//   'USD',
//   'CHF',
//   'GBP',
//   'AED',
//   'AFN',
//   'ALL',
//   'AMD',
//   'ANG',
//   'AOA',
//   'ARS',
//   'AUD',
//   'AWG',
//   'AZN',
//   'BAM',
//   'BBD',
//   'BDT',
//   'BGN',
//   'BHD',
//   'BIF',
//   'BMD',
//   'BND',
//   'BOB',
//   'BOV',
//   'BRL',
//   'BSD',
//   'BTN',
//   'BWP',
//   'BYR',
//   'BZD',
//   'CAD',
//   'CDF',
//   'CHE',
//   'CHW',
//   'CLF',
//   'CLP',
//   'CNY',
//   'COP',
//   'COU',
//   'CRC',
//   'CUC',
//   'CUP',
//   'CVE',
//   'CZK',
//   'DJF',
//   'DKK',
//   'DOP',
//   'DZD',
//   'EGP',
//   'ERN',
//   'ETB',
//   'FJD',
//   'FKP',
//   'GEL',
//   'GHS',
//   'GIP',
//   'GMD',
//   'GNF',
//   'GTQ',
//   'GYD',
//   'HKD',
//   'HNL',
//   'HRK',
//   'HTG',
//   'HUF',
//   'IDR',
//   'ILS',
//   'INR',
//   'IQD',
//   'IRR',
//   'ISK',
//   'JMD',
//   'JOD',
//   'JPY',
//   'KES',
//   'KGS',
//   'KHR',
//   'KMF',
//   'KPW',
//   'KRW',
//   'KWD',
//   'KYD',
//   'KZT',
//   'LAK',
//   'LBP',
//   'LKR',
//   'LRD',
//   'LSL',
//   'LTL',
//   'LVL',
//   'LYD',
//   'MAD',
//   'MDL',
//   'MGA',
//   'MKD',
//   'MMK',
//   'MNT',
//   'MOP',
//   'MRO',
//   'MUR',
//   'MVR',
//   'MWK',
//   'MXN',
//   'MXV',
//   'MYR',
//   'MZN',
//   'NAD',
//   'NGN',
//   'NIO',
//   'NOK',
//   'NPR',
//   'NZD',
//   'OMR',
//   'PAB',
//   'PEN',
//   'PGK',
//   'PHP',
//   'PKR',
//   'PLN',
//   'PYG',
//   'QAR',
//   'RON',
//   'RSD',
//   'RUB',
//   'RWF',
//   'SAR',
//   'SBD',
//   'SCR',
//   'SDG',
//   'SEK',
//   'SGD',
//   'SHP',
//   'SLL',
//   'SOS',
//   'SRD',
//   'SSP',
//   'STD',
//   'SYP',
//   'SZL',
//   'THB',
//   'TJS',
//   'TMT',
//   'TND',
//   'TOP',
//   'TRY',
//   'TTD',
//   'TWD',
//   'TZS',
//   'UAH',
//   'UGX',
//   'USN',
//   'USS',
//   'UYI',
//   'UYU',
//   'UZS',
//   'VEF',
//   'VND',
//   'VUV',
//   'WST',
//   'XAF',
//   'XAG',
//   'XAU',
//   'XBA',
//   'XBB',
//   'XBC',
//   'XBD',
//   'XCD',
//   'XDR',
//   'XOF',
//   'XPD',
//   'XPF',
//   'XPT',
//   'XTS',
//   'XXX',
//   'YER',
//   'ZAR',
//   'ZMW',
// ];

export default function verifyCurrencyCode(code) {
  return currencyCodes.indexOf(code) > 0;
}
