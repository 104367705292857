import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Auth, API } from 'aws-amplify';
import { getAccountsByCategory, putAccount } from '../../../redux/reducers/data';
import { setMessage } from '../../../redux/actions/message';
import { decodeObjectFromLocalStorage, saveObjectToLocalStorage } from '../../../misc/localStorageOps';
import MiniSpinner from '../../../misc/MiniSpinner';

// this is the page to which the openbanking provider redirects after the user has authorised the app
// there are two use cases now:
// 1. User is adding a new account (addAccountState in localStorage is present)
// 2. User is refreshing access to an existing account (renewAccountAccess in localStorage is present)
export default function AddAccountCallbackReceiver() {
  // get store from state, unpack it and replace store
  // get addAccountState from state, unpack it and navigate to AddAccount (tbd.)
  const navigate = useNavigate();
  const { i18n } = useTranslation();

  const dispatch = useDispatch();

  useEffect(() => {
    async function runUseEffect() {
      // if there is addAccountState in localStorage, then it is use case 1
      const addAccountState = await decodeObjectFromLocalStorage('addAccountState');
      // console.info('AddAccountCallbackReceiver: addAccountState', addAccountState);
      if (addAccountState) {
        const updatedState = {
          ...addAccountState,
          displayedStep: addAccountState.displayedStep + 1, // move displayedStep to the next step
          // Dashboard will not change displayedStep when it receives addModeState (Dashboard also must handle browser back button from WebForm, in which case it needs to go back to the previous step)
          doNotChangeDisplayedStep: true,
        };
        updatedState.workflowStepStatus[addAccountState.displayedStep] = true; // update workflowStepStatus of the current step
        // console.log('navigating to /:lang:/app/dashboard with state ', { addAccountState: updatedState }, 'in localStorage');
        saveObjectToLocalStorage(updatedState, 'addAccountState').then(() => {
          navigate(`/${i18n.language}/app/dashboard`);
        });
      }

      // if there is renewAccountAccess in localStorage, then it is use case 2
      const renewAccountAccess = JSON.parse(localStorage.getItem('renewAccountAccess'));
      console.log('AddAccountCallbackReceiver: renewAccountAccess', renewAccountAccess);
      if (renewAccountAccess) {
        // console.log('navigating to /:lang:/app/dashboard with state ', { renewAccountAccess }, 'in localStorage');
        // because GC creates new End-Aser Agreement, new Requisition and new providerAccountIds, we must update "our" accounts before proceeding to Dashboard
        // (FA only updates its "requisition", so the providerAccountIds remain unchanged - we only need to do this for GC)
        if (renewAccountAccess.reconcileAccounts) {
          // console.log('AddAccountCallbackReceiver: reconcileAccounts flag detected, started GC account reconciliation.');
          const session = await Auth.currentSession();

          // need to load deposit accounts into state and get provider account details from backend
          const promises1 = [
            dispatch(getAccountsByCategory('deposits', true)),
            // using a dummy country code of GC to indicate to backend that we want to hit the GC endpoint
            API.get('myAPI', `deposits/processids/${renewAccountAccess.processId}?country=GC`, {
              body: null,
              headers: {
                'Content-Type': 'application/json',
                Authorization: session.idToken.jwtToken,
              },
            }),
          ];

          const [outputDispatch, result] = await Promise.all(promises1);
          // console.log('AddAccountCallbackReceiver: GC account reconciliation result:', result);
          // console.log('AddAccountCallbackReceiver: dispatch accounts:', outputDispatch);

          // returned object is { accounts: [], providerAccessId } // the latter being either bankConnectionId for finapi or requisitionId for gocardless

          // we need to update the accounts in the store with the new accounts
          const promises = [];
          const depositAccounts = outputDispatch.payload.accounts;
          const depositAccountsGC = depositAccounts.length > 0 ? depositAccounts.filter((account) => Object.keys(account.tags?.provider?.gocardless || {}).length > 0) : [];
          // console.log('AddAccountCallbackReceiver: GC accounts in state:', depositAccountsGC);

          result.accounts.forEach((providerAccountObject) => {
            // console.log('AddAccountCallbackReceiver: processing provider account:', providerAccountObject);
            // find the account in the store by iban
            const account = depositAccountsGC.find((acc) => acc.iban === providerAccountObject.iban);
            if (!account) return; // if account not found, skip to next account (user may have deleted this one)

            // console.log('AddAccountCallbackReceiver: found account:', account);
            // update new requisitionId and new accountId
            promises.push(
              dispatch(
                putAccount({
                  data: {
                    ...account,
                    tags: {
                      ...account.tags,
                      provider: {
                        finapi: {},
                        gocardless: {
                          ...account.tags.provider.gocardless, // old providerInstitutionId
                          providerAccessId: result.providerAccessId, // new requisitionId
                          providerAccountId: providerAccountObject.providerAccountId, // new accountId
                        },
                      },
                    },
                  },
                  category: 'deposits',
                }),
              ),
            );
          });

          try {
            if (promises.length > 0) {
              await Promise.all(promises);
              // console.log('AddAccountCallbackReceiver: accounts updated in store');
              navigate(`/${i18n.language}/app/dashboard`, { state: { renewAccountAccess } });
            } else {
              // display dialog to user saying "we have received ibans A, B from provider, but we could not find any accounts with these ibans in our system (there are only C, D)"
              // this should not happen, as the ibans should not change
              // console.log('AddAccountCallbackReceiver: no existing accounts found with ibans maching those provided by provider');

              const event = new CustomEvent('setAlert', {
                detail: {
                  id: 'renewAccountAccessNoIbansFound',
                  args: {
                    providerIbans: result.accounts.map((acc) => acc.iban).join(', '),
                    monestryIbans: depositAccountsGC.map((acc) => acc.iban).join(', '),
                  },
                  caller: 'AddAccountCallbackReceiver',
                  callbackOk: () => {
                    localStorage.removeItem('renewAccountAccess');

                    // navigate back to dashboard in "regular mode", to avoid showing the "renewal success" message
                    navigate(`/${i18n.language}/app/dashboard`);
                  },
                },
              });
              window.dispatchEvent(event);
            }
          } catch (error) {
            // if this fails, tell user to contact support
            console.error('Error updating accounts in store', error);
            localStorage.removeItem('renewAccountAccess');
            dispatch(setMessage({ message: 'renewAccountAccessError' }));

            // navigate back to dashboard in "regular mode", to avoid showing the "renewal success" message
            navigate(`/${i18n.language}/app/dashboard`);
          }
        } else {
          // if there is no reconcileAccounts flag (this would make it a FinApi case), just navigate to Dashboard with renewAccountAccess
          navigate(`/${i18n.language}/app/dashboard`, { state: { renewAccountAccess } });
        }
      }
    }

    runUseEffect();
  }, []);

  return (
    <div className="absolute inset-0 flex items-center justify-center">
      <MiniSpinner className="w-10 h-10 text-gray-400 animate-spin" />
    </div>
  );
}
