/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-restricted-syntax */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { CURRENT_NET_WORTH, CHANGE_IN_VALUE, PREVIOUS_VALUE } from './constants';

import SumTile from './SumTile';
import SumTileExpandable from './SumTileExpandable';

// ATTENTION: this component is used, in a modified version, in the sharedReportViewer - if you change it, make sure to check that component as well
export default function KPIs({ baseCurrency, setKpiReportVisible, calendar, setCalendarState, setAccountDetails, isViewer }) {
  const [expandedMobile, setExpandedMobile] = useState(null);

  return (
    <>
      {/* ABOVE XS */}
      <div
        aria-labelledby="KPIs"
        id="net-worth-kpis"
        className="hidden xs:grid xs:grid-cols-3 lg:grid-cols-1 justify-items-center items-center content-center gap-2 md:gap-4 gap-y-2 md:gap-y-4 lg:col-span-2"
      >
        <SumTile id={CURRENT_NET_WORTH} baseCurrency={baseCurrency} setKpiReportVisible={setKpiReportVisible} isViewer={isViewer} />
        <SumTileExpandable id={CHANGE_IN_VALUE} setKpiReportVisible={setKpiReportVisible} setAccountDetails={setAccountDetails} baseCurrency={baseCurrency} isViewer={isViewer} />
        <SumTileExpandable id={PREVIOUS_VALUE} calendarState={calendar} setCalendarState={setCalendarState} setKpiReportVisible={setKpiReportVisible} baseCurrency={baseCurrency} isViewer={isViewer} />
      </div>
      {/* BELOW XS */}
      {!expandedMobile ? (
        <div aria-labelledby="KPIs" id="net-worth-kpis" className="xs:hidden grid grid-cols-2 gap-4">
          <SumTile id={CURRENT_NET_WORTH} setKpiReportVisible={setKpiReportVisible} baseCurrency={baseCurrency} mode="button" onClick={() => setExpandedMobile(CHANGE_IN_VALUE)} isViewer={isViewer} />
          <SumTile id={CHANGE_IN_VALUE} setKpiReportVisible={setKpiReportVisible} baseCurrency={baseCurrency} mode="button" onClick={() => setExpandedMobile(CHANGE_IN_VALUE)} isViewer={isViewer} />
        </div>
      ) : (
        <div aria-labelledby="KPIs" id="net-worth-kpis" className="xs:hidden grid grid-cols-2 gap-4">
          <SumTile id={CURRENT_NET_WORTH} setKpiReportVisible={setKpiReportVisible} baseCurrency={baseCurrency} mode="button" onClick={() => setExpandedMobile(null)} isViewer={isViewer} />
          <div className="row-span-3">
            {expandedMobile === CHANGE_IN_VALUE ? (
              <SumTileExpandable
                id={CHANGE_IN_VALUE}
                setKpiReportVisible={setKpiReportVisible}
                baseCurrency={baseCurrency}
                mode="button"
                calendarState={calendar}
                setCalendarState={setCalendarState}
                setAccountDetails={setAccountDetails}
                noExpandButton
                isViewer={isViewer}
              />
            ) : (
              <SumTileExpandable
                id={PREVIOUS_VALUE}
                setKpiReportVisible={setKpiReportVisible}
                baseCurrency={baseCurrency}
                mode="button"
                calendarState={calendar}
                setCalendarState={setCalendarState}
                setAccountDetails={setAccountDetails}
                noExpandButton
                isViewer={isViewer}
              />
            )}
          </div>
          <SumTile
            id={CHANGE_IN_VALUE}
            setKpiReportVisible={setKpiReportVisible}
            baseCurrency={baseCurrency}
            mode="button"
            outline={expandedMobile === CHANGE_IN_VALUE}
            onClick={() => setExpandedMobile(CHANGE_IN_VALUE)}
            isViewer={isViewer}
          />
          <SumTile
            id={PREVIOUS_VALUE}
            setKpiReportVisible={setKpiReportVisible}
            baseCurrency={baseCurrency}
            mode="button"
            outline={expandedMobile === PREVIOUS_VALUE}
            onClick={() => setExpandedMobile(PREVIOUS_VALUE)}
            isViewer={isViewer}
          />
        </div>
      )}
    </>
  );
}
KPIs.propTypes = {
  baseCurrency: PropTypes.string.isRequired,
  setKpiReportVisible: PropTypes.func.isRequired,
  calendar: PropTypes.shape({
    visible: PropTypes.bool.isRequired,
    selectedDate: PropTypes.instanceOf(dayjs),
    callback: PropTypes.func,
  }).isRequired,
  setCalendarState: PropTypes.func.isRequired,
  setAccountDetails: PropTypes.func.isRequired,
  isViewer: PropTypes.bool,
};
KPIs.defaultProps = {
  isViewer: false,
};
