/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-no-bind */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { setSimulationBaseDateAndUpdateQuotes } from '../../redux/reducers/simulation';
import TooltipSlider from './SliderTooltip';
import getCurrentBreakpoint from '../../misc/getCurrentBreakpoint'; // adjust path
import '../../css/rcslider.css';

const utc = require('dayjs/plugin/utc');

dayjs.extend(utc);

export default function SliderComponent({ theme }) {
  // 'light' (to be displayed on blue background in Projects) or 'dark' (in Project Details on light background)
  const baseDate = useSelector((state) => state.simulation.baseDate);
  const sliderPreferences = useSelector((state) => state.user.profile.settings);
  // sliderState.baseDate is the date to which the slider is set (in epoch milliseconds UTC)
  const dispatch = useDispatch();

  const currentBreakpoint = getCurrentBreakpoint();

  const date = new Date(sliderPreferences.sliderStartDate || dayjs.utc().subtract(10, 'year').startOf('month')); // beginning date
  const maxDate = new Date(sliderPreferences.sliderEndDate || dayjs.utc().add(10, 'year').startOf('month')); // ending date
  date.setUTCMonth(0); // set to January
  date.setUTCDate(1); // set to 1st
  date.setUTCHours(0, 0, 0, 0); // set to midnight

  function getAxisLabelDistance() {
    if (currentBreakpoint === null) return 48;
    if (['sm', 'md', 'lg'].includes(currentBreakpoint)) return 24;
    return 12;
  }

  // takes store contents as input and converts it into a list of steps for the slider
  const stepsArray = [];
  let marksObject = {};
  const axisLabelDistance = getAxisLabelDistance();
  while (date <= maxDate) {
    if (date.getUTCDate() === 1 && date.getUTCMonth() === 0 && stepsArray.length % axisLabelDistance === 0) {
      marksObject = { ...marksObject, [Number(stepsArray.length)]: String(date.getUTCFullYear()) };
    }
    stepsArray.push(date.valueOf());
    date.setUTCMonth(date.getUTCMonth() + 1);
  }

  // need to store it locally to faciliate a slight delay before calculating the entire dataload
  // find index of baseDate in stepsArray and set sliderIndex to it
  const [sliderIndex, setSliderIndex] = useState(stepsArray.indexOf(baseDate.valueOf()));
  // TODO: turn slider holder into spinner when loading data

  function onSliderChange(value) {
    setSliderIndex(value); // update screen, but don't update store yet
    // triggers useEffect below which handles the timeout
  }

  const previousSliderIndex = React.useRef();
  const isFirstRender = React.useRef(true);

  // handle store updates
  useEffect(() => {
    // don't run on first render (only)
    if (isFirstRender.current) {
      isFirstRender.current = false;
      previousSliderIndex.current = sliderIndex; // set index to the initialised value so that we know if it changes for the code below
      return () => {};
    }

    if (previousSliderIndex.current === sliderIndex) return () => {};
    previousSliderIndex.current = sliderIndex;

    const timeoutId = setTimeout(() => {
      // dispatch(setSimulationBaseDate({ newDate: new Date(stepsArray[sliderIndex]) }));
      dispatch(setSimulationBaseDateAndUpdateQuotes({ newDate: new Date(stepsArray[sliderIndex]).valueOf() }));
    }, 600);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [sliderIndex]);

  // react to selector change
  useEffect(() => {
    const incomingSliderIndex = stepsArray.indexOf(baseDate.valueOf());
    if (sliderIndex !== incomingSliderIndex) setSliderIndex(incomingSliderIndex);
  }, [baseDate]);

  // step depends on the screen size
  function calculateSteps() {
    if (currentBreakpoint === 'xs' || currentBreakpoint === null) return 24;
    if (currentBreakpoint === 'sm') return 12;
    if (currentBreakpoint === 'md') return 3;
    return 1;
  }

  return (
    <div id="projects-slider-parent" className="px-4 text-xs">
      {/* style for slider elements set in css/rcslider.css */}
      <TooltipSlider
        value={sliderIndex}
        valuesArray={stepsArray}
        min={0}
        max={stepsArray.length}
        step={calculateSteps()}
        className={theme}
        tipProps={{ overlayClassName: theme }}
        dots={false}
        marks={marksObject}
        onChange={onSliderChange}
      />
    </div>
  );
}
SliderComponent.propTypes = {
  theme: PropTypes.oneOf(['light', 'dark']),
};
SliderComponent.defaultProps = {
  theme: 'light',
};
