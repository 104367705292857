/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Auth } from 'aws-amplify';
import { putProject } from '../../redux/actions/projects';

export default function Notes({ project }) {
  const { i18n, t } = useTranslation();
  const [user, setUser] = useState(null);
  const [commentField, setCommentField] = useState('');

  const dispatch = useDispatch();
  const comments = useSelector((state) => state.projects.find((p) => p.id === project.id).comments);

  useEffect(() => {
    async function getUser() {
      const returnObject = await Auth.currentAuthenticatedUser();
      setUser(returnObject && (returnObject.attributes?.preferred_username || returnObject.attributes?.email));
    }
    getUser();
  }, []);

  function handleCommentSubmit(e, text) {
    e.preventDefault();
    dispatch(putProject({
      ...project,
      comments: [...comments, {
        author: user,
        text,
        date: new Date(new Date().toUTCString()).valueOf(),
      }],
    }));
    setCommentField('');
  }

  function handleCommentDelete(e, commentIndex) {
    e.preventDefault();
    dispatch(putProject({
      ...project,
      comments: comments.filter((c, i) => i !== commentIndex),
    }));
  }

  return (
    <section aria-labelledby="notes-title">
      <div className="bg-white shadow sm:overflow-hidden sm:rounded-lg">
        <div className="divide-y divide-gray-200">
          <div className="sm:px-6">
            <ul className="space-y-8">
              {comments.map((item) => (
                <li key={item.date} className="first:pt-6 last:pb-6">
                  <div className="flex space-x-3">
                    <div className="flex-shrink-0">
                      <span className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-brandDarkBlue-500">
                        <span className="text-sm font-medium leading-none text-white">{item.author?.substring(0, 1).toUpperCase()}</span>
                      </span>
                    </div>
                    <div>
                      <div className="text-sm">
                        <a href="/" className="font-medium text-gray-900">{item.author}</a>
                      </div>
                      <div className="mt-1 text-sm text-gray-700">
                        <p>{item.text}</p>
                      </div>
                      <div className="mt-2 space-x-2 text-sm">
                        <span className="font-medium text-gray-500">{new Date(item.date).toLocaleDateString('de', { timeZone: 'UTC' })}</span>
                        <span className="font-medium text-gray-500">·</span>
                        <button
                          type="button"
                          className="font-medium text-gray-900 hover:underline"
                          onClick={(e) => handleCommentDelete(e, comments.indexOf(item))}
                        >
                          Delete

                        </button>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="bg-gray-50 px-4 py-6 sm:px-6">
          <div className="flex space-x-3">
            <div className="flex-shrink-0">
              <span className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-brandDarkBlue-500">
                <span className="text-sm font-medium leading-none text-white">{(user || '').substring(0, 1).toUpperCase()}</span>
              </span>
            </div>
            <div className="min-w-0 flex-1">
              <form
                onSubmit={(e) => handleCommentSubmit(e, e.target.comment.value)}
              >
                <div>
                  <label htmlFor="comment" className="sr-only">About</label>
                  <textarea
                    id="comment"
                    name="comment"
                    value={commentField}
                    onChange={(e) => setCommentField(e.target.value)}
                    rows="3"
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-brandBlue-500 focus:ring-brandBlue-500 sm:text-sm"
                    placeholder="Add a note"
                  />
                </div>
                <div className="mt-3 flex items-center justify-right">
                  <button type="submit" className="inline-flex items-center justify-center rounded-md border border-transparent bg-brandBlue-500 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-brandBlue-600 focus:outline-none focus:ring-2 focus:ring-brandBlue-400 focus:ring-offset-2">
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
Notes.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  project: PropTypes.objectOf(PropTypes.any).isRequired,
};
