/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable max-len */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFieldArray } from 'react-hook-form';
import { PlusIcon } from '@heroicons/react/20/solid';
import ToolTip from './ToolTip';

/**
 * Renders an array of options with input fields for name and value.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.label - The label for the option array (displayed above array to user).
 * @param {string} [props.tooltip] - The tooltip information for the option array (displayed as info icon + hover).
 * @param {string} [props.currency] - The currency symbol for the value input fields (displayed after the value fields as ISO code).
 * @param {Object} props.control - The control object from react-hook-form.
 * @param {string} props.name - The name of the option array (i.e. the array field name in the form state).
 * @param {string} [props.namePlaceholder] - placeholder for the name input field (default: 'Name').
 * @param {Object} props.formObject - The form object containing the register function (whatever useForm returns).
 * @returns {JSX.Element} The rendered OptionArray component.
 */
export default function OptionArray({ label, tooltip, currency, control, name, namePlaceholder, formObject }) {
  const { register, unregister } = formObject;
  const { fields, append, remove } = useFieldArray({ control, name });

  useEffect(() => () => console.log('unmounting', name), []);

  return (
    <fieldset>
      <legend className="flex text-sm font-medium leading-6 text-gray-900">
        {label}
        {tooltip && <ToolTip info={tooltip} />}
      </legend>
      <div className="mt-2 -space-y-px rounded-md shadow-sm">
        {fields.map((row, index) => (
          <div className="relative flex -space-x-px items-center" key={(row.name || '').replace(/[^a-zA-Z0-9]/g, '')}>
            <div className="w-1/2 min-w-0 flex-1">
              <label htmlFor={`${name}.${index}.name`} className="sr-only">
                Name
              </label>
              <input
                type="text"
                className={`relative block w-full rounded-none border-0 bg-transparent py-1.5 bg-white text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-brandBlue-600 sm:text-sm sm:leading-6
                ${index === 0 ? 'rounded-tl-md' : ''} ${index === fields.length - 1 ? 'rounded-bl-md' : ''} `}
                placeholder={namePlaceholder || 'Name'}
                {...register(`${name}.${index}.name`, { defaultValue: 'asset' })}
              />
            </div>
            <div className="min-w-0 flex-1">
              <label htmlFor={`${name}.${index}.value`} className="sr-only">
                Value
              </label>
              <input
                type="text"
                className={`relative block w-full rounded-none border-0 bg-transparent py-1.5 pr-6 bg-white  text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-brandBlue-600 sm:text-sm sm:leading-6
                ${index === 0 ? 'rounded-tr-md' : ''} ${index === fields.length - 1 ? 'rounded-br-md' : ''} `}
                placeholder="10000"
                {...register(`${name}.${index}.value`, { defaultValue: 10000, valueAsNumber: true, min: 0 })}
              />
            </div>
            <div className="flex items-center">
              <button
                type="button"
                className="z-10 ml-2 inline-flex items-center px-3 py-1.5 border border-gray-200 text-xs leading-4 font-medium rounded-md text-gray-300 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brandBlue-500"
                onClick={() => remove(index)}
              >
                X
              </button>
            </div>
            {currency && <span className="absolute right-0 pr-12 text-gray-400 text-sm">{currency}</span>}
          </div>
        ))}
      </div>
      <div className="flex items-center">
        <button
          type="button"
          className="mt-2 ml-1 inline-flex items-center px-2 py-1.5 border border-gray-200 text-xs leading-4 font-medium rounded-md text-gray-300 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brandBlue-500"
          onClick={() => append()}
        >
          <PlusIcon className="h-4 w-4" aria-hidden="true" />
        </button>
      </div>
    </fieldset>
  );
}
OptionArray.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  namePlaceholder: PropTypes.string,
  formObject: PropTypes.object.isRequired,
  currency: PropTypes.string,
};
OptionArray.defaultProps = {
  tooltip: null,
  currency: null,
  namePlaceholder: null,
};
