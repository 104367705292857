/* eslint-disable react/forbid-prop-types */
/* eslint-disable object-curly-newline */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Table from '../accountDetails/Table';

const tableLayout = [
  [
    { id: 'accountName', type: 'string', textAlign: 'text-left' },
    { id: 'category', type: 'string', textAlign: 'text-left', classNamesBody: 'font-normal text-xs' },
  ],
  [{ id: 'transactionType', type: 'string', textAlign: 'text-left' }],
  [
    { id: 'displayName', type: 'string', usesHeader: 'assetId' },
    { id: 'displaySymbol', type: 'string', hideHeaderLabel: true, classNamesBody: 'font-normal text-xs' },
  ],
  [{ id: 'deltaAmount', type: 'currency0Decimals', usesHeader: 'amount' }],
];

export default function OutputPane({ rawRebalancingResults, fxRates }) {
  const [tableState, setTableState] = useState({
    sortBy: 'date',
    sortDirectionAsc: true,
    colWidths: [null, null, null, null],
    tableHeight: 300,
  });

  const { t } = useTranslation();

  const presentableResults = rawRebalancingResults
    .filter(({ amount, targetAmount }) => amount !== targetAmount && Math.abs(targetAmount - amount) > 100)
    .map(({
      category, displayName, displaySymbol, amount, accountName, targetAmount, currency, amountAssetCurrency, ...rest
    }) => ({
      category,
      displayName,
      displaySymbol,
      deltaAmount: targetAmount - amount,
      deltaAmountAssetCurrency: (targetAmount - amount) / fxRates.find(({ currency: fxRateCurrency }) => fxRateCurrency === currency).rate,
      accountName,
      transactionType: (targetAmount - amount > 0 ? 'buy' : 'sell'),
      currency,
    }));

  // TO DO -- PER ACCOUNT
  return (
    <div className="flex flex-col items-center" id="rebalancing-output-mode">
      <h2 className="font-bold text-gray-900 text-lg xs:text-xl" aria-label="current allocation">{t('Required transactions')}</h2>
      <div>
        <Table
          data={presentableResults}
          tableLayout={tableLayout}
          tableState={tableState}
          setTableState={setTableState}
          groupByColumn="category"
        />
      </div>
    </div>
  );
}
OutputPane.propTypes = {
  rawRebalancingResults: PropTypes.arrayOf(PropTypes.object).isRequired,
  fxRates: PropTypes.arrayOf(PropTypes.object).isRequired,
};
