/* eslint-disable react/no-array-index-key */
/* eslint-disable react/forbid-prop-types */
import React, { useState, useLayoutEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid';
import ToolTipNoIcon from '../../elements/ToolTipNoIcon';

// generates a row (<tr>) of headers for the table layout definition passed as prop
export default function TableHeaders({ tableLayout, tableState, setTableState, hiddenColumns, setHiddenColumns }) {
  return (
    <>
      {tableLayout.map((columnsArray, index) => (
        // each HeaderCell contains between one and usually three columns, depending on how small the page is
        <HeaderCell
          key={index}
          columnsArray={columnsArray}
          tableState={tableState}
          setTableState={setTableState}
          setHiddenColumns={setHiddenColumns}
          firstLayoutColumn={index === 0}
          hidden={hiddenColumns.includes(columnsArray[0].id) ? 'hidden' : ''} // returns a string!
          classNamesHeader={columnsArray[0].classNamesHeader}
        />
      ))}
    </>
  );
}
TableHeaders.propTypes = {
  tableLayout: PropTypes.array.isRequired,
  tableState: PropTypes.object.isRequired,
  setTableState: PropTypes.func.isRequired,
  hiddenColumns: PropTypes.array.isRequired,
  setHiddenColumns: PropTypes.func.isRequired,
};

// generates the contents of a header cell (<th>)
function HeaderCell({ columnsArray, tableState, setTableState, firstLayoutColumn, setHiddenColumns, hidden, classNamesHeader }) {
  // as there is no possibility to check if the bottom-level descendant of an element is visible, we do it based on computed width
  // if the computed width is below 5px (it usually is 2px), we assume the column is empty and hide it
  const trRef = useRef();
  const headerCellId = columnsArray[0].id;

  // TODO if this has to change on window resize, this should also be triggered by a listener on window resize event
  useLayoutEffect(() => {
    // if any header cell has no visible children, add it to the hiddenColumns array (for it to be hidden)
    if (Array.from(trRef.current.children).every((el) => getComputedStyle(el).display === 'none')) {
      setHiddenColumns((prev) => [...prev, headerCellId]);
    }
  }, [trRef]);

  return (
    <th
      ref={trRef}
      // eslint-disable-next-line max-len
      className={`sticky table-cell top-0 pt-3.5 pb-2 text-sm font-semibold text-gray-900 border-b border-gray-300 bg-gray-50 bg-opacity-75 backdrop-blur backdrop-filter sm:pl-4 ${hidden} ${classNamesHeader} ${
        columnsArray[0].textAlign || 'text-left'
      }`}
    >
      {columnsArray.map((column) => {
        if (column.hideHeaderLabel) return null;
        return <HeaderLabel key={column.id} columnDef={column} tableState={tableState} setTableState={setTableState} firstLayoutColumn={firstLayoutColumn} />;
      })}
    </th>
  );
}
HeaderCell.propTypes = {
  columnsArray: PropTypes.array.isRequired,
  tableState: PropTypes.object.isRequired,
  setTableState: PropTypes.func.isRequired,
  firstLayoutColumn: PropTypes.bool.isRequired,
  setHiddenColumns: PropTypes.func.isRequired,
  hidden: PropTypes.string.isRequired,
  classNamesHeader: PropTypes.string,
};
HeaderCell.defaultProps = {
  classNamesHeader: '',
};

function HeaderLabel({ columnDef, tableState, setTableState, firstLayoutColumn }) {
  // tooltip = 'Click to sort by this column or reverse the sorting order.'
  const { t } = useTranslation('app');

  const tooltip = t([`accountDetails.columns.${columnDef.usesHeader || columnDef.id}.tooltip`, 'accountDetails.columns.standardTooltip']);
  const label = t([`accountDetails.columns.${columnDef.usesHeader || columnDef.id}.label`, `accountDetails.columns.${columnDef.usesHeader || columnDef.id}`, `${columnDef.usesHeader}`]);

  return (
    <ToolTipNoIcon className="mt-4" classNameOwn={`${columnDef.classNamesBoth || ''} ${columnDef.classNamesHeader || ''}`} info={tooltip}>
      {/* CAUTION: the useLayoutEffect relies on this div ^^ being hidden / not hidden to calculate column hiding logic */}
      <button
        type="button"
        id={columnDef.id}
        className={`group justify-between w-full flex ${firstLayoutColumn ? 'pl-2' : 'pl-3'} pr-4 pb-1`}
        onClick={() => setTableState({ ...tableState, sortBy: columnDef.id, sortDirectionAsc: !tableState.sortDirectionAsc })}
      >
        <div className="flex align-middle w-full">
          <span className="text-[11px] leading-tight xs:text-xs font-bold text-left uppercase tracking-wide mt-0.5 text-gray-900">{label}</span>
          <span className={`${tableState.sortBy === columnDef.id ? 'visible' : 'invisible'} ml-1 lg:ml-2 flex-none rounded text-gray-400 group-hover:visible group-focus:visible`}>
            {tableState.sortBy === columnDef.id && tableState.sortDirectionAsc ? <ChevronUpIcon className="h-5 w-5" aria-hidden="true" /> : <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />}
          </span>
        </div>
      </button>
    </ToolTipNoIcon>
  );
}
HeaderLabel.propTypes = {
  tableState: PropTypes.objectOf(PropTypes.any).isRequired,
  setTableState: PropTypes.func.isRequired,
  columnDef: PropTypes.object.isRequired,
  firstLayoutColumn: PropTypes.bool.isRequired,
};
HeaderLabel.defaultProps = {};
