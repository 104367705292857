/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  HomeIcon, BuildingLibraryIcon, CurrencyDollarIcon, ChartBarSquareIcon,
} from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';
import Button from './Button';

export default function mainSection2() {
  const { t, i18n } = useTranslation(['site']);
  const navigate = useNavigate();

  const allAssetClasses = [
    {
      id: 1,
      name: t('site:main.complete.sub1.title'),
      description: t('site:main.complete.sub1.body'),
      icon: HomeIcon,
    },
    {
      id: 2,
      name: t('site:main.complete.sub2.title'),
      description: t('site:main.complete.sub2.body'),
      icon: BuildingLibraryIcon,
    },
  ];
  const automaticUpdatesFeatures = [
    {
      id: 1,
      name: t('site:main.current.sub1.title'),
      description: t('site:main.current.sub1.body'),
      icon: CurrencyDollarIcon,
    },
    {
      id: 2,
      name: t('site:main.current.sub2.title'),
      description: t('site:main.current.sub2.body'),
      icon: ChartBarSquareIcon,
    },
  ];

  return (
    <>
      <div className="bg-white" data-testid="main-section1">
        <div className="max-w-7xl mx-auto py-16 px-4 sm:py-12 sm:px-6 lg:px-8">
          <div className="text-center">
            <h2 className="text-base font-semibold text-brandBlue-500 tracking-wide uppercase">{t('site:main.convenience.header')}</h2>
            <p className="mt-1 text-3xl font-black text-gray-900 sm:text-4xl sm:tracking-tight">
              {t('site:main.convenience.title')}
            </p>
            <p className="max-w-4xl mt-5 mx-auto text-xl text-gray-500">
              {t('site:main.convenience.subtitle')}
            </p>
          </div>
        </div>
      </div>
      <div className="relative bg-white pt-16 pb-32 overflow-hidden">
        <div className="relative">
          <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
            <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0">
              <div>
                <div className="mt-6">
                  <h2 className="text-3xl font-black tracking-tight text-gray-900">
                    {t('site:main.complete.title')}
                  </h2>
                  <p className="mt-4 text-lg text-gray-500">
                    {t('site:main.complete.body')}
                  </p>
                  <dl className="mt-10 space-y-10">
                    {allAssetClasses.map((item) => (
                      <div key={item.id} className="relative">
                        <dt>
                          <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-brandBlue-500 text-white">
                            <item.icon className="h-6 w-6" aria-hidden="true" />
                          </div>
                          <p aria-level="3" role="heading" className="ml-16 text-xl leading-6 font-semibold text-gray-900">{item.name}</p>
                        </dt>
                        <dd className="mt-2 ml-16 text-base text-gray-500">{item.description}</dd>
                      </div>
                    ))}
                  </dl>
                  <div className="mt-8">
                    <Button
                      text={t('site:general.findoutmore')}
                      onClick={() => navigate(`/${i18n.language}/features#one-glance`)}
                      noFrame
                      withAccent
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-12 sm:mt-16 lg:mt-0">
              <div className="pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
                <img
                  className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                  src="https://tailwindui.com/img/component-images/inbox-app-screenshot-1.jpg"
                  alt="Dashboard screenshot 1"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="mt-24">
          <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
            <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2">
              <div>
                <div className="mt-6">
                  <h2 className="text-3xl font-black tracking-tight text-gray-900">
                    {t('site:main.current.title')}
                  </h2>
                  <p className="mt-4 text-lg text-gray-500">
                    {t('site:main.current.body')}
                  </p>
                  <dl className="mt-10 space-y-10">
                    {automaticUpdatesFeatures.map((item) => (
                      <div key={item.id} className="relative">
                        <dt>
                          <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-brandBlue-500 text-white">
                            <item.icon className="h-6 w-6" aria-hidden="true" />
                          </div>
                          <p aria-level="3" role="heading" className="ml-16 text-xl leading-6 font-semibold text-gray-900">{item.name}</p>
                        </dt>
                        <dd className="mt-2 ml-16 text-base text-gray-500">{item.description}</dd>
                      </div>
                    ))}
                  </dl>
                  <div className="mt-8">
                    <Button
                      onClick={() => navigate(`/${i18n.language}/blog/guide/how-do-we-keep-your-financials-current#blog-title`)}
                      text={t('site:general.howdowedoit')}
                      withAccent
                      noFrame
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-12 sm:mt-16 lg:mt-0 lg:col-start-1">
              <div className="pr-4 -ml-48 sm:pr-6 md:-ml-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
                <img
                  className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:right-0 lg:h-full lg:w-auto lg:max-w-none"
                  src="https://tailwindui.com/img/component-images/inbox-app-screenshot-2.jpg"
                  alt="Current value screenshot 1"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
