/* eslint-disable no-alert */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { API, Auth } from 'aws-amplify';
import { Switch } from '@headlessui/react';
import { useForm, Controller } from 'react-hook-form';
import MiniSpinner from '../misc/MiniSpinner';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function Survey() {
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const {
    register, control, handleSubmit, formState: { errors },
  } = useForm({ mode: 'onBlur' });
  const navigate = useNavigate();

  // onSubmit determines what happens with form data onSubmit
  async function onSubmit(data) {
    let response;

    if (!submitted) {
      setLoading(true);
      // on submit update parent component state
      const session = await Auth.currentSession();
      const myInit = {
        body: data, // actual contents go here
        headers: {
          'Content-Type': 'application/json',
          Authorization: session.idToken.jwtToken,
        },
      };

      try {
        response = await API.post('myAPI', 'misc/survey', myInit);
        setLoading(false);
        setSubmitted(true);
        window.alert('Thanks for your help! Your feedback has been submitted.');
      } catch (error) {
        console.log('Error while accessing API', error);
        window.alert('Error: we have not been able to submit your survey.');
        setLoading(false);
      }
    }

    return response;
  }

  return (
    <div className="bg-white">
      <div className="relative bg-gray-50 pt-4 pb-20 px-4 sm:px-6 lg:pb-28 lg:px-8">
        <div className="absolute inset-0">
          <div className="bg-white h-1/3 sm:h-[51%]" />
        </div>
        <div className="relative max-w-7xl mx-auto">
          <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
            <div className="text-center">
              <h2 className="text-base font-semibold text-brandBlue-600 tracking-wide uppercase">Feedback</h2>
              <p className="mt-1 text-4xl font-black text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">
                What did you think?
              </p>
              <p className="max-w-xl mt-5 mx-auto text-xl text-gray-500">
                Would you mind answering a few questions about your experience today?
              </p>
            </div>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mt-6 max-w-lg mx-auto grid gap-5 lg:grid-cols-2 lg:max-w-none">
              <div className="flex flex-col rounded-lg shadow-lg overflow-hidden p-8 bg-white space-y-10">
                <div>
                  <label htmlFor="about" className="block font-medium text-base text-gray-700 leading-5 pb-2">
                    1. After what you have seen today, what three words would you use to describe Monestry?
                  </label>
                  <div className="mt-1">
                    <textarea
                      id="threeWords"
                      name="threeWords"
                      rows={2}
                      className="shadow-sm focus:ring-brandBlue-400 focus:border-brandBlue-500 block w-full sm:text-sm border border-gray-300 rounded-md"
                      defaultValue=""
                      {...register('threeWords', {
                        required: { value: true, message: 'Please enter something.' },
                        maxLength: { value: 200, message: 'If you have a longer comment, please use the box in question 5.' },
                      })}
                    />
                    <p className="mt-2 text-sm text-gray-500">Enter any words that spontaneously come to your mind.</p>
                    <div className="pt-2 sm:text-sm text-brandRed-500">
                      {' '}
                      {errors.threeWords && <span>{errors.threeWords.message}</span>}
                    </div>
                  </div>
                </div>
                <div>
                  <label htmlFor="about" className="block font-medium text-base text-gray-700 leading-5 pb-4">
                    2. Was is easy to find your way around the app?
                  </label>
                  <Controller
                    control={control}
                    name="easyway"
                    render={({ field }) => (
                      <Switch.Group as="div" className="flex items-center justify-center w-full">
                        <Switch.Label as="span" className="mr-3">
                          <span className="text-sm font-medium text-gray-900">Yes</span>
                        </Switch.Label>
                        <Switch
                          {...field}
                          checked={field.value}
                          onChange={field.onChange}
                          className={classNames(
                            field.value ? 'bg-brandBlue-500' : 'bg-gray-200',
                            'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
                          )}
                        >
                          <span
                            aria-hidden="true"
                            className={classNames(
                              field.value ? 'translate-x-5' : 'translate-x-0',
                              'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
                            )}
                          />
                        </Switch>
                        <Switch.Label as="span" className="ml-3">
                          <span className="text-sm font-medium text-gray-900">No</span>
                        </Switch.Label>
                      </Switch.Group>
                    )}
                  />
                </div>
                <div>
                  <label htmlFor="about" className="block font-medium text-base text-gray-700 leading-5 pb-4">
                    3. Did it feel natural / intuitive to move around the app?
                  </label>
                  <Controller
                    control={control}
                    name="intuitive"
                    render={({ field }) => (
                      <Switch.Group as="div" className="flex items-center justify-center w-full">
                        <Switch.Label as="span" className="mr-3">
                          <span className="text-sm font-medium text-gray-900">Yes</span>
                        </Switch.Label>
                        <Switch
                          {...field}
                          checked={field.value}
                          onChange={field.onChange}
                          className={classNames(
                            field.value ? 'bg-brandBlue-500' : 'bg-gray-200',
                            'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
                          )}
                        >
                          <span
                            aria-hidden="true"
                            className={classNames(
                              field.value ? 'translate-x-5' : 'translate-x-0',
                              'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
                            )}
                          />
                        </Switch>
                        <Switch.Label as="span" className="ml-3">
                          <span className="text-sm font-medium text-gray-900">No</span>
                        </Switch.Label>
                      </Switch.Group>
                    )}
                  />
                </div>
              </div>
              <div className="flex flex-col rounded-lg shadow-lg overflow-hidden p-8 bg-white space-y-10">
                <div>
                  <div className="block font-medium text-base text-gray-700 leading-5 pb-4">
                    4. Did the app appear...
                  </div>
                  <div className="relative pt-1 flex flex-col space-y-6">
                    <div className="grid grid-cols-12 content-center items-center justify-items-center">
                      <div className="col-span-1" />
                      <label htmlFor="clutteredSpacious" className="form-label uppercase text-xs leading-loose text-gray-500 col-span-2">Cluttered</label>
                      <input
                        type="range"
                        className="form-range appearance-none w-4/5 h-1 p-0 bg-gray-200 text-brandBlue-500 col-span-6 focus:outline-none focus:ring-0 focus:shadow-none"
                        min="0"
                        max="3"
                        step="1"
                        id="clutteredSpacious"
                        {...register('clutteredSpacious')}
                      />
                      <label htmlFor="clutteredSpacious" className="form-label uppercase text-xs leading-loose text-gray-500 col-span-2">Spacious</label>
                      <div className="col-span-1" />
                    </div>
                    <div className="grid grid-cols-12 content-center items-center justify-items-center">
                      <div className="col-span-1" />
                      <label htmlFor="traditionalModern" className="form-label uppercase text-xs leading-loose text-gray-500 col-span-2">Traditional</label>
                      <input
                        type="range"
                        className="form-range appearance-none w-4/5 h-1 p-0 bg-gray-200 text-brandBlue-500 col-span-6 focus:outline-none focus:ring-0 focus:shadow-none"
                        min="0"
                        max="3"
                        step="1"
                        id="traditionalModern"
                        {...register('traditionalModern')}
                      />
                      <label htmlFor="traditionalModern" className="form-label uppercase text-xs leading-loose text-gray-500 col-span-2">Modern</label>
                      <div className="col-span-1" />
                    </div>
                    <div className="grid grid-cols-12 content-center items-center justify-items-center">
                      <div className="col-span-1" />
                      <label htmlFor="simpleComplex" className="form-label uppercase text-xs leading-loose text-gray-500 col-span-2">Simple</label>
                      <input
                        type="range"
                        className="form-range appearance-none w-4/5 h-1 p-0 bg-gray-200 text-brandBlue-500 col-span-6 focus:outline-none focus:ring-0 focus:shadow-none"
                        min="0"
                        max="3"
                        step="1"
                        id="simpleComplex"
                        {...register('simpleComplex')}
                      />
                      <label htmlFor="simpleComplex" className="form-label uppercase text-xs leading-loose text-gray-500 col-span-2">Complex</label>
                      <div className="col-span-1" />
                    </div>
                    <div className="grid grid-cols-12 content-center items-center justify-items-center">
                      <div className="col-span-1" />
                      <label htmlFor="conventionalInnovative" className="form-label uppercase text-xs leading-loose text-gray-500 col-span-2">Conventional</label>
                      <input
                        type="range"
                        className="form-range appearance-none w-4/5 h-1 p-0 bg-gray-200 text-brandBlue-500 col-span-6 focus:outline-none focus:ring-0 focus:shadow-none"
                        min="0"
                        max="3"
                        step="1"
                        id="conventionalInnovative"
                        {...register('conventionalInnovative')}
                      />
                      <label htmlFor="conventionalInnovative" className="form-label uppercase text-xs leading-loose text-gray-500 col-span-2">Innovative</label>
                      <div className="col-span-1" />
                    </div>
                    <div className="grid grid-cols-12 content-center items-center justify-items-center">
                      <div className="col-span-1" />
                      <label htmlFor="dubiousTrustworthy" className="form-label uppercase text-xs leading-loose text-gray-500 col-span-2">Dubious</label>
                      <input
                        type="range"
                        className="form-range appearance-none w-4/5 h-1 p-0 bg-gray-200 text-brandBlue-500 col-span-6 focus:outline-none focus:ring-0 focus:shadow-none"
                        min="0"
                        max="3"
                        step="1"
                        id="dubiousTrustworthy"
                        {...register('dubiousTrustworthy')}
                      />
                      <label htmlFor="dubiousTrustworthy" className="form-label uppercase text-xs leading-loose text-gray-500 col-span-2">Trustworthy</label>
                      <div className="col-span-1" />
                    </div>
                    <div className="grid grid-cols-12 content-center items-center justify-items-center">
                      <div className="col-span-1" />
                      <label htmlFor="amateurishProfessional" className="form-label uppercase text-xs leading-loose text-gray-500 col-span-2">Amateurish</label>
                      <input
                        type="range"
                        className="form-range appearance-none w-4/5 h-1 p-0 bg-gray-200 text-brandBlue-500 col-span-6 focus:outline-none focus:ring-0 focus:shadow-none"
                        min="0"
                        max="3"
                        step="1"
                        id="amateurishProfessional"
                        {...register('amateurishProfessional')}
                      />
                      <label htmlFor="amateurishProfessional" className="form-label uppercase text-xs leading-loose text-gray-500 col-span-2">Professional</label>
                      <div className="col-span-1" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="rounded-lg shadow-lg overflow-hidden p-8 mt-6 bg-white gap-6">
              <label htmlFor="about" className="block font-medium text-base text-gray-700 leading-5 pb-2">
                (optional) 5. You may have now a better idea on how the finished product might look like. Is there anything the app may be missing? Do you have any other comments?
              </label>
              <div className="mt-1">
                <textarea
                  id="comment"
                  name="comment"
                  rows={10}
                  className="shadow-sm focus:ring-brandBlue-400 focus:border-brandBlue-500 block w-full sm:text-sm border border-gray-300 rounded-md"
                  defaultValue=""
                  {...register('comment', {
                    maxLength: { value: 5000, message: 'If you have a longer comment, please call Piotr :-).' },
                  })}
                />
              </div>
            </div>
            <div className="flex justify-end rounded-lg shadow-lg overflow-hidden p-6 bg-white mt-6">
              <button
                type="button"
                onClick={() => navigate(-1)}
                className="inline-flex items-center px-4 py-2 mr-4 border border-gray-300 text-sm font-medium rounded-md shadow-sm text-gray-400 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brandBlue-400"
              >
                Cancel
              </button>
              {loading
                ? (
                  <div className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-brandBlue-500 hover:bg-brandBlue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brandBlue-400">
                    <MiniSpinner className="text-white h-4 w-4 mx-2 animate-spin" />
                  </div>
                ) : (
                  <input
                    type="submit"
                    className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-brandBlue-500 hover:bg-brandBlue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brandBlue-400"
                    value="Send"
                  />
                )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
