/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import FullPageOverlayContainer from '../../elements/FullPageOverlayContainer';
import Preferences from './Preferences';
import Planning from './Planning';
import Account from './Account';
import Subscription from './Subscription';

export default function Settings({ closeCallback, openOnTab, calendarState, setCalendarState, setSettings }) {
  const { t } = useTranslation('app', { keyPrefix: 'settings' });

  const tabs = [
    { id: 'preferences', name: t('preferences.header'), Component: Preferences },
    { id: 'planning', name: t('simulations.header'), Component: Planning },
    { id: 'account', name: t('account.header'), Component: Account },
  ];
  // add subscription tab only if this is not a mobile app
  if (!window.mobileApp) tabs.push({ id: 'subscription', name: t('billing.header'), Component: Subscription });

  // open tab whose id is passed via openOnTab prop; if nothing found, open first tab
  const [selectedTab, setSelectedTab] = useState(tabs.find((tab) => tab.id === openOnTab) || tabs[0]);
  // holds the entire settings object
  const [settingsData, setSettingsData] = useState(null);
  // used to indicate to users that their settings have not been saved
  const [isDirty, setIsDirty] = useState(false);

  function updateSettingsData(updatedData) {
    setSettingsData({ ...settingsData, ...updatedData });
  }

  function handleCloseSettings(arg) {
    if (!arg && isDirty) {
      window.dispatchEvent(
        new CustomEvent('setAlert', {
          detail: {
            id: 'aboutToLoseData',
            caller: 'Planning',
            callbackOk: () => {
              setSettings(false);
            },
          },
        }),
      );
    } else if (!arg) setSettings(false);
  }

  return (
    <FullPageOverlayContainer fixedHeight closeCallback={() => handleCloseSettings(false)}>
      <div id="settings-window" className="p-4 sm:px-0 sm:py-8">
        {/* Settings title + search bar */}
        <div className="mb-8 flex flex-col-reverse sm:flex-row sm:justify-between sm:items-end">
          <h1 className="mt-6 sm:mt-0 text-2xl sm:text-4xl font-bold text-gray-900">{t('Settings')}</h1>
        </div>
        <div className="lg:hidden">
          <label htmlFor="selected-tab" className="sr-only">
            {t('Select a tab')}
          </label>
          <select
            id="selected-tab"
            name="selected-tab"
            className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-purple-500 focus:outline-none focus:ring-purple-500 sm:text-sm"
            defaultValue={tabs.find((tab) => tab.id === selectedTab.id).name}
            onChange={(e) => setSelectedTab(tabs.find((tab) => tab.name === e.target.value))}
          >
            {tabs.map((tab) => (
              <option key={tab.id}>{tab.name}</option>
            ))}
          </select>
        </div>
        <div className="hidden lg:block">
          <div className="border-b border-gray-300">
            <nav className="-mb-px flex space-x-8">
              {tabs.map((tab) => (
                <button
                  key={tab.id}
                  id={`settings-tab-${tab.id}`}
                  type="button"
                  onClick={() => setSelectedTab(tab)}
                  className={`
                    ${tab.id === selectedTab.id ? 'border-brandBlue-500 text-brandBlue-600' : 'border-transparent text-gray-500 hover:border-gray-400 hover:text-gray-700'}
                    'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm',
                  `}
                >
                  {tab.name}
                </button>
              ))}
            </nav>
          </div>
        </div>
        <div className="pt-8">
          <selectedTab.Component updateSettingsData={updateSettingsData} calendarState={calendarState} setCalendarState={setCalendarState} setSettings={handleCloseSettings} setIsDirty={setIsDirty} />
        </div>
      </div>
    </FullPageOverlayContainer>
  );
}
Settings.propTypes = {
  closeCallback: PropTypes.func,
  openOnTab: PropTypes.string,
  calendarState: PropTypes.shape({
    visible: PropTypes.bool,
    callback: PropTypes.func,
  }),
  setCalendarState: PropTypes.func,
  setSettings: PropTypes.func.isRequired,
};
Settings.defaultProps = {
  closeCallback: () => {},
  openOnTab: 'Preferences',
  calendarState: {},
  setCalendarState: () => {},
};
