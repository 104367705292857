import React from 'react';

const steps = [
  {
    type: 'intro',
    id: 'ADT-intro-2',
    steps: [
      {
        position: 'center',
        content: () => (
          <p>
            Bei Immobilien sind zwei Arten von Informationen wichtig:
            {' '}
            <strong>Kauf- &amp; Verkaufstransaktionen</strong>
            {' '}
            sowie
            {' '}
            <strong>Wertänderungen.</strong>
            .
          </p>
        ),
      },
      {
        selector: '#button-group-two-views',
        content: () => (
          <p>
            Verwende
            {' '}
            <strong>diese Buttons</strong>
            {' '}
            um sich zwischen den beiden Ansichten zu bewegen. Es gibt eine unterschiedliche Spreadsheets für jede Ansicht.
          </p>
        ),
      },
    ],
  },
];

export default steps;
