/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { nanoid } from 'nanoid';
import { postData } from '../../redux/reducers/data';
import { setMessage } from '../../redux/actions/message';

export default function CopyToProject({ transaction, signalHandleSubmit, setSignalHandleSubmit }) {
  CopyToProject.propTypes = {
    transaction: PropTypes.object.isRequired,
    signalHandleSubmit: PropTypes.bool.isRequired,
    setSignalHandleSubmit: PropTypes.func.isRequired,
  };
  const { t } = useTranslation('app', { keyPrefix: 'accountDetails.splitMenu' });

  const [copyToProjectId, setCopyToProjectId] = useState('');
  const [loading, setLoading] = useState(false);
  const projects = useSelector((state) => state.projects);

  const dispatch = useDispatch();

  useEffect(() => {
    async function executeEffect() {
      try {
        setLoading(true);
        await dispatch(
          postData({
            data: [
              {
                ...transaction,
                projectId: copyToProjectId.id,
                id: nanoid(),
                importFlag: 'post',
              },
            ],
            category: transaction.category,
            accountId: transaction.accountId,
          }),
        );
        dispatch(setMessage('transactionCreatedSuccess'));
        window.dispatchEvent(
          new CustomEvent('setDialog', {}),
        );
      } catch (error) {
        dispatch(setMessage('transactionCreatedError'));
        setLoading(false);
      }
    }

    if (signalHandleSubmit) {
      executeEffect();
    }
  }, [signalHandleSubmit]);

  return (
    <div className="mt-2">
      <p className="text-sm text-gray-500">{t('copyAsSimulatedInfo')}</p>
      <div className="mt-4">
        <label htmlFor="projectId" className="flex text-sm font-medium text-gray-700">
          {t('pick')}
        </label>
        <select
          id="projectId"
          name="projectId"
          value={copyToProjectId?.id}
          onChange={(e) => setCopyToProjectId(projects.find((p) => p.id === e.target.value))}
          className="mt-2 shadow-sm focus:ring-brandBlue-400 focus:border-brandBlue-400 block w-full sm:text-sm border-gray-300 rounded-md"
        >
          {[{ id: null, name: '' }].concat(projects).map((p) => (
            <option value={p?.id || null}>{p?.name || ''}</option>
          ))}
        </select>
      </div>
    </div>
  );
}
