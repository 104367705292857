/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import { useDraggable } from '@dnd-kit/core';
import { ViewColumnsIcon } from '@heroicons/react/24/outline';

// 'obj' is the file column object from the fileObject.headers array { id: '...', label: '...', description: '...', bgColour: '...' }
export default function FileColumn({ obj, previewState, setPreviewState }) {
  // hook for drag-and-drop
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: `${obj.id}`,
  });

  // this is for drag-and-drop
  const style = transform
    ? {
      transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
    }
    : undefined;

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <button type="button" ref={setNodeRef} style={style} {...listeners} {...attributes} className="group" id={`file-${obj.id}`}>
      <div key={obj.id} className="relative flex shadow rounded-md">
        <div className={`${obj.bgColour} shrink-0 basis-2 rounded-l-md`}> </div>
        <div className="flex items-center justify-between border-t border-r border-b border-gray-200 hover:border-gray-400 bg-white rounded-r-md">
          <div className="px-4 py-2 text-sm">
            <span className="text-gray-900 font-medium whitespace-nowrap">{obj.label}</span>
            <p className="text-gray-500 text-xs whitespace-nowrap">{obj.description}</p>
          </div>
          <div className="pr-2 pt-1">
            {/* desktop version for above LG */}
            <div
              id={obj.id}
              // eslint-disable-next-line max-len
              className="bg-white inline-flex items-center justify-center text-gray-400 rounded-full bg-transparent focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brandBlue-400"
              onPointerEnter={(e) => {
                setPreviewState({
                  clickX: e.clientX,
                  clickY: e.clientY,
                  targetId: obj.id,
                });
              }}
              onPointerLeave={(e) => {
                setPreviewState(null);
              }}
            >
              <span className="sr-only">File preview</span>
              <ViewColumnsIcon className="w-5 h-5" aria-hidden="true" />
            </div>
          </div>
        </div>
      </div>
    </button>
  );
}
FileColumn.propTypes = {
  obj: PropTypes.shape({
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    bgColour: PropTypes.string.isRequired,
  }).isRequired,
  setPreviewState: PropTypes.func.isRequired,
  previewState: PropTypes.shape({
    clickX: PropTypes.number,
    clickY: PropTypes.number,
    targetId: PropTypes.string,
  }),
};
FileColumn.defaultProps = {
  previewState: null,
};
