import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import siteDe from './locales/de/site.json';
import siteEn from './locales/en/site.json';
import appDe from './locales/de/app.json';
import appEn from './locales/en/app.json';

i18n
  // detect user language
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  .init({
    debug: false,

    // return 'en' to everyone except for those with de-...
    fallbackLng: (code) => {
      if (!code?.startsWith('de')) return 'en';
      return ['de', 'en'];
    },
    supportedLngs: ['en', 'de'],
    // preload: ['en', 'de'],

    detection: {
      order: ['path', 'localStorage', 'cookie', 'sessionStorage', 'navigator'],
    },

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },

    resources: {
      en: {
        site: siteEn,
        app: appEn,
      },
      de: {
        site: siteDe,
        app: appDe,
      },
    },
  });

export default i18n;
