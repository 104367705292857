/* eslint-disable no-unsafe-optional-chaining */

import { createSelector } from '@reduxjs/toolkit';
import * as globals from '..';
import { applyFIFOOnCategory } from '../../../../misc/arrayHelpers';

// returns an array with all transactions (real or real+simulated) up to the Slider Date (baseDate) or to today (depending on dashboardMode in state)
// no isolated transactions are included
// apply FIFO to calculate quantityOpen
export const unlistedSharesTransactions = createSelector(
  (state) => state.simulation?.dashboardMode,
  (state) => state.data?.unlistedShares.transactions,
  (state) => state.data?.unlistedShares.simulatedTransactions,
  (state) => state.simulation?.baseDate,
  (state) => state.projects,
  (state) => state.user?.profile.settings.inflationRate,
  (state) => state.user?.profile.settings.sliderEndDate,
  (dashboardMode, transactions, simulatedTransactions, baseDate, projects, inflationRate, sliderMax) => {
    let returnTransactions;
    if (dashboardMode === 'projects') {
      const isolatedProjects = projects?.filter((project) => project.settings?.isIsolated).map((project) => project.id);
      const simulatedTransactionsWithoutIsolated = simulatedTransactions.filter((txn) => !isolatedProjects.includes(txn.projectId));

      returnTransactions = (transactions || [])
        .concat(simulatedTransactionsWithoutIsolated)
        .filter((transaction) => transaction.date <= baseDate)
        // unpack recurring transactions by creating transactions for every recurring transaction (never = until the end of slider)
        .flatMap((txn) => globals.unpackRecurringTransactionsCore(inflationRate, baseDate, sliderMax, txn));
    } else {
      returnTransactions = transactions || [];
    }
    return applyFIFOOnCategory(returnTransactions);
  },
);

// returns an array with all transactions (simulated and real) up to the Simulation End Date (slider end)
// including all isolated transactions
export const unlistedSharesTransactionsProjectView = createSelector(
  (state) => state.simulation?.dashboardMode,
  (state) => state.data?.unlistedShares.transactions,
  (state) => state.data?.unlistedShares.simulatedTransactions,
  (state) => state.simulation.baseDate,
  (state) => state.user?.profile.settings.inflationRate,
  (state) => state.user?.profile.settings.sliderEndDate,
  (dashboardMode, transactions, simulatedTransactions, baseDate, inflationRate, sliderMax) => {
    let returnTransactions;
    if (dashboardMode === 'projects') {
      returnTransactions = (transactions || [])
        .concat(simulatedTransactions)
        // unpack recurring transactions by creating transactions for every recurring transaction (never = until the end of slider)
        .flatMap((txn) => globals.unpackRecurringTransactionsCore(inflationRate, baseDate, sliderMax, txn));
      // no recurring transactions in real estate
    } else {
      returnTransactions = transactions || [];
    }
    return applyFIFOOnCategory(returnTransactions);
  },
);
