import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTheme } from '@nivo/core';
import { useTranslation } from 'react-i18next';

export default function CustomTooltipTranslated({
  id, value, formattedValue,
}) {
  const theme = useTheme();
  const baseCurrency = useSelector((state) => state.user.profile.settings.baseCurrency);

  const { t } = useTranslation('app', { keyPrefix: 'charts' });

  console.log('style', theme.tooltip.container);

  return (
    <div style={{ ...theme.tooltip.container }} className="text-sm rounded-md shadow">
      <p className="text-gray-700">
        <span className="font-bold">{t(id)}</span>
        {`: ${value.toLocaleString('de', { style: 'currency', currency: baseCurrency || 'EUR', maximumFractionDigits: 0 })}`}
      </p>
    </div>
  );
}
CustomTooltipTranslated.propTypes = {
  id: PropTypes.string,
  value: PropTypes.number,
  formattedValue: PropTypes.string,
};
CustomTooltipTranslated.defaultProps = {
  id: '',
  value: 0,
  formattedValue: '',
};
