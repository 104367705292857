/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { InformationCircleIcon } from '@heroicons/react/20/solid';
import { currencyCodes } from '../../../misc/currencyCodes';

/**
 *
 * Displayed on AccountData as part of the Stock account object (for each account)
 *
 * @param {number} idx - index of the account in the accountArray (and therefore also in the AccountData display)
 * @param {object} formState - formState object from react-hook-form of the entire AddAccount workflow
 * @param {function} register - register function from react-hook-form
 * @param {function} getValues - getValues function from react-hook-form
 * @param {object} userInput - userInput object from the entire AddAccount workflow
 * @param {array} selectDepositAccounts - array of deposit accounts to be displayed in the dropdown
 *
 * @returns AccountDataStockFields component; parent is a grid-cols-1 md:-3 xl:-4 grid
 */
export default function AccountDataStockFields({ idx, formState, register, getValues, userInput, selectDepositAccounts, refreshUserInputAndWorkflowStatus }) {
  const { t } = useTranslation('app', { keyPrefix: 'addAccount.D4' });

  useEffect(() => {
    refreshUserInputAndWorkflowStatus();
  }, [formState]);

  const connectedDepositMode = getValues(`accountArray.${idx}.connectedDepositMode`);

  return (
    <>
      <div className="md:col-start-1 text-sm font-medium text-gray-500">
        <p>{t('whenIBuy')}</p>
        {/* display error on field connectedDepositMode */}
        {formState.errors.accountArray?.[idx]?.connectedDepositMode && (
          <p className="mt-2 text-sm text-red-600" id="connectedDepositMode-error">
            {formState.errors.accountArray?.[idx]?.connectedDepositMode.message}
          </p>
        )}
      </div>
      {/* afaik fieldset is required to treat the options as one field */}
      <fieldset className="md:col-span-2 xl:col-span-3 grid md:grid-cols-2 xl:grid-cols-3 justify-items-start items-center gap-4 text-sm text-gray-500">
        <div className="flex items-center">
          <input
            id="existingAccount"
            name="existingAccount"
            type="radio"
            value="existingAccount"
            className="h-4 w-4 border-gray-300 text-brandBlue-500 focus:ring-brandBlue-400"
            {...register(`accountArray.${idx}.connectedDepositMode`, { required: { value: true, message: t('connectedDepositMode.error') } })}
          />
          <label htmlFor="existingAccount" className="ml-3 block text-sm font-medium text-gray-700">
            {t('existingAccount')}
          </label>
          {/* if this radion button is selected, display the deposit accounts dropdown */}
        </div>
        {/* {userInput.accountArray[idx]?.connectedDepositMode === 'existingAccount' && ( */}
        {/* ↑↑ form will only update userInput whenever it is valid, so if we want accountArray, we need to get it from form state */}
        {connectedDepositMode === 'existingAccount' && (
          <div className="xl:flex xl:items-center">
            <label htmlFor="connectedDepositAccountObject" className="flex text-sm font-medium">
              {t('existingAccountSelect')}
            </label>
            <div className="mt-1 ml-2">
              <select
                id="connectedDepositAccountObject"
                name="connectedDepositAccountObject"
                className="shadow-sm focus:ring-brandBlue-400 focus:border-brandBlue-400 block w-full text-sm border-gray-300 rounded-md"
                {...register(`accountArray.${idx}.connectedDepositAccountObject`)}
              >
                {selectDepositAccounts.map((a) => (
                  <option key={a.id} value={a.id}>
                    {a.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        )}

        <div className="flex items-center col-start-1">
          <input
            id="createTechnicalAccount"
            name="createTechnicalAccount"
            type="radio"
            value="createTechnicalAccount"
            className="h-4 w-4 border-gray-300 text-brandBlue-500 focus:ring-brandBlue-400"
            {...register(`accountArray.${idx}.connectedDepositMode`, { required: { value: true, message: t('connectedDepositMode.error') } })}
          />
          <label htmlFor="createTechnicalAccount" className="ml-3 block text-sm font-medium text-gray-700">
            {t('createTechnicalAccount')}
          </label>
        </div>

        {connectedDepositMode === 'createTechnicalAccount' && (
          <div className="xl:flex xl:items-center">
            <label htmlFor="currency" className="flex text-sm font-medium">
              {t('createTechnicalAccountCurrency')}
            </label>
            <div className="mt-1 xl:mt-0 xl:ml-2">
              <select
                id={`${idx}.currency`}
                name={`${idx}.currency`}
                className="shadow-sm focus:ring-brandBlue-400 focus:border-brandBlue-400 block w-full text-sm border-gray-300 rounded-md min-w-[5.25rem]"
                {...register(`accountArray.${idx}.currency`)}
              >
                {currencyCodes.map((c) => (
                  <option key={c}>{c}</option>
                ))}
              </select>
            </div>
          </div>
        )}
        <div className="flex items-center col-start-1">
          <input
            id="createAccountLater"
            name="createAccountLater"
            type="radio"
            value="createAccountLater"
            className="h-4 w-4 border-gray-300 text-brandBlue-500 focus:ring-brandBlue-400"
            {...register(`accountArray.${idx}.connectedDepositMode`, { required: { value: true, message: t('connectedDepositMode.error') } })}
          />
          <label htmlFor="createAccountLater" className="ml-3 block text-sm font-medium text-gray-700">
            {t('createAccountLater')}
          </label>
        </div>

        {connectedDepositMode === 'createAccountLater' && (
          <div className="flex gap-2">
            <InformationCircleIcon className="h-10 w-10 text-brandBlue-500 self-start" aria-hidden="true" />
            {t('createAccountLaterDescription')}
          </div>
        )}
      </fieldset>
    </>
  );
}
AccountDataStockFields.propTypes = {
  idx: PropTypes.number.isRequired,
  formState: PropTypes.object.isRequired,
  register: PropTypes.func.isRequired,
  getValues: PropTypes.func.isRequired,
  userInput: PropTypes.object.isRequired,
  selectDepositAccounts: PropTypes.array.isRequired,
  refreshUserInputAndWorkflowStatus: PropTypes.func.isRequired,
};
