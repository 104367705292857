// instructions: leave 1 account in accountName filter and 12 most recent months with data in date filter
const realEstate10y = {
  vals: ['value'],
  cols: ['accountName'],
  dataScope: 'snapshots',
  rows: ['Date (Year)'],
  rendererName: 'Bar chart, vertical, grouped',
  rendererType: 'nivo', // 'nivo' requires a height property to be set on its parent to be displayed
  id: 'realEstateByMonthxxxx',
  name: 'Real Estate, value by year',
  aggregatorName: 'Maximum',
  readOnly: true,
};

export default realEstate10y;
