/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable max-len */
/* This example requires Tailwind CSS v2.0+ */
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Dialog, Transition } from '@headlessui/react';
import { CheckIcon, XCircleIcon, ExclamationCircleIcon, InformationCircleIcon } from '@heroicons/react/24/outline';
import { clearAlert, updateAlertResponse } from '../redux/actions/message';
import Button from './Button';

function IconSuccess() {
  return (
    <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
      <CheckIcon className="h-6 w-6 text-green-500" aria-hidden="true" />
    </div>
  );
}

function IconWarning() {
  return (
    <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-yellow-50">
      <ExclamationCircleIcon className="h-6 w-6 text-yellow-400" aria-hidden="true" />
    </div>
  );
}

function IconError() {
  return (
    <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-brandRed-100">
      <XCircleIcon className="h-6 w-6 text-brandRed-500" aria-hidden="true" />
    </div>
  );
}

function IconInfo() {
  return (
    <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-brandDarkBlue-100">
      <InformationCircleIcon className="h-6 w-6 text-brandBlue-500" aria-hidden="true" />
    </div>
  );
}

export default function Alert() {
  // *** THIS IS THE ONE USED BY SET_ALERT IN REDUX AND IN EVENT-BASED ***
  // AlertModalDispatch will be displayed once state.message.alert.visible is true
  // upon rendering it will read state.message.alert.id to get the alert message + button labels from i18n.app.alerts
  // onClick will set state.message.alert.visible to false and .response to the id of the button clicked
  // button: the "affirmative" button (will trigger callbackOk when pressed)
  // button2: the "negative" button (will close alert with no action when pressed)

  // if the event has a Component property, then it will be rendered instead of the body text
  // if there is no Component property, then the body text will be rendered as follows:
  // alertTexts.body can be a string or an array of strings; if the latter, then it will be split into paragraphs

  // args have named arguments which are to be used in the traslation file

  // callbackOk is the function to be called when the affirmative button is clicked
  // callbackCancel is the function to be called when the negative button is clicked

  const [open, setOpen] = useState(false);
  const [alert, setAlert] = useState({});
  const [dispatching, setDispatching] = useState(false);

  const dispatch = useDispatch();
  const selectAlert = useSelector((state) => state.message.alert);

  const { t } = useTranslation(['app']);

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }

  // CAUTION: some components are still relying on alert status changes in state, so that has to be eliminated to move to listener-only mode
  useEffect(() => {
    function handleIncomingEvent(e) {
      console.log('handleAlertChange', e.detail);
      if (e.detail) {
        setAlert({ ...e.detail, visible: true });
        setDispatching(false); // reset state
        setOpen(true);
      } else setOpen(false);
    }
    window.addEventListener('setAlert', handleIncomingEvent);
    return () => window.removeEventListener('setAlert', handleIncomingEvent);
  }, []);
  const alertTexts = alert && t(`alerts.${alert.id}`, { returnObjects: true, ...alert.args });
  // console.log('alertTexts', alertTexts);

  // translation from selector to state, as there is not time to figure out how to make it work with headlessui
  useEffect(() => {
    if (selectAlert) {
      setAlert(selectAlert);
      setOpen(true);
    }
  }, [selectAlert]);

  // if there is button2 in the i18n object, then use two buttons, otherwise just the one
  const buttons = alert && alertTexts?.button2 ? [alertTexts?.button, alertTexts?.button2] : [alertTexts?.button];

  async function handleClick(e, mode) {
    setDispatching(true);
    // if there is a callbackOK, call it on the ok button and clear the alert
    if (alert.callbackOk && mode === 'ok') {
      await alert.callbackOk();
      if (selectAlert) dispatch(clearAlert()); // do this only if the alert came through the selector
      setDispatching(false);
    } else if (alert.callbackCancel && mode === 'cancel') {
      if (alert.callbackCancel) await alert.callbackCancel();
    } else if (selectAlert) {
      // if no callback, just use the old way with publishing response in redux
      setDispatching(false);
      dispatch(updateAlertResponse(mode === 'ok' ? buttons[0].toLowerCase : buttons[1].toLowerCase)); // for backward compatibility
    }
    setOpen(false);
    setAlert({});
  }

  let Icon = IconSuccess;
  if (alert && alertTexts.type === 'error') Icon = IconError;
  if (alert && alertTexts.type === 'warning') Icon = IconWarning;
  if (alert && alertTexts.type === 'info') Icon = IconInfo;

  return alert?.visible ? (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" id="alert" className="fixed z-50 inset-0 overflow-y-auto" onClose={setOpen}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
              <div>
                <Icon />
                <div className="mt-3 text-center sm:mt-5">
                  <Dialog.Title as="h3" className="text-lg leading-6 font-bold text-gray-900">
                    {alertTexts?.header}
                  </Dialog.Title>
                  <div className="mt-4">
                    <div className="text-sm text-gray-500 leading-5">
                      {alert?.Component && <alert.Component {...alert?.componentProps} />}
                      {typeof alertTexts?.body === 'object'
                        && alertTexts?.body.map((item) => (
                          <p className="pb-2" key={item}>
                            {item}
                          </p>
                        ))}
                      {!alert?.component && !(typeof alertTexts?.body === 'object') && alertTexts?.body}
                    </div>
                  </div>
                </div>
              </div>
              {buttons.length === 2 ? (
                <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                  <Button id={buttons[1]?.toLowerCase()} name="cancelButton" text={buttons[1]} onClick={(e) => handleClick(e, 'cancel')} size="xl" />
                  <Button id={buttons[0]?.toLowerCase()} name="affirmativeButton" text={buttons[0]} onClick={(e) => handleClick(e, 'ok')} withAccent spinnerOn={dispatching} size="xl" />
                </div>
              ) : (
                <div className="mt-5 sm:mt-6 sm:grid">
                  <Button id={buttons[0]?.toLowerCase()} name="affirmativeButton" text={buttons[0]} onClick={(e) => handleClick(e, 'ok')} withAccent spinnerOn={dispatching} size="xl" />
                </div>
              )}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  ) : null;
}
