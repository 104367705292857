import { React } from 'react';

import Hero from '../elements/Hero';
import InlineBanner from '../elements/InlineBanner';
import MainSection1 from '../elements/MainSection1';
import MainSection2 from '../elements/MainSection2';
import MainSection3 from '../elements/MainSection3';
import TryItOut from '../elements/TryItOut';

function Main() {
  return (
    <div data-testid="topmost-node">
      <Hero />
      {/* <InlineBanner /> */}
      <MainSection1 />
      <MainSection2 />
      <MainSection3 />
      <TryItOut />
    </div>
  );
}

export default Main;
