/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-shadow */
import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/24/solid';
import ToolTip from './ToolTip';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

// used for: currency codes (AddProjectTransactionData), month / year (AddProjectTransaction), account + accountTo (account object!)
// list can be an array of items or objects (.name property is shown to user - it will look for .name to display; if there are no .name, it will use the value itself)
// it will send the entire object back to parent (onChange)
// extraWide sets the width of the dropdown to w-80 instead of w-full
// sendBackIndex causes the dropdown to operate on index instead of an object (and also sends back index of the selected value in the 'list' array instead of object itself)
/**
 *
 * @param {*} value - object to be pre-filled in dropdown; .name is shown to user; the entire object is returned
 * @param {string} errorMessage - error message from the parent useForm hook
 * @param {boolean} (optional) keepItShort - reduces the maximum height of options box to 20
 * @param {string} (optional) formatting - pass className classes to the parent element
 * @returns React component
 */
export default function Dropdown({
  list,
  label,
  tooltip,
  disableLabel,
  value,
  onChange,
  onBlur,
  errorMessage,
  inputRef,
  extraWide,
  keepItShort,
  sendBackIndex,
  optional,
  multiple,
  enabled,
  formatting,
}) {
  const { t } = useTranslation();

  function handleDisplayValue(item) {
    // if this is an array, display '[...]'
    if (Array.isArray(item)) return t('app:accountDetails.multipleSelected');
    // there is no item.name, so assume it's a list of items (does not apply for item === null, which should return Select...)
    if (item?.name === undefined && item !== null) return item;
    // there is item.name = null, so this is the "empty option" which must return null
    if (item?.name === null || item === null) return 'Select...';
    // there is item.name, so display it
    if (item?.name) return item.name;
    return null;
  }

  const selectedValueLocal = sendBackIndex ? list[value] : handleDisplayValue(value);
  // eslint-disable-next-line max-len
  const valueLocal = value === undefined || value === null || (Object.entries(value).length === 0 && typeof value !== 'number') || (Array.isArray(value) && value.length === 0) ? 'Select...' : selectedValueLocal;

  return (
    <Listbox as="div" value={value} onChange={onChange} onBlur={onBlur} ref={inputRef} multiple={multiple} disabled={!enabled} className={formatting}>
      {({ open }) => (
        <>
          {!disableLabel && (
            <div className="flex justify-between">
              <div className="flex space-x-1">
                <Listbox.Label className="block text-xs sm:text-sm font-medium text-gray-700">{label}</Listbox.Label>
                {tooltip && <ToolTip info={tooltip} />}
              </div>
              <span className="text-sm text-gray-500" id="email-optional">
                {optional ? 'Optional' : ''}
              </span>
            </div>
          )}
          <div className={`relative ${label ? 'mt-1' : ''}`}>
            <Listbox.Button
              className={`relative w-full cursor-default rounded-md ${errorMessage.length > 0 ? 'border-2 border-red-500' : 'border border-gray-300'}
              bg-white py-2 pl-3 pr-10 text-left shadow-sm
              focus:border-brandBlue-500 focus:outline-none focus:ring-1 focus:ring-brandBlue-600 text-sm`}
              id="dropdown"
            >
              <span className="block truncate">{valueLocal.name || valueLocal}</span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </span>
            </Listbox.Button>

            <Transition show={open} as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
              {/* eslint-disable-next-line max-len */}
              <Listbox.Options
                // eslint-disable-next-line max-len
                className={`absolute z-20 mt-1 ${extraWide ? 'w-80' : 'w-full'} ${
                  keepItShort ? 'max-h-20' : 'max-h-60'
                } overflow-auto rounded-md bg-white py-1 text-sm shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`}
              >
                {(list || []).map((item, idx) => (
                  <Listbox.Option
                    key={item?.id || (item === null ? 'select' : item)}
                    id={`dropdown-option-${String(item?.id || (item === null ? 'select' : item))}`}
                    className={({ active }) => classNames(active ? 'text-white bg-brandBlue-500' : 'text-gray-900', 'relative cursor-default select-none py-2 px-3 flex gap-2 items-baseline')}
                    value={sendBackIndex ? idx : item}
                  >
                    {({ selected, active }) => (
                      <>
                        <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>{handleDisplayValue(item)}</span>
                        {item?.category && <span className={`text-xs font-light uppercase ${active ? 'text-white' : 'text-gray-400'}`}>{t(`app:dashboard.singleCategory.${item?.category}`)}</span>}

                        {selected ? (
                          <span className={classNames(active ? 'text-white' : 'text-brandBlue-500', 'absolute inset-y-0 right-0 flex items-center pr-4')}>
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}
Dropdown.propTypes = {
  list: PropTypes.array.isRequired,
  label: PropTypes.string,
  tooltip: PropTypes.string,
  disableLabel: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.array]),
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  errorMessage: PropTypes.string,
  inputRef: PropTypes.objectOf(PropTypes.any),
  extraWide: PropTypes.bool,
  keepItShort: PropTypes.bool,
  sendBackIndex: PropTypes.bool,
  optional: PropTypes.bool,
  multiple: PropTypes.bool,
  enabled: PropTypes.bool,
  formatting: PropTypes.string,
};
Dropdown.defaultProps = {
  label: '',
  tooltip: null,
  disableLabel: false,
  value: {},
  errorMessage: '',
  onBlur: () => {},
  inputRef: {},
  extraWide: false,
  keepItShort: false,
  sendBackIndex: false,
  optional: true,
  multiple: false,
  enabled: true,
  formatting: '',
};
