import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

/**
 * Calculates the present value factor for a pension product based on the account object and the current date.
 * PV of the future value of a stream of payments is calculated using daily compounding, thereafter the FV is calculated using the payout frequency.
 * @param {Account object} pension account object (uses properties: payoutsPhaseStart, payoutsFrequency, payoutsDuration, discountRate); if discountRate is not provided, use global inflation rate
 * @param {int} currentDate which needs to be taken as basis of calculation (usually baselineView.current)
 * @param {float} inflationRate used as the discount rate if discountRate is not provided
 * @returns {float} present value factor that the monthly payout should be multiplied by to return the present value of the pension product
 */
export default function calculatePVFactor(account, currentDate, inflationRate) {
  // Destructuring relevant properties from the account object
  const { payoutsPhaseStart, payoutsFrequency, payoutsDuration, discountRate } = account;
  // console.info('calculatePV for', account.name, payoutsPhaseStart, payoutsFrequency, payoutsDuration, discountRate, inflationRate);

  // Calculate the annual discount rate (assuming discountRate is provided as an annual percentage)
  const dayDiscountRate = (discountRate || inflationRate) / 100 / 365.25;
  const periodDiscountRate = (discountRate || inflationRate) / 100 / (12 / payoutsFrequency);

  // Calculate the number of periods from the current date to the start of the payout phase
  const startDelayDays = Math.max(0, dayjs.utc(payoutsPhaseStart).diff(dayjs.utc(currentDate), 'day'));
  // console.log('start delay days', startDelayDays, 'current date', currentDate, 'payouts start date', payoutsPhaseStart);

  // number of complete payout periods before the end of the payouts phase
  const totalPayoutPeriods = payoutsDuration > 0 ? Math.floor(payoutsDuration / payoutsFrequency) : Number.POSITIVE_INFINITY;

  // Calculate present value factor
  let pvFactor;
  if (totalPayoutPeriods === Number.POSITIVE_INFINITY) {
    // Perpetuity formula (discount rate must not be 0)
    pvFactor = periodDiscountRate > 0 ? 1 / periodDiscountRate : 0;
  } else {
    // Annuity formula
    pvFactor = (1 - (1 + periodDiscountRate) ** -totalPayoutPeriods) / periodDiscountRate;
  }
  // console.log('future PV for 100€', account.name, pvFactor * 100);

  // Applying discounting for the delay until the first payment (if any)
  if (startDelayDays > 0) {
    pvFactor /= (1 + dayDiscountRate) ** startDelayDays;
  }

  return pvFactor;
}
