/* eslint-disable max-len */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { UserGroupIcon, BugAntIcon, ChatBubbleLeftRightIcon, ComputerDesktopIcon, BookOpenIcon, PencilSquareIcon } from '@heroicons/react/24/outline';

export default function Contact() {
  const { t, i18n } = useTranslation(['site'], { keyPrefix: 'contact' });
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);

  return (
    <div className="relative bg-white">
      <div className="lg:absolute lg:inset-0 lg:left-1/2">
        <img
          className="h-64 w-full bg-gray-50 object-cover sm:h-80 lg:absolute lg:h-full"
          // eslint-disable-next-line max-len
          src="https://images.unsplash.com/photo-1559136555-9303baea8ebd?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&crop=focalpoint&fp-x=.4&w=2560&h=3413&&q=80"
          alt=""
        />
      </div>
      <div className="pb-24 pt-12 sm:pb-32 sm:pt-20 lg:mx-auto lg:grid lg:max-w-7xl lg:grid-cols-2 lg:pt-16">
        <div className="px-6 lg:px-8">
          <div className="mx-auto max-w-xl lg:mx-0 lg:max-w-lg">
            <h2 className="text-3xl font-black tracking-tight text-gray-900">{t('header')}</h2>
            <p className="mt-2 text-xl leading-8 text-gray-500">{t('subheader')}</p>
            <div className="mx-auto mt-20 max-w-lg space-y-16">
              <div className="flex gap-x-6">
                <div className="flex h-10 w-10 shrink-0 items-center justify-center rounded-lg bg-brandBlue-500">
                  <ChatBubbleLeftRightIcon className="h-6 w-6 text-white" aria-hidden="true" />
                </div>
                <div>
                  <h3 className="text-base font-semibold leading-7 text-gray-900">{t('prospects.header')}</h3>
                  <p className="mt-2 leading-7 text-gray-600">{t('prospects.body')}</p>
                  <p className="mt-4">
                    <a href="mailto:support@monestry.de" className="text-sm font-semibold leading-6 text-brandBlue-500">
                      {t('emailUs')}
                      {' '}
                      <span aria-hidden="true">&rarr;</span>
                    </a>
                  </p>
                </div>
              </div>
              <div className="flex gap-x-6">
                <div className="flex h-10 w-10 shrink-0 items-center justify-center rounded-lg bg-brandBlue-500">
                  <BugAntIcon className="h-6 w-6 text-white" aria-hidden="true" />
                </div>
                <div>
                  <h3 className="text-base font-semibold leading-7 text-gray-900">{t('users.header')}</h3>
                  <p className="mt-2 leading-7 text-gray-600">{t('users.body')}</p>
                  <p className="mt-4">
                    {isLoggedIn ? (
                      <Link to={`/${i18n.language}/app`} className="text-sm font-semibold leading-6 text-brandBlue-500">
                        {t('goToDashboad')}
                        {' '}
                        <span aria-hidden="true">&rarr;</span>
                      </Link>
                    ) : (
                      <Link to={`/${i18n.language}/login`} className="text-sm font-semibold leading-6 text-brandBlue-500">
                        {t('goToLogin')}
                        {' '}
                        <span aria-hidden="true">&rarr;</span>
                      </Link>
                    )}
                  </p>
                </div>
              </div>
              <div className="flex gap-x-6">
                <div className="flex h-10 w-10 shrink-0 items-center justify-center rounded-lg bg-brandBlue-500">
                  <PencilSquareIcon className="h-6 w-6 text-white" aria-hidden="true" />
                </div>
                <div>
                  <h3 className="text-base font-semibold leading-7 text-gray-900">{t('dataPrivacy.header')}</h3>
                  <p className="mt-2 leading-7 text-gray-600">{t('dataPrivacy.body')}</p>
                  <p className="mt-4">
                    <a href="mailto:support@monestry.de" className="text-sm font-semibold leading-6 text-brandBlue-500">
                      {t('emailUs')}
                      {' '}
                      <span aria-hidden="true">&rarr;</span>
                    </a>
                  </p>
                </div>
              </div>
              <div className="flex gap-x-6">
                <div className="flex h-10 w-10 shrink-0 items-center justify-center rounded-lg bg-brandBlue-500">
                  <UserGroupIcon className="h-6 w-6 text-white" aria-hidden="true" />
                </div>
                <div>
                  <h3 className="text-base font-semibold leading-7 text-gray-900">{t('partners.header')}</h3>
                  <p className="mt-2 leading-7 text-gray-600">{t('partners.body')}</p>
                  <p className="mt-4">
                    <a href="mailto:partners@monestry.de" className="text-sm font-semibold leading-6 text-brandBlue-500">
                      {t('emailUs')}
                      {' '}
                      <span aria-hidden="true">&rarr;</span>
                    </a>
                  </p>
                </div>
              </div>
              <div className="flex gap-x-6">
                <div className="flex h-10 w-10 shrink-0 items-center justify-center rounded-lg bg-brandBlue-500">
                  <BookOpenIcon className="h-6 w-6 text-white" aria-hidden="true" />
                </div>
                <div>
                  <h3 className="text-base font-semibold leading-7 text-gray-900">{t('media.header')}</h3>
                  <p className="mt-2 leading-7 text-gray-600">{t('media.body')}</p>
                  <p className="mt-4">
                    <a href="mailto:media@monestry.de" className="text-sm font-semibold leading-6 text-brandBlue-500">
                      {t('emailUs')}
                      {' '}
                      <span aria-hidden="true">&rarr;</span>
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
