/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { InformationCircleIcon, PlusIcon, XMarkIcon } from '@heroicons/react/20/solid';
import { nanoid } from 'nanoid';
import { postData } from '../../redux/reducers/data';

/**
 * This component will use splitTransactions if they are passed as props, otherwise it will use transaction.
 */
export default function Split({ category, transaction, splitTransactions = [], signalHandleSubmit }) {
  Split.propTypes = {
    category: PropTypes.string.isRequired,
    transaction: PropTypes.object,
    splitTransactions: PropTypes.arrayOf(PropTypes.object),
    signalHandleSubmit: PropTypes.bool.isRequired,
  };
  Split.defaultProps = {
    transaction: null,
    splitTransactions: [],
  };

  const isEditExistingSplitMode = splitTransactions.length > 0;
  const loanMode = category === 'loans';
  // we have to pass transaction to all the TransactionContext subcomponents, because only the two Split... components use splitTransactions
  // some of the code below relies of transaction being undefined when splitTransactions are passed, hence we handle that here
  const localTransaction = isEditExistingSplitMode ? undefined : transaction;

  const { t } = useTranslation('app', { keyPrefix: 'accountDetails.splitMenu' });

  function sumByField(field) {
    return splitTransactions.reduce((acc, curr) => acc + curr[field], 0);
  }

  const amountToSplit = isEditExistingSplitMode ? sumByField('quantity') : localTransaction?.quantity;
  // ↓ for loans only, which has quantityInterest
  const amountToSplitInterest = isEditExistingSplitMode ? sumByField('quantityInterest') : localTransaction?.quantityInterest;

  const [focused, setFocused] = useState(null);
  const [splitArray, setSplitArray] = useState(
    isEditExistingSplitMode ? splitTransactions : [{ id: nanoid(),
      quantity: amountToSplit,
      quantityInterest: amountToSplitInterest, // only used for loans
      description: localTransaction.description || localTransaction.displayName }],
  );
  const [errorArray, setErrorArray] = useState([]);
  const remainingAmount = amountToSplit - splitArray.reduce((a, b) => a + b.quantity, 0);
  const remainingAmountInterest = amountToSplitInterest - splitArray.reduce((a, b) => a + b.quantityInterest, 0);

  const dispatch = useDispatch();

  // subscribe to the signalHandleSubmit and submit the form if it is true
  useEffect(() => {
    if (signalHandleSubmit && remainingAmount === 0 && !(!!remainingAmountInterest || remainingAmountInterest === 0)) {
      let data;
      if (category === 'deposits') {
        data = splitArray
          .map((item, idx) => ({
            // take the original transaction parameters, overwrite id, accountCurrencyAmount and description from item, recalculate amount, add import flag
            // ↓ this is mostly to provide a template with all required fields; if there is no localTransaction, spread first splitTransaction
            // ↓ ("item"s will already have all incoming splitTransactions)
            ...(localTransaction || splitTransactions[0]),
            ...item,
            // id is being given already at append
            parentId: localTransaction?.id || splitTransactions[0]?.parentId, // if we are in splitTransaction mode, they will all have the same parentId
            accountCurrencyAmount: item.quantity,
            amount: ((localTransaction?.amount || sumByField('amount')) * item.quantity) / amountToSplit,
            importFlag: 'post',
          }));
      }
      if (category === 'stocks') {
        data = splitArray
          .map((item) => ({
            // take the original transaction parameters, overwrite id, accountCurrencyAmount and description from item, recalculate amount, add import flag
            ...localTransaction,
            ...item,
            // id is being given already at append
            parentId: localTransaction?.id || splitTransactions[0]?.parentId, // if we are in splitTransaction mode, they will all have the same parentId
            transactionAmount: item.quantity,
            rebasedAmount: ((localTransaction?.rebasedAmount || sumByField('rebasedAmount')) * item.quantity) / amountToSplit,
            amount: ((localTransaction?.amount || sumByField('amount')) * item.quantity) / amountToSplit,
            transactionAmountSold: ((localTransaction?.transactionAmountSold || sumByField('transactionAmountSold')) * item.quantity) / amountToSplit,
            rebasedAmountSold: ((localTransaction?.quantitySold || sumByField('quantitySold')) * item.quantity) / amountToSplit,
            importFlag: 'post',
          }));
      }
      if (category === 'loans') {
        data = splitArray
          .map((item, idx) => ({
            ...(localTransaction || splitTransactions[0]),
            ...item,
            // id is being given already at append
            parentId: localTransaction?.id || splitTransactions[0]?.parentId, // if we are in splitTransaction mode, they will all have the same parentId
            quantity: item.quantity, // in deposits these two will be negative for taken accounts and v.v.
            quantityInterest: item.quantityInterest,
            importFlag: 'post',
          }));
      }
      // delete the original transaction and its mappings
      // but not when we are in editExistingSplitMode (because that transaction does not exist anymore)
      if (!isEditExistingSplitMode) data.push({ ...localTransaction, importFlag: 'delete' });

      dispatch(
        postData({
          data,
          category,
          accountId: localTransaction?.accountId || splitTransactions[0]?.accountId, // they will all have the same accountId
        }),
      );
      window.dispatchEvent(
        new CustomEvent('setDialog', {}),
      );
    }
  }, [signalHandleSubmit]);

  function handleDeleteSplit(e, index) {
    if (splitArray.length > 1) {
      setSplitArray(splitArray.filter((_, idx) => idx !== index));
    }
  }

  // adds a new line to the form (and to splitArray)
  function handleAddSplit(e) {
    // set amountToSplit to the available amountToSplit and description to the description of the original split item
    setSplitArray([
      ...splitArray,
      {
        id: nanoid(),
        quantity: amountToSplit - splitArray.reduce((a, b) => a + b.quantity, 0),
        description: splitArray[0].description,
      },
    ]);
  }

  // updates the form row 'index' and field 'field' represented by item (for convenience) with the new value (e.target.value)
  function handleChangeSplit(e, item, index, field) {
    // check if the account is a number, otherwise display an error
    const arrayCopy = [...splitArray]; // remove the "old" transaction and replace it with t
    arrayCopy.splice(index, 1, {
      ...item,
      [field]: field === 'quantity' && !Number.isNaN(Number(e.target.value)) ? Number(e.target.value) : e.target.value,
    });
    setSplitArray(arrayCopy);
  }

  function handleFocus(e, index = null, field = null) {
    // set focus state so that the input field may change the number format to a simpler one
    setFocused({ index, field });
    setTimeout(() => e.target.select(), 0);
  }

  return (
    <div className="mt-2">
      <p className="text-sm text-gray-500">{t('splitHeader')}</p>
      {category === 'stocks' && (
        <div className="mt-2 flex items-start">
          <InformationCircleIcon className="h-5 w-5 mr-1 text-brandBlue-400" aria-hidden="true" />
          <p className="text-xs italic text-gray-500">{t('splitInfo')}</p>
        </div>
      )}
      {remainingAmount === 0 && <div />}
      {remainingAmount > 0 && <p className="mt-1 text-sm text-red-500">{`${t('amountToAllocate')}: ${-remainingAmount}`}</p>}
      {remainingAmount < 0 && <p className="mt-1 text-sm text-red-500">{`${t('allocatedTooMuch')} ${Math.abs(remainingAmount)}`}</p>}
      {remainingAmountInterest === 0 && <div />}
      {remainingAmountInterest > 0 && <p className="mt-1 text-sm text-red-500">{`${t('amountToAllocateInterest')}: ${-remainingAmountInterest}`}</p>}
      {remainingAmountInterest < 0 && <p className="mt-1 text-sm text-red-500">{`${t('allocatedTooMuchInterest')} ${Math.abs(remainingAmountInterest)}`}</p>}
      <ul className="mt-4 divide divide-gray-100">
        {splitArray.map((item, index) => (
          <li className="grid grid-cols-12 p-2 gap-4 text-sm" key={item.id} id={`row-${index}`}>
            <div className={`flex items-center ${loanMode ? 'col-span-7' : 'col-span-8'}`}>
              {/* in loanMode we display quantity and quantityInterest in each row, so the columnts will be narrower */}
              <input
                className="px-1.5 py-2 flex-1 text-gray-700 text-left truncate border-b rounded-md border-gray-300 focus:outline-none focus:border-brandBlue-400"
                value={item.description}
                onChange={(e) => handleChangeSplit(e, item, index, 'description')}
                onFocus={handleFocus}
                onBlur={(e) => {
                  setFocused(null);
                }}
              />
            </div>
            <input
              className={`px-1.5 py-2 text-gray-700 text-right border-b rounded-md border-gray-300 focus:outline-none focus:border-brandBlue-400 ${loanMode ? 'col-span-2' : 'col-span-3'}`}
              value={`${item.quantity}`}
              onChange={(e) => handleChangeSplit(e, item, index, 'quantity')}
              onFocus={(e) => handleFocus(e, index, 'quantity')}
              onBlur={(e) => {
                setFocused(null);
              }}
            />
            {(loanMode) && (
            <input
              className="px-1.5 py-2 text-gray-700 text-right border-b rounded-md border-gray-300 focus:outline-none focus:border-brandBlue-400 col-span-2"
              value={`${item.quantityInterest}`}
              onChange={(e) => handleChangeSplit(e, item, index, 'quantityInterest')}
              onFocus={(e) => handleFocus(e, index, 'quantityInterest')}
              onBlur={(e) => {
                setFocused(null);
              }}
            />
            )}
            <button type="button" onClick={(e) => handleDeleteSplit(e, index)}>
              <XMarkIcon className="h-5 w-5 flex-0 mr-2 text-gray-300 hover:text-gray-400" aria-hidden="true" />
            </button>
            {errorArray?.includes(index) && <p className="text-red-500 col-span-12 text-sm">{t('error')}</p>}
          </li>
        ))}
        <li className="p-2">
          <button
            type="button"
            onClick={handleAddSplit}
            // eslint-disable-next-line max-len
            className="relative block w-full rounded-lg border border-dashed border-gray-300 p-1.5 text-center group hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-brandBlue-600 focus:ring-offset-2"
          >
            <PlusIcon className="h-7 w-full text-gray-300 group-hover:text-gray-300" aria-hidden="true" />
          </button>
        </li>
        {(isEditExistingSplitMode) && (
        <div className="flex items-center mt-4">
          <InformationCircleIcon className="h-8 w-8 text-brandBlue-500" aria-hidden="true" />
          <p className="ml-2 text-sm text-gray-500">{t('splitExistingDetected')}</p>
        </div>
        )}
      </ul>
    </div>
  );
}
