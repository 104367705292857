/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Grid from './Grid';
import getGridLayout from './gridLayouts';
import ButtonBar from './ButtonBar';

export function getButtonsToShow(category, displayedComponent = 'grid') {
  if (displayedComponent === 'grid') {
    switch (category) {
      case 'deposits':
        return ['tableEditSettings', 'save'];
      case 'metals':
        return ['tableEditSettings', 'save', 'hasWorth', 'historicalValueCalc'];
      case 'unlistedShares':
        return ['tableValuationWizardSettings', 'hasWorth', 'prevNext'];
      case 'stocks':
      case 'realEstate':
      case 'objectsOfValue':
      case 'pension':
      default:
        return ['tableEditSettings', 'save', 'hasWorth'];
    }
  }
  // displayedComponent === 'duplicate'
  return ['submit-cancel'];
}

// applies category-specific changes to data and passes them onwards;
// manages grid layout
export default function GridLayout({
  account,
  setAccount,
  data,
  tableState,
  mode,
  prepAndValidateOutputData,
  postCategoryItems,
  displayedComponent,
  setDisplayedComponent,
  displayedComponentMode,
  setDisplayedComponentMode,
  userChangesPresent,
  setUserChangesPresent,
  customDropdownSources,
  customOnChange,
  saveDialogPromiseCallback,
}) {
  // expecting data to be an array of objects
  // each object should have exactly the same keys as there are columns in the grid + an id AS THE LAST COLUMN

  const [runSave, setRunSave] = useState(false); // if this turns to true, the Grid component will execute handleSaveWrapper
  // we are using state to manage that becuase handleSaveWrapper must run in the context of Grid, not here

  const gridLayout = getGridLayout(account.category, mode);

  return (
    <>
      <ButtonBar
        buttonsToShow={getButtonsToShow(account.category, 'grid')}
        displayedComponent={displayedComponent}
        setDisplayedComponent={setDisplayedComponent}
        displayedComponentMode={displayedComponentMode}
        setDisplayedComponentMode={setDisplayedComponentMode}
        handleSave={() => setRunSave(true)}
        userChangesPresent={userChangesPresent}
      />
      <Grid
        account={account} // to get current account status (after loading) and react to evtl. errors
        setAccount={setAccount} // close AccountDetails after saving
        data={data}
        gridLayout={gridLayout}
        prepAndValidateOutputData={prepAndValidateOutputData}
        postCategoryItems={postCategoryItems}
        tableState={tableState}
        displayedComponent={displayedComponent} // normally 'grid', but also 'duplicate'
        setDisplayedComponent={setDisplayedComponent} // to go back to table after loading is finished,
        // setDisplayedComponentMode,
        userChangesPresent={userChangesPresent} // to prevent closing AccountDetails without saving
        setUserChangesPresent={setUserChangesPresent} // to prevent closing AccountDetails without saving
        runSave={runSave}
        setRunSave={setRunSave}
        customDropdownSources={customDropdownSources}
        customOnChange={customOnChange}
        saveDialogPromiseCallback={saveDialogPromiseCallback}
      />
    </>
  );
}
GridLayout.propTypes = {
  account: PropTypes.object.isRequired,
  setAccount: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
  tableState: PropTypes.object.isRequired,
  mode: PropTypes.string.isRequired,
  prepAndValidateOutputData: PropTypes.func.isRequired,
  postCategoryItems: PropTypes.func.isRequired,
  userChangesPresent: PropTypes.bool.isRequired,
  setUserChangesPresent: PropTypes.func.isRequired,
  displayedComponent: PropTypes.string.isRequired,
  setDisplayedComponent: PropTypes.func.isRequired,
  displayedComponentMode: PropTypes.string.isRequired,
  setDisplayedComponentMode: PropTypes.func.isRequired,
  customDropdownSources: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)),
  customOnChange: PropTypes.func,
  saveDialogPromiseCallback: PropTypes.func,
};
GridLayout.defaultProps = {
  customDropdownSources: {},
  customOnChange: () => {},
  saveDialogPromiseCallback: (resolve) => resolve(null),
};
