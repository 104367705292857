/* eslint-disable no-underscore-dangle */
/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { InformationCircleIcon } from '@heroicons/react/20/solid';
import { convertCurrency } from '../../../misc/ecbCurrencyRates';
import { currencyCodes } from '../../../misc/currencyCodes';
import Dropdown from './Dropdown'; // import wrapper for the common Dropdown component
import InputField from './InputField';
import ToolTip from '../../../elements/ToolTip';

dayjs.extend(utc);

export function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

function Header({ text }) {
  Header.propTypes = {
    text: PropTypes.string.isRequired,
  };
  const { t: tI } = useTranslation(['app'], { keyPrefix: 'addAccount.objectsOfValue' });
  return <h4 className="xs:col-span-2 lg:col-span-3 text-base font-bold text-gray-900">{tI(text)}</h4>;
}

function ObjectsOfValueAccountData({ index, updateWorkflowStatus, userInput, setUserInput, signalNext, setSignalNext }) {
  const { t: tL } = useTranslation('app', { keyPrefix: 'schema.objectsOfValue.account' });
  const { t: tI } = useTranslation(['app'], { keyPrefix: 'addAccount.objectsOfValue' });

  const [fxRate, setFxRate] = useState(null);

  const selectDepositAccounts = useSelector((state) => state.data.deposits.accounts);
  const baseCurrency = useSelector((state) => state.user.profile.settings.baseCurrency);

  //--------------------------------------------------------------------------------
  // default values for the form state (userInput + some tweaks)
  //--------------------------------------------------------------------------------

  const defaultValues = { ...(userInput && { ...userInput.data }) };
  defaultValues.currency = defaultValues.currency === undefined ? baseCurrency : defaultValues.currency;
  defaultValues.quantity = defaultValues.quantity === undefined ? 1 : defaultValues.quantity;

  // register the hook
  const { register, watch, reset, getValues, control, formState, setValue, trigger } = useForm({ mode: 'onChange', defaultValues });
  const { errors, isValid, isDirty, dirtyFields } = formState;
  const accountCurrency = watch('currency');
  const isBaseCurrencySameAsAccountCurrency = accountCurrency === baseCurrency;

  //--------------------------------------------------------------------------------
  // synchronise this component's form with the parent component's form state
  //--------------------------------------------------------------------------------
  function processOutputData(formValues) {
    const obj = { ...formValues };

    // housekeeping: convert dates to epoch
    obj.userInputPurchaseDate = dayjs.utc(formValues.userInputPurchaseDate).valueOf();

    // housekeeping: convert string-numbers to numbers
    obj.userInputPurchaseValueAccountCurrency = Number(formValues.userInputPurchaseValueAccountCurrency);
    obj.userInputPurchaseValueBaseCurrency = accountCurrency === baseCurrency ? Number(formValues.userInputPurchaseValueAccountCurrency) : formValues.userInputPurchaseValueAccountCurrency || null;
    obj.quantity = Number(formValues.quantity);

    // handle connectedDepositIds
    if (obj.connectedDepositMode === 'existingAccount') {
      const connectedObject = obj.connectedDepositAccountsObject || { id: null, name: null }; // before the user picks the answer here
      obj.connectedDepositAccounts = [connectedObject.id];
    } else {
      obj.connectedDepositAccounts = [];
    }

    return obj;
  }

  useEffect(() => {
    // check if all validations are passed and if so, set component status to true
    if (isDirty && isValid) {
      const output = getValues();
      // console.log('isDirty and isValid both true, getValues:', JSON.stringify(output, null, 2));
      // transform the output data before sending them to caller
      const processedOutput = processOutputData(output);
      // console.log('processed output', processedOutput);
      setUserInput({ ...userInput, ...processedOutput });
      updateWorkflowStatus(index, true);
      // reset isDirty status so that we know if the user touched the form again
      reset({ ...userInput, ...output }, { keepIsValid: true });
    }
    // check if the user invalidated the form and if so, remove the next step
    if (isDirty && !isValid) {
      const output = getValues();
      // console.log('isDirty is true and isValid is not, getValues:', JSON.stringify(output, null, 2));
      // CAVEAT: this approach means that the previously entered data is still potentially within the form
      const processedOutput = processOutputData(output);
      setUserInput({ ...userInput, ...processedOutput });
      // console.log('processed output', processedOutput);
      updateWorkflowStatus(index, false);
      // reset isDirty status so that we know if the user touched the form again
      reset({ ...userInput, ...output }, { keepErrors: true });
    }
  }, [formState]);

  //--------------------------------------------------------------------------------
  // subscribe to signal from parent that the user has clicked the "next" button
  // (and then trigger all validations to show errors)
  //--------------------------------------------------------------------------------

  useEffect(() => {
    if (signalNext) {
      trigger();
      setSignalNext(false);
    }
  }, [signalNext]);

  const connectedDepositMode = watch('connectedDepositMode');

  //-------------------------------------------------------------------------------------------------------
  // if accountCurrency !== baseCurrency, display userInputPurchaseDate fx rate for the user's convenience
  //-------------------------------------------------------------------------------------------------------

  // when user selects a non-base-currency for the transaction, get the fx rate in the background and display it
  // when updating this code, remember to change the same code in InflowOutflowTransfer.jsx
  const userInputPurchaseDate = watch('userInputPurchaseDate');
  useEffect(() => {
    async function updateFxRate() {
      if (!isBaseCurrencySameAsAccountCurrency && userInputPurchaseDate) {
        setFxRate('loading');
        let newRate;
        try {
          newRate = await convertCurrency(1, accountCurrency, baseCurrency, dayjs.utc(userInputPurchaseDate).startOf('day').valueOf());
          setFxRate(newRate);
        } catch (err) {
          setFxRate(null);
        }
      }
    }
    updateFxRate();
  }, [accountCurrency, userInputPurchaseDate]);

  // handle the "apply fx rate" button
  function handleFxRateButton() {
    // get a list of dirtyFields and filter them to only leave fieldNames which have payoutAll or payoutOnly and which end in Quote
    // if that list is not empty, apply the fxRate to those fields
    const fieldQuote = getValues('userInputPurchaseValueAccountCurrency');
    if (fieldQuote) {
      setValue('userInputPurchaseValueBaseCurrency', Number((fxRate * fieldQuote).toFixed(2)), { shouldValidate: true });
    }
  }

  //---------------------------------------------------------------------------------------------
  // render the form
  //---------------------------------------------------------------------------------------------

  return (
    <div className="space-y-6 md:space-y-0 md:grid md:grid-cols-4 md:gap-6">
      <div className="md:col-span-1">
        <h3 className="text-xl md:text-2xl font-bold leading-6 text-gray-900">{tI('accountInfo')}</h3>
        <p className="mt-4 text-sm text-gray-500">{tI('provideInfo')}</p>
        <p className="mt-8 text-sm text-gray-500 font-bold">{tI('whyDoWeAsk')}</p>
        <p className="mt-2 text-sm text-gray-500">{tI('whyDoWeAskAnswer')}</p>
      </div>

      <form className="md:col-span-3 ">
        <div className="divide-y divide-gray-200">
          {/* SECTION 1: name, currency, purchaseDate */}

          <div className="py-3 lg:pb-4 grid xs:grid-cols-2 lg:grid-cols-3 gap-4 xs:gap-6 lg:gap-8">
            <InputField
              id="name"
              category="objectsOfValue"
              type="text"
              placeholder={tL('name.placeholder')}
              autoComplete="off"
              optional={false}
              register={register}
              errors={errors}
              validationRules={{ required: { value: true, message: tL('name.error') } }}
            />
            <Dropdown
              id="currency"
              list={currencyCodes}
              label={tL('currency.label')}
              placeholder={tL('currency.placeholder')}
              toolTip={tL('currency.tooltip')}
              register={register}
              optional={false}
              errors={errors}
              control={control}
              validationRules={{ required: { value: true, message: tL('currency.error') } }}
            />
          </div>

          {/* SECTION 2: PURCHASE DETAILS */}

          <div className="py-3 lg:py-8 grid xs:grid-cols-2 lg:grid-cols-3 gap-4 xs:gap-6 lg:gap-8">
            <InputField
              id="userInputPurchaseDate"
              category="objectsOfValue"
              type="date"
              label={tL('userInputPurchaseDate.label')}
              optional={false}
              autoComplete="off"
              register={register}
              errors={errors}
              validationRules={{ required: { value: true, message: tL('userInputPurchaseDate.error') } }}
            />
            <InputField
              id="quantity"
              category="objectsOfValue"
              type="string"
              label={tL('quantity.label')}
              placeholder={1}
              optional={false}
              autoComplete="off"
              register={register}
              errors={errors}
              validationRules={{ required: { value: true, message: tL('quantity.error') } }}
            />
            <InputField
              id="userInputPurchaseValueAccountCurrency"
              category="objectsOfValue"
              type="text"
              label={tL('userInputPurchaseValueAccountCurrency.label', { currency: accountCurrency })}
              formatting="col-start-1"
              placeholder={38000}
              optional={false}
              autoComplete="off"
              register={register}
              errors={errors}
              validationRules={{ required: { value: true, message: tL('userInputPurchaseValueAccountCurrency.error') } }}
            />
            {!isBaseCurrencySameAsAccountCurrency && (
              <div>
                <InputField
                  id="userInputPurchaseValueBaseCurrency"
                  category="objectsOfValue"
                  type="text"
                  label={tL('userInputPurchaseValueBaseCurrency.label', { currency: baseCurrency })}
                  placeholder={38000}
                  optional={false}
                  autoComplete="off"
                  register={register}
                  errors={errors}
                />
                {Number.isFinite(fxRate) && fxRate !== null && (
                  <div className="text-sm mt-2 leading-snug flex flex-col text-gray-700">
                    <div className="flex">
                      <label htmlFor="switch-button" className="font-medium">
                        {`${tL('fxRate.label', { accountCurrency, baseCurrency, date: dayjs.utc(userInputPurchaseDate).format('DD.MM.YYYY') })} ${fxRate.toFixed(4)}`}
                      </label>
                      <ToolTip info={tL('fxRate.tooltip', { baseCurrency })} />
                    </div>
                    <div className="flex items-center w-full mt-1">
                      <div className="flex gap-2 items-center w-full">
                        <button
                          type="button"
                          // eslint-disable-next-line max-len
                          className="rounded-md w-full px-4 py-2 text-sm font-bold shadow-sm border border-gray-300 bg-white text-gray-900 hover:bg-gray-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brandBlue-500"
                          onClick={handleFxRateButton}
                        >
                          {tL('fxRate.apply', { baseCurrency })}
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>

          {/* SECTION 3: CONNECTED DEPOSITS */}

          <div className="py-3 lg:py-8 grid xs:grid-cols-2 lg:grid-cols-3 gap-4 xs:gap-6 lg:gap-8">
            <fieldset className="md:col-span-2 xl:col-span-3 grid md:grid-cols-2 xl:grid-cols-3 justify-items-start items-center gap-4 text-sm text-gray-400">
              <Header text="whenIBuy" />
              <div className="flex items-center">
                <input
                  id="existingAccount"
                  name="existingAccount"
                  type="radio"
                  value="existingAccount"
                  className="h-4 w-4 border-gray-300 text-brandBlue-500 focus:ring-brandBlue-400"
                  {...register('connectedDepositMode', { required: { value: true, message: tL('connectedDepositMode.error') } })}
                />
                <label htmlFor="existingAccount" className="ml-3 block text-sm font-medium text-gray-700">
                  {tI('existingAccount')}
                </label>
                {/* if this radio button is selected, display the deposit accounts dropdown */}
              </div>
              {connectedDepositMode === 'existingAccount' && (
                <div className="xl:flex xl:items-center">
                  <label htmlFor="connectedDepositAccountObject" className="flex text-sm font-medium text-gray-700">
                    {tI('existingAccountSelect')}
                  </label>
                  <div className="mt-1 ml-2">
                    <select
                      id="connectedDepositAccountObject"
                      name="connectedDepositAccountObject"
                      className="text-gray-500 shadow-sm focus:ring-brandBlue-400 focus:border-brandBlue-400 block w-full text-sm border-gray-300 rounded-md"
                      {...register('connectedDepositAccountObject')}
                    >
                      {selectDepositAccounts.map((a) => (
                        <option key={a.id} value={a.id}>
                          {a.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              )}

              <div className="flex items-center col-start-1">
                <input
                  id="createAccountLater"
                  name="createAccountLater"
                  type="radio"
                  value="createAccountLater"
                  className="h-4 w-4 border-gray-300 text-brandBlue-500 focus:ring-brandBlue-400"
                  {...register('connectedDepositMode', { required: { value: true, message: tL('connectedDepositMode.error') } })}
                />
                <label htmlFor="createAccountLater" className="ml-3 block text-sm font-medium text-gray-700">
                  {tI('createAccountLater')}
                </label>
              </div>
              {connectedDepositMode === 'createAccountLater' && (
                <div className="flex gap-2">
                  <InformationCircleIcon className="h-6 w-6 text-brandBlue-500 self-start" aria-hidden="true" />
                  {tI('createAccountLaterDescription')}
                </div>
              )}
              <p className="col-start-1 mt-2 text-sm text-red-500" id="connectedDepositMode-error">
                {formState.errors.connectedDepositMode && <span>{formState.errors.connectedDepositMode.message}</span>}
              </p>
            </fieldset>
          </div>
        </div>
      </form>
    </div>
  );
}

ObjectsOfValueAccountData.propTypes = {
  index: PropTypes.number.isRequired,
  updateWorkflowStatus: PropTypes.func.isRequired,
  userInput: PropTypes.object,
  setUserInput: PropTypes.func,
  signalNext: PropTypes.bool,
  setSignalNext: PropTypes.func,
};
ObjectsOfValueAccountData.defaultProps = {
  userInput: {},
  setUserInput: {},
  signalNext: undefined,
  setSignalNext: () => {},
};

export default ObjectsOfValueAccountData;
