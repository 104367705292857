/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import Button from '../../elements/Button';
import { updateCognitoSettings } from '../../redux/actions/user';
import { setMessage } from '../../redux/actions/message';

dayjs.extend(utc);

export default function SliderDates({ setIsDirty }) {
  const [updating, setUpdating] = useState(false);

  const selectUserSettings = useSelector((state) => state.user.profile.settings);

  const dispatch = useDispatch();
  const { t } = useTranslation('app', { keyPrefix: 'settings.simulations' });

  // default settings updated in getSettings (centrally)

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
    getFieldState,
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      sliderStartDate: dayjs(selectUserSettings.sliderStartDate).format('YYYY-MM'),
      sliderEndDate: dayjs(selectUserSettings.sliderEndDate).format('YYYY-MM'),
    },
  }); // intialised after useEffect fetches data from Amplify store

  // keep isDirty state in sync with parent
  useEffect(() => {
    setIsDirty(isDirty);
  }, [isDirty]);

  async function onSubmit(data) {
    // update global settings in cognito
    if (isDirty) {
      setUpdating(true);

      try {
        await dispatch(
          updateCognitoSettings({
            ...(getFieldState('sliderStartDate').isDirty && { sliderStartDate: dayjs.utc(data.sliderStartDate).startOf('month').valueOf() }),
            ...(getFieldState('sliderEndDate').isDirty && { sliderEndDate: dayjs.utc(data.sliderEndDate).startOf('month').valueOf() }),
          }),
        );
        reset(data);
        dispatch(setMessage('dataUpdatedSuccessfully'));
      } catch (error) {
        console.error('Error updating cognito settings', error);
        dispatch(setMessage('dataUpdateError'));
      }

      setUpdating(false);
    }
  }

  return (
    <div className="text-sm space-y-3 sm:grid sm:grid-cols-3 sm:items-start sm:justify-items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
      <div>
        <p>{t('slider')}</p>
        <p className="mt-1 text-xs text-gray-400">{t('setDates')}</p>
      </div>
      <div className="grid grid-cols-2 space-x-2 sm:flex">
        <div className="">
          <p className="text-gray-500">{t('startDate')}</p>
          <input
            type="month"
            name="sliderStartDate"
            id="sliderStartDate"
            {...register('sliderStartDate')}
            className="mt-0.5 flex-1 focus:ring-brandBlue-400 focus:border-brandBlue-400 block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
          />
          {errors.sliderStartDate && (
            <div className="pt-2 sm:text-sm text-brandRed-500 max-w-fit">
              <span>{errors.sliderStartDate.message}</span>
            </div>
          )}
        </div>
        <div className="">
          <p className="text-gray-500">{t('endDate')}</p>
          <input
            type="month"
            name="sliderEndDate"
            id="sliderEndDate"
            {...register('sliderEndDate')}
            className="mt-0.5 flex-1 focus:ring-brandBlue-400 focus:border-brandBlue-400 block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
          />
          {errors.sliderEndDate && (
            <div className="pt-2 sm:text-sm text-brandRed-500 max-w-fit">
              <span>{errors.sliderEndDate.message}</span>
            </div>
          )}
        </div>
      </div>
      {isDirty && (
        <div className="flex justify-end space-x-2 sm:col-start-2 mt-4">
          <Button type="button" onClick={handleSubmit(onSubmit)} text={t('save')} spinnerOn={updating} id="settings-planning-save" withAccent size="lg" />
        </div>
      )}
    </div>
  );
}
SliderDates.propTypes = {
  setIsDirty: PropTypes.func.isRequired,
};
