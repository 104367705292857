import React from 'react';

const dashboard = [
  {
    type: 'intro',
    id: 'DA-intro',
    steps: [
      {
        selector: '#net-worth-kpis',
        content: () => (
          <div>
            <p className="pb-4">
              Willkomen auf deinem
              <strong> Dashboard </strong>
              -- alle Anlagen auf einen Blick!

            </p>
            <p>Ganz links -- die wichtigsten Kennzahlen deiner Finanzen, sowie ihre gegenwärtige und historiche Werte.</p>
          </div>

        ),
      },
      {
        selector: '#kpi-previousValue',
        content: () => (
          <div>
            <p className="pb-4">
              Vergleiche den Ist-Zustand mit der Vergangenheit. Wir nennen diesen vergangenen Zeitpunkt
              {' '}
              <strong>die Baseline.</strong>
            </p>
            <p>Durch Ausklappen diese Feldes nach unten lässt sich das genaue Datum einstellen.</p>
          </div>

        ),
      },
      {
        selector: '#edit-mode',
        content: () => (
          <p>
            Nutze
            {' '}
            <strong>Edit-Modus</strong>
            {' '}
            um Konten hinzufügen und zu löschen.
          </p>
        ),
      },
      {
        selector: '#sync-all-button',
        content: () => (
          <p>
            <strong>Sync-Button</strong>
            {' '}
            holt die neusten Umsätze und Preise.
          </p>
        ),
      },
      {
        selector: '#your-assets',
        content: () => (
          <p>
            <strong>Alle Konten</strong>
            {' '}
            werden hier unten angezeigt. Bewege die Maus über ein Kachel um die letzten Transaktionen zu sehen und klicke für Details.
          </p>
        ),
      },
      {
        selector: '#add-new-deposits-button',
        content: 'Lass uns starten! Wir haben Demo-Daten vorbereitet, um dir den Einstieg zu erleichtern.',
      },
    ],
  },
];

export default dashboard;
