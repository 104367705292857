/* eslint-disable jsx-a11y/anchor-is-valid */
import { React } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  ArrowLeftOnRectangleIcon, ArrowRightOnRectangleIcon, PresentationChartBarIcon, UserPlusIcon,
} from '@heroicons/react/24/outline';
import Button from './Button';
import Logo from '../assets/LogoNotagline';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

function LocalButton({
  route, label, Icon, isWhite, id,
}) {
  return (
    <div id={id}>
      <NavLink
        to={route}
        href="#"
        className={classNames(
          (isWhite)
            ? 'bg-white border-2 border-brandBlue-400 text-brandBlue-500 hover:bg-opacity-75'
            : 'bg-brandBlue-500 border-2 border-transparent text-white hover:bg-brandBlue-600',
          'hidden sm:inline-block py-2 px-4 rounded-md text-base font-medium',
        )}
      >
        {label}
      </NavLink>
      {/* alternative buttons with icons for small displays */}
      <NavLink
        to={route}
        href="#"
        className={classNames(
          (isWhite)
            ? 'bg-white border-2 border-brandBlue-400 text-brandBlue-500 hover:bg-opacity-75'
            : 'bg-brandBlue-500 border-2 border-transparent text-white hover:bg-brandBlue-600',
          'inline-block mt-2 sm:mt-0 sm:hidden py-2 px-4 rounded-md text-base font-medium',
        )}
      >
        <Icon className="h-6 w-6" />
      </NavLink>
    </div>
  );
}
LocalButton.propTypes = {
  route: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  isWhite: PropTypes.bool.isRequired,
  Icon: PropTypes.elementType.isRequired,
  id: PropTypes.string.isRequired,
};

export default function header() {
  const { isLoggedIn } = useSelector((state) => state.user);
  const { t, i18n } = useTranslation(['site']);

  const navigate = useNavigate();

  const lang = i18n.language;

  const navigation = [
    {
      key: 1, name: t('site:menu.features'), link: `/${lang}/features`,
    },
    {
      key: 2, name: t('site:menu.anonymous'), link: '/goto/anonymous-use',
    },
    {
      key: 3, name: t('site:menu.data'), link: '/goto/data-sources',
    },
    {
      key: 4, name: t('site:menu.blog'), link: `/${lang}/blog`,
    },
    {
      key: 5, name: t('site:menu.meet'), link: `/${lang}/about-us`,
    },
    {
      key: 6, name: t('site:menu.pricing'), link: `/${lang}/prices`,
    },
    {
      key: 7, name: t('site:menu.demo'), link: `/${lang}/demo`,
    },
  ];

  return (
    <div className="relative bg-gray-50 z-10">
      <header className="bg-white shadow relative">
        <nav className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8" aria-label="Top">
          <div className="w-full py-2 flex items-center justify-between border-b border-brandBlue-500 xl:border-none">
            <div className="py-4">
              <NavLink to={`/${lang}/`}>
                <span className="sr-only" role="link" name="homepage">Monestry</span>
                <Logo width="180" height="28" viewBox="0 0 831 126" className="h-6" />
              </NavLink>
            </div>
            <div className="flex items-center">

              {/* NAV LINKS - XL and above */}

              <div className="hidden ml-10 space-x-6 xl:block">
                {navigation.map((link) => (
                  <Link to={link.link} key={link.key} data-testid={`header-${link.key}`} className="text-l font-semibold text-gray-500 hover:text-gray-900">
                    {link.name}
                  </Link>
                ))}
              </div>
            </div>
            <div className="ml-10 space-x-4 flex flex-nowrap">
              {isLoggedIn
                ? (
                  <>
                    <Button key="1" id="dashboard" onClick={() => navigate(`/${lang}/app`)} text={t('site:menu.dashboard')} withAccent />
                    <Button key="2" id="logout" onClick={() => navigate(`/${lang}/logout`)} text={t('site:menu.logout')} noFrame />
                  </>
                ) : (
                  <>
                    <Button key="1" id="signon" onClick={() => navigate(`/${lang}/register`)} text={t('site:menu.register')} withAccent />
                    <Button key="2" id="login" onClick={() => navigate(`/${lang}/login`)} text={t('site:menu.login')} noFrame />
                  </>
                )}
            </div>
          </div>

          {/* NAV LINKS - below XL */}

          <div className="py-4 flex flex-wrap justify-center gap-4 xl:hidden">
            {navigation.map((link) => (
              <a
                key={link.key}
                href={link.href}
                className="text-base font-semibold text-gray-500 hover:text-indigo-50"
              >
                {link.name}
              </a>
            ))}
          </div>
        </nav>
      </header>
    </div>
  );
}
