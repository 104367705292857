import getQuotes from '../../../redux/reducers/sharedThunks/getQuotes';
import { calculateDatesAndAssetIds } from '../../../redux/actions/data/helpers';
import { metalsAssets } from '../../../redux/reducers/data';
import store from '../../../store';

// INPUT transformations

// used to put quote object attributes in the exact order expected by table and grid layouts
export function categoryOrderedObject(account = null) {
  //  outer function takes a parameter returns a CALLBACK function for .map with that parameter already in
  return function metalsOrderedObjectInnerFunc(quote) {
    // this has to exactly follow the column order laid out by gridLayout
    return {
      assetName: metalsAssets(store.getState()).find((a) => a.assetId === quote.assetId).assetName,
      date: quote.date,
      source: quote.source,
      quoteBaseCurrency: quote.quoteBaseCurrency,
      currency: quote.currency,
      quote: quote.quote,
      note: quote.note,
    };
  };
}

// applies category-related final changes before sending to Grid
// add displaySymbol based on figi
export function applyCategorySpecificChanges(_quotes, account = null) {
  return _quotes.map(categoryOrderedObject());
}

// provide sources for custom dropdown 'assetName"
export function getCustomDropdownSources() {
  return {
    assetName: metalsAssets(store.getState()).map((ma) => ma.assetName),
  };
}

// OUTPUT transformations

// used inside of .map
// performs category - specific transformations after standard transformations of Grid output
// seqeunce of properties is no longer important
// this happens already after items with inputFlag = 'delete' have been added, so just take care of 'put'
export function outputTransformCategoryQuotes(account = null) {
  //  outer function takes a parameter returns a CALLBACK function for .map with that parameter already in
  return function outputTransformMetalsQuotesInnerFunc(item) {
    return {
      ...item,
      source: item.source || 'manual',
      importFlag: item.importFlag || 'put',
      assetId: item.assetName.replace(/[^a-zA-Z0-9]/g, ''),
    };
  };
}

// TABLE

// used in table to get quotes from backend
export function handleSync(category, _quotes, dispatch) {
  dispatch(getQuotes(calculateDatesAndAssetIds(_quotes)));
}
