/* eslint-disable react/jsx-filename-extension */
import React from 'react';

const tours = [
  {
    type: 'intro',
    id: 'PR-intro',
    steps: [
      {
        selector: '#projectDetailsParent',
        content: () => (
          <div>
            <p className="pb-4">
              Willkomen bei
              {' '}
              <strong>Projektdetails</strong>
              .
            </p>
            <p>
              Damit kannst du die
              {' '}
              <strong>Zukunft</strong>
              {' '}
              simulieren: Ein- und Ausgaben, Anlagentransaktionen und Wertzuwachs.
            </p>
          </div>
        ),
      },
      {
        selector: '#projectSlider',
        content: () => (
          <div>
            <p className="pb-4">Mit diesem Regler bewegst du dich hin und her durch die Zeit.</p>
            <p>
              Das gewählte Datum heißt
              {' '}
              <strong>Regler-Datum</strong>
              .
            </p>
          </div>
        ),
      },
      {
        selector: '#project-transactions-list',
        content: () => (
          <p>
            Deine zukünftige
            {' '}
            <strong>Transaktionen</strong>
            {' '}
            sowie
            {' '}
            <strong>Wertänderungen</strong>
            {' '}
            der Anlagen erscheinen hier.
          </p>
        ),
      },
      {
        selector: '#projectButtonBar :nth-child(2), #projectButtonBar :nth-child(3)',
        content: () => (
          <p>
            <strong>Füge</strong>
            {' '}
            neue zukünftige
            <strong>Transkationen</strong>
            {' '}
            hiermit...
          </p>
        ),
      },
      {
        selector: '#projectButtonBar :nth-child(2), #projectButtonBar :nth-child(3)',
        content: () => (
          <p>
            und zukünftige
            {' '}
            <strong>Wertänderungen der Anlagen</strong>
            {' '}
            hiermit.
          </p>
        ),
      },
      {
        selector: '#project-assets',
        content: () => (
          <div>
            <p>
              Hier erscheinen alle
              {' '}
              <strong>Konten</strong>
              {' '}
              und
              {' '}
              <strong>Anlagen</strong>
              {' '}
              verwendet in diesem Projekt.
            </p>
            <p className="pt-2">
              Die hier sichtbaren Beträge sind die
              {' '}
              <strong>Zukunftswerte</strong>
              {' '}
              am Regler-Datum.
            </p>
          </div>
        ),
      },
      {
        selector: '#projectSum',
        content: () => (
          <p>
            Das ist der
            {' '}
            <strong>Gesamtwert</strong>
            {' '}
            aller Projektanlagen am Regler-Datum.
          </p>
        ),
      },
      {
        selector: '#project-details-add-goal-button',
        content: () => (
          <p>
            Setze
            {' '}
            <strong>Ziele</strong>
            {' '}
            für dein Projekt hier.
          </p>
        ),
      },
      {
        selector: '#project-details-apply-template-button',
        content: () => (
          <div>
            <p>
              Als Starthilfe kannst du
              {' '}
              <strong>Musterprojekte</strong>
              {' '}
              mit Beispieltransaktionen importieren und entfernen.
            </p>
            <p className="pt-2 italic">Beispiel: Kauf einer Mietimmobilie.</p>
          </div>
        ),
      },
      {
        selector: '#projectIsolated',
        content: () => (
          <div>
            <p>
              Normalerweise (
              {' '}
              <span className="italic">&quot;ja&quot;</span>
              ) sind die Projekttransaktionen und Wertänderungen
              {' '}
              <strong>sichtbar</strong>
              {' '}
              in außerhalb dieser Maske (in Kontodetails sowie in allen Kerndaten und Reports).
            </p>
            <p className="pt-2">
              Dies kann verhindert werden, indem du hier
              <span className="italic">&quot;nein&quot;</span>
              wählst.
            </p>
          </div>
        ),
      },
    ],
  },
];

export default tours;
