/* eslint-disable react/prop-types */
/* eslint-disable object-curly-newline */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LockClosedIcon, LockOpenIcon } from '@heroicons/react/20/solid';

// InputField component
// with a wrapper which passes targetAllocation and setTargetAllocation to it
// NOT controlled: state is updated onBlur
export default function InputFieldWrapper(targetAllocation, setTargetAllocation, recalculatePressed) {
  return function InputField({ value, rowData, id }) {
    //
    // helper function -- get the correct entry from targetAllocation based on tableLevel field
    function getMatchingEntry(inputState) {
      // console.log('getMatchingEntry: finding matchingEntry for', rowData, id, 'in', inputState);
      if (inputState.length === 0) return null;

      let matchingEntry;
      const { tableLevel } = rowData;
      const currency = id.replace('Percent', '');

      if (tableLevel === 'category') {
        // relevant columns in this row type: categoryPercent, EURPercent, USDPercent, etc.
        if (id === 'categoryPercent') {
          matchingEntry = inputState.find((entry) => entry.category === rowData.category && !entry.assetClass && !entry.currency);
        } else {
          matchingEntry = inputState.find((entry) => entry.category === rowData.category && !entry.assetClass && entry.currency === currency);
        }
      }

      if (tableLevel === 'assetClass') {
        matchingEntry = inputState.find((entry) => entry.category === rowData.category && entry.assetClass === rowData.assetClass && entry.currency === currency);
      }
      return matchingEntry;
    }

    const [disabled, setDisabled] = useState(false);
    const [displayDialog, setDisplayDialog] = useState(false);
    const [inputValueMobileView, setInputValueMobileView] = useState(null); // used in the mobile dialog below; not initialised and only used to pass values from the input field to the button

    const { t } = useTranslation('app', { keyPrefix: 'rebalancing' });

    const matchingEntry = getMatchingEntry(targetAllocation);
    // console.log('matchingEntry', matchingEntry, matchingEntry?.value, rowData[id] * 100);
    const inputValue = (matchingEntry?.value || (rowData[id] * 100)).toFixed(2);
    const locked = matchingEntry?.locked || false;

    // when user presses Recalculate, move the values from the form to targetAllocation
    useEffect(() => {
      // update NULL entries in targetAllocation with the current value
      if (recalculatePressed && matchingEntry?.value === null) {
        setTargetAllocation((prev) => {
          const newTargetAllocation = [...prev];
          const matchingEntryLocal = getMatchingEntry(newTargetAllocation);
          matchingEntryLocal.value = Number(rowData[id] * 100);
          return newTargetAllocation;
        });
      }
    }, [recalculatePressed]);

    useEffect(() => {
      function handleOutputMode(event) {
        if (event.detail === true) {
          setDisabled(true);
        }
        if (event.detail === false) {
          setDisabled(false);
        }
      }

      document.addEventListener('rebalancingOutputMode', handleOutputMode);

      return () => {
        document.removeEventListener('rebalancingOutputMode', handleOutputMode);
      };
    }, []);

    function handleMobileDialog() {
      setDisplayDialog(true);
    }

    return (
      <>
        <div className="relative flex justify-end space-x-1 items-center">
          {/* low-res version */}
          <button
            className="sm:hidden mb-1 flex-1 border border-gray-300 bg-gray-50 shadow-sm py-1.5 px-1 focus:ring-brandBlue-400 focus:border-brandBlue-400 w-full min-w-0 rounded-md sm:text-sm text-right"
            type="button"
            disabled={disabled}
            onClick={handleMobileDialog}
          >
            {inputValue}
            <span className="ml-1 text-sm font-medium text-gray-700">%</span>
          </button>
          {/* high-res version */}
          <input
            className="hidden sm:block flex-1 border border-gray-300 py-0.5 px-1 focus:ring-brandBlue-400 focus:border-brandBlue-400 w-full min-w-0 rounded-md sm:text-sm text-right"
            type=""
            disabled={disabled}
            defaultValue={inputValue}
            onBlur={(e) => {
              setTargetAllocation((prev) => {
                const newTargetAllocation = [...prev];
                const matchingEntryLocal = getMatchingEntry(newTargetAllocation);
                matchingEntryLocal.value = Number(e.target.value);
                return newTargetAllocation;
              });
            }}
          />
          <span className="hidden sm:inline ml-2 text-sm font-medium text-gray-700">%</span>
          {(!disabled) && (
          <button
            type="button"
            className="absolute left-1 hidden sm:inline-block"
            disabled={disabled}
            onClick={() => {
              setTargetAllocation((prev) => {
                const newTargetAllocation = [...prev];
                const matchingEntryLocal = getMatchingEntry(newTargetAllocation);
                matchingEntryLocal.value = Number(inputValue);
                matchingEntryLocal.locked = !locked;
                return newTargetAllocation;
              });
            }}
          >
            {(locked)
              ? <LockClosedIcon className="h-4 w-4 text-gray-400" />
              : <LockOpenIcon className="h-4 w-4 text-gray-300" />}
          </button>
          )}
        </div>
        {/* mobile-res dialog for entering values and locking position; position is absolute relative to the entire table row */}
        {(displayDialog) && (
          <div className="absolute flex space-x-4 justify-between items-center z-10 top-0 left-0 bg-white w-full h-full opacity-100">
            <button
              type="button"
              className="ml-4 py-2 px-4 border border-gray-300 cursor-pointer bg-gray-50 shadow-sm rounded-md"
              disabled={disabled}
              onClick={() => {
                setTargetAllocation((prev) => {
                  const newTargetAllocation = [...prev];
                  const matchingEntryLocal = getMatchingEntry(newTargetAllocation);
                  matchingEntryLocal.value = Number(inputValue);
                  matchingEntryLocal.locked = !locked;
                  return newTargetAllocation;
                });
              }}
            >
              {(locked)
                ? <LockClosedIcon className="h-4 w-4 text-gray-400" />
                : <LockOpenIcon className="h-4 w-4 text-gray-300" />}
            </button>
            <div className="flex items-center">
              <input
                className="border border-gray-300 py-2 px-1 focus:ring-brandBlue-400 focus:border-brandBlue-400 w-full min-w-0 rounded-md sm:text-sm text-right"
                type=""
                disabled={disabled}
                defaultValue={inputValue} // intialised to whatever is available for the form
                onChange={(e) => { setInputValueMobileView(e.target.value); }} // when user changes the value, update the state, so that the button below can write it to targetAllocation
              />
              <span className="ml-2 text-sm font-medium text-gray-700">%</span>
            </div>
            <button
              type="button"
              className="py-2 px-2 border border-gray-300 cursor-pointer bg-gray-50 shadow-sm rounded-md"
              disabled={disabled}
              onClick={(e) => {
                setTargetAllocation((prev) => {
                  const newTargetAllocation = [...prev];
                  const matchingEntryLocal = getMatchingEntry(newTargetAllocation);
                  matchingEntryLocal.value = Number(inputValueMobileView);
                  return newTargetAllocation;
                });
                setDisplayDialog(false);
              }}
            >
              {t('Save')}
            </button>
            <button
              type="button"
              className="py-2 px-2 border border-gray-300 cursor-pointer bg-gray-50 shadow-sm rounded-md"
              disabled={disabled}
              onClick={() => {
                setDisplayDialog(false);
              }}
            >
              {t('Cancel')}
            </button>
          </div>
        )}
      </>
    );
  };
}
