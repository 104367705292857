import React, { useState, useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useDispatch, useSelector } from 'react-redux';
import Tour from 'reactour';
import { Auth } from 'aws-amplify';
import PropTypes from 'prop-types';
import { addSeenTours, setTourLock, clearTourLock } from '../redux/actions/user';

// expects: array of tours that the currently open component wants to display
function TourWrapperInner({ localTours: componentTours, devModeShowTour }) {
  if (componentTours.length === 0) return <div />;
  const [tourState, setTourState] = useState(null);
  const dispatch = useDispatch();

  // localTours: tour object is { id, steps[] }

  // get the tour object from the redux store [ 'tour1', 'tour2' ... ]
  const selectToursSeen = useSelector((state) => state.user.profile?.attributes?.toursSeen);
  const globalTourLock = useSelector((state) => state.user.tourLock); // is true if another tour is displayed
  useEffect(() => {
    async function execLogic() {
      let toursSeen = selectToursSeen;
      // check if there aren't more tours in Amplify local storage and substitute them for toursSeen if that is the case
      // (when user refreshes the page, Redux initialised empty!)
      if (!selectToursSeen) {
        try {
          // in case this is a session with no login (resumed from previous login), get attributes from Amplify Auth store
          // get current user attributes
          const user = await Auth.currentAuthenticatedUser({ bypassCache: true });
          const toursSeenFromUser = user?.attributes['custom:tours_seen'];
          toursSeen = toursSeenFromUser ? JSON.parse(toursSeenFromUser) : [];
        } catch (err) {
          console.log(err);
        }
      }

      // handle what tour to display
      let tours;
      if (typeof devModeShowTour === 'number') {
        // display selected tour regardless what is in user's profile
        tours = componentTours;
      } else {
        // remove all tours from localTours whose ids are in toursSeen
        tours = componentTours.filter((tour) => !toursSeen || !toursSeen.includes(tour.id));
      }

      if (tours.length > 0 && !globalTourLock) {
        // state.user.tourLock is true if another tour is being displayed already
        // try to get tourLock from state -- this function (action creator) returns true if the lock is available, false if it is not
        if (dispatch(setTourLock())) {
          // get the first tour from list and display it
          setTourState({ tourToShow: tours[typeof devModeShowTour === 'number' ? devModeShowTour : 0], toursSeen });
        }
      }
    }

    execLogic();
  }, [selectToursSeen]);

  if (!tourState) return <div />;

  return (
    <Tour
      steps={tourState?.tourToShow.steps}
      isOpen={!!tourState} // true if tourState has contents
      rounded={4} // rounded corners
      accentColor="#1797f2"
      disableInteraction
      onRequestClose={() => {
        dispatch(addSeenTours(tourState.tourToShow.id));
        setTourState(null);
        // free the global tour lock so that another tour can be displayed
        dispatch(clearTourLock());
      }}
    />
  );
}

TourWrapperInner.propTypes = {
  localTours: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      // eslint-disable-next-line react/forbid-prop-types
      steps: PropTypes.array.isRequired,
    }),
  ).isRequired,
  devModeShowTour: PropTypes.number,
};
TourWrapperInner.defaultProps = {
  devModeShowTour: null,
};

function Empty() {
  return <div />;
}

export default function TourWrapper({ localTours, devModeShowTour }) {
  return (
    <ErrorBoundary FallbackComponent={Empty}>
      <TourWrapperInner localTours={localTours} devModeShowTour={devModeShowTour} />
    </ErrorBoundary>
  );
}
TourWrapper.propTypes = TourWrapperInner.propTypes;
TourWrapper.defaultProps = TourWrapperInner.defaultProps;
