import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { createSelectorCreator, defaultMemoize } from 'reselect';
import { projectBalancesArray } from '../../redux/reducers/globalSelectors/overarching';

const debugLevel = 0;

export function ProgressRoiCore({ project }) {
  const { id: projectId } = project;
  const { t } = useTranslation('app', { keyPrefix: 'projects' });

  const createSelectorWithDebug = createSelectorCreator(
    defaultMemoize,
    // eslint-disable-next-line arrow-body-style
    (a, b) => {
      console.log('Comparing:', a, b); // Log the values being compared
      return a === b; // Use the default equality check
    },
  );

  // project value / invested amount: only up to the sliderDate
  // invested amount = asset purchases + external costs (outflows) - calculated in projectBalances
  const currentInvestedAmount = useSelector(projectBalancesArray)
    .reduce((acc, curr) => (curr.projectId === projectId ? curr.current.investedAmount + acc : acc), 0); // purchases and outflows are both negative

  const currentValue = useSelector(projectBalancesArray)
    .reduce((acc, curr) => (curr.projectId === projectId ? curr.current.valueBaseCurrency + acc : acc), 0);

  // project value / invested amount at next goal
  // invested amount = asset purchases + external costs (outflows) - calculated in projectBalances
  const projectNextGoalInvestedAmount = useSelector(projectBalancesArray)
    .reduce((acc, curr) => (curr.projectId === projectId ? curr.nextGoalDate.investedAmount + acc : acc), 0); // purchases and outflows are both negative

  const projectSumAtNextGoal = useSelector(projectBalancesArray)
    .reduce((acc, curr) => (curr.projectId === projectId ? curr.nextGoalDate.valueBaseCurrency + acc : acc), 0);

  const currentRoi = currentValue + currentInvestedAmount;
  const currentRoiPercent = currentInvestedAmount ? currentRoi / -currentInvestedAmount : 0;

  const projectNextGoalRoi = projectSumAtNextGoal + projectNextGoalInvestedAmount;
  const projectNextGoalRoiPercent = projectNextGoalInvestedAmount ? projectNextGoalRoi / -projectNextGoalInvestedAmount : 0;

  if (debugLevel > 2) console.log('ProgressRoi', 'currentValue', currentValue, 'currentInvestedAmount', currentInvestedAmount);
  if (debugLevel > 2) console.log('ProgressRoi', 'projectSumAtEnd', projectSumAtNextGoal, 'projectEndInvestedAmount', projectNextGoalInvestedAmount);

  return (
    <div className="sm:px-2 lg:px-0 xl:px-2 w-full grid grid-cols-2 gap-4 text-xs sm:text-sm text-gray-500">
      <div>
        <div className="font-base">{t('roiSliderDate')}</div>
        <div className="text-[1rem] transform scale-y-110 sm:scale-y-100 font-bold text-gray-900 sm:flex sm:items-baseline lg:block xl:flex gap-1 xl:text-lg">
          {currentInvestedAmount < 0
            ? currentRoi.toLocaleString('de', { style: 'currency', currency: 'EUR', maximumSignificantDigits: 4 })
            : <span className="text-lg font-normal">{t('noInvestment')}</span>}
          <span className={`${currentRoiPercent >= 0 ? 'text-green-500' : 'text-brandRed-500'} flex items-baseline text-xs sm:text-sm font-semibold`}>
            {currentInvestedAmount < 0 && currentRoiPercent.toLocaleString('de', { style: 'percent', maximumFractionDigits: 1 })}
          </span>
        </div>
      </div>
      <div>
        <div className="font-base">{t('roiNextGoal')}</div>
        <div className="text-[1rem] transform scale-y-110 sm:scale-y-100 font-bold text-gray-900 sm:flex sm:items-baseline lg:block xl:flex gap-1 xl:text-lg">
          {projectNextGoalInvestedAmount < 0
            ? projectNextGoalRoi.toLocaleString('de', { style: 'currency', currency: 'EUR', maximumSignificantDigits: 4 })
            : <span className="text-lg font-normal">{t('noInvestment')}</span>}
          <span className={`${projectNextGoalRoiPercent >= 0 ? 'text-green-500' : 'text-brandRed-500'} flex items-baseline text-xs sm:text-sm font-semibold`}>
            {projectNextGoalInvestedAmount < 0 && projectNextGoalRoiPercent.toLocaleString('de', { style: 'percent', maximumFractionDigits: 1 })}
          </span>
        </div>
      </div>
    </div>
  );
}
ProgressRoiCore.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  project: PropTypes.object.isRequired,
};

const ProgressRoi = React.memo(ProgressRoiCore);
export default ProgressRoi;
