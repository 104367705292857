/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { MagnifyingGlassIcon } from '@heroicons/react/24/solid';
import dayjs from 'dayjs';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import { allTransactions, globalAccountsView } from '../redux/reducers/data';
import MiniSpinner from '../misc/MiniSpinner';
import { decor } from './tileDecor';
import posts from '../blog/posts.json';
import i18n from '../i18n';
import { getArticleLink } from '../blog';

// As the entire HeaderApp is placed BELOW the main section (to enable overlaps),
// the search contents must be displayed above the main section and controlled by state
// of Dashboard
// Dashboard, the parent component, manages searchControl ({ text (from input box), searching: true|false, focus: true|false })
export default function SearchInput({ searchControl, setSearchControl, theme }) {
  const [inputValue, setInputValue] = React.useState('');

  let timer1;
  useEffect(() => {
    if (inputValue) {
      if (inputValue.length > 2) {
        // if this is another character entered within 800ms of the last one, cancel previous timer and set a new one
        // the searching property is already true (no need to modify state again, as it will trigger other renders)
        if (searchControl?.searching === false) setSearchControl({ ...searchControl, searching: true });
        timer1 = setTimeout(() => setSearchControl({ ...searchControl, text: inputValue }), 800);
      } else {
        setSearchControl({ ...searchControl, text: inputValue });
      }
    }
    return () => {
      clearTimeout(timer1);
    };
  }, [inputValue]);

  // blur should happen except when the user clicks within SearchResults
  // clicks within SearchResults are handled with MouseDown events instead of Click events, which do not fire blur here
  function handleOnBlur(e) {
    setSearchControl({ ...searchControl, searching: false, text: '' });
    setInputValue('');
  }

  return (
    <div className="pr-12 pl-12 xs:pl-44 md:px-12 lg:px-0">
      <div className="max-w-xs mx-auto w-full lg:max-w-lg">
        <label htmlFor="search" className="sr-only">
          Search
        </label>
        <div className={`relative ${theme === 'light' ? 'text-gray-400' : 'text-white'} focus-within:text-gray-600`}>
          <div className="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center">
            <MagnifyingGlassIcon className="h-5 w-5" aria-hidden="true" />
          </div>
          <input
            id="search"
            className={`block w-full text-white bg-white bg-opacity-20 py-2 pl-10 pr-3 border ${
              theme === 'light' ? 'border-2 border-gray-300 focus:border-brandBlue-600' : 'border-transparent focus:bg-opacity-100 focus:border-transparent'
            } rounded-md leading-5 focus:text-gray-900 placeholder-white focus:outline-none  focus:placeholder-gray-500 focus:ring-0 sm:text-sm`}
            placeholder="Search"
            type="search"
            name="search"
            value={inputValue}
            onBlur={handleOnBlur}
            onChange={(e) => setInputValue(e.target.value)}
          />
        </div>
      </div>
    </div>
  );
}
SearchInput.propTypes = {
  searchControl: PropTypes.objectOf(PropTypes.any).isRequired,
  setSearchControl: PropTypes.func.isRequired,
  theme: PropTypes.string,
};
SearchInput.defaultProps = {
  theme: 'dark',
};

function removeMarkdownTags(str) {
  return str
    .replace(/!\[.*?\]\(.*?\)/g, '') // Remove images
    .replace(/\[.*?\]\(.*?\)/g, '') // Remove links
    .replace(/`.*?`/g, '') // Remove inline code
    .replace(/```[\s\S]*?```/g, '') // Remove code blocks
    .replace(/#+\s/g, '') // Remove headers
    .replace(/[*_]{1,2}(.*?)[*_]{1,2}/g, '$1') // Remove bold/italic
    .replace(/~~(.*?)~~/g, '$1') // Remove strikethrough
    .replace(/(\*\n|\*\s|\n)/g, ''); // Remove list items and new lines
}

export function SearchResults({ searchControl, setSearchControl, setAccount, positionAdjustment }) {
  const [searchResults, setSearchResults] = React.useState([]);
  const [pagination, setPagination] = useState({ page: 0 });
  const selectTransactions = useSelector((state) => allTransactions(state));
  const selectAccounts = useSelector((state) => globalAccountsView(state));
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);

  const { text } = searchControl || '';

  // calculate search results
  // if text in searchControl.text appears, kick off search and upload results to local state
  useEffect(() => {
    async function asyncWrapper() {
      // if (text.length < 3) return;
      if (text.length < 3) {
        setSearchResults({});
        return;
      }

      // SEARCH TRANSACTIONS

      const resultsTransactions = new Promise((resolve, reject) => {
        // return null if not logged in
        if (isLoggedIn) resolve(null);

        const result = selectTransactions.filter((transaction) => {
          // console.log('transaction', transaction);
          // make a date searchable in a human-readable format
          // create an array of search terms for each transaction
          const searchTerms = [dayjs(transaction.date).format('DD.MM.YYYY')];
          // add category-specific search fields
          if (transaction.category === 'deposits') {
            searchTerms.push(transaction.description, transaction.quantity * transaction.upbc, transaction.quantity * transaction.upac, transaction.otherParty, transaction.otherPartyAccount);
          }
          if (transaction.category === 'stocks') {
            searchTerms.push(
              transaction.displayName,
              transaction.displaySymbol,
              transaction.quantity,
              transaction.uptc,
            );
          }
          // for real estate only accounts are searchable
          // return true to filter if the searched term is found in any of the search terms
          return searchTerms.some((term) => String(term).toLowerCase().includes(String(text.toLowerCase())));
        });
        if (result.length === 0) {
          setSearchResults(({ transactions, ...rest }) => rest);
          resolve(null);
        } else {
          setSearchResults({ ...searchResults, transactions: result });
          resolve(null);
        }
      });

      // SEARCH ACCOUNTS

      const resultsAccounts = new Promise((resolve, reject) => {
        // return null if not logged in
        if (isLoggedIn) resolve(null);

        const result = selectAccounts.filter((account) => {
          const searchTerms = [account.name];
          if (account.type === 'real-estate') {
            searchTerms.push(account.streetAddress, account.zip, account.country, account.city);
          }
          if (account.type === 'stocks') {
            searchTerms.push(account.brokerName, account.accountNo);
          }
          if (account.type === 'deposits') {
            searchTerms.push(account.iban, account.bic, account.bankName); // TODO: add syncType when translated
          }
          return searchTerms.some((term) => String(term).toLowerCase().includes(String(text.toLowerCase())));
        });
        if (result.length === 0) {
          setSearchResults(({ accounts, ...rest }) => rest);
          resolve(null);
        } else {
          setSearchResults({ ...searchResults, accounts: result });
          resolve(result);
        }
      });

      // SEARCH BLOG POSTS
      const resultsBlog = new Promise((resolve, reject) => {
        const reducedPosts = posts.map((post) => ({
          id: post.id,
          title: post[i18n.language].title,
          datetime: post[i18n.language].datetime,
          // remove markdown tags from body
          body: removeMarkdownTags(post[i18n.language].body),
        }));

        const result = reducedPosts.filter((post) => {
          const searchTerms = [post.title, post.body, post.datetime];
          return searchTerms.some((term) => String(term).toLowerCase().includes(String(text.toLowerCase())));
        });

        if (result.length === 0) {
          setSearchResults(({ blog, ...rest }) => rest);
          resolve(null);
        } else {
          setSearchResults({ ...searchResults, blog: result });
          resolve(result);
        }
      });

      await Promise.all([resultsTransactions, resultsAccounts, resultsBlog]);
    }
    asyncWrapper();
  }, [searchControl.text]);

  // to make sure the spinner goes away at the exact moment the results are ready:
  useEffect(() => {
    setSearchControl({ ...searchControl, searching: false });
  }, [searchResults]);

  const topLevelDiv = useRef();

  function handleOnMouseDown(e) {
    setSearchControl({ ...searchControl, focus: 'SearchResults' });
  }

  function handleOnBlur(e) {
    if (searchControl.focus !== 'SearchInput') {
      setSearchControl({ ...searchControl, searching: false, text: '' });
    }
  }

  return (
    <div
      className={`absolute z-50 ${positionAdjustment || 'right-0 top-0'} w-full sm:max-w-5xl lg:w-[64rem] rounded-sm shadow border border-gray-400 bg-white p-4 md:mr-8`}
      // onMouseDown fires before onBlur on Input and lets us update focus state before the blur event checks it
      // then need to fire click event to make sure it acts normally for the user, see: https://stackoverflow.com/a/57630197/2540323
      onMouseDownCapture={handleOnMouseDown}
      onBlur={handleOnBlur}
    >
      <div className="flex items-center space-x-2">
        <h2 className="font-bold text-gray-900 text-lg">Search results</h2>
        {searchControl.searching && <MiniSpinner className="h-5 w-5 animate-spin" />}
      </div>
      {Object.entries(searchResults).map(([collection, results]) => (
        <SearchResultsSection key={collection} collection={collection} results={results} setAccount={setAccount} />
      ))}
      {searchControl.text.length <= 2 && <p className="text-gray-500 italic">Enter at least 2 characters to start search.</p>}
      {searchControl.text.length > 2 && Object.keys(searchResults).length === 0 && <p className="text-gray-500 italic">Nothing found.</p>}
    </div>
  );
}
SearchResults.propTypes = {
  searchControl: PropTypes.objectOf(PropTypes.any).isRequired,
  setSearchControl: PropTypes.func.isRequired,
  setAccount: PropTypes.func,
  positionAdjustment: PropTypes.string,
};
SearchResults.defaultProps = {
  setAccount: () => {},
  positionAdjustment: null,
};

function SearchResultsSection({ collection, results, setAccount }) {
  const [currentPage, setCurrentPage] = useState(0);
  const { t } = useTranslation();

  const resultsPerPage = 5;
  return (
    <section>
      <div className="flex gap-2 py-2">
        {/* section header */}
        <h3 className="font-bold text-gray-700 text-sm uppercase tracking-wider py-2">{t(`app:schema.${collection}`)}</h3>
        {/* pagination buttons */}
        <nav className="isolate inline-flex -space-x-px" aria-label="Pagination">
          <button
            type="button"
            onMouseDown={(e) => {
              e.preventDefault();
              if (currentPage > 0) setCurrentPage(currentPage - 1);
            }} // see comment in SearchInput
            className="relative inline-flex items-center py-0.5 px-1.5 group focus:z-20"
          >
            <span className="sr-only">Previous</span>
            <ChevronLeftIcon className={`h-5 w-5 ${currentPage === 0 ? 'text-gray-300' : 'text-gray-500 group-hover:text-gray-900'}`} aria-hidden="true" />
          </button>
          <button
            type="button"
            onMouseDown={(e) => {
              e.preventDefault();
              if (currentPage < Math.floor(results.length / resultsPerPage)) setCurrentPage(currentPage + 1);
            }} // see comment in SearchInput
            className="relative inline-flex items-center py-0.5 px-1.5 text-sm font-medium text-gray-500 group focus:z-20"
          >
            <span className="sr-only">Next</span>
            <ChevronRightIcon className={`h-5 w-5 ${currentPage === Math.floor(results.length / resultsPerPage) ? 'text-gray-300' : 'text-gray-500 group-hover:text-gray-900'}`} aria-hidden="true" />
          </button>
        </nav>
      </div>
      <ul className="grid grid-cols-1 divide-y divide-gray-200">
        {results.slice(0 + currentPage * resultsPerPage, resultsPerPage + currentPage * resultsPerPage).map((result) => (
          <SearchResultCard key={result.id} result={result} setAccount={setAccount} currentCollection={collection} />
        ))}
      </ul>
    </section>
  );
}
SearchResultsSection.propTypes = {
  collection: PropTypes.string.isRequired,
  results: PropTypes.array.isRequired,
  setAccount: PropTypes.func.isRequired,
};

function SearchResultCard({ result, setAccount, currentCollection }) {
  const selectAccounts = useSelector((state) => globalAccountsView(state));

  function getAccountById(accountId) {
    return selectAccounts.find((account) => account.id === accountId);
  }

  const [account, _] = useState(getAccountById(result.accountId || result.id));

  function handleClick(e, obj) {
    // this has to handle both transactions and accounts only as blog posts are handled differently (in code)
    // it calls AccountDetails with the account object (slightly modified)
    const accountObject = {
      category: result.category,
      ...account,
      accountId: result.accountId || result.id, // AccountDetails needs this for backwoards compatibility
    };
    if (obj.accountId) accountObject.highlightTransactionId = result.id;
    setAccount(accountObject);
  }

  if (currentCollection === 'transactions') {
    return (
      <button key={result.id} id={result.id} type="button" className="col-span-1 px-2 py-1 bg-white hover:bg-gray-50" onMouseDown={(e) => handleClick(e, result)}>
        <div className="w-full items-center grid grid-cols-5 space-x-3">
          <span className={`inline-block flex-shrink-0 px-2 py-0.5 text-xs font-medium ${decor[account.category].color.bg} ${decor[account.category].color.text}`}>{account.name}</span>
          <h3 className="truncate text-sm font-medium text-gray-900">{result.otherParty || result.displayName}</h3>
          <h3 className="truncate text-sm font-medium text-gray-900">{`${result.description || result.transactionType}`}</h3>
          <p className="truncate text-sm text-gray-500">
            {new Date(result.date).toLocaleDateString('de', {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
              timeZone: 'UTC',
            })}
          </p>
          <p className="truncate text-sm text-gray-500">
            {(result?.accountCurrencyAmount || result?.transactionAmount * result?.transactionPrice).toLocaleString('de', {
              style: 'currency',
              currency: result.currency || 'EUR',
              maximumFractionDigits: 2,
            })}
          </p>
        </div>
      </button>
    );
  }

  if (currentCollection === 'accounts') {
    return (
      <button key={result.id} id={result.id} type="button" className="col-span-1 px-2 py-1 bg-white hover:bg-gray-50" onMouseDown={(e) => handleClick(e, result)}>
        <div className="w-full items-center grid grid-cols-5 space-x-3">
          <span className={`inline-block flex-shrink-0 px-2 py-0.5 text-xs font-medium ${decor[account.category].color.bg} ${decor[account.category].color.text}`}>{result.name}</span>
          <h3 className="truncate text-sm font-medium text-gray-900">{result.bankName || result.brokerName || result.streetAddress}</h3>
          <h3 className="truncate text-sm font-medium text-gray-900">{result.iban || result.accountNo || result.city}</h3>
          <p className="truncate text-sm text-gray-500">{result.bic || result.country || ''}</p>
        </div>
      </button>
    );
  }

  if (currentCollection === 'blog') {
    return (
      <button
        key={result.id}
        id={result.id}
        type="button"
        className="col-span-1 px-2 py-1 bg-white hover:bg-gray-50"
        onMouseDown={() => {
          window.open(`/${i18n.language}/blog/${getArticleLink(result.id, i18n.language)}`, '_blank');
        }}
      >
        <div className="w-full items-center grid grid-cols-3 space-x-3">
          <span className="inline-block flex-shrink-0 px-2 py-0.5 text-xs font-bold text-gray-900">BLOG</span>
          <h3 className="col-span-2 truncate text-sm font-medium text-gray-900">{result.title}</h3>
        </div>
      </button>
    );
  }
}
SearchResultCard.propTypes = {
  result: PropTypes.objectOf(PropTypes.any).isRequired,
  setAccount: PropTypes.func.isRequired,
  currentCollection: PropTypes.string.isRequired,
};
