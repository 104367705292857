/* eslint-disable react/no-unknown-property */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line import/no-extraneous-dependencies
import MfaPane from './MfaPane';
import LoginPane from './LoginPane';
import ForgotPane from './ForgotPane';
import NewPasswordPane from './NewPasswordPane';
import image from '../../assets/login_photo.jpg';

function Login() {
  const { t, i18n } = useTranslation();
  const location = useLocation();

  const { state } = location; // RequireAuth in App passes the intended destination's pathname through location.state

  const [displayedPane, setDisplayedPane] = useState('login');

  const [afterLoginGoTo, setAfterLoginGoTo] = useState(null);

  const [user, setUser] = useState(null); // user object from cognito
  const [recoveryEmail, setRecoveryEmail] = useState(null); // user's recovery email

  useEffect(() => {
    if (state) setAfterLoginGoTo((!state?.afterLoginGoTo) ? `/${i18n.language}/app` : state?.afterLoginGoTo);
  }, []);

  return (
    <div className="min-h-full flex" data-testid="login">
      <div className="flex-1 flex flex-col justify-center py-48 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
        {(displayedPane === 'login') && <LoginPane setUser={setUser} setDisplayedPane={setDisplayedPane} afterLoginGoTo={afterLoginGoTo} />}
        {(displayedPane === 'mfa') && <MfaPane user={user} afterLoginGoTo={afterLoginGoTo} setDisplayedPane={setDisplayedPane} />}
        {(displayedPane === 'forgotPassword') && <ForgotPane setDisplayedPane={setDisplayedPane} setRecoveryEmail={setRecoveryEmail} />}
        {(displayedPane === 'newPassword') && <NewPasswordPane setDisplayedPane={setDisplayedPane} recoveryEmail={recoveryEmail} />}
      </div>
      <div className="hidden lg:block relative w-0 flex-1">
        <img
          className="absolute inset-0 h-full w-full object-cover"
          // src="https://source.unsplash.com/pcKqvvuTvJM/1600x900"
          src={image}
          alt=""
        />
      </div>
    </div>
  );
}

export default Login;
