/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {
  useState, useEffect, useMemo, useRef,
} from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { XMarkIcon } from '@heroicons/react/24/solid';
import dayjs from 'dayjs';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { quote as quoteSchema } from '@monestry-dev/schema';
import { currencyCodes } from '../../misc/currencyCodes';
import { convertCurrency } from '../../misc/ecbCurrencyRates';
import MiniSpinner from '../../misc/MiniSpinner';
import Dropdown from '../../elements/Dropdown';
import ToolTip from '../../elements/ToolTipNoIcon';
import AssetNamePicklist from './AssetNamePicklist';
import ToolTipOnlyIcon from '../../elements/ToolTipOnlyIcon';
import { putOrDeleteQuotes } from '../../redux/reducers/quotes';
import { globalQuotesArrayView } from '../../redux/reducers/globalSelectors';
import { setMessage } from '../../redux/actions/message';

const debugLevel = process.env.REACT_APP_MDEBUG || 0;

const utc = require('dayjs/plugin/utc');

dayjs.extend(utc);

// quoteDialog can have a quote object, true or null
// can be called to add a new quote (with setQuoteDialog === true)
// or to edit a quote (with setQupteDialog containing the quote object)
export default function AddProjectQuote({
  quoteDialog, setQuoteDialog, assetsList, project,
}) {
  const { t } = useTranslation('app', { keyPrefix: 'projects' });
  const selectBaseCurrency = useSelector((state) => state.user.profile.settings.baseCurrency) || 'EUR';
  const baseCurrency = useMemo(() => selectBaseCurrency, [selectBaseCurrency]);

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false); // save button was clicked
  const [deleting, setDeleting] = useState(false); // delete button was clicked
  const [showFxRate, setShowFxRate] = useState(true); // currency section
  const [fxRateLoading, setFxRateLoading] = useState(false); // currency section
  const [existingQuoteError, setExistingQuoteError] = useState(false); // quote already exists

  // receive a list of asset tiles from ProjectDetails with assetId and openAmount
  // caveat: we show a list of assetIds in this project; we do not check if there is enough at the time of the transaction (a warning will show up in TransactionComponent if this is the case)
  function mapToAssetListCallback(asset) {
    return {
      assetId: asset.assetId || asset.accountId, // to handle stocks, realEstate and others
      displayName: asset.displayName,
      displaySymbol: asset.displaySymbol,
    };
  }

  const assetIdsArray = assetsList.map(mapToAssetListCallback);

  const defaultValuesEmptyForm = {
    assetId: null,
    date: dayjs.utc().add(12, 'months').startOf('month').format('YYYY-MM-DD'),
    // first day of current month; toISOString returns UTC time (which is 1 day behind in Berlin), so using Swedish format which the same timezone as Berlin
    quote: 100,
    currency: 'EUR',
    quoteBaseCurrency: 100,
    source: 'manual',
  };

  // initialise the form with values from an existing object or default values
  const formObject = useForm({
    mode: 'onBlur',
    defaultValues: (quoteDialog === true
      ? defaultValuesEmptyForm
      : {
        ...quoteDialog,
        date: dayjs.utc(quoteDialog.date).format('YYYY-MM-DD'), // convert date to the format expected by the input control
        // intialise the asset picklist with the correct object from assetIdsArray (which is used as a source there);
        // "displaySymbol" is hardcoded in the AssetNamePicklist component, hence the strange name, but this contains the asset object
        displaySymbol: mapToAssetListCallback(assetsList.find((a) => a.assetId === quoteDialog.assetId || a.assetId === quoteDialog.accountId)),
      }),
  });
  const {
    handleSubmit, register, unregister, watch, setValue, formState: { errors }, control, trigger, getFieldState,
  } = formObject;

  const currency = watch('currency');
  const quote = watch('quote');
  const quoteBaseCurrency = watch('quoteBaseCurrency');
  const exchangeRate = watch('exchangeRate');
  const displaySymbol = watch('displaySymbol'); // this is {assetId, displayName, displaySymbol}
  const date = watch('date');
  const assetId = displaySymbol?.assetId || null;

  const isolatedProjectId = project.settings?.isIsolated ? project.id : undefined;
  // similar code is in use in ProjectTransactionList (to display quotes there)
  const selectQuotes = useSelector((state) => globalQuotesArrayView(state, isolatedProjectId));
  // only get quotes for assets of this projects (defined in assetIds), by comparing figi, assetId and accountId (we could do it by category, but trying to stay robust)
  const quotes = selectQuotes.filter((item) => item.source === 'manual' && (typeof item.date === 'number') && (assetId === item.accountId || assetId === item.figi || assetId === item.assetId));

  // keep checking if the quote already exists
  useEffect(() => {
    console.log('runnign useEffect', displaySymbol, date, quotes);
    if (displaySymbol && date) {
      if (quotes.some((q) => q.date === dayjs.utc(date).startOf('day').valueOf() && q.assetId === displaySymbol.assetId)) setExistingQuoteError(true);
      else setExistingQuoteError(false);
    }
  }, [displaySymbol, date]);

  // submits the form object to backend
  async function onSubmit(incomingData) {
    // logic for quotes:
    // - if quoteDialog is a 'true', then this is a clear case of posting a new transaction
    // - if quoteDialog is an object, then this is an edit of an existing transaction,
    //   so we need to first delete the old quote and then post the new one (to save ourselves the trouble of figuring out if it can be edited or not)
    // run it through schema before submitting

    try {
      setLoading(true);
      if (quoteDialog === true) {
        const quoteObject = {
          ...incomingData,
          date: dayjs.utc(incomingData.date).startOf('day').valueOf(),
          assetId: incomingData.displaySymbol.assetId,
          projectId: project.id,
          importFlag: 'post',
        };

        if (currency === baseCurrency) quoteObject.quoteBaseCurrency = quoteObject.quote;
        // if currency is not the baseCurrency and if quoteBaseCurrency is not set, use exhangeRate to calculate it
        // user has the option of providing quoteBaseCurrency directly, in which case we don't need to calculate it
        if (currency !== baseCurrency && !quoteObject.quoteBaseCurrency) {
          quoteObject.quoteBaseCurrency = quoteObject.quote * quoteObject.exchangeRate;
        }

        const validatedQuoteObject = await quoteSchema.validate(quoteObject);
        delete validatedQuoteObject.displaySymbol;
        delete validatedQuoteObject.exchangeRate;
        await dispatch(putOrDeleteQuotes({ arrayOfQuotes: [validatedQuoteObject] }));
      }

      if (typeof quoteDialog === 'object') {
        const quoteObject = {
          ...incomingData,
          date: dayjs.utc(incomingData.date).startOf('date').valueOf(),
          assetId: incomingData.displaySymbol.assetId,
          projectId: project.id,
          source: 'manual',
        };

        if (currency === baseCurrency) quoteObject.quoteBaseCurrency = quoteObject.quote;
        // if currency is not the baseCurrency and if quoteBaseCurrency is not set, use exhangeRate to calculate it
        // user has the option of providing quoteBaseCurrency directly, in which case we don't need to calculate it
        if (currency !== baseCurrency && !quoteObject.quoteBaseCurrency) {
          quoteObject.quoteBaseCurrency = quoteObject.quote * quoteObject.exchangeRate;
        }

        const validatedQuoteObject = await quoteSchema.validate(quoteObject);
        delete validatedQuoteObject.displaySymbol;
        delete validatedQuoteObject.exchangeRate;
        await dispatch(putOrDeleteQuotes({ arrayOfQuotes: [{ ...validatedQuoteObject, importFlag: 'delete' }] }));
        await dispatch(putOrDeleteQuotes({ arrayOfQuotes: [{ ...validatedQuoteObject, importFlag: 'put' }] }));
      }
      dispatch(setMessage('dataUpdatedSuccessfully'));
      setLoading(false);
      setQuoteDialog(false);
    } catch (e) {
      console.error(e);
      dispatch(setMessage('dataUpdateError'));
      setLoading(false);
    }
  }

  async function onDelete(e) {
    // delete button only shows up when editing an existing transaction, so no need to check that
    e.preventDefault();
    setDeleting(true);
    try {
      const incomingData = formObject.getValues();

      const quoteObject = {
        ...incomingData,
        date: dayjs.utc(incomingData.date).startOf('date').valueOf(),
        assetId: incomingData.displaySymbol.assetId,
        projectId: project.id,
        source: 'manual',
      };
      const validatedQuoteObject = await quoteSchema.validate(quoteObject);
      await dispatch(putOrDeleteQuotes({ arrayOfQuotes: [{ ...validatedQuoteObject, importFlag: 'delete' }] }));
      setDeleting(false);
      dispatch(setMessage('dataUpdatedSuccessfully'));
      setQuoteDialog(false);
    } catch (err) {
      console.error(err);
      dispatch(setMessage('dataUpdateError'));
      setDeleting(false);
    }
  }

  // when user selects a non-base-currency for the transaction, get the fx rate in the background and display it
  // when updating this code, remember to change the same code in InflowOutflowTransfer.jsx
  useEffect(() => {
    async function updateFxRate() {
      if (currency !== baseCurrency) {
        setFxRateLoading(true);
        let newRate;
        try {
          newRate = await convertCurrency(1, baseCurrency, currency, dayjs.utc().startOf('day').valueOf());
          setValue('exchangeRate', (1 / newRate).toFixed(4), { shouldValidate: true });
          setFxRateLoading(false);
        } catch (err) {
          setFxRateLoading(false);
        }
      }
    }
    updateFxRate();
  }, [currency]);

  // InflowOutflowTransfer has a very similar function, remember to change it there as well if changing here
  function handleFxRateFieldSwitch(e) {
    if (showFxRate) {
      register('quoteBaseCurrency');
      setValue('quoteBaseCurrency', (Number(quote) * Number(exchangeRate)).toFixed(2), { shouldValidate: true });
      unregister('exchangeRate'); // this is done so that prepDataForPost knows if it should use exchangeRate or quoteBaseCurrency (so we only keep one of them in the form)
    } else {
      register('exchangeRate');
      setValue('exchangeRate', (Number(quoteBaseCurrency) / Number(quote)).toFixed(4), { shouldValidate: true });
      unregister('quoteBaseCurrency'); // this is done so that prepDataForPost knows if it should use exchangeRate or quoteBaseCurrency (so we only keep one of them in the form)
    }
    setShowFxRate(!showFxRate);
    // recalculate FX rate or quoteBaseCurrency based on which field was changed
    // if showFxRate is true, then fxRate is being changed to quoteBaseCurrency
  }

  const myRef = useRef(null);
  // scroll all the way up when the dialog opens
  useEffect(() => {
    if (quoteDialog) myRef.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
  }, [quoteDialog]);

  return (
    // <div id="add-project-quote" data-testid="add-project-quote" className="bg-opacity-90 bg-gray-300 absolute top-0 left-0 w-full h-full z-[100] p-4" ref={(el) => { myRef.current = el; }}>
    <div id="add-project-quote" data-testid="add-project-quote" className="bg-opacity-90 bg-gray-300 absolute top-0 left-0 w-full h-full z-[100] p-4" ref={myRef}>
      <div className="flex flex-col items-center justify-center h-full">
        <form
          autoComplete="off"
          onSubmit={handleSubmit(onSubmit)}
          className="relative bg-white rounded-md shadow-lg p-8 max-w-6xl"
        >
          <button
            type="button"
            id="project-details-add-transaction-close"
            onClick={(e) => { setQuoteDialog(false); }}
            className="absolute top-4 right-4 cursor-pointer"
          >
            <XMarkIcon
              className="w-6 h-6 text-gray-500 hover:text-gray-600"
            />
          </button>
          <div className="mb-8">
            <h2 className="inline text-2xl font-bold text-gray-900">{t('addQuote')}</h2>
            <h3 className="text-lg font-normal text-gray-900">{t('addQuoteDescription')}</h3>
          </div>
          <div className="sm:grid sm:grid-cols-3 gap-12">
            {/* Asset name & ISIN + dropdown for BUY / picklist for SELL */}
            <div id="project-details-add-transaction-asset-name">
              <AssetNamePicklist // displaySymbol
                control={control}
                assetIds={assetIdsArray}
                t={t}
                errors={errors}
              />
            </div>
            <div>
              {/* Date */}
              <div className="pb-8">
                <label htmlFor="date" className="block text-sm font-medium text-gray-700">
                  <ToolTip
                    className="text-black border-gray-400 shadow bg-brandYellow-50"
                    info={t('date.tooltip')}
                  >
                    {t('date')}
                    <ToolTipOnlyIcon />
                  </ToolTip>
                </label>
                <div className="mt-1">
                  <input
                    type="date"
                    name="date"
                    id="date"
                    step="month"
                    // onChange
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-brandBlue-500 focus:ring-brandBlue-600 sm:text-sm"
                    {...register('date', {
                      // round down to first day of month
                      onChange: (e) => {
                        const dateEpoch = new Date(e.target.value);
                        dateEpoch.setDate(1);
                        let value;
                        try {
                          [value] = dateEpoch.toISOString().split('T');
                        } catch (err) {
                          // when user uses arrow to change day by one lower and comes to an incorrect date as an effect (e.g. 31-02-2021)
                          // the field sends an empty string as e.target.value and we reset it to the previous value (from form state)
                          // value = transactionDate;  // FIXME
                        }
                        setValue('date', value);
                      },
                      validate: (value) => {
                        if (new Date(value) > new Date()) return true;
                        return t('date.error');
                      },
                    })}
                  />
                </div>
                <div className="pt-2 sm:text-sm text-brandRed-500 max-w-fit">
                  <span>{errors.date?.message}</span>
                </div>
              </div>
            </div>
            {/* Price per piece (or "amount" for deposits assets like time deposits) */}
            <div className="grid grid-cols-3 gap-3" id="project-details-add-transaction-amount">
              <div>
                <label htmlFor="quote" className="block text-sm font-medium text-gray-700">
                  {t('quote.label')}
                </label>
                <div className="mt-1">
                  <input
                    type="number"
                    step="0.01"
                    name="quote"
                    id="quote"
                    placeholder={35.49}
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-brandBlue-500 focus:ring-brandBlue-600 sm:text-sm"
                    {...register('quote', { min: { value: 0, message: t('quote.error') } })}
                  />
                </div>
                <div className="pt-2 sm:text-sm text-brandRed-500 max-w-fit">
                  <span>{errors.quote?.message}</span>
                </div>
              </div>
              {/* currency dropdown */}
              <div className="z-10">
                <Controller
                  name="currency"
                  control={control}
                  render={({ field, ref }) => (
                    <Dropdown
                      label={t('currency')}
                      list={currencyCodes}
                      value={field.value}
                      onChange={field.onChange}
                      onBlur={field.onBlur}
                      inputRef={ref}
                    />
                  )}
                />
              </div>
              {/* exchange rate */}
              <div>
                {showFxRate
                  ? (
                    <>
                      <label htmlFor="exchangeRate" className={`block text-sm font-medium ${currency === baseCurrency ? 'text-gray-300' : 'text-gray-700'}`}>
                        {`${t('exchangeRate.label')} ${baseCurrency}`}
                      </label>
                      <div className="mt-1 relative">
                        <input
                          type="number"
                          step="any"
                          name="exchangeRate"
                          id="exchangeRate"
                          disabled={(currency === baseCurrency)}
                          // eslint-disable-next-line max-len
                          className={`block w-full rounded-md border-gray-300 shadow-sm focus:border-brandBlue-500 focus:ring-brandBlue-600 sm:text-sm ${currency === baseCurrency ? 'text-gray-300' : 'text-gray-700'}`}
                          placeholder={1}
                          {...register('exchangeRate', {
                            validate: (value) => {
                              if (value <= 0) {
                                return t('exchangeRate.mustBeGreaterThanZero');
                              }
                              return true;
                            },
                          })}
                        />
                        {fxRateLoading && (
                        <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                          <MiniSpinner className="h-4 w-4 animate-spin text-gray-600" />
                        </div>
                        )}
                      </div>
                      <button
                        className={`${(currency !== baseCurrency) ? '' : 'hidden'} text-xs underline text-gray-400 hover:text-brandBlue-400`}
                        onClick={handleFxRateFieldSwitch}
                        type="button"
                      >
                        {showFxRate ? t('switchToAmount') : `${t('switchToFxRate')} ${baseCurrency}`}
                      </button>
                    </>
                  )
                  : (
                    <>
                      <label htmlFor="quoteBaseCurrency" className={`block text-sm font-medium ${currency === baseCurrency ? 'text-gray-300' : 'text-gray-700'}`}>
                        {`${t('quoteBaseCurrency.label')} ${baseCurrency}`}
                      </label>
                      <div className="mt-1">
                        <input
                          type="number"
                          name="quoteBaseCurrency"
                          id="quoteBaseCurrency"
                          disabled={(currency === baseCurrency)}
                          // eslint-disable-next-line max-len
                          className={`block w-full rounded-md border-gray-300 shadow-sm focus:border-brandBlue-500 focus:ring-brandBlue-600 sm:text-sm ${currency === baseCurrency ? 'text-gray-300' : 'text-gray-700'}`}
                          placeholder={2000}
                          {...register('quoteBaseCurrency', {
                            validate: (value) => {
                              if (value <= 0) {
                                return t('quoteBaseCurrency.mustBeGreaterThanZero');
                              }
                              return true;
                            },
                          })}
                        />
                      </div>
                      <button
                        className={`${(currency !== baseCurrency) ? '' : 'hidden'} text-xs underline text-gray-400 hover:text-brandBlue-400`}
                        onClick={handleFxRateFieldSwitch}
                        type="button"
                      >
                        {showFxRate ? `${t('switchToAmount')}` : t('switchToFxRate')}
                      </button>
                    </>
                  )}
              </div>
            </div>
          </div>
          {/* buttons */}
          <div className="flex justify-start items-center gap-4 pt-6">
            <button
              id="project-add-quote-save"
              data-testid="project-add-quote-save"
              type="submit"
              // eslint-disable-next-line max-len
              className="inline-flex items-center rounded-md border border-transparent bg-brandBlue-500 hover:bg-brandBlue-600 px-4 py-2 text-sm font-medium leading-4 text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-brandBlue-400 focus:ring-offset-2"
            >
              {(loading === false)
                ? t('save')
                : (
                  <div id="project-add-quote-spinner" className="px-2">
                    <MiniSpinner className="w-4 h-4 text-white animate-spin" />
                  </div>
                )}
            </button>
            <button
              id="project-add-quote-delete"
              type="button"
              onClick={onDelete}
              disabled={quoteDialog === true} // disabled for new entry
              // eslint-disable-next-line max-len
              className={`inline-flex items-center rounded-md border px-4 py-2 text-sm font-medium leading-4 ${quoteDialog === true ? 'hidden' : 'text-gray-700 border-gray-300 bg-white'} shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-brandBlue-400 focus:ring-offset-2`}
            >
              {(deleting === false)
                ? t('deleteQuote')
                : (
                  <div id="project-delete-quote-spinner" className="px-2">
                    <MiniSpinner className="w-4 h-4 text-gray-400 animate-spin" />
                  </div>
                )}
            </button>
            <button
              id="project-add-quote-cancel"
              type="button"
              onClick={(e) => {
                setQuoteDialog(false);
              }}
              // eslint-disable-next-line max-len
              className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-brandBlue-400 focus:ring-offset-2"
            >
              {t('cancel')}
            </button>
            {existingQuoteError && !quoteDialog.quote && ( // only display when not editing an existing transaction
            <div className="text-sm text-brandBlue-500">
              <InformationCircleIcon className="h-4 w-4 -mt-1 mr-1 inline-block text-brandBlue-500" />
              {t('quoteAlreadyExists')}
            </div>
            )}
            {quoteDialog.projectId !== project.id && ( // if the quote being edited belongs to a different project, display information
            <div className="text-sm text-brandBlue-500">
              <InformationCircleIcon className="h-4 w-4 -mt-1 mr-1 inline-block text-brandBlue-500" />
              {t('quoteProjectWillBeChanged')}
            </div>
            )}
          </div>
        </form>
      </div>
    </div>
  );
}
AddProjectQuote.propTypes = {
  quoteDialog: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  setQuoteDialog: PropTypes.func.isRequired,
  assetsList: PropTypes.arrayOf(PropTypes.object).isRequired,
  project: PropTypes.objectOf(PropTypes.any).isRequired,
};
AddProjectQuote.defaultProps = {
  quoteDialog: null,
};
