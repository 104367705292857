/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useRef, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { InformationCircleIcon } from '@heroicons/react/20/solid';
import datenschutzDE from '../assets/legal/datenschutz.de.md';
import datenschutzEN from '../assets/legal/datenschutz.en.md';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

// pass component as prop to display it in the document
// pass body as markdown string to display it in the document
function SingleDocument({ header, about, body }) {
  const { t, i18n } = useTranslation(['site']);
  const [fullyOpen, setFullyOpen] = useState(false);

  function handleClick(e) {
    e.preventDefault();
    setFullyOpen(!fullyOpen);
  }

  return (
    <section aria-labelledby="single-document">
      <div className={classNames(fullyOpen ? null : 'max-h-96', 'relative bg-white shadow sm:rounded-lg overflow-hidden pb-12')}>
        <div className="px-4 py-5 sm:px-6">
          <h2 id="applicant-information-title" className="text-xl leading-6 font-black text-gray-900">
            {header}
          </h2>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">{about}</p>
        </div>
        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
          <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
            <div className="sm:col-span-2">
              <dd className="mt-1 text-sm text-gray-900 prose-base prose-p:text-gray-500 prose-h3:font-bold">{body && <ReactMarkdown>{body}</ReactMarkdown>}</dd>
            </div>
          </dl>
        </div>
        <div className="absolute bottom-0 w-full">
          <a href="/" className="block bg-gray-100 text-sm font-medium text-gray-500 text-center px-4 py-4 hover:text-gray-700 sm:rounded-b-lg" onClick={handleClick}>
            {fullyOpen ? t('site:legal.close') : t('site:legal.read-full-doc')}
          </a>
        </div>
      </div>
    </section>
  );
}
SingleDocument.propTypes = {
  header: PropTypes.string.isRequired,
  about: PropTypes.string.isRequired,
  body: PropTypes.string,
};
SingleDocument.defaultProps = {
  body: '',
};

export default function Legal() {
  const { t, i18n } = useTranslation(['site']);
  const docRef = useRef();

  const docs = t('site:legal.documents', { returnObjects: true });

  function handleScroll(e) {
    e.preventDefault();
    docRef.current.scrollIntoView({ behavior: 'smooth' });
  }

  return (
    <div className="min-h-full">
      <main className="py-10 bg-gray-50">
        <div className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
          <div className="space-y-6 lg:col-start-1 lg:col-span-2">
            {/* FIXME workaround */}
            {i18n.language === 'en' && <SingleDocument header="Data privacy" about="This is a legally mandated document which describes how we handle data privacy." body={datenschutzEN} />}
            {i18n.language === 'de' && (
              <SingleDocument header="Datenschutzerklärung" about="Dies ist ein gesetzlich vorgeschriebenes Dokument, das beschreibt, wie wir mit Datenschutz umgehen." body={datenschutzDE} />
            )}
            <div className="rounded-md bg-blue-50 p-4">
              <div className="flex">
                <div className="flex-shrink-0">
                  <InformationCircleIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
                </div>
                <div className="ml-3 flex-1 md:flex md:justify-between">
                  <p className="text-sm text-blue-700">{t('site:legal.dataPrivacyContact')}</p>
                </div>
              </div>
            </div>
            {docs.map((doc) => (
              <div ref={docRef}>
                <SingleDocument header={doc.header} about={doc.subtitle} body={doc.body} />
              </div>
            ))}
          </div>
          <section aria-labelledby="timeline-title" className="lg:col-start-3 lg:col-span-1 relative">
            <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:px-6">
              <h2 id="toc-title" className="text-xl font-black text-gray-900">
                {t('site:legal.toc')}
              </h2>

              {/* Table of contents */}
              <div className="mt-3 flow-root">
                <ul>
                  {docs.map((item) => (
                    <li key={item.header}>
                      <div className="min-w-0 pt-1.5">
                        <div className="py-1">
                          <p className="text-sm text-gray-500">
                            <a href="#" className="font-medium text-base text-gray-500" onClick={handleScroll}>
                              {item.header}
                            </a>
                          </p>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="flex flex-col rounded-lg shadow overflow-hidden my-6">
              <div className="flex-shrink-0">
                <img
                  className="h-48 w-full object-cover"
                  // eslint-disable-next-line max-len
                  src="https://images.unsplash.com/photo-1493723843671-1d655e66ac1c?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDUyOTN8MHwxfGFsbHx8fHx8fHx8fDE2NDU3ODU2MDQ&ixlib=rb-1.2.1&q=80&w=400"
                  alt=""
                />
              </div>
              <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                <div className="flex-1">
                  <Link to={`/${i18n.language}/features`} className="block mt-2">
                    <p className="text-xl font-semibold text-gray-900">{t('site:article.features-title')}</p>
                  </Link>
                </div>
              </div>
            </div>
            <div className="flex flex-col rounded-lg shadow overflow-hidden my-6">
              <div className="flex-shrink-0">
                {/* eslint-disable-next-line max-len */}
                <img
                  className="h-48 w-full object-cover"
                  src="https://images.unsplash.com/photo-1551288049-bebda4e38f71?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDUyOTN8MHwxfGFsbHx8fHx8fHx8fDE2NDU3ODYwMjI&ixlib=rb-1.2.1&q=80&w=400"
                  alt=""
                />
              </div>
              <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                <div className="flex-1">
                  {/* <a href="/" className="block mt-2"> */}
                  <Link to={`/${i18n.language}/demo`} className="block mt-2">
                    <p className="text-xl font-semibold text-gray-900">{t('site:article.demo-title')}</p>
                  </Link>
                  {/* </a> */}
                </div>
              </div>
            </div>
          </section>
        </div>
      </main>
    </div>
  );
}
