const allAssetsSunburst = {
  userId: 'jiZoVK1rpJEbfxwfX8LKJ',
  cols: [
    'displayName',
    // 'purchaseValue',
  ],
  dataScope: 'assets',
  dataStructure: 'hierarchy',
  rows: [
    'category',
    'accountName',
  ],
  vals: ['currentValue'],
  rendererName: 'Pie chart, multi-layers',
  rendererType: 'nivo', // 'nivo' requires a height property to be set on its parent to be displayed (optional)
  id: 'allAssetsSunburstxxxx',
  name: 'Owned assets - pie chart',
  readOnly: true,
  aggregatorName: 'Sum',
};

export default allAssetsSunburst;
