{
  "BBG001S5N8V8": {
    "current": {
      "assetId": "BBG001S5N8V8",
      "date": 1701475200000,
      "quote": 191.24,
      "quoteDate": 1701388800000,
      "currency": "USD",
      "projectId": null,
      "source": "api",
      "quoteBaseCurrency": 175.8529
    }
  },
  "BBG001S5SYB8": {
    "current": {
      "assetId": "BBG001S5SYB8",
      "date": 1701475200000,
      "quote": 43.24,
      "quoteDate": 1701388800000,
      "currency": "EUR",
      "projectId": null,
      "source": "api",
      "quoteBaseCurrency": 43.24
    }
  },
  "0LBYVRlkkfRWVNk0y2c4a": {
    "1701475200000": {
      "assetId": "0LBYVRlkkfRWVNk0y2c4a",
      "date": 1701475200000,
      "quote": 495000,
      "quoteDate": 1675209600000,
      "currency": "EUR",
      "projectId": "null",
      "source": "manual",
      "quoteBaseCurrency": 495000
    },
    "1701388800000": {
      "assetId": "0LBYVRlkkfRWVNk0y2c4a",
      "date": 1701388800000,
      "quote": 495000,
      "quoteDate": 1675209600000,
      "currency": "EUR",
      "projectId": "null",
      "source": "manual",
      "quoteBaseCurrency": 495000
    },
    "1698796800000": {
      "assetId": "0LBYVRlkkfRWVNk0y2c4a",
      "date": 1698796800000,
      "quote": 495000,
      "quoteDate": 1675209600000,
      "currency": "EUR",
      "projectId": "null",
      "source": "manual",
      "quoteBaseCurrency": 495000
    },
    "1696118400000": {
      "assetId": "0LBYVRlkkfRWVNk0y2c4a",
      "date": 1696118400000,
      "quote": 495000,
      "quoteDate": 1675209600000,
      "currency": "EUR",
      "projectId": "null",
      "source": "manual",
      "quoteBaseCurrency": 495000
    },
    "1693526400000": {
      "assetId": "0LBYVRlkkfRWVNk0y2c4a",
      "date": 1693526400000,
      "quote": 495000,
      "quoteDate": 1675209600000,
      "currency": "EUR",
      "projectId": "null",
      "source": "manual",
      "quoteBaseCurrency": 495000
    },
    "1690848000000": {
      "assetId": "0LBYVRlkkfRWVNk0y2c4a",
      "date": 1690848000000,
      "quote": 495000,
      "quoteDate": 1675209600000,
      "currency": "EUR",
      "projectId": "null",
      "source": "manual",
      "quoteBaseCurrency": 495000
    },
    "1688169600000": {
      "assetId": "0LBYVRlkkfRWVNk0y2c4a",
      "date": 1688169600000,
      "quote": 495000,
      "quoteDate": 1675209600000,
      "currency": "EUR",
      "projectId": "null",
      "source": "manual",
      "quoteBaseCurrency": 495000
    },
    "1685577600000": {
      "assetId": "0LBYVRlkkfRWVNk0y2c4a",
      "date": 1685577600000,
      "quote": 495000,
      "quoteDate": 1675209600000,
      "currency": "EUR",
      "projectId": "null",
      "source": "manual",
      "quoteBaseCurrency": 495000
    },
    "1682899200000": {
      "assetId": "0LBYVRlkkfRWVNk0y2c4a",
      "date": 1682899200000,
      "quote": 495000,
      "quoteDate": 1675209600000,
      "currency": "EUR",
      "projectId": "null",
      "source": "manual",
      "quoteBaseCurrency": 495000
    },
    "1680307200000": {
      "assetId": "0LBYVRlkkfRWVNk0y2c4a",
      "date": 1680307200000,
      "quote": 495000,
      "quoteDate": 1675209600000,
      "currency": "EUR",
      "projectId": "null",
      "source": "manual",
      "quoteBaseCurrency": 495000
    },
    "1677628800000": {
      "assetId": "0LBYVRlkkfRWVNk0y2c4a",
      "date": 1677628800000,
      "quote": 495000,
      "quoteDate": 1675209600000,
      "currency": "EUR",
      "projectId": "null",
      "source": "manual",
      "quoteBaseCurrency": 495000
    },
    "1675209600000": {
      "assetId": "0LBYVRlkkfRWVNk0y2c4a",
      "date": 1675209600000,
      "quote": 495000,
      "quoteDate": 1675209600000,
      "currency": "EUR",
      "projectId": "null",
      "source": "manual",
      "quoteBaseCurrency": 495000
    },
    "1672531200000": {
      "assetId": "0LBYVRlkkfRWVNk0y2c4a",
      "date": 1672531200000,
      "quote": 493500,
      "quoteDate": 1672531200000,
      "currency": "EUR",
      "projectId": "null",
      "source": "manual",
      "quoteBaseCurrency": 493500
    },
    "1669852800000": {
      "assetId": "0LBYVRlkkfRWVNk0y2c4a",
      "date": 1669852800000,
      "quote": 400000,
      "quoteDate": 1346457600000,
      "currency": "EUR",
      "projectId": "null",
      "source": "manual",
      "quoteBaseCurrency": 400000
    },
    "1667260800000": {
      "assetId": "0LBYVRlkkfRWVNk0y2c4a",
      "date": 1667260800000,
      "quote": 400000,
      "quoteDate": 1346457600000,
      "currency": "EUR",
      "projectId": "null",
      "source": "manual",
      "quoteBaseCurrency": 400000
    },
    "1664582400000": {
      "assetId": "0LBYVRlkkfRWVNk0y2c4a",
      "date": 1664582400000,
      "quote": 400000,
      "quoteDate": 1346457600000,
      "currency": "EUR",
      "projectId": "null",
      "source": "manual",
      "quoteBaseCurrency": 400000
    },
    "1661990400000": {
      "assetId": "0LBYVRlkkfRWVNk0y2c4a",
      "date": 1661990400000,
      "quote": 400000,
      "quoteDate": 1346457600000,
      "currency": "EUR",
      "projectId": "null",
      "source": "manual",
      "quoteBaseCurrency": 400000
    },
    "1659312000000": {
      "assetId": "0LBYVRlkkfRWVNk0y2c4a",
      "date": 1659312000000,
      "quote": 400000,
      "quoteDate": 1346457600000,
      "currency": "EUR",
      "projectId": "null",
      "source": "manual",
      "quoteBaseCurrency": 400000
    },
    "1656633600000": {
      "assetId": "0LBYVRlkkfRWVNk0y2c4a",
      "date": 1656633600000,
      "quote": 400000,
      "quoteDate": 1346457600000,
      "currency": "EUR",
      "projectId": "null",
      "source": "manual",
      "quoteBaseCurrency": 400000
    },
    "1654041600000": {
      "assetId": "0LBYVRlkkfRWVNk0y2c4a",
      "date": 1654041600000,
      "quote": 400000,
      "quoteDate": 1346457600000,
      "currency": "EUR",
      "projectId": "null",
      "source": "manual",
      "quoteBaseCurrency": 400000
    },
    "1651363200000": {
      "assetId": "0LBYVRlkkfRWVNk0y2c4a",
      "date": 1651363200000,
      "quote": 400000,
      "quoteDate": 1346457600000,
      "currency": "EUR",
      "projectId": "null",
      "source": "manual",
      "quoteBaseCurrency": 400000
    },
    "1648771200000": {
      "assetId": "0LBYVRlkkfRWVNk0y2c4a",
      "date": 1648771200000,
      "quote": 400000,
      "quoteDate": 1346457600000,
      "currency": "EUR",
      "projectId": "null",
      "source": "manual",
      "quoteBaseCurrency": 400000
    },
    "1646092800000": {
      "assetId": "0LBYVRlkkfRWVNk0y2c4a",
      "date": 1646092800000,
      "quote": 400000,
      "quoteDate": 1346457600000,
      "currency": "EUR",
      "projectId": "null",
      "source": "manual",
      "quoteBaseCurrency": 400000
    },
    "1643673600000": {
      "assetId": "0LBYVRlkkfRWVNk0y2c4a",
      "date": 1643673600000,
      "quote": 400000,
      "quoteDate": 1346457600000,
      "currency": "EUR",
      "projectId": "null",
      "source": "manual",
      "quoteBaseCurrency": 400000
    },
    "1640995200000": {
      "assetId": "0LBYVRlkkfRWVNk0y2c4a",
      "date": 1640995200000,
      "quote": 400000,
      "quoteDate": 1346457600000,
      "currency": "EUR",
      "projectId": "null",
      "source": "manual",
      "quoteBaseCurrency": 400000
    },
    "1638316800000": {
      "assetId": "0LBYVRlkkfRWVNk0y2c4a",
      "date": 1638316800000,
      "quote": 400000,
      "quoteDate": 1346457600000,
      "currency": "EUR",
      "projectId": "null",
      "source": "manual",
      "quoteBaseCurrency": 400000
    },
    "1635724800000": {
      "assetId": "0LBYVRlkkfRWVNk0y2c4a",
      "date": 1635724800000,
      "quote": 400000,
      "quoteDate": 1346457600000,
      "currency": "EUR",
      "projectId": "null",
      "source": "manual",
      "quoteBaseCurrency": 400000
    },
    "1633046400000": {
      "assetId": "0LBYVRlkkfRWVNk0y2c4a",
      "date": 1633046400000,
      "quote": 400000,
      "quoteDate": 1346457600000,
      "currency": "EUR",
      "projectId": "null",
      "source": "manual",
      "quoteBaseCurrency": 400000
    },
    "1630454400000": {
      "assetId": "0LBYVRlkkfRWVNk0y2c4a",
      "date": 1630454400000,
      "quote": 400000,
      "quoteDate": 1346457600000,
      "currency": "EUR",
      "projectId": "null",
      "source": "manual",
      "quoteBaseCurrency": 400000
    },
    "1627776000000": {
      "assetId": "0LBYVRlkkfRWVNk0y2c4a",
      "date": 1627776000000,
      "quote": 400000,
      "quoteDate": 1346457600000,
      "currency": "EUR",
      "projectId": "null",
      "source": "manual",
      "quoteBaseCurrency": 400000
    },
    "1625097600000": {
      "assetId": "0LBYVRlkkfRWVNk0y2c4a",
      "date": 1625097600000,
      "quote": 400000,
      "quoteDate": 1346457600000,
      "currency": "EUR",
      "projectId": "null",
      "source": "manual",
      "quoteBaseCurrency": 400000
    },
    "1622505600000": {
      "assetId": "0LBYVRlkkfRWVNk0y2c4a",
      "date": 1622505600000,
      "quote": 400000,
      "quoteDate": 1346457600000,
      "currency": "EUR",
      "projectId": "null",
      "source": "manual",
      "quoteBaseCurrency": 400000
    },
    "1619827200000": {
      "assetId": "0LBYVRlkkfRWVNk0y2c4a",
      "date": 1619827200000,
      "quote": 400000,
      "quoteDate": 1346457600000,
      "currency": "EUR",
      "projectId": "null",
      "source": "manual",
      "quoteBaseCurrency": 400000
    },
    "1617235200000": {
      "assetId": "0LBYVRlkkfRWVNk0y2c4a",
      "date": 1617235200000,
      "quote": 400000,
      "quoteDate": 1346457600000,
      "currency": "EUR",
      "projectId": "null",
      "source": "manual",
      "quoteBaseCurrency": 400000
    },
    "1614556800000": {
      "assetId": "0LBYVRlkkfRWVNk0y2c4a",
      "date": 1614556800000,
      "quote": 400000,
      "quoteDate": 1346457600000,
      "currency": "EUR",
      "projectId": "null",
      "source": "manual",
      "quoteBaseCurrency": 400000
    },
    "1612137600000": {
      "assetId": "0LBYVRlkkfRWVNk0y2c4a",
      "date": 1612137600000,
      "quote": 400000,
      "quoteDate": 1346457600000,
      "currency": "EUR",
      "projectId": "null",
      "source": "manual",
      "quoteBaseCurrency": 400000
    },
    "1609459200000": {
      "assetId": "0LBYVRlkkfRWVNk0y2c4a",
      "date": 1609459200000,
      "quote": 400000,
      "quoteDate": 1346457600000,
      "currency": "EUR",
      "projectId": "null",
      "source": "manual",
      "quoteBaseCurrency": 400000
    },
    "1606780800000": {
      "assetId": "0LBYVRlkkfRWVNk0y2c4a",
      "date": 1606780800000,
      "quote": 400000,
      "quoteDate": 1346457600000,
      "currency": "EUR",
      "projectId": "null",
      "source": "manual",
      "quoteBaseCurrency": 400000
    },
    "1604188800000": {
      "assetId": "0LBYVRlkkfRWVNk0y2c4a",
      "date": 1604188800000,
      "quote": 400000,
      "quoteDate": 1346457600000,
      "currency": "EUR",
      "projectId": "null",
      "source": "manual",
      "quoteBaseCurrency": 400000
    },
    "1601510400000": {
      "assetId": "0LBYVRlkkfRWVNk0y2c4a",
      "date": 1601510400000,
      "quote": 400000,
      "quoteDate": 1346457600000,
      "currency": "EUR",
      "projectId": "null",
      "source": "manual",
      "quoteBaseCurrency": 400000
    },
    "1598918400000": {
      "assetId": "0LBYVRlkkfRWVNk0y2c4a",
      "date": 1598918400000,
      "quote": 400000,
      "quoteDate": 1346457600000,
      "currency": "EUR",
      "projectId": "null",
      "source": "manual",
      "quoteBaseCurrency": 400000
    },
    "1596240000000": {
      "assetId": "0LBYVRlkkfRWVNk0y2c4a",
      "date": 1596240000000,
      "quote": 400000,
      "quoteDate": 1346457600000,
      "currency": "EUR",
      "projectId": "null",
      "source": "manual",
      "quoteBaseCurrency": 400000
    },
    "1593561600000": {
      "assetId": "0LBYVRlkkfRWVNk0y2c4a",
      "date": 1593561600000,
      "quote": 400000,
      "quoteDate": 1346457600000,
      "currency": "EUR",
      "projectId": "null",
      "source": "manual",
      "quoteBaseCurrency": 400000
    },
    "1590969600000": {
      "assetId": "0LBYVRlkkfRWVNk0y2c4a",
      "date": 1590969600000,
      "quote": 400000,
      "quoteDate": 1346457600000,
      "currency": "EUR",
      "projectId": "null",
      "source": "manual",
      "quoteBaseCurrency": 400000
    },
    "1588291200000": {
      "assetId": "0LBYVRlkkfRWVNk0y2c4a",
      "date": 1588291200000,
      "quote": 400000,
      "quoteDate": 1346457600000,
      "currency": "EUR",
      "projectId": "null",
      "source": "manual",
      "quoteBaseCurrency": 400000
    },
    "1585699200000": {
      "assetId": "0LBYVRlkkfRWVNk0y2c4a",
      "date": 1585699200000,
      "quote": 400000,
      "quoteDate": 1346457600000,
      "currency": "EUR",
      "projectId": "null",
      "source": "manual",
      "quoteBaseCurrency": 400000
    },
    "1583020800000": {
      "assetId": "0LBYVRlkkfRWVNk0y2c4a",
      "date": 1583020800000,
      "quote": 400000,
      "quoteDate": 1346457600000,
      "currency": "EUR",
      "projectId": "null",
      "source": "manual",
      "quoteBaseCurrency": 400000
    },
    "1580515200000": {
      "assetId": "0LBYVRlkkfRWVNk0y2c4a",
      "date": 1580515200000,
      "quote": 400000,
      "quoteDate": 1346457600000,
      "currency": "EUR",
      "projectId": "null",
      "source": "manual",
      "quoteBaseCurrency": 400000
    },
    "1577836800000": {
      "assetId": "0LBYVRlkkfRWVNk0y2c4a",
      "date": 1577836800000,
      "quote": 400000,
      "quoteDate": 1346457600000,
      "currency": "EUR",
      "projectId": "null",
      "source": "manual",
      "quoteBaseCurrency": 400000
    },
    "1575158400000": {
      "assetId": "0LBYVRlkkfRWVNk0y2c4a",
      "date": 1575158400000,
      "quote": 400000,
      "quoteDate": 1346457600000,
      "currency": "EUR",
      "projectId": "null",
      "source": "manual",
      "quoteBaseCurrency": 400000
    },
    "1572566400000": {
      "assetId": "0LBYVRlkkfRWVNk0y2c4a",
      "date": 1572566400000,
      "quote": 400000,
      "quoteDate": 1346457600000,
      "currency": "EUR",
      "projectId": "null",
      "source": "manual",
      "quoteBaseCurrency": 400000
    },
    "1569888000000": {
      "assetId": "0LBYVRlkkfRWVNk0y2c4a",
      "date": 1569888000000,
      "quote": 400000,
      "quoteDate": 1346457600000,
      "currency": "EUR",
      "projectId": "null",
      "source": "manual",
      "quoteBaseCurrency": 400000
    },
    "1567296000000": {
      "assetId": "0LBYVRlkkfRWVNk0y2c4a",
      "date": 1567296000000,
      "quote": 400000,
      "quoteDate": 1346457600000,
      "currency": "EUR",
      "projectId": "null",
      "source": "manual",
      "quoteBaseCurrency": 400000
    },
    "1564617600000": {
      "assetId": "0LBYVRlkkfRWVNk0y2c4a",
      "date": 1564617600000,
      "quote": 400000,
      "quoteDate": 1346457600000,
      "currency": "EUR",
      "projectId": "null",
      "source": "manual",
      "quoteBaseCurrency": 400000
    },
    "1561939200000": {
      "assetId": "0LBYVRlkkfRWVNk0y2c4a",
      "date": 1561939200000,
      "quote": 400000,
      "quoteDate": 1346457600000,
      "currency": "EUR",
      "projectId": "null",
      "source": "manual",
      "quoteBaseCurrency": 400000
    },
    "1559347200000": {
      "assetId": "0LBYVRlkkfRWVNk0y2c4a",
      "date": 1559347200000,
      "quote": 400000,
      "quoteDate": 1346457600000,
      "currency": "EUR",
      "projectId": "null",
      "source": "manual",
      "quoteBaseCurrency": 400000
    },
    "1556668800000": {
      "assetId": "0LBYVRlkkfRWVNk0y2c4a",
      "date": 1556668800000,
      "quote": 400000,
      "quoteDate": 1346457600000,
      "currency": "EUR",
      "projectId": "null",
      "source": "manual",
      "quoteBaseCurrency": 400000
    },
    "1554076800000": {
      "assetId": "0LBYVRlkkfRWVNk0y2c4a",
      "date": 1554076800000,
      "quote": 400000,
      "quoteDate": 1346457600000,
      "currency": "EUR",
      "projectId": "null",
      "source": "manual",
      "quoteBaseCurrency": 400000
    },
    "1551398400000": {
      "assetId": "0LBYVRlkkfRWVNk0y2c4a",
      "date": 1551398400000,
      "quote": 400000,
      "quoteDate": 1346457600000,
      "currency": "EUR",
      "projectId": "null",
      "source": "manual",
      "quoteBaseCurrency": 400000
    },
    "1548979200000": {
      "assetId": "0LBYVRlkkfRWVNk0y2c4a",
      "date": 1548979200000,
      "quote": 400000,
      "quoteDate": 1346457600000,
      "currency": "EUR",
      "projectId": "null",
      "source": "manual",
      "quoteBaseCurrency": 400000
    },
    "1546300800000": {
      "assetId": "0LBYVRlkkfRWVNk0y2c4a",
      "date": 1546300800000,
      "quote": 400000,
      "quoteDate": 1346457600000,
      "currency": "EUR",
      "projectId": "null",
      "source": "manual",
      "quoteBaseCurrency": 400000
    },
    "1543622400000": {
      "assetId": "0LBYVRlkkfRWVNk0y2c4a",
      "date": 1543622400000,
      "quote": 400000,
      "quoteDate": 1346457600000,
      "currency": "EUR",
      "projectId": "null",
      "source": "manual",
      "quoteBaseCurrency": 400000
    },
    "1541030400000": {
      "assetId": "0LBYVRlkkfRWVNk0y2c4a",
      "date": 1541030400000,
      "quote": 400000,
      "quoteDate": 1346457600000,
      "currency": "EUR",
      "projectId": "null",
      "source": "manual",
      "quoteBaseCurrency": 400000
    },
    "1538352000000": {
      "assetId": "0LBYVRlkkfRWVNk0y2c4a",
      "date": 1538352000000,
      "quote": 400000,
      "quoteDate": 1346457600000,
      "currency": "EUR",
      "projectId": "null",
      "source": "manual",
      "quoteBaseCurrency": 400000
    },
    "1535760000000": {
      "assetId": "0LBYVRlkkfRWVNk0y2c4a",
      "date": 1535760000000,
      "quote": 400000,
      "quoteDate": 1346457600000,
      "currency": "EUR",
      "projectId": "null",
      "source": "manual",
      "quoteBaseCurrency": 400000
    },
    "1533081600000": {
      "assetId": "0LBYVRlkkfRWVNk0y2c4a",
      "date": 1533081600000,
      "quote": 400000,
      "quoteDate": 1346457600000,
      "currency": "EUR",
      "projectId": "null",
      "source": "manual",
      "quoteBaseCurrency": 400000
    },
    "1530403200000": {
      "assetId": "0LBYVRlkkfRWVNk0y2c4a",
      "date": 1530403200000,
      "quote": 400000,
      "quoteDate": 1346457600000,
      "currency": "EUR",
      "projectId": "null",
      "source": "manual",
      "quoteBaseCurrency": 400000
    },
    "1527811200000": {
      "assetId": "0LBYVRlkkfRWVNk0y2c4a",
      "date": 1527811200000,
      "quote": 400000,
      "quoteDate": 1346457600000,
      "currency": "EUR",
      "projectId": "null",
      "source": "manual",
      "quoteBaseCurrency": 400000
    },
    "1525132800000": {
      "assetId": "0LBYVRlkkfRWVNk0y2c4a",
      "date": 1525132800000,
      "quote": 400000,
      "quoteDate": 1346457600000,
      "currency": "EUR",
      "projectId": "null",
      "source": "manual",
      "quoteBaseCurrency": 400000
    },
    "1522540800000": {
      "assetId": "0LBYVRlkkfRWVNk0y2c4a",
      "date": 1522540800000,
      "quote": 400000,
      "quoteDate": 1346457600000,
      "currency": "EUR",
      "projectId": "null",
      "source": "manual",
      "quoteBaseCurrency": 400000
    },
    "1519862400000": {
      "assetId": "0LBYVRlkkfRWVNk0y2c4a",
      "date": 1519862400000,
      "quote": 400000,
      "quoteDate": 1346457600000,
      "currency": "EUR",
      "projectId": "null",
      "source": "manual",
      "quoteBaseCurrency": 400000
    },
    "1517443200000": {
      "assetId": "0LBYVRlkkfRWVNk0y2c4a",
      "date": 1517443200000,
      "quote": 400000,
      "quoteDate": 1346457600000,
      "currency": "EUR",
      "projectId": "null",
      "source": "manual",
      "quoteBaseCurrency": 400000
    },
    "1514764800000": {
      "assetId": "0LBYVRlkkfRWVNk0y2c4a",
      "date": 1514764800000,
      "quote": 400000,
      "quoteDate": 1346457600000,
      "currency": "EUR",
      "projectId": "null",
      "source": "manual",
      "quoteBaseCurrency": 400000
    },
    "1512086400000": {
      "assetId": "0LBYVRlkkfRWVNk0y2c4a",
      "date": 1512086400000,
      "quote": 400000,
      "quoteDate": 1346457600000,
      "currency": "EUR",
      "projectId": "null",
      "source": "manual",
      "quoteBaseCurrency": 400000
    },
    "1509494400000": {
      "assetId": "0LBYVRlkkfRWVNk0y2c4a",
      "date": 1509494400000,
      "quote": 400000,
      "quoteDate": 1346457600000,
      "currency": "EUR",
      "projectId": "null",
      "source": "manual",
      "quoteBaseCurrency": 400000
    },
    "1506816000000": {
      "assetId": "0LBYVRlkkfRWVNk0y2c4a",
      "date": 1506816000000,
      "quote": 400000,
      "quoteDate": 1346457600000,
      "currency": "EUR",
      "projectId": "null",
      "source": "manual",
      "quoteBaseCurrency": 400000
    },
    "1504224000000": {
      "assetId": "0LBYVRlkkfRWVNk0y2c4a",
      "date": 1504224000000,
      "quote": 400000,
      "quoteDate": 1346457600000,
      "currency": "EUR",
      "projectId": "null",
      "source": "manual",
      "quoteBaseCurrency": 400000
    },
    "1501545600000": {
      "assetId": "0LBYVRlkkfRWVNk0y2c4a",
      "date": 1501545600000,
      "quote": 400000,
      "quoteDate": 1346457600000,
      "currency": "EUR",
      "projectId": "null",
      "source": "manual",
      "quoteBaseCurrency": 400000
    },
    "1498867200000": {
      "assetId": "0LBYVRlkkfRWVNk0y2c4a",
      "date": 1498867200000,
      "quote": 400000,
      "quoteDate": 1346457600000,
      "currency": "EUR",
      "projectId": "null",
      "source": "manual",
      "quoteBaseCurrency": 400000
    },
    "1496275200000": {
      "assetId": "0LBYVRlkkfRWVNk0y2c4a",
      "date": 1496275200000,
      "quote": 400000,
      "quoteDate": 1346457600000,
      "currency": "EUR",
      "projectId": "null",
      "source": "manual",
      "quoteBaseCurrency": 400000
    },
    "1493596800000": {
      "assetId": "0LBYVRlkkfRWVNk0y2c4a",
      "date": 1493596800000,
      "quote": 400000,
      "quoteDate": 1346457600000,
      "currency": "EUR",
      "projectId": "null",
      "source": "manual",
      "quoteBaseCurrency": 400000
    },
    "1491004800000": {
      "assetId": "0LBYVRlkkfRWVNk0y2c4a",
      "date": 1491004800000,
      "quote": 400000,
      "quoteDate": 1346457600000,
      "currency": "EUR",
      "projectId": "null",
      "source": "manual",
      "quoteBaseCurrency": 400000
    },
    "1488326400000": {
      "assetId": "0LBYVRlkkfRWVNk0y2c4a",
      "date": 1488326400000,
      "quote": 400000,
      "quoteDate": 1346457600000,
      "currency": "EUR",
      "projectId": "null",
      "source": "manual",
      "quoteBaseCurrency": 400000
    },
    "1485907200000": {
      "assetId": "0LBYVRlkkfRWVNk0y2c4a",
      "date": 1485907200000,
      "quote": 400000,
      "quoteDate": 1346457600000,
      "currency": "EUR",
      "projectId": "null",
      "source": "manual",
      "quoteBaseCurrency": 400000
    },
    "1483228800000": {
      "assetId": "0LBYVRlkkfRWVNk0y2c4a",
      "date": 1483228800000,
      "quote": 400000,
      "quoteDate": 1346457600000,
      "currency": "EUR",
      "projectId": "null",
      "source": "manual",
      "quoteBaseCurrency": 400000
    },
    "1480550400000": {
      "assetId": "0LBYVRlkkfRWVNk0y2c4a",
      "date": 1480550400000,
      "quote": 400000,
      "quoteDate": 1346457600000,
      "currency": "EUR",
      "projectId": "null",
      "source": "manual",
      "quoteBaseCurrency": 400000
    },
    "1477958400000": {
      "assetId": "0LBYVRlkkfRWVNk0y2c4a",
      "date": 1477958400000,
      "quote": 400000,
      "quoteDate": 1346457600000,
      "currency": "EUR",
      "projectId": "null",
      "source": "manual",
      "quoteBaseCurrency": 400000
    },
    "1475280000000": {
      "assetId": "0LBYVRlkkfRWVNk0y2c4a",
      "date": 1475280000000,
      "quote": 400000,
      "quoteDate": 1346457600000,
      "currency": "EUR",
      "projectId": "null",
      "source": "manual",
      "quoteBaseCurrency": 400000
    },
    "1472688000000": {
      "assetId": "0LBYVRlkkfRWVNk0y2c4a",
      "date": 1472688000000,
      "quote": 400000,
      "quoteDate": 1346457600000,
      "currency": "EUR",
      "projectId": "null",
      "source": "manual",
      "quoteBaseCurrency": 400000
    },
    "1470009600000": {
      "assetId": "0LBYVRlkkfRWVNk0y2c4a",
      "date": 1470009600000,
      "quote": 400000,
      "quoteDate": 1346457600000,
      "currency": "EUR",
      "projectId": "null",
      "source": "manual",
      "quoteBaseCurrency": 400000
    },
    "1467331200000": {
      "assetId": "0LBYVRlkkfRWVNk0y2c4a",
      "date": 1467331200000,
      "quote": 400000,
      "quoteDate": 1346457600000,
      "currency": "EUR",
      "projectId": "null",
      "source": "manual",
      "quoteBaseCurrency": 400000
    },
    "1464739200000": {
      "assetId": "0LBYVRlkkfRWVNk0y2c4a",
      "date": 1464739200000,
      "quote": 400000,
      "quoteDate": 1346457600000,
      "currency": "EUR",
      "projectId": "null",
      "source": "manual",
      "quoteBaseCurrency": 400000
    },
    "1462060800000": {
      "assetId": "0LBYVRlkkfRWVNk0y2c4a",
      "date": 1462060800000,
      "quote": 400000,
      "quoteDate": 1346457600000,
      "currency": "EUR",
      "projectId": "null",
      "source": "manual",
      "quoteBaseCurrency": 400000
    },
    "1459468800000": {
      "assetId": "0LBYVRlkkfRWVNk0y2c4a",
      "date": 1459468800000,
      "quote": 400000,
      "quoteDate": 1346457600000,
      "currency": "EUR",
      "projectId": "null",
      "source": "manual",
      "quoteBaseCurrency": 400000
    },
    "1456790400000": {
      "assetId": "0LBYVRlkkfRWVNk0y2c4a",
      "date": 1456790400000,
      "quote": 400000,
      "quoteDate": 1346457600000,
      "currency": "EUR",
      "projectId": "null",
      "source": "manual",
      "quoteBaseCurrency": 400000
    },
    "1454284800000": {
      "assetId": "0LBYVRlkkfRWVNk0y2c4a",
      "date": 1454284800000,
      "quote": 400000,
      "quoteDate": 1346457600000,
      "currency": "EUR",
      "projectId": "null",
      "source": "manual",
      "quoteBaseCurrency": 400000
    },
    "1451606400000": {
      "assetId": "0LBYVRlkkfRWVNk0y2c4a",
      "date": 1451606400000,
      "quote": 400000,
      "quoteDate": 1346457600000,
      "currency": "EUR",
      "projectId": "null",
      "source": "manual",
      "quoteBaseCurrency": 400000
    },
    "1448928000000": {
      "assetId": "0LBYVRlkkfRWVNk0y2c4a",
      "date": 1448928000000,
      "quote": 400000,
      "quoteDate": 1346457600000,
      "currency": "EUR",
      "projectId": "null",
      "source": "manual",
      "quoteBaseCurrency": 400000
    },
    "1446336000000": {
      "assetId": "0LBYVRlkkfRWVNk0y2c4a",
      "date": 1446336000000,
      "quote": 400000,
      "quoteDate": 1346457600000,
      "currency": "EUR",
      "projectId": "null",
      "source": "manual",
      "quoteBaseCurrency": 400000
    },
    "1443657600000": {
      "assetId": "0LBYVRlkkfRWVNk0y2c4a",
      "date": 1443657600000,
      "quote": 400000,
      "quoteDate": 1346457600000,
      "currency": "EUR",
      "projectId": "null",
      "source": "manual",
      "quoteBaseCurrency": 400000
    },
    "1441065600000": {
      "assetId": "0LBYVRlkkfRWVNk0y2c4a",
      "date": 1441065600000,
      "quote": 400000,
      "quoteDate": 1346457600000,
      "currency": "EUR",
      "projectId": "null",
      "source": "manual",
      "quoteBaseCurrency": 400000
    },
    "1438387200000": {
      "assetId": "0LBYVRlkkfRWVNk0y2c4a",
      "date": 1438387200000,
      "quote": 400000,
      "quoteDate": 1346457600000,
      "currency": "EUR",
      "projectId": "null",
      "source": "manual",
      "quoteBaseCurrency": 400000
    },
    "1435708800000": {
      "assetId": "0LBYVRlkkfRWVNk0y2c4a",
      "date": 1435708800000,
      "quote": 400000,
      "quoteDate": 1346457600000,
      "currency": "EUR",
      "projectId": "null",
      "source": "manual",
      "quoteBaseCurrency": 400000
    },
    "1433116800000": {
      "assetId": "0LBYVRlkkfRWVNk0y2c4a",
      "date": 1433116800000,
      "quote": 400000,
      "quoteDate": 1346457600000,
      "currency": "EUR",
      "projectId": "null",
      "source": "manual",
      "quoteBaseCurrency": 400000
    },
    "1430438400000": {
      "assetId": "0LBYVRlkkfRWVNk0y2c4a",
      "date": 1430438400000,
      "quote": 400000,
      "quoteDate": 1346457600000,
      "currency": "EUR",
      "projectId": "null",
      "source": "manual",
      "quoteBaseCurrency": 400000
    },
    "1427846400000": {
      "assetId": "0LBYVRlkkfRWVNk0y2c4a",
      "date": 1427846400000,
      "quote": 400000,
      "quoteDate": 1346457600000,
      "currency": "EUR",
      "projectId": "null",
      "source": "manual",
      "quoteBaseCurrency": 400000
    },
    "1425168000000": {
      "assetId": "0LBYVRlkkfRWVNk0y2c4a",
      "date": 1425168000000,
      "quote": 400000,
      "quoteDate": 1346457600000,
      "currency": "EUR",
      "projectId": "null",
      "source": "manual",
      "quoteBaseCurrency": 400000
    },
    "1422748800000": {
      "assetId": "0LBYVRlkkfRWVNk0y2c4a",
      "date": 1422748800000,
      "quote": 400000,
      "quoteDate": 1346457600000,
      "currency": "EUR",
      "projectId": "null",
      "source": "manual",
      "quoteBaseCurrency": 400000
    },
    "1420070400000": {
      "assetId": "0LBYVRlkkfRWVNk0y2c4a",
      "date": 1420070400000,
      "quote": 400000,
      "quoteDate": 1346457600000,
      "currency": "EUR",
      "projectId": "null",
      "source": "manual",
      "quoteBaseCurrency": 400000
    },
    "1417392000000": {
      "assetId": "0LBYVRlkkfRWVNk0y2c4a",
      "date": 1417392000000,
      "quote": 400000,
      "quoteDate": 1346457600000,
      "currency": "EUR",
      "projectId": "null",
      "source": "manual",
      "quoteBaseCurrency": 400000
    },
    "1414800000000": {
      "assetId": "0LBYVRlkkfRWVNk0y2c4a",
      "date": 1414800000000,
      "quote": 400000,
      "quoteDate": 1346457600000,
      "currency": "EUR",
      "projectId": "null",
      "source": "manual",
      "quoteBaseCurrency": 400000
    },
    "1412121600000": {
      "assetId": "0LBYVRlkkfRWVNk0y2c4a",
      "date": 1412121600000,
      "quote": 400000,
      "quoteDate": 1346457600000,
      "currency": "EUR",
      "projectId": "null",
      "source": "manual",
      "quoteBaseCurrency": 400000
    },
    "1409529600000": {
      "assetId": "0LBYVRlkkfRWVNk0y2c4a",
      "date": 1409529600000,
      "quote": 400000,
      "quoteDate": 1346457600000,
      "currency": "EUR",
      "projectId": "null",
      "source": "manual",
      "quoteBaseCurrency": 400000
    },
    "1406851200000": {
      "assetId": "0LBYVRlkkfRWVNk0y2c4a",
      "date": 1406851200000,
      "quote": 400000,
      "quoteDate": 1346457600000,
      "currency": "EUR",
      "projectId": "null",
      "source": "manual",
      "quoteBaseCurrency": 400000
    },
    "1404172800000": {
      "assetId": "0LBYVRlkkfRWVNk0y2c4a",
      "date": 1404172800000,
      "quote": 400000,
      "quoteDate": 1346457600000,
      "currency": "EUR",
      "projectId": "null",
      "source": "manual",
      "quoteBaseCurrency": 400000
    },
    "1401580800000": {
      "assetId": "0LBYVRlkkfRWVNk0y2c4a",
      "date": 1401580800000,
      "quote": 400000,
      "quoteDate": 1346457600000,
      "currency": "EUR",
      "projectId": "null",
      "source": "manual",
      "quoteBaseCurrency": 400000
    },
    "1398902400000": {
      "assetId": "0LBYVRlkkfRWVNk0y2c4a",
      "date": 1398902400000,
      "quote": 400000,
      "quoteDate": 1346457600000,
      "currency": "EUR",
      "projectId": "null",
      "source": "manual",
      "quoteBaseCurrency": 400000
    },
    "1396310400000": {
      "assetId": "0LBYVRlkkfRWVNk0y2c4a",
      "date": 1396310400000,
      "quote": 400000,
      "quoteDate": 1346457600000,
      "currency": "EUR",
      "projectId": "null",
      "source": "manual",
      "quoteBaseCurrency": 400000
    },
    "1393632000000": {
      "assetId": "0LBYVRlkkfRWVNk0y2c4a",
      "date": 1393632000000,
      "quote": 400000,
      "quoteDate": 1346457600000,
      "currency": "EUR",
      "projectId": "null",
      "source": "manual",
      "quoteBaseCurrency": 400000
    },
    "1391212800000": {
      "assetId": "0LBYVRlkkfRWVNk0y2c4a",
      "date": 1391212800000,
      "quote": 400000,
      "quoteDate": 1346457600000,
      "currency": "EUR",
      "projectId": "null",
      "source": "manual",
      "quoteBaseCurrency": 400000
    },
    "1388534400000": {
      "assetId": "0LBYVRlkkfRWVNk0y2c4a",
      "date": 1388534400000,
      "quote": 400000,
      "quoteDate": 1346457600000,
      "currency": "EUR",
      "projectId": "null",
      "source": "manual",
      "quoteBaseCurrency": 400000
    },
    "1385856000000": {
      "assetId": "0LBYVRlkkfRWVNk0y2c4a",
      "date": 1385856000000,
      "quote": 400000,
      "quoteDate": 1346457600000,
      "currency": "EUR",
      "projectId": "null",
      "source": "manual",
      "quoteBaseCurrency": 400000
    },
    "1383264000000": {
      "assetId": "0LBYVRlkkfRWVNk0y2c4a",
      "date": 1383264000000,
      "quote": 400000,
      "quoteDate": 1346457600000,
      "currency": "EUR",
      "projectId": "null",
      "source": "manual",
      "quoteBaseCurrency": 400000
    },
    "1380585600000": {
      "assetId": "0LBYVRlkkfRWVNk0y2c4a",
      "date": 1380585600000,
      "quote": 400000,
      "quoteDate": 1346457600000,
      "currency": "EUR",
      "projectId": "null",
      "source": "manual",
      "quoteBaseCurrency": 400000
    },
    "1377993600000": {
      "assetId": "0LBYVRlkkfRWVNk0y2c4a",
      "date": 1377993600000,
      "quote": 400000,
      "quoteDate": 1346457600000,
      "currency": "EUR",
      "projectId": "null",
      "source": "manual",
      "quoteBaseCurrency": 400000
    },
    "1375315200000": {
      "assetId": "0LBYVRlkkfRWVNk0y2c4a",
      "date": 1375315200000,
      "quote": 400000,
      "quoteDate": 1346457600000,
      "currency": "EUR",
      "projectId": "null",
      "source": "manual",
      "quoteBaseCurrency": 400000
    },
    "1372636800000": {
      "assetId": "0LBYVRlkkfRWVNk0y2c4a",
      "date": 1372636800000,
      "quote": 400000,
      "quoteDate": 1346457600000,
      "currency": "EUR",
      "projectId": "null",
      "source": "manual",
      "quoteBaseCurrency": 400000
    },
    "1370044800000": {
      "assetId": "0LBYVRlkkfRWVNk0y2c4a",
      "date": 1370044800000,
      "quote": 400000,
      "quoteDate": 1346457600000,
      "currency": "EUR",
      "projectId": "null",
      "source": "manual",
      "quoteBaseCurrency": 400000
    },
    "1367366400000": {
      "assetId": "0LBYVRlkkfRWVNk0y2c4a",
      "date": 1367366400000,
      "quote": 400000,
      "quoteDate": 1346457600000,
      "currency": "EUR",
      "projectId": "null",
      "source": "manual",
      "quoteBaseCurrency": 400000
    },
    "1364774400000": {
      "assetId": "0LBYVRlkkfRWVNk0y2c4a",
      "date": 1364774400000,
      "quote": 400000,
      "quoteDate": 1346457600000,
      "currency": "EUR",
      "projectId": "null",
      "source": "manual",
      "quoteBaseCurrency": 400000
    },
    "1362096000000": {
      "assetId": "0LBYVRlkkfRWVNk0y2c4a",
      "date": 1362096000000,
      "quote": 400000,
      "quoteDate": 1346457600000,
      "currency": "EUR",
      "projectId": "null",
      "source": "manual",
      "quoteBaseCurrency": 400000
    },
    "1359676800000": {
      "assetId": "0LBYVRlkkfRWVNk0y2c4a",
      "date": 1359676800000,
      "quote": 400000,
      "quoteDate": 1346457600000,
      "currency": "EUR",
      "projectId": "null",
      "source": "manual",
      "quoteBaseCurrency": 400000
    },
    "1356998400000": {
      "assetId": "0LBYVRlkkfRWVNk0y2c4a",
      "date": 1356998400000,
      "quote": 400000,
      "quoteDate": 1346457600000,
      "currency": "EUR",
      "projectId": "null",
      "source": "manual",
      "quoteBaseCurrency": 400000
    },
    "1354320000000": {
      "assetId": "0LBYVRlkkfRWVNk0y2c4a",
      "date": 1354320000000,
      "quote": 400000,
      "quoteDate": 1346457600000,
      "currency": "EUR",
      "projectId": "null",
      "source": "manual",
      "quoteBaseCurrency": 400000
    },
    "1351728000000": {
      "assetId": "0LBYVRlkkfRWVNk0y2c4a",
      "date": 1351728000000,
      "quote": 400000,
      "quoteDate": 1346457600000,
      "currency": "EUR",
      "projectId": "null",
      "source": "manual",
      "quoteBaseCurrency": 400000
    },
    "1349049600000": {
      "assetId": "0LBYVRlkkfRWVNk0y2c4a",
      "date": 1349049600000,
      "quote": 400000,
      "quoteDate": 1346457600000,
      "currency": "EUR",
      "projectId": "null",
      "source": "manual",
      "quoteBaseCurrency": 400000
    },
    "1346457600000": {
      "assetId": "0LBYVRlkkfRWVNk0y2c4a",
      "date": 1346457600000,
      "quote": 400000,
      "quoteDate": 1346457600000,
      "currency": "EUR",
      "projectId": "null",
      "source": "manual",
      "quoteBaseCurrency": 400000
    }
  }
}