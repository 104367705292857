/* eslint-disable no-param-reassign */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { DndContext, closestCenter, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, SortableContext, sortableKeyboardCoordinates, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { isEqual } from 'lodash';
import dayjs from 'dayjs';
import { globalQuotesArrayView } from '../../redux/reducers/globalSelectors';
import { postMixedData, allTransactionsProjectView, testGlobalView } from '../../redux/reducers/data';
import { TransactionComponent } from './TransactionComponent';
import QuoteComponent from './QuoteComponent';
import GoalComponent from './GoalComponent';

const debugLevel = 0;

function DateSeparator({ dateArg }) {
  const { i18n } = useTranslation();

  return (
    <div id={`project-transaction-list-date-separator-${dayjs().format('YY-MM')}`} className="relative pb-1.5 flex items-center w-full">
      <div className="mx-4 flex-grow border-t border-gray-300" />
      <span className="px-8 uppercase tracking-widest text-sm text-gray-400">
        {new Date(dateArg).toLocaleDateString(i18n.language, {
          month: 'short',
          year: 'numeric',
          timeZone: 'UTC',
        })}
      </span>
      <div className="mx-4 flex-grow border-t border-gray-300" />
    </div>
  );
}
DateSeparator.propTypes = {
  dateArg: PropTypes.number.isRequired,
};

function DraggableTransaction(props) {
  // passes props to Transaction
  const Element = props.element || 'div';
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: props.transaction.id });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    touchAction: 'none',
  };

  return (
    <Element ref={setNodeRef} style={style} {...listeners} {...attributes} className="w-full">
      <ErrorBoundary FallbackComponent={() => <div />}>
        <TransactionComponent {...props} />
      </ErrorBoundary>
    </Element>
  );
}
DraggableTransaction.propTypes = {
  element: PropTypes.string,
  transaction: PropTypes.objectOf(PropTypes.any).isRequired,
  project: PropTypes.objectOf(PropTypes.any).isRequired,
};
DraggableTransaction.defaultProps = {
  element: 'div',
};

/**
  * ProjectTransactionList
  * ----------------------
  * contains the project transactions in the required structure: (items: container1: [item1, item2...], container2: [item3, item4...] ...)
  * order of items in container is the same order in which they are displayed
  * upon initialisation the store's contents is copied into state
  * once drag and drop is complete, the state is copied back into the store}
  */
export default function ProjectTransactionList({ project, setTransactionDialog, setQuoteDialog, setGoalDialog, setAssetTileHover, projectAssets, filteredItems }) {
  const [items, setItems] = useState({});
  // contains the active droppable element id once the user starts dragging
  const [_, setActiveTransaction] = useState();
  const dispatch = useDispatch();

  // contains parent transactionIds for recurring transactions whose parents are supposed to be visible
  // recurringChildren: if your parent is not here, it means you are not visible
  const [visibleRecurringTransactions, setVisibleRecurringTransactions] = useState([]); // kWfjBJK2-RG-JrwxcI4SX

  // TODO: this could be simplified by having prepareDataForPost add a isVisible flag to the created transaction

  // "removes" transactions which should not appear in the project transaction list
  // i.e. deposit side of buy and sell transactions as well as negative side of transfer transactions
  // this is done to avoid showing the same transaction twice and then undone in AddProjectTransaction
  // the filtered out transactions do not get filtered out completely, but included with the "visible" peer transaction as hiddenTransaction property
  // this is done to handle EDIT case of existing transaction (information from hidden transaction must be shown in the dialog, such as target account, prepareDataForPost etc.)
  function applyFilterLogic(prev, curr) {
    const { transactionsStack, sideStack } = prev;
    const { linkedTransactions, category, accountCurrencyAmount, quantity } = curr;

    // find the one linked transaction which describes the transaction type (transfer, buy, sell) - this will be the transaction created by the project
    // TODO: there can be a projectId in the linkedTransaction tags as well, because why not
    const projectLinkedTransaction = linkedTransactions?.find((t) => ['transfer', 'buy', 'sell'].includes(t.tags?.type));
    // ↑ type is buy / sell / transfer, there is also linkType like 'purchase-to-transaction' etc.

    // filtered-out transactions go to the sideStack, where they wait in case they have a parent transaction which needs to be shown (split logic)
    // non-filtered transactions go to the transactionsStack from where they are displayed
    const checkPurchaseSale = ['buy', 'sell'].includes(projectLinkedTransaction?.tags?.type);

    // does it meet conditions to be filtered out?
    // condition 1: the "negative" side of transfer transaction
    if (
      // (['transfer'].includes(projectLinkedTransaction?.tags?.type) && accountCurrencyAmount < 0)
      (['transfer'].includes(projectLinkedTransaction?.tags?.type) && quantity < 0)
      // condition 2: the "deposit" side of buy and sell transactions
      // except "buy / sell asset" transactions which deal with "project deposits" and show up as "buy" or "sell" (handled in condition #3)
      || (checkPurchaseSale && category === 'deposits' && projectLinkedTransaction?.category !== 'deposits')
      // condition 3: the "negative" side of "buy and sell" deposit transactions (because we show all moves among accounts as positive, and they represent such move)
      || (checkPurchaseSale && category === 'deposits' && projectLinkedTransaction?.category === 'deposits' && quantity < 0)
    ) {
      // is there a parent transaction on the main stack already?
      const peerTransaction = transactionsStack.find((t) => t.id === projectLinkedTransaction.id);
      if (peerTransaction) {
        // if there is a peerTransaction, add this transaction as its hiddenTransaction to the object in stack
        peerTransaction.hiddenTransaction = curr;
      } else {
        // if there is no parent, add this transaction to the sideStack and add peerTransactionId
        sideStack.push({
          ...curr,
          peerTransactionId: projectLinkedTransaction.id,
        });
      }
      return { transactionsStack, sideStack };
    }

    if (['buy', 'sell', 'transfer'].includes(projectLinkedTransaction?.tags?.type)) {
      // check if there is a waiting hidden transaction on the sideStack
      const childTransactionIndex = sideStack.findIndex((t) => t.peerTransactionId === curr.id);
      if (debugLevel > 2) {
        console.log('for transaction in category', curr.category, 'with id', curr.id, 'found childTransactionIndex', childTransactionIndex, 'in sideStack', sideStack);
      }
      if (childTransactionIndex > -1) {
        // if there is a waiting hidden transaction, add it to the current transaction
        transactionsStack.push({
          ...curr,
          hiddenTransaction: sideStack[childTransactionIndex],
        });
        // remove the waiting hidden transaction from the sideStack
        sideStack.splice(childTransactionIndex, 1);
      } else {
        transactionsStack.push(curr);
      }
    } else {
      // if the transaction is not a project transaction, just push it to the main stack
      transactionsStack.push(curr);
    }
    return { transactionsStack, sideStack };
  }

  // prepare transactions to be displayed in the list
  const transactionsPrep = useSelector((state) => allTransactionsProjectView(state)) // get all transactions, including isolated
    ?.filter((item) => item.projectId === project.id) // filter those which belong to the current project
    .reduce(applyFilterLogic, { transactionsStack: [], sideStack: [] });
  // the reducer above returns { transactionsStack: [], sideStack: [] }, we only need transactionsStack

  // we need a list of all manual quotes from globalQuotesArrayView
  // get all distinct assetIds from transactions
  // transactions is an object of objects, so we need to flatten it first
  const assetIds = [
    ...new Set(
      transactionsPrep?.transactionsStack
        .filter((item) => item?.category !== 'deposits')
        .map((item) => {
          if (!item.assetId && !item.figi) console.log('item', item);
          return item.assetId || item.figi;
        }),
    ),
  ];
  if (debugLevel > 2) console.log('transactionPrep', transactionsPrep);
  if (debugLevel > 2) console.log('assetIds', assetIds);

  // caution: also used by AddProjectQuote
  const isolatedProjectId = project.settings?.isIsolated ? project.id : undefined;
  const selectQuotes = useSelector((state) => globalQuotesArrayView(state, isolatedProjectId));
  // we need to display manual quotes, eliminate named quotes (so that they don't appear twice)
  // and only show quotes for assets of this projects (defined in assetIds), by comparing figi, assetId and accountId (we could do it by category, but trying to stay robust)
  // assetId isn't used in this context as of 230624, but to make it future-proof
  // also, filter out all quotes that are null or undefined
  const quotes = selectQuotes.filter(
    (item) => item.source === 'manual'
      && typeof item.date === 'number'
      && item.quote !== null && item.quote !== undefined
      && item.date === item.quoteDate
      && ((assetIds || []).includes(item.accountId) || (assetIds || []).includes(item.figi) || (assetIds || []).includes(item.assetId)),
  );

  // --------------------------------------------
  // Filter logic (user-defined filters)
  // --------------------------------------------

  // the filter listbox sets the contents of filteredItems; if an item is in filteredItems, it is to be displayed and v.v.
  // default setting is that all catetories are displayed
  // this is a callback to a filter function which filters the list of transactions for ProjectTransactionList (before adding quotes and goals)
  function filterCallback(transaction) {
    // return true if the item is to be displayed, false if it is not to be displayed
    // if filteredItems does not include 'simulatedDividends' and the transaction has the right label and is simulated, then return false
    if (!filteredItems.includes('simulatedDividends') && transaction.label === 'investment-dividend' && transaction.isSimulated) return false;
    if (!filteredItems.includes('simulatedInterest') && transaction.label === 'investment-interest' && transaction.isSimulated) return false;
    if (!filteredItems.includes('simulatedPension') && transaction.label === 'investment-pension' && transaction.isSimulated) return false;
    if (!filteredItems.includes('simulatedQuotes') && Object.prototype.hasOwnProperty.call(transaction, 'quote')) return false;
    return true;
  }

  // this useEffect handles changes to the RECURRING option (only) in filterItems (which are used to filter transactions by one of their properties)
  // if recurring appears in filteredItems, go through all transactions and add ids of all transactions which have tags?.recurring?.activated to visibleRecurringTransactions
  // if recurring does not appear in filteredItems, empty visibleRecurringTransactions
  useEffect(() => {
    if (filteredItems.includes('recurring')) {
      const newVisibleRecurringTransactions = [];
      transactionsPrep?.transactionsStack.forEach((transaction) => {
        if (transaction.tags?.recurring?.activated) newVisibleRecurringTransactions.push(transaction.id);
      });
      setVisibleRecurringTransactions(newVisibleRecurringTransactions);
    } else {
      setVisibleRecurringTransactions([]);
    }
  }, [filteredItems]);

  // ----------------------------------------------------
  // Prepare transaction + quote + goal list for display
  // ----------------------------------------------------

  // package transactions + quotes in the required format for the drag-and-drop library
  const transactions = transactionsPrep?.transactionsStack
    // show all "parent" transactions (with no recurringParentId) and:
    // - if the transaction has a recurringParentId, check if the recurringParentId is in the visibleRecurringTransactions array (i.e. is visible)
    // - if it is, then show the transaction, otherwise hide it (used to show / display children of recurring transactions)
    ?.filter((item) => !item.recurringParentId || visibleRecurringTransactions?.includes(item.recurringParentId))
    .concat(quotes)
    .filter(filterCallback)
    .concat(project.goals || [])
    // put them into a structure required by the drag-and-drop library (items: container1: [item1, item2], container2: [item3, item4] ...)
    // in other words, { month1: (first day midnight of the month as epoch): [transactions], month2: [transactions], } ...
    .reduce(
      (prev, item) => ({
        ...prev,
        [item.date]: [...(prev[item.date] || []), item].sort((a, b) => a.sortingOrderWithinMonth - b.sortingOrderWithinMonth),
      }),
      {},
    );
  // caution: we don't really have an "isSimulated" filter here, because the general assumption is there will also be non-simulation transactions in the project
  // e.g. when it is underway

  if (debugLevel > 2) {
    console.log(
      'input transactions from selector:',
      useSelector((state) => allTransactionsProjectView(state)?.filter((item) => item.projectId === project.id)),
    );
  }
  if (debugLevel > 2) {
    console.log('processed transactions - stage 1', transactionsPrep);
  }
  if (debugLevel > 2) {
    console.log('processed transactions - final stage', transactions);
  }

  const [breaker, setBreaker] = useState(false);

  useEffect(() => {
    // continuously replicate the store's contents onto state
    // if there is a difference between the store's contents and the state's contents
    // circuit breaker switches this mechanism off for the duration of the drag-and-drop operation
    // including the POST call to backend, so that the user doesn't see confusing behaviour of the dragged element
    if (!isEqual(transactions, items) && breaker === false) {
      if (debugLevel > 2) console.log('updating items from transactions');
      setItems(transactions);
    }
  }, [transactions]);

  // sensors for the drag-and-drop mechanism
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 10,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  // source of all the drag-and-drop magic: https://codesandbox.io/s/lknfe?file=/src/app.js
  // needed to find draggable container by id of the droppable element
  function findContainer(id) {
    if (id in items) {
      return id;
    }

    return Object.keys(items).find((key) => items[key].findIndex((item) => item.id === id) > -1);
  }

  function handleDragStart(event) {
    const { active } = event;
    console.log('handleDragStart', event);
    const { id } = active;

    setBreaker(true); // turning off the circuit breaker to prevent store being replicated onto state
    setActiveTransaction(items[findContainer(id)].find((item) => item.id === id));
  }

  function handleDragOver(event) {
    const { active, over, draggingRect } = event;
    console.log('handleDragOver', event);
    const { id } = active;
    const { id: overId } = over;

    // Find the containers
    const activeContainer = findContainer(id);
    const overContainer = findContainer(overId);

    if (!activeContainer || !overContainer || activeContainer === overContainer) {
      return;
    }

    setItems((prev) => {
      const activeItems = prev[activeContainer];
      const overItems = prev[overContainer];

      // find the current indices for the 'active' and 'over' items
      const activeIndex = activeItems.findIndex((item) => item.id === id);
      const overIndex = overItems.findIndex((item) => item.id === overId);

      let newIndex;
      if (overId in prev) {
        // that means we are at the root droppable container
        newIndex = overItems.length + 1;
      } else {
        const isBelowLastItem = over && overIndex === overItems.length - 1;
        // && draggingRect.offsetTop > over.rect.offsetTop + over.rect.height; // deprecated in API, only meant to be functional in certain cases - test if necessary

        const modifier = isBelowLastItem ? 1 : 0;

        newIndex = overIndex >= 0 ? overIndex + modifier : overItems.length + 1;
      }

      return {
        ...prev,
        [activeContainer]: [...prev[activeContainer].filter((item) => item.id !== active.id)],
        [overContainer]: [...prev[overContainer].slice(0, newIndex), items[activeContainer][activeIndex], ...prev[overContainer].slice(newIndex, prev[overContainer].length)],
      };
    });
  }

  // handle what happens when item is dropped
  function handleDragEnd(event) {
    const { active, over } = event;
    console.log('handleDragEnd', event);
    if (!over || !active) return;
    const { id } = active;
    const { id: overId } = over;

    const activeContainer = findContainer(id);
    const overContainer = findContainer(overId);

    if (!activeContainer || !overContainer || activeContainer !== overContainer) {
      return;
    }

    const activeIndex = items[activeContainer].findIndex((item) => item.id === active.id);
    const overIndex = items[overContainer].findIndex((item) => item.id === overId);

    if (activeIndex !== overIndex) {
      // spits out a new state with a new value for item.date
      setItems((prev) => ({
        ...prev,
        [overContainer]: arrayMove(items[overContainer], activeIndex, overIndex),
      }));
      // i.e. send a transaction to store that changes the sortingOrderWithinMonth for all transactions that belong to item.date -- and the effect will be the same
      // arrayMove returns the target array with its target sequence, so we can use it to update the state
      // we just need to update sortingOrder... with the current index of that array
      const toBeDispatched = arrayMove(items[overContainer], activeIndex, overIndex).map((item, index) => ({ ...item, sortingOrderWithinMonth: index }));
      // they are already in the correct structure which is [ { ...transaction-object, category: 'category-name' } ]
      dispatch(postMixedData({ payload: toBeDispatched }));
      // as soon as all the involved transactions get updated, switch the circuit breaker back on
      // TODO in the future can do a more precise check, for now let us just do the setTimeout
      setTimeout(() => setBreaker(false), 5000);
    }
    setActiveTransaction(null);
  }

  return (
    <div id="project-transactions-list" className="w-full lg:pr-2 flex flex-col gap-4 items-start">
      <DndContext sensors={sensors} collisionDetection={closestCenter} onDragStart={handleDragStart} onDragOver={handleDragOver} onDragEnd={handleDragEnd}>
        {/* reduce transactions for this project to months, and then within each filter transactions for the given month */}
        {/* container is: date row + sortable context element + transactions, it repeats for every month found in data */}
        {/* keys of the transactions object are months, defined as 1. calendar day 00:00:00Z */}
        {/* order by month ascending */}
        {Object.keys(items || [])
          .sort((a, b) => (Number(a) > Number(b) ? 1 : -1))
          .filter((item) => items[item].length > 0)
          .map((month) => (
            <React.Fragment key={Number(month)}>
              <DateSeparator dateArg={Number(month)} />
              {items[month].filter((transaction) => 'quote' in transaction).length > 0 && (
                <div className="w-full flex flex-row justify-center items-center -mt-2 space-x-2">
                  {items[month]
                    .filter((transaction) => 'quote' in transaction)
                    .map((q) => (
                      <QuoteComponent quote={q} setQuoteDialog={setQuoteDialog} projectAssets={projectAssets} key={`${q.assetId}-${q.date}-${q.quote}`} />
                    ))}
                </div>
              )}
              {items[month].filter((transaction) => 'name' in transaction).length > 0 && (
                <div className="w-full flex flex-row justify-center items-center -mt-2">
                  {items[month]
                    .filter((transaction) => transaction.name)
                    .map((g) => (
                      <GoalComponent key={`${g.date}`} goal={g} project={project} setGoalDialog={setGoalDialog} />
                    ))}
                </div>
              )}
              {items[month].filter((transaction) => !transaction.quote && !transaction.name).length > 0 && (
                <SortableContext id={month} items={items[month]}>
                  {items[month]
                    .filter((transaction) => !('quote' in transaction) && !('name' in transaction)) // filter out quotes, they stay a level above, but need to be sorted to months
                    .map((transaction, idx) => (
                      <DraggableTransaction
                        key={transaction.id}
                        id={`project-transactions-item-${month}-idx`}
                        transaction={transaction}
                        project={project}
                        setTransactionDialog={setTransactionDialog}
                        visibleRecurringTransactions={visibleRecurringTransactions}
                        setVisibleRecurringTransactions={setVisibleRecurringTransactions}
                        setAssetTileHover={setAssetTileHover}
                      />
                    ))}
                </SortableContext>
              )}
            </React.Fragment>
          ))}
      </DndContext>
    </div>
  );
}
ProjectTransactionList.propTypes = {
  project: PropTypes.objectOf(PropTypes.any).isRequired,
  setTransactionDialog: PropTypes.func.isRequired,
  setQuoteDialog: PropTypes.func.isRequired,
  setGoalDialog: PropTypes.func.isRequired,
  setAssetTileHover: PropTypes.func.isRequired,
  projectAssets: PropTypes.arrayOf(PropTypes.any),
  filteredItems: PropTypes.arrayOf(PropTypes.any).isRequired,
};
ProjectTransactionList.defaultProps = {
  projectAssets: [],
};
