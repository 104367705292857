// figure out how this should look like
// date | currency | price1 | price2 | price3 | price4 (all account currency)
// the handleSave function must take this and pack it into specialQuotes before sending it on its merry way

import getQuotes from '../../../redux/reducers/sharedThunks/getQuotes';
import { calculateDatesAndAssetIds } from '../../../redux/actions/data/helpers';

// INPUT transformations

// used to put quote object attributes in the exact order expected by table and grid layouts
export function categoryOrderedObject(account = null) { //  outer function takes a parameter returns a CALLBACK function for .map with that parameter already in
  return function pensionOrderedObjectInnerFunc(quote) {
    // this has to exactly follow the column order laid out by gridLayout
    return {
      date: quote.date,
      payoutOnlyContributionsToDate: (quote.specialQuotes.find((sq) => sq.type === 'payoutOnlyContributionsToDate') || { quote: null }).quote,
      payoutOnlyContributionsToDateIndexed: (quote.specialQuotes.find((sq) => sq.type === 'payoutOnlyContributionsToDateIndexed') || { quote: null }).quote,
      payoutAllPlannedContributions: (quote.specialQuotes.find((sq) => sq.type === 'payoutAllPlannedContributions') || { quote: null }).quote,
      payoutAllPlannedContributionsIndexed: (quote.specialQuotes.find((sq) => sq.type === 'payoutAllPlannedContributionsIndexed') || { quote: null }).quote,
      currency: quote.currency,
    };
  };
}

// applies category-related final changes before sending to Grid
export function applyCategorySpecificChanges(_quotes, account = null) {
  return _quotes.map(categoryOrderedObject(account));
}

// OUTPUT transformations

// used inside of .map
// performs category - specific transformations after standard transformations of Grid output
// seqeunce of properties is no longer important
// this happens already after items with inputFlag = 'delete' have been added, so just take care of 'put'
export function outputTransformCategoryQuotes(account = null) { //  outer function takes a parameter returns a CALLBACK function for .map with that parameter already in
  return function outputTransformCategoryQuotesInnerFunc(item) {
    const specialQuotes = [];
    if (item.payoutOnlyContributionsToDate) {
      specialQuotes.push({ type: 'payoutOnlyContributionsToDate', quote: item.payoutOnlyContributionsToDate });
    }
    if (item.payoutOnlyContributionsToDateIndexed) {
      specialQuotes.push({ type: 'payoutOnlyContributionsToDateIndexed', quote: item.payoutOnlyContributionsToDateIndexed });
    }
    if (item.payoutAllPlannedContributions) {
      specialQuotes.push({ type: 'payoutAllPlannedContributions', quote: item.payoutAllPlannedContributions });
    }
    if (item.payoutAllPlannedContributionsIndexed) {
      specialQuotes.push({ type: 'payoutAllPlannedContributionsIndexed', quote: item.payoutAllPlannedContributionsIndexed });
    }

    return {
      ...item,
      currency: account.currency,
      assetId: item.assetId || account.id,
      source: (item.source) || 'manual',
      importFlag: item.importFlag || 'put',
      projectId: null,
      quote: 0,
      quoteBaseCurrency: 0,
      specialQuotes,
    };
  };
}

// TABLE

// used in table to get quotes from backend
export function handleSync(category, _quotes, dispatch) {
  dispatch(getQuotes(calculateDatesAndAssetIds(_quotes)));
}
