/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { BanknotesIcon } from '@heroicons/react/24/outline';
import SumWithLabel from './SumWithLabel';
import { currentNetWorth2 } from '../../redux/reducers/data';
import { CURRENT_NET_WORTH } from './constants';

function SumCurrentNetWorth({ baseCurrency, isViewer }) {
  const { t } = useTranslation();

  // imported a global selector defined in the reducer module
  const selectCurrentNetWorth = isViewer ? useSelector((state) => state.viewer.kpis.currentNetWorth) : useSelector(currentNetWorth2);

  const typeProps = {
    name: t('app:dashboard.sumtile.currentNetWorth'),
    stat: selectCurrentNetWorth?.toLocaleString('de', {
      style: 'currency',
      currency: baseCurrency || 'EUR',
      maximumFractionDigits: 0,
    }),
    icon: BanknotesIcon,
  };

  return <SumWithLabel id={CURRENT_NET_WORTH} name={typeProps.name} stat={typeProps.stat} icon={typeProps.icon} change={typeProps.change} isViewer={isViewer} />;
}

SumCurrentNetWorth.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  baseCurrency: PropTypes.string.isRequired,
  isViewer: PropTypes.bool,
};
SumCurrentNetWorth.defaultProps = {
  isViewer: false,
};

const SumCurrentNetWorthMemo = React.memo(SumCurrentNetWorth);
export default SumCurrentNetWorthMemo;
