/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { QuestionMarkCircleIcon } from '@heroicons/react/24/solid';
import { useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { convertCurrency } from '../../misc/ecbCurrencyRates';
import MiniSpinner from '../../misc/MiniSpinner';
import Button from '../../elements/Button';

dayjs.extend(utc);

/**
 * This component is displayed to user when ColumnMatcher is opened for a pension accounts and the user must provide base currency (special) quotes.
 */
export default function ColumnMatcherAddQBCToPension({ specialQuotes, accountCurrency, baseCurrency, callback, reject }) {
  const { t } = useTranslation('app', { keyPrefix: 'accountDetails' });
  const [selection, setSelection] = useState(null);
  const [fxRate, setFxRate] = useState(null);

  // prepare initialisation values
  const { register, setValue, handleSubmit, errors } = useForm();

  // when the component intialises, get today's fx rate
  useEffect(() => {
    async function updateFxRate() {
      setFxRate('loading');
      let newRate;
      try {
        newRate = await convertCurrency(1, accountCurrency, baseCurrency, dayjs.utc().startOf('day').valueOf());
        setFxRate(newRate);
      } catch (err) {
        setFxRate(null);
      }
    }
    updateFxRate();
  }, [accountCurrency]);

  function handleIgnore() {
    reject();
  }

  function handleOK(userInput) {
    callback(userInput.fxRate);
    window.dispatchEvent(
      new CustomEvent('setDialog', {}),
    );
  }

  return (
    <div className="mx-auto max-w-full md:max-w-xl p-4 overflow-hidden rounded-lg bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
      <div>
        <QuestionMarkCircleIcon className="mx-auto flex items-center justify-center h-12 w-12 text-brandBlue-500" aria-hidden="true" />
        <div className="mt-3 text-center sm:mt-5">
          <h3 className="text-lg leading-6 font-bold text-gray-900">
            {t('pension.import.baseCurrencyDialog.title')}
          </h3>
          {(fxRate === 'loading')
            ? (<MiniSpinner className="mx-auto mt-2 h-5 w-5" />)
            : (<p className="text-center mx-auto mt-2 text-sm text-gray-500 prose">{`${t('pension.import.baseCurrencyDialog.prompt')}`}</p>)}
          <div className="mt-4 mx-4 grid grid-cols-2 gap-4">
            <div>
              <p className="text-gray-500 text-left">{t('pension.import.baseCurrencyDialog.fxRate')}</p>
              <input
                type="number"
                step="0.04"
                name="fxRate"
                id="fxRate"
                {...register('fxRate', { valueAsNumber: true, required: true })}
                className="mt-0.5 w-full flex-1 focus:ring-brandBlue-400 focus:border-brandBlue-400 block min-w-0 rounded-md sm:text-sm border-gray-300"
              />
              {errors?.fxRate && (
              <div className="pt-2 sm:text-sm text-brandRed-500 max-w-fit">
                <span>{errors?.fxRate.message}</span>
              </div>
              )}
            </div>
            <Button
              text={`← ${t('pension.import.baseCurrencyDialog.apply')} ${((fxRate && fxRate !== 'loading') ? fxRate : 0).toFixed(4)}`}
              noFrameNoArrow
              onClick={() => { setValue('fxRate', fxRate); }}
              formatting="self-end justify-self-start"
              size="xl"
            />
          </div>
          <div className="grid grid-cols-4 gap-2 mt-4 mx-4 text-xs md:text-sm text-gray-400">
            {specialQuotes.slice(0, 3).map((quote) => (
              <>
                <span className="col-span-2 text-left">{t(`columns.${quote.type}.label`)}</span>
                <span className="text-right">{quote.quote.toLocaleString('de', { style: 'currency', currency: accountCurrency, maximumFractionDigits: 2 })}</span>
                <span className="text-right">{(quote.quote * (fxRate || 0)).toLocaleString('de', { style: 'currency', currency: baseCurrency, maximumFractionDigits: 2 }) }</span>
              </>
            ))}
          </div>
        </div>
      </div>

      <div className="mt-7 sm:mt-8 grid grid-cols-2 gap-2">
        <Button
          text={t('pension.import.cancel')}
          name="dialog-cancel-button"
          id="dialog-form-cancel-button"
          onClick={handleIgnore}
        />
        <Button
          withAccent
          text="OK"
          name="dialog-save-button"
          id="dialog-form-save-button"
          onClick={handleSubmit(handleOK)}
        />
      </div>
    </div>
  );
}
ColumnMatcherAddQBCToPension.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  specialQuotes: propTypes.array.isRequired,
  callback: propTypes.func.isRequired,
  accountCurrency: propTypes.string.isRequired,
  baseCurrency: propTypes.string.isRequired,
  reject: propTypes.func.isRequired,
};
