/* This example requires Tailwind CSS v2.0+ */
import React from 'react';

/* This example requires Tailwind CSS v2.0+ */
export default function alertDialog(props) {
  return (
    <div className="bg-white shadow sm:rounded-lg" data-testid="alert-dialog">
      <div className="px-4 py-5 sm:p-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">{props.title}</h3>
        <div className="mt-2 max-w-xl text-sm text-gray-500">
          <p>{props.text}</p>
        </div>
        <div className="mt-5">
          <button
            type="button"
            className="inline-flex items-center justify-center px-4 py-2 border border-transparent font-medium rounded-md text-gray-700 bg-gray-200 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:text-sm"
            onClick={() => props.dialogStateCallback({ visible: false })}
          >
            {props.buttonText}
          </button>
        </div>
      </div>
    </div>
  );
}
