import { useTranslation } from 'react-i18next';
import React from 'react';
import { Navigate, useParams } from 'react-router-dom';

export default function GoTo() {
  // this component is to be used for routing to pages within the domain
  // as well as retrieving language versions of the url
  const params = useParams();
  const { i18n } = useTranslation();

  let target;
  switch (params.target) {
    case 'anonymous-use':
      switch (i18n.language) {
        case 'de':
          target = '/de/blog/anleitung/finanzen-management-app-anonym-nutzen-monestry-und-ihr-anonymes-modus';
          break;
        default: // 'en'
          target = '/en/blog/guide/use-finance-management-app-anonymously-monestry-and-its-anonymous-mode';
          break;
      }
      break;
    case 'data-sources':
      switch (i18n.language) {
        case 'de':
          target = '/de/blog/anleitung/wie-halten-wir-die-daten-aktuell';
          break;
        default: // 'en'
          target = '/en/blog/guide/how-do-we-keep-your-financials-current';
          break;
      }
      break;
    default:
      break;
  }
  console.log('returning target:', target);
  return (
    <Navigate to={target} state={`{ origin: /goto/${params.target} }`} />
  );
}
