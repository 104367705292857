import { ArrowDownTrayIcon, AtSymbolIcon, BanknotesIcon, BuildingStorefrontIcon, ChartBarIcon, CircleStackIcon, ClockIcon, HomeIcon, TrophyIcon } from '@heroicons/react/24/outline';

// if changing colors, need to als update following files:

export const decor = {
  deposits: {
    Icon: BanknotesIcon,
    color: {
      bg: 'bg-brandBlue-100',
      hover: 'bg-brandBlue-200',
      bottom: 'bg-brandBlue-500',
      text: 'text-brandBlue-600',
      ring: 'ring-brandBlue-700',
      hex: '#1797f2', // for charts
    },
  },
  stocks: {
    Icon: ChartBarIcon,
    color: {
      bg: 'bg-brandViolet-100',
      hover: 'bg-brandViolet-200',
      bottom: 'bg-brandViolet-500',
      text: 'text-brandViolet-600',
      ring: 'ring-brandViolet-700',
      hex: '#bb6dce',
    },
  },
  realEstate: {
    Icon: HomeIcon,
    color: {
      bg: 'bg-brandGreen-100',
      hover: 'bg-brandGreen-200',
      bottom: 'bg-brandGreen-500',
      text: 'text-brandGreen-600',
      ring: 'ring-brandGreen-700',
      hex: '#92d050',
    },
  },
  pension: {
    Icon: ClockIcon,
    color: {
      bg: 'bg-brandOrange-100',
      hover: 'bg-brandOrange-200',
      bottom: 'bg-brandOrange-500',
      text: 'text-brandOrange-600',
      ring: 'ring-brandOrange-700',
      hex: '#ff7f50',
    },
  },
  objectsOfValue: {
    Icon: TrophyIcon,
    color: {
      bg: 'bg-brandDesertBrown-100',
      hover: 'bg-brandDesertBrown-200',
      bottom: 'bg-brandDesertBrown-500',
      text: 'text-brandDesertBrown-600',
      ring: 'ring-brandDesertBrown-700',
      hex: '#f4a460',
    },
  },
  metals: {
    Icon: CircleStackIcon,
    color: {
      bg: 'bg-brandYellow-100',
      hover: 'bg-brandYellow-200',
      bottom: 'bg-brandYellow-500',
      text: 'text-brandYellow-600',
      ring: 'ring-brandYellow-700',
      hex: '#ffd600',
    },
  },
  loans: {
    Icon: ArrowDownTrayIcon,
    color: {
      bg: 'bg-brandRed-100',
      hover: 'bg-brandRed-200',
      bottom: 'bg-brandRed-500',
      text: 'text-brandRed-600',
      ring: 'ring-brandRed-700',
      hex: '#ed1c23',
    },
  },
  unlistedShares: {
    Icon: BuildingStorefrontIcon,
    color: {
      bg: 'bg-brandEmerald-100',
      hover: 'bg-brandEmerald-200',
      bottom: 'bg-brandEmerald-500',
      text: 'text-brandEmerald-600',
      ring: 'ring-brandEmerald-700',
      hex: '#3cb371',
    },
  },
  crypto: {
    Icon: AtSymbolIcon,
    color: {
      bg: 'bg-brandDarkBlue-100',
      hover: 'bg-brandDarkBlue-200',
      bottom: 'bg-brandDarkBlue-500',
      text: 'text-brandDarkBlue-600',
      ring: 'ring-brandDarkBlue-700',
      hex: '#2e3192',
    },
  },
};

export default decor;
