/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { XMarkIcon } from '@heroicons/react/24/solid';
import dayjs from 'dayjs';
import MiniSpinner from '../../misc/MiniSpinner';
import { putProject } from '../../redux/actions/projects';
import { setMessage } from '../../redux/actions/message';

const debugLevel = process.env.REACT_APP_MDEBUG || 0;

const utc = require('dayjs/plugin/utc');

dayjs.extend(utc);

// goalDialog can have a goal object, true or null
// can be called to add a new goal (with setGoalDialog === true)
// or to edit a transaction (with setGoalDialog containing the goal object)
export default function AddProjectGoal({ goalDialog, setGoalDialog, project }) {
  const { t } = useTranslation('app', { keyPrefix: 'projects' });

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false); // save button was clicked
  const [deleting, setDeleting] = useState(false); // delete button was clicked

  const baseCurrency = useSelector((state) => state.user.profile.settings.baseCurrency);

  const defaultValuesEmptyForm = {
    name: null,
    date: dayjs.utc().add(10, 'years').startOf('month').format('YYYY-MM-DD'),
    // first day of current month; toISOString returns UTC time (which is 1 day behind in Berlin), so using Swedish format which the same timezone as Berlin
    amount: 100000,
  };

  // initialise the form with values from an existing object or default values
  const formObject = useForm({
    mode: 'onBlur',
    defaultValues: (goalDialog === true
      ? defaultValuesEmptyForm
      : {
        ...goalDialog,
        date: dayjs.utc(goalDialog.date).format('YYYY-MM-DD'), // convert date to the format expected by the input control
      }),
  });
  const {
    handleSubmit, register, setValue, formState: { errors },
  } = formObject;

  async function onGoalChange(e, mode = 'put') {
    e.preventDefault();
    if (mode === 'delete') setDeleting(true);
    else setLoading(true);
    const formDataRaw = formObject.getValues();
    const formData = {
      ...formDataRaw,
      date: dayjs.utc(formDataRaw.date).valueOf(),
    };

    const newProjectObject = {
      ...project, goals: (project.goals || []).filter((g) => g.date !== goalDialog?.date), // remove the original goal (passed via props)
    };
    if (mode !== 'delete') newProjectObject.goals.push(formData); // if this is add or change, add the new goal

    try {
      await dispatch(putProject(newProjectObject));
      dispatch(setMessage('dataUpdatedSuccessfully'));
      setGoalDialog(false);
    } catch (error) {
      console.error('AddProjectGoal error', error);
      dispatch(setMessage('dataUpdateError'));
    }
    setLoading(false);
    setDeleting(false);
  }

  const myRef = useRef(null);
  // scroll all the way up when the dialog opens
  useEffect(() => {
    if (goalDialog) myRef.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
  }, [goalDialog]);

  return (
    <div id="add-project-goal" data-testid="add-project-goal" className="bg-opacity-90 bg-gray-300 absolute top-0 left-0 w-full h-full z-[100] p-4" ref={myRef}>
      <div className="flex flex-col items-center justify-center h-full">
        <form
          autoComplete="off"
          onSubmit={(e) => handleSubmit(onGoalChange(e, 'put'))}
          className="relative bg-white rounded-md shadow-lg p-8 max-w-6xl"
        >
          <button
            type="button"
            id="project-details-add-transaction-close"
            onClick={(e) => { setGoalDialog(false); }}
            className="absolute top-4 right-4 cursor-pointer"
          >
            <XMarkIcon
              className="w-6 h-6 text-gray-500 hover:text-gray-600"
            />
          </button>
          <div className="mb-8">
            <h2 className="inline text-2xl font-bold text-gray-900">{t('addGoal')}</h2>
            <h3 className="text-lg font-normal text-gray-900">{t('addGoalDescription')}</h3>
          </div>
          <div className="sm:grid sm:grid-cols-3 gap-12">
            {/* Goal description */}
            <div className="pb-8">
              <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                {t('name')}
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="name"
                  id="name"
                    // onChange
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-brandBlue-500 focus:ring-brandBlue-600 sm:text-sm"
                  {...register('name', { required: t('required') })}
                />
              </div>
              <div className="pt-2 sm:text-sm text-brandRed-500 max-w-fit">
                <span>{errors.name?.message}</span>
              </div>
            </div>
            <div>
              {/* Date */}
              <div className="pb-8">
                <label htmlFor="date" className="block text-sm font-medium text-gray-700">
                  {t('date')}
                </label>
                <div className="mt-1">
                  <input
                    type="date"
                    name="date"
                    id="date"
                    step="month"
                    // onChange
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-brandBlue-500 focus:ring-brandBlue-600 sm:text-sm"
                    {...register('date', {
                      // round down to first day of month
                      onChange: (e) => {
                        const dateEpoch = new Date(e.target.value);
                        dateEpoch.setDate(1);
                        let value;
                        try {
                          [value] = dateEpoch.toISOString().split('T');
                        } catch (err) {
                          // when user uses arrow to change day by one lower and comes to an incorrect date as an effect (e.g. 31-02-2021)
                          // the field sends an empty string as e.target.value and we reset it to the previous value (from form state)
                          // value = transactionDate;  // FIXME
                        }
                        setValue('date', value);
                      },
                      validate: (value) => {
                        if (new Date(value) > new Date()) return true;
                        return t('date.error');
                      },
                    })}
                  />
                </div>
                <div className="pt-2 sm:text-sm text-brandRed-500 max-w-fit">
                  <span>{errors.date?.message}</span>
                </div>
              </div>
            </div>
            {/* Price per piece (or "amount" for deposits assets like time deposits) */}
            <div className="grid grid-cols-2 gap-3" id="project-details-add-goal-amount">
              <div>
                <label htmlFor="amount" className="block text-sm font-medium text-gray-700">
                  {t('amount.label')}
                </label>
                <div className="mt-1 flex items-center">
                  <input
                    type="number"
                    step={1}
                    name="amount"
                    id="amount"
                    placeholder={100000}
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-brandBlue-500 focus:ring-brandBlue-600 sm:text-sm"
                    {...register('amount', {
                      min: { value: 0, message: t('amount.error') },
                    })}
                  />
                  <span className="ml-1 text-sm">{baseCurrency}</span>
                </div>
                <div className="pt-2 sm:text-sm text-brandRed-500 max-w-fit">
                  <span>{errors.amount?.message}</span>
                </div>
              </div>
            </div>
          </div>
          {/* buttons */}
          <div className="flex justify-start items-center gap-4 pt-6">
            <button
              id="project-add-goal-save"
              data-testid="project-add-goal-save"
              type="submit"
              // eslint-disable-next-line max-len
              className="inline-flex items-center rounded-md border border-transparent bg-brandBlue-500 hover:bg-brandBlue-600 px-4 py-2 text-sm font-medium leading-4 text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-brandBlue-400 focus:ring-offset-2"
            >
              {(loading === false)
                ? t('save')
                : (
                  <div id="project-add-goal-spinner" className="px-2">
                    <MiniSpinner className="w-4 h-4 text-white animate-spin" />
                  </div>
                )}
            </button>
            <button
              id="project-add-goal-delete"
              type="button"
              onClick={(e) => onGoalChange(e, 'delete')}
              disabled={goalDialog === true} // disabled for new entry
              // eslint-disable-next-line max-len
              className={`inline-flex items-center rounded-md border px-4 py-2 text-sm font-medium leading-4 ${goalDialog === true ? 'hidden' : 'text-gray-700 border-gray-300 bg-white'} shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-brandBlue-400 focus:ring-offset-2`}
            >
              {(deleting === false)
                ? t('deleteGoal')
                : (
                  <div id="project-delete-goal-spinner" className="px-2">
                    <MiniSpinner className="w-4 h-4 text-gray-400 animate-spin" />
                  </div>
                )}
            </button>
            <button
              id="project-add-goal-cancel"
              type="button"
              onClick={(e) => {
                setGoalDialog(false);
              }}
              // eslint-disable-next-line max-len
              className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-brandBlue-400 focus:ring-offset-2"
            >
              {t('cancel')}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
AddProjectGoal.propTypes = {
  goalDialog: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  setGoalDialog: PropTypes.func.isRequired,
  project: PropTypes.object.isRequired,
};
AddProjectGoal.defaultProps = {
  goalDialog: null,
};
