import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ToolTip from '../../../elements/ToolTip';

export default function SwitchComponent({
  onChange, onBlur, label, tooltip, optional, value, formatting,
}) {
  const { t } = useTranslation();

  let displayedText;
  switch (value) {
    case true:
      displayedText = t('site:general.yes');
      break;
    case false:
      displayedText = t('site:general.no');
      break;
    default:
      displayedText = '?';
  }

  function handleClick() {
    if (value === true) onChange(false);
    if (value === false) onChange(undefined);
    if (!value && value !== false) onChange(true);
  }

  return (
    <div className={`${formatting} col-span-1 flex flex-col space-y-1`}>
      <div className="flex justify-between text-sm">
        <div className="flex">
          <label htmlFor="switch-button" className="font-medium text-gray-700">{label}</label>
          <ToolTip info={tooltip} />
        </div>
        {(optional) && (<span className="text-sm text-gray-500" id="email-optional">Optional</span>)}
      </div>
      <div className="flex items-center w-full">
        <div className="flex gap-2 items-center w-full">
          <button
            type="button"
            // eslint-disable-next-line max-len
            className={`${optional} rounded-md w-full px-4 py-2 text-sm font-bold shadow-sm border border-gray-300 bg-white text-gray-900 hover:bg-gray-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brandBlue-500`}
            onClick={handleClick}
          >
            {displayedText}
          </button>
        </div>
      </div>
    </div>
  );
}
SwitchComponent.propTypes = {
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  formatting: PropTypes.string,
  label: PropTypes.string.isRequired,
  tooltip: PropTypes.string.isRequired,
  optional: PropTypes.bool,
  value: PropTypes.bool.isRequired,
};
SwitchComponent.defaultProps = {
  onBlur: () => { },
  optional: true,
  formatting: '',
};
