import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line import/no-extraneous-dependencies
import { hastToReact } from 'property-information';
// ↑↑ DO NOT REMOVE -- this is a fix for an issue solved in comments to PRD-1405
import ReactMarkdown from 'react-markdown';
import posts from '../blog/posts.json';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function Article(props) {
  console.log('rendering Article', new Date().toISOString().substr(14, 9));
  const { t, i18n } = useTranslation(['site']);
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const [thisPost, setThisPost] = useState(posts.find((post) => post[i18n.language].displayKey === params.article));

  // implements "scroll to anchor" functionality
  useEffect(() => {
    const { hash } = window.location;
    if (hash) {
      const element = document.getElementById(hash.slice(1));
      if (element) {
        element.scrollIntoView();
      }
    }
  }, []);

  // scroll to the top of the page (somehow it got rendered in the middle)
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function handleGoBackClick(e) {
    e.preventDefault();
    // this looks unnecessary, but if pages are accessed via the GoTo component, using "navigate(-1)" just calls out the goto page and returns here
    // there is probably an explanation, but no time to check
    if (location.state && location.state.indexOf('goto') > -1) navigate(-1);
    navigate(-1);
  }

  return (
    <div className="relative bg-white overflow-hidden">
      <div className="absolute inset-x-0 max-w-7xl mx-auto px-4 py-8 rounded opacity-75 sm:px-6 md:grid md:grid-cols-3 md:space-x-8 lg:px-8">
        <div className="md:col-span-2">
          <div className="max-w-prose text-lg mx-auto">
            <button type="button" onClick={handleGoBackClick} className="text-white hover:text-gray-100 text-base tracking-wide">
              {`← ${t('site:article.return-prev-page')}`}
            </button>
          </div>
        </div>
      </div>
      <div className="hidden md:block md:inset-y-0 md:right-0 md:h-[50vh]">
        <img className="inset-0 h-[50vh] w-full object-cover object-bottom" src={thisPost?.image.article} alt="" />
      </div>
      {/* FIXME dotted areas stay or go? <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full lg:pt-[50vh]"> */}
      <div className="hidden">
        <div className="relative h-full text-lg max-w-prose mx-auto" aria-hidden="true">
          <svg className="absolute top-12 left-full transform translate-x-32" width={404} height={384} fill="none" viewBox="0 0 404 384">
            <defs>
              <pattern id="74b3fd99-0a6f-4271-bef2-e80eeafdf357" x={0} y={0} width={20} height={20} patternUnits="userSpaceOnUse">
                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
              </pattern>
            </defs>
            <rect width={404} height={384} fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)" />
          </svg>
          <svg className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32" width={404} height={384} fill="none" viewBox="0 0 404 384">
            <defs>
              <pattern id="f210dbf6-a58d-4871-961e-36d5016a0f49" x={0} y={0} width={20} height={20} patternUnits="userSpaceOnUse">
                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
              </pattern>
            </defs>
            <rect width={404} height={384} fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)" />
          </svg>
          <svg className="absolute bottom-12 left-full transform translate-x-32" width={404} height={384} fill="none" viewBox="0 0 404 384">
            <defs>
              <pattern id="d3eb07ae-5182-43e6-857d-35c643af9034" x={0} y={0} width={20} height={20} patternUnits="userSpaceOnUse">
                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
              </pattern>
            </defs>
            <rect width={404} height={384} fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)" />
          </svg>
        </div>
      </div>
      <div id="blog-title" className="relative px-4 max-w-7xl mx-auto py-8 sm:px-6 md:grid md:grid-cols-3 md:space-x-8 lg:px-8 lg:py-16">
        <div className="relative md:col-span-2">
          <div className="text-lg max-w-prose mx-auto">
            <h1>
              <span className="block text-base text-left text-brandBlue-500 font-semibold tracking-wide uppercase">{thisPost && t(`site:blog.categories.${thisPost.category.key}.title`)}</span>
              <span className="mt-2 block text-3xl text-left leading-8 font-black tracking-tight text-gray-900 sm:text-5xl">{thisPost && thisPost[i18n.language].title}</span>
            </h1>
            {/* <p className="mt-3 text-sm text-gray-500">
              {`${t('site:article.written-by')} ${thisPost?.author}`}
            </p> */}
            <p className="mt-3 text-sm text-gray-500">
              {`${t('site:article.published')} ${thisPost && thisPost[i18n.language].published}`}
              {thisPost && thisPost[i18n.language].updated ? `- ${t('site:article.updated')} ${thisPost && thisPost.updated}` : null}
            </p>
          </div>
          <div className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
            {console.log('rendering Markdown in tree')}
            <ReactMarkdown>{thisPost[i18n.language].body}</ReactMarkdown>
            {/* {thisPost && <ReactMarkdown>{thisPost[i18n.language].body}</ReactMarkdown>} */}
            {/* <ReactMarkdown>{thisPost && thisPost[i18n.language].body}</ReactMarkdown> */}
          </div>
        </div>
        {/* related articles */}
        <div>
          {thisPost
            && posts
              .filter((post) => thisPost.relatedPosts.some((relatedPost) => relatedPost === post.id))
              .map((post, idx) => (
                <div className="mb-12" key={post.id}>
                  <h3 className={classNames(idx > 0 ? 'hidden' : 'text-2xl font-bold text-left mb-9')}>{t('site:article.related-articles')}</h3>
                  <div data-testid="related-items" className="flex flex-col rounded-lg shadow-lg overflow-hidden my-6">
                    <div className="flex-shrink-0">
                      <img className="h-48 w-full object-cover" src={post.image.thumb} alt="" />
                    </div>
                    <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                      <div className="flex-1">
                        <Link to={post[i18n.language].href} className="block mt-2">
                          <p className="text-xl font-semibold text-gray-900">{post[i18n.language].title}</p>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          <h3 className="text-2xl font-bold text-left mb-9">{t('site:article.fixed-articles-header')}</h3>
          <div className="flex flex-col rounded-lg shadow-lg overflow-hidden my-6">
            <div className="flex-shrink-0">
              {/* eslint-disable-next-line max-len */}
              <img
                className="h-48 w-full object-cover"
                // eslint-disable-next-line max-len
                src="https://images.unsplash.com/photo-1493723843671-1d655e66ac1c?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDUyOTN8MHwxfGFsbHx8fHx8fHx8fDE2NDU3ODU2MDQ&ixlib=rb-1.2.1&q=80&w=400"
                alt=""
              />
            </div>
            <div className="flex-1 bg-white p-6 flex flex-col justify-between">
              <div className="flex-1">
                <Link to={`/${params.lang}/features`} className="block mt-2">
                  <p className="text-xl font-semibold text-gray-900">{t('site:article.features-title')}</p>
                </Link>
              </div>
            </div>
          </div>
          <div className="flex flex-col rounded-lg shadow-lg overflow-hidden my-6">
            <div className="flex-shrink-0">
              {/* eslint-disable-next-line max-len */}
              <img
                className="h-48 w-full object-cover"
                src="https://images.unsplash.com/photo-1551288049-bebda4e38f71?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMDUyOTN8MHwxfGFsbHx8fHx8fHx8fDE2NDU3ODYwMjI&ixlib=rb-1.2.1&q=80&w=400"
                alt=""
              />
            </div>
            <div className="flex-1 bg-white p-6 flex flex-col justify-between">
              <div className="flex-1">
                <Link to={`/${params.lang}/demo`} className="block mt-2">
                  <p className="text-xl font-semibold text-gray-900">{t('site:article.demo-title')}</p>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
