/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import SignUp1 from './SignUp1';
import SignUp2 from './SignUp2';
import SignUp3 from './SignUp3';
import MiniSpinner from '../../misc/MiniSpinner';

export default function SignUp() {
  const { t, i18n } = useTranslation(['site']);

  const payment = useSelector((state) => state.user.payment);

  const [state, setState] = useState('username'); // 'username', 'package', 'payment', 'processing' - it will get texts from translation by using those
  const [formObject, setFormObject] = useState({
    username: '',
    password: '',
    repeatPassword: '',
    email: '',
    country: 'Germany',
    tier: '',
    annualBilling: null,
    billingPerMonth: 0,
  });

  function goForward() {
    switch (state) {
      case 'username':
        setState('package');
        break;
      case 'package':
        setState('payment');
        break;
      default:
        break;
    }
  }

  function goBack(event) {
    event.preventDefault();

    switch (state) {
      case 'package':
        setState('username');
        break;
      case 'payment':
        setState('package');
        break;
      default:
        break;
    }
  }

  // handle reentry to payment after payment failed
  useEffect(() => {
    // SignUp3 would put the 'formObject' object into session storage
    // PaymentSuccessful would put 'error' status in the store
    // expect { signUpState, formObject } corresponding to state of this component (state, formState)
    const savedFormObject = sessionStorage.getItem('formObject');
    if (payment?.status === 'error' && savedFormObject) {
      setFormObject(JSON.parse({ ...formObject, ...savedFormObject.formObject }));
      setState(savedFormObject.signUpState);
    }
  }, []);

  return (
    <div className="bg-gray-50">
      {/* FIXME see how this ^^ behaves in lower widths */}
      <div className="max-w-7xl mx-auto py-12 px-4 sm:py-8 sm:px-6 lg:px-8">
        <div className="">
          <div className="text-center">
            <p className="mt-1 text-3xl font-black text-gray-900 sm:text-4xl sm:tracking-tight lg:text-5xl">{t(`site:register.${state}.header`)}</p>
            <p className="max-w-xl mt-5 mx-auto pb-2 text-xl text-gray-500">{t(`site:register.${state}.subheader`)}</p>
          </div>
          {state === 'username' ? <SignUp1 formObject={formObject} formSetObject={setFormObject} goForward={goForward} /> : null}
          {state === 'package' ? <SignUp2 formState={formObject} formSetState={setFormObject} goForward={goForward} goBack={goBack} /> : null}
          {state === 'payment' ? <SignUp3 formState={formObject} formSetState={setFormObject} goBack={goBack} /> : null}
          {state === 'processing' ? <MiniSpinner className="h-5 w-5 animate-spin text-gray-400" /> : null}
        </div>
      </div>
    </div>
  );
}
