import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Dialog, Transition } from '@headlessui/react';
import { PencilIcon, ShareIcon } from '@heroicons/react/24/outline';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import Dropdown from './Dropdown';
import { setMessage } from '../redux/actions/message';
import MiniSpinner from '../misc/MiniSpinner';

dayjs.extend(utc);

function Icon({ isShareReport }) {
  return (
    <div className={`mx-auto flex items-center justify-center h-12 w-12 rounded-full ${isShareReport ? 'bg-brandGreen-500' : 'bg-brandBlue-100'}`}>
      {isShareReport ? <ShareIcon className="h-6 w-6 text-white" aria-hidden="true" /> : <PencilIcon className="h-6 w-6 text-brandBlue-500" aria-hidden="true" />}
    </div>
  );
}
Icon.propTypes = {
  isShareReport: PropTypes.bool,
};
Icon.defaultProps = {
  isShareReport: false,
};

/**
 *  This component displays a dialog window on top of the app component stack. It displays standard dialogs with text blocks and OK / Cancel buttons or custom components.
 *
 *  It subscribes to 'setDialog' window event which receives the following attributes in event.detail:
 *  - required payload: { header, prompt, callback  } or { Component, props (optional) }
 *  and either displays an input dialog with a header, a prompt and OK / Cancel buttons (OK button triggers callback and closes this component) or a custom component.
 *
 *  Pass 'value' attribute if you want to prefill the input field.
 *
 *  For special types of dialog following extra attributes can be passed:
 *  - dropdown: <array> (uses { id, name } in the array and in 'value' if prefilled)
 *  - array: <array> (provide array uses { id, name } in the array and in 'value' if prefilled), multiple selection is allowed
 *  - boolean (value is a boolean)
 *  - date: <string, epoch ms>
 *  - sharedReport - special form for SharedReport only

 *  Value is placed in the input / dropown / boolean field -- dropdown requires { id, name } in the list and in the value fields.
 */
export default function DialogForm() {
  const [open, setOpen] = useState(false);
  const [dialog, setDialog] = useState({});
  const [isTouched, setIsTouched] = useState(false);
  const [formValue, setFormValue] = useState(null);
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation(['app']);
  const dispatch = useDispatch();

  useEffect(() => {
    function handleDialogEvent(e) {
      console.log('DialogForm received event', JSON.stringify(e.detail, null, 2));
      if (e.detail) {
        setDialog({ ...e.detail, visible: true });
        setOpen(true);
      } else setOpen(false);
      if (e.detail?.value !== undefined) {
        setFormValue(e.detail.value);
        setIsTouched(false);
      }
    }
    window.addEventListener('setDialog', handleDialogEvent);
    return () => window.removeEventListener('setDialog', handleDialogEvent);
  }, []);

  async function handleClick(e) {
    setLoading(true);
    try {
      await dialog.callback(formValue);
      // reset dialog
      setFormValue(null);
      setDialog({});
      setIsTouched(false);
      setLoading(false);
      setOpen(false);
    } catch (err) {
      console.log(err);
      dispatch(setMessage('accountCouldNotBeUpdated'));
      setLoading(false);
    }
  }

  function handleCancel() {
    setFormValue(null);
    setDialog({});
    setIsTouched(false);
    setOpen(false);
  }

  useEffect(() => {
    function handleEsc(e) {
      // ↓↓ if it is not open, we should just ignore it here
      if (!open) return;
      // if (open) e.stopImmediatePropagation(); // if it is open, stop the event from propagating; otherwise let other listeners receive it
      if (open && e.key === 'Escape') {
        e.stopImmediatePropagation(); // if it is open, stop the event from propagating; otherwise let other listeners receive it
        handleCancel();
      }
    }

    // if (display === 'grid' || display === 'table') {
    document.addEventListener('keydown', handleEsc, false);
    // }
    return () => {
      document.removeEventListener('keydown', handleEsc, false);
    };
  }, [open]); // if there are dependencies, then they all need to be in one object; if it is more, it creates several listeners

  // these two help with managing true / false / null in Dropdown
  function boolToStr(val) {
    if (val === true) return 'strTrue';
    if (val === false) return 'strFalse';
    return val;
  }

  function setValueBool(obj) {
    if (obj === null) {
      setFormValue(null);
      return;
    }
    if (obj.id === 'strTrue') {
      setFormValue({ id: true, name: '✔️' });
      return;
    }
    if (obj.id === 'strFalse') {
      setFormValue({ id: false, name: '✖️' });
      return;
    }
    setFormValue(null);
  }

  function handleShareReportForm(value, idx) {
    // if the value is not a number, reset
    const arr = formValue.split('-');
    arr[idx] = Number.isNaN(Number(value)) ? '000' : value;
    setFormValue(arr.join('-'));
    setIsTouched(true);
  }

  function handlePaste(e) {
    const pasteData = e.clipboardData.getData('text');
    const parts = pasteData.split('-');

    if (parts.length === 3 && parts.every((part) => !Number.isNaN(Number(part))) && parts.every((part) => part.length === 3)) {
      setFormValue(pasteData);
    }
  }

  const confirmButtonLabel = dialog?.shareReport ? t('dashboard.shareReport.next') : t('accountDetails.buttons.save.label');

  return dialog?.visible ? (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" id="dialog-form" className="fixed z-50 inset-0 overflow-y-auto" onClose={setOpen}>
        <div className="flex items-start justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child as="div" enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as="div"
            id="dialog-form-content"
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            className={`relative inline-block align-bottom rounded-lg px-4 pt-5 pb-4 text-left transform transition-all sm:my-8 sm:align-middle sm:p-6
            ${dialog?.Component ? 'sm:max-w-5xl sm:w-full' : 'shadow-xl bg-white sm:max-w-sm sm:w-full'}`}
          >
            {dialog?.Component ? (
              // if there is a Component, display it and do not render the rest of the dialog
              // eslint-disable-next-line react/jsx-props-no-spreading
              <dialog.Component {...dialog.props} />
            ) : (
              <>
                <div>
                  {/* special icon for shareReport */}
                  <Icon isShareReport={dialog?.shareReport} />
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title as="h3" className="text-lg leading-6 font-bold text-gray-900">
                      {dialog?.header}
                    </Dialog.Title>
                    <p className="mt-2 text-sm text-gray-500">{dialog?.prompt}</p>
                    <div className="mt-6">
                      {!dialog?.boolean && !dialog?.dropdown && !dialog?.array && !dialog.shareReport && (
                        <input
                          type={dialog.date ? 'date' : 'text'}
                          value={dialog.date ? dayjs.utc(formValue).format('YYYY-MM-DD') : formValue}
                          onChange={(e) => {
                            // handle number vs string
                            setFormValue(Number(e.target.value) || e.target.value);
                            if (!isTouched) setIsTouched(true);
                          }}
                          // eslint-disable-next-line max-len
                          className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-brandBlue-600 sm:text-sm sm:leading-6"
                        />
                      )}
                      {dialog?.dropdown && (
                        <Dropdown
                          label=""
                          list={dialog?.dropdown}
                          value={formValue}
                          onChange={(e) => {
                            setFormValue(e);
                            setIsTouched(true);
                          }}
                          optional={false}
                        />
                      )}
                      {dialog?.array && (
                        <Dropdown
                          label=""
                          list={dialog?.array}
                          value={formValue}
                          onChange={(e) => {
                            setFormValue(e);
                            setIsTouched(true);
                          }}
                          optional={false}
                          multiple
                        />
                      )}
                      {dialog?.boolean && (
                        <Dropdown
                          label=""
                          list={[{ id: boolToStr(true), name: '✔️' }, { id: boolToStr(false), name: '✖️' }, null]} // the active property does not distinguish id: false from id: null
                          value={formValue !== null ? { id: boolToStr(formValue), name: formValue ? '✔️' : '✖️' } : null}
                          onChange={(e) => {
                            setValueBool(e);
                            setIsTouched(true);
                          }}
                          optional={false}
                        />
                      )}
                      {dialog?.shareReport && (
                        <div className="w-full flex space-x-2 justify-center items-center align-middle">
                          <input
                            className="border border-gray-300 w-16 rounded-md text-gray-500 text-xl text-center"
                            maxLength={3}
                            type="text"
                            value={formValue.split('-')[0]}
                            onChange={(e) => handleShareReportForm(e.target.value, 0)}
                            onPaste={handlePaste}
                          />
                          <span className="text-xl font-black text-gray-400">—</span>
                          <input
                            className="border border-gray-300 w-16 rounded-md text-gray-500 text-xl text-center"
                            type="text"
                            maxLength={3}
                            value={formValue.split('-')[1]}
                            onChange={(e) => handleShareReportForm(e.target.value, 1)}
                            onPaste={handlePaste}
                          />
                          <span className="text-xl font-black text-gray-400">—</span>
                          <input
                            className="border border-gray-300 w-16 rounded-md text-gray-500 text-xl text-center"
                            type="text"
                            maxLength={3}
                            value={formValue.split('-')[2]}
                            onChange={(e) => handleShareReportForm(e.target.value, 2)}
                            onPaste={handlePaste}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="mt-7 sm:mt-8 grid grid-cols-2 gap-2">
                  <button
                    type="button"
                    name="dialog-cancel-button"
                    id="dialog-form-cancel-button"
                    // eslint-disable-next-line max-len
                    className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-500 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brandBlue-400 sm:text-sm"
                    onClick={handleCancel}
                  >
                    {t('accountDetails.buttons.cancel.label') || 'Cancel'}
                  </button>
                  <button
                    type="button"
                    name="dialog-save-button"
                    id="dialog-form-save-button"
                    // eslint-disable-next-line max-len
                    className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-brandBlue-500 text-base font-medium text-white hover:bg-brandBlue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brandBlue-400 sm:text-sm"
                    onClick={handleClick}
                  >
                    {loading ? <MiniSpinner className="h-5 w-5 text-white animate-spin" aria-hidden="true" /> : confirmButtonLabel || 'OK'}
                  </button>
                </div>
              </>
            )}
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  ) : null;
}
