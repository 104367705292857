/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/forbid-prop-types */
// this must return a different component depending on the transaction type selected
import React from 'react';
import PropTypes from 'prop-types';
import InflowOutflowTransfer from './InflowOutflowTransfer';
import BuySell from './BuySell';

export default function AddProjectTransactionData({
  transactionType, accountList, control, register, errors, watch, unregister, setValue, getFieldState, stocksAssets,
}) {
  const standardProps = {
    accountList, control, register, errors, watch, unregister, setValue, getFieldState,
  };

  switch (transactionType) {
    case 'inflow':
      return (
        <InflowOutflowTransfer mode="inflow" {...standardProps} />
      );
    case 'outflow':
      return (
        <InflowOutflowTransfer mode="outflow" {...standardProps} />
      );
    case 'transfer':
      return (
        <InflowOutflowTransfer mode="transfer" {...standardProps} />
      );
    case 'buy':
      return (
        <BuySell mode="buy" {...standardProps} />
      );
    case 'sell':
      return (
        <BuySell mode="sell" {...standardProps} stocksAssets={stocksAssets} />
      );
    default:
      return (
        <div />
      );
  }
}
AddProjectTransactionData.propTypes = {
  transactionType: PropTypes.string.isRequired,
  accountList: PropTypes.arrayOf(PropTypes.object).isRequired,
  control: PropTypes.objectOf(PropTypes.any).isRequired,
  register: PropTypes.func.isRequired,
  unregister: PropTypes.func.isRequired,
  errors: PropTypes.objectOf(PropTypes.any).isRequired,
  watch: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  getFieldState: PropTypes.func.isRequired,
  stocksAssets: PropTypes.array.isRequired,
};
