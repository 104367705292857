/* eslint-disable react/forbid-prop-types */
/* eslint-disable max-len */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect, useCallback } from 'react';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { nanoid } from 'nanoid';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { CreditCardIcon } from '@heroicons/react/24/outline';
import { InformationCircleIcon } from '@heroicons/react/20/solid';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import MiniSpinner from '../../misc/MiniSpinner';
import Button from '../../elements/Button';

const debugLevel = process.env.MDEBUG || 0;

const utc = require('dayjs/plugin/utc');

dayjs.extend(utc);

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

// this component contains the Stripe PaymentElement and can only be initialised once Stripe setup intent comes through
export function PaymentComponent({ formObject, goBack, subscription }) {
  const stripe = useStripe();
  const elements = useElements();
  const { t, i18n } = useTranslation(['site']);

  const [message, setMessage] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  const user = {
    username: formObject.userId,
    password: formObject.password,
    email: formObject.email,
    address: { countryCode: 'DE' },
    preferredUsername: formObject.username,
    baseCurrency: formObject.baseCurrency,
    locale: i18n.language,
    provider: formObject.provider,
    externalProcess: !!formObject.provider,
    tierId: formObject.tierId, // in "pay later" scenario there is no payment, so tier will be set now
    stripeCustomerId: subscription.stripeCustomerId,
  };

  async function handleSubmit(e, payLater = false) {
    setSubmitting(true);
    e.preventDefault();

    // save form status to local storage should there be an error AFTER Stripe redirects user to return_url
    localStorage.setItem('signUpObject', JSON.stringify({ ...user, signUpState: 'payment' }));

    // the user will be REDIRECTED to return_url (sometimes even to more pages first for SCA)
    // on the return_url the user will be asked to enter email verification from Cognito
    const returnUrl = `${window.location.origin}/callback`;
    if (debugLevel > 2) console.log('checking return_url composition: ', returnUrl);
    // if it returns something, then it is an error; otherwise the user gets forwarded to a SCA verification page and then to return_url

    // if the user provided method of payment, confirm and save it with stripe, then redirect to /callback
    if (!payLater) {
      const { error } = await stripe.confirmSetup({
        elements,
        confirmParams: {
          return_url: returnUrl,
        },
      });

      if (error) {
        // this point will only be reached if there is an immediate error when confirming the payment. Show error to your customer (for example, payment details incomplete)
        setMessage(error.message);
        setSubmitting(false);
      }
    } else {
      // if the user chose to pay later, redirect to /callback
      if (debugLevel > 2) console.log('redirecting to /callback');
      window.location.href = `${returnUrl}?pay_later=1`;
    }
  }

  return (
    <form id="payment-form" onSubmit={handleSubmit} className="p-4">
      {/* Show any error or success messages */}
      {message && (
        <div id="payment-message" className="text-red-500 font-semibold">
          {message}
        </div>
      )}
      <PaymentElement id="payment-element" />
      <div className="flex justify-between">
        <div className="flex justify-end space-x-4 py-4">
          <button
            type="button"
            className="inline-flex items-center px-3 py-2.5 border border-brandBlue-400 text-sm leading-4 font-semibold rounded-md shadow-sm text-brandBlue-500 hover:bg-brandBlue-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brandBlue-400 cursor-pointer"
            onClick={(e) => handleSubmit(e, true)}
          >
            {t('register.signUp3.payLater')}
          </button>
        </div>
        <div className="flex justify-end space-x-4 py-4">
          <button
            type="button"
            className="inline-flex items-center px-3 py-2 border border-brandBlue-400 text-sm leading-4 font-semibold rounded-md shadow-sm text-brandBlue-500 hover:bg-brandBlue-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brandBlue-400 cursor-pointer"
            onClick={goBack}
          >
            {t('general.back')}
          </button>
          <Button
            enabled={stripe && elements && !submitting}
            loading={submitting}
            text={t('register.signUp3.payNow')}
            id="submit"
            name="completetransaction"
            withAccent
            type="submit"
          />
        </div>
      </div>
      {(formObject.provider) && (
      <div className="rounded-md bg-blue-50 p-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <InformationCircleIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
          </div>
          <div className="ml-3 flex-1 md:flex md:justify-between">
            <p className="text-sm text-brandBlue-700">{t('register.signUp3.externalInfo', { provider: formObject.provider.replace('LoginWith', '').replace('SignInWith', '') })}</p>
            <p className="mt-3 text-sm md:ml-6 md:mt-0" />
          </div>
        </div>
      </div>
      )}
    </form>
  );
}
PaymentComponent.propTypes = {
  formObject: PropTypes.objectOf(PropTypes.any).isRequired,
  goBack: PropTypes.func.isRequired,
  subscription: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default function SignUp3({ formState, goBack }) {
  const { i18n, t } = useTranslation('site', { keyPrefix: 'register.signUp3' });

  const [url, setUrl] = useState(`${process.env.REACT_APP_WSS_URL}customer-websocket?clientAuthParam=${nanoid()}`);

  const appearance = {
    theme: 'stripe',
  };
  const [stripeOptions, setStripeOptions] = useState({ appearance, locale: i18n.language });
  const [subscription, setSubscription] = useState(null); // subscription object from Stripe

  const terminateWebSocketConnection = useCallback(() => setUrl(null), []);

  const { sendJsonMessage, lastMessage, readyState } = useWebSocket(url, {
    onOpen: () => { if (debugLevel > 2) console.log('SignUp3 > websocket connection opened'); },
    onMessage: (message) => { if (debugLevel > 2) console.log('SignUp3 > websocket message received: ', message); },
    onError: (error) => { if (debugLevel > 2) console.error('SignUp3 > websocket error: ', error); },
  });

  // -------------------------
  // INITIALISE COMPONENT
  // -------------------------

  // send the action to register user, create subscription and get SecretID
  // once it is here, go to the payment component
  useEffect(() => {
    // when the status changes to 'connectionOpen', it means the connection got open
    if (readyState === ReadyState.OPEN && !lastMessage) {
      sendJsonMessage({
        action: 'postCustomer', // API GW uses that to route the message to the right lambda
        email: formState.email,
        username: formState.userId,
        registrationCode: formState.registrationCode, // if user is being invited, this is the registration code
        stripePriceId: formState.stripePriceId,
        tierId: formState.tierId, //  'tier-1' etc.
      });
    }
    if (lastMessage && JSON.parse(lastMessage.data).clientSecret) {
      // postCustomer returns subscription parameters
      const parsedMessage = JSON.parse(lastMessage.data);
      setStripeOptions({
        // stripeOptions are passed to the stripe Elements component
        ...stripeOptions,
        clientSecret: parsedMessage.clientSecret, // used to retrieve this session after payment callback is ready
      });
      setSubscription({
        ...parsedMessage,
        billingCycleAnchor: parsedMessage.billingCycleAnchor * 1000,
      }); // used to display basket item
      terminateWebSocketConnection();
    }
  }, [lastMessage, readyState]);

  // parse the incoming stripe subscription attrs
  const amountPerMonth = (subscription?.interval === 'year' ? (subscription?.amount || 0) / 12 : subscription?.amount)?.toLocaleString('de', {
    style: 'currency',
    currency: subscription?.currency || 'EUR',
    maximumFractionDigits: 2,
  });
  const billingAmount = subscription?.amount?.toLocaleString('de', { style: 'currency', currency: subscription?.currency, maximumFractionDigits: 2 });

  const navigation = [
    {
      name: t('creditCard'),
      href: '#',
      icon: CreditCardIcon,
      current: true,
    },
  ];

  return (
    <div className="mt-10 grid lg:grid-cols-12 lg:gap-x-5">
      <aside className="w-full sm:w-2/3 sm:mx-auto lg:w-full py-6 px-2 sm:px-6 lg:py-0 lg:px-0 lg:col-span-3">
        <nav className="space-y-1">
          {navigation.map((item) => (
            <a
              key={item.name}
              href={item.href}
              className={classNames(
                item.current ? 'bg-white text-brandBlue-500 hover:text-brandBlue-600 hover:bg-white' : 'text-gray-900 hover:text-gray-900 hover:bg-white',
                'group rounded-md px-3 py-2 flex items-center text-sm font-medium',
              )}
              aria-current={item.current ? 'page' : undefined}
            >
              <item.icon
                className={classNames(item.current ? 'text-brandBlue-500 group-hover:text-brandBlue-600' : 'text-gray-400 group-hover:text-gray-500', 'flex-shrink-0 -ml-1 mr-3 h-6 w-6')}
                aria-hidden="true"
              />
              <span className="truncate">{item.name}</span>
            </a>
          ))}
        </nav>
      </aside>

      <div className="bg-white space-y-6 sm:px-6 lg:px-0 lg:col-span-6">
        {stripeOptions.clientSecret ? (
          <Elements stripe={stripePromise} options={stripeOptions}>
            <PaymentComponent formObject={formState} goBack={goBack} subscription={subscription} />
          </Elements>
        ) : (
          <div>
            <div className="grid animate-pulse grid-cols-2 items-center h-full justify-center gap-4 m-5" id="paymentElementSkeleton">
              <div className="bg-gray-200 border border-gray-200 h-20 rounded-md col-span-2 w-full" />
              <div className="bg-gray-200 border border-gray-200 h-20 rounded-md w-full" />
              <div className="bg-gray-200 border border-gray-200 h-20 rounded-md w-full" />
              <div className="bg-gray-200 border border-gray-200 h-20 rounded-md w-full" />
              <div className="bg-gray-200 border border-gray-200 h-20 rounded-md w-full" />
            </div>
          </div>
        )}
      </div>

      {/* basket */}
      <div className="order-first lg:order-last sm:px-6 lg:px-0 lg:col-span-3">
        <div className="w-full sm:w-2/3 sm:mx-auto lg:w-full space-y-1 bg-white text-gray-900 min-h-full rounded text-sm p-4">
          <h3 className="text-xl font-bold pb-3">{t('yourOrder')}</h3>
          {subscription ? (
            <>
              <h4 className="text-base font-bold text-brandBlue-600">{`Monestry ${formState.tierType.toUpperCase()}`}</h4>
              <p>{`${t('freeUntil')} ${dayjs.utc(subscription?.billingCycleAnchor || null).format('D.MM.YYYY')}`}</p>
              <p>{`${t('afterwards')} ${amountPerMonth?.toLocaleString('de', { style: 'currency', currency: subscription.currency, maximumFractionDigits: 2 })}${t('perMonth')}`}</p>
              <p>{t(`${subscription?.interval}Billing`)}</p>
              <div className="flex justify-between pt-6">
                <p>{t('dueToday')}</p>
                <p className="font-bold">{(0).toLocaleString('de', { style: 'currency', currency: subscription.currency, maximumFractionDigits: 2 })}</p>
              </div>
              <div className="flex justify-between">
                <p>{`${t('dueIn30Days')} ${dayjs.utc(subscription?.billingCycleAnchor || null).format('D.MM.YYYY')}`}</p>
                <p className="font-bold">{billingAmount.toLocaleString('de', { style: 'currency', currency: subscription.currency, maximumFractionDigits: 2 })}</p>
              </div>
              <div className="flex flex-col gap-2 text-gray-500">
                <p className="pt-6 text-xs">
                  {`${t('subscriptionRenewsOn')} ${t(`every${subscription?.interval}`)} ${dayjs
                    .utc(subscription?.billingCycleAnchor || null)
                    .format(subscription?.interval === 'year' ? 'D.MM.' : 'D')}`}
                </p>
                <p className="text-xs">{t('cancelAnyTime')}</p>
                <p className="text-xs">{t('upgradeAnyTime')}</p>
              </div>
            </>
          ) : (
            <div className="flex w-full h-96 justify-center items-center">
              <MiniSpinner className="h-6 w-6 animate-spin text-gray-400" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
SignUp3.propTypes = {
  goBack: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  formState: PropTypes.objectOf(PropTypes.any).isRequired,
};
