import calculatePortfolioAllocation from './calculatePortfolioAllocation';
import calculateDimensionAllocation from './calculateDimensionAllocation';
import getPortfolioDeltaFactors from './getPortfolioDeltaFactors';
import getDimensionDeltaFactors from './getDimensionDeltaFactors';

export const debugLevel = process.env.MDEBUG || 3;

// current allocation is an array of all positions
// target allocation is an array of all rows from the target allocation table
export default function* calculateRebalancing(currentAllocation, targetAllocation) {
  const portfolioAllocation = calculatePortfolioAllocation(currentAllocation, targetAllocation);
  const dimensionAllocation = calculateDimensionAllocation(targetAllocation, currentAllocation);

  const completeTargetAllocation = [
    ...portfolioAllocation,
    ...dimensionAllocation,
  ];

  yield completeTargetAllocation;

  if (debugLevel > 2) console.info('calculateRebalancing: adjustment of target allocation completed for portfolio and dimension', completeTargetAllocation);

  const currentAllocationWithPortfolioFactor = getPortfolioDeltaFactors(currentAllocation, completeTargetAllocation);
  const currentAllocationWithBothFactors = getDimensionDeltaFactors(currentAllocationWithPortfolioFactor, completeTargetAllocation);

  yield currentAllocationWithBothFactors.map((row) => ({ ...row, targetAmount: row.amount * (1 + row.categoryRebalancingAssetDeltaFactor) * (1 + row.dimensionRebalancingAssetDeltaFactor) }));
}
