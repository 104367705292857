import TableRenderers from 'react-pivottable/TableRenderers';
import NivoRenderers from './NivoRenderers';
import ListRenderer from './ListRenderer';
import StockPositionsRenderer from './StockPositionsRenderer';
import KPIReportRendererUnisel from './KPIReportRendererUnisel';

const renderers = {
  ...NivoRenderers,
  ...TableRenderers,
  ...StockPositionsRenderer,
  ...ListRenderer,
  ...KPIReportRendererUnisel,
};

export default renderers;

export function getRendererSettings(rendererName) {
  const genericSettings = {
    xAxisPrimary: {
      label: 'Horizontal axis (X) - top-level categories',
      limit: 2,
    },
    xAxisSecondary: {
      label: 'Horizontal axis (X) - sub-categories',
      limit: 2,
    },
    yAxisPrimary: {
      label: 'Vertical axis (Y) - values',
      limit: 1,
    },
  };

  if (rendererName === 'Pie chart, multi-layers') {
    return {
      xAxisPrimary: {
        label: 'Innermost wheel',
        limit: 1,
      },
      xAxisSecondary: {
        label: 'Outer wheel',
        limit: 1,
      },
      yAxisPrimary: {
        label: 'Slice size',
        limit: 1,
      },
    };
  }
  return genericSettings;
}
