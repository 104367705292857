/**
 * Calculates number of relevant decimal places for a given currency
 *
 * @param {string} currency - The currency code to get the decimal places for
 *
 * @returns {number} - The number of decimal places for the given currency
 */

export default function getCurrencyDecimalPlaces(currency, locale = 'en') {
  // Use a small number to ensure decimal places are included
  const formatted = new Intl.NumberFormat(locale, { style: 'currency', currency }).format(0.1);

  // Find the decimal separator for the current locale
  const decimalSeparator = new Intl.NumberFormat(locale).format(1.1).charAt(1);

  // Extract the part after the decimal separator
  const decimalPart = formatted.split(decimalSeparator)[1];

  // Return the number of digits after the decimal separator, or 0 if none
  return decimalPart ? decimalPart.replace(/\D/g, '').length : 0;
}
