/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useForm, useWatch, Controller } from 'react-hook-form';
import { MinusIcon, PlusIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { Switch } from '@headlessui/react';
import i18n from '../../i18n';
import MiniSpinner from '../../misc/MiniSpinner';
import Dropdown from '../../elements/Dropdown';
import ToolTip from '../../elements/ToolTipNoIcon';
import { InflowIcon, OutflowIcon, TransferIcon } from '../../assets/mdiIcons';
import AddProjectTransactionData from './AddProjectTransactionData';
import { postMixedData, realEstateTransactions } from '../../redux/reducers/data';
import { setSimulationBaseDateAndUpdateQuotes } from '../../redux/reducers/simulation';
import ToolTipOnlyIcon from '../../elements/ToolTipOnlyIcon';
import prepareDataForPost from './prepareDataForPost';
import { setMessage } from '../../redux/actions/message';

const debugLevel = process.env.REACT_APP_MDEBUG || 0;

function TransactionType({ type, labelTop, labelBottom, Icon, colour, value, setValue }) {
  return (
    <button
      type="button"
      id={String(labelTop).toLowerCase()}
      onClick={(e) => setValue(type)}
      className={`rounded-md grid grid-cols-3 items-center bg-white hover:bg-gray-50
      ${String(value).toLowerCase() === String(type).toLowerCase() ? 'border-brandBlue-500 border-2' : 'border'}`}
    >
      <div className={`col-span-1 rounded-full w-10 h-10 m-3 inline-flex items-center justify-center text-white ${colour}`}>
        <Icon className="w-6 h-6" />
      </div>
      <div className="col-span-2 gap-2 text-left">
        <h2 className="text-base font-bold">{labelTop}</h2>
        <p className="text-sm text-gray-500">{labelBottom}</p>
      </div>
    </button>
  );
}
TransactionType.propTypes = {
  type: PropTypes.string.isRequired,
  labelTop: PropTypes.string.isRequired,
  labelBottom: PropTypes.string.isRequired,
  Icon: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  colour: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
};

export const transactionTypes = [
  {
    id: 'inflow',
    labelTop: i18n.t('app:projects.inflow.labelTop'),
    labelBottom: i18n.t('app:projects.inflow.labelBottom'),
    icon: InflowIcon,
    colour: 'bg-brandDarkBlue-400',
  },
  {
    id: 'outflow',
    labelTop: i18n.t('app:projects.outflow.labelTop'),
    labelBottom: i18n.t('app:projects.outflow.labelBottom'),
    icon: OutflowIcon,
    colour: 'bg-brandDarkBlue-400',
  },
  {
    id: 'transfer',
    labelTop: i18n.t('app:projects.transfer.labelTop'),
    labelBottom: i18n.t('app:projects.transfer.labelBottom'),
    icon: TransferIcon,
    colour: 'bg-brandDarkBlue-400',
  },
  {
    id: 'buy',
    labelTop: i18n.t('app:projects.buy.labelTop'),
    labelBottom: i18n.t('app:projects.buy.labelBottom'),
    icon: PlusIcon,
    colour: 'bg-brandGreen-500',
  },
  {
    id: 'sell',
    labelTop: i18n.t('app:projects.sell.labelTop'),
    labelBottom: i18n.t('app:projects.sell.labelBottom'),
    icon: MinusIcon,
    colour: 'bg-brandGreen-500',
  },
];

function TransactionTypesComponent({ value, onChange }) {
  const arg = 'hidden';
  const { t } = useTranslation();

  return (
    <div className="flex gap-4">
      {transactionTypes.map((transactionType) => (
        <TransactionType
          key={transactionType.id}
          type={transactionType.id}
          labelTop={transactionType.labelTop}
          labelBottom={transactionType.labelBottom}
          Icon={transactionType.icon}
          colour={transactionType.colour}
          value={value}
          setValue={onChange}
        />
      ))}
    </div>
  );
}
TransactionTypesComponent.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

/**
 * Component to add a transaction to a project.
 *
 * @param {mixed} transactionDialog - values are: null (dialog is hidden), true (if user clicked New and this is empty) or a transaction object (if user clicked on a transaction)
 * @param {function} setTransactionDialog - setter for the above
 * @param {string} projectId
 * @param {array} stocksAssets
 * @returns React.Component
 *
 * When transaction object is passed in transactionDialog, it already is a complete transaction object, containing hiddenTransaction.
 * Details with regard to quantity, price, etc. are handled in the AddProjectTransactionData component, as they are different for each category.
 */
export default function AddProjectTransaction({ transactionDialog, setTransactionDialog, projectId, stocksAssets }) {
  const { t } = useTranslation('app', { keyPrefix: 'projects' });

  const selectRealEstateTransactions = useSelector((state) => realEstateTransactions(state)); // in project mode, includes all transactions past, present and future
  const realEstateTransactionsCountByAccount = selectRealEstateTransactions.reduce((acc, transaction) => {
    const { accountId } = transaction;
    if (!acc[accountId]) acc[accountId] = 0;
    acc[accountId] += 1;
    return acc;
  }, {});

  // returns accounts across all categories
  const accounts = useSelector((state) => {
    const categories = Object.keys(state.data);
    return (
      categories
        .map((category) => (state.data[category].accounts || []).map((accountsArray) => ({ ...accountsArray, category })))
        .flat()
        // filter out real estate transactions with existing transactions}
        .map((a) => (realEstateTransactionsCountByAccount[a.id] > 0 && a.category === 'realEstate' ? { ...a, notAvailableForBuy: true } : a))
    );
  });
  const account = accounts.find((acc) => acc.id === transactionDialog?.accountId); // this only works for existing transactions, not for new transactions
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  // WARNING: these objects may also have to be updated in TemplateParametersForm (apply template to project also creates project transactions)
  const defaultRecurringObject = {
    activated: false,
    numberOfPeriods: 1,
    periodType: { id: 'month', name: t('repeating.month') },
    end: 'recurringNever', // 'recurringNever' or 'recurringByOccurrence' or 'recurringByDate'; 'recurringNever' will be ended by global selector at the max date of Slider
    endAfterOccurrences: 10,
    endAfterDate: new Date(),
  };

  const defaultIndexedObject = {
    activated: false,
    mode: 'indexedByInflation',
    customRateInput: null,
  };

  const defaultValuesEmptyForm = {
    transactionType: 'inflow',
    amount: 2000,
    assetAmount: 1,
    currency: 'EUR',
    exchangeRate: 1,
    baseCurrencyAmount: 2000,
    transactionCurrency: 'EUR',
    // first day of current month; toISOString returns UTC time (which is 1 day behind in Berlin), so using Swedish format which the same timezone as Berlin
    date: new Date(new Date().getFullYear() + 1, new Date().getMonth(), 1).toLocaleString('sv', { timeZoneName: 'short' }).split(' ')[0],
    recurring: defaultRecurringObject,
    indexed: defaultIndexedObject,
  };

  const tags = typeof transactionDialog.tags === 'string' ? JSON.parse(transactionDialog.tags) : transactionDialog.tags;

  // transfer, buy and sell transactions are represented by 2 transactions each in the data model
  // to make it easier for users, we represend each of those pairs with one transaction in this screen
  // transfer --> only show the positive transaction (the negative transaction is filtered out by ProjectTransactionList)
  // buy / sell --> only show the Asset transaction (the Deposit transaction is filtered out by ProjectTransactionList)

  const initialiseFormObject = {
    ...transactionDialog,
    tags: tags || {},
    transactionType: tags?.type,
    recurring: tags?.recurring ? tags?.recurring : defaultRecurringObject,
    indexed: tags?.indexed ? tags?.indexed : defaultIndexedObject,
    date: new Date(transactionDialog.date).toLocaleString('sv', { timeZoneName: 'short' }).split(' ')[0],
  };

  // add account fields
  // inflow / transfer (represented by the transaction with amount > 0, i.e. it has accountTo)
  if (tags?.type === 'inflow' || tags?.type === 'transfer') {
    initialiseFormObject.accountTo = account;
  }

  // outflow / transfer (this is the linkedTransaction transaction for the transfer transaction here, see above)
  if (tags?.type === 'outflow') initialiseFormObject.account = account;
  if (tags?.type === 'transfer') initialiseFormObject.account = accounts.find((acc) => acc.id === initialiseFormObject.hiddenTransaction.accountId);

  // buy / sell - represented by the asset transaction (the deposit transaction is filtered out by ProjectTransactionList)
  if (tags?.type === 'buy' || tags?.type === 'sell') {
    initialiseFormObject.assetAccount = account;
    initialiseFormObject.assetAmount = (tags?.type === 'sell' ? -transactionDialog.transactionAmount : transactionDialog.transactionAmount) || 1;
    initialiseFormObject.assetUnitPrice = transactionDialog.transactionOriginalPrice || transactionDialog.uptc;
    initialiseFormObject.assetUnitPriceBaseCurrency = transactionDialog.transactionPrice || transactionDialog.upbc;
    initialiseFormObject.assetName = transactionDialog.assetName ? transactionDialog.assetName : '';
    initialiseFormObject.assetId = transactionDialog.assetId ? transactionDialog.assetId : '';
    initialiseFormObject.displaySymbol = transactionDialog.displaySymbol || transactionDialog.assetId || '';
  }

  console.log('initialiseFormObject.displaySymbol', initialiseFormObject.displaySymbol);
  const formObject = useForm({ mode: 'onBlur', defaultValues: transactionDialog === true ? defaultValuesEmptyForm : initialiseFormObject });
  const {
    handleSubmit,
    register,
    unregister,
    watch,
    setValue,
    formState: { errors },
    control,
    trigger,
    getFieldState,
  } = formObject;

  // subscribe to changes in form values
  const transactionType = useWatch({
    control,
    name: 'transactionType',
  });
  const recurring = useWatch({
    control,
    name: 'recurring.activated',
  });
  const indexed = useWatch({
    control,
    name: 'indexed.activated',
  });
  const indexedMode = useWatch({
    control,
    name: 'indexed.mode',
  });
  const recurringEnd = useWatch({
    control,
    name: 'recurring.end',
  });
  const transactionDate = useWatch({
    control,
    name: 'date',
  });
  const assetAccount = useWatch({
    control,
    name: 'assetAccount',
  });

  // if transaction type changes, trigger amount validations
  useEffect(() => {
    async function asyncWrapper() {
      trigger('amount');
    }
    asyncWrapper();
  }, [transactionType]);

  function getCategoryByAccountId(accountId) {
    console.log('getCategoryByAccountId', accountId);
    if (!accountId) return null;
    return accounts.find((a) => a.id === accountId).category;
  }

  // -----------------------------------
  // SUBMIT FORM
  // -----------------------------------

  async function onSubmit(incomingData) {
    // call module function
    // it will return an array with two transactions which need to be posted
    console.log('form fields', incomingData);
    let result;
    try {
      result = await prepareDataForPost({
        formInput: incomingData,
        originalTransaction: transactionDialog,
        projectId,
        category: ['buy', 'sell'].includes(incomingData.transactionType) ? getCategoryByAccountId(incomingData.assetAccount.id) : getCategoryByAccountId(incomingData.accountTo.accountId),
      });
      if (debugLevel > 2) console.log('result from prepareDataForPost', result);
    } catch (e) {
      console.error('AddProjectTransaction > onSubmit caught an error:', e);
      dispatch(setMessage('transactionCreatedError'));
      return;
    }

    // TODO when an error is caught above, AddTransaction MUST NOT CLOSE
    // handle prepareDataForPost noLinkedAccount case (result is an array of 1 or 2 transactions, but one of them would be null)
    // result is false if prepareDataForPost fails
    console.log('result', result);
    if (!result || result.some((txn) => !txn)) {
      return;
    }

    // if no errors, then send data to backend via the correct postData function
    // payload is [ { ...transaction-object, category: 'category-name' } ]
    // prepareDataForPost returns an array of 2 transactions, they have the category attribute already
    try {
      if (result) {
        setLoading(true);
        await dispatch(postMixedData({ payload: result }));
        setTransactionDialog(null);
      }
    } catch (e) {
      // handle errors from posting to backend (display error message and handle user interaction)
      setLoading(false);
      console.error(e);
      throw new Error('Error while submitting to database'); // to stop executing subsequent code
    }

    // put the slider where it needs to be to see this transaction (one month after the transaction date)
    const newDate = new Date(incomingData.date);
    newDate.setMonth(newDate.getMonth() + 1);
    await dispatch(setSimulationBaseDateAndUpdateQuotes({ newDate: newDate.valueOf() }));

    setLoading(false);
    setTransactionDialog(false);
  }

  // const message = useSelector((state) => state.message.message);

  // useEffect(() => {
  //   if (message === 'dataUpdatedSuccessfully' || message === 'dataUpdatedWithErrors' || message === 'dataUpdateProblems') {
  //     setLoading(false);
  //     setTransactionDialog(false);
  //   }
  // }, [message]);

  return (
    <div className="bg-opacity-90 bg-gray-300 absolute top-0 left-0 w-full h-full z-[100] p-4">
      <div className="flex flex-col items-center justify-center h-screen">
        <form autoComplete="off" onSubmit={handleSubmit(onSubmit)} className="relative bg-white rounded-md shadow-lg p-8 space-y-8 max-w-6xl">
          <button
            type="button"
            id="project-details-add-transaction-close"
            onClick={(e) => {
              setTransactionDialog(false);
            }}
            className="absolute top-4 right-4 cursor-pointer"
          >
            <XMarkIcon className="w-6 h-6 text-gray-500 hover:text-gray-600" />
          </button>
          <h2 className="inline text-2xl font-bold text-gray-900">{t('addTransaction')}</h2>
          {/* transaction types */}
          <div className="space-y-4">
            <h3 className="text-xl font-bold text-gray-900">{t('transactionTypes')}</h3>
            <Controller name="transactionType" control={control} render={({ field }) => <TransactionTypesComponent value={field.value} onChange={field.onChange} />} />
          </div>
          {/* transaction data */}
          <div className="grid grid-cols-3 gap-12">
            {/* 1. Transaction details */}
            <div>
              <h3 className="text-lg font-bold text-gray-900 pb-4">{t('transactionDetails')}</h3>
              <AddProjectTransactionData
                transactionType={transactionType || 'inflow'}
                accountList={accounts}
                control={control}
                register={register}
                unregister={unregister}
                errors={errors}
                trigger={trigger}
                watch={watch}
                setValue={setValue}
                getFieldState={getFieldState}
                stocksAssets={stocksAssets}
              />
            </div>
            {/* 2. Date */}
            <div>
              <h3 className="text-lg font-bold text-gray-900 pb-4">{t('date')}</h3>
              <div className="pb-8 -mt-[1px]">
                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                  <ToolTip className="text-black border-gray-400 shadow bg-brandYellow-50" info={t('transactionDate.tooltip')}>
                    {t('transactionDate.label')}
                    <ToolTipOnlyIcon />
                  </ToolTip>
                </label>
                <div className="mt-1">
                  <input
                    type="date"
                    name="date"
                    id="date"
                    step="month"
                    // onChange
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-brandBlue-500 focus:ring-brandBlue-600 sm:text-sm"
                    {...register('date', {
                      // round down to first day of month
                      onChange: (e) => {
                        const dateEpoch = new Date(e.target.value);
                        dateEpoch.setDate(1);
                        let value;
                        try {
                          [value] = dateEpoch.toISOString().split('T');
                        } catch (err) {
                          // when user uses arrow to change day by one lower and comes to an incorrect date as an effect (e.g. 31-02-2021)
                          // the field sends an empty string as e.target.value and we reset it to the previous value (from form state)
                          value = transactionDate;
                        }
                        setValue('date', value);
                      },
                      validate: (value) => {
                        if (new Date(value) > new Date()) return true;
                        return t('transactionDate.error');
                      },
                    })}
                  />
                </div>
                <div className="pt-2 sm:text-sm text-brandRed-500 max-w-fit">
                  <span>{errors.date?.message}</span>
                </div>
              </div>
              {/* do not show that for buy or sell transactions for 'realEstate' */}
              {!(['buy', 'sell'].includes(transactionType) && assetAccount?.category === 'realEstate') && (
                <div>
                  <label htmlFor="recurring" className="block text-sm font-medium text-gray-700 pb-1">
                    {t('recurringTransaction.label')}
                  </label>
                  <Controller
                    name="recurring.activated"
                    control={control}
                    render={({ field }) => (
                      <Switch
                        checked={field.value}
                        id="project-add-transaction-recurring"
                        onChange={field.onChange}
                        className={`relative inline-flex h-5 w-10 items-center rounded-full ${field.value ? 'bg-brandBlue-500 hover:bg-brandBlue-600' : 'bg-gray-300 hover:bg-gray-400'}`}
                      >
                        <span className={`${field.value ? 'translate-x-6' : 'translate-x-1'} inline-block h-3 w-3 transform rounded-full bg-white transition`} />
                      </Switch>
                    )}
                  />
                </div>
              )}
              {recurring && (
                <div className="mt-8">
                  <label htmlFor="indexed" className="block text-sm font-medium text-gray-700 pb-1">
                    {t('indexed.label')}
                  </label>
                  <Controller
                    name="indexed.activated"
                    control={control}
                    render={({ field }) => (
                      <Switch
                        id="project-add-transaction-indexed"
                        checked={field.value}
                        onChange={field.onChange}
                        className={`relative inline-flex h-5 w-10 items-center rounded-full ${field.value ? 'bg-brandBlue-500 hover:bg-brandBlue-600' : 'bg-gray-300 hover:bg-gray-400'}`}
                      >
                        <span className={`${field.value ? 'translate-x-6' : 'translate-x-1'} inline-block h-3 w-3 transform rounded-full bg-white transition`} />
                      </Switch>
                    )}
                  />
                </div>
              )}
              {indexed && recurring && (
                <div className="pl-2">
                  <fieldset className="mt-8 pl-4">
                    <div className="space-y-3">
                      <div className="flex items-center pb-2">
                        <input
                          id="indexedByInflation"
                          name="indexedMode"
                          type="radio"
                          value="indexedByInflation"
                          className="h-4 w-4 border-gray-300 text-brandBlue-500 focus:ring-brandBlue-400"
                          {...register('indexed.mode')}
                        />
                        <label htmlFor="indexedByInflation" className="ml-3 block text-sm font-medium text-gray-700">
                          {t('indexed.byInflation')}
                        </label>
                      </div>
                      <div className="flex items-center">
                        <input
                          id="indexedByCustomRate"
                          name="indexedMode"
                          type="radio"
                          value="indexedByCustomRate"
                          className="h-4 w-4 border-gray-300 text-brandBlue-500 focus:ring-brandBlue-400"
                          {...register('indexed.mode')}
                        />
                        <label htmlFor="indexedByCustomRate" className="ml-3 text-sm font-medium text-gray-700 flex items-center gap-2">
                          {t('indexed.increaseBy')}
                          <input
                            id="indexedByCustomRateInput"
                            name="indexedByCustomRateInput"
                            type="number"
                            disabled={indexedMode !== 'indexedByCustomRate'}
                            className="rounded-md w-16 border-gray-300 shadow-sm focus:border-brandBlue-500 focus:ring-brandBlue-600 sm:text-sm"
                            {...register('indexed.customRateInput', { max: { value: 10000, message: 'Maximum increase is 10000%.' } })}
                          />
                          %
                        </label>
                      </div>
                      <div className="pt-2 sm:text-sm text-brandRed-500 max-w-fit">
                        <span>{errors.indexed?.customRateInput?.message}</span>
                      </div>
                    </div>
                  </fieldset>
                </div>
              )}
            </div>
            {/* 3. Recurrence */}
            {recurring && (
              <div>
                <h3 className="text-lg font-bold text-gray-900 pb-4">{t('repeating.label')}</h3>
                <div className="pb-8 -mt-0.5 grid grid-cols-6 justify-items-stretch">
                  <div className="col-span-2 pr-1">
                    <label htmlFor="recurringNumberOfPeriods" className="block text-sm font-medium text-gray-700">
                      {t('repeating.every')}
                    </label>
                    <div className="mt-1">
                      <input
                        type="number"
                        name="recurringNumberOfPeriods"
                        id="recurringNumberOfPeriods"
                        // if left with no width restriction, it makes the entire Add transaction form take up the full screen width
                        className="block max-w-[6rem] text-center rounded-md border-gray-300 shadow-sm focus:border-brandBlue-500 focus:ring-brandBlue-600 sm:text-sm"
                        placeholder={new Date().toLocaleDateString('de', { timeZone: 'UTC' }).split('T')[0]}
                        {...register('recurring.numberOfPeriods')}
                      />
                    </div>
                  </div>
                  <div className="col-span-4 pt-5">
                    <Controller
                      name="recurring.periodType"
                      control={control}
                      render={({ field }) => (
                        <Dropdown
                          label=""
                          list={[
                            { id: 'month', name: t('repeating.month') },
                            { id: 'year', name: t('repeating.year') },
                          ]}
                          value={field.value}
                          onChange={field.onChange}
                          optional={false}
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="pb-8">
                  <label className="text-sm font-medium text-gray-900">{t('repeating.endBy')}</label>
                  <fieldset className="mt-4">
                    <div className="space-y-3">
                      <div className="flex items-center pb-2">
                        <input
                          id="recurringNever"
                          name="recurringEnd"
                          type="radio"
                          value="recurringNever"
                          className="h-4 w-4 border-gray-300 text-brandBlue-500 focus:ring-brandBlue-400"
                          {...register('recurring.end')}
                        />
                        <label htmlFor="recurringNever" className="ml-3 block text-sm font-medium text-gray-700">
                          {t('repeating.never')}
                        </label>
                      </div>
                      <div className="flex items-center">
                        <input
                          id="recurringByOccurrence"
                          name="recurringEnd"
                          type="radio"
                          value="recurringByOccurrence"
                          className="h-4 w-4 border-gray-300 text-brandBlue-500 focus:ring-brandBlue-400"
                          {...register('recurring.end')}
                        />
                        <label htmlFor="recurringByOccurrence" className="ml-3 text-sm font-medium text-gray-700 flex items-center gap-2">
                          {t('repeating.after')}
                          <input
                            id="recurringEndAfterOccurrences"
                            name="recurringEndAfterOccurrences"
                            type="number"
                            disabled={recurringEnd !== 'recurringByOccurrence'}
                            className="rounded-md w-16 border-gray-300 shadow-sm focus:border-brandBlue-500 focus:ring-brandBlue-600 sm:text-sm"
                            {...register('recurring.endAfterOccurrences', { max: { value: 999, message: t('repeating.afterError') } })}
                          />
                          {t('repeating.occurrences')}
                        </label>
                      </div>
                      <div className="sm:text-sm text-brandRed-500 max-w-fit">
                        <span>{errors.recurring?.endAfterOccurrences?.message}</span>
                      </div>
                      <div className="flex items-center">
                        <input
                          id="recurringByDate"
                          name="recurringEnd"
                          type="radio"
                          value="recurringByDate"
                          className="h-4 w-4 border-gray-300 text-brandBlue-500 focus:ring-brandBlue-400"
                          {...register('recurring.end')}
                        />
                        <label htmlFor="recurringByDate" className="ml-3 text-sm font-medium text-gray-700 flex items-center">
                          {t('repeating.on')}
                          <input
                            id="recurringEndAfterDate"
                            name="recurringEndAfterDate"
                            type="date"
                            disabled={recurringEnd !== 'recurringByDate'}
                            className="ml-2 rounded-md border-gray-300 shadow-sm focus:border-brandBlue-500 focus:ring-brandBlue-600 sm:text-sm"
                            {...register('recurring.endAfterDate')}
                          />
                        </label>
                      </div>
                    </div>
                  </fieldset>
                </div>
              </div>
            )}
          </div>
          {/* buttons */}
          <div className="flex justify-start gap-4 pt-6">
            <button
              id="project-add-transaction-save"
              type="submit"
              // eslint-disable-next-line max-len
              className="inline-flex items-center rounded-md border border-transparent bg-brandBlue-500 px-4 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-brandBlue-600 focus:outline-none focus:ring-2 focus:ring-brandBlue-400 focus:ring-offset-2"
            >
              {loading === false ? (
                t('save')
              ) : (
                <div id="project-add-transaction-spinner" className="px-2">
                  <MiniSpinner className="w-4 h-4 text-white animate-spin" />
                </div>
              )}
            </button>
            <button
              id="project-add-transaction-cancel"
              type="button"
              onClick={(e) => {
                setTransactionDialog(false);
              }}
              // eslint-disable-next-line max-len
              className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-brandBlue-400 focus:ring-offset-2"
            >
              {t('cancel')}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
AddProjectTransaction.propTypes = {
  setCalendarState: PropTypes.func.isRequired,
  transactionDialog: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  setTransactionDialog: PropTypes.func.isRequired,
  projectId: PropTypes.string.isRequired,
  stocksAssets: PropTypes.array.isRequired,
};
AddProjectTransaction.defaultProps = {
  transactionDialog: null,
};
