/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { useState } from 'react';
import { PivotData } from 'react-pivottable/Utilities';
import { useSelector, useDispatch } from 'react-redux';
import { Sankey } from '@nivo/sankey';
import { useTranslation } from 'react-i18next';
import { ArrowsPointingOutIcon } from '@heroicons/react/24/solid';
import { setFullscreenReport } from '../../../redux/actions/message';
import CustomTooltip from '../CustomTooltip';

const colors = ['rgba(23, 151, 242, 1)', 'rgba(146, 208, 80, 1)', 'rgba(187, 109, 206, 1)', 'rgba(255, 214, 0, 1)', 'rgba(237, 28, 35, 1)', 'rgba(46, 49, 146, 1'];

const debugFlag = 3;

function makeNivoRenderer(NivoChart) {
  return function NivoRenderer(props) {
    const pivotData = new PivotData(props);
    const rowKeys = pivotData.getRowKeys();
    const colKeys = pivotData.getColKeys();

    const hideFullscreenButton = useSelector((state) => !state.message.fullscreenReport);

    const [chartErrorMessage, setChartErrorMessage] = useState(null);

    const dispatch = useDispatch();
    const { t } = useTranslation();

    let fullAggName = props.aggregatorName;
    const numInputs = props.aggregators[fullAggName]([])().numInputs || 0;
    if (numInputs !== 0) {
      fullAggName += ` of ${props.vals.slice(0, numInputs).join(', ')}`;
    }

    if (debugFlag > 2) console.log('rowKeys', rowKeys); // all the possible combinations of main key category fields [[2015, 'DKB Giro'], [2017, 'Festgeld'],[2019, 'Festgeld'] ...]
    if (debugFlag > 2) console.log('colKeys', colKeys); // all the possible combinations of the secondary key category fields [['American Express', 'EUR'], ['Arbeitgeber AG', 'EUR'], ['Avis AG', 'EUR']...]
    if (debugFlag > 2) console.log('pivotData with props', pivotData);

    // rowKeys and colKeys have all the needed combinations of keys already
    // e.g. [[2015, 'EUR'], [2015, 'USD'], [2016, 'EUR'], [2017, 'EUR']]
    // even if there are more than 1 attribute in each group
    // so all that needs to happen is to produce for each object (which is an array) in rowKeys
    // an object with the joinedKey
    const rowNames = pivotData.props.rows; // ["Date (Year)", "accountName"]
    const colNames = pivotData.props.cols; // names of the fields that go to secondary category ["otherParty", "currency"]

    let data = [];

    // transform data from PivotTable to hierarchy structure
    const rowKeysByName = rowNames.reduce((prevRowName, currRowName, rowNameIdx) => ({
      ...prevRowName,
      [currRowName]: rowKeys.reduce((prevRowKey, currRowKey) => {
        if (!prevRowKey.includes(currRowKey[rowNameIdx])) prevRowKey.push(currRowKey[rowNameIdx]);
        return prevRowKey;
      }, []),
    }), {});
    const colKeysByName = colNames.reduce((prevColName, currColName, colNameIdx) => ({
      ...prevColName,
      [currColName]: colKeys.reduce((prevColKey, currColKey) => {
        if (!prevColKey.includes(currColKey[colNameIdx])) prevColKey.push(currColKey[colNameIdx]);
        return prevColKey;
      }, []),
    }), {});

    const keysByName = { ...rowKeysByName, ...colKeysByName };
    if (debugFlag > 2) console.info('keysByName', keysByName);

    const fields = Object.keys(keysByName);
    // to simplify the code below, this contains the names of the fields, e.g. ['Transaction sign', 'label', 'otherParty']
    if (debugFlag > 2) console.info('fields', fields);

    function groupByReduce(dataArray, groupPropertyArray) {
      return Object.values(dataArray.reduce((prev, item) => {
        const group = JSON.stringify(groupPropertyArray(item));
        prev[group] = prev[group] || fields.reduce((cum, _, idx) => ({ ...cum, [fields[idx]]: JSON.parse(group)[idx] }), {}); // initialise attribute of the group key
        prev[group].value = prev[group].value || 0; // initialise amount attribute if not yet initialised
        prev[group].value += Number(item.amount); // TODO: vals
        return prev;
      }, {}));
    }

    if (debugFlag > 2) {
      console.info('groupByReduce', groupByReduce(pivotData.props.data.map((x) => ({
        'Transaction sign': x['Transaction sign'], label: x.label, otherParty: x.otherParty, amount: x.amount,
      })), (item) => [item['Transaction sign'], item.label, item.otherParty]));
    }
    if (debugFlag > 2) {
      console.info('groupByReduce', groupByReduce(pivotData.props.data.map((x) => ({
        'Transaction sign': x['Transaction sign'], label: x.label, otherParty: x.otherParty, amount: x.amount,
      })), (item) => [item['Transaction sign'], item.label]));
    }
    if (debugFlag > 2) {
      console.info('groupByReduce', groupByReduce(pivotData.props.data.map((x) => ({
        'Transaction sign': x['Transaction sign'], label: x.label, otherParty: x.otherParty, amount: x.amount,
      })), (item) => [item['Transaction sign']]));
    }

    function generateLinks() {
      // returns links for sankey chart in the format:
      // [  { source: 'A', target: 'B', value: 1 }, ... ]

      const groupedData = [];
      for (let i = 1; i <= fields.length; i += 1) {
        groupedData.push(...groupByReduce(pivotData.props.data, (item) => fields.slice(0, i).map((field) => item[field])));
      }
      // this is a more verbatim way of doing this:
      // const groupedData = [
      //   // ...groupByReduce(pivotData.props.data, (item) => [item['Transaction sign'], item.label, item.otherParty]),
      //   // ...groupByReduce(pivotData.props.data, (item) => [item['Transaction sign'], item.label]),
      //   // ...groupByReduce(pivotData.props.data, (item) => [item['Transaction sign']]),
      // ];

      // DESIGN LIMITATION:
      // the general limitation of PivotTableUI is that each field can only be used once in the rows or columns (or ununsedColumns)
      // i.e. it is not possible to design something like: otherParty > label > transaction sign > label > otherParty to reflect this chart properly
      // it would be possible to design one-way flows, but at the moment it feels difficult to lay out a chart that would be useful
      // with that in mind, we assume for now that the fields must support a symmetrical structure with field[0] being the top level node
      // and having only two values

      // transform in the source/target format required by the sankey chart
      return groupedData.map((item) => {
        // fields is an array containing field names, in a specific order; fields[0] is the top level node; fields[1] is the second level node (on both sides of the top level node), etc.
        // if all fields[x] are undefined except for fields[0], then this is a top level node
        // example: ['Transaction sign', 'label', 'otherParty']

        const topLevelNodeValue0 = keysByName[fields[0]][0] || 'empty'; // <-- sankey does not like null values
        const topLevelNodeValue1 = keysByName[fields[0]][1] || 'empty';

        // handle top-level node, which goes in the middle of the chart
        if (fields.slice(1).every((field) => item[field] === undefined)) {
          // handle left and right side of the chart separately
          if (item[fields[0]] === topLevelNodeValue1) {
            // if the value of fields[0] is the second sorted element from top-level field, then return source: <first top-level element>, target: <second top-level element>
            return {
              source: topLevelNodeValue0,
              sourceLabel: topLevelNodeValue0, // e.g. 'positive'
              target: topLevelNodeValue1,
              targetLabel: topLevelNodeValue1, // e.g. 'negative'
              value: Math.abs(item.value),
            };
          }
          // we just need one top-level link (above), but this one has to be output somewhere, so here it is
          return {
            source: topLevelNodeValue0, // <-- sankey does not like null values
            sourceLabel: topLevelNodeValue0, // <-- sankey does not like null values
            target: topLevelNodeValue1,
            targetLabel: topLevelNodeValue1,
            value: 0,
          };
        }

        // handle first-level nodes which have links to and from the top-level node
        // as the same nodes will inevitably show up on both sides of the top-level node, we need to differentiate them by addind a prefix depending on the side
        // we are using the values of the top-level node for that
        // first-level nodes will be those which have all fields except for fields[0] and fields[1] undefined
        if (fields.slice(2).every((field) => item[field] === undefined)) {
          // handle left and right side of the chart separately
          if (item[fields[0]] === topLevelNodeValue0) {
            return {
              source: `${topLevelNodeValue0}__${item[fields[1]] || 'empty'}`,
              sourceLabel: item[fields[1]] || 'empty',
              target: topLevelNodeValue0,
              targetLabel: item[fields[0]] || 'empty',
              value: Math.abs(item.value),
            };
          }
          return {
            source: topLevelNodeValue1,
            sourceLabel: topLevelNodeValue1,
            target: `${topLevelNodeValue1}__${item[fields[1]] || 'empty'}`,
            targetLabel: item[fields[1]] || 'empty',
            value: Math.abs(item.value),
          };
        }
        // handle second-level nodes and below
        // if we got as far as here, it means that item[field0] and item[field1] are defined
        // so the question is how many other undefineds is there?
        const undefineds = fields.slice(3).filter((field) => item[field] === undefined).length;
        const itemHierarchyLevel = fields.length - undefineds;
        const parentHierarchyLevel = itemHierarchyLevel - 1;
        const itemNodeValue = item[fields[itemHierarchyLevel]] || 'empty';
        const parentNodeValue = item[fields[itemHierarchyLevel - 1]] || 'empty';
        // handle left and right side of the chart separately
        // to avoid node names clashing on levels 1 and below ('negative__null' was possible on all levels), keep the separator length __ depende on the level
        if (item[fields[0]] === topLevelNodeValue0) {
          return {
            source: `${topLevelNodeValue0}${''.padEnd(itemHierarchyLevel, '_')}${parentNodeValue}`, // H
            sourceLabel: parentNodeValue,
            target: `${topLevelNodeValue0}${''.padEnd(parentHierarchyLevel, '_')}${itemNodeValue}`, // L
            targetLabel: itemNodeValue,
            value: Math.abs(item.value),
          };
        }
        return {
          source: `${topLevelNodeValue1}${''.padEnd(parentHierarchyLevel, '_')}${itemNodeValue}`, // L
          sourceLabel: itemNodeValue,
          target: `${topLevelNodeValue1}${''.padEnd(itemHierarchyLevel, '_')}${parentNodeValue}`, // H
          targetLabel: parentNodeValue,
          value: Math.abs(item.value),
        };
        // }
        // return {}
      });
    }

    console.info('GENERATE LINKS: ', generateLinks());

    // generate hierarchical data structure
    const links = generateLinks();
    data = {
      links,
      nodes: Object.values(links
        .reduce((prev, curr) => {
          prev[curr.source] = { id: curr.source, label: curr.sourceLabel };
          prev[curr.target] = { id: curr.target, label: curr.targetLabel };
          return prev;
        }, [])),
    };

    console.info('NODES', data.nodes);

    const commonProps = {
      width: 900,
      height: 500,
      margin: {
        top: 10, right: 10, bottom: 10, left: 10,
      },
      theme: {
        fontFamily: 'Lato',
        tooltip: {
          container: {
            background: '#333',
          },
        },
      },
      padding: 0.2,
      colors: { scheme: 'category10' },
      tooltip: CustomTooltip,
      label: (node) => (node.label === 'empty' ? '∅' : node.label),
      data,
      ...pivotData.props.layoutOptions, // spread any properties which may have been passed to PivotTable
    };

    function handleFullscreen() {
      dispatch(setFullscreenReport(pivotData.props));
    }

    if (debugFlag > 2) console.log(data);

    return (
      <div className="relative">
        <div className="absolute z-10 flex gap-1 items-start">
          {(hideFullscreenButton) && (
            <button
              type="button"
              className="px-2 py-2 border rounded-md text-sm text-gray-500 hover:bg-gray-50"
              onClick={handleFullscreen}
            >
              <ArrowsPointingOutIcon className="h-5 w-5" />
            </button>
          )}
          <div className="text-sm text-brandRed-500 justify-self-end bg-white p-1">{chartErrorMessage}</div>
        </div>
        <div className="flex justify-center items-center">
          {(rowKeys.length > 0) && <NivoChart {...commonProps} />}
        </div>
      </div>
    );
  };
}

export default function createNivoRenderers() {
  return {
    'Sankey chart': makeNivoRenderer(Sankey),
  };
}
