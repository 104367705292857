import CryptoJS from 'crypto-js';
import JSZip from 'jszip';

// expects file as base64-encoded string and a passcode
// returns a js object with the unzipped data
export async function unzipFile(base64EncodedString, passcode) {
  let decrypted;
  try {
    // Decrypt the file using the passcode
    decrypted = CryptoJS.AES.decrypt(base64EncodedString, passcode).toString(CryptoJS.enc.Utf8);
  } catch (error) {
    console.error('Error decrypting file: ', error);
    throw error;
  }
  try {
    // Instantiate JSZip
    const zip = new JSZip();

    // Load decrypted data into JSZip
    const unzippedFiles = await zip.loadAsync(decrypted);

    // Process the unzipped files as needed
    // For example, to log the names of the files in the ZIP
    unzippedFiles.forEach((path, f) => {
      console.log(path, f);
    });

    // Assuming there's only one file in the zip and it's a JSON
    const fileNames = Object.keys(unzippedFiles.files);
    if (fileNames.length === 0) {
      throw new Error('No files found in the zip archive.');
    } else if (fileNames.length > 1) {
      throw new Error('More than one file found in the zip archive.');
    }

    // Extract the first file's content
    const fileContent = await unzippedFiles.file(fileNames[0]).async('string');

    // Parse the JSON content and return
    return JSON.parse(fileContent);
  } catch (error) {
    console.error('Error unzipping file: ', error);
    throw error;
  }
}

// expects a js object and a passcode
// returns a base64-encoded encrypted object
export async function zipFile(obj, passcode) {
  try {
    // Convert the object to a JSON string
    const jsonString = JSON.stringify(obj);
    console.log('jsonString length: ', jsonString.length);

    // Create a new JSZip instance
    const zip = new JSZip();

    // Add the JSON string to the zip
    // The file inside the zip will be named 'file.json'
    zip.file('file.json', jsonString);

    // Generate the zip file as a binary string
    const zipContent = await zip.generateAsync({
      type: 'binarystring',
      compression: 'DEFLATE',
      compressionOptions: {
        level: 9,
      },
    });
    console.log('Zip file size: ', zipContent.length);

    // Encrypt the zipped content using the password
    const encrypted = CryptoJS.AES.encrypt(zipContent, passcode);

    console.log('Encrypted length: ', encrypted.toString().length);

    return encrypted.toString(); // toString() is supposed to return a base64-encoded string
  } catch (error) {
    console.error('Error zipping and encrypting object: ', error);
    throw error;
  }
}
