/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { loanAccount, loanTransaction } from '@monestry-dev/schema';
import { nanoid } from 'nanoid';
import dayjs from 'dayjs';
import { Listbox, Transition } from '@headlessui/react';
import { InformationCircleIcon, CheckIcon, ChevronUpDownIcon } from '@heroicons/react/24/solid';
import { assetBalancesArray, loanTransactions, postData } from '../../redux/reducers/data';
import getSimulatedRepaymentSchedule from '../../redux/reducers/globalSelectors/loans/getSimulatedRepaymentSchedule';
import ToolTipOnlyIcon from '../../elements/ToolTipOnlyIcon';
import ToolTipNoIcon from '../../elements/ToolTipNoIcon';
import Button from '../../elements/Button';
import AccountLabel from '../../elements/AccountLabel';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

// item is a full Transaction object
// selected makes the font bold
function MiniTransaction({ item, selected }) {
  MiniTransaction.propTypes = {
    item: PropTypes.object.isRequired,
    selected: PropTypes.bool,
  };
  MiniTransaction.defaultProps = {
    selected: false,
  };

  const { t } = useTranslation('app', { keyPrefix: 'accountDetails.splitMenu' });

  if (!item) return t('selectLoanTransactionPrompt');

  return (
    <div className={`w-4/5 grid grid-cols-2 gap-1 text-xs ${selected ? 'font-semibold' : 'font-normal'}`}>
      <span className="block truncate">{dayjs.utc(item?.date).format('DD.MM.YYYY')}</span>
      <span className="block truncate">{item?.quantity.toLocaleString('de', { style: 'currency', currency: item?.accountCurrency })}</span>
      <span className="block truncate">{item?.description}</span>
      <AccountLabel accountName={item?.accountName} category="loans" />
    </div>
  );
}

/**
 *
 * @param {object} value - Transaction object
 * @param {array} list - List of Transaction objects
 * @param {function} onChange - Function to handle change (receives the new value, i.e. a Transaction object)
 *
 * @returns React component
 */
function LocalDropdown({ value, list, onChange, disableCreateLoanTransaction }) {
  LocalDropdown.propTypes = {
    value: PropTypes.object,
    list: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    disableCreateLoanTransaction: PropTypes.bool,
  };
  LocalDropdown.defaultProps = {
    value: null,
    disableCreateLoanTransaction: false,
  };

  const { t } = useTranslation('app', { keyPrefix: 'accountDetails.splitMenu' });

  const disabled = list.length === 0 || disableCreateLoanTransaction;

  // value is a string (id), so that Listbox knows how to use active and selected render props
  // when triggering onChange, it will pass the whole object back to the parent
  return (
    <Listbox as="div" value={value?.id} onChange={(newId) => onChange(list.find((x) => x.id === newId))} disabled={disabled} className="col-span-2">
      {({ open }) => (
        <>
          <div className="flex justify-between">
            <Listbox.Label className="flex text-xs sm:text-sm font-medium text-gray-500">
              {t('selectLoanTransaction')}
              <ToolTipNoIcon info={t('selectLoanTransactionInfo')} classNameOwn="ml-2">
                <ToolTipOnlyIcon />
              </ToolTipNoIcon>
            </Listbox.Label>
          </div>
          <div className="relative mt-2">
            <Listbox.Button
              className={`relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm min-h-[2.5rem]
          focus:border-brandBlue-500 focus:outline-none focus:ring-1 focus:ring-brandBlue-600 text-sm`}
              id="dropdown"
            >
              <span className="block truncate">{list.length === 0 ? 'No matching transactions found' : <MiniTransaction item={value} />}</span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </span>
            </Listbox.Button>

            <Transition show={open} as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
              {/* eslint-disable-next-line max-len */}
              <Listbox.Options
                className="absolute z-20 mt-1 w-full max-h-28 overflow-auto rounded-md bg-white py-1 text-sm shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
              >
                {(list || []).map((item) => (
                  <Listbox.Option
                    key={item?.id}
                    id={`dropdown-option-${item?.id}`}
                    className={({ active }) => classNames(active ? 'text-white bg-brandBlue-500' : 'text-gray-900', 'relative cursor-default select-none py-2 px-3 flex gap-2 items-baseline')}
                    value={item?.id}
                  >
                    {({ selected, active }) => (
                      <>

                        <MiniTransaction item={item} />

                        {selected ? (
                          <span className={classNames(active ? 'text-white' : 'text-brandBlue-500', 'absolute inset-y-0 right-0 flex items-center pr-4')}>
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}

/**
 * This component will only be displayed when there is a loan account connected to this deposit account. It will allow the user to split
 * a loan installment transaction into interest and principal, label them accordingly and (optionally) create a matching transaction on the loan account.
 *
 * This component will use splitTransactions if they are passed as props, otherwise it will use transaction. The reinitialisation logic is quite complex, as all states need to be pre-populated.
 *
 * @returns React component
 */
export default function SplitLabelLoan({ category, transaction, splitTransactions = [], signalHandleSubmit, loanAccountsConnectedToThisOne }) {
  SplitLabelLoan.propTypes = {
    category: PropTypes.string.isRequired,
    transaction: PropTypes.object,
    splitTransactions: PropTypes.arrayOf(PropTypes.object),
    signalHandleSubmit: PropTypes.bool.isRequired,
    loanAccountsConnectedToThisOne: PropTypes.array,
  };
  SplitLabelLoan.defaultProps = {
    transaction: null,
    splitTransactions: [],
    loanAccountsConnectedToThisOne: [],
  };

  const isEditExistingSplitMode = splitTransactions.length > 0;
  // we have to pass transaction to all the TransactionContext subcomponents, because only the two Split... components use splitTransactions
  // some of the code below relies of transaction being undefined when splitTransactions are passed, hence we handle that here
  const localTransaction = isEditExistingSplitMode ? undefined : transaction;

  function sumByField(field) {
    return splitTransactions.reduce((acc, curr) => acc + curr[field], 0);
  }

  const { t } = useTranslation('app', { keyPrefix: 'accountDetails.splitMenu' });
  const { t: tLabel } = useTranslation('app', { keyPrefix: 'accountDetails.deposits.labels' });

  const amountToSplit = isEditExistingSplitMode ? sumByField('accountCurrencyAmount') : localTransaction?.accountCurrencyAmount || localTransaction?.transactionAmount;

  const [focused, setFocused] = useState(null);

  // STATE + INITIALISATION FOR SPLITTRANSACTIONS

  // initialise with two rows, one for interest and one for principal; same sequence when there are splitTransactions
  const [splitArray, setSplitArray] = useState(isEditExistingSplitMode
    ? [
      splitTransactions.find((item) => item.label === 'investment-loanPrincipal'),
      splitTransactions.find((item) => item.label === 'investment-loanInterest'),
    ]
    : [
      { id: nanoid(), label: 'investment-loanPrincipal', quantity: amountToSplit, description: localTransaction.description || localTransaction.displayName },
      { id: nanoid(), label: 'investment-loanInterest', quantity: 0, description: localTransaction.description || localTransaction.displayName },
    ]);

  const selectAssetBalances = useSelector(assetBalancesArray);
  const selectLoanTransactions = useSelector(loanTransactions);

  // when we initialise from splitTransactions, we have a linkedTransaction to loans, which id

  let loanTransactionLinkedToPrincipalId;
  let loanTransactionLinkedToPrincipal;
  let loanTransactionLinkedToPrincipalAccountId;
  if (isEditExistingSplitMode) {
    loanTransactionLinkedToPrincipalId = splitTransactions.find((item) => item.label === 'investment-loanPrincipal').linkedTransactions.find((lt) => lt.tags?.linkType === 'sale-to-transaction')?.id;
    loanTransactionLinkedToPrincipal = selectLoanTransactions.find((lt) => lt.id === loanTransactionLinkedToPrincipalId);
    loanTransactionLinkedToPrincipalAccountId = loanTransactionLinkedToPrincipal?.accountId;
  }

  const [currentInstallment, setCurrentInstallment] = useState(undefined); // stores the current month's installment interest and principal when calculated by getCurrentInstallment

  // ↓ if the incoming principal transaction has a linkedTransaction, we will load its account into the state
  const [selectedLoanAccountIndex, setSelectedLoanAccountIndex] = useState(
    (loanTransactionLinkedToPrincipalId)
      ? loanAccountsConnectedToThisOne.findIndex((item) => item.id === loanTransactionLinkedToPrincipalAccountId)
      : 0,
  ); // stores the currently selected "target" loan account for these transctions

  // when intialising

  const disableCreateLoanTransaction = isEditExistingSplitMode && loanTransactionLinkedToPrincipalId; // if there already is a linked transaction disable the checkbox

  // ↓ controls the checkbox displayed when the deposit transaction is split for the first time;
  // if true, the user wants to create a matching transaction on the loan account
  // default to true if there is no linked transaction, false and disabled if there is
  const [createLoanTransaction, setCreateLoanTransaction] = useState(!disableCreateLoanTransaction);

  // ↓ controls the checkbox displayed when we run with splitTransactions; it will make the system resubmit the loan transaction as well
  const [modifyLoanTransaction, setModifyLoanTransaction] = useState(splitTransactions.length > 0);

  const [matchingLoanTransaction, setMatchingLoanTransaction] = useState(
    // we need to add account name to the transaction (for display in the LocalDropdown as value) - it also gets added to the list in dropboxList below
    { ...loanTransactionLinkedToPrincipal, accountName: loanAccountsConnectedToThisOne[selectedLoanAccountIndex]?.name } || null,
  ); // stores the currently selected "target" loan;
  const [createLoanTransactionError, setCreateLoanTransactionError] = useState(false); // controls the error message for the checkbox

  const dispatch = useDispatch();

  // HANDLE SAVE FORM

  const remainingAmount = amountToSplit - splitArray.reduce((a, b) => a + b.quantity, 0);
  // subscribe to the signalHandleSubmit and submit the form if it is true
  useEffect(() => {
    if (signalHandleSubmit && remainingAmount === 0) {
      setCreateLoanTransactionError(false); // reset error message

      if (!createLoanTransaction && !matchingLoanTransaction) {
        setCreateLoanTransactionError(true);
        return;
      }

      // two new transactions + one transaction to be deleted (importFlag === 'delete')
      const depositTransactions = splitArray
        .map((item) => {
          const newItem = {
            // take the original transaction parameters, overwrite id, accountCurrencyAmount and description from item, recalculate amount, add import flag
            // ↓ this is mostly to provide a template with all required fields; if there is no localTransaction, spread first splitTransaction
            // ↓ ("item"s will already have all incoming splitTransactions)
            ...(localTransaction || splitTransactions[0]),
            ...item,
            parentId: localTransaction?.id || splitTransactions[0]?.parentId, // if we are in splitTransaction mode, they will all have the same parentId
            accountCurrencyAmount: item.quantity,
            amount: ((localTransaction?.amount || sumByField('amount')) * item.quantity) / amountToSplit,
            label: item.label,
            importFlag: 'post',
          };

          // if this is the interest transaction, also update the tags
          if (newItem.label === 'investment-loanInterest') {
            newItem.tags = {
              ...(localTransaction || item).tags, // if there is no localTransaction, then the tags are already in the item, because they came in via splitTransactions
              accountId: loanAccountsConnectedToThisOne[selectedLoanAccountIndex].id,
              assetId: loanAccountsConnectedToThisOne[selectedLoanAccountIndex].id,
            };
          } else { // investment-loanPrincipal
            newItem.tags = {
              ...(localTransaction || item).tags, // if there is no localTransaction, then the tags are already in the item, because they came in via splitTransactions
            };
          }

          // we will add linkedTransaction to investment-loanPrincipal later on (once we know the id of the loan transaction)

          return newItem;
        });

      if (!isEditExistingSplitMode) depositTransactions.push({ ...localTransaction, importFlag: 'delete' });

      // when splitting a new transaction, depending on user's preference, either create a new loan transaction or use the existing transaction found by the user
      // when editing a split (splitTransaction mode) check the value of modifyLoanTransaction and if that is true, update and post the existing loan transaction
      let matchingLoanTransactionId = null;
      if (createLoanTransaction) {
        const principalTransactionId = depositTransactions.find((item) => item.label === 'investment-loanPrincipal').id;
        matchingLoanTransactionId = nanoid();
        try {
          const newTransaction = loanTransaction.cast({
            id: matchingLoanTransactionId,
            accountId: loanAccountsConnectedToThisOne[selectedLoanAccountIndex].id,
            date: localTransaction?.date,
            quantity: (-1) * splitArray.find((tx) => tx.label === 'investment-loanPrincipal').quantity, // in deposits these two will be negative for taken accounts and v.v.
            quantityInterest: (-1) * splitArray.find((tx) => tx.label === 'investment-loanPrincipal').quantity,
            description: localTransaction?.description,
            importFlag: 'post',
            upac: 1,
            accountCurrency: loanAccountsConnectedToThisOne[selectedLoanAccountIndex].currency,
            assetCurrency: loanAccountsConnectedToThisOne[selectedLoanAccountIndex].currency,
            projectId: localTransaction?.projectId,
            // add linked transaction to this one
            linkedTransactions: [{
              id: principalTransactionId,
              category: 'deposits',
              tags: {
                linkType: 'sale-to-transaction',
              },
            }],
          });
          dispatch(
            postData({
              data: [newTransaction],
              category: 'loans',
              accountId: loanAccountsConnectedToThisOne[selectedLoanAccountIndex].id,
            }),
          );
        } catch (e) {
          console.error('Error creating loan transaction', e);
        }
      } else if (matchingLoanTransaction) {
        // if there is an existing loan transaction, update its linkedTransaction
        matchingLoanTransactionId = matchingLoanTransaction.id;

        dispatch(
          postData({
            data: [{
              ...matchingLoanTransaction,
              // if modifyLoanTransaction is true, also update the amounts
              ...(modifyLoanTransaction) && {
                quantity: (-1) * splitArray.find((tx) => tx.label === 'investment-loanPrincipal').quantity,
                quantityInterest: (-1) * splitArray.find((tx) => tx.label === 'investment-loanPrincipal').quantity,
              },
              linkedTransactions: matchingLoanTransaction.linkedTransactions.filter((lt) => lt.tags?.linkType !== 'sale-to-transaction').concat({
                id: localTransaction?.id || matchingLoanTransaction.linkedTransactions.find((lt) => lt.tags?.linkType === 'sale-to-transaction').id,
                category: 'deposits',
                tags: {
                  linkType: 'sale-to-transaction',
                },
              }),
            },
            ],
            category: 'loans',
            accountId: loanAccountsConnectedToThisOne[selectedLoanAccountIndex].id,
          }),
        );
      }

      // update the principal transaction with the matching loan transaction found above
      depositTransactions.find((item) => item.label === 'investment-loanPrincipal').linkedTransactions = [{
        id: matchingLoanTransactionId,
        category: 'loans',
        tags: {
          linkType: 'sale-to-transaction',
        },
      }];

      // post new split deposit transactions
      dispatch(
        postData({
          data: depositTransactions,
          category,
          accountId: (localTransaction || splitTransactions[0]).accountId,
        }),
      );

      // close this dialog
      window.dispatchEvent(
        new CustomEvent('setDialog', {}),
      );
    }
  }, [signalHandleSubmit]);

  // updates the form row 'index' and field 'field' represented by item (for convenience) with the new value (e.target.value)
  function handleChangeSplit(e, item, index, field) {
    // check if the account is a number, otherwise display an error
    const arrayCopy = [...splitArray]; // remove the "old" transaction and replace it with t
    arrayCopy.splice(index, 1, {
      ...item,
      [field]: field === 'quantity' && !Number.isNaN(Number(e.target.value)) ? Number(e.target.value) : e.target.value,
    });
    setSplitArray(arrayCopy);
  }

  function handleFocus(e, index = null, field = null) {
    // set focus state so that the input field may change the number format to a simpler one
    setFocused({ index, field });
    setTimeout(() => e.target.select(), 0);
  }

  // FIND LOAN TRANSACTIONS THAT MAY MATCH THIS ONE

  // get the loan current balance from the loan account
  const loanAccountBalanceObject = selectAssetBalances.find((item) => item.accountId === loanAccountsConnectedToThisOne[selectedLoanAccountIndex]?.id) || { current: { quantity: 0 } };
  // sets { interest: number, principal: number } into currentInstallment state
  function getCurrentInstallment() {
    const calculatedObject = getSimulatedRepaymentSchedule(
      dayjs.utc().startOf('month').valueOf(),
      loanAccountBalanceObject.current.quantity,
      loanAccountsConnectedToThisOne[selectedLoanAccountIndex],
      [], // assume future repayments not relevant, as we are dealing with current month only
      // when the user clicks on that, it should retrieve current / previous / next month split and suggest to user (pre-fill current month in the form)
    );
    // expect calculatedObject to have schedule array where installmentDate is a start of month date
    const requestedMonth = calculatedObject.schedule.find((item) => dayjs(item.date).isSame(dayjs.utc().startOf('month'), 'month'));
    setCurrentInstallment({ interest: requestedMonth?.currentInterest, principal: requestedMonth?.currentPrincipal });
  }

  // prepare a list for possible linked transactions on the connected loan account
  const linkedAccountTransactions = selectAssetBalances.find((item) => item.accountId === loanAccountsConnectedToThisOne[selectedLoanAccountIndex]?.id)?.transactions || [];
  const dropdownList = linkedAccountTransactions
    .filter((loanTxn) => Math.abs(loanTxn.quantity + loanTxn.quantityInterest) > Math.abs(amountToSplit) * 0.9 // only show transactions with a quantity beetween 90% and 110% of this one
      && Math.abs(loanTxn.quantity + loanTxn.quantityInterest) < Math.abs(amountToSplit) * 1.1 //                 and the opposite sign
      && dayjs.utc(loanTxn.date).isAfter(dayjs.utc((localTransaction || splitTransactions[0]).date).subtract(7, 'day')) // only show transactions on connected account
      && dayjs.utc(loanTxn.date).isBefore(dayjs.utc((localTransaction || splitTransactions[0]).date).day(7, 'day'))) //    within +/- 7 days of this one (for clarity)
    .map((txn) => ({
      ...txn,
      accountName: loanAccountsConnectedToThisOne[selectedLoanAccountIndex].name, // displayed to user in LocalDropdown (select matching transaction manually)
    }));

  return (
    <div className="mt-2">
      <ToolTipNoIcon info={t('splitAndLabelLoanInfo2')} classNamesOwn="flex gap-2">
        <div className="flex gap-2 items-center">
          <p className="text-sm text-gray-500">{t('splitAndLabelLoanInfo')}</p>
          <ToolTipOnlyIcon />
        </div>
      </ToolTipNoIcon>

      <ul className="mt-4 divide divide-gray-100">
        {splitArray.map((item, index) => (
          <li className="grid grid-cols-12 p-2 gap-4 text-sm" key={item.id} id={`row-${index}`}>
            <span className="col-span-2 self-center inline-flex rounded-lg xl:rounded-full bg-brandBlue-100 hover:bg-brandBlue-200 px-2.5 py-0.5 text-xs font-medium text-brandBlue-700">
              {tLabel(`${item.label}.short`)}
            </span>
            <div className="col-span-6 flex items-center">
              <input
                className="px-1.5 py-2 flex-1 text-gray-700 text-left truncate border-b rounded-md border-gray-300 focus:outline-none focus:border-brandBlue-400"
                value={item.description}
                onChange={(e) => handleChangeSplit(e, item, index, 'description')}
                onFocus={handleFocus}
                onBlur={(e) => {
                  setFocused(null);
                }}
              />
            </div>

            <input
              className="px-1.5 py-2 text-gray-700 text-right border-b rounded-md border-gray-300 focus:outline-none focus:border-brandBlue-400 col-span-3"
              value={`${item.quantity}`}
              onChange={(e) => handleChangeSplit(e, item, index, 'quantity')}
              onFocus={(e) => handleFocus(e, index, 'quantity')}
              onBlur={(e) => {
                setFocused(null);
              }}
            />
          </li>
        ))}
      </ul>
      {/* i.e. find all transactions with the same parentId and put them into the state of Split */}
      {remainingAmount === 0 && <div />}
      {remainingAmount > 0 && <p className="mt-4 text-sm text-red-500">{`${t('amountToAllocate')} ${-remainingAmount.toFixed(2)}`}</p>}
      {remainingAmount < 0 && <p className="mt-4 text-sm text-red-500">{`${t('allocatedTooMuch')} ${Math.abs(remainingAmount).toFixed(2)}`}</p>}

      <div className="mt-2 grid grid-cols-2 items-center">

        {/* if there is more than one loan account connected to this one, the user must choose the correct one */}
        {loanAccountsConnectedToThisOne.length > 1 ? (
          <div className="flex space-x-2 items-center">
            <label htmlFor="loanAccount" className="text-sm text-gray-500">{t('selectLoanAccount')}</label>
            <Button
              size="xl"
              id="loanAccount"
              name="loanAccount"
              enabled={loanAccountsConnectedToThisOne.length > 1 && selectedLoanAccountIndex} // only activate the button if there are connected loan accounts and one of them is selected
              text={loanAccountsConnectedToThisOne[selectedLoanAccountIndex].name}
              onClick={() => {
                if (selectedLoanAccountIndex + 1 < loanAccountsConnectedToThisOne.length) {
                  setSelectedLoanAccountIndex(selectedLoanAccountIndex + 1);
                } else {
                  setSelectedLoanAccountIndex(0);
                }
              }}
            />
          </div>
        ) : <div />}

        {!currentInstallment
          ? (
            <ToolTipNoIcon
              info={t('calculateExpectedSplitInfo')}
            >
              <Button
                onClick={() => getCurrentInstallment()}
                formatting="text-brandBlue-500"
                text="Calculate expected split?"
                size="sm"
                noFrame
              />
            </ToolTipNoIcon>
          )
          : (
            <button
              className="text-sm text-gray-500 cursor-pointer underline"
              type="button"
              onClick={(e) => {
                setSplitArray(
                  [
                    { id: nanoid(), label: 'investment-loanInterest', quantity: currentInstallment.interest, description: 'Interest' },
                    { id: nanoid(), label: 'investment-loanPrincipal', quantity: currentInstallment.principal, description: 'Principal' },
                  ],
                );
              }}
            >
              {`Principal: ${currentInstallment.principal.toFixed(2)}  |  Interest: ${currentInstallment.interest.toFixed(2)}`}
            </button>
          )}
      </div>

      {(isEditExistingSplitMode)
        ? ( // if this is edit mode and there is a linked transaction, show the checkbox asking the user if they want to modify it
          <div className={`mt-8 items-center text-gray-500 grid ${!createLoanTransaction ? 'grid-cols-3' : 'grid-cols-1'}`}>
            <div className="flex items-center">
              <input
                className="text-gray-400"
                id="checkbox-modifyLoanTransaction"
                type="checkbox"
                checked={modifyLoanTransaction}
                onChange={(e) => setModifyLoanTransaction(!modifyLoanTransaction)}
              />
              <label htmlFor="checkbox" className="flex text-sm font-medium ml-2">
                {t('modifyLoanTransaction')}
              </label>
            </div>
            {!createLoanTransaction && (
            // LocalDropdown has col-span-2
            <LocalDropdown
              value={matchingLoanTransaction}
              onChange={setMatchingLoanTransaction}
              list={dropdownList}
              disableCreateLoanTransaction={disableCreateLoanTransaction}
            />
            )}
            {createLoanTransactionError && <p className="mt-2 text-red-500 text-sm col-span-3">{t('createLoanTransactionError')}</p>}
          </div>
        )
        : (
          <div className={`mt-8 items-center text-gray-500 grid ${!createLoanTransaction ? 'grid-cols-3' : 'grid-cols-1'}`}>
            <div className="flex items-center">
              <input
                className="text-gray-400"
                id="checkbox"
                type="checkbox"
                checked={createLoanTransaction}
                onChange={(e) => setCreateLoanTransaction(!createLoanTransaction)}
                disabled={disableCreateLoanTransaction}
              />
              <label htmlFor="checkbox" className="flex text-sm font-medium ml-2">
                {t('createLoanTransaction')}
              </label>
            </div>
            {/* if the loan transaction should NOT be created, select the transaction from list of existing transactions in the similar time period on the loan account */}
            {!createLoanTransaction && (
            // LocalDropdown has col-span-2
            <LocalDropdown
              value={matchingLoanTransaction}
              onChange={setMatchingLoanTransaction}
              list={dropdownList}
              disableCreateLoanTransaction={disableCreateLoanTransaction}
            />
            )}
            {createLoanTransactionError && <p className="mt-2 text-red-500 text-sm col-span-3">{t('createLoanTransactionError')}</p>}
          </div>
        )}
      {(isEditExistingSplitMode) && (
        <div className="flex items-center mt-4">
          <InformationCircleIcon className="h-8 w-8 text-brandBlue-500" aria-hidden="true" />
          <p className="ml-2 text-sm text-gray-500">{t('splitExistingDetected')}</p>
        </div>
      )}
    </div>
  );
}
