/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { postData } from '../../redux/reducers/data';

export default function LinkToProject({ transaction, signalHandleSubmit, setSignalHandleSubmit }) {
  LinkToProject.propTypes = {
    transaction: PropTypes.object.isRequired,
    signalHandleSubmit: PropTypes.bool.isRequired,
    setSignalHandleSubmit: PropTypes.func.isRequired,
  };
  const { t } = useTranslation('app', { keyPrefix: 'accountDetails.splitMenu' });

  const projects = useSelector((state) => state.projects);
  const [inProjectId, setInProjectId] = useState(projects.find((p) => p.id === transaction.projectId));

  const dispatch = useDispatch();

  useEffect(() => {
    if (signalHandleSubmit) {
      dispatch(postData({
        data: [{ ...transaction, projectId: inProjectId.id || null, importFlag: 'put' }],
        category: transaction.category,
        accountId: transaction.accountId,
      }));
      window.dispatchEvent(
        new CustomEvent('setDialog', {}),
      );
    }
  }, [signalHandleSubmit]);

  return (
    <div className="mt-2">
      <p className="text-sm text-gray-500">{t('showInProjectInfo')}</p>
      <div className="mt-4">
        <label htmlFor="projectId" className="flex text-sm font-medium text-gray-700">
          {t('pick')}
        </label>
        <select
          id="projectId"
          name="projectId"
          value={inProjectId?.id}
          onChange={(e) => setInProjectId(projects.find((p) => p.id === e.target.value))}
          className="mt-2 shadow-sm focus:ring-brandBlue-400 focus:border-brandBlue-400 block w-full sm:text-sm border-gray-300 rounded-md"
        >
          {[{ id: null, name: '' }].concat(projects).map((p) => (
            <option value={p?.id || null}>{p?.name || ''}</option>
          ))}
        </select>
      </div>
    </div>
  );
}
