/* eslint-disable no-inline-comments */
/* eslint-disable no-magic-numbers */
import React from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import dayjs from 'dayjs';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
dayjs.extend(quarterOfYear);
import { PivotData, sortAs, getSort } from './Utilities';
import PivotTable from './PivotTable';
import Sortable from 'react-sortablejs';
import Draggable from 'react-draggable';
import { Disclosure } from '@headlessui/react';

/* eslint-disable react/prop-types */
// eslint can't see inherited propTypes!

export class DraggableAttribute extends React.Component {
  constructor(props) {
    super(props);
    this.state = { open: false, filterText: '' };
  }

  toggleValue(value) {
    if (value in this.props.valueFilter) {
      this.props.removeValuesFromFilter(this.props.name, [value]);
    } else {
      this.props.addValuesToFilter(this.props.name, [value]);
    }
  }

  matchesFilter(x) {
    return x
      .toLowerCase()
      .trim()
      .includes(this.state.filterText.toLowerCase().trim());
  }

  selectOnly(e, value) {
    e.stopPropagation();
    this.props.setValuesInFilter(
      this.props.name,
      Object.keys(this.props.attrValues).filter(y => y !== value)
    );
  }

  getFilterBox() {
    const showMenu =
      Object.keys(this.props.attrValues).length < this.props.menuLimit;

    const values = Object.keys(this.props.attrValues);
    const shown = values
      .filter(this.matchesFilter.bind(this))
      .sort(this.props.sorter);

    function ChevronDown(props) {
      return (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4" {...props} >
          <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
        </svg>
      )
    }

    function CheckIcon(props) {
      return (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4" {...props}>
          <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
        </svg>
      )
    }

    function applyFilterClause(dropdownOptionId) {
      switch (dropdownOptionId) {
        case 'currentMonth':
          return { from: dayjs().startOf('month').valueOf(), to: dayjs().valueOf(), id: 'currentMonth' };
        case 'currentQuarter':
          return { from: dayjs().startOf('quarter').valueOf(), to: dayjs().valueOf(), id: 'currentQuarter' };
        case 'previousMonth':
          return { from: dayjs().subtract(1, 'month').startOf('month').valueOf(), to: dayjs().subtract(1, 'month').endOf('month').valueOf(), id: 'previousMonth' };
        case 'previousQuarter':
          return { from: dayjs().subtract(1, 'quarter').startOf('quarter').valueOf(), to: dayjs().subtract(1, 'quarter').endOf('quarter').valueOf(), id: 'previousQuarter' };
        case 'currentYTD':
          return { from: dayjs().startOf('year').valueOf(), to: dayjs().valueOf(), id: 'currentYTD' };
        case 'previousYTD':
          return { from: dayjs().subtract(1, 'year').startOf('year').valueOf(), to: dayjs().subtract(1, 'year').valueOf(), id: 'previousYTD' };
        case 'previousYear':
          return { from: dayjs().subtract(1, 'year').startOf('year').valueOf(), to: dayjs().subtract(1, 'year').endOf('year').valueOf(), id: 'previousYear' };
        case 'last3Months':
          return { from: dayjs().subtract(3, 'months').startOf('month').valueOf(), to: dayjs().subtract(1, 'month').endOf('month').valueOf(), id: 'last3Months' };
        case 'last6Months':
          return { from: dayjs().subtract(6, 'months').startOf('month').valueOf(), to: dayjs().subtract(1, 'month').endOf('month').valueOf(), id: 'last6Months' };
        case 'last12Months':
          return { from: dayjs().subtract(12, 'months').startOf('month').valueOf(), to: dayjs().subtract(1, 'month').endOf('month').valueOf(), id: 'last12Months' };
        // handles noFilter as well
        default:
          return { from: null, to: null, id: 'noFilter' };
      }
    }

    return (
      <Draggable handle=".pvtDragHandle">
        <div
          className="absolute mt-1 w-80 border border-gray-300 rounded-sm shadow-lg bg-white text-center min-h-[100px]"
          style={{
            cursor: 'initial',
            zIndex: this.props.zIndex,
          }}
          onClick={() => this.props.moveFilterBoxToTop(this.props.name)}
        >
          <div className="grid grid-cols-3 items-center mb-2">
            <span className="pl-1 pt-1 text-base text-gray-900 justify-self-start">☰</span>
            <h4 className="text-sm font-bold text-gray-900 justify-self-stretch text-center">{this.props.name}</h4>
            <button type="button" onClick={() => this.setState({ open: false })} className="pr-1 pt-1 text-base text-gray-900 justify-self-end">
              ×
            </button>
          </div>

          {showMenu || <p>(too many values to show)</p>}

          {(this.props.name.toLowerCase().indexOf('date') > -1) && <Disclosure defaultOpen={true} >
            {({ open }) => (
              <React.Fragment>
                {this.props.name.toLowerCase().indexOf('date') > -1}
                <Disclosure.Button className="mx-auto flex justify-center items-center space-x-2 py-1 my-2 w-11/12 uppercase text-xs text-center font-semibold tracking-wider border border-transparent rounded-md bg-gray-100">
                  Filter by period
                  <ChevronDown className={`h-4 w-4 ${open ? 'rotate-180 transform' : ''}`} />
                </Disclosure.Button>
                <Disclosure.Panel>
                  <select
                    id="timeLimit"
                    name="timeLimit"
                    // eslint-disable-next-line no-console
                    // onChange={(e) => { console.info('onChange fired', e.target.value) }}
                    // eslint-disable-next-line no-magic-numbers
                    onChange={(e) => { this.props.setFilterClause(applyFilterClause(e.target.value)) }}
                    className="mt-1 block w-9/12 mx-auto rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    defaultValue={this.props.dateFilter.id || 'nofilter'}
                  >
                    <option value="noFilter">all dates</option>
                    <option value="currentMonth">current month</option>
                    <option value="currentQuarter">current quarter</option>
                    <option value="previousMonth">previous month</option>
                    <option value="previousQuarter">previous quarter</option>
                    <option value="currentYTD">current year to date</option>
                    <option value="previousYTD">previous year to date</option>
                    <option value="previousYear">previous year</option>
                    <option value="last3Months">last 3 months</option>
                    <option value="last6Months">last 6 months</option>
                    <option value="last12Months">last 12 months</option>
                  </select>
                </Disclosure.Panel>
              </React.Fragment>
            )}
          </Disclosure>}
          <Disclosure>
            {({ open }) => (
              <React.Fragment>
                <Disclosure.Button className="mx-auto flex justify-center items-center space-x-2 py-1 my-2 w-11/12 uppercase text-xs text-center font-semibold tracking-wider border border-transparent rounded-md bg-gray-100">
                  Filter by values
                  <ChevronDown className={`h-4 w-4 ${open ? 'rotate-180 transform' : ''}`} />
                </Disclosure.Button>
                <Disclosure.Panel className="grid grid-cols-1 justify-items-center mb-1 w-11/12 mx-auto">
                  <input
                    type="text"
                    placeholder="Filter values"
                    className="block w-9/12 mb-1 text-xs border border-gray-300 rounded-sm shadow-sm focus:ring-brandBlue-500 focus:border-brandBlue-500"
                    value={this.state.filterText}
                    onChange={e =>
                      this.setState({
                        filterText: e.target.value,
                      })
                    }
                  />
                  <div className="my-1 flex justify-around space-x-2">
                    <button
                      role="button"
                      className="text-xs text-gray-700 rounded-sm border border-gray-300 py-1 px-2"
                      onClick={() =>
                        this.props.removeValuesFromFilter(
                          this.props.name,
                          Object.keys(this.props.attrValues).filter(
                            this.matchesFilter.bind(this)
                          )
                        )
                      }
                    >
                      Select {values.length === shown.length ? 'All' : shown.length}
                    </button>
                    <button
                      role="button"
                      className="text-xs text-gray-700 rounded-sm border border-gray-300 py-1 px-2"
                      onClick={() =>
                        this.props.addValuesToFilter(
                          this.props.name,
                          Object.keys(this.props.attrValues).filter(
                            this.matchesFilter.bind(this)
                          )
                        )
                      }
                    >
                      Deselect {values.length === shown.length ? 'All' : shown.length}
                    </button>
                  </div>
                  {showMenu && (
                    <div className="w-11/12 flex flex-col items-stretch justify-center">
                      {shown.map(x => (
                        <div
                          role="button"
                          key={x}
                          onClick={() => this.toggleValue(x)}
                          className={`py-1 grid grid-cols-12 hover:bg-brandBlue-600 text-xs hover:text-white ${x in this.props.valueFilter ? '' : 'selected'}`}
                        >
                          <div className="justify-self-center">
                            {x in this.props.valueFilter ? null : <CheckIcon />}
                          </div>
                          <div
                            className="col-span-11 justify-self-start truncate"
                          >
                            {x === '' ? <em>null</em> : x}
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </Disclosure.Panel>
              </React.Fragment>
            )}
          </Disclosure>
        </div>
      </Draggable>
    );
  }

  toggleFilterBox() {
    this.setState({ open: !this.state.open });
    this.props.moveFilterBoxToTop(this.props.name);
  }

  render() {
    const filtered =
      Object.keys(this.props.valueFilter).length !== 0
        ? 'pvtFilteredAttribute'
        : '';
    return (
      <li data-id={this.props.name}>
        <span className={'pvtAttr ' + filtered}>
          {this.props.name}
          <span
            className="pvtTriangle"
            onClick={this.toggleFilterBox.bind(this)}
          >
            {' '}
            ▾
          </span>
        </span>

        {this.state.open ? this.getFilterBox() : null}
      </li>
    );
  }
}

DraggableAttribute.defaultProps = {
  valueFilter: {},
};

DraggableAttribute.propTypes = {
  name: PropTypes.string.isRequired,
  addValuesToFilter: PropTypes.func.isRequired,
  removeValuesFromFilter: PropTypes.func.isRequired,
  attrValues: PropTypes.objectOf(PropTypes.number).isRequired,
  valueFilter: PropTypes.objectOf(PropTypes.bool),
  moveFilterBoxToTop: PropTypes.func.isRequired,
  sorter: PropTypes.func.isRequired,
  menuLimit: PropTypes.number,
  zIndex: PropTypes.number,
};

export class Dropdown extends React.PureComponent {
  render() {
    return (
      <div className="pvtDropdown" style={{ zIndex: this.props.zIndex }}>
        <div
          onClick={e => {
            e.stopPropagation();
            this.props.toggle();
          }}
          className={
            'pvtDropdownValue pvtDropdownCurrent ' +
            (this.props.open ? 'pvtDropdownCurrentOpen' : '')
          }
          role="button"
        >
          <div className="pvtDropdownIcon">{this.props.open ? '×' : '▾'}</div>
          {this.props.current || <span>&nbsp;</span>}
        </div>

        {this.props.open && (
          <div className="pvtDropdownMenu">
            {this.props.values.map(r => (
              <div
                key={r}
                role="button"
                onClick={e => {
                  e.stopPropagation();
                  if (this.props.current === r) {
                    this.props.toggle();
                  } else {
                    this.props.setValue(r);
                  }
                }}
                className={
                  'pvtDropdownValue ' +
                  (r === this.props.current ? 'pvtDropdownActiveValue' : '')
                }
              >
                {r}
              </div>
            ))}
          </div>
        )}
      </div>
    );
  }
}

class PivotTableUI extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      unusedOrder: [],
      zIndices: {},
      maxZIndex: 1000,
      openDropdown: false,
      attrValues: {},
      materializedInput: [],
      displayWarning: null,
    };
  }

  componentDidMount() {
    this.materializeInput(this.props.data);
  }

  componentDidUpdate() {
    this.materializeInput(this.props.data);
  }

  // original:
  // materializeInput(nextData) {
  // if (this.state.data === nextData) {
  //   return;

  materializeInput(nextDataInput) {
    // apply custom filter by date first

    const nextData = (nextDataInput || [])
      // CAUTION: the filter is alro reused in browser-app ChartWrapper ReportEdit & Reporting components, and must be kept in sync
      // eslint-disable-next-line no-magic-numbers
      .filter((x) => x.date > (this.props.dateFilter.from || new Date(1902, 0, 1).valueOf()) && x.date <= (this.props.dateFilter.to || new Date(2200, 0, 1).valueOf()));
    // if .filter is applied on this function's input object, it no longer is identified as === to state.data, so a .length check has been introduced instead
    // this is a crude solution, but should work for this use case
    if ((this.state.data || []).length === (nextData || []).length) {
      return;
    }
    const newState = {
      data: nextData,
      attrValues: {},
      materializedInput: [],
    };
    let recordsProcessed = 0;
    PivotData.forEachRecord(
      newState.data,
      this.props.derivedAttributes,
      function (record) {
        newState.materializedInput.push(record);
        for (const attr of Object.keys(record)) {
          if (!(attr in newState.attrValues)) {
            newState.attrValues[attr] = {};
            if (recordsProcessed > 0) {
              newState.attrValues[attr].null = recordsProcessed;
            }
          }
        }
        for (const attr in newState.attrValues) {
          const value = attr in record ? record[attr] : 'null';
          if (!(value in newState.attrValues[attr])) {
            newState.attrValues[attr][value] = 0;
          }
          newState.attrValues[attr][value]++;
        }
        recordsProcessed++;
      }
    );
    this.setState(newState);
  }

  sendPropUpdate(command) {
    this.props.onChange(update(this.props, command));
  }

  propUpdater(key) {
    return value => this.sendPropUpdate({ [key]: { $set: value } });
  }

  setValuesInFilter(attribute, values) {
    this.sendPropUpdate({
      valueFilter: {
        [attribute]: {
          $set: values.reduce((r, v) => {
            r[v] = true;
            return r;
          }, {}),
        },
      },
    });
  }

  addValuesToFilter(attribute, values) {
    if (attribute in this.props.valueFilter) {
      this.sendPropUpdate({
        valueFilter: {
          [attribute]: values.reduce((r, v) => {
            r[v] = { $set: true };
            return r;
          }, {}),
        },
      });
    } else {
      this.setValuesInFilter(attribute, values);
    }
  }

  removeValuesFromFilter(attribute, values) {
    this.sendPropUpdate({
      valueFilter: { [attribute]: { $unset: values } },
    });
  }

  moveFilterBoxToTop(attribute) {
    this.setState(
      update(this.state, {
        maxZIndex: { $set: this.state.maxZIndex + 1 },
        zIndices: { [attribute]: { $set: this.state.maxZIndex + 1 } },
      })
    );
  }

  setFilterClause({ from, to }) {
    this.props.setDateFilter(from, to);
  }

  isOpen(dropdown) {
    return this.state.openDropdown === dropdown;
  }

  makeDnDCell(items, onChange, classes, id, label = '', limit = Infinity) {
    return (
      <td
        className={classes}
        rowSpan={id === 'unusedAttrs' ? 2 : 1} // handles rowspan property necessary for unusedAttrs
      >
        <h3 className="text-sm font-bold text-gray-900 pb-2">{label}</h3>
        {(this.state.displayWarning === id) && <p className="text-xs font-normal text-red-500 pb-2">Maximum number of attributes exceeded</p>}
        <Sortable
          options={{
            group: 'shared',
            ghostClass: 'pvtPlaceholder',
            filter: '.pvtFilterBox',
            preventOnFilter: false,
            onMove: (event) => {
              // onMove event has acccess to the 'to' element; each Sortable receives a 'data-limit' property equal to its limit, as set in the makeDnDCell function
              // this handler compares the limit to the actual amount of child nodes already available and returns true if the move is possible (and false if it is not)
              // the onMove event happens always on the SOURCE container, so the state and id belong to the source container
              // for debugging:
              // console.log('onMove test -- source:', id, 'target:', event.to.id, 'target limit:', event.to.getAttribute('data-limit'), 'target usage:', event.to.childNodes.length); // items, sortable, customEvent
              // console.log('onMove test:', event.to.getAttribute('data-limit') >= event.to.childNodes.length, _this8.state.disableMe);
              if ((event.to.childNodes.length + 1) <= Number(event.to.getAttribute('data-limit'))) {
                return true; // move possible              };
              }
              this.setState(update(this.state, {
                displayWarning: { $set: event.to.id },
              }));
              return false; // move impossible
            },
            onEnd: () => {
              this.setState(update(this.state, {
                displayWarning: { $set: null },
              }));
            },
          }}
          tag="div"
          className="flex flex-wrap max-w-xs"
          id={id}
          data-limit={limit}
          onChange={onChange}
        >
          {items.map(x => (
            <DraggableAttribute
              name={x}
              key={x}
              attrValues={this.state.attrValues[x]}
              valueFilter={this.props.valueFilter[x] || {}}
              sorter={getSort(this.props.sorters, x)}
              menuLimit={this.props.menuLimit}
              setValuesInFilter={this.setValuesInFilter.bind(this)}
              addValuesToFilter={this.addValuesToFilter.bind(this)}
              moveFilterBoxToTop={this.moveFilterBoxToTop.bind(this)}
              removeValuesFromFilter={this.removeValuesFromFilter.bind(this)}
              zIndex={this.state.zIndices[x] || this.state.maxZIndex}
              setFilterClause={this.setFilterClause.bind(this)}
              dateFilter={this.props.dateFilter}
            />
          ))}
        </Sortable>
      </td>
    );
  }

  render() {
    const numValsAllowed =
      this.props.aggregators[this.props.aggregatorName]([])().numInputs || 0;

    const rendererName =
      this.props.rendererName in this.props.renderers
        ? this.props.rendererName
        : Object.keys(this.props.renderers)[0];

    const rendererCell = (
      <td className="p-3 align-top">
        <h3 className="text-sm font-bold text-gray-900 pb-2">Report type</h3>
        <Dropdown
          current={rendererName}
          values={Object.keys(this.props.renderers)}
          open={this.isOpen('renderer')}
          zIndex={this.isOpen('renderer') ? this.state.maxZIndex + 1 : 1}
          toggle={() =>
            this.setState({
              openDropdown: this.isOpen('renderer') ? false : 'renderer',
            })
          }
          setValue={this.propUpdater('rendererName')}
        />
      </td>
    );

    const sortIcons = {
      key_a_to_z: {
        rowSymbol: '↕',
        colSymbol: '↔',
        next: 'value_a_to_z',
      },
      value_a_to_z: {
        rowSymbol: '↓',
        colSymbol: '→',
        next: 'value_z_to_a',
      },
      value_z_to_a: { rowSymbol: '↑', colSymbol: '←', next: 'key_a_to_z' },
    };

    const aggregatorCell = (
      <td className="p-3 align-top">
        <h3 className="text-sm font-bold text-gray-900 pb-2">
          {this.props.rendererPivotTableUISettings.yAxisPrimary.label || 'Vertical axis (Y) - values'}
        </h3>
        <Dropdown
          current={this.props.aggregatorName}
          values={Object.keys(this.props.aggregators)}
          open={this.isOpen('aggregators')}
          zIndex={this.isOpen('aggregators') ? this.state.maxZIndex + 1 : 1}
          toggle={() =>
            this.setState({
              openDropdown: this.isOpen('aggregators') ? false : 'aggregators',
            })
          }
          setValue={this.propUpdater('aggregatorName')}
        />
        <a
          role="button"
          className="pvtRowOrder"
          onClick={() =>
            this.propUpdater('rowOrder')(sortIcons[this.props.rowOrder].next)
          }
        >
          {sortIcons[this.props.rowOrder].rowSymbol}
        </a>
        <a
          role="button"
          className="pvtColOrder"
          onClick={() =>
            this.propUpdater('colOrder')(sortIcons[this.props.colOrder].next)
          }
        >
          {sortIcons[this.props.colOrder].colSymbol}
        </a>
        {numValsAllowed > 0 && <br />}
        {new Array(numValsAllowed).fill().map((n, i) => [
          <Dropdown
            key={i}
            current={this.props.vals[i]}
            values={Object.keys(this.state.attrValues).filter(
              e =>
                !this.props.hiddenAttributes.includes(e) &&
                !this.props.hiddenFromAggregators.includes(e)
            )}
            open={this.isOpen(`val${i}`)}
            zIndex={this.isOpen(`val${i}`) ? this.state.maxZIndex + 1 : 1}
            toggle={() =>
              this.setState({
                openDropdown: this.isOpen(`val${i}`) ? false : `val${i}`,
              })
            }
            setValue={value =>
              this.sendPropUpdate({
                vals: { $splice: [[i, 1, value]] },
              })
            }
          />,
          i + 1 !== numValsAllowed ? <br key={`br${i}`} /> : null,
        ])}
      </td>
    );

    const unusedAttrs = Object.keys(this.state.attrValues)
      .filter(
        e =>
          !this.props.rows.includes(e) &&
          !this.props.cols.includes(e) &&
          !this.props.hiddenAttributes.includes(e) &&
          !this.props.hiddenFromDragDrop.includes(e)
      )
      .sort(sortAs(this.state.unusedOrder));

    const unusedLength = unusedAttrs.reduce((r, e) => r + e.length, 0);
    const horizUnused = unusedLength < this.props.unusedOrientationCutoff;

    const rendererPivotTableUISettings = this.props.rendererPivotTableUISettings;

    const unusedAttrsCell = this.makeDnDCell(
      unusedAttrs,
      order => this.setState({ unusedOrder: order }),
      `p-3 align-top pvtAxisContainer pvtUnused ${horizUnused ? 'pvtHorizList' : 'pvtVertList'}`,
      'unusedAttrs',
      'Unused columns'
    );

    const colAttrs = this.props.cols.filter(
      e =>
        !this.props.hiddenAttributes.includes(e) &&
        !this.props.hiddenFromDragDrop.includes(e)
    );

    const colAttrsCell = this.makeDnDCell(
      colAttrs,
      this.propUpdater('cols'),
      'pvtAxisContainer pvtHorizList pvtCols p-3 align-top',
      'colAttrs',
      rendererPivotTableUISettings.xAxisSecondary.label || 'Secondary categories (X-axis) or table columns',
      rendererPivotTableUISettings.xAxisSecondary.limit || 1,
    );

    const rowAttrs = this.props.rows.filter(
      e =>
        !this.props.hiddenAttributes.includes(e) &&
        !this.props.hiddenFromDragDrop.includes(e)
    );
    const rowAttrsCell = this.makeDnDCell(
      rowAttrs,
      this.propUpdater('rows'),
      'pvtAxisContainer pvtVertList pvtRows p-3 align-top',
      'rowAttrs',
      rendererPivotTableUISettings.xAxisPrimary.label || 'Main category (X-axis) or table rows',
      rendererPivotTableUISettings.xAxisPrimary.limit || 1,
    );
    const outputCell = (
      <td className="pvtOutput h-full" rowSpan={3}>
        <div className="p-8 h-full flex items-center justify-center">
          <PivotTable
            {...update(this.props, {
              data: { $set: this.state.materializedInput },
            })}
          />
        </div>
      </td>
    );

    // vv this won't work with the current layout (because of rowspan)
    // if (horizUnused) {
    //   return (
    //     <table className="pvtUi">
    //       <tbody onClick={() => this.setState({ openDropdown: false })}>
    //         <tr>
    //           {rendererCell}
    //           {unusedAttrsCell}
    //         </tr>
    //         <tr>
    //           {aggregatorCell}
    //           {colAttrsCell}
    //         </tr>
    //         <tr>
    //           {rowAttrsCell}
    //           {outputCell}
    //         </tr>
    //       </tbody>
    //     </table>
    //   );
    // }

    return (
      <table className="pvtUi h-full">
        <tbody onClick={() => this.setState({ openDropdown: false })}>
          <tr>
            {rendererCell}
            {aggregatorCell}
            {outputCell}
          </tr>
          <tr>
            {unusedAttrsCell}
            {rowAttrsCell}
          </tr>
          <tr>
            {colAttrsCell}
          </tr>
        </tbody>
      </table>
    );
  }
}

PivotTableUI.propTypes = Object.assign({}, PivotTable.propTypes, {
  onChange: PropTypes.func.isRequired,
  hiddenAttributes: PropTypes.arrayOf(PropTypes.string),
  hiddenFromAggregators: PropTypes.arrayOf(PropTypes.string),
  hiddenFromDragDrop: PropTypes.arrayOf(PropTypes.string),
  unusedOrientationCutoff: PropTypes.number,
  menuLimit: PropTypes.number,
});

PivotTableUI.defaultProps = Object.assign({}, PivotTable.defaultProps, {
  hiddenAttributes: [],
  hiddenFromAggregators: [],
  hiddenFromDragDrop: [],
  unusedOrientationCutoff: 85,
  menuLimit: 500,
});

export default PivotTableUI;
