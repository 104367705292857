/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';

function SvgMonestrySpinner(props) {
  return (
    <svg
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      preserveAspectRatio="xMidYMid"
      width={560}
      height={560}
      {...props}
    >
      <rect
        width={560}
        height={560}
        rx={0}
        ry={0}
        fill="#FFF"
        fillOpacity={0.6}
      />
      <path
        d="M0 125V0h500v95.364C471.85 85.414 441.557 80 410 80c-55.203 0-106.537 16.567-149.3 45H0Z"
        fill="#1797F2"
        fillOpacity={0}
        style={{
          opacity: 1,
          fill: '#1797f2',
          fillOpacity: 1,
        }}
      >
        <animate
          attributeType="auto"
          attributeName="fill-opacity"
          values="0;0;1;1"
          calcMode="spline"
          keyTimes="0;0.6;0.8;1"
          keySplines="0 0 1 1;0 0 1 1;0 0 1 1"
          dur="2s"
          begin="0s"
          repeatCount="indefinite"
          additive="replace"
          accumulate="none"
          fill="freeze"
        />
      </path>
      <path
        d="M0 270V145h234.28c-38.114 32.7-67.004 75.847-82.231 125H0Z"
        fill="#1797F2"
        fillOpacity={0}
        style={{
          opacity: 1,
          fill: '#1797f2',
          fillOpacity: 1,
        }}
      >
        <animate
          attributeType="auto"
          attributeName="fill-opacity"
          values="0;0;1;1"
          calcMode="spline"
          keyTimes="0;0.4;0.6;1"
          keySplines="0 0 1 1;0 0 1 1;0 0 1 1"
          dur="2s"
          begin="0s"
          repeatCount="indefinite"
          additive="replace"
          accumulate="none"
          fill="freeze"
        />
      </path>
      <path
        d="M0 415V290h146.69c-4.378 19.297-6.69 39.378-6.69 60 0 22.41 2.73 44.18 7.876 65H0Z"
        fill="#1797F2"
        fillOpacity={0}
        style={{
          opacity: 1,
          fill: '#1797f2',
          fillOpacity: 1,
        }}
      >
        <animate
          attributeType="auto"
          attributeName="fill-opacity"
          values="0;0;1;1"
          calcMode="spline"
          keyTimes="0;0.2;0.4;1"
          keySplines="0 0 1 1;0 0 1 1;0 0 1 1"
          dur="2s"
          begin="0s"
          repeatCount="indefinite"
          additive="replace"
          accumulate="none"
          fill="freeze"
        />
      </path>
      <path
        d="M0 560V435h153.652c16.44 49.606 46.875 92.832 86.628 125H0Z"
        fill="#1797F2"
        fillOpacity={0}
        style={{
          opacity: 1,
          fill: '#1797f2',
          fillOpacity: 1,
        }}
      >
        <animate
          attributeType="auto"
          attributeName="fill-opacity"
          values="0;1;1"
          calcMode="spline"
          keyTimes="0;0.2;1"
          keySplines="0 0 1 1;0 0 1 1"
          dur="2s"
          begin="0s"
          repeatCount="indefinite"
          additive="replace"
          accumulate="none"
          fill="freeze"
        />
      </path>
      <circle transform="translate(410 350)" opacity={0.55} fill="#FFF" r={270} />
      <circle transform="translate(410 350)" r={150} />
    </svg>
  );
}

export default SvgMonestrySpinner;
