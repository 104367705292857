const cookieList = [
  { name: '__stripe_sid', group: 'essential', providerName: 'Stripe', providerLink: 'https://stripe.com', expiry: 'Session', type: 'Functional' },
  { name: '__stripe_mid', group: 'essential', providerName: 'Stripe', providerLink: 'https://stripe.com', expiry: '1 year', type: 'Functional' },
  { name: 'AppleAuth', group: 'essential', providerName: 'Apple', providerLink: 'https://apple.com', expiry: 'Varies', type: 'Authentication' },
  { name: 'FacebookAuth', group: 'essential', providerName: 'Facebook', providerLink: 'https://facebook.com', expiry: 'Varies', type: 'Authentication' },
  { name: 'GoogleAuth', group: 'essential', providerName: 'Google', providerLink: 'https://google.com', expiry: 'Varies', type: 'Authentication' },
  { name: 'LinkedInAuth', group: 'essential', providerName: 'LinkedIn', providerLink: 'https://linkedin.com', expiry: 'Varies', type: 'Authentication' },
  { name: 'cws_s', group: 'essential', providerName: 'AWS RUM', providerLink: 'https://aws.amazon.com', expiry: 'Session', type: 'Performance' },
  { name: 'cws_u', group: 'essential', providerName: 'AWS RUM', providerLink: 'https://aws.amazon.com', expiry: '1 year', type: 'Performance' },
  { name: 'CognitoAuth', group: 'essential', providerName: 'AWS Cognito', providerLink: 'https://aws.amazon.com/cognito/', expiry: 'Varies', type: 'Authentication' },
  { name: 'i18nextLng', group: 'essential', providerName: 'Local', providerLink: '', expiry: '1 year', type: 'Functional' },
  { name: 'YouTube', group: 'externalMedia', providerName: 'YouTube', providerLink: 'https://youtube.com', expiry: 'Varies', type: 'Functional' },
  { name: 'Consent', group: 'essential', providerName: 'Local', providerLink: '', expiry: '1 year', type: 'Necessary' },
  { name: 'TailwindUI', group: 'essential', providerName: 'TailwindUI', providerLink: 'https://tailwindui.com', expiry: 'Varies', type: 'Unknown' },
  { name: 'Unsplash', group: 'essential', providerName: 'Unsplash', providerLink: 'https://unsplash.com', expiry: 'Varies', type: 'Functional' },
];

export default cookieList;
