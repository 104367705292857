/* eslint-disable no-unsafe-optional-chaining */

import { createSelector } from '@reduxjs/toolkit';
import * as globals from '..';
import { applyFIFOOnCategory } from '../../../../misc/arrayHelpers';

// returns an array with all transactions (real or real+simulated) up to the Slider Date (baseDate) or to today (depending on dashboardMode in state)
// no isolated transactions are included
// apply FIFO to calculate quantityOpen
export const metalsTransactions = createSelector(
  (state) => state.simulation?.dashboardMode,
  (state) => state.data?.metals.transactions,
  (state) => state.data?.metals.simulatedTransactions,
  (state) => state.simulation?.baseDate,
  (state) => state.projects,
  (state) => state.user?.profile.settings.inflationRate,
  (state) => state.user?.profile.settings.sliderEndDate,
  (dashboardMode, transactions, simulatedTransactions, baseDate, projects, inflationRate, sliderMax) => {
    let returnTransactions;
    if (dashboardMode === 'projects') {
      const isolatedProjects = projects?.filter((project) => project.settings?.isIsolated).map((project) => project.id);
      const simulatedTransactionsWithoutIsolated = simulatedTransactions.filter((txn) => !isolatedProjects.includes(txn.projectId));

      returnTransactions = (transactions || [])
        .concat(simulatedTransactionsWithoutIsolated)
        .filter((transaction) => transaction.date <= baseDate)
        // unpack recurring transactions by creating transactions for every recurring transaction (never = until the end of slider)
        .flatMap((txn) => globals.unpackRecurringTransactionsCore(inflationRate, baseDate, sliderMax, txn));
    } else {
      returnTransactions = transactions || [];
    }
    return applyFIFOOnCategory(returnTransactions);
  },
);

// returns an array with all transactions (simulated and real) up to the Simulation End Date (slider end)
// including all isolated transactions
export const metalsTransactionsProjectView = createSelector(
  (state) => state.simulation?.dashboardMode,
  (state) => state.data?.metals.transactions,
  (state) => state.data?.metals.simulatedTransactions,
  (state) => state.simulation.baseDate,
  (state) => state.user?.profile.settings.inflationRate,
  (state) => state.user?.profile.settings.sliderEndDate,
  (dashboardMode, transactions, simulatedTransactions, baseDate, inflationRate, sliderMax) => {
    let returnTransactions;
    if (dashboardMode === 'projects') {
      returnTransactions = (transactions || [])
        .concat(simulatedTransactions)
        // unpack recurring transactions by creating transactions for every recurring transaction (never = until the end of slider)
        .flatMap((txn) => globals.unpackRecurringTransactionsCore(inflationRate, baseDate, sliderMax, txn));
      // no recurring transactions in real estate
    } else {
      returnTransactions = transactions || [];
    }
    return applyFIFOOnCategory(returnTransactions);
  },
);

export function getMetalsAssetId(assetName) {
  // only leave letters and numbers, strip and trim everything else
  return assetName.replace(/[^a-zA-Z0-9]/g, '');
}

// metals asset dictionary (returns an array of all unique name-metal-purity-additionalValue combinations)
// do not use stocksGlobalTransactionView, because if this is run from a component in dashboard mode,
// it won't have any simulated transactions, so it won't have any figis for asset only existing in projects
// this can read directly from state, because it only provides assetId translation (and that does not change through recurring / isolated transactions etc.)
export const metalsAssets = createSelector(
  (state) => state.data.metals.transactions,
  (state) => state.data.metals.simulatedTransactions,
  ($transactions, $simulatedTransactions) => {
    // get all distinct combinations of assetName+assetMetal+assetPurity+assetAdditionalValue from ($transactions.concat($simulatedTransactions) || [])
    // omitting assetId because it is derived from assetName
    const uniqueCombinationsSet = new Set(
      ($transactions.concat($simulatedTransactions) || []).map((item) => `${item.assetName}|${item.assetMetal}|${item.assetWeight}|${item.assetPurity}|${item.assetAdditionalValue}`),
    );

    // Convert Set to Array to get an array of unique combinations
    return Array.from(uniqueCombinationsSet).map((item) => {
      const [assetName, assetMetal, assetWeight, assetPurity, assetAdditionalValue] = item.split('|');
      return {
        assetId: getMetalsAssetId(assetName),
        assetName,
        assetMetal,
        assetWeight,
        assetPurity,
        assetAdditionalValue,
      };
    });
  },
);
