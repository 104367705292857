import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  GlobeAltIcon, BoltIcon, TableCellsIcon, HandRaisedIcon, ArrowPathIcon, CheckIcon,
} from '@heroicons/react/24/outline';
import TryItOut from './TryItOut';

export default function FeaturesSection4() {
  const { t } = useTranslation(['site']);

  const features = [
    {
      name: `${t('site:features.section4.sync.auto.header')}`,
      // FIXME: link missing
      description: `${t('site:features.section4.sync.auto.body')}`,
      icon: ArrowPathIcon,
    },
    {
      name: `${t('site:features.section4.sync.edit.header')}`,
      description: `${t('site:features.section4.sync.edit.body')}`,
      icon: TableCellsIcon,
    },
    {
      name: `${t('site:features.section4.sync.uploads.header')}`,
      description: `${t('site:features.section4.sync.uploads.body')}`,
      icon: BoltIcon,
    },
    {
      name: `${t('site:features.section4.sync.decide.header')}`,
      description: `${t('site:features.section4.sync.decide.body')}`,
      icon: HandRaisedIcon,
    },
  ];

  const foundations = [
    {
      name: `${t('site:features.section4.foundations.sustain.header')}`,
      description: `${t('site:features.section4.foundations.sustain.body')}`,
    },
    {
      name: `${t('site:features.section4.foundations.anonymous.header')}`,
      description: `${t('site:features.section4.foundations.anonymous.body')}`,
    },
    {
      name: `${t('site:features.section4.foundations.control.header')}`,
      description: `${t('site:features.section4.foundations.control.body')}`,
    },
    {
      name: `${t('site:features.section4.foundations.neutral.header')}`,
      description: `${t('site:features.section4.foundations.neutral.body')}`,
    },
    {
      name: `${t('site:features.section4.foundations.security.header')}`,
      description: `${t('site:features.section4.foundations.security.body')}`,
    },
    {
      name: `${t('site:features.section4.foundations.impartial.header')}`,
      description: `${t('site:features.section4.foundations.impartial.body')}`,
    },
    {
      name: `${t('site:features.section4.foundations.audit.header')}`,
      description: `${t('site:features.section4.foundations.audit.body')}`,
    },
    {
      name: `${t('site:features.section4.foundations.german.header')}`,
      description: `${t('site:features.section4.foundations.german.body')}`,
    },
  ];

  return (
    <div>
      <div className="bg-gray-50 overflow-hidden">
        <div className="relative max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
          <svg
            className="absolute top-0 left-full transform -translate-x-1/2 -translate-y-3/4 lg:left-auto lg:right-full lg:translate-x-2/3 lg:translate-y-1/4"
            width={404}
            height={784}
            fill="none"
            viewBox="0 0 404 784"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="8b1b5f72-e944-4457-af67-0c6d15a99f38"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
              </pattern>
            </defs>
            <rect width={404} height={784} fill="url(#8b1b5f72-e944-4457-af67-0c6d15a99f38)" />
          </svg>

          <div className="relative lg:grid lg:grid-cols-3 lg:gap-x-8">
            <div className="lg:col-span-1">
              <h2 className="text-3xl font-black tracking-tight text-gray-900 sm:text-4xl">
                {t('site:features.section4.sync.header')}
              </h2>
            </div>
            <dl className="mt-10 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-8 sm:gap-y-10 lg:mt-0 lg:col-span-2">
              {features.map((feature) => (
                <div key={feature.name}>
                  <dt>
                    <div className="flex items-center justify-center h-12 w-12 rounded-md bg-brandBlue-500 text-white">
                      <feature.icon className="h-6 w-6" aria-hidden="true" />
                    </div>
                    <p className="mt-5 text-xl leading-6 font-bold text-gray-900">{feature.name}</p>
                  </dt>
                  <dd className="mt-2 text-base text-gray-500">{feature.description}</dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </div>
      <div className="bg-white">
        <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-24 lg:px-8">
          <div className="max-w-3xl mx-auto text-center">
            <h2 className="text-4xl font-black text-gray-900">{t('site:features.section4.foundations.header')}</h2>
            <p className="mt-4 text-lg text-gray-500">
              {t('site:features.section4.foundations.body')}
            </p>
          </div>
          <dl className="mt-12 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 lg:grid-cols-4 lg:gap-x-8">
            {foundations.map((feature) => (
              <div key={feature.name} className="relative">
                <dt>
                  <CheckIcon className="absolute h-6 w-6 text-green-500" aria-hidden="true" />
                  <p className="ml-9 text-xl leading-6 font-bold text-gray-900">{feature.name}</p>
                </dt>
                <dd className="mt-2 ml-9 text-base text-gray-500">{feature.description}</dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
      {/* <div className="relative py-16 bg-white">
        <div className="hidden absolute top-0 inset-x-0 h-1/2 bg-gray-50 lg:block" aria-hidden="true" />
        <div className="max-w-7xl mx-auto bg-brandDarkBlue-600 lg:bg-transparent lg:px-8">
          <div className="lg:grid lg:grid-cols-12">
            <div className="relative z-10 lg:col-start-1 lg:row-start-1 lg:col-span-4 lg:py-16 lg:bg-transparent">
              <div className="absolute inset-x-0 h-1/2 bg-gray-50 lg:hidden" aria-hidden="true" />
              <div className="max-w-md mx-auto px-4 sm:max-w-3xl sm:px-6 lg:max-w-none lg:p-0">
                <div className="aspect-w-10 aspect-h-6 sm:aspect-w-2 sm:aspect-h-1 lg:aspect-w-1">
                  <img
                    className="object-cover object-center rounded-3xl shadow-2xl"
                    src="https://images.unsplash.com/photo-1507207611509-ec012433ff52?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=934&q=80"
                    alt=""
                  />
                </div>
              </div>
            </div>

            <div className="relative bg-brandDarkBlue-500 lg:col-start-3 lg:row-start-1 lg:col-span-10 lg:rounded-3xl lg:grid lg:grid-cols-10 lg:items-center">
              <div className="hidden absolute inset-0 overflow-hidden rounded-3xl lg:block" aria-hidden="true">
                <svg
                  className="absolute bottom-full left-full transform translate-y-1/3 -translate-x-2/3 xl:bottom-auto xl:top-0 xl:translate-y-0 stroke-brandDarkBlue-500"
                  width={404}
                  height={384}
                  fill="none"
                  viewBox="0 0 404 384"
                  aria-hidden="true"
                >
                  <defs>
                    <pattern
                      id="39b141fb-6a6b-422c-9cc3-5706844cb989"
                      x={0}
                      y={0}
                      width={20}
                      height={20}
                      patternUnits="userSpaceOnUse"
                    >
                      <rect x={0} y={0} width={4} height={4} className="text-brandDarkBlue-400" fill="currentColor" />
                    </pattern>
                  </defs>
                  <rect width={404} height={384} fill="url(#39b141fb-6a6b-422c-9cc3-5706844cb989)" />
                </svg>
                <svg
                  className="absolute top-full transform -translate-y-1/3 -translate-x-1/3 xl:-translate-y-1/2 stroke-brandDarkBlue-500"
                  width={404}
                  height={384}
                  fill="none"
                  viewBox="0 0 404 384"
                  aria-hidden="true"
                >
                  <defs>
                    <pattern
                      id="39b141fb-6a6b-422c-9cc3-5706844cb989"
                      x={0}
                      y={0}
                      width={20}
                      height={20}
                      patternUnits="userSpaceOnUse"
                    >
                      <rect x={0} y={0} width={4} height={4} className="text-brandDarkBlue-400" fill="currentColor" />
                    </pattern>
                  </defs>
                  <rect width={404} height={384} fill="url(#39b141fb-6a6b-422c-9cc3-5706844cb989)" />
                </svg>
              </div>
              <div className="relative max-w-md mx-auto py-12 px-4 space-y-6 sm:max-w-3xl sm:py-16 sm:px-6 lg:max-w-none lg:p-0 lg:col-start-4 lg:col-span-6">
                <h2 className="text-3xl font-extrabold text-white" id="join-heading">
                  {t('site:features.section4.cta.header')}
                </h2>
                <p className="text-xl text-white">
                  {t('site:features.section4.cta.subheader')}
                </p>
                <a
                  className="block w-full py-3 px-5 text-center bg-white border border-transparent rounded-md shadow-md text-base font-medium text-brandBlue-600 hover:bg-gray-50 sm:inline-block sm:w-auto"
                  href="/"
                >
                  {t('site:general.freetrial')}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <TryItOut />
    </div>
  );
}
