[
  {
    "date": "2012-09-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2012-09",
    "Date (Year)": "2012",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5SYB8",
    "displayName": "BASF (DE000BASF111)"
  },
  {
    "date": "2012-10-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2012-10",
    "Date (Year)": "2012",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5SYB8",
    "displayName": "BASF (DE000BASF111)"
  },
  {
    "date": "2012-11-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2012-11",
    "Date (Year)": "2012",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5SYB8",
    "displayName": "BASF (DE000BASF111)"
  },
  {
    "date": "2012-12-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2012-12",
    "Date (Year)": "2012",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5SYB8",
    "displayName": "BASF (DE000BASF111)"
  },
  {
    "date": "2013-01-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2013-01",
    "Date (Year)": "2013",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5SYB8",
    "displayName": "BASF (DE000BASF111)"
  },
  {
    "date": "2013-02-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2013-02",
    "Date (Year)": "2013",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5SYB8",
    "displayName": "BASF (DE000BASF111)"
  },
  {
    "date": "2013-03-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2013-03",
    "Date (Year)": "2013",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5SYB8",
    "displayName": "BASF (DE000BASF111)"
  },
  {
    "date": "2013-04-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2013-04",
    "Date (Year)": "2013",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5SYB8",
    "displayName": "BASF (DE000BASF111)"
  },
  {
    "date": "2013-05-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2013-05",
    "Date (Year)": "2013",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5SYB8",
    "displayName": "BASF (DE000BASF111)"
  },
  {
    "date": "2013-06-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2013-06",
    "Date (Year)": "2013",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5SYB8",
    "displayName": "BASF (DE000BASF111)"
  },
  {
    "date": "2013-07-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2013-07",
    "Date (Year)": "2013",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5SYB8",
    "displayName": "BASF (DE000BASF111)"
  },
  {
    "date": "2013-08-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2013-08",
    "Date (Year)": "2013",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5SYB8",
    "displayName": "BASF (DE000BASF111)"
  },
  {
    "date": "2013-09-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2013-09",
    "Date (Year)": "2013",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5SYB8",
    "displayName": "BASF (DE000BASF111)"
  },
  {
    "date": "2013-10-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2013-10",
    "Date (Year)": "2013",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5SYB8",
    "displayName": "BASF (DE000BASF111)"
  },
  {
    "date": "2013-11-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2013-11",
    "Date (Year)": "2013",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5SYB8",
    "displayName": "BASF (DE000BASF111)"
  },
  {
    "date": "2013-12-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2013-12",
    "Date (Year)": "2013",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5SYB8",
    "displayName": "BASF (DE000BASF111)"
  },
  {
    "date": "2014-01-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2014-01",
    "Date (Year)": "2014",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5SYB8",
    "displayName": "BASF (DE000BASF111)"
  },
  {
    "date": "2014-02-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2014-02",
    "Date (Year)": "2014",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5SYB8",
    "displayName": "BASF (DE000BASF111)"
  },
  {
    "date": "2014-03-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2014-03",
    "Date (Year)": "2014",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5SYB8",
    "displayName": "BASF (DE000BASF111)"
  },
  {
    "date": "2014-04-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2014-04",
    "Date (Year)": "2014",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5SYB8",
    "displayName": "BASF (DE000BASF111)"
  },
  {
    "date": "2014-05-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2014-05",
    "Date (Year)": "2014",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5SYB8",
    "displayName": "BASF (DE000BASF111)"
  },
  {
    "date": "2014-06-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2014-06",
    "Date (Year)": "2014",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5SYB8",
    "displayName": "BASF (DE000BASF111)"
  },
  {
    "date": "2014-07-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2014-07",
    "Date (Year)": "2014",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5SYB8",
    "displayName": "BASF (DE000BASF111)"
  },
  {
    "date": "2014-08-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2014-08",
    "Date (Year)": "2014",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5SYB8",
    "displayName": "BASF (DE000BASF111)"
  },
  {
    "date": "2014-09-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2014-09",
    "Date (Year)": "2014",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5SYB8",
    "displayName": "BASF (DE000BASF111)"
  },
  {
    "date": "2014-10-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2014-10",
    "Date (Year)": "2014",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5SYB8",
    "displayName": "BASF (DE000BASF111)"
  },
  {
    "date": "2014-11-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2014-11",
    "Date (Year)": "2014",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5SYB8",
    "displayName": "BASF (DE000BASF111)"
  },
  {
    "date": "2014-12-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2014-12",
    "Date (Year)": "2014",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5SYB8",
    "displayName": "BASF (DE000BASF111)"
  },
  {
    "date": "2015-01-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2015-01",
    "Date (Year)": "2015",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5SYB8",
    "displayName": "BASF (DE000BASF111)"
  },
  {
    "date": "2015-02-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2015-02",
    "Date (Year)": "2015",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5SYB8",
    "displayName": "BASF (DE000BASF111)"
  },
  {
    "date": "2015-03-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2015-03",
    "Date (Year)": "2015",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5SYB8",
    "displayName": "BASF (DE000BASF111)"
  },
  {
    "date": "2015-04-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2015-04",
    "Date (Year)": "2015",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5SYB8",
    "displayName": "BASF (DE000BASF111)"
  },
  {
    "date": "2015-05-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2015-05",
    "Date (Year)": "2015",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5SYB8",
    "displayName": "BASF (DE000BASF111)"
  },
  {
    "date": "2015-06-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2015-06",
    "Date (Year)": "2015",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5SYB8",
    "displayName": "BASF (DE000BASF111)"
  },
  {
    "date": "2015-07-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2015-07",
    "Date (Year)": "2015",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5SYB8",
    "displayName": "BASF (DE000BASF111)"
  },
  {
    "date": "2015-08-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2015-08",
    "Date (Year)": "2015",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5SYB8",
    "displayName": "BASF (DE000BASF111)"
  },
  {
    "date": "2015-09-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2015-09",
    "Date (Year)": "2015",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5SYB8",
    "displayName": "BASF (DE000BASF111)"
  },
  {
    "date": "2015-10-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2015-10",
    "Date (Year)": "2015",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5SYB8",
    "displayName": "BASF (DE000BASF111)"
  },
  {
    "date": "2015-11-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2015-11",
    "Date (Year)": "2015",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5SYB8",
    "displayName": "BASF (DE000BASF111)"
  },
  {
    "date": "2015-12-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2015-12",
    "Date (Year)": "2015",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5SYB8",
    "displayName": "BASF (DE000BASF111)"
  },
  {
    "date": "2016-01-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2016-01",
    "Date (Year)": "2016",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5SYB8",
    "displayName": "BASF (DE000BASF111)"
  },
  {
    "date": "2016-02-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2016-02",
    "Date (Year)": "2016",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5SYB8",
    "displayName": "BASF (DE000BASF111)"
  },
  {
    "date": "2016-03-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2016-03",
    "Date (Year)": "2016",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5SYB8",
    "displayName": "BASF (DE000BASF111)"
  },
  {
    "date": "2016-04-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2016-04",
    "Date (Year)": "2016",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5SYB8",
    "displayName": "BASF (DE000BASF111)"
  },
  {
    "date": "2016-05-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2016-05",
    "Date (Year)": "2016",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5SYB8",
    "displayName": "BASF (DE000BASF111)"
  },
  {
    "date": "2016-06-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2016-06",
    "Date (Year)": "2016",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5SYB8",
    "displayName": "BASF (DE000BASF111)"
  },
  {
    "date": "2016-07-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2016-07",
    "Date (Year)": "2016",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5SYB8",
    "displayName": "BASF (DE000BASF111)"
  },
  {
    "date": "2016-08-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2016-08",
    "Date (Year)": "2016",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5SYB8",
    "displayName": "BASF (DE000BASF111)"
  },
  {
    "date": "2016-09-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2016-09",
    "Date (Year)": "2016",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5SYB8",
    "displayName": "BASF (DE000BASF111)"
  },
  {
    "date": "2016-10-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2016-10",
    "Date (Year)": "2016",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5SYB8",
    "displayName": "BASF (DE000BASF111)"
  },
  {
    "date": "2016-11-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2016-11",
    "Date (Year)": "2016",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5SYB8",
    "displayName": "BASF (DE000BASF111)"
  },
  {
    "date": "2016-12-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2016-12",
    "Date (Year)": "2016",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5SYB8",
    "displayName": "BASF (DE000BASF111)"
  },
  {
    "date": "2017-01-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2017-01",
    "Date (Year)": "2017",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5SYB8",
    "displayName": "BASF (DE000BASF111)"
  },
  {
    "date": "2017-02-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2017-02",
    "Date (Year)": "2017",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5SYB8",
    "displayName": "BASF (DE000BASF111)"
  },
  {
    "date": "2017-03-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2017-03",
    "Date (Year)": "2017",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5SYB8",
    "displayName": "BASF (DE000BASF111)"
  },
  {
    "date": "2017-04-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2017-04",
    "Date (Year)": "2017",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5SYB8",
    "displayName": "BASF (DE000BASF111)"
  },
  {
    "date": "2017-05-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2017-05",
    "Date (Year)": "2017",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5SYB8",
    "displayName": "BASF (DE000BASF111)"
  },
  {
    "date": "2017-06-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2017-06",
    "Date (Year)": "2017",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5SYB8",
    "displayName": "BASF (DE000BASF111)"
  },
  {
    "date": "2017-07-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2017-07",
    "Date (Year)": "2017",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5SYB8",
    "displayName": "BASF (DE000BASF111)"
  },
  {
    "date": "2017-08-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2017-08",
    "Date (Year)": "2017",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5SYB8",
    "displayName": "BASF (DE000BASF111)"
  },
  {
    "date": "2017-09-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2017-09",
    "Date (Year)": "2017",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5SYB8",
    "displayName": "BASF (DE000BASF111)"
  },
  {
    "date": "2017-10-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2017-10",
    "Date (Year)": "2017",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5SYB8",
    "displayName": "BASF (DE000BASF111)"
  },
  {
    "date": "2017-11-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2017-11",
    "Date (Year)": "2017",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5SYB8",
    "displayName": "BASF (DE000BASF111)"
  },
  {
    "date": "2017-12-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2017-12",
    "Date (Year)": "2017",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5SYB8",
    "displayName": "BASF (DE000BASF111)"
  },
  {
    "date": "2018-01-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2018-01",
    "Date (Year)": "2018",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5SYB8",
    "displayName": "BASF (DE000BASF111)"
  },
  {
    "date": "2018-02-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2018-02",
    "Date (Year)": "2018",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5SYB8",
    "displayName": "BASF (DE000BASF111)"
  },
  {
    "date": "2018-03-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2018-03",
    "Date (Year)": "2018",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5SYB8",
    "displayName": "BASF (DE000BASF111)"
  },
  {
    "date": "2018-04-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2018-04",
    "Date (Year)": "2018",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5SYB8",
    "displayName": "BASF (DE000BASF111)"
  },
  {
    "date": "2018-05-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2018-05",
    "Date (Year)": "2018",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5SYB8",
    "displayName": "BASF (DE000BASF111)"
  },
  {
    "date": "2018-06-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2018-06",
    "Date (Year)": "2018",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5SYB8",
    "displayName": "BASF (DE000BASF111)"
  },
  {
    "date": "2018-07-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2018-07",
    "Date (Year)": "2018",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5SYB8",
    "displayName": "BASF (DE000BASF111)"
  },
  {
    "date": "2018-08-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2018-08",
    "Date (Year)": "2018",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5SYB8",
    "displayName": "BASF (DE000BASF111)"
  },
  {
    "date": "2018-09-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2018-09",
    "Date (Year)": "2018",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5SYB8",
    "displayName": "BASF (DE000BASF111)"
  },
  {
    "date": "2018-10-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2018-10",
    "Date (Year)": "2018",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5SYB8",
    "displayName": "BASF (DE000BASF111)"
  },
  {
    "date": "2018-11-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2018-11",
    "Date (Year)": "2018",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5SYB8",
    "displayName": "BASF (DE000BASF111)"
  },
  {
    "date": "2018-12-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2018-12",
    "Date (Year)": "2018",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5SYB8",
    "displayName": "BASF (DE000BASF111)"
  },
  {
    "date": "2019-01-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2019-01",
    "Date (Year)": "2019",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5SYB8",
    "displayName": "BASF (DE000BASF111)"
  },
  {
    "date": "2019-02-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2019-02",
    "Date (Year)": "2019",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5SYB8",
    "displayName": "BASF (DE000BASF111)"
  },
  {
    "date": "2019-03-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2019-03",
    "Date (Year)": "2019",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5SYB8",
    "displayName": "BASF (DE000BASF111)"
  },
  {
    "date": "2019-04-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2019-04",
    "Date (Year)": "2019",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5SYB8",
    "displayName": "BASF (DE000BASF111)"
  },
  {
    "date": "2019-05-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2019-05",
    "Date (Year)": "2019",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5SYB8",
    "displayName": "BASF (DE000BASF111)"
  },
  {
    "date": "2019-06-01T00:00:00.000Z",
    "value": 2964.99995,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2019-06",
    "Date (Year)": "2019",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5SYB8",
    "displayName": "BASF (DE000BASF111)"
  },
  {
    "date": "2019-07-01T00:00:00.000Z",
    "value": 3192.4999,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2019-07",
    "Date (Year)": "2019",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5SYB8",
    "displayName": "BASF (DE000BASF111)"
  },
  {
    "date": "2019-08-01T00:00:00.000Z",
    "value": 3007.5001,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2019-08",
    "Date (Year)": "2019",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5SYB8",
    "displayName": "BASF (DE000BASF111)"
  },
  {
    "date": "2019-09-01T00:00:00.000Z",
    "value": 3016,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2019-09",
    "Date (Year)": "2019",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5SYB8",
    "displayName": "BASF (DE000BASF111)"
  },
  {
    "date": "2019-10-01T00:00:00.000Z",
    "value": 3164.99995,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2019-10",
    "Date (Year)": "2019",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5SYB8",
    "displayName": "BASF (DE000BASF111)"
  },
  {
    "date": "2019-11-01T00:00:00.000Z",
    "value": 3452.00005,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2019-11",
    "Date (Year)": "2019",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5SYB8",
    "displayName": "BASF (DE000BASF111)"
  },
  {
    "date": "2019-12-01T00:00:00.000Z",
    "value": 3405.5000499999996,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2019-12",
    "Date (Year)": "2019",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5SYB8",
    "displayName": "BASF (DE000BASF111)"
  },
  {
    "date": "2020-01-01T00:00:00.000Z",
    "value": 3372.0001,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2020-01",
    "Date (Year)": "2020",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5SYB8",
    "displayName": "BASF (DE000BASF111)"
  },
  {
    "date": "2020-02-01T00:00:00.000Z",
    "value": 3051,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2020-02",
    "Date (Year)": "2020",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5SYB8",
    "displayName": "BASF (DE000BASF111)"
  },
  {
    "date": "2020-03-01T00:00:00.000Z",
    "value": 2654.9999,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2020-03",
    "Date (Year)": "2020",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5SYB8",
    "displayName": "BASF (DE000BASF111)"
  },
  {
    "date": "2020-04-01T00:00:00.000Z",
    "value": 2049,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2020-04",
    "Date (Year)": "2020",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5SYB8",
    "displayName": "BASF (DE000BASF111)"
  },
  {
    "date": "2020-05-01T00:00:00.000Z",
    "value": 2334.49995,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2020-05",
    "Date (Year)": "2020",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5SYB8",
    "displayName": "BASF (DE000BASF111)"
  },
  {
    "date": "2020-06-01T00:00:00.000Z",
    "value": 2440.74995,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2020-06",
    "Date (Year)": "2020",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5SYB8",
    "displayName": "BASF (DE000BASF111)"
  },
  {
    "date": "2020-07-01T00:00:00.000Z",
    "value": 2466.5001,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2020-07",
    "Date (Year)": "2020",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5SYB8",
    "displayName": "BASF (DE000BASF111)"
  },
  {
    "date": "2020-08-01T00:00:00.000Z",
    "value": 2329.9999,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2020-08",
    "Date (Year)": "2020",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5SYB8",
    "displayName": "BASF (DE000BASF111)"
  },
  {
    "date": "2020-09-01T00:00:00.000Z",
    "value": 2541,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2020-09",
    "Date (Year)": "2020",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5SYB8",
    "displayName": "BASF (DE000BASF111)"
  },
  {
    "date": "2020-10-01T00:00:00.000Z",
    "value": 2570.0001,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2020-10",
    "Date (Year)": "2020",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5SYB8",
    "displayName": "BASF (DE000BASF111)"
  },
  {
    "date": "2020-11-01T00:00:00.000Z",
    "value": 2361.7500499999996,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2020-11",
    "Date (Year)": "2020",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5SYB8",
    "displayName": "BASF (DE000BASF111)"
  },
  {
    "date": "2020-12-01T00:00:00.000Z",
    "value": 3052.49995,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2020-12",
    "Date (Year)": "2020",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5SYB8",
    "displayName": "BASF (DE000BASF111)"
  },
  {
    "date": "2021-01-01T00:00:00.000Z",
    "value": 3233.4999000000003,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2021-01",
    "Date (Year)": "2021",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5SYB8",
    "displayName": "BASF (DE000BASF111)"
  },
  {
    "date": "2021-02-01T00:00:00.000Z",
    "value": 3234.9998499999997,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2021-02",
    "Date (Year)": "2021",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5SYB8",
    "displayName": "BASF (DE000BASF111)"
  },
  {
    "date": "2021-03-01T00:00:00.000Z",
    "value": 3457.5001,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2021-03",
    "Date (Year)": "2021",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5SYB8",
    "displayName": "BASF (DE000BASF111)"
  },
  {
    "date": "2021-04-01T00:00:00.000Z",
    "value": 3575,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2021-04",
    "Date (Year)": "2021",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5SYB8",
    "displayName": "BASF (DE000BASF111)"
  },
  {
    "date": "2021-05-01T00:00:00.000Z",
    "value": 3372.4998499999997,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2021-05",
    "Date (Year)": "2021",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5SYB8",
    "displayName": "BASF (DE000BASF111)"
  },
  {
    "date": "2021-06-01T00:00:00.000Z",
    "value": 3391.9998,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2021-06",
    "Date (Year)": "2021",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5SYB8",
    "displayName": "BASF (DE000BASF111)"
  },
  {
    "date": "2021-07-01T00:00:00.000Z",
    "value": 3359.0000000000005,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2021-07",
    "Date (Year)": "2021",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5SYB8",
    "displayName": "BASF (DE000BASF111)"
  },
  {
    "date": "2021-08-01T00:00:00.000Z",
    "value": 3318.99985,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2021-08",
    "Date (Year)": "2021",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5SYB8",
    "displayName": "BASF (DE000BASF111)"
  },
  {
    "date": "2021-09-01T00:00:00.000Z",
    "value": 3244.4999500000004,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2021-09",
    "Date (Year)": "2021",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5SYB8",
    "displayName": "BASF (DE000BASF111)"
  },
  {
    "date": "2021-10-01T00:00:00.000Z",
    "value": 3249.4999,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2021-10",
    "Date (Year)": "2021",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5SYB8",
    "displayName": "BASF (DE000BASF111)"
  },
  {
    "date": "2021-11-01T00:00:00.000Z",
    "value": 3154.5,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2021-11",
    "Date (Year)": "2021",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5SYB8",
    "displayName": "BASF (DE000BASF111)"
  },
  {
    "date": "2021-12-01T00:00:00.000Z",
    "value": 2914.99995,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2021-12",
    "Date (Year)": "2021",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5SYB8",
    "displayName": "BASF (DE000BASF111)"
  },
  {
    "date": "2022-01-01T00:00:00.000Z",
    "value": 3085.00005,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2022-01",
    "Date (Year)": "2022",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5SYB8",
    "displayName": "BASF (DE000BASF111)"
  },
  {
    "date": "2022-02-01T00:00:00.000Z",
    "value": 3406.49985,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2022-02",
    "Date (Year)": "2022",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5SYB8",
    "displayName": "BASF (DE000BASF111)"
  },
  {
    "date": "2022-03-01T00:00:00.000Z",
    "value": 2795.0001,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2022-03",
    "Date (Year)": "2022",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5SYB8",
    "displayName": "BASF (DE000BASF111)"
  },
  {
    "date": "2022-04-01T00:00:00.000Z",
    "value": 2613.5,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2022-04",
    "Date (Year)": "2022",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5SYB8",
    "displayName": "BASF (DE000BASF111)"
  },
  {
    "date": "2022-05-01T00:00:00.000Z",
    "value": 2517.4999,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2022-05",
    "Date (Year)": "2022",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5SYB8",
    "displayName": "BASF (DE000BASF111)"
  },
  {
    "date": "2022-06-01T00:00:00.000Z",
    "value": 2596.99995,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2022-06",
    "Date (Year)": "2022",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5SYB8",
    "displayName": "BASF (DE000BASF111)"
  },
  {
    "date": "2022-07-01T00:00:00.000Z",
    "value": 2087.0000999999997,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2022-07",
    "Date (Year)": "2022",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5SYB8",
    "displayName": "BASF (DE000BASF111)"
  },
  {
    "date": "2022-08-01T00:00:00.000Z",
    "value": 2174,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2022-08",
    "Date (Year)": "2022",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5SYB8",
    "displayName": "BASF (DE000BASF111)"
  },
  {
    "date": "2022-09-01T00:00:00.000Z",
    "value": 2090.74995,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2022-09",
    "Date (Year)": "2022",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5SYB8",
    "displayName": "BASF (DE000BASF111)"
  },
  {
    "date": "2022-10-01T00:00:00.000Z",
    "value": 1970.0001,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2022-10",
    "Date (Year)": "2022",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5SYB8",
    "displayName": "BASF (DE000BASF111)"
  },
  {
    "date": "2022-11-01T00:00:00.000Z",
    "value": 2283.4999,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2022-11",
    "Date (Year)": "2022",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5SYB8",
    "displayName": "BASF (DE000BASF111)"
  },
  {
    "date": "2022-12-01T00:00:00.000Z",
    "value": 2397.7501,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2022-12",
    "Date (Year)": "2022",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5SYB8",
    "displayName": "BASF (DE000BASF111)"
  },
  {
    "date": "2023-01-01T00:00:00.000Z",
    "value": 2323.7499,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2023-01",
    "Date (Year)": "2023",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5SYB8",
    "displayName": "BASF (DE000BASF111)"
  },
  {
    "date": "2023-02-01T00:00:00.000Z",
    "value": 2641,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2023-02",
    "Date (Year)": "2023",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5SYB8",
    "displayName": "BASF (DE000BASF111)"
  },
  {
    "date": "2023-03-01T00:00:00.000Z",
    "value": 2434.25005,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2023-03",
    "Date (Year)": "2023",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5SYB8",
    "displayName": "BASF (DE000BASF111)"
  },
  {
    "date": "2023-04-01T00:00:00.000Z",
    "value": 2424.74995,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2023-04",
    "Date (Year)": "2023",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5SYB8",
    "displayName": "BASF (DE000BASF111)"
  },
  {
    "date": "2023-05-01T00:00:00.000Z",
    "value": 2346.5,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2023-05",
    "Date (Year)": "2023",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5SYB8",
    "displayName": "BASF (DE000BASF111)"
  },
  {
    "date": "2023-06-01T00:00:00.000Z",
    "value": 2239.7499,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2023-06",
    "Date (Year)": "2023",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5SYB8",
    "displayName": "BASF (DE000BASF111)"
  },
  {
    "date": "2023-07-01T00:00:00.000Z",
    "value": 2225.5,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2023-07",
    "Date (Year)": "2023",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5SYB8",
    "displayName": "BASF (DE000BASF111)"
  },
  {
    "date": "2023-08-01T00:00:00.000Z",
    "value": 2435.5,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2023-08",
    "Date (Year)": "2023",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5SYB8",
    "displayName": "BASF (DE000BASF111)"
  },
  {
    "date": "2023-09-01T00:00:00.000Z",
    "value": 2345,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2023-09",
    "Date (Year)": "2023",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5SYB8",
    "displayName": "BASF (DE000BASF111)"
  },
  {
    "date": "2023-10-01T00:00:00.000Z",
    "value": 2130,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2023-10",
    "Date (Year)": "2023",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5SYB8",
    "displayName": "BASF (DE000BASF111)"
  },
  {
    "date": "2023-11-01T00:00:00.000Z",
    "value": 2117.5,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2023-11",
    "Date (Year)": "2023",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5SYB8",
    "displayName": "BASF (DE000BASF111)"
  },
  {
    "date": "2023-12-01T00:00:00.000Z",
    "value": 2162,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2023-12",
    "Date (Year)": "2023",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5SYB8",
    "displayName": "BASF (DE000BASF111)"
  },
  {
    "date": "2012-09-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2012-09",
    "Date (Year)": "2012",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5N8V8",
    "displayName": "Apple (US0378331005)"
  },
  {
    "date": "2012-10-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2012-10",
    "Date (Year)": "2012",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5N8V8",
    "displayName": "Apple (US0378331005)"
  },
  {
    "date": "2012-11-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2012-11",
    "Date (Year)": "2012",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5N8V8",
    "displayName": "Apple (US0378331005)"
  },
  {
    "date": "2012-12-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2012-12",
    "Date (Year)": "2012",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5N8V8",
    "displayName": "Apple (US0378331005)"
  },
  {
    "date": "2013-01-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2013-01",
    "Date (Year)": "2013",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5N8V8",
    "displayName": "Apple (US0378331005)"
  },
  {
    "date": "2013-02-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2013-02",
    "Date (Year)": "2013",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5N8V8",
    "displayName": "Apple (US0378331005)"
  },
  {
    "date": "2013-03-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2013-03",
    "Date (Year)": "2013",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5N8V8",
    "displayName": "Apple (US0378331005)"
  },
  {
    "date": "2013-04-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2013-04",
    "Date (Year)": "2013",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5N8V8",
    "displayName": "Apple (US0378331005)"
  },
  {
    "date": "2013-05-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2013-05",
    "Date (Year)": "2013",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5N8V8",
    "displayName": "Apple (US0378331005)"
  },
  {
    "date": "2013-06-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2013-06",
    "Date (Year)": "2013",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5N8V8",
    "displayName": "Apple (US0378331005)"
  },
  {
    "date": "2013-07-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2013-07",
    "Date (Year)": "2013",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5N8V8",
    "displayName": "Apple (US0378331005)"
  },
  {
    "date": "2013-08-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2013-08",
    "Date (Year)": "2013",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5N8V8",
    "displayName": "Apple (US0378331005)"
  },
  {
    "date": "2013-09-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2013-09",
    "Date (Year)": "2013",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5N8V8",
    "displayName": "Apple (US0378331005)"
  },
  {
    "date": "2013-10-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2013-10",
    "Date (Year)": "2013",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5N8V8",
    "displayName": "Apple (US0378331005)"
  },
  {
    "date": "2013-11-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2013-11",
    "Date (Year)": "2013",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5N8V8",
    "displayName": "Apple (US0378331005)"
  },
  {
    "date": "2013-12-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2013-12",
    "Date (Year)": "2013",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5N8V8",
    "displayName": "Apple (US0378331005)"
  },
  {
    "date": "2014-01-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2014-01",
    "Date (Year)": "2014",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5N8V8",
    "displayName": "Apple (US0378331005)"
  },
  {
    "date": "2014-02-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2014-02",
    "Date (Year)": "2014",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5N8V8",
    "displayName": "Apple (US0378331005)"
  },
  {
    "date": "2014-03-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2014-03",
    "Date (Year)": "2014",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5N8V8",
    "displayName": "Apple (US0378331005)"
  },
  {
    "date": "2014-04-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2014-04",
    "Date (Year)": "2014",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5N8V8",
    "displayName": "Apple (US0378331005)"
  },
  {
    "date": "2014-05-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2014-05",
    "Date (Year)": "2014",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5N8V8",
    "displayName": "Apple (US0378331005)"
  },
  {
    "date": "2014-06-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2014-06",
    "Date (Year)": "2014",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5N8V8",
    "displayName": "Apple (US0378331005)"
  },
  {
    "date": "2014-07-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2014-07",
    "Date (Year)": "2014",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5N8V8",
    "displayName": "Apple (US0378331005)"
  },
  {
    "date": "2014-08-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2014-08",
    "Date (Year)": "2014",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5N8V8",
    "displayName": "Apple (US0378331005)"
  },
  {
    "date": "2014-09-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2014-09",
    "Date (Year)": "2014",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5N8V8",
    "displayName": "Apple (US0378331005)"
  },
  {
    "date": "2014-10-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2014-10",
    "Date (Year)": "2014",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5N8V8",
    "displayName": "Apple (US0378331005)"
  },
  {
    "date": "2014-11-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2014-11",
    "Date (Year)": "2014",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5N8V8",
    "displayName": "Apple (US0378331005)"
  },
  {
    "date": "2014-12-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2014-12",
    "Date (Year)": "2014",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5N8V8",
    "displayName": "Apple (US0378331005)"
  },
  {
    "date": "2015-01-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2015-01",
    "Date (Year)": "2015",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5N8V8",
    "displayName": "Apple (US0378331005)"
  },
  {
    "date": "2015-02-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2015-02",
    "Date (Year)": "2015",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5N8V8",
    "displayName": "Apple (US0378331005)"
  },
  {
    "date": "2015-03-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2015-03",
    "Date (Year)": "2015",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5N8V8",
    "displayName": "Apple (US0378331005)"
  },
  {
    "date": "2015-04-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2015-04",
    "Date (Year)": "2015",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5N8V8",
    "displayName": "Apple (US0378331005)"
  },
  {
    "date": "2015-05-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2015-05",
    "Date (Year)": "2015",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5N8V8",
    "displayName": "Apple (US0378331005)"
  },
  {
    "date": "2015-06-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2015-06",
    "Date (Year)": "2015",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5N8V8",
    "displayName": "Apple (US0378331005)"
  },
  {
    "date": "2015-07-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2015-07",
    "Date (Year)": "2015",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5N8V8",
    "displayName": "Apple (US0378331005)"
  },
  {
    "date": "2015-08-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2015-08",
    "Date (Year)": "2015",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5N8V8",
    "displayName": "Apple (US0378331005)"
  },
  {
    "date": "2015-09-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2015-09",
    "Date (Year)": "2015",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5N8V8",
    "displayName": "Apple (US0378331005)"
  },
  {
    "date": "2015-10-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2015-10",
    "Date (Year)": "2015",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5N8V8",
    "displayName": "Apple (US0378331005)"
  },
  {
    "date": "2015-11-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2015-11",
    "Date (Year)": "2015",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5N8V8",
    "displayName": "Apple (US0378331005)"
  },
  {
    "date": "2015-12-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2015-12",
    "Date (Year)": "2015",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5N8V8",
    "displayName": "Apple (US0378331005)"
  },
  {
    "date": "2016-01-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2016-01",
    "Date (Year)": "2016",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5N8V8",
    "displayName": "Apple (US0378331005)"
  },
  {
    "date": "2016-02-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2016-02",
    "Date (Year)": "2016",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5N8V8",
    "displayName": "Apple (US0378331005)"
  },
  {
    "date": "2016-03-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2016-03",
    "Date (Year)": "2016",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5N8V8",
    "displayName": "Apple (US0378331005)"
  },
  {
    "date": "2016-04-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2016-04",
    "Date (Year)": "2016",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5N8V8",
    "displayName": "Apple (US0378331005)"
  },
  {
    "date": "2016-05-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2016-05",
    "Date (Year)": "2016",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5N8V8",
    "displayName": "Apple (US0378331005)"
  },
  {
    "date": "2016-06-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2016-06",
    "Date (Year)": "2016",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5N8V8",
    "displayName": "Apple (US0378331005)"
  },
  {
    "date": "2016-07-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2016-07",
    "Date (Year)": "2016",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5N8V8",
    "displayName": "Apple (US0378331005)"
  },
  {
    "date": "2016-08-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2016-08",
    "Date (Year)": "2016",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5N8V8",
    "displayName": "Apple (US0378331005)"
  },
  {
    "date": "2016-09-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2016-09",
    "Date (Year)": "2016",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5N8V8",
    "displayName": "Apple (US0378331005)"
  },
  {
    "date": "2016-10-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2016-10",
    "Date (Year)": "2016",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5N8V8",
    "displayName": "Apple (US0378331005)"
  },
  {
    "date": "2016-11-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2016-11",
    "Date (Year)": "2016",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5N8V8",
    "displayName": "Apple (US0378331005)"
  },
  {
    "date": "2016-12-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2016-12",
    "Date (Year)": "2016",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5N8V8",
    "displayName": "Apple (US0378331005)"
  },
  {
    "date": "2017-01-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2017-01",
    "Date (Year)": "2017",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5N8V8",
    "displayName": "Apple (US0378331005)"
  },
  {
    "date": "2017-02-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2017-02",
    "Date (Year)": "2017",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5N8V8",
    "displayName": "Apple (US0378331005)"
  },
  {
    "date": "2017-03-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2017-03",
    "Date (Year)": "2017",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5N8V8",
    "displayName": "Apple (US0378331005)"
  },
  {
    "date": "2017-04-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2017-04",
    "Date (Year)": "2017",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5N8V8",
    "displayName": "Apple (US0378331005)"
  },
  {
    "date": "2017-05-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2017-05",
    "Date (Year)": "2017",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5N8V8",
    "displayName": "Apple (US0378331005)"
  },
  {
    "date": "2017-06-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2017-06",
    "Date (Year)": "2017",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5N8V8",
    "displayName": "Apple (US0378331005)"
  },
  {
    "date": "2017-07-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2017-07",
    "Date (Year)": "2017",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5N8V8",
    "displayName": "Apple (US0378331005)"
  },
  {
    "date": "2017-08-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2017-08",
    "Date (Year)": "2017",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5N8V8",
    "displayName": "Apple (US0378331005)"
  },
  {
    "date": "2017-09-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2017-09",
    "Date (Year)": "2017",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5N8V8",
    "displayName": "Apple (US0378331005)"
  },
  {
    "date": "2017-10-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2017-10",
    "Date (Year)": "2017",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5N8V8",
    "displayName": "Apple (US0378331005)"
  },
  {
    "date": "2017-11-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2017-11",
    "Date (Year)": "2017",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5N8V8",
    "displayName": "Apple (US0378331005)"
  },
  {
    "date": "2017-12-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2017-12",
    "Date (Year)": "2017",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5N8V8",
    "displayName": "Apple (US0378331005)"
  },
  {
    "date": "2018-01-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2018-01",
    "Date (Year)": "2018",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5N8V8",
    "displayName": "Apple (US0378331005)"
  },
  {
    "date": "2018-02-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2018-02",
    "Date (Year)": "2018",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5N8V8",
    "displayName": "Apple (US0378331005)"
  },
  {
    "date": "2018-03-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2018-03",
    "Date (Year)": "2018",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5N8V8",
    "displayName": "Apple (US0378331005)"
  },
  {
    "date": "2018-04-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2018-04",
    "Date (Year)": "2018",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5N8V8",
    "displayName": "Apple (US0378331005)"
  },
  {
    "date": "2018-05-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2018-05",
    "Date (Year)": "2018",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5N8V8",
    "displayName": "Apple (US0378331005)"
  },
  {
    "date": "2018-06-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2018-06",
    "Date (Year)": "2018",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5N8V8",
    "displayName": "Apple (US0378331005)"
  },
  {
    "date": "2018-07-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2018-07",
    "Date (Year)": "2018",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5N8V8",
    "displayName": "Apple (US0378331005)"
  },
  {
    "date": "2018-08-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2018-08",
    "Date (Year)": "2018",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5N8V8",
    "displayName": "Apple (US0378331005)"
  },
  {
    "date": "2018-09-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2018-09",
    "Date (Year)": "2018",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5N8V8",
    "displayName": "Apple (US0378331005)"
  },
  {
    "date": "2018-10-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2018-10",
    "Date (Year)": "2018",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5N8V8",
    "displayName": "Apple (US0378331005)"
  },
  {
    "date": "2018-11-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2018-11",
    "Date (Year)": "2018",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5N8V8",
    "displayName": "Apple (US0378331005)"
  },
  {
    "date": "2018-12-01T00:00:00.000Z",
    "value": 15723.2,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2018-12",
    "Date (Year)": "2018",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5N8V8",
    "displayName": "Apple (US0378331005)"
  },
  {
    "date": "2019-01-01T00:00:00.000Z",
    "value": 13778.16,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2019-01",
    "Date (Year)": "2019",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5N8V8",
    "displayName": "Apple (US0378331005)"
  },
  {
    "date": "2019-02-01T00:00:00.000Z",
    "value": 14516.6,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2019-02",
    "Date (Year)": "2019",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5N8V8",
    "displayName": "Apple (US0378331005)"
  },
  {
    "date": "2019-03-01T00:00:00.000Z",
    "value": 15370.279999999999,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2019-03",
    "Date (Year)": "2019",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5N8V8",
    "displayName": "Apple (US0378331005)"
  },
  {
    "date": "2019-04-01T00:00:00.000Z",
    "value": 17020.28,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2019-04",
    "Date (Year)": "2019",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5N8V8",
    "displayName": "Apple (US0378331005)"
  },
  {
    "date": "2019-05-01T00:00:00.000Z",
    "value": 18766.28,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2019-05",
    "Date (Year)": "2019",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5N8V8",
    "displayName": "Apple (US0378331005)"
  },
  {
    "date": "2019-06-01T00:00:00.000Z",
    "value": 15700.84,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2019-06",
    "Date (Year)": "2019",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5N8V8",
    "displayName": "Apple (US0378331005)"
  },
  {
    "date": "2019-07-01T00:00:00.000Z",
    "value": 17760.16,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2019-07",
    "Date (Year)": "2019",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5N8V8",
    "displayName": "Apple (US0378331005)"
  },
  {
    "date": "2019-08-01T00:00:00.000Z",
    "value": 18885.56,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2019-08",
    "Date (Year)": "2019",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5N8V8",
    "displayName": "Apple (US0378331005)"
  },
  {
    "date": "2019-09-01T00:00:00.000Z",
    "value": 18916.28,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2019-09",
    "Date (Year)": "2019",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5N8V8",
    "displayName": "Apple (US0378331005)"
  },
  {
    "date": "2019-10-01T00:00:00.000Z",
    "value": 20609.280000000002,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2019-10",
    "Date (Year)": "2019",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5N8V8",
    "displayName": "Apple (US0378331005)"
  },
  {
    "date": "2019-11-01T00:00:00.000Z",
    "value": 22967.96,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2019-11",
    "Date (Year)": "2019",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5N8V8",
    "displayName": "Apple (US0378331005)"
  },
  {
    "date": "2019-12-01T00:00:00.000Z",
    "value": 24334.36,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2019-12",
    "Date (Year)": "2019",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5N8V8",
    "displayName": "Apple (US0378331005)"
  },
  {
    "date": "2020-01-01T00:00:00.000Z",
    "value": 26138.52,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2020-01",
    "Date (Year)": "2020",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5N8V8",
    "displayName": "Apple (US0378331005)"
  },
  {
    "date": "2020-02-01T00:00:00.000Z",
    "value": 28005.8,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2020-02",
    "Date (Year)": "2020",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5N8V8",
    "displayName": "Apple (US0378331005)"
  },
  {
    "date": "2020-03-01T00:00:00.000Z",
    "value": 24902.96,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2020-03",
    "Date (Year)": "2020",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5N8V8",
    "displayName": "Apple (US0378331005)"
  },
  {
    "date": "2020-04-01T00:00:00.000Z",
    "value": 22030,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2020-04",
    "Date (Year)": "2020",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5N8V8",
    "displayName": "Apple (US0378331005)"
  },
  {
    "date": "2020-05-01T00:00:00.000Z",
    "value": 26579.64,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2020-05",
    "Date (Year)": "2020",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5N8V8",
    "displayName": "Apple (US0378331005)"
  },
  {
    "date": "2020-06-01T00:00:00.000Z",
    "value": 28952.879999999997,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2020-06",
    "Date (Year)": "2020",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5N8V8",
    "displayName": "Apple (US0378331005)"
  },
  {
    "date": "2020-07-01T00:00:00.000Z",
    "value": 32510.719999999998,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2020-07",
    "Date (Year)": "2020",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5N8V8",
    "displayName": "Apple (US0378331005)"
  },
  {
    "date": "2020-08-01T00:00:00.000Z",
    "value": 35874.4,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2020-08",
    "Date (Year)": "2020",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5N8V8",
    "displayName": "Apple (US0378331005)"
  },
  {
    "date": "2020-09-01T00:00:00.000Z",
    "value": 44775.159999999996,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2020-09",
    "Date (Year)": "2020",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5N8V8",
    "displayName": "Apple (US0378331005)"
  },
  {
    "date": "2020-10-01T00:00:00.000Z",
    "value": 39751.52,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2020-10",
    "Date (Year)": "2020",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5N8V8",
    "displayName": "Apple (US0378331005)"
  },
  {
    "date": "2020-11-01T00:00:00.000Z",
    "value": 37223.44,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2020-11",
    "Date (Year)": "2020",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5N8V8",
    "displayName": "Apple (US0378331005)"
  },
  {
    "date": "2020-12-01T00:00:00.000Z",
    "value": 41016.04,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2020-12",
    "Date (Year)": "2020",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5N8V8",
    "displayName": "Apple (US0378331005)"
  },
  {
    "date": "2021-01-01T00:00:00.000Z",
    "value": 43253.2,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2021-01",
    "Date (Year)": "2021",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5N8V8",
    "displayName": "Apple (US0378331005)"
  },
  {
    "date": "2021-02-01T00:00:00.000Z",
    "value": 44402.52,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2021-02",
    "Date (Year)": "2021",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5N8V8",
    "displayName": "Apple (US0378331005)"
  },
  {
    "date": "2021-03-01T00:00:00.000Z",
    "value": 42409.36,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2021-03",
    "Date (Year)": "2021",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5N8V8",
    "displayName": "Apple (US0378331005)"
  },
  {
    "date": "2021-04-01T00:00:00.000Z",
    "value": 41886.6,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2021-04",
    "Date (Year)": "2021",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5N8V8",
    "displayName": "Apple (US0378331005)"
  },
  {
    "date": "2021-05-01T00:00:00.000Z",
    "value": 43522.6,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2021-05",
    "Date (Year)": "2021",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5N8V8",
    "displayName": "Apple (US0378331005)"
  },
  {
    "date": "2021-06-01T00:00:00.000Z",
    "value": 40664.2,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2021-06",
    "Date (Year)": "2021",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5N8V8",
    "displayName": "Apple (US0378331005)"
  },
  {
    "date": "2021-07-01T00:00:00.000Z",
    "value": 46203.28,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2021-07",
    "Date (Year)": "2021",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5N8V8",
    "displayName": "Apple (US0378331005)"
  },
  {
    "date": "2021-08-01T00:00:00.000Z",
    "value": 49065.68,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2021-08",
    "Date (Year)": "2021",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5N8V8",
    "displayName": "Apple (US0378331005)"
  },
  {
    "date": "2021-09-01T00:00:00.000Z",
    "value": 51623.92,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2021-09",
    "Date (Year)": "2021",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5N8V8",
    "displayName": "Apple (US0378331005)"
  },
  {
    "date": "2021-10-01T00:00:00.000Z",
    "value": 49189.64,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2021-10",
    "Date (Year)": "2021",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5N8V8",
    "displayName": "Apple (US0378331005)"
  },
  {
    "date": "2021-11-01T00:00:00.000Z",
    "value": 51463.12,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2021-11",
    "Date (Year)": "2021",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5N8V8",
    "displayName": "Apple (US0378331005)"
  },
  {
    "date": "2021-12-01T00:00:00.000Z",
    "value": 58253.48,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2021-12",
    "Date (Year)": "2021",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5N8V8",
    "displayName": "Apple (US0378331005)"
  },
  {
    "date": "2022-01-01T00:00:00.000Z",
    "value": 62712.36,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2022-01",
    "Date (Year)": "2022",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5N8V8",
    "displayName": "Apple (US0378331005)"
  },
  {
    "date": "2022-02-01T00:00:00.000Z",
    "value": 62028.4,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2022-02",
    "Date (Year)": "2022",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5N8V8",
    "displayName": "Apple (US0378331005)"
  },
  {
    "date": "2022-03-01T00:00:00.000Z",
    "value": 58484.159999999996,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2022-03",
    "Date (Year)": "2022",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5N8V8",
    "displayName": "Apple (US0378331005)"
  },
  {
    "date": "2022-04-01T00:00:00.000Z",
    "value": 63087.24,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2022-04",
    "Date (Year)": "2022",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5N8V8",
    "displayName": "Apple (US0378331005)"
  },
  {
    "date": "2022-05-01T00:00:00.000Z",
    "value": 59829.240000000005,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2022-05",
    "Date (Year)": "2022",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5N8V8",
    "displayName": "Apple (US0378331005)"
  },
  {
    "date": "2022-06-01T00:00:00.000Z",
    "value": 55530.240000000005,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2022-06",
    "Date (Year)": "2022",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5N8V8",
    "displayName": "Apple (US0378331005)"
  },
  {
    "date": "2022-07-01T00:00:00.000Z",
    "value": 53306.479999999996,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2022-07",
    "Date (Year)": "2022",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5N8V8",
    "displayName": "Apple (US0378331005)"
  },
  {
    "date": "2022-08-01T00:00:00.000Z",
    "value": 63133.00000000001,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2022-08",
    "Date (Year)": "2022",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5N8V8",
    "displayName": "Apple (US0378331005)"
  },
  {
    "date": "2022-09-01T00:00:00.000Z",
    "value": 63158.72000000001,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2022-09",
    "Date (Year)": "2022",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5N8V8",
    "displayName": "Apple (US0378331005)"
  },
  {
    "date": "2022-10-01T00:00:00.000Z",
    "value": 56709.079999999994,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2022-10",
    "Date (Year)": "2022",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5N8V8",
    "displayName": "Apple (US0378331005)"
  },
  {
    "date": "2022-11-01T00:00:00.000Z",
    "value": 60581.079999999994,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2022-11",
    "Date (Year)": "2022",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5N8V8",
    "displayName": "Apple (US0378331005)"
  },
  {
    "date": "2022-12-01T00:00:00.000Z",
    "value": 56747.64,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2022-12",
    "Date (Year)": "2022",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5N8V8",
    "displayName": "Apple (US0378331005)"
  },
  {
    "date": "2023-01-01T00:00:00.000Z",
    "value": 48726.799999999996,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2023-01",
    "Date (Year)": "2023",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5N8V8",
    "displayName": "Apple (US0378331005)"
  },
  {
    "date": "2023-02-01T00:00:00.000Z",
    "value": 53398.2,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2023-02",
    "Date (Year)": "2023",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5N8V8",
    "displayName": "Apple (US0378331005)"
  },
  {
    "date": "2023-03-01T00:00:00.000Z",
    "value": 54402.840000000004,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2023-03",
    "Date (Year)": "2023",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5N8V8",
    "displayName": "Apple (US0378331005)"
  },
  {
    "date": "2023-04-01T00:00:00.000Z",
    "value": 60652.880000000005,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2023-04",
    "Date (Year)": "2023",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5N8V8",
    "displayName": "Apple (US0378331005)"
  },
  {
    "date": "2023-05-01T00:00:00.000Z",
    "value": 61775.8,
    "incomeFromCapital": 6423.920219784079,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2023-05",
    "Date (Year)": "2023",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5N8V8",
    "displayName": "Apple (US0378331005)"
  },
  {
    "date": "2023-05-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 6423.920219784079,
    "inflows": 0,
    "inflowType": "incomeFromCapital",
    "inflowValue": 6423.920219784079,
    "Date (Month)": "2023-05",
    "Date (Year)": "2023",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5N8V8",
    "displayName": "Apple (US0378331005)"
  },
  {
    "date": "2023-06-01T00:00:00.000Z",
    "value": 57240.904,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2023-06",
    "Date (Year)": "2023",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5N8V8",
    "displayName": "Apple (US0378331005)"
  },
  {
    "date": "2023-07-01T00:00:00.000Z",
    "value": 60693.74,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2023-07",
    "Date (Year)": "2023",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5N8V8",
    "displayName": "Apple (US0378331005)"
  },
  {
    "date": "2023-08-01T00:00:00.000Z",
    "value": 60626.624,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2023-08",
    "Date (Year)": "2023",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5N8V8",
    "displayName": "Apple (US0378331005)"
  },
  {
    "date": "2023-09-01T00:00:00.000Z",
    "value": 59402.794,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2023-09",
    "Date (Year)": "2023",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5N8V8",
    "displayName": "Apple (US0378331005)"
  },
  {
    "date": "2023-10-01T00:00:00.000Z",
    "value": 54947.502,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2023-10",
    "Date (Year)": "2023",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5N8V8",
    "displayName": "Apple (US0378331005)"
  },
  {
    "date": "2023-11-01T00:00:00.000Z",
    "value": 56135.326,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2023-11",
    "Date (Year)": "2023",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5N8V8",
    "displayName": "Apple (US0378331005)"
  },
  {
    "date": "2023-12-01T00:00:00.000Z",
    "value": 59789.986000000004,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2023-12",
    "Date (Year)": "2023",
    "category": "stocks",
    "accountId": "SKxazU1TIynqfmDvfUR1g",
    "accountName": "DKB Depot",
    "assetId": "BBG001S5N8V8",
    "displayName": "Apple (US0378331005)"
  },
  {
    "date": "2012-09-01T00:00:00.000Z",
    "value": 400000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2012-09",
    "Date (Year)": "2012",
    "category": "realEstate",
    "accountId": "0LBYVRlkkfRWVNk0y2c4a",
    "accountName": "Real estate asset",
    "assetId": "0LBYVRlkkfRWVNk0y2c4a",
    "displayName": "Real estate asset"
  },
  {
    "date": "2012-10-01T00:00:00.000Z",
    "value": 400000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2012-10",
    "Date (Year)": "2012",
    "category": "realEstate",
    "accountId": "0LBYVRlkkfRWVNk0y2c4a",
    "accountName": "Real estate asset",
    "assetId": "0LBYVRlkkfRWVNk0y2c4a",
    "displayName": "Real estate asset"
  },
  {
    "date": "2012-11-01T00:00:00.000Z",
    "value": 400000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2012-11",
    "Date (Year)": "2012",
    "category": "realEstate",
    "accountId": "0LBYVRlkkfRWVNk0y2c4a",
    "accountName": "Real estate asset",
    "assetId": "0LBYVRlkkfRWVNk0y2c4a",
    "displayName": "Real estate asset"
  },
  {
    "date": "2012-12-01T00:00:00.000Z",
    "value": 400000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2012-12",
    "Date (Year)": "2012",
    "category": "realEstate",
    "accountId": "0LBYVRlkkfRWVNk0y2c4a",
    "accountName": "Real estate asset",
    "assetId": "0LBYVRlkkfRWVNk0y2c4a",
    "displayName": "Real estate asset"
  },
  {
    "date": "2013-01-01T00:00:00.000Z",
    "value": 400000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2013-01",
    "Date (Year)": "2013",
    "category": "realEstate",
    "accountId": "0LBYVRlkkfRWVNk0y2c4a",
    "accountName": "Real estate asset",
    "assetId": "0LBYVRlkkfRWVNk0y2c4a",
    "displayName": "Real estate asset"
  },
  {
    "date": "2013-02-01T00:00:00.000Z",
    "value": 400000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2013-02",
    "Date (Year)": "2013",
    "category": "realEstate",
    "accountId": "0LBYVRlkkfRWVNk0y2c4a",
    "accountName": "Real estate asset",
    "assetId": "0LBYVRlkkfRWVNk0y2c4a",
    "displayName": "Real estate asset"
  },
  {
    "date": "2013-03-01T00:00:00.000Z",
    "value": 400000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2013-03",
    "Date (Year)": "2013",
    "category": "realEstate",
    "accountId": "0LBYVRlkkfRWVNk0y2c4a",
    "accountName": "Real estate asset",
    "assetId": "0LBYVRlkkfRWVNk0y2c4a",
    "displayName": "Real estate asset"
  },
  {
    "date": "2013-04-01T00:00:00.000Z",
    "value": 400000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2013-04",
    "Date (Year)": "2013",
    "category": "realEstate",
    "accountId": "0LBYVRlkkfRWVNk0y2c4a",
    "accountName": "Real estate asset",
    "assetId": "0LBYVRlkkfRWVNk0y2c4a",
    "displayName": "Real estate asset"
  },
  {
    "date": "2013-05-01T00:00:00.000Z",
    "value": 400000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2013-05",
    "Date (Year)": "2013",
    "category": "realEstate",
    "accountId": "0LBYVRlkkfRWVNk0y2c4a",
    "accountName": "Real estate asset",
    "assetId": "0LBYVRlkkfRWVNk0y2c4a",
    "displayName": "Real estate asset"
  },
  {
    "date": "2013-06-01T00:00:00.000Z",
    "value": 400000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2013-06",
    "Date (Year)": "2013",
    "category": "realEstate",
    "accountId": "0LBYVRlkkfRWVNk0y2c4a",
    "accountName": "Real estate asset",
    "assetId": "0LBYVRlkkfRWVNk0y2c4a",
    "displayName": "Real estate asset"
  },
  {
    "date": "2013-07-01T00:00:00.000Z",
    "value": 400000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2013-07",
    "Date (Year)": "2013",
    "category": "realEstate",
    "accountId": "0LBYVRlkkfRWVNk0y2c4a",
    "accountName": "Real estate asset",
    "assetId": "0LBYVRlkkfRWVNk0y2c4a",
    "displayName": "Real estate asset"
  },
  {
    "date": "2013-08-01T00:00:00.000Z",
    "value": 400000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2013-08",
    "Date (Year)": "2013",
    "category": "realEstate",
    "accountId": "0LBYVRlkkfRWVNk0y2c4a",
    "accountName": "Real estate asset",
    "assetId": "0LBYVRlkkfRWVNk0y2c4a",
    "displayName": "Real estate asset"
  },
  {
    "date": "2013-09-01T00:00:00.000Z",
    "value": 400000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2013-09",
    "Date (Year)": "2013",
    "category": "realEstate",
    "accountId": "0LBYVRlkkfRWVNk0y2c4a",
    "accountName": "Real estate asset",
    "assetId": "0LBYVRlkkfRWVNk0y2c4a",
    "displayName": "Real estate asset"
  },
  {
    "date": "2013-10-01T00:00:00.000Z",
    "value": 400000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2013-10",
    "Date (Year)": "2013",
    "category": "realEstate",
    "accountId": "0LBYVRlkkfRWVNk0y2c4a",
    "accountName": "Real estate asset",
    "assetId": "0LBYVRlkkfRWVNk0y2c4a",
    "displayName": "Real estate asset"
  },
  {
    "date": "2013-11-01T00:00:00.000Z",
    "value": 400000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2013-11",
    "Date (Year)": "2013",
    "category": "realEstate",
    "accountId": "0LBYVRlkkfRWVNk0y2c4a",
    "accountName": "Real estate asset",
    "assetId": "0LBYVRlkkfRWVNk0y2c4a",
    "displayName": "Real estate asset"
  },
  {
    "date": "2013-12-01T00:00:00.000Z",
    "value": 400000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2013-12",
    "Date (Year)": "2013",
    "category": "realEstate",
    "accountId": "0LBYVRlkkfRWVNk0y2c4a",
    "accountName": "Real estate asset",
    "assetId": "0LBYVRlkkfRWVNk0y2c4a",
    "displayName": "Real estate asset"
  },
  {
    "date": "2014-01-01T00:00:00.000Z",
    "value": 400000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2014-01",
    "Date (Year)": "2014",
    "category": "realEstate",
    "accountId": "0LBYVRlkkfRWVNk0y2c4a",
    "accountName": "Real estate asset",
    "assetId": "0LBYVRlkkfRWVNk0y2c4a",
    "displayName": "Real estate asset"
  },
  {
    "date": "2014-02-01T00:00:00.000Z",
    "value": 400000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2014-02",
    "Date (Year)": "2014",
    "category": "realEstate",
    "accountId": "0LBYVRlkkfRWVNk0y2c4a",
    "accountName": "Real estate asset",
    "assetId": "0LBYVRlkkfRWVNk0y2c4a",
    "displayName": "Real estate asset"
  },
  {
    "date": "2014-03-01T00:00:00.000Z",
    "value": 400000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2014-03",
    "Date (Year)": "2014",
    "category": "realEstate",
    "accountId": "0LBYVRlkkfRWVNk0y2c4a",
    "accountName": "Real estate asset",
    "assetId": "0LBYVRlkkfRWVNk0y2c4a",
    "displayName": "Real estate asset"
  },
  {
    "date": "2014-04-01T00:00:00.000Z",
    "value": 400000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2014-04",
    "Date (Year)": "2014",
    "category": "realEstate",
    "accountId": "0LBYVRlkkfRWVNk0y2c4a",
    "accountName": "Real estate asset",
    "assetId": "0LBYVRlkkfRWVNk0y2c4a",
    "displayName": "Real estate asset"
  },
  {
    "date": "2014-05-01T00:00:00.000Z",
    "value": 400000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2014-05",
    "Date (Year)": "2014",
    "category": "realEstate",
    "accountId": "0LBYVRlkkfRWVNk0y2c4a",
    "accountName": "Real estate asset",
    "assetId": "0LBYVRlkkfRWVNk0y2c4a",
    "displayName": "Real estate asset"
  },
  {
    "date": "2014-06-01T00:00:00.000Z",
    "value": 400000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2014-06",
    "Date (Year)": "2014",
    "category": "realEstate",
    "accountId": "0LBYVRlkkfRWVNk0y2c4a",
    "accountName": "Real estate asset",
    "assetId": "0LBYVRlkkfRWVNk0y2c4a",
    "displayName": "Real estate asset"
  },
  {
    "date": "2014-07-01T00:00:00.000Z",
    "value": 400000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2014-07",
    "Date (Year)": "2014",
    "category": "realEstate",
    "accountId": "0LBYVRlkkfRWVNk0y2c4a",
    "accountName": "Real estate asset",
    "assetId": "0LBYVRlkkfRWVNk0y2c4a",
    "displayName": "Real estate asset"
  },
  {
    "date": "2014-08-01T00:00:00.000Z",
    "value": 400000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2014-08",
    "Date (Year)": "2014",
    "category": "realEstate",
    "accountId": "0LBYVRlkkfRWVNk0y2c4a",
    "accountName": "Real estate asset",
    "assetId": "0LBYVRlkkfRWVNk0y2c4a",
    "displayName": "Real estate asset"
  },
  {
    "date": "2014-09-01T00:00:00.000Z",
    "value": 400000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2014-09",
    "Date (Year)": "2014",
    "category": "realEstate",
    "accountId": "0LBYVRlkkfRWVNk0y2c4a",
    "accountName": "Real estate asset",
    "assetId": "0LBYVRlkkfRWVNk0y2c4a",
    "displayName": "Real estate asset"
  },
  {
    "date": "2014-10-01T00:00:00.000Z",
    "value": 400000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2014-10",
    "Date (Year)": "2014",
    "category": "realEstate",
    "accountId": "0LBYVRlkkfRWVNk0y2c4a",
    "accountName": "Real estate asset",
    "assetId": "0LBYVRlkkfRWVNk0y2c4a",
    "displayName": "Real estate asset"
  },
  {
    "date": "2014-11-01T00:00:00.000Z",
    "value": 400000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2014-11",
    "Date (Year)": "2014",
    "category": "realEstate",
    "accountId": "0LBYVRlkkfRWVNk0y2c4a",
    "accountName": "Real estate asset",
    "assetId": "0LBYVRlkkfRWVNk0y2c4a",
    "displayName": "Real estate asset"
  },
  {
    "date": "2014-12-01T00:00:00.000Z",
    "value": 400000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2014-12",
    "Date (Year)": "2014",
    "category": "realEstate",
    "accountId": "0LBYVRlkkfRWVNk0y2c4a",
    "accountName": "Real estate asset",
    "assetId": "0LBYVRlkkfRWVNk0y2c4a",
    "displayName": "Real estate asset"
  },
  {
    "date": "2015-01-01T00:00:00.000Z",
    "value": 400000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2015-01",
    "Date (Year)": "2015",
    "category": "realEstate",
    "accountId": "0LBYVRlkkfRWVNk0y2c4a",
    "accountName": "Real estate asset",
    "assetId": "0LBYVRlkkfRWVNk0y2c4a",
    "displayName": "Real estate asset"
  },
  {
    "date": "2015-02-01T00:00:00.000Z",
    "value": 400000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2015-02",
    "Date (Year)": "2015",
    "category": "realEstate",
    "accountId": "0LBYVRlkkfRWVNk0y2c4a",
    "accountName": "Real estate asset",
    "assetId": "0LBYVRlkkfRWVNk0y2c4a",
    "displayName": "Real estate asset"
  },
  {
    "date": "2015-03-01T00:00:00.000Z",
    "value": 400000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2015-03",
    "Date (Year)": "2015",
    "category": "realEstate",
    "accountId": "0LBYVRlkkfRWVNk0y2c4a",
    "accountName": "Real estate asset",
    "assetId": "0LBYVRlkkfRWVNk0y2c4a",
    "displayName": "Real estate asset"
  },
  {
    "date": "2015-04-01T00:00:00.000Z",
    "value": 400000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2015-04",
    "Date (Year)": "2015",
    "category": "realEstate",
    "accountId": "0LBYVRlkkfRWVNk0y2c4a",
    "accountName": "Real estate asset",
    "assetId": "0LBYVRlkkfRWVNk0y2c4a",
    "displayName": "Real estate asset"
  },
  {
    "date": "2015-05-01T00:00:00.000Z",
    "value": 400000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2015-05",
    "Date (Year)": "2015",
    "category": "realEstate",
    "accountId": "0LBYVRlkkfRWVNk0y2c4a",
    "accountName": "Real estate asset",
    "assetId": "0LBYVRlkkfRWVNk0y2c4a",
    "displayName": "Real estate asset"
  },
  {
    "date": "2015-06-01T00:00:00.000Z",
    "value": 400000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2015-06",
    "Date (Year)": "2015",
    "category": "realEstate",
    "accountId": "0LBYVRlkkfRWVNk0y2c4a",
    "accountName": "Real estate asset",
    "assetId": "0LBYVRlkkfRWVNk0y2c4a",
    "displayName": "Real estate asset"
  },
  {
    "date": "2015-07-01T00:00:00.000Z",
    "value": 400000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2015-07",
    "Date (Year)": "2015",
    "category": "realEstate",
    "accountId": "0LBYVRlkkfRWVNk0y2c4a",
    "accountName": "Real estate asset",
    "assetId": "0LBYVRlkkfRWVNk0y2c4a",
    "displayName": "Real estate asset"
  },
  {
    "date": "2015-08-01T00:00:00.000Z",
    "value": 400000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2015-08",
    "Date (Year)": "2015",
    "category": "realEstate",
    "accountId": "0LBYVRlkkfRWVNk0y2c4a",
    "accountName": "Real estate asset",
    "assetId": "0LBYVRlkkfRWVNk0y2c4a",
    "displayName": "Real estate asset"
  },
  {
    "date": "2015-09-01T00:00:00.000Z",
    "value": 400000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2015-09",
    "Date (Year)": "2015",
    "category": "realEstate",
    "accountId": "0LBYVRlkkfRWVNk0y2c4a",
    "accountName": "Real estate asset",
    "assetId": "0LBYVRlkkfRWVNk0y2c4a",
    "displayName": "Real estate asset"
  },
  {
    "date": "2015-10-01T00:00:00.000Z",
    "value": 400000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2015-10",
    "Date (Year)": "2015",
    "category": "realEstate",
    "accountId": "0LBYVRlkkfRWVNk0y2c4a",
    "accountName": "Real estate asset",
    "assetId": "0LBYVRlkkfRWVNk0y2c4a",
    "displayName": "Real estate asset"
  },
  {
    "date": "2015-11-01T00:00:00.000Z",
    "value": 400000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2015-11",
    "Date (Year)": "2015",
    "category": "realEstate",
    "accountId": "0LBYVRlkkfRWVNk0y2c4a",
    "accountName": "Real estate asset",
    "assetId": "0LBYVRlkkfRWVNk0y2c4a",
    "displayName": "Real estate asset"
  },
  {
    "date": "2015-12-01T00:00:00.000Z",
    "value": 400000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2015-12",
    "Date (Year)": "2015",
    "category": "realEstate",
    "accountId": "0LBYVRlkkfRWVNk0y2c4a",
    "accountName": "Real estate asset",
    "assetId": "0LBYVRlkkfRWVNk0y2c4a",
    "displayName": "Real estate asset"
  },
  {
    "date": "2016-01-01T00:00:00.000Z",
    "value": 400000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2016-01",
    "Date (Year)": "2016",
    "category": "realEstate",
    "accountId": "0LBYVRlkkfRWVNk0y2c4a",
    "accountName": "Real estate asset",
    "assetId": "0LBYVRlkkfRWVNk0y2c4a",
    "displayName": "Real estate asset"
  },
  {
    "date": "2016-02-01T00:00:00.000Z",
    "value": 400000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2016-02",
    "Date (Year)": "2016",
    "category": "realEstate",
    "accountId": "0LBYVRlkkfRWVNk0y2c4a",
    "accountName": "Real estate asset",
    "assetId": "0LBYVRlkkfRWVNk0y2c4a",
    "displayName": "Real estate asset"
  },
  {
    "date": "2016-03-01T00:00:00.000Z",
    "value": 400000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2016-03",
    "Date (Year)": "2016",
    "category": "realEstate",
    "accountId": "0LBYVRlkkfRWVNk0y2c4a",
    "accountName": "Real estate asset",
    "assetId": "0LBYVRlkkfRWVNk0y2c4a",
    "displayName": "Real estate asset"
  },
  {
    "date": "2016-04-01T00:00:00.000Z",
    "value": 400000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2016-04",
    "Date (Year)": "2016",
    "category": "realEstate",
    "accountId": "0LBYVRlkkfRWVNk0y2c4a",
    "accountName": "Real estate asset",
    "assetId": "0LBYVRlkkfRWVNk0y2c4a",
    "displayName": "Real estate asset"
  },
  {
    "date": "2016-05-01T00:00:00.000Z",
    "value": 400000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2016-05",
    "Date (Year)": "2016",
    "category": "realEstate",
    "accountId": "0LBYVRlkkfRWVNk0y2c4a",
    "accountName": "Real estate asset",
    "assetId": "0LBYVRlkkfRWVNk0y2c4a",
    "displayName": "Real estate asset"
  },
  {
    "date": "2016-06-01T00:00:00.000Z",
    "value": 400000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2016-06",
    "Date (Year)": "2016",
    "category": "realEstate",
    "accountId": "0LBYVRlkkfRWVNk0y2c4a",
    "accountName": "Real estate asset",
    "assetId": "0LBYVRlkkfRWVNk0y2c4a",
    "displayName": "Real estate asset"
  },
  {
    "date": "2016-07-01T00:00:00.000Z",
    "value": 400000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2016-07",
    "Date (Year)": "2016",
    "category": "realEstate",
    "accountId": "0LBYVRlkkfRWVNk0y2c4a",
    "accountName": "Real estate asset",
    "assetId": "0LBYVRlkkfRWVNk0y2c4a",
    "displayName": "Real estate asset"
  },
  {
    "date": "2016-08-01T00:00:00.000Z",
    "value": 400000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2016-08",
    "Date (Year)": "2016",
    "category": "realEstate",
    "accountId": "0LBYVRlkkfRWVNk0y2c4a",
    "accountName": "Real estate asset",
    "assetId": "0LBYVRlkkfRWVNk0y2c4a",
    "displayName": "Real estate asset"
  },
  {
    "date": "2016-09-01T00:00:00.000Z",
    "value": 400000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2016-09",
    "Date (Year)": "2016",
    "category": "realEstate",
    "accountId": "0LBYVRlkkfRWVNk0y2c4a",
    "accountName": "Real estate asset",
    "assetId": "0LBYVRlkkfRWVNk0y2c4a",
    "displayName": "Real estate asset"
  },
  {
    "date": "2016-10-01T00:00:00.000Z",
    "value": 400000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2016-10",
    "Date (Year)": "2016",
    "category": "realEstate",
    "accountId": "0LBYVRlkkfRWVNk0y2c4a",
    "accountName": "Real estate asset",
    "assetId": "0LBYVRlkkfRWVNk0y2c4a",
    "displayName": "Real estate asset"
  },
  {
    "date": "2016-11-01T00:00:00.000Z",
    "value": 400000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2016-11",
    "Date (Year)": "2016",
    "category": "realEstate",
    "accountId": "0LBYVRlkkfRWVNk0y2c4a",
    "accountName": "Real estate asset",
    "assetId": "0LBYVRlkkfRWVNk0y2c4a",
    "displayName": "Real estate asset"
  },
  {
    "date": "2016-12-01T00:00:00.000Z",
    "value": 400000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2016-12",
    "Date (Year)": "2016",
    "category": "realEstate",
    "accountId": "0LBYVRlkkfRWVNk0y2c4a",
    "accountName": "Real estate asset",
    "assetId": "0LBYVRlkkfRWVNk0y2c4a",
    "displayName": "Real estate asset"
  },
  {
    "date": "2017-01-01T00:00:00.000Z",
    "value": 400000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2017-01",
    "Date (Year)": "2017",
    "category": "realEstate",
    "accountId": "0LBYVRlkkfRWVNk0y2c4a",
    "accountName": "Real estate asset",
    "assetId": "0LBYVRlkkfRWVNk0y2c4a",
    "displayName": "Real estate asset"
  },
  {
    "date": "2017-02-01T00:00:00.000Z",
    "value": 400000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2017-02",
    "Date (Year)": "2017",
    "category": "realEstate",
    "accountId": "0LBYVRlkkfRWVNk0y2c4a",
    "accountName": "Real estate asset",
    "assetId": "0LBYVRlkkfRWVNk0y2c4a",
    "displayName": "Real estate asset"
  },
  {
    "date": "2017-03-01T00:00:00.000Z",
    "value": 400000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2017-03",
    "Date (Year)": "2017",
    "category": "realEstate",
    "accountId": "0LBYVRlkkfRWVNk0y2c4a",
    "accountName": "Real estate asset",
    "assetId": "0LBYVRlkkfRWVNk0y2c4a",
    "displayName": "Real estate asset"
  },
  {
    "date": "2017-04-01T00:00:00.000Z",
    "value": 400000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2017-04",
    "Date (Year)": "2017",
    "category": "realEstate",
    "accountId": "0LBYVRlkkfRWVNk0y2c4a",
    "accountName": "Real estate asset",
    "assetId": "0LBYVRlkkfRWVNk0y2c4a",
    "displayName": "Real estate asset"
  },
  {
    "date": "2017-05-01T00:00:00.000Z",
    "value": 400000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2017-05",
    "Date (Year)": "2017",
    "category": "realEstate",
    "accountId": "0LBYVRlkkfRWVNk0y2c4a",
    "accountName": "Real estate asset",
    "assetId": "0LBYVRlkkfRWVNk0y2c4a",
    "displayName": "Real estate asset"
  },
  {
    "date": "2017-06-01T00:00:00.000Z",
    "value": 400000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2017-06",
    "Date (Year)": "2017",
    "category": "realEstate",
    "accountId": "0LBYVRlkkfRWVNk0y2c4a",
    "accountName": "Real estate asset",
    "assetId": "0LBYVRlkkfRWVNk0y2c4a",
    "displayName": "Real estate asset"
  },
  {
    "date": "2017-07-01T00:00:00.000Z",
    "value": 400000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2017-07",
    "Date (Year)": "2017",
    "category": "realEstate",
    "accountId": "0LBYVRlkkfRWVNk0y2c4a",
    "accountName": "Real estate asset",
    "assetId": "0LBYVRlkkfRWVNk0y2c4a",
    "displayName": "Real estate asset"
  },
  {
    "date": "2017-08-01T00:00:00.000Z",
    "value": 400000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2017-08",
    "Date (Year)": "2017",
    "category": "realEstate",
    "accountId": "0LBYVRlkkfRWVNk0y2c4a",
    "accountName": "Real estate asset",
    "assetId": "0LBYVRlkkfRWVNk0y2c4a",
    "displayName": "Real estate asset"
  },
  {
    "date": "2017-09-01T00:00:00.000Z",
    "value": 400000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2017-09",
    "Date (Year)": "2017",
    "category": "realEstate",
    "accountId": "0LBYVRlkkfRWVNk0y2c4a",
    "accountName": "Real estate asset",
    "assetId": "0LBYVRlkkfRWVNk0y2c4a",
    "displayName": "Real estate asset"
  },
  {
    "date": "2017-10-01T00:00:00.000Z",
    "value": 400000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2017-10",
    "Date (Year)": "2017",
    "category": "realEstate",
    "accountId": "0LBYVRlkkfRWVNk0y2c4a",
    "accountName": "Real estate asset",
    "assetId": "0LBYVRlkkfRWVNk0y2c4a",
    "displayName": "Real estate asset"
  },
  {
    "date": "2017-11-01T00:00:00.000Z",
    "value": 400000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2017-11",
    "Date (Year)": "2017",
    "category": "realEstate",
    "accountId": "0LBYVRlkkfRWVNk0y2c4a",
    "accountName": "Real estate asset",
    "assetId": "0LBYVRlkkfRWVNk0y2c4a",
    "displayName": "Real estate asset"
  },
  {
    "date": "2017-12-01T00:00:00.000Z",
    "value": 400000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2017-12",
    "Date (Year)": "2017",
    "category": "realEstate",
    "accountId": "0LBYVRlkkfRWVNk0y2c4a",
    "accountName": "Real estate asset",
    "assetId": "0LBYVRlkkfRWVNk0y2c4a",
    "displayName": "Real estate asset"
  },
  {
    "date": "2018-01-01T00:00:00.000Z",
    "value": 400000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2018-01",
    "Date (Year)": "2018",
    "category": "realEstate",
    "accountId": "0LBYVRlkkfRWVNk0y2c4a",
    "accountName": "Real estate asset",
    "assetId": "0LBYVRlkkfRWVNk0y2c4a",
    "displayName": "Real estate asset"
  },
  {
    "date": "2018-02-01T00:00:00.000Z",
    "value": 400000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2018-02",
    "Date (Year)": "2018",
    "category": "realEstate",
    "accountId": "0LBYVRlkkfRWVNk0y2c4a",
    "accountName": "Real estate asset",
    "assetId": "0LBYVRlkkfRWVNk0y2c4a",
    "displayName": "Real estate asset"
  },
  {
    "date": "2018-03-01T00:00:00.000Z",
    "value": 400000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2018-03",
    "Date (Year)": "2018",
    "category": "realEstate",
    "accountId": "0LBYVRlkkfRWVNk0y2c4a",
    "accountName": "Real estate asset",
    "assetId": "0LBYVRlkkfRWVNk0y2c4a",
    "displayName": "Real estate asset"
  },
  {
    "date": "2018-04-01T00:00:00.000Z",
    "value": 400000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2018-04",
    "Date (Year)": "2018",
    "category": "realEstate",
    "accountId": "0LBYVRlkkfRWVNk0y2c4a",
    "accountName": "Real estate asset",
    "assetId": "0LBYVRlkkfRWVNk0y2c4a",
    "displayName": "Real estate asset"
  },
  {
    "date": "2018-05-01T00:00:00.000Z",
    "value": 400000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2018-05",
    "Date (Year)": "2018",
    "category": "realEstate",
    "accountId": "0LBYVRlkkfRWVNk0y2c4a",
    "accountName": "Real estate asset",
    "assetId": "0LBYVRlkkfRWVNk0y2c4a",
    "displayName": "Real estate asset"
  },
  {
    "date": "2018-06-01T00:00:00.000Z",
    "value": 400000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2018-06",
    "Date (Year)": "2018",
    "category": "realEstate",
    "accountId": "0LBYVRlkkfRWVNk0y2c4a",
    "accountName": "Real estate asset",
    "assetId": "0LBYVRlkkfRWVNk0y2c4a",
    "displayName": "Real estate asset"
  },
  {
    "date": "2018-07-01T00:00:00.000Z",
    "value": 400000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2018-07",
    "Date (Year)": "2018",
    "category": "realEstate",
    "accountId": "0LBYVRlkkfRWVNk0y2c4a",
    "accountName": "Real estate asset",
    "assetId": "0LBYVRlkkfRWVNk0y2c4a",
    "displayName": "Real estate asset"
  },
  {
    "date": "2018-08-01T00:00:00.000Z",
    "value": 400000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2018-08",
    "Date (Year)": "2018",
    "category": "realEstate",
    "accountId": "0LBYVRlkkfRWVNk0y2c4a",
    "accountName": "Real estate asset",
    "assetId": "0LBYVRlkkfRWVNk0y2c4a",
    "displayName": "Real estate asset"
  },
  {
    "date": "2018-09-01T00:00:00.000Z",
    "value": 400000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2018-09",
    "Date (Year)": "2018",
    "category": "realEstate",
    "accountId": "0LBYVRlkkfRWVNk0y2c4a",
    "accountName": "Real estate asset",
    "assetId": "0LBYVRlkkfRWVNk0y2c4a",
    "displayName": "Real estate asset"
  },
  {
    "date": "2018-10-01T00:00:00.000Z",
    "value": 400000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2018-10",
    "Date (Year)": "2018",
    "category": "realEstate",
    "accountId": "0LBYVRlkkfRWVNk0y2c4a",
    "accountName": "Real estate asset",
    "assetId": "0LBYVRlkkfRWVNk0y2c4a",
    "displayName": "Real estate asset"
  },
  {
    "date": "2018-11-01T00:00:00.000Z",
    "value": 400000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2018-11",
    "Date (Year)": "2018",
    "category": "realEstate",
    "accountId": "0LBYVRlkkfRWVNk0y2c4a",
    "accountName": "Real estate asset",
    "assetId": "0LBYVRlkkfRWVNk0y2c4a",
    "displayName": "Real estate asset"
  },
  {
    "date": "2018-12-01T00:00:00.000Z",
    "value": 400000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2018-12",
    "Date (Year)": "2018",
    "category": "realEstate",
    "accountId": "0LBYVRlkkfRWVNk0y2c4a",
    "accountName": "Real estate asset",
    "assetId": "0LBYVRlkkfRWVNk0y2c4a",
    "displayName": "Real estate asset"
  },
  {
    "date": "2019-01-01T00:00:00.000Z",
    "value": 400000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2019-01",
    "Date (Year)": "2019",
    "category": "realEstate",
    "accountId": "0LBYVRlkkfRWVNk0y2c4a",
    "accountName": "Real estate asset",
    "assetId": "0LBYVRlkkfRWVNk0y2c4a",
    "displayName": "Real estate asset"
  },
  {
    "date": "2019-02-01T00:00:00.000Z",
    "value": 400000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2019-02",
    "Date (Year)": "2019",
    "category": "realEstate",
    "accountId": "0LBYVRlkkfRWVNk0y2c4a",
    "accountName": "Real estate asset",
    "assetId": "0LBYVRlkkfRWVNk0y2c4a",
    "displayName": "Real estate asset"
  },
  {
    "date": "2019-03-01T00:00:00.000Z",
    "value": 400000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2019-03",
    "Date (Year)": "2019",
    "category": "realEstate",
    "accountId": "0LBYVRlkkfRWVNk0y2c4a",
    "accountName": "Real estate asset",
    "assetId": "0LBYVRlkkfRWVNk0y2c4a",
    "displayName": "Real estate asset"
  },
  {
    "date": "2019-04-01T00:00:00.000Z",
    "value": 400000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2019-04",
    "Date (Year)": "2019",
    "category": "realEstate",
    "accountId": "0LBYVRlkkfRWVNk0y2c4a",
    "accountName": "Real estate asset",
    "assetId": "0LBYVRlkkfRWVNk0y2c4a",
    "displayName": "Real estate asset"
  },
  {
    "date": "2019-05-01T00:00:00.000Z",
    "value": 400000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2019-05",
    "Date (Year)": "2019",
    "category": "realEstate",
    "accountId": "0LBYVRlkkfRWVNk0y2c4a",
    "accountName": "Real estate asset",
    "assetId": "0LBYVRlkkfRWVNk0y2c4a",
    "displayName": "Real estate asset"
  },
  {
    "date": "2019-06-01T00:00:00.000Z",
    "value": 400000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2019-06",
    "Date (Year)": "2019",
    "category": "realEstate",
    "accountId": "0LBYVRlkkfRWVNk0y2c4a",
    "accountName": "Real estate asset",
    "assetId": "0LBYVRlkkfRWVNk0y2c4a",
    "displayName": "Real estate asset"
  },
  {
    "date": "2019-07-01T00:00:00.000Z",
    "value": 400000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2019-07",
    "Date (Year)": "2019",
    "category": "realEstate",
    "accountId": "0LBYVRlkkfRWVNk0y2c4a",
    "accountName": "Real estate asset",
    "assetId": "0LBYVRlkkfRWVNk0y2c4a",
    "displayName": "Real estate asset"
  },
  {
    "date": "2019-08-01T00:00:00.000Z",
    "value": 400000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2019-08",
    "Date (Year)": "2019",
    "category": "realEstate",
    "accountId": "0LBYVRlkkfRWVNk0y2c4a",
    "accountName": "Real estate asset",
    "assetId": "0LBYVRlkkfRWVNk0y2c4a",
    "displayName": "Real estate asset"
  },
  {
    "date": "2019-09-01T00:00:00.000Z",
    "value": 400000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2019-09",
    "Date (Year)": "2019",
    "category": "realEstate",
    "accountId": "0LBYVRlkkfRWVNk0y2c4a",
    "accountName": "Real estate asset",
    "assetId": "0LBYVRlkkfRWVNk0y2c4a",
    "displayName": "Real estate asset"
  },
  {
    "date": "2019-10-01T00:00:00.000Z",
    "value": 400000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2019-10",
    "Date (Year)": "2019",
    "category": "realEstate",
    "accountId": "0LBYVRlkkfRWVNk0y2c4a",
    "accountName": "Real estate asset",
    "assetId": "0LBYVRlkkfRWVNk0y2c4a",
    "displayName": "Real estate asset"
  },
  {
    "date": "2019-11-01T00:00:00.000Z",
    "value": 400000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2019-11",
    "Date (Year)": "2019",
    "category": "realEstate",
    "accountId": "0LBYVRlkkfRWVNk0y2c4a",
    "accountName": "Real estate asset",
    "assetId": "0LBYVRlkkfRWVNk0y2c4a",
    "displayName": "Real estate asset"
  },
  {
    "date": "2019-12-01T00:00:00.000Z",
    "value": 400000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2019-12",
    "Date (Year)": "2019",
    "category": "realEstate",
    "accountId": "0LBYVRlkkfRWVNk0y2c4a",
    "accountName": "Real estate asset",
    "assetId": "0LBYVRlkkfRWVNk0y2c4a",
    "displayName": "Real estate asset"
  },
  {
    "date": "2020-01-01T00:00:00.000Z",
    "value": 400000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2020-01",
    "Date (Year)": "2020",
    "category": "realEstate",
    "accountId": "0LBYVRlkkfRWVNk0y2c4a",
    "accountName": "Real estate asset",
    "assetId": "0LBYVRlkkfRWVNk0y2c4a",
    "displayName": "Real estate asset"
  },
  {
    "date": "2020-02-01T00:00:00.000Z",
    "value": 400000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2020-02",
    "Date (Year)": "2020",
    "category": "realEstate",
    "accountId": "0LBYVRlkkfRWVNk0y2c4a",
    "accountName": "Real estate asset",
    "assetId": "0LBYVRlkkfRWVNk0y2c4a",
    "displayName": "Real estate asset"
  },
  {
    "date": "2020-03-01T00:00:00.000Z",
    "value": 400000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2020-03",
    "Date (Year)": "2020",
    "category": "realEstate",
    "accountId": "0LBYVRlkkfRWVNk0y2c4a",
    "accountName": "Real estate asset",
    "assetId": "0LBYVRlkkfRWVNk0y2c4a",
    "displayName": "Real estate asset"
  },
  {
    "date": "2020-04-01T00:00:00.000Z",
    "value": 400000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2020-04",
    "Date (Year)": "2020",
    "category": "realEstate",
    "accountId": "0LBYVRlkkfRWVNk0y2c4a",
    "accountName": "Real estate asset",
    "assetId": "0LBYVRlkkfRWVNk0y2c4a",
    "displayName": "Real estate asset"
  },
  {
    "date": "2020-05-01T00:00:00.000Z",
    "value": 400000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2020-05",
    "Date (Year)": "2020",
    "category": "realEstate",
    "accountId": "0LBYVRlkkfRWVNk0y2c4a",
    "accountName": "Real estate asset",
    "assetId": "0LBYVRlkkfRWVNk0y2c4a",
    "displayName": "Real estate asset"
  },
  {
    "date": "2020-06-01T00:00:00.000Z",
    "value": 400000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2020-06",
    "Date (Year)": "2020",
    "category": "realEstate",
    "accountId": "0LBYVRlkkfRWVNk0y2c4a",
    "accountName": "Real estate asset",
    "assetId": "0LBYVRlkkfRWVNk0y2c4a",
    "displayName": "Real estate asset"
  },
  {
    "date": "2020-07-01T00:00:00.000Z",
    "value": 400000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2020-07",
    "Date (Year)": "2020",
    "category": "realEstate",
    "accountId": "0LBYVRlkkfRWVNk0y2c4a",
    "accountName": "Real estate asset",
    "assetId": "0LBYVRlkkfRWVNk0y2c4a",
    "displayName": "Real estate asset"
  },
  {
    "date": "2020-08-01T00:00:00.000Z",
    "value": 400000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2020-08",
    "Date (Year)": "2020",
    "category": "realEstate",
    "accountId": "0LBYVRlkkfRWVNk0y2c4a",
    "accountName": "Real estate asset",
    "assetId": "0LBYVRlkkfRWVNk0y2c4a",
    "displayName": "Real estate asset"
  },
  {
    "date": "2020-09-01T00:00:00.000Z",
    "value": 400000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2020-09",
    "Date (Year)": "2020",
    "category": "realEstate",
    "accountId": "0LBYVRlkkfRWVNk0y2c4a",
    "accountName": "Real estate asset",
    "assetId": "0LBYVRlkkfRWVNk0y2c4a",
    "displayName": "Real estate asset"
  },
  {
    "date": "2020-10-01T00:00:00.000Z",
    "value": 400000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2020-10",
    "Date (Year)": "2020",
    "category": "realEstate",
    "accountId": "0LBYVRlkkfRWVNk0y2c4a",
    "accountName": "Real estate asset",
    "assetId": "0LBYVRlkkfRWVNk0y2c4a",
    "displayName": "Real estate asset"
  },
  {
    "date": "2020-11-01T00:00:00.000Z",
    "value": 400000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2020-11",
    "Date (Year)": "2020",
    "category": "realEstate",
    "accountId": "0LBYVRlkkfRWVNk0y2c4a",
    "accountName": "Real estate asset",
    "assetId": "0LBYVRlkkfRWVNk0y2c4a",
    "displayName": "Real estate asset"
  },
  {
    "date": "2020-12-01T00:00:00.000Z",
    "value": 400000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2020-12",
    "Date (Year)": "2020",
    "category": "realEstate",
    "accountId": "0LBYVRlkkfRWVNk0y2c4a",
    "accountName": "Real estate asset",
    "assetId": "0LBYVRlkkfRWVNk0y2c4a",
    "displayName": "Real estate asset"
  },
  {
    "date": "2021-01-01T00:00:00.000Z",
    "value": 400000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2021-01",
    "Date (Year)": "2021",
    "category": "realEstate",
    "accountId": "0LBYVRlkkfRWVNk0y2c4a",
    "accountName": "Real estate asset",
    "assetId": "0LBYVRlkkfRWVNk0y2c4a",
    "displayName": "Real estate asset"
  },
  {
    "date": "2021-02-01T00:00:00.000Z",
    "value": 400000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2021-02",
    "Date (Year)": "2021",
    "category": "realEstate",
    "accountId": "0LBYVRlkkfRWVNk0y2c4a",
    "accountName": "Real estate asset",
    "assetId": "0LBYVRlkkfRWVNk0y2c4a",
    "displayName": "Real estate asset"
  },
  {
    "date": "2021-03-01T00:00:00.000Z",
    "value": 400000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2021-03",
    "Date (Year)": "2021",
    "category": "realEstate",
    "accountId": "0LBYVRlkkfRWVNk0y2c4a",
    "accountName": "Real estate asset",
    "assetId": "0LBYVRlkkfRWVNk0y2c4a",
    "displayName": "Real estate asset"
  },
  {
    "date": "2021-04-01T00:00:00.000Z",
    "value": 400000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2021-04",
    "Date (Year)": "2021",
    "category": "realEstate",
    "accountId": "0LBYVRlkkfRWVNk0y2c4a",
    "accountName": "Real estate asset",
    "assetId": "0LBYVRlkkfRWVNk0y2c4a",
    "displayName": "Real estate asset"
  },
  {
    "date": "2021-05-01T00:00:00.000Z",
    "value": 400000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2021-05",
    "Date (Year)": "2021",
    "category": "realEstate",
    "accountId": "0LBYVRlkkfRWVNk0y2c4a",
    "accountName": "Real estate asset",
    "assetId": "0LBYVRlkkfRWVNk0y2c4a",
    "displayName": "Real estate asset"
  },
  {
    "date": "2021-06-01T00:00:00.000Z",
    "value": 400000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2021-06",
    "Date (Year)": "2021",
    "category": "realEstate",
    "accountId": "0LBYVRlkkfRWVNk0y2c4a",
    "accountName": "Real estate asset",
    "assetId": "0LBYVRlkkfRWVNk0y2c4a",
    "displayName": "Real estate asset"
  },
  {
    "date": "2021-07-01T00:00:00.000Z",
    "value": 400000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2021-07",
    "Date (Year)": "2021",
    "category": "realEstate",
    "accountId": "0LBYVRlkkfRWVNk0y2c4a",
    "accountName": "Real estate asset",
    "assetId": "0LBYVRlkkfRWVNk0y2c4a",
    "displayName": "Real estate asset"
  },
  {
    "date": "2021-08-01T00:00:00.000Z",
    "value": 400000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2021-08",
    "Date (Year)": "2021",
    "category": "realEstate",
    "accountId": "0LBYVRlkkfRWVNk0y2c4a",
    "accountName": "Real estate asset",
    "assetId": "0LBYVRlkkfRWVNk0y2c4a",
    "displayName": "Real estate asset"
  },
  {
    "date": "2021-09-01T00:00:00.000Z",
    "value": 400000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2021-09",
    "Date (Year)": "2021",
    "category": "realEstate",
    "accountId": "0LBYVRlkkfRWVNk0y2c4a",
    "accountName": "Real estate asset",
    "assetId": "0LBYVRlkkfRWVNk0y2c4a",
    "displayName": "Real estate asset"
  },
  {
    "date": "2021-10-01T00:00:00.000Z",
    "value": 400000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2021-10",
    "Date (Year)": "2021",
    "category": "realEstate",
    "accountId": "0LBYVRlkkfRWVNk0y2c4a",
    "accountName": "Real estate asset",
    "assetId": "0LBYVRlkkfRWVNk0y2c4a",
    "displayName": "Real estate asset"
  },
  {
    "date": "2021-11-01T00:00:00.000Z",
    "value": 400000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2021-11",
    "Date (Year)": "2021",
    "category": "realEstate",
    "accountId": "0LBYVRlkkfRWVNk0y2c4a",
    "accountName": "Real estate asset",
    "assetId": "0LBYVRlkkfRWVNk0y2c4a",
    "displayName": "Real estate asset"
  },
  {
    "date": "2021-12-01T00:00:00.000Z",
    "value": 400000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2021-12",
    "Date (Year)": "2021",
    "category": "realEstate",
    "accountId": "0LBYVRlkkfRWVNk0y2c4a",
    "accountName": "Real estate asset",
    "assetId": "0LBYVRlkkfRWVNk0y2c4a",
    "displayName": "Real estate asset"
  },
  {
    "date": "2022-01-01T00:00:00.000Z",
    "value": 400000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2022-01",
    "Date (Year)": "2022",
    "category": "realEstate",
    "accountId": "0LBYVRlkkfRWVNk0y2c4a",
    "accountName": "Real estate asset",
    "assetId": "0LBYVRlkkfRWVNk0y2c4a",
    "displayName": "Real estate asset"
  },
  {
    "date": "2022-02-01T00:00:00.000Z",
    "value": 400000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2022-02",
    "Date (Year)": "2022",
    "category": "realEstate",
    "accountId": "0LBYVRlkkfRWVNk0y2c4a",
    "accountName": "Real estate asset",
    "assetId": "0LBYVRlkkfRWVNk0y2c4a",
    "displayName": "Real estate asset"
  },
  {
    "date": "2022-03-01T00:00:00.000Z",
    "value": 400000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2022-03",
    "Date (Year)": "2022",
    "category": "realEstate",
    "accountId": "0LBYVRlkkfRWVNk0y2c4a",
    "accountName": "Real estate asset",
    "assetId": "0LBYVRlkkfRWVNk0y2c4a",
    "displayName": "Real estate asset"
  },
  {
    "date": "2022-04-01T00:00:00.000Z",
    "value": 400000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2022-04",
    "Date (Year)": "2022",
    "category": "realEstate",
    "accountId": "0LBYVRlkkfRWVNk0y2c4a",
    "accountName": "Real estate asset",
    "assetId": "0LBYVRlkkfRWVNk0y2c4a",
    "displayName": "Real estate asset"
  },
  {
    "date": "2022-05-01T00:00:00.000Z",
    "value": 400000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2022-05",
    "Date (Year)": "2022",
    "category": "realEstate",
    "accountId": "0LBYVRlkkfRWVNk0y2c4a",
    "accountName": "Real estate asset",
    "assetId": "0LBYVRlkkfRWVNk0y2c4a",
    "displayName": "Real estate asset"
  },
  {
    "date": "2022-06-01T00:00:00.000Z",
    "value": 400000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2022-06",
    "Date (Year)": "2022",
    "category": "realEstate",
    "accountId": "0LBYVRlkkfRWVNk0y2c4a",
    "accountName": "Real estate asset",
    "assetId": "0LBYVRlkkfRWVNk0y2c4a",
    "displayName": "Real estate asset"
  },
  {
    "date": "2022-07-01T00:00:00.000Z",
    "value": 400000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2022-07",
    "Date (Year)": "2022",
    "category": "realEstate",
    "accountId": "0LBYVRlkkfRWVNk0y2c4a",
    "accountName": "Real estate asset",
    "assetId": "0LBYVRlkkfRWVNk0y2c4a",
    "displayName": "Real estate asset"
  },
  {
    "date": "2022-08-01T00:00:00.000Z",
    "value": 400000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2022-08",
    "Date (Year)": "2022",
    "category": "realEstate",
    "accountId": "0LBYVRlkkfRWVNk0y2c4a",
    "accountName": "Real estate asset",
    "assetId": "0LBYVRlkkfRWVNk0y2c4a",
    "displayName": "Real estate asset"
  },
  {
    "date": "2022-09-01T00:00:00.000Z",
    "value": 400000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2022-09",
    "Date (Year)": "2022",
    "category": "realEstate",
    "accountId": "0LBYVRlkkfRWVNk0y2c4a",
    "accountName": "Real estate asset",
    "assetId": "0LBYVRlkkfRWVNk0y2c4a",
    "displayName": "Real estate asset"
  },
  {
    "date": "2022-10-01T00:00:00.000Z",
    "value": 400000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2022-10",
    "Date (Year)": "2022",
    "category": "realEstate",
    "accountId": "0LBYVRlkkfRWVNk0y2c4a",
    "accountName": "Real estate asset",
    "assetId": "0LBYVRlkkfRWVNk0y2c4a",
    "displayName": "Real estate asset"
  },
  {
    "date": "2022-11-01T00:00:00.000Z",
    "value": 400000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2022-11",
    "Date (Year)": "2022",
    "category": "realEstate",
    "accountId": "0LBYVRlkkfRWVNk0y2c4a",
    "accountName": "Real estate asset",
    "assetId": "0LBYVRlkkfRWVNk0y2c4a",
    "displayName": "Real estate asset"
  },
  {
    "date": "2022-12-01T00:00:00.000Z",
    "value": 400000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2022-12",
    "Date (Year)": "2022",
    "category": "realEstate",
    "accountId": "0LBYVRlkkfRWVNk0y2c4a",
    "accountName": "Real estate asset",
    "assetId": "0LBYVRlkkfRWVNk0y2c4a",
    "displayName": "Real estate asset"
  },
  {
    "date": "2023-01-01T00:00:00.000Z",
    "value": 493500,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2023-01",
    "Date (Year)": "2023",
    "category": "realEstate",
    "accountId": "0LBYVRlkkfRWVNk0y2c4a",
    "accountName": "Real estate asset",
    "assetId": "0LBYVRlkkfRWVNk0y2c4a",
    "displayName": "Real estate asset"
  },
  {
    "date": "2023-02-01T00:00:00.000Z",
    "value": 495000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2023-02",
    "Date (Year)": "2023",
    "category": "realEstate",
    "accountId": "0LBYVRlkkfRWVNk0y2c4a",
    "accountName": "Real estate asset",
    "assetId": "0LBYVRlkkfRWVNk0y2c4a",
    "displayName": "Real estate asset"
  },
  {
    "date": "2023-03-01T00:00:00.000Z",
    "value": 495000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2023-03",
    "Date (Year)": "2023",
    "category": "realEstate",
    "accountId": "0LBYVRlkkfRWVNk0y2c4a",
    "accountName": "Real estate asset",
    "assetId": "0LBYVRlkkfRWVNk0y2c4a",
    "displayName": "Real estate asset"
  },
  {
    "date": "2023-04-01T00:00:00.000Z",
    "value": 495000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2023-04",
    "Date (Year)": "2023",
    "category": "realEstate",
    "accountId": "0LBYVRlkkfRWVNk0y2c4a",
    "accountName": "Real estate asset",
    "assetId": "0LBYVRlkkfRWVNk0y2c4a",
    "displayName": "Real estate asset"
  },
  {
    "date": "2023-05-01T00:00:00.000Z",
    "value": 495000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2023-05",
    "Date (Year)": "2023",
    "category": "realEstate",
    "accountId": "0LBYVRlkkfRWVNk0y2c4a",
    "accountName": "Real estate asset",
    "assetId": "0LBYVRlkkfRWVNk0y2c4a",
    "displayName": "Real estate asset"
  },
  {
    "date": "2023-06-01T00:00:00.000Z",
    "value": 495000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2023-06",
    "Date (Year)": "2023",
    "category": "realEstate",
    "accountId": "0LBYVRlkkfRWVNk0y2c4a",
    "accountName": "Real estate asset",
    "assetId": "0LBYVRlkkfRWVNk0y2c4a",
    "displayName": "Real estate asset"
  },
  {
    "date": "2023-07-01T00:00:00.000Z",
    "value": 495000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2023-07",
    "Date (Year)": "2023",
    "category": "realEstate",
    "accountId": "0LBYVRlkkfRWVNk0y2c4a",
    "accountName": "Real estate asset",
    "assetId": "0LBYVRlkkfRWVNk0y2c4a",
    "displayName": "Real estate asset"
  },
  {
    "date": "2023-08-01T00:00:00.000Z",
    "value": 495000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2023-08",
    "Date (Year)": "2023",
    "category": "realEstate",
    "accountId": "0LBYVRlkkfRWVNk0y2c4a",
    "accountName": "Real estate asset",
    "assetId": "0LBYVRlkkfRWVNk0y2c4a",
    "displayName": "Real estate asset"
  },
  {
    "date": "2023-09-01T00:00:00.000Z",
    "value": 495000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2023-09",
    "Date (Year)": "2023",
    "category": "realEstate",
    "accountId": "0LBYVRlkkfRWVNk0y2c4a",
    "accountName": "Real estate asset",
    "assetId": "0LBYVRlkkfRWVNk0y2c4a",
    "displayName": "Real estate asset"
  },
  {
    "date": "2023-10-01T00:00:00.000Z",
    "value": 495000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2023-10",
    "Date (Year)": "2023",
    "category": "realEstate",
    "accountId": "0LBYVRlkkfRWVNk0y2c4a",
    "accountName": "Real estate asset",
    "assetId": "0LBYVRlkkfRWVNk0y2c4a",
    "displayName": "Real estate asset"
  },
  {
    "date": "2023-11-01T00:00:00.000Z",
    "value": 495000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2023-11",
    "Date (Year)": "2023",
    "category": "realEstate",
    "accountId": "0LBYVRlkkfRWVNk0y2c4a",
    "accountName": "Real estate asset",
    "assetId": "0LBYVRlkkfRWVNk0y2c4a",
    "displayName": "Real estate asset"
  },
  {
    "date": "2023-12-01T00:00:00.000Z",
    "value": 495000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2023-12",
    "Date (Year)": "2023",
    "category": "realEstate",
    "accountId": "0LBYVRlkkfRWVNk0y2c4a",
    "accountName": "Real estate asset",
    "assetId": "0LBYVRlkkfRWVNk0y2c4a",
    "displayName": "Real estate asset"
  },
  {
    "date": "2012-09-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2012-09",
    "Date (Year)": "2012",
    "category": "deposits",
    "accountId": "-b3jqm5IvL6kobgCcdICR",
    "accountName": "Bank for real estate",
    "assetId": "-b3jqm5IvL6kobgCcdICR",
    "displayName": "Bank for real estate"
  },
  {
    "date": "2012-10-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2012-10",
    "Date (Year)": "2012",
    "category": "deposits",
    "accountId": "-b3jqm5IvL6kobgCcdICR",
    "accountName": "Bank for real estate",
    "assetId": "-b3jqm5IvL6kobgCcdICR",
    "displayName": "Bank for real estate"
  },
  {
    "date": "2012-11-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2012-11",
    "Date (Year)": "2012",
    "category": "deposits",
    "accountId": "-b3jqm5IvL6kobgCcdICR",
    "accountName": "Bank for real estate",
    "assetId": "-b3jqm5IvL6kobgCcdICR",
    "displayName": "Bank for real estate"
  },
  {
    "date": "2012-12-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2012-12",
    "Date (Year)": "2012",
    "category": "deposits",
    "accountId": "-b3jqm5IvL6kobgCcdICR",
    "accountName": "Bank for real estate",
    "assetId": "-b3jqm5IvL6kobgCcdICR",
    "displayName": "Bank for real estate"
  },
  {
    "date": "2013-01-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2013-01",
    "Date (Year)": "2013",
    "category": "deposits",
    "accountId": "-b3jqm5IvL6kobgCcdICR",
    "accountName": "Bank for real estate",
    "assetId": "-b3jqm5IvL6kobgCcdICR",
    "displayName": "Bank for real estate"
  },
  {
    "date": "2013-02-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2013-02",
    "Date (Year)": "2013",
    "category": "deposits",
    "accountId": "-b3jqm5IvL6kobgCcdICR",
    "accountName": "Bank for real estate",
    "assetId": "-b3jqm5IvL6kobgCcdICR",
    "displayName": "Bank for real estate"
  },
  {
    "date": "2013-03-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2013-03",
    "Date (Year)": "2013",
    "category": "deposits",
    "accountId": "-b3jqm5IvL6kobgCcdICR",
    "accountName": "Bank for real estate",
    "assetId": "-b3jqm5IvL6kobgCcdICR",
    "displayName": "Bank for real estate"
  },
  {
    "date": "2013-04-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2013-04",
    "Date (Year)": "2013",
    "category": "deposits",
    "accountId": "-b3jqm5IvL6kobgCcdICR",
    "accountName": "Bank for real estate",
    "assetId": "-b3jqm5IvL6kobgCcdICR",
    "displayName": "Bank for real estate"
  },
  {
    "date": "2013-05-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2013-05",
    "Date (Year)": "2013",
    "category": "deposits",
    "accountId": "-b3jqm5IvL6kobgCcdICR",
    "accountName": "Bank for real estate",
    "assetId": "-b3jqm5IvL6kobgCcdICR",
    "displayName": "Bank for real estate"
  },
  {
    "date": "2013-06-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2013-06",
    "Date (Year)": "2013",
    "category": "deposits",
    "accountId": "-b3jqm5IvL6kobgCcdICR",
    "accountName": "Bank for real estate",
    "assetId": "-b3jqm5IvL6kobgCcdICR",
    "displayName": "Bank for real estate"
  },
  {
    "date": "2013-07-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2013-07",
    "Date (Year)": "2013",
    "category": "deposits",
    "accountId": "-b3jqm5IvL6kobgCcdICR",
    "accountName": "Bank for real estate",
    "assetId": "-b3jqm5IvL6kobgCcdICR",
    "displayName": "Bank for real estate"
  },
  {
    "date": "2013-08-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2013-08",
    "Date (Year)": "2013",
    "category": "deposits",
    "accountId": "-b3jqm5IvL6kobgCcdICR",
    "accountName": "Bank for real estate",
    "assetId": "-b3jqm5IvL6kobgCcdICR",
    "displayName": "Bank for real estate"
  },
  {
    "date": "2013-09-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2013-09",
    "Date (Year)": "2013",
    "category": "deposits",
    "accountId": "-b3jqm5IvL6kobgCcdICR",
    "accountName": "Bank for real estate",
    "assetId": "-b3jqm5IvL6kobgCcdICR",
    "displayName": "Bank for real estate"
  },
  {
    "date": "2013-10-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2013-10",
    "Date (Year)": "2013",
    "category": "deposits",
    "accountId": "-b3jqm5IvL6kobgCcdICR",
    "accountName": "Bank for real estate",
    "assetId": "-b3jqm5IvL6kobgCcdICR",
    "displayName": "Bank for real estate"
  },
  {
    "date": "2013-11-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2013-11",
    "Date (Year)": "2013",
    "category": "deposits",
    "accountId": "-b3jqm5IvL6kobgCcdICR",
    "accountName": "Bank for real estate",
    "assetId": "-b3jqm5IvL6kobgCcdICR",
    "displayName": "Bank for real estate"
  },
  {
    "date": "2013-12-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2013-12",
    "Date (Year)": "2013",
    "category": "deposits",
    "accountId": "-b3jqm5IvL6kobgCcdICR",
    "accountName": "Bank for real estate",
    "assetId": "-b3jqm5IvL6kobgCcdICR",
    "displayName": "Bank for real estate"
  },
  {
    "date": "2014-01-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2014-01",
    "Date (Year)": "2014",
    "category": "deposits",
    "accountId": "-b3jqm5IvL6kobgCcdICR",
    "accountName": "Bank for real estate",
    "assetId": "-b3jqm5IvL6kobgCcdICR",
    "displayName": "Bank for real estate"
  },
  {
    "date": "2014-02-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2014-02",
    "Date (Year)": "2014",
    "category": "deposits",
    "accountId": "-b3jqm5IvL6kobgCcdICR",
    "accountName": "Bank for real estate",
    "assetId": "-b3jqm5IvL6kobgCcdICR",
    "displayName": "Bank for real estate"
  },
  {
    "date": "2014-03-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2014-03",
    "Date (Year)": "2014",
    "category": "deposits",
    "accountId": "-b3jqm5IvL6kobgCcdICR",
    "accountName": "Bank for real estate",
    "assetId": "-b3jqm5IvL6kobgCcdICR",
    "displayName": "Bank for real estate"
  },
  {
    "date": "2014-04-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2014-04",
    "Date (Year)": "2014",
    "category": "deposits",
    "accountId": "-b3jqm5IvL6kobgCcdICR",
    "accountName": "Bank for real estate",
    "assetId": "-b3jqm5IvL6kobgCcdICR",
    "displayName": "Bank for real estate"
  },
  {
    "date": "2014-05-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2014-05",
    "Date (Year)": "2014",
    "category": "deposits",
    "accountId": "-b3jqm5IvL6kobgCcdICR",
    "accountName": "Bank for real estate",
    "assetId": "-b3jqm5IvL6kobgCcdICR",
    "displayName": "Bank for real estate"
  },
  {
    "date": "2014-06-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2014-06",
    "Date (Year)": "2014",
    "category": "deposits",
    "accountId": "-b3jqm5IvL6kobgCcdICR",
    "accountName": "Bank for real estate",
    "assetId": "-b3jqm5IvL6kobgCcdICR",
    "displayName": "Bank for real estate"
  },
  {
    "date": "2014-07-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2014-07",
    "Date (Year)": "2014",
    "category": "deposits",
    "accountId": "-b3jqm5IvL6kobgCcdICR",
    "accountName": "Bank for real estate",
    "assetId": "-b3jqm5IvL6kobgCcdICR",
    "displayName": "Bank for real estate"
  },
  {
    "date": "2014-08-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2014-08",
    "Date (Year)": "2014",
    "category": "deposits",
    "accountId": "-b3jqm5IvL6kobgCcdICR",
    "accountName": "Bank for real estate",
    "assetId": "-b3jqm5IvL6kobgCcdICR",
    "displayName": "Bank for real estate"
  },
  {
    "date": "2014-09-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2014-09",
    "Date (Year)": "2014",
    "category": "deposits",
    "accountId": "-b3jqm5IvL6kobgCcdICR",
    "accountName": "Bank for real estate",
    "assetId": "-b3jqm5IvL6kobgCcdICR",
    "displayName": "Bank for real estate"
  },
  {
    "date": "2014-10-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2014-10",
    "Date (Year)": "2014",
    "category": "deposits",
    "accountId": "-b3jqm5IvL6kobgCcdICR",
    "accountName": "Bank for real estate",
    "assetId": "-b3jqm5IvL6kobgCcdICR",
    "displayName": "Bank for real estate"
  },
  {
    "date": "2014-11-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2014-11",
    "Date (Year)": "2014",
    "category": "deposits",
    "accountId": "-b3jqm5IvL6kobgCcdICR",
    "accountName": "Bank for real estate",
    "assetId": "-b3jqm5IvL6kobgCcdICR",
    "displayName": "Bank for real estate"
  },
  {
    "date": "2014-12-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2014-12",
    "Date (Year)": "2014",
    "category": "deposits",
    "accountId": "-b3jqm5IvL6kobgCcdICR",
    "accountName": "Bank for real estate",
    "assetId": "-b3jqm5IvL6kobgCcdICR",
    "displayName": "Bank for real estate"
  },
  {
    "date": "2015-01-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2015-01",
    "Date (Year)": "2015",
    "category": "deposits",
    "accountId": "-b3jqm5IvL6kobgCcdICR",
    "accountName": "Bank for real estate",
    "assetId": "-b3jqm5IvL6kobgCcdICR",
    "displayName": "Bank for real estate"
  },
  {
    "date": "2015-02-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2015-02",
    "Date (Year)": "2015",
    "category": "deposits",
    "accountId": "-b3jqm5IvL6kobgCcdICR",
    "accountName": "Bank for real estate",
    "assetId": "-b3jqm5IvL6kobgCcdICR",
    "displayName": "Bank for real estate"
  },
  {
    "date": "2015-03-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2015-03",
    "Date (Year)": "2015",
    "category": "deposits",
    "accountId": "-b3jqm5IvL6kobgCcdICR",
    "accountName": "Bank for real estate",
    "assetId": "-b3jqm5IvL6kobgCcdICR",
    "displayName": "Bank for real estate"
  },
  {
    "date": "2015-04-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2015-04",
    "Date (Year)": "2015",
    "category": "deposits",
    "accountId": "-b3jqm5IvL6kobgCcdICR",
    "accountName": "Bank for real estate",
    "assetId": "-b3jqm5IvL6kobgCcdICR",
    "displayName": "Bank for real estate"
  },
  {
    "date": "2015-05-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2015-05",
    "Date (Year)": "2015",
    "category": "deposits",
    "accountId": "-b3jqm5IvL6kobgCcdICR",
    "accountName": "Bank for real estate",
    "assetId": "-b3jqm5IvL6kobgCcdICR",
    "displayName": "Bank for real estate"
  },
  {
    "date": "2015-06-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2015-06",
    "Date (Year)": "2015",
    "category": "deposits",
    "accountId": "-b3jqm5IvL6kobgCcdICR",
    "accountName": "Bank for real estate",
    "assetId": "-b3jqm5IvL6kobgCcdICR",
    "displayName": "Bank for real estate"
  },
  {
    "date": "2015-07-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2015-07",
    "Date (Year)": "2015",
    "category": "deposits",
    "accountId": "-b3jqm5IvL6kobgCcdICR",
    "accountName": "Bank for real estate",
    "assetId": "-b3jqm5IvL6kobgCcdICR",
    "displayName": "Bank for real estate"
  },
  {
    "date": "2015-08-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2015-08",
    "Date (Year)": "2015",
    "category": "deposits",
    "accountId": "-b3jqm5IvL6kobgCcdICR",
    "accountName": "Bank for real estate",
    "assetId": "-b3jqm5IvL6kobgCcdICR",
    "displayName": "Bank for real estate"
  },
  {
    "date": "2015-09-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2015-09",
    "Date (Year)": "2015",
    "category": "deposits",
    "accountId": "-b3jqm5IvL6kobgCcdICR",
    "accountName": "Bank for real estate",
    "assetId": "-b3jqm5IvL6kobgCcdICR",
    "displayName": "Bank for real estate"
  },
  {
    "date": "2015-10-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2015-10",
    "Date (Year)": "2015",
    "category": "deposits",
    "accountId": "-b3jqm5IvL6kobgCcdICR",
    "accountName": "Bank for real estate",
    "assetId": "-b3jqm5IvL6kobgCcdICR",
    "displayName": "Bank for real estate"
  },
  {
    "date": "2015-11-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2015-11",
    "Date (Year)": "2015",
    "category": "deposits",
    "accountId": "-b3jqm5IvL6kobgCcdICR",
    "accountName": "Bank for real estate",
    "assetId": "-b3jqm5IvL6kobgCcdICR",
    "displayName": "Bank for real estate"
  },
  {
    "date": "2015-12-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2015-12",
    "Date (Year)": "2015",
    "category": "deposits",
    "accountId": "-b3jqm5IvL6kobgCcdICR",
    "accountName": "Bank for real estate",
    "assetId": "-b3jqm5IvL6kobgCcdICR",
    "displayName": "Bank for real estate"
  },
  {
    "date": "2016-01-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2016-01",
    "Date (Year)": "2016",
    "category": "deposits",
    "accountId": "-b3jqm5IvL6kobgCcdICR",
    "accountName": "Bank for real estate",
    "assetId": "-b3jqm5IvL6kobgCcdICR",
    "displayName": "Bank for real estate"
  },
  {
    "date": "2016-02-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2016-02",
    "Date (Year)": "2016",
    "category": "deposits",
    "accountId": "-b3jqm5IvL6kobgCcdICR",
    "accountName": "Bank for real estate",
    "assetId": "-b3jqm5IvL6kobgCcdICR",
    "displayName": "Bank for real estate"
  },
  {
    "date": "2016-03-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2016-03",
    "Date (Year)": "2016",
    "category": "deposits",
    "accountId": "-b3jqm5IvL6kobgCcdICR",
    "accountName": "Bank for real estate",
    "assetId": "-b3jqm5IvL6kobgCcdICR",
    "displayName": "Bank for real estate"
  },
  {
    "date": "2016-04-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2016-04",
    "Date (Year)": "2016",
    "category": "deposits",
    "accountId": "-b3jqm5IvL6kobgCcdICR",
    "accountName": "Bank for real estate",
    "assetId": "-b3jqm5IvL6kobgCcdICR",
    "displayName": "Bank for real estate"
  },
  {
    "date": "2016-05-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2016-05",
    "Date (Year)": "2016",
    "category": "deposits",
    "accountId": "-b3jqm5IvL6kobgCcdICR",
    "accountName": "Bank for real estate",
    "assetId": "-b3jqm5IvL6kobgCcdICR",
    "displayName": "Bank for real estate"
  },
  {
    "date": "2016-06-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2016-06",
    "Date (Year)": "2016",
    "category": "deposits",
    "accountId": "-b3jqm5IvL6kobgCcdICR",
    "accountName": "Bank for real estate",
    "assetId": "-b3jqm5IvL6kobgCcdICR",
    "displayName": "Bank for real estate"
  },
  {
    "date": "2016-07-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2016-07",
    "Date (Year)": "2016",
    "category": "deposits",
    "accountId": "-b3jqm5IvL6kobgCcdICR",
    "accountName": "Bank for real estate",
    "assetId": "-b3jqm5IvL6kobgCcdICR",
    "displayName": "Bank for real estate"
  },
  {
    "date": "2016-08-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2016-08",
    "Date (Year)": "2016",
    "category": "deposits",
    "accountId": "-b3jqm5IvL6kobgCcdICR",
    "accountName": "Bank for real estate",
    "assetId": "-b3jqm5IvL6kobgCcdICR",
    "displayName": "Bank for real estate"
  },
  {
    "date": "2016-09-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2016-09",
    "Date (Year)": "2016",
    "category": "deposits",
    "accountId": "-b3jqm5IvL6kobgCcdICR",
    "accountName": "Bank for real estate",
    "assetId": "-b3jqm5IvL6kobgCcdICR",
    "displayName": "Bank for real estate"
  },
  {
    "date": "2016-10-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2016-10",
    "Date (Year)": "2016",
    "category": "deposits",
    "accountId": "-b3jqm5IvL6kobgCcdICR",
    "accountName": "Bank for real estate",
    "assetId": "-b3jqm5IvL6kobgCcdICR",
    "displayName": "Bank for real estate"
  },
  {
    "date": "2016-11-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2016-11",
    "Date (Year)": "2016",
    "category": "deposits",
    "accountId": "-b3jqm5IvL6kobgCcdICR",
    "accountName": "Bank for real estate",
    "assetId": "-b3jqm5IvL6kobgCcdICR",
    "displayName": "Bank for real estate"
  },
  {
    "date": "2016-12-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2016-12",
    "Date (Year)": "2016",
    "category": "deposits",
    "accountId": "-b3jqm5IvL6kobgCcdICR",
    "accountName": "Bank for real estate",
    "assetId": "-b3jqm5IvL6kobgCcdICR",
    "displayName": "Bank for real estate"
  },
  {
    "date": "2017-01-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2017-01",
    "Date (Year)": "2017",
    "category": "deposits",
    "accountId": "-b3jqm5IvL6kobgCcdICR",
    "accountName": "Bank for real estate",
    "assetId": "-b3jqm5IvL6kobgCcdICR",
    "displayName": "Bank for real estate"
  },
  {
    "date": "2017-02-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2017-02",
    "Date (Year)": "2017",
    "category": "deposits",
    "accountId": "-b3jqm5IvL6kobgCcdICR",
    "accountName": "Bank for real estate",
    "assetId": "-b3jqm5IvL6kobgCcdICR",
    "displayName": "Bank for real estate"
  },
  {
    "date": "2017-03-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2017-03",
    "Date (Year)": "2017",
    "category": "deposits",
    "accountId": "-b3jqm5IvL6kobgCcdICR",
    "accountName": "Bank for real estate",
    "assetId": "-b3jqm5IvL6kobgCcdICR",
    "displayName": "Bank for real estate"
  },
  {
    "date": "2017-04-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2017-04",
    "Date (Year)": "2017",
    "category": "deposits",
    "accountId": "-b3jqm5IvL6kobgCcdICR",
    "accountName": "Bank for real estate",
    "assetId": "-b3jqm5IvL6kobgCcdICR",
    "displayName": "Bank for real estate"
  },
  {
    "date": "2017-05-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2017-05",
    "Date (Year)": "2017",
    "category": "deposits",
    "accountId": "-b3jqm5IvL6kobgCcdICR",
    "accountName": "Bank for real estate",
    "assetId": "-b3jqm5IvL6kobgCcdICR",
    "displayName": "Bank for real estate"
  },
  {
    "date": "2017-06-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2017-06",
    "Date (Year)": "2017",
    "category": "deposits",
    "accountId": "-b3jqm5IvL6kobgCcdICR",
    "accountName": "Bank for real estate",
    "assetId": "-b3jqm5IvL6kobgCcdICR",
    "displayName": "Bank for real estate"
  },
  {
    "date": "2017-07-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2017-07",
    "Date (Year)": "2017",
    "category": "deposits",
    "accountId": "-b3jqm5IvL6kobgCcdICR",
    "accountName": "Bank for real estate",
    "assetId": "-b3jqm5IvL6kobgCcdICR",
    "displayName": "Bank for real estate"
  },
  {
    "date": "2017-08-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2017-08",
    "Date (Year)": "2017",
    "category": "deposits",
    "accountId": "-b3jqm5IvL6kobgCcdICR",
    "accountName": "Bank for real estate",
    "assetId": "-b3jqm5IvL6kobgCcdICR",
    "displayName": "Bank for real estate"
  },
  {
    "date": "2017-09-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2017-09",
    "Date (Year)": "2017",
    "category": "deposits",
    "accountId": "-b3jqm5IvL6kobgCcdICR",
    "accountName": "Bank for real estate",
    "assetId": "-b3jqm5IvL6kobgCcdICR",
    "displayName": "Bank for real estate"
  },
  {
    "date": "2017-10-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2017-10",
    "Date (Year)": "2017",
    "category": "deposits",
    "accountId": "-b3jqm5IvL6kobgCcdICR",
    "accountName": "Bank for real estate",
    "assetId": "-b3jqm5IvL6kobgCcdICR",
    "displayName": "Bank for real estate"
  },
  {
    "date": "2017-11-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2017-11",
    "Date (Year)": "2017",
    "category": "deposits",
    "accountId": "-b3jqm5IvL6kobgCcdICR",
    "accountName": "Bank for real estate",
    "assetId": "-b3jqm5IvL6kobgCcdICR",
    "displayName": "Bank for real estate"
  },
  {
    "date": "2017-12-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2017-12",
    "Date (Year)": "2017",
    "category": "deposits",
    "accountId": "-b3jqm5IvL6kobgCcdICR",
    "accountName": "Bank for real estate",
    "assetId": "-b3jqm5IvL6kobgCcdICR",
    "displayName": "Bank for real estate"
  },
  {
    "date": "2018-01-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2018-01",
    "Date (Year)": "2018",
    "category": "deposits",
    "accountId": "-b3jqm5IvL6kobgCcdICR",
    "accountName": "Bank for real estate",
    "assetId": "-b3jqm5IvL6kobgCcdICR",
    "displayName": "Bank for real estate"
  },
  {
    "date": "2018-02-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2018-02",
    "Date (Year)": "2018",
    "category": "deposits",
    "accountId": "-b3jqm5IvL6kobgCcdICR",
    "accountName": "Bank for real estate",
    "assetId": "-b3jqm5IvL6kobgCcdICR",
    "displayName": "Bank for real estate"
  },
  {
    "date": "2018-03-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2018-03",
    "Date (Year)": "2018",
    "category": "deposits",
    "accountId": "-b3jqm5IvL6kobgCcdICR",
    "accountName": "Bank for real estate",
    "assetId": "-b3jqm5IvL6kobgCcdICR",
    "displayName": "Bank for real estate"
  },
  {
    "date": "2018-04-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2018-04",
    "Date (Year)": "2018",
    "category": "deposits",
    "accountId": "-b3jqm5IvL6kobgCcdICR",
    "accountName": "Bank for real estate",
    "assetId": "-b3jqm5IvL6kobgCcdICR",
    "displayName": "Bank for real estate"
  },
  {
    "date": "2018-05-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2018-05",
    "Date (Year)": "2018",
    "category": "deposits",
    "accountId": "-b3jqm5IvL6kobgCcdICR",
    "accountName": "Bank for real estate",
    "assetId": "-b3jqm5IvL6kobgCcdICR",
    "displayName": "Bank for real estate"
  },
  {
    "date": "2018-06-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 550000,
    "inflowType": "inflow",
    "inflowValue": 550000,
    "Date (Month)": "2018-06",
    "Date (Year)": "2018",
    "category": "deposits",
    "accountId": "-b3jqm5IvL6kobgCcdICR",
    "accountName": "Bank for real estate",
    "assetId": "-b3jqm5IvL6kobgCcdICR",
    "displayName": "Bank for real estate"
  },
  {
    "date": "2018-07-01T00:00:00.000Z",
    "value": 550000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2018-07",
    "Date (Year)": "2018",
    "category": "deposits",
    "accountId": "-b3jqm5IvL6kobgCcdICR",
    "accountName": "Bank for real estate",
    "assetId": "-b3jqm5IvL6kobgCcdICR",
    "displayName": "Bank for real estate"
  },
  {
    "date": "2018-08-01T00:00:00.000Z",
    "value": 550000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2018-08",
    "Date (Year)": "2018",
    "category": "deposits",
    "accountId": "-b3jqm5IvL6kobgCcdICR",
    "accountName": "Bank for real estate",
    "assetId": "-b3jqm5IvL6kobgCcdICR",
    "displayName": "Bank for real estate"
  },
  {
    "date": "2018-09-01T00:00:00.000Z",
    "value": 550000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2018-09",
    "Date (Year)": "2018",
    "category": "deposits",
    "accountId": "-b3jqm5IvL6kobgCcdICR",
    "accountName": "Bank for real estate",
    "assetId": "-b3jqm5IvL6kobgCcdICR",
    "displayName": "Bank for real estate"
  },
  {
    "date": "2018-10-01T00:00:00.000Z",
    "value": 550000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2018-10",
    "Date (Year)": "2018",
    "category": "deposits",
    "accountId": "-b3jqm5IvL6kobgCcdICR",
    "accountName": "Bank for real estate",
    "assetId": "-b3jqm5IvL6kobgCcdICR",
    "displayName": "Bank for real estate"
  },
  {
    "date": "2018-11-01T00:00:00.000Z",
    "value": 550000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2018-11",
    "Date (Year)": "2018",
    "category": "deposits",
    "accountId": "-b3jqm5IvL6kobgCcdICR",
    "accountName": "Bank for real estate",
    "assetId": "-b3jqm5IvL6kobgCcdICR",
    "displayName": "Bank for real estate"
  },
  {
    "date": "2018-12-01T00:00:00.000Z",
    "value": 550000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2018-12",
    "Date (Year)": "2018",
    "category": "deposits",
    "accountId": "-b3jqm5IvL6kobgCcdICR",
    "accountName": "Bank for real estate",
    "assetId": "-b3jqm5IvL6kobgCcdICR",
    "displayName": "Bank for real estate"
  },
  {
    "date": "2019-01-01T00:00:00.000Z",
    "value": 550000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2019-01",
    "Date (Year)": "2019",
    "category": "deposits",
    "accountId": "-b3jqm5IvL6kobgCcdICR",
    "accountName": "Bank for real estate",
    "assetId": "-b3jqm5IvL6kobgCcdICR",
    "displayName": "Bank for real estate"
  },
  {
    "date": "2019-02-01T00:00:00.000Z",
    "value": 550000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2019-02",
    "Date (Year)": "2019",
    "category": "deposits",
    "accountId": "-b3jqm5IvL6kobgCcdICR",
    "accountName": "Bank for real estate",
    "assetId": "-b3jqm5IvL6kobgCcdICR",
    "displayName": "Bank for real estate"
  },
  {
    "date": "2019-03-01T00:00:00.000Z",
    "value": 550000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2019-03",
    "Date (Year)": "2019",
    "category": "deposits",
    "accountId": "-b3jqm5IvL6kobgCcdICR",
    "accountName": "Bank for real estate",
    "assetId": "-b3jqm5IvL6kobgCcdICR",
    "displayName": "Bank for real estate"
  },
  {
    "date": "2019-04-01T00:00:00.000Z",
    "value": 550000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2019-04",
    "Date (Year)": "2019",
    "category": "deposits",
    "accountId": "-b3jqm5IvL6kobgCcdICR",
    "accountName": "Bank for real estate",
    "assetId": "-b3jqm5IvL6kobgCcdICR",
    "displayName": "Bank for real estate"
  },
  {
    "date": "2019-05-01T00:00:00.000Z",
    "value": 550000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2019-05",
    "Date (Year)": "2019",
    "category": "deposits",
    "accountId": "-b3jqm5IvL6kobgCcdICR",
    "accountName": "Bank for real estate",
    "assetId": "-b3jqm5IvL6kobgCcdICR",
    "displayName": "Bank for real estate"
  },
  {
    "date": "2019-06-01T00:00:00.000Z",
    "value": 550000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2019-06",
    "Date (Year)": "2019",
    "category": "deposits",
    "accountId": "-b3jqm5IvL6kobgCcdICR",
    "accountName": "Bank for real estate",
    "assetId": "-b3jqm5IvL6kobgCcdICR",
    "displayName": "Bank for real estate"
  },
  {
    "date": "2019-07-01T00:00:00.000Z",
    "value": 550000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2019-07",
    "Date (Year)": "2019",
    "category": "deposits",
    "accountId": "-b3jqm5IvL6kobgCcdICR",
    "accountName": "Bank for real estate",
    "assetId": "-b3jqm5IvL6kobgCcdICR",
    "displayName": "Bank for real estate"
  },
  {
    "date": "2019-08-01T00:00:00.000Z",
    "value": 550000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2019-08",
    "Date (Year)": "2019",
    "category": "deposits",
    "accountId": "-b3jqm5IvL6kobgCcdICR",
    "accountName": "Bank for real estate",
    "assetId": "-b3jqm5IvL6kobgCcdICR",
    "displayName": "Bank for real estate"
  },
  {
    "date": "2019-09-01T00:00:00.000Z",
    "value": 550000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2019-09",
    "Date (Year)": "2019",
    "category": "deposits",
    "accountId": "-b3jqm5IvL6kobgCcdICR",
    "accountName": "Bank for real estate",
    "assetId": "-b3jqm5IvL6kobgCcdICR",
    "displayName": "Bank for real estate"
  },
  {
    "date": "2019-10-01T00:00:00.000Z",
    "value": 550000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2019-10",
    "Date (Year)": "2019",
    "category": "deposits",
    "accountId": "-b3jqm5IvL6kobgCcdICR",
    "accountName": "Bank for real estate",
    "assetId": "-b3jqm5IvL6kobgCcdICR",
    "displayName": "Bank for real estate"
  },
  {
    "date": "2019-11-01T00:00:00.000Z",
    "value": 550000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2019-11",
    "Date (Year)": "2019",
    "category": "deposits",
    "accountId": "-b3jqm5IvL6kobgCcdICR",
    "accountName": "Bank for real estate",
    "assetId": "-b3jqm5IvL6kobgCcdICR",
    "displayName": "Bank for real estate"
  },
  {
    "date": "2019-12-01T00:00:00.000Z",
    "value": 550000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2019-12",
    "Date (Year)": "2019",
    "category": "deposits",
    "accountId": "-b3jqm5IvL6kobgCcdICR",
    "accountName": "Bank for real estate",
    "assetId": "-b3jqm5IvL6kobgCcdICR",
    "displayName": "Bank for real estate"
  },
  {
    "date": "2020-01-01T00:00:00.000Z",
    "value": 550000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2020-01",
    "Date (Year)": "2020",
    "category": "deposits",
    "accountId": "-b3jqm5IvL6kobgCcdICR",
    "accountName": "Bank for real estate",
    "assetId": "-b3jqm5IvL6kobgCcdICR",
    "displayName": "Bank for real estate"
  },
  {
    "date": "2020-02-01T00:00:00.000Z",
    "value": 550000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2020-02",
    "Date (Year)": "2020",
    "category": "deposits",
    "accountId": "-b3jqm5IvL6kobgCcdICR",
    "accountName": "Bank for real estate",
    "assetId": "-b3jqm5IvL6kobgCcdICR",
    "displayName": "Bank for real estate"
  },
  {
    "date": "2020-03-01T00:00:00.000Z",
    "value": 550000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2020-03",
    "Date (Year)": "2020",
    "category": "deposits",
    "accountId": "-b3jqm5IvL6kobgCcdICR",
    "accountName": "Bank for real estate",
    "assetId": "-b3jqm5IvL6kobgCcdICR",
    "displayName": "Bank for real estate"
  },
  {
    "date": "2020-04-01T00:00:00.000Z",
    "value": 550000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2020-04",
    "Date (Year)": "2020",
    "category": "deposits",
    "accountId": "-b3jqm5IvL6kobgCcdICR",
    "accountName": "Bank for real estate",
    "assetId": "-b3jqm5IvL6kobgCcdICR",
    "displayName": "Bank for real estate"
  },
  {
    "date": "2020-05-01T00:00:00.000Z",
    "value": 550000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2020-05",
    "Date (Year)": "2020",
    "category": "deposits",
    "accountId": "-b3jqm5IvL6kobgCcdICR",
    "accountName": "Bank for real estate",
    "assetId": "-b3jqm5IvL6kobgCcdICR",
    "displayName": "Bank for real estate"
  },
  {
    "date": "2020-06-01T00:00:00.000Z",
    "value": 550000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2020-06",
    "Date (Year)": "2020",
    "category": "deposits",
    "accountId": "-b3jqm5IvL6kobgCcdICR",
    "accountName": "Bank for real estate",
    "assetId": "-b3jqm5IvL6kobgCcdICR",
    "displayName": "Bank for real estate"
  },
  {
    "date": "2020-07-01T00:00:00.000Z",
    "value": 550000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2020-07",
    "Date (Year)": "2020",
    "category": "deposits",
    "accountId": "-b3jqm5IvL6kobgCcdICR",
    "accountName": "Bank for real estate",
    "assetId": "-b3jqm5IvL6kobgCcdICR",
    "displayName": "Bank for real estate"
  },
  {
    "date": "2020-08-01T00:00:00.000Z",
    "value": 550000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2020-08",
    "Date (Year)": "2020",
    "category": "deposits",
    "accountId": "-b3jqm5IvL6kobgCcdICR",
    "accountName": "Bank for real estate",
    "assetId": "-b3jqm5IvL6kobgCcdICR",
    "displayName": "Bank for real estate"
  },
  {
    "date": "2020-09-01T00:00:00.000Z",
    "value": 550000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2020-09",
    "Date (Year)": "2020",
    "category": "deposits",
    "accountId": "-b3jqm5IvL6kobgCcdICR",
    "accountName": "Bank for real estate",
    "assetId": "-b3jqm5IvL6kobgCcdICR",
    "displayName": "Bank for real estate"
  },
  {
    "date": "2020-10-01T00:00:00.000Z",
    "value": 550000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2020-10",
    "Date (Year)": "2020",
    "category": "deposits",
    "accountId": "-b3jqm5IvL6kobgCcdICR",
    "accountName": "Bank for real estate",
    "assetId": "-b3jqm5IvL6kobgCcdICR",
    "displayName": "Bank for real estate"
  },
  {
    "date": "2020-11-01T00:00:00.000Z",
    "value": 550000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2020-11",
    "Date (Year)": "2020",
    "category": "deposits",
    "accountId": "-b3jqm5IvL6kobgCcdICR",
    "accountName": "Bank for real estate",
    "assetId": "-b3jqm5IvL6kobgCcdICR",
    "displayName": "Bank for real estate"
  },
  {
    "date": "2020-12-01T00:00:00.000Z",
    "value": 550000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2020-12",
    "Date (Year)": "2020",
    "category": "deposits",
    "accountId": "-b3jqm5IvL6kobgCcdICR",
    "accountName": "Bank for real estate",
    "assetId": "-b3jqm5IvL6kobgCcdICR",
    "displayName": "Bank for real estate"
  },
  {
    "date": "2021-01-01T00:00:00.000Z",
    "value": 550000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2021-01",
    "Date (Year)": "2021",
    "category": "deposits",
    "accountId": "-b3jqm5IvL6kobgCcdICR",
    "accountName": "Bank for real estate",
    "assetId": "-b3jqm5IvL6kobgCcdICR",
    "displayName": "Bank for real estate"
  },
  {
    "date": "2021-02-01T00:00:00.000Z",
    "value": 550000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2021-02",
    "Date (Year)": "2021",
    "category": "deposits",
    "accountId": "-b3jqm5IvL6kobgCcdICR",
    "accountName": "Bank for real estate",
    "assetId": "-b3jqm5IvL6kobgCcdICR",
    "displayName": "Bank for real estate"
  },
  {
    "date": "2021-03-01T00:00:00.000Z",
    "value": 550000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2021-03",
    "Date (Year)": "2021",
    "category": "deposits",
    "accountId": "-b3jqm5IvL6kobgCcdICR",
    "accountName": "Bank for real estate",
    "assetId": "-b3jqm5IvL6kobgCcdICR",
    "displayName": "Bank for real estate"
  },
  {
    "date": "2021-04-01T00:00:00.000Z",
    "value": 550000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2021-04",
    "Date (Year)": "2021",
    "category": "deposits",
    "accountId": "-b3jqm5IvL6kobgCcdICR",
    "accountName": "Bank for real estate",
    "assetId": "-b3jqm5IvL6kobgCcdICR",
    "displayName": "Bank for real estate"
  },
  {
    "date": "2021-05-01T00:00:00.000Z",
    "value": 550000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2021-05",
    "Date (Year)": "2021",
    "category": "deposits",
    "accountId": "-b3jqm5IvL6kobgCcdICR",
    "accountName": "Bank for real estate",
    "assetId": "-b3jqm5IvL6kobgCcdICR",
    "displayName": "Bank for real estate"
  },
  {
    "date": "2021-06-01T00:00:00.000Z",
    "value": 550000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2021-06",
    "Date (Year)": "2021",
    "category": "deposits",
    "accountId": "-b3jqm5IvL6kobgCcdICR",
    "accountName": "Bank for real estate",
    "assetId": "-b3jqm5IvL6kobgCcdICR",
    "displayName": "Bank for real estate"
  },
  {
    "date": "2021-07-01T00:00:00.000Z",
    "value": 550000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2021-07",
    "Date (Year)": "2021",
    "category": "deposits",
    "accountId": "-b3jqm5IvL6kobgCcdICR",
    "accountName": "Bank for real estate",
    "assetId": "-b3jqm5IvL6kobgCcdICR",
    "displayName": "Bank for real estate"
  },
  {
    "date": "2021-08-01T00:00:00.000Z",
    "value": 550000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2021-08",
    "Date (Year)": "2021",
    "category": "deposits",
    "accountId": "-b3jqm5IvL6kobgCcdICR",
    "accountName": "Bank for real estate",
    "assetId": "-b3jqm5IvL6kobgCcdICR",
    "displayName": "Bank for real estate"
  },
  {
    "date": "2021-09-01T00:00:00.000Z",
    "value": 550000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2021-09",
    "Date (Year)": "2021",
    "category": "deposits",
    "accountId": "-b3jqm5IvL6kobgCcdICR",
    "accountName": "Bank for real estate",
    "assetId": "-b3jqm5IvL6kobgCcdICR",
    "displayName": "Bank for real estate"
  },
  {
    "date": "2021-10-01T00:00:00.000Z",
    "value": 550000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2021-10",
    "Date (Year)": "2021",
    "category": "deposits",
    "accountId": "-b3jqm5IvL6kobgCcdICR",
    "accountName": "Bank for real estate",
    "assetId": "-b3jqm5IvL6kobgCcdICR",
    "displayName": "Bank for real estate"
  },
  {
    "date": "2021-11-01T00:00:00.000Z",
    "value": 550000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2021-11",
    "Date (Year)": "2021",
    "category": "deposits",
    "accountId": "-b3jqm5IvL6kobgCcdICR",
    "accountName": "Bank for real estate",
    "assetId": "-b3jqm5IvL6kobgCcdICR",
    "displayName": "Bank for real estate"
  },
  {
    "date": "2021-12-01T00:00:00.000Z",
    "value": 550000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2021-12",
    "Date (Year)": "2021",
    "category": "deposits",
    "accountId": "-b3jqm5IvL6kobgCcdICR",
    "accountName": "Bank for real estate",
    "assetId": "-b3jqm5IvL6kobgCcdICR",
    "displayName": "Bank for real estate"
  },
  {
    "date": "2022-01-01T00:00:00.000Z",
    "value": 550000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2022-01",
    "Date (Year)": "2022",
    "category": "deposits",
    "accountId": "-b3jqm5IvL6kobgCcdICR",
    "accountName": "Bank for real estate",
    "assetId": "-b3jqm5IvL6kobgCcdICR",
    "displayName": "Bank for real estate"
  },
  {
    "date": "2022-02-01T00:00:00.000Z",
    "value": 550000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2022-02",
    "Date (Year)": "2022",
    "category": "deposits",
    "accountId": "-b3jqm5IvL6kobgCcdICR",
    "accountName": "Bank for real estate",
    "assetId": "-b3jqm5IvL6kobgCcdICR",
    "displayName": "Bank for real estate"
  },
  {
    "date": "2022-03-01T00:00:00.000Z",
    "value": 550000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2022-03",
    "Date (Year)": "2022",
    "category": "deposits",
    "accountId": "-b3jqm5IvL6kobgCcdICR",
    "accountName": "Bank for real estate",
    "assetId": "-b3jqm5IvL6kobgCcdICR",
    "displayName": "Bank for real estate"
  },
  {
    "date": "2022-04-01T00:00:00.000Z",
    "value": 550000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2022-04",
    "Date (Year)": "2022",
    "category": "deposits",
    "accountId": "-b3jqm5IvL6kobgCcdICR",
    "accountName": "Bank for real estate",
    "assetId": "-b3jqm5IvL6kobgCcdICR",
    "displayName": "Bank for real estate"
  },
  {
    "date": "2022-05-01T00:00:00.000Z",
    "value": 550000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2022-05",
    "Date (Year)": "2022",
    "category": "deposits",
    "accountId": "-b3jqm5IvL6kobgCcdICR",
    "accountName": "Bank for real estate",
    "assetId": "-b3jqm5IvL6kobgCcdICR",
    "displayName": "Bank for real estate"
  },
  {
    "date": "2022-06-01T00:00:00.000Z",
    "value": 550000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2022-06",
    "Date (Year)": "2022",
    "category": "deposits",
    "accountId": "-b3jqm5IvL6kobgCcdICR",
    "accountName": "Bank for real estate",
    "assetId": "-b3jqm5IvL6kobgCcdICR",
    "displayName": "Bank for real estate"
  },
  {
    "date": "2022-07-01T00:00:00.000Z",
    "value": 550000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2022-07",
    "Date (Year)": "2022",
    "category": "deposits",
    "accountId": "-b3jqm5IvL6kobgCcdICR",
    "accountName": "Bank for real estate",
    "assetId": "-b3jqm5IvL6kobgCcdICR",
    "displayName": "Bank for real estate"
  },
  {
    "date": "2022-08-01T00:00:00.000Z",
    "value": 550000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2022-08",
    "Date (Year)": "2022",
    "category": "deposits",
    "accountId": "-b3jqm5IvL6kobgCcdICR",
    "accountName": "Bank for real estate",
    "assetId": "-b3jqm5IvL6kobgCcdICR",
    "displayName": "Bank for real estate"
  },
  {
    "date": "2022-09-01T00:00:00.000Z",
    "value": 550000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2022-09",
    "Date (Year)": "2022",
    "category": "deposits",
    "accountId": "-b3jqm5IvL6kobgCcdICR",
    "accountName": "Bank for real estate",
    "assetId": "-b3jqm5IvL6kobgCcdICR",
    "displayName": "Bank for real estate"
  },
  {
    "date": "2022-10-01T00:00:00.000Z",
    "value": 550000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2022-10",
    "Date (Year)": "2022",
    "category": "deposits",
    "accountId": "-b3jqm5IvL6kobgCcdICR",
    "accountName": "Bank for real estate",
    "assetId": "-b3jqm5IvL6kobgCcdICR",
    "displayName": "Bank for real estate"
  },
  {
    "date": "2022-11-01T00:00:00.000Z",
    "value": 550000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2022-11",
    "Date (Year)": "2022",
    "category": "deposits",
    "accountId": "-b3jqm5IvL6kobgCcdICR",
    "accountName": "Bank for real estate",
    "assetId": "-b3jqm5IvL6kobgCcdICR",
    "displayName": "Bank for real estate"
  },
  {
    "date": "2022-12-01T00:00:00.000Z",
    "value": 550000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2022-12",
    "Date (Year)": "2022",
    "category": "deposits",
    "accountId": "-b3jqm5IvL6kobgCcdICR",
    "accountName": "Bank for real estate",
    "assetId": "-b3jqm5IvL6kobgCcdICR",
    "displayName": "Bank for real estate"
  },
  {
    "date": "2023-01-01T00:00:00.000Z",
    "value": 550000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2023-01",
    "Date (Year)": "2023",
    "category": "deposits",
    "accountId": "-b3jqm5IvL6kobgCcdICR",
    "accountName": "Bank for real estate",
    "assetId": "-b3jqm5IvL6kobgCcdICR",
    "displayName": "Bank for real estate"
  },
  {
    "date": "2023-02-01T00:00:00.000Z",
    "value": 550000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2023-02",
    "Date (Year)": "2023",
    "category": "deposits",
    "accountId": "-b3jqm5IvL6kobgCcdICR",
    "accountName": "Bank for real estate",
    "assetId": "-b3jqm5IvL6kobgCcdICR",
    "displayName": "Bank for real estate"
  },
  {
    "date": "2023-03-01T00:00:00.000Z",
    "value": 550000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2023-03",
    "Date (Year)": "2023",
    "category": "deposits",
    "accountId": "-b3jqm5IvL6kobgCcdICR",
    "accountName": "Bank for real estate",
    "assetId": "-b3jqm5IvL6kobgCcdICR",
    "displayName": "Bank for real estate"
  },
  {
    "date": "2023-04-01T00:00:00.000Z",
    "value": 549456.75,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2023-04",
    "Date (Year)": "2023",
    "category": "deposits",
    "accountId": "-b3jqm5IvL6kobgCcdICR",
    "accountName": "Bank for real estate",
    "assetId": "-b3jqm5IvL6kobgCcdICR",
    "displayName": "Bank for real estate"
  },
  {
    "date": "2023-05-01T00:00:00.000Z",
    "value": 549456.75,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2023-05",
    "Date (Year)": "2023",
    "category": "deposits",
    "accountId": "-b3jqm5IvL6kobgCcdICR",
    "accountName": "Bank for real estate",
    "assetId": "-b3jqm5IvL6kobgCcdICR",
    "displayName": "Bank for real estate"
  },
  {
    "date": "2023-06-01T00:00:00.000Z",
    "value": 549456.75,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2023-06",
    "Date (Year)": "2023",
    "category": "deposits",
    "accountId": "-b3jqm5IvL6kobgCcdICR",
    "accountName": "Bank for real estate",
    "assetId": "-b3jqm5IvL6kobgCcdICR",
    "displayName": "Bank for real estate"
  },
  {
    "date": "2023-07-01T00:00:00.000Z",
    "value": 549456.75,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2023-07",
    "Date (Year)": "2023",
    "category": "deposits",
    "accountId": "-b3jqm5IvL6kobgCcdICR",
    "accountName": "Bank for real estate",
    "assetId": "-b3jqm5IvL6kobgCcdICR",
    "displayName": "Bank for real estate"
  },
  {
    "date": "2023-08-01T00:00:00.000Z",
    "value": 549456.75,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2023-08",
    "Date (Year)": "2023",
    "category": "deposits",
    "accountId": "-b3jqm5IvL6kobgCcdICR",
    "accountName": "Bank for real estate",
    "assetId": "-b3jqm5IvL6kobgCcdICR",
    "displayName": "Bank for real estate"
  },
  {
    "date": "2023-09-01T00:00:00.000Z",
    "value": 549456.75,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2023-09",
    "Date (Year)": "2023",
    "category": "deposits",
    "accountId": "-b3jqm5IvL6kobgCcdICR",
    "accountName": "Bank for real estate",
    "assetId": "-b3jqm5IvL6kobgCcdICR",
    "displayName": "Bank for real estate"
  },
  {
    "date": "2023-10-01T00:00:00.000Z",
    "value": 549456.75,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2023-10",
    "Date (Year)": "2023",
    "category": "deposits",
    "accountId": "-b3jqm5IvL6kobgCcdICR",
    "accountName": "Bank for real estate",
    "assetId": "-b3jqm5IvL6kobgCcdICR",
    "displayName": "Bank for real estate"
  },
  {
    "date": "2023-11-01T00:00:00.000Z",
    "value": 549456.75,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2023-11",
    "Date (Year)": "2023",
    "category": "deposits",
    "accountId": "-b3jqm5IvL6kobgCcdICR",
    "accountName": "Bank for real estate",
    "assetId": "-b3jqm5IvL6kobgCcdICR",
    "displayName": "Bank for real estate"
  },
  {
    "date": "2023-12-01T00:00:00.000Z",
    "value": 549456.75,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2023-12",
    "Date (Year)": "2023",
    "category": "deposits",
    "accountId": "-b3jqm5IvL6kobgCcdICR",
    "accountName": "Bank for real estate",
    "assetId": "-b3jqm5IvL6kobgCcdICR",
    "displayName": "Bank for real estate"
  },
  {
    "date": "2012-09-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2012-09",
    "Date (Year)": "2012",
    "category": "deposits",
    "accountId": "AUAMQVhQaeLIHriycINeL",
    "accountName": "Bank for brokerage",
    "assetId": "AUAMQVhQaeLIHriycINeL",
    "displayName": "Bank for brokerage"
  },
  {
    "date": "2012-10-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2012-10",
    "Date (Year)": "2012",
    "category": "deposits",
    "accountId": "AUAMQVhQaeLIHriycINeL",
    "accountName": "Bank for brokerage",
    "assetId": "AUAMQVhQaeLIHriycINeL",
    "displayName": "Bank for brokerage"
  },
  {
    "date": "2012-11-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2012-11",
    "Date (Year)": "2012",
    "category": "deposits",
    "accountId": "AUAMQVhQaeLIHriycINeL",
    "accountName": "Bank for brokerage",
    "assetId": "AUAMQVhQaeLIHriycINeL",
    "displayName": "Bank for brokerage"
  },
  {
    "date": "2012-12-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2012-12",
    "Date (Year)": "2012",
    "category": "deposits",
    "accountId": "AUAMQVhQaeLIHriycINeL",
    "accountName": "Bank for brokerage",
    "assetId": "AUAMQVhQaeLIHriycINeL",
    "displayName": "Bank for brokerage"
  },
  {
    "date": "2013-01-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2013-01",
    "Date (Year)": "2013",
    "category": "deposits",
    "accountId": "AUAMQVhQaeLIHriycINeL",
    "accountName": "Bank for brokerage",
    "assetId": "AUAMQVhQaeLIHriycINeL",
    "displayName": "Bank for brokerage"
  },
  {
    "date": "2013-02-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2013-02",
    "Date (Year)": "2013",
    "category": "deposits",
    "accountId": "AUAMQVhQaeLIHriycINeL",
    "accountName": "Bank for brokerage",
    "assetId": "AUAMQVhQaeLIHriycINeL",
    "displayName": "Bank for brokerage"
  },
  {
    "date": "2013-03-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2013-03",
    "Date (Year)": "2013",
    "category": "deposits",
    "accountId": "AUAMQVhQaeLIHriycINeL",
    "accountName": "Bank for brokerage",
    "assetId": "AUAMQVhQaeLIHriycINeL",
    "displayName": "Bank for brokerage"
  },
  {
    "date": "2013-04-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2013-04",
    "Date (Year)": "2013",
    "category": "deposits",
    "accountId": "AUAMQVhQaeLIHriycINeL",
    "accountName": "Bank for brokerage",
    "assetId": "AUAMQVhQaeLIHriycINeL",
    "displayName": "Bank for brokerage"
  },
  {
    "date": "2013-05-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2013-05",
    "Date (Year)": "2013",
    "category": "deposits",
    "accountId": "AUAMQVhQaeLIHriycINeL",
    "accountName": "Bank for brokerage",
    "assetId": "AUAMQVhQaeLIHriycINeL",
    "displayName": "Bank for brokerage"
  },
  {
    "date": "2013-06-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2013-06",
    "Date (Year)": "2013",
    "category": "deposits",
    "accountId": "AUAMQVhQaeLIHriycINeL",
    "accountName": "Bank for brokerage",
    "assetId": "AUAMQVhQaeLIHriycINeL",
    "displayName": "Bank for brokerage"
  },
  {
    "date": "2013-07-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2013-07",
    "Date (Year)": "2013",
    "category": "deposits",
    "accountId": "AUAMQVhQaeLIHriycINeL",
    "accountName": "Bank for brokerage",
    "assetId": "AUAMQVhQaeLIHriycINeL",
    "displayName": "Bank for brokerage"
  },
  {
    "date": "2013-08-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2013-08",
    "Date (Year)": "2013",
    "category": "deposits",
    "accountId": "AUAMQVhQaeLIHriycINeL",
    "accountName": "Bank for brokerage",
    "assetId": "AUAMQVhQaeLIHriycINeL",
    "displayName": "Bank for brokerage"
  },
  {
    "date": "2013-09-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2013-09",
    "Date (Year)": "2013",
    "category": "deposits",
    "accountId": "AUAMQVhQaeLIHriycINeL",
    "accountName": "Bank for brokerage",
    "assetId": "AUAMQVhQaeLIHriycINeL",
    "displayName": "Bank for brokerage"
  },
  {
    "date": "2013-10-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2013-10",
    "Date (Year)": "2013",
    "category": "deposits",
    "accountId": "AUAMQVhQaeLIHriycINeL",
    "accountName": "Bank for brokerage",
    "assetId": "AUAMQVhQaeLIHriycINeL",
    "displayName": "Bank for brokerage"
  },
  {
    "date": "2013-11-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2013-11",
    "Date (Year)": "2013",
    "category": "deposits",
    "accountId": "AUAMQVhQaeLIHriycINeL",
    "accountName": "Bank for brokerage",
    "assetId": "AUAMQVhQaeLIHriycINeL",
    "displayName": "Bank for brokerage"
  },
  {
    "date": "2013-12-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2013-12",
    "Date (Year)": "2013",
    "category": "deposits",
    "accountId": "AUAMQVhQaeLIHriycINeL",
    "accountName": "Bank for brokerage",
    "assetId": "AUAMQVhQaeLIHriycINeL",
    "displayName": "Bank for brokerage"
  },
  {
    "date": "2014-01-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2014-01",
    "Date (Year)": "2014",
    "category": "deposits",
    "accountId": "AUAMQVhQaeLIHriycINeL",
    "accountName": "Bank for brokerage",
    "assetId": "AUAMQVhQaeLIHriycINeL",
    "displayName": "Bank for brokerage"
  },
  {
    "date": "2014-02-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2014-02",
    "Date (Year)": "2014",
    "category": "deposits",
    "accountId": "AUAMQVhQaeLIHriycINeL",
    "accountName": "Bank for brokerage",
    "assetId": "AUAMQVhQaeLIHriycINeL",
    "displayName": "Bank for brokerage"
  },
  {
    "date": "2014-03-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2014-03",
    "Date (Year)": "2014",
    "category": "deposits",
    "accountId": "AUAMQVhQaeLIHriycINeL",
    "accountName": "Bank for brokerage",
    "assetId": "AUAMQVhQaeLIHriycINeL",
    "displayName": "Bank for brokerage"
  },
  {
    "date": "2014-04-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2014-04",
    "Date (Year)": "2014",
    "category": "deposits",
    "accountId": "AUAMQVhQaeLIHriycINeL",
    "accountName": "Bank for brokerage",
    "assetId": "AUAMQVhQaeLIHriycINeL",
    "displayName": "Bank for brokerage"
  },
  {
    "date": "2014-05-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2014-05",
    "Date (Year)": "2014",
    "category": "deposits",
    "accountId": "AUAMQVhQaeLIHriycINeL",
    "accountName": "Bank for brokerage",
    "assetId": "AUAMQVhQaeLIHriycINeL",
    "displayName": "Bank for brokerage"
  },
  {
    "date": "2014-06-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2014-06",
    "Date (Year)": "2014",
    "category": "deposits",
    "accountId": "AUAMQVhQaeLIHriycINeL",
    "accountName": "Bank for brokerage",
    "assetId": "AUAMQVhQaeLIHriycINeL",
    "displayName": "Bank for brokerage"
  },
  {
    "date": "2014-07-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2014-07",
    "Date (Year)": "2014",
    "category": "deposits",
    "accountId": "AUAMQVhQaeLIHriycINeL",
    "accountName": "Bank for brokerage",
    "assetId": "AUAMQVhQaeLIHriycINeL",
    "displayName": "Bank for brokerage"
  },
  {
    "date": "2014-08-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2014-08",
    "Date (Year)": "2014",
    "category": "deposits",
    "accountId": "AUAMQVhQaeLIHriycINeL",
    "accountName": "Bank for brokerage",
    "assetId": "AUAMQVhQaeLIHriycINeL",
    "displayName": "Bank for brokerage"
  },
  {
    "date": "2014-09-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2014-09",
    "Date (Year)": "2014",
    "category": "deposits",
    "accountId": "AUAMQVhQaeLIHriycINeL",
    "accountName": "Bank for brokerage",
    "assetId": "AUAMQVhQaeLIHriycINeL",
    "displayName": "Bank for brokerage"
  },
  {
    "date": "2014-10-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2014-10",
    "Date (Year)": "2014",
    "category": "deposits",
    "accountId": "AUAMQVhQaeLIHriycINeL",
    "accountName": "Bank for brokerage",
    "assetId": "AUAMQVhQaeLIHriycINeL",
    "displayName": "Bank for brokerage"
  },
  {
    "date": "2014-11-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2014-11",
    "Date (Year)": "2014",
    "category": "deposits",
    "accountId": "AUAMQVhQaeLIHriycINeL",
    "accountName": "Bank for brokerage",
    "assetId": "AUAMQVhQaeLIHriycINeL",
    "displayName": "Bank for brokerage"
  },
  {
    "date": "2014-12-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2014-12",
    "Date (Year)": "2014",
    "category": "deposits",
    "accountId": "AUAMQVhQaeLIHriycINeL",
    "accountName": "Bank for brokerage",
    "assetId": "AUAMQVhQaeLIHriycINeL",
    "displayName": "Bank for brokerage"
  },
  {
    "date": "2015-01-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2015-01",
    "Date (Year)": "2015",
    "category": "deposits",
    "accountId": "AUAMQVhQaeLIHriycINeL",
    "accountName": "Bank for brokerage",
    "assetId": "AUAMQVhQaeLIHriycINeL",
    "displayName": "Bank for brokerage"
  },
  {
    "date": "2015-02-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2015-02",
    "Date (Year)": "2015",
    "category": "deposits",
    "accountId": "AUAMQVhQaeLIHriycINeL",
    "accountName": "Bank for brokerage",
    "assetId": "AUAMQVhQaeLIHriycINeL",
    "displayName": "Bank for brokerage"
  },
  {
    "date": "2015-03-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2015-03",
    "Date (Year)": "2015",
    "category": "deposits",
    "accountId": "AUAMQVhQaeLIHriycINeL",
    "accountName": "Bank for brokerage",
    "assetId": "AUAMQVhQaeLIHriycINeL",
    "displayName": "Bank for brokerage"
  },
  {
    "date": "2015-04-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 100000,
    "inflowType": "inflow",
    "inflowValue": 100000,
    "Date (Month)": "2015-04",
    "Date (Year)": "2015",
    "category": "deposits",
    "accountId": "AUAMQVhQaeLIHriycINeL",
    "accountName": "Bank for brokerage",
    "assetId": "AUAMQVhQaeLIHriycINeL",
    "displayName": "Bank for brokerage"
  },
  {
    "date": "2015-05-01T00:00:00.000Z",
    "value": 100000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2015-05",
    "Date (Year)": "2015",
    "category": "deposits",
    "accountId": "AUAMQVhQaeLIHriycINeL",
    "accountName": "Bank for brokerage",
    "assetId": "AUAMQVhQaeLIHriycINeL",
    "displayName": "Bank for brokerage"
  },
  {
    "date": "2015-06-01T00:00:00.000Z",
    "value": 100000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2015-06",
    "Date (Year)": "2015",
    "category": "deposits",
    "accountId": "AUAMQVhQaeLIHriycINeL",
    "accountName": "Bank for brokerage",
    "assetId": "AUAMQVhQaeLIHriycINeL",
    "displayName": "Bank for brokerage"
  },
  {
    "date": "2015-07-01T00:00:00.000Z",
    "value": 100000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2015-07",
    "Date (Year)": "2015",
    "category": "deposits",
    "accountId": "AUAMQVhQaeLIHriycINeL",
    "accountName": "Bank for brokerage",
    "assetId": "AUAMQVhQaeLIHriycINeL",
    "displayName": "Bank for brokerage"
  },
  {
    "date": "2015-08-01T00:00:00.000Z",
    "value": 100000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2015-08",
    "Date (Year)": "2015",
    "category": "deposits",
    "accountId": "AUAMQVhQaeLIHriycINeL",
    "accountName": "Bank for brokerage",
    "assetId": "AUAMQVhQaeLIHriycINeL",
    "displayName": "Bank for brokerage"
  },
  {
    "date": "2015-09-01T00:00:00.000Z",
    "value": 100000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2015-09",
    "Date (Year)": "2015",
    "category": "deposits",
    "accountId": "AUAMQVhQaeLIHriycINeL",
    "accountName": "Bank for brokerage",
    "assetId": "AUAMQVhQaeLIHriycINeL",
    "displayName": "Bank for brokerage"
  },
  {
    "date": "2015-10-01T00:00:00.000Z",
    "value": 100000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2015-10",
    "Date (Year)": "2015",
    "category": "deposits",
    "accountId": "AUAMQVhQaeLIHriycINeL",
    "accountName": "Bank for brokerage",
    "assetId": "AUAMQVhQaeLIHriycINeL",
    "displayName": "Bank for brokerage"
  },
  {
    "date": "2015-11-01T00:00:00.000Z",
    "value": 100000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2015-11",
    "Date (Year)": "2015",
    "category": "deposits",
    "accountId": "AUAMQVhQaeLIHriycINeL",
    "accountName": "Bank for brokerage",
    "assetId": "AUAMQVhQaeLIHriycINeL",
    "displayName": "Bank for brokerage"
  },
  {
    "date": "2015-12-01T00:00:00.000Z",
    "value": 100000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2015-12",
    "Date (Year)": "2015",
    "category": "deposits",
    "accountId": "AUAMQVhQaeLIHriycINeL",
    "accountName": "Bank for brokerage",
    "assetId": "AUAMQVhQaeLIHriycINeL",
    "displayName": "Bank for brokerage"
  },
  {
    "date": "2016-01-01T00:00:00.000Z",
    "value": 100000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2016-01",
    "Date (Year)": "2016",
    "category": "deposits",
    "accountId": "AUAMQVhQaeLIHriycINeL",
    "accountName": "Bank for brokerage",
    "assetId": "AUAMQVhQaeLIHriycINeL",
    "displayName": "Bank for brokerage"
  },
  {
    "date": "2016-02-01T00:00:00.000Z",
    "value": 100000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2016-02",
    "Date (Year)": "2016",
    "category": "deposits",
    "accountId": "AUAMQVhQaeLIHriycINeL",
    "accountName": "Bank for brokerage",
    "assetId": "AUAMQVhQaeLIHriycINeL",
    "displayName": "Bank for brokerage"
  },
  {
    "date": "2016-03-01T00:00:00.000Z",
    "value": 100000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2016-03",
    "Date (Year)": "2016",
    "category": "deposits",
    "accountId": "AUAMQVhQaeLIHriycINeL",
    "accountName": "Bank for brokerage",
    "assetId": "AUAMQVhQaeLIHriycINeL",
    "displayName": "Bank for brokerage"
  },
  {
    "date": "2016-04-01T00:00:00.000Z",
    "value": 100000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2016-04",
    "Date (Year)": "2016",
    "category": "deposits",
    "accountId": "AUAMQVhQaeLIHriycINeL",
    "accountName": "Bank for brokerage",
    "assetId": "AUAMQVhQaeLIHriycINeL",
    "displayName": "Bank for brokerage"
  },
  {
    "date": "2016-05-01T00:00:00.000Z",
    "value": 100000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2016-05",
    "Date (Year)": "2016",
    "category": "deposits",
    "accountId": "AUAMQVhQaeLIHriycINeL",
    "accountName": "Bank for brokerage",
    "assetId": "AUAMQVhQaeLIHriycINeL",
    "displayName": "Bank for brokerage"
  },
  {
    "date": "2016-06-01T00:00:00.000Z",
    "value": 100000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2016-06",
    "Date (Year)": "2016",
    "category": "deposits",
    "accountId": "AUAMQVhQaeLIHriycINeL",
    "accountName": "Bank for brokerage",
    "assetId": "AUAMQVhQaeLIHriycINeL",
    "displayName": "Bank for brokerage"
  },
  {
    "date": "2016-07-01T00:00:00.000Z",
    "value": 100000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2016-07",
    "Date (Year)": "2016",
    "category": "deposits",
    "accountId": "AUAMQVhQaeLIHriycINeL",
    "accountName": "Bank for brokerage",
    "assetId": "AUAMQVhQaeLIHriycINeL",
    "displayName": "Bank for brokerage"
  },
  {
    "date": "2016-08-01T00:00:00.000Z",
    "value": 100000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2016-08",
    "Date (Year)": "2016",
    "category": "deposits",
    "accountId": "AUAMQVhQaeLIHriycINeL",
    "accountName": "Bank for brokerage",
    "assetId": "AUAMQVhQaeLIHriycINeL",
    "displayName": "Bank for brokerage"
  },
  {
    "date": "2016-09-01T00:00:00.000Z",
    "value": 100000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2016-09",
    "Date (Year)": "2016",
    "category": "deposits",
    "accountId": "AUAMQVhQaeLIHriycINeL",
    "accountName": "Bank for brokerage",
    "assetId": "AUAMQVhQaeLIHriycINeL",
    "displayName": "Bank for brokerage"
  },
  {
    "date": "2016-10-01T00:00:00.000Z",
    "value": 100000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2016-10",
    "Date (Year)": "2016",
    "category": "deposits",
    "accountId": "AUAMQVhQaeLIHriycINeL",
    "accountName": "Bank for brokerage",
    "assetId": "AUAMQVhQaeLIHriycINeL",
    "displayName": "Bank for brokerage"
  },
  {
    "date": "2016-11-01T00:00:00.000Z",
    "value": 100000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2016-11",
    "Date (Year)": "2016",
    "category": "deposits",
    "accountId": "AUAMQVhQaeLIHriycINeL",
    "accountName": "Bank for brokerage",
    "assetId": "AUAMQVhQaeLIHriycINeL",
    "displayName": "Bank for brokerage"
  },
  {
    "date": "2016-12-01T00:00:00.000Z",
    "value": 100000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2016-12",
    "Date (Year)": "2016",
    "category": "deposits",
    "accountId": "AUAMQVhQaeLIHriycINeL",
    "accountName": "Bank for brokerage",
    "assetId": "AUAMQVhQaeLIHriycINeL",
    "displayName": "Bank for brokerage"
  },
  {
    "date": "2017-01-01T00:00:00.000Z",
    "value": 100000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2017-01",
    "Date (Year)": "2017",
    "category": "deposits",
    "accountId": "AUAMQVhQaeLIHriycINeL",
    "accountName": "Bank for brokerage",
    "assetId": "AUAMQVhQaeLIHriycINeL",
    "displayName": "Bank for brokerage"
  },
  {
    "date": "2017-02-01T00:00:00.000Z",
    "value": 100000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2017-02",
    "Date (Year)": "2017",
    "category": "deposits",
    "accountId": "AUAMQVhQaeLIHriycINeL",
    "accountName": "Bank for brokerage",
    "assetId": "AUAMQVhQaeLIHriycINeL",
    "displayName": "Bank for brokerage"
  },
  {
    "date": "2017-03-01T00:00:00.000Z",
    "value": 100000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2017-03",
    "Date (Year)": "2017",
    "category": "deposits",
    "accountId": "AUAMQVhQaeLIHriycINeL",
    "accountName": "Bank for brokerage",
    "assetId": "AUAMQVhQaeLIHriycINeL",
    "displayName": "Bank for brokerage"
  },
  {
    "date": "2017-04-01T00:00:00.000Z",
    "value": 100000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2017-04",
    "Date (Year)": "2017",
    "category": "deposits",
    "accountId": "AUAMQVhQaeLIHriycINeL",
    "accountName": "Bank for brokerage",
    "assetId": "AUAMQVhQaeLIHriycINeL",
    "displayName": "Bank for brokerage"
  },
  {
    "date": "2017-05-01T00:00:00.000Z",
    "value": 100000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2017-05",
    "Date (Year)": "2017",
    "category": "deposits",
    "accountId": "AUAMQVhQaeLIHriycINeL",
    "accountName": "Bank for brokerage",
    "assetId": "AUAMQVhQaeLIHriycINeL",
    "displayName": "Bank for brokerage"
  },
  {
    "date": "2017-06-01T00:00:00.000Z",
    "value": 100000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2017-06",
    "Date (Year)": "2017",
    "category": "deposits",
    "accountId": "AUAMQVhQaeLIHriycINeL",
    "accountName": "Bank for brokerage",
    "assetId": "AUAMQVhQaeLIHriycINeL",
    "displayName": "Bank for brokerage"
  },
  {
    "date": "2017-07-01T00:00:00.000Z",
    "value": 100000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2017-07",
    "Date (Year)": "2017",
    "category": "deposits",
    "accountId": "AUAMQVhQaeLIHriycINeL",
    "accountName": "Bank for brokerage",
    "assetId": "AUAMQVhQaeLIHriycINeL",
    "displayName": "Bank for brokerage"
  },
  {
    "date": "2017-08-01T00:00:00.000Z",
    "value": 100000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2017-08",
    "Date (Year)": "2017",
    "category": "deposits",
    "accountId": "AUAMQVhQaeLIHriycINeL",
    "accountName": "Bank for brokerage",
    "assetId": "AUAMQVhQaeLIHriycINeL",
    "displayName": "Bank for brokerage"
  },
  {
    "date": "2017-09-01T00:00:00.000Z",
    "value": 100000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2017-09",
    "Date (Year)": "2017",
    "category": "deposits",
    "accountId": "AUAMQVhQaeLIHriycINeL",
    "accountName": "Bank for brokerage",
    "assetId": "AUAMQVhQaeLIHriycINeL",
    "displayName": "Bank for brokerage"
  },
  {
    "date": "2017-10-01T00:00:00.000Z",
    "value": 100000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2017-10",
    "Date (Year)": "2017",
    "category": "deposits",
    "accountId": "AUAMQVhQaeLIHriycINeL",
    "accountName": "Bank for brokerage",
    "assetId": "AUAMQVhQaeLIHriycINeL",
    "displayName": "Bank for brokerage"
  },
  {
    "date": "2017-11-01T00:00:00.000Z",
    "value": 100000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2017-11",
    "Date (Year)": "2017",
    "category": "deposits",
    "accountId": "AUAMQVhQaeLIHriycINeL",
    "accountName": "Bank for brokerage",
    "assetId": "AUAMQVhQaeLIHriycINeL",
    "displayName": "Bank for brokerage"
  },
  {
    "date": "2017-12-01T00:00:00.000Z",
    "value": 100000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2017-12",
    "Date (Year)": "2017",
    "category": "deposits",
    "accountId": "AUAMQVhQaeLIHriycINeL",
    "accountName": "Bank for brokerage",
    "assetId": "AUAMQVhQaeLIHriycINeL",
    "displayName": "Bank for brokerage"
  },
  {
    "date": "2018-01-01T00:00:00.000Z",
    "value": 100000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2018-01",
    "Date (Year)": "2018",
    "category": "deposits",
    "accountId": "AUAMQVhQaeLIHriycINeL",
    "accountName": "Bank for brokerage",
    "assetId": "AUAMQVhQaeLIHriycINeL",
    "displayName": "Bank for brokerage"
  },
  {
    "date": "2018-02-01T00:00:00.000Z",
    "value": 100000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2018-02",
    "Date (Year)": "2018",
    "category": "deposits",
    "accountId": "AUAMQVhQaeLIHriycINeL",
    "accountName": "Bank for brokerage",
    "assetId": "AUAMQVhQaeLIHriycINeL",
    "displayName": "Bank for brokerage"
  },
  {
    "date": "2018-03-01T00:00:00.000Z",
    "value": 100000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2018-03",
    "Date (Year)": "2018",
    "category": "deposits",
    "accountId": "AUAMQVhQaeLIHriycINeL",
    "accountName": "Bank for brokerage",
    "assetId": "AUAMQVhQaeLIHriycINeL",
    "displayName": "Bank for brokerage"
  },
  {
    "date": "2018-04-01T00:00:00.000Z",
    "value": 100000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2018-04",
    "Date (Year)": "2018",
    "category": "deposits",
    "accountId": "AUAMQVhQaeLIHriycINeL",
    "accountName": "Bank for brokerage",
    "assetId": "AUAMQVhQaeLIHriycINeL",
    "displayName": "Bank for brokerage"
  },
  {
    "date": "2018-05-01T00:00:00.000Z",
    "value": 100000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2018-05",
    "Date (Year)": "2018",
    "category": "deposits",
    "accountId": "AUAMQVhQaeLIHriycINeL",
    "accountName": "Bank for brokerage",
    "assetId": "AUAMQVhQaeLIHriycINeL",
    "displayName": "Bank for brokerage"
  },
  {
    "date": "2018-06-01T00:00:00.000Z",
    "value": 100000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2018-06",
    "Date (Year)": "2018",
    "category": "deposits",
    "accountId": "AUAMQVhQaeLIHriycINeL",
    "accountName": "Bank for brokerage",
    "assetId": "AUAMQVhQaeLIHriycINeL",
    "displayName": "Bank for brokerage"
  },
  {
    "date": "2018-07-01T00:00:00.000Z",
    "value": 100000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2018-07",
    "Date (Year)": "2018",
    "category": "deposits",
    "accountId": "AUAMQVhQaeLIHriycINeL",
    "accountName": "Bank for brokerage",
    "assetId": "AUAMQVhQaeLIHriycINeL",
    "displayName": "Bank for brokerage"
  },
  {
    "date": "2018-08-01T00:00:00.000Z",
    "value": 100000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2018-08",
    "Date (Year)": "2018",
    "category": "deposits",
    "accountId": "AUAMQVhQaeLIHriycINeL",
    "accountName": "Bank for brokerage",
    "assetId": "AUAMQVhQaeLIHriycINeL",
    "displayName": "Bank for brokerage"
  },
  {
    "date": "2018-09-01T00:00:00.000Z",
    "value": 100000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2018-09",
    "Date (Year)": "2018",
    "category": "deposits",
    "accountId": "AUAMQVhQaeLIHriycINeL",
    "accountName": "Bank for brokerage",
    "assetId": "AUAMQVhQaeLIHriycINeL",
    "displayName": "Bank for brokerage"
  },
  {
    "date": "2018-10-01T00:00:00.000Z",
    "value": 100000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2018-10",
    "Date (Year)": "2018",
    "category": "deposits",
    "accountId": "AUAMQVhQaeLIHriycINeL",
    "accountName": "Bank for brokerage",
    "assetId": "AUAMQVhQaeLIHriycINeL",
    "displayName": "Bank for brokerage"
  },
  {
    "date": "2018-11-01T00:00:00.000Z",
    "value": 100000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2018-11",
    "Date (Year)": "2018",
    "category": "deposits",
    "accountId": "AUAMQVhQaeLIHriycINeL",
    "accountName": "Bank for brokerage",
    "assetId": "AUAMQVhQaeLIHriycINeL",
    "displayName": "Bank for brokerage"
  },
  {
    "date": "2018-12-01T00:00:00.000Z",
    "value": 99954.5378,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2018-12",
    "Date (Year)": "2018",
    "category": "deposits",
    "accountId": "AUAMQVhQaeLIHriycINeL",
    "accountName": "Bank for brokerage",
    "assetId": "AUAMQVhQaeLIHriycINeL",
    "displayName": "Bank for brokerage"
  },
  {
    "date": "2019-01-01T00:00:00.000Z",
    "value": 99954.5378,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2019-01",
    "Date (Year)": "2019",
    "category": "deposits",
    "accountId": "AUAMQVhQaeLIHriycINeL",
    "accountName": "Bank for brokerage",
    "assetId": "AUAMQVhQaeLIHriycINeL",
    "displayName": "Bank for brokerage"
  },
  {
    "date": "2019-02-01T00:00:00.000Z",
    "value": 99954.5378,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2019-02",
    "Date (Year)": "2019",
    "category": "deposits",
    "accountId": "AUAMQVhQaeLIHriycINeL",
    "accountName": "Bank for brokerage",
    "assetId": "AUAMQVhQaeLIHriycINeL",
    "displayName": "Bank for brokerage"
  },
  {
    "date": "2019-03-01T00:00:00.000Z",
    "value": 99954.5378,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2019-03",
    "Date (Year)": "2019",
    "category": "deposits",
    "accountId": "AUAMQVhQaeLIHriycINeL",
    "accountName": "Bank for brokerage",
    "assetId": "AUAMQVhQaeLIHriycINeL",
    "displayName": "Bank for brokerage"
  },
  {
    "date": "2019-04-01T00:00:00.000Z",
    "value": 99954.5378,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2019-04",
    "Date (Year)": "2019",
    "category": "deposits",
    "accountId": "AUAMQVhQaeLIHriycINeL",
    "accountName": "Bank for brokerage",
    "assetId": "AUAMQVhQaeLIHriycINeL",
    "displayName": "Bank for brokerage"
  },
  {
    "date": "2019-05-01T00:00:00.000Z",
    "value": 99954.5378,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2019-05",
    "Date (Year)": "2019",
    "category": "deposits",
    "accountId": "AUAMQVhQaeLIHriycINeL",
    "accountName": "Bank for brokerage",
    "assetId": "AUAMQVhQaeLIHriycINeL",
    "displayName": "Bank for brokerage"
  },
  {
    "date": "2019-06-01T00:00:00.000Z",
    "value": 96329.63780000001,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2019-06",
    "Date (Year)": "2019",
    "category": "deposits",
    "accountId": "AUAMQVhQaeLIHriycINeL",
    "accountName": "Bank for brokerage",
    "assetId": "AUAMQVhQaeLIHriycINeL",
    "displayName": "Bank for brokerage"
  },
  {
    "date": "2019-07-01T00:00:00.000Z",
    "value": 96329.63780000001,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2019-07",
    "Date (Year)": "2019",
    "category": "deposits",
    "accountId": "AUAMQVhQaeLIHriycINeL",
    "accountName": "Bank for brokerage",
    "assetId": "AUAMQVhQaeLIHriycINeL",
    "displayName": "Bank for brokerage"
  },
  {
    "date": "2019-08-01T00:00:00.000Z",
    "value": 96329.63780000001,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2019-08",
    "Date (Year)": "2019",
    "category": "deposits",
    "accountId": "AUAMQVhQaeLIHriycINeL",
    "accountName": "Bank for brokerage",
    "assetId": "AUAMQVhQaeLIHriycINeL",
    "displayName": "Bank for brokerage"
  },
  {
    "date": "2019-09-01T00:00:00.000Z",
    "value": 96329.63780000001,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2019-09",
    "Date (Year)": "2019",
    "category": "deposits",
    "accountId": "AUAMQVhQaeLIHriycINeL",
    "accountName": "Bank for brokerage",
    "assetId": "AUAMQVhQaeLIHriycINeL",
    "displayName": "Bank for brokerage"
  },
  {
    "date": "2019-10-01T00:00:00.000Z",
    "value": 96329.63780000001,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2019-10",
    "Date (Year)": "2019",
    "category": "deposits",
    "accountId": "AUAMQVhQaeLIHriycINeL",
    "accountName": "Bank for brokerage",
    "assetId": "AUAMQVhQaeLIHriycINeL",
    "displayName": "Bank for brokerage"
  },
  {
    "date": "2019-11-01T00:00:00.000Z",
    "value": 96329.63780000001,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2019-11",
    "Date (Year)": "2019",
    "category": "deposits",
    "accountId": "AUAMQVhQaeLIHriycINeL",
    "accountName": "Bank for brokerage",
    "assetId": "AUAMQVhQaeLIHriycINeL",
    "displayName": "Bank for brokerage"
  },
  {
    "date": "2019-12-01T00:00:00.000Z",
    "value": 96329.63780000001,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2019-12",
    "Date (Year)": "2019",
    "category": "deposits",
    "accountId": "AUAMQVhQaeLIHriycINeL",
    "accountName": "Bank for brokerage",
    "assetId": "AUAMQVhQaeLIHriycINeL",
    "displayName": "Bank for brokerage"
  },
  {
    "date": "2020-01-01T00:00:00.000Z",
    "value": 96329.63780000001,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2020-01",
    "Date (Year)": "2020",
    "category": "deposits",
    "accountId": "AUAMQVhQaeLIHriycINeL",
    "accountName": "Bank for brokerage",
    "assetId": "AUAMQVhQaeLIHriycINeL",
    "displayName": "Bank for brokerage"
  },
  {
    "date": "2020-02-01T00:00:00.000Z",
    "value": 96329.63780000001,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2020-02",
    "Date (Year)": "2020",
    "category": "deposits",
    "accountId": "AUAMQVhQaeLIHriycINeL",
    "accountName": "Bank for brokerage",
    "assetId": "AUAMQVhQaeLIHriycINeL",
    "displayName": "Bank for brokerage"
  },
  {
    "date": "2020-03-01T00:00:00.000Z",
    "value": 96329.63780000001,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2020-03",
    "Date (Year)": "2020",
    "category": "deposits",
    "accountId": "AUAMQVhQaeLIHriycINeL",
    "accountName": "Bank for brokerage",
    "assetId": "AUAMQVhQaeLIHriycINeL",
    "displayName": "Bank for brokerage"
  },
  {
    "date": "2020-04-01T00:00:00.000Z",
    "value": 96329.63780000001,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2020-04",
    "Date (Year)": "2020",
    "category": "deposits",
    "accountId": "AUAMQVhQaeLIHriycINeL",
    "accountName": "Bank for brokerage",
    "assetId": "AUAMQVhQaeLIHriycINeL",
    "displayName": "Bank for brokerage"
  },
  {
    "date": "2020-05-01T00:00:00.000Z",
    "value": 96329.63780000001,
    "incomeFromCapital": 0,
    "inflows": 2000,
    "inflowType": "inflow",
    "inflowValue": 2000,
    "Date (Month)": "2020-05",
    "Date (Year)": "2020",
    "category": "deposits",
    "accountId": "AUAMQVhQaeLIHriycINeL",
    "accountName": "Bank for brokerage",
    "assetId": "AUAMQVhQaeLIHriycINeL",
    "displayName": "Bank for brokerage"
  },
  {
    "date": "2020-06-01T00:00:00.000Z",
    "value": 98329.63780000001,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2020-06",
    "Date (Year)": "2020",
    "category": "deposits",
    "accountId": "AUAMQVhQaeLIHriycINeL",
    "accountName": "Bank for brokerage",
    "assetId": "AUAMQVhQaeLIHriycINeL",
    "displayName": "Bank for brokerage"
  },
  {
    "date": "2020-07-01T00:00:00.000Z",
    "value": 98329.63780000001,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2020-07",
    "Date (Year)": "2020",
    "category": "deposits",
    "accountId": "AUAMQVhQaeLIHriycINeL",
    "accountName": "Bank for brokerage",
    "assetId": "AUAMQVhQaeLIHriycINeL",
    "displayName": "Bank for brokerage"
  },
  {
    "date": "2020-08-01T00:00:00.000Z",
    "value": 98329.63780000001,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2020-08",
    "Date (Year)": "2020",
    "category": "deposits",
    "accountId": "AUAMQVhQaeLIHriycINeL",
    "accountName": "Bank for brokerage",
    "assetId": "AUAMQVhQaeLIHriycINeL",
    "displayName": "Bank for brokerage"
  },
  {
    "date": "2020-09-01T00:00:00.000Z",
    "value": 98329.63780000001,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2020-09",
    "Date (Year)": "2020",
    "category": "deposits",
    "accountId": "AUAMQVhQaeLIHriycINeL",
    "accountName": "Bank for brokerage",
    "assetId": "AUAMQVhQaeLIHriycINeL",
    "displayName": "Bank for brokerage"
  },
  {
    "date": "2020-10-01T00:00:00.000Z",
    "value": 98329.63780000001,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2020-10",
    "Date (Year)": "2020",
    "category": "deposits",
    "accountId": "AUAMQVhQaeLIHriycINeL",
    "accountName": "Bank for brokerage",
    "assetId": "AUAMQVhQaeLIHriycINeL",
    "displayName": "Bank for brokerage"
  },
  {
    "date": "2020-11-01T00:00:00.000Z",
    "value": 98329.63780000001,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2020-11",
    "Date (Year)": "2020",
    "category": "deposits",
    "accountId": "AUAMQVhQaeLIHriycINeL",
    "accountName": "Bank for brokerage",
    "assetId": "AUAMQVhQaeLIHriycINeL",
    "displayName": "Bank for brokerage"
  },
  {
    "date": "2020-12-01T00:00:00.000Z",
    "value": 98329.63780000001,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2020-12",
    "Date (Year)": "2020",
    "category": "deposits",
    "accountId": "AUAMQVhQaeLIHriycINeL",
    "accountName": "Bank for brokerage",
    "assetId": "AUAMQVhQaeLIHriycINeL",
    "displayName": "Bank for brokerage"
  },
  {
    "date": "2021-01-01T00:00:00.000Z",
    "value": 98329.63780000001,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2021-01",
    "Date (Year)": "2021",
    "category": "deposits",
    "accountId": "AUAMQVhQaeLIHriycINeL",
    "accountName": "Bank for brokerage",
    "assetId": "AUAMQVhQaeLIHriycINeL",
    "displayName": "Bank for brokerage"
  },
  {
    "date": "2021-02-01T00:00:00.000Z",
    "value": 98329.63780000001,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2021-02",
    "Date (Year)": "2021",
    "category": "deposits",
    "accountId": "AUAMQVhQaeLIHriycINeL",
    "accountName": "Bank for brokerage",
    "assetId": "AUAMQVhQaeLIHriycINeL",
    "displayName": "Bank for brokerage"
  },
  {
    "date": "2021-03-01T00:00:00.000Z",
    "value": 98329.63780000001,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2021-03",
    "Date (Year)": "2021",
    "category": "deposits",
    "accountId": "AUAMQVhQaeLIHriycINeL",
    "accountName": "Bank for brokerage",
    "assetId": "AUAMQVhQaeLIHriycINeL",
    "displayName": "Bank for brokerage"
  },
  {
    "date": "2021-04-01T00:00:00.000Z",
    "value": 98383.63780000001,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2021-04",
    "Date (Year)": "2021",
    "category": "deposits",
    "accountId": "AUAMQVhQaeLIHriycINeL",
    "accountName": "Bank for brokerage",
    "assetId": "AUAMQVhQaeLIHriycINeL",
    "displayName": "Bank for brokerage"
  },
  {
    "date": "2021-05-01T00:00:00.000Z",
    "value": 98383.63780000001,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2021-05",
    "Date (Year)": "2021",
    "category": "deposits",
    "accountId": "AUAMQVhQaeLIHriycINeL",
    "accountName": "Bank for brokerage",
    "assetId": "AUAMQVhQaeLIHriycINeL",
    "displayName": "Bank for brokerage"
  },
  {
    "date": "2021-06-01T00:00:00.000Z",
    "value": 98383.63780000001,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2021-06",
    "Date (Year)": "2021",
    "category": "deposits",
    "accountId": "AUAMQVhQaeLIHriycINeL",
    "accountName": "Bank for brokerage",
    "assetId": "AUAMQVhQaeLIHriycINeL",
    "displayName": "Bank for brokerage"
  },
  {
    "date": "2021-07-01T00:00:00.000Z",
    "value": 98383.63780000001,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2021-07",
    "Date (Year)": "2021",
    "category": "deposits",
    "accountId": "AUAMQVhQaeLIHriycINeL",
    "accountName": "Bank for brokerage",
    "assetId": "AUAMQVhQaeLIHriycINeL",
    "displayName": "Bank for brokerage"
  },
  {
    "date": "2021-08-01T00:00:00.000Z",
    "value": 98383.63780000001,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2021-08",
    "Date (Year)": "2021",
    "category": "deposits",
    "accountId": "AUAMQVhQaeLIHriycINeL",
    "accountName": "Bank for brokerage",
    "assetId": "AUAMQVhQaeLIHriycINeL",
    "displayName": "Bank for brokerage"
  },
  {
    "date": "2021-09-01T00:00:00.000Z",
    "value": 98383.63780000001,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2021-09",
    "Date (Year)": "2021",
    "category": "deposits",
    "accountId": "AUAMQVhQaeLIHriycINeL",
    "accountName": "Bank for brokerage",
    "assetId": "AUAMQVhQaeLIHriycINeL",
    "displayName": "Bank for brokerage"
  },
  {
    "date": "2021-10-01T00:00:00.000Z",
    "value": 98513.63780000001,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2021-10",
    "Date (Year)": "2021",
    "category": "deposits",
    "accountId": "AUAMQVhQaeLIHriycINeL",
    "accountName": "Bank for brokerage",
    "assetId": "AUAMQVhQaeLIHriycINeL",
    "displayName": "Bank for brokerage"
  },
  {
    "date": "2021-11-01T00:00:00.000Z",
    "value": 98513.63780000001,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2021-11",
    "Date (Year)": "2021",
    "category": "deposits",
    "accountId": "AUAMQVhQaeLIHriycINeL",
    "accountName": "Bank for brokerage",
    "assetId": "AUAMQVhQaeLIHriycINeL",
    "displayName": "Bank for brokerage"
  },
  {
    "date": "2021-12-01T00:00:00.000Z",
    "value": 98513.63780000001,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2021-12",
    "Date (Year)": "2021",
    "category": "deposits",
    "accountId": "AUAMQVhQaeLIHriycINeL",
    "accountName": "Bank for brokerage",
    "assetId": "AUAMQVhQaeLIHriycINeL",
    "displayName": "Bank for brokerage"
  },
  {
    "date": "2022-01-01T00:00:00.000Z",
    "value": 98513.63780000001,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2022-01",
    "Date (Year)": "2022",
    "category": "deposits",
    "accountId": "AUAMQVhQaeLIHriycINeL",
    "accountName": "Bank for brokerage",
    "assetId": "AUAMQVhQaeLIHriycINeL",
    "displayName": "Bank for brokerage"
  },
  {
    "date": "2022-02-01T00:00:00.000Z",
    "value": 98513.63780000001,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2022-02",
    "Date (Year)": "2022",
    "category": "deposits",
    "accountId": "AUAMQVhQaeLIHriycINeL",
    "accountName": "Bank for brokerage",
    "assetId": "AUAMQVhQaeLIHriycINeL",
    "displayName": "Bank for brokerage"
  },
  {
    "date": "2022-03-01T00:00:00.000Z",
    "value": 98513.63780000001,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2022-03",
    "Date (Year)": "2022",
    "category": "deposits",
    "accountId": "AUAMQVhQaeLIHriycINeL",
    "accountName": "Bank for brokerage",
    "assetId": "AUAMQVhQaeLIHriycINeL",
    "displayName": "Bank for brokerage"
  },
  {
    "date": "2022-04-01T00:00:00.000Z",
    "value": 98666.13780000001,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2022-04",
    "Date (Year)": "2022",
    "category": "deposits",
    "accountId": "AUAMQVhQaeLIHriycINeL",
    "accountName": "Bank for brokerage",
    "assetId": "AUAMQVhQaeLIHriycINeL",
    "displayName": "Bank for brokerage"
  },
  {
    "date": "2022-05-01T00:00:00.000Z",
    "value": 98666.13780000001,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2022-05",
    "Date (Year)": "2022",
    "category": "deposits",
    "accountId": "AUAMQVhQaeLIHriycINeL",
    "accountName": "Bank for brokerage",
    "assetId": "AUAMQVhQaeLIHriycINeL",
    "displayName": "Bank for brokerage"
  },
  {
    "date": "2022-06-01T00:00:00.000Z",
    "value": 98666.13780000001,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2022-06",
    "Date (Year)": "2022",
    "category": "deposits",
    "accountId": "AUAMQVhQaeLIHriycINeL",
    "accountName": "Bank for brokerage",
    "assetId": "AUAMQVhQaeLIHriycINeL",
    "displayName": "Bank for brokerage"
  },
  {
    "date": "2022-07-01T00:00:00.000Z",
    "value": 98666.13780000001,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2022-07",
    "Date (Year)": "2022",
    "category": "deposits",
    "accountId": "AUAMQVhQaeLIHriycINeL",
    "accountName": "Bank for brokerage",
    "assetId": "AUAMQVhQaeLIHriycINeL",
    "displayName": "Bank for brokerage"
  },
  {
    "date": "2022-08-01T00:00:00.000Z",
    "value": 98666.13780000001,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2022-08",
    "Date (Year)": "2022",
    "category": "deposits",
    "accountId": "AUAMQVhQaeLIHriycINeL",
    "accountName": "Bank for brokerage",
    "assetId": "AUAMQVhQaeLIHriycINeL",
    "displayName": "Bank for brokerage"
  },
  {
    "date": "2022-09-01T00:00:00.000Z",
    "value": 98666.13780000001,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2022-09",
    "Date (Year)": "2022",
    "category": "deposits",
    "accountId": "AUAMQVhQaeLIHriycINeL",
    "accountName": "Bank for brokerage",
    "assetId": "AUAMQVhQaeLIHriycINeL",
    "displayName": "Bank for brokerage"
  },
  {
    "date": "2022-10-01T00:00:00.000Z",
    "value": 98666.13780000001,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2022-10",
    "Date (Year)": "2022",
    "category": "deposits",
    "accountId": "AUAMQVhQaeLIHriycINeL",
    "accountName": "Bank for brokerage",
    "assetId": "AUAMQVhQaeLIHriycINeL",
    "displayName": "Bank for brokerage"
  },
  {
    "date": "2022-11-01T00:00:00.000Z",
    "value": 98666.13780000001,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2022-11",
    "Date (Year)": "2022",
    "category": "deposits",
    "accountId": "AUAMQVhQaeLIHriycINeL",
    "accountName": "Bank for brokerage",
    "assetId": "AUAMQVhQaeLIHriycINeL",
    "displayName": "Bank for brokerage"
  },
  {
    "date": "2022-12-01T00:00:00.000Z",
    "value": 98666.13780000001,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2022-12",
    "Date (Year)": "2022",
    "category": "deposits",
    "accountId": "AUAMQVhQaeLIHriycINeL",
    "accountName": "Bank for brokerage",
    "assetId": "AUAMQVhQaeLIHriycINeL",
    "displayName": "Bank for brokerage"
  },
  {
    "date": "2023-01-01T00:00:00.000Z",
    "value": 98666.13780000001,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2023-01",
    "Date (Year)": "2023",
    "category": "deposits",
    "accountId": "AUAMQVhQaeLIHriycINeL",
    "accountName": "Bank for brokerage",
    "assetId": "AUAMQVhQaeLIHriycINeL",
    "displayName": "Bank for brokerage"
  },
  {
    "date": "2023-02-01T00:00:00.000Z",
    "value": 98666.13780000001,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2023-02",
    "Date (Year)": "2023",
    "category": "deposits",
    "accountId": "AUAMQVhQaeLIHriycINeL",
    "accountName": "Bank for brokerage",
    "assetId": "AUAMQVhQaeLIHriycINeL",
    "displayName": "Bank for brokerage"
  },
  {
    "date": "2023-03-01T00:00:00.000Z",
    "value": 98666.13780000001,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2023-03",
    "Date (Year)": "2023",
    "category": "deposits",
    "accountId": "AUAMQVhQaeLIHriycINeL",
    "accountName": "Bank for brokerage",
    "assetId": "AUAMQVhQaeLIHriycINeL",
    "displayName": "Bank for brokerage"
  },
  {
    "date": "2023-04-01T00:00:00.000Z",
    "value": 98666.13780000001,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2023-04",
    "Date (Year)": "2023",
    "category": "deposits",
    "accountId": "AUAMQVhQaeLIHriycINeL",
    "accountName": "Bank for brokerage",
    "assetId": "AUAMQVhQaeLIHriycINeL",
    "displayName": "Bank for brokerage"
  },
  {
    "date": "2023-05-01T00:00:00.000Z",
    "value": 98666.13780000001,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2023-05",
    "Date (Year)": "2023",
    "category": "deposits",
    "accountId": "AUAMQVhQaeLIHriycINeL",
    "accountName": "Bank for brokerage",
    "assetId": "AUAMQVhQaeLIHriycINeL",
    "displayName": "Bank for brokerage"
  },
  {
    "date": "2023-06-01T00:00:00.000Z",
    "value": 107749.58510000001,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2023-06",
    "Date (Year)": "2023",
    "category": "deposits",
    "accountId": "AUAMQVhQaeLIHriycINeL",
    "accountName": "Bank for brokerage",
    "assetId": "AUAMQVhQaeLIHriycINeL",
    "displayName": "Bank for brokerage"
  },
  {
    "date": "2023-07-01T00:00:00.000Z",
    "value": 107749.58510000001,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2023-07",
    "Date (Year)": "2023",
    "category": "deposits",
    "accountId": "AUAMQVhQaeLIHriycINeL",
    "accountName": "Bank for brokerage",
    "assetId": "AUAMQVhQaeLIHriycINeL",
    "displayName": "Bank for brokerage"
  },
  {
    "date": "2023-08-01T00:00:00.000Z",
    "value": 107749.58510000001,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2023-08",
    "Date (Year)": "2023",
    "category": "deposits",
    "accountId": "AUAMQVhQaeLIHriycINeL",
    "accountName": "Bank for brokerage",
    "assetId": "AUAMQVhQaeLIHriycINeL",
    "displayName": "Bank for brokerage"
  },
  {
    "date": "2023-09-01T00:00:00.000Z",
    "value": 107749.58510000001,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2023-09",
    "Date (Year)": "2023",
    "category": "deposits",
    "accountId": "AUAMQVhQaeLIHriycINeL",
    "accountName": "Bank for brokerage",
    "assetId": "AUAMQVhQaeLIHriycINeL",
    "displayName": "Bank for brokerage"
  },
  {
    "date": "2023-10-01T00:00:00.000Z",
    "value": 107749.58510000001,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2023-10",
    "Date (Year)": "2023",
    "category": "deposits",
    "accountId": "AUAMQVhQaeLIHriycINeL",
    "accountName": "Bank for brokerage",
    "assetId": "AUAMQVhQaeLIHriycINeL",
    "displayName": "Bank for brokerage"
  },
  {
    "date": "2023-11-01T00:00:00.000Z",
    "value": 107749.58510000001,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2023-11",
    "Date (Year)": "2023",
    "category": "deposits",
    "accountId": "AUAMQVhQaeLIHriycINeL",
    "accountName": "Bank for brokerage",
    "assetId": "AUAMQVhQaeLIHriycINeL",
    "displayName": "Bank for brokerage"
  },
  {
    "date": "2023-12-01T00:00:00.000Z",
    "value": 107749.58510000001,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2023-12",
    "Date (Year)": "2023",
    "category": "deposits",
    "accountId": "AUAMQVhQaeLIHriycINeL",
    "accountName": "Bank for brokerage",
    "assetId": "AUAMQVhQaeLIHriycINeL",
    "displayName": "Bank for brokerage"
  },
  {
    "date": "2012-09-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2012-09",
    "Date (Year)": "2012",
    "category": "deposits",
    "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
    "accountName": "Bank for cost of living",
    "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
    "displayName": "Bank for cost of living"
  },
  {
    "date": "2012-10-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2012-10",
    "Date (Year)": "2012",
    "category": "deposits",
    "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
    "accountName": "Bank for cost of living",
    "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
    "displayName": "Bank for cost of living"
  },
  {
    "date": "2012-11-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2012-11",
    "Date (Year)": "2012",
    "category": "deposits",
    "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
    "accountName": "Bank for cost of living",
    "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
    "displayName": "Bank for cost of living"
  },
  {
    "date": "2012-12-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2012-12",
    "Date (Year)": "2012",
    "category": "deposits",
    "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
    "accountName": "Bank for cost of living",
    "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
    "displayName": "Bank for cost of living"
  },
  {
    "date": "2013-01-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2013-01",
    "Date (Year)": "2013",
    "category": "deposits",
    "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
    "accountName": "Bank for cost of living",
    "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
    "displayName": "Bank for cost of living"
  },
  {
    "date": "2013-02-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2013-02",
    "Date (Year)": "2013",
    "category": "deposits",
    "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
    "accountName": "Bank for cost of living",
    "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
    "displayName": "Bank for cost of living"
  },
  {
    "date": "2013-03-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2013-03",
    "Date (Year)": "2013",
    "category": "deposits",
    "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
    "accountName": "Bank for cost of living",
    "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
    "displayName": "Bank for cost of living"
  },
  {
    "date": "2013-04-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2013-04",
    "Date (Year)": "2013",
    "category": "deposits",
    "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
    "accountName": "Bank for cost of living",
    "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
    "displayName": "Bank for cost of living"
  },
  {
    "date": "2013-05-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2013-05",
    "Date (Year)": "2013",
    "category": "deposits",
    "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
    "accountName": "Bank for cost of living",
    "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
    "displayName": "Bank for cost of living"
  },
  {
    "date": "2013-06-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2013-06",
    "Date (Year)": "2013",
    "category": "deposits",
    "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
    "accountName": "Bank for cost of living",
    "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
    "displayName": "Bank for cost of living"
  },
  {
    "date": "2013-07-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2013-07",
    "Date (Year)": "2013",
    "category": "deposits",
    "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
    "accountName": "Bank for cost of living",
    "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
    "displayName": "Bank for cost of living"
  },
  {
    "date": "2013-08-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2013-08",
    "Date (Year)": "2013",
    "category": "deposits",
    "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
    "accountName": "Bank for cost of living",
    "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
    "displayName": "Bank for cost of living"
  },
  {
    "date": "2013-09-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2013-09",
    "Date (Year)": "2013",
    "category": "deposits",
    "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
    "accountName": "Bank for cost of living",
    "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
    "displayName": "Bank for cost of living"
  },
  {
    "date": "2013-10-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2013-10",
    "Date (Year)": "2013",
    "category": "deposits",
    "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
    "accountName": "Bank for cost of living",
    "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
    "displayName": "Bank for cost of living"
  },
  {
    "date": "2013-11-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2013-11",
    "Date (Year)": "2013",
    "category": "deposits",
    "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
    "accountName": "Bank for cost of living",
    "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
    "displayName": "Bank for cost of living"
  },
  {
    "date": "2013-12-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2013-12",
    "Date (Year)": "2013",
    "category": "deposits",
    "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
    "accountName": "Bank for cost of living",
    "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
    "displayName": "Bank for cost of living"
  },
  {
    "date": "2014-01-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2014-01",
    "Date (Year)": "2014",
    "category": "deposits",
    "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
    "accountName": "Bank for cost of living",
    "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
    "displayName": "Bank for cost of living"
  },
  {
    "date": "2014-02-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2014-02",
    "Date (Year)": "2014",
    "category": "deposits",
    "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
    "accountName": "Bank for cost of living",
    "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
    "displayName": "Bank for cost of living"
  },
  {
    "date": "2014-03-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2014-03",
    "Date (Year)": "2014",
    "category": "deposits",
    "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
    "accountName": "Bank for cost of living",
    "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
    "displayName": "Bank for cost of living"
  },
  {
    "date": "2014-04-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2014-04",
    "Date (Year)": "2014",
    "category": "deposits",
    "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
    "accountName": "Bank for cost of living",
    "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
    "displayName": "Bank for cost of living"
  },
  {
    "date": "2014-05-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2014-05",
    "Date (Year)": "2014",
    "category": "deposits",
    "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
    "accountName": "Bank for cost of living",
    "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
    "displayName": "Bank for cost of living"
  },
  {
    "date": "2014-06-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2014-06",
    "Date (Year)": "2014",
    "category": "deposits",
    "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
    "accountName": "Bank for cost of living",
    "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
    "displayName": "Bank for cost of living"
  },
  {
    "date": "2014-07-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2014-07",
    "Date (Year)": "2014",
    "category": "deposits",
    "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
    "accountName": "Bank for cost of living",
    "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
    "displayName": "Bank for cost of living"
  },
  {
    "date": "2014-08-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2014-08",
    "Date (Year)": "2014",
    "category": "deposits",
    "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
    "accountName": "Bank for cost of living",
    "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
    "displayName": "Bank for cost of living"
  },
  {
    "date": "2014-09-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2014-09",
    "Date (Year)": "2014",
    "category": "deposits",
    "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
    "accountName": "Bank for cost of living",
    "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
    "displayName": "Bank for cost of living"
  },
  {
    "date": "2014-10-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2014-10",
    "Date (Year)": "2014",
    "category": "deposits",
    "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
    "accountName": "Bank for cost of living",
    "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
    "displayName": "Bank for cost of living"
  },
  {
    "date": "2014-11-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2014-11",
    "Date (Year)": "2014",
    "category": "deposits",
    "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
    "accountName": "Bank for cost of living",
    "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
    "displayName": "Bank for cost of living"
  },
  {
    "date": "2014-12-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2014-12",
    "Date (Year)": "2014",
    "category": "deposits",
    "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
    "accountName": "Bank for cost of living",
    "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
    "displayName": "Bank for cost of living"
  },
  {
    "date": "2015-01-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2015-01",
    "Date (Year)": "2015",
    "category": "deposits",
    "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
    "accountName": "Bank for cost of living",
    "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
    "displayName": "Bank for cost of living"
  },
  {
    "date": "2015-02-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2015-02",
    "Date (Year)": "2015",
    "category": "deposits",
    "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
    "accountName": "Bank for cost of living",
    "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
    "displayName": "Bank for cost of living"
  },
  {
    "date": "2015-03-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2015-03",
    "Date (Year)": "2015",
    "category": "deposits",
    "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
    "accountName": "Bank for cost of living",
    "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
    "displayName": "Bank for cost of living"
  },
  {
    "date": "2015-04-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2015-04",
    "Date (Year)": "2015",
    "category": "deposits",
    "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
    "accountName": "Bank for cost of living",
    "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
    "displayName": "Bank for cost of living"
  },
  {
    "date": "2015-05-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2015-05",
    "Date (Year)": "2015",
    "category": "deposits",
    "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
    "accountName": "Bank for cost of living",
    "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
    "displayName": "Bank for cost of living"
  },
  {
    "date": "2015-06-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2015-06",
    "Date (Year)": "2015",
    "category": "deposits",
    "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
    "accountName": "Bank for cost of living",
    "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
    "displayName": "Bank for cost of living"
  },
  {
    "date": "2015-07-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2015-07",
    "Date (Year)": "2015",
    "category": "deposits",
    "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
    "accountName": "Bank for cost of living",
    "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
    "displayName": "Bank for cost of living"
  },
  {
    "date": "2015-08-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2015-08",
    "Date (Year)": "2015",
    "category": "deposits",
    "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
    "accountName": "Bank for cost of living",
    "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
    "displayName": "Bank for cost of living"
  },
  {
    "date": "2015-09-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2015-09",
    "Date (Year)": "2015",
    "category": "deposits",
    "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
    "accountName": "Bank for cost of living",
    "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
    "displayName": "Bank for cost of living"
  },
  {
    "date": "2015-10-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2015-10",
    "Date (Year)": "2015",
    "category": "deposits",
    "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
    "accountName": "Bank for cost of living",
    "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
    "displayName": "Bank for cost of living"
  },
  {
    "date": "2015-11-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2015-11",
    "Date (Year)": "2015",
    "category": "deposits",
    "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
    "accountName": "Bank for cost of living",
    "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
    "displayName": "Bank for cost of living"
  },
  {
    "date": "2015-12-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2015-12",
    "Date (Year)": "2015",
    "category": "deposits",
    "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
    "accountName": "Bank for cost of living",
    "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
    "displayName": "Bank for cost of living"
  },
  {
    "date": "2016-01-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2016-01",
    "Date (Year)": "2016",
    "category": "deposits",
    "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
    "accountName": "Bank for cost of living",
    "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
    "displayName": "Bank for cost of living"
  },
  {
    "date": "2016-02-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2016-02",
    "Date (Year)": "2016",
    "category": "deposits",
    "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
    "accountName": "Bank for cost of living",
    "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
    "displayName": "Bank for cost of living"
  },
  {
    "date": "2016-03-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2016-03",
    "Date (Year)": "2016",
    "category": "deposits",
    "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
    "accountName": "Bank for cost of living",
    "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
    "displayName": "Bank for cost of living"
  },
  {
    "date": "2016-04-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2016-04",
    "Date (Year)": "2016",
    "category": "deposits",
    "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
    "accountName": "Bank for cost of living",
    "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
    "displayName": "Bank for cost of living"
  },
  {
    "date": "2016-05-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2016-05",
    "Date (Year)": "2016",
    "category": "deposits",
    "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
    "accountName": "Bank for cost of living",
    "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
    "displayName": "Bank for cost of living"
  },
  {
    "date": "2016-06-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2016-06",
    "Date (Year)": "2016",
    "category": "deposits",
    "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
    "accountName": "Bank for cost of living",
    "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
    "displayName": "Bank for cost of living"
  },
  {
    "date": "2016-07-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2016-07",
    "Date (Year)": "2016",
    "category": "deposits",
    "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
    "accountName": "Bank for cost of living",
    "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
    "displayName": "Bank for cost of living"
  },
  {
    "date": "2016-08-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2016-08",
    "Date (Year)": "2016",
    "category": "deposits",
    "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
    "accountName": "Bank for cost of living",
    "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
    "displayName": "Bank for cost of living"
  },
  {
    "date": "2016-09-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2016-09",
    "Date (Year)": "2016",
    "category": "deposits",
    "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
    "accountName": "Bank for cost of living",
    "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
    "displayName": "Bank for cost of living"
  },
  {
    "date": "2016-10-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2016-10",
    "Date (Year)": "2016",
    "category": "deposits",
    "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
    "accountName": "Bank for cost of living",
    "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
    "displayName": "Bank for cost of living"
  },
  {
    "date": "2016-11-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2016-11",
    "Date (Year)": "2016",
    "category": "deposits",
    "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
    "accountName": "Bank for cost of living",
    "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
    "displayName": "Bank for cost of living"
  },
  {
    "date": "2016-12-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2016-12",
    "Date (Year)": "2016",
    "category": "deposits",
    "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
    "accountName": "Bank for cost of living",
    "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
    "displayName": "Bank for cost of living"
  },
  {
    "date": "2017-01-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2017-01",
    "Date (Year)": "2017",
    "category": "deposits",
    "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
    "accountName": "Bank for cost of living",
    "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
    "displayName": "Bank for cost of living"
  },
  {
    "date": "2017-02-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2017-02",
    "Date (Year)": "2017",
    "category": "deposits",
    "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
    "accountName": "Bank for cost of living",
    "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
    "displayName": "Bank for cost of living"
  },
  {
    "date": "2017-03-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2017-03",
    "Date (Year)": "2017",
    "category": "deposits",
    "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
    "accountName": "Bank for cost of living",
    "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
    "displayName": "Bank for cost of living"
  },
  {
    "date": "2017-04-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2017-04",
    "Date (Year)": "2017",
    "category": "deposits",
    "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
    "accountName": "Bank for cost of living",
    "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
    "displayName": "Bank for cost of living"
  },
  {
    "date": "2017-05-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2017-05",
    "Date (Year)": "2017",
    "category": "deposits",
    "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
    "accountName": "Bank for cost of living",
    "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
    "displayName": "Bank for cost of living"
  },
  {
    "date": "2017-06-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2017-06",
    "Date (Year)": "2017",
    "category": "deposits",
    "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
    "accountName": "Bank for cost of living",
    "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
    "displayName": "Bank for cost of living"
  },
  {
    "date": "2017-07-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2017-07",
    "Date (Year)": "2017",
    "category": "deposits",
    "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
    "accountName": "Bank for cost of living",
    "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
    "displayName": "Bank for cost of living"
  },
  {
    "date": "2017-08-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2017-08",
    "Date (Year)": "2017",
    "category": "deposits",
    "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
    "accountName": "Bank for cost of living",
    "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
    "displayName": "Bank for cost of living"
  },
  {
    "date": "2017-09-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2017-09",
    "Date (Year)": "2017",
    "category": "deposits",
    "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
    "accountName": "Bank for cost of living",
    "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
    "displayName": "Bank for cost of living"
  },
  {
    "date": "2017-10-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2017-10",
    "Date (Year)": "2017",
    "category": "deposits",
    "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
    "accountName": "Bank for cost of living",
    "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
    "displayName": "Bank for cost of living"
  },
  {
    "date": "2017-11-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2017-11",
    "Date (Year)": "2017",
    "category": "deposits",
    "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
    "accountName": "Bank for cost of living",
    "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
    "displayName": "Bank for cost of living"
  },
  {
    "date": "2017-12-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2017-12",
    "Date (Year)": "2017",
    "category": "deposits",
    "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
    "accountName": "Bank for cost of living",
    "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
    "displayName": "Bank for cost of living"
  },
  {
    "date": "2018-01-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2018-01",
    "Date (Year)": "2018",
    "category": "deposits",
    "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
    "accountName": "Bank for cost of living",
    "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
    "displayName": "Bank for cost of living"
  },
  {
    "date": "2018-02-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2018-02",
    "Date (Year)": "2018",
    "category": "deposits",
    "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
    "accountName": "Bank for cost of living",
    "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
    "displayName": "Bank for cost of living"
  },
  {
    "date": "2018-03-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2018-03",
    "Date (Year)": "2018",
    "category": "deposits",
    "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
    "accountName": "Bank for cost of living",
    "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
    "displayName": "Bank for cost of living"
  },
  {
    "date": "2018-04-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2018-04",
    "Date (Year)": "2018",
    "category": "deposits",
    "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
    "accountName": "Bank for cost of living",
    "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
    "displayName": "Bank for cost of living"
  },
  {
    "date": "2018-05-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2018-05",
    "Date (Year)": "2018",
    "category": "deposits",
    "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
    "accountName": "Bank for cost of living",
    "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
    "displayName": "Bank for cost of living"
  },
  {
    "date": "2018-06-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2018-06",
    "Date (Year)": "2018",
    "category": "deposits",
    "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
    "accountName": "Bank for cost of living",
    "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
    "displayName": "Bank for cost of living"
  },
  {
    "date": "2018-07-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2018-07",
    "Date (Year)": "2018",
    "category": "deposits",
    "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
    "accountName": "Bank for cost of living",
    "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
    "displayName": "Bank for cost of living"
  },
  {
    "date": "2018-08-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2018-08",
    "Date (Year)": "2018",
    "category": "deposits",
    "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
    "accountName": "Bank for cost of living",
    "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
    "displayName": "Bank for cost of living"
  },
  {
    "date": "2018-09-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2018-09",
    "Date (Year)": "2018",
    "category": "deposits",
    "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
    "accountName": "Bank for cost of living",
    "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
    "displayName": "Bank for cost of living"
  },
  {
    "date": "2018-10-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2018-10",
    "Date (Year)": "2018",
    "category": "deposits",
    "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
    "accountName": "Bank for cost of living",
    "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
    "displayName": "Bank for cost of living"
  },
  {
    "date": "2018-11-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2018-11",
    "Date (Year)": "2018",
    "category": "deposits",
    "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
    "accountName": "Bank for cost of living",
    "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
    "displayName": "Bank for cost of living"
  },
  {
    "date": "2018-12-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2018-12",
    "Date (Year)": "2018",
    "category": "deposits",
    "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
    "accountName": "Bank for cost of living",
    "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
    "displayName": "Bank for cost of living"
  },
  {
    "date": "2019-01-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2019-01",
    "Date (Year)": "2019",
    "category": "deposits",
    "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
    "accountName": "Bank for cost of living",
    "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
    "displayName": "Bank for cost of living"
  },
  {
    "date": "2019-02-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2019-02",
    "Date (Year)": "2019",
    "category": "deposits",
    "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
    "accountName": "Bank for cost of living",
    "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
    "displayName": "Bank for cost of living"
  },
  {
    "date": "2019-03-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2019-03",
    "Date (Year)": "2019",
    "category": "deposits",
    "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
    "accountName": "Bank for cost of living",
    "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
    "displayName": "Bank for cost of living"
  },
  {
    "date": "2019-04-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2019-04",
    "Date (Year)": "2019",
    "category": "deposits",
    "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
    "accountName": "Bank for cost of living",
    "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
    "displayName": "Bank for cost of living"
  },
  {
    "date": "2019-05-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2019-05",
    "Date (Year)": "2019",
    "category": "deposits",
    "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
    "accountName": "Bank for cost of living",
    "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
    "displayName": "Bank for cost of living"
  },
  {
    "date": "2019-06-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2019-06",
    "Date (Year)": "2019",
    "category": "deposits",
    "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
    "accountName": "Bank for cost of living",
    "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
    "displayName": "Bank for cost of living"
  },
  {
    "date": "2019-07-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2019-07",
    "Date (Year)": "2019",
    "category": "deposits",
    "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
    "accountName": "Bank for cost of living",
    "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
    "displayName": "Bank for cost of living"
  },
  {
    "date": "2019-08-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2019-08",
    "Date (Year)": "2019",
    "category": "deposits",
    "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
    "accountName": "Bank for cost of living",
    "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
    "displayName": "Bank for cost of living"
  },
  {
    "date": "2019-09-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2019-09",
    "Date (Year)": "2019",
    "category": "deposits",
    "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
    "accountName": "Bank for cost of living",
    "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
    "displayName": "Bank for cost of living"
  },
  {
    "date": "2019-10-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2019-10",
    "Date (Year)": "2019",
    "category": "deposits",
    "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
    "accountName": "Bank for cost of living",
    "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
    "displayName": "Bank for cost of living"
  },
  {
    "date": "2019-11-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2019-11",
    "Date (Year)": "2019",
    "category": "deposits",
    "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
    "accountName": "Bank for cost of living",
    "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
    "displayName": "Bank for cost of living"
  },
  {
    "date": "2019-12-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2019-12",
    "Date (Year)": "2019",
    "category": "deposits",
    "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
    "accountName": "Bank for cost of living",
    "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
    "displayName": "Bank for cost of living"
  },
  {
    "date": "2020-01-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 20000,
    "inflowType": "inflow",
    "inflowValue": 20000,
    "Date (Month)": "2020-01",
    "Date (Year)": "2020",
    "category": "deposits",
    "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
    "accountName": "Bank for cost of living",
    "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
    "displayName": "Bank for cost of living"
  },
  {
    "date": "2020-02-01T00:00:00.000Z",
    "value": 20000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2020-02",
    "Date (Year)": "2020",
    "category": "deposits",
    "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
    "accountName": "Bank for cost of living",
    "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
    "displayName": "Bank for cost of living"
  },
  {
    "date": "2020-03-01T00:00:00.000Z",
    "value": 20000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2020-03",
    "Date (Year)": "2020",
    "category": "deposits",
    "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
    "accountName": "Bank for cost of living",
    "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
    "displayName": "Bank for cost of living"
  },
  {
    "date": "2020-04-01T00:00:00.000Z",
    "value": 20000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2020-04",
    "Date (Year)": "2020",
    "category": "deposits",
    "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
    "accountName": "Bank for cost of living",
    "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
    "displayName": "Bank for cost of living"
  },
  {
    "date": "2020-05-01T00:00:00.000Z",
    "value": 20000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2020-05",
    "Date (Year)": "2020",
    "category": "deposits",
    "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
    "accountName": "Bank for cost of living",
    "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
    "displayName": "Bank for cost of living"
  },
  {
    "date": "2020-06-01T00:00:00.000Z",
    "value": 18000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2020-06",
    "Date (Year)": "2020",
    "category": "deposits",
    "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
    "accountName": "Bank for cost of living",
    "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
    "displayName": "Bank for cost of living"
  },
  {
    "date": "2020-07-01T00:00:00.000Z",
    "value": 18000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2020-07",
    "Date (Year)": "2020",
    "category": "deposits",
    "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
    "accountName": "Bank for cost of living",
    "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
    "displayName": "Bank for cost of living"
  },
  {
    "date": "2020-08-01T00:00:00.000Z",
    "value": 18000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2020-08",
    "Date (Year)": "2020",
    "category": "deposits",
    "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
    "accountName": "Bank for cost of living",
    "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
    "displayName": "Bank for cost of living"
  },
  {
    "date": "2020-09-01T00:00:00.000Z",
    "value": 18000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2020-09",
    "Date (Year)": "2020",
    "category": "deposits",
    "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
    "accountName": "Bank for cost of living",
    "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
    "displayName": "Bank for cost of living"
  },
  {
    "date": "2020-10-01T00:00:00.000Z",
    "value": 14833,
    "incomeFromCapital": 0,
    "inflows": 6500,
    "inflowType": "inflow",
    "inflowValue": 6500,
    "Date (Month)": "2020-10",
    "Date (Year)": "2020",
    "category": "deposits",
    "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
    "accountName": "Bank for cost of living",
    "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
    "displayName": "Bank for cost of living"
  },
  {
    "date": "2020-11-01T00:00:00.000Z",
    "value": 16983,
    "incomeFromCapital": 0,
    "inflows": 6500,
    "inflowType": "inflow",
    "inflowValue": 6500,
    "Date (Month)": "2020-11",
    "Date (Year)": "2020",
    "category": "deposits",
    "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
    "accountName": "Bank for cost of living",
    "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
    "displayName": "Bank for cost of living"
  },
  {
    "date": "2020-12-01T00:00:00.000Z",
    "value": 19133,
    "incomeFromCapital": 0,
    "inflows": 6500,
    "inflowType": "inflow",
    "inflowValue": 6500,
    "Date (Month)": "2020-12",
    "Date (Year)": "2020",
    "category": "deposits",
    "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
    "accountName": "Bank for cost of living",
    "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
    "displayName": "Bank for cost of living"
  },
  {
    "date": "2021-01-01T00:00:00.000Z",
    "value": 21283,
    "incomeFromCapital": 0,
    "inflows": 6500,
    "inflowType": "inflow",
    "inflowValue": 6500,
    "Date (Month)": "2021-01",
    "Date (Year)": "2021",
    "category": "deposits",
    "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
    "accountName": "Bank for cost of living",
    "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
    "displayName": "Bank for cost of living"
  },
  {
    "date": "2021-02-01T00:00:00.000Z",
    "value": 23433,
    "incomeFromCapital": 0,
    "inflows": 6500,
    "inflowType": "inflow",
    "inflowValue": 6500,
    "Date (Month)": "2021-02",
    "Date (Year)": "2021",
    "category": "deposits",
    "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
    "accountName": "Bank for cost of living",
    "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
    "displayName": "Bank for cost of living"
  },
  {
    "date": "2021-03-01T00:00:00.000Z",
    "value": 25036.79,
    "incomeFromCapital": 0,
    "inflows": 6500,
    "inflowType": "inflow",
    "inflowValue": 6500,
    "Date (Month)": "2021-03",
    "Date (Year)": "2021",
    "category": "deposits",
    "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
    "accountName": "Bank for cost of living",
    "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
    "displayName": "Bank for cost of living"
  },
  {
    "date": "2021-04-01T00:00:00.000Z",
    "value": 27186.79,
    "incomeFromCapital": 0,
    "inflows": 6500,
    "inflowType": "inflow",
    "inflowValue": 6500,
    "Date (Month)": "2021-04",
    "Date (Year)": "2021",
    "category": "deposits",
    "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
    "accountName": "Bank for cost of living",
    "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
    "displayName": "Bank for cost of living"
  },
  {
    "date": "2021-05-01T00:00:00.000Z",
    "value": 29336.79,
    "incomeFromCapital": 0,
    "inflows": 6500,
    "inflowType": "inflow",
    "inflowValue": 6500,
    "Date (Month)": "2021-05",
    "Date (Year)": "2021",
    "category": "deposits",
    "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
    "accountName": "Bank for cost of living",
    "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
    "displayName": "Bank for cost of living"
  },
  {
    "date": "2021-06-01T00:00:00.000Z",
    "value": 31486.79,
    "incomeFromCapital": 0,
    "inflows": 6500,
    "inflowType": "inflow",
    "inflowValue": 6500,
    "Date (Month)": "2021-06",
    "Date (Year)": "2021",
    "category": "deposits",
    "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
    "accountName": "Bank for cost of living",
    "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
    "displayName": "Bank for cost of living"
  },
  {
    "date": "2021-07-01T00:00:00.000Z",
    "value": 33636.79,
    "incomeFromCapital": 0,
    "inflows": 6500,
    "inflowType": "inflow",
    "inflowValue": 6500,
    "Date (Month)": "2021-07",
    "Date (Year)": "2021",
    "category": "deposits",
    "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
    "accountName": "Bank for cost of living",
    "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
    "displayName": "Bank for cost of living"
  },
  {
    "date": "2021-08-01T00:00:00.000Z",
    "value": 35786.79,
    "incomeFromCapital": 0,
    "inflows": 6500,
    "inflowType": "inflow",
    "inflowValue": 6500,
    "Date (Month)": "2021-08",
    "Date (Year)": "2021",
    "category": "deposits",
    "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
    "accountName": "Bank for cost of living",
    "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
    "displayName": "Bank for cost of living"
  },
  {
    "date": "2021-09-01T00:00:00.000Z",
    "value": 37936.79,
    "incomeFromCapital": 0,
    "inflows": 6500,
    "inflowType": "inflow",
    "inflowValue": 6500,
    "Date (Month)": "2021-09",
    "Date (Year)": "2021",
    "category": "deposits",
    "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
    "accountName": "Bank for cost of living",
    "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
    "displayName": "Bank for cost of living"
  },
  {
    "date": "2021-10-01T00:00:00.000Z",
    "value": 40086.79,
    "incomeFromCapital": 0,
    "inflows": 6500,
    "inflowType": "inflow",
    "inflowValue": 6500,
    "Date (Month)": "2021-10",
    "Date (Year)": "2021",
    "category": "deposits",
    "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
    "accountName": "Bank for cost of living",
    "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
    "displayName": "Bank for cost of living"
  },
  {
    "date": "2021-11-01T00:00:00.000Z",
    "value": 42236.79,
    "incomeFromCapital": 0,
    "inflows": 6500,
    "inflowType": "inflow",
    "inflowValue": 6500,
    "Date (Month)": "2021-11",
    "Date (Year)": "2021",
    "category": "deposits",
    "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
    "accountName": "Bank for cost of living",
    "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
    "displayName": "Bank for cost of living"
  },
  {
    "date": "2021-12-01T00:00:00.000Z",
    "value": 44386.79,
    "incomeFromCapital": 0,
    "inflows": 6500,
    "inflowType": "inflow",
    "inflowValue": 6500,
    "Date (Month)": "2021-12",
    "Date (Year)": "2021",
    "category": "deposits",
    "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
    "accountName": "Bank for cost of living",
    "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
    "displayName": "Bank for cost of living"
  },
  {
    "date": "2022-01-01T00:00:00.000Z",
    "value": 46536.79,
    "incomeFromCapital": 0,
    "inflows": 4850,
    "inflowType": "inflow",
    "inflowValue": 4850,
    "Date (Month)": "2022-01",
    "Date (Year)": "2022",
    "category": "deposits",
    "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
    "accountName": "Bank for cost of living",
    "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
    "displayName": "Bank for cost of living"
  },
  {
    "date": "2022-02-01T00:00:00.000Z",
    "value": 47536.79,
    "incomeFromCapital": 0,
    "inflows": 5000,
    "inflowType": "inflow",
    "inflowValue": 5000,
    "Date (Month)": "2022-02",
    "Date (Year)": "2022",
    "category": "deposits",
    "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
    "accountName": "Bank for cost of living",
    "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
    "displayName": "Bank for cost of living"
  },
  {
    "date": "2022-03-01T00:00:00.000Z",
    "value": 48536.79,
    "incomeFromCapital": 0,
    "inflows": 7700,
    "inflowType": "inflow",
    "inflowValue": 7700,
    "Date (Month)": "2022-03",
    "Date (Year)": "2022",
    "category": "deposits",
    "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
    "accountName": "Bank for cost of living",
    "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
    "displayName": "Bank for cost of living"
  },
  {
    "date": "2022-04-01T00:00:00.000Z",
    "value": 50846.79,
    "incomeFromCapital": 0,
    "inflows": 10000,
    "inflowType": "inflow",
    "inflowValue": 10000,
    "Date (Month)": "2022-04",
    "Date (Year)": "2022",
    "category": "deposits",
    "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
    "accountName": "Bank for cost of living",
    "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
    "displayName": "Bank for cost of living"
  },
  {
    "date": "2022-05-01T00:00:00.000Z",
    "value": 54846.79,
    "incomeFromCapital": 0,
    "inflows": 6250,
    "inflowType": "inflow",
    "inflowValue": 6250,
    "Date (Month)": "2022-05",
    "Date (Year)": "2022",
    "category": "deposits",
    "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
    "accountName": "Bank for cost of living",
    "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
    "displayName": "Bank for cost of living"
  },
  {
    "date": "2022-06-01T00:00:00.000Z",
    "value": 58096.79,
    "incomeFromCapital": 0,
    "inflows": 6750,
    "inflowType": "inflow",
    "inflowValue": 6750,
    "Date (Month)": "2022-06",
    "Date (Year)": "2022",
    "category": "deposits",
    "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
    "accountName": "Bank for cost of living",
    "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
    "displayName": "Bank for cost of living"
  },
  {
    "date": "2022-07-01T00:00:00.000Z",
    "value": 60846.79,
    "incomeFromCapital": 0,
    "inflows": 6750,
    "inflowType": "inflow",
    "inflowValue": 6750,
    "Date (Month)": "2022-07",
    "Date (Year)": "2022",
    "category": "deposits",
    "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
    "accountName": "Bank for cost of living",
    "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
    "displayName": "Bank for cost of living"
  },
  {
    "date": "2022-08-01T00:00:00.000Z",
    "value": 63996.79000000001,
    "incomeFromCapital": 0,
    "inflows": 6750,
    "inflowType": "inflow",
    "inflowValue": 6750,
    "Date (Month)": "2022-08",
    "Date (Year)": "2022",
    "category": "deposits",
    "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
    "accountName": "Bank for cost of living",
    "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
    "displayName": "Bank for cost of living"
  },
  {
    "date": "2022-09-01T00:00:00.000Z",
    "value": 66746.79000000001,
    "incomeFromCapital": 0,
    "inflows": 6750,
    "inflowType": "inflow",
    "inflowValue": 6750,
    "Date (Month)": "2022-09",
    "Date (Year)": "2022",
    "category": "deposits",
    "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
    "accountName": "Bank for cost of living",
    "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
    "displayName": "Bank for cost of living"
  },
  {
    "date": "2022-10-01T00:00:00.000Z",
    "value": 69496.79000000001,
    "incomeFromCapital": 0,
    "inflows": 6950,
    "inflowType": "inflow",
    "inflowValue": 6950,
    "Date (Month)": "2022-10",
    "Date (Year)": "2022",
    "category": "deposits",
    "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
    "accountName": "Bank for cost of living",
    "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
    "displayName": "Bank for cost of living"
  },
  {
    "date": "2022-11-01T00:00:00.000Z",
    "value": 71946.79000000001,
    "incomeFromCapital": 0,
    "inflows": 6750,
    "inflowType": "inflow",
    "inflowValue": 6750,
    "Date (Month)": "2022-11",
    "Date (Year)": "2022",
    "category": "deposits",
    "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
    "accountName": "Bank for cost of living",
    "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
    "displayName": "Bank for cost of living"
  },
  {
    "date": "2022-12-01T00:00:00.000Z",
    "value": 74696.79000000001,
    "incomeFromCapital": 0,
    "inflows": 6750,
    "inflowType": "inflow",
    "inflowValue": 6750,
    "Date (Month)": "2022-12",
    "Date (Year)": "2022",
    "category": "deposits",
    "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
    "accountName": "Bank for cost of living",
    "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
    "displayName": "Bank for cost of living"
  },
  {
    "date": "2023-01-01T00:00:00.000Z",
    "value": 77446.79000000001,
    "incomeFromCapital": 0,
    "inflows": 6750,
    "inflowType": "inflow",
    "inflowValue": 6750,
    "Date (Month)": "2023-01",
    "Date (Year)": "2023",
    "category": "deposits",
    "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
    "accountName": "Bank for cost of living",
    "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
    "displayName": "Bank for cost of living"
  },
  {
    "date": "2023-02-01T00:00:00.000Z",
    "value": 80196.79000000001,
    "incomeFromCapital": 0,
    "inflows": 6750,
    "inflowType": "inflow",
    "inflowValue": 6750,
    "Date (Month)": "2023-02",
    "Date (Year)": "2023",
    "category": "deposits",
    "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
    "accountName": "Bank for cost of living",
    "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
    "displayName": "Bank for cost of living"
  },
  {
    "date": "2023-03-01T00:00:00.000Z",
    "value": 82946.79000000001,
    "incomeFromCapital": 0,
    "inflows": 6750,
    "inflowType": "inflow",
    "inflowValue": 6750,
    "Date (Month)": "2023-03",
    "Date (Year)": "2023",
    "category": "deposits",
    "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
    "accountName": "Bank for cost of living",
    "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
    "displayName": "Bank for cost of living"
  },
  {
    "date": "2023-04-01T00:00:00.000Z",
    "value": 85443.79000000001,
    "incomeFromCapital": 0,
    "inflows": 6750,
    "inflowType": "inflow",
    "inflowValue": 6750,
    "Date (Month)": "2023-04",
    "Date (Year)": "2023",
    "category": "deposits",
    "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
    "accountName": "Bank for cost of living",
    "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
    "displayName": "Bank for cost of living"
  },
  {
    "date": "2023-05-01T00:00:00.000Z",
    "value": 88193.79000000001,
    "incomeFromCapital": 0,
    "inflows": 6750,
    "inflowType": "inflow",
    "inflowValue": 6750,
    "Date (Month)": "2023-05",
    "Date (Year)": "2023",
    "category": "deposits",
    "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
    "accountName": "Bank for cost of living",
    "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
    "displayName": "Bank for cost of living"
  },
  {
    "date": "2023-06-01T00:00:00.000Z",
    "value": 90943.79000000001,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2023-06",
    "Date (Year)": "2023",
    "category": "deposits",
    "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
    "accountName": "Bank for cost of living",
    "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
    "displayName": "Bank for cost of living"
  },
  {
    "date": "2023-07-01T00:00:00.000Z",
    "value": 90943.79000000001,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2023-07",
    "Date (Year)": "2023",
    "category": "deposits",
    "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
    "accountName": "Bank for cost of living",
    "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
    "displayName": "Bank for cost of living"
  },
  {
    "date": "2023-08-01T00:00:00.000Z",
    "value": 90943.79000000001,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2023-08",
    "Date (Year)": "2023",
    "category": "deposits",
    "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
    "accountName": "Bank for cost of living",
    "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
    "displayName": "Bank for cost of living"
  },
  {
    "date": "2023-09-01T00:00:00.000Z",
    "value": 90943.79000000001,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2023-09",
    "Date (Year)": "2023",
    "category": "deposits",
    "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
    "accountName": "Bank for cost of living",
    "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
    "displayName": "Bank for cost of living"
  },
  {
    "date": "2023-10-01T00:00:00.000Z",
    "value": 90943.79000000001,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2023-10",
    "Date (Year)": "2023",
    "category": "deposits",
    "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
    "accountName": "Bank for cost of living",
    "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
    "displayName": "Bank for cost of living"
  },
  {
    "date": "2023-11-01T00:00:00.000Z",
    "value": 90943.79000000001,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2023-11",
    "Date (Year)": "2023",
    "category": "deposits",
    "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
    "accountName": "Bank for cost of living",
    "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
    "displayName": "Bank for cost of living"
  },
  {
    "date": "2023-12-01T00:00:00.000Z",
    "value": 90943.79000000001,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2023-12",
    "Date (Year)": "2023",
    "category": "deposits",
    "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
    "accountName": "Bank for cost of living",
    "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
    "displayName": "Bank for cost of living"
  },
  {
    "date": "2012-09-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2012-09",
    "Date (Year)": "2012",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2012-10-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2012-10",
    "Date (Year)": "2012",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2012-11-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2012-11",
    "Date (Year)": "2012",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2012-12-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2012-12",
    "Date (Year)": "2012",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2013-01-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2013-01",
    "Date (Year)": "2013",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2013-02-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2013-02",
    "Date (Year)": "2013",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2013-03-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2013-03",
    "Date (Year)": "2013",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2013-04-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2013-04",
    "Date (Year)": "2013",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2013-05-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2013-05",
    "Date (Year)": "2013",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2013-06-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2013-06",
    "Date (Year)": "2013",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2013-07-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2013-07",
    "Date (Year)": "2013",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2013-08-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2013-08",
    "Date (Year)": "2013",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2013-09-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2013-09",
    "Date (Year)": "2013",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2013-10-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2013-10",
    "Date (Year)": "2013",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2013-11-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2013-11",
    "Date (Year)": "2013",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2013-12-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2013-12",
    "Date (Year)": "2013",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2014-01-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2014-01",
    "Date (Year)": "2014",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2014-02-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2014-02",
    "Date (Year)": "2014",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2014-03-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2014-03",
    "Date (Year)": "2014",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2014-04-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2014-04",
    "Date (Year)": "2014",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2014-05-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2014-05",
    "Date (Year)": "2014",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2014-06-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2014-06",
    "Date (Year)": "2014",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2014-07-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2014-07",
    "Date (Year)": "2014",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2014-08-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2014-08",
    "Date (Year)": "2014",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2014-09-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2014-09",
    "Date (Year)": "2014",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2014-10-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2014-10",
    "Date (Year)": "2014",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2014-11-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2014-11",
    "Date (Year)": "2014",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2014-12-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2014-12",
    "Date (Year)": "2014",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2015-01-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2015-01",
    "Date (Year)": "2015",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2015-02-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2015-02",
    "Date (Year)": "2015",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2015-03-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2015-03",
    "Date (Year)": "2015",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2015-04-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2015-04",
    "Date (Year)": "2015",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2015-05-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2015-05",
    "Date (Year)": "2015",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2015-06-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2015-06",
    "Date (Year)": "2015",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2015-07-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2015-07",
    "Date (Year)": "2015",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2015-08-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2015-08",
    "Date (Year)": "2015",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2015-09-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2015-09",
    "Date (Year)": "2015",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2015-10-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2015-10",
    "Date (Year)": "2015",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2015-11-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2015-11",
    "Date (Year)": "2015",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2015-12-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2015-12",
    "Date (Year)": "2015",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2016-01-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2016-01",
    "Date (Year)": "2016",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2016-02-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2016-02",
    "Date (Year)": "2016",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2016-03-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2016-03",
    "Date (Year)": "2016",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2016-04-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2016-04",
    "Date (Year)": "2016",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2016-05-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2016-05",
    "Date (Year)": "2016",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2016-06-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2016-06",
    "Date (Year)": "2016",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2016-07-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2016-07",
    "Date (Year)": "2016",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2016-08-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2016-08",
    "Date (Year)": "2016",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2016-09-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2016-09",
    "Date (Year)": "2016",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2016-10-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2016-10",
    "Date (Year)": "2016",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2016-11-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2016-11",
    "Date (Year)": "2016",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2016-12-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2016-12",
    "Date (Year)": "2016",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2017-01-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2017-01",
    "Date (Year)": "2017",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2017-02-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2017-02",
    "Date (Year)": "2017",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2017-03-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2017-03",
    "Date (Year)": "2017",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2017-04-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2017-04",
    "Date (Year)": "2017",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2017-05-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2017-05",
    "Date (Year)": "2017",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2017-06-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2017-06",
    "Date (Year)": "2017",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2017-07-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2017-07",
    "Date (Year)": "2017",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2017-08-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2017-08",
    "Date (Year)": "2017",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2017-09-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2017-09",
    "Date (Year)": "2017",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2017-10-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2017-10",
    "Date (Year)": "2017",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2017-11-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2017-11",
    "Date (Year)": "2017",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2017-12-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 20000,
    "inflowType": "inflow",
    "inflowValue": 20000,
    "Date (Month)": "2017-12",
    "Date (Year)": "2017",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2018-01-01T00:00:00.000Z",
    "value": 20000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2018-01",
    "Date (Year)": "2018",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2018-02-01T00:00:00.000Z",
    "value": 20000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2018-02",
    "Date (Year)": "2018",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2018-03-01T00:00:00.000Z",
    "value": 20000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2018-03",
    "Date (Year)": "2018",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2018-04-01T00:00:00.000Z",
    "value": 20000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2018-04",
    "Date (Year)": "2018",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2018-05-01T00:00:00.000Z",
    "value": 20000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2018-05",
    "Date (Year)": "2018",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2018-06-01T00:00:00.000Z",
    "value": 20000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2018-06",
    "Date (Year)": "2018",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2018-07-01T00:00:00.000Z",
    "value": 20000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2018-07",
    "Date (Year)": "2018",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2018-08-01T00:00:00.000Z",
    "value": 20000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2018-08",
    "Date (Year)": "2018",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2018-09-01T00:00:00.000Z",
    "value": 20000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2018-09",
    "Date (Year)": "2018",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2018-10-01T00:00:00.000Z",
    "value": 20000,
    "incomeFromCapital": 2,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2018-10",
    "Date (Year)": "2018",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2018-10-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 2,
    "inflows": 0,
    "inflowType": "incomeFromCapital",
    "inflowValue": 2,
    "Date (Month)": "2018-10",
    "Date (Year)": "2018",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2018-11-01T00:00:00.000Z",
    "value": 20002,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2018-11",
    "Date (Year)": "2018",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2018-12-01T00:00:00.000Z",
    "value": 20002,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2018-12",
    "Date (Year)": "2018",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2019-01-01T00:00:00.000Z",
    "value": 20002,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2019-01",
    "Date (Year)": "2019",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2019-02-01T00:00:00.000Z",
    "value": 20002,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2019-02",
    "Date (Year)": "2019",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2019-03-01T00:00:00.000Z",
    "value": 20002,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2019-03",
    "Date (Year)": "2019",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2019-04-01T00:00:00.000Z",
    "value": 20002,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2019-04",
    "Date (Year)": "2019",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2019-05-01T00:00:00.000Z",
    "value": 20002,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2019-05",
    "Date (Year)": "2019",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2019-06-01T00:00:00.000Z",
    "value": 20002,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2019-06",
    "Date (Year)": "2019",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2019-07-01T00:00:00.000Z",
    "value": 20002,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2019-07",
    "Date (Year)": "2019",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2019-08-01T00:00:00.000Z",
    "value": 20002,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2019-08",
    "Date (Year)": "2019",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2019-09-01T00:00:00.000Z",
    "value": 20002,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2019-09",
    "Date (Year)": "2019",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2019-10-01T00:00:00.000Z",
    "value": 20002,
    "incomeFromCapital": 2,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2019-10",
    "Date (Year)": "2019",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2019-10-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 2,
    "inflows": 0,
    "inflowType": "incomeFromCapital",
    "inflowValue": 2,
    "Date (Month)": "2019-10",
    "Date (Year)": "2019",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2019-11-01T00:00:00.000Z",
    "value": 20004,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2019-11",
    "Date (Year)": "2019",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2019-12-01T00:00:00.000Z",
    "value": 20004,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2019-12",
    "Date (Year)": "2019",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2020-01-01T00:00:00.000Z",
    "value": 20004,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2020-01",
    "Date (Year)": "2020",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2020-02-01T00:00:00.000Z",
    "value": 20004,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2020-02",
    "Date (Year)": "2020",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2020-03-01T00:00:00.000Z",
    "value": 20004,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2020-03",
    "Date (Year)": "2020",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2020-04-01T00:00:00.000Z",
    "value": 20004,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2020-04",
    "Date (Year)": "2020",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2020-05-01T00:00:00.000Z",
    "value": 20004,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2020-05",
    "Date (Year)": "2020",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2020-06-01T00:00:00.000Z",
    "value": 20004,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2020-06",
    "Date (Year)": "2020",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2020-07-01T00:00:00.000Z",
    "value": 20004,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2020-07",
    "Date (Year)": "2020",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2020-08-01T00:00:00.000Z",
    "value": 20004,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2020-08",
    "Date (Year)": "2020",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2020-09-01T00:00:00.000Z",
    "value": 20004,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2020-09",
    "Date (Year)": "2020",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2020-10-01T00:00:00.000Z",
    "value": 20004,
    "incomeFromCapital": 2,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2020-10",
    "Date (Year)": "2020",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2020-10-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 2,
    "inflows": 0,
    "inflowType": "incomeFromCapital",
    "inflowValue": 2,
    "Date (Month)": "2020-10",
    "Date (Year)": "2020",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2020-11-01T00:00:00.000Z",
    "value": 20006,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2020-11",
    "Date (Year)": "2020",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2020-12-01T00:00:00.000Z",
    "value": 20006,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2020-12",
    "Date (Year)": "2020",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2021-01-01T00:00:00.000Z",
    "value": 20006,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2021-01",
    "Date (Year)": "2021",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2021-02-01T00:00:00.000Z",
    "value": 20006,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2021-02",
    "Date (Year)": "2021",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2021-03-01T00:00:00.000Z",
    "value": 20006,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2021-03",
    "Date (Year)": "2021",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2021-04-01T00:00:00.000Z",
    "value": 20006,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2021-04",
    "Date (Year)": "2021",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2021-05-01T00:00:00.000Z",
    "value": 20006,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2021-05",
    "Date (Year)": "2021",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2021-06-01T00:00:00.000Z",
    "value": 20006,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2021-06",
    "Date (Year)": "2021",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2021-07-01T00:00:00.000Z",
    "value": 20006,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2021-07",
    "Date (Year)": "2021",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2021-08-01T00:00:00.000Z",
    "value": 20006,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2021-08",
    "Date (Year)": "2021",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2021-09-01T00:00:00.000Z",
    "value": 20006,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2021-09",
    "Date (Year)": "2021",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2021-10-01T00:00:00.000Z",
    "value": 20006,
    "incomeFromCapital": 2,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2021-10",
    "Date (Year)": "2021",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2021-10-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 2,
    "inflows": 0,
    "inflowType": "incomeFromCapital",
    "inflowValue": 2,
    "Date (Month)": "2021-10",
    "Date (Year)": "2021",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2021-11-01T00:00:00.000Z",
    "value": 20008,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2021-11",
    "Date (Year)": "2021",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2021-12-01T00:00:00.000Z",
    "value": 20008,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2021-12",
    "Date (Year)": "2021",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2022-01-01T00:00:00.000Z",
    "value": 20008,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2022-01",
    "Date (Year)": "2022",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2022-02-01T00:00:00.000Z",
    "value": 20008,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2022-02",
    "Date (Year)": "2022",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2022-03-01T00:00:00.000Z",
    "value": 20008,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2022-03",
    "Date (Year)": "2022",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2022-04-01T00:00:00.000Z",
    "value": 20008,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2022-04",
    "Date (Year)": "2022",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2022-05-01T00:00:00.000Z",
    "value": 20008,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2022-05",
    "Date (Year)": "2022",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2022-06-01T00:00:00.000Z",
    "value": 20008,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2022-06",
    "Date (Year)": "2022",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2022-07-01T00:00:00.000Z",
    "value": 20008,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2022-07",
    "Date (Year)": "2022",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2022-08-01T00:00:00.000Z",
    "value": 20008,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2022-08",
    "Date (Year)": "2022",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2022-09-01T00:00:00.000Z",
    "value": 20008,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2022-09",
    "Date (Year)": "2022",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2022-10-01T00:00:00.000Z",
    "value": 20008,
    "incomeFromCapital": 2,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2022-10",
    "Date (Year)": "2022",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2022-10-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 2,
    "inflows": 0,
    "inflowType": "incomeFromCapital",
    "inflowValue": 2,
    "Date (Month)": "2022-10",
    "Date (Year)": "2022",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2022-11-01T00:00:00.000Z",
    "value": 20010,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2022-11",
    "Date (Year)": "2022",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2022-12-01T00:00:00.000Z",
    "value": 20010,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2022-12",
    "Date (Year)": "2022",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2023-01-01T00:00:00.000Z",
    "value": 20010,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2023-01",
    "Date (Year)": "2023",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2023-02-01T00:00:00.000Z",
    "value": 20010,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2023-02",
    "Date (Year)": "2023",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2023-03-01T00:00:00.000Z",
    "value": 20010,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2023-03",
    "Date (Year)": "2023",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2023-04-01T00:00:00.000Z",
    "value": 20010,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2023-04",
    "Date (Year)": "2023",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2023-05-01T00:00:00.000Z",
    "value": 20010,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2023-05",
    "Date (Year)": "2023",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2023-06-01T00:00:00.000Z",
    "value": 20010,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2023-06",
    "Date (Year)": "2023",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2023-07-01T00:00:00.000Z",
    "value": 20010,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2023-07",
    "Date (Year)": "2023",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2023-08-01T00:00:00.000Z",
    "value": 20010,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2023-08",
    "Date (Year)": "2023",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2023-09-01T00:00:00.000Z",
    "value": 20010,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2023-09",
    "Date (Year)": "2023",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2023-10-01T00:00:00.000Z",
    "value": 20010,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2023-10",
    "Date (Year)": "2023",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2023-11-01T00:00:00.000Z",
    "value": 20010,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2023-11",
    "Date (Year)": "2023",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2023-12-01T00:00:00.000Z",
    "value": 20010,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2023-12",
    "Date (Year)": "2023",
    "category": "deposits",
    "accountId": "EXWO7q0zVnuwvj4yJ305w",
    "accountName": "Bank for deposit",
    "assetId": "EXWO7q0zVnuwvj4yJ305w",
    "displayName": "Bank for deposit"
  },
  {
    "date": "2012-09-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2012-09",
    "Date (Year)": "2012",
    "category": "deposits",
    "accountId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "accountName": "Bank for deposit 2",
    "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "displayName": "Bank for deposit 2"
  },
  {
    "date": "2012-10-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2012-10",
    "Date (Year)": "2012",
    "category": "deposits",
    "accountId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "accountName": "Bank for deposit 2",
    "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "displayName": "Bank for deposit 2"
  },
  {
    "date": "2012-11-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2012-11",
    "Date (Year)": "2012",
    "category": "deposits",
    "accountId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "accountName": "Bank for deposit 2",
    "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "displayName": "Bank for deposit 2"
  },
  {
    "date": "2012-12-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2012-12",
    "Date (Year)": "2012",
    "category": "deposits",
    "accountId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "accountName": "Bank for deposit 2",
    "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "displayName": "Bank for deposit 2"
  },
  {
    "date": "2013-01-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2013-01",
    "Date (Year)": "2013",
    "category": "deposits",
    "accountId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "accountName": "Bank for deposit 2",
    "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "displayName": "Bank for deposit 2"
  },
  {
    "date": "2013-02-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2013-02",
    "Date (Year)": "2013",
    "category": "deposits",
    "accountId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "accountName": "Bank for deposit 2",
    "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "displayName": "Bank for deposit 2"
  },
  {
    "date": "2013-03-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2013-03",
    "Date (Year)": "2013",
    "category": "deposits",
    "accountId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "accountName": "Bank for deposit 2",
    "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "displayName": "Bank for deposit 2"
  },
  {
    "date": "2013-04-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2013-04",
    "Date (Year)": "2013",
    "category": "deposits",
    "accountId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "accountName": "Bank for deposit 2",
    "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "displayName": "Bank for deposit 2"
  },
  {
    "date": "2013-05-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2013-05",
    "Date (Year)": "2013",
    "category": "deposits",
    "accountId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "accountName": "Bank for deposit 2",
    "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "displayName": "Bank for deposit 2"
  },
  {
    "date": "2013-06-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2013-06",
    "Date (Year)": "2013",
    "category": "deposits",
    "accountId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "accountName": "Bank for deposit 2",
    "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "displayName": "Bank for deposit 2"
  },
  {
    "date": "2013-07-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2013-07",
    "Date (Year)": "2013",
    "category": "deposits",
    "accountId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "accountName": "Bank for deposit 2",
    "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "displayName": "Bank for deposit 2"
  },
  {
    "date": "2013-08-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2013-08",
    "Date (Year)": "2013",
    "category": "deposits",
    "accountId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "accountName": "Bank for deposit 2",
    "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "displayName": "Bank for deposit 2"
  },
  {
    "date": "2013-09-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2013-09",
    "Date (Year)": "2013",
    "category": "deposits",
    "accountId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "accountName": "Bank for deposit 2",
    "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "displayName": "Bank for deposit 2"
  },
  {
    "date": "2013-10-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2013-10",
    "Date (Year)": "2013",
    "category": "deposits",
    "accountId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "accountName": "Bank for deposit 2",
    "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "displayName": "Bank for deposit 2"
  },
  {
    "date": "2013-11-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2013-11",
    "Date (Year)": "2013",
    "category": "deposits",
    "accountId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "accountName": "Bank for deposit 2",
    "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "displayName": "Bank for deposit 2"
  },
  {
    "date": "2013-12-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2013-12",
    "Date (Year)": "2013",
    "category": "deposits",
    "accountId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "accountName": "Bank for deposit 2",
    "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "displayName": "Bank for deposit 2"
  },
  {
    "date": "2014-01-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2014-01",
    "Date (Year)": "2014",
    "category": "deposits",
    "accountId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "accountName": "Bank for deposit 2",
    "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "displayName": "Bank for deposit 2"
  },
  {
    "date": "2014-02-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2014-02",
    "Date (Year)": "2014",
    "category": "deposits",
    "accountId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "accountName": "Bank for deposit 2",
    "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "displayName": "Bank for deposit 2"
  },
  {
    "date": "2014-03-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2014-03",
    "Date (Year)": "2014",
    "category": "deposits",
    "accountId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "accountName": "Bank for deposit 2",
    "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "displayName": "Bank for deposit 2"
  },
  {
    "date": "2014-04-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2014-04",
    "Date (Year)": "2014",
    "category": "deposits",
    "accountId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "accountName": "Bank for deposit 2",
    "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "displayName": "Bank for deposit 2"
  },
  {
    "date": "2014-05-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2014-05",
    "Date (Year)": "2014",
    "category": "deposits",
    "accountId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "accountName": "Bank for deposit 2",
    "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "displayName": "Bank for deposit 2"
  },
  {
    "date": "2014-06-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2014-06",
    "Date (Year)": "2014",
    "category": "deposits",
    "accountId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "accountName": "Bank for deposit 2",
    "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "displayName": "Bank for deposit 2"
  },
  {
    "date": "2014-07-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2014-07",
    "Date (Year)": "2014",
    "category": "deposits",
    "accountId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "accountName": "Bank for deposit 2",
    "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "displayName": "Bank for deposit 2"
  },
  {
    "date": "2014-08-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2014-08",
    "Date (Year)": "2014",
    "category": "deposits",
    "accountId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "accountName": "Bank for deposit 2",
    "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "displayName": "Bank for deposit 2"
  },
  {
    "date": "2014-09-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2014-09",
    "Date (Year)": "2014",
    "category": "deposits",
    "accountId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "accountName": "Bank for deposit 2",
    "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "displayName": "Bank for deposit 2"
  },
  {
    "date": "2014-10-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2014-10",
    "Date (Year)": "2014",
    "category": "deposits",
    "accountId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "accountName": "Bank for deposit 2",
    "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "displayName": "Bank for deposit 2"
  },
  {
    "date": "2014-11-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2014-11",
    "Date (Year)": "2014",
    "category": "deposits",
    "accountId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "accountName": "Bank for deposit 2",
    "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "displayName": "Bank for deposit 2"
  },
  {
    "date": "2014-12-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2014-12",
    "Date (Year)": "2014",
    "category": "deposits",
    "accountId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "accountName": "Bank for deposit 2",
    "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "displayName": "Bank for deposit 2"
  },
  {
    "date": "2015-01-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2015-01",
    "Date (Year)": "2015",
    "category": "deposits",
    "accountId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "accountName": "Bank for deposit 2",
    "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "displayName": "Bank for deposit 2"
  },
  {
    "date": "2015-02-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2015-02",
    "Date (Year)": "2015",
    "category": "deposits",
    "accountId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "accountName": "Bank for deposit 2",
    "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "displayName": "Bank for deposit 2"
  },
  {
    "date": "2015-03-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2015-03",
    "Date (Year)": "2015",
    "category": "deposits",
    "accountId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "accountName": "Bank for deposit 2",
    "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "displayName": "Bank for deposit 2"
  },
  {
    "date": "2015-04-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2015-04",
    "Date (Year)": "2015",
    "category": "deposits",
    "accountId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "accountName": "Bank for deposit 2",
    "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "displayName": "Bank for deposit 2"
  },
  {
    "date": "2015-05-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2015-05",
    "Date (Year)": "2015",
    "category": "deposits",
    "accountId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "accountName": "Bank for deposit 2",
    "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "displayName": "Bank for deposit 2"
  },
  {
    "date": "2015-06-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2015-06",
    "Date (Year)": "2015",
    "category": "deposits",
    "accountId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "accountName": "Bank for deposit 2",
    "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "displayName": "Bank for deposit 2"
  },
  {
    "date": "2015-07-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2015-07",
    "Date (Year)": "2015",
    "category": "deposits",
    "accountId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "accountName": "Bank for deposit 2",
    "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "displayName": "Bank for deposit 2"
  },
  {
    "date": "2015-08-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2015-08",
    "Date (Year)": "2015",
    "category": "deposits",
    "accountId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "accountName": "Bank for deposit 2",
    "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "displayName": "Bank for deposit 2"
  },
  {
    "date": "2015-09-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2015-09",
    "Date (Year)": "2015",
    "category": "deposits",
    "accountId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "accountName": "Bank for deposit 2",
    "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "displayName": "Bank for deposit 2"
  },
  {
    "date": "2015-10-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2015-10",
    "Date (Year)": "2015",
    "category": "deposits",
    "accountId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "accountName": "Bank for deposit 2",
    "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "displayName": "Bank for deposit 2"
  },
  {
    "date": "2015-11-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2015-11",
    "Date (Year)": "2015",
    "category": "deposits",
    "accountId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "accountName": "Bank for deposit 2",
    "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "displayName": "Bank for deposit 2"
  },
  {
    "date": "2015-12-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2015-12",
    "Date (Year)": "2015",
    "category": "deposits",
    "accountId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "accountName": "Bank for deposit 2",
    "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "displayName": "Bank for deposit 2"
  },
  {
    "date": "2016-01-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2016-01",
    "Date (Year)": "2016",
    "category": "deposits",
    "accountId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "accountName": "Bank for deposit 2",
    "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "displayName": "Bank for deposit 2"
  },
  {
    "date": "2016-02-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2016-02",
    "Date (Year)": "2016",
    "category": "deposits",
    "accountId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "accountName": "Bank for deposit 2",
    "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "displayName": "Bank for deposit 2"
  },
  {
    "date": "2016-03-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2016-03",
    "Date (Year)": "2016",
    "category": "deposits",
    "accountId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "accountName": "Bank for deposit 2",
    "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "displayName": "Bank for deposit 2"
  },
  {
    "date": "2016-04-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2016-04",
    "Date (Year)": "2016",
    "category": "deposits",
    "accountId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "accountName": "Bank for deposit 2",
    "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "displayName": "Bank for deposit 2"
  },
  {
    "date": "2016-05-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2016-05",
    "Date (Year)": "2016",
    "category": "deposits",
    "accountId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "accountName": "Bank for deposit 2",
    "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "displayName": "Bank for deposit 2"
  },
  {
    "date": "2016-06-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2016-06",
    "Date (Year)": "2016",
    "category": "deposits",
    "accountId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "accountName": "Bank for deposit 2",
    "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "displayName": "Bank for deposit 2"
  },
  {
    "date": "2016-07-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2016-07",
    "Date (Year)": "2016",
    "category": "deposits",
    "accountId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "accountName": "Bank for deposit 2",
    "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "displayName": "Bank for deposit 2"
  },
  {
    "date": "2016-08-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2016-08",
    "Date (Year)": "2016",
    "category": "deposits",
    "accountId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "accountName": "Bank for deposit 2",
    "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "displayName": "Bank for deposit 2"
  },
  {
    "date": "2016-09-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2016-09",
    "Date (Year)": "2016",
    "category": "deposits",
    "accountId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "accountName": "Bank for deposit 2",
    "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "displayName": "Bank for deposit 2"
  },
  {
    "date": "2016-10-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2016-10",
    "Date (Year)": "2016",
    "category": "deposits",
    "accountId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "accountName": "Bank for deposit 2",
    "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "displayName": "Bank for deposit 2"
  },
  {
    "date": "2016-11-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2016-11",
    "Date (Year)": "2016",
    "category": "deposits",
    "accountId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "accountName": "Bank for deposit 2",
    "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "displayName": "Bank for deposit 2"
  },
  {
    "date": "2016-12-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2016-12",
    "Date (Year)": "2016",
    "category": "deposits",
    "accountId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "accountName": "Bank for deposit 2",
    "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "displayName": "Bank for deposit 2"
  },
  {
    "date": "2017-01-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2017-01",
    "Date (Year)": "2017",
    "category": "deposits",
    "accountId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "accountName": "Bank for deposit 2",
    "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "displayName": "Bank for deposit 2"
  },
  {
    "date": "2017-02-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2017-02",
    "Date (Year)": "2017",
    "category": "deposits",
    "accountId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "accountName": "Bank for deposit 2",
    "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "displayName": "Bank for deposit 2"
  },
  {
    "date": "2017-03-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2017-03",
    "Date (Year)": "2017",
    "category": "deposits",
    "accountId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "accountName": "Bank for deposit 2",
    "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "displayName": "Bank for deposit 2"
  },
  {
    "date": "2017-04-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2017-04",
    "Date (Year)": "2017",
    "category": "deposits",
    "accountId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "accountName": "Bank for deposit 2",
    "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "displayName": "Bank for deposit 2"
  },
  {
    "date": "2017-05-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2017-05",
    "Date (Year)": "2017",
    "category": "deposits",
    "accountId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "accountName": "Bank for deposit 2",
    "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "displayName": "Bank for deposit 2"
  },
  {
    "date": "2017-06-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2017-06",
    "Date (Year)": "2017",
    "category": "deposits",
    "accountId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "accountName": "Bank for deposit 2",
    "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "displayName": "Bank for deposit 2"
  },
  {
    "date": "2017-07-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2017-07",
    "Date (Year)": "2017",
    "category": "deposits",
    "accountId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "accountName": "Bank for deposit 2",
    "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "displayName": "Bank for deposit 2"
  },
  {
    "date": "2017-08-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2017-08",
    "Date (Year)": "2017",
    "category": "deposits",
    "accountId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "accountName": "Bank for deposit 2",
    "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "displayName": "Bank for deposit 2"
  },
  {
    "date": "2017-09-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2017-09",
    "Date (Year)": "2017",
    "category": "deposits",
    "accountId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "accountName": "Bank for deposit 2",
    "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "displayName": "Bank for deposit 2"
  },
  {
    "date": "2017-10-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2017-10",
    "Date (Year)": "2017",
    "category": "deposits",
    "accountId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "accountName": "Bank for deposit 2",
    "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "displayName": "Bank for deposit 2"
  },
  {
    "date": "2017-11-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2017-11",
    "Date (Year)": "2017",
    "category": "deposits",
    "accountId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "accountName": "Bank for deposit 2",
    "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "displayName": "Bank for deposit 2"
  },
  {
    "date": "2017-12-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2017-12",
    "Date (Year)": "2017",
    "category": "deposits",
    "accountId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "accountName": "Bank for deposit 2",
    "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "displayName": "Bank for deposit 2"
  },
  {
    "date": "2018-01-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2018-01",
    "Date (Year)": "2018",
    "category": "deposits",
    "accountId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "accountName": "Bank for deposit 2",
    "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "displayName": "Bank for deposit 2"
  },
  {
    "date": "2018-02-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2018-02",
    "Date (Year)": "2018",
    "category": "deposits",
    "accountId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "accountName": "Bank for deposit 2",
    "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "displayName": "Bank for deposit 2"
  },
  {
    "date": "2018-03-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2018-03",
    "Date (Year)": "2018",
    "category": "deposits",
    "accountId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "accountName": "Bank for deposit 2",
    "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "displayName": "Bank for deposit 2"
  },
  {
    "date": "2018-04-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2018-04",
    "Date (Year)": "2018",
    "category": "deposits",
    "accountId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "accountName": "Bank for deposit 2",
    "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "displayName": "Bank for deposit 2"
  },
  {
    "date": "2018-05-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2018-05",
    "Date (Year)": "2018",
    "category": "deposits",
    "accountId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "accountName": "Bank for deposit 2",
    "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "displayName": "Bank for deposit 2"
  },
  {
    "date": "2018-06-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2018-06",
    "Date (Year)": "2018",
    "category": "deposits",
    "accountId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "accountName": "Bank for deposit 2",
    "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "displayName": "Bank for deposit 2"
  },
  {
    "date": "2018-07-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2018-07",
    "Date (Year)": "2018",
    "category": "deposits",
    "accountId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "accountName": "Bank for deposit 2",
    "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "displayName": "Bank for deposit 2"
  },
  {
    "date": "2018-08-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2018-08",
    "Date (Year)": "2018",
    "category": "deposits",
    "accountId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "accountName": "Bank for deposit 2",
    "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "displayName": "Bank for deposit 2"
  },
  {
    "date": "2018-09-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2018-09",
    "Date (Year)": "2018",
    "category": "deposits",
    "accountId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "accountName": "Bank for deposit 2",
    "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "displayName": "Bank for deposit 2"
  },
  {
    "date": "2018-10-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2018-10",
    "Date (Year)": "2018",
    "category": "deposits",
    "accountId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "accountName": "Bank for deposit 2",
    "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "displayName": "Bank for deposit 2"
  },
  {
    "date": "2018-11-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2018-11",
    "Date (Year)": "2018",
    "category": "deposits",
    "accountId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "accountName": "Bank for deposit 2",
    "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "displayName": "Bank for deposit 2"
  },
  {
    "date": "2018-12-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2018-12",
    "Date (Year)": "2018",
    "category": "deposits",
    "accountId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "accountName": "Bank for deposit 2",
    "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "displayName": "Bank for deposit 2"
  },
  {
    "date": "2019-01-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2019-01",
    "Date (Year)": "2019",
    "category": "deposits",
    "accountId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "accountName": "Bank for deposit 2",
    "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "displayName": "Bank for deposit 2"
  },
  {
    "date": "2019-02-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2019-02",
    "Date (Year)": "2019",
    "category": "deposits",
    "accountId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "accountName": "Bank for deposit 2",
    "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "displayName": "Bank for deposit 2"
  },
  {
    "date": "2019-03-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2019-03",
    "Date (Year)": "2019",
    "category": "deposits",
    "accountId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "accountName": "Bank for deposit 2",
    "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "displayName": "Bank for deposit 2"
  },
  {
    "date": "2019-04-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2019-04",
    "Date (Year)": "2019",
    "category": "deposits",
    "accountId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "accountName": "Bank for deposit 2",
    "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "displayName": "Bank for deposit 2"
  },
  {
    "date": "2019-05-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2019-05",
    "Date (Year)": "2019",
    "category": "deposits",
    "accountId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "accountName": "Bank for deposit 2",
    "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "displayName": "Bank for deposit 2"
  },
  {
    "date": "2019-06-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2019-06",
    "Date (Year)": "2019",
    "category": "deposits",
    "accountId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "accountName": "Bank for deposit 2",
    "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "displayName": "Bank for deposit 2"
  },
  {
    "date": "2019-07-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2019-07",
    "Date (Year)": "2019",
    "category": "deposits",
    "accountId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "accountName": "Bank for deposit 2",
    "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "displayName": "Bank for deposit 2"
  },
  {
    "date": "2019-08-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2019-08",
    "Date (Year)": "2019",
    "category": "deposits",
    "accountId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "accountName": "Bank for deposit 2",
    "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "displayName": "Bank for deposit 2"
  },
  {
    "date": "2019-09-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2019-09",
    "Date (Year)": "2019",
    "category": "deposits",
    "accountId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "accountName": "Bank for deposit 2",
    "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "displayName": "Bank for deposit 2"
  },
  {
    "date": "2019-10-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2019-10",
    "Date (Year)": "2019",
    "category": "deposits",
    "accountId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "accountName": "Bank for deposit 2",
    "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "displayName": "Bank for deposit 2"
  },
  {
    "date": "2019-11-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2019-11",
    "Date (Year)": "2019",
    "category": "deposits",
    "accountId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "accountName": "Bank for deposit 2",
    "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "displayName": "Bank for deposit 2"
  },
  {
    "date": "2019-12-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2019-12",
    "Date (Year)": "2019",
    "category": "deposits",
    "accountId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "accountName": "Bank for deposit 2",
    "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "displayName": "Bank for deposit 2"
  },
  {
    "date": "2020-01-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2020-01",
    "Date (Year)": "2020",
    "category": "deposits",
    "accountId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "accountName": "Bank for deposit 2",
    "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "displayName": "Bank for deposit 2"
  },
  {
    "date": "2020-02-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2020-02",
    "Date (Year)": "2020",
    "category": "deposits",
    "accountId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "accountName": "Bank for deposit 2",
    "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "displayName": "Bank for deposit 2"
  },
  {
    "date": "2020-03-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2020-03",
    "Date (Year)": "2020",
    "category": "deposits",
    "accountId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "accountName": "Bank for deposit 2",
    "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "displayName": "Bank for deposit 2"
  },
  {
    "date": "2020-04-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2020-04",
    "Date (Year)": "2020",
    "category": "deposits",
    "accountId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "accountName": "Bank for deposit 2",
    "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "displayName": "Bank for deposit 2"
  },
  {
    "date": "2020-05-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2020-05",
    "Date (Year)": "2020",
    "category": "deposits",
    "accountId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "accountName": "Bank for deposit 2",
    "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "displayName": "Bank for deposit 2"
  },
  {
    "date": "2020-06-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2020-06",
    "Date (Year)": "2020",
    "category": "deposits",
    "accountId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "accountName": "Bank for deposit 2",
    "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "displayName": "Bank for deposit 2"
  },
  {
    "date": "2020-07-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2020-07",
    "Date (Year)": "2020",
    "category": "deposits",
    "accountId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "accountName": "Bank for deposit 2",
    "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "displayName": "Bank for deposit 2"
  },
  {
    "date": "2020-08-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2020-08",
    "Date (Year)": "2020",
    "category": "deposits",
    "accountId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "accountName": "Bank for deposit 2",
    "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "displayName": "Bank for deposit 2"
  },
  {
    "date": "2020-09-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2020-09",
    "Date (Year)": "2020",
    "category": "deposits",
    "accountId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "accountName": "Bank for deposit 2",
    "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "displayName": "Bank for deposit 2"
  },
  {
    "date": "2020-10-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2020-10",
    "Date (Year)": "2020",
    "category": "deposits",
    "accountId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "accountName": "Bank for deposit 2",
    "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "displayName": "Bank for deposit 2"
  },
  {
    "date": "2020-11-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2020-11",
    "Date (Year)": "2020",
    "category": "deposits",
    "accountId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "accountName": "Bank for deposit 2",
    "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "displayName": "Bank for deposit 2"
  },
  {
    "date": "2020-12-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2020-12",
    "Date (Year)": "2020",
    "category": "deposits",
    "accountId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "accountName": "Bank for deposit 2",
    "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "displayName": "Bank for deposit 2"
  },
  {
    "date": "2021-01-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2021-01",
    "Date (Year)": "2021",
    "category": "deposits",
    "accountId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "accountName": "Bank for deposit 2",
    "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "displayName": "Bank for deposit 2"
  },
  {
    "date": "2021-02-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2021-02",
    "Date (Year)": "2021",
    "category": "deposits",
    "accountId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "accountName": "Bank for deposit 2",
    "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "displayName": "Bank for deposit 2"
  },
  {
    "date": "2021-03-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2021-03",
    "Date (Year)": "2021",
    "category": "deposits",
    "accountId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "accountName": "Bank for deposit 2",
    "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "displayName": "Bank for deposit 2"
  },
  {
    "date": "2021-04-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2021-04",
    "Date (Year)": "2021",
    "category": "deposits",
    "accountId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "accountName": "Bank for deposit 2",
    "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "displayName": "Bank for deposit 2"
  },
  {
    "date": "2021-05-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2021-05",
    "Date (Year)": "2021",
    "category": "deposits",
    "accountId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "accountName": "Bank for deposit 2",
    "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "displayName": "Bank for deposit 2"
  },
  {
    "date": "2021-06-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2021-06",
    "Date (Year)": "2021",
    "category": "deposits",
    "accountId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "accountName": "Bank for deposit 2",
    "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "displayName": "Bank for deposit 2"
  },
  {
    "date": "2021-07-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 40000,
    "inflowType": "inflow",
    "inflowValue": 40000,
    "Date (Month)": "2021-07",
    "Date (Year)": "2021",
    "category": "deposits",
    "accountId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "accountName": "Bank for deposit 2",
    "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "displayName": "Bank for deposit 2"
  },
  {
    "date": "2021-08-01T00:00:00.000Z",
    "value": 40000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2021-08",
    "Date (Year)": "2021",
    "category": "deposits",
    "accountId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "accountName": "Bank for deposit 2",
    "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "displayName": "Bank for deposit 2"
  },
  {
    "date": "2021-09-01T00:00:00.000Z",
    "value": 40000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2021-09",
    "Date (Year)": "2021",
    "category": "deposits",
    "accountId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "accountName": "Bank for deposit 2",
    "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "displayName": "Bank for deposit 2"
  },
  {
    "date": "2021-10-01T00:00:00.000Z",
    "value": 40000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2021-10",
    "Date (Year)": "2021",
    "category": "deposits",
    "accountId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "accountName": "Bank for deposit 2",
    "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "displayName": "Bank for deposit 2"
  },
  {
    "date": "2021-11-01T00:00:00.000Z",
    "value": 40000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2021-11",
    "Date (Year)": "2021",
    "category": "deposits",
    "accountId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "accountName": "Bank for deposit 2",
    "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "displayName": "Bank for deposit 2"
  },
  {
    "date": "2021-12-01T00:00:00.000Z",
    "value": 40000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2021-12",
    "Date (Year)": "2021",
    "category": "deposits",
    "accountId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "accountName": "Bank for deposit 2",
    "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "displayName": "Bank for deposit 2"
  },
  {
    "date": "2022-01-01T00:00:00.000Z",
    "value": 40000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2022-01",
    "Date (Year)": "2022",
    "category": "deposits",
    "accountId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "accountName": "Bank for deposit 2",
    "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "displayName": "Bank for deposit 2"
  },
  {
    "date": "2022-02-01T00:00:00.000Z",
    "value": 40000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2022-02",
    "Date (Year)": "2022",
    "category": "deposits",
    "accountId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "accountName": "Bank for deposit 2",
    "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "displayName": "Bank for deposit 2"
  },
  {
    "date": "2022-03-01T00:00:00.000Z",
    "value": 40000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2022-03",
    "Date (Year)": "2022",
    "category": "deposits",
    "accountId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "accountName": "Bank for deposit 2",
    "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "displayName": "Bank for deposit 2"
  },
  {
    "date": "2022-04-01T00:00:00.000Z",
    "value": 40000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2022-04",
    "Date (Year)": "2022",
    "category": "deposits",
    "accountId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "accountName": "Bank for deposit 2",
    "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "displayName": "Bank for deposit 2"
  },
  {
    "date": "2022-05-01T00:00:00.000Z",
    "value": 40000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2022-05",
    "Date (Year)": "2022",
    "category": "deposits",
    "accountId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "accountName": "Bank for deposit 2",
    "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "displayName": "Bank for deposit 2"
  },
  {
    "date": "2022-06-01T00:00:00.000Z",
    "value": 40000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2022-06",
    "Date (Year)": "2022",
    "category": "deposits",
    "accountId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "accountName": "Bank for deposit 2",
    "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "displayName": "Bank for deposit 2"
  },
  {
    "date": "2022-07-01T00:00:00.000Z",
    "value": 40000,
    "incomeFromCapital": 400,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2022-07",
    "Date (Year)": "2022",
    "category": "deposits",
    "accountId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "accountName": "Bank for deposit 2",
    "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "displayName": "Bank for deposit 2"
  },
  {
    "date": "2022-07-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 400,
    "inflows": 0,
    "inflowType": "incomeFromCapital",
    "inflowValue": 400,
    "Date (Month)": "2022-07",
    "Date (Year)": "2022",
    "category": "deposits",
    "accountId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "accountName": "Bank for deposit 2",
    "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "displayName": "Bank for deposit 2"
  },
  {
    "date": "2022-08-01T00:00:00.000Z",
    "value": 40000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2022-08",
    "Date (Year)": "2022",
    "category": "deposits",
    "accountId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "accountName": "Bank for deposit 2",
    "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "displayName": "Bank for deposit 2"
  },
  {
    "date": "2022-09-01T00:00:00.000Z",
    "value": 40000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2022-09",
    "Date (Year)": "2022",
    "category": "deposits",
    "accountId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "accountName": "Bank for deposit 2",
    "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "displayName": "Bank for deposit 2"
  },
  {
    "date": "2022-10-01T00:00:00.000Z",
    "value": 40000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2022-10",
    "Date (Year)": "2022",
    "category": "deposits",
    "accountId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "accountName": "Bank for deposit 2",
    "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "displayName": "Bank for deposit 2"
  },
  {
    "date": "2022-11-01T00:00:00.000Z",
    "value": 40000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2022-11",
    "Date (Year)": "2022",
    "category": "deposits",
    "accountId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "accountName": "Bank for deposit 2",
    "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "displayName": "Bank for deposit 2"
  },
  {
    "date": "2022-12-01T00:00:00.000Z",
    "value": 40000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2022-12",
    "Date (Year)": "2022",
    "category": "deposits",
    "accountId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "accountName": "Bank for deposit 2",
    "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "displayName": "Bank for deposit 2"
  },
  {
    "date": "2023-01-01T00:00:00.000Z",
    "value": 40000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2023-01",
    "Date (Year)": "2023",
    "category": "deposits",
    "accountId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "accountName": "Bank for deposit 2",
    "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "displayName": "Bank for deposit 2"
  },
  {
    "date": "2023-02-01T00:00:00.000Z",
    "value": 40000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2023-02",
    "Date (Year)": "2023",
    "category": "deposits",
    "accountId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "accountName": "Bank for deposit 2",
    "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "displayName": "Bank for deposit 2"
  },
  {
    "date": "2023-03-01T00:00:00.000Z",
    "value": 40000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2023-03",
    "Date (Year)": "2023",
    "category": "deposits",
    "accountId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "accountName": "Bank for deposit 2",
    "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "displayName": "Bank for deposit 2"
  },
  {
    "date": "2023-04-01T00:00:00.000Z",
    "value": 40000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2023-04",
    "Date (Year)": "2023",
    "category": "deposits",
    "accountId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "accountName": "Bank for deposit 2",
    "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "displayName": "Bank for deposit 2"
  },
  {
    "date": "2023-05-01T00:00:00.000Z",
    "value": 40000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2023-05",
    "Date (Year)": "2023",
    "category": "deposits",
    "accountId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "accountName": "Bank for deposit 2",
    "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "displayName": "Bank for deposit 2"
  },
  {
    "date": "2023-06-01T00:00:00.000Z",
    "value": 40000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2023-06",
    "Date (Year)": "2023",
    "category": "deposits",
    "accountId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "accountName": "Bank for deposit 2",
    "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "displayName": "Bank for deposit 2"
  },
  {
    "date": "2023-07-01T00:00:00.000Z",
    "value": 40000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2023-07",
    "Date (Year)": "2023",
    "category": "deposits",
    "accountId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "accountName": "Bank for deposit 2",
    "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "displayName": "Bank for deposit 2"
  },
  {
    "date": "2023-08-01T00:00:00.000Z",
    "value": 40000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2023-08",
    "Date (Year)": "2023",
    "category": "deposits",
    "accountId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "accountName": "Bank for deposit 2",
    "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "displayName": "Bank for deposit 2"
  },
  {
    "date": "2023-09-01T00:00:00.000Z",
    "value": 40000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2023-09",
    "Date (Year)": "2023",
    "category": "deposits",
    "accountId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "accountName": "Bank for deposit 2",
    "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "displayName": "Bank for deposit 2"
  },
  {
    "date": "2023-10-01T00:00:00.000Z",
    "value": 40000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2023-10",
    "Date (Year)": "2023",
    "category": "deposits",
    "accountId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "accountName": "Bank for deposit 2",
    "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "displayName": "Bank for deposit 2"
  },
  {
    "date": "2023-11-01T00:00:00.000Z",
    "value": 40000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2023-11",
    "Date (Year)": "2023",
    "category": "deposits",
    "accountId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "accountName": "Bank for deposit 2",
    "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "displayName": "Bank for deposit 2"
  },
  {
    "date": "2023-12-01T00:00:00.000Z",
    "value": 40000,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2023-12",
    "Date (Year)": "2023",
    "category": "deposits",
    "accountId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "accountName": "Bank for deposit 2",
    "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
    "displayName": "Bank for deposit 2"
  },
  {
    "date": "2012-09-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2012-09",
    "Date (Year)": "2012",
    "category": "deposits",
    "accountId": "lyFDhscwJibqb-sf3pocN",
    "accountName": "Bank for fx deposit",
    "assetId": "lyFDhscwJibqb-sf3pocN",
    "displayName": "Bank for fx deposit"
  },
  {
    "date": "2012-10-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2012-10",
    "Date (Year)": "2012",
    "category": "deposits",
    "accountId": "lyFDhscwJibqb-sf3pocN",
    "accountName": "Bank for fx deposit",
    "assetId": "lyFDhscwJibqb-sf3pocN",
    "displayName": "Bank for fx deposit"
  },
  {
    "date": "2012-11-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2012-11",
    "Date (Year)": "2012",
    "category": "deposits",
    "accountId": "lyFDhscwJibqb-sf3pocN",
    "accountName": "Bank for fx deposit",
    "assetId": "lyFDhscwJibqb-sf3pocN",
    "displayName": "Bank for fx deposit"
  },
  {
    "date": "2012-12-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2012-12",
    "Date (Year)": "2012",
    "category": "deposits",
    "accountId": "lyFDhscwJibqb-sf3pocN",
    "accountName": "Bank for fx deposit",
    "assetId": "lyFDhscwJibqb-sf3pocN",
    "displayName": "Bank for fx deposit"
  },
  {
    "date": "2013-01-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2013-01",
    "Date (Year)": "2013",
    "category": "deposits",
    "accountId": "lyFDhscwJibqb-sf3pocN",
    "accountName": "Bank for fx deposit",
    "assetId": "lyFDhscwJibqb-sf3pocN",
    "displayName": "Bank for fx deposit"
  },
  {
    "date": "2013-02-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2013-02",
    "Date (Year)": "2013",
    "category": "deposits",
    "accountId": "lyFDhscwJibqb-sf3pocN",
    "accountName": "Bank for fx deposit",
    "assetId": "lyFDhscwJibqb-sf3pocN",
    "displayName": "Bank for fx deposit"
  },
  {
    "date": "2013-03-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2013-03",
    "Date (Year)": "2013",
    "category": "deposits",
    "accountId": "lyFDhscwJibqb-sf3pocN",
    "accountName": "Bank for fx deposit",
    "assetId": "lyFDhscwJibqb-sf3pocN",
    "displayName": "Bank for fx deposit"
  },
  {
    "date": "2013-04-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2013-04",
    "Date (Year)": "2013",
    "category": "deposits",
    "accountId": "lyFDhscwJibqb-sf3pocN",
    "accountName": "Bank for fx deposit",
    "assetId": "lyFDhscwJibqb-sf3pocN",
    "displayName": "Bank for fx deposit"
  },
  {
    "date": "2013-05-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2013-05",
    "Date (Year)": "2013",
    "category": "deposits",
    "accountId": "lyFDhscwJibqb-sf3pocN",
    "accountName": "Bank for fx deposit",
    "assetId": "lyFDhscwJibqb-sf3pocN",
    "displayName": "Bank for fx deposit"
  },
  {
    "date": "2013-06-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2013-06",
    "Date (Year)": "2013",
    "category": "deposits",
    "accountId": "lyFDhscwJibqb-sf3pocN",
    "accountName": "Bank for fx deposit",
    "assetId": "lyFDhscwJibqb-sf3pocN",
    "displayName": "Bank for fx deposit"
  },
  {
    "date": "2013-07-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2013-07",
    "Date (Year)": "2013",
    "category": "deposits",
    "accountId": "lyFDhscwJibqb-sf3pocN",
    "accountName": "Bank for fx deposit",
    "assetId": "lyFDhscwJibqb-sf3pocN",
    "displayName": "Bank for fx deposit"
  },
  {
    "date": "2013-08-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2013-08",
    "Date (Year)": "2013",
    "category": "deposits",
    "accountId": "lyFDhscwJibqb-sf3pocN",
    "accountName": "Bank for fx deposit",
    "assetId": "lyFDhscwJibqb-sf3pocN",
    "displayName": "Bank for fx deposit"
  },
  {
    "date": "2013-09-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2013-09",
    "Date (Year)": "2013",
    "category": "deposits",
    "accountId": "lyFDhscwJibqb-sf3pocN",
    "accountName": "Bank for fx deposit",
    "assetId": "lyFDhscwJibqb-sf3pocN",
    "displayName": "Bank for fx deposit"
  },
  {
    "date": "2013-10-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2013-10",
    "Date (Year)": "2013",
    "category": "deposits",
    "accountId": "lyFDhscwJibqb-sf3pocN",
    "accountName": "Bank for fx deposit",
    "assetId": "lyFDhscwJibqb-sf3pocN",
    "displayName": "Bank for fx deposit"
  },
  {
    "date": "2013-11-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2013-11",
    "Date (Year)": "2013",
    "category": "deposits",
    "accountId": "lyFDhscwJibqb-sf3pocN",
    "accountName": "Bank for fx deposit",
    "assetId": "lyFDhscwJibqb-sf3pocN",
    "displayName": "Bank for fx deposit"
  },
  {
    "date": "2013-12-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2013-12",
    "Date (Year)": "2013",
    "category": "deposits",
    "accountId": "lyFDhscwJibqb-sf3pocN",
    "accountName": "Bank for fx deposit",
    "assetId": "lyFDhscwJibqb-sf3pocN",
    "displayName": "Bank for fx deposit"
  },
  {
    "date": "2014-01-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2014-01",
    "Date (Year)": "2014",
    "category": "deposits",
    "accountId": "lyFDhscwJibqb-sf3pocN",
    "accountName": "Bank for fx deposit",
    "assetId": "lyFDhscwJibqb-sf3pocN",
    "displayName": "Bank for fx deposit"
  },
  {
    "date": "2014-02-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2014-02",
    "Date (Year)": "2014",
    "category": "deposits",
    "accountId": "lyFDhscwJibqb-sf3pocN",
    "accountName": "Bank for fx deposit",
    "assetId": "lyFDhscwJibqb-sf3pocN",
    "displayName": "Bank for fx deposit"
  },
  {
    "date": "2014-03-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2014-03",
    "Date (Year)": "2014",
    "category": "deposits",
    "accountId": "lyFDhscwJibqb-sf3pocN",
    "accountName": "Bank for fx deposit",
    "assetId": "lyFDhscwJibqb-sf3pocN",
    "displayName": "Bank for fx deposit"
  },
  {
    "date": "2014-04-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2014-04",
    "Date (Year)": "2014",
    "category": "deposits",
    "accountId": "lyFDhscwJibqb-sf3pocN",
    "accountName": "Bank for fx deposit",
    "assetId": "lyFDhscwJibqb-sf3pocN",
    "displayName": "Bank for fx deposit"
  },
  {
    "date": "2014-05-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2014-05",
    "Date (Year)": "2014",
    "category": "deposits",
    "accountId": "lyFDhscwJibqb-sf3pocN",
    "accountName": "Bank for fx deposit",
    "assetId": "lyFDhscwJibqb-sf3pocN",
    "displayName": "Bank for fx deposit"
  },
  {
    "date": "2014-06-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2014-06",
    "Date (Year)": "2014",
    "category": "deposits",
    "accountId": "lyFDhscwJibqb-sf3pocN",
    "accountName": "Bank for fx deposit",
    "assetId": "lyFDhscwJibqb-sf3pocN",
    "displayName": "Bank for fx deposit"
  },
  {
    "date": "2014-07-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2014-07",
    "Date (Year)": "2014",
    "category": "deposits",
    "accountId": "lyFDhscwJibqb-sf3pocN",
    "accountName": "Bank for fx deposit",
    "assetId": "lyFDhscwJibqb-sf3pocN",
    "displayName": "Bank for fx deposit"
  },
  {
    "date": "2014-08-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2014-08",
    "Date (Year)": "2014",
    "category": "deposits",
    "accountId": "lyFDhscwJibqb-sf3pocN",
    "accountName": "Bank for fx deposit",
    "assetId": "lyFDhscwJibqb-sf3pocN",
    "displayName": "Bank for fx deposit"
  },
  {
    "date": "2014-09-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2014-09",
    "Date (Year)": "2014",
    "category": "deposits",
    "accountId": "lyFDhscwJibqb-sf3pocN",
    "accountName": "Bank for fx deposit",
    "assetId": "lyFDhscwJibqb-sf3pocN",
    "displayName": "Bank for fx deposit"
  },
  {
    "date": "2014-10-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2014-10",
    "Date (Year)": "2014",
    "category": "deposits",
    "accountId": "lyFDhscwJibqb-sf3pocN",
    "accountName": "Bank for fx deposit",
    "assetId": "lyFDhscwJibqb-sf3pocN",
    "displayName": "Bank for fx deposit"
  },
  {
    "date": "2014-11-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2014-11",
    "Date (Year)": "2014",
    "category": "deposits",
    "accountId": "lyFDhscwJibqb-sf3pocN",
    "accountName": "Bank for fx deposit",
    "assetId": "lyFDhscwJibqb-sf3pocN",
    "displayName": "Bank for fx deposit"
  },
  {
    "date": "2014-12-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2014-12",
    "Date (Year)": "2014",
    "category": "deposits",
    "accountId": "lyFDhscwJibqb-sf3pocN",
    "accountName": "Bank for fx deposit",
    "assetId": "lyFDhscwJibqb-sf3pocN",
    "displayName": "Bank for fx deposit"
  },
  {
    "date": "2015-01-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2015-01",
    "Date (Year)": "2015",
    "category": "deposits",
    "accountId": "lyFDhscwJibqb-sf3pocN",
    "accountName": "Bank for fx deposit",
    "assetId": "lyFDhscwJibqb-sf3pocN",
    "displayName": "Bank for fx deposit"
  },
  {
    "date": "2015-02-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2015-02",
    "Date (Year)": "2015",
    "category": "deposits",
    "accountId": "lyFDhscwJibqb-sf3pocN",
    "accountName": "Bank for fx deposit",
    "assetId": "lyFDhscwJibqb-sf3pocN",
    "displayName": "Bank for fx deposit"
  },
  {
    "date": "2015-03-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2015-03",
    "Date (Year)": "2015",
    "category": "deposits",
    "accountId": "lyFDhscwJibqb-sf3pocN",
    "accountName": "Bank for fx deposit",
    "assetId": "lyFDhscwJibqb-sf3pocN",
    "displayName": "Bank for fx deposit"
  },
  {
    "date": "2015-04-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2015-04",
    "Date (Year)": "2015",
    "category": "deposits",
    "accountId": "lyFDhscwJibqb-sf3pocN",
    "accountName": "Bank for fx deposit",
    "assetId": "lyFDhscwJibqb-sf3pocN",
    "displayName": "Bank for fx deposit"
  },
  {
    "date": "2015-05-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2015-05",
    "Date (Year)": "2015",
    "category": "deposits",
    "accountId": "lyFDhscwJibqb-sf3pocN",
    "accountName": "Bank for fx deposit",
    "assetId": "lyFDhscwJibqb-sf3pocN",
    "displayName": "Bank for fx deposit"
  },
  {
    "date": "2015-06-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2015-06",
    "Date (Year)": "2015",
    "category": "deposits",
    "accountId": "lyFDhscwJibqb-sf3pocN",
    "accountName": "Bank for fx deposit",
    "assetId": "lyFDhscwJibqb-sf3pocN",
    "displayName": "Bank for fx deposit"
  },
  {
    "date": "2015-07-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2015-07",
    "Date (Year)": "2015",
    "category": "deposits",
    "accountId": "lyFDhscwJibqb-sf3pocN",
    "accountName": "Bank for fx deposit",
    "assetId": "lyFDhscwJibqb-sf3pocN",
    "displayName": "Bank for fx deposit"
  },
  {
    "date": "2015-08-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2015-08",
    "Date (Year)": "2015",
    "category": "deposits",
    "accountId": "lyFDhscwJibqb-sf3pocN",
    "accountName": "Bank for fx deposit",
    "assetId": "lyFDhscwJibqb-sf3pocN",
    "displayName": "Bank for fx deposit"
  },
  {
    "date": "2015-09-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2015-09",
    "Date (Year)": "2015",
    "category": "deposits",
    "accountId": "lyFDhscwJibqb-sf3pocN",
    "accountName": "Bank for fx deposit",
    "assetId": "lyFDhscwJibqb-sf3pocN",
    "displayName": "Bank for fx deposit"
  },
  {
    "date": "2015-10-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2015-10",
    "Date (Year)": "2015",
    "category": "deposits",
    "accountId": "lyFDhscwJibqb-sf3pocN",
    "accountName": "Bank for fx deposit",
    "assetId": "lyFDhscwJibqb-sf3pocN",
    "displayName": "Bank for fx deposit"
  },
  {
    "date": "2015-11-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2015-11",
    "Date (Year)": "2015",
    "category": "deposits",
    "accountId": "lyFDhscwJibqb-sf3pocN",
    "accountName": "Bank for fx deposit",
    "assetId": "lyFDhscwJibqb-sf3pocN",
    "displayName": "Bank for fx deposit"
  },
  {
    "date": "2015-12-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2015-12",
    "Date (Year)": "2015",
    "category": "deposits",
    "accountId": "lyFDhscwJibqb-sf3pocN",
    "accountName": "Bank for fx deposit",
    "assetId": "lyFDhscwJibqb-sf3pocN",
    "displayName": "Bank for fx deposit"
  },
  {
    "date": "2016-01-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2016-01",
    "Date (Year)": "2016",
    "category": "deposits",
    "accountId": "lyFDhscwJibqb-sf3pocN",
    "accountName": "Bank for fx deposit",
    "assetId": "lyFDhscwJibqb-sf3pocN",
    "displayName": "Bank for fx deposit"
  },
  {
    "date": "2016-02-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2016-02",
    "Date (Year)": "2016",
    "category": "deposits",
    "accountId": "lyFDhscwJibqb-sf3pocN",
    "accountName": "Bank for fx deposit",
    "assetId": "lyFDhscwJibqb-sf3pocN",
    "displayName": "Bank for fx deposit"
  },
  {
    "date": "2016-03-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2016-03",
    "Date (Year)": "2016",
    "category": "deposits",
    "accountId": "lyFDhscwJibqb-sf3pocN",
    "accountName": "Bank for fx deposit",
    "assetId": "lyFDhscwJibqb-sf3pocN",
    "displayName": "Bank for fx deposit"
  },
  {
    "date": "2016-04-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2016-04",
    "Date (Year)": "2016",
    "category": "deposits",
    "accountId": "lyFDhscwJibqb-sf3pocN",
    "accountName": "Bank for fx deposit",
    "assetId": "lyFDhscwJibqb-sf3pocN",
    "displayName": "Bank for fx deposit"
  },
  {
    "date": "2016-05-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2016-05",
    "Date (Year)": "2016",
    "category": "deposits",
    "accountId": "lyFDhscwJibqb-sf3pocN",
    "accountName": "Bank for fx deposit",
    "assetId": "lyFDhscwJibqb-sf3pocN",
    "displayName": "Bank for fx deposit"
  },
  {
    "date": "2016-06-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2016-06",
    "Date (Year)": "2016",
    "category": "deposits",
    "accountId": "lyFDhscwJibqb-sf3pocN",
    "accountName": "Bank for fx deposit",
    "assetId": "lyFDhscwJibqb-sf3pocN",
    "displayName": "Bank for fx deposit"
  },
  {
    "date": "2016-07-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2016-07",
    "Date (Year)": "2016",
    "category": "deposits",
    "accountId": "lyFDhscwJibqb-sf3pocN",
    "accountName": "Bank for fx deposit",
    "assetId": "lyFDhscwJibqb-sf3pocN",
    "displayName": "Bank for fx deposit"
  },
  {
    "date": "2016-08-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2016-08",
    "Date (Year)": "2016",
    "category": "deposits",
    "accountId": "lyFDhscwJibqb-sf3pocN",
    "accountName": "Bank for fx deposit",
    "assetId": "lyFDhscwJibqb-sf3pocN",
    "displayName": "Bank for fx deposit"
  },
  {
    "date": "2016-09-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2016-09",
    "Date (Year)": "2016",
    "category": "deposits",
    "accountId": "lyFDhscwJibqb-sf3pocN",
    "accountName": "Bank for fx deposit",
    "assetId": "lyFDhscwJibqb-sf3pocN",
    "displayName": "Bank for fx deposit"
  },
  {
    "date": "2016-10-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2016-10",
    "Date (Year)": "2016",
    "category": "deposits",
    "accountId": "lyFDhscwJibqb-sf3pocN",
    "accountName": "Bank for fx deposit",
    "assetId": "lyFDhscwJibqb-sf3pocN",
    "displayName": "Bank for fx deposit"
  },
  {
    "date": "2016-11-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2016-11",
    "Date (Year)": "2016",
    "category": "deposits",
    "accountId": "lyFDhscwJibqb-sf3pocN",
    "accountName": "Bank for fx deposit",
    "assetId": "lyFDhscwJibqb-sf3pocN",
    "displayName": "Bank for fx deposit"
  },
  {
    "date": "2016-12-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2016-12",
    "Date (Year)": "2016",
    "category": "deposits",
    "accountId": "lyFDhscwJibqb-sf3pocN",
    "accountName": "Bank for fx deposit",
    "assetId": "lyFDhscwJibqb-sf3pocN",
    "displayName": "Bank for fx deposit"
  },
  {
    "date": "2017-01-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2017-01",
    "Date (Year)": "2017",
    "category": "deposits",
    "accountId": "lyFDhscwJibqb-sf3pocN",
    "accountName": "Bank for fx deposit",
    "assetId": "lyFDhscwJibqb-sf3pocN",
    "displayName": "Bank for fx deposit"
  },
  {
    "date": "2017-02-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2017-02",
    "Date (Year)": "2017",
    "category": "deposits",
    "accountId": "lyFDhscwJibqb-sf3pocN",
    "accountName": "Bank for fx deposit",
    "assetId": "lyFDhscwJibqb-sf3pocN",
    "displayName": "Bank for fx deposit"
  },
  {
    "date": "2017-03-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2017-03",
    "Date (Year)": "2017",
    "category": "deposits",
    "accountId": "lyFDhscwJibqb-sf3pocN",
    "accountName": "Bank for fx deposit",
    "assetId": "lyFDhscwJibqb-sf3pocN",
    "displayName": "Bank for fx deposit"
  },
  {
    "date": "2017-04-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2017-04",
    "Date (Year)": "2017",
    "category": "deposits",
    "accountId": "lyFDhscwJibqb-sf3pocN",
    "accountName": "Bank for fx deposit",
    "assetId": "lyFDhscwJibqb-sf3pocN",
    "displayName": "Bank for fx deposit"
  },
  {
    "date": "2017-05-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2017-05",
    "Date (Year)": "2017",
    "category": "deposits",
    "accountId": "lyFDhscwJibqb-sf3pocN",
    "accountName": "Bank for fx deposit",
    "assetId": "lyFDhscwJibqb-sf3pocN",
    "displayName": "Bank for fx deposit"
  },
  {
    "date": "2017-06-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2017-06",
    "Date (Year)": "2017",
    "category": "deposits",
    "accountId": "lyFDhscwJibqb-sf3pocN",
    "accountName": "Bank for fx deposit",
    "assetId": "lyFDhscwJibqb-sf3pocN",
    "displayName": "Bank for fx deposit"
  },
  {
    "date": "2017-07-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2017-07",
    "Date (Year)": "2017",
    "category": "deposits",
    "accountId": "lyFDhscwJibqb-sf3pocN",
    "accountName": "Bank for fx deposit",
    "assetId": "lyFDhscwJibqb-sf3pocN",
    "displayName": "Bank for fx deposit"
  },
  {
    "date": "2017-08-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2017-08",
    "Date (Year)": "2017",
    "category": "deposits",
    "accountId": "lyFDhscwJibqb-sf3pocN",
    "accountName": "Bank for fx deposit",
    "assetId": "lyFDhscwJibqb-sf3pocN",
    "displayName": "Bank for fx deposit"
  },
  {
    "date": "2017-09-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2017-09",
    "Date (Year)": "2017",
    "category": "deposits",
    "accountId": "lyFDhscwJibqb-sf3pocN",
    "accountName": "Bank for fx deposit",
    "assetId": "lyFDhscwJibqb-sf3pocN",
    "displayName": "Bank for fx deposit"
  },
  {
    "date": "2017-10-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2017-10",
    "Date (Year)": "2017",
    "category": "deposits",
    "accountId": "lyFDhscwJibqb-sf3pocN",
    "accountName": "Bank for fx deposit",
    "assetId": "lyFDhscwJibqb-sf3pocN",
    "displayName": "Bank for fx deposit"
  },
  {
    "date": "2017-11-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2017-11",
    "Date (Year)": "2017",
    "category": "deposits",
    "accountId": "lyFDhscwJibqb-sf3pocN",
    "accountName": "Bank for fx deposit",
    "assetId": "lyFDhscwJibqb-sf3pocN",
    "displayName": "Bank for fx deposit"
  },
  {
    "date": "2017-12-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2017-12",
    "Date (Year)": "2017",
    "category": "deposits",
    "accountId": "lyFDhscwJibqb-sf3pocN",
    "accountName": "Bank for fx deposit",
    "assetId": "lyFDhscwJibqb-sf3pocN",
    "displayName": "Bank for fx deposit"
  },
  {
    "date": "2018-01-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2018-01",
    "Date (Year)": "2018",
    "category": "deposits",
    "accountId": "lyFDhscwJibqb-sf3pocN",
    "accountName": "Bank for fx deposit",
    "assetId": "lyFDhscwJibqb-sf3pocN",
    "displayName": "Bank for fx deposit"
  },
  {
    "date": "2018-02-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2018-02",
    "Date (Year)": "2018",
    "category": "deposits",
    "accountId": "lyFDhscwJibqb-sf3pocN",
    "accountName": "Bank for fx deposit",
    "assetId": "lyFDhscwJibqb-sf3pocN",
    "displayName": "Bank for fx deposit"
  },
  {
    "date": "2018-03-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2018-03",
    "Date (Year)": "2018",
    "category": "deposits",
    "accountId": "lyFDhscwJibqb-sf3pocN",
    "accountName": "Bank for fx deposit",
    "assetId": "lyFDhscwJibqb-sf3pocN",
    "displayName": "Bank for fx deposit"
  },
  {
    "date": "2018-04-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2018-04",
    "Date (Year)": "2018",
    "category": "deposits",
    "accountId": "lyFDhscwJibqb-sf3pocN",
    "accountName": "Bank for fx deposit",
    "assetId": "lyFDhscwJibqb-sf3pocN",
    "displayName": "Bank for fx deposit"
  },
  {
    "date": "2018-05-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2018-05",
    "Date (Year)": "2018",
    "category": "deposits",
    "accountId": "lyFDhscwJibqb-sf3pocN",
    "accountName": "Bank for fx deposit",
    "assetId": "lyFDhscwJibqb-sf3pocN",
    "displayName": "Bank for fx deposit"
  },
  {
    "date": "2018-06-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2018-06",
    "Date (Year)": "2018",
    "category": "deposits",
    "accountId": "lyFDhscwJibqb-sf3pocN",
    "accountName": "Bank for fx deposit",
    "assetId": "lyFDhscwJibqb-sf3pocN",
    "displayName": "Bank for fx deposit"
  },
  {
    "date": "2018-07-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2018-07",
    "Date (Year)": "2018",
    "category": "deposits",
    "accountId": "lyFDhscwJibqb-sf3pocN",
    "accountName": "Bank for fx deposit",
    "assetId": "lyFDhscwJibqb-sf3pocN",
    "displayName": "Bank for fx deposit"
  },
  {
    "date": "2018-08-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2018-08",
    "Date (Year)": "2018",
    "category": "deposits",
    "accountId": "lyFDhscwJibqb-sf3pocN",
    "accountName": "Bank for fx deposit",
    "assetId": "lyFDhscwJibqb-sf3pocN",
    "displayName": "Bank for fx deposit"
  },
  {
    "date": "2018-09-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2018-09",
    "Date (Year)": "2018",
    "category": "deposits",
    "accountId": "lyFDhscwJibqb-sf3pocN",
    "accountName": "Bank for fx deposit",
    "assetId": "lyFDhscwJibqb-sf3pocN",
    "displayName": "Bank for fx deposit"
  },
  {
    "date": "2018-10-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2018-10",
    "Date (Year)": "2018",
    "category": "deposits",
    "accountId": "lyFDhscwJibqb-sf3pocN",
    "accountName": "Bank for fx deposit",
    "assetId": "lyFDhscwJibqb-sf3pocN",
    "displayName": "Bank for fx deposit"
  },
  {
    "date": "2018-11-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2018-11",
    "Date (Year)": "2018",
    "category": "deposits",
    "accountId": "lyFDhscwJibqb-sf3pocN",
    "accountName": "Bank for fx deposit",
    "assetId": "lyFDhscwJibqb-sf3pocN",
    "displayName": "Bank for fx deposit"
  },
  {
    "date": "2018-12-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2018-12",
    "Date (Year)": "2018",
    "category": "deposits",
    "accountId": "lyFDhscwJibqb-sf3pocN",
    "accountName": "Bank for fx deposit",
    "assetId": "lyFDhscwJibqb-sf3pocN",
    "displayName": "Bank for fx deposit"
  },
  {
    "date": "2019-01-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2019-01",
    "Date (Year)": "2019",
    "category": "deposits",
    "accountId": "lyFDhscwJibqb-sf3pocN",
    "accountName": "Bank for fx deposit",
    "assetId": "lyFDhscwJibqb-sf3pocN",
    "displayName": "Bank for fx deposit"
  },
  {
    "date": "2019-02-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2019-02",
    "Date (Year)": "2019",
    "category": "deposits",
    "accountId": "lyFDhscwJibqb-sf3pocN",
    "accountName": "Bank for fx deposit",
    "assetId": "lyFDhscwJibqb-sf3pocN",
    "displayName": "Bank for fx deposit"
  },
  {
    "date": "2019-03-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2019-03",
    "Date (Year)": "2019",
    "category": "deposits",
    "accountId": "lyFDhscwJibqb-sf3pocN",
    "accountName": "Bank for fx deposit",
    "assetId": "lyFDhscwJibqb-sf3pocN",
    "displayName": "Bank for fx deposit"
  },
  {
    "date": "2019-04-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2019-04",
    "Date (Year)": "2019",
    "category": "deposits",
    "accountId": "lyFDhscwJibqb-sf3pocN",
    "accountName": "Bank for fx deposit",
    "assetId": "lyFDhscwJibqb-sf3pocN",
    "displayName": "Bank for fx deposit"
  },
  {
    "date": "2019-05-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2019-05",
    "Date (Year)": "2019",
    "category": "deposits",
    "accountId": "lyFDhscwJibqb-sf3pocN",
    "accountName": "Bank for fx deposit",
    "assetId": "lyFDhscwJibqb-sf3pocN",
    "displayName": "Bank for fx deposit"
  },
  {
    "date": "2019-06-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2019-06",
    "Date (Year)": "2019",
    "category": "deposits",
    "accountId": "lyFDhscwJibqb-sf3pocN",
    "accountName": "Bank for fx deposit",
    "assetId": "lyFDhscwJibqb-sf3pocN",
    "displayName": "Bank for fx deposit"
  },
  {
    "date": "2019-07-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2019-07",
    "Date (Year)": "2019",
    "category": "deposits",
    "accountId": "lyFDhscwJibqb-sf3pocN",
    "accountName": "Bank for fx deposit",
    "assetId": "lyFDhscwJibqb-sf3pocN",
    "displayName": "Bank for fx deposit"
  },
  {
    "date": "2019-08-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2019-08",
    "Date (Year)": "2019",
    "category": "deposits",
    "accountId": "lyFDhscwJibqb-sf3pocN",
    "accountName": "Bank for fx deposit",
    "assetId": "lyFDhscwJibqb-sf3pocN",
    "displayName": "Bank for fx deposit"
  },
  {
    "date": "2019-09-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2019-09",
    "Date (Year)": "2019",
    "category": "deposits",
    "accountId": "lyFDhscwJibqb-sf3pocN",
    "accountName": "Bank for fx deposit",
    "assetId": "lyFDhscwJibqb-sf3pocN",
    "displayName": "Bank for fx deposit"
  },
  {
    "date": "2019-10-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2019-10",
    "Date (Year)": "2019",
    "category": "deposits",
    "accountId": "lyFDhscwJibqb-sf3pocN",
    "accountName": "Bank for fx deposit",
    "assetId": "lyFDhscwJibqb-sf3pocN",
    "displayName": "Bank for fx deposit"
  },
  {
    "date": "2019-11-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2019-11",
    "Date (Year)": "2019",
    "category": "deposits",
    "accountId": "lyFDhscwJibqb-sf3pocN",
    "accountName": "Bank for fx deposit",
    "assetId": "lyFDhscwJibqb-sf3pocN",
    "displayName": "Bank for fx deposit"
  },
  {
    "date": "2019-12-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2019-12",
    "Date (Year)": "2019",
    "category": "deposits",
    "accountId": "lyFDhscwJibqb-sf3pocN",
    "accountName": "Bank for fx deposit",
    "assetId": "lyFDhscwJibqb-sf3pocN",
    "displayName": "Bank for fx deposit"
  },
  {
    "date": "2020-01-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2020-01",
    "Date (Year)": "2020",
    "category": "deposits",
    "accountId": "lyFDhscwJibqb-sf3pocN",
    "accountName": "Bank for fx deposit",
    "assetId": "lyFDhscwJibqb-sf3pocN",
    "displayName": "Bank for fx deposit"
  },
  {
    "date": "2020-02-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2020-02",
    "Date (Year)": "2020",
    "category": "deposits",
    "accountId": "lyFDhscwJibqb-sf3pocN",
    "accountName": "Bank for fx deposit",
    "assetId": "lyFDhscwJibqb-sf3pocN",
    "displayName": "Bank for fx deposit"
  },
  {
    "date": "2020-03-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2020-03",
    "Date (Year)": "2020",
    "category": "deposits",
    "accountId": "lyFDhscwJibqb-sf3pocN",
    "accountName": "Bank for fx deposit",
    "assetId": "lyFDhscwJibqb-sf3pocN",
    "displayName": "Bank for fx deposit"
  },
  {
    "date": "2020-04-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2020-04",
    "Date (Year)": "2020",
    "category": "deposits",
    "accountId": "lyFDhscwJibqb-sf3pocN",
    "accountName": "Bank for fx deposit",
    "assetId": "lyFDhscwJibqb-sf3pocN",
    "displayName": "Bank for fx deposit"
  },
  {
    "date": "2020-05-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2020-05",
    "Date (Year)": "2020",
    "category": "deposits",
    "accountId": "lyFDhscwJibqb-sf3pocN",
    "accountName": "Bank for fx deposit",
    "assetId": "lyFDhscwJibqb-sf3pocN",
    "displayName": "Bank for fx deposit"
  },
  {
    "date": "2020-06-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2020-06",
    "Date (Year)": "2020",
    "category": "deposits",
    "accountId": "lyFDhscwJibqb-sf3pocN",
    "accountName": "Bank for fx deposit",
    "assetId": "lyFDhscwJibqb-sf3pocN",
    "displayName": "Bank for fx deposit"
  },
  {
    "date": "2020-07-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2020-07",
    "Date (Year)": "2020",
    "category": "deposits",
    "accountId": "lyFDhscwJibqb-sf3pocN",
    "accountName": "Bank for fx deposit",
    "assetId": "lyFDhscwJibqb-sf3pocN",
    "displayName": "Bank for fx deposit"
  },
  {
    "date": "2020-08-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2020-08",
    "Date (Year)": "2020",
    "category": "deposits",
    "accountId": "lyFDhscwJibqb-sf3pocN",
    "accountName": "Bank for fx deposit",
    "assetId": "lyFDhscwJibqb-sf3pocN",
    "displayName": "Bank for fx deposit"
  },
  {
    "date": "2020-09-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2020-09",
    "Date (Year)": "2020",
    "category": "deposits",
    "accountId": "lyFDhscwJibqb-sf3pocN",
    "accountName": "Bank for fx deposit",
    "assetId": "lyFDhscwJibqb-sf3pocN",
    "displayName": "Bank for fx deposit"
  },
  {
    "date": "2020-10-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2020-10",
    "Date (Year)": "2020",
    "category": "deposits",
    "accountId": "lyFDhscwJibqb-sf3pocN",
    "accountName": "Bank for fx deposit",
    "assetId": "lyFDhscwJibqb-sf3pocN",
    "displayName": "Bank for fx deposit"
  },
  {
    "date": "2020-11-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2020-11",
    "Date (Year)": "2020",
    "category": "deposits",
    "accountId": "lyFDhscwJibqb-sf3pocN",
    "accountName": "Bank for fx deposit",
    "assetId": "lyFDhscwJibqb-sf3pocN",
    "displayName": "Bank for fx deposit"
  },
  {
    "date": "2020-12-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2020-12",
    "Date (Year)": "2020",
    "category": "deposits",
    "accountId": "lyFDhscwJibqb-sf3pocN",
    "accountName": "Bank for fx deposit",
    "assetId": "lyFDhscwJibqb-sf3pocN",
    "displayName": "Bank for fx deposit"
  },
  {
    "date": "2021-01-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2021-01",
    "Date (Year)": "2021",
    "category": "deposits",
    "accountId": "lyFDhscwJibqb-sf3pocN",
    "accountName": "Bank for fx deposit",
    "assetId": "lyFDhscwJibqb-sf3pocN",
    "displayName": "Bank for fx deposit"
  },
  {
    "date": "2021-02-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2021-02",
    "Date (Year)": "2021",
    "category": "deposits",
    "accountId": "lyFDhscwJibqb-sf3pocN",
    "accountName": "Bank for fx deposit",
    "assetId": "lyFDhscwJibqb-sf3pocN",
    "displayName": "Bank for fx deposit"
  },
  {
    "date": "2021-03-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2021-03",
    "Date (Year)": "2021",
    "category": "deposits",
    "accountId": "lyFDhscwJibqb-sf3pocN",
    "accountName": "Bank for fx deposit",
    "assetId": "lyFDhscwJibqb-sf3pocN",
    "displayName": "Bank for fx deposit"
  },
  {
    "date": "2021-04-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2021-04",
    "Date (Year)": "2021",
    "category": "deposits",
    "accountId": "lyFDhscwJibqb-sf3pocN",
    "accountName": "Bank for fx deposit",
    "assetId": "lyFDhscwJibqb-sf3pocN",
    "displayName": "Bank for fx deposit"
  },
  {
    "date": "2021-05-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2021-05",
    "Date (Year)": "2021",
    "category": "deposits",
    "accountId": "lyFDhscwJibqb-sf3pocN",
    "accountName": "Bank for fx deposit",
    "assetId": "lyFDhscwJibqb-sf3pocN",
    "displayName": "Bank for fx deposit"
  },
  {
    "date": "2021-06-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2021-06",
    "Date (Year)": "2021",
    "category": "deposits",
    "accountId": "lyFDhscwJibqb-sf3pocN",
    "accountName": "Bank for fx deposit",
    "assetId": "lyFDhscwJibqb-sf3pocN",
    "displayName": "Bank for fx deposit"
  },
  {
    "date": "2021-07-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2021-07",
    "Date (Year)": "2021",
    "category": "deposits",
    "accountId": "lyFDhscwJibqb-sf3pocN",
    "accountName": "Bank for fx deposit",
    "assetId": "lyFDhscwJibqb-sf3pocN",
    "displayName": "Bank for fx deposit"
  },
  {
    "date": "2021-08-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2021-08",
    "Date (Year)": "2021",
    "category": "deposits",
    "accountId": "lyFDhscwJibqb-sf3pocN",
    "accountName": "Bank for fx deposit",
    "assetId": "lyFDhscwJibqb-sf3pocN",
    "displayName": "Bank for fx deposit"
  },
  {
    "date": "2021-09-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2021-09",
    "Date (Year)": "2021",
    "category": "deposits",
    "accountId": "lyFDhscwJibqb-sf3pocN",
    "accountName": "Bank for fx deposit",
    "assetId": "lyFDhscwJibqb-sf3pocN",
    "displayName": "Bank for fx deposit"
  },
  {
    "date": "2021-10-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2021-10",
    "Date (Year)": "2021",
    "category": "deposits",
    "accountId": "lyFDhscwJibqb-sf3pocN",
    "accountName": "Bank for fx deposit",
    "assetId": "lyFDhscwJibqb-sf3pocN",
    "displayName": "Bank for fx deposit"
  },
  {
    "date": "2021-11-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2021-11",
    "Date (Year)": "2021",
    "category": "deposits",
    "accountId": "lyFDhscwJibqb-sf3pocN",
    "accountName": "Bank for fx deposit",
    "assetId": "lyFDhscwJibqb-sf3pocN",
    "displayName": "Bank for fx deposit"
  },
  {
    "date": "2021-12-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2021-12",
    "Date (Year)": "2021",
    "category": "deposits",
    "accountId": "lyFDhscwJibqb-sf3pocN",
    "accountName": "Bank for fx deposit",
    "assetId": "lyFDhscwJibqb-sf3pocN",
    "displayName": "Bank for fx deposit"
  },
  {
    "date": "2022-01-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2022-01",
    "Date (Year)": "2022",
    "category": "deposits",
    "accountId": "lyFDhscwJibqb-sf3pocN",
    "accountName": "Bank for fx deposit",
    "assetId": "lyFDhscwJibqb-sf3pocN",
    "displayName": "Bank for fx deposit"
  },
  {
    "date": "2022-02-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2022-02",
    "Date (Year)": "2022",
    "category": "deposits",
    "accountId": "lyFDhscwJibqb-sf3pocN",
    "accountName": "Bank for fx deposit",
    "assetId": "lyFDhscwJibqb-sf3pocN",
    "displayName": "Bank for fx deposit"
  },
  {
    "date": "2022-03-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2022-03",
    "Date (Year)": "2022",
    "category": "deposits",
    "accountId": "lyFDhscwJibqb-sf3pocN",
    "accountName": "Bank for fx deposit",
    "assetId": "lyFDhscwJibqb-sf3pocN",
    "displayName": "Bank for fx deposit"
  },
  {
    "date": "2022-04-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2022-04",
    "Date (Year)": "2022",
    "category": "deposits",
    "accountId": "lyFDhscwJibqb-sf3pocN",
    "accountName": "Bank for fx deposit",
    "assetId": "lyFDhscwJibqb-sf3pocN",
    "displayName": "Bank for fx deposit"
  },
  {
    "date": "2022-05-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2022-05",
    "Date (Year)": "2022",
    "category": "deposits",
    "accountId": "lyFDhscwJibqb-sf3pocN",
    "accountName": "Bank for fx deposit",
    "assetId": "lyFDhscwJibqb-sf3pocN",
    "displayName": "Bank for fx deposit"
  },
  {
    "date": "2022-06-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2022-06",
    "Date (Year)": "2022",
    "category": "deposits",
    "accountId": "lyFDhscwJibqb-sf3pocN",
    "accountName": "Bank for fx deposit",
    "assetId": "lyFDhscwJibqb-sf3pocN",
    "displayName": "Bank for fx deposit"
  },
  {
    "date": "2022-07-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2022-07",
    "Date (Year)": "2022",
    "category": "deposits",
    "accountId": "lyFDhscwJibqb-sf3pocN",
    "accountName": "Bank for fx deposit",
    "assetId": "lyFDhscwJibqb-sf3pocN",
    "displayName": "Bank for fx deposit"
  },
  {
    "date": "2022-08-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2022-08",
    "Date (Year)": "2022",
    "category": "deposits",
    "accountId": "lyFDhscwJibqb-sf3pocN",
    "accountName": "Bank for fx deposit",
    "assetId": "lyFDhscwJibqb-sf3pocN",
    "displayName": "Bank for fx deposit"
  },
  {
    "date": "2022-09-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2022-09",
    "Date (Year)": "2022",
    "category": "deposits",
    "accountId": "lyFDhscwJibqb-sf3pocN",
    "accountName": "Bank for fx deposit",
    "assetId": "lyFDhscwJibqb-sf3pocN",
    "displayName": "Bank for fx deposit"
  },
  {
    "date": "2022-10-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2022-10",
    "Date (Year)": "2022",
    "category": "deposits",
    "accountId": "lyFDhscwJibqb-sf3pocN",
    "accountName": "Bank for fx deposit",
    "assetId": "lyFDhscwJibqb-sf3pocN",
    "displayName": "Bank for fx deposit"
  },
  {
    "date": "2022-11-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2022-11",
    "Date (Year)": "2022",
    "category": "deposits",
    "accountId": "lyFDhscwJibqb-sf3pocN",
    "accountName": "Bank for fx deposit",
    "assetId": "lyFDhscwJibqb-sf3pocN",
    "displayName": "Bank for fx deposit"
  },
  {
    "date": "2022-12-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2022-12",
    "Date (Year)": "2022",
    "category": "deposits",
    "accountId": "lyFDhscwJibqb-sf3pocN",
    "accountName": "Bank for fx deposit",
    "assetId": "lyFDhscwJibqb-sf3pocN",
    "displayName": "Bank for fx deposit"
  },
  {
    "date": "2023-01-01T00:00:00.000Z",
    "value": 0,
    "incomeFromCapital": 0,
    "inflows": 10137.8751,
    "inflowType": "inflow",
    "inflowValue": 10137.8751,
    "Date (Month)": "2023-01",
    "Date (Year)": "2023",
    "category": "deposits",
    "accountId": "lyFDhscwJibqb-sf3pocN",
    "accountName": "Bank for fx deposit",
    "assetId": "lyFDhscwJibqb-sf3pocN",
    "displayName": "Bank for fx deposit"
  },
  {
    "date": "2023-02-01T00:00:00.000Z",
    "value": 10137.8751,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2023-02",
    "Date (Year)": "2023",
    "category": "deposits",
    "accountId": "lyFDhscwJibqb-sf3pocN",
    "accountName": "Bank for fx deposit",
    "assetId": "lyFDhscwJibqb-sf3pocN",
    "displayName": "Bank for fx deposit"
  },
  {
    "date": "2023-03-01T00:00:00.000Z",
    "value": 10137.8751,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2023-03",
    "Date (Year)": "2023",
    "category": "deposits",
    "accountId": "lyFDhscwJibqb-sf3pocN",
    "accountName": "Bank for fx deposit",
    "assetId": "lyFDhscwJibqb-sf3pocN",
    "displayName": "Bank for fx deposit"
  },
  {
    "date": "2023-04-01T00:00:00.000Z",
    "value": 10137.8751,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2023-04",
    "Date (Year)": "2023",
    "category": "deposits",
    "accountId": "lyFDhscwJibqb-sf3pocN",
    "accountName": "Bank for fx deposit",
    "assetId": "lyFDhscwJibqb-sf3pocN",
    "displayName": "Bank for fx deposit"
  },
  {
    "date": "2023-05-01T00:00:00.000Z",
    "value": 10137.8751,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2023-05",
    "Date (Year)": "2023",
    "category": "deposits",
    "accountId": "lyFDhscwJibqb-sf3pocN",
    "accountName": "Bank for fx deposit",
    "assetId": "lyFDhscwJibqb-sf3pocN",
    "displayName": "Bank for fx deposit"
  },
  {
    "date": "2023-06-01T00:00:00.000Z",
    "value": 10137.8751,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2023-06",
    "Date (Year)": "2023",
    "category": "deposits",
    "accountId": "lyFDhscwJibqb-sf3pocN",
    "accountName": "Bank for fx deposit",
    "assetId": "lyFDhscwJibqb-sf3pocN",
    "displayName": "Bank for fx deposit"
  },
  {
    "date": "2023-07-01T00:00:00.000Z",
    "value": 10137.8751,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2023-07",
    "Date (Year)": "2023",
    "category": "deposits",
    "accountId": "lyFDhscwJibqb-sf3pocN",
    "accountName": "Bank for fx deposit",
    "assetId": "lyFDhscwJibqb-sf3pocN",
    "displayName": "Bank for fx deposit"
  },
  {
    "date": "2023-08-01T00:00:00.000Z",
    "value": 10137.8751,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2023-08",
    "Date (Year)": "2023",
    "category": "deposits",
    "accountId": "lyFDhscwJibqb-sf3pocN",
    "accountName": "Bank for fx deposit",
    "assetId": "lyFDhscwJibqb-sf3pocN",
    "displayName": "Bank for fx deposit"
  },
  {
    "date": "2023-09-01T00:00:00.000Z",
    "value": 10137.8751,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2023-09",
    "Date (Year)": "2023",
    "category": "deposits",
    "accountId": "lyFDhscwJibqb-sf3pocN",
    "accountName": "Bank for fx deposit",
    "assetId": "lyFDhscwJibqb-sf3pocN",
    "displayName": "Bank for fx deposit"
  },
  {
    "date": "2023-10-01T00:00:00.000Z",
    "value": 10137.8751,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2023-10",
    "Date (Year)": "2023",
    "category": "deposits",
    "accountId": "lyFDhscwJibqb-sf3pocN",
    "accountName": "Bank for fx deposit",
    "assetId": "lyFDhscwJibqb-sf3pocN",
    "displayName": "Bank for fx deposit"
  },
  {
    "date": "2023-11-01T00:00:00.000Z",
    "value": 10137.8751,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2023-11",
    "Date (Year)": "2023",
    "category": "deposits",
    "accountId": "lyFDhscwJibqb-sf3pocN",
    "accountName": "Bank for fx deposit",
    "assetId": "lyFDhscwJibqb-sf3pocN",
    "displayName": "Bank for fx deposit"
  },
  {
    "date": "2023-12-01T00:00:00.000Z",
    "value": 10137.8751,
    "incomeFromCapital": 0,
    "inflows": 0,
    "inflowType": "inflow",
    "inflowValue": 0,
    "Date (Month)": "2023-12",
    "Date (Year)": "2023",
    "category": "deposits",
    "accountId": "lyFDhscwJibqb-sf3pocN",
    "accountName": "Bank for fx deposit",
    "assetId": "lyFDhscwJibqb-sf3pocN",
    "displayName": "Bank for fx deposit"
  }
]