/* eslint-disable no-unsafe-optional-chaining */

import { createSelector } from '@reduxjs/toolkit';
import * as globals from '..';
import { applyFIFOOnCategory } from '../../../../misc/arrayHelpers';

// returns an array with all stocks transactions (real or real+simulated) up to the Slider Date (baseDate) or to today (depending on dashboardMode in state)
// no isolated transactions are included
// apply FIFO to calculate quantityOpen
export const objectsOfValueTransactions = createSelector(
  (state) => state.simulation?.dashboardMode,
  (state) => state.data?.objectsOfValue.transactions,
  (state) => state.data?.objectsOfValue.simulatedTransactions,
  (state) => state.simulation?.baseDate,
  (state) => state.projects,
  (dashboardMode, transactions, simulatedTransactions, baseDate, projects) => {
    let returnTransactions;
    if (dashboardMode === 'projects') {
      const isolatedProjects = projects?.filter((project) => project.settings?.isIsolated).map((project) => project.id);
      const simulatedTransactionsWithoutIsolated = simulatedTransactions.filter((txn) => !isolatedProjects.includes(txn.projectId));

      returnTransactions = (transactions || []).concat(simulatedTransactionsWithoutIsolated).filter((transaction) => transaction.date <= baseDate);
      // no recurring transactions in real estate
    } else {
      returnTransactions = transactions || [];
    }
    return applyFIFOOnCategory(returnTransactions);
  },
);

// returns an array with all stocks transactions (simulated and real) up to the Simulation End Date (slider end)
// including all isolated transactions
export const objectsOfValueTransactionsProjectView = createSelector(
  (state) => state.simulation?.dashboardMode,
  (state) => state.data?.objectsOfValue.transactions,
  (state) => state.data?.objectsOfValue.simulatedTransactions,
  (dashboardMode, transactions, simulatedTransactions) => {
    let returnTransactions;
    if (dashboardMode === 'projects') {
      returnTransactions = (transactions || []).concat(simulatedTransactions);
      // no recurring transactions in real estate
    } else {
      returnTransactions = transactions || [];
    }
    return applyFIFOOnCategory(returnTransactions);
  },
);
