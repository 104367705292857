import React, { useEffect, useState, useRef } from 'react';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

// helper function which traverses the DOM to determine what is the constraining element for display:fixed
export default function ToolTipNoIcon() {
  const [tooltip, setTooltip] = useState(null);

  // you can add classNames as string if necessary
  const tipRef = useRef(null);

  const timeoutRef = useRef(null); // to store the timeout reference

  useEffect(() => {
    const handleShowTooltip = (event) => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current); // clear any existing timeout
      }

      // all custom parameters come in event.detail
      if (tipRef.current) {
        if (event.detail === null) {
          tipRef.current.style.opacity = 0;
          tipRef.current.style.marginLeft = '10px';
        } else {
          setTooltip(event.detail);
          tipRef.current.style.opacity = 1;
          tipRef.current.style.marginLeft = '20px';

          // Set the timeout to reset the styles after 10 seconds
          timeoutRef.current = setTimeout(() => {
            tipRef.current.style.opacity = 0;
            tipRef.current.style.marginLeft = '10px';
          }, 10000);
        }
      }
    };

    window.addEventListener('showTooltip', handleShowTooltip);

    return () => {
      window.removeEventListener('showTooltip', handleShowTooltip);
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current); // clear the timeout when the component is unmounted
      }
    };
  }, []);

  // so why is this here?
  // position: absolute does not work, because parts of the tooltip are outside of the parent element and are not visible (obstructed by other elements)
  // position: fixed does not work, because one of the parent element within the table header has a filter css property and that means this is the containing element
  // and not the viewport

  // chrome doesn't trigger onMouseOut when the div gets deleted from DOM
  // for now we've been adding manual dispatch of clearTooltip to the two Close buttons
  // should this become a bigger problem, the solution is to add a caller element to tooltip state in Redux
  // and in ToolTipReceiver useEffect on every change to check if this caller element still exists in DOM
  // if not, dispatch clearTooltip

  return (
    <div aria-live="assertive" className="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start z-[9999]">
      <div
        className={classNames(
          tooltip?.className,
          tooltip === null ? 'hidden' : '',
          'fixed prose leading-5 bg-white border border-gray-100 text-gray-900 text-xs tracking-wider rounded-sm font-light m-2 px-3 py-2 flex items-center group-hover:delay-500 z-[999]',
        )}
        style={{ top: (tooltip?.y ?? 0) + 5, left: tooltip?.x ?? 0 + 10, opacity: 0 }}
        ref={tipRef}
      >
        {tooltip?.message}
      </div>
    </div>
  );
}
