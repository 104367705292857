/* eslint-disable no-shadow */
/* eslint-disable react/jsx-no-bind */
import React, { useState, useEffect, useRef } from 'react';
import { customAlphabet } from 'nanoid';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Combobox } from '@headlessui/react';

/**
 * This custom dropdown is a React component displayed as editor of the jspreadsheet-ce's cell. It uses tailwind css and accepts values from outside of the list.
 *
 * @param {string} initialValue - The initial value of the dropdown
 * @param {function} onSave - The function that saves the selected value
 * @param {array} source - The array to be displayed in the dropdown; it accepts both array of strings or array of { id, name }
 * @returns a React component
 *
 */
export default function CustomDropdown({ initialValue, onSave, source }) {
  const { t } = useTranslation('app', { keyPrefix: 'accountDetails' });

  // const [selected, setSelected] = useState(initialValue);
  const [query, setQuery] = useState('');
  // console.log('CustomDropdown: initialValue is', initialValue, 'selected is', selected, 'query is', query);

  const options = source;
  const filteredOptions = query === '' ? options : options.filter((option) => (option.id || option).toLowerCase().includes(query.toLowerCase()));

  function handleInput(e) {
    // console.log('CustomColumn > handleInput running for', e.target.value);
    setQuery(e.target.value.id || e.target.value);
  }

  // console.log('CustomDropdown: active element', document.activeElement);

  // after initialisation immediately set focus to the input component to emulate the behaviour of the jspreadsheet
  const myIdRef = useRef(customAlphabet('qwertzuioplkjhgfdsayxcvbnmQWERTZUIOPLKJHGFDSAYXCVBNM', 3)());
  useEffect(() => {
    document.querySelector(`#${myIdRef.current}`).focus();
  }, []);

  return (
    <div className="">
      <Combobox
        // value={selected}
        defaultValue={initialValue}
        onChange={(value) => {
          if (!value) return;
          onSave(value.id || value);
        }}
      >
        <Combobox.Input id={myIdRef.current} className="w-full p-0 text-sm text-gray-700 bg-white border-0 appearance-none focus:outline-none focus:shadow-outline" onChange={handleInput} />
        <Combobox.Options static className="absolute z-[999] mt-2 overflow-auto text-xs bg-white shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
          {query !== '' && !source.map((item) => item.id || item).includes(query) && (
            <Combobox.Option value={query}>
              {({ active }) => <div key="custom" className={`cursor-default select-none relative py-1 px-2 ${active ? 'text-white bg-blue-600' : 'text-gray-900'}`}>{`${t('create')} ${query}`}</div>}
            </Combobox.Option>
          )}
          {filteredOptions.map((option) => (
            <Combobox.Option
              key={option.id || option}
              value={option}
              className={({ active }) => `cursor-default select-none relative py-1 px-2 ${active ? 'text-white bg-blue-600' : 'text-gray-900'}`}
            >
              {({ selected, active }) => (
                <span className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}>
                  {option.id ? (
                    <>
                      <span>{option.id}</span>
                      {option?.name && <span className="text-gray-300 text-xs pl-2">{option.name.length > 25 ? `${option.name.substring(0, 25)}...` : option.name}</span>}
                    </>
                  ) : (
                    option
                  )}
                </span>
              )}
            </Combobox.Option>
          ))}
        </Combobox.Options>
      </Combobox>
    </div>
  );
}
CustomDropdown.propTypes = {
  initialValue: PropTypes.string.isRequired,
  onSave: PropTypes.func.isRequired,
  source: PropTypes.arrayOf(PropTypes.string).isRequired,
};
