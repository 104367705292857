/* eslint-disable max-len */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useState, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { XMarkIcon, PlusIcon, ClipboardDocumentListIcon } from '@heroicons/react/24/solid';
import { Switch } from '@headlessui/react';
import { ChartBarIcon, ExclamationTriangleIcon, QuestionMarkCircleIcon } from '@heroicons/react/20/solid';
import ProjectTransactionList from './ProjectTransactionList';
import AccountLabel from '../../elements/AccountLabel';
import Notes from './Notes';
import ProgressRoi from './ProgressRoi';
import ProgressSlider2 from './ProgressSlider2';
import ProgressGoal from './ProgressGoal';
import AddProjectTransaction from './AddProjectTransaction';
import AddProjectQuote from './AddProjectQuote';
import ApplyProjectTemplate from './ApplyProjectTemplate';
import CollisionsDialog from './CollisionsDialog';
import FilterListbox from './FilterListbox';
import Slider from './Slider';
import { putProject, deleteProject } from '../../redux/actions/projects';
import { clearAlert, setAlert, setMessage } from '../../redux/actions/message';
import { allTransactionsProjectView, postMixedData, projectBalancesArray } from '../../redux/reducers/data';
import ToolTipNoIcon from '../../elements/ToolTipNoIcon';
import ToolTipOnlyIcon from '../../elements/ToolTipOnlyIcon';
import MiniSpinner from '../../misc/MiniSpinner';
import TourWrapper from '../../elements/TourWrapper';
import getCollisions from './getCollisions';
import { getArticleLink } from '../../blog';

// import { currencyCodes } from '../../misc/currencyCodes'; TODO PRD-815

// config params
import InputComponent from '../../elements/InputComponentAsyncCallback';
import AddProjectGoal from './AddProjectGoal';
import ProgressWidgetCombobox from './ProgressWidgetCombobox';
import ProjectAssetGrowthRateDialog from './ProjectAssetGrowthRate';
import getTour from '../../tours/getTour';

export function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

function ProjectAssetTile({ header, sum, account, assetTileHover }) {
  const baseCurrency = useSelector((state) => state.user.profile.settings.baseCurrency);

  if (!account) {
    console.error('ProjectAssetTile: account is missing', header, sum);
    return null;
  }

  return (
    <div id={`project-asset-tile-${account?.id || 'none'}`} className={`p-4 ${assetTileHover === account.id ? 'bg-brandBlue-50' : 'bg-white'} border border-gray-300 rounded-lg shadow-sm`}>
      <div className="text-gray-500 font-bold text-sm truncate uppercase">{header}</div>
      <div>
        <span className="text-gray-900 text-xl font-bold">
          {sum.toLocaleString('de', {
            maximumFractionDigits: 0,
            style: 'currency',
            currency: baseCurrency || 'EUR',
          })}
        </span>
        {/* FIXME */}
        {/* <span className="pl-2 text-green-500 text-sm font-semibold">+48.34%</span> */}
      </div>
      <AccountLabel accountName={account.name} category={account.category} />
    </div>
  );
}
ProjectAssetTile.propTypes = {
  header: PropTypes.string.isRequired,
  sum: PropTypes.number.isRequired,
  account: PropTypes.objectOf(PropTypes.any).isRequired,
  assetTileHover: PropTypes.string,
};
ProjectAssetTile.defaultProps = {
  assetTileHover: null,
};

export default function ProjectDetails({ projectId, setProjectId, setCalendarState }) {
  // on mobile layouts, when dashboard is scrolled to the bottom, this component is rendered "above" the visible screen
  // as scrolling is disabled, the user cannot scroll to the top of the page -- so we have to do it for him
  // scroll to the top of the page (somehow it got rendered in the middle)
  const myRef = useRef(null);
  useEffect(() => {
    myRef.current.scrollIntoView({ behavior: 'instant' });
  }, []);

  const { t, i18n } = useTranslation('app');
  const [transactionDialog, setTransactionDialog] = useState(null); // null is invisible, any value means visible (true or transaction object)
  const refTransactionDialog = useRef(undefined);
  refTransactionDialog.current = transactionDialog; // used in a listener below

  const [quoteDialog, setQuoteDialog] = useState(false);
  const refQuoteDialog = useRef(undefined);
  refQuoteDialog.current = quoteDialog; // used in a listener below

  const [assetGrowthDialog, setAssetGrowthDialog] = useState(false);
  const refAssetGrowthDialog = useRef(undefined);
  refAssetGrowthDialog.current = assetGrowthDialog; // used in a listener below

  const [goalDialog, setGoalDialog] = useState(false);
  const refGoalDialog = useRef(undefined);
  refGoalDialog.current = goalDialog; // used in a listener below

  const [templateDialog, setTemplateDialog] = useState(false);
  const [assetTileHover, setAssetTileHover] = useState(null); // when the user hovers over a transaction, the account used by that transaction is highlighted
  const [removeSubmitted, setRemoveSubmitted] = useState(false); // displays spinner when remove template button clicked
  const [collisionsDialog, setCollisionsDialog] = useState(false);

  const filterList = ['recurring', 'simulatedDividends', 'simulatedInterest', 'simulatedQuotes', 'simulatedPension'];
  // option source for the dropdown in FilterListbox
  // used at two places in ProjectTransactionList to filter transactions accordingly (in useEffect for recurring and in filterCallback for labels)
  const [filteredItems, setFilteredItems] = useState(['simulatedDividends', 'simulatedInterest', 'simulatedQuotes', 'simulatedPension']); // used to filter transactions by account; we do not want to expand all recurring txns in the beginning

  const dispatch = useDispatch();

  const project = useSelector((state) => state.projects.find((item) => item.id === projectId));
  const transactions = useSelector((state) => allTransactionsProjectView(state)?.filter((item) => item.projectId === project?.id)); // filter out those which belong to the current project
  const selectProjectBalancesArray = useSelector(projectBalancesArray);

  // set to true if there is any transaction in transactions which has a tag of partOfProjectTemplate of any value
  const templateDetected = transactions?.some((txn) => txn.projectId === projectId && txn.tags?.partOfProjectTemplate);

  const alert = useSelector((state) => state.message.alert);

  // handle user input from alert modal when closing the entire form
  useEffect(() => {
    if (alert?.id === 'aboutToLoseData') {
      if (alert?.response === 'ok') {
        setProjectId(null);
      }
    }
  }, [alert?.response]);

  function handleClose(e) {
    setProjectId(null);
  }

  // if there is a dialog window open, listen for Esc key and close the dialog if it is pressed
  // otherwise close the entire form
  useEffect(() => {
    function handleEsc(e) {
      if (e.key === 'Escape') {
        if (refTransactionDialog.current) setTransactionDialog(false);
        else if (refQuoteDialog.current) setQuoteDialog(false);
        else if (refAssetGrowthDialog.current) setAssetGrowthDialog(false);
        else if (refGoalDialog.current) setGoalDialog(false);
        else setProjectId(null);
      }
    }

    document.addEventListener('keydown', handleEsc, false);
    return () => {
      document.removeEventListener('keydown', handleEsc, false);
    };
  }, []); // it needs to be one object; if it is more, it creates several listeners

  // handle user input from alert modal when deleting project
  useEffect(() => {
    if (alert?.id === 'youAreAboutToDeleteProject' && alert?.caller === project.id && alert?.response) {
      if (alert?.response === 'ok') {
        const transactionsToBeDeleted = transactions.filter((item) => item.projectId === project.id).map((item) => ({ ...item, importFlag: 'delete' }));

        dispatch(deleteProject(project));
        dispatch(postMixedData({ payload: transactionsToBeDeleted })); // TODO this should happen in the action, not here

        setProjectId(null);
      }

      // presence of "alert.response" means the user clicked something, so we can clear the alert
      dispatch(clearAlert());
    }
  }, [alert?.response]);

  const baseCurrencySelect = useSelector((state) => state.user.profile.settings?.baseCurrency);
  const baseCurrency = useMemo(() => baseCurrencySelect, [baseCurrencySelect]);

  // const projectSum = useSelector((state) => projectSumSelect(state));
  const projectSum = selectProjectBalancesArray.reduce((acc, item) => (item.projectId === project.id ? acc + item.current.valueBaseCurrency : acc), 0);

  const projectAssets = selectProjectBalancesArray
    .filter((item) => item.projectId === project.id)
    .map((item) => ({
      account: item.account,
      sum: item.current.valueBaseCurrency,
      header: item.displayName,
      assetId: item.assetId,
      accountName: item.accountName,
    }));

  const assetsList = selectProjectBalancesArray
    .filter((item) => item.projectId === project.id && item.category !== 'deposits')
    .map((item) => ({
      accountId: item.accountId,
      sum: item.current.valueBaseCurrency,
      header: item.displayName,
      assetId: item.assetId,
    }));

  async function deleteTemplateTransactions() {
    setRemoveSubmitted(true);
    const transactionsToBeDeleted = transactions?.filter((item) => item.projectId === project.id && item.tags.partOfProjectTemplate).map((item) => ({ ...item, importFlag: 'delete' }));
    await dispatch(postMixedData({ payload: transactionsToBeDeleted }));
    setRemoveSubmitted(false);
  }

  function handleTemplateButton(e) {
    if (!templateDetected) {
      setTemplateDialog(true);
    } else {
      dispatch(setAlert('youAreAboutToDeleteTemplateTransactions', project.id, () => deleteTemplateTransactions()));
    }
  }

  function handleIsolatedFlagChange(e) {
    // if the project is currently isolated, we need to check if there are conflicts between isolated project quotes of this project and global quotes
    if (project.settings?.isIsolated) {
      // get a list of collisions (stockQuotes has the quotes for this isolated project; the function needs to run global quotes again with no parameter)
      const collisions = getCollisions(project.id);

      // if the list is not empty, display dialog (dialog to handle conflicts and runs the dispatch below)
      if (collisions.length > 0) setCollisionsDialog(collisions);
      // otherwise we can dispatch the change right away
      else {
        dispatch(
          putProject({
            ...project,
            settings: { ...project.settings, isIsolated: false },
          }),
        );
      }
    } else {
      dispatch(
        putProject({
          ...project,
          settings: { ...project.settings, isIsolated: true },
        }),
      );
    }
  }

  async function inputFieldCallback({ propertyName, newValue }) {
    const projectObject = { ...project, [propertyName]: newValue };
    let result;
    try {
      result = await dispatch(putProject(projectObject));
      dispatch(setMessage('dataUpdatedSuccessfully'));
    } catch (err) {
      dispatch(setMessage('dataUpdatedWithErrors'));
      throw new Error('accountCouldNotBeUpdated'); // the error is caught in the InputComponent
    }
    return result; // handled in InputComponent
  }

  // NOTE: '(project) &&' as well as 'project?.' is used to prevent errors after project has been deleted and the form isn't off yet (for a split second, but it happens)
  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <div id="projectDetailsParent" ref={myRef} className="absolute w-full h-auto min-h-full z-50 top-0 left-0 md:p-16 bg-gray-300 bg-opacity-90 space-y-6" role="dialog">
      {/* add transaction component begins here */}
      {transactionDialog && (
        <AddProjectTransaction setCalendarState={setCalendarState} transactionDialog={transactionDialog} setTransactionDialog={setTransactionDialog} projectId={projectId} stocksAssets={assetsList} />
      )}
      {quoteDialog && (
        <AddProjectQuote
          quoteDialog={quoteDialog} // this also determins if the AddTransaction component is visible;
          // ^^ values are: null, true (if user clicked New and this is empty) or a quote object (if user clicked on a quote)
          setQuoteDialog={setQuoteDialog}
          assetsList={assetsList}
          project={project}
        />
      )}
      {assetGrowthDialog && <ProjectAssetGrowthRateDialog setAssetGrowthDialog={setAssetGrowthDialog} assetsList={assetsList} project={project} />}
      {goalDialog && (
        <AddProjectGoal
          goalDialog={goalDialog} // this also determins if the AddGoal component is visible;
          // ^^ values are: null, true (if user clicked New and this is empty) or a goal object (if user clicked on a goal)
          setGoalDialog={setGoalDialog}
          project={project}
        />
      )}
      {templateDialog && (
        <ApplyProjectTemplate
          templateDialog={templateDialog} // this also determins if the AddTransaction component is visible;
          setTemplateDialog={setTemplateDialog}
          project={project}
        />
      )}
      {collisionsDialog && <CollisionsDialog collisions={collisionsDialog} setCollisionDialog={setCollisionsDialog} project={project} />}
      {/* slider */}
      <div id="projectSlider" className="lg:rounded-md lg:-mt-8 px-4 py-6 bg-white">
        <Slider theme="dark" />
      </div>
      {/* project details dialog */}
      <div
        className={`relative bg-gray-50 shadow xl:h-[85vh] sm:rounded-lg
        overflow-y-scroll scrollbar-thin scrollbar-track-rounded scrollbar-thumb-rounded scrollbar-track-gray-200 scrollbar-thumb-gray-400`}
      >
        {/* ↓ only LG and above */}
        <button type="button" id="project-details-close-button" className="hidden lg:inline-block fixed top-4 right-2 m-4 cursor-pointer">
          <XMarkIcon className="w-6 h-6 text-gray-500 hover:text-gray-600" onClick={handleClose} />
        </button>
        <a
          id="account-details-help-button"
          href={`/${i18n.language}/blog/${getArticleLink('financial-simulations-with-monestry-how-does-my-wealth-grow', i18n.language)}`}
          target="_blank"
          rel="noopener noreferrer"
          className="hidden lg:inline fixed top-12 right-2 m-4 cursor-pointer"
        >
          <ToolTipNoIcon info={t('accountDetails.help')} className="-translate-x-20">
            <QuestionMarkCircleIcon className="w-6 h-6 text-brandBlue-400 hover:text-brandBlue-500" onClick={() => {}} />
          </ToolTipNoIcon>
        </a>
        {/* ↑ only LG and above */}
        <div className="relative px-4 py-5 sm:pl-8 sm:pr-10 sm:py-8 flex flex-col gap-4 sm:gap-8">
          {/* ↓ only below LG */}
          <button type="button" id="project-details-close-button" className="lg:hidden absolute top-1 right-1 m-4 cursor-pointer">
            <XMarkIcon className="w-6 h-6 text-gray-500 hover:text-gray-600" onClick={handleClose} />
          </button>
          <a
            id="account-details-help-button"
            className="lg:hidden absolute top-1 right-9 m-4 cursor-pointer"
            href={`/${i18n.language}/blog/${getArticleLink('financial-simulations-with-monestry-how-does-my-wealth-grow', i18n.language)}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <ToolTipNoIcon info={t('accountDetails.help')} className="-translate-x-20">
              <QuestionMarkCircleIcon className="w-6 h-6 text-brandBlue-400 hover:text-brandBlue-500" onClick={() => {}} />
            </ToolTipNoIcon>
          </a>
          {/* ↑ only below LG */}
          {/* project header */}
          <section aria-label="project header">
            <div className="flex items-center justify-between pt-4 sm:pt-0">
              <div className="flex-1 min-w-0">
                {project && (
                  <InputComponent
                    callback={inputFieldCallback}
                    parentValue={project.name}
                    formatting="sm:-ml-[0.4rem] text-2xl sm:text-3xl font-bold text-gray-900"
                    attr="name"
                    stub="Project name?"
                    showButtons
                  />
                )}
              </div>
              <div id="projectSum" className="flex-shrink-0 flex md:mt-0 md:ml-4 z-10">
                {project && (
                  <span className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
                    {projectSum.toLocaleString('de', {
                      style: 'currency',
                      currency: baseCurrency || 'EUR',
                      maximumFractionDigits: 0,
                    })}
                  </span>
                )}
              </div>
            </div>
          </section>
          {/* project settings */}
          <section aria-label="project settings" className="flex gap-8 items-center">
            <div className="w-full lg:w-80 border rounded bg-white p-2 flex-shrink-0">
              {project?.settings?.progressIndicator === 'roi' && <ProgressRoi project={project} />}
              {project?.settings?.progressIndicator === 'duration' && <ProgressSlider2 projectId={project?.id} />}
              {project?.settings?.progressIndicator === 'goal' && <ProgressGoal project={project} />}
            </div>
            <div id="projectProgressIndicator" className="hidden lg:block space-y-2 text-sm text-gray-900">
              <ProgressWidgetCombobox project={project} />
            </div>
            <div id="projectIsolated" className="hidden lg:block space-y-2 text-sm text-gray-900">
              <div className="font-medium">{t('projects.visible')}</div>
              <div className="flex flex-nowrap items-center gap-2 text-xs">
                <div className="text-gray-500">{t('projects.yes')}</div>
                <Switch checked={project?.settings?.isIsolated} onChange={handleIsolatedFlagChange} className="bg-gray-300 hover:bg-gray-400 relative inline-flex h-5 w-10 items-center rounded-full">
                  <span className={`${project?.settings?.isIsolated ? 'translate-x-6' : 'translate-x-1'} inline-block h-3 w-3 transform rounded-full bg-white transition`} />
                </Switch>
                <div className="text-gray-500">{t('projects.noDraftMode')}</div>
                {project?.settings?.isIsolated ? (
                  <ToolTipNoIcon info={t('projects.isolatedWarning')}>
                    <ExclamationTriangleIcon className="w-5 h-5 text-brandYellow-500" />
                  </ToolTipNoIcon>
                ) : null}
              </div>
            </div>
            <div className="hidden lg:block space-y-1 text-sm text-gray-900">
              <button
                type="button"
                id="project-details-add-goal-button"
                className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-brandBlue-600 focus:ring-offset-2"
                onClick={(e) => {
                  setGoalDialog(true);
                }}
              >
                {t('projects.addGoal')}
              </button>
            </div>
            <div className="hidden lg:block space-y-1 text-sm text-gray-900">
              <button
                type="button"
                id="project-details-delete-transaction-button"
                className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-brandBlue-600 focus:ring-offset-2"
                onClick={(e) => {
                  dispatch(setAlert('youAreAboutToDeleteProject', project.id));
                }}
              >
                {t('projects.delete')}
              </button>
            </div>
          </section>
          <div className="grid grid-cols-1 xl:grid-cols-12 gap-8 h-full max-h-full">
            <div className="xl:col-span-7 w-full h-full max-h-full">
              {/* transaction section */}
              <section aria-label="project-transactions" className="pt-2 w-full">
                {/* narrow screen version */}
                <h2 className="lg:hidden pb-4 text-xl font-bold">{t('projects.transactions')}</h2>
                {/* large screen version */}
                <div id="projectButtonBar" className="hidden lg:flex gap-4 pb-4 items-stretch">
                  <h2 className="text-xl font-bold">{t('projects.transactions')}</h2>
                  <button
                    type="button"
                    id="project-details-add-transaction-button"
                    onClick={(e) => {
                      setTransactionDialog(true);
                    }}
                    className="inline-flex items-center rounded-md border border-transparent bg-brandBlue-500 -mt-0.5 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-brandBlue-600 focus:outline-none focus:ring-2 focus:ring-brandBlue-400 focus:ring-offset-2"
                  >
                    <PlusIcon className="-ml-0.5 mr-2 h-4 w-4 text-white" aria-hidden="true" />
                    {t('projects.add')}
                  </button>
                  <ToolTipNoIcon info={assetsList.length === 0 ? t('projects.addQuoteButtonDisabled') : t('projects.addQuoteButtonEnabled')}>
                    <button
                      type="button"
                      disabled={assetsList.length === 0}
                      id="project-details-add-transaction-button"
                      onClick={(e) => {
                        setQuoteDialog(true);
                      }}
                      className="inline-flex items-center rounded-md border border-gray-300 bg-white -mt-0.5 px-3 py-2 text-sm font-medium leading-4 text-gray-500 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-brandBlue-400 focus:ring-offset-2 cursor-pointer"
                    >
                      <PlusIcon className="-ml-0.5 mr-2 h-4 w-4 text-gray-600" aria-hidden="true" />
                      {t('projects.addQuote')}
                    </button>
                  </ToolTipNoIcon>
                  <ToolTipNoIcon info={t('projects.stocksGrowthRates')}>
                    <button
                      type="button"
                      disabled={assetsList.length === 0}
                      id="project-details-add-transaction-button"
                      onClick={(e) => {
                        setAssetGrowthDialog(true);
                      }}
                      className="inline-flex items-center rounded-md border border-gray-300 bg-white -mt-0.5 px-3 py-2 text-sm font-medium leading-4 text-gray-500 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-brandBlue-400 focus:ring-offset-2 cursor-pointer"
                    >
                      <ChartBarIcon className="-ml-0.5 mr-2 h-4 w-4 text-gray-400" aria-hidden="true" />
                      {t('projects.growthRates')}
                    </button>
                  </ToolTipNoIcon>
                  <button
                    type="button"
                    id="project-details-apply-template-button"
                    onClick={handleTemplateButton}
                    className="inline-flex items-center rounded-md border border-gray-300 bg-white -mt-0.5 px-3 py-2 text-sm font-medium leading-4 text-gray-500 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-brandBlue-400 focus:ring-offset-2 cursor-pointer"
                  >
                    <ClipboardDocumentListIcon className="-ml-0.5 mr-2 h-4 w-4 text-gray-400" aria-hidden="true" />
                    {templateDetected ? t('projects.removeTemplate') : t('projects.applyTemplate')}
                    {removeSubmitted && <MiniSpinner className="ml-2 h-5 w-5 text-gray-400 animate-spin" />}
                  </button>
                  <FilterListbox filteredItems={filteredItems} setFilteredItems={setFilteredItems} filterList={filterList} />
                </div>
                {project && (
                  <ProjectTransactionList
                    project={project}
                    setTransactionDialog={setTransactionDialog}
                    setAssetTileHover={setAssetTileHover}
                    setGoalDialog={setGoalDialog}
                    setQuoteDialog={setQuoteDialog}
                    projectAssets={assetsList}
                    filteredItems={filteredItems}
                  />
                )}
              </section>
              {/* notes section */}
              <h2 className="text-xl font-bold mt-8 mb-4">Notes</h2>
              {project && <Notes project={project} />}
            </div>
            {/* assets section */}
            <section aria-label="project-assets" id="project-assets" className="xl:col-span-5">
              <ToolTipNoIcon info={t('projects.assets.tooltip')} classNameOwn="mb-4">
                <h2 className="text-xl font-bold mr-1">{t('projects.assets.label')}</h2>
                <ToolTipOnlyIcon />
              </ToolTipNoIcon>
              <div className="grid grid-cols-2 gap-2 lg:grid-cols-3 lg:gap-4 sticky">
                {projectAssets
                  && projectAssets.map((asset) => (
                    <ProjectAssetTile key={asset.header + asset.accountId} project={project} header={asset.header} sum={asset.sum} account={asset.account} assetTileHover={assetTileHover} />
                  ))}
              </div>
            </section>
          </div>
        </div>
      </div>
      <TourWrapper localTours={getTour('projectDetails')} />
    </div>
  );
}
ProjectDetails.propTypes = {
  projectId: PropTypes.string.isRequired,
  setProjectId: PropTypes.func.isRequired,
  setCalendarState: PropTypes.func.isRequired,
};
