/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useForm, useFieldArray } from 'react-hook-form';
import { XMarkIcon } from '@heroicons/react/24/solid';
import dayjs from 'dayjs';
import MiniSpinner from '../../misc/MiniSpinner';
import { putProject } from '../../redux/actions/projects';
import { setMessage } from '../../redux/actions/message';
import GrowthRateArray from '../../elements/GrowthRatesArray';
import { putStocksSettings } from '../../redux/reducers/data';
import AssetGrowthRate from '../../elements/AssetGrowthRate';

const debugLevel = process.env.REACT_APP_MDEBUG || 0;

const utc = require('dayjs/plugin/utc');

dayjs.extend(utc);

// this is used in ProjectDetails to set project-specific growth rates for assets
// this growth rate would then override the default growth rate for the asset (e.g. from the stocks settings)

// quoteDialog can have a quote object, true or null
// can be called to add a new quote (with setQuoteDialog === true)
// or to edit a quote (with setQupteDialog containing the quote object)
export default function ProjectAssetGrowthRateDialog({ setAssetGrowthDialog, project, assetsList }) {
  const { t } = useTranslation('app', { keyPrefix: 'projects' });
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false); // save button was clicked
  const isIsolated = project.settings?.isIsolated || false;
  const initialValue = isIsolated ? project.settings || [] : useSelector((state) => state.data.stocks.settings) || [];

  // initialise the form with values from an existing object or default values
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    mode: 'onBlur',
    defaultValues: initialValue,
  }); // intialised after useEffect fetches data from Amplify store
  const { fields, append, remove } = useFieldArray({
    name: 'growthRates',
    control,
  });

  // submits the form object to backend
  async function onSubmit({ growthRates }) {
    // if this is an isolated project, save growthRates to project
    // otherwise send them to the store (to be saved in the stocks settings)
    try {
      setLoading(true);
      if (isIsolated) {
        await dispatch(putProject({ ...project, settings: { ...project.settings, growthRates } }));
      } else {
        // TODO stock settings have more settings, i.e. advanced vs normal mode, if normal, then overall setting, if advanced, then per asset
        // TODO besides, both here and in the Settings > Simulation it should be possible to set growth rates for all the asset classes, not only stocks - in preparation for the future
        await dispatch(putStocksSettings({ data: { growthRates }, category: 'stocks' }));
      }

      dispatch(setMessage('dataUpdatedSuccessfully'));
      setLoading(false);
      setAssetGrowthDialog(false);
    } catch (e) {
      console.error(e);
      dispatch(setMessage('dataUpdateError'));
      setLoading(false);
    }
  }

  const assetList = assetsList;

  return (
    <div id="project-details-growth-rates" data-testid="project-details-growth-rates" className="bg-opacity-90 bg-gray-300 absolute top-0 left-0 w-full h-full z-[100] p-4">
      <div className="flex flex-col items-center justify-center h-full">
        <form autoComplete="off" onSubmit={handleSubmit(onSubmit)} className="relative bg-white rounded-md shadow-lg p-8 max-w-6xl">
          <button
            type="button"
            id="project-details-growth-rates-close"
            onClick={(e) => {
              setAssetGrowthDialog(false);
            }}
            className="absolute top-4 right-4 cursor-pointer"
          >
            <XMarkIcon className="w-6 h-6 text-gray-500 hover:text-gray-600" />
          </button>
          <div className="mb-8">
            <h2 className="inline text-2xl font-bold text-gray-900">{t('growthRates')}</h2>
            <h3 className="text-lg font-normal text-gray-900">{isIsolated ? t('growthRatesDescription') : t('growthRatesNonIsolated')}</h3>
          </div>
          <div className="sm:grid sm:grid-cols-3 gap-12">
            <AssetGrowthRate
              register={register}
              fields={fields}
              append={append}
              remove={remove}
              control={control}
              errors={errors}
              watch={watch}
              fieldsGrowthRates={fields}
              appendGrowthRates={append}
              removeGrowthRates={remove}
              {...(isIsolated && assetList)}
            />
          </div>
          {/* buttons */}
          <div className="flex justify-start items-center gap-4 pt-6">
            <button
              id="project-add-quote-save"
              data-testid="project-add-quote-save"
              type="submit"
              // eslint-disable-next-line max-len
              className="inline-flex items-center rounded-md border border-transparent bg-brandBlue-500 hover:bg-brandBlue-600 px-4 py-2 text-sm font-medium leading-4 text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-brandBlue-400 focus:ring-offset-2"
            >
              {loading === false ? (
                t('save')
              ) : (
                <div id="project-add-quote-spinner" className="px-2">
                  <MiniSpinner className="w-4 h-4 text-white animate-spin" />
                </div>
              )}
            </button>
            <button
              id="project-add-quote-cancel"
              type="button"
              onClick={(e) => {
                setAssetGrowthDialog(false);
              }}
              // eslint-disable-next-line max-len
              className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-brandBlue-400 focus:ring-offset-2"
            >
              {t('cancel')}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
ProjectAssetGrowthRateDialog.propTypes = {
  setAssetGrowthDialog: PropTypes.func.isRequired,
  project: PropTypes.objectOf(PropTypes.any).isRequired,
  assetsList: PropTypes.arrayOf(PropTypes.any),
};
ProjectAssetGrowthRateDialog.defaultProps = {
  assetsList: [],
};
