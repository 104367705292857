/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import PropTypes from 'prop-types';

function SvgSymbolWhite(props) {
  return (
    <svg
      {...props}
      version="1.1"
      id="svg833"
      xmlSpace="preserve"
      viewBox="0 0 144.10213 144.10133"
    >
      <defs
        id="defs837"
      >
        <clipPath
          clipPathUnits="userSpaceOnUse"
          id="clipPath849"
        >
          <path
            d="M 0,257.598 H 751.181 V 0 H 0 Z"
            id="path847"
          />
        </clipPath>
        <clipPath
          clipPathUnits="userSpaceOnUse"
          id="clipPath963"
        >
          <path
            d="M 0,257.598 H 751.181 V 0 H 0 Z"
            id="path961"
          />
        </clipPath>
        <clipPath
          clipPathUnits="userSpaceOnUse"
          id="clipPath1077"
        >
          <path
            d="M 0,257.598 H 751.181 V 0 H 0 Z"
            id="path1075"
          />
        </clipPath>
        <clipPath
          clipPathUnits="userSpaceOnUse"
          id="clipPath1191"
        >
          <path
            d="M 0,257.598 H 751.181 V 0 H 0 Z"
            id="path1189"
          />
        </clipPath>
        <clipPath
          clipPathUnits="userSpaceOnUse"
          id="clipPath1305"
        >
          <path
            d="M 0,257.598 H 751.181 V 0 H 0 Z"
            id="path1303"
          />
        </clipPath>
      </defs>
      <g
        id="g841"
        transform="matrix(1.3333333,0,0,-1.3333333,-80.033464,268.45119)"
      >
        <g
          id="g851"
          transform="translate(150.6871,177.5554)"
          style={{ fill: '#ffffff' }}
        >
          <path
            d="M 0,0 V 16.283 H -83.162 V -4.484 h 44.245 c 6.94,4.445 15.185,7.034 24.039,7.034 C -9.66,2.55 -4.654,1.646 0,0"
            style={{
              fill: '#ffffff', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none',
            }}
            id="path853"
          />
        </g>
        <g
          id="g855"
          transform="translate(107.2118,169.7354)"
          style={{ fill: '#ffffff' }}
        >
          <path
            d="m 0,0 h -39.686 v -20.767 h 25.73 C -11.356,-12.565 -6.451,-5.39 0,0"
            style={{
              fill: '#ffffff', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none',
            }}
            id="path857"
          />
        </g>
        <g
          id="g859"
          transform="translate(92.349398,145.6326)"
          style={{ fill: '#ffffff' }}
        >
          <path
            d="M 0,0 H -24.824 V -20.767 H 0.102 c -0.829,3.4 -1.279,6.949 -1.279,10.604 0,3.498 0.416,6.897 1.177,10.163"
            style={{
              fill: '#ffffff', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none',
            }}
            id="path861"
          />
        </g>
        <g
          id="g863"
          transform="translate(93.405598,121.5294)"
          style={{ fill: '#ffffff' }}
        >
          <path
            d="M 0,0 H -25.88 V -20.767 H 14.348 C 7.749,-15.427 2.709,-8.244 0,0"
            style={{
              fill: '#ffffff', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none',
            }}
            id="path865"
          />
        </g>
        <g
          id="g953"
          transform="translate(160.6017,135.4698)"
          style={{ fill: '#ffffff' }}
        >
          <path
            d="m 0,0 c 0,-13.693 -11.101,-24.793 -24.793,-24.793 -13.693,0 -24.794,11.1 -24.794,24.793 0,13.693 11.101,24.793 24.794,24.793 C -11.101,24.793 0,13.693 0,0"
            style={{
              fill: '#ffffff', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none',
            }}
            id="path955"
          />
        </g>
        <g
          id="g969"
          transform="translate(107.2118,169.7354)"
          style={{ fill: '#ffffff' }}
        >
          <path
            d="m 0,0 h -39.686 v -20.767 h 25.73 C -11.356,-12.565 -6.451,-5.39 0,0"
            style={{
              fill: '#ffffff', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none',
            }}
            id="path971"
          />
        </g>
        <g
          id="g973"
          transform="translate(92.349398,145.6326)"
          style={{ fill: '#ffffff' }}
        >
          <path
            d="M 0,0 H -24.824 V -20.767 H 0.102 c -0.829,3.4 -1.279,6.949 -1.279,10.604 0,3.498 0.416,6.897 1.177,10.163"
            style={{
              fill: '#ffffff', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none',
            }}
            id="path975"
          />
        </g>
        <g
          id="g977"
          transform="translate(93.405598,121.5294)"
          style={{ fill: '#ffffff' }}
        >
          <path
            d="M 0,0 H -25.88 V -20.767 H 14.348 C 7.749,-15.427 2.709,-8.244 0,0"
            style={{
              fill: '#ffffff', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none',
            }}
            id="path979"
          />
        </g>
      </g>

    </svg>

  );
}

export default SvgSymbolWhite;
