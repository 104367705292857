/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/20/solid';
import { globalQuotesView, depositTransactions, assetBalances, pensionTransactions, assetBalancesArray } from '../../redux/reducers/data';
import AccountSum from '../../elements/AccountSum';

export function TransactionPreviewDeposits({ account }) {
  TransactionPreviewDeposits.propTypes = {
    account: PropTypes.objectOf(PropTypes.any),
  };
  TransactionPreviewDeposits.defaultProps = {
    account: null,
  };
  const { t } = useTranslation('app', { keyPrefix: 'accountDetails.columns' });

  // if (!account) return true;
  const transactionsPerAccount = useSelector((state) => depositTransactions(state).filter((x) => x.accountId === account?.id));
  const baseCurrency = useSelector((state) => state.user.profile.settings.baseCurrency) || 'EUR';

  const transactions = (transactionsPerAccount || [])
    .sort((a, b) => b.date - a.date)
    .slice(0, 6)
    .map((item) => ({
      date: item.date,
      accountCurrencyAmount: item.quantity.toLocaleString('de', { style: 'currency', currency: account?.currency || 'EUR', maximumFractionDigits: 2 }),
      otherParty: item.otherParty,
      currency: item.currency,
    }));

  return (
    <div id="transaction-preview" className="py-4 align-middle">
      <table className="divide-y divide-gray-300 table-fixed w-full max-w-full">
        <thead className="text-xs font-semibold text-gray-900 uppercase bg-gray-50">
          <tr>
            <th scope="col" className="whitespace-nowrap w-1/4 py-3.5 pl-4 pr-3 text-left sm:pl-6">
              {t('date')}
            </th>
            <th scope="col" className="whitespace-nowrap w-1/2 px-2 py-3.5 text-left">
              {t('otherParty')}
            </th>
            <th scope="col" className="whitespace-nowrap w-1/4 px-2 py-3.5 pr-6 text-right">
              {t('amount.label')}
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 bg-white text-sm text-gray-700">
          {transactions.map((transaction) => (
            <tr key={transaction.id || Math.random() * 10000}>
              <td className="whitespace-nowrap w-1/4  py-3 pl-4 pr-3 sm:pl-6">
                {new Date(transaction.date).toLocaleDateString('de', {
                  year: '2-digit',
                  month: '2-digit',
                  day: '2-digit',
                  timezone: 'UTC',
                })}
              </td>
              <td className="truncate px-2 py-3 w-1/2">{transaction.otherParty}</td>
              <td className="whitespace-nowrap w-1/4 pl-2 py-3 text-right pr-6">{transaction.accountCurrencyAmount}</td>
            </tr>
          ))}
        </tbody>
      </table>
      {/* display balance in fx currency only for accounts which have accountCurrency not equal to base currency */}

      {account?.currency && account?.currency !== baseCurrency && (
        <p className="uppercase font-semibold mt-8 text-sm text-gray-700 w-full">
          <span>{`Current balance in ${account?.currency || null} `}</span>
          <span>
            <AccountSum account={account} category="deposits" currencyCodeFormat="" showInBaseCurrency={false} />
          </span>
        </p>
      )}
    </div>
  );
}

export function TransactionPreviewStocks({ account }) {
  TransactionPreviewStocks.propTypes = {
    account: PropTypes.objectOf(PropTypes.any),
  };
  TransactionPreviewStocks.defaultProps = {
    account: null,
  };

  const { t } = useTranslation('app', { keyPrefix: 'accountDetails.columns' });

  const selectAssetBalances = useSelector(assetBalancesArray);
  const positions = (selectAssetBalances || []).filter((ab) => ab.accountId === account.id).sort((a, b) => b.current.valueBaseCurrency - a.current.valueBaseCurrency);
  // console.log('DEBUG tab', positions);

  const baseCurrency = useSelector((state) => state.user.profile.settings.baseCurrency) || 'EUR';

  return (
    <div id="transaction-preview" className="inline-block min-w-full py-4 align-middle">
      <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5">
        <table className="min-w-full divide-y divide-gray-300">
          <thead className="bg-gray-50 text-xs font-semibold text-gray-900 uppercase">
            <tr>
              <th scope="col" className="whitespace-nowrap py-3.5 pl-4 pr-3 sm:pl-6 text-left">
                {t('assetId.label')}
              </th>
              <th scope="col" className="whitespace-nowrap pl-2 py-3.5 text-right">
                {t('priceShort.label')}
              </th>
              <th scope="col" className="whitespace-nowrap px-2 py-3.5 pr-6 text-right">
                {t('value')}
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white text-sm text-gray-700">
            {positions.map((position) => (
              <tr key={position.assetId}>
                <td className="whitespace-nowrap py-3 pl-4 pr-3 sm:pl-6">{position.displayName.length > 25 ? `${position.displayName.substring(0, 25)}...` : position.displayName}</td>
                <td className="whitespace-nowrap pl-2 py-3 text-right">
                  {position.current.quoteBaseCurrency.toLocaleString('de', { style: 'currency', currency: baseCurrency, maximumFractionDigits: 2 })}
                </td>
                <td className="whitespace-nowrap pl-2 py-3 text-right pr-6">
                  {position.current.valueBaseCurrency.toLocaleString('de', { style: 'currency', currency: baseCurrency, maximumFractionDigits: 0 })}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export function TransactionPreviewRealEstate({ account }) {
  TransactionPreviewRealEstate.propTypes = {
    account: PropTypes.objectOf(PropTypes.any),
  };
  TransactionPreviewRealEstate.defaultProps = {
    account: null,
  };

  const { t } = useTranslation('app', { keyPrefix: 'accountDetails.columns' });

  const baseCurrency = useSelector((state) => state.user.profile.settings.baseCurrency) || 'EUR';
  const quotes = useSelector((state) => globalQuotesView(state)[account.id]);

  // handle no valuations case
  if (Object.keys(quotes || []).length === 0) {
    return <div className="inline-block min-w-full py-4 align-middle">No data yet.</div>;
  }

  const mostRecentQuotes = (Object.entries(quotes) || [])
    .map(([date, quoteObject]) => ({
      date,
      ...quoteObject,
      quote: (quoteObject.quoteBaseCurrency || 0).toLocaleString('de', { style: 'currency', currency: baseCurrency, maximumFractionDigits: 0 }),
    }))
    .filter((item) => item.date < new Date().valueOf()) // HACK: this should be done centrally, but it seems quite heavy
    .sort((a, b) => a.date - b.date) // sort to make the reduce below easy
    .reduce((prev, curr) => (curr.quoteDate > (prev[prev.length - 1] || { quoteDate: 0 }).quoteDate ? [...prev, curr] : prev), []) // keep the first quote (it's sorted!) for each new quoteDate
    .sort((a, b) => b.date - a.date)
    .slice(0, 6);

  return (
    <div id="transaction-preview" className="inline-block min-w-full py-4 align-middle">
      <div className="overflow-hidden ring-1 ring-black ring-opacity-5">
        <table className="min-w-full divide-y divide-gray-300">
          <thead className="text-xs font-semibold text-gray-900 uppercase bg-gray-50">
            <tr>
              <th scope="col" className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left sm:pl-6">
                {t('date')}
              </th>
              <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-left">
                {t('value')}
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white text-sm text-gray-700">
            {mostRecentQuotes.map((valuation) => (
              <tr key={valuation.id || Math.random() * 10000}>
                <td className="whitespace-nowrap py-3 pl-4 pr-3 sm:pl-6">
                  {new Date(Number(valuation.date)).toLocaleDateString('de', {
                    year: '2-digit',
                    month: '2-digit',
                    day: '2-digit',
                    timezone: 'UTC',
                  })}
                </td>
                <td className="whitespace-nowrap px-2 py-2">{valuation.quote}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export function TransactionPreviewLoans({ account }) {
  TransactionPreviewLoans.propTypes = {
    account: PropTypes.objectOf(PropTypes.any),
  };
  TransactionPreviewLoans.defaultProps = {
    account: null,
  };

  const { t } = useTranslation('app', { keyPrefix: 'accountDetails.columns' });

  const selectTransactions = useSelector(assetBalances).loans[account.id]?.[account.currency]?.transactions;

  const transactions = (selectTransactions || [])
    .sort((a, b) => b.date - a.date)
    .slice(0, 6)
    .map((item) => ({
      date: item.date,
      name: item.description,
      quantity: (item.quantity * item.upac || 0).toLocaleString('de', { style: 'currency', currency: item.accountCurrency, maximumFractionDigits: 2 }),
      quantityInterest: (item.quantityInterest * item.upac || 0).toLocaleString('de', { style: 'currency', currency: item.accountCurrency, maximumFractionDigits: 2 }),
    }));

  return (
    <div id="transaction-preview" className="inline-block min-w-full py-4 align-middle">
      <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5">
        <table className="min-w-full divide-y divide-gray-300">
          <thead className="bg-gray-50 text-xs font-semibold text-gray-900 uppercase">
            <tr>
              <th scope="col" className="whitespace-nowrap py-3.5 pl-4 pr-3 sm:pl-6 text-left">
                {t('date')}
              </th>
              <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-left">
                {t('description')}
              </th>
              <th scope="col" className="whitespace-nowrap pl-2 py-3.5 text-right">
                {t('principal.label')}
              </th>
              <th scope="col" className="whitespace-nowrap px-2 py-3.5 pr-6 text-right">
                {t('interest.label')}
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white text-sm text-gray-700">
            {transactions.map((transaction) => (
              <tr key={transaction.id || Math.random() * 10000}>
                <td className="whitespace-nowrap py-3 pl-4 pr-3 sm:pl-6">
                  {new Date(transaction.date).toLocaleDateString('de', {
                    year: '2-digit',
                    month: '2-digit',
                    day: '2-digit',
                    timezone: 'UTC',
                  })}
                </td>
                <td className="whitespace-nowrap px-2 py-3 ">{transaction.name.length > 25 ? `${transaction.name.substring(0, 25)}...` : transaction.name}</td>
                <td className="whitespace-nowrap pl-2 py-3 text-right">{transaction.quantity}</td>
                <td className="whitespace-nowrap pl-2 py-3 text-right pr-6">{transaction.quantityInterest}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export function TransactionPreviewPension({ account }) {
  TransactionPreviewPension.propTypes = {
    account: PropTypes.objectOf(PropTypes.any),
  };
  TransactionPreviewPension.defaultProps = {
    account: null,
  };
  const { t } = useTranslation('app', { keyPrefix: 'accountDetails.columns' });

  // I want to see recent payouts. If there are no payouts, show me contributions. If there are no contributions, show payout estimates from provider.

  // if (!account) return true;
  const selectTransactions = useSelector((state) => pensionTransactions(state));
  const thisAccountTransactions = selectTransactions.filter((x) => x.accountId === account?.id);
  const baseCurrency = useSelector((state) => state.user.profile.settings.baseCurrency) || 'EUR';
  const contributions = thisAccountTransactions.filter((tx) => tx.label === 'pension-contribution');
  const payouts = thisAccountTransactions.filter((tx) => tx.label === 'pension-payout');
  const selectQuotes = useSelector(globalQuotesView);
  const currentSpecialQuotes = selectQuotes[account.id]?.current.specialQuotes || [];

  const displayMode = (() => {
    if (payouts.length > 0) return 'payouts';
    if (contributions.length > 0) return 'contributions';
    return 'quotes';
  })();

  if (['payouts', 'contributions'].includes(displayMode)) {
    const transactions = (displayMode === 'payouts' ? payouts : contributions)
      .sort((a, b) => b.date - a.date)
      .slice(0, 6)
      .map((item) => ({
        date: item.date,
        description: item.description,
        quantity: item.quantity.toLocaleString('de', { style: 'currency', currency: account?.currency || 'EUR', maximumFractionDigits: 2 }),
        currency: item.currency,
      }));

    return (
      <div id="transaction-preview" className="inline-block min-w-full py-4 align-middle">
        <div className="overflow-hidden ring-1 ring-black ring-opacity-5 ">
          <table className="min-w-full divide-y divide-gray-300">
            <thead className="text-xs font-semibold text-gray-900 uppercase bg-gray-50">
              <tr>
                <th scope="col" className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left sm:pl-6">
                  {t('date')}
                </th>
                <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-left">
                  {t('description')}
                </th>
                <th scope="col" className="whitespace-nowrap px-2 py-3.5 pr-6 text-right">
                  {t('amount.label')}
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white text-sm text-gray-700">
              {transactions.map((transaction) => (
                <tr key={transaction.id || Math.random() * 10000}>
                  <td className="whitespace-nowrap py-3 pl-4 pr-3 sm:pl-6">
                    {new Date(transaction.date).toLocaleDateString('de', {
                      year: '2-digit',
                      month: '2-digit',
                      day: '2-digit',
                      timezone: 'UTC',
                    })}
                  </td>
                  <td className="whitespace-nowrap px-2 py-3">{transaction.description}</td>
                  <td className="whitespace-nowrap pl-2 py-3 text-right pr-6">{transaction.quantity}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {/* display balance in fx currency only for accounts which have accountCurrency not equal to base currency */}
        {account?.currency && account?.currency !== baseCurrency && (
          <p className="uppercase font-semibold my-8 text-sm text-gray-700 w-full">
            <span>{`Current balance in ${account?.currency || null} `}</span>
            <span>
              <AccountSum account={account} category="deposits" currencyCodeFormat="" showInBaseCurrency={false} />
            </span>
          </p>
        )}
      </div>
    );
  }

  // handle quotes mode

  return (
    <div id="transaction-preview" className="inline-block min-w-full py-4 px-2 align-middle">
      <div className="overflow-hidden ring-1 ring-black ring-opacity-5 ">
        <div className="p-2 mb-2">
          <h3 className="font-semibold text-sm mb-1 sm:mb-2">{t('payoutOnlyContributionsToDate.label')}</h3>
          <p className="font-base text-gray-700 text-sm sm:text-base">
            {currentSpecialQuotes
              .find((sq) => sq.type === 'payoutOnlyContributionsToDate')
              ?.quoteBaseCurrency.toLocaleString('de', { style: 'currency', currency: baseCurrency, maximumFractionDigits: 2 })}
          </p>
        </div>
        <div className="p-2 mb-2">
          <h3 className="font-semibold text-sm mb-1 sm:mb-2">{t('payoutOnlyContributionsToDateIndexed.label')}</h3>
          <p className="font-base text-gray-700 text-sm sm:text-base">
            {currentSpecialQuotes
              .find((sq) => sq.type === 'payoutOnlyContributionsToDateIndexed')
              ?.quoteBaseCurrency.toLocaleString('de', { style: 'currency', currency: baseCurrency, maximumFractionDigits: 2 })}
          </p>
        </div>
        <div className="p-2 mb-2">
          <h3 className="font-semibold text-sm mb-1 sm:mb-2">{t('payoutAllPlannedContributions.label')}</h3>
          <p className="font-base text-gray-700 text-sm sm:text-base">
            {currentSpecialQuotes
              .find((sq) => sq.type === 'payoutAllPlannedContributions')
              ?.quoteBaseCurrency.toLocaleString('de', { style: 'currency', currency: baseCurrency, maximumFractionDigits: 2 })}
          </p>
        </div>
        <div className="p-2 mb-2">
          <h3 className="font-semibold text-sm mb-1 sm:mb-2">{t('payoutAllPlannedContributionsIndexed.label')}</h3>
          <p className="font-base text-gray-700 text-sm sm:text-base">
            {currentSpecialQuotes
              .find((sq) => sq.type === 'payoutAllPlannedContributionsIndexed')
              ?.quoteBaseCurrency.toLocaleString('de', { style: 'currency', currency: baseCurrency, maximumFractionDigits: 2 })}
          </p>
        </div>
      </div>
    </div>
  );
}

export default function TransactionPreview({ tiletip, setTiletip }) {
  let Component;
  const account = tiletip?.account;

  const { t } = useTranslation('app');

  let header;
  switch (account?.category || 'deposits') {
    case 'deposits':
      Component = <TransactionPreviewDeposits account={account} />;
      header = t('charts.mostRecentTransactions');
      break;
    case 'stocks':
    case 'metals':
    case 'crypto':
      Component = <TransactionPreviewStocks account={account} />;
      header = t('charts.currentPositions');
      break;
    case 'realEstate':
    case 'objectsOfValue':
    case 'unlistedShares':
      Component = <TransactionPreviewRealEstate account={account} />;
      header = t('charts.mostRecentQuotes');
      break;
    case 'loans':
      Component = <TransactionPreviewLoans account={account} />;
      header = t('charts.mostRecentTransactions');
      break;
    case 'pension':
      Component = <TransactionPreviewPension account={account} />;
      header = t('charts.currentInformation');
      break;
    default:
      Component = true;
      header = t('charts.mostRecentTransactions');
  }

  return (
    <Transition
      className="fixed right-8 top-4 w-1/4 py-4 px-6 text-sm z-10 bg-white rounded-lg shadow-md border border-gray-100"
      unmount
      show={!!tiletip}
      enter="transition-opacity duration-200"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-200"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <h2 className="text-base font-semibold text-gray-900" id="chart-top-title">
        {header}
      </h2>
      <button type="button" onClick={() => setTiletip(null)} className="absolute top-2 right-2 text-gray-400 hover:text-gray-500">
        <XMarkIcon className="h-5 w-5" />
      </button>
      <div className="py-2 align-middle bg-white min-h-full">{Component}</div>
    </Transition>
  );
}
TransactionPreview.propTypes = {
  tiletip: PropTypes.objectOf(PropTypes.any),
  setTiletip: PropTypes.func.isRequired,
};
TransactionPreview.defaultProps = {
  tiletip: null,
};
