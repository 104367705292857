/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';
import { Switch } from '@headlessui/react';
import GrowthRatesArray from './GrowthRatesArray';

export default function AssetGrowthRate({ register, control, errors, watch, fieldsGrowthRates, appendGrowthRates, removeGrowthRates, namePrefix, header }) {
  const { t } = useTranslation('app', { keyPrefix: 'settings.simulations' });

  return (
    <div className="sm:grid sm:grid-cols-4 sm:gap-4">
      <div>
        {header && <p className="text-base font-bold text-gray-500 mb-2">{header}</p>}
        <div className="sm:max-w-sm">
          {/* GROWTH RATE: SWITCH */}
          <Controller
            control={control}
            name={`${namePrefix}growthRateAdvancedMode`}
            render={({ field }) => (
              <Switch.Group as="div" className="flex items-center justify-start w-full">
                <Switch.Label as="span" className="mr-3">
                  <span className="text-sm font-medium text-gray-500">{t('simple')}</span>
                </Switch.Label>
                <Switch
                  {...field}
                  checked={field.value}
                  onChange={field.onChange}
                  className={`${field.value ? 'bg-brandBlue-500' : 'bg-gray-200'} relative inline-flex flex-shrink-0 h-6 w-11 border-2
          border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
                >
                  <span
                    aria-hidden="true"
                    className={`${field.value ? 'translate-x-5' : 'translate-x-0'}
              pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200`}
                  />
                </Switch>
                <Switch.Label as="span" className="ml-3">
                  <span className="text-sm font-medium text-gray-500">{t('advanced')}</span>
                </Switch.Label>
              </Switch.Group>
            )}
          />
          {/* GROWTH RATE: SIMPLE MODE */}
          <div className="mt-4">
            <label htmlFor="growthRate" className="block text-sm font-medium text-gray-500 mb-1">
              {t('growthRate')}
            </label>
            <input
              type="number"
              step="0.01"
              name={`${namePrefix}growthRate`}
              id={`${namePrefix}growthRate`}
              {...register(`${namePrefix}growthRate`, { valueAsNumber: true })}
              className="mt-0.5 flex-1 focus:ring-brandBlue-400 focus:border-brandBlue-400 block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
            />
            {errors.growthRate && (
              <div className="pt-2 sm:text-sm text-brandRed-500 max-w-fit">
                <span>{namePrefix ? errors[namePrefix.split('.')[0]].growthRate.message : errors.growthRate.message}</span>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* GROWTH RATE: ADVANCED MODE COMPONENT */}
      {watch(`${namePrefix}growthRateAdvancedMode`) && (
        <div className="sm:col-span-3 sm:mt-1">
          <GrowthRatesArray fields={fieldsGrowthRates} append={appendGrowthRates} remove={removeGrowthRates} register={register} control={control} namePrefix={namePrefix} />
        </div>
      )}
    </div>
  );
}
AssetGrowthRate.propTypes = {
  register: PropTypes.func.isRequired,
  control: PropTypes.shape({
    field: PropTypes.shape({
      value: PropTypes.string,
      onChange: PropTypes.func,
      onBlur: PropTypes.func,
    }),
  }).isRequired,
  errors: PropTypes.objectOf(PropTypes.any).isRequired,
  watch: PropTypes.func.isRequired,
  assetList: PropTypes.array,
  fieldsGrowthRates: PropTypes.arrayOf(
    PropTypes.shape({
      symbol: PropTypes.string,
      value: PropTypes.number,
    }),
  ).isRequired,
  appendGrowthRates: PropTypes.func.isRequired,
  removeGrowthRates: PropTypes.func.isRequired,
  namePrefix: PropTypes.string,
  header: PropTypes.string,
};
AssetGrowthRate.defaultProps = {
  assetList: [],
  namePrefix: '',
  header: '',
};
