import React from 'react';
import PropTypes from 'prop-types';

export default function ErrorFallback({ error }) {
  return (
    <div className="flex min-h-full flex-col bg-white pt-16 pb-12">
      <main className="mx-auto flex w-full max-w-7xl flex-grow flex-col justify-center px-4 sm:px-6 lg:px-8">
        <div className="flex flex-shrink-0 justify-center">
          <a href="/" className="inline-flex">
            <span className="sr-only">Monestry</span>
            <img
              className="h-12 w-auto"
              src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
              alt=""
            />
          </a>
        </div>
        <div className="py-16">
          <div className="text-center">
            <p className="text-base font-semibold text-brandBlue-500">ERROR</p>
            <h1 className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">Something went wrong.</h1>
            <p className="mt-2 text-base text-gray-500">We are sorry - the application detected an error. Refresh the page to start over.</p>
            <div className="mt-6">
              <a href="/" className="text-base font-medium text-brandBlue-500 hover:text-brandBlue-400">
                Go back home
                <span aria-hidden="true"> &rarr;</span>
              </a>
            </div>
          </div>
        </div>
        <h3 className="p-2 mx-auto max-w-3xl text-gray-500">Please include the following information should you decide to contact our support.</h3>
        <div className="p-2 border mx-auto max-w-md sm:max-w-3xl text-gray-300 text-sm">
          <p>{JSON.stringify(error.message)}</p>
          <p>{JSON.stringify(error.stack)}</p>
        </div>
      </main>
      <footer className="mt-8 mx-auto w-full max-w-7xl flex-shrink-0 px-4 sm:px-6 lg:px-8">
        <nav className="flex justify-center space-x-4">
          <a href="/" className="text-sm font-medium text-gray-500 hover:text-gray-600">
            Contact Support
          </a>
          <span className="inline-block border-l border-gray-300" aria-hidden="true" />
          <a href="/" className="text-sm font-medium text-gray-500 hover:text-gray-600">
            Status
          </a>
          <span className="inline-block border-l border-gray-300" aria-hidden="true" />
          <a href="/" className="text-sm font-medium text-gray-500 hover:text-gray-600">
            Twitter
          </a>
        </nav>
      </footer>
    </div>
  );
}
ErrorFallback.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  error: PropTypes.objectOf(PropTypes.any).isRequired,
};
