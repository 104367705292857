import { nanoid } from 'nanoid';
import { useSelector } from 'react-redux';
import store from '../../store';
import i18n from '../../i18n';
import { globalAccountsView } from '../../redux/reducers/globalSelectors';
import { validateSchema } from '../../redux/actions/data';
import { setMessage } from '../../redux/actions/message';

const debugLevel = process.env.REACT_APP_MDEBUG || 3;

// creates a deposit transaction based on user input
// provide depositAccount for deposit transactions, assetAccount for buy / sell transactions (see below for logic)
// 'currency' is the currency of the transaction, i.e. it will be mapped to fxCurrency (see below)
async function createDepositTransaction({
  transaction,
  depositAccount,
  assetAccount,
  amount,
  fxCurrency,
  description = '',
  linkedTransactionId = null,
  linkedTransactionCategory = null,
  linkedDepositAccount = null,
  designatedConnectedDepositAccountIndex = 0,
}) {
  // creates a deposit transaction based on user input
  // account will be treated as assetAccount if transaction type is buy or sell; otherwise as depositsAccount
  // amount is in the currency indicated by user (transactionCurrency for buy / sell)
  // eslint-disable-next-line max-len
  if (debugLevel > 2) {
    console.log(
      'createDepositTransaction started with args',
      transaction,
      depositAccount,
      assetAccount,
      amount,
      fxCurrency,
      description,
      linkedTransactionId,
      linkedTransactionCategory,
      linkedDepositAccount,
      designatedConnectedDepositAccountIndex,
    );
  }

  const tt = transaction.transactionType;

  const accountId = depositAccount?.id || assetAccount?.connectedDepositAccounts[designatedConnectedDepositAccountIndex];

  // get currency for deposit account transactions is the asset account currency
  // we do it by looking it up on the target account
  // we provide currency, fxCurrency and fxAmount to the API, it will sort the transaction out (will remove it from fx... fields if it's not a fx transaction)
  let depositAccountCurrency;
  if (assetAccount) {
    const state = store.getState();
    const accounts = globalAccountsView(state);
    try {
      depositAccountCurrency = accounts.find((a) => a.id === accountId).currency;
      console.log(
        'DEBUG from props deposit acc:',
        depositAccount,
        'asset acc:',
        assetAccount,
        'calculated',
        accounts.find((a) => a.id === accountId),
      );
    } catch (err) {
      console.error('prepareDataForPosT: error while looking up deposit account currency', err);
      console.debug('accounts:', accounts, 'accountId:', accountId);
      throw new Error('Error while looking up deposit account currency');
    }
  }

  // calculate the label for deposit transaction based on the incoming transaction
  function getLabel(_transaction) {
    const { transactionType, category } = _transaction;

    switch (transactionType) {
      case 'buy':
        switch (category) {
          case 'pension':
            return 'investment-contribution';
          case 'loans':
            return 'investment-payout';
          default:
            return 'investment-purchase';
        }
      case 'sell':
        // we assume that for loans this can only be an out-of-schedule repayment because interest is calculated automatically
        return category === 'loans' ? 'investment-loanPrincipal' : 'investment-sale';
      case 'transfer':
        return 'investment-transfer';
      default:
        return '';
    }
  }

  const depositTransaction = {
    ...transaction,
    accountId,
    category: 'deposits',
    otherParty: i18n.t('app:projects.simulated'),
    otherPartyAccount: tt === 'transfer' ? linkedDepositAccount.id : null,
    description,
    currency: depositAccountCurrency,
    fxCurrency: fxCurrency || assetAccount.currency, // asset transaction currency from props
    fxAmount: amount, // accountCurrencyAmount and amount (in base currency) are calculated in the API (postData > calculate FX rates)
    label: getLabel(transaction),
    ...(!!linkedTransactionId && {
      // only if this is not inflow or outflow
      linkedTransactions: [
        // in case this is an existng transaction with additional LTs, filter out the one that we are about to replace
        ...(transaction.linkedTransactions || []).filter((lt) => lt.id !== linkedTransactionId),
        // insert link to the linked transaction (asset or deposits for transfer)
        {
          id: linkedTransactionId,
          category: linkedTransactionCategory,
          tags: {
            type: tt,
            linkType: `${tt === 'buy' ? 'purchase-to-transaction' : ''}${tt === 'sell' ? 'sale-to-transaction' : ''}${tt === 'transfer' ? 'transfer' : ''}`,
          },
        },
      ],
    }),
  };

  // put data into schema
  // validateSchema is a wrapper which expects { category: <category-name>, transaction: <transaction-object> } as payload
  // returns { category: 'deposits', transaction: {transaction-object}, validation: true / false }
  let castDepositTransaction;
  try {
    castDepositTransaction = depositTransaction && validateSchema({ category: 'deposits', transaction: depositTransaction });
    if (debugLevel > 2) console.log('castDepositTransaction', castDepositTransaction);
  } catch (e) {
    // handle errors coming from schema (display error message and handle user interaction)
    if (debugLevel > 2) console.info('deposit transaction:', depositTransaction);
    console.error(e.errors || e);
    store.dispatch(setMessage('dataUpdateErrorContents'));
    throw new Error('Error while validating object schema (deposit transaction)'); // to stop executing subsequent code
  }

  return castDepositTransaction.transaction;
}

function createAssetTransaction({ transaction, assetAccount, assetAmount, assetId, transactionOriginalPrice = 0, transactionPrice = 0, linkedTransactionId, formInput }) {
  // creates an asset transaction based on user input
  if (debugLevel > 2) console.log('createAssetTransaction started with args', transaction, assetAccount, assetAmount, assetId, transactionOriginalPrice, transactionPrice, linkedTransactionId);

  // abbreviate transactionType for readibility
  const tt = transaction.transactionType;

  // for buy and sell, the transactionDialog is the asset transaction (not the linked deposit transaction)
  const assetTransaction = {
    ...transaction,
    accountId: assetAccount.id,
    displayName: assetId,
    displaySymbol: assetId,
    errorId: null,
    category: assetAccount.category,
    transactionType: tt === 'buy' ? 'purchase' : 'sale',
    // displayName: will only be picked up from the form by Stocks API, for real estate that information is supposed to be in account name
    linkedTransactions: [
      // in case this is an existng transaction with additional LTs, filter out the one that we are about to replace
      ...(transaction?.linkedTransactions || []).filter((lt) => lt.id !== linkedTransactionId),
      // insert link to the linked transaction (asset or deposits for transfer)
      {
        id: linkedTransactionId,
        category: 'deposits',
        tags: {
          type: tt,
          linkType: `${tt === 'buy' ? 'purchase-to-transaction' : ''}${tt === 'sell' ? 'sale-to-transaction' : ''}${tt === 'transfer' ? 'transfer' : ''}`,
        },
      },
    ],
  };
  if (assetAccount.category === 'stocks' || assetAccount.category === 'crypto') {
    assetTransaction.transactionAmount = tt === 'buy' ? assetAmount : -assetAmount;
    assetTransaction.transactionOriginalPrice = transactionOriginalPrice;
    assetTransaction.transactionCurrency = transaction.currency;
  }
  if (assetAccount.category === 'realEstate') {
    assetTransaction.amount = tt === 'buy' ? 1 : -1;
    assetTransaction.price = transactionOriginalPrice;
  }
  if (assetAccount.category === 'pension') {
    assetTransaction.description = i18n.t('app:accountDetails.pension.labels.pension-contribution.short');
    assetTransaction.label = 'pension-contribution';
    assetTransaction.quantity = transactionPrice; // amount is 1 for pension; quantity is only available for pension, but not for stocks / realEstate as of now (240427)
  }
  if (assetAccount.category === 'objectsOfValue') {
    assetTransaction.description = tt === 'buy' ? i18n.t('app:projects.purchase') : i18n.t('app:projects.sale');
    assetTransaction.quantity = tt === 'buy' ? assetAmount : -assetAmount;
    assetTransaction.uptc = transactionPrice;
    assetTransaction.transactionCurrency = transaction.currency;
  }
  if (assetAccount.category === 'metals') {
    assetTransaction.assetId = assetId;
    assetTransaction.assetName = formInput.assetName;
    assetTransaction.quantity = tt === 'buy' ? assetAmount : -assetAmount;
    assetTransaction.uptc = transactionPrice;
    assetTransaction.transactionCurrency = transaction.currency;
    assetTransaction.assetMetal = formInput.assetMetal;
    assetTransaction.assetPurity = formInput.assetPurity;
    assetTransaction.assetWeight = formInput.assetWeight;
    assetTransaction.assetAdditionalValue = formInput.assetAdditionalValue;
  }
  if (assetAccount.category === 'unlistedShares') {
    assetTransaction.assetId = assetAccount.id;
    assetTransaction.quantity = tt === 'buy' ? assetAmount : -assetAmount;
    assetTransaction.uptc = transactionPrice;
    assetTransaction.transactionCurrency = transaction.currency;
    assetTransaction.upac = null; // get it from uptc + transactionCurrency + evtl. fx conversion in API
    assetTransaction.upbc = null; // get it from uptc + transactionCurrency + evtl. fx conversion in API
  }

  // put data into schema
  // validateSchema is a wrapper which expects { category: <category-name>, transaction: <transaction-object> } as payload
  // returns { category: 'deposits', transaction: {transaction-object}, validation: true / false }
  let castAssetTransaction;
  try {
    castAssetTransaction = assetTransaction && validateSchema({ category: assetAccount.category, transaction: assetTransaction });
    if (debugLevel > 2) console.log('castAssetTransaction', castAssetTransaction);
  } catch (e) {
    // handle errors coming from schema (display error message and handle user interaction)
    if (debugLevel > 2) console.info('asset transaction:', assetTransaction);
    console.error(e.errors || e);
    store.dispatch(setMessage('dataUpdateErrorContents'));
    throw new Error('Error while validating object schema (asset transaction)'); // to stop executing subsequent code
  }

  return castAssetTransaction.transaction;
}

export default async function prepareDataForPost({ formInput, originalTransaction, projectId, category }) {
  const { transactionType } = formInput;

  console.log('prepareDataForPost', formInput, originalTransaction, projectId, category);

  // create transaction template objects with all common fields
  const transactionTemplate = {
    date: new Date(formInput.date).valueOf(), // convert date to epoch
    projectId,
    importFlag: originalTransaction.id ? 'put' : 'post',
    transactionType,
    isSimulated: true,
    description: formInput.description || formInput.displayName,
    currency: formInput.currency || formInput.transactionCurrency,
  };

  const recurringTemplate = {
    ...formInput.recurring,
    periodType: { ...formInput.recurring.periodType },
    endAfterDate: new Date(formInput.recurring.endAfterDate).valueOf(),
  };

  const indexingTemplate = { ...formInput.indexed }; // just copy it over
  // amount, accountCurrencyAmount, account, accountTo, tags, linkedTransactions

  // perform general transformations
  // spreading formInput over all that seems the wrong approach, as most of the values will need to be custom-written depending on transaction type
  // if there is an id from originalTransaction, it means it's an update, so we need to use put instead of post

  // we need to assign ids here already, because they are BOTH needed in EACH transaction object
  const mainTransactionId = nanoid();
  const hiddenTransactionId = nanoid();

  const mainTransaction = {
    ...originalTransaction,
    id: originalTransaction?.id ? originalTransaction?.id : mainTransactionId,
    ...transactionTemplate,
    tags: {
      ...originalTransaction.tags,
      type: transactionType,
      recurring: { ...recurringTemplate },
      indexing: { ...indexingTemplate },
    },
  };
  const hiddenTransaction = {
    ...originalTransaction.hiddenTransaction,
    id: originalTransaction?.hiddenTransaction?.id ? originalTransaction?.hiddenTransaction?.id : hiddenTransactionId,
    ...transactionTemplate,
    tags: {
      ...originalTransaction.tags,
      type: transactionType,
      recurring: { ...recurringTemplate },
      indexing: { ...indexingTemplate },
    },
  };

  // incoming buy / sell transaction will _either_ have exchangeRate _or_ assetUnitPriceBaseCurrency
  // deposit transactions for buy / sell should be created in the same currency as the asset transaction

  let result;
  // "normal" asset buy / sell transaction
  if (['buy', 'sell'].includes(transactionType) && formInput.assetAccount.category !== 'deposits') {
    // handle error when account has no linked deposit account
    const { assetAccount } = formInput;
    if (!assetAccount.connectedDepositAccounts || assetAccount.connectedDepositAccounts.length === 0) {
      store.dispatch(setMessage('noLinkedAccount'));
      return false;
    }
    const designatedConnectedDepositAccountIndex = formInput.designatedConnectedDepositAccountIndex === undefined ? 0 : formInput.designatedConnectedDepositAccountIndex;

    result = await Promise.all([
      createAssetTransaction({
        transaction: mainTransaction,
        assetAccount, // entire account object
        assetAmount: formInput.assetAmount,
        assetId: formInput.displaySymbol,
        transactionOriginalPrice: formInput.assetUnitPrice,
        transactionPrice: formInput.assetUnitPriceBaseCurrency || formInput.exchangeRate * formInput.assetUnitPrice,
        linkedTransactionId: hiddenTransaction.id,
        formInput,
      }),
      createDepositTransaction({
        transaction: hiddenTransaction,
        assetAccount,
        amount: (transactionType === 'buy' ? -1 : 1) * formInput.assetAmount * formInput.assetUnitPrice,
        description: `${i18n.t('app:projects.simulated')} ${transactionType === 'buy' ? i18n.t('app:projects.purchase') : i18n.t('app:projects.sale')} ${formInput.displaySymbol}`,
        fxCurrency: formInput.transactionCurrency || formInput.currency, // if it is the account currency, it will get fixed in API
        linkedTransactionId: mainTransaction.id,
        linkedTransactionCategory: category,
        // vv check if the field is 'undefined' (this field can return 0 and 0 is a valid value, so be careful about tests!)
        designatedConnectedDepositAccountIndex,
        formInput,
      }),
    ]);
    // as the buy / sell button very sadly also includes deposits, this is the case for deposits (which is identical to transfers)
  } else if (['buy', 'sell'].includes(transactionType) && formInput.assetAccount.category === 'deposits') {
    result = Promise.all([
      createDepositTransaction({
        transaction: mainTransaction,
        depositAccount: formInput.assetAccount,
        fxCurrency: formInput.currency, // if it is the account currency, it will get fixed in API
        amount: formInput.assetAmount,
        description: `${i18n.t('app:projects.simulated')} ${i18n.t('app:projects.deposit')}`,
        linkedTransactionId: hiddenTransaction.id,
        linkedTransactionCategory: 'deposits',
        linkedDepositAccount: formInput.account,
        formInput,
      }),
      createDepositTransaction({
        transaction: hiddenTransaction,
        depositAccount: formInput.assetAccount,
        fxCurrency: formInput.currency, // if it is the account currency, it will get fixed in API
        amount: -formInput.assetAmount,
        description: `${i18n.t('app:projects.simulated')} ${i18n.t('app:projects.deposit')}`,
        linkedTransactionId: mainTransaction.id,
        linkedTransactionCategory: 'deposits',
        linkedDepositAccount: formInput.accountTo,
        formInput,
      }),
    ]);
  } else if (transactionType === 'transfer') {
    result = Promise.all([
      createDepositTransaction({
        transaction: mainTransaction,
        depositAccount: formInput.accountTo,
        fxCurrency: formInput.currency, // if it is the account currency, it will get fixed in API
        amount: formInput.amount,
        description: `${i18n.t('app:projects.simulated')} ${i18n.t('app:projects.transfer.label')}`,
        linkedTransactionId: hiddenTransaction.id,
        linkedTransactionCategory: 'deposits',
        linkedDepositAccount: formInput.account,
        formInput,
      }),
      createDepositTransaction({
        transaction: hiddenTransaction,
        depositAccount: formInput.account,
        fxCurrency: formInput.currency, // if it is the account currency, it will get fixed in API
        amount: -formInput.amount,
        description: `${i18n.t('app:projects.simulated')} ${i18n.t('app:projects.transfer.label')}`,
        linkedTransactionId: mainTransaction.id,
        linkedTransactionCategory: 'deposits',
        linkedDepositAccount: formInput.accountTo,
        formInput,
      }),
    ]);
  } else if (['inflow', 'outflow'].includes(transactionType)) {
    result = Promise.all([
      createDepositTransaction({
        transaction: mainTransaction,
        depositAccount: transactionType === 'inflow' ? formInput.accountTo : formInput.account,
        description: `${transactionType === 'inflow' ? i18n.t('app:projects.inflow.labelTop') : i18n.t('app:projects.outflow.labelTop')}`,
        fxCurrency: formInput.currency, // if it is the account currency, it will get fixed in API
        amount: formInput.amount, // this will be a negative number if transactionType is outflow (validation in form)
        formInput,
      }),
    ]);
  } else {
    throw new Error('Transaction type not supported');
  }
  // Promise.all will throw if one of the promises is rejected
  return result;
}
