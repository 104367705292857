import { AdjustmentsHorizontalIcon, BriefcaseIcon, BuildingLibraryIcon, ClockIcon, FlagIcon } from '@heroicons/react/24/outline';

const products = {
  deposits: [
    {
      id: 'savings-account-giro', // unique
      type: 'savings-account', // generic product type, not unique
      Icon: BuildingLibraryIcon,
      label: 'Girokonto', // customer-friendly name
      description: 'app:addAccount.D1.products.savings-account-giro',
      link: 'https://www.monestry.de/blog/address',
    },
    {
      id: 'savings-account-tagesgeld', // unique
      type: 'savings-account', // generic product type, not unique
      Icon: BuildingLibraryIcon,
      label: 'Tagesgeldkonto', // customer-friendly name
      description: 'app:addAccount.D1.products.savings-account-tagesgeld',
      link: 'https://www.monestry.de/blog/address',
    },
    {
      id: 'time-deposit-festgeld',
      type: 'time-deposit',
      Icon: ClockIcon,
      label: 'Festgeld',
      description: 'app:addAccount.D1.products.time-deposit-festgeld',
      link: 'https://www.monestry.de/blog/address',
    },
    {
      id: 'savings-account-generic', // unique
      type: 'savings-account', // generic product type, not unique
      Icon: BuildingLibraryIcon,
      label: 'Current account', // customer-friendly name
      description: 'app:addAccount.D1.products.savings-account-generic',
      link: 'https://www.monestry.de/blog/address',
    },
    {
      id: 'time-deposit-generic',
      type: 'time-deposit',
      Icon: ClockIcon,
      label: 'Time deposit',
      description: 'app:addAccount.D1.products.time-deposit-generic',
      link: 'https://www.monestry.de/blog/address',
    },
  ],
  stocks: [],
  pension: [
    {
      id: 'pension-drv',
      type: 'pension-drv',
      Icon: FlagIcon,
      link: 'https://www.monestry.de/blog/address',
      contributionsSelfPaid: false,
      contributionsFrequency: null,
      contributionsDeductible: null,
      payoutsTaxable: true,
      payoutsIndexed: true,
      payoutsFrequency: 1,
      payoutsDuration: 0,
    },
    {
      id: 'pension-betriebliche',
      type: 'pension-betriebliche',
      Icon: BriefcaseIcon,
      link: 'https://www.monestry.de/blog/address',
      contributionsSelfPaid: false,
      contributionsFrequency: null,
      contributionsDeductible: null,
      payoutsTaxable: true,
      payoutsIndexed: true,
      payoutsFrequency: 1,
      payoutsDuration: 0,
    },
    {
      id: 'pension-riester',
      type: 'pension-riester',
      Icon: BuildingLibraryIcon,
      link: 'https://www.monestry.de/blog/address',
      contributionsSelfPaid: true,
      contributionsFrequency: 1,
      contributionsDeductible: true,
      payoutsTaxable: true,
      payoutsIndexed: false,
      payoutsFrequency: 1,
      payoutsDuration: 0,
    },
    {
      id: 'pension-ruerup',
      type: 'pension-ruerup',
      Icon: BuildingLibraryIcon,
      link: 'https://www.monestry.de/blog/address',
      contributionsSelfPaid: true,
      contributionsFrequency: 1,
      contributionsDeductible: true,
      payoutsTaxable: true,
      payoutsIndexed: false,
      payoutsFrequency: 1,
      payoutsDuration: 0,
    },
    {
      id: 'custom',
      type: 'custom',
      Icon: AdjustmentsHorizontalIcon,
      link: 'https://www.monestry.de/blog/address',
    },
  ],
  realEstate: [],
  objectsOfValue: [],
  metals: [],
  loans: [],
  unlistedShares: [],
};

export default products;
