/* eslint-disable global-require */
/* eslint-disable import/no-extraneous-dependencies */
const defaultTheme = require('tailwindcss/defaultTheme');
const colors = require('tailwindcss/colors');

module.exports = {
  content: [
    './src/pages/**/*.{html,js,jsx}',
    './src/elements/**/*.{html,js,jsx}',
    './public/index.html',
  ],
  theme: {
    fontWeight: {
      hairline: 100,
      extralight: 100,
      light: 300,
      normal: 400,
      medium: 400,
      semibold: 700,
      bold: 700,
      extrabold: 700,
      black: 900,
    },
    extend: {
      fontFamily: {
        sans: ['Lato', ...defaultTheme.fontFamily.sans],
      },
      colors: {
        gray: colors.slate,
        brandBlue: {
          50: '#F0FAFF',
          100: '#E0F4FF',
          200: '#C1E9FF',
          300: '#94D4F7',
          400: '#60B9F7',
          500: '#1797F2',
          600: '#007bd2',
          700: '#0060b4',
          800: '#003a87',
          900: '#002f79',
        },
        brandGreen: {
          50: '#f1f9ec',
          100: '#ecf7e6',
          200: '#ddf0d3',
          300: '#CAE8BA',
          400: '#ABDE82',
          500: '#92D050',
          600: '#6ba929',
          700: '#307200',
          800: '#034f00',
          900: '#012e00',
        },
        brandViolet: {
          50: '#f9f2fb',
          100: '#f4e4f6',
          200: '#eed7f2',
          300: '#e2bde9',
          400: '#ce95dc',
          500: '#BA6DCE',
          600: '#8e44a3',
          700: '#722987',
          800: '#56096c',
          900: '#49005e',
        },
        brandYellow: {
          50: '#FFFAE6',
          100: '#fff7d9',
          200: '#fff3c7',
          300: '#ffeca1',
          400: '#ffe47a',
          500: '#FFD600',
          600: '#ceab00',
          700: '#9e8200',
          800: '#725b00',
          900: '#4d3700',
        },
        brandRed: {
          50: '#FFF5F4',
          100: '#ffeae8',
          200: '#FFC6C2',
          300: '#FF9696',
          400: '#f95949',
          500: '#ED1C24',
          600: '#cf000e',
          700: '#b00000',
          800: '#930000',
          900: '#780000',
        },
        brandDarkBlue: {
          50: '#F1F8F8',
          100: '#DBE7FF',
          200: '#879DD4',
          300: '#616EAE',
          400: '#474E9B',
          500: '#2E3192',
          600: '#1d2785',
          700: '#001873',
          800: '#000a60',
          900: '#000055',
        },
      },
    },
  },
  plugins: [
    require('@tailwindcss/forms'),
    require('@tailwindcss/aspect-ratio'),
    require('@tailwindcss/typography'),
    require('@tailwindcss/line-clamp'),
  ],
};
