/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-restricted-syntax */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { createSelector } from 'reselect';
import { decodeObjectFromLocalStorage } from '../misc/localStorageOps';
import DashboardCategory from '../sections/accountDetails/DashboardCategory';
import ChartWrapper from '../sections/reports/ChartWrapper';
import getTour from '../tours/getTour';
import HeaderApp from '../elements/HeaderApp';
import AddAccount from '../sections/addAccount/AddAccount';
import AccountDetails from '../sections/accountDetails/AccountDetails';
import ProjectDetails from '../sections/projects/ProjectDetails';
import Settings from '../sections/settings/Settings';
import RecommendUs from '../elements/RecommendUs';
import Rebalancing from '../sections/rebalancing/Rebalancing';
import Calendar from '../elements/Calendar';
import FullScreenReport from '../elements/FullScreenReport';
import TransactionPreview from '../sections/accountDetails/TransactionPreview';
import EditSync from './EditSync';
import Slider from '../sections/projects/Slider';
import Button from '../elements/Button';
import ProjectList from '../sections/projects/ProjectList';
import { getDataByCategory, getDataByAccount, globalAccountsView } from '../redux/reducers/data';
import { setSimulationBaseDateAndUpdateQuotes } from '../redux/reducers/simulation';
import { getProjects } from '../redux/actions/projects';
import TourWrapper from '../elements/TourWrapper';
import KPIs from '../sections/kpis/KPIs';
import { SearchResults } from '../elements/SearchComponent';
import { enabledCategories } from '../misc/globalSettings';
import { setMessage } from '../redux/actions/message';

export function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

// Dashboard component is used to display the primary dashboard (mode === 'dashboard')
// as well as to display the projects page (mode === 'projects')
// which displays additionally the slider, project list and project details
// but disables Tiletip (recent transaction preview)

// ATTENTION: this component is used, in a modified version, in the SharedReportViewer - if you change it, make sure to check that component as well
export default function Dashboard({ mode }) {
  const { t, i18n } = useTranslation('app');

  const [editMode, setEditMode] = useState(false); // all categories and "add new" buttons are visible
  const [calendar, setCalendarState] = useState({
    visible: false,
    selectedDate: null,
    callback: null,
  }); // calendar used to set the baseline for comparison to current net worth
  const [addAccountMode, setAddAccountMode] = useState({
    visible: false,
    category: null,
    addAccountState: undefined,
  }); // displays AddAccount, if addAccountState is present, it will be passed to its state
  const [account, setAccount] = useState(null); // controls the visibility of the AccountDetails component, has account object, do not use to change account props
  const [projectId, setProjectId] = useState(null); // do not try to replace it with project object, because it does not get updated when Redux changes (e.g. when project name gets updated)
  const [rebalancing, setRebalancing] = useState(false); // controls the visibility of the Rebalancing component // FIXME
  const [kpiReportVisible, setKpiReportVisible] = useState(false);
  const [settings, setSettings] = useState(false); // truthy or true for settings visible or name of tab; false or falsey for settings hidden
  const [recommendUsVisible, setRecommendUsVisible] = useState(false); // controls the visibility of the RecommendUs component
  const [searchControl, setSearchControl] = useState({
    text: '',
    searching: false,
    focus: null,
  }); // search results for the search bar;
  const [tiletip, setTiletip] = useState(null); // holds the transaction object for the transaction preview
  // focus is true when either Input or Results are in focus; click on Results would otherwise cause onBlur on Input to fire and hide the results
  const [projectsLoading, setProjectsLoading] = useState(false);
  const [fileReceived, setFileReceived] = useState(false);

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const baseCurrency = useSelector((state) => state.user.profile.settings.baseCurrency);
  const sliderDate = useSelector((state) => state.simulation.baseDate);
  const fullscreenReport = useSelector((state) => state.message.fullscreenReport);

  // ----------------------------------
  // SET DASHBOARD MODE BASED ON URL
  // ----------------------------------

  // set the state.simulation.dashboardMode to 'projects' as soon as the location turns /app/projects
  // and set it back to 'dashboard' when the location is anything else
  useEffect(() => {
    // set dashboard / project mode based on URL
    if (location.pathname === `/${i18n.language}/app/projects`) {
      setProjectsLoading(true);
      dispatch({
        type: 'simulation/setDashboardMode',
        payload: { dashboardMode: 'projects' },
      });
      dispatch(getProjects()).then(
        () => setProjectsLoading(false),
        () => setProjectsLoading(false),
      );
      dispatch(setSimulationBaseDateAndUpdateQuotes({ newDate: null })); // this needs the slider base date as argument
    } else {
      dispatch(getProjects()); // needed for loans (connectedProjectId dialog)
      dispatch({
        type: 'simulation/setDashboardMode',
        payload: { dashboardMode: 'dashboard' },
      });
    }
  }, [location.pathname]);

  // ----------------------------------
  // READ LOCATION.STATE AND HANDLE IT
  // ----------------------------------

  useEffect(() => {
    // show AccountDetails (e.g. when redirected from /app/reports after the user clicks on a search result)
    if (location.state && location.state.account) {
      setAccount(location.state.account);
    }
    // handle addAccount workflow reentry after being redirected to the provider (navigated from AddAccountCallbackReceiver)
    // ↓↓ this would be present if the user presses browser back while in WebForm
    if (localStorage.getItem('addAccountState')) {
      decodeObjectFromLocalStorage('addAccountState').then((addAccountState) => {
        // console.log('storageObject', addAccountState);
        const modifiedState = { ...addAccountState };
        // ↓↓ this flag is set by AddAccountCallbackReceiver, which pushes the displayedStep ahead by 1 to proceed with the workflow
        if (!modifiedState.doNotChangeDisplayedStep) modifiedState.displayedStep -= 1;
        // console.log('modifiedState', modifiedState);
        setAddAccountMode({
          visible: true,
          category: modifiedState.category,
          addAccountState: modifiedState,
        });
      });
    }

    // handle provider callback after the user has authorised the app for the subsequent time (navigate from AddAccountCallbackReceiver)
    if (location.state && location.state.renewAccountAccess) {
      const { renewAccountAccess } = location.state;
      console.log('location.state.renewAccountAccess', location.state.renewAccountAccess);
      dispatch(setMessage('renewAccountAccessSuccess'));
      // refresh the account data for entire category, we may have more than one account modified (if there were more than one account with the same provider)
      dispatch(getDataByCategory(renewAccountAccess.category));
      localStorage.removeItem('renewAccountAccess');
    }
  }, [location.state]);

  // ----------------------------------
  // HANDLE postFile (MOBILE APP ONLY)
  // ----------------------------------

  const accounts = useSelector(globalAccountsView);

  const selectCategoryStatus = createSelector(
    (state) => state.data,
    (data) => Object.keys(data).map((category) => ({ category, status: data[category].status })),
  );

  const categoryStatus = useSelector(selectCategoryStatus);

  async function getAllData() {
    const promises = enabledCategories.map((category) => dispatch(getDataByCategory(category)));
    await Promise.all(promises);
  }

  // FIXME: this will have the state from the moment it was attached to window, not the current state
  // check how ref is used to work around that and reapply this here (it is crucial that setFileReceived is the current version of the component)
  // this is fired-and-forgot from the mobile app, but should wait for async bits inside when it is running here
  async function postFile(fileData, fileName) {
    if (!fileData || !fileName) {
      setMessage('fileUploadError');
      console.error('postFile called without fileData or fileName');
      return;
    }

    function postFileCallback(accountIdName) {
      // open account details for the selected account
      const targetAccount = accounts.find((a) => a.id === accountIdName.id);
      setAccount(targetAccount); // this will open AccountDetails which will already receive fileReceived as prop
    }

    setFileReceived({ fileData, fileName }); // add file to state for AccountDetails later

    const accountList = accounts.filter((a) => ['deposts', 'stocks'].includes(a.category)).map((a) => ({ name: a.name, id: a.id }));

    // webview removes redux state under some conditions (while keeping the UI unchanged!), so we need to check if it is still there
    // if categories are all either idle or inactive, it means that redux has been reset
    if (categoryStatus.every((c) => ['inactive', 'idle'].includes(c.status))) {
      // need to reload data
      console.log('need to reload data, running getAllData');
      await getAllData(); // FIXME does this have to get ALL data?
      // console.log('data reload completed', JSON.stringify(categoryStatus, null, 2));
    }

    // run dialog and ask which account is this for
    window.dispatchEvent(
      new CustomEvent('setDialog', {
        detail: {
          header: t('dashboard.postFile.header'),
          prompt: t('dashboard.postFile.prompt'),
          value: accountList[0],
          callback: postFileCallback,
          dropdown: accountList,
        },
      }),
    );
  }

  // mobileApp mode only
  // postFile must be re-exposed every time the state gets updated, otherwise it "remembers" the old state only (a la jSpreadsheet)
  // once loading is complete, send message to mobileApp (in mobileApp mode only)
  useEffect(() => {
    // 'idle' is the initial status for active accounts; 'inactive' is the initial status for inactive accounts
    // we need all accounts to be either 'success', 'error', 'inactive' to be able to deduce that getData is finished
    if (window.mobileApp && categoryStatus.every((c) => ['success', 'error', 'inactive'].includes(c.status))) {
      window.postFile = postFile;
      window.mobileApp.postMessage('dashboardReady');
    }
    return () => {
      window.postFile = null;
    };
  }, [categoryStatus]);

  // settings === true: call Settings with no openOnTab (i.e. default tab)
  // settings is string: call Settings with the string
  // settings is falsey: do not call Settings
  const SettingsComponent = settings ? (
    <Settings closeCallback={() => setSettings(false)} openOnTab={settings === true ? null : settings} calendarState={calendar} setCalendarState={setCalendarState} setSettings={setSettings} />
  ) : null;

  const topChartFilterCallback = useCallback((item) => item.date > dayjs().subtract(10, 'year').valueOf(), []); // holds between renders and returns the function

  const topChartLayoutOptions = useMemo(
    () => ({
      // holds between renders and returns the value
      startLabel: false,
      endLabel: false,
      margin: {
        top: 10,
        right: 20,
        bottom: 30,
        left: 20,
      },
    }),
    [],
  );

  // handle TransactionPreview on and off
  useEffect(() => {
    const handleShowTiletip = (event) => {
      // all custom parameters come in event.detail
      if (event.detail === null) {
        setTiletip(null);
      } else {
        setTiletip(event.detail);
      }
    };

    window.addEventListener('showTransactionPreview', handleShowTiletip);

    return () => {
      window.removeEventListener('showTransactionPreview', handleShowTiletip);
    };
  }, []);

  return (
    <div className="" id="dashboard-root">
      <div className="relative 2xl:static" id="dashboard-level-1">
        {calendar.visible && <Calendar calendarState={calendar} setCalendarState={setCalendarState} />}
        {addAccountMode.visible && <AddAccount category={addAccountMode.category} addModeState={addAccountMode} setAddModeState={setAddAccountMode} addAccountState={addAccountMode.addAccountState} />}
        {account && <AccountDetails account={account} setAccount={setAccount} fileReceived={fileReceived} setFileReceived={setFileReceived} />}
        {projectId && <ProjectDetails projectId={projectId} setProjectId={setProjectId} setCalendarState={setCalendarState} />}
        {fullscreenReport && <FullScreenReport reportProps={fullscreenReport} />}
        {SettingsComponent}
        {recommendUsVisible && <RecommendUs setRecommendUsVisible={setRecommendUsVisible} />}
        {rebalancing && <Rebalancing setRebalancing={setRebalancing} closeCallback={() => setRebalancing(false)} />}
        <TransactionPreview tiletip={tiletip} setTiletip={setTiletip} />
        {/* ↓↓ setup for debugging TransactionPreview */}
        {/* <TransactionPreview tiletip={{ account: accounts.find((a) => a.name === 'My loan 1'), baseCurrency }} setTiletip={setTiletip} /> */}
        <div className="relative min-h-full bg-gray-50">
          <HeaderApp
            mode={mode}
            openSettings={() => setSettings(true)}
            openAccount={() => setSettings('account')}
            searchControl={searchControl}
            setSearchControl={setSearchControl}
            setRecommendUsVisible={setRecommendUsVisible}
          />
          {/* mt corresponds to the height of background padding set in HeaderApp */}
          {/* CAUTION: when changing the following line, change it in Reporting as well */}
          <main className={classNames('pb-8', mode === 'dashboard' ? '-mt-12 lg:-mt-20' : '-mt-40 lg:-mt-56')}>
            <div className="relative max-w-3xl mx-auto px-4 sm:px-6 lg:max-w-[100rem] lg:px-8">
              {searchControl.text && <SearchResults searchControl={searchControl} setSearchControl={setSearchControl} setAccount={setAccount} />}
              <h1 className="sr-only">Profile</h1>
              {/* project time slider */}
              {mode === 'projects' && (
                <div className="w-full h-28 lg:mb-6">
                  <h2 className="text-white text-sm font-bold mb-6 text-center uppercase tracking-wider">{t('dashboard.setDate')}</h2>
                  <Slider />
                </div>
              )}
              {/* ------------------ */}
              {/* MAIN 3-COLUMN GRID */}
              {/* ------------------ */}
              {/* FIXME in conjunction with fixing KPIs */}
              <div className="grid grid-cols-1 gap-2 md:gap-4 items-start lg:grid-cols-12 lg:gap-8">
                {/* Left column */}
                <KPIs baseCurrency={baseCurrency} setKpiReportVisible={setKpiReportVisible} calendar={calendar} setCalendarState={setCalendarState} setAccountDetails={setAccount} />
                {/* Middle column */}

                <div className="grid grid-cols-1 gap-4 lg:col-span-6">
                  {/* Header panel */}
                  <section aria-labelledby="accounts-control-panel">
                    <div className="xs:overflow-hidden flex flex-wrap justify-between sm:grid sm:grid-cols-3">
                      <h2 className="sr-only" id="profile-overview-title">
                        Accounts Overview
                      </h2>
                      <div className="py-4 px-0 sm:px-3 md:px-4 sm:col-span-2">
                        <div className="flex gap-3 align-baseline" id="oneaboveyourassets">
                          <h2 className="text-2xl sm:text-3xl md:text-4xl lg:text-3xl xl:text-4xl font-bold text-gray-900 lg:text-white" id="your-assets">
                            {t('dashboard.yourAssets')}
                            {mode === 'projects' && (
                              <span>
                                {' '}
                                in
                                {' '}
                                {new Date(sliderDate)
                                  .toLocaleDateString(i18n.language, {
                                    month: 'short',
                                    timeZone: 'UTC',
                                  })
                                  .substring(0, 3)}
                                {' '}
                                {new Date(sliderDate).toLocaleDateString(i18n.language, { year: 'numeric', timeZone: 'UTC' })}
                              </span>
                            )}
                          </h2>
                        </div>
                      </div>
                      {/* Edit / Sync / Share buttons */}
                      <EditSync editMode={editMode} setEditMode={setEditMode} getAllData={getAllData} mode={mode} />
                    </div>
                  </section>
                  {enabledCategories.map((category) => (
                    <DashboardCategory
                      key={category}
                      category={category}
                      editMode={editMode}
                      setEditMode={setEditMode}
                      addMode={addAccountMode}
                      setAddMode={setAddAccountMode}
                      setAccount={setAccount}
                    />
                  ))}
                </div>
                {/* Right column */}
                <div className="grid grid-cols-1 gap-4 lg:col-span-4 ">
                  {/* Charts / transaction preview  / project list */}
                  {mode === 'projects' ? (
                    <ProjectList setProjectId={setProjectId} editMode={editMode} projectsLoading={projectsLoading} />
                  ) : (
                    <>
                      <section aria-labelledby="chart-title" className="">
                        <div className="relative rounded-md bg-white overflow-hidden shadow">
                          <div className="p-6 h-[32rem]">
                            <h2 className="text-base font-semibold text-gray-900 pb-4" id="chart-top-title">
                              {t('charts.last12months')}
                            </h2>
                            <div className="w-full h-[80%]">
                              <ChartWrapper reportId="assetBalancesOverTime" filterCallback={topChartFilterCallback} layoutOptions={topChartLayoutOptions} />
                            </div>
                            <div className="mt-6 mb-6 bg-white">
                              <Button onClick={() => navigate(`/${i18n.language}/app/reports`)} text={t('charts.goToReports')} noFrame size="xl" formatting="w-full bg-white" />
                            </div>
                          </div>
                        </div>
                      </section>
                      <section aria-labelledby="chart-title">
                        <div className="rounded-md bg-white overflow-hidden shadow z-10">
                          <div className="p-6 h-[32rem]">
                            <h2 className="mb-6 text-base font-semibold text-gray-900" id="chart-bottom-title">
                              {t('charts.currentAllocation')}
                            </h2>
                            <div className="w-full h-[80%]">
                              <ChartWrapper reportId="allAssetsSunburstxxxx" />
                            </div>
                            <div className="mb-6 flex space-x-4">
                              {/* <button
                                type="button"
                                onClick={() => setRebalancing(true)}
                                className="w-full flex justify-center items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                              >
                                {t('charts.rebalancing')}
                              </button> */}
                              <Button onClick={() => setRebalancing(true)} text={t('charts.rebalancing')} noFrame size="xl" formatting="w-full bg-white" />
                              <Button onClick={() => navigate(`/${i18n.language}/app/reports`)} text={t('charts.goToReports')} noFrame size="xl" formatting="w-full bg-white" />
                              {/* <Link
                                to={`/${i18n.language}/app/reports`}
                                className="w-full flex justify-center items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                              >
                                {t('charts.goToReports')}
                              </Link> */}
                            </div>
                          </div>
                        </div>
                      </section>
                    </>
                  )}
                </div>
              </div>
            </div>
          </main>
          <TourWrapper localTours={getTour('dashboard')} />
        </div>
        {/* <Footer /> */}
        {/* 231115, PL - removed, as Footer is already added in App */}
      </div>
    </div>
  );
}
Dashboard.propTypes = {
  mode: PropTypes.string,
};
Dashboard.defaultProps = {
  mode: 'dashboard',
};
