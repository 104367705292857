import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import BaselineDropdown from '../kpis/BaselineDropdown';
import { LanguageDropdown } from '../../elements/Footer';

export default function Preferences({ calendarState, setCalendarState }) {
  const { t, i18n } = useTranslation('app', { keyPrefix: 'settings.preferences' });
  const baseCurrency = useSelector((state) => state.user.profile.settings.baseCurrency);

  return (
    <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5 text-gray-700 font-normal">
      {/* Notifications: monthly report, tips? */}
      <div className="space-y-6 sm:space-y-5">
        <div className="space-y-6 sm:space-y-5">
          <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
            <p className="text-sm pb-2 sm:pb-0">{t('baseCurrency')}</p>
            <p className="text-gray-500">{baseCurrency}</p>
          </div>
          <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5 text-sm">
            <p>{t('preferredLanguage')}</p>
            <div className="sm:max-w-xs">
              <LanguageDropdown />
            </div>
          </div>

          <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
            <label htmlFor="about" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
              {t('baselineDate')}
            </label>
            <div className="mt-3 sm:col-span-2 sm:mt-0 sm:max-w-xs">
              <BaselineDropdown calendarState={calendarState} setCalendarState={setCalendarState} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
Preferences.propTypes = {
  calendarState: PropTypes.shape({
    year: PropTypes.number,
    month: PropTypes.number,
  }).isRequired,
  setCalendarState: PropTypes.func.isRequired,
};
