const kpiReport = {
  name: 'KPI Report (new, EN only)',
  readOnly: true,
  dataScope: 'balances', // does not matter, it uses its own data source
  cols: [],
  rows: [],
  vals: [],
  aggregatorName: 'Count',
  rendererName: 'KPI Report Unisel',
  id: 'kpiReportUniselxxxxxx',
};

export default kpiReport;
