/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { loanTransactions, loanTransactionsProjectView } from '../../redux/reducers/data';
import ChartWrapperNoPivotTable from '../reports/ChartWrapperNoPivotTable';

dayjs.extend(utc);

// remaining principal and interest:
const progressColour = 'bg-brandBlue-500';
const backgroundColour = 'bg-gray-200';

function PercentDone({ loanAmount, sumPrincipal }) {
  const { t } = useTranslation('app', { keyPrefix: 'accountDetails.loanIndicators' });

  const marker = loanAmount ? Math.abs((sumPrincipal / loanAmount) * 100) : 0;

  return (
    <div className="p-2 sm:p-4 w-full border border-gray-300 rounded-lg">
      <h3 className="font-bold text-base sm:text-lg mb-1 sm:mb-2">{t('loanRepaid')}</h3>
      <div className="space-y-1 px-2">
        <div style={{ marginLeft: '-8px', paddingLeft: `${marker}%` }} className="w-fit">
          {`${Number(marker || 0).toFixed(0)} %`}
        </div>
        <div className="relative w-full">
          <div className={`${backgroundColour} relative h-2 w-full rounded-sm`}>
            <div className={`${progressColour} absolute h-2 rounded-sm`} style={{ width: `${marker}%` }} />
          </div>
        </div>
        <div style={{ marginLeft: '-8px', paddingLeft: `${marker}%` }} className="w-fit">
          <div className="-rotate-90 w-fit">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4">
              <path fillRule="evenodd" d="M4.5 5.653c0-1.426 1.529-2.33 2.779-1.643l11.54 6.348c1.295.712 1.295 2.573 0 3.285L7.28 19.991c-1.25.687-2.779-.217-2.779-1.643V5.653z" clipRule="evenodd" />
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
}
PercentDone.propTypes = {
  loanAmount: PropTypes.number.isRequired,
  sumPrincipal: PropTypes.number.isRequired,
};

function InterestPaid({ sumInterest, sumPrincipal, accountCurrency }) {
  const { t } = useTranslation('app', { keyPrefix: 'accountDetails.loanIndicators' });

  return (
    <div className="p-2 sm:p-4 w-full border border-gray-300 rounded-lg grid grid-cols-1 gap-2 xs:gap-0 xs:grid-cols-2">
      <h3 className="font-bold text-base sm:text-lg mb-1 sm:mb-2 xs:col-span-2">{t('interest')}</h3>
      <div>
        <div className="text-xs xs:text-sm">{t('interestPaid')}</div>
        <div className="text-[1rem] transform scale-y-110 sm:scale-y-100 font-bold text-gray-900 sm:flex sm:items-baseline lg:block xl:flex gap-1 xl:text-xl">
          {(sumInterest).toLocaleString('de', { style: 'currency', currency: accountCurrency })}
          {/* <span className={`${currentRoiPercent >= 0 ? 'text-green-500' : 'text-brandRed-500'} flex items-baseline text-xs sm:text-sm font-semibold`}>
            {currentInvestedAmount < 0 && currentRoiPercent.toLocaleString('de', { style: 'percent', maximumFractionDigits: 1 })}
          </span> */}
        </div>
      </div>
      <div>
        <div className="text-xs xs:text-sm">{t('interestPaidToPrincipalPaid')}</div>
        <div className="text-[1rem] transform scale-y-110 sm:scale-y-100 font-bold text-gray-900 sm:flex sm:items-baseline lg:block xl:flex gap-1 xl:text-xl">
          {(sumInterest / sumPrincipal).toLocaleString('de', { style: 'percent', maximumFractionDigits: 2, minimumFractionDigits: 2 })}
          {/* <span className={`${projectNextGoalRoiPercent >= 0 ? 'text-green-500' : 'text-brandRed-500'} flex items-baseline text-xs sm:text-sm font-semibold`}>
            {projectNextGoalInvestedAmount < 0 && projectNextGoalRoiPercent.toLocaleString('de', { style: 'prcent', maximumFractionDigits: 1 })}
          </span> */}
        </div>
      </div>
    </div>
  );
}
InterestPaid.propTypes = {
  sumInterest: PropTypes.number.isRequired,
  sumPrincipal: PropTypes.number.isRequired,
  accountCurrency: PropTypes.string.isRequired,
};

function ToBePaid({ thisAccount, sumPrincipal: sumPastPrincipal }) {
  const { t } = useTranslation('app', { keyPrefix: 'accountDetails.loanIndicators' });

  const accountCurrency = thisAccount.currency;
  const allFutureTransactions = useSelector(loanTransactionsProjectView);

  const sumFutureInterest = allFutureTransactions.reduce((acc, tx) => (tx.isSimulated && tx.accountId === thisAccount.id ? acc + tx.quantityInterest : acc), 0);

  return (
    <div className="p-2 sm:p-4 w-full border border-gray-300 rounded-lg col-span-2 grid grid-cols-2">
      <h3 className="font-bold text-base sm:text-lg mb-1 sm:mb-2 col-span-3">{t('balance')}</h3>
      <div>
        <div className="text-xs xs:text-sm">{t('principalRemaining')}</div>
        <div className="text-[1rem] transform scale-y-110 sm:scale-y-100 font-bold text-gray-900 sm:flex sm:items-baseline lg:block xl:flex gap-1 xl:text-xl">
          {Math.abs(thisAccount.loanAmount + sumPastPrincipal).toLocaleString('de', { style: 'currency', currency: accountCurrency })}
        </div>
      </div>
      <div>
        <div className="text-xs xs:text-sm">{t('interestRemaining')}</div>
        <div className="text-[1rem] transform scale-y-110 sm:scale-y-100 font-bold text-gray-900 sm:flex sm:items-baseline lg:block xl:flex gap-1 xl:text-xl">
          {(sumFutureInterest).toLocaleString('de', { style: 'currency', currency: accountCurrency })}
        </div>
      </div>
    </div>
  );
}
ToBePaid.propTypes = {
  thisAccount: PropTypes.object.isRequired,
  sumPrincipal: PropTypes.number.isRequired,
};

export default function LoanIndicators({ accountId }) {
  const { t } = useTranslation('app', { keyPrefix: 'accountDetails.loanIndicators' });

  const accounts = useSelector((state) => state.data.loans.accounts);
  const thisAccount = accounts.find((a) => a.id === accountId);
  const transactions = useSelector(loanTransactions);
  const thisAccountTransactions = transactions.filter((tx) => tx.accountId === accountId);
  const sumPrincipal = thisAccountTransactions
  // without payout transactions
    .filter((tx) => ((thisAccount.direction === 'received') ? tx.quantity > 0 : tx.quantity < 0))
    .reduce((acc, tx) => acc + tx.quantity, 0);
  const sumInterest = thisAccountTransactions.reduce((acc, tx) => acc + tx.quantityInterest, 0);

  const accountCurrency = thisAccount.currency;

  // prepare data for chart
  const adjustedTransactions = transactions
    // without payout transactions
    .filter((tx) => ((thisAccount.direction === 'received') ? tx.quantity > 0 : tx.quantity < 0))
    .map((tx) => {
      const year = dayjs.utc(tx.date).format('YYYY');
      const principal = tx.quantity;
      const interest = tx.quantityInterest;
      return {
        ...tx,
        year,
        principal,
        interest,
      };
    });

  return (
    <div className="w-full gap-2 grid grid-cols-2">
      <div className="relative p-2 sm:p-4 w-full h-48 border border-gray-300 rounded-lg col-span-2">
        <h3 className="absolute font-bold text-base sm:text-lg mb-1 sm:mb-2 col-span-3">{t('history')}</h3>
        <ChartWrapperNoPivotTable data={adjustedTransactions} chartType="barStacked" colsField="year" rows={['principal', 'interest']} colorsForRows={['#92D050', '#ED1C23']} />
      </div>
      <PercentDone loanAmount={thisAccount.loanAmount} sumPrincipal={sumPrincipal} />
      <InterestPaid sumPrincipal={sumPrincipal} sumInterest={sumInterest} accountCurrency={accountCurrency} />
      <ToBePaid thisAccount={thisAccount} sumPrincipal={sumPrincipal} />
    </div>
  );
}
LoanIndicators.propTypes = {
  accountId: PropTypes.string.isRequired,
};
