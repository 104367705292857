import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
// eslint-disable-next-line import/no-cycle
import store from '../../../store';
import { globalBaselineView } from '../../reducers/globalSelectors';

dayjs.extend(utc);

export function generateMonthStartDates(start, end) {
  const arr = [];
  while (start <= end) {
    arr.push(dayjs.utc(start).startOf('month').startOf('day').valueOf());
    // eslint-disable-next-line no-param-reassign, newline-per-chained-call
    start = dayjs.utc(start).add(1, 'month').startOf('month').startOf('day').valueOf();
  }
  return arr;
}

/**
 * Compares two values. If both values are strings, it performs a string comparison.
 * If both values are objects, it performs a shallow comparison of their properties.
 *
 * @param {string|object} a - The first value to compare.
 * @param {string|object} b - The second value to compare.
 * @returns {boolean} - True if the values are equal, false otherwise.
 */
function compare(a, b) {
  if (typeof a === 'string' && typeof b === 'string') {
    return a === b;
  }

  if (typeof a === 'object' && a !== null && typeof b === 'object' && b !== null) {
    const aKeys = Object.keys(a);
    const bKeys = Object.keys(b);

    if (aKeys.length !== bKeys.length) {
      return false;
    }

    // eslint-disable-next-line no-restricted-syntax
    for (const key of aKeys) {
      if (a[key] !== b[key]) {
        return false;
      }
    }

    return true;
  }

  if (!a && !b) return true;

  return false;
}

/**
 * Calculates distinct assetId and dates for getQuotes based on transactions.
 *
 * @param {array} transactions (only requires date and transactionCurrency (?) )
 * @param {number} (optional) _baseline
 * @param {number} (optional) _referenceBaseline
 * @param {number} (optional) _current
 * @param {bool} (optional) onlyGetNamed (default: false)
 *
 * @returns an object with assets { assetId, providerAssetId, currency, category }
 *          and dates (baseline, referenceBaseline, current)
 *          if onlyGetNamed is false, also earliestTransactionDate and every month start date between earliestTransactionDate and current
 */
export function calculateDatesAndAssetIds(transactions, _baseline = undefined, _referenceBaseline = undefined, _current = undefined, onlyGetNamed = false) {
  // if there is no baseline, get it from the store
  let selectBaseline;
  if (!_baseline || !_referenceBaseline || !_current) {
    const state = store.getState();
    selectBaseline = globalBaselineView(state);
  } else {
    selectBaseline = { baseline: _baseline, referenceBaseline: _referenceBaseline, current: _current };
  }
  const { current, baseline, referenceBaseline } = selectBaseline;

  // find all distinct figis in all transactions and put them into array with the accompanying providerAssetIds and currencies
  let earliestTransactionDate = 9999999999999;
  const assets = [];
  transactions.forEach((transaction) => {
    if (transaction.date < earliestTransactionDate) earliestTransactionDate = transaction.date;
    const { assetId, category } = transaction;
    const providerAssetId = transaction.providerAssetId || null;
    const currency = transaction.assetCurrency || transaction.accountCurrency; // get currency in which the asset is managed or its account (if asset not available)

    // calculate all distinct combinations of assetId, providerAssetId and currency

    // if this combination of assetId, providerAssetId and currency does not existin in assets yet, add it there
    if (!assets.some((a) => a.assetId === assetId && compare(a.providerAssetId, providerAssetId) && a.currency === currency)) {
      assets.push({ assetId, providerAssetId, currency, category });
    }

    // add all required currencies to the assets array (from transactionCurrency, accountCurrency, assetCurrency and currency fields)

    if (transaction.transactionCurrency) {
      const { transactionCurrency } = transaction;
      if (!assets.some((a) => a.assetId === transactionCurrency && !a.providerAssetId && a.currency === transactionCurrency)) {
        assets.push({ assetId: transactionCurrency, providerAssetId: null, currency: transactionCurrency, category });
      }
    }
    if (transaction.accountCurrency) {
      const { accountCurrency } = transaction;
      if (!assets.some((a) => a.assetId === accountCurrency && !a.providerAssetId && a.currency === accountCurrency)) {
        assets.push({ assetId: accountCurrency, providerAssetId: null, currency: accountCurrency, category });
      }
    }
    if (transaction.assetCurrency) {
      const { assetCurrency } = transaction;
      if (!assets.some((a) => a.assetId === assetCurrency && !a.providerAssetId && a.currency === assetCurrency)) {
        assets.push({ assetId: assetCurrency, providerAssetId: null, currency: assetCurrency, category });
      }
    }
    if (transaction.currency) {
      const { currency: _currency } = transaction;
      if (!assets.some((a) => a.assetId === _currency && !a.providerAssetId && a.currency === _currency)) {
        assets.push({ assetId: _currency, providerAssetId: null, currency: _currency, category });
      }
    }
  });

  // get baseline, referenceBaseline and todayUTC
  const dates = [baseline, referenceBaseline, current];
  if (!onlyGetNamed) dates.push(...generateMonthStartDates(earliestTransactionDate, current));

  return { assets, dates };
}
