/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useForm, useFieldArray } from 'react-hook-form';
import { ArrowDownOnSquareIcon, PlusIcon, XMarkIcon } from '@heroicons/react/24/outline';
import ToolTipNoIcon from '../../elements/ToolTipNoIcon';
import { putAccount } from '../../redux/reducers/data';
import { setMessage } from '../../redux/actions/message';
import Button from '../../elements/Button';

const operations = [{ id: 'addIfEmpty', name: 'Add if empty' }];

function ConfigureColumn({ formObject, columnIndex, columns, deleteColumn }) {
  console.log('ConfigureColumn: props', formObject, columnIndex, columns);
  const { t } = useTranslation(['app'], { keyPrefix: 'accountDetails.buttons' });

  const {
    control,
    register,
    formState: { errors },
  } = formObject;
  const { fields, append, remove } = useFieldArray({ control, name: `customColumns.${columnIndex}.definition`, shouldUnregister: true });

  return (
    <section>
      <h3 className="mb-4 font-semibold">{t('columnProperties.label')}</h3>
      <div className="flex space-x-4 items-center">
        <label htmlFor="field" className="block text-sm font-small leading-6 text-gray-900 whitespace-nowrap">
          {t('columnName')}
        </label>
        <input
          name="field"
          id="field"
          // eslint-disable-next-line max-len
          className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-brandBlue-600 sm:text-sm sm:leading-6"
          // placeholder="newColumn"
          {...register(`customColumns.${columnIndex}.name`, { required: true })}
        />
      </div>
      <div>{errors.columnName && <span className="text-red-500">This field is required</span>}</div>
      <div className="mt-6">
        <h3 className="mb-4 font-semibold">Calculation</h3>
        <div className="grid grid-cols-3 gap-2">
          {fields.map((field, index) => (
            <>
              {index !== 0 && (
                <select
                  key={`${field.id}-operationId`}
                  id={`${field.id}-operationId`}
                  {...register(`customColumns.${columnIndex}.definition.${index}.operationId`)}
                  // eslint-disable-next-line max-len
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-brandBlue-600 sm:text-sm sm:leading-6"
                >
                  {operations.map((op) => (
                    <option key={op.id} value={op.id}>
                      {op.name}
                    </option>
                  ))}
                </select>
              )}
              <select
                key={`${field.id}-columnId`}
                id={`${field.id}-columnId`}
                {...register(`customColumns.${columnIndex}.definition.${index}.columnIndex`)}
                // eslint-disable-next-line max-len
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-brandBlue-600 sm:text-sm sm:leading-6"
              >
                {/* definitions object requires columnIndex as value, user must see column name */}
                {columns
                  .filter((col) => !col.isCustomColumn)
                  .map((column, idx) => (
                    <option key={column.id} value={idx}>
                      {column.name}
                    </option>
                  ))}
              </select>
              {index === 0 && <div />}
              {index < 2 ? (
                <div />
              ) : (
                <button
                  type="button"
                  onClick={() => remove(index)}
                  // eslint-disable-next-line max-len
                  className="flex justify-self-start justify-center items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brandBlue-400"
                >
                  <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              )}
            </>
          ))}
          <Button text={t('addAnotherOperation.label')} onClick={() => append({ operation: null, columnIndex: 0 })} size="md" formatting="mt-4" />
        </div>
      </div>
      <h3 className="mt-8 mb-4 font-semibold">{t('columnActions.label')}</h3>
      <Button text={t('deleteThisColumn.label')} onClick={(e) => deleteColumn(columnIndex)} size="md" formatting="mt-4" />
    </section>
  );
}
ConfigureColumn.propTypes = {
  formObject: PropTypes.objectOf(PropTypes.any).isRequired,
  columnIndex: PropTypes.number.isRequired,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  deleteColumn: PropTypes.func.isRequired,
};

// 'columns' are all file columns in format { id, name, isCustomColumn }
export default function CombinePane({ setCombinePane, account, columns }) {
  const { t } = useTranslation(['app'], { keyPrefix: 'accountDetails.buttons' });

  const formObject = useForm();

  const {
    control,
    formState: { errors, isDirty },
    handleSubmit,
    reset,
    getValues,
  } = formObject;

  const { append, remove, fields } = useFieldArray({ control, name: 'customColumns' });

  const [selectedColumnIndex, setSelectedColumnIndex] = useState(null);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    // defaultValues resets after every render, so using this instead
    if (account.importFileMappings?.customColumns && account.importFileMappings?.customColumns.length > 0) {
      // if there are any customColumns already, add them to formState
      account.importFileMappings?.customColumns.forEach((customColumn) => {
        append(customColumn);
      });
      setSelectedColumnIndex(0);
    } else {
      // if there are none, add a default column
      append({
        name: 'new column',
        definition: [
          { operationId: null, columnIndex: 0 },
          { operationId: 'addIfEmpty', columnIndex: 1 },
        ],
      });
      setSelectedColumnIndex(0);
    }
    return () => {
      console.log('removing all fields');
      remove();
    };
  }, []);

  async function handleSave() {
    setLoading(true);
    try {
      const result = await dispatch(
        putAccount({
          data: {
            ...account,
            importFileMappings: {
              ...account.importFileMappings,
              customColumns: formObject.getValues().customColumns, // CHANGE THIS
            },
          },
          category: account.category,
        }),
      );
      console.log(result);
      if (result.meta.requestStatus === 'rejected') throw new Error('Could not update account');
    } catch (error) {
      dispatch(setMessage('accountCouldNotBeUpdated'));
    }
    setLoading(false);
    setCombinePane(false);
  }

  function handleTabClick(e) {
    e.preventDefault();
    setSelectedColumnIndex(e.target.value);
  }

  function handleNewTab() {
    append({
      name: 'new column',
      definition: [
        { operationId: null, columnIndex: 0 },
        { operationId: 'addIfEmpty', columnIndex: 1 },
      ],
    });
    setSelectedColumnIndex(getValues().customColumns.length - 1);
  }

  function handleDeleteColumn(colIndex) {
    remove(colIndex);
    // de-render the category component if there are no columns left (if it is rendered, it will put empty default values in formState)
    if (getValues().customColumns.length === 0) setSelectedColumnIndex(null);
    else setSelectedColumnIndex(0);
  }

  function handleClose() {
    if (isDirty) {
      const event = new CustomEvent('setAlert', {
        detail: {
          id: 'closeAccountDetails',
          caller: 'CombinePane',
          callbackOk: () => {
            setCombinePane(false);
            reset();
          },
        },
      });
      window.dispatchEvent(event);
    } else {
      setCombinePane(false);
    }
  }

  return (
    <>
      <section className="ml-1 flex flex-rows gap-2 sm:gap-4">
        <ToolTipNoIcon info={t('close.tooltip')}>
          <Button text={t('close.label')} Icon={XMarkIcon} onClick={handleClose} size="lg" />
        </ToolTipNoIcon>
        <ToolTipNoIcon info={t('saveAndClose.tooltip')}>
          <Button text={t('saveAndClose.label')} Icon={ArrowDownOnSquareIcon} onClick={handleSubmit(handleSave)} spinnerOn={loading} enabled={!errors.columnName} withAccent size="lg" />
        </ToolTipNoIcon>
      </section>
      <div>
        <div className="sm:hidden">
          <label htmlFor="tabs" className="sr-only">
            Select a tab
          </label>
          <select
            id="tabs"
            name="tabs"
            className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
            defaultValue={fields[0]?.id || null}
            onChange={handleTabClick}
          >
            {fields.map((column, index) => (
              <option key={column.id} value={index}>
                {column.name}
              </option>
            ))}
          </select>
        </div>
        <div className="hidden sm:block">
          <div className="border-b border-gray-200">
            <nav className="-mb-px flex space-x-8" aria-label="Tabs">
              {fields.map((field, index) => (
                <button
                  key={field.name}
                  id={field.id} // automatically generated by react-hook-form
                  type="button"
                  onClick={() => handleTabClick({ target: { value: index } })}
                  className={`${index === selectedColumnIndex ? 'border-brandBlue-500 text-brandBlue-600' : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'}
                  whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium`}
                  aria-current={field.current ? 'page' : undefined}
                >
                  {field.name}
                </button>
              ))}
              <button
                key="addButton"
                id="addButton"
                type="button"
                onClick={handleNewTab}
                className="border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium flex space-x-2"
              >
                <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                {t('newColumn.label')}
              </button>
            </nav>
          </div>
        </div>
      </div>
      {(selectedColumnIndex || selectedColumnIndex === 0) && <ConfigureColumn formObject={formObject} columnIndex={selectedColumnIndex} columns={columns} deleteColumn={handleDeleteColumn} />}
    </>
  );
}
CombinePane.propTypes = {
  setCombinePane: PropTypes.func.isRequired,
  account: PropTypes.objectOf(PropTypes.any).isRequired,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
};
