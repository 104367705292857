/* eslint-disable no-underscore-dangle */
/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { useSelector } from 'react-redux';
import { Controller, useForm, useWatch } from 'react-hook-form';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { InformationCircleIcon } from '@heroicons/react/20/solid';
import { realEstateAccount } from '@monestry-dev/schema';
import ToolTip from '../../../elements/ToolTip';
import { currencyCodes } from '../../../misc/currencyCodes';
import { countryCodes, getCountryName } from '../../../misc/countries';
import Dropdown from './Dropdown'; // import wrapper for the common Dropdown component
import InputField from './InputField';
import SwitchComponent from './SwitchComponent';

dayjs.extend(utc);

export function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

// expects an array of accounts which have a nextValuationDate as well as an array of all valuationSlots from Cognito
// returns next available valuation date (can be today if it is unused), after considering the accounts which already have a nextValuationDate
export function getNextAvailableValuationDate(accountsWithValuationSlots, valuationSlots) {
  // what is the next available valuation date? it is the date from valuationSlots which is not used in accountsWithValuationSlots.tags.nextValuationSlot
  // for each accountsWithValuationSlots.tags.nextValuationDate remove ONE corresponding item from valuationSlots
  const assignedSlots = accountsWithValuationSlots.map((x) => x.tags?.nextValuationDate).filter((x) => !!x); // remove undefined from the array (accounts with no nextValuationDate)

  const availableSlots = [...valuationSlots];

  assignedSlots.forEach((assignedSlot) => {
    const toBeRemoved = availableSlots.indexOf(assignedSlot);
    availableSlots.splice(toBeRemoved, 1);
  });

  const availableSlotsWithNoNull = availableSlots.map((x) => x || dayjs.utc().startOf('day').valueOf());

  // sort the array by date ascending and get the first element
  const nextValuationDate = availableSlotsWithNoNull.sort((a, b) => a - b)[0];
  return nextValuationDate;
}

function RealEstateAccountData({ index, updateWorkflowStatus, userInput, setUserInput, signalNext, setSignalNext, messageToShowBeforeNext, setMessageToShowBeforeNext }) {
  // register the hook
  const { register, watch, reset, getValues, control, formState, setValue, trigger } = useForm({ mode: 'onChange', defaultValues: userInput && userInput.data });
  const { errors, isValid, isDirty } = formState;

  const [displayUpdateValueSwitch, setDisplayUpdateValueSwitch] = useState(false);
  const [valuationSlots, setValuationSlots] = useState([]);
  const [subtypeList, setSubtypeList] = useState([{ id: null, name: null }]);

  const { t } = useTranslation();
  const { t: tp } = useTranslation(['app'], { keyPrefix: 'schema.realEstate.account.valuationParameters' });

  const accounts = useSelector((state) => state.data.realEstate.accounts);
  const accountsWithValuationSlots = accounts.filter((x) => x.tags.nextValuationDate);

  // get a list of valuationParamteres fields from schema that we want to display
  const valuationParametersSchemaObject = realEstateAccount.fields.valuationParameters.fields;
  // ↑↑ object of { field1: { type: 'string', _whitelist: ... }, field2: { type: 'number', _whitelist: ... }, ... }
  // _whitelist contains the oneOf members; its a ReferenceSet class instance; size property is the number of members
  const valuationParameters = Object.keys(valuationParametersSchemaObject);
  // console.log(valuationParametersSchemaObject);

  //--------------------------------------------------------------------------------
  // synchronise this component's form with the parent component's form state
  //--------------------------------------------------------------------------------

  function syncParentForm() {
    // check if all validations are passed and if so, set component status to true
    if (isDirty && isValid) {
      // console.log('isDirty and isValid both true');
      const input = getValues();
      setUserInput({ ...userInput, ...input });
      updateWorkflowStatus(index, true);
      // reset isDirty status so that we know if the user touched the form again
      reset({ ...userInput, ...input }, { keepIsValid: true });
    }
    // check if the user invalidated the form and if so, remove the next step
    if (isDirty && !isValid) {
      const input = getValues();
      // CAVEAT: this approach means that the previously entered data is still potentially within the form
      setUserInput({ ...userInput, ...input });
      updateWorkflowStatus(index, false);
      // reset isDirty status so that we know if the user touched the form again
      reset({ ...userInput, ...input }, { keepErrors: true });
    }
  }

  useEffect(() => {
    // if the form has tags.nextValuationDate, display a dialog asking the user to be sure they provided all the info
    if (isDirty && isValid && getValues('tags.nextValuationDate') && !messageToShowBeforeNext) {
      syncParentForm();
      setMessageToShowBeforeNext('aboutToReceiveRealEstateValuation');
    } else syncParentForm();
  }, [formState]);

  //--------------------------------------------------------------------------------
  // subscribe to signal from parent that the user has clicked the "next" button
  // (and then trigger all validations to show errors)
  //--------------------------------------------------------------------------------

  useEffect(() => {
    if (signalNext) {
      trigger();
      setSignalNext(false);
    }
  }, [signalNext]);

  //--------------------------------------------------------------------------------
  // get valuationSlots from Cognito on initialisation
  //--------------------------------------------------------------------------------

  useEffect(() => {
    Auth.currentAuthenticatedUser({ bypassCache: true })
      .then((user) => {
        const result = JSON.parse(user.attributes['custom:valuation_slots'] || '[]');
        // console.log('result', result);
        setValuationSlots(result);
      })
      .catch((err) => console.error(err));
  }, []);

  //--------------------------------------------------------------------------------
  // when accounts / valuationSlots update,
  // decide if to show the automatic update switch
  //--------------------------------------------------------------------------------

  useEffect(() => {
    if (accountsWithValuationSlots.length < valuationSlots.length) {
      setValue('tags.nextValuationDate', getNextAvailableValuationDate(accountsWithValuationSlots, valuationSlots));
      setDisplayUpdateValueSwitch(true);
    } else setDisplayUpdateValueSwitch(false);
  }, [accounts, valuationSlots]);

  const type = watch('valuationParameters.type');

  //--------------------------------------------------------------------------------
  // handle subtype -- when type changes, reset subtype
  //--------------------------------------------------------------------------------

  useEffect(() => {
    // console.log('useEffect type changed');
    if (getValues('valuationParameters.type') === 'building') {
      setSubtypeList(Object.entries(t('app:schema.realEstate.account.parameters.subtype.building', { returnObjects: true })).map(([key, value]) => ({ id: key, name: value })));
    }
    if (getValues('valuationParameters.type') === 'apartment') {
      setSubtypeList(Object.entries(t('app:schema.realEstate.account.parameters.subtype.apartment', { returnObjects: true })).map(([key, value]) => ({ id: key, name: value })));
    }
  }, [type]);

  function handleNextValuationDateChange(newFieldValue) {
    // newFieldValue is the new boolean field value
    // when switch is flipped and the argument is TRUE, we need to set the nextValuationDate to the next available date
    if (newFieldValue) {
      setValue('tags.nextValuationDate', getNextAvailableValuationDate(accountsWithValuationSlots, valuationSlots));
    } else {
      setValue('tags.nextValuationDate', undefined);
    }
  }

  //--------------------------------------------------------------------------------
  // render the component
  //--------------------------------------------------------------------------------

  return (
    <div className="space-y-6 md:space-y-0 md:grid md:grid-cols-4 md:gap-6">
      <div className="md:col-span-1">
        <h3 className="text-xl md:text-2xl font-bold leading-6 text-gray-900">{t('app:addAccount.R1.accountInfo')}</h3>
        <p className="mt-4 text-sm text-gray-500">{t('app:addAccount.R1.provideInfo')}</p>
        <p className="mt-1 text-sm text-gray-500">{t('app:addAccount.R1.oneAccount')}</p>
        <p className="mt-8 text-sm text-gray-500 font-bold">{t('app:addAccount.R1.whyDoWeAsk')}</p>
        <p className="mt-2 text-sm text-gray-500">{t('app:addAccount.R1.whyDoWeAskAnswer')}</p>
        <p className="mt-8 text-sm text-gray-500 font-bold">{t('app:addAccount.R1.doIHaveToFillThemAllOut')}</p>
        <p className="mt-2 text-sm text-gray-500">{t('app:addAccount.R1.doIHaveToFillThemAllOutAnswer')}</p>
      </div>

      <form className="md:col-span-3 ">
        <div className="divide-y divide-gray-200">
          <div className="py-3 lg:pb-4 grid xs:grid-cols-2 lg:grid-cols-3 gap-4 xs:gap-6 lg:gap-8">
            <InputField
              id="name"
              type="text"
              autoComplete="off"
              optional={false}
              register={register}
              errors={errors}
              validationRules={{ required: { value: true, message: t('app:addAccount.R1.name.error') } }}
            />
            <div className="hidden md:col-span-2" />
            <InputField id="date" type="date" autoComplete="off" register={register} errors={errors} />
            <InputField
              id="originalPrice"
              type="text"
              autoComplete="off"
              register={register}
              errors={errors}
              validationRules={{
                number: { value: true, message: t('app:addAccount.R1.originalPrice.error') },
              }}
            />
            <div>
              <div className="flex space-x-1">
                <label htmlFor="currency" className="block text-sm font-medium text-gray-700">
                  {t('app:addAccount.R1.currency.label')}
                </label>
                <ToolTip info={t('app:addAccount.R1.currency.tooltip')} />
              </div>
              <select
                id="currency"
                name="currency"
                onBlur={(e) => {
                  setUserInput({ ...userInput, ...getValues() });
                }}
                {...register('currency')}
                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-brandBlue-400 focus:border-brandBlue-400 sm:text-sm rounded-md"
                defaultValue="EUR"
              >
                {currencyCodes.map((curr) => (
                  <option key={curr} value={curr}>
                    {curr}
                  </option>
                ))}
              </select>
            </div>
            {displayUpdateValueSwitch ? (
              <SwitchComponent
                onChange={handleNextValuationDateChange}
                onBlur={(e) => {
                  setUserInput({ ...userInput, ...getValues() });
                }}
                label={t('app:addAccount.R1.automaticUpdate.label')}
                tooltip={t('app:addAccount.R1.automaticUpdate.tooltip')}
                optional={false}
                value={watch('tags.nextValuationDate') !== undefined}
              />
            ) : (
              <div className="flex items-center space-x-2">
                <InformationCircleIcon className="h-5 w-5 text-brandBlue-400" aria-hidden="true" />
                <span className="text-sm font-medium text-gray-500">{t('app:addAccount.R1.automaticUpdate.disabled')}</span>
              </div>
            )}
          </div>

          {/* VALUATION PARAMETERS */}

          <div className="py-3 lg:py-8 grid xs:grid-cols-2 lg:grid-cols-3 gap-4 xs:gap-6 lg:gap-8">
            {valuationParameters
              .sort((a, b) => {
                // put all the required fields first
                if (valuationParametersSchemaObject[a].spec.optional === valuationParametersSchemaObject[b].spec.optional) return 0;
                if (valuationParametersSchemaObject[a].spec.optional === true) return 1;
                return -1;
              })
              .map((param) => {
                const isStringOrNumber = ['number', 'string'].includes(valuationParametersSchemaObject[param].type);
                const hasOneOf = valuationParametersSchemaObject[param]._whitelist.size > 0 || param === 'subtype';
                const isOptional = valuationParametersSchemaObject[param].spec.optional;
                const validationRules = isOptional === true ? {} : { validationRules: { required: { value: true, message: tp(`${param}.error`) } } };

                // STRING

                if (isStringOrNumber && !hasOneOf) {
                  return (
                    <InputField
                      key={param}
                      id={`valuationParameters.${param}`}
                      type={valuationParametersSchemaObject[param].type}
                      label={tp(`${param}.label`)}
                      placeholder={tp(`${param}.placeholder`)}
                      toolTip={tp(`${param}.tooltip`)}
                      register={register}
                      errors={errors}
                      optional={isOptional}
                      {...validationRules}
                    />
                  );
                }

                // DROPDOWN LIST

                if (isStringOrNumber && hasOneOf) {
                  // for energyClass there are no transactions, so we can just pass the whitelist
                  let list = [];
                  // ↓↓ easier to handle in { id, name } format in Dropdown
                  if (param === 'energyClass') {
                    list = [...valuationParametersSchemaObject[param]._whitelist].map((x) => ({ id: x, name: x }));
                  }
                  if (param === 'countryCode') {
                    list = countryCodes.map((x) => ({ id: x, name: t([`countryNames.${getCountryName(x)}`, getCountryName(x)]) }));
                  }
                  if (param === 'subtype') {
                    list = subtypeList;
                  }
                  return (
                    <Dropdown
                      id={`valuationParameters.${param}`}
                      list={list}
                      type={valuationParametersSchemaObject[param].type}
                      label={tp(`${param}.label`)}
                      placeholder={tp(`${param}.placeholder`)}
                      toolTip={tp(`${param}.tooltip`)}
                      register={register}
                      errors={errors}
                      control={control}
                      optional={isOptional}
                    />
                  );
                }

                // BOOLEAN

                if (valuationParametersSchemaObject[param].type === 'boolean') {
                  return (
                    <Controller
                      name={`valuationParameters.${param}`}
                      control={control}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <SwitchComponent
                          id={`valuationParameters.${param}`}
                          onChange={onChange}
                          onBlur={onBlur}
                          value={value}
                          inputRef={ref}
                          label={tp(`${param}.label`)}
                          toolTip={tp(`${param}.tooltip`)}
                          optional={isOptional}
                        />
                      )}
                    />
                  );
                }
                return true;
              })}
          </div>
        </div>
      </form>
    </div>
  );
}

RealEstateAccountData.propTypes = {
  index: PropTypes.number.isRequired,
  updateWorkflowStatus: PropTypes.func.isRequired,
  userInput: PropTypes.object,
  setUserInput: PropTypes.func,
  signalNext: PropTypes.bool,
  setSignalNext: PropTypes.func,
  messageToShowBeforeNext: PropTypes.string,
  setMessageToShowBeforeNext: PropTypes.func,
};
RealEstateAccountData.defaultProps = {
  userInput: {},
  setUserInput: {},
  signalNext: undefined,
  setSignalNext: () => {},
  messageToShowBeforeNext: '',
  setMessageToShowBeforeNext: () => {},
};

export default RealEstateAccountData;
