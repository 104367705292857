export const controlData = [
  {
    id: 'berkus',
    appliesTo: ['idea', 'prototype'],
    questions: ['ideaStrength', 'prototypeProgress', 'teamCapability', 'marketingRelationships', 'marketTraction', 'competitiveAdvantage'],
  },
  {
    id: 'scorecard',
    appliesTo: ['idea', 'prototype'],
    questions: [
      'similarStartupValuationsArray',
      'prototypeProgress',
      'technologyRisk',
      'ipStrength',
      'teamCapability',
      'marketingRelationships',
      'marketTraction',
      'totalAdressableMarketSize',
      'marketGrowthRate',
      'marketEntryBarriers',
      'competitiveAdvantage',
      'needForMoreInvestment',
    ],
  },
  {
    id: 'riskFactor',
    appliesTo: ['idea', 'prototype'],
    questions: [
      'similarStartupValuationsArray',
      'prototypeProgress',
      'technologyRisk',
      'teamCapability',
      'marketTraction',
      'competitiveAdvantage',
      'needForMoreInvestment',
      'legalRisk',
      'internationalRisk',
    ],
  },
  {
    id: 'valuationByStage',
    appliesTo: ['idea', 'prototype', 'mvp', 'expansion'],
    questions: [
      'similarStartupValuationsArray',
      'ideaStrength',
      'prototypeProgress',
      'technologyRisk',
      'ipStrength',
      'teamCapability',
      'marketingRelationships',
      'marketTraction',
      'totalAdressableMarketSize',
      'marketGrowthRate',
      'marketRisk',
      'marketEntryBarriers',
      'competitiveAdvantage',
      'financialPerformance',
      'needForMoreInvestment',
      'legalRisk',
    ],
  },
  {
    id: 'replacementCost',
    appliesTo: ['idea', 'prototype', 'mvp', 'expansion', 'market'],
    questions: ['assetsArray', 'intangiblesArray'],
  },
  {
    id: 'bookValue',
    appliesTo: ['idea', 'prototype', 'mvp', 'expansion', 'market'],
    questions: ['assetsArray', 'liabilitiesArray'],
  },
  {
    id: 'fiveTimesYourRise',
    appliesTo: ['idea', 'prototype', 'mvp', 'expansion', 'market'],
    questions: ['targetRaisedCapital'],
  },
  {
    id: 'dcf',
    appliesTo: ['mvp', 'expansion', 'market'],
    questions: ['cashFlowArray', 'discountRate', 'longTermGrowthRate'],
  },
];

export const questions = [
  { id: 'ideaStrength', type: 'score' },
  { id: 'prototypeProgress', type: 'score' },
  { id: 'teamCapability', type: 'score' },
  { id: 'marketingRelationships', type: 'score' },
  { id: 'marketTraction', type: 'score' },
  { id: 'competitiveAdvantage', type: 'score' },
  { id: 'similarStartupValuationsArray', type: 'array' },
  { id: 'technologyRisk', type: 'score' },
  { id: 'ipStrength', type: 'score' },
  { id: 'totalAdressableMarketSize', type: 'score' },
  { id: 'marketGrowthRate', type: 'score' },
  { id: 'marketEntryBarriers', type: 'score' },
  { id: 'needForMoreInvestment', type: 'score' },
  { id: 'legalRisk', type: 'score' },
  { id: 'internationalRisk', type: 'score' },
  { id: 'marketRisk', type: 'score' },
  { id: 'financialPerformance', type: 'score' },
  { id: 'assetsArray', type: 'array' },
  { id: 'intangiblesArray', type: 'array' },
  { id: 'liabilitiesArray', type: 'array' },
  { id: 'targetRaisedCapital', type: 'number' },
  { id: 'cashFlowArray', type: 'array' },
  { id: 'discountRate', type: 'number' },
  { id: 'longTermGrowthRate', type: 'number' },
];

function n(score) {
  return (score + 2) / 4;
}

function calculateBerkus(company) {
  const ideaScore = (n(company.ideaStrength) || 0) * 500000;
  const prototypeProgressScore = (n(company.prototypeProgress) || 0) * 500000;
  const teamCapabilityScore = (n(company.teamCapability) || 0) * 500000;
  const relationshipsScore = (n(company.marketingRelationships) || 0) * 500000;
  const marketTractionScore = (n(company.marketTraction) || 0) * 500000;

  return ideaScore + prototypeProgressScore + teamCapabilityScore + relationshipsScore + marketTractionScore;
}

function calculateReplacementCost(company) {
  // Needs additional logic to handle replacementCostAssets array
  if (!company.assetsArray || !company.assetsArray.length) {
    return null; // No assets provided
  }

  const totalReplacementCost = company.assetsArray.reduce((acc, asset) => acc + (asset?.value || 0), 0);

  return totalReplacementCost;
}

function calculateValuationByStageMethod(company) {
  const { stage } = company;
  const stageRanges = {
    idea: { min: 250000, max: 500000 },
    prototype: { min: 500000, max: 1000000 },
    mvp: { min: 1000000, max: 2000000 },
    expansion: { min: 2000000, max: 5000000 },
  };

  if (!stageRanges[stage]) {
    return null; // Invalid stage provided
  }

  // Exclude similarStartupValuationsArray from the questions
  const relevantQuestions = controlData.find((method) => method.id === 'valuationByStage').questions.filter((q) => q !== 'similarStartupValuationsArray');

  const avgScore = relevantQuestions.reduce((acc, questionId) => {
    const score = company[questionId] || 0;
    return acc + score;
  }, 0) / relevantQuestions.length;

  if (avgScore === -2) {
    return stageRanges[stage].min;
  }
  if (avgScore === 2) {
    return stageRanges[stage].max;
  }
  // return the middle of the range with adjustment for avgScore, rounded to 4 digits
  const adjustedValue = stageRanges[stage].min + (avgScore * (stageRanges[stage].max - stageRanges[stage].min)) / 2;
  return Math.round(adjustedValue * 10000) / 10000;
}

function calculateScorecardMethod(company) {
  const factors = [
    { id: 'teamCapability', weight: 0.3 },
    { id: 'marketTraction', weight: 0.1 },
    { id: 'totalAdressableMarketSize', weight: 0.1 },
    { id: 'marketGrowthRate', weight: 0.05 },
    { id: 'prototypeProgress', weight: 0.1 },
    { id: 'technologyRisk', weight: 0.05 },
    { id: 'marketEntryBarriers', weight: 0.05 },
    { id: 'competitiveAdvantage', weight: 0.05 },
    { id: 'marketingRelationships', weight: 0.1 },
    { id: 'needForMoreInvestment', weight: 0.05 },
    { id: 'ipStrength', weight: 0.05 },
  ];

  // add score to factors based on user input
  const factorScores = factors.map((factor) => ({ ...factor, score: company[factor.id] || 0 }));

  // calculate weighted score
  const weightedScore = factorScores.reduce((acc, factorScore) => acc + factorScore.score * factors.find((f) => f.name === factorScore.name).weight, 0);

  // calculate average pre-money valuation from similar startups
  const { similarStartupValuationsArray } = company;
  if (!similarStartupValuationsArray || !similarStartupValuationsArray.length) return NaN;

  const averagePreMoneyValuation = similarStartupValuationsArray.reduce((acc, startup) => acc + (startup?.value || 0), 0) / similarStartupValuationsArray.length;

  // apply adjustments based on weighted score
  const minPercentage = 0.25;
  const maxPercentage = 1.75;

  // Normalize weighted score to a range of 0 to 1, where -2 maps to 0 and 2 maps to 1
  const normalizedScore = (weightedScore + 2) / 4;

  // Interpolate to find the valuation percentage
  const valuationPercentage = minPercentage + normalizedScore * (maxPercentage - minPercentage);

  // Calculate the final valuation
  const finalValuation = averagePreMoneyValuation * valuationPercentage;

  return finalValuation;
}

function calculateRiskFactor(company) {
  const riskFactors = controlData.find((method) => method.id === 'riskFactor').questions.filter((q) => q !== 'similarStartupValuationsArray');

  // Calculate risk score for each factor
  const riskScores = riskFactors.map((factor) => company[factor] || 0);

  // Calculate average risk score
  const totalRiskScore = riskScores.reduce((acc, score) => acc + score, 0);
  const averageRiskScore = totalRiskScore / riskFactors.length;

  // Get average pre-money valuation from similar startups
  const { similarStartupValuationsArray } = company;
  if (!similarStartupValuationsArray || !similarStartupValuationsArray.length) return NaN;

  const averagePreMoneyValuation = similarStartupValuationsArray.reduce((acc, startup) => acc + (startup?.value || 0), 0) / similarStartupValuationsArray.length;

  // Normalize average risk score to range of -2 to 2
  const normalizedRiskScore = Math.max(-2, Math.min(2, averageRiskScore));

  // Define the range for valuation adjustment
  const minPercentage = 0.25;
  const maxPercentage = 1.75;

  // Normalize average score to a range of 0 to 1, where -2 maps to 0 and 2 maps to 1
  const normalizedScore = (normalizedRiskScore + 2) / 4;

  // Interpolate to find the valuation percentage
  const valuationPercentage = minPercentage + normalizedScore * (maxPercentage - minPercentage);

  // Calculate the final valuation
  const finalValuation = averagePreMoneyValuation * valuationPercentage;

  return finalValuation;
}

function calculateFiveTimes(company) {
  const { targetRaisedCapital } = company;
  if (!targetRaisedCapital) return NaN;

  return targetRaisedCapital * 5;
}

function calculateBookValue(company) {
  const { assetsArray, liabilitiesArray } = company;
  if (!assetsArray || !assetsArray.length) return NaN;
  if (!liabilitiesArray || !liabilitiesArray.length) return NaN;

  const totalAssets = assetsArray.reduce((acc, asset) => acc + (asset?.value || 0), 0);
  const totalLiabilities = liabilitiesArray.reduce((acc, liability) => acc + (liability?.value || 0), 0);

  return totalAssets - totalLiabilities;
}

function calculateDCF(company) {
  const { cashFlowArray, discountRate, longTermGrowthRate } = company;
  if (!cashFlowArray || !cashFlowArray.length) return NaN;
  if (!discountRate) return NaN;
  if (!longTermGrowthRate) return NaN; // Ensure the long-term growth rate is provided
  if (longTermGrowthRate >= discountRate) return NaN; // Ensure the long-term growth rate is less than the discount rate

  // Convert percentage rates to fractions
  const discountRateFraction = discountRate / 100;
  const longTermGrowthRateFraction = longTermGrowthRate / 100;

  // Sort cashFlowArray by name in ascending order
  const sortedCashFlowArray = [...cashFlowArray].sort((a, b) => a.name.localeCompare(b.name));

  // Calculate Present Value of each cash flow
  const discountedCashFlows = sortedCashFlowArray.map((cashFlow, year) => (cashFlow?.value || 0) / (1 + discountRateFraction) ** (year + 1));

  // Calculate Terminal Value using Perpetuity Growth Model with long-term growth rate
  const lastYearCashFlow = sortedCashFlowArray[sortedCashFlowArray.length - 1].value;
  const terminalValue = (lastYearCashFlow * (1 + longTermGrowthRateFraction)) / (discountRateFraction - longTermGrowthRateFraction);

  const terminalValueDiscounted = terminalValue / (1 + discountRateFraction) ** sortedCashFlowArray.length;

  // Sum the Present Values of projected cash flows and the discounted terminal value
  const totalValuation = discountedCashFlows.reduce((acc, discountedCashFlow) => acc + discountedCashFlow, 0) + terminalValueDiscounted;

  return totalValuation;
}

export function calculateValue(methodId, fieldValues) {
  // Find the valuation method with the given id
  const method = controlData.find((m) => m.id === methodId);

  if (!method) {
    console.error(`No valuation method found with id: ${methodId}`);
  }

  switch (methodId) {
    case 'berkus':
      return calculateBerkus(fieldValues);
    case 'replacementCost':
      return calculateReplacementCost(fieldValues);
    case 'valuationByStage':
      return calculateValuationByStageMethod(fieldValues);
    case 'scorecard':
      return calculateScorecardMethod(fieldValues);
    case 'riskFactor':
      return calculateRiskFactor(fieldValues);
    case 'bookValue':
      return calculateBookValue(fieldValues);
    case 'fiveTimesYourRise':
      return calculateFiveTimes(fieldValues);
    case 'dcf':
      return calculateDCF(fieldValues);
    default:
      return NaN;
  }
}

export function getMissingFields(methodId, fieldValues) {
  const method = controlData.find((m) => m.id === methodId);
  if (!method) {
    console.error(`No valuation method found with id: ${methodId}`);
  }

  // leave only questions which have null / undefined / '' or length === 0 or all values are empty (when adding a line it first adds undefined to array as an item, that must be captured)
  const missingFields = method.questions.filter(
    (q) => fieldValues[q] === null
      || fieldValues[q] === undefined
      || fieldValues[q] === ''
      || (Array.isArray(fieldValues[q]) && fieldValues[q].length === 0)
      || (Array.isArray(fieldValues[q]) && fieldValues[q].every((x) => !x || !x.value)),
  );

  return missingFields;
}
