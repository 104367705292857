// instructions: leave 1 account in accountName filter and 12 most recent months with data in date filter
const quotesByAssetId = {
  vals: [
    'quoteBaseCurrency',
  ],
  cols: ['date'],
  dataScope: 'quotes',
  rows: [
    'displayName',
  ],
  rendererName: 'Line chart, horizontal',
  rendererType: 'nivo', // 'nivo' requires a height property to be set on its parent to be displayed
  id: 'quotesByAssetIdxxxxxx',
  name: 'Quotes, by asset',
  aggregatorName: 'Sum',
  readOnly: true,
};

export default quotesByAssetId;
