import React from 'react';

const steps = [
  {
    type: 'intro',
    id: 'ADT-intro-2',
    steps: [
      {
        position: 'center',
        content: () => (
          <p>
            For real estate we need to keep track of two kinds of information:
            {' '}
            <strong>purchase &amp; sale transactions</strong>
            {' '}
            and
            {' '}
            <strong>changes in the property value</strong>
            .
          </p>
        ),
      },
      {
        selector: '#button-group-two-views',
        content: () => (
          <p>
            Use
            {' '}
            <strong>these buttons</strong>
            {' '}
            to switch back and forth between the two modes. There is a different spreadsheet view for each mode.
          </p>
        ),
      },
    ],
  },
];

export default steps;
