/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { PencilIcon } from '@heroicons/react/24/outline';
import { PlusIcon, XMarkIcon } from '@heroicons/react/20/solid';
import Button from '../../elements/Button';
import Dropdown from '../../elements/Dropdown';

dayjs.extend(utc);

/**
 * This component specifically handles the attribute form for special / initial quotes.
 *
 * @param {object} formValues: expects array of { type, quote, quoteBaseCurrency }
 * @param {string} header: title of the form (translated)
 * @param {string} prompt: description of the form (translated)
 * @param {function} callback: function to call when the user clicks 'Save'
 *
 * @returns React component
 */
export default function AttributeArrayOfObjectsFormQuote({ formValues, header, prompt, callback }) {
  const { t } = useTranslation('app', { keyPrefix: 'accountDetails' });
  const { t: tSchema } = useTranslation('app', { keyPrefix: 'schema.pension.account.parameters' });
  const baseCurrency = useSelector((state) => state.user.profile.settings.baseCurrency);

  const [loading, setLoading] = React.useState(false);

  const { register, control, handleSubmit, formState: { errors }, setError, clearErrors } = useForm({
    defaultValues: { // useFieldArray doesn't work with array at root level of form
      table: formValues,
    },
  });
  const { fields, append, remove } = useFieldArray({
    name: 'table',
    control,
  });

  // TODO convert quote and quoteBaseCurrency from text to number before submitting
  async function onSubmit(data) {
    setLoading(true);
    try {
      // if there are data in form state or there were data as input (handle delete)
      if (data.table.length > 0 || formValues.length > 0) {
        const result = data.table.map((item) => ({ type: item.type.id, quote: Number(item.quote), quoteBaseCurrency: Number(item.quoteBaseCurrency) }));
        await callback(result);
      }
      setLoading(false);
      window.dispatchEvent(new CustomEvent('setDialog'));
    } catch (error) {
      setLoading(false);
      console.error('AttributeArrayOfObjectsForm error:', error);
    }
  }

  function onCancel() {
    window.dispatchEvent(new CustomEvent('setDialog'));
  }

  const types = tSchema('useSpecialQuote', { returnObjects: true });
  const valueDropdown = Object.entries(types).map(([key, value]) => ({ id: key, name: value }));

  return (
    <div className="mx-auto max-w-xl transform overflow-hidden rounded-lg bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all p-6">
      <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-brandBlue-100">
        <PencilIcon className="h-6 w-6 text-brandBlue-500" aria-hidden="true" />
      </div>

      <div className="mt-3 text-center sm:mt-5">
        <h3 className="text-lg leading-6 font-bold text-gray-900">
          {header}
        </h3>
        <p className="mt-2 text-sm text-gray-500">{prompt}</p>
        <div className="mt-4 grid grid-cols-12 gap-3 text-sm xs:text-base items-start">
          <span className="col-span-7 ml-1 -mb-1 text-sm text-left">{t('estimateType')}</span>
          <span className="col-span-2 ml-1 -mb-1 text-sm text-left">{t('quote')}</span>
          <span className="col-span-2 ml-1 -mb-1 text-sm text-left">{t('quoteInCurrency', { currency: baseCurrency })}</span>
          <div />
          {fields.map((item, index) => (
            <>
              <div className="col-span-7" key={item.type}>
                <Controller
                  name={`table.${index}.type`}
                  control={control}
                  rules={{ required: true }}
                  render={({ field, ref }) => (
                    <Dropdown
                      label=""
                      value={field.value}
                      list={valueDropdown}
                      onChange={field.onChange}
                      onBlur={field.onBlur}
                      inputRef={ref}
                      optional={false}
                      extraWide
                      keepItShort
                      errorMessage={errors.table?.[index]?.type?.type === 'required' ? t('errorFieldRequired') : ''}
                    />
                  )}
                />
              </div>
              <div className="col-span-2">
                <input
                  type="text"
                  name={`value-${index}`}
                  id={`value-${index}`}
                  autoComplete="off"
                  className="block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-gray-900 focus:outline-none focus:ring-gray-900 sm:text-sm"
                  {...register(`table.${index}.quote`, { required: true, min: 0, pattern: /^[0-9]+$/ })}
                />
                {errors.table?.[index]?.quote && errors.table?.[index]?.quote?.type === 'pattern' && <p className="text-xs text-red-500 mt-1">{t('errorMustBeNumber')}</p>}
                {errors.table?.[index]?.quote && errors.table?.[index]?.quote?.type === 'required' && <p className="text-xs text-red-500 mt-1">{t('errorFieldRequired')}</p>}
                {errors.table?.[index]?.quote && errors.table?.[index]?.quote?.type === 'min' && <p className="text-xs text-red-500 mt-1">{t('errorMustBePositive')}</p>}
              </div>
              <div className="col-span-2">
                <input
                  type="text"
                  name={`value-${index}`}
                  id={`value-${index}`}
                  autoComplete="off"
                  className="col-span-2 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-gray-900 focus:outline-none focus:ring-gray-900 sm:text-sm"
                  {...register(`table.${index}.quoteBaseCurrency`, { required: true, min: 0, pattern: /^[0-9]+$/ })}
                />
                {errors.table?.[index]?.quoteBaseCurrency && errors.table?.[index]?.quoteBaseCurrency?.type === 'pattern' && <p className="text-xs text-red-500 mt-1">{t('errorMustBeNumber')}</p>}
                {errors.table?.[index]?.quoteBaseCurrency && errors.table?.[index]?.quoteBaseCurrency?.type === 'required' && <p className="text-xs text-red-500 mt-1">{t('errorFieldRequired')}</p>}
                {errors.table?.[index]?.quoteBaseCurrency && errors.table?.[index]?.quoteBaseCurrency?.type === 'min' && <p className="text-xs text-red-500 mt-1">{t('errorMustBePositive')}</p>}
              </div>

              <Button
                Icon={XMarkIcon}
                onClick={() => remove(index)}
                size="lg"
                formatting="self-stretch pl-0 pr-0"
              />
            </>
          ))}
          <Button
            Icon={PlusIcon}
            onClick={() => append()}
            formatting="w-12"
          />
        </div>
        {/* {errors.table && <span className="text-xs text-red-500">{t('errorCompleteRowRequired')}</span>} */}
        <div className="flex justify-center mt-4 space-x-3">
          <Button
            text="Save"
            type="submit"
            size="lg"
            withAccent
            onClick={handleSubmit(onSubmit)}
            spinnerOn={loading}
          />
          <Button
            text="Cancel"
            size="lg"
            onClick={onCancel}
          />
        </div>
      </div>
    </div>
  );
}
AttributeArrayOfObjectsFormQuote.propTypes = {
  formValues: PropTypes.array.isRequired,
  header: PropTypes.string.isRequired,
  prompt: PropTypes.string.isRequired,
  callback: PropTypes.func.isRequired,
};
