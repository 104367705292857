import categories from './categories.json';
import posts from './posts.json';

export function getCategoryLink(categoryId, lng) {
  return categories.find((c) => c.key === categoryId)[lng].displayKey;
}

export function getArticleLink(articleId, lng) {
  const post = posts.find((p) => p.id === articleId);
  return `${getCategoryLink(post.category.key, lng)}/${post[lng].displayKey}`;
}
