import React from 'react';

const steps = [
  {
    type: 'intro',
    id: 'GR-intro',
    steps: [
      {
        selector: '#account-details-grid-save-button',
        content: () => (
          <p>
            Always press
            {' '}
            <strong>Save</strong>
            {' '}
            after you are through making changes.
          </p>
        ),
      },
      {
        selector: '#account-details-grid-spreadsheet',
        content: () => (
          <div>
            <p className="pb-2">
              Add and change your data just like in a
              {' '}
              <strong>regular spreadsheet</strong>
              .
            </p>
            <p>
              Right mouse button displays a useful
              {' '}
              <strong>context menu</strong>
              .
            </p>
          </div>
        ),
      },
    ],
  },
];

export default steps;
