import React from 'react';
import PropTypes from 'prop-types';
import { ArrowPathIcon, CloudArrowUpIcon, LockClosedIcon } from '@heroicons/react/20/solid';

const features = [
  {
    name: 'Enter the current saldo',
    description:
      'Click >Edit<, enter the current account saldo and save. You can add more details later.',
    icon: CloudArrowUpIcon,
  },
  {
    name: 'Import transactions from file',
    description:
      'Download a transaction extract file from your bank\'s online banking. Once you have it, press the >Import< button and follow our step-by-step instructions.',
    icon: LockClosedIcon,
  },
  {
    name: 'Get transactions automatically',
    description:
      'If there is a technical link, you can get your transactions automatically. Just press the >Sync< button and we will do the rest.',
    icon: ArrowPathIcon,
  },
];

export default function GettingStarted() {
  return (
    <div className="bg-white py-8">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:text-center">
          <h2 className="text-base font-semibold leading-8 tracking-wide text-brandBlue-500 uppercase">Getting started</h2>
          <p className="mt-2 text-3xl font-bold text-gray-900 sm:text-4xl">
            Fill your new account with data
          </p>
          <p className="mt-4 text-lg leading-7 text-gray-600">
            It is easy to get started and it is up to you to decide how to do that.
            <br />
            Remember: everything you do can be updated later.
          </p>
        </div>
        <div className="mx-auto mt-8 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
            {features.map((feature) => (
              <div key={feature.name} className="flex flex-col">
                <dt className="flex items-center gap-x-3 text-base font-semibold leading-7 text-gray-900">
                  <feature.icon className="h-5 w-5 flex-none text-brandBlue-500" aria-hidden="true" />
                  {feature.name}
                </dt>
                <dd className="mt-4 flex flex-auto flex-col text-base leading-6 text-gray-600">
                  <p className="flex-auto">{feature.description}</p>
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}
