/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import CategoryGrowthRates from './CategoryGrowthRates';
import InflationRate from './InflationRate';
import SliderDates from './SliderDates';
import DividendRates from './DividendRates';

dayjs.extend(utc);

export default function Planning({ setIsDirty }) {
  const { t } = useTranslation('app', { keyPrefix: 'settings.simulations' });

  return (
    <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5 text-gray-700 font-normal" id="settings-planning-pane">
      <div className="space-y-6 sm:space-y-5">
        <div className="space-y-6 sm:space-y-5">
          <div>
            <p className="mt-1 text-sm text-gray-500">{t('info')}</p>
          </div>

          <SliderDates setIsDirty={setIsDirty} />
          <InflationRate setIsDirty={setIsDirty} />
          <CategoryGrowthRates setIsDirty={setIsDirty} />
          <DividendRates setIsDirty={setIsDirty} />
        </div>
      </div>
    </div>
  );
}
Planning.propTypes = {
  setIsDirty: PropTypes.func.isRequired,
};
