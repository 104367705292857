/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/forbid-prop-types */
import React, { useState, useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { capitalize } from 'lodash';
import PropTypes from 'prop-types';
import { nanoid } from 'nanoid';
import dayjs from 'dayjs';
import { getSchemaByCategory } from '@monestry-dev/schema';
import { globalAccountsView } from '../../redux/reducers/globalSelectors';
import { postAccount, postData } from '../../redux/reducers/data';
import { setMessage } from '../../redux/actions/message';
import MiniSpinner from '../../misc/MiniSpinner';

const utc = require('dayjs/plugin/utc');

dayjs.extend(utc);

export default function TemplateParametersForm({ selectedTemplate, setTemplateDialog, project }) {
  const [accountValues, setAccountValues] = useState(selectedTemplate.accounts.map((account) => ({ ...account, value: 'selectAnOption', newAccountId: nanoid() })));
  // ^^ use index of input accounts array to reference accountValues
  // initialise with the rump account objects from the template
  // when user selects an account, replace the rump object with the full account object or with 'createNewAccount'
  // newAccountId only in case user wants to create a new account

  const [formError, setFormError] = useState(false);
  const [formIsDirty, setFormIsDirty] = useState(false);
  const [loading, setLoading] = useState(false); // for loading spinner
  const { t } = useTranslation('app');

  const accounts = useSelector(globalAccountsView);
  const selectBaseCurrency = useSelector((state) => state.user.profile.settings.baseCurrency) || 'EUR';
  const baseCurrency = useMemo(() => selectBaseCurrency, [selectBaseCurrency]);

  const dispatch = useDispatch();

  // validate template and throw errors if any
  useEffect(() => {
    // check if all transactions have .category and .accountIndex
    if (selectedTemplate && selectedTemplate.transactions.some((transaction) => (!transaction.category || !(transaction.accountIndex > -1)))) {
      // because if it is just !transaction.accountIndex, it interprets 0 as false
      console.error('Template transactions are missing category and / or accountIndex');
      setFormError(t('projects.templateInitialisationError'));
    }
  }, [selectedTemplate]);

  async function handleSubmit(e) {
    if (accountValues.some((account) => account.value === 'selectAnOption')) {
      setFormError(t('projects.errorNotAllFields'));
      return;
    }

    setLoading(true);

    // create accounts if any of the fields are set to "create account" (await completion)
    // create payload
    if (accountValues.some((account) => account.createNewAccount)) {
      // for each account dispatch and wait for result using Promise.all
      // if any of the promises fail, show toast to user and return
      const promises = [];
      accountValues.forEach((account) => {
        if (account.createNewAccount) {
          // cast to schema
          const schema = getSchemaByCategory(account.category, 'account');
          const newAccount = schema.cast({
            id: account.newAccountId,
            name: capitalize(t(`dashboard.singleCategory.${account.category}`)),
            currency: baseCurrency,
          });
          // add promise to promises array
          promises.push(dispatch(postAccount({ data: [newAccount], category: account.category })));
        }
      });
      await Promise.all(promises);
    }

    const defaultRecurringObject = {
      activated: false,
      numberOfPeriods: 1,
      periodType: { id: 'month', name: t('repeating.month') },
      end: 'recurringNever', // 'recurringNever' or 'recurringByOccurrence' or 'recurringByDate'; 'recurringNever' will be ended by global selector at the max date of Slider
      endAfterOccurrences: 10,
      endAfterDate: dayjs.utc().add(1, 'year').valueOf(),
    };

    const defaultIndexedObject = {
      activated: false,
      mode: 'indexedByInflation',
      customRateInput: null,
    };

    const defaultProjectTagValues = {
      recurring: defaultRecurringObject,
      indexed: defaultIndexedObject,
    };

    // create transactions as per template (await completion)
    const transactionsByCategory = {};
    selectedTemplate.transactions.forEach((transaction) => {
      const transactionSchema = getSchemaByCategory(transaction.category, 'transaction', false);
      const accountId = accountValues[transaction.accountIndex].id || accountValues[transaction.accountIndex].newAccountId;
      console.log('schema: ', transactionSchema, transaction.category);
      const newTransaction = transactionSchema.cast({
        id: nanoid(),
        accountId,
        date: dayjs.utc().add(transaction.daysToAdd || 100, 'day').valueOf(),
        isSimulated: true,
        projectId: project.id,
        tags: { ...transaction.tags, ...defaultProjectTagValues, partOfProjectTemplate: true },
        ...transaction,
      });
      // group transactions to optimise posting
      if (transactionsByCategory[transaction.category]) {
        transactionsByCategory[transaction.category].push(newTransaction);
      } else {
        transactionsByCategory[transaction.category] = [newTransaction];
      }
    });

    try {
      // post transactions by category
      const transactionPromises = [];
      Object.keys(transactionsByCategory).forEach((category) => {
        transactionPromises.push(dispatch(postData({ data: transactionsByCategory[category], category })));
      });

      await Promise.all(transactionPromises);
      dispatch(setMessage('applyProjectTemplateSuccess'));
      setTemplateDialog(null);
    } catch (error) {
      setLoading(false);
      console.error(error);
      dispatch(setMessage('applyProjectTemplateError'));
    }
  }

  useEffect(() => {
    // if any of the accounts has 'selectAnOption' as value, toggle error state
    if (formIsDirty && accountValues.some((account) => account.value === 'selectAnOption')) {
      setFormError(t('projects.errorNotAllFields'));
    } else {
      setFormError(false);
    }
  }, [accountValues]);

  const handleAccountSelect = (event, index) => {
    setFormIsDirty(true);
    // check if error is shown and remove it if conditions met
    if (event.target.value === 'selectAnOption') return;

    const accountsValuesCopy = [...accountValues];

    if (event.target.value === 'createNewAccount') {
      accountsValuesCopy[index] = { ...accountsValuesCopy[index], createNewAccount: true };
    } else {
      accountsValuesCopy[index] = JSON.parse(event.target.value); // writes the account object to accountsValues
      setAccountValues(accountsValuesCopy);
    }
  };

  const getOptions = (category) => accounts
    .filter((a) => a.category === category)
    .map((account) => (
      <option key={account.id} value={JSON.stringify(account)}>
        {account.name}
      </option>
    ));

  return (
    <div>
      {formError && (<h3 className="text-brandRed-500" id="loginIncorrectError">{formError}</h3>)}
      {selectedTemplate && selectedTemplate.accounts.map((account, index) => (
        // for each placeholder account from the template, render a dropdown
        // eslint-disable-next-line react/no-array-index-key
        <div className="grid grid-cols-1 gap-x-8 border-b border-gray-900/10 py-5 md:grid-cols-3" key={index}>
          <div>
            <h2 className="text-base font-semibold leading-7 text-gray-900">{capitalize(t(`dashboard.singleCategory.${account.category}`))}</h2>
            <p className="mt-1 text-sm leading-6 text-gray-600">
              {account.role}
            </p>
          </div>
          <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
            <div className="sm:col-span-3">
              <div className="mt-8">
                <select
                  id="accountSelectionDropdown"
                  value={JSON.stringify(accountValues[index]) || 'selectAnOption'}
                  onChange={(event) => handleAccountSelect(event, index)}
                  // eslint-disable-next-line max-len
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                >
                  <option key="selectAnOption" value="selectAnOption">{t('projects.selectAnOption')}</option>
                  <option key="createNewAccount" value="createNewAccount">{`* ${t('projects.createNewAccount')}`}</option>
                  {getOptions(account.category)}
                </select>
              </div>
            </div>
          </div>
        </div>
      ))}
      <div className="w-1/2 mt-8 grid grid-cols-2 gap-2">
        <button
          type="button"
          id="project-details-apply-template-parameters-save"
          onClick={handleSubmit}
          // eslint-disable-next-line max-len
          className={`inline-flex items-center justify-center w-full rounded-md border border-transparent py-2 px-4 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-brandBlue-400 focus:ring-offset-2
          ${formError ? 'bg-gray-300 cursor-not-allowed' : 'bg-brandBlue-500 hover:bg-brandBlue-600'}}`}
        >
          Save
          <MiniSpinner className={`ml-3 h-5 w-4 animate-spin ${loading ? 'inline-block' : 'hidden'}`} />
        </button>
        <button
          type="button"
          onClick={() => { setTemplateDialog(undefined); }}
          // eslint-disable-next-line max-len
          className="inline-flex items-center justify-center w-full rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-400 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-brandBlue-400 focus:ring-offset-2"
        >
          Cancel
        </button>
      </div>
    </div>

  );
}
TemplateParametersForm.propTypes = {
  selectedTemplate: PropTypes.object.isRequired,
  setTemplateDialog: PropTypes.func.isRequired,
  project: PropTypes.object.isRequired,
};
