/* eslint-disable react/forbid-prop-types */
import React, { useState, Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Popover, Transition } from '@headlessui/react';
import { ChevronDownIcon, ExclamationCircleIcon } from '@heroicons/react/20/solid';

const solutions = [
  { name: 'Blog', description: 'Learn about tips, product updates and company culture.', href: '#' },
  {
    name: 'Help Center',
    description: 'Get all of your questions answered in our forums of contact support.',
    href: '#',
  },
  { name: 'Guides', description: 'Learn how to maximize our platform to get the most out of it.', href: '#' },
  { name: 'Events', description: 'Check out webinars with experts and learn about our annual conference.', href: '#' },
  { name: 'Security', description: 'Understand how we take your privacy seriously.', href: '#' },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function ColumnMatcherErrorPopover({ validationErrorArray }) {
  // expects the inner array from the validation error object
  // returns [ { message: 'error message', rows: [1, 2, 3] }, { message: 'error message', rows: [1, 2, 3] } ]
  function prepareMessages(arr) {
    const groupedErrorMessages = arr
      .reduce((prev, curr) => {
        const { path } = curr;
        let { message } = curr;
        const row = path.split('.')[0].substring(1, path.split('.')[0].length - 1);
        // some error messages include the row number first in the message ([2].otherParty ...), so we need to remove it
        message = (/\[\d+\]\./.test(message)) ? message.slice(message.indexOf('.') + 1) : message;
        if (Object.keys(prev).includes(message)) prev[message].push(Number(row) + 1);
        else return { ...prev, [message]: [Number(row) + 1] };
        return prev;
      }, {});
    return Object.keys(groupedErrorMessages).map((key) => ({ message: key, rows: groupedErrorMessages[key] }));
  }

  const [messages, setMessages] = useState([]);

  useEffect(() => {
    setMessages(prepareMessages(validationErrorArray));
  }, [validationErrorArray]);

  return (
    <Popover className="relative" id="error-popover">
      {({ open }) => (
        <>
          <Popover.Button
            className={classNames(
              open ? 'text-brandRed-800' : 'text-brandRed-500',
              'group px-3 py-2 inline-flex items-center rounded-md bg-brandRed-50 text-base font-medium hover:text-brandRed-800 focus:outline-none focus:ring-2 focus:ring-brandBlue-500 focus:ring-offset-2',
            )}
          >
            <ExclamationCircleIcon className="text-brandRed-400 group-hover:text-brandRed-500 mr-2 h-5 w-5" aria-hidden="true" />
            <span>Errors</span>
            <ChevronDownIcon
              className={classNames(open ? 'text-brandRed-600' : 'text-brandRed-400', 'ml-2 h-5 w-5 group-hover:text-brandRed-500')}
              aria-hidden="true"
            />
          </Popover.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute left-1/2 z-10 mt-3 w-screen max-w-md -translate-x-1/2 transform px-2 sm:px-0" id="error-popover-content">
              <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                  {messages.map((item, idx) => (
                    <div
                      key={item.message}
                      className="-m-3 block rounded-md p-3 transition duration-150 ease-in-out hover:bg-gray-50"
                    >
                      <p className="text-base font-medium text-gray-900" id={`error-popover-message-${idx}`}>{item.message}</p>
                      <p className="mt-1 text-sm text-gray-500 prose" id={`error-popover-rows-${idx}`}>{`Occurs in rows: ${item.rows.join(', ')}`}</p>
                    </div>
                  ))}
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
}
ColumnMatcherErrorPopover.propTypes = {
  validationErrorArray: PropTypes.array.isRequired,
};
