import { createSelector } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { nanoid } from 'nanoid';
import { depositTransaction, loanTransaction } from '@monestry-dev/schema';
import { applyFIFOReturnAll } from '../../../../misc/arrayHelpers';
import * as globals from '..';
import getSimulatedRepaymentSchedule from './getSimulatedRepaymentSchedule';
import i18n from '../../../../i18n';

dayjs.extend(utc);

// DOES NOT return user-added out of schedule transactions (e.g. manual principal payment)
function transformRepaymentScheduleItemToTransaction(item, accountId, projectId, currency, baseCurrency, quotes, transactions) {
  // upbc is by what do we need to multiply quantity (of account currency) to get base currency
  // quoteBaseCurrency is how much is 1 unit of baseCurrency in currency, e.g. for USD it is 0.92
  // as fallback take the upbc from last known transaction
  let upbc = 1;
  if (baseCurrency !== currency) {
    const currentQBC = quotes[currency]?.current?.quoteBaseCurrency;
    upbc = (currentQBC && currentQBC !== 0 ? (1 / currentQBC) : undefined);
    if (!upbc) {
      const lastTransaction = transactions
        .filter((transaction) => transaction.accountId === accountId)
        .sort((a, b) => b.date - a.date)[0];
      upbc = lastTransaction?.upbc;
    }
    if (!upbc) {
      throw new Error('loansTransactions > transformRepaymentScheduleItemToTransaction: no upbc found');
    }
  }

  // we need to link this loan transaction to a deposit (transfer) transaction, which will be created later than this view
  // that deposit transaction will be generated in depositTransactions > simulatedLoanInstallmentView based on the loanTransactions transaction and will pass the linkedDepositTransctionId in tags
  const linkedDepositTransctionId = nanoid();
  // one item has currentPrincipal and currentInterest, this needs to return two transactions
  const commonProperties = loanTransaction.cast({
    id: nanoid(),
    accountId,
    projectId,
    date: item.date,
    category: 'loans',
    description: `${i18n.language === 'de' ? 'Darlehensrate' : 'Installment'} ${dayjs.utc(item.date).format('YYYY-MM')}`,
    assetId: currency,
    accountCurrency: currency,
    assetCurrency: currency,
    upbc,
    upac: 1,
    isSimulated: true,
    linkedTransactions: [
      {
        id: linkedDepositTransctionId,
        category: 'deposits',
        tags: {
          type: 'transfer',
        },
      },
    ],
    tags: {
      linkedDepositTransctionId,
    },
  });

  return [
    {
      ...commonProperties,
      quantity: item.currentPrincipal,
      quantityInterest: item.currentInterest,
    },
  ];
}

export const loanTransactions = createSelector(
  (state) => state.data?.loans.transactions,
  (state) => state.data?.loans.simulatedTransactions,
  (state) => state.data?.loans.accounts,
  globals.globalQuotesView,
  (state) => state.simulation.dashboardMode,
  (state) => state.simulation.baseDate,
  (state) => state.user.profile.settings.baseCurrency,
  (state) => state.projects,
  (transactions, simulatedTransactions, accounts, quotes, dashboardMode, baseDate, baseCurrency, projects) => {
    // DASHBOARD MODE

    if (dashboardMode === 'dashboard') {
      // split transactions by account, for every account find out reverseMode, run through FIFO and return as a flat array
      return accounts
        .flatMap((loanAccount) => {
          // decide if we need reverseMode in FIFO calculation (reverseMode is for loans taken, not given -- we need it because the first transaction is a "sale")
          const accountTransactions = transactions.filter((transaction) => transaction.accountId === loanAccount.id);
          const reverseMode = loanAccount.direction === 'received'; // i.e. 'taken'
          return applyFIFOReturnAll(accountTransactions, reverseMode);
        })
        // add transactionType based on account.direction
        .map((txn) => {
          const account = accounts.find((acc) => acc.id === txn.accountId);
          switch (account.direction) {
            case 'received':
              // if this is a received loan, then the purchase (payout) is negative and repayment (sale) is positive and v.v.
              return { ...txn, transactionType: txn.quantity >= 0 ? 'sale' : 'purchase' };
            case 'granted':
              return { ...txn, transactionType: txn.quantity < 0 ? 'sale' : 'purchase' };
            default:
              console.error('Unknown account direction', account.direction);
              return txn;
          }
        });
    }

    // handle situation where the new month has begun already, but there is still no this month transaction
    // the simulation should always begin from month after the month of the last available transaction
    // slice creates a copy of the array, because sort does it in place
    const lastAvailableRealTransaction = transactions.slice().sort((a, b) => b.date - a.date)[0];
    const lastAvailableMonth = dayjs.utc(lastAvailableRealTransaction?.date).valueOf() || dayjs.utc().valueOf();

    // PROJECT MODE

    const isolatedProjectIds = projects?.filter((project) => project.settings?.isIsolated).map((project) => project.id);

    // for each account calculate a repayment schedule and transform to two transactions per period
    const transactionsWithSimulated = accounts
      .flatMap((loanAccount) => {
        const thisAccountTransactions = transactions.filter((txn) => txn.accountId === loanAccount.id);
        // ↓ exclude all isolated projects' transactions
        const thisAccountSimulatedTransactions = simulatedTransactions.filter((txn) => txn.accountId === loanAccount.id && !isolatedProjectIds.includes(txn.projectId));

        const allRealTransactionsBalance = transactions
          .filter((transaction) => transaction.accountId === loanAccount.id)
          .reduce((acc, transaction) => acc + transaction.quantity, 0); // principal (interest is in 'quantityInterest') in account currency

        // generate simulated transactions for every account from getSimulatedRepaymentSchedule
        const repaymentSchedule = getSimulatedRepaymentSchedule(
          dayjs.utc(lastAvailableMonth).add(1, 'month').startOf('month').valueOf(), // startDate for the schedule is next month
          allRealTransactionsBalance,
          loanAccount,
          simulatedTransactions.filter((transaction) => transaction.accountId === loanAccount.id), // returns [] when empty
        ) || { schedule: [] };
        // repaymentSchedule is just that, now it needs to be transfored into transactions
        // the transactions are under .schedule
        // this DOES NOT return user-added out of schedule transactions (e.g. manual principal payment), so they have to be added back later
        const simulatedRepaymentTransactionsForAccount = repaymentSchedule.schedule
          .filter((item) => item.date.valueOf() <= baseDate)
        // transform each output of repaymentSchedule into transactions
          .flatMap((item) => transformRepaymentScheduleItemToTransaction(
            item,
            loanAccount.id,
            loanAccount.connectedProjectId,
            loanAccount.currency,
            baseCurrency,
            quotes,
            thisAccountTransactions,
          ));

        // decide if we need reverseMode in FIFO calculation (reverseMode is for loans taken, not given -- we need it because the first transaction is a "sale")
        const reverseMode = loanAccount.direction === 'received';
        const allTransactions = thisAccountTransactions.concat(thisAccountSimulatedTransactions).concat(simulatedRepaymentTransactionsForAccount);
        return applyFIFOReturnAll(allTransactions, reverseMode)
          .map((txn) => {
            const account = accounts.find((acc) => acc.id === txn.accountId);
            switch (account.direction) {
              case 'received':
              // if this is a received loan, then the purchase (payout) is negative and repayment (sale) is positive and v.v.
                return { ...txn, transactionType: txn.quantity >= 0 ? 'sale' : 'purchase' };
              case 'granted':
                return { ...txn, transactionType: txn.quantity < 0 ? 'sale' : 'purchase' };
              default:
                console.error('Unknown account direction', account.direction);
                return txn;
            }
          });
      });

    // apply FIFO to all transactions, one accountId after another
    // if this is a loan TAKEN, activate reverse mode
    return transactionsWithSimulated;
  },
);

export const loanTransactionsProjectView = createSelector(
  (state) => state.data?.loans.transactions,
  (state) => state.data?.loans.simulatedTransactions,
  (state) => state.data?.loans.accounts,
  globals.globalQuotesView,
  (state) => state.user.profile.settings.baseCurrency,
  (transactions, simulatedTransactions, accounts, quotes, baseCurrency) => {
    // handle situation where the new month has begun already, but there is still no this month transaction
    // the simulation should always begin from month after the month of the last available transaction
    // slice creates a copy of the array, because sort does it in place
    const lastAvailableRealTransaction = transactions.slice().sort((a, b) => b.date - a.date)[0];
    const lastAvailableMonth = dayjs.utc(lastAvailableRealTransaction?.date).valueOf() || dayjs.utc().valueOf();

    // for each account calculate a repayment schedule and transform to two transactions per period
    const transactionsWithSimulated = accounts
      .flatMap((loanAccount) => {
        const thisAccountTransactions = transactions.filter((transaction) => transaction.accountId === loanAccount.id);
        const thisAccountSimulatedTransactions = simulatedTransactions.filter((transaction) => transaction.accountId === loanAccount.id);

        const allPastTransactionsBalance = transactions
          .filter((transaction) => transaction.accountId === loanAccount.id)
          .reduce((acc, transaction) => acc + transaction.quantity, 0); // in account currency

        // generate simulated transactions for every account from getSimulatedRepaymentSchedule
        const repaymentSchedule = getSimulatedRepaymentSchedule(
          dayjs.utc(lastAvailableMonth).add(1, 'month').startOf('month').valueOf(), // startDate for the schedule is next month
          allPastTransactionsBalance,
          loanAccount,
          simulatedTransactions.filter((transaction) => transaction.accountId === loanAccount.id), // returns [] when empty
        ) || { schedule: [] };

        // repaymentSchedule is just that, now it needs to be transfored into transactions
        // the transactions are under .schedule
        // this DOES NOT return user-added out of schedule transactions (e.g. manual principal payment), so they have to be added back later
        const simulatedRepaymentTransactionsForAccount = repaymentSchedule.schedule
        // transform each output of repaymentSchedule into transactions
          .flatMap((item) => transformRepaymentScheduleItemToTransaction(
            item,
            loanAccount.id,
            loanAccount.connectedProjectId,
            loanAccount.currency,
            baseCurrency,
            quotes,
            transactions,
          ));

        // decide if we need reverseMode in FIFO calculation (reverseMode is for loans taken, not given -- we need it because the first transaction is a "sale")
        const reverseMode = loanAccount.direction === 'received';

        // caution: FIFO calc only should happen on PRINCIPAL transactions, but INTEREST transactions must be added later on
        const allTransactions = thisAccountTransactions.concat(thisAccountSimulatedTransactions).concat(simulatedRepaymentTransactionsForAccount);
        return applyFIFOReturnAll(allTransactions, reverseMode)
          .map((txn) => {
            const account = accounts.find((acc) => acc.id === txn.accountId);
            switch (account.direction) {
              case 'received':
              // if this is a received loan, then the purchase (payout) is negative and repayment (sale) is positive and v.v.
                return { ...txn, transactionType: txn.quantity >= 0 ? 'sale' : 'purchase' };
              case 'granted':
                return { ...txn, transactionType: txn.quantity < 0 ? 'sale' : 'purchase' };
              default:
                console.error('Unknown account direction', account.direction);
                return txn;
            }
          });
      });

    // apply FIFO to all transactions, one accountId after another
    // if this is a loan TAKEN, activate reverse mode
    return transactionsWithSimulated;
  },
);

// if constructPrincipal is true, it uses quantity and tags it investment-loanPrincipal, if false, it uses quantityInterest and tags it investment-loanInterest
function constructTransaction(_loanTransaction, _loanAccounts, _depositAccounts, _quotes, constructPrincipal = true) {
  const depositTransactionTemplate = {
    category: 'deposits',
    isSimulated: true,
    otherParty: 'Bank',
    tags: {},
  };

  function getConnectedDepositAccountId(_loanAccountId) {
    return _loanAccounts.find((a) => a.id === _loanAccountId)?.connectedDepositAccounts[0];
  }

  let depositAccount;
  try {
    const depositAccountId = getConnectedDepositAccountId(_loanTransaction.accountId);
    depositAccount = _depositAccounts.find((a) => a.id === depositAccountId);

    if (!depositAccount) {
      console.error('No connected deposit account found for loan account', _loanTransaction.accountId);
      return null; // we cannot do that if there is no deposit account
    }

    // all amounts are inversed because the deposit account is the opposite of the loan account
    const localQuantity = constructPrincipal ? ((-1) * _loanTransaction.quantity) : ((-1) * _loanTransaction.quantityInterest);

    const transaction = depositTransaction.cast({
      ...depositTransactionTemplate,
      // ↓ "principal" transaction receives the id from the loan transaction, as the loan transaction must have the linkedTransaction.id already before this one is created
      id: constructPrincipal ? _loanTransaction.tags.linkedDepositTransctionId : nanoid(),
      date: _loanTransaction.date,
      description: _loanTransaction.description,
      accountId: depositAccountId,
      projectId: _loanTransaction.projectId,
      amount: localQuantity * _loanTransaction.upbc, // in base currency
      currency: depositAccount.currency,
      accountCurrencyAmount: localQuantity, // this assumes this (loan) account currency is the same as deposit account currency; this will be overwritten below
      quantity: localQuantity,
      upbc: _loanTransaction.upbc,
      upac: 1,
      fxAmount: null, // ditto
      fxCurrency: null,
      ...(constructPrincipal ? {
        label: 'investment-loanPrincipal',
        linkedTransactions: [
          {
            id: _loanTransaction.id,
            category: 'loans',
            tags: {
              type: 'transfer',
            },
          },
        ],
      } : {
        label: 'investment-loanInterest',
        tags: {
          accountId: _loanTransaction.accountId,
          assetId: _loanTransaction.assetId,
        },
      }),
    });

    // if loan account has different currency than deposit currency, we need to use fxAmount and fxCurrency
    // but we will have that currency in quotes;
    // that quote can be mapped from / to baseCurrency via quote and quoteBaseCurrency
    if (depositAccount.currency !== _loanTransaction.accountCurrency) {
    // 1 USD * quoteBaseCurrency(USD) = 1 EUR = 1 CHF * quoteBaseCurrency(CHF)
      const accountCurrencyQuote = _quotes[depositAccount.currency][_loanTransaction.date].quoteBaseCurrency;
      const loanCurrencyQuote = _quotes[_loanTransaction.accountCurrency][_loanTransaction.date].quoteBaseCurrency;
      transaction.fxAmount = localQuantity;
      transaction.fxCurrency = _loanTransaction.accountCurrency;
      // 1 USD = 1 CHF * quoteBaseCurrency(CHF) / quoteBaseCurrency(USD)
      transaction.accountCurrencyAmount = (loanCurrencyQuote / accountCurrencyQuote) * localQuantity;
    }

    return transaction;
  } catch (error) {
    console.error('simulatedLoanInstallmentView > transaction creation error for', _loanTransaction, error);
    console.info('simulatedLoanInstallmentView: depositAccount: ', depositAccount);
  }
  return null;
}

/**
 * This function takes all real and simulated loan transactions from loanTransactions and generates simulated deposit-side transactions for the loan transactions.
 * The input transactions are provided from depositsTransactions selector, which reads loanTransactions as input.
 *
 * @param {array} loanTransactions - all real and simulated loan transactions from loanTransactions selector
 * @param {array} loanAccounts - all loan accounts from state
 * @param {array} depositAccounts - all deposit accounts from state
 * @param {object} quotes - all quotes from globalQuotesView
 * projectMode is not necessary, as this only calculates the deposit side of the loan transactions and the loan transactions have already been filtered by projectMode
 *
 * @returns {array} - all real and simulated deposit transactions for the loan transactions
 */
export function simulatedLoanInstallmentView(_loanTransactions, _loanAccounts, _depositAccounts, _quotes) {
  if (_loanAccounts.length === 0 || _depositAccounts.length === 0 || _loanTransactions.length === 0) return [];
  const outputArray = [];

  // currency of deposit account may not be the same as the loan account
  // in such case uptc and upbc will not be the same (uptc will be the currency of the loan account, upbc is from accountCurrency, which is different in loan)

  // for each loan transaction, generate a deposit transaction
  _loanTransactions
    .filter((t) => t.isSimulated)
    .forEach((_loanTransaction) => {
    // create principal transaction and put it on the array
      outputArray.push(constructTransaction(_loanTransaction, _loanAccounts, _depositAccounts, _quotes, true));
      outputArray.push(constructTransaction(_loanTransaction, _loanAccounts, _depositAccounts, _quotes, false));
    });
  return outputArray.filter((t) => t !== null);
}
