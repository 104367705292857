/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import getQuotes from './sharedThunks/getQuotes';
import { globalBaselineView } from './globalSelectors';
import { assetBalancesArray } from './data';

const utc = require('dayjs/plugin/utc');

dayjs.extend(utc);

const baseDate = dayjs.utc().add(1, 'year').startOf('month').valueOf();

const initialState = {
  baseDate: baseDate.valueOf(),
  dashboardMode: 'dashboard', // 'projects'
  slider: {
    min: new Date('2015-01-01T00:00:00Z').valueOf(),
    max: new Date('2031-01-01T00:00:00Z').valueOf(),
  },
};

const simulationSlice = createSlice({
  name: 'simulation',
  initialState,
  reducers: {
    setSimulationBaseDate(state, action) {
      const { newDate } = action.payload;

      // check if we are receiving a correct argument
      if (Number.isNaN(newDate)) throw new Error(`Error: setSimulationBaseDate expects a valid Number (got ${newDate} instead)`);

      // force set argument to be the midnight of the 1st calendar day of the month
      const transformedDate = dayjs.utc(newDate).startOf('month');

      state.baseDate = transformedDate.valueOf();

      // if there are quotes in quotes slice, get new named quotes for all the assets in there
      // if there are no quotes, do nothing (this should not run)
    },
    setDashboardMode(state, action) {
      const { dashboardMode } = action.payload;
      if (!['dashboard', 'projects'].includes(dashboardMode)) throw new Error(`Error: setDashboardMode expects a valid dashboardMode (got ${dashboardMode} instead)`);

      state.dashboardMode = dashboardMode;
    },
  },
});

export default simulationSlice.reducer;

// export all reducers as actions if needed
export const { setDashboardMode, setSimulationBaseDate } = simulationSlice.actions;

export const setSimulationBaseDateAndUpdateQuotes = createAsyncThunk('simulation/setSimulationBaseDateAndUpdateQuotes', async ({ newDate }, { dispatch, getState }) => {
  // check if we are receiving a correct argument
  // if null is passed as newDate, use the current baseDate and just get quotes (used when initialising Dashboard in project mode)
  if (newDate !== null && Number.isNaN(newDate.valueOf())) throw new Error(`Error: setSimulationBaseDate expects a Number or a null (got ${newDate} instead)`);

  const { baseline, referenceBaseline } = globalBaselineView(getState());

  let transformedDate;
  if (newDate) {
    // force-set argument to be the midnight of the 1st calendar day of the month
    transformedDate = dayjs.utc(newDate).startOf('month').valueOf();

    // await dispatch set simulation basedate (sliderDate)
    await dispatch(setSimulationBaseDate({ newDate: transformedDate }));
  }

  if (newDate === null) {
    transformedDate = getState().simulation?.baseDate;
  }

  // and then, because baseline has changed in project mode, we need to update quotes
  // replace named baseline elements from quotes slice with new dates
  const assets = assetBalancesArray(getState())
    .map((a) => ({
      assetId: a.assetId,
      providerAssetId: a.providerAssetId,
      transactionCurrency: a.transactionCurrency,
    }));

  // getQuotes will overwrite existing assetId-date combinations, but will leave the rest untouched
  // it will also take care of assigning dates to named quotes: 'baseline', 'referenceBaseline', 'current'
  dispatch(
    getQuotes({
      dates: [baseline, referenceBaseline, transformedDate],
      assets,
    }),
  );

  return { transformedDate };
  // if there are quotes in quotes slice, get new named quotes for all the assets in there
  // if there are no quotes, do nothing (this should not run)
});
