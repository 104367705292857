import { SET_MESSAGE, SET_ALERT, CLEAR_ALERT, UPDATE_ALERT_RESPONSE, SET_APP_SIGNAL, CLEAR_APP_SIGNAL, SET_FULLSCREEN_REPORT, CLEAR_FULLSCREEN_REPORT } from '../actions/types';

const initialState = {
  alert: null,
  message: null,
  messages: [],
  tooltip: null,
  tiletip: null,
  signal: {},
  fullscreenReport: null,
};

// eslint-disable-next-line default-param-last
export default function messageReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_MESSAGE: {
      const { message } = payload;

      if (message === null) {
        // for compatibility reasons, clears the message property
        return { ...state, message: null };
      }
      return {
        ...state,
        message, // for compatility reasonds displays the last message
        // message stack (NotificationMessage and Toast components display the last one from here); payload is { message, extraMessage }
        // handle payload as string and as { message, extraMessage }
        messages: [...state.messages, payload.message ? payload : { message: payload }],
      };
    }

    case SET_FULLSCREEN_REPORT:
      return { ...state, fullscreenReport: payload };

    case CLEAR_FULLSCREEN_REPORT:
      return { ...state, fullscreenReport: null };

    case SET_ALERT:
      return {
        ...state,
        alert: {
          visible: true,
          id: payload.alertId,
          caller: payload.caller,
          callbackOk: payload.callbackOk,
        },
      };

    case UPDATE_ALERT_RESPONSE:
      return {
        ...state,
        alert: {
          ...state.alert,
          visible: false,
          response: payload,
        },
      };

    case CLEAR_ALERT:
      return {
        ...state,
        alert: null,
      };

    case SET_APP_SIGNAL:
      // expecting payload: { callerId: 'string', message: 'string' or boolean}
      return {
        ...state,
        signal: { ...state.signal, [payload?.callerId]: payload?.message },
      };

    case CLEAR_APP_SIGNAL:
      // expecting payload: { callerId: 'string'}
      return {
        ...state,
        signal: { ...state.signal, [payload?.callerId]: null },
      };

    default:
      return state;
  }
}
