/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { Auth, API } from 'aws-amplify';
import dayjs from 'dayjs';
import { globalBaselineView } from './globalSelectors';
import { allTransactionsProjectView } from './globalSelectors/overarching';
import { calculateDatesAndAssetIds } from '../actions/data/helpers';
import getQuotes, { getIsolatedQuotes } from './sharedThunks/getQuotes';

const utc = require('dayjs/plugin/utc');

dayjs.extend(utc);

const initialState = {
  global: [],
  isolated: [],
};

export async function prepCallToApi(body) {
  const session = await Auth.currentSession();
  const myInit = {
    body,
    headers: {
      'Content-Type': 'application/json',
      Authorization: session.idToken.jwtToken,
    },
  };
  return myInit;
}

export function createNamedArray(arrayOfQuotes, store) {
  const { baseline, referenceBaseline, current } = globalBaselineView(store);
  const r = arrayOfQuotes.reduce((prev, curr) => {
    if (Number(curr.date) === current) return [...prev, { ...curr, date: 'current' }];
    if (Number(curr.date) === baseline) return [...prev, { ...curr, date: 'baseline' }];
    if (Number(curr.date) === referenceBaseline) return [...prev, { ...curr, date: 'referenceBaseline' }];
    return prev;
  }, []);
  return r;
}

export const putQuotes = createAsyncThunk('quotes/putQuotes', async ({ body, category, accountId }, { dispatch, getState }) => {
  const payload = await prepCallToApi(body);
  const response = await API.post('myAPI', 'quotes/put', payload);
  await dispatch(getQuotes(calculateDatesAndAssetIds(getState().data[category].transactions.filter((t) => t.accountId === accountId)))); // wait for getQuotes to finish
  return response.quotes;
});

// expects:
// - arrayOfQuotes, each of which includes importFlag;
// - accountId (optional, to limit the assetIds to those present in a specific account, like we can during updates from Grid)
export const putOrDeleteQuotes = createAsyncThunk('quotes/putOrDeleteQuotes', async ({ arrayOfQuotes, accountId }, { dispatch, getState }) => {
  const allDistinctIncomingDates = [...new Set(arrayOfQuotes.map((q) => q.date))];
  const allDistinctIncomingAssetIds = [...new Set(arrayOfQuotes.map((q) => q.assetId))];
  const earliestQuoteDate = Math.min(...arrayOfQuotes.map((q) => q.date)); // Math.min requires a comma-separated list of arguments
  const putItems = arrayOfQuotes.filter((q) => q.importFlag !== 'delete');
  const payloadPut = await prepCallToApi(putItems);

  const deleteItems = arrayOfQuotes.filter((q) => q.importFlag === 'delete');
  const payloadDelete = await prepCallToApi(deleteItems);

  const promises = [];

  if (putItems.length > 0) promises.push(API.post('myAPI', 'quotes/put', payloadPut));
  if (deleteItems.length > 0) promises.push(API.post('myAPI', 'quotes/delete', payloadDelete));

  await Promise.all(promises);

  // we have changed some quotes above, so we need to run getQuotes to refresh them in state
  // select all assetIds in the transactions, and if we are only doing that for an account, filter by that account
  // in addition, only get dates which happen after the earliest quote date (so that we don't have to refresh the entire past where nothing has changed)
  const transactionsInScope = allTransactionsProjectView(getState()).filter((t) => (accountId ? t.accountId === accountId : true) && t.date >= earliestQuoteDate && t.category !== 'deposits');

  const getQuotesPayload = calculateDatesAndAssetIds(transactionsInScope);

  // handle user updating quotes for before earliestQuoteDate (for some reason)
  // - it is in incoming quotes and it will get updated in DB, but it won't be returned by getQuotes if we don't add it here
  // theoretically it is possible to provide a quote for a non-existent asset, so let's handle it for now
  allDistinctIncomingDates.forEach((date) => {
    if (!getQuotesPayload.dates.includes(date)) getQuotesPayload.dates.push(date);
  });
  allDistinctIncomingAssetIds.forEach((assetId) => {
    if (!getQuotesPayload.assets.map((a) => a.assetId).includes(assetId) && !!assetId) getQuotesPayload.assets.push({ assetId, providerAssetId: null });
    // TODO null is not super elegant, but we assume these are manual quotes mostly here
    // !!assetId: somehow now we sometimes get an empty string as assetId, which is not valid
  });

  await dispatch(getQuotes(calculateDatesAndAssetIds(transactionsInScope)));

  return arrayOfQuotes;
});

const quotesSlice = createSlice({
  name: 'quotes',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getQuotes.fulfilled, (state, action) => {
        if (action.payload?.length > 0) {
          // do not do forEach on state because it triggers recalc of globalQuoteView every time, just create a new array and replace it when ready
          const stateGlobalCopy = [...state.global];
          action.payload?.forEach((newQuote) => {
            //   state.global[quote.assetId] = state.global[quote.assetId] || {}; // in case a new object needs to be created; i can mutate state here because it's the redux-toolkit way
            //   state.global[quote.assetId][quote.date] = quote;
            const index = stateGlobalCopy.findIndex((q) => q.assetId === newQuote.assetId && q.date === newQuote.date);
            if (index !== -1) {
              stateGlobalCopy[index] = newQuote;
            } else {
              stateGlobalCopy.push(newQuote);
            }
          });
          state.global = stateGlobalCopy;
        }
      })
      .addCase(getIsolatedQuotes.fulfilled, (state, action) => {
        if (action.payload?.length > 0) {
          const stateIsolatedCopy = [...state.isolated];
          action.payload?.forEach((newQuote) => {
            //   state.isolated[quote.projectId] = state.isolated[quote.projectId] || {}; // in case a new object needs to be created; i can mutate state here because it's the redux-toolkit way
            //   state.isolated[quote.projectId][quote.assetId] = state.isolated[quote.projectId][quote.assetId] || {}; // like above
            //   state.isolated[quote.projectId][quote.assetId][quote.date] = quote;
            const index = stateIsolatedCopy.findIndex((q) => q.assetId === newQuote.assetId && q.date === newQuote.date);
            if (index !== -1) {
              stateIsolatedCopy[index] = newQuote;
            } else {
              stateIsolatedCopy.push(newQuote);
            }
          });
          state.isolated = stateIsolatedCopy;
        }
      });
  },
});

export default quotesSlice.reducer;
