import createNivoRenderers1 from './nivo/Sunburst';
import createNivoRenderers2 from './nivo/BarChart';
import createNivoRenderers3 from './nivo/AreaBump';
import createNivoRenderers4 from './nivo/Sankey';
import createNivoRenderers5 from './nivo/LineChart';

const NivoRenderers = {
  ...createNivoRenderers1(), ...createNivoRenderers2(), ...createNivoRenderers3(), ...createNivoRenderers4(), ...createNivoRenderers5(),
};

export default NivoRenderers;
