import { depositTransaction, depositTransactions } from '@monestry-dev/schema';

// export { getDepositData as getData } from '../../../redux/actions/data';

export const schemaArray = depositTransactions;
export const schemaTransaction = depositTransaction;

export function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

// ACCOUNTDETAILS ----------------------------------------------------------------------------------------------------

export const colWidths = {
  date: 100, otherParty: 150, description: 450, amount: 100, // FIXME: stocks (cols)
};

// these are initialisation values for the fields with changeable contents
export const attrs = {
  name: 'accountName',
  // bankName: 'bankName',
  // iban: 'IBAN',
  // bic: 'BIC',
};
