/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Auth, API } from 'aws-amplify';
import { QRCodeSVG } from 'qrcode.react';
import { ArrowTopRightOnSquareIcon, CheckCircleIcon } from '@heroicons/react/24/solid';
import { EnvelopeIcon } from '@heroicons/react/20/solid';
import { useTranslation } from 'react-i18next';
import { updateUserAttribute, changePassword } from '../../redux/actions/user';
import MiniSpinner from '../../misc/MiniSpinner';
import * as SignUp1 from '../signUp/SignUp1';
import { SET_MESSAGE } from '../../redux/actions/types';

export default function Account() {
  const dispatch = useDispatch();
  const [updating, setUpdating] = useState(null); // expects a field name as string
  const [passwordChange, setPasswordChange] = useState(false);
  const [showPasswordTip, setShowPasswordTip] = useState(false);
  const [user, setUser] = useState(null);
  const [showTwoFactorAuth, setShowTwoFactorAuth] = useState(false);
  const [MFASetUpCode, setMFASetUpCode] = useState(false);
  const [confirmationCodeReady, setConfirmationCodeReady] = useState(false);
  const [showEmailConfirmationCode, setShowEmailConfirmationCode] = useState(false);

  const useFormObject = useForm({ mode: 'onBlur', defaultValues: {} }); // intialised after useEffect fetches data from Amplify store
  const {
    register, reset, watch, getFieldState, formState, formState: { errors, dirtyFields },
  } = useFormObject;

  const { t } = useTranslation('app', { keyPrefix: 'settings.account' });

  useEffect(() => {
    const fieldState = getFieldState('confirmationCode');
    if (fieldState.isDirty && !fieldState.error && watch('confirmationCode').length === 6) {
      setConfirmationCodeReady(true);
    } else {
      setConfirmationCodeReady(false);
    }
  }, [formState]);

  const password = useRef({});
  password.current = watch('password', '');
  const repeatPassword = useRef({});
  repeatPassword.current = watch('repeatPassword', '');

  // initialise user object
  useEffect(() => {
    Auth.currentAuthenticatedUser().then((u) => {
      setUser(u);
      reset({ username: u.attributes.preferred_username, email: u.attributes.email });
    });
  }, []);

  function handleUpdateAttribute(event, fieldId, fieldValue) {
    // set spinner on button, the button should disappear once we reset the form with new values
    setUpdating(fieldId);
    dispatch(updateUserAttribute((fieldId === 'username' ? 'preferred_username' : fieldId), fieldValue)).then(
      () => {
        setUpdating(null);
        // get new values from state
        Auth.currentAuthenticatedUser().then((u) => {
          setUser(u);
          // supply the new values to the form as default values
          reset({ username: u.attributes.preferred_username, email: u.attributes.email });
          if (fieldId === 'email') {
            setShowEmailConfirmationCode(true);
          }
        });
      },
      () => {
        setUpdating(null);
      },
    );
  }

  // FIXME: this needs to change in the field / formObject once the new addreess is updated
  function onSubmitNewPassword() {
    // if all three password fields are valid, then we can change the password
    if (!getFieldState('currentPassword').invalid && !getFieldState('password').invalid && !getFieldState('repeatPassword').invalid) {
      setUpdating('password');
      dispatch(changePassword(watch('currentPassword'), watch('password'))).then(
        () => {
          setUpdating(null);
          setPasswordChange(false);
        },
        () => {
          setUpdating(null);
        },
      );
    }
  }

  function handleEnable2FA(e) {
    Auth.setupTOTP(user).then((code) => {
      // You can directly display the `code` to the user or convert it to a QR code to be scanned.
      // E.g., use following code sample to render a QR code with `qrcode.react` component:
      setShowTwoFactorAuth(true);
      console.log('verification code', code);
      setMFASetUpCode(code);
    });
  }
  const QRString = `otpauth://totp/username:${user?.attributes?.preferred_username}?secret=${MFASetUpCode}&issuer=Monestry`;

  async function handleSubmitMfaConfirmationCode(e) {
    e.preventDefault();
    setUpdating('confirmationCode');
    await Auth.verifyTotpToken(user, watch('confirmationCode'));
    await Auth.setPreferredMFA(user, 'SOFTWARE_TOKEN_MFA');
    // refresh user object in state
    const userObject = await Auth.currentAuthenticatedUser();
    setUser(userObject);
    setShowTwoFactorAuth(false);
    setUpdating(null);
  }

  async function handleEmailConfirmationCode(e) {
    e.preventDefault();
    try {
      setUpdating('emailConfirmationCode');
      const emailConfirmationCode = watch('emailConfirmationCode');

      // confirm email change in Cognito
      await Auth.verifyCurrentUserAttributeSubmit('email', emailConfirmationCode);

      // update email in Stripe
      const session = await Auth.currentSession();
      const apiPayload = {
        body: { email: watch('email') },
        headers: {
          'Content-Type': 'application/json',
          Authorization: session.idToken.jwtToken,
        },
      };
      try {
        await API.put('myAPI', 'customer/attribute', apiPayload);
      } catch (error) {
        throw new Error('stripeError');
      }

      dispatch({
        type: SET_MESSAGE,
        payload: 'profileUpdatedSuccessfully',
      });
      setUpdating(null);
      setShowEmailConfirmationCode(false);
    } catch (err) {
      setUpdating(null);
      if (err.message === 'stripeError') {
        dispatch({
          type: SET_MESSAGE,
          payload: 'errorUpdatingStripe',
        });
      } else {
        dispatch({
          type: SET_MESSAGE,
          payload: 'profileCouldNotBeUpdated',
        });
      }
    }
  }

  return (
    // Password + change
    <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5 text-gray-700 font-normal">
      {/* Notifications: monthly report, tips? */}
      <div className="space-y-6 sm:space-y-5">
        <div className="space-y-6 sm:space-y-5">
          <div className="space-y-3 sm:grid sm:grid-cols-3 sm:items-start sm:justify-items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5 text-sm">
            <div>
              <p>{t('Email address')}</p>
            </div>
            <div className="sm:max-w-sm sm:justify-self-stretch">
              <SignUp1.InputEmail useFormObject={useFormObject} />
              {/* only display errors when the field content has changed */}
              {(Object.keys(dirtyFields || []).includes('email')) && (
                <>
                  <div className="flex space-x-2 items-center ml-1">
                    <EnvelopeIcon className="inline w-8 h-8 text-gray-300 -mb-1" aria-hidden="true" />
                    <p className="pt-2 text-xs font-light tracking-wide text-gray-400">{t('emailInfo')}</p>
                  </div>

                  <div className="pt-2 sm:text-sm text-brandRed-500">
                    {errors.email && <span>{errors.email.message}</span>}
                  </div>
                </>
              )}
            </div>
            <button
              type="button"
              disabled={!Object.keys(dirtyFields || []).includes('email') || showEmailConfirmationCode}
              onClick={(e) => handleUpdateAttribute(e, 'email', watch('email'))}
              // -ml-24 is meant to eliminate a large gap between field and button that appears in 2xl
              className={`sm:ml-3 2xl:-ml-24 inline-flex justify-center rounded-md border py-2 px-4
                text-sm font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-brandBlue-400 focus:ring-offset-2
                ${Object.keys(dirtyFields || []).includes('email') ? 'bg-brandBlue-500 hover:bg-brandBlue-600  text-white border-transparent'
                : 'bg-white text-gray-300 hover:bg-gray-50 border-gray-200'}`}
            >
              {t('saveChanges')}
              {(updating === 'email')
                && (
                  <MiniSpinner className="h-3 w-3 ml-2 mt-1 animate-spin text-white text-sm" />
                )}
            </button>
            {showEmailConfirmationCode && (
              <>
                <div className="sm:max-w-sm sm:justify-self-stretch col-start-2">
                  <input
                    type="number"
                    step="1"
                    name="emailConfirmationCode"
                    id="emailConfirmationCode"
                    autoComplete="false"
                    className="flex-1 focus:ring-brandBlue-400 focus:border-brandBlue-400 block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
                    {...useFormObject.register('emailConfirmationCode')}
                  />
                  <div className="flex items-baseline ml-1">
                    <span className="animate-pulse inline-flex h-3 w-3 shadow-sm rounded-full bg-brandYellow-500 opacity-75" />
                    <p className="pl-2 pt-2 text-sm text-gray-700">{t('pleaseEnterCode')}</p>
                  </div>
                  {/* only display errors when the field content has changed */}
                  {(Object.keys(dirtyFields || []).includes('emailConfirmationCode')) && (
                    <div className="pt-2 sm:text-sm text-brandRed-500">
                      {errors.emailConfirmationCode && <span>{errors.emailConfirmationCode.message}</span>}
                    </div>
                  )}
                </div>
                <button
                  type="button"
                  disabled={!Object.keys(dirtyFields || []).includes('emailConfirmationCode')}
                  onClick={handleEmailConfirmationCode}
                  // -ml-24 is meant to eliminate a large gap between field and button that appears in 2xl
                  className={`sm:ml-3 2xl:-ml-24 inline-flex justify-center rounded-md border py-2 px-4
                  text-sm font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-brandBlue-400 focus:ring-offset-2
                  ${Object.keys(dirtyFields || []).includes('emailConfirmationCode') ? 'bg-brandBlue-500 hover:bg-brandBlue-600  text-white border-transparent'
                    : 'bg-white text-gray-300 hover:bg-gray-50 border-gray-200'}`}
                >
                  {t('Submit')}
                  {(updating === 'emailConfirmationCode')
                    && (
                      <MiniSpinner className="h-3 w-3 ml-2 mt-1 animate-spin text-white text-sm" />
                    )}
                </button>
              </>
            )}

          </div>

          <div className="space-y-3 sm:grid sm:grid-cols-3 sm:items-start sm:justify-items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5 text-sm">
            <p>{t('Username')}</p>
            <div className="sm:max-w-sm sm:justify-self-stretch">
              <SignUp1.InputUsername register={register} />
              {/* only display errors when the field content has changed */}
              {(Object.keys(dirtyFields || []).includes('username')) && (
                <div className="pt-2 sm:text-sm text-brandRed-500">
                  {errors.username && <span>{errors.username.message}</span>}
                </div>
              )}
            </div>
            <button
              type="button"
              disabled={!Object.keys(dirtyFields || []).includes('username')}
              onClick={(e) => handleUpdateAttribute(e, 'username', watch('username'))}
              className={`sm:ml-3 2xl:-ml-24 inline-flex justify-center rounded-md border py-2 px-4
                text-sm font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-brandBlue-400 focus:ring-offset-2
                ${Object.keys(dirtyFields || []).includes('username') ? 'bg-brandBlue-500 hover:bg-brandBlue-600  text-white border-transparent'
                : 'bg-white text-gray-300 hover:bg-gray-50 border-gray-200'}`}
            >
              {t('saveChanges')}
              {(updating === 'username')
                && (
                  <MiniSpinner className="h-3 w-3 ml-2 mt-1 animate-spin text-white text-sm" />
                )}

            </button>
          </div>
          {/* PASSWORD SECTION */}
          <div className="space-y-3 sm:grid sm:grid-cols-3 sm:items-start sm:justify-items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5 text-sm">
            <p>{t('Password')}</p>
            <button
              type="button"
              onClick={() => setPasswordChange(!passwordChange)}
              className={`inline-flex justify-center rounded-md border py-2 px-4 mb-4 sm:col-span-2
                text-sm font-medium focus:outline-none focus:ring-2 focus:ring-brandBlue-400 focus:ring-offset-2
                ${!passwordChange ? 'shadow-sm bg-white hover:bg-gray-50  text-brandBlue-500 border-brandBlue-400' : 'inset bg-gray-200 text-gray-400 hover:bg-gray-300 border-gray-200'}`}
            >
              {t('changePassword')}
            </button>
            {passwordChange && (
              <>
                <p>{t('Enter your current password')}</p>
                <div className="sm:max-w-sm sm:col-span-2">
                  <input
                    type="password"
                    name="currentPassword"
                    id="currentPassword"
                    {...register('currentPassword', {
                      required: { value: true, message: t('Please enter your current password.') },
                      validate: (value) => (value !== password.current && value !== repeatPassword.current) || t('The new password cannot be the same as the current password.'),
                    })}
                    className="flex-1 font-base text-gray-700 focus:ring-brandBlue-400 focus:border-brandBlue-400 block w-full min-w-0 rounded-md border border-gray-300 "
                  />
                  {errors.currentPassword
                    && (
                      <div className="pt-2 sm:text-sm text-brandRed-500 max-w-fit">
                        <span>{errors.currentPassword.message}</span>
                      </div>
                    )}
                </div>
                <p>{t('Enter new password')}</p>
                <div className="sm:max-w-sm sm:col-span-2">
                  <SignUp1.InputPassword1 useFormObject={useFormObject} setShowPasswordTip={setShowPasswordTip} repeatPassword={repeatPassword} />
                  {(Object.keys(dirtyFields || []).includes('password')) && (
                    <div className="pt-2 sm:text-sm text-brandRed-500 max-w-fit">
                      {errors.password && <span>{errors.password.message}</span>}
                    </div>
                  )}
                </div>
                <p className="">{t('Repeat new password')}</p>
                <div className="sm:max-w-sm sm:col-span-2">
                  <SignUp1.InputPassword2 useFormObject={useFormObject} setShowPasswordTip={setShowPasswordTip} password={password} />
                  {(Object.keys(dirtyFields || []).includes('repeatPassword')) && (
                    <div className="pt-2 sm:text-sm text-brandRed-500 max-w-fit">
                      {errors.repeatPassword && <span>{errors.repeatPassword.message}</span>}
                    </div>
                  )}
                </div>
                <button
                  type="submit" // this is the only button that is a submit button
                  onClick={onSubmitNewPassword}
                  className={`sm:col-start-2 inline-flex justify-center rounded-md border py-2 px-4
                text-sm font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-brandBlue-400 focus:ring-offset-2
                ${(!getFieldState('password').invalid && !getFieldState('repeatPassword').invalid) ? 'bg-brandBlue-500 hover:bg-brandBlue-600  text-white border-transparent'
                    : 'bg-white text-gray-300 hover:bg-gray-50 border-gray-200'}`}
                >
                  {t('saveChanges')}
                  {(updating === 'password')
                    && (
                      <MiniSpinner className="h-3 w-3 ml-2 mt-1 animate-spin text-white text-sm" />
                    )}
                </button>
              </>
            )}
          </div>
          {/* MFA SECTION */}
          <div className="space-y-3 sm:grid sm:grid-cols-3 sm:items-start sm:justify-items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5 text-sm">
            <p>{t('2fa')}</p>
            {/* ENABLE MFA button */}
            {(user?.preferredMFA === 'NOMFA' && !showTwoFactorAuth)
              && (
                <button
                  type="button"
                  onClick={handleEnable2FA}
                  className={`inline-flex justify-center rounded-md border py-2 px-4 mb-4 sm:col-span-2
                text-sm font-medium focus:outline-none focus:ring-2 focus:ring-brandBlue-400 focus:ring-offset-2
                ${!showTwoFactorAuth ? 'shadow-sm bg-white hover:bg-gray-50  text-brandBlue-500 border-brandBlue-400' : 'inset bg-gray-200 text-gray-400 hover:bg-gray-300 border-gray-200'}`}
                >
                  {t('Enable')}
                </button>
              )}
            {(user?.preferredMFA === 'SOFTWARE_TOKEN_MFA') && (
              // MFA IS ENABLED
              <div className="flex gap-2 items-center justify-start">
                <CheckCircleIcon className="h-5 w-5 mr-1 text-green-500" />
                {t('Enabled')}
              </div>
            )}
            {showTwoFactorAuth && (
              // HANDLE ENABLE MFA PROCESS
              <>
                <div className="col-start-2">
                  <p className="text-sm text-gray-500 mb-4">
                    {t('qrCode1')}
                    {' '}
                    <a
                      href="https://support.google.com/accounts/answer/1066447?hl=en&co=GENIE.Platform%3DAndroid"
                      target="_blank"
                      className="hover:underline group"
                      rel="noreferrer"
                    >
                      Google Authenticator
                      <ArrowTopRightOnSquareIcon className="inline h-4 w-4 ml-1 text-gray-400 group-hover:text-gray-500" />
                    </a>
                    {' '}
                    {t('qrCode2')}
                    {' '}
                    <a
                      href="https://support.apple.com/guide/iphone/automatically-fill-in-verification-codes-ipha6173c19f/ios"
                      target="_blank"
                      className="hover:underline group"
                      rel="noreferrer"
                    >
                      Apple Passwords
                      <ArrowTopRightOnSquareIcon className="inline h-4 w-4 ml-1 text-gray-400 group-hover:text-gray-500" />
                    </a>
                  </p>
                  <QRCodeSVG value={QRString} />
                </div>
                <div className="w-full">
                  <p className="text-sm text-gray-500 leading-5">
                    {t('manualEntry')}
                  </p>
                  <p className="text-base text-gray-900 mt-2" style={{ overflowWrap: 'anywhere', fontFamily: 'monospace' }}>{MFASetUpCode}</p>
                </div>
                <div className="col-start-2 col-span-2 flex flex-col space-y-2 text-gray-500">
                  <p>{t('enterAuthCode')}</p>
                  <div className="flex-1 w-full flex justify-left items-center space-x-2">
                    <input
                      name="confirmationCode"
                      type="number"
                      id="confirmationCode"
                      {...register('confirmationCode', {
                        maxLength: 6,
                        minLength: 6,
                      })}
                      className="p-2 focus:ring-brandBlue-400 focus:border-brandBlue-400 block w-1/3 min-w-0 rounded-md sm:text-sm border border-gray-300"
                    />
                    <button
                      type="button"
                      disabled={!confirmationCodeReady}
                      onClick={handleSubmitMfaConfirmationCode}
                      className={`inline-flex justify-center rounded-md border py-2 px-4 max-w-md
                text-sm font-medium focus:outline-none focus:ring-2 focus:ring-brandBlue-400 focus:ring-offset-2
                ${confirmationCodeReady ? 'shadow-sm bg-white hover:bg-gray-50  text-brandBlue-500 border-brandBlue-400' : 'inset bg-gray-200 text-gray-400 hover:bg-gray-300 border-gray-200'}`}
                    >
                      {t('Confirm')}
                      {(updating === 'confirmationCode') && (<MiniSpinner className="h-3 w-3 ml-2 mt-1 animate-spin text-white text-sm" />)}
                    </button>
                  </div>
                  {errors.confirmationCode
                    && (
                      <div className="pt-2 sm:text-sm text-brandRed-500 max-w-fit">
                        <span>{errors.confirmationCode.message}</span>
                      </div>
                    )}

                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
