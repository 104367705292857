/* eslint-disable import/no-extraneous-dependencies */
import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from './tailwind.config'; // Fix the path

const fullConfig = resolveConfig(tailwindConfig);
export const screens = { xs: '475px', ...fullConfig.theme.screens }; // {xs: '475px', sm: '640px', md: '768px', lg: '1024px', xl: '1280px', 2xl: '1536px'}

export const breakpoints = Object.keys(screens);

/**
 * @returns {string|null} The current breakpoint or null if the current window width is below the smallest breakpoint
 */
export default function getCurrentBreakpoint() {
  const sortedBreakpointsBelowCurrentWidth = Object.entries(screens)
    .map(([key, value]) => [key, Number(value.replace('px', ''))])
    .sort(([_, a], [__, b]) => b - a)
    .filter(([_, a]) => window.innerWidth >= a);
  if (sortedBreakpointsBelowCurrentWidth.length === 0) {
    return null;
  }
  return sortedBreakpointsBelowCurrentWidth[0][0];
}
