/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { quote } from '@monestry-dev/schema';
import { set } from 'lodash';
import { putOrDeleteQuotes } from '../../redux/reducers/quotes';
import { setMessage } from '../../redux/actions/message';
import { globalQuotesArrayView } from '../../redux/reducers/globalSelectors';
import { controlData, questions, calculateValue, getMissingFields } from './unlistedAddValuationControl';
import ButtonBar from './ButtonBar';
import { getButtonsToShow } from './GridLayout';
import OptionButtons from '../../elements/OptionButtons';
import OptionButtonsMultiple from '../../elements/OptionButtonsMultiple';
import OptionArray from '../../elements/OptionArray';
import InputField from '../addAccount/accountWorkflow/InputField';
import ToolTip from '../../elements/ToolTip';
import Button from '../../elements/Button';

dayjs.extend(utc);

export default function UnlistedAddValuationWizard(props) {
  const { t } = useTranslation('app', { keyPrefix: 'accountDetails.unlistedShares.wizard.valuation' });
  const { t: tMethods } = useTranslation('app', { keyPrefix: 'accountDetails.unlistedShares.wizard.valuation.methods' });

  const selectQuotes = useSelector(globalQuotesArrayView);
  const baseCurrency = useSelector((state) => state.user.profile.settings.baseCurrency);
  // all quotes are manual in unlisted; quotes API returns all manual quotes "which change something",
  // i.e. which were the one actually entered by users (as opposed to those generated from a past quote for a requested date)
  // so we want to see only them (they are the ones with date === quoteDate)
  const quotes = (selectQuotes.filter((q) => q.assetId === props.account.id && q.date === q.quoteDate) || [])
    .sort((a, b) => a.date - b.date)
    // adding the empty object for a new quote (to be created in the wizard)
    .concat([{}]);
  console.log('DEBUG quotes', quotes);

  // determines which quote from quotes array do we currently see (initialise on the last position, which is the empty object for a new quote)
  const [unlistedQuoteIndex, setUnlistedQuoteIndex] = useState(quotes.length - 1);

  const formObject = useForm({
    defaultValues: {},
  });
  const { register, reset, watch, formState, setValue, handleSubmit, control } = formObject;
  const watchAll = watch();
  const { stage, valuationMethods } = watchAll;

  const [displayedQuestions, setDisplayedQuestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const dispatch = useDispatch();

  // -----------------------------
  // HANDLE PREV / NEXT BUTTONS
  // -----------------------------

  // helper: reset the form and updated the index in one function
  function resetAndSetIndex(newIndex) {
    setUnlistedQuoteIndex(newIndex);
    // initial quotes (added in the add account process) have no valuation parameters as stringified JSON, so we need to catch that
    let note;
    try {
      note = JSON.parse(quotes[newIndex].note || '{}');
    } catch (err) {
      console.error(err);
      note = quotes[newIndex].note;
    }
    reset({
      date: dayjs.utc(quotes[newIndex].date || undefined).format('YYYY-MM-DD'),
      quote: quotes[newIndex].quote,
      quoteBaseCurrency: quotes[newIndex].quoteBaseCurrency,
      projectId: quotes[newIndex].projectId,
      source: 'manual',
      assetId: quotes[newIndex].assetId,
      // ...JSON.parse(quotes[newIndex].note || '{}'),
      ...(typeof note === 'object' ? note : {}),
    });
  }

  function handlePrevNext(direction) {
    const newIndex = direction === 'next' ? unlistedQuoteIndex + 1 : unlistedQuoteIndex - 1;

    // Ensure the new index is within bounds
    if (newIndex >= 0 && newIndex < quotes.length) {
      // if form is dirty, ask permission first
      if (formState.isDirty) {
        // before navigating away from the form, display array
        const event = new CustomEvent('setAlert', {
          detail: {
            id: 'aboutToLoseData',
            caller: 'UnlistedAddValuationWizard',
            callbackOk: () => {
              try {
                resetAndSetIndex(newIndex);
              } catch (err) {
                console.error(err);
              }
            },
          },
        });
        window.dispatchEvent(event);
      } else {
        // if the form is not dirty, just navigate away
        resetAndSetIndex(newIndex);
      }
    }
  }

  // -----------------------------
  // HANDLE SUBMIT
  // -----------------------------

  // submit button is enabled only when the form is VALID and DIRTY
  async function onSubmit(inputQuote) {
    setLoading(true);
    // it seems difficult to remove fields from form state as they unregister (especially useFieldArray fields)
    // therefore remove all fields here which are not in displayedQuestions
    const trimmedQuote = Object.entries(inputQuote)
      .filter(([key]) => displayedQuestions.includes(key))
      .reduce((acc, [key, value]) => {
        acc[key] = value;
        return acc;
      }, {});

    // remember to move everything exept quote and date under note and stringify it
    let transformedQuote;
    try {
      transformedQuote = quote.cast(
        {
          ...trimmedQuote,
          // apply quotes in proportion to the percent owned indicated by user; if there is no percent owned, assume 100% (i.e. intial quote for the account)
          quote: Number(inputQuote.quote) * (Number(inputQuote.percentOwned) / 100 || 1),
          assetId: props.account.id,
          quoteDate: dayjs.utc(inputQuote.date).valueOf(),
          date: dayjs.utc(inputQuote.date).valueOf(),
          quoteBaseCurrency: Number(inputQuote.quoteBaseCurrency) * (Number(inputQuote.percentOwned) / 100 || 1),
          currency: props.account.currency,
          source: 'manual',
          projectId: null,
          note: null,
        },
        { stripUnknown: true },
      );
    } catch (err) {
      console.error(err);
      dispatch(setMessage('validationError'));
    }

    // get every property which is NOT in trimmedQuote and put it in its note property as stringified JSON
    // remember NOT to save quote properties in notes
    try {
      const note = JSON.stringify(
        Object.entries(inputQuote)
          .filter(([key]) => !Object.keys(transformedQuote).includes(key))
          .reduce((acc, [key, value]) => {
            acc[key] = value;
            return acc;
          }, {}),
      );

      await dispatch(
        putOrDeleteQuotes({
          arrayOfQuotes: [
            {
              ...transformedQuote,
              note,
            },
          ],
          accountId: props.account.id,
        }),
      );
    } catch (err) {
      console.error(err);
      dispatch(setMessage('validationError'));
    }
    setLoading(false);
    props.setDisplayedComponent('table');
  }

  // -----------------------------
  // HANDLE DELETE
  // -----------------------------

  function handleDelete() {
    setDeleting(true);
    // if this is a "previous" quote (i.e. not the last item of the array), ask confirmation and send a delete to backend
    // if this is a "new" quote and it is dirty, reset the form (ask confirmation first)
    // if this is a "new" quote and it is NOT dirty, do nothing (disabled delete button should not allow this to be executed)
    const thisIsTheLastQuote = unlistedQuoteIndex === quotes.length - 1;
    // first check if the form is dirty, and if it is, display warning
    if ((thisIsTheLastQuote && formState.isDirty) || !thisIsTheLastQuote) {
      // before navigating away from the form, display array
      const event = new CustomEvent('setAlert', {
        detail: {
          id: 'youAreAboutToDeleteValuation',
          caller: 'UnlistedAddValuationWizard',
          callbackOk: async () => {
            try {
              if (!thisIsTheLastQuote) await dispatch(putOrDeleteQuotes({ arrayOfQuotes: [{ ...quotes[unlistedQuoteIndex], importFlag: 'delete' }], accountId: props.account.id }));
              else resetAndSetIndex(quotes.length - 1);
              setDeleting(false);
              props.setDisplayedComponent('table');
            } catch (err) {
              console.error(err);
              setDeleting(false);
            }
          },
        },
      });
      window.dispatchEvent(event);
    }
    setDeleting(false);
  }

  // -----------------------------
  // HANDLE FORM MECHANICS
  // -----------------------------

  const stageButtons = [
    { id: 'idea', name: t('stage.idea.label') },
    { id: 'prototype', name: t('stage.prototype.label') },
    { id: 'mvp', name: t('stage.mvp.label') },
    { id: 'expansion', name: t('stage.expansion.label') },
    { id: 'market', name: t('stage.market.label') },
  ];

  const allMethods = [
    { id: 'berkus', name: t('methods.berkus.label'), tooltip: t('methods.berkus.tooltip') },
    { id: 'scorecard', name: t('methods.scorecard.label'), tooltip: t('methods.scorecard.tooltip') },
    { id: 'riskFactor', name: t('methods.riskFactor.label'), tooltip: t('methods.riskFactor.tooltip') },
    { id: 'valuationByStage', name: t('methods.valuationByStage.label'), tooltip: t('methods.valuationByStage.tooltip') },
    { id: 'replacementCost', name: t('methods.replacementCost.label'), tooltip: t('methods.replacementCost.tooltip') },
    { id: 'bookValue', name: t('methods.bookValue.label'), tooltip: t('methods.bookValue.tooltip') },
    { id: 'fiveTimesYourRise', name: t('methods.fiveTimesYourRise.label'), tooltip: t('methods.fiveTimesYourRise.tooltip') },
    { id: 'dcf', name: t('methods.dcf.label'), tooltip: t('methods.dcf.tooltip') },
  ];

  // when user selects a stage, show only the methods that apply to that stage (part of the valuation business object, so update it there)
  useEffect(() => {
    if (stage) {
      const methodsPerStage = controlData.filter((method) => method.appliesTo.includes(stage));
      const allMethodsToShow = allMethods.filter((method) => methodsPerStage.map((m) => m.id).includes(method.id));
      setValue(
        'valuationMethods',
        allMethodsToShow.map((method) => ({ id: method.id, selected: true })),
      );
    }
  }, [stage]);

  // generate a list of questions to be displayed in reaction to changes of method selection
  useEffect(() => {
    const questionIds = new Set();
    if (valuationMethods) {
      // get a list of unique questionIds for all currently selected methods using a Set
      valuationMethods
        .filter((method) => method.selected)
        .forEach((selectedMethod) => {
          const methodObject = controlData.find((method) => method.id === selectedMethod.id);
          methodObject.questions.forEach((question) => questionIds.add(question));
        });

      // update the component state (question components will self-register in form state)
      setDisplayedQuestions(Array.from(questionIds));
    } else {
      // if there are no valuationMethods (like for example when we go back from previous to new quote), show no questions
      setDisplayedQuestions([]);
    }
  }, [stage, valuationMethods]);

  // -----------------------------
  // RENDER COMPONENT
  // -----------------------------

  return (
    <div className="w-full h-full">
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <ButtonBar {...props} buttonsToShow={getButtonsToShow('unlistedShares')} handlePrevNext={handlePrevNext} />
      <form className="mt-2 w-full h-full grid lg:grid-cols-2 xl:grid-cols-3 py-8 px-2 overflow-y-auto" onSubmit={handleSubmit(onSubmit)}>
        {/* COL 1: STAGES + METHODS */}

        <section id="unlisted-add-valuation-col-1" className="space-y-8">
          <div>
            <h3 className="text-lg font-bold">{t('title')}</h3>
            <p className="text-sm mt-2">{t('description')}</p>
          </div>
          <OptionButtons control={control} id="stage" label={t('stage.label')} tooltip={t('stage.tooltip')} forceFlex fullWidth overrideOptions={stageButtons} />
          {stage && (
            <OptionButtonsMultiple
              control={control}
              id="valuationMethods"
              label={t('valuationMethod.label')}
              tooltip={t('valuationMethod.tooltip')}
              tPrefix="accountDetails.unlistedShares.wizard.valuation.methods"
            />
          )}
        </section>

        {/* COL 2: QUESTIONS */}

        <section className="space-y-8 overflow-y-auto mb-8" id="unlisted-add-valuation-col-2">
          {displayedQuestions.map((question) => {
            const questionObject = questions.find((q) => q.id === question);
            if (questionObject.type === 'score') {
              return (
                <OptionButtons
                  key={question}
                  control={control}
                  id={question}
                  label={t(`${question}.label`)}
                  tooltip={t(`${question}.tooltip`)}
                  tPrefix="accountDetails.unlistedShares.wizard.valuation"
                />
              );
            }
            if (questionObject.type === 'array') {
              return (
                <OptionArray
                  key={question}
                  formObject={formObject}
                  control={control}
                  currency={props.account.currency}
                  name={question}
                  label={t(`${question}.label`)}
                  tooltip={t(`${question}.tooltip`)}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...(questionObject.id === 'cashFlowArray' && { namePlaceholder: t('cashFlowArray.namePlaceholder') })}
                />
              );
            }
            if (questionObject.type === 'number') {
              return (
                <InputField
                  key={question}
                  id={question}
                  type="text"
                  register={register}
                  errors={formState.errors}
                  // rate fields have a placeholder which looks like a percentage; the rest are amounts, so they have a placeholder that looks like an amount
                  placeholder={['discountRate', 'longTermGrowthRate'].includes(questionObject.id) ? '8' : '100000'}
                  label={t(`${question}.label`)}
                  toolTip={t(`${question}.tooltip`)}
                  category="accountDetails.unlistedShares.wizard.valuation"
                  formatting="w-1/2"
                  optional={false}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...(questionObject.id === 'longTermGrowthRate' && {
                    validationRules: { validate: (value) => parseFloat(value) <= watchAll.discountRate || 'Value must be greater than discount rate.' },
                  })}
                />
              );
            }
            return null;
          })}
        </section>

        {/* COL 3: RESULTS */}
        <section className="space-y-8 p-4" id="unlisted-add-valuation-col-3">
          <div className="border border-gray-100 bg-white round-lg p-4">
            <h2 className="text-sm font-bold uppercase">{t('finalQuote.label')}</h2>
            <p className="mt-1 mb-2 text-sm">{t('finalQuote.description')}</p>
            <InputField
              id="date"
              type="date"
              register={register}
              errors={formState.errors}
              placeholder={dayjs().format('YYYY-MM-DD')}
              disableLabel
              toolTip={t('finalQuote.tooltip')}
              category="accountDetails.unlistedShares.wizard.valuation"
              formatting="w-1/2"
              optional={false}
              validationRules={{ required: { value: true, message: t('finalQuote.validation.required') } }}
            />
            <InputField
              id="quote"
              type="text"
              register={register}
              errors={formState.errors}
              placeholder="100000"
              disableLabel
              toolTip={t('finalQuote.tooltip')}
              category="accountDetails.unlistedShares.wizard.valuation"
              formatting="w-1/2"
              optional={false}
              validationRules={{ required: { value: true, message: t('finalQuote.validation.required') } }}
              currency={props.account.currency}
            />
            {props.account.currency !== baseCurrency && (
              <InputField
                id="quoteBaseCurrency"
                type="text"
                register={register}
                errors={formState.errors}
                placeholder="100000"
                disableLabel
                toolTip={t('finalQuote.tooltip')}
                category="accountDetails.unlistedShares.wizard.valuation"
                formatting="w-1/2"
                optional={false}
                validationRules={{ required: { value: true, message: t('finalQuote.validation.required') } }}
                currency={baseCurrency}
              />
            )}
            <InputField
              id="percentOwned"
              type="text"
              label="Percent Owned"
              register={register}
              errors={formState.errors}
              placeholder="100"
              toolTip={t('percentOwned.tooltip')}
              category="accountDetails.unlistedShares.wizard.valuation"
              formatting="w-1/2"
              optional={false}
              validationRules={{ required: { value: true, message: t('finalQuote.validation.required') } }}
              currency="%"
            />
            <div className="flex mt-4 space-x-2">
              <Button type="submit" text={t('finalQuote.save')} withAccent className="mt-2" enabled={formState.isValid && formState.isDirty && !deleting} size="xl" spinnerOn={loading} />
              <Button type="button" text={t('finalQuote.deleteThisQuote')} className="mt-2" size="xl" onClick={handleDelete} enabled={!loading} spinnerOn={deleting} />
            </div>
          </div>
          {(valuationMethods || [])
            .filter((o) => o.selected)
            .map((methodObject) => {
              const value = calculateValue(methodObject.id, watchAll);
              return (
                <div key={methodObject.id}>
                  <h2 className="text-sm font-bold uppercase">{tMethods(`${methodObject.id}.label`)}</h2>
                  {value ? (
                    <p className="mt-1 text-xl font-bold">{(value || '').toLocaleString('de', { style: 'currency', currency: props.account.currency, maximumFractionDigits: 0 })}</p>
                  ) : (
                    <div className="flex space-x-0.5 items-baseline">
                      <p className="mt-1 text-sm italic text-gray-400">{t('insufficientData')}</p>
                      <ToolTip
                        formatting="-mb-1"
                        info={t('insufficientDataTooltip', {
                          fields: getMissingFields(methodObject.id, watchAll)
                            .map((x) => t(`${x}.label`))
                            .join(', '),
                        })}
                      />
                    </div>
                  )}
                </div>
              );
            })}
        </section>
      </form>
    </div>
  );
}
UnlistedAddValuationWizard.propTypes = {
  account: PropTypes.shape({
    currency: PropTypes.string,
    id: PropTypes.string,
  }).isRequired,
  data: PropTypes.array.isRequired,
  unlistedQuoteIndex: PropTypes.number.isRequired,
  setDisplayedComponent: PropTypes.func.isRequired,
};
