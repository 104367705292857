const inflowsByType = {
  id: 'inflowsByTypexxxxxxxx',
  name: 'Income from capital vs other inflows',
  dataScope: 'snapshots',
  cols: [
    'inflowType',
  ],
  rows: [
    'Date (Year)',
  ],
  vals: [
    'inflowValue',
  ],
  aggregatorName: 'Sum',
  rendererName: 'Bar chart, vertical, grouped',
  valueFilter: {},
  readOnly: true,
};

export default inflowsByType;
