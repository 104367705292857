/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect, shallowEqual } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';
import { useTranslation } from 'react-i18next';
import Grid from './Grid';
import ButtonBar from './ButtonBar';
import { getButtonsToShow } from './GridLayout';
import getGridLayout from './gridLayouts';
import * as importDeposits from './import/deposits';
import * as importStocks from './import/stocksTransactions';
import * as importRealEstateQuotes from './import/realEstate';
import { putQuotes } from '../../redux/reducers/quotes';
import { globalQuotesView, postFileImportData } from '../../redux/reducers/data';
import * as common from './params/common';
import MiniSpinner from '../../misc/MiniSpinner';

function sortArrayByDateAndSource(a, b) {
  // First sort by descending date
  if (a.date > b.date) return -1;
  if (a.date < b.date) return 1;

  // If dates are the same, sort by ascending source
  if (a.source < b.source) return -1;
  if (a.source > b.source) return 1;

  return 0;
}

export default function DuplicateCheck({
  account,
  displayedComponent,
  setDisplayedComponent,
  displayedComponentMode,
  setDisplayedComponentMode,
  userChangesPresent,
  setUserChangesPresent,
  tableState,
  setAccount,
}) {
  // expects a concatenated, single array of all transactions from all input files
  // runs the duplicate check; if duplicates found, they are displayed in a Grid for use to take action on
  // if no duplicates found, the data are posted

  let categoryModule;
  switch (account.category) {
    case 'realEstate': // !!! realEstate only has quotes enabled, so leaving this logic as is for now
      categoryModule = importRealEstateQuotes;
      break;
    case 'stocks':
      categoryModule = importStocks;
      break;
    case 'deposits':
      categoryModule = importDeposits;
      break;
    default:
      categoryModule = {};
  }

  const [gridData, setGridData] = useState([]);
  const [runSave, setRunSave] = useState(false); // if this turns to true, the Grid component will execute handleSaveWrapper
  // we are using state to manage that becuase handleSaveWrapper must run in the context of Grid, not here

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const importedDataSelector = createSelector(
    (state) => state.data[account.category]?.importFiles,
    (importFiles) => ({
      nextFileIndex: importFiles?.nextFileIndex,
      data: importFiles.transformed?.map((tt) => ({ ...tt, keepFlag: true, source: 'import' })),
    }),
    {
      memoizeOptions: {
        // equalityCheck: (a, b) => a === b,
        // maxSize: 10,
        resultEqualityCheck: shallowEqual,
      },
    },
  );

  function markDuplicateTransactions(_transactions) {
    for (let i = 0; i < _transactions.length; i += 1) {
      const transaction1 = _transactions[i];
      for (let j = i + 1; j < _transactions.length; j += 1) {
        const transaction2 = _transactions[j];
        if (categoryModule.checkIfDuplicate(transaction1, transaction2)) {
          transaction1.isDuplicate = true;
          transaction2.isDuplicate = true;
        }
      }
    }
    return _transactions;
  }

  const accountDataSelector = createSelector(
    (state) => state.data[account.category].transactions,
    ($transactions) => $transactions
      .filter((tt) => tt.accountId === account.id)
    // (deposits only) handle case when someone already split an imported transaction
      .reduce((prev, curr) => {
        // this applies only to deposit transactions, so if this is not a deposit transaction, just return it
        console.log(prev, curr);
        if (curr.category !== 'deposits') return [...prev, curr];
        // first filter out transactions which do not have a parentId
        if (!curr.parentId) return [...prev, curr];
        // is the parent transaction already in prev?
        const parentTransactionIndex = prev.findIndex((tt) => tt.parentId === curr.parentId);
        // if there is none, this transaction is the first one to belong to that parentId supertransaction
        if (parentTransactionIndex === -1) return [...prev, curr];
        // otherwise add amount to the already existing parentId supertransaction
        prev.push({ ...prev[parentTransactionIndex], accountCurrencyAmount: prev[parentTransactionIndex].accountCurrencyAmount + curr.accountCurrencyAmount });
        // date, accountCurrencyAmount and otherPartyAccount are relevant for duplicate check, so we need to update them
        return prev;
      }, [])
      .map((tt) => ({ ...tt, source: 'app' })),
    {
      memoizeOptions: {
        // equalityCheck: (a, b) => a === b,
        // maxSize: 10,
        resultEqualityCheck: shallowEqual,
      },
    },
  );

  const quotesSelector = createSelector(globalQuotesView, (quotes) => quotes);

  const selectTransformedData = useSelector(importedDataSelector) || [];
  const selectAccountData = useSelector(accountDataSelector);
  const selectQuotes = useSelector(quotesSelector);

  const dataForDuplicateCheck = [...selectTransformedData.data, ...selectAccountData];
  const { nextFileIndex } = selectTransformedData;

  const gridLayout = getGridLayout(account.category, displayedComponentMode, 'duplicate');

  // helper function used in Grid (see one below)

  function prepareTransactionForPost(dataIn) {
    return (
      dataIn
        // handle transactions that were marked as duplicates (coming from Grid) only
        .map((item) => {
          const inputTransaction = selectTransformedData.data.find((transaction) => transaction.id === item.id);
          const importFlag = 'post'; // all imported transactions are new

          return {
            ...inputTransaction, // spread the original transaction from before Grid first
            ...item, // spread the modified transaction from Grid on top of that
            ...(displayedComponentMode === 'transactions' && { accountId: account.id }), // 'post' transactions won't have accountId or currency, so we need to add them here
            ...(account.category === 'deposits' && { currency: account.currency }), // add currency to deposits (stocks arrive with its own transactionCurrency)
            importFlag,
          };
        })
        .concat(markDuplicateTransactions(dataForDuplicateCheck).filter((transaction) => !transaction.isDuplicate)) // add all transactions that were not duplicates (they should be complete)
        .map(categoryModule.outputTransformCategoryTransactions(account))
    ); // this is here for compatibility with the existing code, but it's not needed for deposits (it does nothing)
  }

  // TRANSFORM OUTPUT (executed in Grid, needs gridLayout from context)

  function prepAndValidateOutputData(data) {
    // transform data from Grid to the "canonical" format
    const transformedData = data
      .filter((row) => row.join('').length > 0) // remove completely empty rows
      .map((row) => gridLayout.reduce((prev, curr, idx) => ({ ...prev, [curr.id]: row[idx] }), {})); // convert array of arrays to array of objects

    // remove grid formatting
    const dataAfterReformatting = common.removeGridFormatting(transformedData, gridLayout);

    // perform category-specific transformations, add importFlag
    const dataAfterCategorySpeficicTransformations = prepareTransactionForPost(dataAfterReformatting);

    return { status: 'success', data: dataAfterCategorySpeficicTransformations };
  }

  // COMPONENT INITIALISATION
  // if there are no duplicates, post the data
  // if there are, setGridData to display them in Grid for the user to take action

  useEffect(() => {
    // quotes bypass duplicate check and head straight to dispatch
    if (displayedComponentMode === 'quotes') {
      dispatch(
        putQuotes({
          body: selectTransformedData.data,
          category: account.category,
          accountId: account.id,
        }),
      ).then(() => {
        if (nextFileIndex !== null) {
          // if ColumnMatcher set this flag, go back to ColumnMatcher and deal with the next file
          setDisplayedComponent('column');
        } else {
          setDisplayedComponent('table');
        }
      });
    } else if (selectTransformedData.data.length > 0) {
      // when this is run the first time after component renders, it already has the data in both selects
      // we can assume the incoming file is not empty (it's checked in DialogSelectFile)
      // run duplicate check
      const transactionsWithDuplicateFlag = markDuplicateTransactions(dataForDuplicateCheck);

      // if duplicates found, display them in a Grid for user to take action on
      // if no duplicates found, post the data
      if (transactionsWithDuplicateFlag.some((transaction) => transaction.isDuplicate)) {
        const prepDataForGrid = transactionsWithDuplicateFlag
          .filter((transaction) => transaction.isDuplicate)
          .map(({ isDuplicate, ...tt }) => tt)
          .map(categoryModule.categoryOrderedObject(account, displayedComponent))
          .sort(sortArrayByDateAndSource);
        setGridData(prepDataForGrid);
      } else {
        console.log('no duplicates found');
        // post the data
        // isDuplicate, keepFlag and source will be removed by the action creator; filter by keepFlag and source will also be applied there
        // run general transformations from prepAndValidateOutputData (it is also run in Grid for duplicated data, but we need to run it here as well)
        // prepareDataForPost already gets all the non-duplicate transactions from the app and adds them to the data to be posted
        // dispatch({ type: 'data/fileImportedSuccessfully', payload: { category: account.category } });
        if (displayedComponentMode === 'transactions') {
          dispatch(
            postFileImportData({
              data: prepareTransactionForPost([]),
              category: account.category,
              accountId: account.id,
            }),
          ).then(() => {
            if (nextFileIndex !== null) {
              // if ColumnMatcher set this flag, go back to ColumnMatcher and deal with the next file
              setDisplayedComponent('column');
            } else {
              setDisplayedComponent('table');
            }
          });
        }
      }
    }
  }, []);

  if (gridData.length === 0) {
    return (
      <div className="flex items-center gap-2">
        <MiniSpinner className="ml-2 h-5 w-5 animate-spin" />
        <span className="text-gray-500">{t('')}</span>
      </div>
    );
  }
  return (
    <>
      <ButtonBar
        buttonsToShow={getButtonsToShow(account.category, 'duplicate')}
        displayedComponent={displayedComponent}
        setDisplayedComponent={setDisplayedComponent}
        displayedComponentMode={displayedComponentMode}
        setDisplayedComponentMode={setDisplayedComponentMode}
        handleSave={() => setRunSave(true)}
        userChangesPresent={userChangesPresent}
      />
      <Grid
        account={account} // to get current account status (after loading) and react to evtl. errors
        setAccount={setAccount} // close AccountDetails after saving
        data={gridData}
        gridLayout={gridLayout}
        prepAndValidateOutputData={prepAndValidateOutputData}
        postCategoryItems={categoryModule.postCategoryItems}
        tableState={tableState}
        displayedComponent="duplicate"
        setDisplayedComponent={setDisplayedComponent} // to go back to table after loading is finished,
        userChangesPresent={userChangesPresent} // to prevent closing AccountDetails without saving
        setUserChangesPresent={setUserChangesPresent} // to prevent closing AccountDetails without saving
        runSave={runSave}
        setRunSave={setRunSave}
      />
    </>
  );
}
DuplicateCheck.propTypes = {
  account: PropTypes.objectOf(PropTypes.any).isRequired,
  displayedComponent: PropTypes.string.isRequired,
  setDisplayedComponent: PropTypes.func.isRequired,
  displayedComponentMode: PropTypes.string.isRequired,
  setDisplayedComponentMode: PropTypes.func.isRequired,
  userChangesPresent: PropTypes.bool.isRequired,
  setUserChangesPresent: PropTypes.func.isRequired,
  tableState: PropTypes.objectOf(PropTypes.any).isRequired,
  setAccount: PropTypes.func.isRequired,
};
