import {
  GET_PROJECTS_SUCCESS,
  POST_PROJECT_SUCCESS,
  POST_PROJECT_FAIL,
  DELETE_PROJECT_SUCCESS,
} from '../actions/types';

// eslint-disable-next-line default-param-last
export default function projectsReducer(state = [], action) {
  const { type, payload } = action;

  switch (type) {
    case GET_PROJECTS_SUCCESS:
      return [
        ...payload,
      ];
    case POST_PROJECT_SUCCESS:
    case POST_PROJECT_FAIL:
    // payload is a complete Project object
    // remove current Project by that id from state (if existing)
    // and add the new one from payload
    // FAIL does technically the same as success, but with a different payload (the original state of store)
      return state
        .filter((project) => project.id !== payload.id)
        .concat(payload);
    case DELETE_PROJECT_SUCCESS:
      // payload is the complete project object or just its id in an object { id: 123 }
      return state
        .filter((project) => project.id !== payload.id);
    default:
      return state;
  }
}
