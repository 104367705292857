{
  "stocks": {
    "SKxazU1TIynqfmDvfUR1g": {
      "BBG001S5N8V8": [
        {
          "id": "EcHfZXVMS395YtxSQQbiB",
          "userId": "TestUserTier2Loadedxx",
          "date": 1541030400000,
          "accountId": "SKxazU1TIynqfmDvfUR1g",
          "figi": "BBG001S5N8V8",
          "providerAssetId": "AAPL",
          "transactionType": "purchase",
          "displayName": "Apple",
          "displaySymbol": "US0378331005",
          "errorId": null,
          "projectId": null,
          "isManuallyAdjusted": false,
          "isSimulated": false,
          "rebasedOriginalPrice": 50.5,
          "rebasedPrice": 44.3255,
          "rebasedAmount": 400,
          "rebasedAmountSold": -60,
          "rebasedAmountSoldWhenSimulated": -60,
          "transactionOriginalPrice": 202,
          "transactionCurrency": "USD",
          "transactionPrice": 177.3019,
          "transactionAmount": 100,
          "transactionAmountSold": -15,
          "splitRatioNew": null,
          "splitRatioOld": null,
          "tags": {
            "type": null,
            "indexed": null,
            "recurring": null
          },
          "sortingOrderWithinMonth": null,
          "currency": "USD",
          "linkedTransactions": [
            {
              "id": "7rcZwnrwaZIKhSQp60rPl",
              "category": "stocks",
              "tags": {
                "date": 1682985600000,
                "type": "sale",
                "isSimulated": false,
                "rebasedPrice": 151.39078887368902,
                "rebasedAmount": 60
              }
            }
          ],
          "category": "stocks",
          "assetId": "BBG001S5N8V8",
          "amount": 400,
          "originalUnitPrice": 50.5,
          "unitPrice": 44.3255,
          "accountName": "DKB Depot",
          "openAmount": 340,
          "unsold": 340,
          "rowType": "purchase",
          "positionOnDate": 400,
          "positionsPerProjectId": {
            "null": 400
          },
          "valueOnDateTransactionCurrency": 20200,
          "valueOnDate": 17730.2
        },
        {
          "date": 1638316800000,
          "assetId": "BBG001S5N8V8",
          "rowType": "kpi.referenceBaseline",
          "currency": "USD",
          "displayName": "Apple",
          "displaySymbol": "US0378331005",
          "accountName": "DKB Depot",
          "positionOnDate": 400,
          "positionsPerProjectId": {
            "null": 400
          },
          "valueOnDateTransactionCurrency": 65908,
          "valueOnDate": 58253.48
        },
        {
          "date": 1669852800000,
          "assetId": "BBG001S5N8V8",
          "rowType": "kpi.baseline",
          "currency": "USD",
          "displayName": "Apple",
          "displaySymbol": "US0378331005",
          "accountName": "DKB Depot",
          "positionOnDate": 400,
          "positionsPerProjectId": {
            "null": 400
          },
          "valueOnDateTransactionCurrency": 59324,
          "valueOnDate": 56747.64
        },
        {
          "id": "7rcZwnrwaZIKhSQp60rPl",
          "userId": "TestUserTier2Loadedxx",
          "date": 1682985600000,
          "accountId": "SKxazU1TIynqfmDvfUR1g",
          "figi": "BBG001S5N8V8",
          "providerAssetId": "AAPL",
          "transactionType": "sale",
          "displayName": "Apple",
          "displaySymbol": "US0378331005",
          "errorId": null,
          "projectId": null,
          "isManuallyAdjusted": false,
          "isSimulated": false,
          "rebasedOriginalPrice": 166,
          "rebasedPrice": 151.3908,
          "rebasedAmount": -60,
          "rebasedAmountSold": 0,
          "rebasedAmountSoldWhenSimulated": null,
          "transactionOriginalPrice": 166,
          "transactionCurrency": "USD",
          "transactionPrice": 151.3908,
          "transactionAmount": -60,
          "transactionAmountSold": 0,
          "splitRatioNew": null,
          "splitRatioOld": null,
          "tags": {
            "purchaseValue": 2659.527780215922,
            "incomeFromCapital": 6423.91955220542,
            "unableToMapToProviderAssetId": false
          },
          "sortingOrderWithinMonth": null,
          "currency": "USD",
          "linkedTransactions": [
            {
              "id": "EcHfZXVMS395YtxSQQbiB",
              "category": "stocks",
              "tags": {
                "date": 1541030400000,
                "type": "purchase",
                "isSimulated": false,
                "rebasedPrice": 44.3254630035987,
                "rebasedAmount": 60
              }
            }
          ],
          "category": "stocks",
          "assetId": "BBG001S5N8V8",
          "amount": -60,
          "originalUnitPrice": 166,
          "unitPrice": 151.3908,
          "accountName": "DKB Depot",
          "openAmount": -60,
          "rowType": "sale",
          "positionOnDate": 340,
          "positionsPerProjectId": {
            "null": 340
          },
          "valueOnDateTransactionCurrency": 56440,
          "valueOnDate": 51472.872
        },
        {
          "date": 1701475200000,
          "assetId": "BBG001S5N8V8",
          "rowType": "kpi.current",
          "currency": "USD",
          "displayName": "Apple",
          "displaySymbol": "US0378331005",
          "accountName": "DKB Depot",
          "positionOnDate": 340,
          "positionsPerProjectId": {
            "null": 340
          },
          "valueOnDateTransactionCurrency": 65021.600000000006,
          "valueOnDate": 59789.986000000004
        }
      ],
      "BBG001S5SYB8": [
        {
          "id": "8Dazmw3aq9ftU5tkiNdfY",
          "userId": "TestUserTier2Loadedxx",
          "date": 1556755200000,
          "accountId": "SKxazU1TIynqfmDvfUR1g",
          "figi": "BBG001S5SYB8",
          "providerAssetId": "BAS.F",
          "transactionType": "purchase",
          "displayName": "BASF",
          "displaySymbol": "DE000BASF111",
          "errorId": null,
          "projectId": null,
          "isManuallyAdjusted": false,
          "isSimulated": false,
          "rebasedOriginalPrice": 72.1,
          "rebasedPrice": 72.1,
          "rebasedAmount": 50,
          "rebasedAmountSold": 0,
          "rebasedAmountSoldWhenSimulated": 0,
          "transactionOriginalPrice": 72.1,
          "transactionCurrency": "EUR",
          "transactionPrice": 72.1,
          "transactionAmount": 50,
          "transactionAmountSold": 0,
          "splitRatioNew": null,
          "splitRatioOld": null,
          "tags": {
            "type": null,
            "indexed": null,
            "recurring": null
          },
          "sortingOrderWithinMonth": null,
          "currency": "EUR",
          "linkedTransactions": [],
          "category": "stocks",
          "assetId": "BBG001S5SYB8",
          "amount": 50,
          "originalUnitPrice": 72.1,
          "unitPrice": 72.1,
          "accountName": "DKB Depot",
          "openAmount": 50,
          "rowType": "purchase",
          "positionOnDate": 50,
          "positionsPerProjectId": {
            "null": 50
          },
          "valueOnDateTransactionCurrency": 3604.9999999999995,
          "valueOnDate": 3604.9999999999995
        },
        {
          "date": 1638316800000,
          "assetId": "BBG001S5SYB8",
          "rowType": "kpi.referenceBaseline",
          "currency": "EUR",
          "displayName": "BASF",
          "displaySymbol": "DE000BASF111",
          "accountName": "DKB Depot",
          "positionOnDate": 50,
          "positionsPerProjectId": {
            "null": 50
          },
          "valueOnDateTransactionCurrency": 2914.99995,
          "valueOnDate": 2914.99995
        },
        {
          "date": 1669852800000,
          "assetId": "BBG001S5SYB8",
          "rowType": "kpi.baseline",
          "currency": "EUR",
          "displayName": "BASF",
          "displaySymbol": "DE000BASF111",
          "accountName": "DKB Depot",
          "positionOnDate": 50,
          "positionsPerProjectId": {
            "null": 50
          },
          "valueOnDateTransactionCurrency": 2397.7501,
          "valueOnDate": 2397.7501
        },
        {
          "date": 1701475200000,
          "assetId": "BBG001S5SYB8",
          "rowType": "kpi.current",
          "currency": "EUR",
          "displayName": "BASF",
          "displaySymbol": "DE000BASF111",
          "accountName": "DKB Depot",
          "positionOnDate": 50,
          "positionsPerProjectId": {
            "null": 50
          },
          "valueOnDateTransactionCurrency": 2162,
          "valueOnDate": 2162
        }
      ],
      "BAS.F": [
        {
          "id": "y-Fr26F2C6vERrrQL-FB7",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "SKxazU1TIynqfmDvfUR1g",
          "otherParty": "DKB",
          "otherPartyAccount": null,
          "description": "Depot Nr. 56547783 Transaktionsgebühren Kauf BASF",
          "amount": -19.9,
          "currency": "EUR",
          "accountCurrencyAmount": -19.9,
          "fxAmount": null,
          "fxCurrency": null,
          "date": 1556755200000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": "investment-fees",
          "isManuallyAdjusted": null,
          "tags": {
            "type": null,
            "assetId": "BAS.F",
            "indexed": null,
            "accountId": "SKxazU1TIynqfmDvfUR1g",
            "recurring": null
          },
          "sortingOrderWithinMonth": null,
          "linkedTransactions": [
            {
              "id": "8Dazmw3aq9ftU5tkiNdfY",
              "category": "stocks",
              "tags": {
                "linkType": "fee-to-transaction"
              }
            },
            {
              "id": "iO2r1oCZVIMbUJT-Ggumx",
              "category": "stocks",
              "tags": {
                "linkType": "fee-to-transaction"
              }
            }
          ],
          "category": "stocks",
          "assetId": "BAS.F",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for brokerage",
          "transactionAmount": -19.9,
          "transactionPrice": -19.9,
          "transactionCurrency": "EUR",
          "openAmount": -19.9,
          "rebasedAmount": -19.9,
          "displayName": "Bank for brokerage",
          "rowType": "fees",
          "positionOnDate": 0
        },
        {
          "id": "-wuGAmqRhO5ttBrQhvSSf",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "SKxazU1TIynqfmDvfUR1g",
          "otherParty": "DKB",
          "otherPartyAccount": null,
          "description": "Depot Nr. 56547783 Dividenden / Zinsen",
          "amount": 69,
          "currency": "EUR",
          "accountCurrencyAmount": 69,
          "fxAmount": null,
          "fxCurrency": null,
          "date": 1615766400000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": "investment-dividend",
          "isManuallyAdjusted": null,
          "tags": {
            "type": null,
            "assetId": "BAS.F",
            "indexed": null,
            "accountId": "SKxazU1TIynqfmDvfUR1g",
            "recurring": null
          },
          "sortingOrderWithinMonth": null,
          "linkedTransactions": [],
          "category": "stocks",
          "assetId": "BAS.F",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for brokerage",
          "transactionAmount": 69,
          "transactionPrice": 69,
          "transactionCurrency": "EUR",
          "openAmount": 69,
          "rebasedAmount": 69,
          "displayName": "Bank for brokerage",
          "rowType": "dividend",
          "positionOnDate": 0
        },
        {
          "id": "T5XUL37Ptjma52D0MydQR",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "SKxazU1TIynqfmDvfUR1g",
          "otherParty": "DKB",
          "otherPartyAccount": null,
          "description": "Depot Nr. 56547783 Dividenden / Zinsen",
          "amount": 130,
          "currency": "EUR",
          "accountCurrencyAmount": 130,
          "fxAmount": null,
          "fxCurrency": null,
          "date": 1631664000000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": "investment-dividend",
          "isManuallyAdjusted": null,
          "tags": {
            "type": null,
            "assetId": "BAS.F",
            "indexed": null,
            "accountId": "SKxazU1TIynqfmDvfUR1g",
            "recurring": null
          },
          "sortingOrderWithinMonth": null,
          "linkedTransactions": [],
          "category": "stocks",
          "assetId": "BAS.F",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for brokerage",
          "transactionAmount": 130,
          "transactionPrice": 130,
          "transactionCurrency": "EUR",
          "openAmount": 130,
          "rebasedAmount": 130,
          "displayName": "Bank for brokerage",
          "rowType": "dividend",
          "positionOnDate": 0
        },
        {
          "date": 1638316800000,
          "assetId": "BAS.F",
          "rowType": "kpi.referenceBaseline",
          "currency": null,
          "displayName": null,
          "displaySymbol": null,
          "accountName": null,
          "positionOnDate": 0,
          "positionsPerProjectId": {
            "null": 0
          },
          "valueOnDateTransactionCurrency": 0,
          "valueOnDate": 0
        },
        {
          "id": "MoTxK3yaKV3JRyV5v8vNU",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "SKxazU1TIynqfmDvfUR1g",
          "otherParty": "DKB",
          "otherPartyAccount": null,
          "description": "Depot Nr. 56547783 Dividenden / Zinsen",
          "amount": 162.5,
          "currency": "EUR",
          "accountCurrencyAmount": 162.5,
          "fxAmount": null,
          "fxCurrency": null,
          "date": 1647302400000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": "investment-dividend",
          "isManuallyAdjusted": null,
          "tags": {
            "type": null,
            "assetId": "BAS.F",
            "indexed": null,
            "accountId": "SKxazU1TIynqfmDvfUR1g",
            "recurring": null
          },
          "sortingOrderWithinMonth": null,
          "linkedTransactions": [],
          "category": "stocks",
          "assetId": "BAS.F",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for brokerage",
          "transactionAmount": 162.5,
          "transactionPrice": 162.5,
          "transactionCurrency": "EUR",
          "openAmount": 162.5,
          "rebasedAmount": 162.5,
          "displayName": "Bank for brokerage",
          "rowType": "dividend",
          "positionOnDate": 0
        },
        {
          "date": 1669852800000,
          "assetId": "BAS.F",
          "rowType": "kpi.baseline",
          "currency": null,
          "displayName": null,
          "displaySymbol": null,
          "accountName": null,
          "positionOnDate": 0,
          "positionsPerProjectId": {
            "null": 0
          },
          "valueOnDateTransactionCurrency": 0,
          "valueOnDate": 0
        },
        {
          "date": 1701475200000,
          "assetId": "BAS.F",
          "rowType": "kpi.current",
          "currency": null,
          "displayName": null,
          "displaySymbol": null,
          "accountName": null,
          "positionOnDate": 0,
          "positionsPerProjectId": {
            "null": 0
          },
          "valueOnDateTransactionCurrency": 0,
          "valueOnDate": 0
        }
      ],
      "SKxazU1TIynqfmDvfUR1g": [
        {
          "id": "EE5FgU0hKIz5o_LMvkjWL",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "SKxazU1TIynqfmDvfUR1g",
          "otherParty": "DKB",
          "otherPartyAccount": null,
          "description": "Kontoführungsgebühren ",
          "amount": -15,
          "currency": "EUR",
          "accountCurrencyAmount": -15,
          "fxAmount": null,
          "fxCurrency": null,
          "date": 1614556800000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": "investment-costs",
          "isManuallyAdjusted": null,
          "tags": {
            "type": null,
            "assetId": "SKxazU1TIynqfmDvfUR1g",
            "indexed": null,
            "accountId": "SKxazU1TIynqfmDvfUR1g",
            "recurring": null
          },
          "sortingOrderWithinMonth": null,
          "linkedTransactions": [],
          "category": "stocks",
          "assetId": "SKxazU1TIynqfmDvfUR1g",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for brokerage",
          "transactionAmount": -15,
          "transactionPrice": -15,
          "transactionCurrency": "EUR",
          "openAmount": -15,
          "rebasedAmount": -15,
          "displayName": "Bank for brokerage",
          "rowType": "costs",
          "positionOnDate": 0
        },
        {
          "date": 1638316800000,
          "assetId": "SKxazU1TIynqfmDvfUR1g",
          "rowType": "kpi.referenceBaseline",
          "currency": null,
          "displayName": null,
          "displaySymbol": null,
          "accountName": null,
          "positionOnDate": 0,
          "positionsPerProjectId": {
            "null": 0
          },
          "valueOnDateTransactionCurrency": 0,
          "valueOnDate": 0
        },
        {
          "id": "4sni0J65uwDx2QKIIqnTz",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "SKxazU1TIynqfmDvfUR1g",
          "otherParty": "DKB",
          "otherPartyAccount": null,
          "description": "Kontoführungsgebühren",
          "amount": -10,
          "currency": "EUR",
          "accountCurrencyAmount": -10,
          "fxAmount": null,
          "fxCurrency": null,
          "date": 1646092800000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": "investment-costs",
          "isManuallyAdjusted": null,
          "tags": {
            "type": null,
            "assetId": "SKxazU1TIynqfmDvfUR1g",
            "indexed": null,
            "accountId": "SKxazU1TIynqfmDvfUR1g",
            "recurring": null
          },
          "sortingOrderWithinMonth": null,
          "linkedTransactions": [],
          "category": "stocks",
          "assetId": "SKxazU1TIynqfmDvfUR1g",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for brokerage",
          "transactionAmount": -10,
          "transactionPrice": -10,
          "transactionCurrency": "EUR",
          "openAmount": -10,
          "rebasedAmount": -10,
          "displayName": "Bank for brokerage",
          "rowType": "costs",
          "positionOnDate": 0
        },
        {
          "date": 1669852800000,
          "assetId": "SKxazU1TIynqfmDvfUR1g",
          "rowType": "kpi.baseline",
          "currency": null,
          "displayName": null,
          "displaySymbol": null,
          "accountName": null,
          "positionOnDate": 0,
          "positionsPerProjectId": {
            "null": 0
          },
          "valueOnDateTransactionCurrency": 0,
          "valueOnDate": 0
        },
        {
          "date": 1701475200000,
          "assetId": "SKxazU1TIynqfmDvfUR1g",
          "rowType": "kpi.current",
          "currency": null,
          "displayName": null,
          "displaySymbol": null,
          "accountName": null,
          "positionOnDate": 0,
          "positionsPerProjectId": {
            "null": 0
          },
          "valueOnDateTransactionCurrency": 0,
          "valueOnDate": 0
        }
      ],
      "AAPL": [
        {
          "id": "RG2Ix5aAKpCHvWhgIWkg-",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "SKxazU1TIynqfmDvfUR1g",
          "otherParty": "DKB",
          "otherPartyAccount": null,
          "description": "Depot Nr. 56547783 Transaktionsgebühren Kauf AAPL",
          "amount": -29.9,
          "currency": "EUR",
          "accountCurrencyAmount": -29.9,
          "fxAmount": null,
          "fxCurrency": null,
          "date": 1541030400000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": "investment-fees",
          "isManuallyAdjusted": null,
          "tags": {
            "type": null,
            "assetId": "AAPL",
            "indexed": null,
            "accountId": "SKxazU1TIynqfmDvfUR1g",
            "recurring": null
          },
          "sortingOrderWithinMonth": null,
          "linkedTransactions": [
            {
              "id": "E-kX0-PILO-5P-_Bdsyx4",
              "category": "stocks",
              "tags": {
                "type": "fee-to-transaction"
              }
            },
            {
              "id": "EcHfZXVMS395YtxSQQbiB",
              "category": "stocks",
              "tags": {
                "linkType": "fee-to-transaction"
              }
            }
          ],
          "category": "stocks",
          "assetId": "AAPL",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for brokerage",
          "transactionAmount": -29.9,
          "transactionPrice": -29.9,
          "transactionCurrency": "EUR",
          "openAmount": -29.9,
          "rebasedAmount": -29.9,
          "displayName": "Bank for brokerage",
          "rowType": "fees",
          "positionOnDate": 0
        },
        {
          "date": 1638316800000,
          "assetId": "AAPL",
          "rowType": "kpi.referenceBaseline",
          "currency": null,
          "displayName": null,
          "displaySymbol": null,
          "accountName": null,
          "positionOnDate": 0,
          "positionsPerProjectId": {
            "null": 0
          },
          "valueOnDateTransactionCurrency": 0,
          "valueOnDate": 0
        },
        {
          "date": 1669852800000,
          "assetId": "AAPL",
          "rowType": "kpi.baseline",
          "currency": null,
          "displayName": null,
          "displaySymbol": null,
          "accountName": null,
          "positionOnDate": 0,
          "positionsPerProjectId": {
            "null": 0
          },
          "valueOnDateTransactionCurrency": 0,
          "valueOnDate": 0
        },
        {
          "date": 1701475200000,
          "assetId": "AAPL",
          "rowType": "kpi.current",
          "currency": null,
          "displayName": null,
          "displaySymbol": null,
          "accountName": null,
          "positionOnDate": 0,
          "positionsPerProjectId": {
            "null": 0
          },
          "valueOnDateTransactionCurrency": 0,
          "valueOnDate": 0
        }
      ]
    }
  },
  "realEstate": {
    "0LBYVRlkkfRWVNk0y2c4a": {
      "0LBYVRlkkfRWVNk0y2c4a": [
        {
          "id": "7TLzk2epzuio98ia27Z15",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "0LBYVRlkkfRWVNk0y2c4a",
          "date": 1346457600000,
          "price": 400000,
          "currency": "EUR",
          "originalPrice": 400000,
          "transactionType": "purchase",
          "valid": true,
          "projectId": null,
          "linkedTransactions": [],
          "isSimulated": null,
          "amount": true,
          "sortingOrderWithinMonth": 0,
          "tags": {},
          "category": "realEstate",
          "assetId": "0LBYVRlkkfRWVNk0y2c4a",
          "originalUnitPrice": 400000,
          "unitPrice": 400000,
          "accountName": "Real estate asset",
          "transactionAmount": true,
          "rebasedAmount": true,
          "transactionPrice": 400000,
          "transactionCurrency": "EUR",
          "openAmount": 1,
          "displayName": "Real estate asset",
          "rowType": "purchase",
          "positionOnDate": true,
          "positionsPerProjectId": {
            "null": true
          },
          "valueOnDateTransactionCurrency": 400000,
          "valueOnDate": 400000
        },
        {
          "date": 1638316800000,
          "assetId": "0LBYVRlkkfRWVNk0y2c4a",
          "rowType": "kpi.referenceBaseline",
          "currency": "EUR",
          "displayName": "Real estate asset",
          "accountName": "Real estate asset",
          "positionOnDate": 1,
          "positionsPerProjectId": {
            "null": 1
          },
          "valueOnDateTransactionCurrency": 400000,
          "valueOnDate": 400000
        },
        {
          "date": 1669852800000,
          "assetId": "0LBYVRlkkfRWVNk0y2c4a",
          "rowType": "kpi.baseline",
          "currency": "EUR",
          "displayName": "Real estate asset",
          "accountName": "Real estate asset",
          "positionOnDate": 1,
          "positionsPerProjectId": {
            "null": 1
          },
          "valueOnDateTransactionCurrency": 400000,
          "valueOnDate": 400000
        },
        {
          "id": "ckOAEiHdq9AAfCvkgzbre",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "0LBYVRlkkfRWVNk0y2c4a",
          "otherParty": "Handwerker GbR",
          "otherPartyAccount": null,
          "description": "Rechnung Renovierung 12/3/23",
          "amount": -543.25,
          "currency": "EUR",
          "accountCurrencyAmount": -543.25,
          "fxAmount": null,
          "fxCurrency": null,
          "date": 1677628800000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": "investment-costs",
          "isManuallyAdjusted": null,
          "tags": {
            "assetId": "0LBYVRlkkfRWVNk0y2c4a",
            "accountId": "0LBYVRlkkfRWVNk0y2c4a"
          },
          "sortingOrderWithinMonth": null,
          "linkedTransactions": [],
          "category": "realEstate",
          "assetId": "0LBYVRlkkfRWVNk0y2c4a",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for real estate",
          "transactionAmount": -543.25,
          "transactionPrice": -543.25,
          "transactionCurrency": "EUR",
          "openAmount": -543.25,
          "rebasedAmount": -543.25,
          "displayName": "Bank for real estate",
          "rowType": "costs",
          "positionOnDate": 1
        },
        {
          "date": 1701475200000,
          "assetId": "0LBYVRlkkfRWVNk0y2c4a",
          "rowType": "kpi.current",
          "currency": "EUR",
          "displayName": "Real estate asset",
          "accountName": "Real estate asset",
          "positionOnDate": 1,
          "positionsPerProjectId": {
            "null": 1
          },
          "valueOnDateTransactionCurrency": 495000,
          "valueOnDate": 495000
        }
      ]
    }
  },
  "deposits": {
    "IRHUSsu_Vp9quJ5CJ_MlG": {
      "IRHUSsu_Vp9quJ5CJ_MlG": [
        {
          "id": "54Z0IHjPF8klVokVvxpoY",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "IRHUSsu_Vp9quJ5CJ_MlG",
          "otherParty": "initial saldo",
          "otherPartyAccount": null,
          "description": "initial saldo",
          "amount": 40000,
          "currency": "EUR",
          "accountCurrencyAmount": 40000,
          "fxAmount": null,
          "fxCurrency": null,
          "date": 1625097600000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": null,
          "isManuallyAdjusted": null,
          "tags": null,
          "sortingOrderWithinMonth": null,
          "linkedTransactions": [],
          "category": "deposits",
          "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for deposit 2",
          "transactionAmount": 40000,
          "transactionPrice": 40000,
          "transactionCurrency": "EUR",
          "openAmount": 40000,
          "rebasedAmount": 40000,
          "displayName": "Bank for deposit 2",
          "rowType": "purchase",
          "amountAssetCurrency": 40000,
          "positionOnDate": 40000,
          "positionsPerProjectId": {
            "null": 40000
          },
          "valueOnDateTransactionCurrency": 40000,
          "valueOnDate": 40000
        },
        {
          "date": 1638316800000,
          "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
          "rowType": "kpi.referenceBaseline",
          "currency": "EUR",
          "displayName": "Bank for deposit 2",
          "accountName": "Bank for deposit 2",
          "positionOnDate": 40000,
          "positionsPerProjectId": {
            "null": 40000
          },
          "valueOnDateTransactionCurrency": 40000,
          "valueOnDate": 40000
        },
        {
          "id": "k8k-uu7uEmrv22Nov7YVB",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "IRHUSsu_Vp9quJ5CJ_MlG",
          "otherParty": "Bank for deposits 2",
          "otherPartyAccount": null,
          "description": "Zinsen Tagesgeld",
          "amount": 400,
          "currency": "EUR",
          "accountCurrencyAmount": 400,
          "fxAmount": null,
          "fxCurrency": null,
          "date": 1656633600000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": "investment-interest",
          "isManuallyAdjusted": null,
          "tags": {
            "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
            "accountId": "IRHUSsu_Vp9quJ5CJ_MlG"
          },
          "sortingOrderWithinMonth": null,
          "linkedTransactions": [],
          "category": "deposits",
          "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for cost of living",
          "transactionAmount": 400,
          "transactionPrice": 400,
          "transactionCurrency": "EUR",
          "openAmount": 400,
          "rebasedAmount": 400,
          "displayName": "Bank for cost of living",
          "rowType": "interest",
          "positionOnDate": 40000
        },
        {
          "date": 1669852800000,
          "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
          "rowType": "kpi.baseline",
          "currency": "EUR",
          "displayName": "Bank for deposit 2",
          "accountName": "Bank for deposit 2",
          "positionOnDate": 40000,
          "positionsPerProjectId": {
            "null": 40000
          },
          "valueOnDateTransactionCurrency": 40000,
          "valueOnDate": 40000
        },
        {
          "date": 1701475200000,
          "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
          "rowType": "kpi.current",
          "currency": "EUR",
          "displayName": "Bank for deposit 2",
          "accountName": "Bank for deposit 2",
          "positionOnDate": 40000,
          "positionsPerProjectId": {
            "null": 40000
          },
          "valueOnDateTransactionCurrency": 40000,
          "valueOnDate": 40000
        }
      ]
    },
    "EXWO7q0zVnuwvj4yJ305w": {
      "EXWO7q0zVnuwvj4yJ305w": [
        {
          "id": "dRRHBD6XMQp8a44trj90w",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "EXWO7q0zVnuwvj4yJ305w",
          "otherParty": "me",
          "otherPartyAccount": "DE12345678912345697833",
          "description": "initial ",
          "amount": 1100,
          "currency": "EUR",
          "accountCurrencyAmount": 1100,
          "fxAmount": null,
          "fxCurrency": null,
          "date": 1512086400000,
          "batchId": null,
          "projectId": null,
          "parentId": "c1zXe1n73jsBGQLI5cXQq",
          "isSimulated": false,
          "label": null,
          "isManuallyAdjusted": null,
          "tags": {},
          "sortingOrderWithinMonth": null,
          "linkedTransactions": [],
          "category": "deposits",
          "assetId": "EXWO7q0zVnuwvj4yJ305w",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for deposit",
          "transactionAmount": 1100,
          "transactionPrice": 1100,
          "transactionCurrency": "EUR",
          "openAmount": 1100,
          "rebasedAmount": 1100,
          "displayName": "Bank for deposit",
          "rowType": "purchase",
          "amountAssetCurrency": 1100,
          "positionOnDate": 1100,
          "positionsPerProjectId": {
            "null": 1100
          },
          "valueOnDateTransactionCurrency": 1100,
          "valueOnDate": 1100
        },
        {
          "id": "ILc3WwwH0mtqJ2iMKskkh",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "EXWO7q0zVnuwvj4yJ305w",
          "otherParty": "me",
          "otherPartyAccount": "DE12345678912345697833",
          "description": "initial ",
          "amount": 18900,
          "currency": "EUR",
          "accountCurrencyAmount": 18900,
          "fxAmount": null,
          "fxCurrency": null,
          "date": 1512086400000,
          "batchId": null,
          "projectId": null,
          "parentId": "c1zXe1n73jsBGQLI5cXQq",
          "isSimulated": false,
          "label": null,
          "isManuallyAdjusted": null,
          "tags": {},
          "sortingOrderWithinMonth": null,
          "linkedTransactions": [],
          "category": "deposits",
          "assetId": "EXWO7q0zVnuwvj4yJ305w",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for deposit",
          "transactionAmount": 18900,
          "transactionPrice": 18900,
          "transactionCurrency": "EUR",
          "openAmount": 18900,
          "rebasedAmount": 18900,
          "displayName": "Bank for deposit",
          "rowType": "purchase",
          "amountAssetCurrency": 18900,
          "positionOnDate": 20000,
          "positionsPerProjectId": {
            "null": 20000
          },
          "valueOnDateTransactionCurrency": 20000,
          "valueOnDate": 20000
        },
        {
          "id": "9RAuacnFTPAAI9hJJGYND",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "EXWO7q0zVnuwvj4yJ305w",
          "otherParty": "Commerzbank",
          "otherPartyAccount": "none",
          "description": "Zinsen",
          "amount": 2,
          "currency": "EUR",
          "accountCurrencyAmount": 2,
          "fxAmount": null,
          "fxCurrency": null,
          "date": 1538352000000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": "investment-interest",
          "isManuallyAdjusted": null,
          "tags": {
            "assetId": "EXWO7q0zVnuwvj4yJ305w",
            "accountId": "EXWO7q0zVnuwvj4yJ305w"
          },
          "sortingOrderWithinMonth": null,
          "linkedTransactions": [],
          "category": "deposits",
          "assetId": "EXWO7q0zVnuwvj4yJ305w",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for deposit",
          "transactionAmount": 2,
          "transactionPrice": 2,
          "transactionCurrency": "EUR",
          "openAmount": 2,
          "rebasedAmount": 2,
          "displayName": "Bank for deposit",
          "rowType": "interest",
          "positionOnDate": 20000
        },
        {
          "id": "9RAuacnFTPAAI9hJJGYND",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "EXWO7q0zVnuwvj4yJ305w",
          "otherParty": "Commerzbank",
          "otherPartyAccount": "none",
          "description": "Zinsen",
          "amount": 2,
          "currency": "EUR",
          "accountCurrencyAmount": 2,
          "fxAmount": null,
          "fxCurrency": null,
          "date": 1538352000000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": "investment-interest",
          "isManuallyAdjusted": null,
          "tags": {
            "assetId": "EXWO7q0zVnuwvj4yJ305w",
            "accountId": "EXWO7q0zVnuwvj4yJ305w"
          },
          "sortingOrderWithinMonth": null,
          "linkedTransactions": [],
          "category": "deposits",
          "assetId": "EXWO7q0zVnuwvj4yJ305w",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for deposit",
          "transactionAmount": 2,
          "transactionPrice": 2,
          "transactionCurrency": "EUR",
          "openAmount": 2,
          "rebasedAmount": 2,
          "displayName": "Bank for deposit",
          "rowType": "purchase",
          "amountAssetCurrency": 2,
          "positionOnDate": 20002,
          "positionsPerProjectId": {
            "null": 20002
          },
          "valueOnDateTransactionCurrency": 20002,
          "valueOnDate": 20002
        },
        {
          "id": "fKLr0hOn84wcKYwXUaV43",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "EXWO7q0zVnuwvj4yJ305w",
          "otherParty": "Commerzbank",
          "otherPartyAccount": "none",
          "description": "Zinsen",
          "amount": 2,
          "currency": "EUR",
          "accountCurrencyAmount": 2,
          "fxAmount": null,
          "fxCurrency": null,
          "date": 1569888000000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": "investment-interest",
          "isManuallyAdjusted": null,
          "tags": {
            "assetId": "EXWO7q0zVnuwvj4yJ305w",
            "accountId": "EXWO7q0zVnuwvj4yJ305w"
          },
          "sortingOrderWithinMonth": null,
          "linkedTransactions": [],
          "category": "deposits",
          "assetId": "EXWO7q0zVnuwvj4yJ305w",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for deposit",
          "transactionAmount": 2,
          "transactionPrice": 2,
          "transactionCurrency": "EUR",
          "openAmount": 2,
          "rebasedAmount": 2,
          "displayName": "Bank for deposit",
          "rowType": "interest",
          "positionOnDate": 20002
        },
        {
          "id": "fKLr0hOn84wcKYwXUaV43",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "EXWO7q0zVnuwvj4yJ305w",
          "otherParty": "Commerzbank",
          "otherPartyAccount": "none",
          "description": "Zinsen",
          "amount": 2,
          "currency": "EUR",
          "accountCurrencyAmount": 2,
          "fxAmount": null,
          "fxCurrency": null,
          "date": 1569888000000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": "investment-interest",
          "isManuallyAdjusted": null,
          "tags": {
            "assetId": "EXWO7q0zVnuwvj4yJ305w",
            "accountId": "EXWO7q0zVnuwvj4yJ305w"
          },
          "sortingOrderWithinMonth": null,
          "linkedTransactions": [],
          "category": "deposits",
          "assetId": "EXWO7q0zVnuwvj4yJ305w",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for deposit",
          "transactionAmount": 2,
          "transactionPrice": 2,
          "transactionCurrency": "EUR",
          "openAmount": 2,
          "rebasedAmount": 2,
          "displayName": "Bank for deposit",
          "rowType": "purchase",
          "amountAssetCurrency": 2,
          "positionOnDate": 20004,
          "positionsPerProjectId": {
            "null": 20004
          },
          "valueOnDateTransactionCurrency": 20004,
          "valueOnDate": 20004
        },
        {
          "id": "IWe-7BAzyj2IKbe6wiVUu",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "EXWO7q0zVnuwvj4yJ305w",
          "otherParty": "Commerzbank",
          "otherPartyAccount": "none",
          "description": "Zinsen",
          "amount": 2,
          "currency": "EUR",
          "accountCurrencyAmount": 2,
          "fxAmount": null,
          "fxCurrency": null,
          "date": 1601510400000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": "investment-interest",
          "isManuallyAdjusted": null,
          "tags": {
            "assetId": "EXWO7q0zVnuwvj4yJ305w",
            "accountId": "EXWO7q0zVnuwvj4yJ305w"
          },
          "sortingOrderWithinMonth": null,
          "linkedTransactions": [],
          "category": "deposits",
          "assetId": "EXWO7q0zVnuwvj4yJ305w",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for deposit",
          "transactionAmount": 2,
          "transactionPrice": 2,
          "transactionCurrency": "EUR",
          "openAmount": 2,
          "rebasedAmount": 2,
          "displayName": "Bank for deposit",
          "rowType": "interest",
          "positionOnDate": 20004
        },
        {
          "id": "IWe-7BAzyj2IKbe6wiVUu",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "EXWO7q0zVnuwvj4yJ305w",
          "otherParty": "Commerzbank",
          "otherPartyAccount": "none",
          "description": "Zinsen",
          "amount": 2,
          "currency": "EUR",
          "accountCurrencyAmount": 2,
          "fxAmount": null,
          "fxCurrency": null,
          "date": 1601510400000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": "investment-interest",
          "isManuallyAdjusted": null,
          "tags": {
            "assetId": "EXWO7q0zVnuwvj4yJ305w",
            "accountId": "EXWO7q0zVnuwvj4yJ305w"
          },
          "sortingOrderWithinMonth": null,
          "linkedTransactions": [],
          "category": "deposits",
          "assetId": "EXWO7q0zVnuwvj4yJ305w",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for deposit",
          "transactionAmount": 2,
          "transactionPrice": 2,
          "transactionCurrency": "EUR",
          "openAmount": 2,
          "rebasedAmount": 2,
          "displayName": "Bank for deposit",
          "rowType": "purchase",
          "amountAssetCurrency": 2,
          "positionOnDate": 20006,
          "positionsPerProjectId": {
            "null": 20006
          },
          "valueOnDateTransactionCurrency": 20006,
          "valueOnDate": 20006
        },
        {
          "id": "ihbGc1XxLhZCJ5vufr9p0",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "EXWO7q0zVnuwvj4yJ305w",
          "otherParty": "Commerzbank",
          "otherPartyAccount": "none",
          "description": "Zinsen",
          "amount": 2,
          "currency": "EUR",
          "accountCurrencyAmount": 2,
          "fxAmount": null,
          "fxCurrency": null,
          "date": 1633046400000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": "investment-interest",
          "isManuallyAdjusted": null,
          "tags": {
            "assetId": "EXWO7q0zVnuwvj4yJ305w",
            "accountId": "EXWO7q0zVnuwvj4yJ305w"
          },
          "sortingOrderWithinMonth": null,
          "linkedTransactions": [],
          "category": "deposits",
          "assetId": "EXWO7q0zVnuwvj4yJ305w",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for deposit",
          "transactionAmount": 2,
          "transactionPrice": 2,
          "transactionCurrency": "EUR",
          "openAmount": 2,
          "rebasedAmount": 2,
          "displayName": "Bank for deposit",
          "rowType": "interest",
          "positionOnDate": 20006
        },
        {
          "id": "ihbGc1XxLhZCJ5vufr9p0",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "EXWO7q0zVnuwvj4yJ305w",
          "otherParty": "Commerzbank",
          "otherPartyAccount": "none",
          "description": "Zinsen",
          "amount": 2,
          "currency": "EUR",
          "accountCurrencyAmount": 2,
          "fxAmount": null,
          "fxCurrency": null,
          "date": 1633046400000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": "investment-interest",
          "isManuallyAdjusted": null,
          "tags": {
            "assetId": "EXWO7q0zVnuwvj4yJ305w",
            "accountId": "EXWO7q0zVnuwvj4yJ305w"
          },
          "sortingOrderWithinMonth": null,
          "linkedTransactions": [],
          "category": "deposits",
          "assetId": "EXWO7q0zVnuwvj4yJ305w",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for deposit",
          "transactionAmount": 2,
          "transactionPrice": 2,
          "transactionCurrency": "EUR",
          "openAmount": 2,
          "rebasedAmount": 2,
          "displayName": "Bank for deposit",
          "rowType": "purchase",
          "amountAssetCurrency": 2,
          "positionOnDate": 20008,
          "positionsPerProjectId": {
            "null": 20008
          },
          "valueOnDateTransactionCurrency": 20008,
          "valueOnDate": 20008
        },
        {
          "date": 1638316800000,
          "assetId": "EXWO7q0zVnuwvj4yJ305w",
          "rowType": "kpi.referenceBaseline",
          "currency": "EUR",
          "displayName": "Bank for deposit",
          "accountName": "Bank for deposit",
          "positionOnDate": 20008,
          "positionsPerProjectId": {
            "null": 20008
          },
          "valueOnDateTransactionCurrency": 20008,
          "valueOnDate": 20008
        },
        {
          "id": "sP9BKBJ8YFk28wJynJ5ZC",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "EXWO7q0zVnuwvj4yJ305w",
          "otherParty": "Commerzbank",
          "otherPartyAccount": "none",
          "description": "Zinsen",
          "amount": 2,
          "currency": "EUR",
          "accountCurrencyAmount": 2,
          "fxAmount": null,
          "fxCurrency": null,
          "date": 1664582400000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": "investment-interest",
          "isManuallyAdjusted": null,
          "tags": {
            "assetId": "EXWO7q0zVnuwvj4yJ305w",
            "accountId": "EXWO7q0zVnuwvj4yJ305w"
          },
          "sortingOrderWithinMonth": null,
          "linkedTransactions": [],
          "category": "deposits",
          "assetId": "EXWO7q0zVnuwvj4yJ305w",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for deposit",
          "transactionAmount": 2,
          "transactionPrice": 2,
          "transactionCurrency": "EUR",
          "openAmount": 2,
          "rebasedAmount": 2,
          "displayName": "Bank for deposit",
          "rowType": "interest",
          "positionOnDate": 20008
        },
        {
          "id": "sP9BKBJ8YFk28wJynJ5ZC",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "EXWO7q0zVnuwvj4yJ305w",
          "otherParty": "Commerzbank",
          "otherPartyAccount": "none",
          "description": "Zinsen",
          "amount": 2,
          "currency": "EUR",
          "accountCurrencyAmount": 2,
          "fxAmount": null,
          "fxCurrency": null,
          "date": 1664582400000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": "investment-interest",
          "isManuallyAdjusted": null,
          "tags": {
            "assetId": "EXWO7q0zVnuwvj4yJ305w",
            "accountId": "EXWO7q0zVnuwvj4yJ305w"
          },
          "sortingOrderWithinMonth": null,
          "linkedTransactions": [],
          "category": "deposits",
          "assetId": "EXWO7q0zVnuwvj4yJ305w",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for deposit",
          "transactionAmount": 2,
          "transactionPrice": 2,
          "transactionCurrency": "EUR",
          "openAmount": 2,
          "rebasedAmount": 2,
          "displayName": "Bank for deposit",
          "rowType": "purchase",
          "amountAssetCurrency": 2,
          "positionOnDate": 20010,
          "positionsPerProjectId": {
            "null": 20010
          },
          "valueOnDateTransactionCurrency": 20010,
          "valueOnDate": 20010
        },
        {
          "date": 1669852800000,
          "assetId": "EXWO7q0zVnuwvj4yJ305w",
          "rowType": "kpi.baseline",
          "currency": "EUR",
          "displayName": "Bank for deposit",
          "accountName": "Bank for deposit",
          "positionOnDate": 20010,
          "positionsPerProjectId": {
            "null": 20010
          },
          "valueOnDateTransactionCurrency": 20010,
          "valueOnDate": 20010
        },
        {
          "date": 1701475200000,
          "assetId": "EXWO7q0zVnuwvj4yJ305w",
          "rowType": "kpi.current",
          "currency": "EUR",
          "displayName": "Bank for deposit",
          "accountName": "Bank for deposit",
          "positionOnDate": 20010,
          "positionsPerProjectId": {
            "null": 20010
          },
          "valueOnDateTransactionCurrency": 20010,
          "valueOnDate": 20010
        }
      ]
    },
    "-b3jqm5IvL6kobgCcdICR": {
      "-b3jqm5IvL6kobgCcdICR": [
        {
          "id": "wHQ91su-MwMeeHYk1_iEn",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "-b3jqm5IvL6kobgCcdICR",
          "otherParty": "initial saldo",
          "otherPartyAccount": "initial saldo",
          "description": "initial saldo",
          "amount": 550000,
          "currency": "EUR",
          "accountCurrencyAmount": 550000,
          "fxAmount": null,
          "fxCurrency": null,
          "date": 1527897600000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": null,
          "isManuallyAdjusted": null,
          "tags": null,
          "sortingOrderWithinMonth": null,
          "linkedTransactions": [],
          "category": "deposits",
          "assetId": "-b3jqm5IvL6kobgCcdICR",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for real estate",
          "transactionAmount": 550000,
          "transactionPrice": 550000,
          "transactionCurrency": "EUR",
          "openAmount": 550000,
          "rebasedAmount": 550000,
          "displayName": "Bank for real estate",
          "rowType": "purchase",
          "amountAssetCurrency": 550000,
          "positionOnDate": 550000,
          "positionsPerProjectId": {
            "null": 550000
          },
          "valueOnDateTransactionCurrency": 550000,
          "valueOnDate": 550000
        },
        {
          "date": 1638316800000,
          "assetId": "-b3jqm5IvL6kobgCcdICR",
          "rowType": "kpi.referenceBaseline",
          "currency": "EUR",
          "displayName": "Bank for real estate",
          "accountName": "Bank for real estate",
          "positionOnDate": 550000,
          "positionsPerProjectId": {
            "null": 550000
          },
          "valueOnDateTransactionCurrency": 550000,
          "valueOnDate": 550000
        },
        {
          "date": 1669852800000,
          "assetId": "-b3jqm5IvL6kobgCcdICR",
          "rowType": "kpi.baseline",
          "currency": "EUR",
          "displayName": "Bank for real estate",
          "accountName": "Bank for real estate",
          "positionOnDate": 550000,
          "positionsPerProjectId": {
            "null": 550000
          },
          "valueOnDateTransactionCurrency": 550000,
          "valueOnDate": 550000
        },
        {
          "id": "ckOAEiHdq9AAfCvkgzbre",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "-b3jqm5IvL6kobgCcdICR",
          "otherParty": "Handwerker GbR",
          "otherPartyAccount": null,
          "description": "Rechnung Renovierung 12/3/23",
          "amount": -543.25,
          "currency": "EUR",
          "accountCurrencyAmount": -543.25,
          "fxAmount": null,
          "fxCurrency": null,
          "date": 1677628800000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": "investment-costs",
          "isManuallyAdjusted": null,
          "tags": {
            "assetId": "0LBYVRlkkfRWVNk0y2c4a",
            "accountId": "0LBYVRlkkfRWVNk0y2c4a"
          },
          "sortingOrderWithinMonth": null,
          "linkedTransactions": [],
          "category": "deposits",
          "assetId": "-b3jqm5IvL6kobgCcdICR",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for real estate",
          "transactionAmount": -543.25,
          "transactionPrice": -543.25,
          "transactionCurrency": "EUR",
          "openAmount": -543.25,
          "rebasedAmount": -543.25,
          "displayName": "Bank for real estate",
          "rowType": "sale",
          "amountAssetCurrency": -543.25,
          "positionOnDate": 549456.75,
          "positionsPerProjectId": {
            "null": 549456.75
          },
          "valueOnDateTransactionCurrency": 549456.75,
          "valueOnDate": 549456.75
        },
        {
          "date": 1701475200000,
          "assetId": "-b3jqm5IvL6kobgCcdICR",
          "rowType": "kpi.current",
          "currency": "EUR",
          "displayName": "Bank for real estate",
          "accountName": "Bank for real estate",
          "positionOnDate": 549456.75,
          "positionsPerProjectId": {
            "null": 549456.75
          },
          "valueOnDateTransactionCurrency": 549456.75,
          "valueOnDate": 549456.75
        }
      ]
    },
    "AUAMQVhQaeLIHriycINeL": {
      "AUAMQVhQaeLIHriycINeL": [
        {
          "id": "WJNxppJLD-uFFEk_KZYJ1",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "AUAMQVhQaeLIHriycINeL",
          "otherParty": "own",
          "otherPartyAccount": null,
          "description": "initial booking",
          "amount": 100000,
          "currency": "EUR",
          "accountCurrencyAmount": 100000,
          "fxAmount": null,
          "fxCurrency": null,
          "date": 1427846400000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": null,
          "isManuallyAdjusted": null,
          "tags": {
            "type": null,
            "assetId": null,
            "indexed": null,
            "accountId": null,
            "recurring": null
          },
          "sortingOrderWithinMonth": null,
          "linkedTransactions": [],
          "category": "deposits",
          "assetId": "AUAMQVhQaeLIHriycINeL",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for brokerage",
          "transactionAmount": 100000,
          "transactionPrice": 100000,
          "transactionCurrency": "EUR",
          "openAmount": 100000,
          "rebasedAmount": 100000,
          "displayName": "Bank for brokerage",
          "rowType": "purchase",
          "amountAssetCurrency": 100000,
          "positionOnDate": 100000,
          "positionsPerProjectId": {
            "null": 100000
          },
          "valueOnDateTransactionCurrency": 100000,
          "valueOnDate": 100000
        },
        {
          "id": "LlKSrDIKVf2By8_bh6Z80",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "AUAMQVhQaeLIHriycINeL",
          "otherParty": "DKB ",
          "otherPartyAccount": null,
          "description": "Depot Nr. 56547783 Kauf AAPL 100 Stk.",
          "amount": -15.5622,
          "currency": "EUR",
          "accountCurrencyAmount": -15.5622,
          "fxAmount": -17.73,
          "fxCurrency": "USD",
          "date": 1541030400000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": "investment-purchase",
          "isManuallyAdjusted": null,
          "tags": {
            "type": null,
            "assetId": null,
            "indexed": null,
            "accountId": null,
            "recurring": null
          },
          "sortingOrderWithinMonth": null,
          "linkedTransactions": [],
          "category": "deposits",
          "assetId": "AUAMQVhQaeLIHriycINeL",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for brokerage",
          "transactionAmount": -15.5622,
          "transactionPrice": -15.5622,
          "transactionCurrency": "EUR",
          "openAmount": -15.5622,
          "rebasedAmount": -15.5622,
          "displayName": "Bank for brokerage",
          "rowType": "sale",
          "amountAssetCurrency": -15.5622,
          "positionOnDate": 99984.4378,
          "positionsPerProjectId": {
            "null": 99984.4378
          },
          "valueOnDateTransactionCurrency": 99984.4378,
          "valueOnDate": 99984.4378
        },
        {
          "id": "RG2Ix5aAKpCHvWhgIWkg-",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "AUAMQVhQaeLIHriycINeL",
          "otherParty": "DKB",
          "otherPartyAccount": null,
          "description": "Depot Nr. 56547783 Transaktionsgebühren Kauf AAPL",
          "amount": -29.9,
          "currency": "EUR",
          "accountCurrencyAmount": -29.9,
          "fxAmount": null,
          "fxCurrency": null,
          "date": 1541030400000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": "investment-fees",
          "isManuallyAdjusted": null,
          "tags": {
            "type": null,
            "assetId": "AAPL",
            "indexed": null,
            "accountId": "SKxazU1TIynqfmDvfUR1g",
            "recurring": null
          },
          "sortingOrderWithinMonth": null,
          "linkedTransactions": [
            {
              "id": "E-kX0-PILO-5P-_Bdsyx4",
              "category": "stocks",
              "tags": {
                "type": "fee-to-transaction"
              }
            },
            {
              "id": "EcHfZXVMS395YtxSQQbiB",
              "category": "stocks",
              "tags": {
                "linkType": "fee-to-transaction"
              }
            }
          ],
          "category": "deposits",
          "assetId": "AUAMQVhQaeLIHriycINeL",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for brokerage",
          "transactionAmount": -29.9,
          "transactionPrice": -29.9,
          "transactionCurrency": "EUR",
          "openAmount": -29.9,
          "rebasedAmount": -29.9,
          "displayName": "Bank for brokerage",
          "rowType": "sale",
          "amountAssetCurrency": -29.9,
          "positionOnDate": 99954.5378,
          "positionsPerProjectId": {
            "null": 99954.5378
          },
          "valueOnDateTransactionCurrency": 99954.5378,
          "valueOnDate": 99954.5378
        },
        {
          "id": "oORs6F46DBdnTK-KQInyf",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "AUAMQVhQaeLIHriycINeL",
          "otherParty": "DKB",
          "otherPartyAccount": null,
          "description": "Depot Nr. 56547783 Kauf BASF 50 Stk.",
          "amount": -3605,
          "currency": "EUR",
          "accountCurrencyAmount": -3605,
          "fxAmount": null,
          "fxCurrency": null,
          "date": 1556755200000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": "investment-purchase",
          "isManuallyAdjusted": null,
          "tags": {
            "type": null,
            "assetId": null,
            "indexed": null,
            "accountId": null,
            "recurring": null
          },
          "sortingOrderWithinMonth": null,
          "linkedTransactions": [],
          "category": "deposits",
          "assetId": "AUAMQVhQaeLIHriycINeL",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for brokerage",
          "transactionAmount": -3605,
          "transactionPrice": -3605,
          "transactionCurrency": "EUR",
          "openAmount": -3605,
          "rebasedAmount": -3605,
          "displayName": "Bank for brokerage",
          "rowType": "sale",
          "amountAssetCurrency": -3605,
          "positionOnDate": 96349.5378,
          "positionsPerProjectId": {
            "null": 96349.5378
          },
          "valueOnDateTransactionCurrency": 96349.5378,
          "valueOnDate": 96349.5378
        },
        {
          "id": "y-Fr26F2C6vERrrQL-FB7",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "AUAMQVhQaeLIHriycINeL",
          "otherParty": "DKB",
          "otherPartyAccount": null,
          "description": "Depot Nr. 56547783 Transaktionsgebühren Kauf BASF",
          "amount": -19.9,
          "currency": "EUR",
          "accountCurrencyAmount": -19.9,
          "fxAmount": null,
          "fxCurrency": null,
          "date": 1556755200000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": "investment-fees",
          "isManuallyAdjusted": null,
          "tags": {
            "type": null,
            "assetId": "BAS.F",
            "indexed": null,
            "accountId": "SKxazU1TIynqfmDvfUR1g",
            "recurring": null
          },
          "sortingOrderWithinMonth": null,
          "linkedTransactions": [
            {
              "id": "8Dazmw3aq9ftU5tkiNdfY",
              "category": "stocks",
              "tags": {
                "linkType": "fee-to-transaction"
              }
            },
            {
              "id": "iO2r1oCZVIMbUJT-Ggumx",
              "category": "stocks",
              "tags": {
                "linkType": "fee-to-transaction"
              }
            }
          ],
          "category": "deposits",
          "assetId": "AUAMQVhQaeLIHriycINeL",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for brokerage",
          "transactionAmount": -19.9,
          "transactionPrice": -19.9,
          "transactionCurrency": "EUR",
          "openAmount": -19.9,
          "rebasedAmount": -19.9,
          "displayName": "Bank for brokerage",
          "rowType": "sale",
          "amountAssetCurrency": -19.9,
          "positionOnDate": 96329.63780000001,
          "positionsPerProjectId": {
            "null": 96329.63780000001
          },
          "valueOnDateTransactionCurrency": 96329.63780000001,
          "valueOnDate": 96329.63780000001
        },
        {
          "id": "SD6J4UL8PVRijjBNPZibb",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "AUAMQVhQaeLIHriycINeL",
          "otherParty": "Dave Brubeck",
          "otherPartyAccount": "DE02500105170137075030",
          "description": "internal transfer",
          "amount": 2000,
          "currency": "EUR",
          "accountCurrencyAmount": 2000,
          "fxAmount": null,
          "fxCurrency": null,
          "date": 1588291200000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": null,
          "isManuallyAdjusted": null,
          "tags": {
            "type": null,
            "assetId": null,
            "indexed": null,
            "accountId": null,
            "recurring": null
          },
          "sortingOrderWithinMonth": null,
          "linkedTransactions": [],
          "category": "deposits",
          "assetId": "AUAMQVhQaeLIHriycINeL",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for brokerage",
          "transactionAmount": 2000,
          "transactionPrice": 2000,
          "transactionCurrency": "EUR",
          "openAmount": 2000,
          "rebasedAmount": 2000,
          "displayName": "Bank for brokerage",
          "rowType": "purchase",
          "amountAssetCurrency": 2000,
          "positionOnDate": 98329.63780000001,
          "positionsPerProjectId": {
            "null": 98329.63780000001
          },
          "valueOnDateTransactionCurrency": 98329.63780000001,
          "valueOnDate": 98329.63780000001
        },
        {
          "id": "EE5FgU0hKIz5o_LMvkjWL",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "AUAMQVhQaeLIHriycINeL",
          "otherParty": "DKB",
          "otherPartyAccount": null,
          "description": "Kontoführungsgebühren ",
          "amount": -15,
          "currency": "EUR",
          "accountCurrencyAmount": -15,
          "fxAmount": null,
          "fxCurrency": null,
          "date": 1614556800000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": "investment-costs",
          "isManuallyAdjusted": null,
          "tags": {
            "type": null,
            "assetId": "SKxazU1TIynqfmDvfUR1g",
            "indexed": null,
            "accountId": "SKxazU1TIynqfmDvfUR1g",
            "recurring": null
          },
          "sortingOrderWithinMonth": null,
          "linkedTransactions": [],
          "category": "deposits",
          "assetId": "AUAMQVhQaeLIHriycINeL",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for brokerage",
          "transactionAmount": -15,
          "transactionPrice": -15,
          "transactionCurrency": "EUR",
          "openAmount": -15,
          "rebasedAmount": -15,
          "displayName": "Bank for brokerage",
          "rowType": "sale",
          "amountAssetCurrency": -15,
          "positionOnDate": 98314.63780000001,
          "positionsPerProjectId": {
            "null": 98314.63780000001
          },
          "valueOnDateTransactionCurrency": 98314.63780000001,
          "valueOnDate": 98314.63780000001
        },
        {
          "id": "-wuGAmqRhO5ttBrQhvSSf",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "AUAMQVhQaeLIHriycINeL",
          "otherParty": "DKB",
          "otherPartyAccount": null,
          "description": "Depot Nr. 56547783 Dividenden / Zinsen",
          "amount": 69,
          "currency": "EUR",
          "accountCurrencyAmount": 69,
          "fxAmount": null,
          "fxCurrency": null,
          "date": 1615766400000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": "investment-dividend",
          "isManuallyAdjusted": null,
          "tags": {
            "type": null,
            "assetId": "BAS.F",
            "indexed": null,
            "accountId": "SKxazU1TIynqfmDvfUR1g",
            "recurring": null
          },
          "sortingOrderWithinMonth": null,
          "linkedTransactions": [],
          "category": "deposits",
          "assetId": "AUAMQVhQaeLIHriycINeL",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for brokerage",
          "transactionAmount": 69,
          "transactionPrice": 69,
          "transactionCurrency": "EUR",
          "openAmount": 69,
          "rebasedAmount": 69,
          "displayName": "Bank for brokerage",
          "rowType": "purchase",
          "amountAssetCurrency": 69,
          "positionOnDate": 98383.63780000001,
          "positionsPerProjectId": {
            "null": 98383.63780000001
          },
          "valueOnDateTransactionCurrency": 98383.63780000001,
          "valueOnDate": 98383.63780000001
        },
        {
          "id": "T5XUL37Ptjma52D0MydQR",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "AUAMQVhQaeLIHriycINeL",
          "otherParty": "DKB",
          "otherPartyAccount": null,
          "description": "Depot Nr. 56547783 Dividenden / Zinsen",
          "amount": 130,
          "currency": "EUR",
          "accountCurrencyAmount": 130,
          "fxAmount": null,
          "fxCurrency": null,
          "date": 1631664000000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": "investment-dividend",
          "isManuallyAdjusted": null,
          "tags": {
            "type": null,
            "assetId": "BAS.F",
            "indexed": null,
            "accountId": "SKxazU1TIynqfmDvfUR1g",
            "recurring": null
          },
          "sortingOrderWithinMonth": null,
          "linkedTransactions": [],
          "category": "deposits",
          "assetId": "AUAMQVhQaeLIHriycINeL",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for brokerage",
          "transactionAmount": 130,
          "transactionPrice": 130,
          "transactionCurrency": "EUR",
          "openAmount": 130,
          "rebasedAmount": 130,
          "displayName": "Bank for brokerage",
          "rowType": "purchase",
          "amountAssetCurrency": 130,
          "positionOnDate": 98513.63780000001,
          "positionsPerProjectId": {
            "null": 98513.63780000001
          },
          "valueOnDateTransactionCurrency": 98513.63780000001,
          "valueOnDate": 98513.63780000001
        },
        {
          "date": 1638316800000,
          "assetId": "AUAMQVhQaeLIHriycINeL",
          "rowType": "kpi.referenceBaseline",
          "currency": "EUR",
          "displayName": "Bank for brokerage",
          "accountName": "Bank for brokerage",
          "positionOnDate": 98513.63780000001,
          "positionsPerProjectId": {
            "null": 98513.63780000001
          },
          "valueOnDateTransactionCurrency": 98513.63780000001,
          "valueOnDate": 98513.63780000001
        },
        {
          "id": "4sni0J65uwDx2QKIIqnTz",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "AUAMQVhQaeLIHriycINeL",
          "otherParty": "DKB",
          "otherPartyAccount": null,
          "description": "Kontoführungsgebühren",
          "amount": -10,
          "currency": "EUR",
          "accountCurrencyAmount": -10,
          "fxAmount": null,
          "fxCurrency": null,
          "date": 1646092800000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": "investment-costs",
          "isManuallyAdjusted": null,
          "tags": {
            "type": null,
            "assetId": "SKxazU1TIynqfmDvfUR1g",
            "indexed": null,
            "accountId": "SKxazU1TIynqfmDvfUR1g",
            "recurring": null
          },
          "sortingOrderWithinMonth": null,
          "linkedTransactions": [],
          "category": "deposits",
          "assetId": "AUAMQVhQaeLIHriycINeL",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for brokerage",
          "transactionAmount": -10,
          "transactionPrice": -10,
          "transactionCurrency": "EUR",
          "openAmount": -10,
          "rebasedAmount": -10,
          "displayName": "Bank for brokerage",
          "rowType": "sale",
          "amountAssetCurrency": -10,
          "positionOnDate": 98503.63780000001,
          "positionsPerProjectId": {
            "null": 98503.63780000001
          },
          "valueOnDateTransactionCurrency": 98503.63780000001,
          "valueOnDate": 98503.63780000001
        },
        {
          "id": "MoTxK3yaKV3JRyV5v8vNU",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "AUAMQVhQaeLIHriycINeL",
          "otherParty": "DKB",
          "otherPartyAccount": null,
          "description": "Depot Nr. 56547783 Dividenden / Zinsen",
          "amount": 162.5,
          "currency": "EUR",
          "accountCurrencyAmount": 162.5,
          "fxAmount": null,
          "fxCurrency": null,
          "date": 1647302400000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": "investment-dividend",
          "isManuallyAdjusted": null,
          "tags": {
            "type": null,
            "assetId": "BAS.F",
            "indexed": null,
            "accountId": "SKxazU1TIynqfmDvfUR1g",
            "recurring": null
          },
          "sortingOrderWithinMonth": null,
          "linkedTransactions": [],
          "category": "deposits",
          "assetId": "AUAMQVhQaeLIHriycINeL",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for brokerage",
          "transactionAmount": 162.5,
          "transactionPrice": 162.5,
          "transactionCurrency": "EUR",
          "openAmount": 162.5,
          "rebasedAmount": 162.5,
          "displayName": "Bank for brokerage",
          "rowType": "purchase",
          "amountAssetCurrency": 162.5,
          "positionOnDate": 98666.13780000001,
          "positionsPerProjectId": {
            "null": 98666.13780000001
          },
          "valueOnDateTransactionCurrency": 98666.13780000001,
          "valueOnDate": 98666.13780000001
        },
        {
          "date": 1669852800000,
          "assetId": "AUAMQVhQaeLIHriycINeL",
          "rowType": "kpi.baseline",
          "currency": "EUR",
          "displayName": "Bank for brokerage",
          "accountName": "Bank for brokerage",
          "positionOnDate": 98666.13780000001,
          "positionsPerProjectId": {
            "null": 98666.13780000001
          },
          "valueOnDateTransactionCurrency": 98666.13780000001,
          "valueOnDate": 98666.13780000001
        },
        {
          "id": "JpyKtleDwf5XEVVmuQ8Gl",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "AUAMQVhQaeLIHriycINeL",
          "otherParty": "DKB",
          "otherPartyAccount": null,
          "description": "Depot Nr. 56547783 Transaktionsgebühren Verkauf AAPL 60 St",
          "amount": 9083.4473,
          "currency": "EUR",
          "accountCurrencyAmount": 9083.4473,
          "fxAmount": 9960,
          "fxCurrency": "USD",
          "date": 1682985600000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": "investment-sale",
          "isManuallyAdjusted": null,
          "tags": null,
          "sortingOrderWithinMonth": null,
          "linkedTransactions": [
            {
              "id": "7rcZwnrwaZIKhSQp60rPl",
              "category": "stocks",
              "tags": {
                "linkType": "sale-to-transaction"
              }
            }
          ],
          "category": "deposits",
          "assetId": "AUAMQVhQaeLIHriycINeL",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for brokerage",
          "transactionAmount": 9083.4473,
          "transactionPrice": 9083.4473,
          "transactionCurrency": "EUR",
          "openAmount": 9083.4473,
          "rebasedAmount": 9083.4473,
          "displayName": "Bank for brokerage",
          "rowType": "purchase",
          "amountAssetCurrency": 9083.4473,
          "positionOnDate": 107749.58510000001,
          "positionsPerProjectId": {
            "null": 107749.58510000001
          },
          "valueOnDateTransactionCurrency": 107749.58510000001,
          "valueOnDate": 107749.58510000001
        },
        {
          "date": 1701475200000,
          "assetId": "AUAMQVhQaeLIHriycINeL",
          "rowType": "kpi.current",
          "currency": "EUR",
          "displayName": "Bank for brokerage",
          "accountName": "Bank for brokerage",
          "positionOnDate": 107749.58510000001,
          "positionsPerProjectId": {
            "null": 107749.58510000001
          },
          "valueOnDateTransactionCurrency": 107749.58510000001,
          "valueOnDate": 107749.58510000001
        }
      ]
    },
    "CJ2-NiJHmIDdTI_f9UuXs": {
      "CJ2-NiJHmIDdTI_f9UuXs": [
        {
          "id": "usD9GGkEY8Vgf4Bzo1HO-",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
          "otherParty": "initial saldo",
          "otherPartyAccount": null,
          "description": "initial saldo",
          "amount": 20000,
          "currency": "EUR",
          "accountCurrencyAmount": 20000,
          "fxAmount": null,
          "fxCurrency": null,
          "date": 1577836800000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": null,
          "isManuallyAdjusted": null,
          "tags": {
            "type": null,
            "assetId": null,
            "indexed": null,
            "accountId": null,
            "recurring": null
          },
          "sortingOrderWithinMonth": null,
          "linkedTransactions": [],
          "category": "deposits",
          "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for cost of living",
          "transactionAmount": 20000,
          "transactionPrice": 20000,
          "transactionCurrency": "EUR",
          "openAmount": 20000,
          "rebasedAmount": 20000,
          "displayName": "Bank for cost of living",
          "rowType": "purchase",
          "amountAssetCurrency": 20000,
          "positionOnDate": 20000,
          "positionsPerProjectId": {
            "null": 20000
          },
          "valueOnDateTransactionCurrency": 20000,
          "valueOnDate": 20000
        },
        {
          "id": "d3QCZMWgZJfQOmW60dsvj",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
          "otherParty": "Dave Brubeck",
          "otherPartyAccount": "DE02120300000000202051",
          "description": "internal transfer",
          "amount": -2000,
          "currency": "EUR",
          "accountCurrencyAmount": -2000,
          "fxAmount": null,
          "fxCurrency": null,
          "date": 1588291200000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": null,
          "isManuallyAdjusted": null,
          "tags": {
            "type": null,
            "assetId": null,
            "indexed": null,
            "accountId": null,
            "recurring": null
          },
          "sortingOrderWithinMonth": null,
          "linkedTransactions": [],
          "category": "deposits",
          "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for cost of living",
          "transactionAmount": -2000,
          "transactionPrice": -2000,
          "transactionCurrency": "EUR",
          "openAmount": -2000,
          "rebasedAmount": -2000,
          "displayName": "Bank for cost of living",
          "rowType": "sale",
          "amountAssetCurrency": -2000,
          "positionOnDate": 18000,
          "positionsPerProjectId": {
            "null": 18000
          },
          "valueOnDateTransactionCurrency": 18000,
          "valueOnDate": 18000
        },
        {
          "id": "9LQMsnWVBbm0dcXp8ZbrJ",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
          "otherParty": "Avis AG",
          "otherPartyAccount": "DE12387618273672636322",
          "description": "Mietwagen-Rechnung ",
          "amount": -317,
          "currency": "EUR",
          "accountCurrencyAmount": -317,
          "fxAmount": -313,
          "fxCurrency": "CHF",
          "date": 1600300800000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": null,
          "isManuallyAdjusted": null,
          "tags": {
            "type": null,
            "assetId": null,
            "indexed": null,
            "accountId": null,
            "recurring": null
          },
          "sortingOrderWithinMonth": 0,
          "linkedTransactions": [],
          "category": "deposits",
          "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for cost of living",
          "transactionAmount": -317,
          "transactionPrice": -317,
          "transactionCurrency": "EUR",
          "openAmount": -317,
          "rebasedAmount": -317,
          "displayName": "Bank for cost of living",
          "rowType": "sale",
          "amountAssetCurrency": -317,
          "positionOnDate": 17683,
          "positionsPerProjectId": {
            "null": 17683
          },
          "valueOnDateTransactionCurrency": 17683,
          "valueOnDate": 17683
        },
        {
          "id": "sDYdMXblsBRgUsTeVBK4L",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
          "otherParty": "Vonovia AG",
          "otherPartyAccount": null,
          "description": "Miete",
          "amount": -2850,
          "currency": "EUR",
          "accountCurrencyAmount": -2850,
          "fxAmount": null,
          "fxCurrency": null,
          "date": 1601251200000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": null,
          "isManuallyAdjusted": null,
          "tags": {
            "type": null,
            "assetId": null,
            "indexed": null,
            "accountId": null,
            "recurring": null
          },
          "sortingOrderWithinMonth": 0,
          "linkedTransactions": [],
          "category": "deposits",
          "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for cost of living",
          "transactionAmount": -2850,
          "transactionPrice": -2850,
          "transactionCurrency": "EUR",
          "openAmount": -2850,
          "rebasedAmount": -2850,
          "displayName": "Bank for cost of living",
          "rowType": "sale",
          "amountAssetCurrency": -2850,
          "positionOnDate": 14833,
          "positionsPerProjectId": {
            "null": 14833
          },
          "valueOnDateTransactionCurrency": 14833,
          "valueOnDate": 14833
        },
        {
          "id": "ghglCj86BOYDPDVKao66t",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
          "otherParty": "American Express",
          "otherPartyAccount": null,
          "description": "Kreditkarte 4160xxxxxxxxx4860",
          "amount": 6500,
          "currency": "EUR",
          "accountCurrencyAmount": 6500,
          "fxAmount": null,
          "fxCurrency": null,
          "date": 1601510400000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": null,
          "isManuallyAdjusted": null,
          "tags": {
            "type": null,
            "assetId": null,
            "indexed": null,
            "accountId": null,
            "recurring": null
          },
          "sortingOrderWithinMonth": 0,
          "linkedTransactions": [],
          "category": "deposits",
          "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for cost of living",
          "transactionAmount": 6500,
          "transactionPrice": 6500,
          "transactionCurrency": "EUR",
          "openAmount": 6500,
          "rebasedAmount": 6500,
          "displayName": "Bank for cost of living",
          "rowType": "purchase",
          "amountAssetCurrency": 6500,
          "positionOnDate": 21333,
          "positionsPerProjectId": {
            "null": 21333
          },
          "valueOnDateTransactionCurrency": 21333,
          "valueOnDate": 21333
        },
        {
          "id": "Esqc1Tn8T0kA0o8kjYgVb",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
          "otherParty": "Arbeitgeber AG",
          "otherPartyAccount": "DE02100500000054540402",
          "description": "Gehalt",
          "amount": -1500,
          "currency": "EUR",
          "accountCurrencyAmount": -1500,
          "fxAmount": null,
          "fxCurrency": null,
          "date": 1602288000000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": null,
          "isManuallyAdjusted": null,
          "tags": {
            "type": null,
            "assetId": null,
            "indexed": null,
            "accountId": null,
            "recurring": null
          },
          "sortingOrderWithinMonth": 0,
          "linkedTransactions": [],
          "category": "deposits",
          "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for cost of living",
          "transactionAmount": -1500,
          "transactionPrice": -1500,
          "transactionCurrency": "EUR",
          "openAmount": -1500,
          "rebasedAmount": -1500,
          "displayName": "Bank for cost of living",
          "rowType": "sale",
          "amountAssetCurrency": -1500,
          "positionOnDate": 19833,
          "positionsPerProjectId": {
            "null": 19833
          },
          "valueOnDateTransactionCurrency": 19833,
          "valueOnDate": 19833
        },
        {
          "id": "5FUV0z1_YeLpiQ6KCucCp",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
          "otherParty": "Vonovia AG",
          "otherPartyAccount": null,
          "description": "Miete",
          "amount": -2850,
          "currency": "EUR",
          "accountCurrencyAmount": -2850,
          "fxAmount": null,
          "fxCurrency": null,
          "date": 1603843200000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": null,
          "isManuallyAdjusted": null,
          "tags": {
            "type": null,
            "assetId": null,
            "indexed": null,
            "accountId": null,
            "recurring": null
          },
          "sortingOrderWithinMonth": 0,
          "linkedTransactions": [],
          "category": "deposits",
          "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for cost of living",
          "transactionAmount": -2850,
          "transactionPrice": -2850,
          "transactionCurrency": "EUR",
          "openAmount": -2850,
          "rebasedAmount": -2850,
          "displayName": "Bank for cost of living",
          "rowType": "sale",
          "amountAssetCurrency": -2850,
          "positionOnDate": 16983,
          "positionsPerProjectId": {
            "null": 16983
          },
          "valueOnDateTransactionCurrency": 16983,
          "valueOnDate": 16983
        },
        {
          "id": "VDNA1g-gbd4teRwujHSfL",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
          "otherParty": "American Express",
          "otherPartyAccount": null,
          "description": "Kreditkarte 4160xxxxxxxxx4859",
          "amount": 6500,
          "currency": "EUR",
          "accountCurrencyAmount": 6500,
          "fxAmount": null,
          "fxCurrency": null,
          "date": 1604188800000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": null,
          "isManuallyAdjusted": null,
          "tags": {
            "type": null,
            "assetId": null,
            "indexed": null,
            "accountId": null,
            "recurring": null
          },
          "sortingOrderWithinMonth": 0,
          "linkedTransactions": [],
          "category": "deposits",
          "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for cost of living",
          "transactionAmount": 6500,
          "transactionPrice": 6500,
          "transactionCurrency": "EUR",
          "openAmount": 6500,
          "rebasedAmount": 6500,
          "displayName": "Bank for cost of living",
          "rowType": "purchase",
          "amountAssetCurrency": 6500,
          "positionOnDate": 23483,
          "positionsPerProjectId": {
            "null": 23483
          },
          "valueOnDateTransactionCurrency": 23483,
          "valueOnDate": 23483
        },
        {
          "id": "DD_8cyDC4E6hJjHUlzQhV",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
          "otherParty": "Arbeitgeber AG",
          "otherPartyAccount": "DE02100500000054540402",
          "description": "Gehalt",
          "amount": -1500,
          "currency": "EUR",
          "accountCurrencyAmount": -1500,
          "fxAmount": null,
          "fxCurrency": null,
          "date": 1604966400000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": null,
          "isManuallyAdjusted": null,
          "tags": {
            "type": null,
            "assetId": null,
            "indexed": null,
            "accountId": null,
            "recurring": null
          },
          "sortingOrderWithinMonth": 0,
          "linkedTransactions": [],
          "category": "deposits",
          "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for cost of living",
          "transactionAmount": -1500,
          "transactionPrice": -1500,
          "transactionCurrency": "EUR",
          "openAmount": -1500,
          "rebasedAmount": -1500,
          "displayName": "Bank for cost of living",
          "rowType": "sale",
          "amountAssetCurrency": -1500,
          "positionOnDate": 21983,
          "positionsPerProjectId": {
            "null": 21983
          },
          "valueOnDateTransactionCurrency": 21983,
          "valueOnDate": 21983
        },
        {
          "id": "SklcLhdDtx44aRXXN_sJB",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
          "otherParty": "Vonovia AG",
          "otherPartyAccount": null,
          "description": "Miete",
          "amount": -2850,
          "currency": "EUR",
          "accountCurrencyAmount": -2850,
          "fxAmount": null,
          "fxCurrency": null,
          "date": 1606521600000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": null,
          "isManuallyAdjusted": null,
          "tags": {
            "type": null,
            "assetId": null,
            "indexed": null,
            "accountId": null,
            "recurring": null
          },
          "sortingOrderWithinMonth": 0,
          "linkedTransactions": [],
          "category": "deposits",
          "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for cost of living",
          "transactionAmount": -2850,
          "transactionPrice": -2850,
          "transactionCurrency": "EUR",
          "openAmount": -2850,
          "rebasedAmount": -2850,
          "displayName": "Bank for cost of living",
          "rowType": "sale",
          "amountAssetCurrency": -2850,
          "positionOnDate": 19133,
          "positionsPerProjectId": {
            "null": 19133
          },
          "valueOnDateTransactionCurrency": 19133,
          "valueOnDate": 19133
        },
        {
          "id": "34_uKEdswbfPMmYi3okdg",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
          "otherParty": "American Express",
          "otherPartyAccount": null,
          "description": "Kreditkarte 4160xxxxxxxxx4858",
          "amount": 6500,
          "currency": "EUR",
          "accountCurrencyAmount": 6500,
          "fxAmount": null,
          "fxCurrency": null,
          "date": 1606780800000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": null,
          "isManuallyAdjusted": null,
          "tags": {
            "type": null,
            "assetId": null,
            "indexed": null,
            "accountId": null,
            "recurring": null
          },
          "sortingOrderWithinMonth": 0,
          "linkedTransactions": [],
          "category": "deposits",
          "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for cost of living",
          "transactionAmount": 6500,
          "transactionPrice": 6500,
          "transactionCurrency": "EUR",
          "openAmount": 6500,
          "rebasedAmount": 6500,
          "displayName": "Bank for cost of living",
          "rowType": "purchase",
          "amountAssetCurrency": 6500,
          "positionOnDate": 25633,
          "positionsPerProjectId": {
            "null": 25633
          },
          "valueOnDateTransactionCurrency": 25633,
          "valueOnDate": 25633
        },
        {
          "id": "qqE1OwLxvg4pEDHAbNLjr",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
          "otherParty": "Arbeitgeber AG",
          "otherPartyAccount": "DE02100500000054540402",
          "description": "Gehalt",
          "amount": -1500,
          "currency": "EUR",
          "accountCurrencyAmount": -1500,
          "fxAmount": null,
          "fxCurrency": null,
          "date": 1607558400000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": null,
          "isManuallyAdjusted": null,
          "tags": {
            "type": null,
            "assetId": null,
            "indexed": null,
            "accountId": null,
            "recurring": null
          },
          "sortingOrderWithinMonth": 0,
          "linkedTransactions": [],
          "category": "deposits",
          "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for cost of living",
          "transactionAmount": -1500,
          "transactionPrice": -1500,
          "transactionCurrency": "EUR",
          "openAmount": -1500,
          "rebasedAmount": -1500,
          "displayName": "Bank for cost of living",
          "rowType": "sale",
          "amountAssetCurrency": -1500,
          "positionOnDate": 24133,
          "positionsPerProjectId": {
            "null": 24133
          },
          "valueOnDateTransactionCurrency": 24133,
          "valueOnDate": 24133
        },
        {
          "id": "VbNTdYcEkSOkCqxALN94c",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
          "otherParty": "Vonovia AG",
          "otherPartyAccount": null,
          "description": "Miete",
          "amount": -2850,
          "currency": "EUR",
          "accountCurrencyAmount": -2850,
          "fxAmount": null,
          "fxCurrency": null,
          "date": 1609113600000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": null,
          "isManuallyAdjusted": null,
          "tags": {
            "type": null,
            "assetId": null,
            "indexed": null,
            "accountId": null,
            "recurring": null
          },
          "sortingOrderWithinMonth": 0,
          "linkedTransactions": [],
          "category": "deposits",
          "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for cost of living",
          "transactionAmount": -2850,
          "transactionPrice": -2850,
          "transactionCurrency": "EUR",
          "openAmount": -2850,
          "rebasedAmount": -2850,
          "displayName": "Bank for cost of living",
          "rowType": "sale",
          "amountAssetCurrency": -2850,
          "positionOnDate": 21283,
          "positionsPerProjectId": {
            "null": 21283
          },
          "valueOnDateTransactionCurrency": 21283,
          "valueOnDate": 21283
        },
        {
          "id": "yDUtW0xHV-aoVexssWMVE",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
          "otherParty": "American Express",
          "otherPartyAccount": null,
          "description": "Kreditkarte 4160xxxxxxxxx4857",
          "amount": 6500,
          "currency": "EUR",
          "accountCurrencyAmount": 6500,
          "fxAmount": null,
          "fxCurrency": null,
          "date": 1609459200000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": null,
          "isManuallyAdjusted": null,
          "tags": {
            "type": null,
            "assetId": null,
            "indexed": null,
            "accountId": null,
            "recurring": null
          },
          "sortingOrderWithinMonth": 0,
          "linkedTransactions": [],
          "category": "deposits",
          "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for cost of living",
          "transactionAmount": 6500,
          "transactionPrice": 6500,
          "transactionCurrency": "EUR",
          "openAmount": 6500,
          "rebasedAmount": 6500,
          "displayName": "Bank for cost of living",
          "rowType": "purchase",
          "amountAssetCurrency": 6500,
          "positionOnDate": 27783,
          "positionsPerProjectId": {
            "null": 27783
          },
          "valueOnDateTransactionCurrency": 27783,
          "valueOnDate": 27783
        },
        {
          "id": "zGyWUosMOjIjUO2llm9JD",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
          "otherParty": "Arbeitgeber AG",
          "otherPartyAccount": "DE02100500000054540402",
          "description": "Gehalt",
          "amount": -1500,
          "currency": "EUR",
          "accountCurrencyAmount": -1500,
          "fxAmount": null,
          "fxCurrency": null,
          "date": 1610236800000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": null,
          "isManuallyAdjusted": null,
          "tags": {
            "type": null,
            "assetId": null,
            "indexed": null,
            "accountId": null,
            "recurring": null
          },
          "sortingOrderWithinMonth": 0,
          "linkedTransactions": [],
          "category": "deposits",
          "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for cost of living",
          "transactionAmount": -1500,
          "transactionPrice": -1500,
          "transactionCurrency": "EUR",
          "openAmount": -1500,
          "rebasedAmount": -1500,
          "displayName": "Bank for cost of living",
          "rowType": "sale",
          "amountAssetCurrency": -1500,
          "positionOnDate": 26283,
          "positionsPerProjectId": {
            "null": 26283
          },
          "valueOnDateTransactionCurrency": 26283,
          "valueOnDate": 26283
        },
        {
          "id": "rSPjjGgzU3NKMdT0fVNoH",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
          "otherParty": "Vonovia AG",
          "otherPartyAccount": null,
          "description": "Miete",
          "amount": -2850,
          "currency": "EUR",
          "accountCurrencyAmount": -2850,
          "fxAmount": null,
          "fxCurrency": null,
          "date": 1611792000000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": null,
          "isManuallyAdjusted": null,
          "tags": {
            "type": null,
            "assetId": null,
            "indexed": null,
            "accountId": null,
            "recurring": null
          },
          "sortingOrderWithinMonth": 0,
          "linkedTransactions": [],
          "category": "deposits",
          "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for cost of living",
          "transactionAmount": -2850,
          "transactionPrice": -2850,
          "transactionCurrency": "EUR",
          "openAmount": -2850,
          "rebasedAmount": -2850,
          "displayName": "Bank for cost of living",
          "rowType": "sale",
          "amountAssetCurrency": -2850,
          "positionOnDate": 23433,
          "positionsPerProjectId": {
            "null": 23433
          },
          "valueOnDateTransactionCurrency": 23433,
          "valueOnDate": 23433
        },
        {
          "id": "Ww5olKNjbY1-MIwOGCxwB",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
          "otherParty": "American Express",
          "otherPartyAccount": null,
          "description": "Kreditkarte 4160xxxxxxxxx4856",
          "amount": 6500,
          "currency": "EUR",
          "accountCurrencyAmount": 6500,
          "fxAmount": null,
          "fxCurrency": null,
          "date": 1612137600000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": null,
          "isManuallyAdjusted": null,
          "tags": {
            "type": null,
            "assetId": null,
            "indexed": null,
            "accountId": null,
            "recurring": null
          },
          "sortingOrderWithinMonth": 0,
          "linkedTransactions": [],
          "category": "deposits",
          "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for cost of living",
          "transactionAmount": 6500,
          "transactionPrice": 6500,
          "transactionCurrency": "EUR",
          "openAmount": 6500,
          "rebasedAmount": 6500,
          "displayName": "Bank for cost of living",
          "rowType": "purchase",
          "amountAssetCurrency": 6500,
          "positionOnDate": 29933,
          "positionsPerProjectId": {
            "null": 29933
          },
          "valueOnDateTransactionCurrency": 29933,
          "valueOnDate": 29933
        },
        {
          "id": "UrNWZ2TgoHR1pJ6ioTsmk",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
          "otherParty": "Handwerker GbR",
          "otherPartyAccount": null,
          "description": "Rechnung 12/233/21",
          "amount": -546.21,
          "currency": "EUR",
          "accountCurrencyAmount": -546.21,
          "fxAmount": null,
          "fxCurrency": null,
          "date": 1612224000000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": null,
          "isManuallyAdjusted": null,
          "tags": {
            "type": null,
            "assetId": null,
            "indexed": null,
            "accountId": null,
            "recurring": null
          },
          "sortingOrderWithinMonth": null,
          "linkedTransactions": [],
          "category": "deposits",
          "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for cost of living",
          "transactionAmount": -546.21,
          "transactionPrice": -546.21,
          "transactionCurrency": "EUR",
          "openAmount": -546.21,
          "rebasedAmount": -546.21,
          "displayName": "Bank for cost of living",
          "rowType": "sale",
          "amountAssetCurrency": -546.21,
          "positionOnDate": 29386.79,
          "positionsPerProjectId": {
            "null": 29386.79
          },
          "valueOnDateTransactionCurrency": 29386.79,
          "valueOnDate": 29386.79
        },
        {
          "id": "jgKCFtgSM-1BbsKJDpw87",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
          "otherParty": "Vonovia AG",
          "otherPartyAccount": null,
          "description": "Miete",
          "amount": -1500,
          "currency": "EUR",
          "accountCurrencyAmount": -1500,
          "fxAmount": null,
          "fxCurrency": null,
          "date": 1612915200000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": null,
          "isManuallyAdjusted": null,
          "tags": {
            "type": null,
            "assetId": null,
            "indexed": null,
            "accountId": null,
            "recurring": null
          },
          "sortingOrderWithinMonth": 0,
          "linkedTransactions": [],
          "category": "deposits",
          "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for cost of living",
          "transactionAmount": -1500,
          "transactionPrice": -1500,
          "transactionCurrency": "EUR",
          "openAmount": -1500,
          "rebasedAmount": -1500,
          "displayName": "Bank for cost of living",
          "rowType": "sale",
          "amountAssetCurrency": -1500,
          "positionOnDate": 27886.79,
          "positionsPerProjectId": {
            "null": 27886.79
          },
          "valueOnDateTransactionCurrency": 27886.79,
          "valueOnDate": 27886.79
        },
        {
          "id": "E1TIl2Rw0bSufQ7QkjxZo",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
          "otherParty": "American Express",
          "otherPartyAccount": null,
          "description": "Kreditkarte 4160xxxxxxxxx4858",
          "amount": -2850,
          "currency": "EUR",
          "accountCurrencyAmount": -2850,
          "fxAmount": null,
          "fxCurrency": null,
          "date": 1614470400000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": null,
          "isManuallyAdjusted": null,
          "tags": {
            "type": null,
            "assetId": null,
            "indexed": null,
            "accountId": null,
            "recurring": null
          },
          "sortingOrderWithinMonth": 0,
          "linkedTransactions": [],
          "category": "deposits",
          "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for cost of living",
          "transactionAmount": -2850,
          "transactionPrice": -2850,
          "transactionCurrency": "EUR",
          "openAmount": -2850,
          "rebasedAmount": -2850,
          "displayName": "Bank for cost of living",
          "rowType": "sale",
          "amountAssetCurrency": -2850,
          "positionOnDate": 25036.79,
          "positionsPerProjectId": {
            "null": 25036.79
          },
          "valueOnDateTransactionCurrency": 25036.79,
          "valueOnDate": 25036.79
        },
        {
          "id": "mucDdxZgos49pSKfJ9EtA",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
          "otherParty": "Arbeitgeber AG",
          "otherPartyAccount": "DE02100500000054540402",
          "description": "Gehalt",
          "amount": 6500,
          "currency": "EUR",
          "accountCurrencyAmount": 6500,
          "fxAmount": null,
          "fxCurrency": null,
          "date": 1614556800000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": null,
          "isManuallyAdjusted": null,
          "tags": {
            "type": null,
            "assetId": null,
            "indexed": null,
            "accountId": null,
            "recurring": null
          },
          "sortingOrderWithinMonth": 0,
          "linkedTransactions": [],
          "category": "deposits",
          "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for cost of living",
          "transactionAmount": 6500,
          "transactionPrice": 6500,
          "transactionCurrency": "EUR",
          "openAmount": 6500,
          "rebasedAmount": 6500,
          "displayName": "Bank for cost of living",
          "rowType": "purchase",
          "amountAssetCurrency": 6500,
          "positionOnDate": 31536.79,
          "positionsPerProjectId": {
            "null": 31536.79
          },
          "valueOnDateTransactionCurrency": 31536.79,
          "valueOnDate": 31536.79
        },
        {
          "id": "-oYtAmJSjCupLydPCRqK6",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
          "otherParty": "Vonovia AG",
          "otherPartyAccount": null,
          "description": "Miete",
          "amount": -1500,
          "currency": "EUR",
          "accountCurrencyAmount": -1500,
          "fxAmount": null,
          "fxCurrency": null,
          "date": 1615334400000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": null,
          "isManuallyAdjusted": null,
          "tags": {
            "type": null,
            "assetId": null,
            "indexed": null,
            "accountId": null,
            "recurring": null
          },
          "sortingOrderWithinMonth": 0,
          "linkedTransactions": [],
          "category": "deposits",
          "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for cost of living",
          "transactionAmount": -1500,
          "transactionPrice": -1500,
          "transactionCurrency": "EUR",
          "openAmount": -1500,
          "rebasedAmount": -1500,
          "displayName": "Bank for cost of living",
          "rowType": "sale",
          "amountAssetCurrency": -1500,
          "positionOnDate": 30036.79,
          "positionsPerProjectId": {
            "null": 30036.79
          },
          "valueOnDateTransactionCurrency": 30036.79,
          "valueOnDate": 30036.79
        },
        {
          "id": "73B2iFv1LijYpuA9ZMdGU",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
          "otherParty": "American Express",
          "otherPartyAccount": null,
          "description": "Kreditkarte 4160xxxxxxxxx4857",
          "amount": -2850,
          "currency": "EUR",
          "accountCurrencyAmount": -2850,
          "fxAmount": null,
          "fxCurrency": null,
          "date": 1616889600000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": null,
          "isManuallyAdjusted": null,
          "tags": {
            "type": null,
            "assetId": null,
            "indexed": null,
            "accountId": null,
            "recurring": null
          },
          "sortingOrderWithinMonth": 0,
          "linkedTransactions": [],
          "category": "deposits",
          "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for cost of living",
          "transactionAmount": -2850,
          "transactionPrice": -2850,
          "transactionCurrency": "EUR",
          "openAmount": -2850,
          "rebasedAmount": -2850,
          "displayName": "Bank for cost of living",
          "rowType": "sale",
          "amountAssetCurrency": -2850,
          "positionOnDate": 27186.79,
          "positionsPerProjectId": {
            "null": 27186.79
          },
          "valueOnDateTransactionCurrency": 27186.79,
          "valueOnDate": 27186.79
        },
        {
          "id": "GU-MKsUSUGi7QdGUpzy-g",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
          "otherParty": "Arbeitgeber AG",
          "otherPartyAccount": "DE02100500000054540402",
          "description": "Gehalt",
          "amount": 6500,
          "currency": "EUR",
          "accountCurrencyAmount": 6500,
          "fxAmount": null,
          "fxCurrency": null,
          "date": 1617235200000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": null,
          "isManuallyAdjusted": null,
          "tags": {
            "type": null,
            "assetId": null,
            "indexed": null,
            "accountId": null,
            "recurring": null
          },
          "sortingOrderWithinMonth": 0,
          "linkedTransactions": [],
          "category": "deposits",
          "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for cost of living",
          "transactionAmount": 6500,
          "transactionPrice": 6500,
          "transactionCurrency": "EUR",
          "openAmount": 6500,
          "rebasedAmount": 6500,
          "displayName": "Bank for cost of living",
          "rowType": "purchase",
          "amountAssetCurrency": 6500,
          "positionOnDate": 33686.79,
          "positionsPerProjectId": {
            "null": 33686.79
          },
          "valueOnDateTransactionCurrency": 33686.79,
          "valueOnDate": 33686.79
        },
        {
          "id": "NC_EArhIlvyBpSOzz8W3t",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
          "otherParty": "Vonovia AG",
          "otherPartyAccount": null,
          "description": "Miete",
          "amount": -1500,
          "currency": "EUR",
          "accountCurrencyAmount": -1500,
          "fxAmount": null,
          "fxCurrency": null,
          "date": 1618012800000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": null,
          "isManuallyAdjusted": null,
          "tags": {
            "type": null,
            "assetId": null,
            "indexed": null,
            "accountId": null,
            "recurring": null
          },
          "sortingOrderWithinMonth": 0,
          "linkedTransactions": [],
          "category": "deposits",
          "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for cost of living",
          "transactionAmount": -1500,
          "transactionPrice": -1500,
          "transactionCurrency": "EUR",
          "openAmount": -1500,
          "rebasedAmount": -1500,
          "displayName": "Bank for cost of living",
          "rowType": "sale",
          "amountAssetCurrency": -1500,
          "positionOnDate": 32186.79,
          "positionsPerProjectId": {
            "null": 32186.79
          },
          "valueOnDateTransactionCurrency": 32186.79,
          "valueOnDate": 32186.79
        },
        {
          "id": "3SlAQQIey9hvBuIRDve-m",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
          "otherParty": "American Express",
          "otherPartyAccount": null,
          "description": "Kreditkarte 4160xxxxxxxxx4856",
          "amount": -2850,
          "currency": "EUR",
          "accountCurrencyAmount": -2850,
          "fxAmount": null,
          "fxCurrency": null,
          "date": 1619568000000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": null,
          "isManuallyAdjusted": null,
          "tags": {
            "type": null,
            "assetId": null,
            "indexed": null,
            "accountId": null,
            "recurring": null
          },
          "sortingOrderWithinMonth": 0,
          "linkedTransactions": [],
          "category": "deposits",
          "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for cost of living",
          "transactionAmount": -2850,
          "transactionPrice": -2850,
          "transactionCurrency": "EUR",
          "openAmount": -2850,
          "rebasedAmount": -2850,
          "displayName": "Bank for cost of living",
          "rowType": "sale",
          "amountAssetCurrency": -2850,
          "positionOnDate": 29336.79,
          "positionsPerProjectId": {
            "null": 29336.79
          },
          "valueOnDateTransactionCurrency": 29336.79,
          "valueOnDate": 29336.79
        },
        {
          "id": "bMcKjWk1OE9GACGuF5Kur",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
          "otherParty": "Arbeitgeber AG",
          "otherPartyAccount": "DE02100500000054540402",
          "description": "Gehalt",
          "amount": 6500,
          "currency": "EUR",
          "accountCurrencyAmount": 6500,
          "fxAmount": null,
          "fxCurrency": null,
          "date": 1619827200000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": null,
          "isManuallyAdjusted": null,
          "tags": {
            "type": null,
            "assetId": null,
            "indexed": null,
            "accountId": null,
            "recurring": null
          },
          "sortingOrderWithinMonth": 0,
          "linkedTransactions": [],
          "category": "deposits",
          "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for cost of living",
          "transactionAmount": 6500,
          "transactionPrice": 6500,
          "transactionCurrency": "EUR",
          "openAmount": 6500,
          "rebasedAmount": 6500,
          "displayName": "Bank for cost of living",
          "rowType": "purchase",
          "amountAssetCurrency": 6500,
          "positionOnDate": 35836.79,
          "positionsPerProjectId": {
            "null": 35836.79
          },
          "valueOnDateTransactionCurrency": 35836.79,
          "valueOnDate": 35836.79
        },
        {
          "id": "5u0OpUh42_jNJ4OgM7X1Q",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
          "otherParty": "Vonovia AG",
          "otherPartyAccount": null,
          "description": "Miete",
          "amount": -1500,
          "currency": "EUR",
          "accountCurrencyAmount": -1500,
          "fxAmount": null,
          "fxCurrency": null,
          "date": 1620604800000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": null,
          "isManuallyAdjusted": null,
          "tags": {
            "type": null,
            "assetId": null,
            "indexed": null,
            "accountId": null,
            "recurring": null
          },
          "sortingOrderWithinMonth": 0,
          "linkedTransactions": [],
          "category": "deposits",
          "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for cost of living",
          "transactionAmount": -1500,
          "transactionPrice": -1500,
          "transactionCurrency": "EUR",
          "openAmount": -1500,
          "rebasedAmount": -1500,
          "displayName": "Bank for cost of living",
          "rowType": "sale",
          "amountAssetCurrency": -1500,
          "positionOnDate": 34336.79,
          "positionsPerProjectId": {
            "null": 34336.79
          },
          "valueOnDateTransactionCurrency": 34336.79,
          "valueOnDate": 34336.79
        },
        {
          "id": "_F4GHxNmaFRrc_n8lFKEb",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
          "otherParty": "American Express",
          "otherPartyAccount": null,
          "description": "Kreditkarte 4160xxxxxxxxx4858",
          "amount": -2850,
          "currency": "EUR",
          "accountCurrencyAmount": -2850,
          "fxAmount": null,
          "fxCurrency": null,
          "date": 1622160000000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": null,
          "isManuallyAdjusted": null,
          "tags": {
            "type": null,
            "assetId": null,
            "indexed": null,
            "accountId": null,
            "recurring": null
          },
          "sortingOrderWithinMonth": 0,
          "linkedTransactions": [],
          "category": "deposits",
          "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for cost of living",
          "transactionAmount": -2850,
          "transactionPrice": -2850,
          "transactionCurrency": "EUR",
          "openAmount": -2850,
          "rebasedAmount": -2850,
          "displayName": "Bank for cost of living",
          "rowType": "sale",
          "amountAssetCurrency": -2850,
          "positionOnDate": 31486.79,
          "positionsPerProjectId": {
            "null": 31486.79
          },
          "valueOnDateTransactionCurrency": 31486.79,
          "valueOnDate": 31486.79
        },
        {
          "id": "Tg5BJT5F5f_PSK8dMAL-z",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
          "otherParty": "Arbeitgeber AG",
          "otherPartyAccount": "DE02100500000054540402",
          "description": "Gehalt",
          "amount": 6500,
          "currency": "EUR",
          "accountCurrencyAmount": 6500,
          "fxAmount": null,
          "fxCurrency": null,
          "date": 1622505600000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": null,
          "isManuallyAdjusted": null,
          "tags": {
            "type": null,
            "assetId": null,
            "indexed": null,
            "accountId": null,
            "recurring": null
          },
          "sortingOrderWithinMonth": 0,
          "linkedTransactions": [],
          "category": "deposits",
          "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for cost of living",
          "transactionAmount": 6500,
          "transactionPrice": 6500,
          "transactionCurrency": "EUR",
          "openAmount": 6500,
          "rebasedAmount": 6500,
          "displayName": "Bank for cost of living",
          "rowType": "purchase",
          "amountAssetCurrency": 6500,
          "positionOnDate": 37986.79,
          "positionsPerProjectId": {
            "null": 37986.79
          },
          "valueOnDateTransactionCurrency": 37986.79,
          "valueOnDate": 37986.79
        },
        {
          "id": "bIaG0Jm3mrybBaKomKfh2",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
          "otherParty": "Vonovia AG",
          "otherPartyAccount": null,
          "description": "Miete",
          "amount": -1500,
          "currency": "EUR",
          "accountCurrencyAmount": -1500,
          "fxAmount": null,
          "fxCurrency": null,
          "date": 1623283200000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": null,
          "isManuallyAdjusted": null,
          "tags": {
            "type": null,
            "assetId": null,
            "indexed": null,
            "accountId": null,
            "recurring": null
          },
          "sortingOrderWithinMonth": 0,
          "linkedTransactions": [],
          "category": "deposits",
          "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for cost of living",
          "transactionAmount": -1500,
          "transactionPrice": -1500,
          "transactionCurrency": "EUR",
          "openAmount": -1500,
          "rebasedAmount": -1500,
          "displayName": "Bank for cost of living",
          "rowType": "sale",
          "amountAssetCurrency": -1500,
          "positionOnDate": 36486.79,
          "positionsPerProjectId": {
            "null": 36486.79
          },
          "valueOnDateTransactionCurrency": 36486.79,
          "valueOnDate": 36486.79
        },
        {
          "id": "zMA7Ib9n3k4wgcn0wjQep",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
          "otherParty": "American Express",
          "otherPartyAccount": null,
          "description": "Kreditkarte 4160xxxxxxxxx4857",
          "amount": -2850,
          "currency": "EUR",
          "accountCurrencyAmount": -2850,
          "fxAmount": null,
          "fxCurrency": null,
          "date": 1624838400000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": null,
          "isManuallyAdjusted": null,
          "tags": {
            "type": null,
            "assetId": null,
            "indexed": null,
            "accountId": null,
            "recurring": null
          },
          "sortingOrderWithinMonth": 0,
          "linkedTransactions": [],
          "category": "deposits",
          "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for cost of living",
          "transactionAmount": -2850,
          "transactionPrice": -2850,
          "transactionCurrency": "EUR",
          "openAmount": -2850,
          "rebasedAmount": -2850,
          "displayName": "Bank for cost of living",
          "rowType": "sale",
          "amountAssetCurrency": -2850,
          "positionOnDate": 33636.79,
          "positionsPerProjectId": {
            "null": 33636.79
          },
          "valueOnDateTransactionCurrency": 33636.79,
          "valueOnDate": 33636.79
        },
        {
          "id": "bf-cV0inlYPD5BcC6zai3",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
          "otherParty": "Arbeitgeber AG",
          "otherPartyAccount": "DE02100500000054540402",
          "description": "Gehalt",
          "amount": 6500,
          "currency": "EUR",
          "accountCurrencyAmount": 6500,
          "fxAmount": null,
          "fxCurrency": null,
          "date": 1625097600000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": null,
          "isManuallyAdjusted": null,
          "tags": {
            "type": null,
            "assetId": null,
            "indexed": null,
            "accountId": null,
            "recurring": null
          },
          "sortingOrderWithinMonth": 0,
          "linkedTransactions": [],
          "category": "deposits",
          "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for cost of living",
          "transactionAmount": 6500,
          "transactionPrice": 6500,
          "transactionCurrency": "EUR",
          "openAmount": 6500,
          "rebasedAmount": 6500,
          "displayName": "Bank for cost of living",
          "rowType": "purchase",
          "amountAssetCurrency": 6500,
          "positionOnDate": 40136.79,
          "positionsPerProjectId": {
            "null": 40136.79
          },
          "valueOnDateTransactionCurrency": 40136.79,
          "valueOnDate": 40136.79
        },
        {
          "id": "SlaU9IQLfnu7gOn4xz_Jl",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
          "otherParty": "Vonovia AG",
          "otherPartyAccount": null,
          "description": "Miete",
          "amount": -1500,
          "currency": "EUR",
          "accountCurrencyAmount": -1500,
          "fxAmount": null,
          "fxCurrency": null,
          "date": 1625875200000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": null,
          "isManuallyAdjusted": null,
          "tags": {
            "type": null,
            "assetId": null,
            "indexed": null,
            "accountId": null,
            "recurring": null
          },
          "sortingOrderWithinMonth": 0,
          "linkedTransactions": [],
          "category": "deposits",
          "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for cost of living",
          "transactionAmount": -1500,
          "transactionPrice": -1500,
          "transactionCurrency": "EUR",
          "openAmount": -1500,
          "rebasedAmount": -1500,
          "displayName": "Bank for cost of living",
          "rowType": "sale",
          "amountAssetCurrency": -1500,
          "positionOnDate": 38636.79,
          "positionsPerProjectId": {
            "null": 38636.79
          },
          "valueOnDateTransactionCurrency": 38636.79,
          "valueOnDate": 38636.79
        },
        {
          "id": "zDGz8kPd3lk1rHX4xcZV1",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
          "otherParty": "American Express",
          "otherPartyAccount": null,
          "description": "Kreditkarte 4160xxxxxxxxx4860",
          "amount": -2850,
          "currency": "EUR",
          "accountCurrencyAmount": -2850,
          "fxAmount": null,
          "fxCurrency": null,
          "date": 1627430400000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": null,
          "isManuallyAdjusted": null,
          "tags": {
            "type": null,
            "assetId": null,
            "indexed": null,
            "accountId": null,
            "recurring": null
          },
          "sortingOrderWithinMonth": 0,
          "linkedTransactions": [],
          "category": "deposits",
          "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for cost of living",
          "transactionAmount": -2850,
          "transactionPrice": -2850,
          "transactionCurrency": "EUR",
          "openAmount": -2850,
          "rebasedAmount": -2850,
          "displayName": "Bank for cost of living",
          "rowType": "sale",
          "amountAssetCurrency": -2850,
          "positionOnDate": 35786.79,
          "positionsPerProjectId": {
            "null": 35786.79
          },
          "valueOnDateTransactionCurrency": 35786.79,
          "valueOnDate": 35786.79
        },
        {
          "id": "P48u4wBfX3xZPQHgjqtg8",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
          "otherParty": "Arbeitgeber AG",
          "otherPartyAccount": "DE02100500000054540402",
          "description": "Gehalt",
          "amount": 6500,
          "currency": "EUR",
          "accountCurrencyAmount": 6500,
          "fxAmount": null,
          "fxCurrency": null,
          "date": 1627776000000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": null,
          "isManuallyAdjusted": null,
          "tags": {
            "type": null,
            "assetId": null,
            "indexed": null,
            "accountId": null,
            "recurring": null
          },
          "sortingOrderWithinMonth": 0,
          "linkedTransactions": [],
          "category": "deposits",
          "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for cost of living",
          "transactionAmount": 6500,
          "transactionPrice": 6500,
          "transactionCurrency": "EUR",
          "openAmount": 6500,
          "rebasedAmount": 6500,
          "displayName": "Bank for cost of living",
          "rowType": "purchase",
          "amountAssetCurrency": 6500,
          "positionOnDate": 42286.79,
          "positionsPerProjectId": {
            "null": 42286.79
          },
          "valueOnDateTransactionCurrency": 42286.79,
          "valueOnDate": 42286.79
        },
        {
          "id": "0HK29b4YAMJIIwrR8p_y1",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
          "otherParty": "Vonovia AG",
          "otherPartyAccount": null,
          "description": "Miete",
          "amount": -1500,
          "currency": "EUR",
          "accountCurrencyAmount": -1500,
          "fxAmount": null,
          "fxCurrency": null,
          "date": 1628553600000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": null,
          "isManuallyAdjusted": null,
          "tags": {
            "type": null,
            "assetId": null,
            "indexed": null,
            "accountId": null,
            "recurring": null
          },
          "sortingOrderWithinMonth": 0,
          "linkedTransactions": [],
          "category": "deposits",
          "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for cost of living",
          "transactionAmount": -1500,
          "transactionPrice": -1500,
          "transactionCurrency": "EUR",
          "openAmount": -1500,
          "rebasedAmount": -1500,
          "displayName": "Bank for cost of living",
          "rowType": "sale",
          "amountAssetCurrency": -1500,
          "positionOnDate": 40786.79,
          "positionsPerProjectId": {
            "null": 40786.79
          },
          "valueOnDateTransactionCurrency": 40786.79,
          "valueOnDate": 40786.79
        },
        {
          "id": "G2df6niByJVi1WQNSZEt6",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
          "otherParty": "American Express",
          "otherPartyAccount": null,
          "description": "Kreditkarte 4160xxxxxxxxx4859",
          "amount": -2850,
          "currency": "EUR",
          "accountCurrencyAmount": -2850,
          "fxAmount": null,
          "fxCurrency": null,
          "date": 1630108800000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": null,
          "isManuallyAdjusted": null,
          "tags": {
            "type": null,
            "assetId": null,
            "indexed": null,
            "accountId": null,
            "recurring": null
          },
          "sortingOrderWithinMonth": 0,
          "linkedTransactions": [],
          "category": "deposits",
          "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for cost of living",
          "transactionAmount": -2850,
          "transactionPrice": -2850,
          "transactionCurrency": "EUR",
          "openAmount": -2850,
          "rebasedAmount": -2850,
          "displayName": "Bank for cost of living",
          "rowType": "sale",
          "amountAssetCurrency": -2850,
          "positionOnDate": 37936.79,
          "positionsPerProjectId": {
            "null": 37936.79
          },
          "valueOnDateTransactionCurrency": 37936.79,
          "valueOnDate": 37936.79
        },
        {
          "id": "Ysvj7Y-jCbMHIM-l-mxIc",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
          "otherParty": "Arbeitgeber AG",
          "otherPartyAccount": "DE02100500000054540402",
          "description": "Gehalt",
          "amount": 6500,
          "currency": "EUR",
          "accountCurrencyAmount": 6500,
          "fxAmount": null,
          "fxCurrency": null,
          "date": 1630454400000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": null,
          "isManuallyAdjusted": null,
          "tags": {
            "type": null,
            "assetId": null,
            "indexed": null,
            "accountId": null,
            "recurring": null
          },
          "sortingOrderWithinMonth": 0,
          "linkedTransactions": [],
          "category": "deposits",
          "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for cost of living",
          "transactionAmount": 6500,
          "transactionPrice": 6500,
          "transactionCurrency": "EUR",
          "openAmount": 6500,
          "rebasedAmount": 6500,
          "displayName": "Bank for cost of living",
          "rowType": "purchase",
          "amountAssetCurrency": 6500,
          "positionOnDate": 44436.79,
          "positionsPerProjectId": {
            "null": 44436.79
          },
          "valueOnDateTransactionCurrency": 44436.79,
          "valueOnDate": 44436.79
        },
        {
          "id": "t6yZPKJ0CfCvJpteWMMr4",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
          "otherParty": "Vonovia AG",
          "otherPartyAccount": null,
          "description": "Miete",
          "amount": -1500,
          "currency": "EUR",
          "accountCurrencyAmount": -1500,
          "fxAmount": null,
          "fxCurrency": null,
          "date": 1631232000000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": null,
          "isManuallyAdjusted": null,
          "tags": {
            "type": null,
            "assetId": null,
            "indexed": null,
            "accountId": null,
            "recurring": null
          },
          "sortingOrderWithinMonth": 0,
          "linkedTransactions": [],
          "category": "deposits",
          "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for cost of living",
          "transactionAmount": -1500,
          "transactionPrice": -1500,
          "transactionCurrency": "EUR",
          "openAmount": -1500,
          "rebasedAmount": -1500,
          "displayName": "Bank for cost of living",
          "rowType": "sale",
          "amountAssetCurrency": -1500,
          "positionOnDate": 42936.79,
          "positionsPerProjectId": {
            "null": 42936.79
          },
          "valueOnDateTransactionCurrency": 42936.79,
          "valueOnDate": 42936.79
        },
        {
          "id": "D2SAFp465_vXcQQ7rphNr",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
          "otherParty": "American Express",
          "otherPartyAccount": null,
          "description": "Kreditkarte 4160xxxxxxxxx4858",
          "amount": -2850,
          "currency": "EUR",
          "accountCurrencyAmount": -2850,
          "fxAmount": null,
          "fxCurrency": null,
          "date": 1632787200000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": null,
          "isManuallyAdjusted": null,
          "tags": {
            "type": null,
            "assetId": null,
            "indexed": null,
            "accountId": null,
            "recurring": null
          },
          "sortingOrderWithinMonth": 0,
          "linkedTransactions": [],
          "category": "deposits",
          "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for cost of living",
          "transactionAmount": -2850,
          "transactionPrice": -2850,
          "transactionCurrency": "EUR",
          "openAmount": -2850,
          "rebasedAmount": -2850,
          "displayName": "Bank for cost of living",
          "rowType": "sale",
          "amountAssetCurrency": -2850,
          "positionOnDate": 40086.79,
          "positionsPerProjectId": {
            "null": 40086.79
          },
          "valueOnDateTransactionCurrency": 40086.79,
          "valueOnDate": 40086.79
        },
        {
          "id": "S3O2Z9we8Faf0QFWvyk3U",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
          "otherParty": "Arbeitgeber AG",
          "otherPartyAccount": "DE02100500000054540402",
          "description": "Gehalt",
          "amount": 6500,
          "currency": "EUR",
          "accountCurrencyAmount": 6500,
          "fxAmount": null,
          "fxCurrency": null,
          "date": 1633046400000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": null,
          "isManuallyAdjusted": null,
          "tags": {
            "type": null,
            "assetId": null,
            "indexed": null,
            "accountId": null,
            "recurring": null
          },
          "sortingOrderWithinMonth": 0,
          "linkedTransactions": [],
          "category": "deposits",
          "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for cost of living",
          "transactionAmount": 6500,
          "transactionPrice": 6500,
          "transactionCurrency": "EUR",
          "openAmount": 6500,
          "rebasedAmount": 6500,
          "displayName": "Bank for cost of living",
          "rowType": "purchase",
          "amountAssetCurrency": 6500,
          "positionOnDate": 46586.79,
          "positionsPerProjectId": {
            "null": 46586.79
          },
          "valueOnDateTransactionCurrency": 46586.79,
          "valueOnDate": 46586.79
        },
        {
          "id": "pSbtXojtc50MvMOHD1MZP",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
          "otherParty": "Vonovia AG",
          "otherPartyAccount": null,
          "description": "Miete",
          "amount": -1500,
          "currency": "EUR",
          "accountCurrencyAmount": -1500,
          "fxAmount": null,
          "fxCurrency": null,
          "date": 1633824000000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": null,
          "isManuallyAdjusted": null,
          "tags": {
            "type": null,
            "assetId": null,
            "indexed": null,
            "accountId": null,
            "recurring": null
          },
          "sortingOrderWithinMonth": 0,
          "linkedTransactions": [],
          "category": "deposits",
          "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for cost of living",
          "transactionAmount": -1500,
          "transactionPrice": -1500,
          "transactionCurrency": "EUR",
          "openAmount": -1500,
          "rebasedAmount": -1500,
          "displayName": "Bank for cost of living",
          "rowType": "sale",
          "amountAssetCurrency": -1500,
          "positionOnDate": 45086.79,
          "positionsPerProjectId": {
            "null": 45086.79
          },
          "valueOnDateTransactionCurrency": 45086.79,
          "valueOnDate": 45086.79
        },
        {
          "id": "QzpdJuhUGmVk8NoAQbRSP",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
          "otherParty": "American Express",
          "otherPartyAccount": null,
          "description": "Kreditkarte 4160xxxxxxxxx4857",
          "amount": -2850,
          "currency": "EUR",
          "accountCurrencyAmount": -2850,
          "fxAmount": null,
          "fxCurrency": null,
          "date": 1635379200000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": null,
          "isManuallyAdjusted": null,
          "tags": {
            "type": null,
            "assetId": null,
            "indexed": null,
            "accountId": null,
            "recurring": null
          },
          "sortingOrderWithinMonth": 0,
          "linkedTransactions": [],
          "category": "deposits",
          "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for cost of living",
          "transactionAmount": -2850,
          "transactionPrice": -2850,
          "transactionCurrency": "EUR",
          "openAmount": -2850,
          "rebasedAmount": -2850,
          "displayName": "Bank for cost of living",
          "rowType": "sale",
          "amountAssetCurrency": -2850,
          "positionOnDate": 42236.79,
          "positionsPerProjectId": {
            "null": 42236.79
          },
          "valueOnDateTransactionCurrency": 42236.79,
          "valueOnDate": 42236.79
        },
        {
          "id": "Y-gInjA8xdsSJmpcJ4_CG",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
          "otherParty": "Arbeitgeber AG",
          "otherPartyAccount": "DE02100500000054540402",
          "description": "Gehalt",
          "amount": 6500,
          "currency": "EUR",
          "accountCurrencyAmount": 6500,
          "fxAmount": null,
          "fxCurrency": null,
          "date": 1635724800000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": null,
          "isManuallyAdjusted": null,
          "tags": {
            "type": null,
            "assetId": null,
            "indexed": null,
            "accountId": null,
            "recurring": null
          },
          "sortingOrderWithinMonth": 0,
          "linkedTransactions": [],
          "category": "deposits",
          "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for cost of living",
          "transactionAmount": 6500,
          "transactionPrice": 6500,
          "transactionCurrency": "EUR",
          "openAmount": 6500,
          "rebasedAmount": 6500,
          "displayName": "Bank for cost of living",
          "rowType": "purchase",
          "amountAssetCurrency": 6500,
          "positionOnDate": 48736.79,
          "positionsPerProjectId": {
            "null": 48736.79
          },
          "valueOnDateTransactionCurrency": 48736.79,
          "valueOnDate": 48736.79
        },
        {
          "id": "qPT0DYA4fhZX9RMBVpD0X",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
          "otherParty": "Vonovia AG",
          "otherPartyAccount": null,
          "description": "Miete",
          "amount": -1500,
          "currency": "EUR",
          "accountCurrencyAmount": -1500,
          "fxAmount": null,
          "fxCurrency": null,
          "date": 1636502400000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": null,
          "isManuallyAdjusted": null,
          "tags": {
            "type": null,
            "assetId": null,
            "indexed": null,
            "accountId": null,
            "recurring": null
          },
          "sortingOrderWithinMonth": 0,
          "linkedTransactions": [],
          "category": "deposits",
          "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for cost of living",
          "transactionAmount": -1500,
          "transactionPrice": -1500,
          "transactionCurrency": "EUR",
          "openAmount": -1500,
          "rebasedAmount": -1500,
          "displayName": "Bank for cost of living",
          "rowType": "sale",
          "amountAssetCurrency": -1500,
          "positionOnDate": 47236.79,
          "positionsPerProjectId": {
            "null": 47236.79
          },
          "valueOnDateTransactionCurrency": 47236.79,
          "valueOnDate": 47236.79
        },
        {
          "id": "tiTTxhYZNNwLPzFuK4Zq3",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
          "otherParty": "American Express",
          "otherPartyAccount": null,
          "description": "Kreditkarte 4160xxxxxxxxx4856",
          "amount": -2850,
          "currency": "EUR",
          "accountCurrencyAmount": -2850,
          "fxAmount": null,
          "fxCurrency": null,
          "date": 1638057600000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": null,
          "isManuallyAdjusted": null,
          "tags": {
            "type": null,
            "assetId": null,
            "indexed": null,
            "accountId": null,
            "recurring": null
          },
          "sortingOrderWithinMonth": 0,
          "linkedTransactions": [],
          "category": "deposits",
          "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for cost of living",
          "transactionAmount": -2850,
          "transactionPrice": -2850,
          "transactionCurrency": "EUR",
          "openAmount": -2850,
          "rebasedAmount": -2850,
          "displayName": "Bank for cost of living",
          "rowType": "sale",
          "amountAssetCurrency": -2850,
          "positionOnDate": 44386.79,
          "positionsPerProjectId": {
            "null": 44386.79
          },
          "valueOnDateTransactionCurrency": 44386.79,
          "valueOnDate": 44386.79
        },
        {
          "id": "Ikz4KfiK_0qb20MXnbEjN",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
          "otherParty": "Arbeitgeber AG",
          "otherPartyAccount": "DE02100500000054540402",
          "description": "Gehalt",
          "amount": 6500,
          "currency": "EUR",
          "accountCurrencyAmount": 6500,
          "fxAmount": null,
          "fxCurrency": null,
          "date": 1638316800000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": null,
          "isManuallyAdjusted": null,
          "tags": {
            "type": null,
            "assetId": null,
            "indexed": null,
            "accountId": null,
            "recurring": null
          },
          "sortingOrderWithinMonth": 0,
          "linkedTransactions": [],
          "category": "deposits",
          "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for cost of living",
          "transactionAmount": 6500,
          "transactionPrice": 6500,
          "transactionCurrency": "EUR",
          "openAmount": 6500,
          "rebasedAmount": 6500,
          "displayName": "Bank for cost of living",
          "rowType": "purchase",
          "amountAssetCurrency": 6500,
          "positionOnDate": 50886.79,
          "positionsPerProjectId": {
            "null": 50886.79
          },
          "valueOnDateTransactionCurrency": 50886.79,
          "valueOnDate": 50886.79
        },
        {
          "date": 1638316800000,
          "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
          "rowType": "kpi.referenceBaseline",
          "currency": "EUR",
          "displayName": "Bank for cost of living",
          "accountName": "Bank for cost of living",
          "positionOnDate": 50886.79,
          "positionsPerProjectId": {
            "null": 50886.79
          },
          "valueOnDateTransactionCurrency": 50886.79,
          "valueOnDate": 50886.79
        },
        {
          "id": "hq4xHAXopCAnTNaKDs-N0",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
          "otherParty": "Vonovia AG",
          "otherPartyAccount": null,
          "description": "Miete",
          "amount": -1500,
          "currency": "EUR",
          "accountCurrencyAmount": -1500,
          "fxAmount": null,
          "fxCurrency": null,
          "date": 1639094400000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": null,
          "isManuallyAdjusted": null,
          "tags": {
            "type": null,
            "assetId": null,
            "indexed": null,
            "accountId": null,
            "recurring": null
          },
          "sortingOrderWithinMonth": 0,
          "linkedTransactions": [],
          "category": "deposits",
          "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for cost of living",
          "transactionAmount": -1500,
          "transactionPrice": -1500,
          "transactionCurrency": "EUR",
          "openAmount": -1500,
          "rebasedAmount": -1500,
          "displayName": "Bank for cost of living",
          "rowType": "sale",
          "amountAssetCurrency": -1500,
          "positionOnDate": 49386.79,
          "positionsPerProjectId": {
            "null": 49386.79
          },
          "valueOnDateTransactionCurrency": 49386.79,
          "valueOnDate": 49386.79
        },
        {
          "id": "u_vFNpRwd0JR0tZZx9yyV",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
          "otherParty": "American Express",
          "otherPartyAccount": null,
          "description": "Kreditkarte 4160xxxxxxxxx4859",
          "amount": -2850,
          "currency": "EUR",
          "accountCurrencyAmount": -2850,
          "fxAmount": null,
          "fxCurrency": null,
          "date": 1640649600000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": null,
          "isManuallyAdjusted": null,
          "tags": {
            "type": null,
            "assetId": null,
            "indexed": null,
            "accountId": null,
            "recurring": null
          },
          "sortingOrderWithinMonth": 0,
          "linkedTransactions": [],
          "category": "deposits",
          "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for cost of living",
          "transactionAmount": -2850,
          "transactionPrice": -2850,
          "transactionCurrency": "EUR",
          "openAmount": -2850,
          "rebasedAmount": -2850,
          "displayName": "Bank for cost of living",
          "rowType": "sale",
          "amountAssetCurrency": -2850,
          "positionOnDate": 46536.79,
          "positionsPerProjectId": {
            "null": 46536.79
          },
          "valueOnDateTransactionCurrency": 46536.79,
          "valueOnDate": 46536.79
        },
        {
          "id": "cWxMCZ-J5apkgisNfjMut",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
          "otherParty": "Arbeitgeber AG",
          "otherPartyAccount": "DE02100500000054540402",
          "description": "Gehalt",
          "amount": 4850,
          "currency": "EUR",
          "accountCurrencyAmount": 4850,
          "fxAmount": null,
          "fxCurrency": null,
          "date": 1640995200000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": null,
          "isManuallyAdjusted": null,
          "tags": {
            "type": null,
            "assetId": null,
            "indexed": null,
            "accountId": null,
            "recurring": null
          },
          "sortingOrderWithinMonth": 0,
          "linkedTransactions": [],
          "category": "deposits",
          "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for cost of living",
          "transactionAmount": 4850,
          "transactionPrice": 4850,
          "transactionCurrency": "EUR",
          "openAmount": 4850,
          "rebasedAmount": 4850,
          "displayName": "Bank for cost of living",
          "rowType": "purchase",
          "amountAssetCurrency": 4850,
          "positionOnDate": 51386.79,
          "positionsPerProjectId": {
            "null": 51386.79
          },
          "valueOnDateTransactionCurrency": 51386.79,
          "valueOnDate": 51386.79
        },
        {
          "id": "scZVjHgEJkwblPEc-fIJR",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
          "otherParty": "Vonovia AG",
          "otherPartyAccount": null,
          "description": "Miete",
          "amount": -1500,
          "currency": "EUR",
          "accountCurrencyAmount": -1500,
          "fxAmount": null,
          "fxCurrency": null,
          "date": 1641772800000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": null,
          "isManuallyAdjusted": null,
          "tags": {
            "type": null,
            "assetId": null,
            "indexed": null,
            "accountId": null,
            "recurring": null
          },
          "sortingOrderWithinMonth": 0,
          "linkedTransactions": [],
          "category": "deposits",
          "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for cost of living",
          "transactionAmount": -1500,
          "transactionPrice": -1500,
          "transactionCurrency": "EUR",
          "openAmount": -1500,
          "rebasedAmount": -1500,
          "displayName": "Bank for cost of living",
          "rowType": "sale",
          "amountAssetCurrency": -1500,
          "positionOnDate": 49886.79,
          "positionsPerProjectId": {
            "null": 49886.79
          },
          "valueOnDateTransactionCurrency": 49886.79,
          "valueOnDate": 49886.79
        },
        {
          "id": "NCwhZMEyCkLmiER5Zmxlq",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
          "otherParty": "American Express",
          "otherPartyAccount": null,
          "description": "Kreditkarte 4160xxxxxxxxx4858",
          "amount": -2350,
          "currency": "EUR",
          "accountCurrencyAmount": -2350,
          "fxAmount": null,
          "fxCurrency": null,
          "date": 1643328000000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": null,
          "isManuallyAdjusted": null,
          "tags": {
            "type": null,
            "assetId": null,
            "indexed": null,
            "accountId": null,
            "recurring": null
          },
          "sortingOrderWithinMonth": 0,
          "linkedTransactions": [],
          "category": "deposits",
          "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for cost of living",
          "transactionAmount": -2350,
          "transactionPrice": -2350,
          "transactionCurrency": "EUR",
          "openAmount": -2350,
          "rebasedAmount": -2350,
          "displayName": "Bank for cost of living",
          "rowType": "sale",
          "amountAssetCurrency": -2350,
          "positionOnDate": 47536.79,
          "positionsPerProjectId": {
            "null": 47536.79
          },
          "valueOnDateTransactionCurrency": 47536.79,
          "valueOnDate": 47536.79
        },
        {
          "id": "7qcl1TcTl_7l2P8ATtMdK",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
          "otherParty": "Arbeitgeber AG",
          "otherPartyAccount": "DE02100500000054540402",
          "description": "Gehalt",
          "amount": 5000,
          "currency": "EUR",
          "accountCurrencyAmount": 5000,
          "fxAmount": null,
          "fxCurrency": null,
          "date": 1643673600000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": null,
          "isManuallyAdjusted": null,
          "tags": {
            "type": null,
            "assetId": null,
            "indexed": null,
            "accountId": null,
            "recurring": null
          },
          "sortingOrderWithinMonth": 0,
          "linkedTransactions": [],
          "category": "deposits",
          "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for cost of living",
          "transactionAmount": 5000,
          "transactionPrice": 5000,
          "transactionCurrency": "EUR",
          "openAmount": 5000,
          "rebasedAmount": 5000,
          "displayName": "Bank for cost of living",
          "rowType": "purchase",
          "amountAssetCurrency": 5000,
          "positionOnDate": 52536.79,
          "positionsPerProjectId": {
            "null": 52536.79
          },
          "valueOnDateTransactionCurrency": 52536.79,
          "valueOnDate": 52536.79
        },
        {
          "id": "3m0OADQP6boadQdt3a09m",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
          "otherParty": "Vonovia AG",
          "otherPartyAccount": null,
          "description": "Miete",
          "amount": -1500,
          "currency": "EUR",
          "accountCurrencyAmount": -1500,
          "fxAmount": null,
          "fxCurrency": null,
          "date": 1644451200000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": null,
          "isManuallyAdjusted": null,
          "tags": {
            "type": null,
            "assetId": null,
            "indexed": null,
            "accountId": null,
            "recurring": null
          },
          "sortingOrderWithinMonth": 0,
          "linkedTransactions": [],
          "category": "deposits",
          "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for cost of living",
          "transactionAmount": -1500,
          "transactionPrice": -1500,
          "transactionCurrency": "EUR",
          "openAmount": -1500,
          "rebasedAmount": -1500,
          "displayName": "Bank for cost of living",
          "rowType": "sale",
          "amountAssetCurrency": -1500,
          "positionOnDate": 51036.79,
          "positionsPerProjectId": {
            "null": 51036.79
          },
          "valueOnDateTransactionCurrency": 51036.79,
          "valueOnDate": 51036.79
        },
        {
          "id": "wKvrA2xiy_Cql_GhFF3Nr",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
          "otherParty": "American Express",
          "otherPartyAccount": null,
          "description": "Kreditkarte 4160xxxxxxxxx4857",
          "amount": -2500,
          "currency": "EUR",
          "accountCurrencyAmount": -2500,
          "fxAmount": null,
          "fxCurrency": null,
          "date": 1646006400000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": null,
          "isManuallyAdjusted": null,
          "tags": {
            "type": null,
            "assetId": null,
            "indexed": null,
            "accountId": null,
            "recurring": null
          },
          "sortingOrderWithinMonth": 0,
          "linkedTransactions": [],
          "category": "deposits",
          "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for cost of living",
          "transactionAmount": -2500,
          "transactionPrice": -2500,
          "transactionCurrency": "EUR",
          "openAmount": -2500,
          "rebasedAmount": -2500,
          "displayName": "Bank for cost of living",
          "rowType": "sale",
          "amountAssetCurrency": -2500,
          "positionOnDate": 48536.79,
          "positionsPerProjectId": {
            "null": 48536.79
          },
          "valueOnDateTransactionCurrency": 48536.79,
          "valueOnDate": 48536.79
        },
        {
          "id": "nskSKomr6uu0V7g59Il6S",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
          "otherParty": "Arbeitgeber AG",
          "otherPartyAccount": "DE02100500000054540402",
          "description": "Gehalt",
          "amount": 7700,
          "currency": "EUR",
          "accountCurrencyAmount": 7700,
          "fxAmount": null,
          "fxCurrency": null,
          "date": 1646092800000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": null,
          "isManuallyAdjusted": null,
          "tags": {
            "type": null,
            "assetId": null,
            "indexed": null,
            "accountId": null,
            "recurring": null
          },
          "sortingOrderWithinMonth": 0,
          "linkedTransactions": [],
          "category": "deposits",
          "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for cost of living",
          "transactionAmount": 7700,
          "transactionPrice": 7700,
          "transactionCurrency": "EUR",
          "openAmount": 7700,
          "rebasedAmount": 7700,
          "displayName": "Bank for cost of living",
          "rowType": "purchase",
          "amountAssetCurrency": 7700,
          "positionOnDate": 56236.79,
          "positionsPerProjectId": {
            "null": 56236.79
          },
          "valueOnDateTransactionCurrency": 56236.79,
          "valueOnDate": 56236.79
        },
        {
          "id": "hRjJsRZKbKzvDTBfgGLia",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
          "otherParty": "Vonovia AG",
          "otherPartyAccount": null,
          "description": "Miete",
          "amount": -1500,
          "currency": "EUR",
          "accountCurrencyAmount": -1500,
          "fxAmount": null,
          "fxCurrency": null,
          "date": 1646870400000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": null,
          "isManuallyAdjusted": null,
          "tags": {
            "type": null,
            "assetId": null,
            "indexed": null,
            "accountId": null,
            "recurring": null
          },
          "sortingOrderWithinMonth": 0,
          "linkedTransactions": [],
          "category": "deposits",
          "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for cost of living",
          "transactionAmount": -1500,
          "transactionPrice": -1500,
          "transactionCurrency": "EUR",
          "openAmount": -1500,
          "rebasedAmount": -1500,
          "displayName": "Bank for cost of living",
          "rowType": "sale",
          "amountAssetCurrency": -1500,
          "positionOnDate": 54736.79,
          "positionsPerProjectId": {
            "null": 54736.79
          },
          "valueOnDateTransactionCurrency": 54736.79,
          "valueOnDate": 54736.79
        },
        {
          "id": "V9gQ2mnk0SR-Iq0-1CBzP",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
          "otherParty": "American Express",
          "otherPartyAccount": null,
          "description": "Kreditkarte 4160xxxxxxxxx4856",
          "amount": -3890,
          "currency": "EUR",
          "accountCurrencyAmount": -3890,
          "fxAmount": null,
          "fxCurrency": null,
          "date": 1648425600000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": null,
          "isManuallyAdjusted": null,
          "tags": {
            "type": null,
            "assetId": null,
            "indexed": null,
            "accountId": null,
            "recurring": null
          },
          "sortingOrderWithinMonth": 0,
          "linkedTransactions": [],
          "category": "deposits",
          "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for cost of living",
          "transactionAmount": -3890,
          "transactionPrice": -3890,
          "transactionCurrency": "EUR",
          "openAmount": -3890,
          "rebasedAmount": -3890,
          "displayName": "Bank for cost of living",
          "rowType": "sale",
          "amountAssetCurrency": -3890,
          "positionOnDate": 50846.79,
          "positionsPerProjectId": {
            "null": 50846.79
          },
          "valueOnDateTransactionCurrency": 50846.79,
          "valueOnDate": 50846.79
        },
        {
          "id": "nXTBUijnOyo_Kt8xEjQlj",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
          "otherParty": "Arbeitgeber AG",
          "otherPartyAccount": "DE02100500000054540402",
          "description": "Gehalt",
          "amount": 10000,
          "currency": "EUR",
          "accountCurrencyAmount": 10000,
          "fxAmount": null,
          "fxCurrency": null,
          "date": 1648771200000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": null,
          "isManuallyAdjusted": null,
          "tags": {
            "type": null,
            "assetId": null,
            "indexed": null,
            "accountId": null,
            "recurring": null
          },
          "sortingOrderWithinMonth": 0,
          "linkedTransactions": [],
          "category": "deposits",
          "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for cost of living",
          "transactionAmount": 10000,
          "transactionPrice": 10000,
          "transactionCurrency": "EUR",
          "openAmount": 10000,
          "rebasedAmount": 10000,
          "displayName": "Bank for cost of living",
          "rowType": "purchase",
          "amountAssetCurrency": 10000,
          "positionOnDate": 60846.79,
          "positionsPerProjectId": {
            "null": 60846.79
          },
          "valueOnDateTransactionCurrency": 60846.79,
          "valueOnDate": 60846.79
        },
        {
          "id": "2ezbtD-JZbNokiZRxx1nI",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
          "otherParty": "Vonovia AG",
          "otherPartyAccount": null,
          "description": "Miete",
          "amount": -1500,
          "currency": "EUR",
          "accountCurrencyAmount": -1500,
          "fxAmount": null,
          "fxCurrency": null,
          "date": 1649548800000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": null,
          "isManuallyAdjusted": null,
          "tags": {
            "type": null,
            "assetId": null,
            "indexed": null,
            "accountId": null,
            "recurring": null
          },
          "sortingOrderWithinMonth": 0,
          "linkedTransactions": [],
          "category": "deposits",
          "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for cost of living",
          "transactionAmount": -1500,
          "transactionPrice": -1500,
          "transactionCurrency": "EUR",
          "openAmount": -1500,
          "rebasedAmount": -1500,
          "displayName": "Bank for cost of living",
          "rowType": "sale",
          "amountAssetCurrency": -1500,
          "positionOnDate": 59346.79,
          "positionsPerProjectId": {
            "null": 59346.79
          },
          "valueOnDateTransactionCurrency": 59346.79,
          "valueOnDate": 59346.79
        },
        {
          "id": "TSfqBC-A2YLOa44LNfFmY",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
          "otherParty": "American Express",
          "otherPartyAccount": null,
          "description": "Kreditkarte 4160xxxxxxxxx4855",
          "amount": -4500,
          "currency": "EUR",
          "accountCurrencyAmount": -4500,
          "fxAmount": null,
          "fxCurrency": null,
          "date": 1651104000000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": null,
          "isManuallyAdjusted": null,
          "tags": {
            "type": null,
            "assetId": null,
            "indexed": null,
            "accountId": null,
            "recurring": null
          },
          "sortingOrderWithinMonth": 0,
          "linkedTransactions": [],
          "category": "deposits",
          "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for cost of living",
          "transactionAmount": -4500,
          "transactionPrice": -4500,
          "transactionCurrency": "EUR",
          "openAmount": -4500,
          "rebasedAmount": -4500,
          "displayName": "Bank for cost of living",
          "rowType": "sale",
          "amountAssetCurrency": -4500,
          "positionOnDate": 54846.79,
          "positionsPerProjectId": {
            "null": 54846.79
          },
          "valueOnDateTransactionCurrency": 54846.79,
          "valueOnDate": 54846.79
        },
        {
          "id": "ffoHDAy2zx_gB_SgISZA_",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
          "otherParty": "Arbeitgeber AG",
          "otherPartyAccount": "DE02100500000054540402",
          "description": "Gehalt",
          "amount": 6250,
          "currency": "EUR",
          "accountCurrencyAmount": 6250,
          "fxAmount": null,
          "fxCurrency": null,
          "date": 1651363200000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": null,
          "isManuallyAdjusted": null,
          "tags": {
            "type": null,
            "assetId": null,
            "indexed": null,
            "accountId": null,
            "recurring": null
          },
          "sortingOrderWithinMonth": 0,
          "linkedTransactions": [],
          "category": "deposits",
          "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for cost of living",
          "transactionAmount": 6250,
          "transactionPrice": 6250,
          "transactionCurrency": "EUR",
          "openAmount": 6250,
          "rebasedAmount": 6250,
          "displayName": "Bank for cost of living",
          "rowType": "purchase",
          "amountAssetCurrency": 6250,
          "positionOnDate": 61096.79,
          "positionsPerProjectId": {
            "null": 61096.79
          },
          "valueOnDateTransactionCurrency": 61096.79,
          "valueOnDate": 61096.79
        },
        {
          "id": "cJHAMJo4ty1EXON4Ie2rM",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
          "otherParty": "Vonovia AG",
          "otherPartyAccount": null,
          "description": "Miete",
          "amount": -1500,
          "currency": "EUR",
          "accountCurrencyAmount": -1500,
          "fxAmount": null,
          "fxCurrency": null,
          "date": 1652140800000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": null,
          "isManuallyAdjusted": null,
          "tags": {
            "type": null,
            "assetId": null,
            "indexed": null,
            "accountId": null,
            "recurring": null
          },
          "sortingOrderWithinMonth": 0,
          "linkedTransactions": [],
          "category": "deposits",
          "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for cost of living",
          "transactionAmount": -1500,
          "transactionPrice": -1500,
          "transactionCurrency": "EUR",
          "openAmount": -1500,
          "rebasedAmount": -1500,
          "displayName": "Bank for cost of living",
          "rowType": "sale",
          "amountAssetCurrency": -1500,
          "positionOnDate": 59596.79,
          "positionsPerProjectId": {
            "null": 59596.79
          },
          "valueOnDateTransactionCurrency": 59596.79,
          "valueOnDate": 59596.79
        },
        {
          "id": "bjZg2XaGcaLyRaijdsToI",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
          "otherParty": "American Express",
          "otherPartyAccount": null,
          "description": "Kreditkarte 4160xxxxxxxxx4855",
          "amount": -1500,
          "currency": "EUR",
          "accountCurrencyAmount": -1500,
          "fxAmount": null,
          "fxCurrency": null,
          "date": 1653696000000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": null,
          "isManuallyAdjusted": null,
          "tags": {
            "type": null,
            "assetId": null,
            "indexed": null,
            "accountId": null,
            "recurring": null
          },
          "sortingOrderWithinMonth": 0,
          "linkedTransactions": [],
          "category": "deposits",
          "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for cost of living",
          "transactionAmount": -1500,
          "transactionPrice": -1500,
          "transactionCurrency": "EUR",
          "openAmount": -1500,
          "rebasedAmount": -1500,
          "displayName": "Bank for cost of living",
          "rowType": "sale",
          "amountAssetCurrency": -1500,
          "positionOnDate": 58096.79,
          "positionsPerProjectId": {
            "null": 58096.79
          },
          "valueOnDateTransactionCurrency": 58096.79,
          "valueOnDate": 58096.79
        },
        {
          "id": "HcpJx56a3K1LxBSPldp7N",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
          "otherParty": "Arbeitgeber AG",
          "otherPartyAccount": "DE02100500000054540402",
          "description": "Gehalt",
          "amount": 6750,
          "currency": "EUR",
          "accountCurrencyAmount": 6750,
          "fxAmount": null,
          "fxCurrency": null,
          "date": 1654041600000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": null,
          "isManuallyAdjusted": null,
          "tags": {
            "type": null,
            "assetId": null,
            "indexed": null,
            "accountId": null,
            "recurring": null
          },
          "sortingOrderWithinMonth": 0,
          "linkedTransactions": [],
          "category": "deposits",
          "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for cost of living",
          "transactionAmount": 6750,
          "transactionPrice": 6750,
          "transactionCurrency": "EUR",
          "openAmount": 6750,
          "rebasedAmount": 6750,
          "displayName": "Bank for cost of living",
          "rowType": "purchase",
          "amountAssetCurrency": 6750,
          "positionOnDate": 64846.79,
          "positionsPerProjectId": {
            "null": 64846.79
          },
          "valueOnDateTransactionCurrency": 64846.79,
          "valueOnDate": 64846.79
        },
        {
          "id": "0dVYSoo-AhZGOtu76GYQG",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
          "otherParty": "Vonovia AG",
          "otherPartyAccount": null,
          "description": "Miete",
          "amount": -1500,
          "currency": "EUR",
          "accountCurrencyAmount": -1500,
          "fxAmount": null,
          "fxCurrency": null,
          "date": 1654819200000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": null,
          "isManuallyAdjusted": null,
          "tags": {
            "type": null,
            "assetId": null,
            "indexed": null,
            "accountId": null,
            "recurring": null
          },
          "sortingOrderWithinMonth": 0,
          "linkedTransactions": [],
          "category": "deposits",
          "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for cost of living",
          "transactionAmount": -1500,
          "transactionPrice": -1500,
          "transactionCurrency": "EUR",
          "openAmount": -1500,
          "rebasedAmount": -1500,
          "displayName": "Bank for cost of living",
          "rowType": "sale",
          "amountAssetCurrency": -1500,
          "positionOnDate": 63346.79,
          "positionsPerProjectId": {
            "null": 63346.79
          },
          "valueOnDateTransactionCurrency": 63346.79,
          "valueOnDate": 63346.79
        },
        {
          "id": "h1wa1f8o7QE9hot8JvpUU",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
          "otherParty": "American Express",
          "otherPartyAccount": null,
          "description": "Kreditkarte 4160xxxxxxxxx4855",
          "amount": -2500,
          "currency": "EUR",
          "accountCurrencyAmount": -2500,
          "fxAmount": null,
          "fxCurrency": null,
          "date": 1656374400000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": null,
          "isManuallyAdjusted": null,
          "tags": {
            "type": null,
            "assetId": null,
            "indexed": null,
            "accountId": null,
            "recurring": null
          },
          "sortingOrderWithinMonth": 0,
          "linkedTransactions": [],
          "category": "deposits",
          "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for cost of living",
          "transactionAmount": -2500,
          "transactionPrice": -2500,
          "transactionCurrency": "EUR",
          "openAmount": -2500,
          "rebasedAmount": -2500,
          "displayName": "Bank for cost of living",
          "rowType": "sale",
          "amountAssetCurrency": -2500,
          "positionOnDate": 60846.79,
          "positionsPerProjectId": {
            "null": 60846.79
          },
          "valueOnDateTransactionCurrency": 60846.79,
          "valueOnDate": 60846.79
        },
        {
          "id": "-UhCYWgGdlX4vp-zjHYhE",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
          "otherParty": "Arbeitgeber AG",
          "otherPartyAccount": "DE02100500000054540402",
          "description": "Gehalt",
          "amount": 6750,
          "currency": "EUR",
          "accountCurrencyAmount": 6750,
          "fxAmount": null,
          "fxCurrency": null,
          "date": 1656633600000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": null,
          "isManuallyAdjusted": null,
          "tags": null,
          "sortingOrderWithinMonth": null,
          "linkedTransactions": [],
          "category": "deposits",
          "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for cost of living",
          "transactionAmount": 6750,
          "transactionPrice": 6750,
          "transactionCurrency": "EUR",
          "openAmount": 6750,
          "rebasedAmount": 6750,
          "displayName": "Bank for cost of living",
          "rowType": "purchase",
          "amountAssetCurrency": 6750,
          "positionOnDate": 67596.79000000001,
          "positionsPerProjectId": {
            "null": 67596.79000000001
          },
          "valueOnDateTransactionCurrency": 67596.79000000001,
          "valueOnDate": 67596.79000000001
        },
        {
          "id": "k8k-uu7uEmrv22Nov7YVB",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
          "otherParty": "Bank for deposits 2",
          "otherPartyAccount": null,
          "description": "Zinsen Tagesgeld",
          "amount": 400,
          "currency": "EUR",
          "accountCurrencyAmount": 400,
          "fxAmount": null,
          "fxCurrency": null,
          "date": 1656633600000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": "investment-interest",
          "isManuallyAdjusted": null,
          "tags": {
            "assetId": "IRHUSsu_Vp9quJ5CJ_MlG",
            "accountId": "IRHUSsu_Vp9quJ5CJ_MlG"
          },
          "sortingOrderWithinMonth": null,
          "linkedTransactions": [],
          "category": "deposits",
          "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for cost of living",
          "transactionAmount": 400,
          "transactionPrice": 400,
          "transactionCurrency": "EUR",
          "openAmount": 400,
          "rebasedAmount": 400,
          "displayName": "Bank for cost of living",
          "rowType": "purchase",
          "amountAssetCurrency": 400,
          "positionOnDate": 67996.79000000001,
          "positionsPerProjectId": {
            "null": 67996.79000000001
          },
          "valueOnDateTransactionCurrency": 67996.79000000001,
          "valueOnDate": 67996.79000000001
        },
        {
          "id": "61wbS4jQeAbj_8KZVllCZ",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
          "otherParty": "Vonovia AG",
          "otherPartyAccount": null,
          "description": "Miete",
          "amount": -1500,
          "currency": "EUR",
          "accountCurrencyAmount": -1500,
          "fxAmount": null,
          "fxCurrency": null,
          "date": 1657411200000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": null,
          "isManuallyAdjusted": null,
          "tags": null,
          "sortingOrderWithinMonth": null,
          "linkedTransactions": [],
          "category": "deposits",
          "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for cost of living",
          "transactionAmount": -1500,
          "transactionPrice": -1500,
          "transactionCurrency": "EUR",
          "openAmount": -1500,
          "rebasedAmount": -1500,
          "displayName": "Bank for cost of living",
          "rowType": "sale",
          "amountAssetCurrency": -1500,
          "positionOnDate": 66496.79000000001,
          "positionsPerProjectId": {
            "null": 66496.79000000001
          },
          "valueOnDateTransactionCurrency": 66496.79000000001,
          "valueOnDate": 66496.79000000001
        },
        {
          "id": "40VzlDQ7b20whGDYfaflE",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
          "otherParty": "American Express",
          "otherPartyAccount": null,
          "description": "Kreditkarte 4160xxxxxxxxx4855",
          "amount": -2500,
          "currency": "EUR",
          "accountCurrencyAmount": -2500,
          "fxAmount": null,
          "fxCurrency": null,
          "date": 1658966400000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": null,
          "isManuallyAdjusted": null,
          "tags": null,
          "sortingOrderWithinMonth": null,
          "linkedTransactions": [],
          "category": "deposits",
          "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for cost of living",
          "transactionAmount": -2500,
          "transactionPrice": -2500,
          "transactionCurrency": "EUR",
          "openAmount": -2500,
          "rebasedAmount": -2500,
          "displayName": "Bank for cost of living",
          "rowType": "sale",
          "amountAssetCurrency": -2500,
          "positionOnDate": 63996.79000000001,
          "positionsPerProjectId": {
            "null": 63996.79000000001
          },
          "valueOnDateTransactionCurrency": 63996.79000000001,
          "valueOnDate": 63996.79000000001
        },
        {
          "id": "ChCykvbEOr2axaIOax8f4",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
          "otherParty": "Arbeitgeber AG",
          "otherPartyAccount": "DE02100500000054540402",
          "description": "Gehalt",
          "amount": 6750,
          "currency": "EUR",
          "accountCurrencyAmount": 6750,
          "fxAmount": null,
          "fxCurrency": null,
          "date": 1659312000000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": null,
          "isManuallyAdjusted": null,
          "tags": null,
          "sortingOrderWithinMonth": null,
          "linkedTransactions": [],
          "category": "deposits",
          "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for cost of living",
          "transactionAmount": 6750,
          "transactionPrice": 6750,
          "transactionCurrency": "EUR",
          "openAmount": 6750,
          "rebasedAmount": 6750,
          "displayName": "Bank for cost of living",
          "rowType": "purchase",
          "amountAssetCurrency": 6750,
          "positionOnDate": 70746.79000000001,
          "positionsPerProjectId": {
            "null": 70746.79000000001
          },
          "valueOnDateTransactionCurrency": 70746.79000000001,
          "valueOnDate": 70746.79000000001
        },
        {
          "id": "NFow7M62KGhQzxjNEsOC_",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
          "otherParty": "Vonovia AG",
          "otherPartyAccount": null,
          "description": "Miete",
          "amount": -1500,
          "currency": "EUR",
          "accountCurrencyAmount": -1500,
          "fxAmount": null,
          "fxCurrency": null,
          "date": 1660089600000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": null,
          "isManuallyAdjusted": null,
          "tags": null,
          "sortingOrderWithinMonth": null,
          "linkedTransactions": [],
          "category": "deposits",
          "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for cost of living",
          "transactionAmount": -1500,
          "transactionPrice": -1500,
          "transactionCurrency": "EUR",
          "openAmount": -1500,
          "rebasedAmount": -1500,
          "displayName": "Bank for cost of living",
          "rowType": "sale",
          "amountAssetCurrency": -1500,
          "positionOnDate": 69246.79000000001,
          "positionsPerProjectId": {
            "null": 69246.79000000001
          },
          "valueOnDateTransactionCurrency": 69246.79000000001,
          "valueOnDate": 69246.79000000001
        },
        {
          "id": "d8s7zUx7BjjIeReb78KWI",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
          "otherParty": "American Express",
          "otherPartyAccount": null,
          "description": "Kreditkarte 4160xxxxxxxxx4855",
          "amount": -2500,
          "currency": "EUR",
          "accountCurrencyAmount": -2500,
          "fxAmount": null,
          "fxCurrency": null,
          "date": 1661644800000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": null,
          "isManuallyAdjusted": null,
          "tags": null,
          "sortingOrderWithinMonth": null,
          "linkedTransactions": [],
          "category": "deposits",
          "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for cost of living",
          "transactionAmount": -2500,
          "transactionPrice": -2500,
          "transactionCurrency": "EUR",
          "openAmount": -2500,
          "rebasedAmount": -2500,
          "displayName": "Bank for cost of living",
          "rowType": "sale",
          "amountAssetCurrency": -2500,
          "positionOnDate": 66746.79000000001,
          "positionsPerProjectId": {
            "null": 66746.79000000001
          },
          "valueOnDateTransactionCurrency": 66746.79000000001,
          "valueOnDate": 66746.79000000001
        },
        {
          "id": "slCGuNGnEaNOIixHUb9Dk",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
          "otherParty": "Arbeitgeber AG",
          "otherPartyAccount": "DE02100500000054540402",
          "description": "Gehalt",
          "amount": 6750,
          "currency": "EUR",
          "accountCurrencyAmount": 6750,
          "fxAmount": null,
          "fxCurrency": null,
          "date": 1661990400000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": null,
          "isManuallyAdjusted": null,
          "tags": null,
          "sortingOrderWithinMonth": null,
          "linkedTransactions": [],
          "category": "deposits",
          "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for cost of living",
          "transactionAmount": 6750,
          "transactionPrice": 6750,
          "transactionCurrency": "EUR",
          "openAmount": 6750,
          "rebasedAmount": 6750,
          "displayName": "Bank for cost of living",
          "rowType": "purchase",
          "amountAssetCurrency": 6750,
          "positionOnDate": 73496.79000000001,
          "positionsPerProjectId": {
            "null": 73496.79000000001
          },
          "valueOnDateTransactionCurrency": 73496.79000000001,
          "valueOnDate": 73496.79000000001
        },
        {
          "id": "U22rw9szJ9eIqLVQcNTm5",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
          "otherParty": "Vonovia AG",
          "otherPartyAccount": null,
          "description": "Miete",
          "amount": -1500,
          "currency": "EUR",
          "accountCurrencyAmount": -1500,
          "fxAmount": null,
          "fxCurrency": null,
          "date": 1662768000000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": null,
          "isManuallyAdjusted": null,
          "tags": null,
          "sortingOrderWithinMonth": null,
          "linkedTransactions": [],
          "category": "deposits",
          "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for cost of living",
          "transactionAmount": -1500,
          "transactionPrice": -1500,
          "transactionCurrency": "EUR",
          "openAmount": -1500,
          "rebasedAmount": -1500,
          "displayName": "Bank for cost of living",
          "rowType": "sale",
          "amountAssetCurrency": -1500,
          "positionOnDate": 71996.79000000001,
          "positionsPerProjectId": {
            "null": 71996.79000000001
          },
          "valueOnDateTransactionCurrency": 71996.79000000001,
          "valueOnDate": 71996.79000000001
        },
        {
          "id": "p_01jHH2cg1pm6QEoLcd2",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
          "otherParty": "American Express",
          "otherPartyAccount": null,
          "description": "Kreditkarte 4160xxxxxxxxx4855",
          "amount": -2500,
          "currency": "EUR",
          "accountCurrencyAmount": -2500,
          "fxAmount": null,
          "fxCurrency": null,
          "date": 1664323200000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": null,
          "isManuallyAdjusted": null,
          "tags": null,
          "sortingOrderWithinMonth": null,
          "linkedTransactions": [],
          "category": "deposits",
          "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for cost of living",
          "transactionAmount": -2500,
          "transactionPrice": -2500,
          "transactionCurrency": "EUR",
          "openAmount": -2500,
          "rebasedAmount": -2500,
          "displayName": "Bank for cost of living",
          "rowType": "sale",
          "amountAssetCurrency": -2500,
          "positionOnDate": 69496.79000000001,
          "positionsPerProjectId": {
            "null": 69496.79000000001
          },
          "valueOnDateTransactionCurrency": 69496.79000000001,
          "valueOnDate": 69496.79000000001
        },
        {
          "id": "8Q0O_rzvkydxKDAJpuVQ0",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
          "otherParty": "Arbeitgeber AG",
          "otherPartyAccount": "DE02100500000054540402",
          "description": "Gehalt",
          "amount": 6750,
          "currency": "EUR",
          "accountCurrencyAmount": 6750,
          "fxAmount": null,
          "fxCurrency": null,
          "date": 1664582400000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": null,
          "isManuallyAdjusted": null,
          "tags": null,
          "sortingOrderWithinMonth": null,
          "linkedTransactions": [],
          "category": "deposits",
          "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for cost of living",
          "transactionAmount": 6750,
          "transactionPrice": 6750,
          "transactionCurrency": "EUR",
          "openAmount": 6750,
          "rebasedAmount": 6750,
          "displayName": "Bank for cost of living",
          "rowType": "purchase",
          "amountAssetCurrency": 6750,
          "positionOnDate": 76246.79000000001,
          "positionsPerProjectId": {
            "null": 76246.79000000001
          },
          "valueOnDateTransactionCurrency": 76246.79000000001,
          "valueOnDate": 76246.79000000001
        },
        {
          "id": "gxMQVa7WKuSramzpSwZP_",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
          "otherParty": "Vonovia AG",
          "otherPartyAccount": null,
          "description": "Miete",
          "amount": -500,
          "currency": "EUR",
          "accountCurrencyAmount": -500,
          "fxAmount": null,
          "fxCurrency": null,
          "date": 1665014400000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": null,
          "isManuallyAdjusted": null,
          "tags": {
            "type": null,
            "assetId": null,
            "indexed": null,
            "accountId": null,
            "recurring": null
          },
          "sortingOrderWithinMonth": null,
          "linkedTransactions": [],
          "category": "deposits",
          "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for cost of living",
          "transactionAmount": -500,
          "transactionPrice": -500,
          "transactionCurrency": "EUR",
          "openAmount": -500,
          "rebasedAmount": -500,
          "displayName": "Bank for cost of living",
          "rowType": "sale",
          "amountAssetCurrency": -500,
          "positionOnDate": 75746.79000000001,
          "positionsPerProjectId": {
            "null": 75746.79000000001
          },
          "valueOnDateTransactionCurrency": 75746.79000000001,
          "valueOnDate": 75746.79000000001
        },
        {
          "id": "DIdsMfpxXUvepHgitox6x",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
          "otherParty": "St. Claus",
          "otherPartyAccount": null,
          "description": "Geschenk",
          "amount": 100,
          "currency": "EUR",
          "accountCurrencyAmount": 100,
          "fxAmount": null,
          "fxCurrency": null,
          "date": 1665100800000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": null,
          "isManuallyAdjusted": null,
          "tags": {
            "type": null,
            "assetId": null,
            "indexed": null,
            "accountId": null,
            "recurring": null
          },
          "sortingOrderWithinMonth": null,
          "linkedTransactions": [],
          "category": "deposits",
          "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for cost of living",
          "transactionAmount": 100,
          "transactionPrice": 100,
          "transactionCurrency": "EUR",
          "openAmount": 100,
          "rebasedAmount": 100,
          "displayName": "Bank for cost of living",
          "rowType": "purchase",
          "amountAssetCurrency": 100,
          "positionOnDate": 75846.79000000001,
          "positionsPerProjectId": {
            "null": 75846.79000000001
          },
          "valueOnDateTransactionCurrency": 75846.79000000001,
          "valueOnDate": 75846.79000000001
        },
        {
          "id": "D7N0amVKiy7AoyLnI572i",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
          "otherParty": "St. Claus",
          "otherPartyAccount": null,
          "description": "Geschenkt",
          "amount": 100,
          "currency": "EUR",
          "accountCurrencyAmount": 100,
          "fxAmount": null,
          "fxCurrency": null,
          "date": 1665187200000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": null,
          "isManuallyAdjusted": null,
          "tags": {
            "type": null,
            "assetId": null,
            "indexed": null,
            "accountId": null,
            "recurring": null
          },
          "sortingOrderWithinMonth": null,
          "linkedTransactions": [],
          "category": "deposits",
          "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for cost of living",
          "transactionAmount": 100,
          "transactionPrice": 100,
          "transactionCurrency": "EUR",
          "openAmount": 100,
          "rebasedAmount": 100,
          "displayName": "Bank for cost of living",
          "rowType": "purchase",
          "amountAssetCurrency": 100,
          "positionOnDate": 75946.79000000001,
          "positionsPerProjectId": {
            "null": 75946.79000000001
          },
          "valueOnDateTransactionCurrency": 75946.79000000001,
          "valueOnDate": 75946.79000000001
        },
        {
          "id": "hBplWkErF7yIeMSg__GTs",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
          "otherParty": "Vonovia AG",
          "otherPartyAccount": null,
          "description": "Miete",
          "amount": -1500,
          "currency": "EUR",
          "accountCurrencyAmount": -1500,
          "fxAmount": null,
          "fxCurrency": null,
          "date": 1665360000000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": null,
          "isManuallyAdjusted": null,
          "tags": null,
          "sortingOrderWithinMonth": null,
          "linkedTransactions": [],
          "category": "deposits",
          "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for cost of living",
          "transactionAmount": -1500,
          "transactionPrice": -1500,
          "transactionCurrency": "EUR",
          "openAmount": -1500,
          "rebasedAmount": -1500,
          "displayName": "Bank for cost of living",
          "rowType": "sale",
          "amountAssetCurrency": -1500,
          "positionOnDate": 74446.79000000001,
          "positionsPerProjectId": {
            "null": 74446.79000000001
          },
          "valueOnDateTransactionCurrency": 74446.79000000001,
          "valueOnDate": 74446.79000000001
        },
        {
          "id": "6rljnAxMkcH7sGPq0phbw",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
          "otherParty": "American Express",
          "otherPartyAccount": null,
          "description": "Kreditkarte 4160xxxxxxxxx4855",
          "amount": -2500,
          "currency": "EUR",
          "accountCurrencyAmount": -2500,
          "fxAmount": null,
          "fxCurrency": null,
          "date": 1666915200000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": null,
          "isManuallyAdjusted": null,
          "tags": null,
          "sortingOrderWithinMonth": null,
          "linkedTransactions": [],
          "category": "deposits",
          "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for cost of living",
          "transactionAmount": -2500,
          "transactionPrice": -2500,
          "transactionCurrency": "EUR",
          "openAmount": -2500,
          "rebasedAmount": -2500,
          "displayName": "Bank for cost of living",
          "rowType": "sale",
          "amountAssetCurrency": -2500,
          "positionOnDate": 71946.79000000001,
          "positionsPerProjectId": {
            "null": 71946.79000000001
          },
          "valueOnDateTransactionCurrency": 71946.79000000001,
          "valueOnDate": 71946.79000000001
        },
        {
          "id": "sE8LlQCDgJsREkZi8dY_L",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
          "otherParty": "Arbeitgeber AG",
          "otherPartyAccount": "DE02100500000054540402",
          "description": "Gehalt",
          "amount": 6750,
          "currency": "EUR",
          "accountCurrencyAmount": 6750,
          "fxAmount": null,
          "fxCurrency": null,
          "date": 1667260800000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": null,
          "isManuallyAdjusted": null,
          "tags": null,
          "sortingOrderWithinMonth": null,
          "linkedTransactions": [],
          "category": "deposits",
          "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for cost of living",
          "transactionAmount": 6750,
          "transactionPrice": 6750,
          "transactionCurrency": "EUR",
          "openAmount": 6750,
          "rebasedAmount": 6750,
          "displayName": "Bank for cost of living",
          "rowType": "purchase",
          "amountAssetCurrency": 6750,
          "positionOnDate": 78696.79000000001,
          "positionsPerProjectId": {
            "null": 78696.79000000001
          },
          "valueOnDateTransactionCurrency": 78696.79000000001,
          "valueOnDate": 78696.79000000001
        },
        {
          "id": "FqL-AR21myOIn6gQZHrVT",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
          "otherParty": "Vonovia AG",
          "otherPartyAccount": null,
          "description": "Miete",
          "amount": -1500,
          "currency": "EUR",
          "accountCurrencyAmount": -1500,
          "fxAmount": null,
          "fxCurrency": null,
          "date": 1668038400000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": null,
          "isManuallyAdjusted": null,
          "tags": null,
          "sortingOrderWithinMonth": null,
          "linkedTransactions": [],
          "category": "deposits",
          "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for cost of living",
          "transactionAmount": -1500,
          "transactionPrice": -1500,
          "transactionCurrency": "EUR",
          "openAmount": -1500,
          "rebasedAmount": -1500,
          "displayName": "Bank for cost of living",
          "rowType": "sale",
          "amountAssetCurrency": -1500,
          "positionOnDate": 77196.79000000001,
          "positionsPerProjectId": {
            "null": 77196.79000000001
          },
          "valueOnDateTransactionCurrency": 77196.79000000001,
          "valueOnDate": 77196.79000000001
        },
        {
          "id": "GL3Zt_JlaHRLY_4rstSlb",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
          "otherParty": "American Express",
          "otherPartyAccount": null,
          "description": "Kreditkarte 4160xxxxxxxxx4855",
          "amount": -2500,
          "currency": "EUR",
          "accountCurrencyAmount": -2500,
          "fxAmount": null,
          "fxCurrency": null,
          "date": 1669593600000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": null,
          "isManuallyAdjusted": null,
          "tags": null,
          "sortingOrderWithinMonth": null,
          "linkedTransactions": [],
          "category": "deposits",
          "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for cost of living",
          "transactionAmount": -2500,
          "transactionPrice": -2500,
          "transactionCurrency": "EUR",
          "openAmount": -2500,
          "rebasedAmount": -2500,
          "displayName": "Bank for cost of living",
          "rowType": "sale",
          "amountAssetCurrency": -2500,
          "positionOnDate": 74696.79000000001,
          "positionsPerProjectId": {
            "null": 74696.79000000001
          },
          "valueOnDateTransactionCurrency": 74696.79000000001,
          "valueOnDate": 74696.79000000001
        },
        {
          "id": "F_F0dZq2cfwlYN1YsliJ3",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
          "otherParty": "Arbeitgeber AG",
          "otherPartyAccount": "DE02100500000054540402",
          "description": "Gehalt",
          "amount": 6750,
          "currency": "EUR",
          "accountCurrencyAmount": 6750,
          "fxAmount": null,
          "fxCurrency": null,
          "date": 1669852800000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": null,
          "isManuallyAdjusted": null,
          "tags": null,
          "sortingOrderWithinMonth": null,
          "linkedTransactions": [],
          "category": "deposits",
          "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for cost of living",
          "transactionAmount": 6750,
          "transactionPrice": 6750,
          "transactionCurrency": "EUR",
          "openAmount": 6750,
          "rebasedAmount": 6750,
          "displayName": "Bank for cost of living",
          "rowType": "purchase",
          "amountAssetCurrency": 6750,
          "positionOnDate": 81446.79000000001,
          "positionsPerProjectId": {
            "null": 81446.79000000001
          },
          "valueOnDateTransactionCurrency": 81446.79000000001,
          "valueOnDate": 81446.79000000001
        },
        {
          "date": 1669852800000,
          "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
          "rowType": "kpi.baseline",
          "currency": "EUR",
          "displayName": "Bank for cost of living",
          "accountName": "Bank for cost of living",
          "positionOnDate": 81446.79000000001,
          "positionsPerProjectId": {
            "null": 81446.79000000001
          },
          "valueOnDateTransactionCurrency": 81446.79000000001,
          "valueOnDate": 81446.79000000001
        },
        {
          "id": "bZKXl9Pobnx75rqtmzblA",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
          "otherParty": "Vonovia AG",
          "otherPartyAccount": null,
          "description": "Miete",
          "amount": -1500,
          "currency": "EUR",
          "accountCurrencyAmount": -1500,
          "fxAmount": null,
          "fxCurrency": null,
          "date": 1670630400000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": null,
          "isManuallyAdjusted": null,
          "tags": null,
          "sortingOrderWithinMonth": null,
          "linkedTransactions": [],
          "category": "deposits",
          "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for cost of living",
          "transactionAmount": -1500,
          "transactionPrice": -1500,
          "transactionCurrency": "EUR",
          "openAmount": -1500,
          "rebasedAmount": -1500,
          "displayName": "Bank for cost of living",
          "rowType": "sale",
          "amountAssetCurrency": -1500,
          "positionOnDate": 79946.79000000001,
          "positionsPerProjectId": {
            "null": 79946.79000000001
          },
          "valueOnDateTransactionCurrency": 79946.79000000001,
          "valueOnDate": 79946.79000000001
        },
        {
          "id": "TdmLQHHE9CY-pnHC812iQ",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
          "otherParty": "American Express",
          "otherPartyAccount": null,
          "description": "Kreditkarte 4160xxxxxxxxx4855",
          "amount": -2500,
          "currency": "EUR",
          "accountCurrencyAmount": -2500,
          "fxAmount": null,
          "fxCurrency": null,
          "date": 1672185600000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": null,
          "isManuallyAdjusted": null,
          "tags": null,
          "sortingOrderWithinMonth": null,
          "linkedTransactions": [],
          "category": "deposits",
          "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for cost of living",
          "transactionAmount": -2500,
          "transactionPrice": -2500,
          "transactionCurrency": "EUR",
          "openAmount": -2500,
          "rebasedAmount": -2500,
          "displayName": "Bank for cost of living",
          "rowType": "sale",
          "amountAssetCurrency": -2500,
          "positionOnDate": 77446.79000000001,
          "positionsPerProjectId": {
            "null": 77446.79000000001
          },
          "valueOnDateTransactionCurrency": 77446.79000000001,
          "valueOnDate": 77446.79000000001
        },
        {
          "id": "Tr-_-c1o0KnojlzTicRi2",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
          "otherParty": "Arbeitgeber AG",
          "otherPartyAccount": "DE02100500000054540402",
          "description": "Gehalt",
          "amount": 6750,
          "currency": "EUR",
          "accountCurrencyAmount": 6750,
          "fxAmount": null,
          "fxCurrency": null,
          "date": 1672531200000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": null,
          "isManuallyAdjusted": null,
          "tags": null,
          "sortingOrderWithinMonth": null,
          "linkedTransactions": [],
          "category": "deposits",
          "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for cost of living",
          "transactionAmount": 6750,
          "transactionPrice": 6750,
          "transactionCurrency": "EUR",
          "openAmount": 6750,
          "rebasedAmount": 6750,
          "displayName": "Bank for cost of living",
          "rowType": "purchase",
          "amountAssetCurrency": 6750,
          "positionOnDate": 84196.79000000001,
          "positionsPerProjectId": {
            "null": 84196.79000000001
          },
          "valueOnDateTransactionCurrency": 84196.79000000001,
          "valueOnDate": 84196.79000000001
        },
        {
          "id": "s5rC2aiaPmccn7u8k5xo4",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
          "otherParty": "Vonovia AG",
          "otherPartyAccount": null,
          "description": "Miete",
          "amount": -1500,
          "currency": "EUR",
          "accountCurrencyAmount": -1500,
          "fxAmount": null,
          "fxCurrency": null,
          "date": 1673308800000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": null,
          "isManuallyAdjusted": null,
          "tags": null,
          "sortingOrderWithinMonth": null,
          "linkedTransactions": [],
          "category": "deposits",
          "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for cost of living",
          "transactionAmount": -1500,
          "transactionPrice": -1500,
          "transactionCurrency": "EUR",
          "openAmount": -1500,
          "rebasedAmount": -1500,
          "displayName": "Bank for cost of living",
          "rowType": "sale",
          "amountAssetCurrency": -1500,
          "positionOnDate": 82696.79000000001,
          "positionsPerProjectId": {
            "null": 82696.79000000001
          },
          "valueOnDateTransactionCurrency": 82696.79000000001,
          "valueOnDate": 82696.79000000001
        },
        {
          "id": "g_ySU3eb7eogm2WCaQ3-6",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
          "otherParty": "American Express",
          "otherPartyAccount": null,
          "description": "Kreditkarte 4160xxxxxxxxx4855",
          "amount": -2500,
          "currency": "EUR",
          "accountCurrencyAmount": -2500,
          "fxAmount": null,
          "fxCurrency": null,
          "date": 1674864000000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": null,
          "isManuallyAdjusted": null,
          "tags": null,
          "sortingOrderWithinMonth": null,
          "linkedTransactions": [],
          "category": "deposits",
          "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for cost of living",
          "transactionAmount": -2500,
          "transactionPrice": -2500,
          "transactionCurrency": "EUR",
          "openAmount": -2500,
          "rebasedAmount": -2500,
          "displayName": "Bank for cost of living",
          "rowType": "sale",
          "amountAssetCurrency": -2500,
          "positionOnDate": 80196.79000000001,
          "positionsPerProjectId": {
            "null": 80196.79000000001
          },
          "valueOnDateTransactionCurrency": 80196.79000000001,
          "valueOnDate": 80196.79000000001
        },
        {
          "id": "L-otyUKpq0Ny1ZLgBpMTU",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
          "otherParty": "Arbeitgeber AG",
          "otherPartyAccount": "DE02100500000054540402",
          "description": "Gehalt",
          "amount": 6750,
          "currency": "EUR",
          "accountCurrencyAmount": 6750,
          "fxAmount": null,
          "fxCurrency": null,
          "date": 1675209600000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": null,
          "isManuallyAdjusted": null,
          "tags": null,
          "sortingOrderWithinMonth": null,
          "linkedTransactions": [],
          "category": "deposits",
          "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for cost of living",
          "transactionAmount": 6750,
          "transactionPrice": 6750,
          "transactionCurrency": "EUR",
          "openAmount": 6750,
          "rebasedAmount": 6750,
          "displayName": "Bank for cost of living",
          "rowType": "purchase",
          "amountAssetCurrency": 6750,
          "positionOnDate": 86946.79000000001,
          "positionsPerProjectId": {
            "null": 86946.79000000001
          },
          "valueOnDateTransactionCurrency": 86946.79000000001,
          "valueOnDate": 86946.79000000001
        },
        {
          "id": "ThLoCV0-e3TFwR2V_WFNX",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
          "otherParty": "Vonovia AG",
          "otherPartyAccount": null,
          "description": "Miete",
          "amount": -1500,
          "currency": "EUR",
          "accountCurrencyAmount": -1500,
          "fxAmount": null,
          "fxCurrency": null,
          "date": 1675987200000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": null,
          "isManuallyAdjusted": null,
          "tags": null,
          "sortingOrderWithinMonth": null,
          "linkedTransactions": [],
          "category": "deposits",
          "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for cost of living",
          "transactionAmount": -1500,
          "transactionPrice": -1500,
          "transactionCurrency": "EUR",
          "openAmount": -1500,
          "rebasedAmount": -1500,
          "displayName": "Bank for cost of living",
          "rowType": "sale",
          "amountAssetCurrency": -1500,
          "positionOnDate": 85446.79000000001,
          "positionsPerProjectId": {
            "null": 85446.79000000001
          },
          "valueOnDateTransactionCurrency": 85446.79000000001,
          "valueOnDate": 85446.79000000001
        },
        {
          "id": "nhTbHinwMxztP2M1IZ-bz",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
          "otherParty": "American Express",
          "otherPartyAccount": null,
          "description": "Kreditkarte 4160xxxxxxxxx4855",
          "amount": -2500,
          "currency": "EUR",
          "accountCurrencyAmount": -2500,
          "fxAmount": null,
          "fxCurrency": null,
          "date": 1677542400000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": null,
          "isManuallyAdjusted": null,
          "tags": null,
          "sortingOrderWithinMonth": null,
          "linkedTransactions": [],
          "category": "deposits",
          "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for cost of living",
          "transactionAmount": -2500,
          "transactionPrice": -2500,
          "transactionCurrency": "EUR",
          "openAmount": -2500,
          "rebasedAmount": -2500,
          "displayName": "Bank for cost of living",
          "rowType": "sale",
          "amountAssetCurrency": -2500,
          "positionOnDate": 82946.79000000001,
          "positionsPerProjectId": {
            "null": 82946.79000000001
          },
          "valueOnDateTransactionCurrency": 82946.79000000001,
          "valueOnDate": 82946.79000000001
        },
        {
          "id": "9mhKCL0Z9a9YurjJl64Kz",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
          "otherParty": "Hertz CH AG",
          "otherPartyAccount": null,
          "description": "Rg.-Nr. 123223123/263",
          "amount": -253,
          "currency": "EUR",
          "accountCurrencyAmount": -253,
          "fxAmount": -252.9241,
          "fxCurrency": "CHF",
          "date": 1677628800000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": null,
          "isManuallyAdjusted": null,
          "tags": {
            "type": null,
            "assetId": null,
            "indexed": null,
            "accountId": null,
            "recurring": null
          },
          "sortingOrderWithinMonth": null,
          "linkedTransactions": [],
          "category": "deposits",
          "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for cost of living",
          "transactionAmount": -253,
          "transactionPrice": -253,
          "transactionCurrency": "EUR",
          "openAmount": -253,
          "rebasedAmount": -253,
          "displayName": "Bank for cost of living",
          "rowType": "sale",
          "amountAssetCurrency": -253,
          "positionOnDate": 82693.79000000001,
          "positionsPerProjectId": {
            "null": 82693.79000000001
          },
          "valueOnDateTransactionCurrency": 82693.79000000001,
          "valueOnDate": 82693.79000000001
        },
        {
          "id": "w5Ehx6Ld_Uie_ZcsMFOGl",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
          "otherParty": "Arbeitgeber AG",
          "otherPartyAccount": "DE02100500000054540402",
          "description": "Gehalt",
          "amount": 6750,
          "currency": "EUR",
          "accountCurrencyAmount": 6750,
          "fxAmount": null,
          "fxCurrency": null,
          "date": 1677628800000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": null,
          "isManuallyAdjusted": null,
          "tags": null,
          "sortingOrderWithinMonth": null,
          "linkedTransactions": [],
          "category": "deposits",
          "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for cost of living",
          "transactionAmount": 6750,
          "transactionPrice": 6750,
          "transactionCurrency": "EUR",
          "openAmount": 6750,
          "rebasedAmount": 6750,
          "displayName": "Bank for cost of living",
          "rowType": "purchase",
          "amountAssetCurrency": 6750,
          "positionOnDate": 89443.79000000001,
          "positionsPerProjectId": {
            "null": 89443.79000000001
          },
          "valueOnDateTransactionCurrency": 89443.79000000001,
          "valueOnDate": 89443.79000000001
        },
        {
          "id": "9ipDEjgMVgMMiZZilg4i0",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
          "otherParty": "Vonovia AG",
          "otherPartyAccount": null,
          "description": "Miete",
          "amount": -1500,
          "currency": "EUR",
          "accountCurrencyAmount": -1500,
          "fxAmount": null,
          "fxCurrency": null,
          "date": 1678406400000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": null,
          "isManuallyAdjusted": null,
          "tags": null,
          "sortingOrderWithinMonth": null,
          "linkedTransactions": [],
          "category": "deposits",
          "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for cost of living",
          "transactionAmount": -1500,
          "transactionPrice": -1500,
          "transactionCurrency": "EUR",
          "openAmount": -1500,
          "rebasedAmount": -1500,
          "displayName": "Bank for cost of living",
          "rowType": "sale",
          "amountAssetCurrency": -1500,
          "positionOnDate": 87943.79000000001,
          "positionsPerProjectId": {
            "null": 87943.79000000001
          },
          "valueOnDateTransactionCurrency": 87943.79000000001,
          "valueOnDate": 87943.79000000001
        },
        {
          "id": "TmLbtK9lM3RhDfDtCS4Mn",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
          "otherParty": "American Express",
          "otherPartyAccount": null,
          "description": "Kreditkarte 4160xxxxxxxxx4855",
          "amount": -2500,
          "currency": "EUR",
          "accountCurrencyAmount": -2500,
          "fxAmount": null,
          "fxCurrency": null,
          "date": 1679961600000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": null,
          "isManuallyAdjusted": null,
          "tags": null,
          "sortingOrderWithinMonth": null,
          "linkedTransactions": [],
          "category": "deposits",
          "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for cost of living",
          "transactionAmount": -2500,
          "transactionPrice": -2500,
          "transactionCurrency": "EUR",
          "openAmount": -2500,
          "rebasedAmount": -2500,
          "displayName": "Bank for cost of living",
          "rowType": "sale",
          "amountAssetCurrency": -2500,
          "positionOnDate": 85443.79000000001,
          "positionsPerProjectId": {
            "null": 85443.79000000001
          },
          "valueOnDateTransactionCurrency": 85443.79000000001,
          "valueOnDate": 85443.79000000001
        },
        {
          "id": "6OsugM3udfth-4rYCAu_C",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
          "otherParty": "Arbeitgeber AG",
          "otherPartyAccount": "DE02100500000054540402",
          "description": "Gehalt",
          "amount": 6750,
          "currency": "EUR",
          "accountCurrencyAmount": 6750,
          "fxAmount": null,
          "fxCurrency": null,
          "date": 1680307200000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": null,
          "isManuallyAdjusted": null,
          "tags": null,
          "sortingOrderWithinMonth": null,
          "linkedTransactions": [],
          "category": "deposits",
          "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for cost of living",
          "transactionAmount": 6750,
          "transactionPrice": 6750,
          "transactionCurrency": "EUR",
          "openAmount": 6750,
          "rebasedAmount": 6750,
          "displayName": "Bank for cost of living",
          "rowType": "purchase",
          "amountAssetCurrency": 6750,
          "positionOnDate": 92193.79000000001,
          "positionsPerProjectId": {
            "null": 92193.79000000001
          },
          "valueOnDateTransactionCurrency": 92193.79000000001,
          "valueOnDate": 92193.79000000001
        },
        {
          "id": "2mvoi6LjTt-Gj4ZQ0KNwz",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
          "otherParty": "Vonovia AG",
          "otherPartyAccount": null,
          "description": "Miete",
          "amount": -1500,
          "currency": "EUR",
          "accountCurrencyAmount": -1500,
          "fxAmount": null,
          "fxCurrency": null,
          "date": 1681084800000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": null,
          "isManuallyAdjusted": null,
          "tags": null,
          "sortingOrderWithinMonth": null,
          "linkedTransactions": [],
          "category": "deposits",
          "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for cost of living",
          "transactionAmount": -1500,
          "transactionPrice": -1500,
          "transactionCurrency": "EUR",
          "openAmount": -1500,
          "rebasedAmount": -1500,
          "displayName": "Bank for cost of living",
          "rowType": "sale",
          "amountAssetCurrency": -1500,
          "positionOnDate": 90693.79000000001,
          "positionsPerProjectId": {
            "null": 90693.79000000001
          },
          "valueOnDateTransactionCurrency": 90693.79000000001,
          "valueOnDate": 90693.79000000001
        },
        {
          "id": "txyO_XhlAfD_mZU9QZOBQ",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
          "otherParty": "American Express",
          "otherPartyAccount": null,
          "description": "Kreditkarte 4160xxxxxxxxx4855",
          "amount": -2500,
          "currency": "EUR",
          "accountCurrencyAmount": -2500,
          "fxAmount": null,
          "fxCurrency": null,
          "date": 1682640000000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": null,
          "isManuallyAdjusted": null,
          "tags": null,
          "sortingOrderWithinMonth": null,
          "linkedTransactions": [],
          "category": "deposits",
          "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for cost of living",
          "transactionAmount": -2500,
          "transactionPrice": -2500,
          "transactionCurrency": "EUR",
          "openAmount": -2500,
          "rebasedAmount": -2500,
          "displayName": "Bank for cost of living",
          "rowType": "sale",
          "amountAssetCurrency": -2500,
          "positionOnDate": 88193.79000000001,
          "positionsPerProjectId": {
            "null": 88193.79000000001
          },
          "valueOnDateTransactionCurrency": 88193.79000000001,
          "valueOnDate": 88193.79000000001
        },
        {
          "id": "ZCT1wW0xyQfgdbBdn7Vrn",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
          "otherParty": "Arbeitgeber AG",
          "otherPartyAccount": "DE02100500000054540402",
          "description": "Gehalt",
          "amount": 6750,
          "currency": "EUR",
          "accountCurrencyAmount": 6750,
          "fxAmount": null,
          "fxCurrency": null,
          "date": 1682899200000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": null,
          "isManuallyAdjusted": null,
          "tags": null,
          "sortingOrderWithinMonth": null,
          "linkedTransactions": [],
          "category": "deposits",
          "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for cost of living",
          "transactionAmount": 6750,
          "transactionPrice": 6750,
          "transactionCurrency": "EUR",
          "openAmount": 6750,
          "rebasedAmount": 6750,
          "displayName": "Bank for cost of living",
          "rowType": "purchase",
          "amountAssetCurrency": 6750,
          "positionOnDate": 94943.79000000001,
          "positionsPerProjectId": {
            "null": 94943.79000000001
          },
          "valueOnDateTransactionCurrency": 94943.79000000001,
          "valueOnDate": 94943.79000000001
        },
        {
          "id": "4ZeYmvQ_ZfdmoiZRrHvcd",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
          "otherParty": "Vonovia AG",
          "otherPartyAccount": null,
          "description": "Miete",
          "amount": -1500,
          "currency": "EUR",
          "accountCurrencyAmount": -1500,
          "fxAmount": null,
          "fxCurrency": null,
          "date": 1683676800000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": null,
          "isManuallyAdjusted": null,
          "tags": null,
          "sortingOrderWithinMonth": null,
          "linkedTransactions": [],
          "category": "deposits",
          "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for cost of living",
          "transactionAmount": -1500,
          "transactionPrice": -1500,
          "transactionCurrency": "EUR",
          "openAmount": -1500,
          "rebasedAmount": -1500,
          "displayName": "Bank for cost of living",
          "rowType": "sale",
          "amountAssetCurrency": -1500,
          "positionOnDate": 93443.79000000001,
          "positionsPerProjectId": {
            "null": 93443.79000000001
          },
          "valueOnDateTransactionCurrency": 93443.79000000001,
          "valueOnDate": 93443.79000000001
        },
        {
          "id": "d2Fh2VZ0G2VYaDYIPVKSw",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "CJ2-NiJHmIDdTI_f9UuXs",
          "otherParty": "American Express",
          "otherPartyAccount": null,
          "description": "Kreditkarte 4160xxxxxxxxx4855",
          "amount": -2500,
          "currency": "EUR",
          "accountCurrencyAmount": -2500,
          "fxAmount": null,
          "fxCurrency": null,
          "date": 1685232000000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": null,
          "isManuallyAdjusted": null,
          "tags": null,
          "sortingOrderWithinMonth": null,
          "linkedTransactions": [],
          "category": "deposits",
          "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for cost of living",
          "transactionAmount": -2500,
          "transactionPrice": -2500,
          "transactionCurrency": "EUR",
          "openAmount": -2500,
          "rebasedAmount": -2500,
          "displayName": "Bank for cost of living",
          "rowType": "sale",
          "amountAssetCurrency": -2500,
          "positionOnDate": 90943.79000000001,
          "positionsPerProjectId": {
            "null": 90943.79000000001
          },
          "valueOnDateTransactionCurrency": 90943.79000000001,
          "valueOnDate": 90943.79000000001
        },
        {
          "date": 1701475200000,
          "assetId": "CJ2-NiJHmIDdTI_f9UuXs",
          "rowType": "kpi.current",
          "currency": "EUR",
          "displayName": "Bank for cost of living",
          "accountName": "Bank for cost of living",
          "positionOnDate": 90943.79000000001,
          "positionsPerProjectId": {
            "null": 90943.79000000001
          },
          "valueOnDateTransactionCurrency": 90943.79000000001,
          "valueOnDate": 90943.79000000001
        }
      ]
    },
    "lyFDhscwJibqb-sf3pocN": {
      "lyFDhscwJibqb-sf3pocN": [
        {
          "date": 1638316800000,
          "assetId": "lyFDhscwJibqb-sf3pocN",
          "rowType": "kpi.referenceBaseline",
          "currency": null,
          "displayName": null,
          "displaySymbol": null,
          "accountName": null,
          "positionOnDate": 0,
          "positionsPerProjectId": {
            "null": 0
          },
          "valueOnDateTransactionCurrency": 0,
          "valueOnDate": 0
        },
        {
          "date": 1669852800000,
          "assetId": "lyFDhscwJibqb-sf3pocN",
          "rowType": "kpi.baseline",
          "currency": null,
          "displayName": null,
          "displaySymbol": null,
          "accountName": null,
          "positionOnDate": 0,
          "positionsPerProjectId": {
            "null": 0
          },
          "valueOnDateTransactionCurrency": 0,
          "valueOnDate": 0
        },
        {
          "id": "tvVU7XBydpXJRHegRrosi",
          "userId": "TestUserTier2Loadedxx",
          "accountId": "lyFDhscwJibqb-sf3pocN",
          "otherParty": "Grandpa",
          "otherPartyAccount": "CH8451978641397864",
          "description": "Gift",
          "amount": 10137.8751,
          "currency": "CHF",
          "accountCurrencyAmount": 10000,
          "fxAmount": null,
          "fxCurrency": null,
          "date": 1673049600000,
          "batchId": null,
          "projectId": null,
          "parentId": null,
          "isSimulated": false,
          "label": null,
          "isManuallyAdjusted": null,
          "tags": {},
          "sortingOrderWithinMonth": null,
          "linkedTransactions": [],
          "category": "deposits",
          "assetId": "lyFDhscwJibqb-sf3pocN",
          "originalUnitPrice": 1,
          "unitPrice": 1,
          "accountName": "Bank for fx deposit",
          "transactionAmount": 10137.8751,
          "transactionPrice": 10000,
          "transactionCurrency": "CHF",
          "openAmount": 10137.8751,
          "rebasedAmount": 10137.8751,
          "displayName": "Bank for fx deposit",
          "rowType": "purchase",
          "amountAssetCurrency": 10000,
          "positionOnDate": 10137.8751,
          "positionsPerProjectId": {
            "null": 10137.8751
          },
          "valueOnDateTransactionCurrency": 10137.8751,
          "valueOnDate": 10137.8751
        },
        {
          "date": 1701475200000,
          "assetId": "lyFDhscwJibqb-sf3pocN",
          "rowType": "kpi.current",
          "currency": "CHF",
          "displayName": "Bank for fx deposit",
          "accountName": "Bank for fx deposit",
          "positionOnDate": 10137.8751,
          "positionsPerProjectId": {
            "null": 10137.8751
          },
          "valueOnDateTransactionCurrency": 10137.8751,
          "valueOnDate": 10137.8751
        }
      ]
    }
  }
}