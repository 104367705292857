/* eslint-disable max-len */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Switch } from '@headlessui/react';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { XMarkIcon } from '@heroicons/react/24/solid';
import { PlusIcon } from '@heroicons/react/20/solid';
import { putStocksSettings, assetBalancesArray } from '../../redux/reducers/data';
import ToolTipNoIcon from '../../elements/ToolTipNoIcon';
import MiniSpinner from '../../misc/MiniSpinner';
import Dropdown from '../../elements/Dropdown';
import { setMessage } from '../../redux/actions/message';

// this is the component which handles the advanced dividend settings (define dividend per symbol)
// this thing has to return an array of simulatedDividends: [{ symbol, dividend }]
export function DividendSettings({ register, fields, append, remove, control, touchedFields, handleSubmit, reset, account }) {
  const { t } = useTranslation('app', { keyPrefix: 'accountDetails' });
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  // get a list of all assetIds in the transactions (also simulated in the future) to show in the dropdown
  // also get whatever friendly name user used for the symbol
  const uniqueFigis = useSelector((state) => assetBalancesArray(state))
    .filter((item) => item.category === 'stocks')
    .reduce((acc, curr) => ({ ...acc, [curr.assetId]: curr.displayName }), {});

  async function onSubmit(data) {
    // on submit of the form, update the account details
    setLoading(true);
    const response = await dispatch(
      putStocksSettings({
        data: {
          dividendYield: data.dividendYield,
          dividendYieldRates: data.dividendYieldRates, // INFO: these are in currency, not in percent
          dividendYieldAdvancedMode: data.dividendYieldAdvancedMode,
        },
        category: 'stocks',
      }),
    );
    if (response.meta.requestStatus === 'fulfilled') {
      reset();
      setLoading(false);
      dispatch(setMessage('accountUpdatedSuccessfully', account.id));
    } else {
      setLoading(false);
      dispatch(setMessage('accountCouldNotBeUpdated', account.id));
    }
    // console.log('AccountSettings > response dispatch', response);
  }

  return (
    <>
      <div className="grid grid-cols-2 space-x-4 text-xs xs:text-sm text-gray-700">
        <span>{t('settings.symbol')}</span>
        <ToolTipNoIcon info={t('simulatedAccountGrowth.dividendTooltip')}>
          <div>
            <span>{t('settings.dividend')}</span>
            <span className="ml-2 px-1.5 rounded-full bg-white border-[1px] border-gray-300 text-gray-300 text-xs font-bold">i</span>
          </div>
        </ToolTipNoIcon>
      </div>
      {fields.map((item, index) => (
        <div key={item.symbol} className="grid grid-cols-2 space-x-4 text-sm xs:text-base">
          <Controller
            name={`dividendYieldRates.${index}.symbol`}
            control={control}
            render={({ field, ref }) => (
              <Dropdown
                label=""
                value={field.value.name}
                list={Object.keys(uniqueFigis).map((figi) => ({ id: figi, name: uniqueFigis[figi] || figi }))}
                onChange={field.onChange}
                onBlur={field.onBlur}
                inputRef={ref}
                optional={false}
              />
            )}
          />
          <div className="flex space-x-3 items-center">
            <input
              type="number"
              name={`value${index}`}
              id={`value${index}`}
              placeholder={1.25}
              step={0.01}
              className="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-gray-900 focus:outline-none focus:ring-gray-900 sm:text-sm"
              {...register(`dividendYieldRates.${index}.value`)}
            />
            <button
              type="button"
              className="inline-flex items-center px-1 py-1 mt-1 border border-gray-300 text-xs font-medium rounded-md shadow-sm bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
              onClick={() => remove(index)}
            >
              <XMarkIcon className="h-4 w-4 text-gray-400" aria-hidden="true" />
            </button>
          </div>
        </div>
      ))}
      <div className="flex space-x-2">
        {/* only display ADD button if there are more different stock symbols in the store than on our list */}
        {fields.length < Object.keys(uniqueFigis).length && (
          <button
            type="button"
            className="mt-4 inline-flex text-gray-400 items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md shadow-sm bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
            onClick={() => append({ symbol: '', value: 0.15 })}
          >
            <PlusIcon className="h-4 w-4" aria-hidden="true" />
            <span>{t('settings.add')}</span>
          </button>
        )}
        {touchedFields?.dividendYieldRates && (
          <button
            type="button"
            onClick={handleSubmit(onSubmit)}
            className="mt-4 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-brandBlue-500 hover:bg-brandBlue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brandBlue-400"
          >
            {!loading ? t('buttons.save.label') : <MiniSpinner className="w-5 h-5 animate-spin" />}
          </button>
        )}
      </div>
    </>
  );
}
DividendSettings.propTypes = {
  register: PropTypes.func.isRequired,
  fields: PropTypes.object.isRequired,
  append: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  control: PropTypes.object.isRequired,
  reset: PropTypes.func.isRequired,
  touchedFields: PropTypes.object.isRequired,
  handleSubmit: PropTypes.object.isRequired,
  account: PropTypes.object.isRequired,
};

export default function DividendGrowthRates({ propAccount }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const selectBaseCurrency = useSelector((state) => state.user.profile.settings.baseCurrency);
  const baseCurrency = useMemo(() => selectBaseCurrency, [selectBaseCurrency]);

  // need to get account information from redux store, as the prop is not updated in Tile when the account object changes in store
  const account = useSelector((state) => state.data[propAccount.category].accounts).filter((a) => a.id === propAccount.id)[0];
  const tags = typeof account.tags === 'string' ? JSON.parse(account.tags) : account.tags;
  // stock settings for dividendYieldRates are stored centrally for all accounts in stocks.settings
  // growthRate is an account-level setting and is stored as account tag (also for stocks)
  let dividendYieldRates;
  let dividendYield;
  let dividendYieldAdvancedMode;
  // let growthRate; // 240625 FIX: removing growthRate from this component, as it is not used for categories with assets (stocks, crypto) --> only category- and asset-level settings are used
  if (account.category === 'stocks') {
    dividendYieldRates = useSelector((state) => state.data.stocks.settings?.dividendYieldRates);
    dividendYield = useSelector((state) => state.data.stocks.settings?.dividendYield);
    dividendYieldAdvancedMode = useSelector((state) => state.data.stocks.settings?.dividendYieldAdvancedMode);
    // growthRate = tags?.growthRate;
  } else {
    // growthRate = tags?.growthRate;
  }

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitted, touchedFields },
    control,
    watch,
    reset,
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      // growthRate,
      dividendYieldRates,
      dividendYield,
      dividendYieldAdvancedMode,
    },
  });
  const { fields, append, remove } = useFieldArray({
    name: 'dividendYieldRates',
    control,
  });

  async function onSubmit(data) {
    // on submit of the form, update the account details
    setLoading(true);
    const response = await dispatch(
      // putStocksSettings will spread the new values over the entire existing object
      putStocksSettings({
        data: {
          dividendYield: data.dividendYield,
          dividendYieldRates: data.dividendYieldRates, // INFO: these are in currency, not in percent
          dividendYieldAdvancedMode: data.dividendYieldAdvancedMode,
        },
        category: 'stocks',
      }),
    );
    if (response.meta.requestStatus === 'fulfilled') {
      reset();
      setLoading(false);
      dispatch(setMessage('accountUpdatedSuccessfully', account.id));
    } else {
      setLoading(false);
      dispatch(setMessage('accountCouldNotBeUpdated', account.id));
    }
    // console.log('AccountSettings > response dispatch', response);
  }

  return (
    <>
      <div className="sm:max-w-sm col-span-2">
        <Controller
          control={control}
          name="dividendYieldAdvancedMode"
          render={({ field }) => (
            <Switch.Group as="div" className="flex items-center justify-start w-full">
              <Switch.Label as="span" className="mr-3">
                <span className="text-sm font-medium text-gray-500">Simple</span>
              </Switch.Label>
              <Switch
                {...field}
                checked={field.value}
                onChange={field.onChange}
                className={`${field.value ? 'bg-brandBlue-500' : 'bg-gray-200'} relative inline-flex flex-shrink-0 h-6 w-11 border-2
                      border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
              >
                <span
                  aria-hidden="true"
                  className={`${field.value ? 'translate-x-5' : 'translate-x-0'}
                          pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200`}
                />
              </Switch>
              <Switch.Label as="span" className="ml-3">
                <span className="text-sm font-medium text-gray-500">Advanced</span>
              </Switch.Label>
            </Switch.Group>
          )}
        />
        <div className="mt-4 text-gray-700">
          <p className="text-gray-700 text-sm">{t('app:accountDetails.settings.dividend')}</p>
          <div className="flex space-x-2 items-center pt-0.5">
            <input
              type="number"
              step="0.01"
              name="dividendYield"
              id="dividendYield"
              {...register('dividendYield', { valueAsNumber: true })}
              className="mt-0.5 flex-1 focus:ring-brandBlue-400 focus:border-brandBlue-400 block w-full min-w-0 rounded-md sm:text-sm border-gray-300 placeholder:text-gray-200 placeholder:italic"
            />
            <span>%</span>
          </div>
          {errors.dividendYield && (
            <div className="pt-2 sm:text-sm text-brandRed-500 max-w-fit">
              <span>{errors.dividendYield.message}</span>
            </div>
          )}
          {touchedFields?.dividendYield && (
            <button
              type="button"
              onClick={handleSubmit(onSubmit)}
              className="mt-4 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-brandBlue-500 hover:bg-brandBlue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brandBlue-400"
            >
              {!loading ? t('app:accountDetails.buttons.save.label') : <MiniSpinner className="w-5 h-5 animate-spin" />}
            </button>
          )}
        </div>
      </div>
      {watch('dividendYieldAdvancedMode') && (
        <div className="sm:col-span-2">
          <DividendSettings
            fields={fields}
            append={append}
            remove={remove}
            register={register}
            control={control}
            touchedFields={touchedFields}
            handleSubmit={handleSubmit}
            onSubmit={onSubmit}
            reset={reset}
            account={account}
          />
        </div>
      )}
    </>
  );
}
DividendGrowthRates.propTypes = {
  propAccount: PropTypes.object.isRequired,
};
