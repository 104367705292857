import axios from 'axios';
import dayjs from 'dayjs';

// returns the exchange rate against EUR for a given date
export async function getFxRates(fxCode, date) {
  // logic: get data for a 7-day period ENDING with the target date; always get the last date from the array:
  // it will either be the target date or the last value from before the target date
  if (fxCode === 'EUR') return 1;
  const startDate = dayjs(date).subtract(7, 'day').format('YYYY-MM-DD');
  const endDate = dayjs(date).format('YYYY-MM-DD');
  const response = await axios.get(`https://data-api.ecb.europa.eu/service/data/EXR/D.${fxCode}.EUR.SP00.A?startPeriod=${startDate}&endPeriod=${endDate}`);
  return Object.values(response.data.dataSets[0].series['0:0:0:0:0'].observations)
    .map((value) => value[0])
    .filter((value) => value !== null)
    .reverse()[0];
}

export async function convertCurrency(amount, amountFxCode, toFxCode, date) {
  // return error if the date is before 1999-01-04
  if (dayjs(date).isBefore('1999-01-04')) {
    throw new Error('FX rates before 1999 are not available.');
  }

  return amount * ((await getFxRates(toFxCode, date)) / (await getFxRates(amountFxCode, date)));
}
