/* eslint-disable react/forbid-prop-types */
import { ArrowRightCircleIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { useSelector, useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { depositTransactions } from '../../redux/reducers/data';
import { setMessage } from '../../redux/actions/message';
import AccountLabel from '../../elements/AccountLabel';
import Button from '../../elements/Button';

export default function OrphanedTransactionsDialog({ setDialogOpen, transactions, setAccountDetails }) {
  const selectDepositTransactions = useSelector(depositTransactions);
  const dispatch = useDispatch();

  function handleTransactionClick(transaction) {
    // try to find out the most likely deposit transaction in the connectedDepositAccounts (which every transaction in 'transactions' has)
    const connectedDepositAccountsIds = transaction.connectedDepositAccounts.map((a) => a.id);
    // see if you can find any deposit transaction in the deposit accounts connected to this asset account
    // bear in mind that 'transaction' is an asset transaction, so quantity can be positive or negative (loans taken)
    // so find all transactions in connectedDepositAccounts.map((a) => a.id) which have a Math.abs(quantity) within 10% of transaction.quantity and date within 5 days of transaction.date
    // if found, add the transaction to connectedDepositTransactions
    const transactionCandidates = selectDepositTransactions.filter((txn) => connectedDepositAccountsIds.includes(txn.accountId)
      && Math.abs(txn.quantity) >= Math.abs(transaction.quantity) * 0.9 && Math.abs(txn.quantity) <= Math.abs(transaction.quantity) * 1.1
      && Math.abs(txn.date - transaction.date) <= 5 * 24 * 60 * 60 * 1000);

    if (transactionCandidates.length > 0) {
      if (transactionCandidates.length === 1) {
        // if there is only one, setAccountDetails to the full Account object of that transaction's accountId and add highlightTransactionId
        const targetAccount = transaction.connectedDepositAccounts.find((a) => a.id === transactionCandidates[0].accountId);
        setAccountDetails({ ...targetAccount, highlightTransactionId: transactionCandidates[0].id });
        return;
      }
      // if there is more than one candidate, just open the account without highlighting anything (show a warning to user)
      dispatch(setMessage('moreThan1ConnectedAccountFound'));
      setAccountDetails(transaction.connectedDepositAccounts[0]);
    }
    setAccountDetails(transaction.connectedDepositAccounts[0]);
    setDialogOpen(false);
  }

  const { t } = useTranslation(['app'], { keyPrefix: 'dashboard.sumtile.orphanedDialog' });

  return (
    <div className="absolute flex bg-gray-500 bg-opacity-75 inset-0 z-10">
      <div className="relative max-w-2xl mx-auto my-auto bg-white p-12 rounded-md shadow-sm">
        <XMarkIcon className="absolute top-4 right-4 h-6 w-6 text-gray-500 hover:text-gray-900 cursor-pointer" onClick={() => setDialogOpen(false)} />
        <h2 className="w-full sm:w-auto text-xl sm:text-2xl font-bold text-gray-900 mb-4 prose">{t('assetTransactionsDialogTitle')}</h2>
        <div className="flex space-x-2">
          <div className="flex-shrink-0">
            <InformationCircleIcon className="w-6 h-6 text-brandBlue-500" />
          </div>
          <h3 className="w-full sm:w-auto text-base font-normal text-gray-700 leading-snug mb-4">{t('netWorthExplanation')}</h3>
        </div>
        <div className="flex space-x-2">
          <div className="flex-shrink-0">
            <ArrowRightCircleIcon className="w-6 h-6 text-green-500" />
          </div>
          <h3 className="w-full sm:w-auto text-base font-normal text-gray-700 leading-snug mb-4">{t('notLinkedTransactionsExplanation')}</h3>
        </div>
        <div className="mt-4 space-y-4">
          <div className="p-2 w-full grid grid-cols-4 grid-flow-row gap-4 text-gray-900 text-xs font-bold uppercase">
            <div>
              <p>{t('transactionTableHeaders.date')}</p>
              <p>{t('transactionTableHeaders.assetAccount')}</p>
            </div>
            <p>{t('transactionTableHeaders.assetName')}</p>
            <p className="text-right">{t('transactionTableHeaders.amount')}</p>
            <p className="text-right">{t('transactionTableHeaders.price')}</p>
          </div>
          {transactions.map((transaction) => (
            <button
              type="button"
              key={transaction.id}
              onClick={() => handleTransactionClick(transaction)}
              className="px-4 py-2 w-full grid grid-cols-4 grid-flow-row gap-4 border rounded-md border-gray-300 text-sm items-center cursor-pointer hover:bg-gray-50"
            >
              <div className="text-left">
                <p>{dayjs.utc(transaction.date).format('YYYY.MM.DD')}</p>
                <AccountLabel accountName={transaction.accountName} category={transaction.category} styleClasses="justify-self-start mt-1" />
              </div>
              <p className="text-left">{transaction.displayName || transaction.accountName}</p>
              <p className="text-right">{transaction.quantity}</p>
              <p className="text-right">
                {/* display transactions in acccount currency, because the user is about to see them in account currency anyway */}
                {`${(transaction.quantity * transaction.upac).toLocaleString('de', {
                  style: 'currency',
                  currency: transaction.accountCurrency,
                })}`}
              </p>
            </button>
          ))}
        </div>
        {/* close button */}
        <div className="mt-6 flex justify-end">
          <Button
            text={t('closeButtonText')}
            onClick={() => setDialogOpen(false)}
            withAccent
          />
        </div>
      </div>
    </div>
  );
}
OrphanedTransactionsDialog.propTypes = {
  setDialogOpen: PropTypes.func.isRequired,
  transactions: PropTypes.arrayOf(PropTypes.object),
  setAccountDetails: PropTypes.func.isRequired,
};
OrphanedTransactionsDialog.defaultProps = {
  transactions: [],
};
