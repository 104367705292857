/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { FlagIcon } from '@heroicons/react/20/solid';
import { projectBalancesArray } from '../../redux/reducers/data';
import MiniSpinner from '../../misc/MiniSpinner';

export default function GoalComponent({ goal, project, setGoalDialog }) {
  // set this is this is an isolated project
  const sliderDate = useSelector((state) => state.simulation.baseDate);
  const baseCurrency = useSelector((state) => state.user.profile.settings.baseCurrency);

  const projectSumAtDate = useSelector(projectBalancesArray)
    .reduce((acc, curr) => (curr.projectId === project.id ? curr.current.valueBaseCurrency : acc), 0);

  const options = { style: 'currency', currency: baseCurrency, maximumFractionDigits: 0 };

  return (
    <button className="w-full pb-1" type="button" onClick={(e) => setGoalDialog(goal)}>
      <div
        role="button"
        tabIndex={0}
        className={`w-full px-3 py-2.5 flex justify-between bg-white border border-gray-300 rounded-lg shadow-sm hover:bg-brandBlue-50 group cursor-pointer
                  ${Number(goal.date.valueOf()) < Number(sliderDate) ? 'opacity-60' : ''}`}
        name="project-goal"
      >
        <div className="flex items-center gap-12">
          <span className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-gray-900 text-white">{<FlagIcon className="w-5 h-5 text-white" /> || <div />}</span>
          <div className="flex items-center justify-between gap-8 text-md font-medium text-gray-700">
            <span>{goal.name.toUpperCase()}</span>
            <span>{`Target: ${goal.amount.toLocaleString('de', options)}`}</span>
            {typeof projectSumAtDate === 'number' ? <span>{`Forecast: ${projectSumAtDate.toLocaleString('de', options)}`}</span> : <MiniSpinner className="w-4 h-4 animate-spin text-gray-400" />}
          </div>
        </div>
      </div>
    </button>
  );
}
GoalComponent.propTypes = {
  goal: PropTypes.objectOf(PropTypes.any).isRequired,
  project: PropTypes.objectOf(PropTypes.any).isRequired,
  setGoalDialog: PropTypes.func.isRequired,
};
