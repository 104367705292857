/* eslint-disable newline-per-chained-call */
/* eslint-disable react/forbid-prop-types */
import { object, string, number, boolean, array, mixed, ref, ValidationError } from 'yup';
import dayjs from 'dayjs';
import { currencyCodes } from './currencyCodes';
import countryNames, { countryCodes } from './countries';

const utc = require('dayjs/plugin/utc');

dayjs.extend(utc);

const customParseFormat = require('dayjs/plugin/customParseFormat');

dayjs.extend(customParseFormat);

const nanoId = string()
  .length(21)
  .matches(/^[0-9a-z\-_]{21}$/i);

const isoCurrencyCode = string()
  .nullable(true)  // Explicitly allow null
  .length(3, 'Currency code must be exactly 3 characters long')
  .matches(/^[A-Z]{3}$/, 'Currency code must be 3 uppercase letters')
  .oneOf(currencyCodes, 'Invalid currency code');

const countryCode = string()
  .length(2, 'Country code must be exactly 2 characters long')
  .matches(/^[A-Z]{2}$/i)
  .oneOf([...countryCodes, null], 'Invalid country code');

const accountStatus = string()
  .oneOf(['idle', 'loading', 'noerrors', 'errors', 'failed', 'awaitingMappingInput', null], 'Invalid value of account status');

const providerTags = {
  provider: object().shape({
    finapi: object().shape({ // caution: all small caps in key name
      providerAccountId: string(),
      providerInstitutionId: string(),
      providerAccessId: string(),
    }),
    gocardless: object().shape({ // caution: all small caps in key name
      providerAccountId: string(),
      providerInstitutionId: string(),
      providerAccessId: string(),
    }),
  }),
};

// common for deposits and stocks
const accountTags = object()
  .shape({
    growthRate: number().nullable(true).default(null),
    ...providerTags,
  })
  .nullable(true)
  .default(null);

const projectTags = {
  type: string().nullable(true).default(null), // entered by AddProjectTransaction
  recurring: object().shape({ // entered by AddProjectTransaction // relevant for stocks and realEstate
    activated: boolean().default(false),
    numberOfPeriods: number().nullable(true).default(1),
    periodType: object().shape({
      id: string().nullable(true).default(null),
      name: string().nullable(true).default(null),
    }),
    end: string().oneOf(['recurringNever', 'recurringByOccurrence', 'recurringByDate']).default('recurringNever'),
    endAfterOccurrences: number().nullable(true).default(10),
    endAfterDate: number().nullable(true).default(null),
  })
    .nullable(true)
    .default(null),
  indexed: object().shape({ // entered by AddProjectTransaction // relevant for stocks and realEstate
    activated: boolean().default(false),
    mode: string().oneOf(['indexedByInflation', 'indexedByCustomRate']).default('indexedByInflation'),
    customRateInput: number().nullable(true).default(null),
  })
    .nullable(true)
    .default(null),
};

const genericDate = number('Date must be in epoch(ms) format')
  .meta({ dialogFormType: 'date' })
  .typeError('Date is invalid or missing')
  .required('Date is required')
  .transform((newValue, orig) => {
    if (dayjs.utc(orig).isValid()) return dayjs.utc(orig).startOf('day').valueOf();
    if (dayjs.utc(orig, 'DD.MM.YYYY', true).isValid()) return dayjs.utc(orig, 'DD.MM.YYYY').startOf('day').valueOf();
    if (dayjs.utc(orig, 'YYYY.MM.DD', true).isValid()) return dayjs.utc(orig, 'YYYY.MM.DD').startOf('day').valueOf();
    if (dayjs.utc(orig, 'DD-MM-YYYY', true).isValid()) return dayjs.utc(orig, 'DD-MM-YYYY').startOf('day').valueOf();
    if (dayjs.utc(orig, 'DD-MM-YY', true).isValid()) return dayjs.utc(orig, 'DD-MM-YY').startOf('day').valueOf();
    if (dayjs.utc(orig, 'YY-MM-DD', true).isValid()) return dayjs.utc(orig, 'YY-MM-DD').startOf('day').valueOf();
    if (dayjs.utc(orig, 'MM/DD/YYYY', true).isValid()) return dayjs.utc(orig, 'MM/DD/YYYY').startOf('day').valueOf();
    if (dayjs.utc(orig, 'DD/MM/YYYY', true).isValid()) return dayjs.utc(orig, 'DD/MM/YYYY').startOf('day').valueOf();
    if (dayjs.utc(orig, 'DD MM YYYY', true).isValid()) return dayjs.utc(orig, 'DD MM YYYY').startOf('day').valueOf();
    if (dayjs.utc(orig, 'YYYY MM DD', true).isValid()) return dayjs.utc(orig, 'YYYY MM DD').startOf('day').valueOf();
    return newValue; // if nothing works, give up (and cause an error to be thrown in GUI)
  })
  .min(999999999999, 'Date must be in epoch(ms) format');

const depositAccount = object({
  id: nanoId
    .nullable(true)
    .default(null),
  name: string()
    .required('Account name is required')
    .max(100, 'Account name must be shorter than 50 characters'),
  bankName: string()
    .nullable(false) // required by backend for now
    .default('BANK')
    .max(100, 'Bank name must be shorter than 100 characters'),
  bic: string()
    .nullable(true)
    .default(null)
    .max(11, 'BIC must 11 characters or shorter'),
  iban: string()
    .nullable(true)
    .default(null)
    .max(45, 'IBAN must be shorter than 45 characters'),
  currency: isoCurrencyCode
    .required('Currency code is required')
    .default('EUR'),
  countryCode: countryCode
    .required('Country code is required')
    .default('DE'),
  depositType: string()
    .oneOf(['savings-account', 'time-deposit', null], 'Invalid depositType')
    .nullable(true)
    .default(null)
    .max(30, 'DepositType is too long'),
  syncType: string()
    .oneOf(['manual', 'automatic', null], 'Invalid syncType')
    .nullable(true)
    .default(null)
    .max(20, 'SyncType is too long'),
  status: accountStatus,
  statusTimestamp: number('Status timestamp must be in epoch(ms) format')
    .nullable(true)
    .default(null)
    .min(999999999999, 'Timestamp must be in epoch(ms) format'),
  tags: accountTags,
  importFileMappings: object()
    .meta({ hideFromImport: true })
    .nullable(true)
    .default(null),
});

const depositTransaction = object().shape({
  id: nanoId // new transactions must exist without an id first
    .nullable(true)
    .default(null)
    .meta({ hideFromImport: true }),
  accountId: nanoId
    .required()
    .meta({ hideFromImport: true }),
  date: genericDate,
  otherParty: string()
    .transform((newValue, orig) => ((String(orig) === '' || orig === null) ? 'null' : newValue))
    .min(1)
    .max(100, 'Transaction party must be shorter than 100 characters')
    .required('Transaction party is required'),
  otherPartyAccount: string() // IBAN
    .max(50, 'Transaction party account must be shorter than 50 characters')
    .nullable(true)
    .default(null),
  description: string()
    .max(1000, 'Transaction description must be shorter than 1000 characters')
    .nullable(true)
    .default(null),
  amount: number('Amount must be a number') // always in base currency, will be calculated in API based on currency and accountCurrencyAmount
    .meta({ hideFromImport: true })
    .transform((newValue, originalValue) => {
      if (originalValue === null) return null;
      return (Number.isNaN(Number(originalValue))) ? Number(originalValue.replace(/,/, '.')) : newValue;
    })
    .nullable(true)
    .default(null),
  currency: isoCurrencyCode
    .meta({ hideFromImport: true })
    .required('Currency code is required')
    .default('EUR'),
  accountCurrencyAmount: number('Account currency amount must be a number')
    // if we have fxCurrency and fxAmount then this is not required
    .when(['fxAmount', 'fxCurrency'], {
      is: (fxAmount, fxCurrency) => !!(fxCurrency) && !(fxAmount), // if fxCurrency exists, but there is no fxAmount, then this is required
      then: (schema) => schema.required('Account currency amount or foreign currency amount are required when foreign currency is specified'),
      otherwise: (schema) => schema.optional().nullable(true).default(null),
    })
    // if the value cannot be parsed as a number, try to replace the first comma with a dot
    .transform((newValue, originalValue) => {
      if (originalValue === null) return null;
      return (Number.isNaN(Number(originalValue))) ? Number(originalValue.replace(/,/, '.')) : newValue;
    }),
  fxAmount: number('Foreign exchange amount must be a number')
    .meta({ importOptional: true })
    .nullable(true)
    .default(null)
    // this is required unless there is fxCurrency and accountCurrencyAmount
    .when(['fxCurrency', 'accountCurrencyAmount'], {
      is: (fxCurrency, accountCurrencyAmount) => !!(fxCurrency) && !(accountCurrencyAmount), // if fxCurrency exists, but there is no accountCurrencyAmount, then this is required
      then: (schema) => schema.required('Foreign currency or account currency amount is required when foreign currency is specified'),
      otherwise: (schema) => schema.optional(),
    })
    .transform((newValue, originalValue) => {
      if (originalValue === null) return null;
      return (Number.isNaN(Number(originalValue))) ? Number(originalValue.replace(/,/, '.')) : newValue;
    }),
  fxCurrency: isoCurrencyCode
    .meta({ importOptional: true })
    .nullable(true)
    .default(null)
    .when('fxAmount', {
      is: (fxAmount) => !!(fxAmount),
      then: (schema) => schema.required('Foreign exchange currency is required when a foreign currency amount is specified'),
      otherwise: (schema) => schema.optional(),
    }),
  batchId: nanoId
    .meta({ hideFromImport: true })
    .nullable(true)
    .default(null),
  linkedTransactions: array().default([]).meta({ hideFromImport: true }),
  isSimulated: boolean()
    .meta({ hideFromImport: true })
    .default(false),
  isIsolated: boolean()
    .meta({ hideFromImport: true })
    .default(false),
  isManuallyAdjusted: boolean()
    .meta({ hideFromImport: true })
    .transform((newValue, orig) => ((String(orig) === '' || orig === null) ? false : newValue))
    .default(false),
  projectId: nanoId
    .meta({ hideFromImport: true })
    .nullable(true)
    .default(null),
  parentId: nanoId // each transaction split from another transaction has the same parentId
    .meta({ hideFromImport: true })
    .nullable(true)
    .default(null),
  errorId: string()
    .meta({ hideFromImport: true })
    .nullable(true)
    .default(null),
  importFlag: string() // used by Browser App to mark transactions to be deleted ('delete'); tbc. if also put or post
    .meta({ hideFromImport: true })
    .nullable(true)
    .default(null),
  label: string()
    .meta({ hideFromImport: true })
    .nullable(true)
    .default(null),
  tags: object()
    .meta({ hideFromImport: true })
    .shape({
      assetId: string().nullable(true).default(null), // entered by BlueTransactionDialog, used to link transaction to account and / or asset for dividends, costs etc.
      accountId: string().nullable(true).default(null), // entered by BlueTransactionDialog, used to link transaction to account and / or asset for dividends, costs etc.
      ...projectTags,
      ...providerTags,
    })
    .nullable(true)
    .default(null),
  sortingOrderWithinMonth: number()
    .meta({ hideFromImport: true })
    .nullable(true)
    .default(null),
}, [
  ['fxAmount', 'fxCurrency'],
  ['accountCurrencyAmount', 'fxAmount'],
  ['accountCurrencyAmount', 'fxCurrency'],
]);

const depositTransactions = array()
  .of(depositTransaction);

const stockAccount = object({
  // does not have a currency!
  id: nanoId
    .nullable(true)
    .default(null),
  brokerName: string()
    .nullable(true)
    .default(null)
    .max(100, 'Broker name must be shorter than 100 characters'),
  name: string()
    .required('Account name is required')
    .max(100, 'Account name must be shorter than 50 characters'),
  username: string()
    .nullable(true)
    .default(null)
    .max(50, 'Username must be shorter than 50 characters'),
  accountNo: string()
    .nullable(true)
    .default(null)
    .max(50, 'Account number must be shorter than 50 characters'),
  credential: string()
    .nullable(true)
    .default(null)
    .max(50, 'Account number must be shorter than 50 characters'),
  connectedDepositAccounts: array()
    .of(nanoId)
    .nullable(false)
    .default([]),
  ownCurrentAccount: string()
    .nullable(true)
    .default(null)
    .max(45, 'Cash account IBAN must be shorter than 45 characters'),
  countryCode: countryCode
    .required('Country code is required')
    .default('DE'),
  syncType: string()
    .nullable(true)
    .default(null)
    .max(20, 'SyncType is too long'),
  subcategory: string()
    .nullable(true)
    .default(null)
    .oneOf(['stocks', 'crypto', null]),
  status: accountStatus,
  statusTimestamp: number('Status timestamp must be in epoch(ms) format')
    .nullable(true)
    .default(null)
    .min(999999999999, 'Timestamp must be in epoch(ms) format'),
  tags: accountTags,
  importFileMappings: object()
    .meta({ hideFromImport: true })
    .nullable(true)
    .default(null),
});

const stockTransaction = object({
  accountId: nanoId
    .meta({ hideFromImport: true })
    .required('AccountId is required'),
  id: nanoId
    .meta({ hideFromImport: true })
    .nullable(true)
    .default(null),
  date: genericDate,
  figi: string()
    .meta({ hideFromImport: true })
    .nullable(true) // when users enter transactions, there won't be a figi
    .default(null),
  providerAssetId: string()
    .max(255, 'Provider Asset ID cannot be longer than 100 characters') // database file restriction; long names are used by crypto assets in mobula api
    .meta({ hideFromImport: true })
    .nullable(true) // when users enter transactions, there won't be a providerAssetId
    .default(null),
  displaySymbol: string()
    .required('Symbol (ISIN/WKN...) of the security is required'),
  displayName: string()
    .nullable(true)
    .default(null),
  transactionType: string()
    .meta({ hideFromImport: true })
    .required('Transaction type is required')
    .oneOf(['purchase', 'sale', 'split', 'transfer'], 'Invalid transaction type'),
  splitRatioOld: number()
    .meta({ hideFromImport: true })
    .when('transactionType', {
      is: 'split',
      then: (schema) => schema.required('Split ratio (old) is required for split transactions'),
      otherwise: (schema) => schema.nullable(true).default(null),
    }),
  splitRatioNew: number()
    .meta({ hideFromImport: true })
    .when('transactionType', {
      is: 'split',
      then: (schema) => schema.required('Split ratio (old) is required for split transactions'),
      otherwise: (schema) => schema.nullable(true).default(null),
    }),
  transactionAmount: number('Transaction amount must be a number') // original transaction amount (never changes)
    .nullable(true)
    .when('transactionType', {
      is: (transactionType) => transactionType === 'purchase' || transactionType === 'sale',
      then: (schema) => schema.required('Transaction amount is required for purchase and sale transactions'),
      otherwise: (schema) => schema.optional().nullable(true),
    })
    .when('transactionType', {
      is: (transactionType) => transactionType === 'sale',
      then: (schema) => schema.max(0, 'Transaction amount must be negative for sale transactions'),
      otherwise: (schema) => schema.nullable(true),
    }),
  transactionPrice: number('Transaction price must be a number') // original price per piece in base currency at the quote of that day
    .meta({ hideFromImport: true })
    .nullable(true)
    .default(null),
  transactionCurrency: isoCurrencyCode
    .when('transactionType', {
      is: (transactionType) => transactionType === 'purchase' || transactionType === 'sale',
      then: (schema) => schema.required('Transaction currency is required for purchase and sale transactions'),
      otherwise: (schema) => schema.optional().nullable(true),
    })
    .default('EUR'),
  transactionOriginalPrice: number('Transaction FX price must be a number') // original price per piece in foreign currency; equal to transactionPrice if transaction took place in base currency
    .when(['transactionType', 'transactionPrice'], {
      is: (transactionType, transactionPrice) => (transactionType === 'purchase' || transactionType === 'sale') && (!transactionPrice || transactionPrice.length === 0),
      then: (schema) => schema.required('Transaction original price (fx) or base currency are required for purchase and sale transactions'),
      otherwise: (schema) => schema.optional().nullable(true),
    }),
  rebasedAmount: number('Rebased amount must be a number') // if stock has split, this is the adjusted amount reflecting all the subsequent splits; it can change if more splits come
    .meta({ hideFromImport: true })
    .nullable(true)
    .default(null)
    .when('transactionType', {
      is: (transactionType) => transactionType === 'sale',
      then: (schema) => schema.max(0, 'Transaction amount must be negative for sale transactions'),
      otherwise: (schema) => schema.nullable(true),
    }),
  rebasedPrice: number('Rebased price must be a number') // if stock has split, this is the adjusted price in user base currency reflecting all the subsequent splits; it can change if more splits come
    .meta({ hideFromImport: true })
    .nullable(true)
    .default(null),
  rebasedOriginalPrice: number('Rebased original price must be a number') // adjusted price per piece in foreign currency; equal to transactionPrice if transaction took place in base currency
    .meta({ hideFromImport: true })
    .nullable(true)
    .default(null),
  linkedTransactions: array()
    .meta({ hideFromImport: true })
    .nullable(true)
    .default([]),
  isSimulated: boolean()
    .meta({ hideFromImport: true })
    .default(false),
  isIsolated: boolean()
    .meta({ hideFromImport: true })
    .default(false),
  isManuallyAdjusted: boolean()
    .meta({ hideFromImport: true })
    .default(false),
  errorId: string()
    .meta({ hideFromImport: true })
    .nullable(true)
    .default(null),
  transactionAmountSold: number('Rebased amount sold must be a number')
    .meta({ hideFromImport: true })
    .nullable(true)
    .default(null),
  rebasedAmountSold: number('Rebased amount sold must be a number')
    .meta({ hideFromImport: true })
    .nullable(true)
    .default(null),
  rebasedAmountSoldWhenSimulated: number('Rebased amount when simulated sold must be a number')
    .meta({ hideFromImport: true })
    .nullable(true)
    .default(null),
  projectId: nanoId
    .meta({ hideFromImport: true })
    .nullable(true)
    .default(null),
  tags: object().shape({
    ...projectTags,
    ...providerTags,
  })
    .meta({ hideFromImport: true })
    .nullable(true)
    .default(null),
  importFlag: string()
    .meta({ hideFromImport: true })
    .nullable(true)
    .default(null),
  sortingOrderWithinMonth: number()
    .meta({ hideFromImport: true })
    .nullable(true)
    .default(null),
}, ['transactionPrice', 'transactionOriginalPrice']);

const stockTransactions = array()
  .of(stockTransaction);

const realEstateAccount = object({
  id: nanoId.nullable(true).default(null),
  name: string().max(100, 'Account name may be up to 100 characters long').required(),
  currency: isoCurrencyCode.required(),
  status: accountStatus.nullable(null).default(null),
  statusTimestamp: number('Status timestamp must be in epoch(ms) format').nullable(true).default(null).min(999999999999, 'Timestamp must be in epoch(ms) format'),
  lastMarketPriceRetrieved: number().min(-2, 'Market price cannot be negative').max(100000000, 'Property value cannot exceed 100 000 000 EUR').nullable(null).default(null), // sanity check.
  connectedDepositAccounts: array().of(nanoId).nullable(false).default([]),
  tags: object()
    .shape({
      growthRate: number().nullable(true).default(null),
      nextValuationDate: number('nextValuationDate must be in epoch(ms) format').nullable(true).default(undefined).min(999999999999, 'nextValuationDate must be in epoch(ms) format'),
    })
    .nullable(true)
    .default(null),
  valuationParameters: object().shape({
    type: string().oneOf(['apartment', 'building', null]).required().default('apartment'),
    subtype: string()
      .when('type', {
        is: (type) => type === 'building',
        then: (schema) => schema.oneOf(['farm', 'special-building', 'bungalow', 'castle', 'semi-detached', 'detached', 'apartment-building', 'terraced', 'terraced-corner', 'villa', 'other', null]).nullable(true).default(null),
        otherwise: (schema) => schema.oneOf(['top-floor', 'ground-floor', 'other-floor', 'high-ground-floor', 'loft', 'two-levels', 'penthouse', 'other', 'souterrain', 'with-balcony', 'other', null]).nullable(true).default(null),
      }),
    streetAddress: string().max(100, 'Street name wíth number may be up to 100 characters long').nullable(true).default(null),
    zip: string().max(10, 'Postal code cannot be longer than 10 characters').required(),
    district: string().max(100, 'District / Town name cannot be longer than 100 characters').nullable(true).default(null),
    city: string().max(100, 'Stadtkreis / Landkreis name cannot be longer than 100 characters').required(),
    countryCode: countryCode.nullable(true).default('DE'),
    interiorLevel: string().oneOf(['simple', 'normal', 'high', 'luxus', null]).nullable(true).default(null),
    condition: string().oneOf(['new', 'newly-renovated', 'well-maintained', 'modernized', 'flexible', 'like-new', 'needs-renovation', 'refurbished', 'completely-renovated', 'needs-repair', null]),
    completeArea: number()
      .min(0, 'Floor area cannot be negative')
      .max(10000, 'Complete floor area cannot be larger than 10 000 sq. m.')
      .transform((value) => (Number.isNaN(value) ? null : value))
      .nullable(true)
      .default(null), // Nutzfläche
    livingArea: number()
      .min(0, 'Floor area cannot be negative')
      .max(10000, 'Living floor area cannot be larger than 10 000 sq. m.')
      .transform((value) => (Number.isNaN(value) ? null : value))
      .required(), // Wohnfläche
    rooms: number().max(1000, 'Room number cannot be larger than 1000').transform((value) => (Number.isNaN(value) ? null : value)).nullable(true).default(null), // handle ""
    lotArea: number()
      .min(0, 'Lot area cannot be negative')
      .max(10000, 'Lot area cannot be larger than 10000')
      .transform((value) => (Number.isNaN(value) ? null : value)) // handles ""
      .nullable(true)
      .default(null),
    floor: number().max(1000, 'Floor number cannot be larger than 1000').transform((value) => (Number.isNaN(value) ? null : value)).nullable(true).default(null), // handle ""
    totalFloors: number().max(1000, 'Total floor number cannot be larger than 1000').transform((value) => (Number.isNaN(value) ? null : value)).nullable(true).default(null), // handle ""
    constructionYear: number().min(-2500, 'Construction year cannot be lower than 2500 BC').max(2100, 'Construction year cannot be higher than 2100').transform((value) => (Number.isNaN(value) ? null : value)).nullable(true).default(null), // handle ""
    lastRenovationYear: number()
      .min(1800, 'last renovation year cannot be lower than 1800 AD')
      .max(2100, 'Construction year cannot be higher than 2100')
      .transform((value) => (Number.isNaN(value) ? null : value))
      .nullable(true)
      .default(null)
      .when(['constructionYear'], {
        is: (constructionYear) => constructionYear !== null,
        then: (schema) => schema.moreThan(ref('constructionYear'), 'If provided, last renovation year must be greater than construction year'), // required by estimata API
      }),
    energyClass: number()
      .transform((value) => (Number.isNaN(value) ? null : value)) // handles ""
      .nullable(true)
      .default(null), // provider wants to see the kWh per m²
    floorHeating: boolean().nullable(true).default(null),
    garden: boolean().nullable(true).default(null),
    heatingType: string().oneOf(['gas', 'oil', 'electricity', 'district', 'wood', 'coal', 'solar', 'block', 'stock', 'floor', 'nachtspeicher', 'wärmekraft', 'central', null]).nullable(true).default(null),
    bathTub: boolean().nullable(true).default(null),
    bathWithWindows: boolean().nullable(true).default(null),
    flooring: string().oneOf(['carpet', 'parquet', 'tiles', 'laminate', 'floorboards', null]).nullable(true).default(null),
    fullHeightWindows: boolean().nullable(true).default(null),
    privateParkingSpaces: number().max(9, 'Private parking spaces are limited to 9').transform((value) => (Number.isNaN(value) ? null : value)).nullable(true).default(null), // handle ""
  }).nullable(false).default({}),
});

const realEstateTransaction = object().shape({
  id: nanoId
    .nullable(true)
    .default(null),
  accountId: nanoId
    .required(),
  date: genericDate,
  amount: number()
    .required()
    .oneOf([-1, 1]), // FIXME link to purchase / sale and v.v.
  price: number()
    .transform((_, val) => (val ? Number(val) : null)) // convert NaN to null
    .min(0, 'Price cannot be negative')
    .max(100000000, 'Price cannot exceed 100 000 000 EUR') // sanity check
    .when('originalPrice', { // if there is no originalPrice, then price is required
      is: (originalPrice) => !originalPrice || originalPrice.length === 0,
      then: (schema) => schema
        .required('Either base currency price or original currency price is required.'),
      otherwise: (schema) => schema.nullable(true).default(null),
    }),
  currency: isoCurrencyCode
    .required(),
  originalPrice: number()
    .transform((_, val) => (val ? Number(val) : null)) // convert NaN to null
    .min(0, 'Price cannot be negative')
    .when('price', { // if there is no price, then originalPrice is required
      is: (price) => !price || price.length === 0,
      then: (schema) => schema
        .required('Either base currency price or original currency price is required.'),
      otherwise: (schema) => schema.nullable(true).default(null),
    }),
  transactionType: string()
    .required('Transaction type is required')
    .oneOf(['purchase', 'sale'], 'Invalid transaction type'),
  valid: boolean()
    .nullable(true)
    .default(true),
  linkedTransactions: array().default([]),
  isSimulated: boolean()
    .nullable(true)
    .default(false),
  isIsolated: boolean()
    .nullable(true)
    .default(false),
  projectId: nanoId
    .nullable(true)
    .default(null),
  importFlag: string() // used by Browser App to mark transactions to be deleted ('delete'); tbc. if also put or post
    .nullable(true)
    .default(null),
  sortingOrderWithinMonth: number()
    .nullable(true)
    .default(null),
  tags: object().shape({
    ...projectTags,
  }),
}, ['price', 'originalPrice']);

const realEstateTransactions = array()
  .of(realEstateTransaction);

const realEstateValuation = object().shape({
  id: nanoId
    .nullable(true)
    .default(null),
  accountId: nanoId
    .required(),
  errorId: string()
    .nullable(true)
    .default(null),
  date: number('Date must be in epoch(ms) format')
    .typeError('Date is invalid or missing')
    .min(999999999999, 'Timestamp must be in epoch(ms) format')
    .required(),
  price: number()
    .transform((_, val) => ((val) ? Number(val) : null)) // convert NaN to null
    .nullable(true)
    .min(0, 'Valuation price cannot be negative')
    .max(100000000, 'Valuation price cannot exceed 100 000 000 EUR') // sanity check
    .when('originalPrice', { // if there is no originalPrice, then price is required
      is: (originalPrice) => !originalPrice || originalPrice.length === 0,
      then: (schema) => schema
        .required('Either base currency price or original currency price is required.')
        .nullable(true),
    }),
  originalPrice: number()
    .transform((_, val) => ((val) ? Number(val) : null)) // convert NaN to null
    .nullable(true)
    .min(0, 'Valuation price cannot be negative')
    .when('price', { // if there is no price, then originalPrice is required
      is: (price) => !price || price.length === 0,
      then: (schema) => schema
        .required('Either base currency price or original currency price is required.')
        .nullable(true),
    }),
  currency: isoCurrencyCode
    .required(), // that means currency calculation via fx service should happen
  source: string()
    .oneOf(['own', 'provider'])
    .required(),
  valid: boolean()
    .required()
    .default(true),
  isSimulated: boolean()
    .nullable(true)
    .default(false),
  projectId: nanoId
    .nullable(true)
    .default(null),
  importFlag: string() // used by Browser App to mark transactions to be deleted ('delete'); tbc. if also put or post
    .nullable(true)
    .default(null),
}, ['price', 'originalPrice']);

const realEstateValuations = array()
  .of(realEstateValuation);

const a = 1;
export default a;

const quote = object().shape({
  date: genericDate,
  assetId: string()
    .meta({ hideFromImport: true })
    .nullable(true) // when users enter transactions, there won't be a figi
    .default(null),
  currency: isoCurrencyCode
    .required('Currency code is required')
    .default('EUR'),
  source: string()
    .meta({ hideFromImport: true })
    .required('Transaction source is required')
    .oneOf(['api', 'manual'], 'Invalid source type'),
  quote: number('Quote must be a number') // original transaction amount (never changes)
    .transform((_, val) => ((val || val === 0) ? Number(val) : null)) // convert NaN to null
    .when('quoteBaseCurrency', { // if there is no quoteBaseCurrency, then quote is required)
      is: (qbc) => (!qbc && qbc !== 0) || qbc.length === 0,
      then: (schema) => schema
        .required('Either transaction currency or base currency quote is required')
        .min(0), // no negative quotes accepted
      otherwise: (schema) => schema.nullable(true).default(null),
    }),
  quoteBaseCurrency: number('QuoteBaseCurrency must be a number') // original transaction amount (never changes)
    .transform((_, val) => ((val || val === 0) ? Number(val) : null)) // convert NaN to null
    .when('quote', { // if there is no quote, then quoteBaseCurrency is required)
      is: (q) => (!q && q !==0) || q.length === 0,
      then: (schema) => schema
        .required('Either transaction currency or base currency quote is required')
        .min(0), // no negative quotes accepted
      otherwise: (schema) => schema.nullable(true).default(null),
    }),
  // specialQuotes can generally be empty, but if they have a record, then that record must be complete
  specialQuotes: array().of(
    object().shape({
      type: string().required('Special quote type is required'),
      quote: number('Quote must be a number')
        .transform((_, val) => ((val) ? Number(val) : null)) // Convert invalid numbers to null
        .required('Quote is required'), // Correct placement of the message
      quoteBaseCurrency: number('Quote base currency must be a number')
        .transform((_, val) => ((val) ? Number(val) : null)), // Convert invalid numbers to null
        // not required, can be calculated in backend
    })
  ).nullable(true).default([]),
  // optional, to store valuation details
  note: string().nullable(true).default(null),
  importFlag: string() // used by Browser App to mark transactions to be deleted ('delete'); tbc. if also put or post
    .meta({ hideFromImport: true })
    .nullable(true)
    .default(null),
}, ['quote', 'quoteBaseCurrency']);

const quotes = array().of(quote);

const attributesWithEffectiveDate = object().shape({
  effectiveDate: number('Effective date must be in epoch(ms) format').required('Effective date is required').max(9999999999999, 'Timestamp must be in epoch(ms) format'),
  value: mixed()
    .test(
      'is-number-or-string',
      'The field must be either a number or a string',
      (value) => typeof value === 'number' || typeof value === 'string',
    ),
});

const loanAccount = object({
  id: nanoId.nullable(true).default(null),
  name: string().required('Account name is required').max(100, 'Account name must be shorter than 50 characters'),
  type: string().oneOf(['annuity', 'fixedPrincipal', 'interestOnly'], 'Invalid loan type').required('Loan type is required').default('annuity'),
  direction: string().oneOf(['granted', 'received'], 'Invalid loan direction').required('Loan granted / received field is required').default('received'),
  loanAmount: number().required('Loan amount is required'),
  currency: isoCurrencyCode.required('Currency code is required').default('EUR'),
  interestRate: array().of(attributesWithEffectiveDate).min(1, 'Interest rate must have at least one value'),
  period: array().of(object().shape({
    effectiveDate: number('Effective date must be in epoch(ms) format').required('Effective date is required').max(9999999999999, 'Timestamp must be in epoch(ms) format'),
    value: string().oneOf(['annual', 'quarter', 'month'], 'Invalid period value').required('Period value is required'),
  })).min(1, 'Period must have at least one value'),
  percentRepaidAnnually: array().of(attributesWithEffectiveDate).min(1, 'Percent repaid annually must have at least one value'),
  contractEndDate: number('Contract end date must be in epoch(ms) format')
    .meta({ dialogFormType: 'date' })
    .nullable(true).default(null).max(9999999999999, 'Timestamp must be in epoch(ms) format'),
  schedulingExceptions: array().of(object().shape({
    date: number('Date must be in epoch(ms) format').required('Date is required').max(9999999999999, 'Timestamp must be in epoch(ms) format'),
    type: string().oneOf(['disabled'], 'Invalid scheduling exception type').required('Scheduling exception type is required'),
  })).nullable(false).default([]),
  interestCalcMonthEnd: boolean().default(true),
  connectedDepositAccounts: array().of(nanoId).nullable(true).default([]),
  connectedProjectId: nanoId.nullable(true).default(null),
  syncType: string().oneOf(['automatic', 'manual']).required().default('manual'),
  iban: string().max(50, 'IBAN must be shorter than 50 characters').nullable(true).default(null),
  bankName: string().max(100, 'Bank name must be shorter than 100 characters').nullable(true).default(null),
  tags: accountTags,
});

const baseTransactionAttributes = {
  id: nanoId // new transactions must exist without an id first
    .nullable(true)
    .default(null)
    .meta({ hideFromImport: true }),
  accountId: nanoId.required().meta({ hideFromImport: true }),
  assetId: string().nullable(true).default(null),
  date: genericDate,
  quantity: number('Quantity must be a number') // always in account currency
    .transform((newValue, originalValue) => {
      if (originalValue === null) return null;
      return Number.isNaN(Number(originalValue)) ? Number(originalValue.replace(/,/, '.')) : newValue;
    })
    .nullable(true)
    .default(null),
  upac: number('Unit price account currency must be a number').required().default(1), // always 1 for cash transactions
  accountCurrency: isoCurrencyCode.meta({ hideFromImport: true }).required('Account currency code is required').default('EUR'), // this is always the same as account currency
  assetCurrency: isoCurrencyCode.meta({ hideFromImport: true }).required('Asset currency code is required').default('EUR'), // this is always the same as account currency
  upbc: number('Unit price account currency must be a number').nullable(true).default(null), // can be calculated in backend
  uptc: number('Unit price account currency must be a number').nullable(true).default(null),
  transactionCurrency: isoCurrencyCode.meta({ hideFromImport: true }).nullable(true).default(null), // this is always the same as account currency
  isSimulated: boolean().meta({ hideFromImport: true }).default(false),
  projectId: nanoId.meta({ hideFromImport: true }).nullable(true).default(null),
  linkedTransactions: array().default([]).meta({ hideFromImport: true }),
  tags: object()
    .meta({ hideFromImport: true })
    .shape({
      assetId: string().nullable(true).default(null), // entered by BlueTransactionDialog, used to link transaction to account and / or asset for dividends, costs etc.
      accountId: string().nullable(true).default(null), // entered by BlueTransactionDialog, used to link transaction to account and / or asset for dividends, costs etc.
      ...projectTags,
      ...providerTags,
    })
    .nullable(true)
    .default(null),
  importFlag: string() // used by Browser App (Grid) to mark changed / added / deleted transactions before sending them to the backend, needs to be on the object
    .meta({ hideFromImport: true })
    .nullable(true)
    .default(null),
  sortingOrderWithinMonth: number().meta({ hideFromImport: true }).nullable(true).default(null),
};

const loanTransaction = object().shape(
  {
    ...baseTransactionAttributes,
    quantity: number('Principal amount must be a number') // loan principal; required for loans (as loans are following the unit price quantity model)
      .transform((newValue, originalValue) => {
        if (originalValue === null) return null;
        return Number.isNaN(Number(originalValue)) ? Number(originalValue.replace(/,/, '.')) : newValue;
      })
      .required(true)
      .default(0),
    quantityInterest: number('Interest amount must be a number') // always in account currency; interest amount
      .transform((newValue, originalValue) => {
        if (originalValue === null) return null;
        return Number.isNaN(Number(originalValue)) ? Number(originalValue.replace(/,/, '.')) : newValue;
      })
      .required(true)
      .default(0),
    otherPartyIban: string() // IBAN
      .max(50, 'Transaction party account must be shorter than 50 characters')
      .nullable(true)
      .default(null),
    description: string().max(1000, 'Transaction description must be shorter than 1000 characters').nullable(true).default(null),
    upac: number('Unit price account currency must be a number').required().default(1), // always 1 (overwrite the base transaction attributes)
  },
);

const loanTransactions = array().of(loanTransaction);

const customQuoteTypesPension = ['payoutOnlyContributionsToDate', 'payoutOnlyContributionsToDateIndexed', 'payoutAllPlannedContributions', 'payoutAllPlannedContributionsIndexed'];

const pensionAccount = object({
  id: nanoId.nullable(true).default(null),
  name: string().required('Account name is required').max(100, 'Account name must be shorter than 100 characters'),
  productPurchaseDate: genericDate.required('Product purchase date is required'), // used to generate the purchase transaction
  currency: isoCurrencyCode.required('Currency code is required').default('EUR'),
  connectedDepositAccounts: array().of(nanoId).nullable(true).default([]),
  contributionsSelfPaid: boolean().nullable(true).default(false), // if false, they are paid by employer and we do not simulate them
  contributionsAmounts: array().of(attributesWithEffectiveDate).min(1, 'Contributions must have at least one value'), // to simulate all future contributions
  contributionsFrequency: number().min(0).oneOf([null, 0, 1, 3, 12]).nullable(true).default(null), // contribution frequency in months; 0 means one-time contribution; oneOf introduced to simplify user's entries
  contributionsDeductible: boolean().nullable(true).default(null),
  payoutsPhaseStart: genericDate.nullable(true).default(null),
  payoutsFrequency: number().min(0).oneOf([null, 0, 1, 3, 12]).nullable(true).default(null), // payout frequency in months; 0 means one-time payout;  oneOf introduced to simplify user's entries
  payoutsDuration: number() // Payout duration in months; 0 means indefinite
  .min(0, 'Payout duration must be at least 0.')
  .nullable(true)
  .default(null)
  .test( // to make calculation of calculatePV in browser-app more straightforward
    'is-divisible',
    'Payout duration must be divisible by payout frequency with no remainder.',
    function(value) {
      const { payoutsFrequency } = this.parent;
      // Ensure payoutsFrequency is not 0 to avoid division by zero
      // Also check that the value is not null and payoutsFrequency is a valid number
      if (value === 0) return true; // if payoutsDuration is 0 (indefnite), then we don't care
      return payoutsFrequency === 0 || value === null || (payoutsFrequency > 0 && value % payoutsFrequency === 0);
    }
  ),
  payoutsTaxable: boolean().nullable(true).default(null),
  payoutsIndexed: boolean().nullable(true).default(null),
  discountRate: number().min(0, 'Discount rate may not be negative').nullable(true).default(null), // in % points; if this is null, then use inflation
  useSpecialQuote: string().oneOf(customQuoteTypesPension).default('payoutAllPlannedContributionsIndexed'),
  initialQuotes: array().of(
    object().shape({
      type: string().oneOf(customQuoteTypesPension).required('Special quote type is required in pensionAccount.initialQuotes'),
      quote: number()
        .transform((_, val) => ((val) ? Number(val) : null)) // Convert invalid numbers to null
        .required('Quote must be a number'), // Correct placement of the message
      quoteBaseCurrency: number()
        .transform((_, val) => ((val) ? Number(val) : null)) // Convert invalid numbers to null
        .required('Quote base currency must be a number'), // Correct placement of the message
    })
  ).nullable(true).default([]),
  tags: accountTags,
});

const pensionTransaction = object().shape(
  {
    ...baseTransactionAttributes,
    description: string().max(1000, 'Transaction description must be shorter than 1000 characters').nullable(true).default(null),
    label: string().oneOf(['pension-contribution', 'pension-payout', 'pension-purchase'], 'Invalid pension transaction label').required('Transaction label is required'),
    isNotKpiRelevant: boolean().default(false), // set to true when the transaction should NOT be counted towards Net Worth, KPIs and reports (as it is the case with contributions and payouts from pension accounts - pension accounts get their value from Present Value)
  },
);

const pensionTransactions = array().of(pensionTransaction);

const objectsOfValueAccount = object({
  id: nanoId.nullable(true).default(null),
  name: string().required('Account name is required').max(100, 'Account name must be shorter than 100 characters'),
  currency: isoCurrencyCode.required('Currency code is required').default('EUR'),
  connectedDepositAccounts: array().of(nanoId).nullable(true).default([]),
  tags: accountTags,
});

const objectsOfValueTransaction = object().shape(
  {
    ...baseTransactionAttributes,
    description: string().max(1000, 'Transaction description must be shorter than 1000 characters').nullable(true).default(null),
    quantity: number('Quantity must be a number')
      .nullable(true)
      .transform((newValue, originalValue) => {
        if (originalValue === null) return null;
        return Number.isNaN(Number(originalValue)) ? Number(originalValue.replace(/,/, '.')) : newValue;
      })
      .required(true)
      .default(1),
    upac: number('Unit price account currency must be a number').nullable(true),
    accountCurrency: isoCurrencyCode.meta({ hideFromImport: true }).required('Account currency code is required').default('EUR'), // this is always the same as account currency
    assetCurrency: isoCurrencyCode.meta({ hideFromImport: true }).required('Asset currency code is required').default('EUR'), // this is always the same as account currency
    upbc: number('Unit price account currency must be a number').nullable(true),
    uptc: number('Unit price account currency must be a number').nullable(true),
    transactionCurrency: isoCurrencyCode.meta({ hideFromImport: true }).nullable(true).default(null), // this is always the same as account currency
  },
).transform((originalValue, originalObject) => {
  const { upac, upbc, uptc } = originalObject;
  if ((upac === null || isNaN(upac)) && (upbc === null || isNaN(upbc)) && (uptc === null || isNaN(uptc))) {
    throw new ValidationError(`At least one of the prices must be a valid number`, originalValue);
  }
  return originalValue;
});
// Comment to the .transform above:
// - this isn't ideal, but when using .when on upac, upbc and uptc, they seem to be build from top, so upac always receives undefined, undefined, which forces it to be required and not nullable, which throws an error

const objectsOfValueTransactions = array().of(objectsOfValueTransaction);

const metalsAccount = object({
  id: nanoId.nullable(true).default(null),
  name: string().required('Account name is required').max(100, 'Account name must be shorter than 100 characters'),
  currency: isoCurrencyCode.required('Currency code is required').default('EUR'),
  connectedDepositAccounts: array().of(nanoId).nullable(true).default([]),
  tags: accountTags,
});

const metalsTransaction = object().shape(
  {
    ...baseTransactionAttributes,
    assetId: string().max(100, 'assetId must be shorter than 100 characters').required('assetId is required'), // done by removing spaces and special characters from the asset name
    assetName: string().max(120, 'assetName must be shorter than 120 characters').required('assetName is required'), // same as displayName in stocks
    assetMetal: string().oneOf(['gold', 'silver', 'platinum', 'palladium', 'other']).required('assetMetal is required').default('gold'),
    assetPurity: number().min(1).max(999).required('assetPurity is required').default(999),
    assetWeight: number().min(1).max(100000).required('assetWeight is required').default(1), // in grams
    assetAdditionalValue: number().min(0).max(1000000).nullable(true).default(null), // in percent
    quantity: number('Quantity must be a number') // of asset
      .nullable(true)
      .transform((newValue, originalValue) => {
        if (originalValue === null) return null;
        return Number.isNaN(Number(originalValue)) ? Number(originalValue.replace(/,/, '.')) : newValue;
      })
      .required(true)
      .default(1),
    upac: number('Unit price account currency must be a number').nullable(true),
    accountCurrency: isoCurrencyCode.meta({ hideFromImport: true }).required('Account currency code is required').default('EUR'), // this is always the same as account currency
    assetCurrency: isoCurrencyCode.meta({ hideFromImport: true }).required('Asset currency code is required').default('EUR'), // this is always the same as account currency
    upbc: number('Unit price base currency must be a number').nullable(true),
    uptc: number('Unit price transaction currency must be a number').nullable(true),
    transactionCurrency: isoCurrencyCode.meta({ hideFromImport: true }).nullable(true).default(null), // this is always the same as account currency
  },
).transform((originalValue, originalObject) => {
  const { upac, upbc, uptc } = originalObject;
  if ((upac === null || isNaN(upac)) && (upbc === null || isNaN(upbc)) && (uptc === null || isNaN(uptc))) {
    throw new ValidationError(`At least one of the prices must be a valid number`, originalValue);
  }
  return originalValue;
});
// Comment to the .transform above:
// - this isn't ideal, but when using .when on upac, upbc and uptc, they seem to be build from top, so upac always receives undefined, undefined, which forces it to be required and not nullable, which throws an error

const metalsTransactions = array().of(metalsTransaction);

const unlistedSharesAccount = object({
  id: nanoId.nullable(true).default(null),
  name: string().required('Account name is required').max(100, 'Account name must be shorter than 100 characters'),
  currency: isoCurrencyCode.required('Currency code is required').default('EUR'),
  // legalForm: string().oneOf(['limited', 'unlimited']).required('Legal form is required').default('limited'), // might be useful for valuations later on --> removing it for now
  connectedDepositAccounts: array().of(nanoId).nullable(true).default([]),
  tags: accountTags,
});

const unlistedSharesTransaction = object().shape(
  {
    ...baseTransactionAttributes,
    upac: number('Unit price account currency must be a number').nullable(true), // allowing null, bec the api can derive upac / upbc from uptc and transactionCurrency
    upbc: number('Unit price base currency must be a number').nullable(true), // allowing null, bec the api can derive upac / upbc from uptc and transactionCurrency
    uptc: number('Unit price transaction currency must be a number').nullable(true), // this isn't always used
  },
).transform((originalValue, originalObject) => {
  const { upac, upbc, uptc } = originalObject;
  if ((upac === null || isNaN(upac)) && (upbc === null || isNaN(upbc)) && (uptc === null || isNaN(uptc))) {
    throw new ValidationError(`At least one of the prices must be a valid number`, originalValue);
  }
  return originalValue;
});
// Comment to the .transform above:
// - this isn't ideal, but when using .when on upac, upbc and uptc, they seem to be build from top, so upac always receives undefined, undefined, which forces it to be required and not nullable, which throws an error

const unlistedSharesTransactions = array().of(unlistedSharesTransaction);

const cryptoAccount = stockAccount;
const cryptoTransaction = stockTransaction;
const cryptoTransactions = stockTransactions;

export {
  depositAccount, depositTransaction, depositTransactions, stockTransaction, stockTransactions, stockAccount, realEstateAccount,
  realEstateValuation, realEstateValuations, realEstateTransaction, realEstateTransactions, quote, quotes,
  loanAccount, loanTransaction, loanTransactions, pensionAccount, pensionTransaction, pensionTransactions, customQuoteTypesPension,
  objectsOfValueAccount, objectsOfValueTransaction, objectsOfValueTransactions,
  metalsAccount, metalsTransaction, metalsTransactions,
  unlistedSharesAccount, unlistedSharesTransaction, unlistedSharesTransactions,
  cryptoAccount, cryptoTransaction, cryptoTransactions,
};

export function getSchemaByCategory(category, type = 'transaction', getArray = false) {
  if (type === 'transaction') {
    switch (category) {
      case 'deposits':
        return getArray ? depositTransactions : depositTransaction;
      case 'stocks':
        return getArray ? stockTransactions : stockTransaction;
      case 'realEstate':
        return getArray ? realEstateTransactions : realEstateTransaction;
      case 'loans':
        return getArray ? loanTransactions : loanTransaction;
      case 'pension':
        return getArray ? pensionTransactions : pensionTransaction;
      case 'objectsOfValue':
        return getArray ? objectsOfValueTransactions : objectsOfValueTransaction;
      case 'metals':
        return getArray ? metalsTransactions : metalsTransaction;
      case 'unlistedShares':
        return getArray ? unlistedSharesTransactions : unlistedSharesTransaction;
      case 'crypto':
        return getArray ? cryptoTransactions : cryptoTransaction;
      default:
        return null;
    }
  }
  if (type === 'account') {
    switch (category) {
      case 'deposits':
        return depositAccount;
      case 'stocks':
        return stockAccount;
      case 'realEstate':
        return realEstateAccount;
      case 'loans':
        return loanAccount;
      case 'pension':
        return pensionAccount;
      case 'objectsOfValue':
        return objectsOfValueAccount;
      case 'metals':
        return metalsAccount;
      case 'unlistedShares':
        return unlistedSharesAccount;
      case 'crypto':
        return cryptoAccount;
      default:
        return null;
    }
  }
  return null;
}
