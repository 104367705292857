/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-no-useless-fragment */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { ArrowTopRightOnSquareIcon, BookmarkIcon, CheckCircleIcon, ChevronUpIcon, ChevronDownIcon, EyeSlashIcon } from '@heroicons/react/24/outline';
import { Disclosure } from '@headlessui/react';
import { updateConsent } from '../../redux/actions/user';
import FullPageOverlayContainer from '../../elements/FullPageOverlayContainer';
import Button from '../../elements/Button';
import cookieList from './cookieList';

dayjs.extend(utc);

const cookieGroups = ['essential', 'analytics', 'externalMedia'];

function MainPane({ handleAcceptAll, consentState, setShowCustomisePane }) {
  const { t } = useTranslation('site');
  const dispatch = useDispatch();

  function handleOnlyEssential() {
    const payload = { ...consentState, essential: dayjs.utc().valueOf() };
    dispatch(updateConsent(payload));
    handleAcceptAll();
  }

  return (
    <div className="px-6 sm:px-0 py-6 sm:py-6">
      <h3 className="text-lg sm:text-xl font-semibold leading-6 text-gray-900">{t('cookies.title')}</h3>
      <div className="mt-8 max-w-2xl text-xs sm:text-sm font-normal text-gray-500 grid grid-cols-12 gap-4 sm:gap-8 items-center">
        <BookmarkIcon className="w-5 h-5 text-brandBlue-500  justify-self-center" />
        <div className="col-span-11">
          <p>{t('cookies.description')}</p>
        </div>
        <p className="w-5 h-5 text-brandBlue-500 text-lg justify-self-center -mt-1 pl-1">§</p>
        <div className="col-span-11">
          <p>{t('cookies.legalBasis')}</p>
        </div>
        <CheckCircleIcon className="w-5 h-5 text-brandBlue-500 justify-self-center " />
        <div className="col-span-11">
          <p>{t('cookies.settingsInfo')}</p>
        </div>
        <EyeSlashIcon className="w-5 h-5 text-brandBlue-500  justify-self-center" />
        <div className="col-span-11">
          <p>{t('cookies.noTracking')}</p>
        </div>
        <ArrowTopRightOnSquareIcon className="w-5 h-5 text-brandBlue-500  justify-self-center" />
        <div className="col-span-11 flex">
          <p>{t('cookies.privacyPolicy')}</p>
          <ArrowTopRightOnSquareIcon className="ml-1 w-5 h-5 text-gray-400  justify-self-center" />
        </div>
      </div>
      <div className="mt-8 flex justify-between space-x-2 sm:space-x-0">
        <div className="flex space-x-2 sm:space-x-4">
          <Button text={t('cookies.onlyEssential')} onClick={handleOnlyEssential} withAccent={false} size="md" />
          <Button text={t('cookies.customise')} onClick={() => setShowCustomisePane(true)} withAccent={false} size="md" />
        </div>
        <Button text={t('cookies.acceptAll')} onClick={handleAcceptAll} withAccent={false} size="md" />
      </div>
    </div>
  );
}
MainPane.propTypes = {
  handleAcceptAll: PropTypes.func.isRequired,
  consentState: PropTypes.shape({
    essential: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([null])]),
    marketing: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([null])]),
    analytics: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([null])]),
    externalMedia: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([null])]),
  }).isRequired,
  setShowCustomisePane: PropTypes.func.isRequired,
};

// local form state is used to prevent the form from closing as soon as one checkbox is ticked
function CustomisePane({ handleAcceptAll, consentState, setShowCustomisePane }) {
  const { t } = useTranslation('site');
  const dispatch = useDispatch();
  const [formState, setFormState] = useState({ essential: null, marketing: null, analytics: null, externalMedia: null });

  // synchronises redux to local form state
  useEffect(() => {
    setFormState(consentState);
  }, [consentState]);

  // puts a mandatory true for essentials
  function getTickboxValue(group) {
    if (group === 'essential') return true;
    return formState[group] !== null;
  }

  // handles save button; this is where essential gets set to true in redux / localStorage
  function handleSave(group, value) {
    const payload = { ...consentState, [group]: value === true ? dayjs.utc().valueOf() : null, essential: dayjs.utc().valueOf() };
    dispatch(updateConsent(payload));
  }

  return (
    <div className="px-6 sm:px-0 py-6 sm:py-6">
      <h3 className="text-xl font-semibold leading-6 text-gray-900">{t('cookies.customiseTitle')}</h3>
      <div className="mt-4 max-w-2xl text-xs sm:text-sm font-normal text-gray-500 grid grid-cols-12 gap-4 items-center">
        <p className="col-span-12">{t('cookies.customiseSubtitle')}</p>
      </div>
      <div className="mx-auto max-w-4xl divide-y divide-gray-900/10">
        <dl className="mt-3 space-y-4 sm:space-y-6 divide-y divide-gray-900/10">
          {cookieGroups.map((group) => (
            <Disclosure as="div" key={group} className="pt-6">
              {({ open }) => (
                <>
                  <dt>
                    <Disclosure.Button className="w-full text-left text-xs sm:text-sm">
                      <div className="mt-2 flex items-start justify-between text-left">
                        <div className="flex space-x-4 items-center">
                          <input
                            type="checkbox"
                            checked={getTickboxValue(group)}
                            onChange={(e) => setFormState({ ...formState, [group]: e.target.checked })}
                            className="h-4 w-4 text-brandBlue-500 focus:ring-brandBlue-400 border-gray-300 rounded"
                          />
                          <div>
                            <p className="font-semibold text-gray-900">{t(`cookies.${group}.label`)}</p>
                            <p className="text-gray-500">{t(`cookies.${group}.description`)}</p>
                          </div>
                        </div>
                        <span className="ml-6 flex h-7 items-center">
                          {open ? <ChevronUpIcon className="h-4 w-4 sm:h-6 sm:w-6" aria-hidden="true" /> : <ChevronDownIcon className="h-4 w-4 sm:h-6 sm:w-6" aria-hidden="true" />}
                        </span>
                      </div>
                    </Disclosure.Button>
                  </dt>
                  <Disclosure.Panel as="dd" className="mt-2 px-2 sm:px-6 text-gray-500 text-sm divide-y divide-gray-900/10">
                    {cookieList
                      .filter((cookie) => cookie.group === group)
                      .map((cookie) => (
                        <div key={cookie.name} className="grid grid-cols-2 sm:grid-cols-4 py-1 text-xs sm:text-sm">
                          <p className="text-gray-700">{cookie.name}</p>
                          <a className="" href={cookie.providerLink} target="_blank" rel="noreferrer">
                            {cookie.providerName}
                          </a>
                          <p className="">{cookie.expiry}</p>
                          <p className="">{cookie.type}</p>
                        </div>
                      ))}
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          ))}
        </dl>
      </div>
      <div className="mt-12 flex justify-between">
        <div className="flex space-x-4">
          <Button text={t('cookies.goBack')} onClick={() => setShowCustomisePane(false)} withAccent={false} size="md" />
          <Button text={t('cookies.acceptAll')} onClick={handleAcceptAll} withAccent={false} size="md" />
        </div>
        <Button text={t('cookies.save')} onClick={handleSave} withAccent={false} size="md" />
      </div>
    </div>
  );
}
CustomisePane.propTypes = {
  handleAcceptAll: PropTypes.func.isRequired,
  consentState: PropTypes.shape({
    essential: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([null])]),
    marketing: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([null])]),
    analytics: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([null])]),
    externalMedia: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([null])]),
  }).isRequired,
  setShowCustomisePane: PropTypes.func.isRequired,
};

export default function CookieBanner({ children }) {
  // this ↓↓ just tells us if there is consent or not, the actual consent object { essential, marketing etc. }
  // is stored in redux - update it there (initialise to true to prevent flash of consent banner)
  const [consent, setConsent] = useState(true);
  const [editMode, setEditMode] = useState(false); // user can go back to editing their consent
  const [showCustomisePane, setShowCustomisePane] = useState(false); // user can customise their consent

  const consentState = useSelector((state) => state.user.consent);
  const dispatch = useDispatch();

  // consent is an object with cookie classes and nulls / dates as value
  // if there is at least one date, it means the user has seen the consent banner already
  const userHasGivenConsent = Object.values(consentState).some((value) => value !== null);

  useEffect(() => {
    if (userHasGivenConsent) {
      setConsent(true);
    } else {
      setConsent(false);
    }
  }, [consentState]);

  // handle "editConsent" document custom event - when it fires, the user wants to edit their consent, so set editMode to true
  useEffect(() => {
    const handleEditConsent = () => {
      setEditMode(true);
    };
    document.addEventListener('editConsent', handleEditConsent);
    return () => {
      document.removeEventListener('editConsent', handleEditConsent);
    };
  }, []);

  // TODO this should not show up in mobile app mode

  function handleAcceptAll() {
    const payload = Object.entries(consentState).reduce((acc, [key]) => ({ ...acc, [key]: dayjs.utc().valueOf() }), {}); // set all groups to current date
    dispatch(updateConsent(payload)); // async, but no need to wait for it
    setConsent(true);
    if (editMode) setEditMode(false);
  }

  if (consent && !editMode) {
    return <>{children}</>;
  }

  return (
    <div>
      {children}
      <FullPageOverlayContainer open={!consent || editMode} verticalCenter variableWidth fixedHeight={showCustomisePane}>
        {showCustomisePane ? (
          <CustomisePane handleAcceptAll={handleAcceptAll} consentState={consentState} setShowCustomisePane={setShowCustomisePane} />
        ) : (
          <MainPane handleAcceptAll={handleAcceptAll} setShowCustomisePane={setShowCustomisePane} consentState={consentState} />
        )}
      </FullPageOverlayContainer>
    </div>
  );
}
CookieBanner.propTypes = {
  children: PropTypes.node.isRequired,
};
