import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { getSchemaByCategory } from '@monestry-dev/schema';
import { postAccount } from '../../redux/reducers/data';
import { postMixedData } from '../../redux/actions/data';
import getQuotes from '../../redux/reducers/sharedThunks/getQuotes';
import { putQuotes } from '../../redux/reducers/quotes';
import { postProject } from '../../redux/actions/projects';
import seedDataInput from '../../pages/seedInitData';
import MiniSpinner from '../../misc/MiniSpinner';

const utc = require('dayjs/plugin/utc');

dayjs.extend(utc);

// takes seedDataInput and dispatches all API calls to seed the demo data
// handles cancellation via cancelSignal
export async function seedDemoData(dispatch, navigate, t, i18n, cancelSignal, promisesManagedByParent, rGetQuotes) {
  // run a few API calls via axios to seed the demo data
  const aaplDate = dayjs.utc().subtract(19, 'months').startOf('day').valueOf();
  const tslaDate = dayjs.utc().subtract(18, 'months').startOf('day').valueOf();

  // eslint-disable-next-line no-param-reassign
  rGetQuotes = await dispatch(
    getQuotes({
      assets: [
        { providerAssetId: 'AAPL', assetId: 'dummyAAAPPPPLLL', currency: 'USD', category: 'stocks' },
        { providerAssetId: 'TSLA', assetId: 'dummyTTSSSSLLAA', currency: 'USD', category: 'stocks' },
      ],
      dates: [aaplDate, tslaDate],
      cancelSignal,
    }),
  );
  const AAPL = rGetQuotes?.payload?.find((q) => q.assetId === 'dummyAAAPPPPLLL' && String(q.date) === String(aaplDate))?.quote;
  // TSLA was split, so the original price needs to be multiplied by 3 (FMP returns rebased quotes only)
  // eslint-disable-next-line no-unsafe-optional-chaining
  const TSLA = rGetQuotes?.payload?.find((q) => q.assetId === 'dummyTTSSSSLLAA' && String(q.date) === String(tslaDate))?.quote * 3;

  const payload = seedDataInput(t); // pass the translation function to have labels translated
  // get quotes from API and set prices in both stocks and deposit transactions
  // stocks
  payload.transactions[0].transactionOriginalPrice = AAPL;
  payload.transactions[1].transactionOriginalPrice = TSLA;
  // deposits
  payload.transactions[3].fxAmount = AAPL * payload.transactions[0].transactionAmount;
  payload.transactions[5].fxAmount = TSLA * payload.transactions[1].transactionAmount;

  // useEffect cleanup sets aborted to true
  // my guess is that when useEffect is executed, all promises land in the macrotask queue at the time of execution
  // when StrictMode re-mounts this component, getQuotes above are running (and are being aborted as planned), but all promises below have been queued and are not running yet
  // eslint-disable-next-line no-param-reassign
  promisesManagedByParent = [];
  if (!cancelSignal.aborted) {
    payload.accounts.forEach((a) => {
      // validate accounts with schema
      const schema = getSchemaByCategory(a.category, 'account');
      try {
        const validationResult = schema.validateSync(a);
      } catch (e) {
        console.error('error when validating schema for', a, e);
      }

      if (!cancelSignal.aborted) promisesManagedByParent.push(dispatch(postAccount({ category: a.category, data: a, cancelSignal })));
      console.log('starting dispatch postAccount for', a, 'aborted is', JSON.stringify(cancelSignal));
    });

    // create all accounts first
    const response1 = await Promise.allSettled(promisesManagedByParent);
    // if any of the promises failed, log the error
    response1.forEach((r) => {
      // response is the RTK output object; if it has error property, it means the promise was rejected
      if (r.error) console.log('error in account promise', JSON.stringify(r.error, null, 2));
    });

    // validate transactions with schema
    payload.transactions.forEach((x) => {
      const schema = getSchemaByCategory(x.category, 'transaction');
      try {
        const validationResult = schema.validateSync(x);
      } catch (e) {
        console.error('error when validating schema for', x, e);
      }
    });

    // reset promises array from parent
    // eslint-disable-next-line no-param-reassign
    promisesManagedByParent = [];
    if (!cancelSignal.aborted) promisesManagedByParent.push(dispatch(postMixedData(payload.transactions)));
    console.log('starting dispatch postAccount for postMixedData', 'aborted is', JSON.stringify(cancelSignal));

    // vv this will only work if quotes are realEstate
    if (!cancelSignal.aborted) {
      promisesManagedByParent.push(
        dispatch(
          putQuotes({
            body: payload.quotes,
            category: 'realEstate',
            accountId: payload.quotes[0].assetId,
          }),
        ),
      );
    }
    console.log('starting dispatch postAccount for quotes 2', 'aborted is', JSON.stringify(cancelSignal));
    if (!cancelSignal.aborted) payload.projects.forEach((p) => promisesManagedByParent.push(dispatch(postProject(p))));
    console.log('starting dispatch postAccount for projects', 'aborted is', JSON.stringify(cancelSignal));

    const response2 = await Promise.allSettled(promisesManagedByParent);
    // if any of the promises failed, log the error
    response2.forEach((r) => {
      if (r.error) console.log('error in transaction / quote promise', JSON.stringify(r.error, null, 2));
    });
  }
}

// this component needs to be bypassed if this is not the first time the user is using the app (i.e. the one after registration)
// store is still in initial state at this stage (because only the Dashboard or Reporting gets data), so that is not helpful
// we will check if user has any tours in his profile, and if yes, redirect away to /app;
// (if the user opened Dashboard at least once, they would have seen at least one tour)

export default function Prep() {
  // upon initialising it should run a 5 second timer and redirect to /app/dashboard
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation(['site'], { keyPrefix: 'register.prep' });
  const [showContent, setShowContent] = useState(false); // so that it does not show content before decision on whether to show this component or not has been taken
  // (federated signIn and signUp only can forward to /getready, not directly to /app/)

  const selectToursSeen = useSelector((state) => state.user.profile.attributes?.toursSeen);

  const ref = React.useRef({ aborted: false });

  useEffect(() => {
    // declare API calls here, so that we may cancel them from the useEffect cleanup
    let rGetQuotes;
    const promises = [];

    // check if the user has been to Dashboard before (if they had, they would have seen at least one tour)
    console.log('Prep: checking if demo data need to be generated, aborted is', JSON.stringify(ref.current));
    if (selectToursSeen && selectToursSeen.length > 0) {
      navigate(`/${i18n.language}/app/dashboard`);
    } else {
      try {
        seedDemoData(dispatch, navigate, t, i18n, ref.current, promises, rGetQuotes).then(() => {
          console.info('Prep > seedDemoData: finished without errors, moving on to Dashboard');
          ref.current = { aborted: true };
          navigate(`/${i18n.language}/app/dashboard`);
        });
      } catch (e) {
        console.error('Error: Prep > seedDemoData', e);
        console.info('Prep > seedDemoData: errors caught, moving on to Dashboard');
        ref.current = { aborted: true };
        navigate(`/${i18n.language}/app/dashboard`);
      }
    }

    // cleanup / abort sequence
    // ATTENTION: this cleanup callback is created on the first render; it will hold on to the variables from that first render (including state!)
    // the only way to pass "current" variables is to "tunnel" them through a ref
    return () => {
      if (typeof rGetQuotes === 'function') rGetQuotes.abort();
      ref.current = { aborted: true };
      promises.forEach((p) => {
        if (typeof p === 'function') p.abort();
      });
    };
  }, []);

  return (
    <div className="relative bg-white">
      <div className="mx-auto lg:grid lg:grid-cols-12 lg:gap-x-8 lg:px-8">
        <div className="px-6 pb-24 pt-10 sm:pb-32 lg:col-span-7 lg:px-0 lg:pb-56 lg:pt-60 lg:pl-24 lg:min-h-screen xl:pt-80 xl:col-span-6">
          <div className="mx-auto max-w-2xl lg:mx-0">
            <h1 className="mt-24 text-4xl font-bold tracking-tight text-gray-900 sm:mt-10 sm:text-6xl">{t('header')}</h1>
            <p className="mt-6 text-lg leading-6 text-gray-600">{t('body')}</p>
            <div className="mt-8 flex items-center gap-x-6">
              <MiniSpinner className="w-8 h-8 text-gray-300 animate-spin" />
            </div>
          </div>
        </div>
        <div className="relative lg:col-span-5 lg:-mr-8 xl:absolute xl:inset-0 xl:left-1/2 xl:mr-0">
          <img
            className="aspect-[3/2] w-full bg-gray-50 object-cover lg:absolute lg:inset-0 lg:aspect-auto lg:h-full"
            src="https://images.unsplash.com/photo-1498758536662-35b82cd15e29?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2102&q=80"
            alt=""
          />
        </div>
      </div>
    </div>
  );
}
