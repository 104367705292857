import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/solid';

// displays a full page overlay with grey blurred background and a closing button
// pass the function meant to close the overlay as closeCallback
// if it needs to keep its height fixed, pass fixedHeight as true
export default function FullPageOverlayContainer({
  children,
  open,
  closeCallback,
  fixedHeight,
  noPadding,
  verticalCenter,
  variableWidth,
  helpCallback,
}) {
  return (
    <Transition.Root show={open} as={Fragment}>
      <div
        id="fullPageOverlayContainerParent"
        // className={`absolute w-full z-50 top-0 left-0 md:p-8 bg-gray-300 bg-opacity-90 space-y-6
        className={`absolute w-full z-50 top-0 left-0 md:p-8 bg-gray-300 bg-opacity-90 space-y-6
        ${verticalCenter ? 'flex items-center justify-center' : ''}
        ${fixedHeight ? 'h-auto min-h-full' : 'h-screen overflow-y-scroll'}`}
        role="dialog"
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
          enterTo="opacity-100 translate-y-0 md:scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 translate-y-0 md:scale-100"
          leaveTo="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
        >
          <div
            className={`relative bg-gray-50 shadow container rounded-sm mx-auto ${
              noPadding ? '' : 'sm:px-6 lg:px-8'
            }
            ${fixedHeight ? 'h-screen md:h-[93vh] overflow-y-auto' : ''}
            ${variableWidth ? 'max-w-3xl' : ''}`}
          >
            <button
              type="button"
              className="hidden sm:inline absolute top-0 right-0 m-4 cursor-pointer"
              onClick={closeCallback}
            >
              <XMarkIcon className="w-6 h-6 text-gray-400 hover:text-gray-500" />
            </button>
            <button
              type="button"
              className="sm:hidden absolute top-0 right-0 m-4 px-3 py-2 cursor-pointer text-gray-700 text-sm font-semibold rounded-md border border-gray-300"
              onClick={closeCallback}
            >
              Close
            </button>
            {children}
          </div>
        </Transition.Child>
      </div>
    </Transition.Root>
  );
}
FullPageOverlayContainer.propTypes = {
  open: PropTypes.bool,
  children: PropTypes.node.isRequired,
  closeCallback: PropTypes.func,
  fixedHeight: PropTypes.bool,
  noPadding: PropTypes.bool,
  verticalCenter: PropTypes.bool,
  variableWidth: PropTypes.bool,
  helpCallback: PropTypes.func,
};
FullPageOverlayContainer.defaultProps = {
  open: true,
  closeCallback: () => {},
  fixedHeight: false,
  noPadding: false,
  verticalCenter: false,
  variableWidth: false,
  helpCallback: () => {},
};
