/* eslint-disable no-unsafe-optional-chaining */

import dayjs from 'dayjs';
import { getDataByAccount, postData, incomeFromCapitalLabels, incomeFromCapitalLabelsExpense, incomeFromCapitalLabelsIncome } from '../../../redux/reducers/data';
import store from '../../../store';
import i18n from '../../../i18n';
// this does not update!

const utc = require('dayjs/plugin/utc');

dayjs.extend(utc);

const debugLevel = 3;

// -----------------------
// INPUT transformations
// -----------------------

// used to put transaction object attributes in the exact order expected by the GRID layout (and the gridLayout.js file which defines the columns in the spreadsheet)
// TABLE will use the same object, but the sequence does not matter (sequence is set in tableLayout.js file)
export function categoryOrderedObject(account = null, displayedComponent = 'table') {
  //  outer function takes parameters and returns a CALLBACK function for .map with parameters already in
  return function unlistedSharesOrdereObjectInnerFunc(transaction) {
    // this has to exactly follow the column order laid out by gridLayout
    const { quantity, uptc, transactionCurrency, upac, accountCurrency, assetId, id } = transaction;
    return {
      date: displayedComponent === 'table' ? Number(transaction.date) : dayjs.utc(Number(transaction.date)).format(), // table expects a number, grid expects a string
      quantity,
      uptc,
      transactionCurrency,
      transactionCurrencyValue: quantity * uptc || 0,
      upac,
      transactionValue: quantity * upac || 0,
      id: transaction.id,
      accountCurrency,
      assetId: transaction.assetId,
      ...(displayedComponent === 'table' && { assetName: transaction.assetName }),
      ...(displayedComponent === 'table' && { isSimulated: transaction.isSimulated }),
    };
  };
}

const depositTransactionLabels = incomeFromCapitalLabels.map((l) => l.split('-')[1]);
const depositIncomeTransactionLabels = incomeFromCapitalLabelsIncome.map((l) => l.split('-')[1]);
const depositExpenseTransactionLabels = incomeFromCapitalLabelsExpense.map((l) => l.split('-')[1]);

// transformations for INPUT
export function applyCategorySpecificChanges(transactions, account = null, displayedComponent = 'table', quotes = []) {
  // <- receives some parameters and
  // GRID is easy
  if (displayedComponent === 'grid') return transactions.map(categoryOrderedObject(account, displayedComponent)); // <- returns an array of objects

  const state = store.getState();
  const baseCurrency = state.user?.profile?.settings?.baseCurrency || 'EUR';

  // if this is a TABLE, we need a more complex transformation
  // 'transactions' for table is a different object: it is the 'unlistedShares.accountId' branch of completeAssetsView
  // described here: https://monestry.atlassian.net/l/cp/t1S1KbTF
  // in other words, an object with keys being accountIds and values being arrays of transactions belonging to that account + all related  deposit transactions sorted ascending by date
  // this is essentially the same code as in stocks (minus the summary row), but as long as field names are not synchronised among asset categories, it needs to be slightly different
  console.log('DEBUG: applyCategorySpecificChanges transactions', transactions, 'quotes', quotes);
  return Object.keys(transactions).reduce((acc, assetId) => {
    // for each assetId: { object }
    const depositTransactionsThisAsset = transactions[assetId].filter((x) => depositTransactionLabels.includes(x.rowType)).map((x) => ({ ...x, payoutOrFeePerPiece: x.quantity / x.positionOnDate }));

    // let's sort out transactions first
    const singleTransactions = transactions[assetId]
      .filter((item) => item.rowType === 'purchase') // leave only purchases which have not been sold yet
      .map((item) => {
        const openPositionSize = item.quantity;
        const purchaseValue = item.quantity * item.upac;
        const currentQuote = quotes[item.assetId]?.current?.quote;
        const currentValue = item.quantity * currentQuote;

        const dividendsPerPieceSincePurchase = depositTransactionsThisAsset // this is in base currency
          .filter((x) => x.date >= item.date && depositIncomeTransactionLabels.includes(x.rowType))
          .reduce((acc2, curr2) => acc2 + curr2.payoutOrFeePerPiece, 0);
        const dividendsSincePurchase = openPositionSize * dividendsPerPieceSincePurchase;

        const thisTransactionFees = depositTransactionsThisAsset
          // has a linkedTransaction which has the same id as the current item
          .filter((x) => depositExpenseTransactionLabels.includes(x.rowType) && x.linkedTransactions.findIndex((lt) => lt.id === item.id) > -1)
          .reduce((acc2, curr2) => acc2 + curr2.quantity, 0);

        const accountCostShareSincePurchase = (transactions[account.id] || []) // account-level cost are not linked to any assetId ([] handle case of no cost transactions)
          .filter((x) => x.date >= item.date && x.rowType === 'costs')
          .reduce((acc2, curr2) => acc2 + curr2.quantity, 0) / Object.keys(transactions).length; // divide equally per assetId within one account

        const fees = thisTransactionFees + accountCostShareSincePurchase;

        return {
          ...item,
          currency: baseCurrency, // display rebased values in base currency
          openPositionSize,
          purchaseValue,
          purchaseQuote: item.upac,
          currentValue,
          currentQuote,
          fees,
          dividendsSincePurchase,
          roi: (currentValue + dividendsSincePurchase + fees - purchaseValue) / purchaseValue,
          roiAnnual: ((currentValue + dividendsSincePurchase + fees) / purchaseValue) ** (1 / ((new Date().valueOf() - item.date) / (365 * 24 * 60 * 60 * 1000))) - 1,
          isSummaryRow: false,
        };
      });

    if (debugLevel > 2) console.log('singleTransactions', singleTransactions);

    // for each assetId in this account we need to calculate the summary row
    let summaryRow = null;
    if (singleTransactions.length > 0) {
      summaryRow = singleTransactions.reduce(
        (prev, curr) => ({
          ...prev,
          openPositionSize: (prev.openPositionSize || 0) + (curr.openPositionSize || 0),
          purchaseValue: (prev.purchaseValue || 0) + curr.purchaseValue,
          currentValue: (prev.currentValue || 0) + curr.currentValue,
          dividendsSincePurchase: (prev.dividendsSincePurchase || 0) + curr.dividendsSincePurchase,
          fees: (prev.fees || 0) + curr.fees,
          numeratorRoiAnnualWeighted: (prev.numeratorRoiAnnualWeighted || 0) + (curr.roiAnnual || 0) * curr.quantityOpen,
          numeratorTransactionPrice: (prev.numeratorTransactionPrice || 0) + (curr.purchaseValue || 0),
          ...(curr.itemHasFlag && { itemHasFlag: curr.itemHasFlag }), // adds itemHasFlag if any of the transactions for this assetId has it
        }),
        {
          date: 1,
          assetId,
          assetName: singleTransactions[0].assetName,
          currentQuote: singleTransactions[0].currentQuote,
        },
      );

      if (debugLevel > 2) console.log('summaryRow', summaryRow);

      summaryRow = {
        ...summaryRow,
        currency: baseCurrency, // display rebased values in base currency
        purchaseQuote: summaryRow.numeratorTransactionPrice / summaryRow.openPositionSize,
        roi: (summaryRow.currentValue + summaryRow.fees - summaryRow.purchaseValue) / summaryRow.purchaseValue,
        roiAnnual: summaryRow.numeratorRoiAnnualWeighted / summaryRow.openPositionSize,
        isSummaryRow: true,
      };
    }

    return [...acc, ...singleTransactions, ...(summaryRow ? [summaryRow] : [])];
  }, []);
}

// ------------------------
// OUTPUT transformations
// ------------------------

// used inside of .map
// performs category-specific transformations after standard transformations of Grid output
// seqeunce of properties is no longer important
// this happens already after items with inputFlag = 'delete' have been added, so just take care of 'put'
// the original transaction has been spread here so all its paramters are available
export function outputTransformCategoryTransactions(account = null) {
  //  outer function takes a parameter returns a CALLBACK function for .map with that parameter already in
  return function outputTransformCategoryTransactionsInnerFunc(item) {
    // if transactionValue / transactionCurrencyValue is provided, calculate upac / uptc
    return {
      ...item,
      upac: item.upac || (item.transactionValue && item.quantity ? item.transactionValue / item.quantity : null),
      uptc: item.uptc || (item.transactionCurrencyValue && item.quantity ? item.transactionCurrencyValue / item.quantity : null),
      transactionCurrency: item.transactionCurrency || null,
      accountCurrency: account.currency,
      assetCurrency: account.currency,
      assetId: item.assetName.replace(/[^a-zA-Z0-9]/g, ''), // also used in globalSelectors/unlistedShares/index
    };
  };
}

// additional validations which need to happen after schema validation
// receives all the input data from Grid, including deleted ones; those that have been changed have importFlag
export function additionalValidations(transactions) {
  // make sure we don't sell items that we do not have in this account
  // i.e. if

  // make sure we are not selling more than we own

  // take validatedData and calculate cumulative amount of unlistedShares owned
  // sort by date ascending, then by transactionType ascending (so that purchase on the same day comes before sale)
  console.log('additionalValidations', transactions);
  let quantityBySymbol = 0;
  let skipToNextSymbol = false;
  const returnErrors = [];
  const dataForMetalsValidation = (transactions || [])
    .map((item, idx) => ({ ...item, originalRowNumber: idx })) // add original row numbers so that we know where to show an error message if there is one
    .sort((a, b) => {
      // sort by symbol ascending
      if (a.assetId < b.assetId) return -1;
      if (a.assetId > b.assetId) return 1;

      // if symbols are equal, sort by date ascending
      if (a.date < b.date) return -1;
      if (a.date > b.date) return 1;

      // if dates are equal, sort positive before negative
      return b.quantity - a.quantity;
    });

  for (let idx = 0; idx < dataForMetalsValidation.length; idx += 1) {
    const prev = dataForMetalsValidation[idx - 1];
    const curr = dataForMetalsValidation[idx];
    // go through the sorted array top to bottom (it's sorted by symbol)
    // if curr has a different symbol than prev, reset amount
    if (idx > 0 && prev.symbol !== curr.symbol) {
      skipToNextSymbol = false;
      quantityBySymbol = 0;
    }
    // if previous iteration set this flag to true, it will skip to the next transaction
    // until a new symbol is introduced
    if (skipToNextSymbol === false) {
      // add this iteration's amount to the amount for the current symbol
      quantityBySymbol += curr.quantity || 0;
      // if prev becomes negative, signal a validation error and add error to validationErrors
      // cannot break loop, because there will be more symbols (potentially)
      if (quantityBySymbol < 0) {
        console.error('The amount of securities has turned negative. You cannot own negative securities. See blog on how to deal with short transactions.');
        returnErrors.push({
          message: 'The amount of securities has turned negative. You cannot own negative securities. See blog on how to deal with short transactions.',
          path: `[${curr.originalRowNumber}].quantity`,
        });
        // we want this to only show up at the first offending transaction
        // to let user sort it out and not confuse them with many errors
        // the checking of remaining transactions for the same symbol is skipped
        skipToNextSymbol = true;
      }
    }
  }
  return returnErrors;
}

// Grid handles dispatch and its result; this is just the action creator for post transactions
export function postCategoryItems(data, account) {
  return postData({ data, category: 'unlistedShares', accountId: account.id });
}

// TABLE

// used in table to get transactions from backend
export function handleSync(accountId, dispatch) {
  dispatch(getDataByAccount({ category: 'unlistedShares', accountId }));
}

function compare(a, b, propertyName, asc = true) {
  const multiplier = asc ? 1 : -1;
  // so that it doesn't sort capital letters separately from lowercase
  const elementA = typeof a[propertyName] === 'number' ? a[propertyName] : a[propertyName].toLowerCase();
  const elementB = typeof b[propertyName] === 'number' ? b[propertyName] : b[propertyName].toLowerCase();

  if (elementA < elementB) {
    return -1 * multiplier;
  }
  if (elementA > elementB) {
    return 1 * multiplier;
  }
  return 0;
}

export function getSortingCallback(tableState) {
  return function sortingCallback(a, b) {
    // reverse sorting order if date is selected a
    // localeCompare compares strings according to rules of the current locale, returns -1, 0, 1
    try {
      let compareFigi = a.assetId.localeCompare(b.assetId);
      if (tableState.sortBy === 'date' && !tableState.sortDirectionAsc) {
        compareFigi = -a.assetId.localeCompare(b.assetId);
      }
      // sort by symbol, then sort the total row first and then sort the rest by the column
      return compareFigi || !!b.isSummaryRow - !!a.isSummaryRow || compare(a, b, tableState.sortBy, tableState.sortDirectionAsc);
    } catch (e) {
      if (debugLevel > 2) console.log('error in getSortingCallback', e, 'tableState', tableState, 'a', a, 'b', b);
      return 0;
    }
  };
}
