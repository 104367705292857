import React from 'react';
import MonestrySpinner from '../assets/MonestrySpinner';

function Spinner() {
  return (
    <div className="bg-white fixed top-0 left-0 right-0 h-screen z-50 grid place-content-center">
      <div className="w-16">
        <MonestrySpinner width="75" height="75" viewBox="0 0 600 600" />
      </div>
    </div>
  );
}

export default Spinner;
