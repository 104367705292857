/* eslint-disable no-unsafe-optional-chaining */

import { createSelector } from '@reduxjs/toolkit';
import * as globals from '..';
import { applyFIFOOnCategory } from '../../../../misc/arrayHelpers';

// returns an array with all transactions (real or real+simulated) up to the Slider Date (baseDate) or to today (depending on dashboardMode in state)
// no isolated transactions are included
// apply FIFO to calculate quantityOpen
export const cryptoTransactions = createSelector(
  (state) => state.simulation?.dashboardMode,
  (state) => state.data?.crypto.transactions,
  (state) => state.data?.crypto.simulatedTransactions,
  (state) => state.simulation?.baseDate,
  (state) => state.projects,
  (state) => state.user?.profile.settings.inflationRate,
  (state) => state.user?.profile.settings.sliderEndDate,
  (dashboardMode, transactions, simulatedTransactions, baseDate, projects, inflationRate, sliderMax) => {
    let returnTransactions;
    if (dashboardMode === 'projects') {
      const isolatedProjects = projects?.filter((project) => project.settings?.isIsolated).map((project) => project.id);
      const simulatedTransactionsWithoutIsolated = simulatedTransactions.filter((txn) => !isolatedProjects.includes(txn.projectId));

      returnTransactions = (transactions || [])
        .concat(simulatedTransactionsWithoutIsolated)
        .filter((transaction) => transaction.date <= baseDate)
        // unpack recurring transactions by creating transactions for every recurring transaction (never = until the end of slider)
        .flatMap((txn) => globals.unpackRecurringTransactionsCore(inflationRate, baseDate, sliderMax, txn));
    } else {
      returnTransactions = transactions || [];
    }
    return applyFIFOOnCategory(returnTransactions);
  },
);

// returns an array with all transactions (simulated and real) up to the Simulation End Date (slider end)
// including all isolated transactions
export const cryptoTransactionsProjectView = createSelector(
  (state) => state.simulation?.dashboardMode,
  (state) => state.data?.crypto.transactions,
  (state) => state.data?.crypto.simulatedTransactions,
  (state) => state.simulation.baseDate,
  (state) => state.user?.profile.settings.inflationRate,
  (state) => state.user?.profile.settings.sliderEndDate,
  (dashboardMode, transactions, simulatedTransactions, baseDate, inflationRate, sliderMax) => {
    let returnTransactions;
    if (dashboardMode === 'projects') {
      returnTransactions = (transactions || [])
        .concat(simulatedTransactions)
        // unpack recurring transactions by creating transactions for every recurring transaction (never = until the end of slider)
        .flatMap((txn) => globals.unpackRecurringTransactionsCore(inflationRate, baseDate, sliderMax, txn));
      // no recurring transactions in real estate
    } else {
      returnTransactions = transactions || [];
    }
    return applyFIFOOnCategory(returnTransactions);
  },
);

export function getCryptoAssetId(assetName) {
  // only leave letters and numbers, strip and trim everything else
  return assetName.replace(/[^a-zA-Z0-9]/g, '');
}

// provides a figi-displaySymbol-providerAssetId mapping dictionaries
// do not use cryptoGlobalTransactionView, because if this is run from a component in dashboard mode,
// it won't have any simulated transactions, so it won't have any figis for asset only existing in projects
// this can read directly from state, because it only provides assetId translation (and that does not change through recurring / isolated transactions etc.)
export const cryptoMetadata = createSelector(
  (state) => state.data.crypto.transactions,
  (state) => state.data.crypto.simulatedTransactions,
  ($transactions, $simulatedTransactions) => {
    const displaySymbolByAssetId = {};
    const assetIdByDisplaySymbol = {};
    const providerAssetIdByDisplaySymbol = {};
    const displayNameByDisplaySymbol = {};

    // include all transactions (also those beyond the slider date)
    ($transactions.concat($simulatedTransactions) || []).forEach((transaction) => {
      const { assetId, displaySymbol, displayName, providerAssetId } = transaction;

      // before updating check if the updated value is null (and do not update if it is)

      // Update assetId object
      if (!displaySymbolByAssetId[assetId] || displaySymbol) displaySymbolByAssetId[assetId] = displaySymbol;

      // Update displaySymbols object
      if (!assetIdByDisplaySymbol[displaySymbol] || assetId) assetIdByDisplaySymbol[displaySymbol] = assetId;

      // Update displaySymbols object
      if (!providerAssetIdByDisplaySymbol[displaySymbol] || providerAssetId) providerAssetIdByDisplaySymbol[displaySymbol] = providerAssetId;

      // Update displayNames object
      if (!displayNameByDisplaySymbol[displaySymbol] || displayName) displayNameByDisplaySymbol[displaySymbol] = displayName;
    });

    // displaySymbolByAssetId: { assetId1: displaySymbol1, assetId2: displaySymbol2, ... }
    // assetIdByDisplaySymbol: { displaySymbol1: figi1, displaySymbol2: figi2, ... }
    // providerAssetIdByDisplaySymbol: { displaySymbol1: providerAssetId1, displaySymbol2: providerAssetId2, ... }
    // displayNameByDisplaySymbol: { displaySymbol1: displayName1, displaySymbol2: displayName2, ... }
    return {
      displaySymbolByAssetId,
      assetIdByDisplaySymbol,
      providerAssetIdByDisplaySymbol,
      displayNameByDisplaySymbol,
    };
  },
);
