/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { PencilIcon } from '@heroicons/react/24/outline';
import { ShareIcon, PencilIcon as SolidPencilIcon, DocumentDuplicateIcon, EllipsisVerticalIcon } from '@heroicons/react/20/solid';
import { Transition, Menu } from '@headlessui/react';
import { isMobile } from 'react-device-detect';
import { setMessage } from '../redux/actions/message';
import { postSharedReport } from '../redux/reducers/viewer';
import { SpinnerRight } from '../assets/mdiIcons';

function ShareReportConfirmation({ url, passcode }) {
  const { t, i18n } = useTranslation('app', { keyPrefix: 'dashboard' });
  const { share } = navigator; // navigator API, share calls up the >Share< menu on a mobile device

  async function handleClick(e, value) {
    if (share && isMobile) {
      try {
        await navigator.share({
          title: t('title'),
          text: value,
          url: value,
        });
        console.log('Successfully shared');
      } catch (error) {
        console.error('Error sharing:', error);
      }
    } else {
      // copy invitationText to clipboard and set copied to true
      navigator.clipboard.writeText(value);
    }
  }

  return (
    <div className="flex flex-col">
      <div className="text-center">
        <div className="mt-2 space-y-4">
          <p className="text-sm text-gray-500">{t('shareReport.confirmation.copyBoth')}</p>
          <button type="button" className="mx-auto flex flex-col justify-center items-center text-brandBlue-500 text-sm" onClick={(e) => handleClick(e, url)}>
            <p>{`https://www.monestry.de/${i18n.language}/shared/`}</p>
            <p>{url.split('/').at(-1)}</p>
            <div className="mt-2 w-24 flex justify-center items-center rounded-md shadow-sm space-x-2 text-sm px-1.5 py-1 font-sm cursor-pointer bg-white hover:bg-gray-50 text-brandBlue-500 border border-brandBlue-400">
              <DocumentDuplicateIcon className="h-4 w-4 inline-block" />
              <span>{t('shareReport.confirmation.copy')}</span>
            </div>
          </button>
          <p className="text-sm text-gray-500">{t('shareReport.confirmation.securityHint')}</p>
          <button type="button" className="text-brandBlue-500 text-base" onClick={(e) => handleClick(e, passcode)}>
            <span className="text-brandBlue-500">{passcode}</span>
            <div className="mt-2 flex justify-center items-center rounded-md shadow-sm space-x-2 text-sm px-1.5 py-1 font-sm cursor-pointer bg-white hover:bg-gray-50 text-brandBlue-500 border border-brandBlue-400">
              <DocumentDuplicateIcon className="h-4 w-4 inline-block" />
              <span>{t('shareReport.confirmation.copy')}</span>
            </div>
          </button>
        </div>
      </div>
    </div>
  );
}
ShareReportConfirmation.propTypes = {
  url: PropTypes.string.isRequired,
  passcode: PropTypes.string.isRequired,
};

// INFO: pressing the >Share< button will bring up the DIALOG first
// the user can press >Next< there to upload the report data and generate the link
// after which the DIALOG will close and update the passcode state of this component
// passcode update will trigger another useEffect which will display an alert with the link
// and the passcode so that they both can be copied

export default function EditSync({ editMode, setEditMode, getAllData, mode }) {
  const { t, i18n } = useTranslation('app', { keyPrefix: 'dashboard' });
  const [loading, setLoading] = useState(false);
  const [reportFilename, setReportFilename] = useState(undefined);
  const [passcode, setPasscode] = useState(undefined);

  const dispatch = useDispatch();

  async function handleSync(e) {
    setLoading(true);
    await getAllData();
    setLoading(false);
  }

  // ---------------------------------------------------------
  // initiate DIALOG after pressing >Share< button
  // ---------------------------------------------------------

  function str() {
    return Math.floor(100 + Math.random() * 900).toString();
  }

  async function dispatchPostReport(value) {
    const result = await dispatch(postSharedReport({ passcode: value }));
    if (result?.error) {
      console.error('error in postSharedReport', result?.error);
      dispatch(setMessage('generalError'));
    } else {
      setReportFilename(result?.payload);
      setPasscode(value); // this will trigger the useEffect below and show the ALERT
      console.log('result of postSharedReport', JSON.stringify(result, null, 2));
    }
  }

  async function handleShare(e) {
    // display an alert, put the dispatch postSharedReport into callbackOk
    window.dispatchEvent(
      new CustomEvent('setDialog', {
        detail: {
          header: t('shareReport.header'),
          prompt: t('shareReport.tooltip'),
          value: `${str()}-${str()}-${str()}`,
          callback: dispatchPostReport,
          shareReport: true,
        },
      }),
    );
  }

  // ---------------------------------------------------------
  // intitiate ALERT after passcode is set
  // ---------------------------------------------------------

  useEffect(() => {
    if (passcode) {
      window.dispatchEvent(
        new CustomEvent('setAlert', {
          detail: {
            id: 'shareReportLinkSuccess',
            Component: ShareReportConfirmation,
            componentProps: { url: `https://www.monestry.de/${i18n.language}/share/${reportFilename.split('.')[0]}`, passcode },
            callbackOK: () => {},
          },
        }),
      );
    }
  }, [passcode]);

  return (
    <>
      {/* BELOW XS RESOLUTION - show ellipsis menu */}

      <div className="xs:hidden only:col-span-1 self-center">
        <Menu as="div" className="relative inline-block text-left">
          <Menu.Button className="bg-none inline-flex items-center -mb-1 px-2 py-2" id="edit-sync-low-res-ellipsis-button">
            <EllipsisVerticalIcon className="h-5 w-5 text-gray-400 lg:text-white" aria-hidden="true" />
          </Menu.Button>
          <Transition
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items static className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="py-1">
                <Menu.Item>
                  {({ active }) => (
                    <button type="button" className={`block px-4 py-2 text-sm text-gray-700 w-full text-left ${active ? 'bg-gray-100' : ''}`} onClick={(e) => setEditMode(!editMode)}>
                      {editMode ? t('done') : t('edit')}
                    </button>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <button type="button" disabled={editMode} className={`block px-4 py-2 text-sm text-gray-700 w-full text-left ${active ? 'bg-gray-100' : ''}`} onClick={handleSync}>
                      Sync
                    </button>
                  )}
                </Menu.Item>
                {mode === 'dashboard' && (
                  <Menu.Item>
                    {({ active }) => (
                      <button type="button" disabled={editMode} className={`block px-4 py-2 text-sm text-gray-700 w-full text-left ${active ? 'bg-gray-100' : ''}`} onClick={handleShare}>
                        {t('share')}
                      </button>
                    )}
                  </Menu.Item>
                )}
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>

      {/* ABOVE XS RESOLUTION */}

      <div className="hidden xs:flex p-4 col-span-1 space-x-4 self-center sm:justify-self-end">
        <button
          id="edit-mode"
          type="button"
          className={`${editMode ? 'bg-gray-200 lg:bg-white lg:bg-opacity-30' : 'bg-none hover:bg-white hover:bg-opacity-10'}
            inline-flex items-center -mb-1 px-2 sm:px-4 py-2 rounded-md shadow-sm text-sm font-semibold text-gray-400 lg:text-white`}
          onClick={(e) => setEditMode(!editMode)}
        >
          {editMode ? (
            <SolidPencilIcon className="sm:-ml-1 sm:mr-2 h-4 w-4 text-gray-400 lg:text-white" aria-hidden="true" />
          ) : (
            <PencilIcon className="sm:-ml-1 sm:mr-2 h-4 w-4 text-gray-400 lg:text-white" aria-hidden="true" />
          )}
          <span className="hidden sm:inline">{t('edit')}</span>
        </button>
        <button
          type="button"
          id="sync-all-button"
          disabled={editMode}
          onClick={handleSync}
          // eslint-disable-next-line max-len
          className={`bg-none hover:bg-white hover:bg-opacity-10 inline-flex items-center -mb-1 px-2 sm:px-4 py-2 lg:text-white
          rounded-md shadow-sm text-sm font-semibold text-gray-400`}
        >
          <SpinnerRight className={`sm:-ml-1 sm:mr-2 h-4 w-4 text-gray-400 lg:text-white ${loading ? 'animate-spin' : ''}`} aria-hidden="true" />
          <span className="hidden sm:inline">Sync</span>
        </button>
        {mode === 'dashboard' && (
          <button
            type="button"
            id="share-button"
            disabled={editMode}
            onClick={handleShare}
            // eslint-disable-next-line max-len
            className="bg-none hover:bg-white hover:bg-opacity-10 inline-flex items-center -mb-1 px-2 sm:px-4 py-2 rounded-md shadow-sm text-sm font-semibold text-gray-400 lg:text-white"
          >
            <ShareIcon className="sm:-ml-1 sm:mr-2 h-4 w-4 text-gray-400 lg:text-white" aria-hidden="true" />
            <span className="hidden sm:inline">{t('share')}</span>
          </button>
        )}
      </div>
    </>
  );
}
EditSync.propTypes = {
  editMode: PropTypes.bool.isRequired,
  setEditMode: PropTypes.func.isRequired,
  getAllData: PropTypes.func.isRequired,
  mode: PropTypes.string.isRequired,
};
