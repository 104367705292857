/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import PropTypes, { array } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { CheckIcon } from '@heroicons/react/24/outline';
import PaymentTypeButton from '../../elements/PaymentTypeButton';
import PricingComponent from './PricingComponent';
import getProducts from '../../misc/products';

// order of this array matters (they will be displayed in this order, filtered by billingFrequency)
const products = getProducts();

export default function SignUp2({ formState, formSetState, goForward, goBack }) {
  const { i18n, t } = useTranslation(['site']);

  function handleClick(tierId, tierType, frequency) {
    const annualBilling = frequency === 'annually';
    const displayProducts = products.filter((product) => product.billingFrequency === (annualBilling ? 'annually' : 'monthly'));
    const selectedProduct = displayProducts.find((product) => product.tierId === tierId);
    // move billing day to a day that exists in all months
    const billingDayOfMonth = new Date().getDate() > 28 ? 28 : new Date().getDate();

    formSetState({
      ...formState,
      tierType: selectedProduct.type,
      tierId: selectedProduct.tierId,
      annualBilling,
      stripePriceId: selectedProduct.stripePriceId,
      billingPerMonth: selectedProduct.monthlyPrice,
      billingDayOfMonth,
    });
    goForward();
  }

  const format = { minimumFractionDigits: 2, maximumFractionDigits: 2 };

  return (
    <div className="max-w-7xl mx-auto px-4 mt-12 sm:px-6 lg:px-8">
      {/* eslint-disable-next-line react/jsx-no-bind */}
      <PricingComponent buttonFunction={handleClick} selectedTier={formState.tierId} />
      <div className="flex justify-center mt-8">
        <button type="button" className="border border-brandBlue-400 rounded-md text-brandBlue-400 text-sm font-semibold hover:bg-gray-50 py-2 px-6" onClick={goBack}>
          {t('register.goBack')}
        </button>
      </div>
    </div>
  );
}
SignUp2.propTypes = {
  goForward: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  formState: PropTypes.objectOf(PropTypes.any).isRequired,
  formSetState: PropTypes.func.isRequired,
};
