import {
  GET_PROJECTS_SUCCESS,
  GET_PROJECTS_FAIL,
  SET_MESSAGE,
  POST_PROJECT_SUCCESS,
  POST_PROJECT_FAIL,
  UPDATE_PROJECT_SUCCESS,
  DELETE_PROJECT_SUCCESS,
  SET_APP_SIGNAL,
  CLEAR_APP_SIGNAL,
} from './types';

import ProjectsServices from '../services/projects.service';
// eslint-disable-next-line import/no-cycle
import { postMixedData } from './data';
import { allTransactionsProjectView } from '../reducers/data';

export function getProjects() {
  return async function getProjectsThunk(dispatch) {
    let data;
    try {
      data = await ProjectsServices.getData();

      dispatch({
        type: GET_PROJECTS_SUCCESS,
        payload: data,
      });

      return;
    } catch (error) {
      const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

      console.log(message);

      dispatch({
        type: GET_PROJECTS_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: { message },
      });
    }
  };
}

// expects the Project object as payload (NOT array)
export const postProject = (payload) => (dispatch, getState) => {
  // get a snapshot of current Project object in case of API call fails and we will need to roll it back
  const prevState = getState().projects.filter((p) => p.id === payload.id);

  dispatch({
    type: POST_PROJECT_SUCCESS,
    payload,
  });

  // if the thing being changed
  dispatch({
    type: SET_APP_SIGNAL,
    payload: { callerId: payload.id, message: 'projectPostPutFinished' },
  });

  ProjectsServices.postData(payload).then(
    (data) => Promise.resolve(),
    (error) => {
      const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

      console.log(message);

      // revert state to previous state
      dispatch({
        type: POST_PROJECT_FAIL,
        payload: prevState,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: { message: 'dataUpdateError' },
      });

      return Promise.reject();
    },
  );
};

// expects the Project object as payload (NOT array)
// returns a promise, so that the dispatch(deleteProject()) can be chained with .then()
// and used to close the ProjectDetails component (evtl. remove the Spinner if deemed necessary)
export const deleteProject = (payload) => (dispatch, getState) => {
  dispatch({
    type: 'SET_APP_SIGNAL',
    payload: { callerId: payload.id, message: 'deleteProject' },
  });

  return Promise.resolve(
    ProjectsServices.deleteData(payload).then(
      // eslint-disable-next-line arrow-body-style
      (data) => {
        dispatch({
          type: CLEAR_APP_SIGNAL,
          payload: { callerId: payload.id },
        });

        // get a list of all transactions with projectId from allTransactionsProjectView (to also get transactions beyond current slider date)
        const transactions = allTransactionsProjectView(getState()).filter((t) => t.projectId === payload.id);

        // dispatch postMixedData with importFlag of 'delete' for all transactions
        dispatch(postMixedData(transactions.map((t) => ({ ...t, importFlag: 'delete' }))));

        return dispatch({
          type: DELETE_PROJECT_SUCCESS,
          payload,
        });
      },
      (error) => {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

        console.error(message);

        dispatch({
          type: CLEAR_APP_SIGNAL,
          payload: { callerId: payload.id },
        });

        return dispatch({
          type: SET_MESSAGE,
          payload: { message: 'projectUpdateError' },
        });
      },
    ),
  );
};

// expects FULL Project object as payload (NOT array)
export const putProject = postProject;
