import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/24/solid';
import { Listbox } from '@headlessui/react';
import { putProject } from '../../redux/actions/projects';

export default function ProgressWidgetCombobox({ project }) {
  const selectedType = project?.settings?.progressIndicator;
  const [query, setQuery] = useState('');
  const { t } = useTranslation('app');

  const dispatch = useDispatch();

  const widgetTypes = [
    { type: 'roi', label: t('projects.roi') },
    { type: 'duration', label: t('projects.duration') },
    { type: 'goal', label: t('projects.goal') },
  ];

  const filteredValues = query === ''
    ? widgetTypes
    : widgetTypes.filter((x) => x.label.toUpperCase().includes(query.toUpperCase()));

  return (
    <Listbox
      as="div"
      value={selectedType}
      onChange={(e) => dispatch(
        putProject({ ...project, settings: { ...project.settings, progressIndicator: e.type } }),
      )}
    >
      <Listbox.Label className="block text-sm font-medium text-gray-700">{t('projects.progressIndicator')}</Listbox.Label>
      <div className="relative mt-1.5 w-24">
        <Listbox.Button
          className="w-24 text-left rounded-md border border-gray-300 bg-white py-1 pl-2 pr-5 shadow-sm focus:border-brandBlue-400 focus:outline-none focus:ring-1 focus:ring-brandBlue-400 sm:text-xs"
          onChange={(event) => setQuery(event.target.value)}
        >
          {widgetTypes.find((x) => x.type === selectedType).label}
          <div className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
            <ChevronDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </div>
        </Listbox.Button>

        {filteredValues.length > 0 && (
          <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-sm shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-xs">
            {filteredValues.map((x) => (
              <Listbox.Option
                key={x}
                value={x}
                className={({ active }) => `relative cursor-default select-none py-2 pl-2 pr-4 ${active ? 'bg-brandBlue-600 text-white' : 'text-gray-900'}`}
              >
                {({ active, selected }) => (
                  <>
                    <span className={`block truncate ${selected && 'font-semibold'}`}>{x.label}</span>

                    {selected && (
                      <span
                        className={`absolute inset-y-0 left-0 flex items-center pl-1.5 ${active ? 'text-white' : 'text-brandBlue-600'}`}
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        )}
      </div>
    </Listbox>
  );
}
ProgressWidgetCombobox.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  project: PropTypes.objectOf(PropTypes.any).isRequired,
};
