/* eslint-disable max-len */
const datenschutz = `## 1. Data Protection at a Glance

### General Information
The following information provides a simple overview of what happens to your personal data when you visit this website. Personal data is any data that can personally identify you. Detailed information on data protection can be found in our [privacy policy](link-to-privacy-policy) listed below this text.

### Data Collection on this Website

#### Who is responsible for data collection on this website?

The data processing on this website is carried out by the website operator. You can find their contact details in the "Controller's Contact Information" section of this [privacy policy](link-to-privacy-policy).

#### How do we collect your data?

Your data is collected in part by you providing it to us. This can include data you enter into a contact form, for example.

Other data is automatically collected or with your consent when you visit the website through our IT systems. These are primarily technical data (e.g., internet browser, operating system, or time of page access). This data is collected automatically as soon as you enter this website.

#### What do we use your data for?

Some of the data is collected to ensure the proper functioning of the website. Other data may be used for analyzing your user behavior.

#### What rights do you have regarding your data?

You have the right to receive information about the origin, recipient, and purpose of your stored personal data at any time free of charge. You also have the right to request the correction or deletion of this data. If you have given consent to data processing, you can revoke this consent at any time for the future. Furthermore, you have the right to request the restriction of the processing of your personal data under certain circumstances. You also have the right to lodge a complaint with the competent supervisory authority.

For this and other questions regarding data protection, you can contact us at any time.

### Analysis Tools and Third-Party Tools

When visiting this website, your surfing behavior may be statistically evaluated. This is primarily done with so-called analysis programs.

Detailed information about these analysis programs can be found in the following privacy policy.

## 2. Hosting

We host the content of our website with the following provider:

### Amazon Web Services (AWS)

The provider is Amazon Web Services EMEA SARL, 38 Avenue John F. Kennedy, 1855 Luxembourg (hereinafter AWS).

When you visit our website, your personal data is processed on AWS servers. Personal data may also be transferred to AWS's parent company in the USA. The data transfer to the USA is based on EU Standard Contractual Clauses. Details can be found here: [https://aws.amazon.com/de/blogs/security/aws-gdpr-data-processing-addendum/](https://aws.amazon.com/de/blogs/security/aws-gdpr-data-processing-addendum/).

For further information, please refer to AWS's privacy policy: [https://aws.amazon.com/de/privacy/?nc1=f_pr](https://aws.amazon.com/de/privacy/?nc1=f_pr).

The use of AWS is based on Article 6(1)(f) of the GDPR. We have a legitimate interest in providing a reliable presentation of our website. If consent is requested, the processing is carried out exclusively based on Article 6(1)(a) of the GDPR and § 25(1) TTDSG, insofar as the consent includes the storage of cookies or access to information on the user's device (e.g., Device-Fingerprinting) as defined by TTDSG. Consent can be revoked at any time.

The company is certified under the "EU-US Data Privacy Framework" (DPF). The DPF is an agreement between the European Union and the USA aimed at ensuring compliance with European data protection standards for data processing in the USA. Every company certified under the DPF commits to adhering to these data protection standards. Further information can be obtained from the provider at the following link: [https://www.dataprivacyframework.gov/s/participant-search/participant-detail?contact=true&id=a2zt0000000TOWQAA4&status=Active](https://www.dataprivacyframework.gov/s/participant-search/participant-detail?contact=true&id=a2zt0000000TOWQAA4&status=Active)

#### Data Processing Agreement

We have entered into a Data Processing Agreement (DPA) for the use of the above-mentioned service. This is a legally required data protection agreement that ensures that this service processes the personal data of our website visitors only according to our instructions and in compliance with the GDPR.

## 3. General Information and Mandatory Information

### Data Protection

The operators of these pages take the protection of your personal data very seriously. We treat your personal data confidentially and in accordance with the applicable data protection regulations and this privacy policy.

When you use this website, various personal data is collected. Personal data is data that can personally identify you. This privacy policy explains what data we collect and how and for what purpose it is used. It also informs you that data transmission over the Internet (e.g., when communicating via email) may have security vulnerabilities, and complete protection of data from access by third parties is not possible.

### Controller's Contact Information

The controller responsible for data processing on this website is:

Monestry GmbH
Data Protection
Käthchen-Paulus-Str. 18
60486 Frankfurt am Main

Phone: +4915147787321
Email: support@monestry.de

The controller is the natural or legal person who alone or jointly with others decides on the purposes and means of processing personal data (e.g., names, email addresses, etc.).

### Data Retention Period

Unless a specific retention period is mentioned within this privacy policy, your personal data will remain with us until the purpose of data processing ceases. If you request the deletion of your data or revoke your consent to data processing, your data will be deleted unless we have other legally permissible reasons for storing your personal data (e.g., tax or commercial retention periods); in the latter case, the deletion will occur after these reasons no longer apply.

### General Information about the Legal Basis for Data Processing on this Website

If you have given your consent to data processing, we process your personal data on the basis of Article 6(1)(a) GDPR or Article 9(2)(a) GDPR, if special categories of data according to Article 9(1) GDPR are processed. In the case of explicit consent to the transfer of personal data to third countries, data processing is also based on Article 49(1)(a) GDPR. If you have given your consent to the storage of cookies or access to information on your device (e.g., via Device-Fingerprinting), data processing also occurs based on § 25(1) TTDSG. Consent can be revoked at any time. If your data is necessary for the performance of a contract or for the implementation of pre-contractual measures, we process your data based on Article 6(1)(b) GDPR. Furthermore, we process your data if it is necessary to fulfill a legal obligation based on Article 6(1)(c) GDPR. Data processing may also occur based on our legitimate interest under Article 6(1)(f) GDPR. The relevant legal bases for data processing in specific cases are provided in the following paragraphs of this privacy policy.

### Recipients of Personal Data

In the course of our business activities, we work with various external entities. In some cases, the transfer of personal data to these external entities is necessary. We only disclose personal data to external entities when it is necessary as part of fulfilling a contract, when we are legally obligated to do so (e.g., disclosing data to tax authorities), when we have a legitimate interest under Article 6(1)(f) GDPR in disclosing data, or when other legal grounds permit data disclosure. When using data processors, we only disclose personal data of our customers based on a valid data processing agreement. In the case of joint processing, a joint processing agreement is concluded.

### Revocation of Your Consent to Data Processing

Many data processing operations are only possible with your express consent. You can revoke consent you have already given at any time. The legality of data processing carried out before revocation remains unaffected by the revocation.

### Right to Object to Data Collection in Special Cases and Direct Marketing (Article 21 GDPR)

IF DATA PROCESSING IS BASED ON ARTICLE 6(1)(E) OR (F) GDPR, YOU HAVE THE RIGHT TO OBJECT TO THE PROCESSING OF YOUR PERSONAL DATA AT ANY TIME FOR REASONS ARISING FROM YOUR PARTICULAR SITUATION, INCLUDING PROFILING BASED ON THESE PROVISIONS. THE RESPECTIVE LEGAL BASIS FOR DATA PROCESSING IS PROVIDED IN THIS PRIVACY POLICY. IF YOU OBJECT, WE WILL NO LONGER PROCESS YOUR AFFECTED PERSONAL DATA UNLESS WE CAN DEMONSTRATE COMPELLING LEGITIMATE GROUNDS FOR PROCESSING THAT OVERRIDE YOUR INTERESTS, RIGHTS, AND FREEDOMS, OR THE PROCESSING SERVES TO ASSERT, EXERCISE, OR DEFEND LEGAL CLAIMS (OBJECTION PURSUANT TO ARTICLE 21(1) GDPR).

IF YOUR PERSONAL DATA IS PROCESSED FOR THE PURPOSE OF DIRECT MARKETING, YOU HAVE THE RIGHT TO OBJECT AT ANY TIME TO THE PROCESSING OF YOUR PERSONAL DATA FOR THE PURPOSES OF SUCH ADVERTISING; THIS ALSO APPLIES TO PROFILING TO THE EXTENT THAT IT IS RELATED TO SUCH DIRECT MARKETING. IF YOU OBJECT, YOUR PERSONAL DATA WILL SUBSEQUENTLY NO LONGER BE USED FOR THE PURPOSE OF DIRECT MARKETING (OBJECTION PURSUANT TO ARTICLE 21(2) GDPR).

### Right to Lodge a Complaint with the Supervisory Authority

In the event of breaches of the GDPR, data subjects have the right to lodge a complaint with a supervisory authority, in particular in the member state of their habitual residence, place of work, or place of the alleged infringement. The right to lodge a complaint is without prejudice to any other administrative or judicial remedies.

### Right to Data Portability

You have the right to have data that we process automatically based on your consent or in fulfillment of a contract handed over to you or to a third party in a common, machine-readable format. If you request the direct transfer of data to another responsible party, this will only be done to the extent that it is technically feasible.

### Information, Correction, and Deletion

Within the framework of applicable legal provisions, you have the right to obtain free information about your stored personal data, its origin and recipient, and the purpose of data processing, and, if applicable, a right to correction or deletion of this data. For this purpose and for any further questions about personal data, you can always contact us.

### Right to Restrict Processing

You have the right to request the restriction of the processing of your personal data. To do so, you can always contact us. The right to restrict processing exists in the following cases:

*   If you dispute the accuracy of your personal data stored with us, we usually need time to verify this. For the duration of the examination, you have the right to request the restriction of the processing of your personal data.
*   If the processing of your personal data is unlawful, you can request the restriction of data processing instead of deletion.
*   If we no longer need your personal data, but you need it for the exercise, defense, or assertion of legal claims, you have the right to request the restriction of the processing of your personal data instead of deletion.
*   If you have filed an objection pursuant to Article 21(1) GDPR, a balance must be struck between your interests and ours. As long as it is not yet clear whose interests prevail, you have the right to request the restriction of the processing of your personal data.

If you have restricted the processing of your personal data, such data – apart from being stored – may only be processed with your consent or for the assertion, exercise, or defense of legal claims or for the protection of the rights of another natural or legal person or for reasons of important public interest of the European Union or a member state.
### SSL or TLS Encryption

For security reasons and to protect the transmission of confidential content, such as orders or inquiries that you send to us as the website operator, this site uses SSL or TLS encryption. You can recognize an encrypted connection by the fact that the address line of the browser changes from "http://" to "https://" and by the lock symbol in your browser line. When SSL or TLS encryption is activated, the data you transmit to us cannot be read by third parties.

### Objection to Promotional Emails

We hereby expressly prohibit the use of contact data published in the context of website legal notice requirements with regard to sending promotional and informational materials not expressly requested. The website operators expressly reserve the right to take legal action in the event of unsolicited sending of promotional information, for example through spam emails.

## 4. Data Collection on this Website

### Cookies

Our websites use so-called "cookies". Cookies are small data packets that do not harm your device. They are either temporarily stored for the duration of a session (session cookies) or permanently (persistent cookies) on your device. Session cookies are automatically deleted after your visit ends. Persistent cookies remain stored on your device until you delete them manually or they are automatically deleted by your web browser.

Cookies can come from us (first-party cookies) or from third-party companies (so-called third-party cookies). Third-party cookies allow the integration of certain services from third-party companies within websites (e.g., cookies for processing payment services).

Cookies have various functions. Many cookies are technically necessary, as certain website functions would not work without them (e.g., the shopping cart function or video display). Other cookies may be used for user behavior analysis or advertising purposes.

Cookies that are necessary for the electronic communication process, the provision of certain functions requested by you (e.g., shopping cart function), or for the optimization of the website (e.g., cookies for measuring web audience) are stored based on Article 6(1)(f) GDPR, unless another legal basis is specified. The website operator has a legitimate interest in storing necessary cookies for the technically error-free and optimized provision of its services. If consent for the storage of cookies and similar recognition technologies has been requested, processing is based solely on this consent (Article 6(1)(a) GDPR and § 25(1) TTDSG); consent can be revoked at any time.

You can configure your browser to inform you about the setting of cookies and to allow cookies only on an individual basis, to accept cookies for specific cases or to exclude them generally, and to activate the automatic deletion of cookies when you close your browser. If cookies are deactivated, the functionality of this website may be limited.

You can find information on which cookies and services are used on this website in this privacy policy.

### Contact via Email, Telephone, or Fax

If you contact us via email, telephone, or fax, your inquiry, including all resulting personal data (name, inquiry), will be stored and processed by us for the purpose of processing your request. We do not disclose this data without your consent.

The processing of this data is based on Article 6(1)(b) GDPR, provided your inquiry is related to the performance of a contract or is necessary for the implementation of pre-contractual measures. In all other cases, the processing is based on our legitimate interest in effectively processing the inquiries directed to us (Article 6(1)(f) GDPR) or on your consent (Article 6(1)(a) GDPR) if requested; consent can be revoked at any time.

The data you send us via contact inquiries will remain with us until you request deletion, revoke your consent to storage, or the purpose for data storage no longer applies (e.g., after processing your request is completed). Mandatory statutory provisions - especially statutory retention periods - remain unaffected.

## 5. Analysis Tools and Advertising

### Matomo

This website uses the open-source web analysis service Matomo.

With Matomo, we are able to collect and analyze data about the use of our website by website visitors. This allows us to determine, among other things, when specific page views were made and from which region they originate. We also collect various log files (e.g., IP address, referrer, used browsers, and operating systems) and can measure whether our website visitors perform specific actions (e.g., clicks, purchases, etc.).

The use of this analysis tool is based on Article 6(1)(f) GDPR. The website operator has a legitimate interest in analyzing user behavior in order to optimize both its web offering and its advertising. If consent for this analysis tool has been requested, processing is based solely on this consent (Article 6(1)(a) GDPR and § 25(1) TTDSG, insofar as the consent includes the storage of cookies or access to information on the user's device (e.g., Device-Fingerprinting) as defined by the TTDSG. Consent can be revoked at any time.

#### Hosting

We exclusively host Matomo on our own servers, ensuring that all analysis data remains with us and is not shared with others.

## 6. Newsletter

### Newsletter Data

If you wish to subscribe to the newsletter offered on the website, we require your email address and information that allows us to verify that you are the owner of the provided email address and agree to receive the newsletter. No further data is collected, or it is collected only on a voluntary basis. We use this data exclusively for sending the requested information and do not share it with third parties.

The processing of data entered into the newsletter registration form is based solely on your consent (Article 6(1)(a) GDPR). The consent given for data storage, the email address, and their use for sending the newsletter can be revoked at any time, for example via the "Unsubscribe" link in the newsletter. The legality of data processing operations already carried out remains unaffected by the revocation.

The data you provide us for the purpose of subscribing to the newsletter will be stored by us or the newsletter service provider until you unsubscribe from the newsletter or the purpose for data storage no longer applies. We reserve the right to delete or block email addresses from our newsletter distribution list at our discretion within the scope of our legitimate interests under Article 6(1)(f) GDPR.

Data stored by us for other purposes remains unaffected.

After unsubscribing from the newsletter distribution list, your email address may be stored in a blacklist by us or the newsletter service provider, if this is necessary to prevent future mailings. The data from the blacklist is used only for this purpose and is not merged with other data. This serves both your and our interest in compliance with legal requirements when sending newsletters (legitimate interest within the meaning of Article 6(1)(f) GDPR). The storage in the blacklist is not time-limited. **You can object to the storage if your interests outweigh our legitimate interest.**

## 7. Plugins and Tools

### YouTube with Enhanced Privacy

This website embeds videos from the YouTube website. The operator of these pages is Google Ireland Limited ("Google"), Gordon House, Barrow Street, Dublin 4, Ireland.

We use YouTube in enhanced privacy mode. According to YouTube, this mode means that YouTube does not store information about visitors to this website before they watch the video. However, the enhanced privacy mode does not necessarily exclude data transfer to YouTube partners. Regardless of whether you watch a video, YouTube establishes a connection to the Google Marketing Network.

When you start a YouTube video on this website, a connection to YouTube's servers is established. YouTube is notified of which of our pages you have visited. If you are logged into your YouTube account, you enable YouTube to directly associate your browsing behavior with your personal profile. You can prevent this by logging out of your YouTube account.

Furthermore, after starting a video, YouTube may store various cookies on your device or use similar recognition technologies (e.g., Device-Fingerprinting). This allows YouTube to obtain information about visitors to this website. This information is used, among other things, to record video statistics, improve user-friendliness, and prevent fraud.

Additional data processing operations may be triggered after starting a YouTube video, over which we have no influence.

The use of YouTube is in the interest of an attractive presentation of our online offerings. This constitutes a legitimate interest within the meaning of Article 6(1)(f) GDPR. If consent has been requested, the processing is based exclusively on Article 6(1)(a) GDPR and § 25(1) TTDSG, insofar as the consent includes the storage of cookies or access to information on the user's device (e.g., Device-Fingerprinting) as defined by the TTDSG. Consent can be revoked at any time.

Further information on data protection at YouTube can be found in their privacy policy at: [https://policies.google.com/privacy?hl=en](https://policies.google.com/privacy?hl=en).

The company has a certification under the "EU-US Data Privacy Framework" (DPF). The DPF is an agreement between the European Union and the United States that is intended to ensure compliance with European data protection standards for data processing in the United States. Each company certified under the DPF commits to complying with these data protection standards. Further information can be obtained from the provider at the following link: [https://www.dataprivacyframework.gov/s/participant-search/participant-detail?contact=true&id=a2zt000000001L5AAI&status=Active](https://www.dataprivacyframework.gov/s/participant-search/participant-detail?contact=true&id=a2zt000000001L5AAI&status=Active)

### Google Fonts (Local Hosting)

This page uses so-called Google Fonts for the uniform display of fonts, provided by Google. Google Fonts are installed locally. There is no connection to Google's servers.

For more information about Google Fonts, please visit [https://developers.google.com/fonts/faq](https://developers.google.com/fonts/faq) and Google's privacy policy: [https://policies.google.com/privacy?hl=en](https://policies.google.com/privacy?hl=en).

### Google reCAPTCHA

We use "Google reCAPTCHA" (hereinafter "reCAPTCHA") on this website. The provider is Google Ireland Limited ("Google"), Gordon House, Barrow Street, Dublin 4, Ireland.

reCAPTCHA is intended to check whether data entry on this website (e.g., in a contact form) is done by a human or an automated program. For this purpose, reCAPTCHA analyzes the behavior of website visitors based on various characteristics. This analysis starts automatically as soon as the website visitor enters the website. For analysis, reCAPTCHA evaluates various information (e.g., IP address, length of stay on the website, or mouse movements made by the user). The data collected during the analysis is forwarded to Google.

The reCAPTCHA analyses run entirely in the background. Website visitors are not informed that an analysis is taking place.

The storage and analysis of the data is based on Article 6(1)(f) GDPR. The website operator has a legitimate interest in protecting its web offers from abusive automated spying and spam. If the appropriate consent has been obtained, the processing is carried out exclusively on the basis of Article 6(1)(a) GDPR and § 25(1) TTDSG, insofar as the consent includes the storage of cookies or access to information on the user's device (e.g., Device-Fingerprinting) as defined by the TTDSG. Consent can be revoked at any time.

Further information on Google reCAPTCHA can be found in Google's privacy policy and terms of use at the following links: [https://policies.google.com/privacy?hl=en](https://policies.google.com/privacy?hl=en) and [https://policies.google.com/terms?hl=en](https://policies.google.com/terms?hl=en).

The company has a certification under the "EU-US Data Privacy Framework" (DPF). The DPF is an agreement between the European Union and the United States that is intended to ensure compliance with European data protection standards for data processing in the United States. Each company certified under the DPF commits to complying with these data protection standards. Further information can be obtained from the provider at the following link: [https://www.dataprivacyframework.gov/s/participant-search/participant-detail?contact=true&id=a2zt000000001L5AAI&

Source is e-recht24`;

export default datenschutz;
