/* eslint-disable no-eval */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate, useLocation, Link } from 'react-router-dom';
import SearchInput, { SearchResults } from '../elements/SearchComponent';
import posts from '../blog/posts.json';
import categories from '../blog/categories.json';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function Blog() {
  const { t, i18n } = useTranslation(['site']);
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const [activeCategory, setActiveCategory] = useState('blog'); // 'blog' means show all categories + filter
  const [searchControl, setSearchControl] = useState({
    text: '',
    searching: false,
    focus: null,
  }); // search results for the search bar;

  // implements "scroll to anchor" functionality
  useEffect(() => {
    const { hash } = window.location;
    if (hash) {
      const element = document.getElementById(hash.slice(1));
      if (element) {
        element.scrollIntoView();
      }
    }
  }, []);

  function getCategoryDisplayKey(categoryKey) {
    return categories.find((cat) => cat.key === categoryKey)[i18n.language]
      .displayKey;
  }

  function getCategoryKey(categoryDisplayKey) {
    // catch case where this is called before useEffect runs (when changing language)
    if (categoryDisplayKey) {
      const result = categories.find(
        (cat) => cat[i18n.language].displayKey === categoryDisplayKey,
      );
      if (result) return result.key;
    }
    return null;
  }

  // if there exists url param after "blog/", then this is a category page, so set the appropriate state;
  // otherwise it is a main blog page and leave the initial value of 'blog'
  useEffect(() => {
    const newLocation = window.location.pathname;
    if (location.pathname !== newLocation) {
      console.log(`trying to go to ${newLocation}`);
      navigate(newLocation);
    } else if (params.cat) {
      // check if category exists -- if not, redirect to not found
      if (
        !categories.some((cat) => cat[i18n.language].displayKey === params.cat)
      ) navigate(`/${i18n.language}/notfound`);
      setActiveCategory(getCategoryKey(params.cat));
    } else {
      setActiveCategory('blog');
    }
  }, [params.cat, i18n.language]);

  return (
    <div className="relative bg-gray-50 pt-16 pb-20 px-4 sm:px-6 lg:pt-16 lg:pb-28 lg:px-8">
      <div className="absolute inset-0">
        <div className="bg-white h-1/3 sm:h-2/3" />
      </div>
      <div className="relative max-w-7xl mx-auto">
        <div className="relative text-center mb-12">
          {/* if there is a 'cat' passed as a parameter by router from /blog/:cat, them this is a category view, otherwise keep blog headings */}
          {/* find category id for the categoryDisplayKey passed in route */}
          <h2 className="text-3xl tracking-tight font-black text-gray-900 sm:text-4xl">
            {t(
              `blog.categories.${
                getCategoryKey(params.cat) ? getCategoryKey(params.cat) : 'blog'
              }.title`,
            )}
          </h2>
          <p className="mt-3 mb-6 max-w-4xl mx-auto text-xl text-gray-500 sm:mt-4">
            {t(
              `blog.categories.${
                getCategoryKey(params.cat) ? getCategoryKey(params.cat) : 'blog'
              }.subtitle`,
            )}
          </p>
          <SearchInput
            searchControl={searchControl}
            setSearchControl={setSearchControl}
            theme="light"
          />
          {searchControl.text && (
            <SearchResults
              searchControl={searchControl}
              setSearchControl={setSearchControl}
              positionAdjustment="left-1/2 -translate-x-1/2 mt-4"
            />
          )}
        </div>
        {/* don't show filter when this is a category view */}
        {!params.cat ? (
          <div>
            {/* Category filter device */}
            {/* Mobile screen */}
            <div className="sm:hidden">
              <label htmlFor="tabs" className="sr-only">
                Select a category
              </label>
              {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
              <select
                id="tabs"
                name="tabs"
                className="block w-full focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                defaultValue={
                  categories.find((tab) => tab.key === activeCategory).key
                }
              >
                {categories.map((tab) => (
                  <option key={tab.key}>
                    {t(`site:blog.categories.${tab.key}.title`)}
                  </option>
                ))}
              </select>
            </div>
            {/* Browser screen */}
            <div className="hidden sm:block">
              <nav className="flex space-x-6 justify-center" aria-label="Tabs">
                <label htmlFor="tabs" className="grid content-center">
                  {' '}
                  <div className="my-auto font-semibold text-base">
                    {t('site:blog.filter-header')}
                  </div>
                  {' '}
                </label>
                {categories.map((tab) => (
                  <a
                    key={tab.key}
                    href={tab.href}
                    className={classNames(
                      tab.key === activeCategory
                        ? 'bg-brandBlue-100 text-brandBlue-600'
                        : 'text-gray-400 hover:text-gray-500',
                      'px-3 py-2 font-semibold text-base rounded-md',
                    )}
                    onClick={(e) => setActiveCategory(tab.key)}
                    aria-current={tab.current ? 'page' : undefined}
                  >
                    {/* blog is an exception: it has a different title on top of the page and different name in the filter */}
                    {tab.key === 'blog'
                      ? t(`site:blog.categories.${tab.key}.filter-title`)
                      : t(`site:blog.categories.${tab.key}.title`)}
                  </a>
                ))}
              </nav>
            </div>
          </div>
        ) : null}
        <div className="mt-6 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
          {posts.map((post) => (
            <div
              key={post.id}
              data-testid="blog-tile"
              className={classNames(
                (post.category.key === activeCategory
                  || activeCategory === 'blog')
                  && !post.hiddenFromBrowsing
                  ? null
                  : 'hidden',
                'flex flex-col rounded-lg shadow-lg overflow-hidden',
              )}
            >
              <Link
                to={`/${i18n.language}/blog/${getCategoryDisplayKey(
                  post.category.key,
                )}/${post[i18n.language].displayKey}`}
                className="block mt-2"
              >
                <div className="flex-shrink-0">
                  <img
                    className="h-48 w-full object-cover"
                    src={post.image.thumb}
                    alt=""
                  />
                </div>
                <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                  <div className="flex-1">
                    <p className="text-sm font-semibold text-brandBlue-500 uppercase">
                      <a
                        href={`/${i18n.language}/blog/${getCategoryDisplayKey(
                          post.category.key,
                        )}/`}
                        className="hover:underline"
                      >
                        {t(`site:blog.categories.${post.category.key}.title`)}
                      </a>
                    </p>

                    <p className="mt-1 text-xl font-semibold text-gray-900">
                      {post[i18n.language].title}
                    </p>
                    <p className="mt-3 text-base text-gray-500">
                      {post[i18n.language].description}
                    </p>
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
