/* eslint-disable no-param-reassign */

const debugLevel = 0;

/**
 * Processes an array of transactions to apply FIFO logic for handling quantities,
 * adjusting open quantities, and linking transactions based on their types (PURCHASE, SALE, HYBRID).
 * Each transaction is evaluated to determine its type, adjust its `quantityOpen` as necessary,
 * and for SALE and HYBRID transactions, link to previous transactions from which quantities are deducted.
 *
 * Debugging instructions: set debugLevel to 3 and observe changes between each iteration.
 *
 * @param {Object[]} transactions - An array of transactions to process. Each transaction should have
 *                                  properties like `quantity`, `id`, `date`, `category`, `upbc`, and optionally `quantityOpen`
 *                                  and `linkedTransactions` which will be modified by the function.
 *                                  Transactions MUST BE FOR THE SAME ACCOUNT.
 * @returns {Object[]} The modified array of transactions with updated `quantityOpen` for PURCHASE transactions,
 *                     and `linkedTransactions` for SALE and HYBRID transactions, indicating the source of deducted quantities.
 */
export default function applyUniversalFIFO(transactions) {
  const transactionsCopy = []; // when running on server, this fails saying that cannot assign to read only property linkedTransactions
  transactions.forEach((transaction) => {
    try {
      if (transaction.accountId !== transactions[0].accountId) throw new Error('All transactions must be for the same account');
      const newTransaction = { ...transaction }; // to avoid modifying the original transaction
      newTransaction.linkedTransactions = newTransaction.linkedTransactions || [];
      transactionsCopy.push({ ...newTransaction });
    } catch (err) {
      console.error('applyUniversalFIFO error for transaction', JSON.stringify(transaction, null, 2), 'error object:', err);
    }
  });

  let balance = 0;

  // Helper function to adjust open quantities and link transactions
  // expects quantityNeeded with the "purchase" transaction sign already
  function findOpenQuantity(currentTransaction, quantityNeeded) {
    if (quantityNeeded === 0) return undefined;
    let purchaseValueBaseCurrency = 0;
    const newLinkedTransactions = [];

    const quantityNeededSign = Math.sign(quantityNeeded);
    let quantityNeededAbs = Math.abs(quantityNeeded);
    transactionsCopy.forEach((transaction) => {
      try {
        // we expect only to see quantityOpen with the "correct" sign, as the transactions with quantityOpen with the opposite sign have already been closed out by the last HYBRID transaction
        if (transaction.quantityOpen && Math.abs(transaction.quantityOpen) > 0) {
          // we can deduct either the entire quantity needed or the entire quantityOpen, whichever is smaller
          const quantityToDeduct = Math.min(quantityNeededAbs, Math.abs(transaction.quantityOpen));
          if (quantityToDeduct > 0) {
            transaction.quantityOpen -= quantityToDeduct * quantityNeededSign;
            transaction.quantitySold = transaction.quantity - transaction.quantityOpen;
            quantityNeededAbs -= quantityToDeduct;
            purchaseValueBaseCurrency += transaction.upbc * quantityToDeduct * quantityNeededSign;

            // Link the transaction
            newLinkedTransactions.push({
              id: transaction.id, // Assuming each transaction has a unique ID
              category: transactionsCopy[0].category, // Assuming category of the first transaction
              tags: {
                meta: { source: 'applyUniversalFIFO' },
                type: 'purchase',
                date: transaction.date, // Assuming the date field exists
                quantity: quantityToDeduct * quantityNeededSign,
                upbc: transaction.upbc, // Assuming upbc field exists
              },
            });
          }
        }
      } catch (err) {
        console.error('findOpenQuantity error for transaction', JSON.stringify(transaction, null, 2), err);
      }
    });
    return { purchaseValueBaseCurrency, newLinkedTransactions };
  }

  transactionsCopy
    .sort((a, b) => a.date - b.date)
    .forEach((transaction) => {
      const initialBalance = balance;
      if (debugLevel > 2) console.log('initialBalance', initialBalance);
      balance += transaction.quantity;

      // Determine transaction type
      let transactionType;
      if (initialBalance * transaction.quantity >= 0) transactionType = 'PURCHASE';
      else if (Math.abs(initialBalance) >= Math.abs(transaction.quantity)) {
        transactionType = 'SALE';
      } else {
        transactionType = 'HYBRID';
      }
      if (debugLevel > 2) console.log('transactionType', transactionType);

      if (transactionType === 'PURCHASE') {
        transaction.quantityOpen = transaction.quantity;
        transaction.quantitySold = 0;
      } else {
        // if balance is 30 and this is a sale of -10, then we need to find a purchase transaction with openQuantity of 10 (SALE case)
        // if balance is 10 and this is a sale of -20, then we need to find a purchase transaction with openQuantity of 10 (HYBRID case)
        // as HYBRID cases are by definition those where intial balance is of a different sign to the transaction quantity
        // quantityNeeded is passed with the sign that is needed for the opposite transaction (i.e. the sale transaction of -10 will pass quantityNeeded of +10)
        const quantityNeeded = transactionType === 'SALE' ? -transaction.quantity : initialBalance;
        const { purchaseValueBaseCurrency, newLinkedTransactions } = findOpenQuantity(transaction, quantityNeeded);
        transaction.purchaseValueBaseCurrency = purchaseValueBaseCurrency;
        transaction.linkedTransactions = [...transaction.linkedTransactions, ...newLinkedTransactions];

        if (transactionType === 'HYBRID') {
          // this is like a PURCHASE after subtracting the part which has been used to cover the SALE part of the HYBRID transaction
          transaction.quantityOpen = initialBalance + transaction.quantity;
        }
      }
      if (debugLevel > 2) console.log(JSON.stringify(transactionsCopy, null, 2));
    });

  return transactionsCopy;
}
