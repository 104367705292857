import React, {
  useEffect, useState, useLayoutEffect, useRef,
} from 'react';
import PropTypes from 'prop-types';
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/solid';

export function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export function InputComponent({
  value, callback, formatting, attr, stub,
}) {
  // value is the value of the input field from the state of the parent component
  // callback is the function to be called when the input field is changed
  // formatting needs a string, attr as string (field name) and stub as string
  const [hasFocus, setFocus] = useState(false);
  const [widthState, setWidthState] = useState(0);
  const [measuringInProgress, setMeasuringInProgress] = useState(false); // turns on and off the span component for a fraction of a sec, trick to measure / resize input field
  // ^^  turn this true for debug if there are any issues with length of the field

  const measurer = useRef(null);
  const myInputField = useRef(null);

  useEffect(() => { // toggles the visibility of span and activates useLayoutEffect
    setMeasuringInProgress(true);
  }, [value, hasFocus]);

  useLayoutEffect(() => { // measures the span size and updates the state, switches span visibility to false
    if (measuringInProgress && measurer?.current) {
      const rect = measurer.current.getBoundingClientRect();
      setWidthState(rect.width + (hasFocus ? 72 : 0) /* account for yes / no icons */ + 24 /* account for padding */);
      setMeasuringInProgress(false);
    }
  }, [measuringInProgress]);

  function handleFieldOk(e) {
    setFocus(false);
    myInputField.current.blur();
  }

  function handleFieldCancel(e) {
    setFocus(false);
    myInputField.current.blur();
  }

  return (
    <label htmlFor={`${attr}-input`} className="relative h-full">
      {/* this is for measuring the input field length by substitution */}
      <span className={classNames(formatting, 'whitespace-nowrap')} ref={measurer}>{measuringInProgress && (value || stub)}</span>
      <input
        className={classNames(
          'bg-gray-50 border-none rounded',
          formatting,
          'hover:underline hover:cursor-pointer focus:cursor-text focus:ring-1 focus:ring-offset-0 focus:outline-none focus:ring-brandBlue-400',
        )}
        style={{ width: widthState }}
        id={`${attr}-input`}
        ref={myInputField}
        placeholder={stub}
        value={value}
        onChange={(e) => callback(e.target.value)}
        onKeyPress={(e) => { if (e.key === 'Enter') { handleFieldOk(); } }}
        onFocus={() => setFocus(true)}
        onBlur={(e) => {
          // a short timeout to delay the blur event until the onclick event of the buttons have been processed
          setTimeout(() => handleFieldCancel(e), 150);
        }}
        type="text"
      />

      {hasFocus
        && (
          <div className="absolute flex right-0 top-0 -mt-1 h-full space-x-2 pr-2 z-10">
            <button
              type="button"
              onClick={handleFieldOk}
            >
              <CheckCircleIcon className={classNames(
                (attr === 'name') ? 'h-6 w-6' : 'hidden',
                'text-gray-300 hover:text-gray-400 hover:cursor-pointer',
              )}
              />
            </button>
            <button
              type="button"
              onClick={(e) => {
                // a short timeout to delay the blur event until the onclick event of the buttons have been processed
                setTimeout(() => {
                  handleFieldCancel(e);
                }, 150);
              }}
            >
              <XCircleIcon className={classNames(
                (attr === 'name') ? 'h-6 w-6' : 'hidden',
                'text-gray-300 hover:text-gray-400 hover:cursor-pointer',
              )}
              />
            </button>
          </div>
        )}

    </label>
  );
}
InputComponent.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  value: PropTypes.string.isRequired,
  callback: PropTypes.func.isRequired,
  formatting: PropTypes.string.isRequired,
  attr: PropTypes.string.isRequired,
  stub: PropTypes.string.isRequired,
};
