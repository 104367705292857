/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { PencilIcon } from '@heroicons/react/24/outline';
import { PlusIcon, XMarkIcon } from '@heroicons/react/20/solid';
import Button from '../../elements/Button';
import Dropdown from '../../elements/Dropdown';

dayjs.extend(utc);

/**
 *
 * @param {object} formValues: expects array of { effectiveDate, value }
 * @param {string} header: title of the form (translated)
 * @param {string} prompt: description of the form (translated)
 * @param {function} callback: function to call when the user clicks 'Save'
 * @param {array} valueDropdown: optional array of values to display as dropdown (otherwise component displays a number input)
 *
 * @returns React component
 */
export default function AttributeArrayOfObjectsForm({ formValues, header, prompt, callback, valueDropdown = [1, 3, 12] }) {
  const { t } = useTranslation('app', { keyPrefix: 'accountDetails' });

  const [loading, setLoading] = React.useState(false);

  const { register, control, handleSubmit, formState: { errors }, setError, clearErrors } = useForm({
    defaultValues: { // useFieldArray doesn't work with array at root level of form
      table: formValues.map((item) => ({ date: dayjs.utc(item.effectiveDate).format('YYYY-MM-DD'), value: item.value })),
    },
  });
  const { fields, append, remove } = useFieldArray({
    name: 'table',
    control,
  });

  // TODO get user a picklist for period with human-readable values

  async function onSubmit(data) {
    setLoading(true);
    try {
      // we need to make sure there is at least 1 row in 'table'
      if (data.table.length < 1) {
        setError('table', { type: 'custom', message: t('errorOneRowRequired') });
      } else {
        clearErrors('table');
      }

      // we need to return the initial object to caller
      if (data.table.length > 0) {
        const result = data.table.map((item) => ({ effectiveDate: dayjs.utc(item.date).valueOf(), value: item.value }));
        await callback(result);
      }
      setLoading(false);
      window.dispatchEvent(new CustomEvent('setDialog'));
    } catch (error) {
      setLoading(false);
      console.error('AttributeArrayOfObjectsForm error:', error);
    }
  }

  function onCancel() {
    window.dispatchEvent(new CustomEvent('setDialog'));
  }

  return (
    <div className="mx-auto max-w-md transform overflow-hidden rounded-lg bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all p-6">
      <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-brandBlue-100">
        <PencilIcon className="h-6 w-6 text-brandBlue-500" aria-hidden="true" />
      </div>

      <div className="mt-3 text-center sm:mt-5">
        <h3 className="text-lg leading-6 font-bold text-gray-900">
          {header}
        </h3>
        <p className="mt-2 text-sm text-gray-500">{prompt}</p>
        <div className="mt-4 grid grid-cols-[auto,auto,50px] gap-3 text-sm xs:text-base items-start">
          <span className="ml-1 -mb-1 text-sm text-left">{t('effectiveDate')}</span>
          <span className="ml-1 -mb-1 text-sm text-left">{t('value')}</span>
          <div />
          {fields.map((item, index) => (
            <>
              <div>
                <input
                  type="date"
                  id={`date-${index}`}
                  name={`date-${index}`}
                  className="block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-gray-900 focus:outline-none focus:ring-gray-900 sm:text-sm"
                  {...register(`table.${index}.date`, { required: true })}
                />
                {errors.table?.[index]?.date && <span className="text-xs text-red-500">{t('errorFieldRequired')}</span>}
              </div>

              <div>
                {/* if valueDropdown is provided, display the value field as a dropdown using translation keys from the valueDropdown */}
                {(valueDropdown)
                  ? (
                    <Controller
                      name={`table.${index}.value`}
                      control={control}
                      render={({ field, ref }) => (
                        <Dropdown
                          label=""
                          value={field.value}
                          list={valueDropdown}
                          onChange={field.onChange}
                          onBlur={field.onBlur}
                          inputRef={ref}
                          optional={false}
                        />
                      )}
                    />
                  )
                  : (
                    <input
                      type="number"
                      step={3}
                      name={`value-${index}`}
                      id={`value-${index}`}
                      className="block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-gray-900 focus:outline-none focus:ring-gray-900 sm:text-sm"
                      {...register(`table.${index}.value`, { required: true })}
                    />
                  )}
                {errors.table?.[index]?.value && <span className="text-xs text-red-500">{t('errorFieldRequired')}</span>}
              </div>

              <Button
                Icon={XMarkIcon}
                onClick={() => remove(index)}
                size="lg"
              />
            </>
          ))}
          <Button
            Icon={PlusIcon}
            onClick={() => append()}
            formatting="w-12"
          />
        </div>
        {errors.table && <span className="text-xs text-red-500">{t('errorOneRowRequired')}</span>}
        <div className="flex justify-center mt-4 space-x-3">
          <Button
            text="Save"
            type="submit"
            size="lg"
            withAccent
            onClick={handleSubmit(onSubmit)}
            spinnerOn={loading}
          />
          <Button
            text="Cancel"
            size="lg"
            onClick={onCancel}
          />
        </div>
      </div>
    </div>
  );
}
AttributeArrayOfObjectsForm.propTypes = {
  formValues: PropTypes.array.isRequired,
  header: PropTypes.string.isRequired,
  prompt: PropTypes.string.isRequired,
  callback: PropTypes.func.isRequired,
  valueDropdown: PropTypes.array,
};
AttributeArrayOfObjectsForm.defaultProps = {
  valueDropdown: null,
};
