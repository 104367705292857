import dayjs from 'dayjs';

const utc = require('dayjs/plugin/utc');

dayjs.extend(utc);

// named baselines for project mode are defined in globalBaselineView
const namedBaselines = {
  sameTimeLastYear: dayjs.utc().subtract(1, 'year').startOf('day').valueOf(),
  currentYearBegin: dayjs.utc().startOf('year').startOf('day').valueOf(),
  previousYearBegin: dayjs.utc().subtract(1, 'year').startOf('year').startOf('day')
    .valueOf(),
};

export default namedBaselines;
