/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Auth } from 'aws-amplify';
import { postAccount } from '../../redux/reducers/data';
import { setMessage } from '../../redux/actions/message';
import MiniSpinner from '../../misc/MiniSpinner';
import { getNextAvailableValuationDate } from '../addAccount/accountWorkflow/RealEstateAccountData';

function AccountComponent({ accountName, index, selected, handleClick }) {
  return (
    <tr>
      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3">{accountName}</td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center">
        {/* {account.tags?.nextValuationDate || 'No Valuation Date'} */}
        <input type="checkbox" className="text-brandBlue-500" checked={selected} onChange={(e) => handleClick(index)} />
      </td>
    </tr>
  );
}
AccountComponent.propTypes = {
  accountName: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  selected: PropTypes.bool,
  handleClick: PropTypes.func.isRequired,
};
AccountComponent.defaultProps = {
  selected: false,
};

export default function RealEstateValuations({ account }) {
  const [valuationSlots, setValuationSlots] = useState([]);
  const [tickboxes, setTickboxes] = useState([]);
  const [touched, setTouched] = useState(false);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const { t } = useTranslation(['app']);

  const accounts = useSelector((state) => state.data.realEstate.accounts);
  const accountsWithValuationSlots = accounts.filter((a) => a.tags?.nextValuationDate);

  // get valuationSlots from Cognito on initialisation
  useEffect(() => {
    Auth.currentAuthenticatedUser({ bypassCache: true })
      .then((user) => {
        const result = JSON.parse(user.attributes['custom:valuation_slots'] || '[]');
        setValuationSlots(result);
      })
      .catch((err) => console.error(err));
  }, []);

  // initialise tickboxes
  useEffect(() => {
    setTickboxes(accounts.map((a) => a.tags?.nextValuationDate));
  }, [accounts]);

  const unusedValuations = valuationSlots.length - tickboxes.filter((tickbox) => tickbox).length;

  const tooManySelections = tickboxes.filter((tickbox) => tickbox).length > valuationSlots.length;

  function handleClick(index) {
    setTouched(true);
    // set the indexth element of tickboxes to the opposite of what it is now
    const newTickboxes = [...tickboxes];
    newTickboxes[index] = !newTickboxes[index];
    setTickboxes(newTickboxes);
  }

  async function onSubmit() {
    // we submit only if tooManySelections is false
    if (!tooManySelections) {
      setLoading(true);
      // tickboxes:
      // - false --> user clicked on it --> remove nextValuationDate from tags
      // - true --> user selected it for updates --> add nextValuationDate to tags
      // - undefined --> no valuation date and no change (skip)
      const category = 'realEstate';
      const promises = tickboxes.map((tickbox, index) => {
        if (tickbox === true) {
          const accountToUpdate = accounts[index];
          const tags = accountToUpdate.tags || {};
          // eslint-disable-next-line max-len
          return dispatch(
            postAccount({ category, data: { ...accountToUpdate, importFlag: 'put', tags: { ...tags, nextValuationDate: getNextAvailableValuationDate(accountsWithValuationSlots, valuationSlots) } } }),
          );
        }
        if (tickbox === false) {
          const accountToUpdate = accounts[index];
          const tags = { ...accountToUpdate.tags } || {};
          delete tags.nextValuationDate;
          return dispatch(postAccount({ category, data: { ...accountToUpdate, importFlag: 'put', tags } }));
        }
        return null;
      });

      const responses = await Promise.allSettled(promises);

      console.log(JSON.stringify(responses, null, 2));
      if (responses.every((response) => response.status === 'fulfilled')) {
        setLoading(false);
        dispatch(setMessage('accountUpdatedSuccessfully', account.id));
      } else {
        setLoading(false);
        dispatch(setMessage('accountCouldNotBeUpdated', account.id));
      }
    }
  }

  return (
    <div className="col-span-4 md:col-span-2">
      <div className="pb-4 text-sm text-gray-500">
        <p>{`You can update ${valuationSlots.length} account(s) automatically.`}</p>
        {tooManySelections ? <p className={`${tooManySelections ? 'text-red-500' : ''}`}>Too many accounts are selected.</p> : <p>{`Unused valuations: ${unusedValuations}`}</p>}
      </div>
      <table className="divide-y divide-gray-300">
        <thead>
          <tr>
            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3">
              Account
            </th>
            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
              Updated automatically
            </th>
          </tr>
        </thead>
        {accounts.map((a, index) => AccountComponent({
          accountName: a?.name,
          index,
          selected: tickboxes[index],
          handleClick,
        }))}
      </table>
      {touched && (
        <button
          type="button"
          onClick={onSubmit}
          // eslint-disable-next-line max-len
          className="mt-4 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-brandBlue-500 hover:bg-brandBlue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brandBlue-400"
        >
          {!loading ? t('accountDetails.buttons.save.label') : <MiniSpinner className="w-5 h-5 animate-spin" />}
        </button>
      )}
    </div>
  );
}
RealEstateValuations.propTypes = {
  account: PropTypes.object.isRequired,
};
