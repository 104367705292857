import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Button from '../../elements/Button';

export default function SublistOkCancel({ handleSave, handleCancel, loading }) {
  const { t } = useTranslation('app', { keyPrefix: 'accountDetails.buttons' });

  return (
    <div className="grid grid-cols-2 gap-2 p-1">
      <Button text={t('cancel.label')} onClick={() => handleCancel()} size="md" />
      <Button text={t('save.label')} onClick={handleSave} size="md" withAccent spinnerOn={loading} />
    </div>
  );
}
SublistOkCancel.propTypes = {
  handleSave: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};
