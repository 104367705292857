import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTheme } from '@nivo/core';

export default function CustomTooltip({
  id, value, formattedValue,
}) {
  const theme = useTheme();
  const baseCurrency = useSelector((state) => state.user.profile.settings.baseCurrency);

  return (
    <div style={{ ...theme.tooltip.container }} className="text-sm rounded-md shadow">
      <p className="text-white">
        <span className="font-bold text-white">{id}</span>
        {`: ${value.toLocaleString('de', { style: 'currency', currency: baseCurrency || 'EUR', maximumFractionDigits: 0 })}`}
      </p>
      <p className="text-white">{`${formattedValue}`}</p>
    </div>
  );
}
CustomTooltip.propTypes = {
  id: PropTypes.string,
  value: PropTypes.number,
  formattedValue: PropTypes.string,
};
CustomTooltip.defaultProps = {
  id: '',
  value: 0,
  formattedValue: '',
};
