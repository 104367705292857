import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useForm, useFieldArray } from 'react-hook-form';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { putStocksSettings } from '../../redux/reducers/data';
import AssetGrowthRate from '../../elements/AssetGrowthRate';
import { settingsStocksAndCrypto } from '../../elements/config';
import Button from '../../elements/Button';
import { setMessage } from '../../redux/actions/message';

dayjs.extend(utc);

/**
 * Displays the growth rates form for stocks and cryptocurrencies including the left-hand column with labels and help texts.
 * Takes up the entire width of the provided space (adjusted to the settings screens).
 * State, saving and data submissions are managed within.
 *
 * @param {setIsDirty} (optional) function which sets the parent's isDirty state to true when the form is dirty
 * @param {displayOnly} (optional) string to display only the selected category
 * @returns React.Component
 */
export default function CategoryGrowthRates({ setIsDirty, displayOnly }) {
  const [updating, setUpdating] = useState(false); // controls the spinner on the Save button

  const selectStockSettings = useSelector((state) => state.data.stocks.settings);
  const selectCryptoSettings = useSelector((state) => state.data.crypto.settings);
  console.log('DEBUG selectStockSettings', selectStockSettings);
  console.log('DEBUG selectCryptoSettings', selectCryptoSettings);

  const dispatch = useDispatch();
  const { t } = useTranslation('app', { keyPrefix: 'settings.simulations' });

  // default settings updated in getSettings (centrally)

  const {
    register,
    control,
    watch,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      ...settingsStocksAndCrypto, // making sure the default values are in the form object and the form object will work
      ...selectStockSettings,
      crypto: selectCryptoSettings,
    },
  }); // intialised after useEffect fetches data from Amplify store
  const { fields, append, remove } = useFieldArray({
    name: 'growthRates',
    control,
  });
  const {
    fields: fieldsC,
    append: appendC,
    remove: removeC,
  } = useFieldArray({
    name: 'crypto.growthRates',
    control,
  });

  // keep parent's isDirty state in sync with the form's isDirty state, so that a warning can be shown when the user tries to navigate away without saving
  useEffect(() => {
    setIsDirty(isDirty);
  }, [isDirty]);

  async function onSubmit(data) {
    // if the form is dirty, update the entire object (as it would have otherwise been added by redux)
    if (isDirty) {
      setUpdating(true);
      try {
        await dispatch(
          putStocksSettings({
            data,
            category: null, // null has the same effect as 'stocks', but just to explicitly state that we are updating settings both for crypto and stocks
          }),
        );
        reset(data);
        dispatch(setMessage('dataUpdatedSuccessfully'));
      } catch (error) {
        console.error(error);
        dispatch(setMessage('dataUpdateError'));
      }
      setUpdating(false);
    }
  }

  return (
    <div className="sm:grid sm:grid-cols-6 sm:items-start sm:justify-items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5 text-sm">
      <div className="sm:col-span-2">
        <p>{t('stockMarketGrowthRate')}</p>
        <p className="mt-1 text-xs text-gray-400">{t('simpleMode')}</p>
        <p className="mt-1 text-xs text-gray-400">{t('simpleMode2')}</p>
        <p className="mt-2 text-xs text-gray-400">{t('advancedMode')}</p>
        <p className="mt-1 text-xs text-gray-400">{t('advancedMode2')}</p>
      </div>
      <div className="sm:col-span-4 sm:space-y-8">
        {displayOnly !== 'crypto' && (
          <AssetGrowthRate register={register} control={control} watch={watch} errors={errors} fieldsGrowthRates={fields} appendGrowthRates={append} removeGrowthRates={remove} header="Stocks" />
        )}
        {displayOnly !== 'stocks' && (
          <AssetGrowthRate
            register={register}
            control={control}
            watch={watch}
            errors={errors}
            fieldsGrowthRates={fieldsC}
            appendGrowthRates={appendC}
            removeGrowthRates={removeC}
            namePrefix="crypto."
            header="Cryptocurrencies"
          />
        )}
      </div>
      {isDirty && (
        <div className="flex justify-end space-x-2 sm:col-start-3 mt-4">
          <Button type="button" onClick={handleSubmit(onSubmit)} text={t('save')} spinnerOn={updating} id="settings-planning-save" withAccent size="lg" />
        </div>
      )}
    </div>
  );
}
CategoryGrowthRates.propTypes = {
  setIsDirty: PropTypes.func,
  displayOnly: PropTypes.string,
};
CategoryGrowthRates.defaultProps = {
  setIsDirty: () => {},
  displayOnly: null,
};
