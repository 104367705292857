/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/forbid-prop-types */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TableHeaders from './TableHeaders';
import TableBody from './TableBody';

export default function Table({
  data, tableLayout, tableState, setTableState, highlightTransactionId, groupByColumn,
}) {
  // if header is less wide than 5px, then its contents has been hidden due to current breakpoint and the entire containing column needs to be hidden as well
  // the header which detects that its width is below 5px adds its id to the hiddenColumns state array in this component
  // HeaderCell and BodyColumn components check hiddenColumns if their id is there and if so, they add 'hidden' to their className
  const [hiddenColumns, setHiddenColumns] = useState([]);

  return (
    <section
      // eslint-disable-next-line max-len
      className="max-h-[50vh] xl:max-h-max self-stretch flex flex-col overflow-hidden relative overflow-y-auto overflow-x-hidden scrollbar-thin scrollbar-track-gray-100 scrollbar-thumb-gray-200 hover:scrollbar-thumb-zinc-200"
      aria-label="transaction details"
    >
      <table className="w-full table table-fixed divide-y divide-gray-300 border-collapse">
        <thead className="sticky top-0 z-10">
          <tr>
            <TableHeaders tableLayout={tableLayout} tableState={tableState} setTableState={setTableState} hiddenColumns={hiddenColumns} setHiddenColumns={setHiddenColumns} />
          </tr>
        </thead>
        <TableBody
          data={data}
          tableLayout={tableLayout}
          hiddenColumns={hiddenColumns}
          highlightTransactionId={highlightTransactionId}
          groupByColumn={groupByColumn}
        />
      </table>
    </section>
  );
}
Table.propTypes = {
  data: PropTypes.array.isRequired, // array of objects (each object should have exactly the same keys as there are columns in the table + an id)
  tableLayout: PropTypes.array.isRequired,
  tableState: PropTypes.object.isRequired, // contains column widths, sorting, filtering, etc., has to be passed to e.g. TableHeaders to be able to change it
  setTableState: PropTypes.func.isRequired,
  highlightTransactionId: PropTypes.string, // coming from search function, hightlights a transaction in the table
  groupByColumn: PropTypes.string, // determins which field of each row will be used to manage the open / closed state; default is 'assetId'
};
Table.defaultProps = {
  highlightTransactionId: null,
  groupByColumn: 'assetId',
};
