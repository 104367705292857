/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { Listbox, Transition } from '@headlessui/react';
import dayjs from 'dayjs';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/24/solid';
import ToolTip from '../../elements/ToolTipNoIcon';

const utc = require('dayjs/plugin/utc');

dayjs.extend(utc);

export default function AssetNamePicklist({
  control, account, t, errors, assetIds,
}) {
  // assetIds is an array with { assetId, displaySymbol, displayName }

  return (
    <>
      <Controller
        name="displaySymbol"
        control={control}
        defaultValue=""
        render={({ field, ref }) => {
          // if there is no field.value, set it to assetIds[0]
          if (!field.value) field.onChange(assetIds[0]);

          return (
            <Listbox as="div" value={field.value} onChange={field.onChange} ref={ref}>
              {({ open }) => (
                <>
                  <Listbox.Label
                    className="block text-sm font-medium text-gray-700 mb-0.5"
                    htmlFor="dropdown"
                  >
                    <ToolTip
                      className="text-black border-gray-400 shadow bg-brandYellow-50"
                      info={t('assetName.tooltip')}
                    >
                      <span>{`${t('assetName.label')} ${t('assetName.orISIN')}`}</span>
                      <span className="ml-2 px-1.5 rounded-full bg-white border-[1px] border-gray-300 text-gray-300 text-xs font-bold">i</span>
                    </ToolTip>
                  </Listbox.Label>
                  <div className="relative mt-1">
                    <Listbox.Button
                      className={`relative w-full cursor-default rounded-md ${(errors.displaySymbol?.length > 0) ? 'border-2 border-red-500' : 'border border-gray-300'}
              bg-white py-2 pl-3 pr-10 text-left shadow-sm
              focus:border-brandBlue-500 focus:outline-none focus:ring-1 focus:ring-brandBlue-600 sm:text-sm`}
                      id="dropdown"
                      data-testid="assetName"
                    >
                      <span className="block truncate">{field.value.displayName}</span>
                      <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                        <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                      </span>
                    </Listbox.Button>

                    <Transition
                      show={open}
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Listbox.Options
                        className="absolute z-20 mt-2 p-2 bg-white block rounded-md border border-gray-300 shadow-md sm:text-sm"
                      >
                        {assetIds.map((asset) => (
                          <Listbox.Option
                            key={asset.assetId}
                            value={asset}
                            className={({ active }) => `${active ? 'text-white bg-brandBlue-500' : 'text-gray-900'} cursor-default select-none relative py-2 pl-3 pr-9 flex items-baseline`}
                          >
                            {({ selected, active }) => (
                              <>
                                <span className={`${selected ? 'font-semibold' : 'font-normal'} block truncate`}>
                                  {`${asset.displayName} ${asset.displaySymbol ? ` | ${asset.displaySymbol}` : ''}`}
                                </span>
                                {selected ? (
                                  <span className={`${active ? 'text-white' : 'text-brandBlue-500'} absolute inset-y-0 right-0 flex items-center pr-4`}>
                                    <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                  </span>

                                ) : null}
                              </>
                            )}
                          </Listbox.Option>
                        ))}
                      </Listbox.Options>
                    </Transition>
                  </div>
                </>
              )}
            </Listbox>
          );
        }}
      />
      <div className="pt-2 sm:text-sm text-brandRed-500">
        <span>{errors.displayName?.message}</span>
      </div>
    </>
  );
}
AssetNamePicklist.propTypes = {
  control: PropTypes.object.isRequired,
  assetIds: PropTypes.array.isRequired,
  account: PropTypes.object,
  t: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
};
AssetNamePicklist.defaultProps = {
  account: null,
};
