import { Auth } from 'aws-amplify';
import { customAlphabet } from 'nanoid';
import dayjs from 'dayjs';
import i18n from '../../i18n';
import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  REGISTER_CONFIRMATION_SUCCESS,
  REGISTER_CONFIRMATION_FAIL,
  RESEND_CONFIRMATION_EMAIL_SUCCESS,
  RESEND_CONFIRMATION_EMAIL_FAIL,
  LOGOUT,
  SET_MESSAGE,
  UPDATE_TOURS_SUCCESS,
  UPDATE_TOURS_FAIL,
  SET_GLOBAL_TOUR_LOCK,
  CLEAR_GLOBAL_TOUR_LOCK,
  UPDATE_COGNITO_SETTINGS_SUCCESS,
  UPDATE_USER_ATTRIBUTES_SUCCESS,
  UPDATE_CONSENT,
} from './types';

import AuthService from '../services/auth.service';

import getQuotes from '../reducers/sharedThunks/getQuotes';
import { calculateDatesAndAssetIds } from './data/helpers';
import { globalBaselineView } from '../reducers/globalSelectors';
import { assetBalancesArray } from '../reducers/data';

const utc = require('dayjs/plugin/utc');

dayjs.extend(utc);

export const register = (user) => (dispatch) => AuthService.register(user).then(
  (response) => {
    console.log(response);
    dispatch({
      type: REGISTER_SUCCESS,
      payload: response,
    });

    return Promise.resolve();
  },
  (error) => {
    dispatch({
      type: REGISTER_FAIL,
    });
    return Promise.reject();
  },
);

// complete federated signup; first step is handled by Auth.register(), this is the second step
// expects the federated email address; creates a cognito account for that address and then triggers to federate the external user with the cognito user
// (handled in the Cognito lambda PreSignUp trigger)
// https://monestry.atlassian.net/wiki/spaces/TECH/pages/240222776/Payments+process

export const registerFederated = (email) => (dispatch) => new Promise((resolve, reject) => {
  console.log('Redux action creators > USER: Create Cognito account for external user:', email);

  // get data from storage
  const user = JSON.parse(localStorage.getItem('signUpObject'));

  // delete password from storage
  delete user.password;
  localStorage.setItem('signUpObject', JSON.stringify(user));

  function createPassword() {
    // we need to make sure there is at least 1 capital letter, 1 small letter, 1 number and 1 special character
    const nanoid = customAlphabet('abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+-=[]{}|;:,./<>?', 20);
    const nanoidCap = customAlphabet('ABCDEFGHIJKLMNOPQRSTUVWXYZ', 1);
    const nanoidNum = customAlphabet('0123456789', 1);
    const nanoidSpecial = customAlphabet('!@#$%^&*()_+-=[]{}|;:,./<>?', 1);
    return `${nanoidCap()}${nanoid()}${nanoidNum()}${nanoidSpecial()}`;
  }

  // create new user in Cognito with a random password (if user ever wants to use username/password login, they can have it reset)
  // need to remove provider to run Auth.register in Cognito mode
  // do not use the email, as it will send the verification code (and the user wants to use the external IdP email)
  // email will be added in the Cognito lambda PreSignUp trigger
  AuthService.register({
    ...user,
    email,
    password: `${createPassword()}`,
    provider: undefined,
    putEmailInCustomSignupAttribute: true,
  }).then(
    (response) => {
      console.log('Redux action creators > USER: Cognito account created, now federate with external IdP provider.');
      Auth.federatedSignIn({ provider: user.provider }).then(
        // once the Cognito account is there, reattempt federated signup
        // the above call this will anyway result in a redirect, so it doesn't matter much what happens hereafter
        () => {
          resolve();
        },
        (error) => {
          reject(error);
        },
      );
    },
    (error) => {
      console.error('Redux action creators > USER: Error detected in AuthService.register', error);
      reject(error);
    },
  );
});

export const resendConfirmationEmail = (user) => (dispatch) => AuthService.resendConfirmationEmail(user).then(
  (response) => {
    console.log(response);
    dispatch({
      type: RESEND_CONFIRMATION_EMAIL_SUCCESS,
      payload: response,
    });
    return Promise.resolve();
  },
  (error) => {
    dispatch({
      type: RESEND_CONFIRMATION_EMAIL_FAIL,
    });

    return Promise.reject();
  },
);

export const confirmUser = (username, code) => (dispatch) => new Promise((resolve, reject) => {
  AuthService.confirmUser(username, code).then(
    (response) => {
      console.log(response);
      dispatch({
        type: REGISTER_CONFIRMATION_SUCCESS,
        payload: response,
      });
      return resolve();
    },
    (error) => {
      dispatch({
        type: REGISTER_CONFIRMATION_FAIL,
      });

      return reject(error);
    },
  );
});

export const logout = () => (dispatch) => {
  AuthService.logout();

  dispatch({
    type: LOGOUT,
  });
};

export const updateUserAttribute = (attributeName, attributeValueAsString) => (dispatch) => Promise.resolve(
  AuthService.updateUserAttribute(attributeName, attributeValueAsString).then(
    (data) => {
      try {
        dispatch({
          type: UPDATE_USER_ATTRIBUTES_SUCCESS,
        });
      } catch (error) {
        console.error(error);
      }
      if (attributeName !== 'email') {
        // for email one still needs a confirmation code, so can't display success message just yet
        dispatch({
          type: SET_MESSAGE,
          payload: 'profileUpdatedSuccessfully',
        });
      }

      return Promise.resolve();
    },
    (error) => {
      console.error('UpdateUserAttribute failed: ', error, error.message);

      if (error.message === 'cognitoUpdateFailed') {
        dispatch({
          type: SET_MESSAGE,
          payload: 'profileCouldNotBeUpdated',
        });
      }
      return Promise.reject(error.stack);
    },
  ),
);

export const changePassword = (currentPassword, newPassword) => (dispatch) => Promise.resolve(
  AuthService.changePassword(currentPassword, newPassword).then(
    (data) => {
      console.info('ChangePassword successful: ', data);
      try {
        dispatch({
          type: UPDATE_USER_ATTRIBUTES_SUCCESS,
        });
      } catch (error) {
        console.error(error);
      }
      dispatch({
        type: SET_MESSAGE,
        payload: 'profileUpdatedSuccessfully',
      });

      return Promise.resolve();
    },
    (error) => {
      if (error.message === 'Incorrect username or password.') {
        dispatch({
          type: SET_MESSAGE,
          payload: 'incorrectPassword',
        });
      } else if (error.message === 'Attempt limit exceeded, please try after some time.') {
        dispatch({
          type: SET_MESSAGE,
          payload: 'attemptLimitExceeded',
        });
      } else {
        dispatch({
          type: SET_MESSAGE,
          payload: 'profileCouldNotBeUpdated',
        });
      }

      return Promise.reject(error.stack);
    },
  ),
);

// eslint-disable-next-line max-len
export const addSeenTours = (payload) => (dispatch) => AuthService.addSeenTours(payload).then(
  // returns an array of tours seen by user from Cognito [ 'tour1', 'tour2', 'tour3' ]
  (attributes) => {
    dispatch({
      type: UPDATE_TOURS_SUCCESS,
      payload: attributes,
    });
    console.log('update attributes - dispatch complete');

    return Promise.resolve();
  },
  (error) => {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

    dispatch({
      type: UPDATE_TOURS_FAIL,
    });

    dispatch({
      type: SET_MESSAGE,
      payload: message,
    });

    return Promise.reject();
  },
);

// sets global tour lock for onboarding tours so that only one can be displayed at any given time
export const setTourLock = () => (dispatch, getState) => {
  // if global tour lock is available (is not true), set it to true and return true to the caller
  if (!getState().user.tourLock) {
    dispatch({
      type: SET_GLOBAL_TOUR_LOCK,
    });
    return true;
  }
  return false;
};

export const clearTourLock = () => (dispatch) => {
  dispatch({
    type: CLEAR_GLOBAL_TOUR_LOCK,
  });
};

export const updateCognitoSettings = (data) => (dispatch, getState) => {
  // data is the settings object { namedBaseline, baselineDate, etc. }
  // the settings in Redux are initialised from storage, they are retrieved from Cognito only upon login

  AuthService.updateCognitoSettings(data); // we are not waiting for the response
  dispatch({
    type: UPDATE_COGNITO_SETTINGS_SUCCESS,
    payload: { settings: data },
  });
  console.log('test - after dispatch to Cognito, do we see the new baselines already?', globalBaselineView(getState()));
  // baselineDate has been updated, now we need to get new quotes for the new baseline / referenceBaseline ()
  if (data.baselineDate) {
    const { baseline, referenceBaseline, current } = globalBaselineView(getState());

    const assetIds = assetBalancesArray(getState())
      .map((a) => ({
        assetId: a.assetId,
        providerAssetId: a.providerAssetId,
        transactionCurrency: a.transactionCurrency,
      }));
    // only get named dates update, the rest can stay the same
    dispatch(getQuotes(calculateDatesAndAssetIds(assetIds, baseline, referenceBaseline, current, true)));
    // if baseline and/or referenceBaseline dates are in the future, quotes API will ignore them and not return any data
    // all quotes for the future are to be calculated based on growth factors (or will come indirectly from the purchase / sale prices of assets)
  }
  return Promise.resolve();
};

export function updateConsent(consent) {
  return function updateConsentInner(dispatch) {
    // update consent in localStorage
    localStorage.setItem('consent', JSON.stringify(consent));
    dispatch({
      type: UPDATE_CONSENT,
      payload: consent,
    });

    return Promise.resolve();
  };
}
