/* eslint-disable max-len */
import dayjs from 'dayjs';
import { nanoid } from 'nanoid';
import i18n from '../i18n';

const utc = require('dayjs/plugin/utc');

dayjs.extend(utc);

// take transaction object and clone it, changing the 'date' field beginning at fromDate, ending at toDate, and incrementing by interval + intervalType (1 'month')
function cloneTransaction({ fromDate, toDate, transaction, interval = 12, intervalType = 'month' }) {
  if (!fromDate || !toDate || !transaction) throw new Error('cloneTransaction: missing arguments');

  const clonedTransaction = { ...transaction };
  const clonedTransactions = [];
  let date = fromDate;
  while (date.isBefore(toDate)) {
    clonedTransaction.id = nanoid();
    clonedTransaction.date = date.startOf('day').valueOf();
    clonedTransactions.push({ ...clonedTransaction });
    date = date.add(interval, intervalType);
  }
  return clonedTransactions;
}

// get category from account / transaction object
// get account Id by referencing data.accounts[index]
export default function seedInitData(t) {
  // const t = i18n.t.bind(i18n);
  // putting the template objects here, so that the t function can be applied
  // template accounts
  const stockAccount = {
    category: 'stocks',
    subcategory: null,
    statusTimestamp: null,
    syncType: null,
    ownCurrentAccount: null,
    connectedDepositAccounts: null,
    accountNo: '',
    username: '',
    name: 'DUMMY ACCOUNT',
    brokerName: '',
    id: null,
    tags: null,
    importFileMappings: null,
  };

  const depositAccount = {
    category: 'deposits',
    bankName: 'My Demo Bank',
    name: 'DUMMY',
    iban: 'DE49201304000055555551',
    bic: 'ABCDEFGHIJ',
    currency: 'EUR',
    syncType: 'manual',
    depositType: 'savings-account',
    tags: null,
    importFileMappings: null,
    countryCode: 'DE',
  };

  const realEstateAccount = {
    category: 'realEstate',
    connectedDepositAccounts: null,
    lastMarketPriceRetrieved: null,
    statusTimestamp: null,
    status: null,
    currency: 'EUR',
    name: 'DUMMY property',
    id: null,
    tags: { growthRate: 3.2 },
    valuationParameters: {
      garage: false,
      constructionYear: null,
      lotArea: null,
      rooms: 5,
      livingArea: 100,
      countryCode: 'DE',
      city: 'Bielefeld',
      zip: '33602',
      streetAddress: '',
      type: 'apartment',
    },
  };

  const depositTransaction = {
    category: 'deposits',
    id: null,
    accountId: null,
    date: new Date(),
    otherParty: t('otherParty'),
    otherPartyAccount: null,
    description: 'DUMMY',
    amount: 1000.5,
    currency: 'EUR',
    accountCurrencyAmount: 1000.5,
    fxAmount: null,
    fxCurrency: null,
    batchId: null,
    linkedTransactions: [],
    isSimulated: false,
    isIsolated: false,
    isManuallyAdjusted: false,
    projectId: null,
    parentId: null,
    errorId: null,
    importFlag: null,
    label: null,
    tags: {
      assetId: null,
      accountId: null,
      indexed: null,
      recurring: null,
      type: null,
    },
    sortingOrderWithinMonth: null,
    importFileMappings: null,
  };

  const stocksTransaction = {
    category: 'stocks',
    sortingOrderWithinMonth: null,
    importFlag: 'post',
    tags: {
      indexed: null,
    },
    projectId: null,
    rebasedAmountSoldWhenSimulated: null,
    rebasedAmountSold: null,
    transactionAmountSold: null,
    errorId: null,
    isManuallyAdjusted: false,
    isIsolated: false,
    isSimulated: false,
    linkedTransactions: [],
    rebasedOriginalPrice: null,
    rebasedPrice: null,
    transactionType: 'purchase',
    rebasedAmount: null,
    transactionPrice: null,
    transactionOriginalPrice: 89.32,
    transactionCurrency: 'EUR',
    transactionAmount: 20,
    splitRatioNew: null,
    splitRatioOld: null,
    displayName: 'DE12345',
    displaySymbol: 'DE12345',
    providerAssetId: null,
    figi: null,
    date: 1688169600000,
    id: 'piFWNgHTjNOl2tydBHCMV',
    accountId: 'DUMMYstockAccount0001',
    currency: 'EUR',
  };

  const realEstateTransaction = {
    category: 'realEstate',
    tags: {
      indexed: null,
      recurring: null,
      type: null,
    },
    sortingOrderWithinMonth: null,
    importFlag: 'post',
    projectId: null,
    isIsolated: false,
    isSimulated: false,
    linkedTransactions: [],
    valid: true,
    transactionType: 'purchase',
    currency: 'EUR',
    price: 500000,
    originalPrice: 500000,
    amount: 1,
    date: 1661388800000,
    accountId: null,
    id: undefined,
    errorId: null,
  };

  const date0 = dayjs.utc();
  const depositAccount0Id = nanoid();
  const depositAccount1Id = nanoid();
  const depositAccount2Id = nanoid();
  const stockAccount1Id = nanoid();
  const realEstateAccount1Id = nanoid();
  const aaplDepositSide = nanoid();
  const tslaDepositSide = nanoid();
  const realEstateDepositSide = nanoid();
  const project0 = nanoid();
  return {
    accounts: [
      {
        // 0
        ...depositAccount,
        id: depositAccount0Id,
        name: t('account0'),
        iban: 'DE49201304000055550001',
      },
      {
        // 1
        ...depositAccount,
        id: depositAccount1Id,
        name: t('account1'),
        iban: 'DE68500105178297336485',
      },
      {
        // 2
        ...depositAccount,
        id: depositAccount2Id,
        name: t('account2'),
        iban: 'DE88500105176414922794',
        depositType: 'savings-account',
      },
      {
        // 3
        ...stockAccount,
        id: stockAccount1Id,
        name: t('account3'),
        connectedDepositAccounts: [depositAccount1Id],
      },
      {
        // 4
        ...realEstateAccount,
        id: realEstateAccount1Id,
        name: t('account4'),
        connectedDepositAccounts: [depositAccount0Id],
      },
    ],
    transactions: [
      // stocks account goes first, we need to update quotes
      {
        // 0
        ...stocksTransaction,
        id: nanoid(),
        accountId: stockAccount1Id,
        transactionOriginalPrice: 150,
        transactionCurrency: 'USD',
        currency: 'USD',
        date: date0.subtract(19, 'months').startOf('day').valueOf(),
        transactionAmount: 10,
        displayName: 'Apple Inc.',
        displaySymbol: 'US0378331005',
        linkedTransactions: [
          {
            id: aaplDepositSide,
            category: 'deposits',
            tags: {
              type: 'buy',
              linkType: 'purchase-to-transaction',
            },
          },
        ],
      },
      {
        // 1
        ...stocksTransaction,
        id: nanoid(),
        accountId: stockAccount1Id,
        transactionOriginalPrice: 200,
        transactionCurrency: 'USD',
        currency: 'USD',
        date: date0.subtract(18, 'months').startOf('day').valueOf(),
        transactionAmount: 10,
        displayName: 'Tesla',
        displaySymbol: 'US88160R1014',
        linkedTransactions: [
          {
            id: tslaDepositSide,
            category: 'deposits',
            tags: {
              type: 'buy',
              linkType: 'purchase-to-transaction',
            },
          },
        ],
      },
      // brokerage bank account
      {
        // 2
        ...depositTransaction,
        id: nanoid(),
        description: t('initialSaldo'),
        amount: 50000,
        accountCurrencyAmount: 50000,
        accountId: depositAccount1Id,
        date: date0.subtract(40, 'months').startOf().valueOf(),
      },
      {
        // 3 -- AAPL US0378331005
        ...depositTransaction,
        id: aaplDepositSide,
        description: t('purchaseAppleStock'),
        amount: null,
        accountCurrencyAmount: null,
        fxCurrency: 'USD',
        fxAmount: undefined,
        accountId: depositAccount1Id,
        date: date0.subtract(19, 'months').startOf('day').valueOf(),
        label: 'investment-purchase',
      },
      {
        // 4
        ...depositTransaction,
        id: nanoid(),
        description: t('transactionFees'),
        amount: -9.9,
        accountCurrencyAmount: -9.9,
        accountId: depositAccount1Id,
        date: date0.subtract(19, 'months').startOf('day').valueOf(),
        label: 'investment-fees',
        tags: { ...depositTransaction.tags, assetId: 'AAPL', accountId: stockAccount1Id },
      },
      {
        // 5 -- TSLA US88160R1014
        ...depositTransaction,
        id: tslaDepositSide,
        description: t('purchaseTeslaStock'),
        amount: null,
        accountCurrencyAmount: null,
        fxCurrency: 'USD',
        fxAmount: undefined,
        accountId: depositAccount1Id,
        date: date0.subtract(18, 'months').startOf('day').valueOf(),
        label: 'investment-purchase',
      },
      {
        // 6
        ...depositTransaction,
        id: nanoid(),
        description: t('transactionFees'),
        amount: -9.9,
        accountCurrencyAmount: -9.9,
        accountId: depositAccount1Id,
        date: date0.subtract(18, 'months').startOf('day').valueOf(),
        label: 'investment-fees',
        tags: { ...depositTransaction.tags, assetId: 'TSLA', accountId: stockAccount1Id },
      },
      {
        // 7
        ...depositTransaction,
        id: nanoid(),
        description: t('accountRunningCost'),
        amount: -19.9,
        accountCurrencyAmount: -19.9,
        accountId: depositAccount1Id,
        date: date0.subtract(16, 'months').startOf('month').valueOf(),
        label: 'investment-costs',
        tags: { ...depositTransaction.tags, assetId: stockAccount1Id, accountId: stockAccount1Id },
      },
      {
        // 8
        ...depositTransaction,
        id: nanoid(),
        description: t('accountRunningCost'),
        amount: -19.9,
        accountCurrencyAmount: -19.9,
        accountId: depositAccount1Id,
        date: date0.subtract(4, 'months').startOf('month').valueOf(),
        label: 'investment-costs',
        tags: { ...depositTransaction.tags, assetId: stockAccount1Id, accountId: stockAccount1Id },
      },
      {
        // 9
        ...depositTransaction,
        id: nanoid(),
        description: t('dividendAppleStock'),
        amount: 25.3,
        accountCurrencyAmount: 25.3,
        fxAmount: 27.2,
        fxCurrency: 'USD',
        accountId: depositAccount1Id,
        date: date0.subtract(13, 'months').startOf('month').valueOf(),
        label: 'investment-dividend',
        tags: { ...depositTransaction.tags, assetId: 'AAPL', accountId: stockAccount1Id },
      },
      {
        // 10
        ...depositTransaction,
        id: nanoid(),
        description: t('dividendAppleStock'),
        amount: 25.3,
        accountCurrencyAmount: 25.3,
        fxAmount: 27.2,
        fxCurrency: 'USD',
        accountId: depositAccount1Id,
        date: date0.subtract(1, 'months').startOf('month').valueOf(),
        label: 'investment-dividend',
        tags: { ...depositTransaction.tags, assetId: 'TESL', accountId: stockAccount1Id },
      },
      // my bank account - historical transactions
      {
        ...depositTransaction,
        id: nanoid(),
        description: t('initialSaldo'),
        amount: 20000,
        accountCurrencyAmount: 20000,
        accountId: depositAccount0Id,
        date: date0.subtract(48, 'months').startOf('day').valueOf(),
      },
      // *** if using cloneTransaction, put all transactions that will be updated by the calling component before them, as they need to be updated by index in this array ***
      ...cloneTransaction({
        fromDate: date0.subtract(2, 'years'),
        toDate: date0,
        transaction: {
          ...depositTransaction,
          description: t('salary'),
          amount: 7500,
          accountCurrencyAmount: 7500,
          accountId: depositAccount0Id,
        },
        interval: 1,
      }),
      ...cloneTransaction({
        fromDate: date0.subtract(2, 'years').add(5, 'day'),
        toDate: date0,
        transaction: {
          ...depositTransaction,
          description: t('rentPayment'),
          amount: -1500,
          accountCurrencyAmount: -1500,
          accountId: depositAccount0Id,
        },
        interval: 1,
      }),
      ...cloneTransaction({
        fromDate: date0.subtract(2, 'years').add(10, 'day'),
        toDate: date0,
        transaction: {
          ...depositTransaction,
          description: t('creditCardBill'),
          amount: -2500,
          accountCurrencyAmount: -2500,
          accountId: depositAccount0Id,
        },
        interval: 1,
      }),
      {
        ...depositTransaction,
        id: nanoid(),
        description: t('carRental'),
        amount: 1250,
        accountCurrencyAmount: 1250,
        fxAmount: 1380,
        fxCurrency: 'CHF',
        accountId: depositAccount0Id,
        date: date0.subtract(13, 'months').startOf('day').valueOf(),
      },
      {
        ...depositTransaction,
        id: nanoid(),
        description: t('hotelBill'),
        amount: 1500,
        accountCurrencyAmount: 1500,
        fxAmount: 1550,
        fxCurrency: 'CHF',
        accountId: depositAccount0Id,
        date: date0.subtract(13, 'months').add(10, 'days').startOf('day').valueOf(),
      },
      // my bank account - simulated transactions
      {
        ...depositTransaction,
        id: nanoid(),
        description: t('salary'),
        date: dayjs.utc().add(1, 'month').startOf('month').valueOf(),
        amount: 7500,
        accountCurrencyAmount: 7500,
        accountId: depositAccount0Id,
        isSimulated: true,
        projectId: project0,
        tags: {
          indexed: null,
          recurring: {
            endAfterDate: 1686823434489,
            endAfterOccurrences: 48,
            end: 'recurringByOccurrence',
            periodType: {
              id: 'month',
              name: 'months',
            },
            numberOfPeriods: 1,
            activated: true,
          },
          type: 'inflow',
          accountId: null,
          assetId: null,
          indexing: {
            activated: true,
            mode: 'indexedByCustomRate',
            customRateInput: '2',
          },
        },
        transactionType: 'inflow',
      },
      {
        ...depositTransaction,
        id: nanoid(),
        date: dayjs.utc().add(1, 'month').startOf('month').valueOf(),
        description: t('rentPayment'),
        amount: -1500,
        accountCurrencyAmount: -1500,
        accountId: depositAccount0Id,
        isSimulated: true,
        projectId: project0,
        tags: {
          indexed: null,
          recurring: {
            endAfterDate: 1686823434489,
            endAfterOccurrences: 48,
            end: 'recurringByOccurrence',
            periodType: {
              id: 'month',
              name: 'months',
            },
            numberOfPeriods: 1,
            activated: true,
          },
          type: 'outflow',
          accountId: null,
          assetId: null,
          indexing: {
            activated: true,
            mode: 'indexedByCustomRate',
            customRateInput: '2',
          },
        },
        transactionType: 'outflow',
      },
      {
        ...depositTransaction,
        id: nanoid(),
        date: dayjs.utc().add(1, 'month').startOf('month').valueOf(),
        description: t('creditCardBill'),
        amount: -2500,
        accountCurrencyAmount: -2500,
        accountId: depositAccount0Id,
        isSimulated: true,
        projectId: project0,
        tags: {
          indexed: null,
          recurring: {
            endAfterDate: 1686823434489,
            endAfterOccurrences: 48,
            end: 'recurringByOccurrence',
            periodType: {
              id: 'month',
              name: 'months',
            },
            numberOfPeriods: 1,
            activated: true,
          },
          type: 'outflow',
          accountId: null,
          assetId: null,
          indexing: {
            activated: true,
            mode: 'indexedByCustomRate',
            customRateInput: '2',
          },
        },
        transactionType: 'outflow',
      },
      // savings account
      {
        ...depositTransaction,
        id: nanoid(),
        description: t('initialSaldo'),
        amount: 100000,
        accountCurrencyAmount: 100000,
        accountId: depositAccount2Id,
        date: date0.subtract(46, 'months').startOf('day').valueOf(),
      },
      {
        ...depositTransaction,
        id: nanoid(),
        description: t('interestPayment'),
        amount: 120,
        accountCurrencyAmount: 120,
        accountId: depositAccount2Id,
        date: date0.subtract(3, 'months').startOf('day').valueOf(),
        label: 'investment-interest',
      },
      // real estate account
      //
      {
        ...realEstateTransaction,
        id: nanoid(),
        accountId: realEstateAccount1Id,
        date: date0.subtract(7, 'years').startOf('day').valueOf(),
        linkedTransactions: [{ id: realEstateDepositSide, category: 'deposits', tags: { type: 'buy', linkType: 'purchase-to-transaction' } }],
        price: 500000,
        originalPrice: 500000,
      },
    ],
    // CAUTION: quotes are hardcoded to realEstate in Prep for now
    quotes: [
      {
        assetId: realEstateAccount1Id,
        date: date0.subtract(5, 'years').startOf('day').valueOf(),
        quote: 540000,
        currency: 'EUR',
        quoteBaseCurrency: 540000,
      },
      {
        assetId: realEstateAccount1Id,
        date: date0.subtract(3, 'years').startOf('day').valueOf(),
        quote: 565000,
        currency: 'EUR',
        quoteBaseCurrency: 565000,
      },
      {
        assetId: realEstateAccount1Id,
        date: date0.subtract(1, 'years').startOf('day').valueOf(),
        quote: 582000,
        currency: 'EUR',
        quoteBaseCurrency: 582000,
      },
    ],
    projects: [
      {
        id: project0,
        name: t('project0'),
        settings: {
          progressIndicator: 'roi',
          currency: 'EUR',
          isIsolated: false,
        },
        comments: [],
      },
    ],
  };
}
