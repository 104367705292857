/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { useState, useEffect } from 'react';
import { PivotData } from 'react-pivottable/Utilities';
import { useSelector, useDispatch } from 'react-redux';
import { ResponsiveSunburst as Sunburst } from '@nivo/sunburst';
import { useTranslation } from 'react-i18next';
import { ArrowsPointingOutIcon } from '@heroicons/react/24/solid';
import { setFullscreenReport } from '../../../redux/actions/message';
import CustomTooltip from '../CustomTooltip';
import { decor } from '../../../elements/tileDecor';

const colors = ['rgba(23, 151, 242, 1)', 'rgba(146, 208, 80, 1)', 'rgba(187, 109, 206, 1)', 'rgba(255, 214, 0, 1)', 'rgba(237, 28, 35, 1)', 'rgba(46, 49, 146, 1'];

const debugFlag = 0;

function CenteredMetric(props) {
  const { nodes, centerX, centerY } = props;
  const total = nodes.reduce((totl, item) => (item.depth === 1 ? totl + item.value : totl), 0);

  const baseCurrency = useSelector((state) => state.user.profile.settings.baseCurrency);

  return (
    <text
      x={centerX}
      y={centerY}
      textAnchor="middle"
      dominantBaseline="central"
      style={{
        fontSize: '32px',
        fontWeight: 700,
      }}
    >
      {Number.parseFloat(`${total}`).toLocaleString('de', { style: 'currency', currency: baseCurrency || 'EUR', maximumFractionDigits: 0 })}
    </text>
  );
}

export function SunburstChart(props) {
  const pivotData = new PivotData(props);
  const rowKeys = pivotData.getRowKeys();
  const colKeys = pivotData.getColKeys();

  const hideFullscreenButton = useSelector((state) => !state.message.fullscreenReport);

  const [chartErrorMessage, setChartErrorMessage] = useState(null);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  let fullAggName = props.aggregatorName;
  const numInputs = props.aggregators[fullAggName]([])().numInputs || 0;
  if (numInputs !== 0) {
    fullAggName += ` of ${props.vals.slice(0, numInputs).join(', ')}`;
  }

  // all the possible combinations of main key category fields [[2015, 'DKB Giro'], [2017, 'Festgeld'],[2019, 'Festgeld'] ...]
  if (debugFlag > 2) console.log('rowKeys', rowKeys);
  // all the possible combinations of the secondary key category fields [['American Express', 'EUR'], ['Arbeitgeber AG', 'EUR'], ['Avis AG', 'EUR']...]
  if (debugFlag > 2) console.log('colKeys', colKeys);
  if (debugFlag > 2) console.log('pivotData with props', pivotData);

  // rowKeys and colKeys have all the needed combinations of keys already
  // e.g. [[2015, 'EUR'], [2015, 'USD'], [2016, 'EUR'], [2017, 'EUR']]
  // even if there are more than 1 attribute in each group
  // so all that needs to happen is to produce for each object (which is an array) in rowKeys
  // an object with the joinedKey
  const rowNames = pivotData.props.rows; // ["Date (Year)", "accountName"]
  const colNames = pivotData.props.cols; // names of the fields that go to secondary category ["otherParty", "currency"]

  let data = [];

  // transform data from PivotTable to hierarchy structure
  const rowKeysByName = rowNames.reduce(
    (prevRowName, currRowName, rowNameIdx) => ({
      ...prevRowName,
      [currRowName]: rowKeys.reduce((prevRowKey, currRowKey) => {
        if (!prevRowKey.includes(currRowKey[rowNameIdx])) prevRowKey.push(currRowKey[rowNameIdx]);
        return prevRowKey;
      }, []),
    }),
    {},
  );
  const colKeysByName = colNames.reduce(
    (prevColName, currColName, colNameIdx) => ({
      ...prevColName,
      [currColName]: colKeys.reduce((prevColKey, currColKey) => {
        if (!prevColKey.includes(currColKey[colNameIdx])) prevColKey.push(currColKey[colNameIdx]);
        return prevColKey;
      }, []),
    }),
    {},
  );

  const keysByName = { ...rowKeysByName, ...colKeysByName };
  if (debugFlag > 2) console.info('keysByName', keysByName);
  // this has all unique values organised by field name, i.e.:
  // { 'Date (Year)': [2015, 2016, 2017, 2018, 2019], 'accountName': ['DKB Giro', 'Festgeld', 'Girokonto', 'Kreditkarte', 'Sparkonto'], ... }
  // the sequence of keys is the hierarchy structure, i.e.: first object goes on top level, then the next object goes as a child of the first object, etc.
  // TODO this has some room for improvement, because this includes all possible combinations of keys, and not only those with data

  const valueToBeAggregated = pivotData.props.vals[0];

  function getAggregate(parents) {
    // takes the array with all parent key-value arrays and returns the sum of all values matching those criteria
    const returnObject = pivotData.props.data
      .filter((x) => x.displayName !== 'Σ' && x.accountName !== 'Σ')
      .reduce((prev, curr) => {
        if (curr.formattingLevel) return prev; // for performance reasons, skip all summary rows
        // go through all summary rows, lowest level only
        // only add value when ALL the parent level's conditions from 'parents' are met
        // i.e. if parents is [['category', 'deposits'], ['accountName', 'DKB Giro']]
        // then only add value when both conditions are met
        if (debugFlag > 2) console.info('testing condition match for parents ', JSON.stringify(parents), ' and record ', curr);
        if (parents.every((parent) => curr[parent[0]] === parent[1])) {
          if (debugFlag > 2) console.info('found condition match for parents ', JSON.stringify(parents), ' which is ', curr);
          return prev + curr[valueToBeAggregated];
        }
        return prev;
      }, 0);
    if (debugFlag > 2) console.info('returning aggregate', returnObject, ' for parents ', JSON.stringify(parents), ' and field being aggregated ', valueToBeAggregated);
    return returnObject;
  }

  const hierarchyLevels = Object.keys(keysByName).length;

  // CAUTION: Nivo seems to expect each node to have a unique id, so we need to split the label from the id
  function generateChildrenHierarchy(keyIndex, parents = []) {
    // stop recursion when the lowest level is reached
    if (keyIndex === hierarchyLevels) return [];
    // keyIndex is the level of hierarchy, starts with 0 and adds 1 for each level
    // parents is a "key-value" array containing all "group by" fields and their values, i.e. [['category', 'deposits'], ['accountName', 'DKB Giro']]
    if (debugFlag > 2) console.info('starting generateChildrenHierarchy for keyIndex', keyIndex, ', parents ', parents);
    // keysByName contains all values of fields:
    // { 'Date (Year)': [2015, 2016, 2017, 2018, 2019], 'accountName': ['DKB Giro', 'Festgeld', 'Girokonto', 'Kreditkarte', 'Sparkonto'], ... }

    const returnGenerateChildrenHierarchy = Object.values(keysByName)[keyIndex]?.reduce((cum, val) => {
      // for all values of the field, i.e. 'Date (Year)' - 2007, 2009, 2010 ...
      const newParents = [...parents, [Object.keys(keysByName)[keyIndex], val]]; // add the current key-value pair, to be passed to child recursion
      const children = generateChildrenHierarchy(keyIndex + 1, newParents); // this returns [] when recursion bottom is reached
      // calculate the value of all children, if this is null, then skip this node
      let value = getAggregate(newParents);
      if (value === 0) return cum;
      // handle the data exception: it is impossible to display a negative chart value
      if (value < 0) {
        value = 0;
      }

      const returnObject = {
        // name: val,
        name: newParents.map((parent) => parent[1]).join(' - '), // CAUTION: if ever changing this to a different separator, make sure to update it in getDrillDownColor
        label: val,
        // insert key-value pair of current level as a parent, i.e. ['category', 'deposits']
        parents: newParents,
      };
      // prevent adding children if there is only one, it has the same name and it has a value (i.e. it is the last level)
      if (keyIndex === Object.keys(keysByName).length - 1 || (children.length === 1 && children[0].value === value && children[0].label === val)) {
        // if this is the last level, then add the value
        returnObject.value = value;
      } else {
        returnObject.children = children;
      }
      return [...cum, returnObject];
    }, []);
    if (debugFlag > 2) console.info('keyIndex', keyIndex, 'parents', parents, 'returnedObject', returnGenerateChildrenHierarchy);
    return returnGenerateChildrenHierarchy;
  }

  // generate hierarchical data structure
  data = {
    name: 'root',
    children: generateChildrenHierarchy(0),
  };

  // sunburst chart with drill-down needs a state to manipulate the data when user wants to drill down
  // initialise state with data object, if avaliable
  const [chartData, setChartData] = useState(data);
  useEffect(() => {
    // make sure data object is updated when the data are updated
    setChartData(data);
  }, [pivotData.props.data, rowNames, colNames, pivotData.props.vals]);

  // helper functions to enable drill-down in Sunburst chart
  const flatten = (dataLocal) => dataLocal.reduce((acc, item) => {
    if (item.children) {
      return [...acc, item, ...flatten(item.children)];
    }

    return [...acc, item];
  }, []);

  function findObject(dataLocal, name) {
    return dataLocal.find((searchedName) => searchedName.name === name);
  }

  // looks up the "official" colors based on category
  function getAccountColor(accountCategory) {
    if (accountCategory) {
      return decor[accountCategory].color.hex;
    }
    return null;
  }

  // colors are assigned to the first level of hierarchy (e.g. category for the assets sunburst chart)
  // the first attribute of keysByName object will have an array with those values
  function getDrillDownColor(node) {
    // converting them both to String is necessary because the values in the keysByName object are strings and the values in the node.parents array are sometimes numbers (like years)
    const itemName = node.id.split(' - ')[0]; // caution: this only works as long as the id follows the naming convention <category> - <accountName>
    const hierarchyLevel = node.path.length - 1; // starts at 0 for root
    const opacityModifier = 1 - hierarchyLevel / 7;
    const topLevelKeyValues = Object.values(keysByName)[0];
    if (!topLevelKeyValues) return '#000000';

    // console.log('DEBUG getDrillDownColor', itemName, node, topLevelKeyValues);

    // try to get the color from the account category; if that doesn't work, assign the same color for the same itemNam
    // apply opacity modifier (hex's format is #rrggbbaa)
    const opacityModifierHex = Math.floor(opacityModifier * 255)
      .toString(16)
      .padStart(2, '0');
    return `${getAccountColor(itemName)}${opacityModifierHex}` || colors[topLevelKeyValues.map((x) => String(x)).indexOf(itemName)]?.replace('1)', `${opacityModifier})`);
  }
  const commonProps = {
    margin: {
      top: 10,
      right: 10,
      bottom: 10,
      left: 10,
    },
    theme: {
      fontFamily: 'Lato',
      tooltip: {
        container: {
          background: '#333',
        },
      },
    },
    padding: 0.2,
    id: 'name',
    value: 'value',
    colors: getDrillDownColor,
    cornerRadius: 2,
    inheritColorFromParent: false,
    animate: true,
    motionConfig: 'gentle',
    // ['default', 'gentle', 'wobbly', 'stiff', 'slow', 'molasses'],
    layers: ['arcs', 'arcLabels', CenteredMetric], // includes the metric in the center
    enableArcLabels: true,
    arcLabel: (node) => node.data.label, // cannot use id: 'name', because Nivo seems to expect all chart elements to have a unique id
    arcLabelsSkipAngle: 20,
    arcLabelsTextColor: {
      from: 'color',
      modifiers: [['darker', 2]],
    },
    tooltip: CustomTooltip,
    transitionMode: 'pushIn',
    data: chartData,
    onClick: (clickedData) => {
      const foundObject = findObject(flatten(chartData.children), clickedData.id);
      if (foundObject && foundObject.children) {
        setChartData(foundObject);
      }
    },
    ...pivotData.props.layoutOptions, // spread any properties which may have been passed to PivotTable
  };

  function handleFullscreen() {
    dispatch(setFullscreenReport(pivotData.props));
  }

  if (debugFlag > 2) console.log(data);

  return (
    <div className="relative w-full h-full">
      <div className="absolute z-10 flex gap-1 items-start">
        <button type="button" className="px-2 py-2 border rounded-md text-sm text-gray-700 hover:bg-gray-50" onClick={() => setChartData(data)}>
          Reset
        </button>
        {hideFullscreenButton && (
          <button type="button" className="px-2 py-2 border rounded-md text-sm text-gray-500 hover:bg-gray-50" onClick={handleFullscreen}>
            <ArrowsPointingOutIcon className="h-5 w-5" />
          </button>
        )}
      </div>
      <Sunburst {...commonProps} />
    </div>
  );
}

function makeNivoRenderer(NivoChart) {
  return SunburstChart;
}

export default function createNivoRenderers() {
  return {
    'Pie chart, multi-layers': makeNivoRenderer(Sunburst),
  };
}
