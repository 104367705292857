/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Controller } from 'react-hook-form';
// import { createSelector } from '@reduxjs/toolkit';
import { Combobox } from '@headlessui/react';
import dayjs from 'dayjs';
import ToolTip from '../../elements/ToolTipNoIcon';
import { assetBalancesArray, projectBalancesArray } from '../../redux/reducers/data';

const utc = require('dayjs/plugin/utc');

dayjs.extend(utc);

export default function AssetNameDropdown({ control, accountCategory, t, errors }) {
  const [assetNameQuery, setAssetNameQuery] = useState('');

  const selectAssets = useSelector(assetBalancesArray) // does not include simulated transactions beyound sliderDate
    .concat(projectBalancesArray) // includes the above with some duplicates of the above, but this is not a problem, because we just need a list of unique ids / symbols
    .filter((item) => item.category === accountCategory);

  const assetSymbols = [...new Set(selectAssets.map((txn) => `${txn.displaySymbol}##${txn.displayName}`))].map((txn) => {
    const [displaySymbol, displayName] = txn.split('##');
    return { displaySymbol, displayName };
  });

  console.log('assetSymbols', assetSymbols);

  const filteredSymbols = assetNameQuery === ''
    ? assetSymbols
    : assetSymbols.filter((s) => s.displayName.toLowerCase().includes(assetNameQuery.toLowerCase()) || s.displaySymbol.toLowerCase().includes(assetNameQuery.toLowerCase())).slice(0, 6);

  return (
    <Controller
      name="displaySymbol"
      control={control}
      defaultValue=""
      render={({ field, ref }) => (
        <Combobox
          as="div"
          className={accountCategory === 'metals' ? 'col-span-2' : ''}
          // show the correct record from assetSymbol, if not found then user input, if not found, show '' to prevent this component to throw a warning uncontrolled > controlled
          value={assetSymbols.find((s) => s.displaySymbol === field.value)?.displaySymbol || field.value || ''}
          onChange={field.onChange}
        >
          {/* FIXME: remove all CSS hover directives you moron */}
          {/* Label and Input limited to 2 cols in metals to make room for metals asset fields */}
          <Combobox.Label className="block text-sm font-medium text-gray-700 mb-1">
            <ToolTip className="text-black border-gray-400 shadow bg-brandYellow-50" info={accountCategory === 'stocks' ? t('assetSymbol.tooltip') : t('assetName.tooltip')}>
              {accountCategory === 'stocks' ? <span>{t('assetSymbol.label')}</span> : <span>{t('assetName.label')}</span>}
              <span className="ml-2 px-1.5 rounded-full bg-white border-[1px] border-gray-300 text-gray-300 text-xs font-bold">i</span>
            </ToolTip>
          </Combobox.Label>
          <Combobox.Input
            id="project-details-asset-name-input"
            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-brandBlue-500 focus:ring-brandBlue-600 sm:text-sm"
            onChange={(event) => setAssetNameQuery(event.target.value)}
            // the field has displaySymbol, but the user is to see displayName
            displayValue={assetSymbols.find((s) => s.displaySymbol === field.value)?.displaySymbol || field.value || ''}
          />
          <Combobox.Options className="absolute mt-2 p-2 bg-white block rounded-md border border-gray-300 shadow-md sm:text-sm">
            {assetNameQuery.length > 0 && (
              <Combobox.Option
                key="custom-option"
                value={assetNameQuery}
                id="project-details-asset-name-input-custom-option"
                className="ui-active:text-white ui-active:bg-brandBlue-600 text-gray-900 cursor-default select-none relative py-1 pl-3 pr-9"
              >
                {`[NEW] ${assetNameQuery}`}
              </Combobox.Option>
            )}
            {(filteredSymbols.slice(0, 10) || []).map((symbol) => (
              <Combobox.Option
                key={`${symbol.displaySymbol}-${symbol.displayName}`}
                value={symbol.displaySymbol}
                id={`project-details-asset-name-input-option-${symbol.displaySymbol}`}
                className="ui-active:text-white ui-active:bg-brandBlue-600 text-gray-900 cursor-default select-none relative py-1 pl-3 pr-9"
              >
                {`${symbol.displayName} | ${symbol.displaySymbol}`}
              </Combobox.Option>
            ))}
          </Combobox.Options>
          <div className="pt-2 sm:text-sm text-brandRed-500">
            <span>{errors.displayName?.message}</span>
          </div>
        </Combobox>
      )}
    />
  );
}
AssetNameDropdown.propTypes = {
  control: PropTypes.object.isRequired,
  accountCategory: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
};
