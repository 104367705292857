/* eslint-disable max-len */
/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { ChevronDoubleRightIcon } from '@heroicons/react/24/solid';
import { Listbox, Transition } from '@headlessui/react';
import MiniSpinner from '../../misc/MiniSpinner';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function ReportsList({ reportDefs, displayedReportDef, setDisplayedReportDef, setReport, showSpinner }) {
  const { t } = useTranslation('app', { keyPrefix: 'reporting' });

  return (
    <>
      {/* <div className="divide-y divide-gray-200 lg:grid lg:grid-cols-12 lg:divide-y-0 lg:divide-x">
        <aside className="pt-4 pb-6 lg:col-span-3">
          <nav className="space-y-1">
            <div className="font-semibold text-base ml-4 mb-4">Reports</div> */}
      <div className="hidden lg:block">
        {[...reportDefs]
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((item) => (
            <button
              key={item.name}
              type="button"
              onClick={() => setDisplayedReportDef(item)}
              onDoubleClick={() => setReport(item)}
              className={`
                    ${
                      item?.id === displayedReportDef?.id
                        ? 'bg-brandBlue-50 border-brandBlue-500 text-brandBlue-700 hover:bg-brandBlue-50 hover:text-brandBlue-700'
                        : 'border-transparent text-gray-900 hover:bg-gray-50 hover:text-gray-900'
                    }
                    group border-l-4 px-3 py-2 flex items-center text-sm font-medium w-full`}
              aria-current={item?.id === displayedReportDef?.id ? 'page' : undefined}
            >
              <ChevronDoubleRightIcon
                className={`
                      ${item?.id === displayedReportDef?.id ? 'text-brandBlue-500 group-hover:text-brandBlue-500' : 'text-gray-400 group-hover:text-gray-500'}
                      flex-shrink-0 -ml-1 mr-3 h-6 w-6`}
                aria-hidden="true"
              />
              <span className="truncate">{item.name}</span>
              {!item.readOnly && <span className="ml-2 inline-flex items-center rounded-md bg-gray-100 px-1.5 py-0.5 text-xs font-medium text-gray-600">{t('myReport')}</span>}
            </button>
          ))}
        {(showSpinner) && (
          <div className="w-full flex justify-center">
            <MiniSpinner className="text-gray-300 w-10 h-10 animate-spin" />
          </div>
        )}
      </div>
      <Listbox className="lg:hidden" value={displayedReportDef} onChange={setDisplayedReportDef}>
        {({ open }) => (
          <div className="relative mt-2">
            <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-brandBlue-600 sm:text-sm sm:leading-6">
              <span className="block truncate">{displayedReportDef?.name}</span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </span>
            </Listbox.Button>

            <Transition show={open} as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
              <Listbox.Options className="absolute z-20 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {[...reportDefs].map((person) => (
                  <Listbox.Option
                    key={person.id}
                    className={({ active }) => classNames(active ? 'bg-brandBlue-500 text-white' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-3 pr-9')}
                    value={person}
                  >
                    {({ selected, active }) => (
                      <>
                        <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>{person.name}</span>

                        {selected ? (
                          <span className={classNames(active ? 'text-white' : 'text-brandBlue-500', 'absolute inset-y-0 right-0 flex items-center pr-4')}>
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        )}
      </Listbox>
    </>
  );
}
ReportsList.propTypes = {
  reportDefs: PropTypes.arrayOf(PropTypes.object).isRequired,
  displayedReportDef: PropTypes.objectOf(PropTypes.any),
  setDisplayedReportDef: PropTypes.func.isRequired,
  setReport: PropTypes.func.isRequired,
  showSpinner: PropTypes.bool,
};
ReportsList.defaultProps = {
  displayedReportDef: null,
  showSpinner: false,
};
