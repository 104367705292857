/* eslint-disable max-len */
/* eslint-disable func-names */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useState, useRef } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { XMarkIcon } from '@heroicons/react/24/solid';
import PivotTableUI from 'react-pivottable/PivotTableUI';
import { nanoid } from 'nanoid';
import '../../css/pivottable.css';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';
import renderers, { getRendererSettings } from './renderers';
import NivoRenderers from './NivoRenderers';
import getDataDependentSettings from './reportingSelectors';
import { InputComponent } from '../../elements/InputComponentDumb';
import { setAlert, clearAlert, setMessage } from '../../redux/actions/message';
import { postReport, putReport, deleteReport } from '../../redux/reducers/reports';
import { getArticleLink } from '../../blog';
import ToolTipNoIcon from '../../elements/ToolTipNoIcon';
import Button from '../../elements/Button';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

// can be displayed empty (new report) or with data (edit report)
// when displayed empty, does it make sense to pre-populate with some example data?
export default function ReportEdit({ report, setReport, setDisplayedReportDef }) {
  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();

  const reportTemplate = {
    cols: ['accountName'],
    rows: ['category'],
    vals: ['amount'],
    aggregatorName: 'Sum',
    rendererName: 'Bar chart, vertical, grouped',
    name: 'New report',
    dataScope: 'transactions',
    valueFilter: {},
    dateFilter: { from: null, to: null, id: 'noFilter' },
    colOrder: 'key_a_to_z',
    rowOrder: 'key_a_to_z',
  };

  // initialise chartState: spread default report template and then report values if there are any (there will be none if this is a new report)
  const [chartState, setChartState] = useState({
    ...reportTemplate,
    ...report,
    dateFilter: { ...reportTemplate?.dateFilter, ...report?.dateFilter },
  });
  const [formIsDirty, setFormIsDirty] = useState(false); // keeps track of whether anything has been changed

  const pivotTableUIRef = useRef();
  const pivotTableUIProps = pivotTableUIRef.current?.props;
  const dataDependentSettings = useSelector((state) => getDataDependentSettings(state, chartState.dataScope));

  function twoValueSum() {
    return function ([attr1, attr2]) {
      return function () {
        let sumAttr1 = 0;
        let sumAttr2 = 0;
        return {
          push: (record) => {
            if (!Number.isNaN(parseFloat(record[attr1]))) {
              sumAttr1 += parseFloat(record[attr1]);
            }
            if (!Number.isNaN(parseFloat(record[attr2]))) {
              sumAttr2 += parseFloat(record[attr2]);
            }
          },
          value: () => `${sumAttr1} ${sumAttr2}`, // leaving it like this so that it may be displayed in pivot table
          format: (x) => x,
          numInputs: 2,
        };
      };
    };
  }

  const customAggregators = {
    'Sum, two values': twoValueSum(),
  };

  useEffect(() => {
    // on initial load (i.e. aggregators attribute is not there yet), copy the extended set of props from PivotTableUI up to chartState to keep them in sync
    // as this will then be used to save the report
    // this does not work with a dependency array, as the first pass still does not have the PivotTableUI props
    if (!chartState.aggregators) {
      console.log('copying default props from PivotTableUI to ReportEdit');
      setChartState({
        ...pivotTableUIProps,
        ...chartState,
        aggregators: {
          ...pivotTableUIProps?.aggregators,
          ...customAggregators,
        },
      });
    }
  }, []);

  useEffect(() => {
    // if dataScope is changed, update the chartState with the new data, from where it will get updated in PivotTableUI
    setChartState({ ...chartState, ...dataDependentSettings });
  }, [chartState.dataScope, dataDependentSettings]);

  const alert = useSelector((state) => state.message.alert);

  function handleClose(e) {
    setDisplayedReportDef(report);
    setReport(undefined);
  }

  // handle user input from alert modal when closing the entire form
  useEffect(() => {
    if (alert?.id === 'aboutToLoseData') {
      if (alert?.response === 'ok') {
        handleClose();
      }
    }
  }, [alert?.response]);

  // FIXME: balances data source

  function handleSave(e) {
    const reportDef = {
      id: chartState.id || nanoid(),
      name: chartState.name,
      dataScope: chartState.dataScope,
      cols: chartState.cols,
      rows: chartState.rows,
      vals: chartState.vals,
      aggregatorName: chartState.aggregatorName,
      rendererName: chartState.rendererName,
      dateFilter: chartState.dateFilter,
      valueFilter: chartState.valueFilter,
      colOrder: chartState.colOrder,
      rowOrder: chartState.rowOrder,
    };

    setReport(reportDef);
    if (chartState.id) {
      // there was an id in the chartState already, so this is an update
      console.info('updating report', reportDef);
      dispatch(putReport(reportDef));
    } else {
      console.info('saving new report', reportDef);
      dispatch(postReport({ ...reportDef }));
    }
    setDisplayedReportDef(reportDef);
    setReport(undefined);
  }

  function handleCancel(e) {
    if (formIsDirty) {
      dispatch(setAlert('aboutToLoseData', chartState.id));
    } else {
      handleClose();
    }
  }

  // handle user input from alert modal when deleting project
  useEffect(() => {
    if (alert?.id === 'aboutToLoseData' && alert?.caller === chartState.id && alert?.response) {
      if (alert?.response === 'ok') {
        handleClose();
      }
    }

    // presence of "alert.response" means the user clicked something, so we can clear the alert
    dispatch(clearAlert());
  }, [alert?.response]);

  // this is used as callback from PivotTableUI which synchronises ReportEdit state
  function handleChange(reportDef) {
    setChartState(reportDef);
    setFormIsDirty(true);
  }

  function setDateFilter(from = null, to = null, id = 'noFilter') {
    // check if the new filter criteria return any rows from data
    // if not, show a toas</td>t message and do not update the filter
    const newState = chartState.data.filter((x) => x.date > (from || new Date(1902, 0, 1).valueOf()) && x.date <= (to || new Date(2200, 0, 1).valueOf()));
    if (newState?.length > 0) setChartState({ ...chartState, dateFilter: { from, to, id } });
    else dispatch(setMessage('noDataForDateFilter'));
  }

  // create an event listener which calls setAlert when the user hits Esc when there are changes in the Grid view
  // DO NOT attempt to move it to the Redux alert, it won't work, as hooks cannot be used in listeners
  useEffect(() => {
    function handleEsc(e) {
      if (e.key === 'Escape') {
        handleClose();
      }
    }

    document.addEventListener('keydown', handleEsc, false);
    return () => {
      document.removeEventListener('keydown', handleEsc, false);
    };
  }, []); // it needs to be one object; if it is more, it creates several listeners

  // handle user input from alert modal when deleting project
  useEffect(() => {
    if (alert?.id === 'youAreAboutToDeleteReport' && alert?.caller === chartState.id && alert?.response) {
      if (alert?.response === 'ok') {
        dispatch(deleteReport(report)).then((data) => {
          setReport(null);
        });
      }

      // presence of "alert.response" means the user clicked something, so we can clear the alert
      dispatch(clearAlert());
    }
  }, [alert?.response]);

  // these depend of which dataScope are we displaying

  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <div id="projectDetailsParent" className="absolute w-full h-auto min-h-full z-50 top-0 left-0 md:p-16 bg-gray-300 bg-opacity-90 space-y-6" role="dialog">
      {/* project details dialog */}
      <div
        className={`relative bg-gray-50 shadow px-4 py-5 xl:h-[85vh] sm:rounded-lg sm:pl-8 sm:pr-10 sm:py-8 flex flex-col gap-4 sm:gap-8
        overflow-y-scroll scrollbar-thin scrollbar-track-rounded scrollbar-thumb-rounded scrollbar-track-gray-200 scrollbar-thumb-gray-400`}
      >
        <button type="button" className="fixed top-0 right-0 m-4 cursor-pointer">
          <XMarkIcon className="w-6 h-6 text-gray-500 hover:text-gray-600" onClick={handleClose} />
        </button>
        {/* project header */}
        <section aria-label="project header">
          <div className="flex items-center justify-between pt-4 sm:pt-0">
            <div className="flex-1 items-center min-w-min">
              <InputComponent
                value={chartState?.name}
                callback={(newValue) => setChartState({ ...chartState, name: newValue })}
                formatting="sm:-ml-[0.4rem] sm:mr-2 text-2xl sm:text-3xl font-bold text-gray-900"
                attr="name"
                stub="Report name?"
              />
            </div>
            <div className="mt-1 w-full flex justify-between items-center">
              <div className="flex items-center font-semibold text-md">
                <button
                  type="button"
                  onClick={() => {
                    setChartState({
                      ...chartState,
                      dataScope: 'transactions',
                      vals: ['amount'],
                    }); // reset the 'value' to a value field available in transactions
                    setFormIsDirty(true);
                  }}
                  // chartState is controlled by this component and is initialised to the report object, so check for dataScope there
                  className={classNames(
                    chartState?.dataScope === 'transactions' ? 'bg-gray-200 text-gray-900' : 'bg-white text-gray-400',
                    'relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-brandBlue-400 focus:border-brandBlue-400',
                  )}
                >
                  {t('app:reporting.transactions')}
                </button>
                <button
                  type="button"
                  onClick={() => {
                    setChartState({
                      ...chartState,
                      dataScope: 'assets',
                      vals: ['currentValue'],
                    }); // reset the 'value' to a value field available in transactions
                    setFormIsDirty(true);
                  }}
                  className={classNames(
                    chartState?.dataScope === 'assets' ? 'bg-gray-200 text-gray-700' : 'bg-white text-gray-400',
                    '-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-brandBlue-400 focus:border-brandBlue-400',
                  )}
                >
                  {t('app:reporting.balances')}
                </button>
                <button
                  type="button"
                  onClick={() => {
                    setChartState({
                      ...chartState,
                      dataScope: 'snapshots',
                      vals: ['value'],
                    }); // reset the 'value' to a value field available in transactions
                    setFormIsDirty(true);
                  }}
                  className={classNames(
                    chartState?.dataScope === 'snapshots' ? 'bg-gray-200 text-gray-700' : 'bg-white text-gray-400',
                    '-ml-px relative inline-flex items-center px-4 py-2 rounded-r-md border border-gray-300 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-brandBlue-400 focus:border-brandBlue-400',
                  )}
                >
                  {t('app:reporting.history')}
                </button>
                <a
                  id="report-edit-help-button"
                  href={`/${i18n.language}/blog/${getArticleLink('reporting', i18n.language)}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="ml-4 cursor-pointer group"
                >
                  <ToolTipNoIcon info={t('accountDetails.help')}>
                    <span className="text-brandBlue-400 text-sm font-semibold group-hover:text-brandBlue-500">How to use reports?</span>
                    <ArrowTopRightOnSquareIcon className="w-4 h-4 ml-1 text-brandBlue-400 group-hover:text-brandBlue-500" />
                  </ToolTipNoIcon>
                </a>
              </div>
              <div className="flex gap-2">
                <Button text={t('app:reporting.save')} onClick={handleSave} withAccent />
                <Button text={t('app:reporting.cancel')} onClick={handleCancel} />
              </div>
            </div>
          </div>
        </section>
        {/* negative margin compensates the outer border-spacing imposed by the table component */}
        <div className="h-full max-h-full -mx-4">
          <ErrorBoundary FallbackComponent={() => <div />}>
            <PivotTableUI
              ref={pivotTableUIRef}
              {...chartState}
              setDateFilter={setDateFilter}
              onChange={handleChange}
              renderers={NivoRenderers} // only Nivo charts are available for now
              rendererPivotTableUISettings={getRendererSettings(chartState.rendererName)}
              unusedOrientationCutoff={0}
            />
          </ErrorBoundary>
        </div>
      </div>
    </div>
  );
}
ReportEdit.propTypes = {
  report: PropTypes.object,
  setReport: PropTypes.func,
  setDisplayedReportDef: PropTypes.func.isRequired,
};
ReportEdit.defaultProps = {
  report: undefined,
  setReport: null,
};
