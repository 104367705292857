import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Menu, Transition } from '@headlessui/react';
import { nanoid } from 'nanoid';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { getSchemaByCategory } from '@monestry-dev/schema';
import { enabledCategories } from '../../misc/globalSettings';
import { assetBalancesArray, depositTransactionsProjectView, globalAccountsView, postAccount } from '../../redux/reducers/data';
import { setMessage } from '../../redux/actions/message';
import MiniSpinner from '../../misc/MiniSpinner';

dayjs.extend(utc);

export default function NewAccountButton({ baseCurrency, setSignalNewAccount, accountFieldName }) {
  const { t } = useTranslation('app');
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  // list of enabled categories is kept centrally in enabledCategories.js
  const categories = enabledCategories
    // filter loans and pensions require more input attributes, so they are not included for now (PRD-1526)
    .filter((category) => category !== 'loans' && category !== 'pension')
    .map((category) => ({
      id: category,
      name: t(`dashboard.singleCategory.${category}`),
    }));

  // this would be easier in assetBalances, but it has no project mode?
  const accounts = useSelector(globalAccountsView);
  const depositAccountsDropdown = accounts
    .filter((account) => account.category === 'deposits')
    .map(({ id, name }) => ({ id, name }));

  async function createAccount(connectedDepositAccountIdName, category) {
    console.log('DEBUG', connectedDepositAccountIdName);
    // cast to schema

    const schema = getSchemaByCategory(category, 'account');
    const newAccountId = nanoid();
    const newAccount = schema.cast({
      id: newAccountId,
      name: `${t('projects.newAccount.description')} ${nanoid(6)}`,
      currency: baseCurrency,
      connectedDepositAccounts: [connectedDepositAccountIdName.id],
    });
    try {
      const response = await dispatch(postAccount({ data: newAccount, category }));
      // the above somehow does not throw an error when being rejected
      if (response.meta.requestStatus === 'rejected') {
        dispatch(setMessage('accountCouldNotBeCreated'));
      } else {
        dispatch(setMessage('accountCreatedSuccessfully'));
        setSignalNewAccount({ accountFieldName, newAccountId }); // returns an object
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  }

  async function onClick(_, category) {
    setLoading(true);
    // ask in a dialog which deposit account to connect this one to
    window.dispatchEvent(
      new CustomEvent('setDialog', {
        detail: {
          header: t('projects.selectDepositAccount.header'),
          prompt: t('projects.selectDepositAccount.prompt'),
          dropdown: depositAccountsDropdown,
          value: depositAccountsDropdown[0],
          callback: (selectedValue) => createAccount(selectedValue, category),
        },
      }),
    );
  }

  return (
    <Menu as="div" className="relative self-end">
      <Menu.Button
        id="project-details-add-transaction-new-account"
        type="button"
        disabled={loading}
        className="block w-full h-10 rounded-md border border-gray-300 shadow-sm focus:border-brandBlue-500 focus:ring-brandBlue-600 hover:bg-gray-50 sm:text-sm text-gray-700"
      >
        {t('app:projects.newAccount.label')}
        {/* {loading ? <MiniSpinner className="w-4 h-4 animate-spin mx-auto text-gray-400" /> : t('app:projects.newAccount.label')} */}
      </Menu.Button>
      <Transition
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
        <Menu.Items className="absolute z-50 mt-3 -translate-x-4 px-4 bg-white text-sm w-64">
          <div className="flex flex-col space-y-1 shrink rounded-md bg-white p-3 text-sm font-normal leading-6 text-gray-900 shadow-lg ring-1 ring-gray-900/5">
            {categories.map((category) => (
              /* Use the `active` state to conditionally style the active item. */
              <Menu.Item key={category.id}>
                {({ active }) => (
                  <button type="button" onClick={(e) => onClick(e, category.id)} className={`${active ? 'bg-brandBlue-500 text-white' : 'bg-white'} text-left px-2 py-1`}>
                    {category.name}
                  </button>
                )}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
NewAccountButton.propTypes = {
  baseCurrency: PropTypes.string.isRequired,
  setSignalNewAccount: PropTypes.func.isRequired,
  accountFieldName: PropTypes.oneOf(['account', 'accountTo', 'assetAccount']).isRequired,
};
