/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';
import { XMarkIcon } from '@heroicons/react/24/solid';
import { PlusIcon } from '@heroicons/react/20/solid';
import { allTransactionsProjectView } from '../redux/reducers/data';
import ToolTipNoIcon from './ToolTipNoIcon';
import Dropdown from './Dropdown';
import Button from './Button';

/**
 * Returns a form component based on FieldArray with assetIds and their growthRates as well as [remove] and [add buttons]
 *
 * @param {func} register - from useForm of the parent
 * @param {object} control - from useForm of the parent
 * @param {array} fields - from useFieldArray of the parent
 * @param {func} append - from useFieldArray of the parent
 * @param {func} remove - from useFieldArray of the parent
 * @param {array} assetList - list of assets to show in the dropdown; may be empty (in such case the component will retrieve all STOCKS assetIds from allTransactionsProjectView)
 * @param {string} namePrefix - prefix for the name of the form fields (to handle crypto fields which are the same fields as stocks in settings.*, but in settings.crypto.*);
 *                              requires trailing . (e.g. 'crypto.')
 *
 * @returns React.Component
 *
 * Used in ProjectDetails > Asset Growth Rates and in AccountDetails > Simulated Account Growth
 */
export default function GrowthRatesArray({ register, fields, append, remove, control, assetList, namePrefix }) {
  const { t } = useTranslation('app', { keyPrefix: 'accountDetails.settings' });

  // get a list of all figis in the transactions (also simulated in the future) to show in the dropdown
  // also get whatever friendly name user used for the symbol
  // NOTE: this used to be globalTransactionViewNoTimeLimit 2
  const dropdownSource = assetList.length > 0
    ? assetList.reduce((acc, curr) => ({ ...acc, [curr.assetId]: curr.displayName }), {})
    : useSelector((state) => allTransactionsProjectView(state))
      .filter((transaction) => transaction.category === (namePrefix ? 'crypto' : 'stocks'))
      .reduce((acc, curr) => ({ ...acc, [curr.assetId]: curr.displayName }), {});

  return (
    <div className="col-span-2 grid grid-cols-1">
      <div className="grid grid-cols-2 space-x-4 text-sm text-gray-700">
        <span className="mb-1">{t('symbol')}</span>
        <ToolTipNoIcon info={t('simulatedAccountGrowth.dividendTooltip')}>
          <div>
            <span>{t('growthRate')}</span>
            <span className="ml-2 px-1.5 rounded-full bg-white border-[1px] border-gray-300 text-gray-300 text-xs font-bold">i</span>
          </div>
        </ToolTipNoIcon>
      </div>
      {fields.map((item, index) => (
        <div key={item.symbol} className="grid grid-cols-2 space-x-4 mb-1">
          <Controller
            name={`${namePrefix}growthRates.${index}.symbol`}
            control={control}
            render={({ field, ref }) => (
              <Dropdown
                label=""
                value={field.value?.name}
                list={Object.keys(dropdownSource)
                  .filter((obj) => !fields.map((f) => f.symbol).includes(obj)) // remove those already in the array
                  .map((obj) => ({ id: obj, name: dropdownSource[obj] || obj }))}
                onChange={field.onChange}
                onBlur={field.onBlur}
                inputRef={ref}
                optional={false}
              />
            )}
          />
          <div className="flex space-x-3 items-center">
            <input
              type="number"
              name={`${namePrefix}value${index}`}
              id={`${namePrefix}value${index}`}
              placeholder={5.5}
              step={0.01}
              className="block max-w-xs rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-gray-900 focus:outline-none focus:ring-gray-900 sm:text-sm"
              {...register(`${namePrefix}growthRates.${index}.value`)}
            />
            <Button type="button" onClick={() => remove(index)} size="sm" Icon={XMarkIcon} textColor="text-gray-400" textColorHover="text-gray-500" />
          </div>
        </div>
      ))}
      {/* only display ADD button if there are more different stock symbols in the store than on our list */}
      {fields.length < Object.keys(dropdownSource).length && (
        <Button
          formatting="mt-3 inline w-20"
          type="button"
          onClick={() => append({ symbol: '', value: 0.15 })}
          size="sm"
          Icon={PlusIcon}
          text={t('add')}
          textColor="text-gray-400"
          textColorHover="text-gray-500"
        />
      )}
    </div>
  );
}
GrowthRatesArray.propTypes = {
  register: PropTypes.func.isRequired,
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      symbol: PropTypes.string,
      value: PropTypes.number,
    }),
  ).isRequired,
  append: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  control: PropTypes.shape({
    field: PropTypes.shape({
      value: PropTypes.string,
      onChange: PropTypes.func,
      onBlur: PropTypes.func,
    }),
  }).isRequired,
  assetList: PropTypes.array,
  namePrefix: PropTypes.oneOf(['', 'crypto.']),
};
GrowthRatesArray.defaultProps = {
  assetList: [],
  namePrefix: '',
};
