/* eslint-disable no-unsafe-optional-chaining */
import { createSelector } from '@reduxjs/toolkit';
import { createSelectorCreator, defaultMemoize } from 'reselect';
import { nanoid } from 'nanoid';
import dayjs from 'dayjs';
import { t } from 'i18next';
import { groupBy, applyFIFOOnCategory } from '../../../../misc/arrayHelpers';
import * as globals from '..';

const utc = require('dayjs/plugin/utc');

dayjs.extend(utc);

const createSelectorWithDebug = createSelectorCreator(
  defaultMemoize,
  // eslint-disable-next-line arrow-body-style
  (a, b) => {
    console.log('Comparing:', a, b); // Log the values being compared
    return a === b; // Use the default equality check
  },
);

// returns array of { symbol: { id, name }, value: 0.05 }
export function dividendSettings(state) {
  return {
    dividendYieldAdvancedMode: state.data?.stocks.settings?.dividendYieldAdvancedMode || false,
    dividendYieldRates: state.data?.stocks.settings?.dividendYieldRates || [],
    dividendYield: state.data?.stocks.settings?.dividendYield || 0,
  };
}

// returns an array with all stocks transactions (real or real+simulated) up to the Slider Date (baseDate) or to today (depending on dashboardMode in state)
// no isolated transactions are included
export const stockTransactions = createSelector(
  (state) => state.simulation?.dashboardMode,
  (state) => state.simulation,
  (state) => state.data?.stocks.transactions,
  (state) => state.data?.stocks.simulatedTransactions,
  (state) => state.projects,
  (state) => state.user?.profile.settings.inflationRate,
  (state) => state.user?.profile.settings.sliderEndDate,
  (dashboardMode, simulation, transactions, simulatedTransactions, projects, inflationRate, sliderMax) => {
    let returnObject;
    if (dashboardMode === 'projects') {
      const { baseDate } = simulation;
      const isolatedProjects = projects?.filter((project) => project.settings?.isIsolated).map((project) => project.id);
      const simulatedTransactionsWithoutIsolated = simulatedTransactions.filter((txn) => !isolatedProjects.includes(txn.projectId));

      returnObject = (transactions || [])
        .concat(simulatedTransactionsWithoutIsolated)
        .filter((transaction) => transaction.date <= baseDate)
        // unpack recurring transactions by creating transactions for every recurring transaction (never = until the end of slider)
        .flatMap((txn) => globals.unpackRecurringTransactionsCore(inflationRate, baseDate, sliderMax, txn));
    } else {
      // to handle links between simulated and non-simulated transactions, there are some linkedTransactions which are simulated transactions; those need to be removed in this mode
      returnObject = (transactions || []).map((transaction) => ({ ...transaction, linkedTransactions: transaction.linkedTransactions?.filter((txn) => !txn.tags.isSimulated) }));
    }

    // calculate quantityOpen for each transaction
    return applyFIFOOnCategory(returnObject);
  },
);

// returns an array with all stocks transactions (simulated and real) up to the Simulation End Date (slider end)
// including all isolated transactions
export const stockTransactionsProjectView = createSelector(
  (state) => state.simulation?.dashboardMode,
  (state) => state.simulation,
  (state) => state.data?.stocks.transactions,
  (state) => state.data?.stocks.simulatedTransactions,
  (state) => state.user?.profile.settings.inflationRate,
  (state) => state.user?.profile.settings.sliderEndDate,
  (dashboardMode, simulation, transactions, simulatedTransactions, inflationRate, sliderMax) => {
    let returnObject;
    if (dashboardMode === 'projects') {
      const { baseDate } = simulation;
      returnObject = (transactions || [])
        .concat(simulatedTransactions)
        // unpack recurring transactions by creating transactions for every recurring transaction (never = until the end of slider)
        .flatMap((txn) => globals.unpackRecurringTransactionsCore(inflationRate, baseDate, sliderMax, txn));
    } else {
      // to handle links between simulated and non-simulated transactions, there are some linkedTransactions which are simulated transactions; those need to be removed in this mode
      returnObject = (transactions || []).map((transaction) => ({
        ...transaction,
        assetId: transaction.figi,
        linkedTransactions: transaction.linkedTransactions?.filter((txn) => !txn.tags.isSimulated),
      }));
    }
    return applyFIFOOnCategory(returnObject);
  },
);

// this assumes that dividends are always paid in the fxCurrency of the asset
// this returns an object (!) { projectId1: [{ projectId, position: amount, currency }], projectId2: [], null: [] }
function getPositionPerProjectIdAssetId(accountTransactions, date, assetId) {
  const transactionsInScope = accountTransactions?.[assetId];
  const returnObject = [];
  Object.entries(transactionsInScope).forEach(([projectId, transactions]) => {
    // keep only purchases and sales
    const filteredTransactions = transactions.filter((txn) => ['purchase', 'sale'].includes(txn.transactionType));

    // under the assumption that all transactions related to a figi have the same currency
    const currency = Object.values(filteredTransactions)[0]?.transactionCurrency;
    const quantity = filteredTransactions
      .filter((tx) => String(tx.projectId) === projectId && tx.date <= date)
      .reduce((prev, curr) => prev + curr.quantity, 0);
    returnObject.push({ projectId, quantity, currency });
  });
  return returnObject;
}
// returns an array of all projects with their position in a given stock on a given account, on a given date

// return asset-level dividend yield; if not found, return account-level dividend yield; if not found, return 0
// if dividend yield is in advanced mode, it only returns dividend yield if it is defined in the settings
// if dividend yield is in the simple mode, it will return dividend yield for each asset which has at least one deposit transaction with investment-dividend label
export function getDividendYield(stockSettings, quotes, assetId, date, dividendTransactions) {
  if (!quotes[assetId] || !quotes[assetId][date]) return {};

  const { dividendYieldAdvancedMode, dividendYieldRates, dividendYield } = stockSettings; // see above

  const { quote, quoteBaseCurrency, currency } = quotes[assetId][date];

  // handle advanced mode
  if (dividendYieldAdvancedMode) {
    const dividendPercentage = dividendYieldRates.find((x) => x.symbol.id === assetId)?.value / 100; // values are in %
    return {
      dividendYieldBaseCurrency: quoteBaseCurrency * dividendPercentage,
      dividendYieldQuoteCurrency: quote * dividendPercentage,
      quoteCurrency: currency,
    };
  }

  // handle simple mode
  // if there was at least one dividend transaction for this asset, return the overall dividend yield
  if (dividendTransactions.map((dt) => dt.tags?.assetId).includes(assetId)) {
    return {
      dividendYieldBaseCurrency: quoteBaseCurrency * (dividendYield / 100), // values are in %
      dividendYieldQuoteCurrency: quote * (dividendYield / 100),
      quoteCurrency: currency,
    };
  }
  return 0;
}

function getConnectedDepositAccount(accounts, stockAccountId) {
  const connectedDepositAccountId = accounts.find((account) => account.id === stockAccountId)?.connectedDepositAccounts?.[0]; // the first connected deposit account (i.e. sequence matters)
  if (!connectedDepositAccountId) return null;
  // return the entire object
  return accounts.find((account) => account.id === connectedDepositAccountId);
  // find deposit account which has this stockAccountId in its connectedAccountIds array
}

/**
 * This function takes the position per account / asset every year and creates one transaction per that combination based on the preset dividend yield.
 *
 * @param {*} stocks
 * @param {*} stockSettings
 * @param {*} dividendTransactions
 * @param {*} accounts
 * @param {*} quotes
 * @param {*} baseDate
 * @param {*} sliderEndDate
 * @param {*} baseCurrency
 * @param {*} projectMode
 * @returns
 */
export function simulatedDividendView(stocks, stockSettings, dividendTransactions, accounts, quotes, baseDate, sliderEndDate, baseCurrency, projectMode = false) {
  // generate a list of years in scope, starting with now() and ending with slider date
  const startDate = dayjs.utc().add(1, 'month').startOf('month').valueOf(); // start of next month
  const years = [];
  let year = startDate;
  do {
    years.push(year);
    year = dayjs.utc(year).add(1, 'year').valueOf();
  } while (year < (projectMode ? sliderEndDate : baseDate));

  // generate a view of stock transactions grouped by accountId and figi (only for figis with a dividend growth setting)
  const stockTransactionsGroupByView = groupBy(stocks, ['accountId', 'assetId', 'projectId']);
  // { accountId1: { figi1: { projectId1: [], projectId2: [], null: [] }, figi2: { projectId1: [], projectId2: [], null: [] } }, accountId2: ... }

  // for each account, for each figi, for each project, calculate the position at the end of each year
  const simulatedDividendTransactions = [];
  // FIX 230706: moved from months to years, as doing it per month significantly slows down the app (see PRD-1129)
  years.forEach((date) => {
    Object.entries(stockTransactionsGroupByView).forEach(([accountId, accountIdObject]) => {
      Object.keys(accountIdObject).forEach((assetId) => {
        // ↓↓ returns an array with { projectId, quantity, currency } -- currency is the currency of first asset transaction
        const transactionsToCreate = getPositionPerProjectIdAssetId(stockTransactionsGroupByView[accountId], date, assetId);
        transactionsToCreate.forEach((position) => {
          try { // dividend yield in euros per share per year * position (amount of shares on that date, per projectId || null)
            if (position.quantity === 0) return;

            // getDividendYield returns { dividendYieldBaseCurrency, dividendYieldQuoteCurrency, quoteCurrency }
            // the returned dividendYields in currency are PER PIECE
            const amount = (getDividendYield(stockSettings, quotes, assetId, date, dividendTransactions).dividendYieldBaseCurrency || 0) * position.quantity;
            // need to convert that to account currency / base currency
            const depositAccount = getConnectedDepositAccount(accounts, accountId);
            // dividends will not be added if there is no connected deposit account
            if (!depositAccount) return;
            const { currency: accountCurrency, id: depositAccountId } = depositAccount;

            const newTransaction = {
              date,
              id: nanoid(),
              category: 'deposits',
              otherParty: t('app:projects.simulated'),
              otherPartyAccount: t('app:projects.simulated'),
              isSimulated: true,
              description: `${t('app:projects.dividendPayment')} ${assetId} `,
              accountId: depositAccountId,
              label: 'investment-dividend',
              projectId: position.projectId, // this may potentially return 'null' instead of null
              tags: {
                type: 'inflow',
                recurring: { activated: false },
                dividendPayment: true,
                nonEditable: true,
                accountId,
                assetId,
              },
            };
            if (amount > 0) simulatedDividendTransactions.push(newTransaction);

            // handle FX differences - we need to post a transaction in account currency and in base currency, but the stock position may be in a different currency
            // each currency in quotes has a quoteBaseCurrency which is its relationship to the base currency
            // let us figure out the base currency first
            if (position.currency === baseCurrency) {
            // if the position currency is the same as the base currency, we only need to post a transaction in the account currency
              newTransaction.amount = amount; // amount is in base currency
            } else {
            // if the position currency is different from the base currency, we need to figure out the amount in base currency
              newTransaction.amount = amount * quotes[position.currency][date].quoteBaseCurrency;
              newTransaction.currency = accountCurrency;
              newTransaction.baseCurrencyAmount = amount;
              newTransaction.baseCurrency = baseCurrency;
            }
          } catch (e) {
            console.error('simulatedDividendView', e);
            console.info('simulatedDividendView: error parameters', JSON.stringify(position, null, 2), date, accountId, assetId);
          }
        });
      });
    });
  });
  return simulatedDividendTransactions;
}

// provides a figi-displaySymbol-providerAssetId mapping dictionaries
// do not use stocksGlobalTransactionView, because if this is run from a component in dashboard mode,
// it won't have any simulated transactions, so it won't have any figis for asset only existing in projects
// this can read directly from state, because it only provides assetId translation (and that does not change through recurring / isolated transactions etc.)
export const stocksMetadata = createSelector(
  (state) => state.data.stocks.transactions,
  (state) => state.data.stocks.simulatedTransactions,
  ($transactions, $simulatedTransactions) => {
    const displaySymbolByAssetId = {};
    const assetIdByDisplaySymbol = {};
    const providerAssetIdByDisplaySymbol = {};
    const displayNameByDisplaySymbol = {};

    // include all transactions (also those beyond the slider date)
    ($transactions.concat($simulatedTransactions) || []).forEach((transaction) => {
      const { figi, displaySymbol, displayName, providerAssetId } = transaction;

      // before updating check if the updated value is null (and do not update if it is)

      // Update figis object
      if (!displaySymbolByAssetId[figi] || displaySymbol) displaySymbolByAssetId[figi] = displaySymbol;

      // Update displaySymbols object
      if (!assetIdByDisplaySymbol[displaySymbol] || figi) assetIdByDisplaySymbol[displaySymbol] = figi;

      // Update displaySymbols object
      if (!providerAssetIdByDisplaySymbol[displaySymbol] || providerAssetId) providerAssetIdByDisplaySymbol[displaySymbol] = providerAssetId;

      // Update displayNames object
      if (!displayNameByDisplaySymbol[displaySymbol] || displayName) displayNameByDisplaySymbol[displaySymbol] = displayName;
    });

    // displaySymbolByAssetId: { figi1: displaySymbol1, figi2: displaySymbol2, ... }
    // assetIdByDisplaySymbol: { displaySymbol1: figi1, displaySymbol2: figi2, ... }
    // providerAssetIdByDisplaySymbol: { displaySymbol1: providerAssetId1, displaySymbol2: providerAssetId2, ... }
    // displayNameByDisplaySymbol: { displaySymbol1: displayName1, displaySymbol2: displayName2, ... }
    return {
      displaySymbolByAssetId,
      assetIdByDisplaySymbol,
      providerAssetIdByDisplaySymbol,
      displayNameByDisplaySymbol,
    };
  },
);
