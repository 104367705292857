/* eslint-disable react/forbid-prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/destructuring-assignment */
import { Transition } from '@headlessui/react';
import { ChevronDownIcon, ChevronUpIcon, ExclamationTriangleIcon } from '@heroicons/react/24/solid';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import BaselineKPIElement from './BaselineKPIElement';
import ChangesInValue from './ChangesInValue';
import SumPreviousValue from './SumPreviousValue';
import { PREVIOUS_VALUE, CHANGE_IN_VALUE } from './constants';
import SumChangeInValue from './SumChangesInValue';
import { orphanedSaleTransactions } from '../../redux/reducers/data';
import OrphanedTransactionsDialog from './OrphanedTransactionsDialog';
import ToolTipNoIcon from '../../elements/ToolTipNoIcon';

const utc = require('dayjs/plugin/utc');

dayjs.extend(utc);

export const debugLevel = process.env.REACT_APP_MDEBUG || 0;

export function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

function SumTileExpendable({ id, calendarState, setCalendarState, setKpiReportVisible, setAccountDetails, baseCurrency, noExpandButton, isViewer }) {
  const [expanded, setExpanded] = useState(noExpandButton);
  const [orphanedDialogOpen, setOrphanedDialogOpen] = useState(false);
  const { t } = useTranslation(['app']);

  const orphanedTransactions = useSelector(orphanedSaleTransactions);

  const selectCategoryStatus = createSelector(
    (state) => state.data,
    (data) => Object.keys(data).map((category) => ({ category, status: data[category].status })),
  );

  const allCategoriesReady = useSelector(selectCategoryStatus).every((category) => ['success', 'inactive'].includes(category.status));

  return (
    <Transition
      as="div"
      appear
      show
      enter="transition-opacity duration-200"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-150"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      key="1"
      id={`kpi-${id}`}
      className={`relative bg-white w-full max-w-[18rem] lg:min-w-full h-full px-2 xs:px-4 sm:px-6 lg:px-3 xl:px-4 pt-4 ${
        noExpandButton ? 'pb-4 space-y-4' : 'pb-10'
      } shadow rounded-lg overflow-hidden grid`}
    >
      {expanded && id === PREVIOUS_VALUE && <BaselineKPIElement calendarState={calendarState} setCalendarState={setCalendarState} baseCurrency={baseCurrency} isViewer={isViewer} />}
      {!expanded && id === PREVIOUS_VALUE && <SumPreviousValue baseCurrency={baseCurrency} setKpiReportVisible={setKpiReportVisible} isViewer={isViewer} />}

      {expanded && id === CHANGE_IN_VALUE && <ChangesInValue isViewer={isViewer} />}
      {!expanded && id === CHANGE_IN_VALUE && <SumChangeInValue baseCurrency={baseCurrency} setKpiReportVisible={setKpiReportVisible} isViewer={isViewer} />}

      {(allCategoriesReady && id === CHANGE_IN_VALUE && orphanedTransactions.length > 0) && (
      <ToolTipNoIcon info={t('app:dashboard.sumtile.warning')} classNameOwn="-mb-0.5 absolute">
        <button type="button" onClick={() => setOrphanedDialogOpen(true)} className="bg-brandYellow-50 mt-2 py-1 px-2 rounded-md flex space-x-2 items-center">
          <ExclamationTriangleIcon className="w-5 h-5 text-brandYellow-500 cursor-pointer" />
          <span className="text-xs text-brandYellow-600">{t('app:dashboard.sumtile.inconsistent')}</span>
        </button>
      </ToolTipNoIcon>
      )}

      {!noExpandButton && (
      <div className="absolute grid justify-center bottom-0 inset-x-0 bg-gray-100 pt-1">
        <div className="text-sm">
          <button type="button" onClick={(e) => setExpanded(!expanded)} className="font-medium text-brandBlue-500 hover:text-brandBlue-600">
            {expanded ? <ChevronUpIcon className="text-brandBlue-500 h-4 w-4" /> : <ChevronDownIcon className="text-brandBlue-500 h-4 w-4" />}
            <span className="sr-only">changes in value stats</span>
          </button>
        </div>
      </div>
      )}
      {orphanedDialogOpen && createPortal(
        // eslint-disable-next-line react/jsx-no-bind
        <OrphanedTransactionsDialog setDialogOpen={setOrphanedDialogOpen} transactions={orphanedTransactions} setAccountDetails={setAccountDetails} />,
        document.querySelector('div#dashboard-level-1'),
      )}
    </Transition>
  );
}
SumTileExpendable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  calendarState: PropTypes.object,
  setCalendarState: PropTypes.func,
  id: PropTypes.string.isRequired,
  setKpiReportVisible: PropTypes.func,
  setAccountDetails: PropTypes.func,
  baseCurrency: PropTypes.string.isRequired,
  noExpandButton: PropTypes.bool,
  isViewer: PropTypes.bool,
};
SumTileExpendable.defaultProps = {
  calendarState: {},
  setCalendarState: () => {},
  setKpiReportVisible: () => {},
  setAccountDetails: () => {},
  noExpandButton: false,
  isViewer: false,
};

const SumTileExpandableMemo = React.memo(SumTileExpendable);
export default SumTileExpandableMemo;
