import enAccountDetailsGrid from './en/accountDetailsGrid';
import enAccountDetailsTable from './en/accountDetailsTable';
import enTableLayoutRealEstate from './en/tableLayoutRealEstate';
import enDashboard from './en/dashboard';
import enProjectDetails from './en/projectDetails';
import deAccountDetailsGrid from './de/accountDetailsGrid';
import deAccountDetailsTable from './de/accountDetailsTable';
import deTableLayoutRealEstate from './de/tableLayoutRealEstate';
import deDashboard from './de/dashboard';
import deProjectDetails from './de/projectDetails';
import i18n from '../i18n';

// returns the tour object for the module
// language is retrieved directly from i18n
export default function getTour(module) {
  const lng = i18n.language;
  if (lng === 'en') {
    switch (module) {
      case 'accountDetailsGrid':
        return enAccountDetailsGrid;
      case 'accountDetailsTable':
        return enAccountDetailsTable;
      case 'tableLayoutRealEstate':
        return enTableLayoutRealEstate;
      case 'dashboard':
        return enDashboard;
      case 'projectDetails':
        return enProjectDetails;
      default:
        return [];
    }
  }
  if (lng === 'de') {
    switch (module) {
      case 'accountDetailsGrid':
        return deAccountDetailsGrid;
      case 'accountDetailsTable':
        return deAccountDetailsTable;
      case 'tableLayoutRealEstate':
        return deTableLayoutRealEstate;
      case 'dashboard':
        return deDashboard;
      case 'projectDetails':
        return deProjectDetails;
      default:
        return [];
    }
  }
  return null;
}
