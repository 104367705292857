import React from 'react';
import KPIReportUnisel from '../../pages/KPIReportUnisel';

function KPIReportRendererUnisel() {
  return (
    <div className="h-fit">
      <KPIReportUnisel />
    </div>
  );
}
KPIReportRendererUnisel.propTypes = {};
KPIReportRendererUnisel.defaultProps = {};

export default {
  'KPI Report Unisel': KPIReportRendererUnisel,
};
