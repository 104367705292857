{
  "general": {
    "loginFailed": "Deine Logindaten waren falsch. Bitte versuche es erneut.",
    "refreshTokenExpired": "Du bist aus Sicherheitsgründen ausgeloggt worden. Bitte logge dich erneut ein.",
    "open": "Öffnen",
    "error": "Fehler",
    "cancel": "Abbrechen",
    "save": "Speichern"
  },
  "header": {
    "projects": "Projekte",
    "reports": "Reports",
    "help": "Hilfe & Support",
    "survey": "UX Test: Umfrage",
    "profile": "Dein Profil",
    "settings": "Einstellungen",
    "recommendUs": "Empfehle uns!",
    "signOut": "Abmelden",
    "noMessages": "Keine Nachrichten vorhanden"
  },
  "dashboard": {
    "yourAssets": "Deine Anlagen",
    "yourAssetsOn": "Deine Anlagen am",
    "setDate": "Datum für diese Ansicht wählen",
    "edit": "Anpassen",
    "share": "Teilen",
    "done": "Anpassen beenden",
    "pleaseWait": "Bitte warten, wir bereiten die Daten vor...",
    "postFile": {
      "header": "Welches Konto?",
      "prompt": "Bitte wähle das Konto aus, zu dem du die Datei gehört."
    },
    "shareReport": {
      "header": "Dashboard teilen",
      "tooltip": "Wir erstellen einen Link, den du mit anderen teilen kannst. Für extra Sicherheit, setze jetzt ein Passcode, mit dem der Link geschützt wird.",
      "next": "Weiter",
      "confirmation": {
        "copy": "Kopieren",
        "copyBoth": "Schicke Link und Passcode an den Empfänger. Er(sie) sieht das Dashboard mit heutigen Daten.",
        "securityHint": "Wenn möglich, schicke die beiden Codes über verschiedene Kanäle (z.B. Email und Chat)."
      }
    },
    "sharedReportViewer": {
      "header": "Passcode",
      "prompt": "Bitte gib den Passcode ein, um das Dashboard zu sehen.",
      "reportNotFound": "Dashboard nicht gefunden",
      "unableToLoadReport": "Das Dashboard kann nicht geladen werden. Bitte überprüfe den Passcode."
    },
    "categories": {
      "deposits": "Bankkonten",
      "stocks": "Depots",
      "pension": "Rentenprodukte",
      "realEstate": "Immobilien",
      "objectsOfValue": "Wertobjekte",
      "metals": "Edelmetalle",
      "loans": "Darlehen",
      "unlistedShares": "Private Unternehmen",
      "crypto": "Kryptowährungen"
    },
    "singleCategory": {
      "deposits": "Bankkonto",
      "stocks": "Depot",
      "pension": "Rentenanlage",
      "realEstate": "Immobilie",
      "objectsOfValue": "Wertobjekt",
      "metals": "Edelmetall",
      "loans": "Darlehen",
      "unlistedShares": "Privates Unternehmen",
      "crypto": "Kryptowährung"
    },
    "sumtile": {
      "change": "Änderung gegenüber dem Basisdatum.",
      "warning": "Es gibt Unstimmigkeiten in deinen Daten, die sich auf diese Zahl auswirken können. Klicke hier, um Details anzuzeigen.",
      "inconsistent": "Unstimmigkeiten",
      "orphanedDialog": {
        "assetTransactionsDialogTitle": "Transaktionen für Vermögenswerte ohne Einzahlungstransaktionen",
        "netWorthExplanation": "Um deinen Nettowert und dessen Änderungen korrekt zu berechnen, muss jeder Verkauf von Vermögenswerten mit einer Transaktion auf einem Bank- oder Bar-Konto verknüpft sein. Die entsprechende Banktransaktion muss dazu getaggt werden.",
        "notLinkedTransactionsExplanation": "Die folgenden Transaktionen sind keiner Einzahlungstransaktion zugeordnet. Klicke eine, um zum entsprechenden Bankkonto weitergeleitet zu werden, und tagge dort die passende Transaktion.",
        "transactionTableHeaders": {
          "date": "Datum",
          "assetAccount": "Vermögenskonto",
          "assetName": "Vermögenswert",
          "amount": "Betrag",
          "price": "Preis"
        },
        "closeButtonText": "Schließen"
      },
      "generalIncome": {
        "label": "Allgemeine Einnahmen",
        "tooltip": "Einkommen aus externen Quellen, z.B. Beschäftigung."
      },
      "incomeFromCapital": {
        "label": "Kapitaleinkommen",
        "tooltip": "Kapitaleinkommen erzielt seit dem Basisdatum - aus z.B. Dividenden oder Erträgen von Verkaufen von Aktien sowie weiteren Investitionen. Blauer Kreis neben einer Transaktion bedeutet, dass sie zu dieser Kategorie gehört."
      },
      "assetValueIncrease": {
        "label": "Änderung des Anlagenwerts",
        "tooltip": "Zeigt, wie sich seit Basisdatum der Wert aller Anlagen (Aktien, Immobilien etc.) geändert hat."
      },
      "outflows": {
        "label": "Allgemeine Ausgaben",
        "tooltip": "Ausgaben, die nicht mit Investitionen zusammenhängen, wie z.B. Miete, Nebenkosten, Einkäufe. gehören zu dieser Kategorie. Käufe von Aktien und Investitionen gehören hierzu nicht."
      },
      "previousValue": {
        "label": "Vermögen am Basisdatum",
        "tooltip": "Gesamtwert aller Anlagen und Konten am unten gewählten Basisdatum.",
        "selectBaseline": "Basisdatum wählen:",
        "sameTimeLastYear": "Gleicher Tag letztes Jahr",
        "beginning": "Anfang des Jahres",
        "beginningLastYear": "Anfang des Vorjahres",
        "custom": "Frei wählbar"
      },
      "changeInValue": {
        "label": "Wertänderung",
        "tooltip": "Wertänderung aller Anlagen und Konten zwischen dem Basisdatum und heute."
      },
      "currentNetWorth": {
        "label": "Aktuelles Vermögen",
        "tooltip": "Wert deines gesamten Vermögens mit Abzug aller Schulden."
      }
    }
  },
  "charts": {
    "last12months": "Kontosalden",
    "currentAllocation": "Aktuelle Aufteilung (nur positive Anlagen)",
    "goToReports": "Zu den Reports",
    "mostRecentTransactions": "Letzte Transaktionen",
    "mostRecentQuotes": "Letzte Bewertungen",
    "currentInformation": "Aktuelle Daten",
    "currentPositions": "Aktueller Bestand",
    "cannotDisplayNegative1": "Für eine der Kombinationen der ausgewälten Parameter hat sich eine negative Summe ergeben: ",
    "cannotDisplayNegative2": ". Dieses Diagramm kann das leider nicht richtig darstellen. Benutze eine andere Kombination.",
    "rebalancing": "Rebalancing",
    "interest": "Zinsen",
    "principal": "Kapital"
  },
  "addAccount": {
    "addNewAccount": "Neues Konto hinzufügen",
    "clickSubmitToContinue": "Klicke auf \"Absenden\" um fortzufahren.",
    "thatsAllWeNeed": "Das ist alles, was wir brauchen.",
    "errorCompleteStep": "Schliesse bitte zuerst diesen Schritt ab.",
    "back": "Zurück",
    "next": "Weiter",
    "cancel": "Abbrechen",
    "submit": "Absenden",
    "pressNextToContinue": "Klicke auf \"Weiter\" um fortzufahren.",
    "pressSubmitToFinish": "Klicke auf \"Absenden\" um fortzufahren.",
    "currentBalance": "Aktuelles Saldo",
    "D1": {
      "accountType": "Kontoart",
      "whatKindOfAccount": "Welche Art von Konto möchtest du hinzufügen? Wähle die Option, die am besten zu deinem Konto passt.",
      "products": {
        "savings-account-giro": "Bankkonto für deine täglichen Ausgaben und Ausgaben. Du kannst jederzeit Geld abheben und einzahlen.",
        "savings-account-tagesgeld": "Ein Sparbuch, in dem du - theoretisch - etwas mehr Zinsen als mit deinem Girokonto erhältst, aber oft daran gehindert bist, wann du abheben kannst.",
        "savings-account-generic": "Bankkonto für deine täglichen Ausgaben und Ausgaben. Du kannst jederzeit Geld abheben und einzahlen.",
        "time-deposit-festgeld": "Bankeneinlage, die du bis zu ihrem Fälligkeitstermin nicht abheben kannst.",
        "time-deposit-generic": "Bankeneinlage, die du bis zu ihrem Fälligkeitstermin nicht abheben kannst."
      }
    },
    "D2": {
      "automaticOrManual": "Automatisch oder manuelle Updates",
      "updateAutomatically": "Möchtest du, dass wir deine Daten automatisch abrufen? Du kannst es später ändern.",
      "automatic": {
        "label": "Automatische Synchronisierung",
        "description": "Wir stellen eine sichere Verbindung zu deiner Bank her und kümmern uns um das Abrufen der letzten Transaktionen. Du musst möglicherweise deine Bank-App verwenden, um diesen Vorgang zu bestätigen. Eine manuelle Import ist immer noch möglich."
      },
      "manual": {
        "label": "Manuelle Updates",
        "description": "Du kannst auch unseren intelligenten Datenimport verwenden, um Daten über deine Konten einfach zu übertragen. Alles, was du tun musst, ist, die Transaktionen von deiner Bank-Website herunterzuladen und hier hochzuladen."
      }
    },
    "D3": {
      "bank": "Bank",
      "banks": "Banken",
      "aBank": "eine Bank",
      "yourBank": "deine Bank",
      "broker": "Broker",
      "brokers": "Broker",
      "aBroker": "einen Broker",
      "yourBroker": "deinen Broker",
      "accountCountry": "Land des Kontos",
      "accountCountryBody": "In welchem Land befindet sich das Institut, wo das Konto geführt wird?",
      "selectCountry": "Wähle Land",
      "select": "Wähle {{yourInstitutionType}} aus",
      "selectCountryFirst": "Wähle zuerst das Land oben.",
      "retrievingInstitutions": "Wir bereiten die Liste vor...",
      "nothingFound": "In diesem Land sind {{yourInstitutions}} leider noch nicht angebunden. Füge {{yourInstitutionType}} manuell mit dem Knopf unten hinzu.",
      "noSlots": "Du hast bereits die maximale Anzahl von automatischen Updates bei ausländischen Finanzinsituten erreicht. Füge {{yourInstitutionType}} manuell mit dem Knopf unten hinzu.",
      "findYourBank": "Finde {{yourInstitutionType}} in der Liste, um deine Daten automatisch zu synchronisieren.",
      "cannotFind": "Wenn du deine Bank hier nicht finden kannst, ist eine automatische Datensynchronisierung noch nicht möglich. Geh einen Schritt zurück und wähle >Manual updates<",
      "noResultsFound": "Keine Ergebnisse gefunden",
      "thisAccountManual": "Dieses Konto wird nicht automatisch synchonisiert. Daten könnem manuell importiert oder eingegeben werden.",
      "thisAccountManual2": "Um automatische Synchronisierung zu aktivieren, wähle {{anInstitutionType}} aus der Liste oben.",
      "thisAccountManual3": "Fortsetzen mit >Weiter<.",
      "addManualAccount": "Bank manuell hinzufügen",
      "intelligentImport": "Dies ist die Liste aller Banken, wo wir deine Daten automatisch abfragen können. Falls du deine Bank hier nicht finden kannst, kannst du deine Daten manuell importieren.",
      "couldNotFindAnything": "Für diesen Begriff gab es keine Suchtreffer. Bitte versuche es erneut.",
      "youHaveSelected": "Deine Auswahl",
      "yourSelection": "Deine Auswahl",
      "details": "Details der ausgewählten Bank",
      "search": "Suche"
    },
    "D4": {
      "account": "Konto",
      "accountInfo": "Kontodaten",
      "accountInfoManual": "Bitte gib einige Informationen zu deinem Konto an.",
      "accountInfoAutomatic": "Deine Bank hat uns diese Kontoinformationen übermittelt.",
      "accountInfoAutomatic2": "Deaktiviere >Dieses Konto wird erstellt<, um zu verhindern, dass es in Monestry erstellt wird.",
      "whichCashAccount": "Welches Bankkonto verwendest du, um Wertpapiere zu kaufen und zu verkaufen?",
      "whyDoWeAsk": "Warum benötigen wir ein verbundenes Bankkonto?",
      "whyDoWeAskAnswer": "Wir berechnen die Rendite aus der Investition und das Kapitaleinkommen anhand von Bewegungen auf Bankkonten, aus denen die Aktienumsätze abgezogen oder gutgeschrieben werden.",
      "notHaveAccount": "Was ist, wenn ich kein separates Bankkonto habe?",
      "notHaveAccountAnswer": "Wenn dein Broker alle Bargeldtransaktionen abwickelt, erstellen wir ein 'technisches Konto', damit du ein besseres Bild von allen Transaktionen hast - wähle dazu einfach die Option am Ende des Formulars.",
      "whenIBuy": "Wenn ich Wertpapiere kaufe, wird das Geld...",
      "existingAccount": "... von einem vorhandenen Bankkonto abgezogen",
      "existingAccountSelect": "Wähle das Bankkonto aus",
      "createTechnicalAccount": "... von einem Bankkonto bei meiner Brokerfirma abgezogen",
      "createTechnicalAccountCurrency": "In welcher Währung ist dieses Bankkonto?",
      "createAccountLater": "... von einem Bankkonto abgezogen, das ich später einrichte",
      "createAccountLaterDescription": "Sobald das neue Bankkonto angelegt wird, öffne die Detailansicht dieses Kontos und füge es hinzu.",
      "gettingAdditionalAccountInformation": "Zusätzliche Kontoinformationen werden abgerufen...",
      "providerAllAccountsAdded": "Keine neue Kontos gefunden. Alle Konten wurden bereits hinzugefügt.",
      "accountName": {
        "label": "Kontoname",
        "tooltip": "Unter welchem Namen möchtest du dieses Konto im System sehen?",
        "error": "Bitte gebe eine Bezeichnung für dieses Konto ein."
      },
      "baseCurrency": {
        "label": "Basiswährung",
        "tooltip": "Welche Währung möchtest du als Basiswährung verwenden? In der Regel ist dies die Währung, in der du verdienst."
      },
      "iban": {
        "label": "IBAN",
        "tooltip": "Die IBAN-Nummer dieses Kontos. Beginnt mit einem Land-Kürzel z.B. >DE< für Deutschland.",
        "placeholder": "DE12345678901234567890"
      },
      "bic": {
        "label": "BIC",
        "tooltip": "Internationaler Bankcode (genannt >BIC<) für die Bank, bei der dieses Konto geführt wird. Bis zu 11 Zeichen lang. Dient zur Identifikation einer Bank im internationalen Kontext."
      },
      "bankName": {
        "label": "Bankname",
        "tooltip": "Name der Bank, bei der dieses Konto geführt wird.",
        "placeholder": "Sparkasse Bielefeld"
      },
      "brokerName": {
        "label": "Brokername",
        "tooltip": "Bei welchem Broker ist dieses Konto?",
        "placeholder": "DepotX"
      },
      "isImported": {
        "label": "Dieses Konto wird erstellt.",
        "tooltip": "Entferne das Häkchen, um zu verhindern, dass dieses Konto erstellt wird.",
        "error": "Dein Abo erlaubt nur {{num}} weitere Konten.",
        "error1": "Dein Abo erlaubt nur ein weiteres Konto.",
        "error0": "Dein Abo erlaubt keine weitere Konten."
      },
      "connectedDepositMode": {
        "error": "Bitte wähle eine Option aus."
      },
      "accountNo": {
        "label": "Depotnummer",
        "tooltip": "Welche Nummer trägt dein Depot?",
        "placeholder": "C12345678"
      },
      "connectedCashAccount": "Verbundenes Girokonto"
    },
    "R1": {
      "accountInfo": "Kontoinformationen",
      "provideInfo": "Bitte gebe einige Informationen zu deiner Immobilie an.",
      "oneAccount": "Ein Konto deckt genau eine Immobilie ab - wenn du mehr als eines hast, erstelle mehr Konten.",
      "whyDoWeAsk": "Warum wollen wir das wissen?",
      "whyDoWeAskAnswer": "Das sind alle Parameter, die verwendet werden, um den Wert deiner Immobilie zu berechnen.",
      "doIHaveToFillThemAllOut": "Muss ich alle Felder ausfüllen?",
      "doIHaveToFillThemAllOutAnswer": "Nein, aber je mehr Informationen du angibst, desto genauer wird die automatische Bewertung deiner Immobilie.",
      "name": {
        "label": "Immobilienname",
        "tooltip": "Welchen Namen möchtest du in der App für diese Immobilie sehen?",
        "placeholder": "Wohnung in Bielefeld",
        "error": "Bitte gebe deine Bezeichnung für diese Immobilie an."
      },
      "originalPrice": {
        "label": "Kaufpreis",
        "tooltip": "Wie viel hat die Immobilie gekostet?",
        "placeholder": "456000",
        "error": "Solange Kaufdatum angegeben ist, muss auch der Kaufpreis vorhanden sein. Er kann nachher geändert werden."
      },
      "currency": {
        "label": "Währung",
        "tooltip": "In welcher Währung möchstest du die Wertentwicklung dieser Immobilie verfolgen? Typischerweise ist dies die Wahrung des Landes, in dem die Immobilie liegt. Umrechnung in deine Basiswährung erfolgt automatisch."
      },
      "automaticUpdate": {
        "label": "Automatische Wertaktualisierung?",
        "tooltip": "Schalte es ein, um automatische Aktualisierungen des Immobilienweres zu aktivieren. Du kannst es später ändern.",
        "disabled": "Automatische Wertaktualisierung bereits eingesetzt oder nicht verfügbar."
      },
      "date": {
        "label": "Kaufdatum",
        "tooltip": "Seit wann besizt du diese Immobilie? Du kannst dieses Datum nachher ändern.",
        "placeholder": "2015-05-15",
        "error": "Solange Kaufpreis angegeben ist, muss auch das Kaufdatum vorhanden sein. Es kann nachher geändert werden."
      },
      "kindOfProperty": "Um was für eine Immobilie handelt es sich?",
      "type": {
        "label": "Immobilientyp",
        "apartment": "Wohnung",
        "house": "Haus"
      },
      "streetAddress": {
        "label": "Straße und Nummer",
        "tooltip": "Wo befindet sich deine Immobilie?",
        "placeholder": "Hochstr. 12"
      },
      "zip": {
        "label": "Postleitzahl",
        "tooltip": "Welche Postleitzahl hat deine Immobilie?",
        "placeholder": "33602"
      },
      "city": {
        "label": "Stadt",
        "tooltip": "In welcher Stadt / Stadt / Dorf befindet sich deine Immobilie?",
        "placeholder": "Bielefeld"
      },
      "country": {
        "label": "Land",
        "tooltip": "In welchem Land befindet sich deine Immobilie?",
        "placeholder": "Deutschland"
      },
      "livingArea": {
        "label": "Gesamtfläche",
        "tooltip": "Wie groß ist deine Immobilie (in Quadratmetern)?",
        "placeholder": "88"
      },
      "rooms": {
        "label": "Zimmer",
        "tooltip": "Wie viele Zimmer hat deine Immobilie?",
        "placeholder": "3"
      },
      "lotArea": {
        "label": "Grundstücksfläche",
        "tooltip": "Wie groß ist das Grundstück, auf dem sich deine Immobilie befindet (in Quadratmetern)?",
        "placeholder": "450"
      },
      "constructionYear": {
        "label": "Baujahr",
        "tooltip": "Wann wurde deine Immobilie gebaut?",
        "placeholder": "2010"
      },
      "parking": {
        "label": "Privatparkplatz inklusive?"
      }
    },
    "S1": {
      "accountInformation": "Konteninformationen",
      "description": "Geben Sie uns einige Informationen über dein Konto.",
      "whyDoWeAsk": "Warum fragen wir?",
      "whyDoWeAskAnswer": "Wir verwenden Daten wie Kontonummer, Benutzername, verbundenes Cash-Konto, um Bargeldtransaktionen mit Ihren Börsentransaktionen zu verknüpfen. Du musst sie nicht angeben, aber unser System kann möglicherweise die entsprechenden Transaktionen nicht verknüpfen und du musst diese möglicherweise manuell durchführen.",
      "name": {
        "label": "Kontoname",
        "tooltip": "Welchen Namen möchtest du in dieser App für dieses Konto sehen?",
        "placeholder": "DepotX-Brokerkonto",
        "error": "Bitte geben Sie Ihre Bezeichnung für dieses Konto ein."
      },
      "username": {
        "label": "Dein Benutzername für dieses Brokerkonto",
        "tooltip": "Der Benutzername, mit dem du dich in dieses Konto einloggst.",
        "placeholder": "ab12345678"
      }
    },
    "S2": {
      "connectedCashAccount": "Verbundenes Bankkonto",
      "whichCashAccount": "Welches Bankkonto verwendest du, um Wertpapiere zu kaufen und zu verkaufen?",
      "whyDoWeAsk": "Warum benötigen wir ein verbundenes Bankkonto?",
      "whyDoWeAskAnswer": "Wir berechnen die Rendite aus der Investition und das Kapitaleinkommen anhand von Bewegungen auf Bankkonten, aus denen die Aktienumsätze abgezogen oder gutgeschrieben werden.",
      "notHaveAccount": "Was ist, wenn ich kein separates Bankkonto habe?",
      "notHaveAccountAnswer": "Wenn dein Broker alle Bargeldtransaktionen abwickelt, erstellen wir ein 'technisches Konto', damit du ein besseres Bild von allen Transaktionen hast - wähle dazu einfach die Option am Ende des Formulars.",
      "whenIBuy": "Wenn ich Wertpapiere kaufe, wird das Geld...",
      "existingAccount": "... von einem vorhandenen Bankkonto abgezogen",
      "existingAccountSelect": "Wähle das Bankkonto aus",
      "createTechnicalAccount": "... von einem Bankkonto bei meiner Brokerfirma abgezogen",
      "createTechnicalAccountCurrency": "In welcher Währung ist dieses Bankkonto?",
      "createAccountLater": "... von einem Bankkonto abgezogen, das ich später einrichte",
      "createAccountLaterDescription": "Sobald das Bankkonto hinzugefügt wird, öffnen Du die Detailansicht dieses Kontos und verknüpfen Du das Bankkonto dort."
    },
    "loans": {
      "given": "Darlehen gegeben",
      "taken": "Darlehen genommen",
      "provideSomeParameters": "Bitte gib einige Parameter an, die wir verwenden werden, um zukünftige Darlehenszahlungen zu simulieren. Du musst keine vergangenen Änderungen eingeben, da diese bereits in den Transaktionen des Darlehenskontos berücksichtigt sind.",
      "loanAmount": {
        "label": "Darlehensbetrag",
        "tooltip": "Gib den Gesamtbetrag des Darlehens ein (nur Hauptbetrag/Kapital).",
        "error": "Bitte gib den Darlehensbetrag ein."
      },
      "direction": {
        "label": "Darlehen genommen oder gegeben?",
        "tooltip": "Wenn du Geld geliehen hast, wähle 'genommen'; sonst wähle 'gegeben'.",
        "error": "Bitte markiere, ob das Darlehen genommen oder gegeben ist."
      },
      "period": {
        "month": "Monat",
        "quarter": "Quartal",
        "year": "Jahr",
        "label": "Raten werden alle",
        "placeholder": "Periode auswählen",
        "tooltip": "Wie oft zahlst du Darlehensraten?",
        "error": "Bitte wähle die Ratenzahlungsperiode."
      },
      "interestRate": {
        "text": "Zinssatz und in einigen Fällen kann sich der Ratenbetrag über die Zeit ändern. Verwende die >Erweitert< Schaltfläche, wenn du diese Änderungen jetzt eingeben möchtest. Du kannst dies auch später tun.",
        "label": "Zinssatz (%)",
        "tooltip": "Gib den jährlichen Zinssatz für das Darlehen ein.",
        "error": "Bitte gib den jährlichen Zinssatz in % ein."
      },
      "endDate": {
        "text": "Wenn du ein Enddatum eingibst, wird die Simulation die letzte Rate als die gesamte ausstehende Schuld berechnen (auch als Ballonzahlung oder Restschuld bekannt). Wenn du vorhast, ein anderes Darlehen aufzunehmen, gib hier das Enddatum ein und erstelle ein anderes Darlehenskonto, das im Monat der letzten Rate dieses Darlehens beginnt.",
        "label": "Enddatum des Darlehensvertrags",
        "tooltip": "Wann endet der Darlehensvertrag? Du kannst dieses Feld leer lassen.",
        "error": "Datum muss in Zukunft liegen."
      },
      "calculateUntilEnd": {
        "label": "Bis zur vollständigen Rückzahlung simulieren",
        "tooltip": "Wenn du die Darlehenszahlungen bis zum Ende der Darlehenslaufzeit simulieren möchtest, wähle diese Option."
      },
      "advanced": "Erweitert",
      "installmentCalc": "Ist die Darlehensrate jedes Mal gleich? Wähle 'Annuität'. Wird sie jedes Mal niedriger (du zahlst jeden Monat einen festen Kapitalbetrag mit wechselnden Zinsen zurück)? Wähle 'Festes Kapital'. Wenn du nur die Zinsen zurückzahlst (das Kapital wird am Ende der Laufzeit zurückgezahlt), wähle 'Nur Zinsen'. Vergiss nicht, ein Enddatum einzugeben, damit wir die letzte Rate berechnen können.",
      "type": {
        "annuity": "Annuität",
        "fixedPrincipal": "Festes Kapital",
        "interestOnly": "Nur Zinsen",
        "label": "Ratentyp",
        "tooltip": "Wie wird deine Rate berechnet? Siehe Hinweis links zur Erklärung.",
        "error": "Bitte wähle den Ratentyp."
      },
      "byInstallentAmount": "Gib den Betrag jeder Rate (Annuität) ein.",
      "byTilgungsrate": "Gib den Prozentsatz des Darlehensbetrags ein, der jährlich zurückgezahlt werden soll ('Tilgungsrate').",
      "annuitySubtype": {
        "error": "Bitte wähle eine Option."
      },
      "annuitySubtypeAmount": {
        "label": "Ratenbetrag",
        "tooltip": "Gib den Betrag für deine feste Rate ein.",
        "error": "Bitte gib den Betrag für deine feste Rate ein."
      },
      "annuitySubtypeTilgung": {
        "label": "Jährliche Tilgung (%)",
        "tooltip": "Gib den Prozentsatz des Darlehensbetrags an, der jährlich zurückgezahlt wird.",
        "error": "Bitte gib den Prozentsatz des jährlich zurückgezahlten Darlehensbetrags ein."
      },
      "fixedPrincipalSubtype": {
        "error": "Bitte wähle eine Option."
      },
      "byPrincipalRepaid": "Gib den Betrag des Kapitals (Hauptbetrag) ein, der in jeder Periode zurückgezahlt wird.",
      "fixedPrincipalSubtypeAmount": {
        "label": "Hauptbetrag",
        "tooltip": "Gib den Hauptbetrag (Kapital) ein, der in jeder Periode zurückgezahlt wird.",
        "error": "Bitte gib den Betrag ein, der in jeder Periode zurückgezahlt wird."
      },
      "fixedPrincipalSubtypeTilgung": {
        "label": "Jährliche Tilgung (%)",
        "tooltip": "Gib den Prozentsatz des Darlehensbetrags an, der jährlich zurückgezahlt wird.",
        "error": "Bitte gib den Prozentsatz des jährlich zurückgezahlten Darlehensbetrags ein."
      },
      "connectedDepositAccount": "Um die Darlehensauszahlungen und -zahlungen nachzuverfolgen, müssen wir wissen, von welchem Konto das Geld kommt und wohin es geht. Wenn du mehr als eines verwendest, kannst du das andere Konto später hinzufügen.",
      "connectedDepositMode": {
        "error": "Bitte wähle, wie du das Einzahlungskonto verbinden möchtest."
      },
      "existingAccount": "Bestehendes Konto",
      "existingAccountSelect": "Wähle ein bestehendes Konto",
      "createAccountLater": "Konto später erstellen",
      "createAccountLaterDescription": "Du kannst wählen, später ein Einzahlungskonto zu verbinden.",
      "loanProject": "Du kannst die simulierten zukünftigen Zahlungen in Projekten sehen. Wenn du bereits ein Projekt für dieses Darlehen hast, wähle es hier. Wenn nicht, markiere >Neues Projekt erstellen<.",
      "connectedProjectId": {
        "label": "Verbundenes Projekt",
        "tooltip": "Wähle das mit diesem Darlehen verbundene Projekt."
      },
      "projectCreateNew": "Erstelle ein neues Projekt",
      "bereitstellungszinsen": {
        "text": "Zinsen vor vollständiger Darlehensauszahlung (Bereitstellungszinsen)",
        "label": "Anfänglicher Zinssatz (%)",
        "tooltip": "Gib den Zinssatz ein, der auf den nicht ausgezahlten Teil des Darlehens erhoben wird."
      },
      "bereitstellungszinsenSinceDate": {
        "label": "Beginnend am",
        "tooltip": "Gib das Datum an, ab dem die Bereitstellungszinsen berechnet werden."
      },
      "interestAfterTerm": {
        "text": "Wende neuen Zinssatz nach Ende des Darlehensvertrags an. Dies ermöglicht die Option 'bis zur vollständigen Rückzahlung simulieren' oben und wendet diesen Zinssatz ab dem angegebenen Datum an.",
        "label": "Zinssatz nach Vertragsende (%)",
        "tooltip": "Gib den Zinssatz ein, der nach dem ursprünglichen Ende der Laufzeit gilt."
      },
      "interestAfterTermSinceDate": {
        "label": "Beginnend am",
        "tooltip": "Gib das Startdatum für den Zinssatz nach Vertragsende an."
      },
      "interestCalculationCutoff": "Berechnet deine Bank deine Rate zu Monatsbeginn oder zu Monatsende? Wenn am Monatsende, dann werden alle zusätzlichen Rückzahlungen, die in diesem Monat gemacht wurden, bereits in der Zins-Kapital-Relation der Rate reflektiert. Wenn zu Monatsbeginn, dann werden sie erst im folgenden Monat reflektiert.",
      "interestCalcMonthEnd": {
        "label": "Zinsen werden am Monatsende berechnet",
        "tooltip": "Wähle, ob die Zinsen am Ende jeden Monats berechnet werden sollen."
      }
    },
    "pension": {
      "accountInfo": "Neues Konto",
      "provideInfo": "Bitte gib einige Details zu deinem Rentenkonto an.",
      "whyDoWeAsk": "Warum wollen wir das wissen?",
      "whyDoWeAskAnswer": "Wir verwenden diese Parameter, um den Wert deiner Rente zu berechnen und zukünftige Zahlungen zu simulieren.",
      "doIHaveToFillThemAllOut": "Muss ich alle diese Felder ausfüllen?",
      "doIHaveToFillThemAllOutAnswer": "Wir benötigen einige Informationen, um dir sinnvolle Auskünfte geben zu können. Felder, die als 'optional' gekennzeichnet sind, sind nicht zwingend erforderlich, werden jedoch verwendet, um weitere nützliche Daten für dich zu berechnen.",
      "useATemplate": "Benutze eine Vorlage!",
      "useATemplateAnswer": "Die gängigen Rentenprodukte wurden bereits für dich konfiguriert - wähle sie einfach aus der Liste aus.",
      "yourPensionContributions": "Beiträge - während der Ansparphase",
      "yourPensionPayouts": "Auszahlungen - wenn du in Rente gehst",
      "whatYouWillReceive": "Wieviel beträgen die Auszahlungen?",
      "whenIBuy": "Wenn du Wertpapiere kaufst, das Geld...",
      "existingAccount": "...wird von einem bestehenden Bankkonto abgezogen.",
      "existingAccountSelect": "Wähle dieses Bankkonto aus.",
      "createAccountLater": "... wird von einem Bankkonto abgebucht, das ich später einrichten werde.",
      "createAccountLaterDescription": "Sobald du dieses Konto eingerichtet hast, denke daran, das verknüpfte Konto in diesem Konto zu aktualisieren."
    },
    "objectsOfValue": {
      "accountInfo": "Neues Konto hinzufügen",
      "provideInfo": "Bitte gib einige Details zu deinem Rentenkonto an.",
      "whyDoWeAsk": "Warum fragen wir das?",
      "whyDoWeAskAnswer": "Wir verwenden diese Parameter, um den Wert deiner Rente zu berechnen und zukünftige Zahlungen zu simulieren.",
      "whenIBuy": "Wenn ich Wertobjekte kaufe, wird das Geld...",
      "existingAccount": "... von einem bestehenden Bankkonto abgebucht",
      "existingAccountSelect": "Wähle dieses Bankkonto aus",
      "createAccountLater": "... von einem Bankkonto abgebucht, das ich später einrichten werde",
      "createAccountLaterDescription": "Sobald du dieses Konto eingerichtet hast, denke daran, das verknüpfte Konto in diesem Konto zu aktualisieren."
    },
    "metals": {
      "accountInfo": "Neues Konto hinzufügen",
      "provideInfo": "Du kannst entscheiden, wie du deine Edelmetall-Assets strukturieren möchtest, indem du sie in Konten organisierst. Du kannst mehrere Konten für verschiedene Metallarten oder verschiedene Lagerorte erstellen.",
      "whenIBuy": "Wenn ich Edelmetalle kaufe, wird das Geld...",
      "existingAccount": "... von einem bestehenden Bankkonto abgebucht",
      "existingAccountSelect": "Wähle dieses Bankkonto aus",
      "createAccountLater": "... von einem Bankkonto abgebucht, das ich später einrichten werde",
      "createAccountLaterDescription": "Sobald du dieses Konto eingerichtet hast, denke daran, das verknüpfte Konto in diesem Konto zu aktualisieren.",
      "apply": "Anwenden"
    },
    "unlistedShares": {
      "accountInfo": "Neues Konto hinzufügen",
      "provideInfo": "Bitte gib ein paar Grundinfos ein, um loszulegen. Sobald das Konto erstellt wurde, kannst du Bewertungen und weitere Einzahlungen hinzufügen.",
      "whenIBuy": "Wenn ich Edelmetalle kaufe, wird das Geld...",
      "existingAccount": "... von einem bestehenden Bankkonto abgebucht",
      "existingAccountSelect": "Wähle dieses Bankkonto aus",
      "createAccountLater": "... von einem Bankkonto abgebucht, das ich später einrichten werde",
      "createAccountLaterDescription": "Sobald du dieses Konto eingerichtet hast, denke daran, das verknüpfte Konto in diesem Konto zu aktualisieren.",
      "fxRate": "Wechselkurs: {{fxRate}}."
    },
    "crypto": {
      "accountInfo": "Neues Konto hinzufügen",
      "provideInfo": "Bitte gib ein paar Grundinfos ein, um loszulegen. Sobald das Konto erstellt wurde, kannst du ihm die eigentlichen Cryptoanlagen hinzufügen.",
      "whenIBuy": "Wenn ich Edelmetalle kaufe, wird das Geld...",
      "existingAccount": "... von einem bestehenden Bankkonto abgebucht",
      "existingAccountSelect": "Wähle dieses Bankkonto aus",
      "createAccountLater": "... von einem Bankkonto abgebucht, das ich später einrichten werde",
      "createAccountLaterDescription": "Sobald du dieses Konto eingerichtet hast, denke daran, das verknüpfte Konto in diesem Konto zu aktualisieren.",
      "createTechnicalAccount": "... von einem Geldkonto bei meiner Börse / Broker abgezogen",
      "createTechnicalAccountTooltip": "Wenn du diese Option auswählst, erstellen wir automatisch ein Geldkonto.",
      "createTechnicalAccountCurrency": "In welcher Währung ist dieses Geldkonto?",
      "apply": "Anwenden"
    }
  },
  "accountDetails": {
    "accountDetails": "Kontodetails",
    "last12months": "Letzte 12 Monate (mit Transaktionen)",
    "last10years": "Letzte 10 Jahre",
    "stats": "Kernzahlen",
    "goToReports": "Zu den Reports",
    "synchronisingChanges": "Synchronisiere Änderungen...",
    "noConnectedAccount": "Bankkonto verlinken",
    "initialSaldo": "Deine Bank gab keine Details für eine Anfangstransaktion an. Falls nötig, teile sie in kleinere Transaktionen auf und passe die Daten an.",
    "help": "Link zu den Hilfeseiten dieses Screens.",
    "draft": "Deine Bank gibt keine Details für eine Transaktion an. Falls nötig, teile sie in kleinere Transaktionen auf und passe die Daten an.",
    "multipleSelected": "Mehrauswahl möglich",
    "errorFieldRequired": "Feld ist erforderlich",
    "errorOneRowRequired": "Mindestens ein Eintrag ist notwendig",
    "errorMustBePositive": "Muss positiv sein",
    "errorMustBeNumber": "Muss eine Zahl sein",
    "effectiveDate": "Gültig ab",
    "value": "Wert",
    "estimateType": "Art der Schätzung",
    "quote": "Auszahlung",
    "quoteInCurrency": "Auszahlung in {{currency}}",
    "create": "Neuer Eintrag",
    "buttons": {
      "duplicatesFound": "Wir haben Einträge identifiziert, die möglicherweise doppelt sind. Bitte überprüfe die folgenden Einträge und wenn gewünscht, unmarkiere die doppelten Einträge.",
      "firstUpload": "Dies ist der erste Upload für dieses Konto. Bitte überprüfe die folgenden Einträge und wenn gewünscht, unmarkiere die doppelten Einträge.",
      "loading": "Datei wird verarbeitet...",
      "loadingTakesLong": "Der Vorgang kann mehr als 30 Sekunden dauern.",
      "columnMatcherHeader": "Spaltenzuordnung",
      "columnMatcherInfo": "Ziehe die Spaltenüberschriften an ihre richtigen Stellen in der oberen Zeile. Einige Überschriften wurden möglicherweise bereits für dich platziert. Bewege den Cursor über Tabelle-Ikonen in jeder Datei-Spalte um eine Vorschau dieser Spalte anzuzeigen. Nicht alle spalten der Datei müssen verwendet werden.",
      "columnName": "Spaltenname",
      "customColumnDescription": "Eine zusammengesetzte Spalte",
      "dragToScroll": "Ziehe um zu scrollen",
      "headersInRow": {
        "label": "Überschriften in Zeile",
        "tooltip": "In welcher Zeile der Datei befinden sich die Überschriften?"
      },
      "readOnly": {
        "tooltip": "Du kannst Transaktionen den Projekten hinzufügen, und sie werden hier angezeigt.",
        "label": "Diese Ansicht ist schreibgeschützt in Projekt-Modus."
      },
      "whatYouOwn": {
        "label": "Dein Eigentum",
        "tooltip": "Zeige die Liste aller Käufe und Verkäufe deiner Anlagen."
      },
      "whatIsItWorth": {
        "label": "Wert",
        "tooltip": "Zeige Wertänderungen deiner Anlagen in der Zeit an."
      },
      "payments": {
        "label": "Zahlungen",
        "tooltip": "Zeige geleistete und erhaltene Zahlungen an."
      },
      "presentValue": {
        "label": "Wert",
        "tooltip": "Zeige zu erwarteten monatlichen Zahlungen deines Produktes an (wie du sie von dem Institut erhalten hast, von dem du dieses Produkt gekauft hast)."
      },
      "sync": {
        "tooltip": "Transaktionen automatisch importieren."
      },
      "import": {
        "tooltip": "Transaktionen aus einer Datei importieren."
      },
      "table": {
        "label": "Tabelle",
        "tooltip": "Transaktionen anzeigen.",
        "tooltipActive": "Änderungen verlassen und zurück zu der Tabelle gehen."
      },
      "edit": {
        "label": "Bearbeiten",
        "tooltip": "Transaktionen in einem Spreadsheet editieren.",
        "tooltipActive": "Änderungen verlassen und zurück zu der Tabelle gehen."
      },
      "addNew": {
        "label": "Hinzufügen",
        "tooltip": "Neue Kapitaltransaktion oder .",
        "tooltipActive": "Änderungen verlassen und zurück zu der Tabelle gehen."
      },
      "settings": {
        "label": "Einstellungen",
        "tooltip": "Passe Kontoeinstellungen an."
      },
      "close": {
        "label": "Schliessen",
        "tooltip": "Änderungen verlassen und zum Dashboard zurückkehren."
      },
      "save": {
        "label": "Speichern",
        "tooltip": "Änderungen permanent speichern."
      },
      "saveAndClose": {
        "label": "Speichern und schliessen",
        "tooltip": "Änderungen permanent speichern und zum Import-Dialog zurückkehren."
      },
      "cancel": {
        "label": "Abbrechen",
        "tooltip": "Abbrechen und zu Tabelle-Sicht zurückkehren."
      },
      "undo": {
        "label": "Undo",
        "tooltip": "Letzte Änderung rückgängig machen."
      },
      "redo": {
        "label": "Redo",
        "tooltip": "Letzte Änderung wiederholen."
      },
      "uploadFiles": {
        "label": "Dateien hochladen",
        "tooltip": "Transaktionen aus lokalen Dateien importieren."
      },
      "cancelFileUpload": {
        "label": "Dateiupload abbrechen",
        "tooltip": "Schließt diesen Dialog, ohne etwas hochzuladen."
      },
      "resetColumns": {
        "label": "Spalten zurücksetzen",
        "tooltip": "Setzt alle Zuordnungen zurück."
      },
      "confirmAndContinue": {
        "label": "Bestätigen und fortfahren",
        "tooltip": "Fährt mit dem Import der Datei gemäß der unten angegebenen Zuordnungen fort."
      },
      "skipThisFile": {
        "label": "Diese Datei überspringen",
        "tooltip": "Überspringt diese Datei (ohne sie hochzuladen) und geht zur nächsten über."
      },
      "addAnotherOperation": {
        "label": "Weitere Operation zufügen",
        "tooltip": "Fügt eine weitere Operation zu Berechnung dieser Spalte hinzu."
      },
      "newColumn": {
        "label": "Spalte hinzufügen",
        "tooltip": "Fügt eine neue Spalte hinzu."
      },
      "customColumns": {
        "label": "Neue Spalten",
        "tooltip": "Neuen Spalten, die ihre Werte aus bestehenden Spalten erhalten, hinzufügen / ändern."
      },
      "columnProperties": {
        "label": "Eigenschaften",
        "tooltip": ""
      },
      "columnActions": {
        "label": "Aktionen",
        "tooltip": ""
      }
    },
    "deleteThisColumn": {
      "label": "Diese Spalte löschen",
      "tooltip": "Löscht diese Spalte und alle zugeordneten Daten."
    },
    "historicalValueCalc": {
      "label": "Kaufpreis unbekannt?",
      "tooltip": "Wir berechnen gerne den geschätzten Wert am Kaufdatum. Wir benötigen dazu das Kaufdatum und die Attribute (Gewicht, Reinheit usw.)."
    },
    "previous": {
      "label": "Vorherige",
      "tooltip": "Details der vorherigen Bewertung öffnen."
    },
    "next": {
      "label": "Nächste",
      "tooltip": "Details der nächsten Bewertung öffnen."
    },
    "attributes": {
      "category": {
        "tooltip": "Kategorie, zu dieser dieses Konto gehört."
      },
      "bankName": {
        "tooltip": "Bank, die dieses Konto bereitstellt."
      },
      "brokerName": {
        "tooltip": "Broker, der dieses Depot bereitstellt."
      },
      "iban": {
        "tooltip": "IBAN-Nummer dieses Kontos."
      },
      "accountNo": {
        "tooltip": "Identifikator oder Nummer dieses Kontos.",
        "stub": "Depotnummer?"
      },
      "bic": {
        "tooltip": "BIC (Bank-Identifikationsnummer) dieser Bank."
      },
      "connectedCashAccount": {
        "tooltip": "Geldkonto, worauf die Kauf- / Verkaufsbetrag sowie Rendite-Zahlungen hierfür erfolgen.",
        "error": "Fehler: kein verbundenes Geldkonto liegt vor"
      },
      "streetAddress": {
        "tooltip": "Die Adresse der Immobilie"
      },
      "floorArea": {
        "tooltip": "Wohnfläche"
      },
      "numberOfRooms": {
        "tooltip": "Anzahl Zimmer"
      },
      "lotArea": {
        "tooltip": "Grundstückfläche"
      },
      "constructionYear": {
        "tooltip": "Baujahr"
      },
      "garage": {
        "tooltip": "Verfügt die Immobilie über eigene Garagen- oder Stellplatz?"
      },
      "currency": {
        "tooltip": "In welcher Währung soll die Wert der Immobilie angegeben werden?"
      },
      "multiple": "verlinkte Konten"
    },
    "columns": {
      "standardTooltip": "Klicke hier um nach dieser Spalte zu sortieren oder um die Reihenfolge zu ändern.",
      "emptyString": "",
      "readOnly": "(geschützt)",
      "category": "Kategorie",
      "date": "Datum",
      "Date (Day)": "Datum",
      "Date (Month)": "Datum",
      "Date (Year)": "Datum",
      "Income from capital": "Kapitaleinkommen",
      "accountName": "Kontoname",
      "displayName": "Name",
      "total": "Summe",
      "otherParty": "Andere Partei",
      "otherPartyAccount": "Andere Partei Kontonr.",
      "symbol": "Symbol",
      "description": "Bezeichnung",
      "amount": {
        "label": "Betrag",
        "tooltip": "Transaktionsbetrag in der Währung dieses Kontos. Bei vorhandenen Orginalwährung und Orginalbetrag kann der Betrag automatisch berechnet werden."
      },
      "quantity": {
        "label": "Anzahl",
        "tooltip": "Anzahl der gekauften (positive Zahl) oder verkauften (negative Zahl) Assets"
      },
      "label": {
        "label": "Tag",
        "tooltip": "Welche Art der Transaktion ist es? Diese Tags ermöglichen Berechnung deines Vermögens sowie diversen Indikatoren."
      },
      "principal": {
        "label": "Kapital",
        "tooltip": "Kapitalanteil der Kreditrate (d.h. der Betrag, der zur Tilgung des Kredits verwendet wird)."
      },
      "interest": {
        "label": "Zinsen",
        "tooltip": "Zinsanteil der Kreditrate (d.h. der Betrag, der als Zinsen an den Kreditgeber gezahlt wird)."
      },
      "iban": {
        "label": "IBAN",
        "tooltip": "IBAN-Nummer des Senders / Empfängers dieser Transaktion."
      },
      "transactionAmount": {
        "label": "Anzahl",
        "tooltip": "Anzahl der gehandelten Wertpapiere"
      },
      "transactionPrice": {
        "label": "Stückpreis",
        "tooltip": "Preis pro Stück des Wertpapiers, in der Währung der Transaktion"
      },
      "transactionCurrency": {
        "label": "Währung",
        "tooltip": "Währung, in der die Transaktion ursprünglich getätigt wurde"
      },
      "type": "Typ",
      "fxCurrency": {
        "label": "Originalwährung",
        "tooltip": "Währung, in der die Transaktion ursprünglich getätigt wurde (optional)"
      },
      "fxAmount": {
        "label": "Originalbetrag",
        "tooltip": "Transaktionsbetrag in der originalen Währung (optional)"
      },
      "fxAmountInterest": {
        "label": "Originalzinsen",
        "tooltip": "Transaktionszinsen in der originalen Währung (optional)"
      },
      "fxRate": {
        "label": "Wechselkurs",
        "tooltip": "Wechselkurs zwischen der originalen Währung und der Währung dieses Kontos (berechnet automatisch)"
      },
      "value": "Wert",
      "rebasedOriginalValue": "Wert\n(Transaktions-\nwärhung)",
      "rebasedValue": "Wert\n(Basis-\nwährung)",
      "rebasedAmount": {
        "label": "Anzahl\nnach\nSplits",
        "tooltip": "Anzahl der Wertpapiere der Transaktion, nachdem alle Splits seit dem Transaktionsdatum berücksichtigt worden sind."
      },
      "assetId": {
        "label": "Asset",
        "tooltip": "Für welches Asset ist dieser Preis?"
      },
      "assetName": {
        "label": "Name",
        "tooltip": "Wie möchtest du dieses Asset nennen?"
      },
      "assetMetal": {
        "label": "Metall",
        "tooltip": "Um welches Edelmetall handelt es sich? Wähle aus der Liste."
      },
      "assetPurity": {
        "label": "Reinheit",
        "tooltip": "Verschiedene Formen von Edelmetallvermögen haben unterschiedliche Reinheiten, z. B. .999 für Gold. Gib die Reinheit dieses Assets in Tausendstel an (999 oder 965)."
      },
      "assetAdditionalValue": {
        "label": "Zusatzwert",
        "tooltip": "Einige Münzen haben aufgrund ihrer Seltenheit oder künstlerischen Bedeutung einen zusätzlichen Wert. Gib den Zusatzwert deines Assets in Prozent an (dies wird zum reinen Metallwert hinzugefügt). Beispiel: wenn das Metall darin allein 100 wert ist und der zusätzliche Wert 20% beträgt, berechnen wir den Wert dieser Münze als 120. Steigt der Metallpreis, addieren wir weiterhin die 20% für diese Münze."
      },
      "assetWeight": {
        "label": "Gewicht",
        "tooltip": "Wieviel wiegt dieses Asset? Trage das Gewicht in Gramm ein."
      },
      "transactionValue": {
        "label": "Transaktionssumme",
        "tooltip": "Optional. Gekaufte/verkaufte Menge * Preis pro Einheit. Wenn du beide Werte eingibst, berechnen wir dies automatisch."
      },
      "transactionCurrencyValue": {
        "label": "Originelle Summe",
        "tooltip": "Optional. Gekaufte/verkaufte Menge * Preis pro Einheit in der ursprünglichen Transaktionswährung. Wenn du beide Werte eingibst, berechnen wir dies automatisch."
      },
      "rebasedOriginalPrice": {
        "label": "Preis\nnach\nSplits",
        "tooltip": "Wenn Wertpapiere einem Split unterworfen wurden, verringern sich ihre Stückpreise verhältnismäßig. Preise in dieser Spalte berücksichtigen diese Änderungen."
      },
      "pricePerSquareMetre": "Preis per m",
      "originalPricePerSquareMetre": "Preis (Original-\nwährung) per m",
      "transactionType": {
        "label": "Transaktionsart",
        "tooltip": "Kauf oder Verkauf der Immobilie."
      },
      "realEstateTransactionPrice": {
        "label": "Preis\n(Basiswährung)",
        "tooltip": "Der eigentliche Kaufs- oder Verkaufspreis, in der Basiswährung, Transaktionswährung kann automatisch berechnet werden. Klicke hier um nach dieser Spalte zu sortieren oder um die Reihenfolge zu ändern."
      },
      "realEstateOriginalPrice": {
        "label": "Preis\n(Originalwährung)",
        "tooltip": "Der eigentliche Kaufs- oder Verkaufspreis, in der eigentlichen Transaktionswährung, Basiswährung kann automatisch berechnet werden.. Klicke hier um nach dieser Spalte zu sortieren oder um die Reihenfolge zu ändern."
      },
      "source": {
        "label": "Quelle",
        "tooltip": "Kommt die Bewertung von dem Provider oder ist es manuell eingepflegt? Klicke hier um nach dieser Spalte zu sortieren oder um die Reihenfolge zu ändern."
      },
      "price": {
        "label": "Wert\n(Basis-\nwährung)",
        "tooltip": "Welchen Verkaufspreis (in Basiswährung) würde die Immobilie an dem Datum erzielen? Klicke hier um nach dieser Spalte zu sortieren oder um die Reihenfolge zu ändern.",
        "tooltipGrid": "Wenn Originalwährung und -preis vorhanden sind, wird dieses Feld automatisch berechnet."
      },
      "priceShort": {
        "label": "Preis"
      },
      "currency": "Originalwährung",
      "originalPrice": {
        "label": "Wert\n(Original-\nwährung)",
        "tooltip": "Welchen Verkaufspreis (in der Originalwährung) würde die Immobilie an dem Datum erzielen? Klicke hier um nach dieser Spalte zu sortieren oder um die Reihenfolge zu ändern.",
        "tooltipGrid": "Wenn leer, wird EUR eingesetzt."
      },
      "size": {
        "label": "Anzahl",
        "tooltip": "Anzahl aller noch nicht verkauften Wertpapiere dieser Transaktionen. Klicke hier um nach dieser Spalte zu sortieren oder um die Reihenfolge zu ändern."
      },
      "split": {
        "label": "Split",
        "tooltip": "Verhältnis von alten zu neuen Aktien."
      },
      "openPositionSize": {
        "label": "Unverkaufte Anzahl",
        "tooltip": "Anzahl aller noch nicht verkauften Wertpapiere dieser Transaktionen. Klicke hier um nach dieser Spalte zu sortieren oder um die Reihenfolge zu ändern."
      },
      "purchaseValue": {
        "label": "Kaufwert",
        "tooltip": "Wert beim Ankauf der Wertpapiere, in deiner Basiswährung. Klicke hier um nach dieser Spalte zu sortieren oder um die Reihenfolge zu ändern."
      },
      "baselineValue": {
        "label": "Basiswert",
        "tooltip": "Wert an dem von dir festgelegten Basisdatum, in deiner Basiswährung. Klicke hier um nach dieser Spalte zu sortieren oder um die Reihenfolge zu ändern."
      },
      "currentValue": {
        "label": "Aktueller Wert",
        "tooltip": "Aktueller Wert der Wertpapiere in deiner Basiswärhung. Klicke hier um nach dieser Spalte zu sortieren oder um die Reihenfolge zu ändern."
      },
      "valueTransactionCurrency": {
        "label": "Wert\n(Transaktions-\nwärhung)",
        "tooltip": "Optional. Wert der Anlage in der Transaktionswährung. Klicke hier um nach dieser Spalte zu sortieren oder um die Reihenfolge zu ändern."
      },
      "valueAccountCurrency": {
        "label": "Wert",
        "tooltip": "Optional. Wert der Anlage in der Kontowährung. Klicke hier um nach dieser Spalte zu sortieren oder um die Reihenfolge zu ändern."
      },
      "valueBaseCurrency": {
        "label": "Wert\n(Basis\nwährung)",
        "tooltip": "Optional. Wert der Anlage in deiner Basisswährung. Klicke hier um nach dieser Spalte zu sortieren oder um die Reihenfolge zu ändern."
      },
      "purchasePrice": {
        "label": "Kaufpreis",
        "tooltip": "Preis beim Ankauf der Wertpapiere, in deiner Basiswährung. Klicke hier um nach dieser Spalte zu sortieren oder um die Reihenfolge zu ändern."
      },
      "baselinePrice": {
        "label": "Basispreis",
        "tooltip": "Preis an dem von dir festgelegten Basisdatum, in deiner Basiswährung. Klicke hier um nach dieser Spalte zu sortieren oder um die Reihenfolge zu ändern."
      },
      "currentPrice": {
        "label": "Aktueller Preis",
        "tooltip": "Aktueller Preis und Wert der Wertpapiere in deiner Basiswärhung. Klicke hier um nach dieser Spalte zu sortieren oder um die Reihenfolge zu ändern."
      },
      "purchaseCurrentValuePrice": {
        "label": "Wert / Preis",
        "tooltip": "Preis und Wert beim Ankauf der Wertpapiere sowie jetzt, in deiner Basiswährung. Klicke hier um nach dieser Spalte zu sortieren oder um die Reihenfolge zu ändern."
      },
      "purchaseValuePrice": {
        "label": "Kaufwert / -preis",
        "tooltip": "Preis und Wert beim Ankauf der Wertpapiere, in deiner Basiswährung. Klicke hier um nach dieser Spalte zu sortieren oder um die Reihenfolge zu ändern."
      },
      "baselineValuePrice": {
        "label": "Basiswert / -preis",
        "tooltip": "Preis und Wert an dem von dir festgelegten Basisdatum, in deiner Basiswährung. Klicke hier um nach dieser Spalte zu sortieren oder um die Reihenfolge zu ändern."
      },
      "currentValuePrice": {
        "label": "Aktueller Wert / Preis",
        "tooltip": "Aktueller Preis und Wert der Wertpapiere in deiner Basiswärhung. Klicke hier um nach dieser Spalte zu sortieren oder um die Reihenfolge zu ändern."
      },
      "dividends": {
        "label": "Dividenden / Gebühren",
        "tooltip": "Summe aller Dividenden und Gebühren seit dem Kauf der Wertpapiere + anteilig kontobezogenen Kosten. Klicke hier um nach dieser Spalte zu sortieren oder um die Reihenfolge zu ändern."
      },
      "dividendsSincePurchase": {
        "label": "Dividenden / Gebühren seit Kauf ",
        "tooltip": "Summe aller Dividenden und Gebühren seit dem Kauf der Wertpapiere. Klicke hier um nach dieser Spalte zu sortieren oder um die Reihenfolge zu ändern."
      },
      "roi": {
        "label": "ROI gesamt / ROI jährlich",
        "tooltip": "Aktueller Wert der Wertpapiere im Verhältnis zu dem Kaufwert (Return on Investment). Berücksichtigt Dividenden und Gebühren. Gesamt und als Durchschnitt pro Jahr. Klicke hier um nach dieser Spalte zu sortieren oder um die Reihenfolge zu ändern."
      },
      "roiShort": {
        "label": "ROI gesamt / jährlich",
        "tooltip": "Aktueller Wert der Wertpapiere im Verhältnis zu dem Kaufwert (Return on Investment). Dividenden und Gebühren inklusiv. Gesamt und als Durchschnitt pro Jahr. Klicke hier um nach dieser Spalte zu sortieren oder um die Reihenfolge zu ändern."
      },
      "roiOnly": {
        "label": "ROI",
        "tooltip": "Aktueller Wert der Wertpapiere im Verhältnis zu dem Kaufwert (Return on Investment). Dividenden und Gebühren inklusiv. Klicke hier um nach dieser Spalte zu sortieren oder um die Reihenfolge zu ändern."
      },
      "payoutsSincePurchase": {
        "label": "Auszahlungen seit Kauf",
        "tooltip": "Summe aller Auszahlungen seit dem Kauf der Assets. Klicke hier um nach dieser Spalte zu sortieren oder um die Reihenfolge zu ändern."
      },
      "feesSincePurchase": {
        "label": "Gebühren seit Kauf",
        "tooltip": "Summe aller Gebühren seit dem Kauf der Assets. Klicke hier um nach dieser Spalte zu sortieren oder um die Reihenfolge zu ändern."
      },
      "costsSincePurchase": {
        "label": "Kosten seit Kauf",
        "tooltip": "Summe aller Kosten seit dem Kauf der Assets. Klicke hier um nach dieser Spalte zu sortieren oder um die Reihenfolge zu ändern."
      },
      "roiAnnual": {
        "label": "Jährliches ROI",
        "tooltip": "Wie müsste der anfängliche Betrag der Investition verzinst sein, um den aktuellen Wert zu erreichen? Berücksichtigt Dividenden und Gebühren. Klicke hier um nach dieser Spalte zu sortieren oder um die Reihenfolge zu ändern."
      },
      "keepFlag": {
        "label": "Behalten?",
        "tooltip": "Uncheck the rows that you want to remove from your import."
      },
      "payoutOnlyContributionsToDate": {
        "label": "Rente\n(nur Beiträge bis heute)",
        "tooltip": "Höhe der Rentenzahlungen, die du erhalten würdest, falls du heute die Beiträge einstellst."
      },
      "payoutOnlyContributionsToDateIndexed": {
        "label": "Rente\n(nur Beiträge bis heute,\nmit Wertentwicklung)",
        "tooltip": "Höhe der Rentenzahlungen, die du erhalten würdest, falls du heute die Beiträge einstellst, mit zukünftiger Wertentwicklung bereits angelegtes Kapitals."
      },
      "payoutAllPlannedContributions": {
        "label": "Rente\n(alle zukünftigen Beiträge)",
        "tooltip": "Höhe der Rentenzahlungen, die du erhalten würdest, falls du die Beiträge bis der Ende der Ansparphase zahlst."
      },
      "payoutAllPlannedContributionsIndexed": {
        "label": "Rente\n(alle zukünftigen Beiträge,\nmit Wertentwicklung)",
        "tooltip": "Höhe der Rentenzahlungen, die du erhalten würdest, falls du die Beiträge bis der Ende der Ansparphase zahlst, mit zukünftiger Wertentwicklung bereits angelegtes Kapitals."
      },
      "note": {
        "label": "Note",
        "tooltip": "Valuation details"
      },
      "proceeds": {
        "label": "Einnahmen",
        "tooltip": "Einnahmen von diesem Wertobjekt"
      }
    },
    "stocks": {
      "allowedTransactionTypes": [
        "sale",
        "purchase",
        "split"
      ]
    },
    "deposits": {
      "labels": {
        "noLabel": {
          "long": "Ohne Tag",
          "short": "Ohne Tag"
        },
        "investment-fees": {
          "long": "Transaktionsgebühr",
          "short": "Gebühr"
        },
        "investment-costs": {
          "long": "Kontogebühr",
          "short": "Kontogebühr"
        },
        "investment-interest": {
          "long": "Zinsen",
          "short": "Zinsen"
        },
        "investment-dividend": {
          "long": "Dividende",
          "short": "Dividende"
        },
        "investment-transfer": {
          "long": "Transfer",
          "short": "Transfer"
        },
        "investment-rent": {
          "long": "Mieteinnahmen",
          "short": "Miete"
        },
        "investment-purchase": {
          "long": "Asset-Kauf",
          "short": "Asset-kauf"
        },
        "investment-sale": {
          "long": "Asset-Verkauf",
          "short": "Asset-Verkauf"
        },
        "investment-payout": {
          "long": "Kreditauszahlung",
          "short": "Kreditauszahlung"
        },
        "investment-loanInterest": {
          "long": "Kreditrate (Zins)",
          "short": "Kredit (Zinsen)"
        },
        "investment-loanPrincipal": {
          "long": "Kreditrate (Kapital)",
          "short": "Kredit (Kapital)"
        },
        "investment-pension": {
          "long": "Rente (Auszahlung)",
          "short": "Rente"
        },
        "investment-contribution": {
          "long": "Rentenbeitrag",
          "short": "Beitrag"
        }
      },
      "markThisTransaction": "Diese Transaktion taggen",
      "noTransactions": "Keine mit diesem Konto verlinkten Transaktionen gefunden.",
      "noTransactionsWithin": "Keine ähnlichen, mit diesem Konto verlinkten Transaktionen gefunden, die innerhalb von 7 Tagen von dieser Transaktion stattfanden.",
      "noAccounts": "Keine verlinkten Konten gefunden.",
      "noAssets": "Keine Assets auf verlinkten Kontos gefunden.",
      "addTag": "Tag hinzufügen",
      "whichItem": "Auf welche(s) Konto / Transaktion bezieht sich die Transaktion?",
      "createMatchingTransaction": "Erstelle auch eine Transaktion im Asset-Konto",
      "transactionCurrencyMismatch": "Die Originalwährung dieser Transaktion muss auch die Währung des verlinkten Asset-Kontos sein."
    },
    "pension": {
      "labels": {
        "noLabel": {
          "long": "Ohne Tag",
          "short": "Ohne Tag"
        },
        "pension-purchase": {
          "long": "Produktkauf",
          "short": "Kauf"
        },
        "pension-contribution": {
          "long": "Beitrag",
          "short": "Beitrag"
        },
        "pension-payout": {
          "long": "Auszahlung",
          "short": "Zahlung"
        }
      },
      "import": {
        "title": "Welches Produkt verwenden?",
        "prompt": "Du scheinst Daten von rvuebersicht.de zu importieren. In dieser Datei gibt es Daten für mehere Produkte. Bitte wähle das für dieses Konto richtige Produkt aus.",
        "ignore": "Ignorieren",
        "cancel": "Cancel",
        "baseCurrencyDialog": {
          "title": "Currency conversion",
          "prompt": "The currency of the imported data is different from the base currency of this account. How should we convert this currency? As it is difficult to predict the future exchange rate, we suggest to use today's.",
          "fxRate": "Exchange rate to use",
          "todayFxIs": "Today's exchange rate is",
          "apply": "Anwenden"
        }
      }
    },
    "contextMenu": {
      "addEmptyRowAbove": "Leere Zeile(n) oben einfügen",
      "addEmptyRowBelow": "Leere Zeile(n) unten einzufügen",
      "duplicateRow": "Zeile duplizieren",
      "deleteRow": "Zeile löschen",
      "cut": "Ausschneiden",
      "copy": "Kopieren",
      "paste": "Einfügen",
      "download": "Alles exportieren",
      "downloadSelected": "Markierte Zeilen exportieren"
    },
    "splitMenu": {
      "split": "Aufteilen",
      "splitHeader": "Diese Transaktion in zwei oder mehr Transaktionen aufteilen",
      "splitInfo": "Denke daran, Transaktionsgebühren und Kauf-/Verkaufstransaktionen im verbundenen Einzahlungskonto aufzuteilen.",
      "splitExistingDetected": "Diese Transaktion wurde bereits aufgeteilt. Du kannst die Aufteilung bearbeiten oder die Aufteilung rückgängig machen.",
      "copyAsSimulated": "Als Simulation kopieren",
      "copyAsSimulatedInfo": "Erstelle eine simulierte Transaktion basierend auf dieser. Du kannst alle Parameter im nächsten Bildschirm anpassen.",
      "showInProject": "Mit Projekt verlinken",
      "showInProjectInfo": "Du kannst diese tatsächliche Transaktion in einem Projekt anzeigen.",
      "splitLabelLoan": "Darlehen: teilen, verlinken + taggen.",
      "splitLabelLoanInfo": "Teile Darlehenrate in den Zins- und Tilgungsanteil auf, verlinke sie mit dem Darlehen-Projekt und tagge sie.",
      "splitLabelLoanInfo2": "Darlehenrate muss aufgeteilt werden, da beide Teile sich unterschiedlich auf das Kapitaleinkommen auswirken. Die Labels werden den neuen Transaktionen automatisch zugewiesen. Eine gelinkte Transaktion in dem Darlehen-Konto kann auch erstellt werden.",
      "createLoanTransaction": "Erstelle daraus auch eine Transaktion im Darlehen-Konto.",
      "modifyLoanTransaction": "Die verlinkte Darlehen-Transaktion entsprechend anpassen.",
      "selectLoanTransaction": "Wähle die passende Transaction aus dem Darlehen-Konto aus.",
      "selectLoanTransactionInfo": "Zeigt Transaktionen mit ähnlichen Datum und Betrag aus dem verlinkten Darlehen-Konto an.",
      "selectLoanTransactionPrompt": "Wähle...",
      "createLoanTransactionError": "Eine passende Darlehentransaktion wird benötigt. Entweder markiere >Erstelle...< oder finde die passende Transaktion im Dropdown-Menü >Wähle...<.",
      "calculateExpectedSplitInfo": "Falls es in der Transaktion keine Infos zu der Aufteilung von Zins/Kapital dieser Rate gibt, wir können sie anhand Darlehenparameter berechnen.",
      "delete": "Löschen",
      "pressSaveToDelete": "Drücke >Speichern<, um diese Transaktion zu löschen.",
      "pick": "Ein Projekt auswählen",
      "error": "Bitte überprüfe deine Eingabe.",
      "amountToAllocate": "Noch aufzuteilen",
      "allocatedTooMuch": "Zu viel aufgeteilt um",
      "amountToAllocateInterest": "Zinsen noch aufzuteilen",
      "allocatedTooMuchInterest": "Zu viel Zinsen aufgeteilt um",
      "selectLoanAccount": "Darlehen-Konto auswählen",
      "splitAndLabelLoan": "Kredite: aufteilen, taggen + verlinken.",
      "splitAndLabelLoanInfo": "Teile die Kreditrate in Zins- und Tilgungsanteile auf, tagge sie und verknüpfe sie mit dem Kreditprojekt.",
      "splitAndLabelLoanInfo2": "Wir müssen die Kreditrate aufteilen, da die beiden Teile sich unterschiedlich auf das Einkommen aus Kapital auswirken. Dieser Prozess wird auch beide Transaktionen taggen und hier auch die Darlehenskontotransaktion erstellen."
    },
    "settings": {
      "symbol": "Symbol",
      "dividend": "Dividend (% pro Jahr)",
      "growthRate": "Wachstum (% pro Jahr)",
      "add": "Add",
      "simulatedAccountGrowth": {
        "heading": "Simuliertes Kontowachstum",
        "description": "Dieser Wert wird verwendet, um zukünftiges Wachstum all deiner Aktien-Konten zu simulieren. Weitere Informationen findest du in diesem Blog-Eintrag.",
        "fieldLabel": "Wachstumsrate",
        "dividendPayments": "Du kannst auch zukünftige Dividendenzahlungen simulieren - über alle Aktien-Konten hinweg. Gebe die erwartete Dividende pro Aktie pro Jahr an, die jährlich um die oben angegebene Wachstumsrate erhöht wird.",
        "dividendTooltip": "Dividende / Zins wird als % der aktuellen Wert berechnet."
      },
      "spread": {
        "description": "Wir berechnen den aktuellen Wert deiner Edelmetalle basierend auf dem realistischen Preis, den du beim Verkauf deines Vermögenswerts erzielen wirst.",
        "description2": "Dieser Preis ist niedriger als der Marktpreis aufgrund der Marge des Käufers (genannt 'Spread'). Wir empfehlen einen konservativen Spread von 10% des Marktpreises, aber du kannst ihn hier ändern. Der Spread wird vom aktuellen Marktwert deiner Metallwerte abgezogen.",
        "label": "Spread (%)",
        "error": "Bitte gib einen Spread-Prozentsatz an"
      }
    },
    "loanIndicators": {
      "interestPaid": "Gezahlt",
      "interestPaidToPrincipalPaid": "Zu gez. Tilgung",
      "principalRemaining": "Restschuld",
      "interestRemaining": "Restzinsen",
      "history": "Verlauf",
      "loanRepaid": "Getilgt",
      "interest": "Zinsen",
      "balance": "Restsalden"
    },
    "pensionIndicators": {
      "presentValueChanges": "Veränderungen der avisierten Auszahlung",
      "roi": "ROI",
      "sumContributions": "Beiträge",
      "presentValue": "Aktueller Produktwert"
    },
    "unlistedShares": {
      "wizard": {
        "transactions": {
          "header": "Welche Art von Transaktion fügen wir hinzu?",
          "moreShares": "Neue Aktien kaufen",
          "moreCapital": "Eigenkapital erhöhen (ohne neue Aktien zu emittieren)",
          "sharesSale": "Aktien verkaufen",
          "capitalDisbursement": "Eigenkapital als Auszahlung erhalten (Kapitalausschüttung)",
          "error": "Bitte wähle eine Option",
          "formHeader": "Details der Transaktion",
          "submit": "Speichern",
          "clickToOpenDeposit": "Um Eigenkapital ein- oder auszuzahlen, ohne Aktien zu emittieren / zurückzukaufen, gehe zum verbundenen Bankkonto und tagge deine Transaktion als 'Kapitalerhöhung' oder 'Kapitalauszahlung':",
          "noConnectedDeposit": "Verbinde zuerst ein Bankkonto (Button oben, direkt unter Firmenname).",
          "quantity": {
            "label": "Anzahl an Aktien",
            "tooltip": "Anzahl der Aktien, die du kaufst oder verkaufst",
            "error": "Bitte gib eine Anzahl an"
          },
          "upac": {
            "label": "Preis pro Stück",
            "tooltip": "Preis pro Aktie, in der Währung dieses Kontos",
            "error": "Bitte gib einen Preis an"
          },
          "capitalAmount": {
            "label": "Kapitalerhöhungsbetrag",
            "tooltip": "Betrag, den du in das Unternehmen investierst, in der Währung dieses Kontos",
            "error": "Bitte gib einen Betrag an"
          },
          "capitalDisbursementAmount": {
            "label": "Kapitalerauszahlungsbetrag",
            "tooltip": "Betrag, den du aus dem Eigenkapital des Unternehmen erhälst, in der Währung dieses Kontos",
            "error": "Bitte gib einen Betrag an"
          },
          "dateUserInput": {
            "label": "Datum",
            "tooltip": "Datum der Transaktion",
            "error": "Bitte gib ein Datum an"
          }
        },
        "valuation": {
          "title": "Firmenwert-Rechner",
          "description": "Wähle  die Lebenszyklusphase deines Unternehmens unten aus oder trage einen Wert manuell ein.",
          "score": {
            "-2": {
              "label": "Viel weniger",
              "tooltip": "Dein Unternehmen ist in einer deutlich schlechteren Position als der Markt / seine Mitbewerber"
            },
            "-1": {
              "label": "Weniger",
              "tooltip": "Dein Unternehmen ist in einer schlechteren Position als der Markt / seine Mitbewerber"
            },
            "0": {
              "label": "Neutral",
              "tooltip": "Dein Unternehmen ist in einer ähnlichen Position wie der Markt / seine Mitbewerber"
            },
            "1": {
              "label": "Mehr",
              "tooltip": "Dein Unternehmen ist in einer besseren Position als der Markt / seine Mitbewerber"
            },
            "2": {
              "label": "Viel mehr",
              "tooltip": "Dein Unternehmen ist in einer deutlich besseren Position als der Markt / seine Mitbewerber"
            }
          },
          "stage": {
            "label": "Stage",
            "tooltip": "Bewertungsmethoden, die wir anwenden können, hängen von der Entwicklungsphase deines Unternehmens ab. Wähle die Phase aus, die das Unternehmen momentan am besten beschreibt.",
            "idea": {
              "label": "Idee",
              "tooltip": "Das Unternehmen befindet sich in der Ideenphase. Es wurde noch nicht gegründet."
            },
            "prototype": {
              "label": "Prototyp",
              "tooltip": "Das Unternehmen hat einen Prototyp oder die Arbeit ist sehr fortgeschritten. Der Markteintritt ist noch nicht erfolgt."
            },
            "mvp": {
              "label": "MVP",
              "tooltip": "Das Unternehmen hat ein Minimum Viable Product und testet es auf dem Markt."
            },
            "expansion": {
              "label": "Expansion",
              "tooltip": "Das Unternehmen wächst schnell und hat eine stabile Kundenbasis."
            },
            "market": {
              "label": "Markt",
              "tooltip": "Das Unternehmen ist gut etabliert und hat eine starke Kundenbasis."
            }
          },
          "valuationMethod": {
            "label": "Verfügbare Bewertungsmethoden",
            "tooltip": "Wähle die Methoden aus, die du zur Bewertung deines Unternehmens verwenden möchtest. Du kannst mehrere Methoden auswählen."
          },
          "info": "Vergleiche dein Unternehmen mit ähnlichen Unternehmen auf dem Markt. Die Bewertungsmethoden werden basierend auf den von dir bereitgestellten Informationen angewendet.",
          "insufficientData": "Mehr Daten erforderlich",
          "insufficientDataTooltip": "Wir brauchen noch die folgenden Infos, um die Schätzung zu berechnen: {{fields}}.",
          "required": "Bitte gib sowohl das Datum als auch die Bewertung an.",
          "ideaStrength": {
            "label": "Stärke der Idee",
            "tooltip": "Die Stärke der Idee oder des Geschäftskonzepts hinter dem Unternehmen"
          },
          "prototypeProgress": {
            "label": "Prototyp Fortschritt",
            "tooltip": "Der Fortschritt bei der Entwicklung des Produktprototyps ('viel weniger', wenn kein Prototyp existiert, 'viel mehr', wenn ein vollständig getesteter Prototyp existiert)"
          },
          "teamCapability": {
            "label": "Teamfähigkeit",
            "tooltip": "Die Fähigkeit und Erfahrung der Teammitglieder"
          },
          "marketingRelationships": {
            "label": "Marketingbeziehungen",
            "tooltip": "Die Stärke und das Ausmaß der Marketing- / Lieferkettenbeziehungen"
          },
          "marketTraction": {
            "label": "Markttraktion",
            "tooltip": "Erfolg bei der Einführung des Produkts auf dem Markt / Umsatzwachstum"
          },
          "competitiveAdvantage": {
            "label": "Wettbewerbsvorteil",
            "tooltip": "Hat dieses Unternehmen irgendeine Art von Vorteil gegenüber seinen Wettbewerbern?"
          },
          "similarStartupValuationsArray": {
            "label": "Bewertungen ähnlicher Unternehmen",
            "tooltip": "Ungefähre Bewertungen für ähnliche Unternehmen"
          },
          "technologyRisk": {
            "label": "Technologisches Vertrauen",
            "tooltip": "Im Vergleich zu ähnlichen Unternehmen, ist die von diesem Unternehmen verwendete Technologie mehr oder weniger riskant?"
          },
          "ipStrength": {
            "label": "Stärke des geistigen Eigentums",
            "tooltip": "Besitzt das Unternehmen alle wichtigen Patente / geistiges Eigentum, die für den Start und das weitere Wachstum notwendig sind?"
          },
          "totalAdressableMarketSize": {
            "label": "Gesamte adressierbare Marktgröße",
            "tooltip": "Die Gesamtgröße des Marktes, den das Unternehmen adressieren kann"
          },
          "marketGrowthRate": {
            "label": "Marktwachstumsrate",
            "tooltip": "Die Wachstumsrate des Marktes, in dem das Unternehmen tätig ist"
          },
          "marketEntryBarriers": {
            "label": "Markteintrittsbarrieren",
            "tooltip": "Wie schwierig (wegen Kapital, Werkzeuge, geistiges Eigentum etc.) ist es, ein Unternehmen in diesem Markt zu gründen?"
          },
          "needForMoreInvestment": {
            "label": "Bedarf an weiteren Investitionen",
            "tooltip": "Wie viel mehr Investitionen sind erforderlich, um das Unternehmen auf die nächste Stufe zu bringen?"
          },
          "legalRisk": {
            "label": "Rechtliche Stabilität",
            "tooltip": "Je mehr rechtliche (Gesetzgebung, Patente etc.) Risiken das Unternehmen haben könnte, desto geringer ist die Stabilität."
          },
          "internationalRisk": {
            "label": "Internationale Stabilität",
            "tooltip": "Die Risiken im Zusammenhang mit der Ausweitung der internationalen Geschäftstätigkeit"
          },
          "marketRisk": {
            "label": "Marktstabilität",
            "tooltip": "Ist der Markt, in dem das Unternehmen tätig ist, stabil ('mehr') oder volatil ('weniger')?"
          },
          "financialPerformance": {
            "label": "Finanzielle Leistung",
            "tooltip": "Die finanzielle Leistung des Unternehmens (Rentabilität)"
          },
          "assetsArray": {
            "label": "Vermögenswerte",
            "tooltip": "Eine Auflistung der Vermögenswerte des Unternehmens"
          },
          "intangiblesArray": {
            "label": "Immaterielle Werte",
            "tooltip": "Eine Auflistung der immateriellen Werte des Unternehmens"
          },
          "liabilitiesArray": {
            "label": "Verbindlichkeiten",
            "tooltip": "Eine Auflistung der Verbindlichkeiten des Unternehmens"
          },
          "targetRaisedCapital": {
            "label": "Kapital zu beschaffen",
            "tooltip": "Welches Kapital möchte dein Unternehmen derzeit beschaffen?"
          },
          "cashFlowArray": {
            "label": "Cashflows",
            "namePlaceholder": "Jahr",
            "tooltip": "Wie sehen die erwarteten Cashflows deines Unternehmens aus?"
          },
          "discountRate": {
            "label": "Abzinsungssatz (in %)",
            "tooltip": "Der Abzinsungssatz, der in der DCF-Methode verwendet wird (Kapitalbeschaffungskosten für dein Unternehmen, d.h. ungefähr welcher Zinssatz wird auf Darlehen gezahlt)"
          },
          "longTermGrowthRate": {
            "label": "Stabile Wachstumsrate (in %)",
            "tooltip": "Langfristige, nachhaltige Wachstumsrate, sobald die Cashflows stabil sind (sollte konservativ sein und niedriger als der Abzinsungssatz). Wird zur Berechnung des Terminalwerts verwendet.",
            "error": "Die stabile Wachstumsrate muss für diese Berechnung niedriger als der Abzinsungssatz sein."
          },
          "finalQuote": {
            "label": "Endbewertung",
            "tooltip": "Welche Bewertung möchtest du speichern, basierend auf den bereitgestellten Informationen?",
            "error": "Bitte gebe eine Endbewertung an",
            "description": "Bestimme die endgültige Bewertung des Unternehmens basierend auf den bereitgestellten Informationen.",
            "save": "Speichern",
            "deleteThisQuote": "Diese Bewertung löschen"
          },
          "percentOwned": {
            "label": "Anteil in Besitz",
            "tooltip": "Welchen Anteil dieses Unternehmen besitzt du?",
            "error": "Bitte gebe den Anteil an, den du besitzt"
          },
          "methods": {
            "berkus": {
              "label": "Berkus",
              "tooltip": "Eine Methode zur Bewertung von Startups basierend auf Idee, Prototyp, Team, Markttraktion und Wettbewerbsvorteil."
            },
            "scorecard": {
              "label": "Scorecard",
              "tooltip": "Eine Methode zur Bewertung von Startups mittels eines Punktesystems, das verschiedene Aspekte wie Team, Produkt und Markt vergleicht."
            },
            "riskFactor": {
              "label": "Risikofaktor",
              "tooltip": "Eine Methode zur Bewertung der Risiken eines Startups, einschließlich Technologie-, Markt- und Teamrisiken."
            },
            "valuationByStage": {
              "label": "Bewertung nach Phase",
              "tooltip": "Eine Methode zur Bestimmung des Werts eines Startups basierend auf seiner aktuellen Entwicklungsphase."
            },
            "replacementCost": {
              "label": "Ersatzkosten",
              "tooltip": "Eine Methode zur Berechnung der Kosten, um die Vermögenswerte und den Betrieb des Unternehmens zu ersetzen."
            },
            "bookValue": {
              "label": "Buchwert",
              "tooltip": "Eine Methode zur Bewertung des Nettovermögens eines Unternehmens durch Abzug der Verbindlichkeiten von den Vermögenswerten."
            },
            "fiveTimesYourRise": {
              "label": "Fünfmal dein Kapital",
              "tooltip": "Eine Methode, bei der das Zielkapital um das Fünffache multipliziert wird, um den Unternehmenswert zu schätzen."
            },
            "dcf": {
              "label": "DCF",
              "tooltip": "Eine Discounted Cash Flow-Methode zur Bewertung eines Unternehmens basierend auf seinen prognostizierten zukünftigen Cashflows."
            }
          }
        }
      }
    },
    "cryptoAssetMappingDialog": {
      "header": "Automatische Preisaktualisierungen",
      "prompt": "Kryptowährungen sind dezentralisiert. Um sicherzustellen, dass wir den richtigen Preis für deine Vermögenswerte ermittelt haben, überprüfe diese Zuordnungen und klicke auf >Speichern<. Wenn die vorgeschlagenen Preise zu deinem Asset nicht passen, lasse es unmarkiert.",
      "asset": "Krypto-Symbol",
      "status": "Preise ermittelt",
      "headerAssetDetails": "Preise finden für:",
      "promptAssetDetails": "Für diese Kryptowährungen können wir die Preise automatisch aktualisieren. Wähle eine aus oder suche nach einem passenden Begriff, danach klicke auf >OK<. Wenn keine der vorgeschlagenen Kryptowährungen passt, lasse die Liste unmarkiert.",
      "symbol": "Symbol",
      "name": "Name",
      "marketCap": "Marktgröße",
      "liquidity": "Liquidität",
      "price": "Aktueller Preis",
      "page": "Seite",
      "pageOf": "von",
      "next": "Nächstes",
      "previous": "Voheriges",
      "noResults": "Keine Preise gefunden"
    }
  },
  "settings": {
    "Settings": "Einstellungen",
    "Select a tab": "Wähle einen Tab",
    "preferences": {
      "header": "Präferenzen",
      "baseCurrency": "Deine Basiswährung",
      "preferredLanguage": "Bevorzugte Sprache",
      "baselineDate": "Basisdatum"
    },
    "simulations": {
      "header": "Simulationen",
      "info": "Die folgenden Parameter werden in den Simulationen zur Berechnung des zukünftigen Wachstums deiner Vermögenswerte verwendet.",
      "slider": "Schieberegler",
      "setDates": "Lege das Start- und Enddatum für all deine Projekte, Simulationen und den Schieberegler fest.",
      "startDate": "Startdatum",
      "endDate": "Enddatum",
      "inflation": "Inflation",
      "inflationEnabledDescription": "Wenn aktiviert, erhöht das System automatisch alle Ein- und Auszahlungen jedes Jahr um den angegebenen Prozentsatz. Dies gilt nicht für Ein- und Auszahlungen, für die eine individuelle Erhöhungsrate festgelegt ist.",
      "disabled": "Deaktiviert",
      "enabled": "Aktiviert",
      "simulatedInflationRate": "Simulierte Inflationsrate (%)",
      "averageInflation": "Die durchschnittliche Inflation in allen OECD-Ländern betrug von 1982-2022 3,55%, in der Eurozone 2,95% und in Deutschland 1,89%. Quelle",
      "source": "Quelle ",
      "stockMarketGrowthRate": "Wachstumsrate der Kategorien (%)",
      "simpleMode": "Einfacher Modus: setze einen Wachstumsfaktor für alle Anlagen (in allen Depots / Konten in derselben Kategorie).",
      "simpleMode2": "Anhand historischer Daten beträgt die langfristige jährliche Wachstumsrate des Aktienmarkts ca. 5-6%.",
      "advancedMode": "Expertenmodus: setze individuelle Wachstumsraten für jede Aktie / Kryptowährung. Diese gelten für dieses Asset in allen Depots / Konten in der Kategorie.",
      "advancedMode2": "Für Aktien / Kryptowährungen, für die es keine individuelle Wachstumsfaktoren gibt, gilt der allgemeine Wachstumsfaktor.",
      "growthRate": "Wachstumsrate der Kategorie (%)",
      "simple": "Einfach",
      "advanced": "Expert",
      "stockDividends": "Aktiendividenden",
      "stockDividendsDescription": "Einfacher modus: setze eine Dividendenrendite (in %) für alle Aktien. Wir setzen dann diese Rendite für alle Aktien in allen Depots ein, die mindestens einmal Dividende ausgezahlt haben.",
      "stockDividendsDescription2": "Expertenmodus: setze individuelle Dividendenrenditen (in %) für jede dividendenbringende Aktie. Diese Renditen gelten für alle Konten. Die Dividendenrendite werden in diesem Modus nur für hier definierten Aktien simuliert.",
      "dividendYield": "Dividendenrendite (%)",
      "symbol": "Symbol",
      "dividendPerYear": "Dividende (%)",
      "add": "Hinzufügen",
      "save": "Speichern",
      "cancel": "Abbrechen"
    },
    "account": {
      "header": "Nutzerkonto",
      "saveChanges": "Änderungen speichern",
      "2fa": "Zwei-Faktor-Authentifizierung",
      "changePassword": "Passwort ändern",
      "qrCode1": "Scanne den QR-Code mit deiner bevorzugten Authenticator-App (wie ",
      "qrCode2": "oder ",
      "manualEntry": "Wenn du den Code lieber manuell eingeben möchtest, verwende den folgenden Code.",
      "Enable": "Aktivieren",
      "Enabled": "Aktiviert",
      "Email address": "E-Mail-Adresse",
      "emailInfo": "Halt dein E-Mail-Konto bereit, wenn du deine E-Mail änderst -- wir schicken dir einen Bestätigungscode.",
      "pleaseEnterCode": "Bitte gib den Bestätigungscode aus deiner E-Mail ein.",
      "enterAuthCode": "Gib den Code aus deiner Authenticator-App ein, sobald du fertig bist:",
      "Confirm": "Bestätigen",
      "Submit": "Abschicken",
      "Username": "Benutzername",
      "Password": "Passwort",
      "Enter your current password": "Gib dein aktuelles Passwort ein",
      "Please enter your current password.": "Bitte gib dein aktuelles Passwort ein.",
      "The new password cannot be the same as the current password.": "Das neue Passwort darf nicht dasselbe wie das aktuelle Passwort sein.",
      "Enter new password": "Neues Passwort eingeben",
      "Repeat new password": "Neues Passwort wiederholen"
    },
    "billing": {
      "header": "Abo",
      "title": "Abo",
      "leadingText1": "Deine Privatsphäre ist uns wichtig. Nur unser spezialisiertes Payment-Provider Stripe hat Zugriff auf deine Zahlungsdaten.",
      "leadingText2": "Folge einen der folgenden Links, um deinen Plan direkt bei Stripe zu verwalten. Alle Links öffnen in einem neuen Tab.",
      "currentSubscription": "Aktueller Plan",
      "upgradePlan": "Plan upgraden",
      "paymentMethod": "Zahlungsmethode",
      "downloadInvoices": "Rechnungen herunterladen",
      "cancelSubscription": "Plan kündigen"
    }
  },
  "alerts": {
    "internalError": {
      "type": "error",
      "header": "Fehler",
      "body": "Die Operation ist leider fehlgeschlagen.",
      "button": "OK"
    },
    "loginFailed": {
      "type": "error",
      "header": "Anmeldung fehlgeschlagen",
      "body": "Anmeldung war leider derzeit nicht möglich. Bitte versuche es erneut oder setze dich mit uns in Verbindung.",
      "button": "OK"
    },
    "userRegistrationSuccessful": {
      "type": "success",
      "header": "Registrierung erfolgreich",
      "body": "Dein Benutzerkonto ist fertig. Du kannst dich nun anmelden.",
      "button": "OK"
    },
    "userRegistrationSuccessfulWithPassword": {
      "type": "success",
      "header": "Anmeldung erfolgreich",
      "body": "Dein Benutzerkonto ist fertig. Aus Sicherheitsgründen bitten wir dich, sich noch einmal einzuloggen.",
      "button": "OK"
    },
    "userConfirmationNotPossible": {
      "type": "error",
      "header": "Registrierung fehlgeschlagen",
      "body": "Wir konnten die Registrierung nicht abschließen. Bitte setze dich mit uns in Verbindung an support@monestry.de.",
      "button": "OK"
    },
    "pressOkToContinue": {
      "type": "warning",
      "header": "Fehler",
      "body": "Zum Fortfahren OK klicken.",
      "button": "OK"
    },
    "formIncomplete": {
      "type": "error",
      "header": "Formular ist noch unvollständig",
      "body": "Bitte ergänze alle Pflichtfelder.",
      "button": "Schliessen"
    },
    "nothingToSave": {
      "type": "success",
      "header": "Keine Änderungen",
      "body": "Keine Änderungen sind festgestellt, also nichts muss gespeichert werden.",
      "button": "Schliessen"
    },
    "localValidationFailed": {
      "type": "error",
      "header": "Wir haben Fehler festgestellt",
      "body": "Die Fehler sind nun in dem Spreadsheet rot gekennzeichnet. Bitte korrigiere diese Fehler und speichere erneut.",
      "button": "Schliessen"
    },
    "aboutToSave": {
      "type": "warning",
      "header": "Deine Daten werden geändert",
      "body": "Deine Daten werden unwiderruflich geändert. Bitte bestätige mit OK oder kehre mit Abbrechen zurück.",
      "button": "OK",
      "button2": "Abbrechen"
    },
    "saveCompleted": {
      "type": "success",
      "header": "Daten erfolgreich aktualisiert.",
      "body": "Deine Daten werden erfolgreich aktualisiert.",
      "button": "Schliessen"
    },
    "saveFailed": {
      "type": "error",
      "header": "Speichern fehlgeschlagen",
      "body": "Wir konnten leider die Änderungen nicht abspeichern. Bitte beachte die Fehlermeldungen.",
      "button": "Schliessen"
    },
    "fileUploadSuccessful": {
      "type": "success",
      "header": "Erfolg",
      "body": "Datei erfolgreich hochgeladen.",
      "button": "Schliessen"
    },
    "fileUploadFailed": {
      "type": "error",
      "header": "Fehler",
      "body": "Wir konnten leider die Datei nicht hochladen. Versuche bitte später erneut.",
      "button": "Schliessen"
    },
    "aboutToLoseData": {
      "type": "warning",
      "header": "Änderungen verwerfen?",
      "body": "Nach Verlassen des Edit-Modus gehen alle nicht gespeicherte Änderungen verloren. Bitte bestätige mit OK oder kehre mit Abbrechen zurück.",
      "button": "OK",
      "button2": "Abbrechen"
    },
    "abandonUpload": {
      "type": "warning",
      "header": "Änderungen verwerfen?",
      "body": "Du bist dabei, diese Datei zu verwerfen. Bitte bestätige mit OK oder kehre mit Abbrechen zurück.",
      "button": "OK",
      "button2": "Abbrechen"
    },
    "youAreAboutToDeleteAccount": {
      "type": "warning",
      "header": "Deine Daten werden gelöscht",
      "body": "Du bist dabei, das Konto samt allen Daten zu löschen. Bitte bestätige mit OK oder kehre mit Abbrechen zurück.",
      "button": "OK",
      "button2": "Abbrechen"
    },
    "youAreAboutToDeleteProject": {
      "type": "warning",
      "header": "Dieses Projekt wird gelöscht",
      "body": "Du bist dabei, das Projekt samt allen Transaktionen zu löschen. Ausgeführte Transaktionen werden nicht gelöscht. Bitte bestätige mit OK oder kehre mit Abbrechen zurück.",
      "button": "OK",
      "button2": "Cancel"
    },
    "youAreAboutToDeleteTransaction": {
      "type": "warning",
      "header": "Diese Transaktion wird gelöscht",
      "body": "Du bist dabei, diese Transaktion permanent zu löschen. Bitte bestätige mit OK oder kehre mit Abbrechen zurück.",
      "button": "OK",
      "button2": "Cancel"
    },
    "youAreAboutToDeleteValuation": {
      "type": "warning",
      "header": "Diese Bewertung wird gelöscht",
      "body": "Du bist dabei, diese Bewertung permanent zu löschen. Bitte bestätige mit OK oder kehre mit Abbrechen zurück.",
      "button": "OK",
      "button2": "Cancel"
    },
    "closeAccountDetails": {
      "type": "warning",
      "header": "Änderungen verwerfen?",
      "body": "Ggf. getätigte Änderungen werden verworfen. Bitte bestätige mit OK oder kehre mit Abbrechen zurück.",
      "button": "OK",
      "button2": "Abbrechen"
    },
    "Invalid currency code": {
      "type": "error",
      "header": "Währungs-Code ungültig",
      "body": "Bitte einen gültigen Währungs-Code eingeben.",
      "button": "Schliessen"
    },
    "Transaction date must be in a date format": {
      "type": "error",
      "header": "Transaktionsdatum ungültig",
      "body": "Transkationsdatum muss im Datumsformat sein (DD-MM-JJJJ).",
      "button": "Schliessen"
    },
    "Transaction date is required": {
      "type": "error",
      "header": "Transaktionsdatum fehlt",
      "body": "Datum ist ein Pflichtfeld.",
      "button": "Schliessen"
    },
    "Transaction party must be shorter than 100 characters": {
      "type": "error",
      "header": "Transaktionspartei zu lang",
      "body": "Transkationspartei darf nicht länger als 100 Zeichen sein.",
      "button": "Schliessen"
    },
    "Transaction party is required": {
      "type": "error",
      "header": "Transaktionspartei fehlt.",
      "body": "Transaktionspartei (Sender oder Empfänger) wird für alle Transaktionen benötigt.",
      "button": "Schliessen"
    },
    "Transaction description must be shorter than 1000 characters": {
      "type": "error",
      "header": "Transaktionsbeschreibung zu lang",
      "body": "Transaktionsbeschreibung muss 1000 Zeichen nicht überschreiten.",
      "button": "Schliessen"
    },
    "Transaction description is required": {
      "type": "error",
      "header": "Transaktionsbeschreibung fehlt",
      "body": "Transaktionsbeschreibung ist ein Pflichtfeld.",
      "button": "Schliessen"
    },
    "Amount must be a number": {
      "type": "error",
      "header": "Betrag muss ein eine Zahl sein",
      "body": "Alle Transaktionsbeträge müssen Zahlen sein und dürfen keine Buchstaben enthalten.",
      "button": "Schliessen"
    },
    "Amount is required": {
      "type": "error",
      "header": "Transaktionsbetrag fehlt",
      "body": "Transaktionsbetrag ist ein Pflichtfeld.",
      "button": "Schliessen"
    },
    "Currency code is required": {
      "type": "error",
      "header": "Währungs-Code fehlt",
      "body": "Währungs-Code ist ein Pflichtfeld.",
      "button": "Schliessen"
    },
    "Foreign currency amount must be a number": {
      "type": "error",
      "header": "Fremdwährungsbetrag muss eine Zahl sein",
      "body": "Fremdwährungsbetrag muss eine Zahl sein und darf keine Buchstaben enthalten.",
      "button": "Schliessen"
    },
    "Foreign exchange currency is required when a foreign currency amount is specified": {
      "type": "error",
      "header": "Fremdwährung und Fremdwährungsbetrag stimmen nicht überein",
      "body": "Wenn ein Fremdwährungsbetrag angegeben ist, muss auch eine Fremdwährung angegeben werden (sowie umgekehrt)",
      "button": "Schliessen"
    },
    "Currency code must be exactly 3 characters long": {
      "type": "error",
      "header": "Währungs-Code hat nicht die richtige Länge",
      "body": "Währungs-Code darf genau 3 Zeichen lang sein.",
      "button": "Schliessen"
    },
    "newPasswordSuccess": {
      "type": "success",
      "header": "Erfolg",
      "body": "Passwort erfolgreich geändert. Logge dich jetzt mit deinem neuen Passwort ein.",
      "button": "Schliessen"
    },
    "youAreAboutToDeleteTemplateTransactions": {
      "type": "warning",
      "header": "Daten werden gelöscht",
      "body": "Alle Mustertransaktionen werden löschen. Fortsetzen?",
      "button": "OK",
      "button2": "Abbrechen"
    },
    "infoRedirectToProvider": {
      "type": "info",
      "header": "Bank-Login",
      "body": [
        "Wir leiten dich auf eine sichere Webseite unseres Anbieters weiter. Bitte logge dich dort bei deiner Bank ein, um die Verbindung zu genehmigen.",
        "Nachdem du fertig bist, wirst du zu Monestry zurückgeleitet.",
        "Es ist möglich, dass du dich mehrmals einloggen musst, da es verschiedene Möglichkeiten gibt, auf die Daten deiner Bank zuzugreifen."
      ],
      "button": "OK",
      "button2": "Cancel"
    },
    "infoNoNewAccounts": {
      "type": "info",
      "header": "Keine neue Konten",
      "body": "Alle deine Konten aus dieser Bank sind bereits eingerichtet. Bitte wähle eine andere Bank.",
      "button": "OK"
    },
    "expiredCredentialsError": {
      "type": "info",
      "header": "Bank-Zugang abgelaufen",
      "body": [
        "Zugang für {{accountName}} muss erneuert werden. Klicke OK, um sich erneut bei der Bank einzuloggen.",
        "Wenn du mehr als ein Konto von dieser Bank verknüpft hast, musst du dies nur einmal tun."
      ],
      "button": "OK",
      "button2": "Abbrechen"
    },
    "signUpForNewsletter": {
      "type": "info",
      "header": "Danke für dein Interesse!",
      "body": [
        "Wir schicken dir in der Regel alle 1-2 Monate eine E-Mail über Neuigkeiten rund um Monestry.",
        "Du erhälst jetzt noch eine Bestätigungsemail. Abmeldung ist jederzeit möglich; ein Link dazu gibt es in der Email."
      ],
      "button": "OK",
      "button2": "Abbrechen"
    },
    "shareReportLinkSuccess": {
      "type": "success",
      "header": "Dein Link und Passcode",
      "button": "OK"
    },
    "renewAccountAccessNoIbansFound": {
      "type": "error",
      "header": "IBANs nicht gefunden",
      "body": [
        "Deine Bank hat uns folgende Konten (IBANs) mitgeteilt: {{providerIbans}}.",
        "Du hast jedoch in Monestry lediglich folgende ausländichen Konten mit automatischen Updates eingerichtet: {{monestryIbans}}.",
        "Bitte überprüfe, ob die IBANs in Monestry korrekt sind und füge fehlende Konten hinzu."
      ],
      "button": "OK"
    },
    "providerAccountsRemoved": {
      "type": "info",
      "header": "Konten entfernt",
      "body": [
        "Wir haben keine Daten für {{list}} von deiner Bank erhalten. Diese Konten scheinen entfernt worden zu sein.",
        "Wir haben sie in manuell verwalteten Konten umgewandelt."
      ],
      "button": "OK"
    },
    "aboutToReceiveRealEstateValuation": {
      "type": "info",
      "header": "Die erste Bewertung",
      "body": [
        "Wir sind dabei, den Wert deiner Immobilie zu berechnen. Stelle sicher, dass du alle dir bekannten Details auf dem vorherigen Bildschirm eingegeben hast, da dies sich auf die Genauigkeit der Bewertung auswirkt.",
        "Drücke OK, um zu bestätigen, oder Abbrechen, um abzubrechen."
      ],
      "button": "OK",
      "button2": "Abbrechen"
    }
  },
  "messages": {
    "dataUpdatedSuccessfully": {
      "type": "success",
      "header": "Erfolg",
      "body": "Daten wurden erfolgreich aktualisiert."
    },
    "accountCreatedSuccessfully": {
      "type": "success",
      "header": "Erfolg",
      "body": "Konto erfolgreich angelegt."
    },
    "accountUpdatedSuccessfully": {
      "type": "success",
      "header": "Erfolg",
      "body": "Konto erfolgreich aktualisiert."
    },
    "profileUpdatedSuccessfully": {
      "type": "success",
      "header": "Erfolg",
      "body": "Profil erfolgreich aktualisiert."
    },
    "profileCouldNotBeUpdated": {
      "type": "error",
      "header": "Fehler",
      "body": "Profil konnte leider nicht aktualisiert werden."
    },
    "accountDeletedSuccessfully": {
      "type": "success",
      "header": "Success",
      "body": "Konto gelöscht."
    },
    "dataUpdatedWithErrors": {
      "type": "error",
      "header": "Fehler",
      "body": "Daten konnten nicht aktualisiert werden."
    },
    "accountCouldNotBeCreated": {
      "type": "error",
      "header": "Fehler",
      "body": "Konto konnte leider nicht angelegt werden."
    },
    "accountCouldNotBeUpdated": {
      "type": "error",
      "header": "Fehler",
      "body": "Konto konnte leider nicht aktualisiert werden."
    },
    "dataUpdateProblems": {
      "type": "warning",
      "header": "Warnung",
      "body": "Einige Transaktionen könnten leider nicht aktualisiert werden."
    },
    "dataUpdateError": {
      "type": "error",
      "header": "Fehler",
      "body": "Daten konnten leider nicht aktualisiert werden. Bitte überprüfe die Netzwerkverbindung."
    },
    "projectUpdateError": {
      "type": "error",
      "header": "Error",
      "body": "Projekt konnte leider nicht aktualisiert werden. Bitte überprüfe die Netzwerkverbindung."
    },
    "dataUpdateErrorContents": {
      "type": "error",
      "header": "Fehler",
      "body": "Daten konnten leider nicht aktualisiert werden. Bitte überprüfe die Werte des Formulars."
    },
    "checkYourData": {
      "type": "error",
      "header": "Fehler",
      "body": "Änderungen nicht gespeichert. Siehe Zell-Kommentaren."
    },
    "fileUploadAbortedSuccessfully": {
      "type": "success",
      "header": "Erfolg",
      "body": "Datei-Upload storniert. Datei wurde nicht hochgeladen."
    },
    "Error. Request failed with status code 502": {
      "type": "error",
      "header": "Sync fehlgeschlagen",
      "body": "Daten konnten nicht synchronisiert werden. Bitte versuche es in 30 Sekunden noch einmal."
    },
    "surveySendingError": {
      "type": "error",
      "header": "Umfrage konnte nicht geschickt werden",
      "body": "Die Daten konnten nicht übermittelt werden."
    },
    "surveySentSuccessfully": {
      "type": "success",
      "header": "Vielen Dank!",
      "body": "Die Umfrage ist erfolgreich übermittelt worden."
    },
    "transactionNotSelected": {
      "type": "error",
      "header": "Keine Transaktion ausgewählt",
      "body": "Bitte wähle eine Transaktion aus, die die Gebühren verursacht hat."
    },
    "symbolNotSelected": {
      "type": "error",
      "header": "Kein Wertpapier ausgewählt",
      "body": "Bitte wähle ein Wertpapier aus, das die Dividende gezahlt hat."
    },
    "incorrectPassword": {
      "type": "error",
      "header": "Falsches Passwort",
      "body": "Das bisherige Passwort war leider falsch."
    },
    "attemptLimitExceeded": {
      "type": "error",
      "header": "Zu viele Versuche",
      "body": "Wir haben zu viele Passwort-Versuche registriert und aus Sicherheitsgründen diese Funktion temporär gesperrt. Bitte warte 15 Minuten und versuche es dann noch einmal."
    },
    "fileTooLarge": {
      "type": "error",
      "header": "Datei zu groß",
      "body": "Wir können leider nur Dateien kleiner als 5MB verarbeiten."
    },
    "fileUploadError": {
      "type": "warning",
      "header": "Datei-Upload fehlgeschlagen",
      "body": "Einige Dateien konnten nicht hochgeladen werden."
    },
    "noDataForDateFilter": {
      "type": "error",
      "header": "Keine Daten",
      "body": "Für die gewählte Periode gebet es keine Daten. Zurück zur ursprünglich gewählten Periode."
    },
    "applyProjectTemplateSuccess": {
      "type": "success",
      "header": "Erfolg",
      "body": "Vorlage erfolgreich angewendet."
    },
    "applyProjectTemplateError": {
      "type": "error",
      "header": "Fehler",
      "body": "Vorlage konnte nicht angewendet werden."
    },
    "addTransactionManually": {
      "type": "warning",
      "header": "Warning",
      "body": "Kauftransaktion konnte nicht automatisch registriert werden. Bitte füge sie manuell hinzu.",
      "button": "OK",
      "button2": "Cancel"
    },
    "noLinkedAccount": {
      "type": "error",
      "header": "Error",
      "body": "Dein Asset-Konto hat kein verknüpftes Bankkonto. Bitte ergänze dies in der Kontoansicht."
    },
    "errorUpdatingStripe": {
      "type": "error",
      "header": "Emailwechsel unvollständig",
      "body": "Deine E-Mail wurde geändert, aber leider nicht bei unserem Zahlungsdienstleister. Bitte wende dich an unseren Support."
    },
    "existingBankConnectionDetected": {
      "type": "info",
      "header": "Bank-Login bereits vorhanden",
      "body": "Du hast dich bei deiner Bank bereits eingeloggt. Login ist somit nicht mehr erforderlich."
    },
    "newsletterSubscriptionSuccess": {
      "type": "success",
      "header": "Erfolg",
      "body": "Newsletter-Anmeldung erfolgreich."
    },
    "newsletterSubscriptionError": {
      "type": "error",
      "header": "Fehler",
      "body": "Newsletter-Anmeldung fehlgeschlagen."
    },
    "noMoreAccountsAllowed": {
      "type": "error",
      "header": "Fehler",
      "body": "Du hast die maximale Anzahl an Konten des Abos erreicht."
    },
    "inconsistentAssetTransactions": {
      "type": "warninig",
      "header": "Warnung",
      "body": "Anlage-Transaktionen für {{displayName}} in Konto {{accountName}} finden in unterschiedlichen Währungen statt."
    },
    "moreThan1ConnectedAccountFound": {
      "type": "warning",
      "header": "Warnung",
      "body": "Dieses Anlage-Konto ist mit mehr als 1 Bankkonto verbunden."
    },
    "generalError": {
      "type": "error",
      "header": "Fehler",
      "body": "Ein Fehler ist aufgetreten. Bitte versuche es später noch einmal."
    },
    "validationError": {
      "type": "error",
      "header": "Fehler",
      "body": "Daten konnten leider nicht validiert werden."
    },
    "renewAccountAccessSuccess": {
      "type": "success",
      "header": "Erfolg",
      "body": "Kontozugriff erfolgreich erneuert."
    },
    "renewAccountAccessError": {
      "type": "error",
      "header": "Fehler",
      "body": "Wir konnten den Kontozugriff nicht erneuern. Bitte kontaktiere unseren Support."
    }
  },
  "validationErrors": {
    "genericError": "Dieser Wert kann nicht gespeichert werden."
  },
  "schema": {
    "transactions": "Transaktionen",
    "accounts": "Konten",
    "projects": "Projekte",
    "reports": "Reports",
    "blog": "Blog",
    "pleaseEnterValue": "Bitte gib einen neuen Wert für dieses Attribut ein.",
    "transactionTypes": {
      "sale": "Kauf",
      "purchase": "Verkauf",
      "split": "Split"
    },
    "deposits": {
      "transaction": {
        "ownName": "Transaktion",
        "date": {
          "label": "Datum",
          "description": "Transaktionsdatum."
        },
        "otherParty": {
          "label": "Transaktionspartei",
          "description": "Sender oder Empfänger der Transaktion."
        },
        "otherPartyAccount": {
          "label": "Kontonummer",
          "description": "IBAN (Kontonr.) des Senders oder Empfängers der Transaktion."
        },
        "description": {
          "label": "Beschreibung",
          "description": "Transaktionsbeschreibung."
        },
        "amount": {
          "label": "Betrag",
          "description": "Betrag dieser Transaktion in deiner Basiswährung."
        },
        "accountCurrencyAmount": {
          "label": "Betrag",
          "description": "Betrag dieser Transaktion in der Währung dieses Kontos."
        },
        "currency": {
          "label": "Kontowährung",
          "description": "Die Währung, in der dieses Konto geführt wird."
        },
        "fxAmount": {
          "label": "Fremdwährungsbetrag",
          "description": "Optional. Wenn die Transaktion ursprünglich in einer Fremdwährung erfolgt ist, dann der Fremdwährungsbetrag gehört hierhin."
        },
        "fxCurrency": {
          "label": "Fremdwährungs-Code",
          "description": "Optional. Wenn die Transaktion ursprünglich in einer Fremdwährung erfolgt ist, dann hier gehört das 3-stellige Währungssymbol."
        },
        "simulated": {
          "label": "Simulierte Transaktion",
          "description": "Ein Zeichen in dieser Spalte bedeutet, dass dies eine fiktive (simulierte) Transaktion ist, die in der Zukunft stattfindet."
        },
        "incomeFromInterest": {
          "label": "Kapitaleinkommen",
          "description": "Ein Etikett in dieser Spalte bedeutet, dass die Transaktion zu Kapitaleinkommen gehört."
        }
      },
      "account": {
        "ownName": "Bankkonto",
        "name": "Bankname",
        "country": "Land",
        "accountName": {
          "label": "Kontoname",
          "tooltip": "Unter welchem Namen möchtest du dieses Konto im System sehen?",
          "error": "Bitte gebe eine Bezeichnung für dieses Konto ein."
        },
        "baseCurrency": {
          "label": "Basiswährung",
          "tooltip": "Welche Währung möchtest du als Basiswährung verwenden? In der Regel ist dies die Währung, in der du verdienst."
        },
        "iban": {
          "label": "IBAN",
          "tooltip": "Die IBAN-Nummer dieses Kontos. Beginnt mit einem Land-Kürzel z.B. >DE< für Deutschland.",
          "placeholder": "DE12345678901234567890"
        },
        "bic": {
          "label": "BIC",
          "tooltip": "Internationaler Bankcode (genannt >BIC<) für die Bank, bei der dieses Konto geführt wird. Bis zu 11 Zeichen lang. Dient zur Identifikation einer Bank im internationalen Kontext."
        },
        "bankName": {
          "label": "Bankname",
          "tooltip": "Name der Bank, bei der dieses Konto geführt wird.",
          "placeholder": "Sparkasse Bielefeld"
        },
        "syncType": {
          "label": "Synchronisation",
          "tooltip": "Wie sollen die Daten für dieses Konto synchronisiert werden?",
          "placeholder": "Wähle eine Option"
        },
        "depositType": {
          "label": "Kontoart",
          "tooltip": "Tagesgeld oder Girokonto?",
          "placeholder": "Wähle eine Option"
        },
        "countryCode": {
          "label": "Land",
          "tooltip": "In welchem Land befindet sich dieses Konto?",
          "placeholder": "Wähle ein Land"
        },
        "category": {
          "label": "Kategorie",
          "tooltip": "Welcher Anlagekategorie gehört dieses Konto an?",
          "placeholder": "Wähle eine Option"
        },
        "parameters": {
          "syncType": {
            "manual": "Manuell",
            "automatic": "Automatisch"
          },
          "depositType": {
            "savings-account": "Giro",
            "time-deposit": "Fest- / Tagesgeld"
          }
        }
      }
    },
    "stocks": {
      "transaction": {
        "date": {
          "label": "Datum",
          "description": "Datum der Transaktion."
        },
        "displaySymbol": {
          "label": "Wertpapiersymbol*",
          "description": "Symbol des gehandelten Wertpapiers, wie ISIN, WKN oder CUSIP. Optionalfeld."
        },
        "displayName": {
          "label": "Wertpapiername",
          "description": "Name des Aktienwertpapiers."
        },
        "transactionAmount": {
          "label": "Menge",
          "description": "Anzahl der gekauften oder verkauften Wertpapiere."
        },
        "transactionPrice": {
          "label": "Preis (Basiswährung)",
          "description": "Einheitspreis, bezahlt in Ihrer Basiswährung."
        },
        "transactionCurrency": {
          "label": "Währung",
          "description": "Währung der Transaktion."
        },
        "transactionOriginalPrice": {
          "label": "Preis (Transaktionswährung)",
          "description": "Einheitspreis, bezahlt in Transaktionswährung."
        }
      },
      "account": {
        "brokerName": {
          "label": "Brokername",
          "tooltip": "Bei welchem Broker ist dieses Konto?",
          "placeholder": "DepotX"
        },
        "isImported": {
          "label": "Dieses Konto wird erstellt.",
          "tooltip": "Entferne das Häkchen, um zu verhindern, dass dieses Konto erstellt wird."
        },
        "accountNo": {
          "label": "Depotnummer",
          "tooltip": "Welche Nummer trägt dein Depot?",
          "placeholder": "C12345678"
        },
        "username": {
          "label": "Username",
          "tooltip": "Wie lautet dein Username bei diesem Broker?",
          "placeholder": "user01"
        },
        "syncType": {
          "label": "Synchronisierung",
          "tooltip": "Wie sollen die Daten für dieses Konto synchronisiert werden?",
          "placeholder": "Wähle eine Option"
        },
        "ownCurrentAccount": {
          "label": "Verknüpftes Girokonto (IBAN)",
          "tooltip": "Welches Girokonto ist mit diesem Depot verknüpft?",
          "placeholder": "DE12345678901234567890"
        },
        "countryCode": {
          "label": "Land",
          "tooltip": "In welchem Land befindet sich dieses Konto?",
          "placeholder": "Wähle ein Land"
        },
        "connectedDepositAccounts": {
          "label": "Verlinkte Bankkonten",
          "tooltip": "Auf welche Bankkonten fließen Erlöse aus diesem Konto?"
        }
      }
    },
    "meta-comment": "the entries below are realEstate quotes, but this is only used in import of quotes, so leaving it be for now",
    "realEstate": {
      "transaction": {
        "date": {
          "label": "Datum",
          "description": "Datum der Bewertung."
        },
        "currency": {
          "label": "Währung",
          "description": "Die Währung der Bewertung."
        },
        "quote": {
          "label": "Preis",
          "description": "Bewertung in dieser Währung."
        },
        "quoteBaseCurrency": {
          "label": "Preis (Basiswährung)",
          "description": "Bewertung in Ihrer Basiswährung."
        }
      },
      "account": {
        "kindOfProperty": "Um was für eine Immobilie handelt es sich?",
        "connectedDepositAccounts": {
          "label": "Verlinkte Bankkonten",
          "tooltip": "Auf welche Bankkonten fließen Erlöse aus diesem Konto?"
        },
        "valuationParameters": {
          "type": {
            "label": "Haus oder Wohnung"
          },
          "subtype": {
            "label": "Art der Immobilie",
            "description": "Was für Haus / Wohnung ist es?",
            "tooltip": "Welche Art von Gebäude / Wohnung ist das hier?"
          },
          "streetAddress": {
            "label": "Straße und Nummer",
            "tooltip": "Wo befindet sich deine Immobilie?",
            "placeholder": "Hochstr. 12"
          },
          "zip": {
            "label": "Postleitzahl",
            "tooltip": "Welche Postleitzahl hat deine Immobilie?",
            "placeholder": "33602",
            "error": "Bitte trage eine Postleitzahl ein."
          },
          "district": {
            "label": "Ort / Stadtteil",
            "tooltip": "Für Immobilien in Stadtkreisen verwende bitte hier den Stadtteil. Für Immobilien in Landkreisen verwende bitte hier den Ort (ggf. Kernstadt) und KEINEN Stadtteil.",
            "placeholder": "Mitte"
          },
          "city": {
            "label": "Stadtkreis / Landkreis",
            "tooltip": "In welchem Stadt- oder Landkreis befindet sich deine Immobilie?",
            "placeholder": "Bielefeld",
            "error": "Bitte trage einen Stadtkreis oder Landkreis ein."
          },
          "countryCode": {
            "label": "Land",
            "tooltip": "In welchem Land befindet sich deine Immobilie?",
            "placeholder": "Deutschland",
            "error": "Bitte trage ein Land ein."
          },
          "completeArea": {
            "label": "Nutzfläche",
            "tooltip": "Wie groß ist deine gesamte Immobilie (in m²)?",
            "placeholder": "100"
          },
          "livingArea": {
            "label": "Wohnfläche",
            "tooltip": "Wie groß ist die Wohnfläche der Immobilie (in m²)?",
            "placeholder": "88",
            "error": "Bitte trage die Wohnfläche ein."
          },
          "floor": {
            "label": "Etage",
            "tooltip": "In welcher Etage liegt die Immobilie?",
            "placeholder": "3"
          },
          "totalFloors": {
            "label": "Anzahl Stockwerke",
            "tooltip": "Wie viele Stockwerke hat das Gebäude insgesamt?",
            "placeholder": "4"
          },
          "rooms": {
            "label": "Zimmer",
            "tooltip": "Wie viele Zimmer hat deine Immobilie?",
            "placeholder": "3"
          },
          "flooring": {
            "label": "Fußboden",
            "tooltip": "Welche Art von Fußboden hat deine Immobilie?"
          },
          "interiorLevel": {
            "label": "Ausstattungsniveau",
            "tooltip": "Wie hochwertig ist die Ausstattung deiner Immobilie?"
          },
          "condition": {
            "label": "Zustand",
            "tooltip": "Wie ist der aktueller Zustand deiner Immobilie zu bewerten?"
          },
          "lotArea": {
            "label": "Grundstücksfläche",
            "tooltip": "Wie groß ist das Grundstück, auf dem sich deine Immobilie befindet (in Quadratmetern)?",
            "placeholder": "450"
          },
          "constructionYear": {
            "label": "Baujahr",
            "tooltip": "Wann wurde deine Immobilie gebaut?",
            "placeholder": "2010"
          },
          "energyClass": {
            "label": "Energieverbrauch",
            "tooltip": "Jährlicher Energieverbrauch in kWh/m². Wenn dir nur die Energieeffizienzklasse vorliegt, trage den Durchschnittwert für diese Klasse ein.",
            "placeholder": 60
          },
          "heatingType": {
            "label": "Heizung",
            "tooltip": "Welche Art von Heizung hat deine Immobilie?"
          },
          "floorHeating": {
            "label": "Fußbodenheizung?",
            "tooltip": "Ist Fußbodenheizung vorhanden?"
          },
          "lastRenovationYear": {
            "label": "Letzte Renovierung",
            "tooltip": "Wann wurde deine Immobilie zuletzt renoviert?",
            "placeholder": "2020"
          },
          "privateParkingSpaces": {
            "label": "Stellplätze",
            "tooltip": "Wie viele reservierte Stellplätze gibt es für deine Immobilie?",
            "placeholder": 1
          },
          "garden": {
            "label": "Garten?",
            "tooltip": "Hat deine Immobilie einen Garten?"
          },
          "bathTub": {
            "label": "Badewanne?",
            "tooltip": "Gibt es in der Immobilie eine Badewanne?"
          },
          "bathWithWindows": {
            "label": "Tageslichtbad",
            "tooltip": "Gibt es in der Immobilie ein Bad mit Fenster?"
          },
          "fullHeightWindows": {
            "label": "Bodentiefe Fenster?",
            "tooltip": "Gibt es in der Immobilie bodentiefe Fenster?"
          }
        },
        "parameters": {
          "meta-comment": "these are also used in Real Estate API - change them there as well if changing them here",
          "type": {
            "apartment": "Wohnung",
            "building": "Gebäude"
          },
          "subtype": {
            "building": {
              "farm": "Bauernhof",
              "special-building": "Sondergebäude",
              "bungalow": "Bungalow",
              "castle": "Schloss",
              "semi-detached": "Doppelhaushälfte",
              "detached": "Einfamilienhaus",
              "apartment-building": "Mehrfamilienhaus",
              "terraced": "Reihenhaus",
              "terraced-corner": "Eckreihenhaus",
              "villa": "Villa",
              "other": "Andere"
            },
            "apartment": {
              "top-floor": "Dachgeschoss",
              "ground-floor": "Erdgeschoss",
              "other-floor": "Anderes Geschoss",
              "high-ground-floor": "Hochparterre",
              "loft": "Loft",
              "two-levels": "Maisonette",
              "penthouse": "Penthouse",
              "souterrain": "Souterrain",
              "with-balcony": "Terrassenwohnung",
              "other": "Andere"
            }
          },
          "interiorLevel": {
            "simple": "Einfach",
            "normal": "Normal",
            "high": "Gehoben",
            "luxus": "Luxus"
          },
          "condition": {
            "new": "Erstbezug",
            "newly-renovated": "Erstbezug nach Sanierung",
            "well-maintained": "Gepflegt",
            "modernized": "Modernisiert",
            "flexible": "Nach Vereinbarung",
            "like-new": "Neuwertig",
            "needs-renovation": "Renovierungsbedürftig",
            "refurbished": "Saniert",
            "completely-renovated": "Vollständig renoviert",
            "needs-repair": "Abbruchreif"
          },
          "heatingType": {
            "gas": "Gas",
            "oil": "Öl",
            "electricity": "Elektrisch",
            "district": "Fernwärme",
            "wood": "Holz",
            "coal": "Kohle",
            "solar": "Solar",
            "block": "Blockheizung",
            "stock": "Stockheizung",
            "floor": "Fußbodenheizung",
            "nachtspeicher": "Nachtspeicherheizung",
            "wärmekraft": "Wärmekraft",
            "central": "Zentral"
          },
          "flooring": {
            "carpet": "Teppich",
            "parquet": "Parkett",
            "tiles": "Fliesen",
            "laminate": "Laminat",
            "floorboards": "Dielen"
          }
        }
      }
    },
    "loans": {
      "account": {
        "parameters": {
          "type": {
            "annuity": "Feste Rate (Annuität)",
            "fixedPrincipal": "Variable Rate (fester Kapitalrückzahlungsbetrag)",
            "interestOnly": "Nur Zinsen"
          },
          "period": {
            "month": "Monatlich",
            "quarter": "Vierteljährlich",
            "year": "Jährlich",
            "annual": "Jährlich"
          },
          "direction": {
            "taken": "Empfangen",
            "granted": "Gegeben",
            "received": "Kredit aufgenommen"
          }
        },
        "type": {
          "label": "Darlehenstyp",
          "tooltip": "Um welche Art von Darlehen handelt es sich? Annuität, feste Kapitalrückzahlung usw.",
          "placeholder": "Darlehenstyp auswählen",
          "error": "Darlehenstyp ist falsch."
        },
        "direction": {
          "label": "Gegeben or empfangen?",
          "tooltip": "Hast du das Darlehen gegeben oder empfangen?",
          "error": "Darlehensart ist falsch."
        },
        "loanAmount": {
          "label": "Darlehensbetrag",
          "tooltip": "Wie viel Geld hast du geliehen? Empfangene Darlehen sind negativ, gegebene Darlehen sind positiv.",
          "placeholder": "-100000",
          "error": "Bitte den Darlehensbetrag eingeben."
        },
        "interestRate": {
          "label": "Zinssatz",
          "tooltip": "Wie hoch ist der Zinssatz deines Darlehens? Änderungen wähernd der Laufzeit sind möglich.",
          "error": "Bitte den Zinssatz eingeben."
        },
        "period": {
          "label": "Ratenfrequenz",
          "tooltip": "Wie oft machst du Rückzahlungen? Änderungen wähernd der Laufzeit sind möglich.",
          "error": "Bitte den frequenz eingeben."
        },
        "bankName": {
          "label": "Bankname",
          "tooltip": "Die IBAN (Kontonummer) des Kontos (Darlehenskontos).",
          "placeholder": "DE12345678901234567890"
        },
        "iban": {
          "label": "IBAN",
          "tooltip": "Die IBAN-Nummer dieses Kontos. Beginnt mit einem Land-Kürzel z.B. >DE< für Deutschland.",
          "placeholder": "DE12345678901234567890"
        },
        "percentRepaidAnnually": {
          "label": "Tilgung",
          "tooltip": "Wie viel des Darlehens tilgst du jährlich? Änderungen wähernd der Laufzeit sind möglich.",
          "error": "Bitte die Tilgungsrate eingeben."
        },
        "contractEndDate": {
          "label": "Enddatum des Darlehens",
          "tooltip": "Wann endet der Darlehensvertrag?",
          "error": "Bitte das Enddatum des Vertrags eingeben."
        },
        "connectedDepositAccounts": {
          "label": "Verbundenes Bankkonto/Konten",
          "tooltip": "Welche(s) Bankkonto/Konten tätigt und empfängt Zahlungen für dieses Darlehen?"
        },
        "interestCalcMonthEnd": {
          "label": "Werden Zinsen am Monatsende berechnet?",
          "tooltip": "Wenn deine Bank Zinsen am Monatsende berechnet, wähle >ja<."
        },
        "connectedProjectId": {
          "label": "Verbundenes Projekt",
          "tooltip": "Welches Projekt zeigt den simulierten Tilgungsplan für dieses Darlehen?"
        }
      }
    },
    "pension": {
      "account": {
        "parameters": {
          "useSpecialQuote": {
            "payoutOnlyContributionsToDate": "Rente (nur Beiträge bis heute)",
            "payoutOnlyContributionsToDateIndexed": "Rente (nur Beiträge bis heute, mit Wertentwicklung)",
            "payoutAllPlannedContributions": "Rente (alle zukünftigen Beiträge)",
            "payoutAllPlannedContributionsIndexed": "Rente (alle zukünftigen Beiträge, mit Wertentwicklung)"
          },
          "contributionsFrequency": {
            "0": "Einmalig",
            "1": "Monatlich",
            "3": "Vierteljährlich",
            "12": "Jährlich"
          },
          "payoutsFrequency": {
            "0": "Einmalig",
            "1": "Monatlich",
            "3": "Vierteljährlich",
            "12": "Jährlich"
          },
          "frequencyUnit": {
            "0": "unbegrenzt",
            "1": "Monate",
            "12": "Jahre"
          }
        },
        "connectedDepositAccounts": {
          "label": "Verbundene Bankkonten",
          "tooltip": "Welche Bankkonten senden und empfangen Zahlungen für dieses Rentenprodukt?"
        },
        "connectedDepositMode": {
          "error": "Bitte wähle hier eine Option aus."
        },
        "productPurchaseDate": {
          "label": "Kaufdatum",
          "tooltip": "Wann begann der Vertrag? Dieses Datum muss nicht sehr genau angegeben sein, wir verwenden es, um dir die Wertänderungen seit dem Kauf anzuzeigen.",
          "error": "Bitte gebe ein (ungefähres) Datum ein."
        },
        "initialQuotes": {
          "label": "Anfängliche Auszahlungsschätzungen",
          "tooltip": "Was waren die anfänglichen Auszahlungsschätzungen, die dir bei Vertragsabschluss gegeben wurden?",
          "error": "Anfängliche Auszahlungsschätzungen fehlen"
        },
        "contributionsSelfPaid": {
          "label": "Beiträge selbst gezahlt?",
          "tooltip": "Stelle dies auf JA, wenn du die Beiträge zahlst. Wenn es dein Arbeitgeber ist, stelle es auf NEIN.",
          "error": "Angabe erforderlich, ob Beiträge selbst gezahlt sind"
        },
        "contributionsAmounts": {
          "label": "Beitragshöhe",
          "placeholder": 100,
          "tooltip": "Gib die Beitragshöhe ein (wenn selbst gezahlt). Du kannst später unterschiedliche Beträge für verschiedene Zeiträume festlegen.",
          "error": "Bitte gebe die (ungefähre) Beitragshöhe ein."
        },
        "contributionsFrequency": {
          "label": "Beiträgesfrequenz",
          "tooltip": "Wie oft zahlst du Beiträge zu diesem Produkt?",
          "error": "Beitragsfrequenz nicht festgelegt"
        },
        "contributionsDeductible": {
          "label": "Sind Beiträge steuerlich absetzbar?",
          "tooltip": "Dürfen die Beiträge für dieses Produkt steuerlich angesetzt werden?",
          "error": "Bitte gebe die Information hier ein."
        },
        "payoutsPhaseStart": {
          "label": "Beginn der Auszahlungsphase",
          "tooltip": "Wann findet die erste Auszahlung statt? Wir brauchen hier lediglich ein ungefähres Datum, das später noch angepasst werden kann. Dieses Datum wird zur Darstellung der künftigen Auszahlungen verwendet.",
          "error": "Bitte gebe hier ein Datum ein."
        },
        "payoutsFrequency": {
          "label": "Auszahlungsfrequenz",
          "tooltip": "Handelt es sich um eine monatliche, vierteljährliche, jährliche oder einmalige Auszahlung?",
          "error": "Auszahlungsfrequenz nicht festgelegt"
        },
        "payoutsDuration": {
          "label": "Dauer der Auszahlungsphase",
          "tooltip": "Wie lange werden die Auszahlungen geleistet? Gebe die Anzahl der Monate an (12 für ein Jahr, 120 für zehn Jahre usw.). Bei lebenslangen Auszahlungen gebe '0' ein.",
          "error": "Dauer der Auszahlungen nicht festgelegt"
        },
        "payoutsTaxable": {
          "label": "Auszahlungen steuerpflichtig?",
          "tooltip": "Ist die Auszahlung steuerpflichtig?",
          "error": "Steuerpflicht der Auszahlung nicht angegeben"
        },
        "payoutsIndexed": {
          "label": "Auszahlungen mit Wertentwicklung?",
          "tooltip": "Wird der Auszahlungsbetrag über die Zeit angepasst? Wir verwenden die Inflation oder den angegebenen Produktszinssatz zur Berechnung.",
          "error": "Wachstum der Auszahlung nicht festgelegt"
        },
        "useSpecialQuote": {
          "label": "Berechnung des Produktwerts",
          "tooltip": "Welche Rentenschätzung sollen wir verwenden, um den Barwert dieses Rentenprodukts zu berechnen?",
          "error": "Rentenschätzung nicht angegeben"
        },
        "discountRate": {
          "label": "Produktszinssatz",
          "tooltip": "Zinssatz, der verwendet wird, um den Barwert dieses Produkts sowie dessen zukünftiges Wachstum zu berechnen, falls vorhanden. Lasse ihn offen, um den in den Einstellungen angegebenen Inflationszinssatz zu verwenden.",
          "error": "Produktszinssatz nicht festgelegt"
        },
        "fxRate": {
          "label": "Heutiges Wechselkurs von {{accountCurrency}} zu {{baseCurrency}}:",
          "tooltip": "Um es einfach zu halten, wir empfehlen die Verwendung von aktuellem Wechselkurs. Du kannst gerne einen anderen Wechselkurs anwenden, den für dich langfristig besser geeignet ist.",
          "apply": "Auszahlungen in {{baseCurrency}} berechnen"
        }
      },
      "transaction": {
        "date": {
          "label": "Datum",
          "description": "An welchem Datum hat das Institut diese neue Bewertung kommuniziert?"
        },
        "currency": {
          "label": "Währung",
          "description": "Die Währung der Bewertung."
        },
        "payoutOnlyContributionsToDate": {
          "label": "Rente\n(nur Beiträge bis heute)",
          "description": "Höhe der Rentenzahlungen, die du erhalten würdest, falls du heute die Beiträge einstellst."
        },
        "payoutOnlyContributionsToDateIndexed": {
          "label": "Rente\n(nur Beiträge bis heute,\nmit Wertentwicklung)",
          "description": "Höhe der Rentenzahlungen, die du erhalten würdest, falls du heute die Beiträge einstellst, mit zukünftiger Wertentwicklung bereits angelegtes Kapitals."
        },
        "payoutAllPlannedContributions": {
          "label": "Rente\n(alle zukünftigen Beiträge)",
          "description": "Höhe der Rentenzahlungen, die du erhalten würdest, falls du die Beiträge bis der Ende der Ansparphase zahlst."
        },
        "payoutAllPlannedContributionsIndexed": {
          "label": "Rente\n(alle zukünftigen Beiträge,\nmit Wertentwicklung)",
          "description": "Höhe der Rentenzahlungen, die du erhalten würdest, falls du die Beiträge bis der Ende der Ansparphase zahlst, mit zukünftiger Wertentwicklung bereits angelegtes Kapitals."
        }
      }
    },
    "objectsOfValue": {
      "account": {
        "connectedDepositAccounts": {
          "label": "Verbundene Bankkonten",
          "tooltip": "Welche Bankkonten erhalten Erlöse aus den Vermögenswerten dieses Kontos?"
        },
        "name": {
          "label": "Objektname",
          "tooltip": "Unter welchem Namen soll dieses Objekt angezeigt werden?",
          "placeholder": "Mercedes 450 SEL",
          "error": "Bitte gib den Objektnamen an."
        },
        "currency": {
          "label": "Kontowährung",
          "tooltip": "Welche Währung wird für Beiträge und Auszahlungen dieses Rentenprodukts verwendet?"
        },
        "userInputPurchaseDate": {
          "label": "Kaufdatum",
          "tooltip": "Wann hast du dieses Objekt gekauft?",
          "error": "Bitte gib das Kaufdatum an."
        },
        "userInputPurchaseValueAccountCurrency": {
          "label": "Kaufpreis in {{currency}}",
          "tooltip": "Wie viel hast du für dieses Objekt bezahlt?",
          "error": "Bitte gib den Kaufpreis an."
        },
        "userInputPurchaseValueBaseCurrency": {
          "label": "Kaufpreis in {{currency}}",
          "tooltip": "Wie viel hast du für dieses Objekt bezahlt?",
          "error": "Bitte gib den Kaufpreis an."
        },
        "fxRate": {
          "label": "Wechselkurs von {{accountCurrency}} zu {{baseCurrency}} am {{date}} war",
          "tooltip": "Zu deiner Bequemlichkeit haben wir den Wechselkurs am von dir angegebenen Tag nachgeschlagen.",
          "apply": "Kaufpreis in {{baseCurrency}} berechnen"
        },
        "quantity": {
          "label": "Gekaufte Menge",
          "tooltip": "Falls hier zutreffend, wie viele Objekte hast du zuerst gekauft? Standardwert ist 1."
        }
      }
    },
    "metals": {
      "account": {
        "connectedDepositAccounts": {
          "label": "Verbundene Bankkonten",
          "tooltip": "Welche Bankkonten erhalten Erlöse aus den Vermögenswerten dieses Kontos?"
        },
        "name": {
          "label": "Kontoname",
          "tooltip": "Unter welchem Namen soll dieses Konto angezeigt werden?",
          "placeholder": "Tresor",
          "error": "Bitte gib den Kontonamen an."
        },
        "currency": {
          "label": "Kontowährung",
          "tooltip": "Welche Währung wird für Beiträge und Auszahlungen dieses Kontos verwendet?"
        }
      },
      "transaction": {
        "parameters": {
          "assetMetal": {
            "gold": "Gold",
            "silver": "Sibver",
            "platinum": "Platin",
            "palladium": "Palladium",
            "other": "anderes"
          }
        }
      }
    },
    "unlistedShares": {
      "account": {
        "connectedDepositAccounts": {
          "label": "Verbundene Bankkonto(s)",
          "tooltip": "Welche Bankkonto(s) erhalten Erlöse aus den Vermögenswerten dieses Kontos?",
          "error": "Bitte wähle eine Option aus"
        },
        "connectedDepositMode": {
          "error": "Bitte wähle eine Option aus"
        },
        "name": {
          "label": "Kontoname",
          "tooltip": "Wie heißt die Firma?",
          "placeholder": "Meine Firma",
          "error": "Bitte gebe einen Namen an"
        },
        "currency": {
          "label": "Kontowährung",
          "tooltip": "In welcher Währung sollen die Firmenbewertung in diesem Konto gehalten werden?"
        },
        "date": {
          "label": "Unternehmensgründungsdatum",
          "tooltip": "Wann wurde das Unternehmen gegründet? Ein ungefähres Datum genügt.",
          "error": "Bitte trage ein Datum ein"
        },
        "upac": {
          "label": "Startkapital",
          "tooltip": "Wie viel Startkapital hast du in das Unternehmen investiert?",
          "placeholder": 25000,
          "error": "Bitte trage einen Wert ein"
        },
        "upbc": {
          "label": "Startkapital (Basiswährung)",
          "tooltip": "Wie viel Startkapital ist das in deiner Basiswährung?",
          "placeholder": 25000,
          "error": "Bitte trage einen Wert ein"
        },
        "quantity": {
          "label": "Anzahl der Aktien",
          "tooltip": "Wie viele Aktien deiner Firma hast du gekauft? Wenn du nicht sicher bist, gebe 1 ein.",
          "placeholder": 1,
          "error": "Bitte trage einen Wert ein"
        }
      }
    },
    "crypto": {
      "account": {
        "connectedDepositAccounts": {
          "label": "Verbundene Bankkonten",
          "tooltip": "Welche Bankkonten erhalten Erlöse aus den Vermögenswerten dieses Kontos?"
        },
        "name": {
          "label": "Kontoname",
          "tooltip": "Unter welchem Namen soll dieses Konto angezeigt werden?",
          "placeholder": "Tresor",
          "error": "Bitte gib den Kontonamen an."
        },
        "brokerName": {
          "label": "Provider",
          "tooltip": "Bei welcher Börse / welchem Broker ist dieses Konto?",
          "placeholder": "Binance"
        },
        "accountNo": {
          "label": "Konto-ID",
          "tooltip": "Welche ID (Nummer / Kennung) hat dieses Konto?",
          "placeholder": "C12345678"
        }
      }
    }
  },
  "projects": {
    "newProject": "Neues Projekt",
    "interestPayment": "Zinszahlung auf",
    "dividendPayment": "Dividendenzahlung",
    "simulated": "Simuliert",
    "purchase": "Kauf",
    "sale": "Verkauf",
    "baseCurrency": "Basiswährung für dieses Projekt",
    "progressIndicator": "Fortschrittsindikator",
    "roi": "ROI",
    "roiSliderDate": "ROI jetzt",
    "roiNextGoal": "ROI am Ziel",
    "duration": "Dauer",
    "goal": "Ziel",
    "noInvestment": "Keine Daten",
    "visible": "Außerhalb dieses Projekts sichtbar?",
    "planned": "Geplannt",
    "actual": "Realisiert",
    "yes": "ja",
    "noDraftMode": "nein",
    "delete": "Projekt löschen",
    "addGoal": "Neues Ziel",
    "addGoalDescription": "Lege ein Ziel für dieses Projekt fest.",
    "deleteGoal": "Ziel löschen",
    "name": "Überschrift",
    "transactions": "Transaktionen",
    "assets": {
      "label": "Projektmittel",
      "tooltip": "Hier erscheinen die in dem Projekt verwendete Bankkonten und Assets"
    },
    "add": "Neue Transaktion",
    "addQuote": "Neuer Zukunftspreis",
    "addQuoteDescription": "Lege den zukünftigen Wert eines Assets fest.",
    "addQuoteButtonDisabled": "Füge mindestens ein Asset zu diesem Projekt hinzu, um einen zukünftigen Wert hinzufügen können.",
    "addQuoteButtonEnabled": "Füge einen zukünftigen Wert zu diesem Projekt hinzu.",
    "inflow": {
      "labelTop": "Einzahlung",
      "labelBottom": "Geld erhalten"
    },
    "outflow": {
      "labelTop": "Auszahlung",
      "labelBottom": "Geld ausgeben"
    },
    "transfer": {
      "labelTop": "Transfer",
      "labelBottom": "zwischen Konten"
    },
    "buy": {
      "labelTop": "Kaufen",
      "labelBottom": "eine Anlage"
    },
    "sell": {
      "labelTop": "Verkaufen",
      "labelBottom": "eine Anlage"
    },
    "newAccount": {
      "label": "Neu",
      "description": "New account"
    },
    "applyTemplate": "Musterprojekt anwenden",
    "removeTemplate": "Musterprojekt entfernen",
    "addTransaction": "Transaktion hinzufügen",
    "growthRates": "Wachstumsraten",
    "growthRatesDescription": "Definiere Wachstumraten für Assets in diesem Projekt. Sie gelten nur für dieses Projekt.",
    "growthRatesNonIsolated": "Diese Wachstumraten gelten für alle Assets, auch außerhalb dieses Projektes. Um Wachstumraten nur für ein Projekt festzulegen, schalte den Isolationsmodus ein.",
    "transactionTypes": "Transaktionstypen",
    "transactionDetails": "Transaktionsdetails",
    "date": "Datum",
    "transactionDate": {
      "label": "Transaktionsdatum",
      "tooltip": "Immer auf den 1. Tag des aktuellen Monats gerundet. Passen Sie die Reihenfolge der Transaktionen innerhalb eines Monats durch Ziehen der Transaktionen in der richtigen Reihenfolge in der Projektdetails Ansicht an.",
      "error": "Datum muss ein zukünftiges Datum sein. Verwenden Sie die Kontodetails Ansicht, um eine vergangene Transaktion hinzuzufügen."
    },
    "recurringTransaction": {
      "label": "Sollte diese Transaktion regelmäßig wiederholt werden?"
    },
    "indexed": {
      "label": "Soll der Betrag jedes Jahr steigen?",
      "byInflation": "um Inflationsrate erhöhen (Link)",
      "increaseBy": "erhöhen um"
    },
    "repeating": {
      "label": "Wiederholungsmuster",
      "every": "Wiederhole alle",
      "month": "Monate",
      "year": "Jahre",
      "endBy": "Wiederholung endet:",
      "never": "nie",
      "after": "nach",
      "afterError": "Maximale Anzahl der Perioden ist 999.",
      "occurrences": "Vorkommnissen",
      "on": "am"
    },
    "synchronising": "Synchronisiere...",
    "save": "Speichern",
    "cancel": "Abbrechen",
    "deleteQuote": "Diesen Zukunftspreis löschen",
    "amount": {
      "label": "Betrag",
      "inflowsCanOnlyBePositive": "Eingänge können nur positiv sein. Verwenden Sie Auszahlungen für negative Transaktionen.",
      "outflowsCanOnlyBeNegative": "Auszahlungen können nur negativ sein. Verwenden Sie Einzahlungen für positive Transaktionen.",
      "transfersCanOnlyBePositive": "Transfers sollten immer positiv sein. Um einen Geldfluss in die entgegengesetzte Richtung zu definieren, tauschen Sie die To- und From-Konten aus."
    },
    "exchangeRate": {
      "label": "Währungskurs",
      "mustBeGreaterThanZero": "Währungskurs muss größer als 0 sein."
    },
    "switchToAmount": "Betrag eingeben",
    "switchToFxRate": "Währungskurs eingeben",
    "baseCurrencyAmount": {
      "label": "Betrag (Basiswährung)"
    },
    "currency": "Währung",
    "account": {
      "label": "Die Transaktion wird von diesem Konto abgezogen"
    },
    "accountTo": {
      "label": "Die Transaktion wird auf diesem Konto erscheinen",
      "error": "Für dieses Konto ist kein verbundenes Anlagekonto definiert."
    },
    "transactionDescription": {
      "label": "Transaktionsbeschreibung wird sein",
      "placeholder": "Gehalt",
      "required": "Bitte geben Sie eine Beschreibung ein",
      "length": "Beschreibung muss mindestens 2 Zeichen lang sein"
    },
    "assetAccount": {
      "label": "Neue Anlage erscheint auf diesem Konto",
      "error": "Verbundenes Anlagekonto für dieses Konto ist nicht definiert."
    },
    "assetContributionLabel": {
      "label": "Neuer Beitrag auf diesem Konto",
      "error": "Verbundenes Anlagekonto für dieses Konto ist nicht definiert."
    },
    "designatedDepositConnectedAccountIndex": {
      "label": "Bankkonto für diese Transaktion ",
      "error": "Fehler."
    },
    "assetName": {
      "tooltip": "Dies ist nur eine Platzhalterinformation, die für Simulationen verwendet wird.",
      "label": "Anlagenname",
      "orISIN": "oder ISIN / WKN-Code",
      "placeholder": "Tesla Aktien"
    },
    "assetSymbol": {
      "tooltip": "Wähle ein vorhandenes Wertpapier aus der Liste oder erstelle ein neues. Du kannst einen Platzhalternamen verwenden, da dieser nur für Simulationen verwendet wird.",
      "label": "Wertpapier / ISIN / WKN",
      "placeholder": "DE0000A1B2C3"
    },
    "assetMetal": {
      "label": "Metal",
      "error": "Bitte wähle das Metall aus."
    },
    "assetWeight": {
      "label": "Gewicht (g)",
      "error": "Bitte trage das Gewicht in Gramm ein."
    },
    "assetPurity": {
      "label": "Reinheit (‰)",
      "error": "Bitte trage die Reinheit in Promille (z.B. 999 oder 965) ein."
    },
    "assetAdditionalValue": {
      "label": "Zusatzwert (%)",
      "tooltip": "Zusätzlicher Wert in Prozent, der über den reinen Metallwert hinausgeht (z.B. dank der artistischer oder historischer Bedeutung). Wir erhöhen den Wert des Metalls um diesen Prozentsatz."
    },
    "assetAmount": {
      "label": "Wie viele Anlagen sollen",
      "buy": "gekauft",
      "sell": "verkauft"
    },
    "depositAmount": {
      "label": "Betrag"
    },
    "deposit": "Tagesgeld",
    "assetUnitPrice": {
      "label": "Preis pro Stück",
      "error": "Der Anlageeinheitpreis muss größer als 0 sein."
    },
    "assetContribution": {
      "label": "Beitrag",
      "error": "Beitrag muss größer als 0 sein."
    },
    "assetUnitPriceBaseCurrency": {
      "label": "In Basiswährung",
      "error": "Der Anlageeinheitpreis muss größer als 0 sein."
    },
    "quote": {
      "label": "Preis"
    },
    "quoteBaseCurrency": {
      "label": "In Basiswährung",
      "error": "Der Anlageeinheitpreis muss größer als 0 sein."
    },
    "todaysPrice": "Current price of {{displayName}} is",
    "selectAnOption": "Wähle eine Option",
    "createNewAccount": "Neues Konto erstellen",
    "errorNotAllFields": "Bitte fülle alle Felder aus.",
    "templateInitialisationError": "Fehler: diese Vorlage ist fehlerhaft.",
    "quoteAlreadyExists": "Es existiert bereits einen Zukunftspreis für dieses Asset und Preis. Sie wird durch diesen Preis ersetzt.",
    "quoteProjectWillBeChanged": "Dieser Zukunftspreis wird diesem Projekt hinzugefügt und später zusammen mit ihm gelöscht.",
    "isolatedWarning": "Die Transaktionen und Zukunftspreise sind außerhalb dieses Projekt nicht sichtbar.",
    "collisions": "Welche Zukunftspreise möchtest du behalten?",
    "collisionsDescription": "Du wechselst ein isoliertes Projekt zu einem globalen Projekt. Dieses Projekt hat Zukunftspreise, die bereits in anderen Projekten definiert sind. Du kannst entweder die Werte von diesem Projekt behalten, oder die Werte von den anderen Projekten behalten.",
    "asset": "Vermögenswert",
    "thisProjectQuote": "Dieses Projekt",
    "globalQuote": "Andere Projekte",
    "allCollisionsMustBeResolved": "Bitte wähle eine Option in jeder Zeile aus.",
    "recurring": "Wiederholende Transaktionen",
    "simulatedDividends": "Simulierte Dividenden",
    "simulatedInterest": "Simulierte Zinsen",
    "simulatedQuotes": "Zukunftspreise",
    "applyTemplateDescription": "Wende einen Satz von Transaktionen und zukünftigen Werten auf dein Projekt an. Du kannst sie dann nach Belieben bearbeiten. Wenn du damit nicht zufrieden bist, kannst du die gesamte Vorlage mit einem Klick entfernen.",
    "templates": {
      "select": "Wählen",
      "investToRent": {
        "name": "Vermietung einer Immobilie mit Darlehen",
        "description": "Kauf einer Immobilie zum Vermieten mit Hilfe eines Hypothekdarlehen. Die Mieteinnahmen werden verwendet, um das Darlehen zurückzuzahlen.",
        "role1": "Eigenanteil",
        "transaction1": "Erste erhaltene Überweisung.",
        "transaction2": "Zweite erhaltene Überweisung."
      }
    }
  },
  "invitation": {
    "title": "Kostenlose Monate erhalten",
    "subtitle.top": "Deine Empfehlung",
    "subtitle.bottom": "bedeutet uns viel!",
    "description": "Auch Ihre Freunde, Familie und Kollegen können von Monestry profitieren. Wenn sie sich für ein Monestry-Abo entscheiden, erhalten sowohl Sie als auch sie einen kostenlosen Monat des gewählten Plans.",
    "paragraphs.0.name": "Wie funktioniert es?",
    "paragraphs.0.description": "Teilen Sie die unten stehenden Informationen mit jemandem. Wenn diese Person ein Monestry-Abo erwirbt, erhalten sowohl Sie als auch sie einen zusätzlichen kostenlosen Monat des Plans.",
    "paragraphs.1.name": "Registrierungscode ist wichtig.",
    "paragraphs.1.description": "Die Person muss den Link unten oder den Registrierungscode verwenden, damit wir Ihnen ebenfalls einen kostenlosen Monat gewähren können.",
    "paragraphs.2.name": "Ihre personalisierte Einladung.",
    "paragraphs.2.description": "Klicken Sie auf den Button unten, um den Einladungstext in Ihre Zwischenablage zu kopieren. Sie können ihn dann in eine E-Mail oder Nachricht einfügen.",
    "invitationText": "Besuchen Sie diese Website!",
    "invitationText.0": "Probiere mal Monestry aus, eine vertrauenswürdige Finanzplanungs-App! Schau es dir hier an: http://www.monestry.de/features.",
    "invitationText.1": "Du kannst es 30 Tage lang kostenlos auszuprobieren, und wenn du dich für ein Abo entscheidest, erhältst du einen zusätzlichen Monat kostenlos.",
    "invitationText.2": "Verwende diesen Link zur Registrierung: https://www.monestry.de/register?registrationCode={{code}} oder gib den Code {{code}} ein.",
    "copyButton.share": "Teilen",
    "copyButton.copy": "Kopieren",
    "invitationText0": "Ich empfehle dir, Monestry auszuprobieren, eine neue Finanzplanungs-App! Schau es dir hier an: http://www.monestry.de/features.",
    "invitationText1": "Du kannst es 30 Tage lang kostenlos ausprobieren, und wenn du dich entscheidest zu abonnieren, bekommst du einen zusätzlichen Monat kostenlos dazu.",
    "invitationText2": "Benutze diesen Link, um dich anzumelden: https://www.monestry.de/register?registrationCode={{code}} oder gib den Code {{code}} bei der Anmeldung ein."
  },
  "countryNames": {
    "Afghanistan": "Afghanistan",
    "Albania": "Albanien",
    "Algeria": "Algerien",
    "Andorra": "Andorra",
    "Angola": "Angola",
    "Antigua & Barabuda": "Antigua und Barbuda",
    "Argentina": "Argentinien",
    "Armenia": "Armenien",
    "Australia": "Australien",
    "Austria": "Österreich",
    "Azerbaijan": "Aserbaidschan",
    "Bahamas": "Bahamas",
    "Bahrain": "Bahrain",
    "Bangladesh": "Bangladesch",
    "Barbados": "Barbados",
    "Belarus": "Weißrussland",
    "Belgium": "Belgien",
    "Belize": "Belize",
    "Benin": "Benin",
    "Bhutan": "Bhutan",
    "Bolivia": "Bolivien",
    "Bosnia Herzegovina": "Bosnien und Herzegowina",
    "Botswana": "Botsuana",
    "Brazil": "Brasilien",
    "Brunei": "Brunei",
    "Bulgaria": "Bulgarien",
    "Burkina": "Burkina Faso",
    "Burundi": "Burundi",
    "Cambodia": "Kambodscha",
    "Cameroon": "Kamerun",
    "Canada": "Kanada",
    "Cape Verde": "Kap Verde",
    "Central African": "Zentralafrikanische Republik",
    "Chad": "Tschad",
    "Chile": "Chile",
    "China": "China",
    "Colombia": "Kolumbien",
    "Comoros": "Komoren",
    "Congo": "Republik Kongo",
    "Democratic Rep. of Congo": "Demokratische Republik Kongo",
    "Costa Rica": "Costa Rica",
    "Croatia": "Kroatien",
    "Cuba": "Kuba",
    "Cyprus": "Zypern",
    "Czechia": "Tschechien",
    "Denmark": "Dänemark",
    "Djibouti": "Dschibuti",
    "Dominica": "Dominica",
    "Dominican Republic": "Dominikanische Republik",
    "East Timor": "Osttimor",
    "Ecuador": "Ecuador",
    "Egypt": "Ägypten",
    "El Salvador": "El Salvador",
    "Equatorial Guinea": "Äquatorialguinea",
    "Eritrea": "Eritrea",
    "Estonia": "Estland",
    "Ethiopia": "Äthiopien",
    "Fiji": "Fidschi",
    "Finland": "Finnland",
    "France": "Frankreich",
    "Gabon": "Gabun",
    "Gambia": "Gambia",
    "Georgia": "Georgien",
    "Germany": "Deutschland",
    "Ghana": "Ghana",
    "Greece": "Griechenland",
    "Grenada": "Grenada",
    "Guatemala": "Guatemala",
    "Guinea": "Guinea",
    "Guinea Bissau": "Guinea-Bissau",
    "Guyana": "Guyana",
    "Haiti": "Haiti",
    "Honduras": "Honduras",
    "Hungary": "Ungarn",
    "Iceland": "Island",
    "India": "Indien",
    "Indonesia": "Indonesien",
    "Iran": "Iran",
    "Iraq": "Irak",
    "Ireland": "Irland",
    "Israel": "Israel",
    "Italy": "Italien",
    "Ivory Coast": "Elfenbeinküste",
    "Jamaica": "Jamaika",
    "Japan": "Japan",
    "Jordan": "Jordanien",
    "Kazakhstan": "Kasachstan",
    "Kenya": "Kenia",
    "Kiribati": "Kiribati",
    "Kosovo": "Kosovo",
    "Kuwait": "Kuwait",
    "Kyrgyzstan": "Kirgisistan",
    "Laos": "Laos",
    "Latvia": "Lettland",
    "Lebanon": "Libanon",
    "Lesotho": "Lesotho",
    "Liberia": "Liberia",
    "Libya": "Libyen",
    "Liechtenstein": "Liechtenstein",
    "Lithuania": "Litauen",
    "Luxembourg": "Luxemburg",
    "Macedonia": "Nordmazedonien",
    "Madagascar": "Madagaskar",
    "Malawi": "Malawi",
    "Malaysia": "Malaysia",
    "Maldives": "Malediven",
    "Mali": "Mali",
    "Malta": "Malta",
    "Marshall Islands": "Marshallinseln",
    "Mauritania": "Mauretanien",
    "Mauritius": "Mauritius",
    "Mexico": "Mexiko",
    "Micronesia": "Mikronesien",
    "Moldova": "Moldawien",
    "Monaco": "Monaco",
    "Mongolia": "Mongolei",
    "Montenegro": "Montenegro",
    "Morocco": "Marokko",
    "Mozambique": "Mosambik",
    "Myanmar": "Myanmar",
    "Namibia": "Namibia",
    "Nauru": "Nauru",
    "Nepal": "Nepal",
    "Netherlands": "Niederlande",
    "New Zealand": "Neuseeland",
    "Nicaragua": "Nicaragua",
    "Niger": "Niger",
    "Nigeria": "Nigeria",
    "North Korea": "Nordkorea",
    "Norway": "Norwegen",
    "Oman": "Oman",
    "Pakistan": "Pakistan",
    "Palau": "Palau",
    "Panama": "Panama",
    "Papua New Guinea": "Papua-Neuguinea",
    "Paraguay": "Paraguay",
    "Peru": "Peru",
    "Philippines": "Philippinen",
    "Poland": "Polen",
    "Portugal": "Portugal",
    "Qatar": "Katar",
    "Romania": "Rumänien",
    "Russian Federation": "Russische Föderation",
    "Rwanda": "Ruanda",
    "St Kitts & Nevis": "St. Kitts und Nevis",
    "St Lucia": "St. Lucia",
    "Saint Vincent & the Grenadines": "St. Vincent und die Grenadinen",
    "Samoa": "Samoa",
    "San Marino": "San Marino",
    "Sao Tome & Principe": "São Tomé und Príncipe",
    "Saudi Arabia": "Saudi-Arabien",
    "Senegal": "Senegal",
    "Serbia": "Serbien",
    "Seychelles": "Seychellen",
    "Sierra Leone": "Sierra Leone",
    "Singapore": "Singapur",
    "Slovakia": "Slowakei",
    "Slovenia": "Slowenien",
    "Solomon Islands": "Salomonen",
    "Somalia": "Somalia",
    "South Africa": "Südafrika",
    "South Korea": "Südkorea",
    "South Sudan": "Südsudan",
    "Spain": "Spanien",
    "Sri Lanka": "Sri Lanka",
    "Sudan": "Sudan",
    "Suriname": "Suriname",
    "Swaziland": "Eswatini",
    "Sweden": "Schweden",
    "Switzerland": "Schweiz",
    "Syria": "Syrien",
    "Taiwan": "Taiwan",
    "Tajikistan": "Tadschikistan",
    "Tanzania": "Tansania",
    "Thailand": "Thailand",
    "Togo": "Togo",
    "Tonga": "Tonga",
    "Trinidad & Tobago": "Trinidad und Tobago",
    "Tunisia": "Tunesien",
    "Turkey": "Türkei",
    "Turkmenistan": "Turkmenistan",
    "Tuvalu": "Tuvalu",
    "Uganda": "Uganda",
    "Ukraine": "Ukraine",
    "United Arab Emirates": "Vereinigte Arabische Emirate",
    "United Kingdom": "Vereinigtes Königreich",
    "United States": "Vereinigte Staaten von Amerika",
    "Uruguay": "Uruguay",
    "Uzbekistan": "Usbekistan",
    "Vanuatu": "Vanuatu",
    "Vatican City": "Vatikanstadt",
    "Venezuela": "Venezuela",
    "Vietnam": "Vietnam",
    "Yemen": "Jemen",
    "Zambia": "Sambia",
    "Zimbabwe": "Simbabwe"
  },
  "rebalancing": {
    "Currency": "Währung",
    "Back to calculation": "Zurück zu Kalkulation",
    "Show required transactions": "Zu erforderlichen Transaktionen",
    "Recalculate": "Neu berechnen",
    "Required transactions": "Erforderliche Transaktionen",
    "Save": "Speichern",
    "Cancel": "Abbrechen"
  },
  "reporting": {
    "yourReports": "Deine Reports",
    "selectReport": "Wähle einen Report",
    "myReport": "Mein Report",
    "save": "Speichern",
    "cancel": "Abbrechen",
    "delete": "Löschen",
    "new": "Neu",
    "edit": "Anpassen",
    "transactions": "Transaktionen",
    "duplicate": "Duplizieren",
    "balances": "Salden",
    "history": "Historie",
    "howToUseReports": "Wie nutze ich Reports?",
    "switchToProjects": "Zur Projekt-Ansicht wechseln",
    "switchToDashboard": "Zur Dashboard-Ansicht wechseln",
    "tooltips": {
      "new": "Erstelle einen neuen Report",
      "edit": "Bearbeite den ausgewählten Report",
      "delete": "Lösche den ausgewählten Report",
      "duplicate": "Dupliziere den ausgewählten Report"
    }
  }
}