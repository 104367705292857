/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { setMessage } from '../actions/message';
import ReportsService from '../services/reports.service';
import * as reports from '../../sections/reports/prebuildReportDefs';
import {
  SET_APP_SIGNAL,
} from '../actions/types';

export const getReports = createAsyncThunk('reports/getReports', async (_, { dispatch, getState }) => {
  try {
    const response = await ReportsService.getData();
    return response;
  } catch (error) {
    console.log('Redux > reports > getReports error:', error);
    dispatch(setMessage('dataUpdateError'));
  }
  return null;
});

export const postReport = createAsyncThunk('reports/postReport', async (report, { dispatch, getState }) => {
  try {
    const response = await ReportsService.postData(report);

    dispatch({
      type: SET_APP_SIGNAL,
      payload: { callerId: report.id, message: 'reportPostPutFinished' },
    });
    return response;
  } catch (error) {
    console.log('Redux > reports > postReport error:', error);
    dispatch(setMessage('dataUpdateError'));
  }
  return null;
});

export const putReport = postReport;

export const deleteReport = createAsyncThunk('reports/deleteReport', async (reportId, { dispatch, getState }) => {
  try {
    const response = await ReportsService.deleteData({ id: reportId });
    return { id: reportId, ...response }; // Assuming deleteData returns something useful; adjust as needed
  } catch (error) {
    console.log('Redux > reports > deleteReport error:', error);
    dispatch(setMessage('dataUpdateError'));
  }
  return null;
});

export const updateReport = createAsyncThunk('reports/updateReport', async (report) => {
  const response = await ReportsService.putData(report);
  return response;
});

const initialState = reports.default;

const reportsSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getReports.fulfilled, (state, action) => {
        // this is apparently how array operations are done on Immer / RTK
        // Efficiently create a set of ids from the payload for faster lookups
        const payloadIds = new Set(action.payload.map((r) => r.id));

        // Filter the state directly by mutating it
        const filteredState = state.filter((report) => !payloadIds.has(report.id));

        // Use state.splice to clear the array and push new items
        state.splice(0, state.length, ...filteredState, ...action.payload);
      })
      // this was already fired on PENDING before, is that a good idea?
      .addCase(postReport.fulfilled, (state, action) => {
        state.push(action.payload);
      })
      .addCase(deleteReport.fulfilled, (state, action) => {
        state = state.filter((report) => report.id !== action.payload.id);
      })
      .addCase(updateReport.fulfilled, (state, action) => {
        const index = state.findIndex((report) => report.id === action.payload.id);
        if (index !== -1) {
          state[index] = action.payload;
        }
      });
  },
});

export default reportsSlice.reducer;
