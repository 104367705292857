/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { globalAccountsView, postData, allTransactions } from '../../redux/reducers/data';
import { setMessage } from '../../redux/actions/message';
import AccountLabel from '../../elements/AccountLabel';
import SublistOkCancel from './SublistOkCancel';

dayjs.extend(utc);

export default function BlueTransactionsTransfer({ selectedOption, accountId, linkedAttribute, handleCloseAfterSale, handleCancel, transaction }) {
  const { t } = useTranslation('app', { keyPrefix: 'accountDetails.deposits' });
  const [loading, setLoading] = useState(false);
  const [localLinkedAttribute, setLocalLinkedAttribute] = useState(linkedAttribute); // intialise to the object from props (should there be an existing linkedAttribute already)
  const dispatch = useDispatch();
  const allAccounts = useSelector(globalAccountsView) || [];
  const baseCurrency = useSelector((state) => state.user.profile.settings.baseCurrency);

  function getAccountById(a) {
    return allAccounts.find((account) => account.id === a);
  }

  // PREPARE DATA TO BE DISPLAYED

  // prepare list of accounts to look for matching transfer transactions in (connected asset accounts + other deposit accounts)
  const connectedAndDepositAccountIds = allAccounts
    .filter((account) => account.connectedDepositAccounts?.includes(accountId)) // get asset accounts which use this deposit account as connectedDepositAccount
    .concat(allAccounts.filter((account) => account.category === 'deposits')) // add all deposit accounts
    .filter((a) => a.id !== accountId) // filter out the currently open account
    .map((account) => account.id);

  // display a list of all transactions from those connected asset accounts
  // so this can be a list of transactions from more than 1 account
  console.log(
    'DEBUG',
    connectedAndDepositAccountIds,
    transaction,
    transaction.quantity,
    useSelector(allTransactions).filter(
      (txn) => connectedAndDepositAccountIds?.includes(txn.accountId) && txn.quantity && Math.abs(txn.quantity + (txn.quantityInterest || 0)) > Math.abs(transaction.quantity) * 0.9,
    ),
    //  && Math.abs(txn.quantity + (txn.quantityInterest || 0)) < Math.abs(transaction.quantity) * 1.1
  );
  //
  //  && dayjs.utc(txn.date).isAfter(dayjs.utc(transaction.date).subtract(7, 'day'))
  //  && dayjs.utc(txn.date).isBefore(dayjs.utc(transaction.date).day(7, 'day')));

  const connectedAndDepositAccountsTransactions = useSelector(allTransactions).filter(
    (txn) => connectedAndDepositAccountIds?.includes(txn.accountId)
      && txn.quantity // to eliminate splits
      && Math.abs(txn.quantity) > Math.abs(transaction.quantity) * 0.9 // only show transactions with a quantity of at least 90% of this one and the opposite sign
      && Math.abs(txn.quantity) < Math.abs(transaction.quantity) * 1.1 // only show transactions with a quantity of at most 110% of this one and the opposite sign
      && dayjs.utc(txn.date).isAfter(dayjs.utc(transaction.date).subtract(7, 'day')) // only show transactions on connected account within +/- 7 days of this one (for clarity)
      && dayjs.utc(txn.date).isBefore(dayjs.utc(transaction.date).day(7, 'day')),
  );

  // update localLinkedAttribute with the new linkedTransaction which user selected
  function handleClick(txn) {
    setLocalLinkedAttribute({
      ...localLinkedAttribute,
      linkedTransaction: {
        id: txn.id,
        category: txn.category,
        tags: {
          linkType: 'transfer',
        },
      },
    });
  }

  // SAVE DATA

  async function handleSave() {
    // update tranaction label to 'transfer'
    const updatedTransaction = {
      ...transaction,
      label: selectedOption.id,
      importFlag: 'put',
    };

    // if the transaction is not marked, do not save it
    if (!localLinkedAttribute?.linkedTransaction) {
      dispatch(setMessage('transactionNotSelected'));
      return;
    }

    // add the selected transaction as linkedTransaction (remove any previously added linkedTransactions of type 'transfer')
    updatedTransaction.linkedTransactions = updatedTransaction.linkedTransactions.filter((tx) => tx.tags.linkType !== 'transfer').concat(localLinkedAttribute.linkedTransaction);

    setLoading(true);
    try {
      // update "this" deposit transaction
      const promisePostThisTransaction = dispatch(
        postData({
          data: [updatedTransaction],
          category: 'deposits',
          accountId,
        }),
      );

      // update the "other" transaction that the user has selected
      const otherTransaction = connectedAndDepositAccountsTransactions.find((txn) => txn.id === localLinkedAttribute.linkedTransaction.id);
      const promisePostOtherTransaction = dispatch(
        postData({
          data: [
            {
              ...otherTransaction,
              label: 'investment-transfer',
              linkedTransactions: otherTransaction.linkedTransactions
                .filter((tx) => tx.tags.linkType !== 'transfer')
                .concat({
                  id: transaction.id,
                  category: transaction.category,
                  tags: {
                    linkType: 'transfer',
                  },
                }),
            },
          ],
          category: otherTransaction.category,
          accountId: otherTransaction.accountId,
        }),
      );

      await Promise.all([promisePostThisTransaction, promisePostOtherTransaction]);

      setLoading(false);
      handleCloseAfterSale();
    } catch (err) {
      setLoading(false);
      dispatch(setMessage('dataUpdateError'));
    }
  }

  return (
    <>
      <div data-testid="blue-transactions-transfer" className="flex flex-col gap-2 overflow-y-auto">
        {connectedAndDepositAccountsTransactions
          .filter((txn) => (transaction.quantity >= 0 ? txn.quantity < 0 : txn.quantity >= 0)) // reversed sign transactions only
          .sort((a, b) => new Date(a.date) - new Date(b.date))
          .map((txn) => (
            <button
              type="button"
              key={txn.id}
              onClick={() => handleClick(txn)}
              data-testid="sublist-transaction"
              className={`text-xs border border-gray-200 shadow-sm rounded-md p-2 flex justify-between gap-2 cursor-pointer
                        ${
                          txn?.id === localLinkedAttribute?.linkedTransaction?.id ? 'bg-brandBlue-500 text-white hover:bg-brandBlue-600 border-transparent' : 'bg-white text-gray-900 hover:bg-gray-50'
                        }`}
            >
              <div>
                {new Date(txn.date).toLocaleDateString('de', {
                  year: '2-digit',
                  month: '2-digit',
                  day: '2-digit',
                  timeZone: 'UTC',
                })}
              </div>
              <div>{txn.displayName}</div>
              <div>
                {(txn.quantity * txn.upbc).toLocaleString('de', {
                  style: 'currency',
                  currency: baseCurrency,
                  maximumFractionDigits: 0,
                  minimumFractionDigits: 0, // https://stackoverflow.com/a/76342011
                })}
              </div>
              <AccountLabel accountName={getAccountById(txn.accountId)?.name} category={getAccountById(txn.accountId)?.category} />
            </button>
          ))}
        {connectedAndDepositAccountsTransactions.length === 0 && <div className="text-sm text-gray-500 self-center mt-2">{t('noTransactionsWithin')}</div>}
      </div>
      <SublistOkCancel handleSave={handleSave} handleCancel={handleCancel} loading={loading} />
    </>
  );
}
BlueTransactionsTransfer.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  selectedOption: PropTypes.objectOf(PropTypes.any).isRequired,
  accountId: PropTypes.string.isRequired,
  linkedAttribute: PropTypes.object,
  handleCloseAfterSale: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  transaction: PropTypes.object.isRequired,
};
BlueTransactionsTransfer.defaultProps = {
  linkedAttribute: {},
};
