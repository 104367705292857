/* eslint-disable no-promise-executor-return */
import { API, Auth } from 'aws-amplify';

async function callApi(method, payload, resource) {
  // get session details from Amplify.Auth
  const session = await Auth.currentSession();
  const myInit = {
    body: payload, // actual contents go here
    headers: {
      'Content-Type': 'application/json',
      Authorization: session.idToken.jwtToken,
    },
  };

  let response;
  try {
    if (method === 'get') {
      response = await API.get('myAPI', `stocks/${resource}`, myInit);
    }
    if (method === 'post') {
      response = await API.post('myAPI', `stocks/${resource}`, myInit);
    }
    if (method === 'put') {
      response = await API.put('myAPI', `stocks/${resource}`, myInit);
    }
    if (method === 'delete') {
      response = await API.del('myAPI', `stocks/${resource}`, myInit);
    }
  } catch (error) {
    console.log('Error while accessing API', error);
    throw new Error(error);
  }

  return response;
}

async function getSettings() {
  try {
    const data = await callApi('get', null, 'settings');
    return data;
  } catch (e) {
    console.error('Error while accessing stocks > getSettings API.');
    console.error(`Lambda error message: ${e.response.data}, statusCode: ${e.response.status}, statusText: ${e.response.statusText}`);
    throw new Error(e.response.data); // error message from the backend lambda
  }
}

async function getData() {
  try {
    const data = await callApi('get', null, 'data');
    return { ...data, status: 'noerrors' };
  } catch (e) {
    console.error('Error while accessing stocks > getData API.');
    console.error(`Lambda error message: ${e.response.data}, statusCode: ${e.response.status}, statusText: ${e.response.statusText}`);
    throw new Error(e.response.data); // error message from the backend lambda
  }
}

async function getDataByAccount(accountId) {
  try {
    const data = await callApi('get', null, `data/accounts/${accountId}`);
    return { ...data, status: 'noerrors' };
  } catch (e) {
    console.error('Error while accessing stocks > getData API.');
    console.error(`Lambda error message: ${e.response.data}, statusCode: ${e.response.status}, statusText: ${e.response.statusText}`);
    throw new Error(e.response.data); // error message from the backend lambda
  }
}

const clearData = () => 0;

async function postData(payload) {
  // expected payload: [{transaction1}, {transaction2}, ...]
  // transactions must be complete (all fields provided)
  try {
    const result = await callApi('post', payload, 'transactions');
    return result; // returns validated transactions
  } catch (e) {
    console.error('Error while accessing stocks > postData API.');
    console.error(`Lambda error message: ${e.response.data}, statusCode: ${e.response.status}, statusText: ${e.response.statusText}`);
    throw new Error(e.response.data); // error message from the backend lambda
  }
}

async function postAccount(payload) {
  try {
    const response = await callApi('post', payload, 'accounts');
    return response;
  } catch (e) {
    console.error('Error while accessing stocks > postAccount API.');
    console.error(`Lambda error message: ${e.response.data}, statusCode: ${e.response.status}, statusText: ${e.response.statusText}`);
    throw new Error(e.response.data); // error message from the backend lambda
  }
}

async function putAccount(payload) {
  try {
    const response = await callApi('put', payload, 'accounts');
    return response;
  } catch (e) {
    console.error('Error while accessing stocks > putAccount API.');
    console.error(`Lambda error message: ${e.response.data}, statusCode: ${e.response.status}, statusText: ${e.response.statusText}`);
    throw new Error(e.response.data); // error message from the backend lambda
  }
}

async function deleteAccount(payload) {
  try {
    const response = await callApi('delete', payload, 'accounts');
    return response;
  } catch (e) {
    console.error('Error while accessing stocks > deleteAccount API.');
    console.error(`Lambda error message: ${e.response.data}, statusCode: ${e.response.status}, statusText: ${e.response.statusText}`);
    throw new Error(e.response.data); // error message from the backend lambda
  }
}

async function postSettings(payload) {
  try {
    const response = await callApi('post', payload, 'settings');
    return response;
  } catch (e) {
    console.error('Error while accessing stocks > postSettings API.');
    console.error(`Lambda error message: ${e.response.data}, statusCode: ${e.response.status}, statusText: ${e.response.statusText}`);
    throw new Error(e.response.data); // error message from the backend lambda
  }
}

async function putSettings(payload) {
  try {
    const response = await callApi('put', payload, 'settings');
    return response;
  } catch (e) {
    console.error('Error while accessing stocks > putSettings API.');
    console.error(`Lambda error message: ${e.response.data}, statusCode: ${e.response.status}, statusText: ${e.response.statusText}`);
    throw new Error(e.response.data); // error message from the backend lambda
  }
}

async function deleteSettings(payload) {
  try {
    const response = await callApi('delete', payload, 'settings');
    return response;
  } catch (e) {
    console.error('Error while accessing stocks > deleteSettings API.');
    console.error(`Lambda error message: ${e.response.data}, statusCode: ${e.response.status}, statusText: ${e.response.statusText}`);
    throw new Error(e.response.data); // error message from the backend lambda
  }
}

// common function which polls for status and then is supposed to call the correct service and return data
async function pollForData(batchIds) {
  // simulated polling request with getStatusPerBatchId
  await new Promise((r) => setTimeout(r, 1500));
  // expected response:
  const status = batchIds.map((batchId) => ({
    batchId,
    status: 'noerrors',
  }));

  // LOGIC BASED ON STATUS:
  // if status noerrors --> do getCleanData(batchIds)
  await new Promise((r) => setTimeout(r, 1500));
  // expected response: portion of data for the batchIds
  // const response = data.transactions.filter((t) => batchIds.includes(t.batchId));

  // if status awaitingMapping --> do getMappingDataPerBatchId(batchIds) // doesn't apply for grid

  // if status errors --> do getErrorDataPerBatchId(batchIds)

  // if (Math.random() > 1) return { ...data, status: 'errors' };
  // return { ...data, status: 'noerrors' };
}

// wrapper to be called from actions
async function postMappingData(payload, batchId) {
  // simulated POST request
  await new Promise((r) => setTimeout(r, 500));

  // simulated polling request
  const response = await pollForData([batchId]);
  return response;
}

// this is doing two things:
// - post data
// - get the data containing the freshly posted data from Category Service
async function postFile(payload) {
  // simulated POST request
  await new Promise((r) => setTimeout(r, 500));

  // TODO here be the file column reconciliation bit

  // simulated polling request
  await new Promise((r) => setTimeout(r, 1500));
  // if (Math.random() > 1) return { ...data, status: 'errors' };
  // return { ...data, status: 'noerrors' };
  // throw new Error('Simulated error');
}

export default {
  getData,
  getDataByAccount,
  clearData,
  postData,
  postFile,
  postMappingData,
  postAccount,
  putAccount,
  deleteAccount,
  postSettings,
  putSettings,
  deleteSettings,
  getSettings,
};
