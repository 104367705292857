/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  GlobeAltIcon, ScaleIcon, PresentationChartLineIcon, BuildingOfficeIcon, HomeIcon,
} from '@heroicons/react/24/outline';

export default function FeaturesSection1() {
  const { t } = useTranslation(['site']);

  const features = [
    {
      name: `${t('site:features.section2.valuation.stocks.header')}`,
      description:
        `${t('site:features.section2.valuation.stocks.body')}`,
      icon: PresentationChartLineIcon,
    },
    {
      name: `${t('site:features.section2.valuation.fx.header')}`,
      description:
        `${t('site:features.section2.valuation.fx.body')}`,
      icon: GlobeAltIcon,
    },
    {
      name: `${t('site:features.section2.valuation.unlisted.header')}`,
      description:
        `${t('site:features.section2.valuation.unlisted.body')}`,
      icon: BuildingOfficeIcon,
    },
    {
      name: `${t('site:features.section2.valuation.real-estate.header')}`,
      description:
        `${t('site:features.section2.valuation.real-estate.body')}`,
      icon: HomeIcon,
    },
    {
      name: `${t('site:features.section2.valuation.metals.header')}`,
      description:
        `${t('site:features.section2.valuation.metals.body')}`,
      icon: ScaleIcon,
    },
  ];

  return (
    <div id="features-automatic-updates">
      <div className="py-12 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="lg:text-center">
            <h2 className="text-base text-brandBlue-500 font-semibold tracking-wide uppercase">{t('site:features.section2.lead')}</h2>
            <p className="mt-2 text-3xl leading-8 font-black tracking-tight text-gray-900 sm:text-4xl">
              {t('site:features.section2.header')}
            </p>
            <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
              {t('site:features.section2.body')}
            </p>
          </div>

          <div className="mt-10">
            <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
              {features.map((feature) => (
                <div key={feature.name} className="relative">
                  <dt>
                    <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-brandBlue-500 text-white">
                      <feature.icon className="h-6 w-6" aria-hidden="true" />
                    </div>
                    <p className="ml-16 text-xl leading-6 font-bold text-gray-900">{feature.name}</p>
                  </dt>
                  <dd className="mt-2 ml-16 text-base text-gray-500">{feature.description}</dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </div>
    </div>
  );
}
