import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  XMarkIcon,
} from '@heroicons/react/24/solid';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

function addMonths(date, months) {
  const d = date.getDate();
  date.setMonth(date.getMonth() + +months);
  if (date.getDate() !== d) {
    date.setDate(0);
  }
  return date;
}

function addDays(date, days) {
  date.setDate(date.getDate() + days);
  return date;
}

function generateDays(month, year) {
  // reminder: months are 0 - 11
  const firstDay = new Date(Date.UTC(year, month, 1));
  const lastDay = addDays(addMonths(new Date(Date.UTC(year, month, 1)), 1), -1);
  firstDay.setUTCHours(0);
  lastDay.setUTCHours(0);
  // reminder: Sunday is 0
  const daysToAddBefore = firstDay.getDay() === 0 ? 6 : firstDay.getDay() - 1;
  const daysToAddAfter = lastDay.getDay() === 0 ? 0 : 7 - lastDay.getDay();
  addDays(firstDay, -1 * daysToAddBefore);
  addDays(lastDay, daysToAddAfter);
  const days = [];
  for (
    let j = firstDay.valueOf();
    j <= lastDay.valueOf();
    j += 1000 * 60 * 60 * 24
  ) {
    days.push({
      date: new Date(j).toISOString().split('T')[0],
      isCurrentMonth: (new Date(j).getMonth() === month),
      isToday: (new Date(j).toISOString().split('T')[0] === new Date().toISOString().split('T')[0]),
    });
  }

  return days;
}

/* This example requires Tailwind CSS v2.0+ */
export default function Calendar({ calendarState, setCalendarState }) {
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [days, setDays] = useState(undefined);
  useEffect(() => {
    setDays(generateDays(new Date(currentMonth).getMonth(), new Date(currentMonth).getFullYear()));
  }, [currentMonth]);

  function handleSelect(dayIdx) {
    // remove the selected status from all days "wholesale" and enable it on the selected day
    // update the parent state
    // eslint-disable-next-line no-restricted-syntax
    const newDays = days.map((item) => ({ ...item, isSelected: false }));
    newDays[dayIdx].isSelected = true;
    setDays(newDays);
    setCalendarState({ ...calendarState, selectedDate: newDays[dayIdx].date });
  }

  function handleSelectButton(e) {
    // callback is the dispatch baseline change function from Dashboard
    calendarState.callback(new Date(calendarState.selectedDate));
    setCalendarState({ ...calendarState, visible: false });
  }

  return (
    <div className="absolute w-full h-full z-[60] top-0 left-0 p-0 xs:p-16 bg-gray-200 bg-opacity-90 flex justify-center items-start">
      <div className="bg-white overflow-hidden shadow p-8  sm:rounded-lg">
        <div className="flex justify-between">
          <div>
            <h2 className="text-xl font-semibold text-gray-900">Select a day</h2>
          </div>
          <button
            type="button"
            onClick={(e) => setCalendarState({ selectedDate: null, visible: false, callback: null })}
          >
            <XMarkIcon className="w-6 h-6 text-gray-400" />
          </button>
        </div>
        <div className="mt-10 text-center lg:mt-9">
          <div className="flex flex-wrap justify-center sm:flex-nowrap sm:justify-around">
            <div className="flex items-center text-gray-900">
              <button
                type="button"
                className="border rounded-sm flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
                onClick={(e) => setCurrentMonth(addMonths(new Date(currentMonth), -1))}
              >
                <span className="sr-only">Previous month</span>
                <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
              </button>
              <div className="flex-auto font-semibold text-lg px-4">{currentMonth.toLocaleString('default', { month: 'long' })}</div>
              <button
                type="button"
                className="border rounded-sm flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
                onClick={(e) => setCurrentMonth(addMonths(new Date(currentMonth), 1))}
              >
                <span className="sr-only">Next month</span>
                <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
            <div className="flex items-center text-gray-900">
              <button
                type="button"
                className="border rounded-sm flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
                onClick={(e) => setCurrentMonth(addMonths(new Date(currentMonth), -12))}
              >
                <span className="sr-only">Previous year</span>
                <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
              </button>
              <div className="flex-auto font-semibold text-lg px-4">{currentMonth.toISOString().split('-')[0]}</div>
              <button
                type="button"
                className="border rounded-sm flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
                onClick={(e) => setCurrentMonth(addMonths(new Date(currentMonth), 12))}
              >
                <span className="sr-only">Next year</span>
                <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
          </div>
          <div className="mt-6 grid grid-cols-7 text-xs leading-6 text-gray-500">
            <div>M</div>
            <div>T</div>
            <div>W</div>
            <div>T</div>
            <div>F</div>
            <div>S</div>
            <div>S</div>
          </div>
          <div className="isolate mt-2 grid grid-cols-7 gap-px rounded-lg bg-gray-200 text-sm shadow ring-1 ring-gray-200">
            {days && days.map((day, dayIdx) => (
              <button
                key={day.dayIdx}
                type="button"
                onClick={() => handleSelect(dayIdx)}
                className={classNames(
                  'py-1.5 hover:bg-gray-100 focus:z-10',
                  day.isCurrentMonth ? 'bg-white' : 'bg-gray-50',
                  (day.isSelected || day.isToday) && 'font-semibold',
                  day.isSelected && 'text-white',
                  !day.isSelected && day.isCurrentMonth && !day.isToday && 'text-gray-900',
                  !day.isSelected && !day.isCurrentMonth && !day.isToday && 'text-gray-400',
                  day.isToday && !day.isSelected && 'text-brandBlue-500',
                  dayIdx === 0 && 'rounded-tl-lg',
                  dayIdx === 6 && 'rounded-tr-lg',
                  dayIdx === days.length - 7 && 'rounded-bl-lg',
                  dayIdx === days.length - 1 && 'rounded-br-lg',
                )}
              >
                <time
                  dateTime={day.date}
                  className={classNames(
                    'mx-auto flex h-7 w-7 items-center justify-center rounded-full',
                    day.isSelected && day.isToday && 'bg-brandBlue-500',
                    day.isSelected && !day.isToday && 'bg-gray-900',
                  )}
                >
                  {day.date.split('-').pop().replace(/^0/, '')}
                </time>
              </button>
            ))}
          </div>
          <button
            type="button"
            className="focus:outline-none mt-8 w-full rounded-md border border-transparent bg-brandBlue-500 py-2 px-4 text-base text-white shadow hover:bg-brandBlue-600 focus:ring-2 focus:ring-brandBlue-400 focus:ring-offset-2"
            onClick={handleSelectButton}
          >
            Select
          </button>
          <button
            type="button"
            onClick={(e) => setCalendarState({ selectedDate: null, visible: false, callback: null })}
            className="focus:outline-none mt-4 w-full rounded-md border border-gray-400 bg-white py-2 px-4 text-base text-gray-400 shadow hover:bg-gray-100 focus:ring-2 focus:ring-brandBlue-400 focus:ring-offset-2"
          >
            Cancel
          </button>
        </div>
      </div>
      {/* </div> */}
    </div>
  );
}

Calendar.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  calendarState: PropTypes.object,
  setCalendarState: PropTypes.func,
};
Calendar.defaultProps = {
  calendarState: {},
  setCalendarState: {},
};
