import React, { useEffect } from 'react';
import FeaturesSection1 from '../elements/FeaturesSection1';
import FeaturesSection2 from '../elements/FeaturesSection2';
import FeaturesSection3 from '../elements/FeaturesSection3';
import FeaturesSection4 from '../elements/FeaturesSection4';

export default function Features() {
  // implements "scroll to anchor" functionality
  useEffect(() => {
    const { hash } = window.location;
    if (hash) {
      const element = document.getElementById(hash.slice(1));
      if (element) {
        element.scrollIntoView();
      }
    }
  }, []);

  return (
    <div data-testid="features-topmost">
      <FeaturesSection1 />
      <FeaturesSection2 />
      <FeaturesSection3 />
      <FeaturesSection4 />
    </div>
  );
}
