/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-filename-extension */
import { stockTransaction, stockTransactions } from '@monestry-dev/schema';

export const schemaArray = stockTransactions;
export const schemaTransaction = stockTransaction;

// ACCOUNTDETAILS ----------------------------------------------------------------------------------------------------

export const colWidths = {
  date: 100, otherParty: 150, description: 450, amount: 100, // FIXME: stocks (cols)
};

// these are initialisation values for the fields with changeable contents
export const attrs = {
  name: 'accountName',
  // bankName: 'bankName',
  // iban: 'IBAN',
  // bic: 'BIC',
};
