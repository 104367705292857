/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useMemo, useEffect, useRef } from 'react';
import AbortController from 'abort-controller';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { Auth, API } from 'aws-amplify';
import { InformationCircleIcon } from '@heroicons/react/24/solid';
import { ChartBarSquareIcon } from '@heroicons/react/24/outline';
import Dropdown from '../../elements/Dropdown';
import { currencyCodes } from '../../misc/currencyCodes';
import { convertCurrency } from '../../misc/ecbCurrencyRates';
import AssetNameDropdown from './AssetNameDropdown';
import AssetNamePicklist from './AssetNamePicklist';
import MiniSpinner from '../../misc/MiniSpinner';
import NewAccountButton from './NewAccountButton';
import { stocksMetadata, metalsAssets } from '../../redux/reducers/data';

const utc = require('dayjs/plugin/utc');

dayjs.extend(utc);

export default function BuySell({ accountList, control, register, mode, errors, watch, unregister, setValue, stocksAssets }) {
  const account = watch('assetAccount');
  const accountCategory = account?.category;
  const connectedDepositAccountsList = account?.connectedDepositAccounts?.map((a) => accountList?.find((b) => b.id === a)); // populate connectedDepositAccountsList with the actual account objects

  const { t } = useTranslation('app', { keyPrefix: 'projects' });
  const { t: tS } = useTranslation('app', { keyPrefix: 'schema' });

  const selectBaseCurrency = useSelector((state) => state.user.profile.settings.baseCurrency) || 'EUR';
  const baseCurrency = useMemo(() => selectBaseCurrency, [selectBaseCurrency]);
  const selectStocksMetadata = useSelector(stocksMetadata);

  const selectMetalAssets = useSelector(metalsAssets);

  const [showFxRate, setShowFxRate] = useState(true);
  const [fxRateLoading, setFxRateLoading] = useState(false);
  const [abortController, setAbortController] = useState(null);
  const [currentPriceInfo, setCurrentPriceInfo] = useState(null);
  const [chartLink, setChartLink] = useState(null);

  const exchangeRate = watch('exchangeRate');
  const assetUnitPriceBaseCurrency = watch('assetUnitPriceBaseCurrency');
  const assetAmount = watch('assetAmount');
  const transactionCurrency = watch('transactionCurrency');
  const displaySymbol = watch('displaySymbol');

  // initialise fxRate -- if this is an existing transaction, it will have assetUnitPriceBaseCurrency, but no exchangeRate (set fxRate to false)
  useEffect(() => {
    if (!exchangeRate && !!assetUnitPriceBaseCurrency) setShowFxRate(false);
  }, []);

  // unregister designatedConnectedDepositAccountIndex when it is not needed
  useEffect(() => {
    if (account?.connectedDepositAccounts?.length <= 1 || !account?.connectedDepositAccounts) {
      setValue('designatedConnectedDepositAccountIndex', undefined);
      unregister('designatedConnectedDepositAccountIndex', { keepValue: false });
    }
  }, [account]);

  // when user selects a non-base-currency for the transaction, get the fx rate in the background and display it
  // when updating this code, remember to change the same code in InflowOutflowTransfer.jsx
  useEffect(() => {
    async function updateFxRate() {
      if (transactionCurrency !== baseCurrency) {
        setFxRateLoading(true);
        let newRate;
        try {
          newRate = await convertCurrency(1, baseCurrency, transactionCurrency, dayjs.utc().startOf('day').valueOf());
          setValue('exchangeRate', (1 / newRate).toFixed(4), { shouldValidate: true });
          setFxRateLoading(false);
        } catch (err) {
          setFxRateLoading(false);
        }
      }
    }
    updateFxRate();
  }, [transactionCurrency]);

  useEffect(() => {
    if (account) {
      setValue('transactionCurrency', account.currency);
    }
  }, [account]);

  // receive stocksAssets with assetId and openAmount
  // caveat: we show a list of assetIds in this project; we do not check if there is enough at the time of the transaction (a warning will show up in TransactionComponent if this is the case)
  const assetIdsArray = stocksAssets.map(({ assetId, displayName, _displaySymbol }) => ({ assetId, displayName, displaySymbol: _displaySymbol }));

  // InflowOutflowTransfer and AddProjectQuote have a very similar function, remember to change it there as well if changing here
  function handleFxRateFieldSwitch(e) {
    if (showFxRate) {
      register('assetUnitPriceBaseCurrency');
      setValue('assetUnitPriceBaseCurrency', (Number(assetAmount) * Number(exchangeRate)).toFixed(2), { shouldValidate: true });
      unregister('exchangeRate'); // this is done so that prepDataForPost knows if it should use exchangeRate or assetUnitPriceBaseCurrency (so we only keep one of them in the form)
    } else {
      register('exchangeRate');
      setValue('exchangeRate', (Number(assetUnitPriceBaseCurrency) / Number(assetAmount)).toFixed(4), { shouldValidate: true });
      unregister('assetUnitPriceBaseCurrency'); // this is done so that prepDataForPost knows if it should use exchangeRate or assetUnitPriceBaseCurrency (so we only keep one of them in the form)
    }
    setShowFxRate(!showFxRate);
    // recalculate FX rate or assetUnitPriceBaseCurrency based on which field was changed
    // if showFxRate is true, then fxRate is being changed to assetUnitPriceBaseCurrency
  }

  // AddProjectTransaction adds a 'notAvailableForBuy' flag to rE accounts if they already have transactions in them (you can't add more transactions to account, it's one per property)
  // also do not show any pension accounts in Sell, as it is not possible to sell pension assets at present
  const accountListFiltered = mode === 'buy' ? accountList.filter((a) => !a.notAvailableForBuy) : accountList.filter((a) => a.category !== 'pension');

  // metal list for metals
  const metals = accountCategory === 'metals' ? Object.entries(tS('metals.transaction.parameters.assetMetal', { returnObjects: true })).map(([id, name]) => ({ id, name })) : [];
  useEffect(() => {
    // set metal to default gold if not set already
    if (accountCategory === 'metals' && !watch('assetMetalUserInput')) setValue('assetMetalUserInput', metals[0]);
  }, [accountCategory]);

  const [signalNewAccount, setSignalNewAccount] = useState(undefined); // will be set to an object of { accountFieldName, accountId } by NewAccountButton when a new account is created

  // this is done with useEffect instead of a direct callback function, because callback function does not receive updates in accountListFiltered
  useEffect(() => {
    if (signalNewAccount) {
      const newAccount = accountListFiltered.find((a) => a.id === signalNewAccount.newAccountId);
      if (newAccount) setValue(signalNewAccount.accountFieldName, newAccount);
    }
    setSignalNewAccount(undefined);
  }, [signalNewAccount]);

  // gets call parameters from closure
  // returns { displaySymbol, figi, providerAssetId, ... }
  async function getFigi() {
    const controller = new AbortController();
    setAbortController(controller);

    try {
      const session = await Auth.currentSession();
      const response = await API.post('myAPI', 'stocks/assetids', {
        body: { currency: transactionCurrency, symbol: displaySymbol },
        headers: {
          'Content-Type': 'application/json',
          Authorization: session.idToken.jwtToken,
        },
      });
      return response;

      // Handle the fetched data as required...
    } catch (error) {
      // no error message, as this is a courtesy service
      console.error('error API call to getAssetIds', error);
    }
    return null;
  }

  // gets call parameters from closure
  // returns { displaySymbol, figi, providerAssetId, ... }
  async function getQuote(assetId, providerAssetId, currency = null) {
    const controller = new AbortController();
    setAbortController(controller);

    const assets = [{ assetId, providerAssetId }];
    if (currency) assets[0].currency = currency;

    try {
      const session = await Auth.currentSession();
      const response = await API.post('myAPI', 'quotes/get', {
        body: {
          dates: [dayjs.utc().startOf('day').valueOf()],
          assets,
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: session.idToken.jwtToken,
        },
      });

      return response;

      // Handle the fetched data as required...
    } catch (error) {
      // no error message, as this is a courtesy service
      console.error('error API call to get quotes', error);
    }
    return null;
  }

  // when displaySymbol changes, fetch the figi and the current quote, and display it to user
  // i.e. the user may actually enter a new (previously unknown) ISIN, for which we need to derive providerAssetId (that is done in getFigi), so that we can get the quote
  // TODO when i enter data in displaySymbol, I want to be able to press arrow down to go to the picklist and select the asset
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    console.log('DEBUG useEffect - displaySymbol changed', displaySymbol, accountCategory);
    setCurrentPriceInfo(null);
    setChartLink(null);
    let timeout;
    let providerAssetId;
    let assetId;
    let displayName;
    if (displaySymbol) {
      if (accountCategory === 'stocks') {
        if (abortController) {
          abortController.abort();
        }

        // Debounce the new fetch request using setTimeout (make sure it is not called too frequently).

        timeout = setTimeout(async () => {
          if (!selectStocksMetadata.providerAssetIdByDisplaySymbol[displaySymbol]) {
            const response = await getFigi({ currency: transactionCurrency, symbol: displaySymbol }); // Call your async API data fetch function.
            providerAssetId = response?.providerAssetId;
            assetId = response?.figi;
            displayName = response?.displayName;
          } else {
            providerAssetId = selectStocksMetadata.providerAssetIdByDisplaySymbol[displaySymbol]; // get providerAssetId for newly entered symbols
            assetId = selectStocksMetadata.assetIdByDisplaySymbol[displaySymbol];
            displayName = selectStocksMetadata.displayNameByDisplaySymbol[displaySymbol];
          }

          // get the actual quote
          const responseQuotes = await getQuote(assetId, providerAssetId); // returns { quotes: [], status }
          if (responseQuotes?.quotes?.length > 0) {
            setCurrentPriceInfo(`${t('todaysPrice', { displayName })} ${responseQuotes?.quotes[0]?.quote?.toLocaleString('de', { style: 'currency', currency: responseQuotes?.quotes[0]?.currency })}`);
            setChartLink(`https://finance.yahoo.com/quote/${providerAssetId}`);
          }
        }, 2000);
      } else if (accountCategory === 'metals') {
        // update other asset fields
        // check if the asset exists in metalAssets; when user enters a new asset manually, the text they enter appears in displaySymbol; if they pick one, it's assetId
        // displaySymbol displays assetName for metals, but its form state is assetId
        const metal = selectMetalAssets.find((m) => m.assetId === displaySymbol);
        if (metal) {
          setValue('assetId', displaySymbol);
          setValue('assetName', metal.assetName);
          setValue('assetMetal', metal.assetMetal);
          setValue('assetWeight', metal.assetWeight);
          setValue('assetPurity', metal.assetPurity);
          setValue('assetAdditionalValue', metal.assetAdditionalValue);
        } else {
          // this is a new asset, so we only have the displaySymbol (assetName)
          setValue('assetName', displaySymbol);
          setValue('assetId', displaySymbol.replace(/[^a-zA-Z0-9]/g, '')); // only leave letters and numbers, strip and trim everything else
        }

        // get quotes will be handled separately, because it needs to react to changes in all the asset fields (not only displaySymbol)
      }

      return () => clearTimeout(timeout); // Clear the timeout if the component unmounts or if 'displaySymbol' changes again.
    }
  }, [displaySymbol]);

  // react to changes in metals asset fields; if they are complete, get a quote
  const assetId = watch('assetId');
  const assetName = watch('assetName');
  const assetMetalUserInput = watch('assetMetalUserInput'); // this has the output of Dropdown: { id, name }
  const assetWeight = watch('assetWeight');
  const assetPurity = watch('assetPurity');
  const assetAdditionalValue = watch('assetAdditionalValue');

  useEffect(() => {
    // if this anyway runs every time assetMetal changes, use it to convert assetMetalUserInput to assetMetal
    if (assetMetalUserInput) setValue('assetMetal', assetMetalUserInput.id);

    // get current quote
    if (accountCategory === 'metals' && assetMetalUserInput && assetWeight && assetPurity && assetAdditionalValue) {
      const timeout = setTimeout(async () => {
        // get the quote (displaySymbol is assetId for metals, from the AssetNameDropdown (it displays assetName, but binds assetId))
        // returns { quotes: [], status }
        const responseQuotes = await getQuote(assetId, { assetMetal: assetMetalUserInput.id, assetMetalWeight: assetWeight * (assetPurity / 1000), assetAdditionalValue }, transactionCurrency);
        if (responseQuotes?.quotes?.length > 0) {
          setCurrentPriceInfo(
            `${t('todaysPrice', { displayName: assetName })} ${responseQuotes?.quotes[0]?.quote?.toLocaleString('de', { style: 'currency', currency: responseQuotes?.quotes[0]?.currency })}`,
          );
        }
      }, 2000);
    }
  }, [displaySymbol, assetName, assetMetalUserInput, assetWeight, assetPurity, assetAdditionalValue]);

  // when the user changes the account, reset the displaySymbol field
  // this doesn't work as useEffect on account, because the form gets rendered a few times on initialisation and there is no way of knowing if this is intialisation or not
  // (during initialisation we need to pass the original transaction's displaySymbol to form to show it)
  function handleOnChangeAccount(e, onChange) {
    setValue('displaySymbol', '');
    onChange(e);
  }

  return (
    <div className="space-y-8">
      {/* ACCOUNT DROPDOWN + NEW ACCOUNT BUTTON */}

      <div id="project-details-add-transaction-accountTo" className="relative z-50 grid grid-cols-4 gap-2">
        <div className="col-span-3">
          <Controller
            name="assetAccount"
            control={control}
            defaultValue={accountListFiltered[0]}
            rules={{
              validate: (value) => {
                // throw an error if the selected account does not have a connected deposit account
                // (a deposit transaction is created on the connected account to match asset purchase and sale)
                console.info('BuySell > assetAccount > validate: value', value);
                if (value.connectedDepositAccounts?.length === 0) {
                  return t('assetAccount.error');
                }
                return true;
              },
            }}
            render={({ field, ref }) => (
              <Dropdown
                label={accountCategory !== 'pension' ? t('assetAccount.label') : t('assetContributionLabel.label')}
                list={accountListFiltered}
                value={field.value}
                onChange={(e) => handleOnChangeAccount(e, field.onChange)}
                onBlur={field.onBlur}
                inputRef={ref}
                errorMessage={errors.assetAccount?.message}
                extraWide
                optional={false}
              />
            )}
          />
        </div>
        <NewAccountButton baseCurrency={baseCurrency} setSignalNewAccount={setSignalNewAccount} accountFieldName="assetAccount" />
        <div className="pt-2 sm:text-sm text-brandRed-500 col-span-4">
          <span>{errors.assetAccount?.message}</span>
        </div>
      </div>

      {/* ASSET NAME / ISIN + METAL (for metals) + dropdown for BUY / picklist for SELL */}

      {['stocks', 'crypto'].includes(accountCategory) && mode === 'buy' && (
        <div id="project-details-add-transaction-asset-name" className="relative z-40">
          <AssetNameDropdown control={control} accountCategory={accountCategory} t={t} errors={errors} />
        </div>
      )}
      {['metals'].includes(accountCategory) && mode === 'buy' && (
        <div id="project-details-add-transaction-asset-name" className="relative z-40 grid grid-cols-4 gap-2">
          <AssetNameDropdown control={control} accountCategory={accountCategory} t={t} errors={errors} />
          <Controller
            name="assetMetalUserInput"
            control={control}
            render={({ field, ref }) => (
              <Dropdown
                formatting="col-span-2"
                label={t('assetMetal.label')}
                list={metals}
                value={field.value}
                onChange={field.onChange}
                onBlur={field.onBlur}
                inputRef={ref}
                errorMessage={errors.assetMetal?.message}
                extraWide
                optional={false}
              />
            )}
          />
        </div>
      )}
      {['stocks', 'crypto', 'metals'].includes(accountCategory) && mode === 'sell' && (
        <div id="project-details-add-transaction-asset-name" className="relative z-50">
          <AssetNamePicklist // displaySymbol
            control={control}
            account={account}
            assetIds={assetIdsArray}
            t={t}
            errors={errors}
          />
        </div>
      )}

      {/* metals: asset details fields */}
      {['metals'].includes(accountCategory) && mode === 'buy' && (
        <div id="project-details-add-transaction-metal-supplemental" className="grid grid-cols-3 gap-2">
          <div>
            <label htmlFor="assetWeight" className="block text-sm font-medium text-gray-700">
              {t('assetWeight.label')}
            </label>
            <div className="mt-1">
              <input
                type="number"
                name="assetWeight"
                id="assetWeight"
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-brandBlue-500 focus:ring-brandBlue-600 sm:text-sm"
                placeholder={10}
                {...register('assetWeight')}
              />
            </div>
          </div>
          <div>
            <label htmlFor="assetPurity" className="block text-sm font-medium text-gray-700">
              {t('assetPurity.label')}
            </label>
            <div className="mt-1">
              <input
                type="number"
                name="assetPurity"
                id="assetPurity"
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-brandBlue-500 focus:ring-brandBlue-600 sm:text-sm"
                placeholder={999}
                {...register('assetPurity')}
              />
            </div>
          </div>
          <div>
            <label htmlFor="assetAdditionalValue" className="block text-sm font-medium text-gray-700">
              {t('assetAdditionalValue.label')}
            </label>
            <div className="mt-1">
              <input
                type="number"
                name="assetAdditionalValue"
                id="assetAdditionalValue"
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-brandBlue-500 focus:ring-brandBlue-600 sm:text-sm"
                placeholder={0}
                {...register('assetAdditionalValue')}
              />
            </div>
          </div>
        </div>
      )}

      {/* supplemental dropdown for choosing one of several connected deposit accounts */}
      {account?.connectedDepositAccounts?.length > 1 && (
        <div id="project-details-add-transaction-designate-account" className="z-50 grid grid-cols-4 gap-2">
          <div className="col-span-4">
            <Controller
              name="designatedConnectedDepositAccountIndex"
              control={control}
              defaultValue={0}
              render={({ field, ref }) => (
                <Dropdown
                  label={t('designatedConnectedDepositAccountIndex.label')}
                  list={connectedDepositAccountsList}
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  inputRef={ref}
                  errorMessage={errors.designatedConnectedDepositAccountIndex?.message}
                  extraWide
                  sendBackIndex
                />
              )}
            />
            {/* <div className="pt-2 sm:text-sm text-brandRed-500">
                  <span>{errors.assetAccount?.message}</span>
                </div> */}
          </div>
        </div>
      )}

      {/* Pieces to buy */}

      {['stocks', 'metals', 'crypto', 'unlistedShares'].includes(accountCategory) && (
        <div>
          <label htmlFor="assetAmount" className="block text-sm font-medium text-gray-700">
            {`${t('assetAmount.label')} ${mode === 'buy' ? t('assetAmount.buy') : t('assetAmount.sell')}?`}
          </label>
          <div className="mt-1">
            <input
              type="number"
              name="assetAmount"
              id="assetAmount"
              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-brandBlue-500 focus:ring-brandBlue-600 sm:text-sm"
              placeholder={100}
              {...register('assetAmount')}
            />
          </div>
        </div>
      )}

      {/* Price per piece (or "amount" for deposits assets like time deposits) */}

      <div className="grid grid-cols-3 gap-3" id="project-details-add-transaction-amount">
        {accountCategory === 'deposits' ? (
          <div>
            <label htmlFor="assetAmount" className="block text-sm font-medium text-gray-700">
              {accountCategory === 'deposits' ? t('depositAmount.label') : t('assetAmount.label')}
            </label>
            <div className="mt-1">
              <input
                type="number"
                step="0.01"
                name="assetAmount"
                id="assetAmount"
                placeholder={35.49}
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-brandBlue-500 focus:ring-brandBlue-600 sm:text-sm"
                {...register('assetAmount', { min: { value: 0, message: t('assetAmount.error') } })}
              />
            </div>
            <div className="pt-2 sm:text-sm text-brandRed-500 max-w-fit">
              <span>{errors.assetAmount?.message}</span>
            </div>
          </div>
        ) : (
          <div>
            <label htmlFor="assetUnitPrice" className="block text-sm font-medium text-gray-700">
              {accountCategory === 'pension' ? t('assetContribution.label') : t('assetUnitPrice.label')}
            </label>
            <div className="mt-1">
              <input
                type="number"
                step="0.01"
                name="assetUnitPrice"
                id="assetUnitPrice"
                placeholder={35.49}
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-brandBlue-500 focus:ring-brandBlue-600 sm:text-sm"
                {...register('assetUnitPrice', { min: { value: 0, message: t('assetUnitPrice.error') } })}
              />
            </div>
            <div className="pt-2 sm:text-sm text-brandRed-500 max-w-fit">
              <span>{errors.assetUnitPrice?.message}</span>
            </div>
          </div>
        )}
        {/* currency dropdown */}
        <div className="z-10">
          <Controller
            name="transactionCurrency"
            control={control}
            render={({ field, ref }) => <Dropdown label={t('currency')} list={currencyCodes} value={field.value} onChange={field.onChange} onBlur={field.onBlur} inputRef={ref} optional={false} />}
          />
        </div>
        {/* exchange rate */}
        <div>
          {showFxRate ? (
            <>
              <label htmlFor="exchangeRate" className={`block text-sm font-medium ${transactionCurrency === baseCurrency ? 'text-gray-300' : 'text-gray-700'}`}>
                {`${t('exchangeRate.label')} ${baseCurrency}`}
              </label>
              <div className="mt-1 relative">
                <input
                  type="number"
                  step="any"
                  name="exchangeRate"
                  id="exchangeRate"
                  disabled={transactionCurrency === baseCurrency}
                  // eslint-disable-next-line max-len
                  className={`block w-full rounded-md border-gray-300 shadow-sm focus:border-brandBlue-500 focus:ring-brandBlue-600 sm:text-sm ${
                    transactionCurrency === baseCurrency ? 'text-gray-300' : 'text-gray-700'
                  }`}
                  placeholder={1}
                  {...register('exchangeRate', {
                    validate: (value) => {
                      if (value <= 0) {
                        return t('exchangeRate.mustBeGreaterThanZero');
                      }
                      return true;
                    },
                  })}
                />
                {fxRateLoading && (
                  <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                    <MiniSpinner className="h-4 w-4 animate-spin text-gray-600" />
                  </div>
                )}
              </div>
              <button className={`${transactionCurrency !== baseCurrency ? '' : 'hidden'} text-xs underline text-gray-400 hover:text-brandBlue-400`} onClick={handleFxRateFieldSwitch} type="button">
                {showFxRate ? t('switchToAmount') : `${t('switchToFxRate')} ${baseCurrency}`}
              </button>
            </>
          ) : (
            <>
              <label htmlFor="assetUnitPriceBaseCurrency" className={`block text-sm font-medium ${transactionCurrency === baseCurrency ? 'text-gray-300' : 'text-gray-700'}`}>
                {`${t('assetUnitPriceBaseCurrency.label')} ${baseCurrency}`}
              </label>
              <div className="mt-1">
                <input
                  type="number"
                  name="assetUnitPriceBaseCurrency"
                  id="assetUnitPriceBaseCurrency"
                  disabled={transactionCurrency === baseCurrency}
                  className={`block w-full rounded-md border-gray-300 shadow-sm focus:border-brandBlue-500 focus:ring-brandBlue-600 sm:text-sm ${
                    transactionCurrency === baseCurrency ? 'text-gray-300' : 'text-gray-700'
                  }`}
                  placeholder={2000}
                  {...register('assetUnitPriceBaseCurrency', {
                    validate: (value) => {
                      if (value <= 0) {
                        return t('assetUnitPriceBaseCurrency.mustBeGreaterThanZero');
                      }
                      return true;
                    },
                  })}
                />
              </div>
              <button className={`${transactionCurrency !== baseCurrency ? '' : 'hidden'} text-xs underline text-gray-400 hover:text-brandBlue-400`} onClick={handleFxRateFieldSwitch} type="button">
                {showFxRate ? `${t('switchToAmount')}` : t('switchToFxRate')}
              </button>
            </>
          )}
        </div>
        {currentPriceInfo && (
          <div className="col-span-3 transition ease-in flex space-x-1 items-center bg-brandBlue-50 px-2 py-2 rounded-lg">
            <InformationCircleIcon className="h-5 w-5 text-brandBlue-700" />
            <div className="text-brandBlue-700 text-sm ">{currentPriceInfo}</div>
            <a href={chartLink} target="_blank" rel="noreferrer" className="text-xs underline text-gray-400 hover:text-gray-500">
              <ChartBarSquareIcon className="h-5 w-5" />
            </a>
          </div>
        )}
      </div>
    </div>
  );
}
// eslint-disable-next-line max-len
BuySell.propTypes = {
  accountList: PropTypes.arrayOf(PropTypes.object).isRequired,
  control: PropTypes.objectOf(PropTypes.any).isRequired,
  register: PropTypes.func.isRequired,
  unregister: PropTypes.func.isRequired,
  mode: PropTypes.string.isRequired,
  errors: PropTypes.objectOf(PropTypes.any).isRequired,
  watch: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  getFieldState: PropTypes.func.isRequired,
  stocksAssets: PropTypes.array,
};
BuySell.defaultProps = {
  stocksAssets: [],
};
