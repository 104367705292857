/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/no-unknown-property */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Auth } from 'aws-amplify';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth/lib/types';
import MiniSpinner from '../../misc/MiniSpinner';

export default function LoginPane({
  setUser, setDisplayedPane, afterLoginGoTo,
}) {
  const form = useRef();

  const { isLoggedIn } = useSelector((state) => state.user);

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(true);
  const [loading, setLoading] = useState(false);
  const [loginError, setLoginError] = useState(undefined); // null or error message

  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    // handle error when user tries to use a federated identity with an unknown email address
    // redirected from hubListener with unknownUser=true
    const unknownUser = searchParams.get('unknownUser');
    if (unknownUser) setLoginError('unknownUser');
    // remove the unknownUser param from the URL
    setSearchParams({});
  }, []);

  // if Amplify Hub detects an expiring Refresh Token, it will set a Signal in the Redux store
  // check if the Signal is there, get it into a variable and clear it from the store
  const { refreshTokenExpiringSignal } = useSelector((s) => s.message.signal);
  useEffect(() => {
    if (refreshTokenExpiringSignal?.caller === 'RequireAuth') {
      setLoginError(refreshTokenExpiringSignal.message);
      dispatch({
        type: 'CLEAR_APP_SIGNAL',
        payload: { callerId: 'RequireAuth' },
      });
    }
  }, [refreshTokenExpiringSignal]);

  const onChangeUsername = (e) => {
    // eslint-disable-next-line no-shadow
    const username = e.target.value;
    setUsername(username);
  };

  const onChangePassword = (e) => {
    // eslint-disable-next-line no-shadow
    const password = e.target.value;
    setPassword(password);
  };

  function required(value) {
    if (!value) {
      return (<div className="alert alert-danger" role="alert">{t('site:login.fieldRequired')}</div>);
    }
    return (<div />);
  }

  async function handleLogin(e) {
    // console.log('handleLogin');
    e.preventDefault();
    e.persist(); // see: https://medium.com/@ian.mundy/async-event-handlers-in-react-a1590ed24399

    if (!isLoggedIn) {
      setLoading(true);
      setLoginError(null);

      // tokens are stored in sessionStorage by default, but if the user checks the "remember me" checkbox, they need to be persisted in localStorage
      if (rememberMe) {
        Auth.configure({
          storage: localStorage,
        });
      } else {
        Auth.configure({
          storage: sessionStorage,
        });
      }

      try {
        const cognitoUser = await Auth.signIn(username, password);
        setUser(cognitoUser);
        if (cognitoUser.challengeName === 'SOFTWARE_TOKEN_MFA') {
          // switch to MFA entry input
          setLoading(false);
          setDisplayedPane('mfa');
        } else {
          // The user directly signs in, amplify hub handles the entire process
          setLoading(false);
        }
      } catch (err) {
        setLoading(false);
        console.log('error code', err.code);
        if (err.code === 'UserNotConfirmedException') {
          // The error happens if the user didn't finish the confirmation step when signing up
          // In this case you need to resend the code and confirm the user
          // About how to resend the code and confirm the user, please check the signUp part
          setLoginError('userNotConfirmed');
        } else if (err.code === 'PasswordResetRequiredException') {
          setLoginError('passwordResetRequired');
          // The error happens when the password is reset in the Cognito console
          // In this case you need to call forgotPassword to reset the password
          // Please check the Forgot Password part.
        } else if (err.code === 'NotAuthorizedException' || err.code === 'UserNotFoundException') {
          setLoginError('invalidUsernameOrPassword');
          // The error happens when the incorrect password is provided
        } else {
          console.log(err);
        }
      }
    }
  }

  useEffect(() => {
    // if isLoggedIn turned true and loading is true
    if (isLoggedIn === true && loading === true) {
      try {
        navigate(afterLoginGoTo || `/${i18n.language}/app`, { replace: true });
        console.log(`Navigate to ${afterLoginGoTo || `/${i18n.language}/app`} hook executed`);
      } catch (error) {
        console.log(`Error while rendering page ${afterLoginGoTo}`);
      }
    } else if (isLoggedIn === true) {
      // there is no point in showing the login form if the user is already logged in
      console.log('user logged in, bypassing login form');
      navigate(`/${i18n.language}/app`, { replace: true });
    }
  }, [isLoggedIn]);

  async function handleFederatedLogin(e, provider) {
    try {
      await Auth.federatedSignIn(provider);
    } catch (error) {
      console.error(error);
      setLoginError('unknownUser');
    }
  }

  return (
    <div className="mx-auto w-full max-w-sm lg:w-96">
      <div>
        {(loginError) ? <h3 className="text-brandRed-500" id="loginIncorrectError">{t(`site:login.${loginError}`)}</h3> : null}
        <h2 className="mt-6 text-3xl font-extrabold text-gray-900 text-brand">
          {t('site:login.signInIntoYourAccount')}
        </h2>
      </div>
      <div className="mt-8">
        <div>
          <div>
            <p className="text-sm font-medium text-gray-700">
              {t('site:login.signInWith')}
            </p>

            <div className="mt-1 grid grid-cols-4 gap-3">
              <div>
                <button
                  className="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                  onClick={(e) => handleFederatedLogin(e, { provider: CognitoHostedUIIdentityProvider.Facebook })}
                >
                  <span className="sr-only">{`${t('site:login.signInWith')} Facebook`}</span>
                  <svg
                    className="w-5 h-5"
                    aria-hidden="true"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path
                      fillRule="evenodd"
                      // eslint-disable-next-line max-len
                      d="M20 10c0-5.523-4.477-10-10-10S0 4.477 0 10c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V10h2.54V7.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V10h2.773l-.443 2.89h-2.33v6.988C16.343 19.128 20 14.991 20 10z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
              </div>

              <div>
                <button
                  className="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                  onClick={(e) => handleFederatedLogin(e, { provider: CognitoHostedUIIdentityProvider.Google })}
                >
                  <span className="sr-only">{`${t('site:login.signInWith')} Google`}</span>
                  <svg fill="currentColor" className="w-5 h-5" version="1.1" id="Capa_1" viewBox="0 0 210 210">
                    <g id="SVGRepo_bgCarrier" strokeWidth="0" />
                    <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />
                    <g id="SVGRepo_iconCarrier">
                      {/* eslint-disable-next-line max-len */}
                      <path d="M0,105C0,47.103,47.103,0,105,0c23.383,0,45.515,7.523,64.004,21.756l-24.4,31.696C133.172,44.652,119.477,40,105,40 c-35.841,0-65,29.159-65,65s29.159,65,65,65c28.867,0,53.398-18.913,61.852-45H105V85h105v20c0,57.897-47.103,105-105,105 S0,162.897,0,105z" />
                    </g>
                  </svg>
                </button>
              </div>

              <div>
                <a
                  href="#"
                  className="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                  onClick={(e) => handleFederatedLogin(e, { provider: CognitoHostedUIIdentityProvider.Apple })}
                >
                  <span className="sr-only">{`${t('site:login.signInWith')} Apple`}</span>
                  <svg
                    className="w-5 h-5 -mt-0.5 mb-0.5"
                    aria-hidden="true"
                    fill="currentColor"
                    viewBox="-56.24 0 608.728 608.728"
                  >
                    {/* eslint-disable-next-line max-len */}
                    <path d="M273.81 52.973C313.806.257 369.41 0 369.41 0s8.271 49.562-31.463 97.306c-42.426 50.98-90.649 42.638-90.649 42.638s-9.055-40.094 26.512-86.971zM252.385 174.662c20.576 0 58.764-28.284 108.471-28.284 85.562 0 119.222 60.883 119.222 60.883s-65.833 33.659-65.833 115.331c0 92.133 82.01 123.885 82.01 123.885s-57.328 161.357-134.762 161.357c-35.565 0-63.215-23.967-100.688-23.967-38.188 0-76.084 24.861-100.766 24.861C89.33 608.73 0 455.666 0 332.628c0-121.052 75.612-184.554 146.533-184.554 46.105 0 81.883 26.588 105.852 26.588z" />

                  </svg>
                </a>
              </div>

              <div>
                <a
                  href="#"
                  className="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                  onClick={(e) => handleFederatedLogin(e, { provider: CognitoHostedUIIdentityProvider.Amazon })}
                >
                  <span className="sr-only">{`${t('site:login.signInWith')} Amazon`}</span>
                  <svg
                    className="w-5 h-5"
                    aria-hidden="true"
                    fill="currentColor"
                    viewBox="0 0 35.418 35.418"
                  >
                    <g>
                      {/* eslint-disable-next-line max-len */}
                      <path d="M20.948,9.891c-0.857,0.068-1.847,0.136-2.837,0.269c-1.516,0.195-3.032,0.461-4.284,1.053 c-2.439,0.994-4.088,3.105-4.088,6.209c0,3.898,2.506,5.875,5.669,5.875c1.057,0,1.913-0.129,2.703-0.328 c1.255-0.396,2.31-1.123,3.562-2.441c0.727,0.99,0.923,1.453,2.177,2.509c0.329,0.133,0.658,0.133,0.922-0.066 c0.791-0.659,2.174-1.848,2.901-2.508c0.328-0.267,0.263-0.66,0.066-0.992c-0.727-0.924-1.45-1.718-1.45-3.498v-5.943 c0-2.513,0.195-4.822-1.647-6.537c-1.518-1.391-3.891-1.916-5.735-1.916c-0.264,0-0.527,0-0.792,0 c-3.362,0.197-6.921,1.647-7.714,5.811c-0.13,0.525,0.267,0.726,0.53,0.793l3.691,0.464c0.396-0.07,0.593-0.398,0.658-0.73 c0.333-1.449,1.518-2.176,2.836-2.309c0.067,0,0.133,0,0.265,0c0.79,0,1.646,0.332,2.109,0.987 c0.523,0.795,0.461,1.853,0.461,2.775L20.948,9.891L20.948,9.891z M20.223,17.749c-0.461,0.925-1.253,1.519-2.11,1.718 c-0.131,0-0.327,0.068-0.526,0.068c-1.45,0-2.31-1.123-2.31-2.775c0-2.11,1.254-3.104,2.836-3.565 c0.857-0.197,1.847-0.265,2.836-0.265v0.793C20.948,15.243,21.01,16.43,20.223,17.749z M35.418,26.918v0.215 c-0.035,1.291-0.716,3.768-2.328,5.131c-0.322,0.25-0.645,0.107-0.503-0.254c0.469-1.145,1.541-3.803,1.04-4.412 c-0.355-0.465-1.826-0.43-3.079-0.322c-0.572,0.072-1.075,0.105-1.469,0.183c-0.357,0.033-0.431-0.287-0.071-0.537 c0.466-0.323,0.969-0.573,1.541-0.756c2.039-0.608,4.406-0.25,4.729,0.146C35.348,26.414,35.418,26.629,35.418,26.918z M32.016,29.428c-0.466,0.357-0.965,0.682-1.468,0.973c-3.761,2.261-8.631,3.441-12.856,3.441c-6.807,0-12.895-2.512-17.514-6.709 c-0.396-0.324-0.073-0.789,0.393-0.539C5.549,29.5,11.709,31.26,18.084,31.26c4.013,0,8.342-0.754,12.463-2.371 c0.285-0.104,0.608-0.252,0.895-0.356C32.087,28.242,32.661,28.965,32.016,29.428z" />
                    </g>
                  </svg>
                </a>
              </div>
            </div>
          </div>
          <div className="mt-6 relative">
            <div
              className="absolute inset-0 flex items-center"
              aria-hidden="true"
            >
              <div className="w-full border-t border-gray-300" />
            </div>
            <div className="relative flex justify-center text-sm">
              <span className="px-2 bg-white text-gray-500">
                {t('site:login.orContinueWith')}
              </span>
            </div>
          </div>
        </div>
        <div className="mt-6">
          <form onSubmit={handleLogin} ref={form} className="space-y-6">
            <div>
              <label
                htmlFor="username"
                className="block text-sm font-medium text-gray-700"
              >
                {t('site:login.emailOrUsername')}
              </label>
              <div className="mt-1">
                <input
                  id="username"
                  name="username"
                  type="text"
                  autoComplete="username"
                  required
                  // eslint-disable-next-line max-len
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-brandBlue-500 focus:border-brandBlue-500 sm:text-sm"
                  value={username}
                  onChange={onChangeUsername}
                  validations={[required]}
                />
              </div>
            </div>
            <div className="space-y-1">
              <label
                htmlFor="password"
                className="block text-sm font-medium text-gray-700"
              >
                {t('site:login.password')}
              </label>
              <div className="mt-1">
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  value={password}
                  onChange={onChangePassword}
                  validations={[required]}
                  // eslint-disable-next-line max-len
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-brandBlue-500 focus:border-brandBlue-500 sm:text-sm"
                />
              </div>
            </div>
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <input
                  id="remember-me"
                  name="remember-me"
                  type="checkbox"
                  checked={rememberMe}
                  onChange={() => setRememberMe(!rememberMe)}
                  className="h-4 w-4 text-brandBlue-500 focus:ring-brandBlue-500 border-gray-300 rounded"
                />
                <label
                  htmlFor="remember-me"
                  className="ml-2 block text-sm text-gray-900"
                >
                  {t('site:login.rememberMe')}
                </label>
              </div>
              <div className="text-sm">
                <button type="button" className="font-semibold text-brandBlue-500 hover:text-brandBlue-600" onClick={() => setDisplayedPane('forgotPassword')}>
                  {t('site:login.forgotPassword')}
                </button>
              </div>
            </div>
            <div>
              <button
                type="submit"
                // eslint-disable-next-line max-len
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-semibold text-white bg-brandBlue-500 hover:bg-brandBlue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brandBlue-500"
                id="submit"
                disabled={loading}
              >
                {loading ? <MiniSpinner className="h-4 w-4 animate-spin text-white" /> : (<span>{t('site:login.signIn')}</span>)}
              </button>
            </div>
            <div>
              <button
                // type="submit"
                // eslint-disable-next-line max-len
                className="w-full flex justify-center py-2 px-4 border rounded-md shadow-sm text-sm font-semibold outline-brandBlue-500 text-brandBlue-500 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brandBlue-500"
                disabled={loading}
                onClick={() => navigate(-1)}
              >
                {t('site:general.cancel')}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
LoginPane.propTypes = {
  setUser: PropTypes.func.isRequired,
  setDisplayedPane: PropTypes.func.isRequired,
  afterLoginGoTo: PropTypes.string,
};
LoginPane.defaultProps = {
  afterLoginGoTo: null,
};
