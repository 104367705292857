/* eslint-disable no-underscore-dangle */
/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { InformationCircleIcon } from '@heroicons/react/20/solid';
import { convertCurrency } from '../../../misc/ecbCurrencyRates';
import { currencyCodes } from '../../../misc/currencyCodes';
import Dropdown from './Dropdown'; // import wrapper for the common Dropdown component
import InputField from './InputField';
import SwitchComponent from './SwitchComponent';
import CollapsibleLabelButtons from './CollapsibleLabelButtons';
import ToolTip from '../../../elements/ToolTip';
import products from './products';

dayjs.extend(utc);

export function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

function Header({ text }) {
  Header.propTypes = {
    text: PropTypes.string.isRequired,
  };
  const { t: tI } = useTranslation(['app'], { keyPrefix: 'addAccount.pension' });
  return <h4 className="xs:col-span-2 lg:col-span-3 text-base font-bold text-gray-900">{tI(text)}</h4>;
}

/**
 * Helper component to create a double quotes input field. Works only for the four specialQuotes types in the pension account.
 */
function DoubleQuotes({ errors, register, watch, accountCurrency, isBaseCurrencySameAsAccountCurrency, baseCurrency, fieldName }) {
  DoubleQuotes.propTypes = {
    errors: PropTypes.object.isRequired,
    register: PropTypes.func.isRequired,
    watch: PropTypes.func.isRequired,
    accountCurrency: PropTypes.string,
    isBaseCurrencySameAsAccountCurrency: PropTypes.bool.isRequired,
    baseCurrency: PropTypes.string.isRequired,
    fieldName: PropTypes.string.isRequired,
  };
  DoubleQuotes.defaultProps = {
    accountCurrency: '',
  };

  const { t: tD } = useTranslation(['app'], { keyPrefix: 'accountDetails.columns' });

  const fieldQuote = watch(`${fieldName}Quote`);
  const fieldQuoteBaseCurrency = watch(`${fieldName}QuoteBaseCurrency`);

  return (
    <div>
      <div className="flex justify-between">
        <label htmlFor="email" className="text-sm font-medium text-gray-700">
          {tD(`${fieldName}.label`)}
          <ToolTip formatting="inline-block -mb-1" info={tD(`${fieldName}.tooltip`)} />
        </label>
        <span className="text-sm text-gray-500" id="email-optional">
          Optional
        </span>
      </div>
      <div className="mt-1 relative">
        <div className="grid grid-cols-6 gap-2 items-center">
          <input
            id={`${fieldName}Quote`}
            type="number"
            autoComplete="off"
            name={`${fieldName}Quote`}
            className={`block w-full focus:outline-none  sm:text-sm rounded-md col-span-2
                        ${
                          errors?.payoutsDuration
                            ? 'border-red-300 text-red-900  placeholder-red-300 focus:ring-red-500 focus:border-red-500'
                            : 'border-gray-300 placeholder-gray-400 focus:ring-brandBlue-500 focus:border-brandBlue-500'
                        }`}
            placeholder="250"
            defaultValue=""
            aria-invalid="true"
            aria-describedby="username-error"
            {...register(
              `${fieldName}Quote`,
              // if baseCurrency !== accountCurrency: if base currency quote is filled out, but the account currency is not
              { validate: (value) => (!isBaseCurrencySameAsAccountCurrency && fieldQuoteBaseCurrency && !value ? 'This field is required when the base currency field is filled out' : true) },
            )}
          />
          <span className="text-xs xs:text-sm">{accountCurrency || '?'}</span>
          {!isBaseCurrencySameAsAccountCurrency && (
            <>
              <input
                id={`${fieldName}QuoteBaseCurrency`}
                type="number"
                autoComplete="off"
                name={`${fieldName}QuoteBaseCurrency`}
                className={`block w-full focus:outline-none  sm:text-sm rounded-md col-span-2
                        ${
                          errors?.payoutsDuration
                            ? 'border-red-300 text-red-900  placeholder-red-300 focus:ring-red-500 focus:border-red-500'
                            : 'border-gray-300 placeholder-gray-400 focus:ring-brandBlue-500 focus:border-brandBlue-500'
                        }`}
                placeholder="230"
                {...register(`${fieldName}QuoteBaseCurrency`, {
                  validate: (value) => (!isBaseCurrencySameAsAccountCurrency && !value ? 'This field is required when this account currency is not equal to your base currency' : true),
                })}
              />
              <span className="text-xs xs:text-sm">{baseCurrency}</span>
            </>
          )}
        </div>
      </div>

      <p className="mt-2 text-sm text-red-600" id="email-error">
        {true && <span>{errors?.payoutsDuration?.message}</span>}
      </p>
    </div>
  );
}

function PensionAccountData({ index, updateWorkflowStatus, userInput, setUserInput, signalNext, setSignalNext }) {
  const { t: tL } = useTranslation('app', { keyPrefix: 'schema.pension.account' });
  const { t: tI } = useTranslation(['app'], { keyPrefix: 'addAccount.pension' });

  const [fxRate, setFxRate] = useState(null);

  const selectDepositAccounts = useSelector((state) => state.data.deposits.accounts);
  const baseCurrency = useSelector((state) => state.user.profile.settings.baseCurrency);

  //--------------------------------------------------------------------------------
  // default values for the form state (userInput + some tweaks)
  //--------------------------------------------------------------------------------

  const defaultValues = { ...(userInput && { ...userInput.data }) };
  defaultValues.useInflationUserInput = defaultValues.useInflationUserInput === undefined ? true : defaultValues.useInflationUserInput;
  defaultValues.payoutDurationFrequencyUnitUserInput = 'indefinite';
  defaultValues.payoutsFrequency = 1; // { id: 1, name: tL('parameters.payoutsFrequency.1') };
  defaultValues.currency = defaultValues.currency === undefined ? baseCurrency : defaultValues.currency;
  // ↓ these will be filled out in processOutputData based on ...UserInput fields
  defaultValues.intialQuotes = undefined;
  defaultValues.payoutsDuration = undefined;
  defaultValues.connectedDepositAccounts = undefined;

  // register the hook
  const { register, watch, reset, getValues, control, formState, setValue, trigger } = useForm({ mode: 'onChange', defaultValues });
  const { errors, isValid, isDirty, dirtyFields } = formState;
  const accountCurrency = watch('currency');
  const isBaseCurrencySameAsAccountCurrency = accountCurrency === baseCurrency;
  const contributionsSelfPaid = watch('contributionsSelfPaid');

  //--------------------------------------------------------------------------------
  // synchronise this component's form with the parent component's form state
  //--------------------------------------------------------------------------------
  function processOutputData(formValues) {
    const obj = { ...formValues };

    // housekeeping: convert dates to epoch
    obj.productPurchaseDate = dayjs.utc(formValues.productPurchaseDate).valueOf(); // required
    obj.payoutsPhaseStart = dayjs.utc(formValues.payoutsPhaseStart).valueOf(); // required

    // convert contributionsAmountsUserInput to an array of { effectiveDate, value }
    obj.contributionsAmounts = [{ effectiveDate: obj.productPurchaseDate, value: obj.contributionsAmountsUserInput }];

    // convert payoutsDurationFrequencyUserInput and payoutsDurationUserInput to payoutsDuration
    if (obj.payoutDurationFrequencyUnitUserInput === 'indefinite') obj.payoutsDuration = 0;
    else if (obj.payoutDurationFrequencyUnitUserInput === 'years') obj.payoutsDuration = 12 * obj.payoutsDurationUserInput;
    else if (obj.payoutDurationFrequencyUnitUserInput === 'months') obj.payoutsDuration = obj.payoutsDurationUserInput;

    // move quotes to intialQuotes
    obj.initialQuotes = [];
    if (obj.payoutOnlyContributionsToDateQuote) {
      obj.initialQuotes.push({
        type: 'payoutOnlyContributionsToDate',
        quote: obj.payoutOnlyContributionsToDateQuote,
        quoteBaseCurrency: isBaseCurrencySameAsAccountCurrency ? obj.payoutOnlyContributionsToDateQuote : obj.payoutOnlyContributionsToDateQuoteBaseCurrency,
      });
    }
    if (obj.payoutOnlyContributionsToDateIndexedQuote) {
      obj.initialQuotes.push({
        type: 'payoutOnlyContributionsToDateIndexed',
        quote: obj.payoutOnlyContributionsToDateIndexedQuote,
        quoteBaseCurrency: isBaseCurrencySameAsAccountCurrency ? obj.payoutOnlyContributionsToDateIndexedQuote : obj.payoutOnlyContributionsToDateIndexedQuoteBaseCurrency,
      });
    }
    if (obj.payoutAllPlannedContributionsQuote) {
      obj.initialQuotes.push({
        type: 'payoutAllPlannedContributions',
        quote: obj.payoutAllPlannedContributionsQuote,
        quoteBaseCurrency: isBaseCurrencySameAsAccountCurrency ? obj.payoutAllPlannedContributionsQuote : obj.payoutAllPlannedContributionsQuoteBaseCurrency,
      });
    }
    if (obj.payoutAllPlannedContributionsIndexedQuote) {
      obj.initialQuotes.push({
        type: 'payoutAllPlannedContributionsIndexed',
        quote: obj.payoutAllPlannedContributionsIndexedQuote,
        quoteBaseCurrency: isBaseCurrencySameAsAccountCurrency ? obj.payoutAllPlannedContributionsIndexedQuote : obj.payoutAllPlannedContributionsIndexedQuoteBaseCurrency,
      });
    }

    // handle connectedDepositIds
    if (obj.connectedDepositMode === 'existingAccount') {
      const connectedObject = obj.connectedDepositAccountsObject || { id: null }; // before the user picks the answer here
      obj.connectedDepositAccounts = [connectedObject.id];
    } else {
      obj.connectedDepositAccounts = [];
    }

    return obj;
  }

  useEffect(() => {
    // check if all validations are passed and if so, set component status to true
    if (isDirty && isValid) {
      const output = getValues();
      // console.log('isDirty and isValid both true, getValues:', JSON.stringify(output, null, 2));
      // transform the output data before sending them to caller
      const processedOutput = processOutputData(output);
      // console.log('processed output', processedOutput);
      setUserInput({ ...userInput, ...processedOutput });
      updateWorkflowStatus(index, true);
      // reset isDirty status so that we know if the user touched the form again
      reset({ ...userInput, ...output }, { keepIsValid: true });
    }
    // check if the user invalidated the form and if so, remove the next step
    if (isDirty && !isValid) {
      const output = getValues();
      // console.log('isDirty is true and isValid is not, getValues:', JSON.stringify(output, null, 2));
      // CAVEAT: this approach means that the previously entered data is still potentially within the form
      const processedOutput = processOutputData(output);
      setUserInput({ ...userInput, ...processedOutput });
      // console.log('processed output', processedOutput);
      updateWorkflowStatus(index, false);
      // reset isDirty status so that we know if the user touched the form again
      reset({ ...userInput, ...output }, { keepErrors: true });
    }
  }, [formState]);

  //--------------------------------------------------------------------------------
  // subscribe to signal from parent that the user has clicked the "next" button
  // (and then trigger all validations to show errors)
  //--------------------------------------------------------------------------------

  useEffect(() => {
    if (signalNext) {
      trigger();
      setSignalNext(false);
    }
  }, [signalNext]);

  //---------------------------------------------------------------------------------------------
  // react to changes in the product field; if user selects a new product
  // apply the changes to other form fields
  //---------------------------------------------------------------------------------------------

  // icon and id are in products, need to add the translations here
  const productList = products.pension.map((p) => ({
    ...p,
    name: tI(`product.${p.id}.name`),
    description: tI(`product.${p.id}.description`),
  }));

  // list of fields which change when user selects a new template
  // eslint-disable-next-line max-len
  const templateFieldNames = [
    'contributionsSelfPaid',
    'contributionsAmounts',
    'contributionsFrequency',
    'contributionsDeductible',
    'payoutsTaxable',
    'payoutsIndexed',
    'payoutsFrequency',
    'payoutsDuration',
  ];

  //---------------------------------------------------------------------------------------------
  // if the user selects a new product, apply the product values to the form fields
  //---------------------------------------------------------------------------------------------

  const product = watch('product');
  useEffect(() => {
    // get products.pension and apply everything except id, type, Icon, link onto form state
    const selectedProduct = productList.find((p) => p.id === product);
    if (selectedProduct) {
      const { id, name, description, type, Icon, link, ...rest } = selectedProduct;
      Object.entries(rest).forEach(([key, value]) => {
        setValue(key, value); // needed?, { shouldValidate: true });
      });
    }
  }, [product]);

  //---------------------------------------------------------------------------------------------
  // set product to "custom" if the user changes any of the connected fields
  //---------------------------------------------------------------------------------------------

  // if the product is not on "custom" and the user changes any connected form fields, "product" should be reset to "custom"
  const templateFieldsObjects = watch(templateFieldNames); // this destructures to an array, i.e. const [field1, field2] = watch(['field1', 'field2'])
  useEffect(() => {
    setValue('product', 'custom'); // needed? , { shouldValidate: true }
  }, templateFieldsObjects);

  // watch frequencyUnit and clear payoutsDuration to 0 if it is indefinite
  const frequencyUnit = watch('payoutDurationFrequencyUnitUserInput');
  useEffect(() => {
    if (frequencyUnit === 'indefinite') setValue('payoutsDuration', 0);
  }, [frequencyUnit]);

  const useInflationUserInput = watch('useInflationUserInput');
  const connectedDepositMode = watch('connectedDepositMode');

  //---------------------------------------------------------------------------------------------
  // if accountCurrency !== baseCurrency, display current fx rate for the user's convenience
  //---------------------------------------------------------------------------------------------

  // when user selects a non-base-currency for the transaction, get the fx rate in the background and display it
  // when updating this code, remember to change the same code in InflowOutflowTransfer.jsx
  useEffect(() => {
    async function updateFxRate() {
      if (!isBaseCurrencySameAsAccountCurrency) {
        setFxRate('loading');
        let newRate;
        try {
          newRate = await convertCurrency(1, accountCurrency, baseCurrency, dayjs.utc().startOf('day').valueOf());
          // setValue('exchangeRate', (1 / newRate).toFixed(4), { shouldValidate: true });
          setFxRate(newRate);
        } catch (err) {
          setFxRate(null);
        }
      }
    }
    updateFxRate();
  }, [accountCurrency]);

  // TODO: translations + fxRate information + (i) button

  // handle the "apply fx rate" button
  function handleFxRateButton() {
    // get a list of dirtyFields and filter them to only leave fieldNames which have payoutAll or payoutOnly and which end in Quote
    // if that list is not empty, apply the fxRate to those fields
    ['payoutAllPlannedContributions', 'payoutAllPlannedContributionsIndexed', 'payoutOnlyContributionsToDate', 'payoutOnlyContributionsToDateIndexed'].forEach((fieldName) => {
      const fieldQuote = getValues(`${fieldName}Quote`);
      if (fieldQuote) {
        setValue(`${fieldName}QuoteBaseCurrency`, Number((fxRate * fieldQuote).toFixed(2)), { shouldValidate: true });
      }
    });
  }

  //---------------------------------------------------------------------------------------------
  // render the form
  //---------------------------------------------------------------------------------------------

  return (
    <div className="space-y-6 md:space-y-0 md:grid md:grid-cols-4 md:gap-6">
      <div className="md:col-span-1">
        <h3 className="text-xl md:text-2xl font-bold leading-6 text-gray-900">{tI('accountInfo')}</h3>
        <p className="mt-4 text-sm text-gray-500">{tI('provideInfo')}</p>
        <p className="mt-8 text-sm text-gray-500 font-bold">{tI('whyDoWeAsk')}</p>
        <p className="mt-2 text-sm text-gray-500">{tI('whyDoWeAskAnswer')}</p>
        <p className="mt-8 text-sm text-gray-500 font-bold">{tI('doIHaveToFillThemAllOut')}</p>
        <p className="mt-2 text-sm text-gray-500">{tI('doIHaveToFillThemAllOutAnswer')}</p>
        <p className="mt-8 text-sm text-gray-500 font-bold">{tI('useATemplate')}</p>
        <p className="mt-2 text-sm text-gray-500">{tI('useATemplateAnswer')}</p>
      </div>

      <form className="md:col-span-3 ">
        <div className="divide-y divide-gray-200">
          {/* SECTION 1: name, currency, purchaseDate */}

          <div className="py-3 lg:pb-4 grid xs:grid-cols-2 lg:grid-cols-3 gap-4 xs:gap-6 lg:gap-8">
            <InputField
              id="name"
              category="pension"
              type="text"
              placeholder={tL('name.placeholder')}
              autoComplete="off"
              optional={false}
              register={register}
              errors={errors}
              validationRules={{ required: { value: true, message: tL('name.error') } }}
            />
            <Dropdown
              id="currency"
              list={currencyCodes}
              label={tL('currency.label')}
              placeholder={tL('currency.placeholder')}
              toolTip={tL('currency.tooltip')}
              register={register}
              optional={false}
              errors={errors}
              control={control}
              validationRules={{ required: { value: true, message: tL('currency.error') } }}
            />
            <InputField
              id="productPurchaseDate"
              category="pension"
              type="date"
              optional={false}
              autoComplete="off"
              register={register}
              errors={errors}
              validationRules={{ required: { value: true, message: tL('productPurchaseDate.error') } }}
            />
          </div>

          {/* SECTION 2: TEMPLATE SELECTION */}

          <div className="py-3 lg:py-8 grid xs:grid-cols-2 lg:grid-cols-3 gap-4 xs:gap-6 lg:gap-8">
            <CollapsibleLabelButtons control={control} id="product" keyPrefix="addAccount.pension.product" label={tI('product.label')} list={productList} optional />
          </div>

          {/* SECTION 3: CONTRIBUTIONS */}

          <div className="py-3 lg:py-8 grid xs:grid-cols-2 lg:grid-cols-3 gap-4 xs:gap-6 lg:gap-8">
            <Header text="yourPensionContributions" />
            {/* contributionsSelfPaid */}
            <Controller
              name="contributionsSelfPaid"
              control={control}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <SwitchComponent
                  id="contributionsSelfPaid"
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  inputRef={ref}
                  label={tL('contributionsSelfPaid.label')}
                  toolTip={tL('contributionsSelfPaid.tooltip')}
                />
              )}
            />
            <InputField
              id="contributionsAmountsUserInput"
              label={tL('contributionsAmounts.label')}
              category="pension"
              type="text"
              placeholder={tL('contributionsAmounts.placeholder')}
              autoComplete="off"
              optional
              register={register}
              errors={errors}
              validationRules={{
                validate: (value) => (contributionsSelfPaid === true && !value ? tL('contributionsAmounts.error') : true),
              }}
            />
            <Controller
              name="contributionsDeductible"
              control={control}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <SwitchComponent
                  id="contributionsDeductible"
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  inputRef={ref}
                  label={tL('contributionsDeductible.label')}
                  toolTip={tL('contributionsDeductible.tooltip')}
                />
              )}
            />
          </div>

          {/* SECTION 4: PAYOUTS */}

          <div className="py-3 lg:py-8 grid xs:grid-cols-2 lg:grid-cols-3 gap-4 xs:gap-6 lg:gap-8">
            <Header text="yourPensionPayouts" />
            <InputField
              id="payoutsPhaseStart"
              category="pension"
              type="date"
              optional={false}
              autoComplete="off"
              register={register}
              errors={errors}
              validationRules={{ required: { value: true, message: tL('payoutsPhaseStart.error') } }}
            />
            <Dropdown
              id="payoutsFrequency"
              list={[
                null,
                { id: 1, name: tL('parameters.payoutsFrequency.1') },
                { id: 3, name: tL('parameters.payoutsFrequency.3') },
                { id: 12, name: tL('parameters.payoutsFrequency.12') },
                { id: 0, name: tL('parameters.payoutsFrequency.0') },
              ]}
              label={tL('payoutsFrequency.label')}
              placeholder={tL('payoutsFrequency.placeholder')}
              toolTip={tL('payoutsFrequency.tooltip')}
              register={register}
              errors={errors}
              control={control}
              optional={false}
            />
            {/* duration months | years */}
            <div className="">
              <div className="flex justify-between">
                <label htmlFor="email" className="text-sm font-medium text-gray-700 flex justify-start">
                  {tL('payoutsDuration.label')}
                  <ToolTip info="text" />
                </label>
              </div>
              <div className="mt-1 relative rounded-md shadow-sm">
                <div className="grid grid-cols-2 gap-4">
                  <input
                    id="payoutsDurationUserInput"
                    type="number"
                    autoComplete="off"
                    disabled={frequencyUnit === 'indefinite'}
                    name="payoutsDurationUserInput"
                    className={`block w-full focus:outline-none  sm:text-sm rounded-md
                      ${
                        errors?.payoutsDuration
                          ? 'border-red-300 text-red-900  placeholder-red-300 focus:ring-red-500 focus:border-red-500'
                          : 'border-gray-300 placeholder-gray-400 focus:ring-brandBlue-500 focus:border-brandBlue-500'
                      }`}
                    placeholder=""
                    defaultValue=""
                    aria-invalid="true"
                    aria-describedby="username-error"
                    {...register('payoutsDurationUserInput')}
                  />
                  <select
                    id="payoutDurationFrequencyUnitUserInput"
                    name="payoutDurationFrequencyUnitUserInput"
                    className={`block w-full focus:outline-none sm:text-sm rounded-md
                      ${
                        errors?.payoutsDuration
                          ? 'border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500'
                          : 'border-gray-300 placeholder-gray-400 focus:ring-brandBlue-500 focus:border-brandBlue-500'
                      }`}
                    defaultValue={null}
                    {...register('payoutDurationFrequencyUnitUserInput')}
                  >
                    <option value={null}>Select...</option>
                    <option value="months">{tL('parameters.frequencyUnit.1')}</option>
                    <option value="years">{tL('parameters.frequencyUnit.12')}</option>
                    <option value="indefinite">{tL('parameters.frequencyUnit.0')}</option>
                  </select>
                </div>
              </div>
              <p className="mt-2 text-sm text-red-600" id="email-error">
                {true && <span>{errors?.payoutsDuration?.message}</span>}
              </p>
            </div>
            <Controller
              name="payoutsTaxable"
              control={control}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <SwitchComponent id="payoutsTaxable" onChange={onChange} onBlur={onBlur} value={value} inputRef={ref} label={tL('payoutsTaxable.label')} toolTip={tL('payoutsTaxable.tooltip')} />
              )}
            />
            <Controller
              name="payoutsIndexed"
              control={control}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <SwitchComponent id="payoutsIndexed" onChange={onChange} onBlur={onBlur} value={value} inputRef={ref} label={tL('payoutsIndexed.label')} toolTip={tL('payoutsIndexed.tooltip')} />
              )}
            />
            <Controller
              name="useInflationUserInput"
              control={control}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <SwitchComponent
                  id="useInflationUserInput"
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  inputRef={ref}
                  label={tL('useInflationUserInput.label')}
                  toolTip={tL('useInflationUserInput.tooltip')}
                />
              )}
            />
            {!useInflationUserInput && <InputField id="discountRate" category="pension" type="number" autoComplete="off" register={register} errors={errors} />}
          </div>

          {/* SECTION 5: ESTIMATED PAYOUTS */}
          <div className="py-3 lg:py-8 grid xs:grid-cols-2 lg:grid-cols-3 gap-4 xs:gap-6 lg:gap-8">
            {/* TODO: produce current exchange rate to help like in Projects --> make it a component */}
            <Header text="whatYouWillReceive" />
            <DoubleQuotes
              errors={errors}
              register={register}
              watch={watch}
              accountCurrency={accountCurrency}
              isBaseCurrencySameAsAccountCurrency={isBaseCurrencySameAsAccountCurrency}
              baseCurrency={baseCurrency}
              fieldName="payoutOnlyContributionsToDate"
            />
            <DoubleQuotes
              errors={errors}
              register={register}
              watch={watch}
              accountCurrency={accountCurrency}
              isBaseCurrencySameAsAccountCurrency={isBaseCurrencySameAsAccountCurrency}
              baseCurrency={baseCurrency}
              fieldName="payoutOnlyContributionsToDateIndexed"
            />
            <DoubleQuotes
              errors={errors}
              register={register}
              watch={watch}
              accountCurrency={accountCurrency}
              isBaseCurrencySameAsAccountCurrency={isBaseCurrencySameAsAccountCurrency}
              baseCurrency={baseCurrency}
              fieldName="payoutAllPlannedContributions"
            />
            <DoubleQuotes
              errors={errors}
              register={register}
              watch={watch}
              accountCurrency={accountCurrency}
              isBaseCurrencySameAsAccountCurrency={isBaseCurrencySameAsAccountCurrency}
              baseCurrency={baseCurrency}
              fieldName="payoutAllPlannedContributionsIndexed"
            />

            {/* FXRATE BUTTON (if fxRate is a number, display this button) */}

            {Number.isFinite(fxRate) && fxRate !== null && (
              <div className="text-sm leading-snug flex flex-col text-gray-700">
                <div className="flex">
                  <label htmlFor="switch-button" className="font-medium">
                    {tL('fxRate.label', { accountCurrency, baseCurrency })}
                  </label>
                  <ToolTip info={tL('fxRate.tooltip', { baseCurrency })} />
                </div>
                <p>{fxRate.toFixed(2)}</p>
                <div className="flex items-center w-full mt-1">
                  <div className="flex gap-2 items-center w-full">
                    <button
                      type="button"
                      // eslint-disable-next-line max-len
                      className="rounded-md w-full px-4 py-2 text-sm font-bold shadow-sm border border-gray-300 bg-white text-gray-900 hover:bg-gray-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brandBlue-500"
                      onClick={handleFxRateButton}
                    >
                      {tL('fxRate.apply', { baseCurrency })}
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>

          {/* SECTION 6: CONNECTED DEPOSITS */}

          <div className="py-3 lg:py-8 grid xs:grid-cols-2 lg:grid-cols-3 gap-4 xs:gap-6 lg:gap-8">
            <fieldset className="md:col-span-2 xl:col-span-3 grid md:grid-cols-2 xl:grid-cols-3 justify-items-start items-center gap-4 text-sm text-gray-400">
              <Header text="whenIBuy" />
              <div className="flex items-center">
                <input
                  id="existingAccount"
                  name="existingAccount"
                  type="radio"
                  value="existingAccount"
                  className="h-4 w-4 border-gray-300 text-brandBlue-500 focus:ring-brandBlue-400"
                  {...register('connectedDepositMode', { required: { value: true, message: tL('connectedDepositMode.error') } })}
                />
                <label htmlFor="existingAccount" className="ml-3 block text-sm font-medium text-gray-700">
                  {tI('existingAccount')}
                </label>
                {/* if this radio button is selected, display the deposit accounts dropdown */}
              </div>
              {connectedDepositMode === 'existingAccount' && (
                <div className="xl:flex xl:items-center">
                  <label htmlFor="connectedDepositAccountObject" className="flex text-sm font-medium text-gray-700">
                    {tI('existingAccountSelect')}
                  </label>
                  <div className="mt-1 ml-2">
                    <select
                      id="connectedDepositAccountObject"
                      name="connectedDepositAccountObject"
                      className="text-gray-500 shadow-sm focus:ring-brandBlue-400 focus:border-brandBlue-400 block w-full text-sm border-gray-300 rounded-md"
                      {...register('connectedDepositAccountObject')}
                    >
                      {selectDepositAccounts.map((a) => (
                        <option key={a.id} value={a.id}>
                          {a.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              )}

              <div className="flex items-center col-start-1">
                <input
                  id="createAccountLater"
                  name="createAccountLater"
                  type="radio"
                  value="createAccountLater"
                  className="h-4 w-4 border-gray-300 text-brandBlue-500 focus:ring-brandBlue-400"
                  {...register('connectedDepositMode', { required: { value: true, message: tL('connectedDepositMode.error') } })}
                />
                <label htmlFor="createAccountLater" className="ml-3 block text-sm font-medium text-gray-700">
                  {tI('createAccountLater')}
                </label>
              </div>
              {connectedDepositMode === 'createAccountLater' && (
                <div className="flex gap-2">
                  <InformationCircleIcon className="h-6 w-6 text-brandBlue-500 self-start" aria-hidden="true" />
                  {tI('createAccountLaterDescription')}
                </div>
              )}
              <p className="col-start-1 mt-2 text-sm text-red-500" id="connectedDepositMode-error">
                {formState.errors.connectedDepositMode && <span>{formState.errors.connectedDepositMode.message}</span>}
              </p>
            </fieldset>
          </div>
        </div>
      </form>
    </div>
  );
}

PensionAccountData.propTypes = {
  index: PropTypes.number.isRequired,
  updateWorkflowStatus: PropTypes.func.isRequired,
  userInput: PropTypes.object,
  setUserInput: PropTypes.func,
  signalNext: PropTypes.bool,
  setSignalNext: PropTypes.func,
};
PensionAccountData.defaultProps = {
  userInput: {},
  setUserInput: {},
  signalNext: undefined,
  setSignalNext: () => {},
};

export default PensionAccountData;
