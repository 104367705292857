/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Auth, API } from 'aws-amplify';
import jspreadsheet from '@monestry-dev/jspreadsheet-ce';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { setMessage, setAlert } from '../../redux/actions/message';
import getTour from '../../tours/getTour';
import TourWrapperInner from '../../elements/TourWrapper';
import * as common from './params/common';
import executeFormulaPatch from './jspreadsheetExecuteFormulaPatch';
import gridCustomDropdown from './gridCustomDropdown';
import GridAssetMappingDialog from './GridAssetMappingDialog';
import gridConvertGridDataToObject from './gridConvertGridDataToObject';

import '../../css/jspreadsheet.css';

import ContextMenu from './GridContextMenu';

dayjs.extend(utc);

const debugLevel = process.env.MDEBUG || 3;

// this function attempts to automatically create spreadsheet (formatting) masks based on
// the output of toLocaleString for a given locale / currency parameter
// this may not work for exotic formats, has been confirmed to work with EN and DE only
// locale should match the country, not the language
function generateSpreadsheetMaskFromLocale(locale = 'de', currency = 'EUR', returnMode = 'currency') {
  if (process.env.REACT_APP_MDEBUG > 2) console.log('started generateSpreadsheetMaskFromLocale', locale, currency, returnMode);
  // other returnModes: 'decimalPoint' || 'number2Decimals' || 'number0Decimals' || 'number4Decimals'

  const testValue = 1234.567;
  const testString = testValue.toLocaleString(locale, { style: 'currency', currency });
  // if there is anything before first number, this is the currency
  // just put all that on the begining of the mask
  // include the space -- examples: kr 1 234,57 €1,234.57

  const firstInt = testString.search(/\d/);
  let maskPrefix = '';
  let maskSuffix = '';

  // if the first sign is a number, just get all the non-numbers from the end
  // of the string and assume this is the currency code
  // include the space
  if (firstInt !== 0) maskPrefix = testString.substring(0, firstInt);
  else maskSuffix = testString.substring(testString.search(/(\d)(?!.*\d)/) + 1);

  // if there is a space or character between 1 and 2, this is the thousands separator
  // can be a space
  const thousandSeparator = testString.substring(testString.indexOf('1') + 1, testString.indexOf('1') + 2);

  // there can only be a comma or a point between 4 and 5
  const decimalSeparator = testString.substring(testString.indexOf('4') + 1, testString.indexOf('4') + 2);

  // let's put it all together
  const mask = `${maskPrefix}#${thousandSeparator}##0${decimalSeparator}00${maskSuffix}`;
  const maskNumber = `#${thousandSeparator}##0${decimalSeparator}`;

  if (returnMode === 'decimalPoint') return decimalSeparator;
  if (returnMode === 'number0Decimals') return maskNumber.slice(0, -1);
  if (returnMode === 'number2Decimals') return `${maskNumber}00`;
  if (returnMode === 'number4Decimals') return `${maskNumber}0000`;
  if (returnMode === 'currency0Decimals') return `${maskPrefix}#${thousandSeparator}##0${maskSuffix}`;
  return mask;
}

// receives data as array of objects, handlesSave from CategoryWrapper, tableState,
export default function Grid({
  data,
  gridLayout,
  prepAndValidateOutputData,
  postCategoryItems,
  tableState,
  account,
  setAccount,
  displayedComponent,
  setDisplayedComponent,
  userChangesPresent,
  setUserChangesPresent,
  runSave,
  setRunSave,
  customDropdownSources,
  customOnChange,
  saveDialogPromiseCallback,
}) {
  function getColIdxFromLayout(columnId) {
    return gridLayout.findIndex((item) => item.id === columnId);
  }

  // console.log('active element', document.activeElement);

  const { i18n, t } = useTranslation('app', { keyPrefix: 'accountDetails' });
  const dispatch = useDispatch();

  const dashboardMode = useSelector((state) => state.simulation.dashboardMode);
  const baseCurrency = useSelector((state) => state.user.profile.settings.baseCurrency);

  const [dirtyRows, setDirtyRows] = useState(new Set()); // holds the list of rows that have been changed by the user

  // holds the state of the context menu
  const [contextMenu, setContextMenu] = useState({
    visible: false,
    x: 20,
    y: 20,
    clickedRow: 1,
    clickedCol: 1,
  }); // (x,y) are coordinates of the cursor when the right click was triggered (relative to the jSpreadsheet component!)

  const [displaySpinner, setDisplaySpinner] = useState(false); // shows the spinner when user is committing changes

  // set up refs (used to anchor and exchange information with the jspreadsheet component)
  const jRef = useRef(null); // holds the jspreadsheet component
  const rootElement = useRef(null);
  const contextMenuRef = useRef(); // it cannot access the current state and keeps using the initial value
  contextMenuRef.current = contextMenu; // make sure the ref stays equal to the updated state
  const dirtyRowsRef = useRef(); // it cannot access the current state and keeps using the initial value
  dirtyRowsRef.current = dirtyRows; // make sure the ref stays equal to the updated state
  // because the jSpreadsheet component is activated directly in DOM and not through React

  // ---------------------------------------------------
  // EVENT HANDLERS FOR EVENTS OUT OF JSPREADSHEET
  // ---------------------------------------------------

  function handleContextMenu(obj, x, y, e, items, section) {
    if (process.env.REACT_APP_MDEBUG > 2) console.log('triggering ContextMenu handler', obj, x, y, e, items, section);
    // context menu is defined in React (not in jSpreadsheet)
    // the following jSpreadsheet functionality updates contextMenu state object with the coordinates of the context menu
    // and displays it

    // if clientY + 350 is greater than the height of the window, the context menu will be displayed above the cursor
    // if clientY - 350 is less than 0, the context menu will be displayed below the cursor
    const windowHeight = window.innerHeight;
    const yCoordinate = e.clientY > windowHeight - 350 ? e.clientY - 350 : e.clientY;

    setContextMenu({
      ...contextMenuRef.current,
      visible: true,
      x: e.clientX + 4,
      y: yCoordinate + 4,
      clickedRow: y,
      clickedCol: x,
    });

    return false;
  }

  const obj = jRef.current && jRef.current.jspreadsheet;

  function handleOnSelection(element, x, y) {
    if (x === undefined || y === undefined) return;
    if (jRef) {
      setContextMenu({
        ...contextMenuRef.current,
        selectedColumns: jRef.current.jexcel.getSelectedColumns(true),
        selectionHTML: Array.from(document.getElementsByClassName('highlight')).map((el) => el.innerHTML),
      });
      // if cell is in a dropdown column and if the cell is empty, then automatically open editor
      const columnName = jspreadsheet.getColumnNameFromId([x, y]);
      // eslint-disable-next-line no-use-before-define
      if (options.columns[x].type === 'dropdown' && jRef.current.jexcel.getValue(columnName) === '') {
        const cell = jRef.current.jexcel.getCell(columnName);
        // mouseup event after the dropdown is opened cancels the dropdown
        // so delay the opening of the editor by 125ms to allow that event to pass
        setTimeout(() => jRef.current.jexcel.openEditor(cell), 125);
      }
    }
  }

  // executeFormula uses eval -- copied that function locally and patched it unitl there is a permanent fix
  // @monestry-dev/jspreadsheet-ce@4.2.2 has a partial fix (fixes the usage of eval in executeFormula), but the problem still persists
  // (see jspreadsheetFormulaPatch to get you started)
  useEffect(() => {
    if (jRef && jRef.current && jRef.current.jexcel) {
      jRef.current.jexcel.executeFormula = function customExecuteFormula(arg1, arg2, arg3) {
        return executeFormulaPatch(arg1, arg2, arg3, jRef.current.jexcel);
      };
    }
  }, []);

  function handleChange(instance, cell, x, y, value) {
    console.log('Grid > handleChange', instance, cell, x, y, value);
    // cell returns outerHTML of the cell, value returns new value
    const cellName = jspreadsheet.getColumnNameFromId([x, y]);
    jRef.current.jspreadsheet.setStyle(cellName, 'background-color', 'white'); // if it is just "set yellow", it switches yellow on and off
    jRef.current.jspreadsheet.setStyle(cellName, 'background-color', 'rgb(254 249 195 / var(--tw-bg-opacity))');
    setUserChangesPresent(true);

    const updatedDirtyRows = new Set(dirtyRowsRef.current);
    updatedDirtyRows.add(y);
    setDirtyRows(updatedDirtyRows);

    // execute any custom onChange function passed from Wrapper parent components
    if (customOnChange) customOnChange(instance, cell, x, y, value);
  }

  // display incoming errors: if property errorId is truthy, the comment on the corresponding cell in jRef is set to the translated error message
  function handleLoad() {
    // if errorId is truthy (not undefined, '' or null) takes errorIds from column  of the dataset,
    // translates them and puts them into the cell comments of the second column (first may be date / calendar)
    data.forEach((item, row) => {
      if (item.errorId) {
        jRef.current.jexcel.setComments(jspreadsheet.helpers.getColumnNameFromCoords(1, row));
      } // this goes into column 2 only!
    });
    // inherit sorting order from table
    jRef.current.jexcel.orderBy(getColIdxFromLayout(tableState.sortBy), Number(!tableState.sortDirectionAsc)); // true (1) means descending in this function
    // in projects mode, format the isSimulated cells in italics and with light violet background
  }

  function handleDeleteRow(_obj, rowNo, numberOfRows, elements, deletedData) {
    // handle following bug through workaround:
    // right-click a cell in any row (which is not the bottom row) and select delete row from context menu
    // immediatelly right-click the same cell again (new row is there already) to see context menu -- does not work
    // workaround: click anywhere else in spreadsheet after delete
    if (!userChangesPresent) setUserChangesPresent(true); // this capturest the deletion of a row by using Delete key instead of context menu
    jRef.current.jexcel.updateSelectionFromCoords(0, rowNo - 1 < 0 ? 0 : rowNo - 1);
  }

  function handlePaste(object, pastedData) {
    // on every paste command (via context menu or keyboard shortcut)
    jRef.current.jexcel.getSelectedColumns(true).forEach((col) => {
      jRef.current.jexcel.getSelectedRows(true).forEach((row) => {
        const cellName = jspreadsheet.helpers.getColumnNameFromCoords(col, row);
        jRef.current.jexcel.setStyle(cellName, 'background-color', 'white'); // if it is just "set yellow", it switches yellow on and off
        jRef.current.jexcel.setStyle(cellName, 'background-color', 'rgb(254 249 195 / var(--tw-bg-opacity))');
        if (!userChangesPresent) setUserChangesPresent(true);
      });
    });
  }

  // -----------------------------------------------------------------------
  // COLUMN FORMATTER (based on gridLayout)
  // -----------------------------------------------------------------------

  // expects an array of column definitions from gridLayout
  // updates each object in the array with type-specific properties needed for Grid
  // we can't pass customDropdownSources via options (options.* get stripped down to only the known ones), so we need to use columns.*
  function addFormattingToColumns(_gridLayout, _customDropdownSources, secondaryCurrency = null) {
    return _gridLayout.map((col) => {
      // if usesHeader is defined, use it, otherwise use the default column id
      const colLabel = col.usesHeader ?? col.id;
      // add standard properties to each column
      const standardProperties = {
        title: `${t([`columns.${colLabel}.label`, `columns.${colLabel}`])}${col.readOnly ? `\n${t('columns.readOnly')}` : ''}`,
        // conditionally insert tooltip
        ...(i18n.exists(`app:accountDetails.columns.${colLabel}.tooltip`) && { tooltip: t(`columns.${colLabel}.tooltip`) }),
        width: (col.width || tableState.colWidths?.[col.id]) ?? 100,
        // condditionally add read-only
        ...(col.readOnly && { readOnly: true }),
      };

      // add specific properties to each column by its .type
      switch (col.type) {
        case 'date':
          return {
            ...standardProperties,
            ...common.calendarColumnAttributes,
          };
        case 'string':
          return {
            ...standardProperties,
            type: 'text',
            wordWrap: true,
            wrap: true,
            align: 'left',
          };
        case 'currency0Decimals':
          return {
            ...standardProperties,
            type: 'number',
            align: 'right',
            mask: generateSpreadsheetMaskFromLocale(i18n.language, baseCurrency, 'currency0Decimals'),
            decimal: generateSpreadsheetMaskFromLocale(i18n.language, baseCurrency, 'decimalPoint'),
          };
        case 'currency':
          return {
            ...standardProperties,
            type: 'number',
            align: 'right',
            mask: generateSpreadsheetMaskFromLocale(i18n.language, baseCurrency, 'currency'),
            decimal: generateSpreadsheetMaskFromLocale(i18n.language, baseCurrency, 'decimalPoint'),
          };
        case 'secondaryCurrency0Decimals':
          return {
            ...standardProperties,
            type: 'number',
            align: 'right',
            mask: generateSpreadsheetMaskFromLocale(i18n.language, secondaryCurrency, 'currency0Decimals'),
            decimal: generateSpreadsheetMaskFromLocale(i18n.language, secondaryCurrency, 'decimalPoint'),
          };
        case 'secondaryCurrency':
          return {
            ...standardProperties,
            // type: 'number',
            // type: 'text',
            type: 'numeric',
            align: 'right',
            mask: generateSpreadsheetMaskFromLocale(i18n.language, secondaryCurrency, 'currency'),
            decimal: generateSpreadsheetMaskFromLocale(i18n.language, secondaryCurrency, 'decimalPoint'),
          };
        case 'dropdown':
          return {
            ...standardProperties,
            type: 'dropdown',
            align: 'center',
            source: col.source,
            autocomplete: true,
          };
        // same as 'dropdowm', but we add source when we render Grid and not when we start the app
        case 'dropdownDynamic': {
          const source = _customDropdownSources[col.id];
          if (!source || source.length === 0) console.warn('Grid > addFormattingToColumns: no source found for dynamic dropdown column', col.id, 'in customDropdownSources', customDropdownSources);
          return {
            ...standardProperties,
            type: 'dropdown',
            align: 'center',
            source,
            autocomplete: true,
          };
        }
        case 'customDropdown': {
          // get the right source list from customDropdownSources
          const source = _customDropdownSources[col.id];
          if (!source || source.length === 0) console.warn('Grid > addFormattingToColumns: no source found for custom dropdown column', col.id, 'in customDropdownSources', customDropdownSources);
          return {
            ...standardProperties,
            type: 'text',
            align: 'left',
            editor: gridCustomDropdown,
            source,
          };
        }
        case 'number0Decimals':
          return {
            ...standardProperties,
            type: 'number',
            align: 'right',
            mask: generateSpreadsheetMaskFromLocale(i18n.language, baseCurrency, 'number0Decimals'),
            decimal: generateSpreadsheetMaskFromLocale(i18n.language, baseCurrency, 'decimalPoint'),
          };
        case 'number':
          return {
            ...standardProperties,
            type: 'number',
            align: 'right',
            mask: generateSpreadsheetMaskFromLocale(i18n.language, baseCurrency, 'number2Decimals'),
            decimal: generateSpreadsheetMaskFromLocale(i18n.language, baseCurrency, 'decimalPoint'),
          };
        case 'number4Decimals':
          return {
            ...standardProperties,
            type: 'number',
            align: 'right',
            mask: generateSpreadsheetMaskFromLocale(i18n.language, baseCurrency, 'number4Decimals'),
            decimal: generateSpreadsheetMaskFromLocale(i18n.language, baseCurrency, 'decimalPoint'),
          };
        case 'checkbox':
          return {
            ...standardProperties,
            type: 'checkbox',
            align: 'center',
          };
        case 'hidden':
        default:
          if (col.type !== 'hidden') console.warn('Grid > addFormattingToColumns: unknown column type', col.type);
          return { ...standardProperties, type: 'hidden' };
      }
    });
  }

  // -----------------------------------------------------------------------
  // ESCAPE KEY HANDLER
  // -----------------------------------------------------------------------

  // close context menu when Esc is pressed anywhere in the document
  const handleEscPress = useCallback((event) => {
    if (contextMenuRef.current.visible && event.key === 'Escape') {
      event.stopImmediatePropagation();
      setContextMenu({ ...contextMenuRef.current, visible: false });
    }
  }, []);

  // intialise: add event listener for Esc key; reset userChangesPresent; remove the default alert function (because it got triggered when deleting a row using Del)
  useEffect(() => {
    window.confirm = () => true;
    // normally window.confirm displays an OK / Cancel dialog and is used in jSpreadsheet to display among others "are you sure you want to delete this row", which we don't really want
    // this has been overridden here to return true immediatelly; handleDeleteRow will then set userChangesPresent to true

    document.addEventListener('keydown', handleEscPress, false);
    setUserChangesPresent(false);

    return () => {
      document.removeEventListener('keydown', handleEscPress, false);
    };
  }, []);

  // -----------------------------------------------------------------------
  // OPTIONS FOR JSPREADSHEET
  // -----------------------------------------------------------------------

  // standard set of options to be partially overwritten by whatever is needed for different categories (params)
  const options = {
    data, // array of objects is ok as input
    editable: dashboardMode !== 'projects',
    minDimensions: [6, 20],
    minSpareRows: 1,
    tableHeight: '100%',
    tableWidth: '100%',
    tableOverflow: true,
    columnDrag: false,
    allowInsertColumn: false,
    allowManualInsertColumn: false,
    allowDeleteColumn: false,
    allowRenameColumn: false,
    contextMenu: handleContextMenu,
    onselection: handleOnSelection,
    onchange: handleChange,
    onload: handleLoad,
    onpaste: handlePaste,
    ondeleterow: handleDeleteRow,
    columns: addFormattingToColumns(gridLayout, customDropdownSources, account?.currency),
  };

  // FIXME this only has to run once, but it is run every time the component is rendered
  // in project mode calculate which rows are simulated and format them violet
  // expected format [ { A1: 'background-color: orange; ' }, ... ]
  const letters = [];
  let cursor = 0;
  while (cursor < options.columns.length) {
    if (options.columns[cursor].type !== 'hidden') letters.push(String.fromCharCode(65 + cursor)); // generates an array of capital letters starting with ASCII code 65 (A)
    cursor += 1;
  }
  const style = (data || []).reduce(
    // eslint-disable-next-line max-len
    (acc, row, index) => (row.isSimulated ? { ...acc, ...letters.reduce((prev, curr) => ({ ...prev, [`${curr}${index + 1}`]: 'background-color: rgb(248 240 250); font-style: italic; ' }), {}) } : { ...acc }),
    {},
  );

  options.style = style;

  // -----------------------------------------------------------------------
  // INITIALISE JSPREADSHEET
  // -----------------------------------------------------------------------

  // initialise jspreadsheet at the jRef ref
  useEffect(() => {
    console.log('Grid > options:', options);
    if (!jRef.current.jspreadsheet && options.data) {
      jspreadsheet(jRef.current, options);

      // add tooltips to the headers
      // it has the title property (set to column name), so we will just overwrite it with the tooltip
      const headers = document.querySelectorAll('.jexcel thead tr td');
      // console.log('DEBUG headers', headers);
      // const tooltips = ['Tip for A', 'Tip for B', 'Tip for C'];

      headers.forEach((header, index) => {
        // index starts at 0, which is the row number column, so we need to skip it
        if (index === 0) return;
        // check if there is a tooltip; if there isn't, don't change anything
        if (options.columns[index - 1]?.tooltip) {
          header.setAttribute('title', options.columns[index - 1].tooltip);
        }
      });

      // additionally add an event listener for jexcel_container because the keydown event originates from the jexcel_container
      // so it will be triggered BEFORE the document-level JSuites element which prevents other events from being triggered
      document.getElementsByClassName('jexcel_container')[0].addEventListener('keydown', handleEscPress, false);
    }

    return () => {
      document.getElementsByClassName('jexcel_container')[0]?.removeEventListener('keydown', handleEscPress, false);
    };
  }, [data]);

  // -----------------------------------------------------------------------
  // HANDLE SAVE
  // -----------------------------------------------------------------------

  // does some internal validation and then calls handleSave
  // if there is responseDialog, it means that the user was asked to provide some additional data (like asset mapping for Cryptos)
  // responseDialog is an array containing the same number of items (rows) as the outputData, but only a subset of fields,
  // for example only { assetId, displaySymbol, providerAssetId } for Cryptos, so it needs to be spread over the outputData
  function prepAndValidateOutputDataWrapper(responseDialog = undefined) {
    // get data from the spreadsheet object and send them on their merry way
    const outputData = jRef.current.jspreadsheet.getData();

    // now run the prepAndValidateOutputData function from CategoryWrapper
    // (it will run general transformations and category-specific ones from accountDetails/transactions or /quotes)
    // send gridLayout back to CategoryWrapper, because it is it's determined in its child, so CategoryWrapper doesn't know about it
    const prepAndValidateResult = prepAndValidateOutputData(outputData, gridLayout, dirtyRows, responseDialog);

    if (debugLevel > 2) console.log('Grid > prepAndValidateOutputDataWrapper: prepAndValidateResult', prepAndValidateResult);

    if (prepAndValidateResult.status === 'error') {
      setRunSave(false);
      const validationErrors = prepAndValidateResult.errors;

      // clear all old errors (comments)
      // TODO if previous data had more rows, the comments will not get cleaned
      try {
        outputData.forEach((row, rowIdx) => {
          row.forEach((cell, colIdx) => {
            // clear all comments
            jRef.current.jexcel.setComments(jspreadsheet.helpers.getColumnNameFromCoords(colIdx, rowIdx), '');
          });
        });

        // for each error get row and column and put error in comments
        // the thrown error is error message from yup and path to error like [row].fieldName (e.g. This is an error, [0].otherParty)
        console.log('validationErrors', validationErrors);

        validationErrors.forEach((err) => {
          console.log('Validation error:', err.message);

          const rowNo = err.path.split('.')[0].replace(/\D+/g, '') || 0;
          const columnNo = getColIdxFromLayout(err.path.split('.')[1]);

          console.log('rowNo', rowNo, 'columnNo', columnNo);
          console.info('Row causing the error:', prepAndValidateResult[rowNo]);

          // if column has been found in Grid (is a number and is greater or equal 0) and that column is visible, then set the comment there
          // otherwise set it in the first visible column
          if ((!!columnNo || columnNo === 0) && columnNo >= 0 && options.columns[columnNo]?.type !== 'hidden') {
            jRef.current.jexcel.setComments(jspreadsheet.helpers.getColumnNameFromCoords(columnNo, rowNo), err.message);
          } else {
            console.info('Column for the above error is not displayed in Grid');
            // find first column which is not hidden
            const firstNotHiddenColumnNo = options.columns.findIndex((c) => c.type !== 'hidden');
            jRef.current.jexcel.setComments(jspreadsheet.helpers.getColumnNameFromCoords(firstNotHiddenColumnNo, rowNo), err.message);
          }
        });
        // throw new Error('localValidationFailed');
        // show message to user
        dispatch(setMessage('checkYourData'));
        setDisplaySpinner(false);
      } catch (err) {
        throw new Error(`Grid > prepAndValidateOutputDataWrapper: unhandled error ${err.message}`, err);
      }
    }

    if (prepAndValidateResult.status === 'success') {
      return prepAndValidateResult; // { status, data }
    }
    return { status: 'error' };
  }

  // this gets called a little later, read on...
  async function handleSave(validatedData) {
    console.log('Grid > starting handleSave');
    try {
      setDisplaySpinner(true);
      const response = await dispatch(postCategoryItems(validatedData, account));
      setDisplaySpinner(false);
      console.log('Grid: postData response', response);
      // response.meta.requestStatus is used in quotes, it is the "redux-toolkit" way of doing things
      if (response?.status === 'success' || response?.meta?.requestStatus === 'fulfilled') {
        setUserChangesPresent(false);
        setDisplayedComponent('table');
        return;
      }
      dispatch(setMessage('dataUpdateProblems')); // if we got here, it means that was not a 'success' response
      console.error('Grid: handleSave ran aground.');
    } catch (err) {
      console.error('Grid: error in dispatch', err);
      setDisplaySpinner(false);
      dispatch(setMessage('dataUpdateProblems'));
    }
  }

  // passes jRef and gridLayout to the dialog promise callback
  function createDialogPromise() {
    console.log('DEBUG createDialogPromise - saveDialogPromiseCallback is', saveDialogPromiseCallback);
    return new Promise((resolve, reject) => {
      saveDialogPromiseCallback(resolve, reject, jRef, gridLayout);
    });
  }

  // ---------------------------------------------------
  // SUBSCRIBE TO runSave EVENT FROM BUTTON_BAR
  // ---------------------------------------------------

  // ButtonBar will set the runSave flag to true when the user clicks Save
  useEffect(() => {
    if (debugLevel > 2) console.log('Grid > runSave signal received', runSave, 'userChangesPreset', userChangesPresent, 'displayedComponent', displayedComponent);

    // if the user has not made any changes, save and close (exception: for displayedComponent: 'duplicate', we accept also no changes here)
    if (runSave === true && !userChangesPresent && displayedComponent !== 'duplicate') {
      setRunSave(false); // reset the signal
      setDisplayedComponent('table');
    }

    if (runSave === true && (userChangesPresent || displayedComponent === 'duplicate')) {
      // if we need to ask user something before validating and saving data, this is where it happens
      // this promise shows a dialog and sends back updated data when user clicks ok
      // if there is no saveDialogPromiseCallback, the default function from propTypes will resolve to null (responseDialog will be null)
      createDialogPromise().then(
        (responseDialog) => {
          console.log('DEBUG responseDialog returns', responseDialog);
          // if dialog is not necessary for this workflow, responseDialog will be null;
          // otherwise it will be an array of the same length as the grid data with objects { assetId, displaySymbol, providerAssetId }
          // because responseDialog is just a subset of the data, so we will pass it to prepAndValidate...
          // to be spread over the converted all data object

          // get data from the spreadsheet object, transform back to their original structure and validate them
          // if prepAndValidate... receives an non-empty argument, it knows it has to spread it over the data
          const resultsPrepAndValidate = responseDialog ? prepAndValidateOutputDataWrapper(responseDialog) : prepAndValidateOutputDataWrapper();
          if (debugLevel > 2) console.log('Grid > runSave: resultsPrepAndValidate', resultsPrepAndValidate);
          setRunSave(false); // reset the signal

          if (resultsPrepAndValidate?.status === 'error') {
            dispatch(setMessage('checkYourData'));
          }
          if (resultsPrepAndValidate?.status === 'success') {
            dispatch(setAlert('aboutToSave', account.id, () => handleSave(resultsPrepAndValidate.data))); // if the user presses OK, it will start handleSave
          }
        },
        // handle error from waiting for the dialog
        (errorDialog) => {
          console.error('Grid > runSave: error in dialog promise', errorDialog);
          dispatch(setMessage('checkYourData'));
          setRunSave(false);
        },
      );
    }
  }, [runSave]);

  // ---------------------------------------------------
  // (METALS ONLY) HANDLE GET PURCHASE VALUE
  // ---------------------------------------------------

  useEffect(() => {
    async function getPurchaseValues() {
      try {
        // get data from spreadsheet (getData, returns array of array)
        const jData = jRef.current.jspreadsheet.getData();

        // convert data to object
        const columnNames = gridLayout.map((column) => column.id);
        const jDataObj = jData.map((row) => {
          const newRow = {};
          row.forEach((value, index) => {
            const columnName = columnNames[index];
            newRow[columnName] = value;
          });
          return newRow;
        });

        // identify which rows have all data (date, assetWeight, assetPurity, evtl. assetAdditionalValue || 0) and no price yet
        const quotesToGet = { assets: [], dates: [] };
        jDataObj.forEach((row, index) => {
          const { date, assetMetal, assetPurity, assetWeight, assetAdditionalValue, upac, transactionValue, uptc, transactionCurrencyValue } = row;
          if (date && assetMetal && assetPurity && assetWeight && !upac && !transactionValue && !uptc && !transactionCurrencyValue) {
            // criteria fulfilled, let us get the quote
            // TODO assetId index is all right?
            quotesToGet.assets.push({
              assetId: `row${index}`,
              providerAssetId: { assetMetal, assetMetalWeight: assetWeight * (assetPurity / 1000), assetAdditionalValue },
              currency: account.currency,
            });
            quotesToGet.dates.push(dayjs.utc(date).startOf('day').valueOf());
          }
        });

        // get quotes for that data (can be via direct api call, so that we bypass redux)
        let result;
        if (quotesToGet.assets.length > 0) {
          const session = await Auth.currentSession();
          const payload = {
            body: quotesToGet,
            headers: {
              'Content-Type': 'application/json',
              Authorization: session.idToken.jwtToken,
            },
          };

          result = await API.post('myAPI', 'quotes/get', payload); // getQuotes API expects { assets: [{ assetId, providerAssetId }], dates: [dates]}
          console.log('DEBUG result', result);

          // run setValue for the rows above
          result.quotes.forEach((quote, index) => {
            if (quote.quote) {
              const rowIndex = quote.assetId.split('row')[1];
              jRef.current.jexcel.setValueFromCoords(getColIdxFromLayout('upac'), rowIndex, quote.quote * ((account.spread || 0) / 100 + 1)); // purchase price, so with spread on top
              jRef.current.jexcel.setValueFromCoords(getColIdxFromLayout('uptc'), rowIndex, quote.quote * ((account.spread || 0) / 100 + 1));
              jRef.current.jexcel.setValueFromCoords(getColIdxFromLayout('transactionCurrency'), rowIndex, quote.currency);
            }
          });
        } else {
          dispatch(setMessage('gridGetPurchaseValuesNoRows'));
        }
        // if all rows had data and were updated
        window.dispatchEvent(new CustomEvent('handleGridGetPurchaseValuesCompleted', { outcome: (result.quotes || []).length > jData.length ? 'allRowsUpdated' : 'someRowsUpdated' }));
      } catch (error) {
        console.error('Grid > getPurchaseValue error', error);
        window.dispatchEvent(new CustomEvent('handleGridGetPurchaseValuesCompleted', { outcome: 'error' }));
      }
    }

    if (account.category === 'metals') window.addEventListener('gridGetPurchaseValues', getPurchaseValues);

    return () => {
      if (account.category === 'metals') window.removeEventListener('gridGetPurchaseValues', getPurchaseValues);
    };
  }, [data]);

  return (
    <>
      <section className="pt-2 grow flex flex-col overflow-hidden w-full relative" aria-label="context menu" id="contextmenu">
        {displaySpinner && (
          <div className="absolute bg-gray-50/50 transition duration-700 w-full h-full z-30" id="synchronisingspinner">
            <div className="flex h-full items-center justify-center">
              <button
                // eslint-disable-next-line max-len
                className="-mt-2 inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-md text-white bg-brandBlue-500 hover:bg-brandBlue-400 transition ease-in-out duration-150 cursor-not-allowed"
                type="button"
              >
                <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white text-base" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
                </svg>
                {t('synchronisingChanges')}
              </button>
            </div>
          </div>
        )}
        <div
          className="z-20 h-full relative inline-block text-left"
          onClick={(e) => {
            setContextMenu({ ...contextMenuRef.current, visible: false });
          }}
          ref={rootElement}
          role="button"
          tabIndex="0"
        >
          {/* JSPREADSHEET IS ATTACHED HERE */}
          <div className="h-full" id="account-details-grid-spreadsheet">
            <div className="h-full w-full" ref={jRef} />
            <br />
          </div>
          {/* CONTEXT MENU */}
          <ContextMenu
            obj={obj}
            jRef={jRef}
            contextMenu={contextMenu}
            setContextMenu={setContextMenu}
            userChangesPresent={userChangesPresent}
            setUserChangesPresent={setUserChangesPresent}
            gridLayout={gridLayout}
            accountName={account.name}
          />
        </div>
      </section>
      <TourWrapperInner localTours={getTour('accountDetailsGrid')} />
    </>
  );
}

Grid.propTypes = {
  tableState: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  gridLayout: PropTypes.array.isRequired,
  prepAndValidateOutputData: PropTypes.func.isRequired,
  postCategoryItems: PropTypes.func.isRequired,
  account: PropTypes.objectOf(PropTypes.any).isRequired,
  setAccount: PropTypes.func.isRequired,
  displayedComponent: PropTypes.string.isRequired,
  setDisplayedComponent: PropTypes.func.isRequired,
  userChangesPresent: PropTypes.bool.isRequired,
  setUserChangesPresent: PropTypes.func.isRequired,
  runSave: PropTypes.bool.isRequired,
  setRunSave: PropTypes.func.isRequired,
  customDropdownSources: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)),
  customOnChange: PropTypes.func,
  saveDialogPromiseCallback: PropTypes.func,
};
Grid.defaultProps = {
  customDropdownSources: {},
  customOnChange: () => {},
  saveDialogPromiseCallback: (resolve) => resolve(null),
};
