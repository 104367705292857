import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import getProjectTemplates from './getProjectTemplates';

export default function TemplateList({ setSelectedTemplate }) {
  const tiles = useMemo(() => getProjectTemplates(), []);

  return (
    <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
      {tiles.map((tile) => (
        <li
          key={tile.id}
          id={`project-details-select-template-${tile.id}`}
          className="col-span-1 flex flex-col divide-y divide-gray-200 border rounded-lg bg-white text-center shadow group hover:shadow-lg"
        >
          <div className="flex flex-1 flex-col p-8">
            <h3 className="mt-6 mb-6 text-md tracking-wider uppercase font-bold text-gray-900">{tile.name}</h3>
            <dl className="mt-1 flex flex-grow flex-col justify-between">
              <dt className="sr-only">Description</dt>
              <dd className="text-sm text-gray-500">{tile.description}</dd>
              <dt className="sr-only">Role</dt>
            </dl>
          </div>
          <div>
            <div className="-mt-px flex divide-x divide-gray-200">
              <div className="flex w-0 flex-1">
                <button
                  type="button"
                  onClick={() => { setSelectedTemplate(tile); }}
                  className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-b-lg border border-transparent py-4 text-base text-white font-normal bg-brandBlue-500 group-hover:bg-brandBlue-600"
                >
                  Select
                </button>
              </div>
            </div>
          </div>
        </li>
      ))}
    </ul>
  );
}
TemplateList.propTypes = {
  setSelectedTemplate: PropTypes.func.isRequired,
};
