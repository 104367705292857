import React from 'react';
import PropTypes from 'prop-types';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function PaymentTypeButton({ onClick, active, label }) {
  return (
    <button
      type="button"
      onClick={onClick}
      className={classNames(
        (active)
          ? 'bg-white border-gray-200 shadow-sm text-gray-900'
          : 'border border-transparent text-gray-700 ',
        'relative w-1/2 rounded-md py-2 text-sm font-medium whitespace-nowrap focus:outline-none focus:ring-2 focus:ring-brandBlue-500 focus:z-10 sm:w-auto sm:px-8',
      )}
    >
      {label}
    </button>
  );
}
PaymentTypeButton.propTypes = {
  label: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};
