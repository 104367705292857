/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  ComputerDesktopIcon, BuildingLibraryIcon, PresentationChartLineIcon, HomeIcon, BanknotesIcon, BriefcaseIcon,
  GiftIcon, BoltIcon, ArrowsRightLeftIcon,
} from '@heroicons/react/24/outline';
import Button from './Button';

export default function FeaturesSection1() {
  const { t, i18n } = useTranslation(['site']);
  const navigate = useNavigate();

  const features = [
    {
      name: `${t('site:features.section1.all-classes.bank.header')}`,
      description: `${t('site:features.section1.all-classes.bank.body')}`,
      icon: BuildingLibraryIcon,
    },
    {
      name: `${t('site:features.section1.all-classes.stocks.header')}`,
      description: `${t('site:features.section1.all-classes.stocks.body')}`,
      icon: PresentationChartLineIcon,
    },
    {
      name: `${t('site:features.section1.all-classes.real-estate.header')}`,
      description: `${t('site:features.section1.all-classes.real-estate.body')}`,
      icon: HomeIcon,
    },
    {
      name: `${t('site:features.section1.all-classes.pension.header')}`,
      description: `${t('site:features.section1.all-classes.pension.body')}`,
      icon: BanknotesIcon,
    },
    {
      name: `${t('site:features.section1.all-classes.unlisted.header')}`,
      description: `${t('site:features.section1.all-classes.unlisted.body')}`,
      icon: BriefcaseIcon,
    },
    {
      name: `${t('site:features.section1.all-classes.crypto.header')}`,
      description: `${t('site:features.section1.all-classes.crypto.body')}`,
      icon: BoltIcon,
    },
    {
      name: `${t('site:features.section1.all-classes.objects.header')}`,
      description: `${t('site:features.section1.all-classes.objects.body')}`,
      icon: GiftIcon,
    },
    {
      name: `${t('site:features.section1.all-classes.loans.header')}`,
      description: `${t('site:features.section1.all-classes.loans.body')}`,
      icon: ArrowsRightLeftIcon,
    },
  ];

  return (
    <div id="one-glance" className="pt-16 bg-gray-50 overflow-hidden lg:pt-20">
      <div className="relative max-w-xl mx-auto pb-20 py-4 sm:px-6 lg:px-8 lg:max-w-7xl">
        <svg
          className="hidden lg:block absolute left-full transform -translate-x-1/2 -translate-y-1/4"
          width={404}
          height={784}
          fill="none"
          viewBox="0 0 404 784"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="b1e6e422-73f8-40a6-b5d9-c8586e37e0e7"
              x={0}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
            </pattern>
          </defs>
          <rect width={404} height={784} fill="url(#b1e6e422-73f8-40a6-b5d9-c8586e37e0e7)" />
        </svg>

        <div className="relative">
          <h2 className="text-center text-3xl leading-8 font-black tracking-tight text-gray-900 sm:text-5xl">
            {t('site:features.section1.header')}
            {' '}
            <span className="text-brandBlue-500">{t('site:features.section1.header-accent')}</span>
          </h2>
          <p className="mt-4 max-w-2xl mx-auto text-center text-xl text-gray-500">
            {t('site:features.section1.body')}
          </p>
        </div>
      </div>

      {/* <div className="relative bg-white pt-16 pb-32 overflow-hidden"> */}
      <div className="relative pb-8">
        <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
          <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0">
            <div>
              <div>
                <span className="h-12 w-12 rounded-md flex items-center justify-center bg-brandBlue-500">
                  <ComputerDesktopIcon className="h-6 w-6 text-white" aria-hidden="true" />
                </span>
              </div>
              <div className="mt-6">
                <h2 className="text-3xl font-black tracking-tight text-gray-900">
                  {t('site:features.section1.one-glance.title')}
                </h2>
                <p className="mt-4 text-lg text-gray-500">
                  {t('site:features.section1.one-glance.body')}
                </p>
                <div className="mt-6">
                  <Button
                    text={t('site:general.startDemo')}
                    onClick={() => navigate(`/${i18n.language}/demo`)}
                    withAccent
                  />
                </div>
              </div>
            </div>
            <div className="mt-8 border-t border-gray-200 pt-6">
              <blockquote>
                <div>
                  <p className="text-base text-gray-500">
                    {t('site:features.section1.one-glance.quote')}
                  </p>
                </div>
                <footer className="mt-3">
                  <div className="flex items-center space-x-3">
                    <div className="flex-shrink-0">
                      <img
                        className="h-6 w-6 rounded-full"
                        src="https://images.unsplash.com/photo-1509783236416-c9ad59bae472?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80"
                        alt=""
                      />
                    </div>
                    <div className="text-base font-medium text-gray-700">{t('site:features.section1.one-glance.person')}</div>
                  </div>
                </footer>
              </blockquote>
            </div>
          </div>
          <div className="mt-12 sm:mt-16 lg:mt-0">
            <div className="pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
              <img
                className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                src="https://tailwindui.com/img/component-images/inbox-app-screenshot-1.jpg"
                alt="Inbox user interface"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="bg-brandBlue-700 my-8">
        <div className="max-w-4xl mx-auto px-4 py-16 sm:px-6 sm:pt-20 sm:pb-24 lg:max-w-7xl lg:pt-24 lg:px-8">
          <h2 className="text-3xl font-black text-white tracking-tight">{t('site:features.section1.all-classes.header')}</h2>
          <p className="mt-4 max-w-3xl text-lg text-indigo-200">
            {t('site:features.section1.all-classes.body')}
          </p>
          <div className="mt-12 grid grid-cols-1 gap-x-6 gap-y-12 sm:grid-cols-2 lg:mt-16 lg:grid-cols-4 lg:gap-x-8 lg:gap-y-16">
            {features.map((feature) => (
              <div key={feature.name}>
                <div>
                  <span className="flex items-center justify-center h-12 w-12 rounded-md bg-white bg-opacity-10">
                    <feature.icon className="h-6 w-6 text-white" aria-hidden="true" />
                  </span>
                </div>
                <div className="mt-6">
                  <h3 className="text-xl font-medium text-white">{feature.name}</h3>
                  <p className="mt-2 text-base text-indigo-200">{feature.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
