/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Button from '../../elements/Button';

export default function FilePreview({ bgColour, previewState, setPreviewState, fileObject }) {
  const { t } = useTranslation(['app']);

  // display on right if previewState clickX is more than half the width of the screen
  const showOnRight = previewState.clickX < window.innerWidth / 2;
  const showOnTop = previewState.clickY > window.innerHeight / 2;

  const isNarrowDisplay = window.innerWidth < 1024;

  // to find the index of headerId property, map the .headerId property of all objects in fileObject.headers into a new array and run indexOf on that
  // targetId is set there by handleMouseEnter based on the id of the button that is hovered over
  const currentColumnId = fileObject.headers.map((obj) => obj.id).indexOf(previewState.targetId);

  // if there are more than 7 columns in fileObject, only show 3 to the left of the current column and 3 to the right of the current column
  const headers = fileObject.headers.length > 7 ? fileObject.headers.slice(Math.max(0, currentColumnId - 3), currentColumnId + 4) : fileObject.headers;

  // determine rows to be displayed
  // if there are more than 7 rows in fileObject, only show 3 to the top of the current row and 3 to the bottom of the current row
  const displayedRows = fileObject.rows.length > 7
    ? fileObject.rows
      .slice(0, 4)
      .concat([new Array(fileObject.rows[3].length).fill('...')])
      .concat(fileObject.rows.slice(-4)) // display 4 rows from the top and 4 rows from the bottom, separated by ellipses
    : fileObject.rows;

  const trimmedRows = displayedRows
    // remove the same columns from each row that were removed from the headers
    .map((row) => (fileObject.headers.length > 7 ? row.slice(Math.max(0, currentColumnId - (isNarrowDisplay ? 0 : 3)), currentColumnId + (isNarrowDisplay ? 1 : 4)) : row))
    .map((row) => Array.from(row).map((col) => col || ' '));
  // Array.from(row) will replace holes in array with undefined, otherwise map will not include them
  // replace empty array items with ' ' (otherwise the table will not render properly)

  return (
    <div className={`absolute m-0 sm:m-4 py-1.5 sm:py-3 px-1 sm:px-2 bg-white border shadow rounded-md max-w-fit z-[999] ${showOnRight ? 'right-0' : 'left-0'} ${showOnTop ? 'top-0' : 'bottom-0'}`}>
      <div className="flex">
        {currentColumnId > 3 ? <div className="pl-1 pr-2 self-center">&hellip;</div> : null}
        <table className="font-mono tracking-tighter text-xs sm:text-sm">
          <thead>
            <tr>
              {headers.map((col, idx2) => (
                <td key={col.id} className={`${(col.id === previewState.targetId ? bgColour : '', 'px-3')}`}>
                  {col.label}
                </td>
              ))}
            </tr>
          </thead>
          <tbody>
            {trimmedRows.map((row, idx) => (
              <tr key={idx} className="">
                {/* vv replace empty array items with '' */}
                {row.map((col, idxMap) => (
                  <td key={idxMap} className={`px-3 truncate ${idxMap === Math.min(3, currentColumnId) ? bgColour : ''}`}>
                    {col}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
        {currentColumnId < fileObject.headers.length - 4 ? <div className="pl-2 pr-1 self-center">&hellip;</div> : null}
      </div>
      <Button text={t('accountDetails.buttons.close.label')} onClick={() => setPreviewState(null)} size="sm" formatting="m-2" />
    </div>
  );
}
FilePreview.propTypes = {
  bgColour: PropTypes.string,
  previewState: PropTypes.shape({
    clickX: PropTypes.number,
    clickY: PropTypes.number,
    marginTop: PropTypes.number,
    marginLeft: PropTypes.number,
    marginRight: PropTypes.number,
    targetId: PropTypes.string,
  }).isRequired,
  setPreviewState: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  fileObject: PropTypes.objectOf(PropTypes.any).isRequired,
};
FilePreview.defaultProps = {
  bgColour: 'bg-gray-100',
  setPreviewState: undefined,
};
