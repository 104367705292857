/* eslint-disable max-len */
// import { nanoid } from 'nanoid';
import dayjs from 'dayjs';
// eslint-disable-next-line import/extensions
import utc from 'dayjs/plugin/utc.js';
import getCurrencyDecimalPlaces from '../../../../misc/getCurrencyDecimalPlaces';
// import i18n from '../../../../i18n';

dayjs.extend(utc);

// expects an array of { effectiveDate, value } objects
// returns the currently applicable annual interest rate in percent (e.g. 4.5 and not 0.045)
function getCurrentInterestRate(currentDate, interestRateArray) {
  if (interestRateArray.length < 1) throw new Error('getSimulatedRepaymentSchedule > getCurrentInterestRate: interestRateArray is empty');
  const interestRate = interestRateArray.filter((x) => x.effectiveDate <= currentDate).sort((a, b) => b.effectiveDate - a.effectiveDate)[0]; // DESC, we want to find the most recent interest rate
  if (!interestRate) {
    console.warn('getSimulatedRepaymentSchedule > getCurrentInterestRate: interestRate for period', dayjs.utc(currentDate).format('YYYY-MM-DD'), 'not found. Using first available interest rate.');
    return interestRateArray.sort((a, b) => a.effectiveDate - b.effectiveDate)[0].value; // ASC
  }
  return interestRate.value;
}

// expects an array of { effectiveDate, value } objects
// returns the currently applicable length of period in months
function getCurrentPeriodNum(currentDate, periodArray) {
  if (periodArray.length < 1) throw new Error(' aymentSchedule > getCurrentPeriodNum: periodArray is empty');
  const period = periodArray.filter((x) => x.effectiveDate <= currentDate).sort((a, b) => b.effectiveDate - a.effectiveDate)[0]; // DESC, we want to find the most recent
  if (!period) {
    console.warn('getSimulatedRepaymentSchedule > getCurrentPeriodNum: period for period', dayjs.utc(currentDate).format('YYYY-MM-DD'), 'not found. Using first available period.');
    return periodArray.sort((a, b) => a.effectiveDate - b.effectiveDate)[0].value; // ASC
  }
  switch (period.value) {
    case 'month':
      return 12;
    case 'quarter':
      return 4;
    case 'annual':
      return 1;
    default:
      throw new Error('getSimulatedRepaymentSchedule: Invalid period');
  }
}

// expects an array of { effectiveDate, value } objects
// returns the currently applicable percent repaid annually (Tilgung)
function getCurrentPercentRepaidAnnually(currentDate, periodArray) {
  if (periodArray.length < 1) throw new Error('getSimulatedRepaymentSchedule > getCurrentPercentRepaidAnnually: periodArray is empty');
  const pra = periodArray.filter((x) => x.effectiveDate <= currentDate).sort((a, b) => b.effectiveDate - a.effectiveDate)[0]; // DESC
  if (!pra) {
    console.warn('getSimulatedRepaymentSchedule > getCurrentPercentRepaidAnnually: period for period', dayjs.utc(currentDate).format('YYYY-MM-DD'), 'not found. Using first available period.');
    return periodArray.sort((a, b) => a.effectiveDate - b.effectiveDate)[0].value; // ASC
  }
  return pra.value;
}

function getAnnuity(annualInterestRate, loanAmount, percentRepaidAnnually, periodNum) {
  const firstInterestPayment = (loanAmount * (annualInterestRate / 100)) / periodNum;
  const firstPrincipalPayment = (loanAmount * (percentRepaidAnnually / 100)) / periodNum;
  return firstInterestPayment + firstPrincipalPayment;
}

/**
 *
 * Function to calculate a repayment schedule with a variety of options.
 *
 * @param {number} startDate - first month of the schedule
 * @param {number} outstandingPrincipal - outstanding loan balance (what still remains to be repaid on principal); may be different from loanAmount if there are past repayments already
 * @param {object} loanAccount object
 * @param {array} plannedExtraRepayments - planned (simulated) “out-of-schedule” repayments (expects objects with date and quantity)
 *
 * @returns {object} { schedule, sumCustomRepayment, sumInterestPaid, totalAmount } - schedule for each period and calculated totals (NOT in transaction format);
 * @returns {array} schedule { date <dayjs>, installmentDate <MM-YYYY>, interestRate, periodNum, percentRepaidAnnually, openingBalance, currentPrincipal, currentInterest, currentRepayment, outOfScheduleRepayments, paymentWithOOSRepayments, remainingLoanAmount, percentLoanPaid }
 *
 * Logic:
 *
 * 0. If the loanAmount is negative (loan given to user), we will use absolute value for calculations and adjust the signs at the end
 * 1. Update loan parameters: calculate the current interest rate, period and percentRepaidAnnually (considering changes that happened this month start date and since last month's start date)
 * 2. For type 'annuity':
 *    - calculate the annuity for the current month based on the updated values above and loan amount
 *    - calculate the interest for the current period based on the updated values above and loan opening balance
 *    - calculate the principal for the current month as a difference of annuity and interest
 *    (if interestCalcMonthEnd is true, we consider the extra payment this month already, otherwise it gets deducted from the remainingLoanAmount and gets considered next month)
 * 3. For type 'fixedPrincipal':
 *    - calculate the principal for the current month from percentRepaidAnnually (considering changes as above)
 *    - calculate the interest for the current period based on the updated values above and loan opening balance
 *    (if interestCalcMonthEnd is true, we consider the extra payment this month already, otherwise it gets deducted from the remainingLoanAmount and gets considered next month)
 * 4. For type 'interestOnly':
 *    - calculate the interest for the current period based on loan amount and interest rate
 * 5. Return the resulting object
 *
 */

export default function getSimulatedRepaymentSchedule(startDate, outstandingPrincipal, loanAccount, plannedExtraRepayments = []) {
  if (Math.abs(outstandingPrincipal) > Math.abs(loanAccount.loanAmount)) {
    console.error('getSimulatedRepaymentSchedule: outstandingPrincipal cannot be greater than loanAmount');
    return { schedule: [], sumCustomRepayment: 0, sumInterestPaid: 0, totalAmount: 0 };
  }

  // console.log('getSimulatedRepaymentSchedule', startDate, outstandingPrincipal, loanAccount, plannedExtraRepayments);
  const { type, interestRate, period, percentRepaidAnnually, loanAmount, contractEndDate, interestCalcMonthEnd } = loanAccount;
  const decimalPlaces = getCurrencyDecimalPlaces(loanAccount.currency);
  const round = (value) => Number(value.toFixed(decimalPlaces));

  // to simplify the code, we work on a positive loanAmount and adjust the signs at the end
  // loanAmount will be negative for loans taken and positive for loans given
  const loanAmountAbs = Math.abs(loanAmount);

  // interestOnly loans are only accepted with a contractEndDate (otherwise they run infinitely)
  if (type === 'interestOnly' && !contractEndDate) throw new Error('getSimulatedRepaymentSchedule: interestOnly loans require a contractEndDate');

  // initialise variables
  let remainingLoanAmount = Math.abs(outstandingPrincipal);
  let sumInterestPaid = 0;
  let sumCustomRepayment = 0;
  let currentInstallmentDate = dayjs.utc(startDate).startOf('month');

  let totalPaymentToBePaid = 0;

  // array to hold the schedule
  const schedule = [];

  // loop through each month to catch all changes in interest rate, percentRepaidAnnually or period
  // (but only produce transactions within the period "Taktung", so every 1 / 3 / 12 months)
  while (remainingLoanAmount > 0) {
    // update loan parameters
    const currentInterestRate = getCurrentInterestRate(currentInstallmentDate, interestRate);
    const currentPeriodNum = getCurrentPeriodNum(currentInstallmentDate, period);
    const currentPercentRepaidAnnually = getCurrentPercentRepaidAnnually(currentInstallmentDate, percentRepaidAnnually);

    // opening balance for the current month
    const openingBalance = remainingLoanAmount;

    // if there are any extra payments scheduled
    // we add the repayment before calculating interest for this month and add the extra payment to the principal of this
    let customRepayment = 0;

    if (plannedExtraRepayments.length > 0) {
      // find if there are any out of schedule repayments for current month and sum up the quantity (in account currency, so quantity * 1)
      // eslint-disable-next-line no-loop-func
      customRepayment = round(plannedExtraRepayments.reduce((acc, curr) => (curr.date === currentInstallmentDate.valueOf() ? acc + curr.quantity : acc), 0));
      sumCustomRepayment += customRepayment;
    }

    let annuity;
    let currentPrincipalAmount;
    let currentInterestAmount;
    switch (type) {
      case 'annuity':
        // annuity is always calculated based on the INITIAL loan amount and current parameters
        annuity = round(getAnnuity(currentInterestRate, loanAmountAbs, currentPercentRepaidAnnually, currentPeriodNum));

        // calculate the interest for the current period
        // if interestCalcMonthEnd is true, we consider the extra payment this month already
        // otherwise it gets deducted from the remainingLoanAmount and gets considered next month
        currentInterestAmount = round((remainingLoanAmount - (interestCalcMonthEnd ? customRepayment : 0)) * (currentInterestRate / 100) * (1 / currentPeriodNum));

        // calculate the principal for the current month + add the custom repayment if existing (is default 0)
        currentPrincipalAmount = annuity - currentInterestAmount;

        totalPaymentToBePaid += annuity;
        break;
      case 'fixedPrincipal':
        // calculate the principal for the current month
        currentPrincipalAmount = round((loanAmountAbs * (currentPercentRepaidAnnually / 100)) / currentPeriodNum);

        // calculate the interest for the current period
        // if interestCalcMonthEnd is true, we consider the extra payment this month already
        // otherwise it gets deducted from the remainingLoanAmount and gets considered next month
        currentInterestAmount = round((remainingLoanAmount - (interestCalcMonthEnd ? customRepayment : 0)) * (currentInterestRate / 100) * (1 / currentPeriodNum));

        totalPaymentToBePaid += currentPrincipalAmount + currentInterestAmount;
        break;
      case 'interestOnly':
        // calculate the interest for the current period
        currentInterestAmount = round((loanAmountAbs - (interestCalcMonthEnd ? customRepayment : 0)) * (currentInterestRate / 100) * (1 / currentPeriodNum));
        currentPrincipalAmount = 0;
        totalPaymentToBePaid += currentInterestAmount;
        break;
      default:
        throw new Error('getSimulatedRepaymentSchedule: Invalid loan type');
    }

    totalPaymentToBePaid += customRepayment;

    // reduce the remaining loan amount by the principal
    remainingLoanAmount -= currentPrincipalAmount + customRepayment;

    // if the remaining loan amount is less than or equal to 0, make adjustments
    if (remainingLoanAmount <= 0) {
      // adjust the principal by adding the negative remaining loan amount
      currentPrincipalAmount += remainingLoanAmount;
      // set the remaining loan amount to 0
      remainingLoanAmount = 0;
    }

    // if this is the contractEndDate month, we need to return the last "balloon" installment
    if (currentInstallmentDate.valueOf() === contractEndDate) {
      currentPrincipalAmount += remainingLoanAmount;
      // set the remaining loan amount to 0
      remainingLoanAmount = 0;
    }
    // increase the total interest paid by the monthly interest
    sumInterestPaid += currentInterestAmount;

    // reintroduce the sign which was removed from loanAmount in the beginning
    // if the loanAmount was negative, the principal and interest amounts need to POSITIVE and vice versa
    const sign = loanAmount <= 0 ? 1 : -1;

    // create an object for the current installment
    const installment = {
      date: currentInstallmentDate.valueOf(),
      installmentDate: currentInstallmentDate.format('YYYY-MM'),
      interestRate: currentInterestRate,
      periodNum: currentPeriodNum,
      percentRepaidAnnually: currentPercentRepaidAnnually,
      openingBalance: round(openingBalance) * sign,
      currentPrincipal: round(currentPrincipalAmount) * sign,
      currentInterest: round(currentInterestAmount) * sign,
      currentRepayment: round(currentPrincipalAmount + currentInterestAmount) * sign,
      outOfScheduleRepayments: round(customRepayment) * sign,
      paymentWithOOSRepayments: round(currentPrincipalAmount + currentInterestAmount + customRepayment) * sign,
      remainingLoanAmount: round(remainingLoanAmount) * sign,
      percentLoanPaid: round(((outstandingPrincipal - remainingLoanAmount) / outstandingPrincipal) * 100),
    };

    // add the current installment to the schedule
    schedule.push(installment);
    // move the installment date to the next month
    currentInstallmentDate = currentInstallmentDate.add(12 / currentPeriodNum, 'month');

    // if the remaining loan amount is 0 or less, break the loop
    if (remainingLoanAmount <= 0) {
      break;
    }
  }

  // reintroduce the sign which was removed from loanAmount in the beginning
  // if the loanAmount was negative, the principal and interest amounts need to POSITIVE and vice versa
  const sign = loanAmount <= 0 ? 1 : -1;

  // console.log({
  //   schedule,
  //   sumCustomRepayment: sumCustomRepayment * sign,
  //   sumInterestPaid: sumInterestPaid * sign,
  //   totalAmount: (outstandingPrincipal + sumInterestPaid + sumCustomRepayment) * sign,
  // });

  // return the schedule and calculated totals
  return {
    schedule,
    sumCustomRepayment: sumCustomRepayment * sign,
    sumInterestPaid: sumInterestPaid * sign,
    totalAmount: (outstandingPrincipal + sumInterestPaid + sumCustomRepayment) * sign,
  };
}
