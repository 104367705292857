import i18n from '../../i18n';

function t(key) {
  return i18n.t(`app:projects.templates.${key}`);
}

// perform translation and return project templates
export default function getProjectTemplates() {
  return [
    {
      id: 'investToRent',
      name: t('investToRent.name'),
      description: t('investToRent.description'),
      accounts: [
        {
          category: 'deposits',
          role: t('investToRent.role1'),
        },
      ],
      parameters: [
        {
          key: 'value',
        },
      ],
      transactions: [
        {
          category: 'deposits',
          accountIndex: 0,
          accountCurrencyAmount: 529,
          currency: 'EUR',
          daysToAdd: 180,
          type: 'inflow',
          description: t('investToRent.transaction1'),
          otherParty: 'Arbeitgeber GmbH',
        },
        {
          category: 'deposits',
          accountIndex: 0,
          accountCurrencyAmount: 531,
          currency: 'EUR',
          daysToAdd: 240,
          type: 'inflow',
          description: t('investToRent.transaction2'),
          otherParty: 'Arbeitgeber GmbH',
        },
      ],
    },
  ];
}
