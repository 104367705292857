/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { Auth, API } from 'aws-amplify';
import { PencilIcon } from '@heroicons/react/24/outline';
import { ArrowLongLeftIcon, ArrowLongRightIcon, CheckCircleIcon, MagnifyingGlassIcon, MinusCircleIcon } from '@heroicons/react/20/solid';
import Button from '../../elements/Button';
import MiniSpinner from '../../misc/MiniSpinner';
import ToolTipNoIcon from '../../elements/ToolTipNoIcon';

dayjs.extend(utc);

const toM = (number) => {
  if (number === null || number === undefined) return '';
  let decimals = 0;
  if (number <= 10000000) decimals = 2;
  if (number > 10000000 && number <= 100000000) decimals = 1;
  const formattedNumber = number / 1000000;
  return `${new Intl.NumberFormat('en-US', {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  }).format(formattedNumber)} M`;
};

// TODO return data to caller

function AssetDetails({ assetDetails, setAssetDetails, setUpdater }) {
  AssetDetails.propTypes = {
    assetDetails: PropTypes.object.isRequired, // asset object { assetId, displaySymbol, providerAssetId, ...}
    setAssetDetails: PropTypes.func.isRequired,
    setUpdater: PropTypes.func.isRequired,
  };

  const { t } = useTranslation('app', { keyPrefix: 'accountDetails.cryptoAssetMappingDialog' });

  const [selectedRow, setSelectedRow] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchResults, setSearchResults] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 5;
  const pagesTotal = Math.ceil(searchResults.length / itemsPerPage);

  // get search results from API
  async function getSearchResults(searchString) {
    if (!searchString || searchString.length === 0) return;
    setLoading(true);
    const session = await Auth.currentSession();
    const payload = {
      body: { searchString },
      headers: {
        'Content-Type': 'application/json',
        Authorization: session.idToken.jwtToken,
      },
    };

    try {
      const response = await API.post('myAPI', 'stocks/search/crypto', payload);
      // there is a database limit on providerAssetId length of 255 characters, so let us not show those with longer names (it's just a handful of niche cryptos)
      setSearchResults(response.filter((item) => item.name.length < 255).sort());
    } catch (error) {
      console.error('Error in getSearchResults:', error);
      setSearchResults([]);
    }
    setLoading(false);
  }

  // initialise form by running a search
  useEffect(() => {
    getSearchResults(assetDetails.displaySymbol);

    // if we have a assetId, we need to try select it (assetId is the numerical id from Mobula)
    if (assetDetails.assetId) {
      const idx = searchResults.findIndex((item) => item.id === assetDetails.assetId);
      if (idx > -1) setSelectedRow(idx);
    }
  }, []);

  // handle running a search initiated by user (search button)
  async function handleSearch(e) {
    e.preventDefault();
    const formData = new FormData(e.target);

    // Access the form data
    const searchString = formData.get('asset-details-input');
    await getSearchResults(searchString);
  }

  function handleClick(idx) {
    if (idx === selectedRow) setSelectedRow(null);
    else setSelectedRow(idx);
  }

  function handleSubmit() {
    if (selectedRow !== null) {
      const selectedAsset = searchResults[selectedRow];
      const updatedAsset = { ...assetDetails, providerAssetId: selectedAsset.name, assetId: selectedAsset.id };
      setUpdater(updatedAsset);
      setAssetDetails(undefined);
    }
  }

  return (
    <div className="mt-3 text-center sm:mt-5">
      <h3 className="text-lg leading-6 font-bold text-gray-900">{`${t('headerAssetDetails')} ${assetDetails.displaySymbol}`}</h3>
      <p className="mt-2 text-sm text-gray-500">{t('promptAssetDetails')}</p>
      <form className="mt-4 pb-4 flex space-x-2 items-center text-sm mx-auto w-full md:w-3/4" onSubmit={handleSearch}>
        <span className="text-left text-sm text-gray-500 flex-shrink-0">{t('search')}</span>
        <input
          id="asset-details-input"
          name="asset-details-input"
          autoComplete="off"
          // eslint-disable-next-line max-len
          className="text-gray-700 p-1.5 rounded-md border border-gray-300 placeholder-gray-300 focus:ring-brandBlue-500 focus:border-brandBlue-500 block w-full focus:outline-none sm:text-sm"
          defaultValue={assetDetails.displaySymbol}
        />
        <Button Icon={MagnifyingGlassIcon} size="md" type="submit" textColor="text-gray-400" textColorHover="text-gray-500" enabled={!loading} />
      </form>

      {/* LOW-RES VERSION (BELOW MD) */}

      <div className="mt-4 pb-4 block md:hidden text-sm mx-auto w-3/4">
        <div className="w-full grid grid-cols-2 gap-4 items-stretch">
          <div className="uppercase tracking-wide pb-4 text-left text-xs font-bold border-b border-gray-300">
            <p>{t('symbol')}</p>
            <p>{t('name')}</p>
          </div>
          <div className="uppercase tracking-wide pb-4 text-left text-xs font-bold border-b border-gray-300">
            <p className="">{`${t('price')} ($)`}</p>
            <p className="">{`${t('marketCap')} ($)`}</p>
            <p className="">{`${t('liquidity')} ($)`}</p>
          </div>
        </div>
        {searchResults.map((item, idx) => (
          <button
            type="button"
            className={`p-1 mt-1 w-full grid grid-cols-2 gap-4 items-stretch text-sm text-gray-700 rounded-sm
              ${selectedRow === idx ? 'bg-brandBlue-600 text-white' : 'bg-white text-gray-500'}`}
            onClick={(e) => handleClick(idx)}
          >
            <div className="text-left">
              <p className="truncate">{item.symbol}</p>
              <p className="truncate">{item.name}</p>
            </div>
            <div>
              <p className="text-right">{item.price.toFixed(2)}</p>
              <p className="text-right">{toM(item.marketCap)}</p>
              <p className="text-right">{toM(item.liquidity)}</p>
            </div>
          </button>
        ))}
      </div>

      {/* HI-RES VERSION (MD+) */}

      <div className="mt-4 pb-4 hidden md:block space-y-1">
        <div className="grid grid-cols-5 gap-4 mx-auto pb-4 w-full items-stretch uppercase font-bold tracking-wide text-left text-xs border-b border-gray-300">
          <p className="">{t('symbol')}</p>
          <p className="">{t('name')}</p>
          <p className="">{`${t('price')} ($)`}</p>
          <p className="">{`${t('marketCap')} ($)`}</p>
          <p className="">{`${t('liquidity')} ($)`}</p>
        </div>
        {loading && (
          <div className="w-full h-full py-8 flex justify-center items-center">
            <MiniSpinner className="w-6 h-6 text-gray-400 animate-spin" />
          </div>
        )}
        {!loading
          && searchResults.length > 0
          && searchResults.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage).map((item, idx) => (
            <button
              key={item.id}
              className={`px-1 py-1.5 grid grid-cols-5 gap-4 text-sm mx-auto w-full items-stretch text-left rounded-sm
              ${selectedRow === currentPage * itemsPerPage + idx ? 'bg-brandBlue-600 text-white hover:bg-brandBlue-500' : 'bg-white text-gray-500 hover:bg-gray-100'}`}
              type="button"
              // here we need the index of the item in the entire array, not just on this page
              onClick={(e) => handleClick(currentPage * itemsPerPage + idx)}
            >
              <span className="truncate">{item.symbol}</span>
              <ToolTipNoIcon info={item.name}>
                <span className="truncate">{item.name}</span>
              </ToolTipNoIcon>
              <span className="text-right">{item.price.toFixed(2)}</span>
              <span className="text-right">{toM(item.marketCap)}</span>
              <span className="text-right">{toM(item.liquidity)}</span>
            </button>
          ))}
        {!loading && searchResults.length === 0 && (
          <div className="text-center text-gray-500 text-sm py-8">
            <p>{t('noResults')}</p>
          </div>
        )}
        <nav className="flex items-center justify-between mt-4 border-t border-gray-200 px-4 sm:px-0">
          <div className="-mt-px flex w-0 flex-1">
            {currentPage > 0 && (
              <button type="button" className="inline-flex items-center pr-1 pt-4 text-sm  text-gray-500 hover:text-gray-700" onClick={() => setCurrentPage(currentPage - 1)}>
                <ArrowLongLeftIcon className="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />
                {t('previous')}
              </button>
            )}
          </div>
          <div className="-mt-px px-4 pt-4 text-gray-500 text-sm">{searchResults.length > 0 ? `${t('page')} ${currentPage + 1} ${t('pageOf')} ${pagesTotal}` : ''}</div>
          <div className="-mt-px flex w-0 flex-1 justify-end">
            {currentPage < pagesTotal - 1 && (
              <button type="button" className="inline-flex items-center pl-1 pt-4 text-sm font-medium text-gray-500 hover:text-gray-700" onClick={() => setCurrentPage(currentPage + 1)}>
                {t('next')}
                <ArrowLongRightIcon className="ml-3 h-5 w-5 text-gray-400" aria-hidden="true" />
              </button>
            )}
          </div>
        </nav>
      </div>
      <div className="grid mx-auto w-2/3 grid-cols-2 mt-4 space-x-3">
        <Button text="OK" type="submit" size="lg" withAccent onClick={handleSubmit} enabled={!loading} />
        <Button text="Back" size="lg" onClick={() => setAssetDetails(undefined)} />
      </div>
    </div>
  );
}

// TODO
const data = [
  { displaySymbol: 'BTC', providerAssetId: 'Bitcoin', assetId: 100001656 },
  { displaySymbol: 'ETH', providerAssetId: 'ethereum', assetId: 100004304 },
  { displaySymbol: 'USDT', providerAssetId: undefined, assetId: undefined },
];

/**
 * Main component in this process, shows a list of assets in the current account with their status (mapped or not mapped to providerAssetId).
 * Used in crypto. After saving the Grid, user sees this dialog to map their symbols to providerAssetIds, as it cannot be done automatically.
 *
 * @param {array} incomingData: all Grid rows mapped to { displaySymbol, providerAssetId, assetId }
 * @param {string} header: title of the form (translated)
 * @param {string} prompt: description of the form (translated)
 * @param {function} callback: function to call when the user clicks 'Save'
 *
 * @returns React component
 */
export default function GridAssetMappingDialog({ incomingData, callback }) {
  const { t } = useTranslation('app', { keyPrefix: 'accountDetails.cryptoAssetMappingDialog' });

  const [localData, setLocalData] = useState([...incomingData]); // copy of incomingData; we will modify it in place and send it back to caller via callback
  const [assetDetails, setAssetDetails] = useState(undefined); // to show the individual asset details dialog when has the asset object { providerAssetId, assetId ... }
  const [updater, setUpdater] = useState(null); // AssetDetails sets it to the asset object with updated providerAssetId and assetId when user clicks OK there (the rest handled by an useEffect later)

  // responds to changes done by AssetDetails (the component will use setUpdated to passed changes back to this component)
  // when updater has changed, we need to update the localData and reset updater
  useEffect(() => {
    if (updater) {
      // there may be more than one row with the same asset, so we need to update all rows
      const copy = [...localData].map((item) => {
        if (item.displaySymbol === updater.displaySymbol) return { ...item, providerAssetId: updater.providerAssetId, assetId: updater.assetId };
        return item;
      });

      // update local data state
      setLocalData(copy);
      // reset updater
      setUpdater(null);
    }
  }, [updater]);

  function onClose() {
    window.dispatchEvent(new CustomEvent('setDialog'));
  }

  function onSubmit() {
    try {
      // we need to return the initial object with changes to caller
      callback(localData);
      onClose();
    } catch (error) {
      console.error('GetAssetMappingDialog onSubmit error:', error);
    }
  }

  return (
    <div className="mx-auto max-w-md md:max-w-lg transform overflow-hidden rounded-lg bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all p-6">
      {assetDetails ? (
        <AssetDetails assetDetails={assetDetails} setAssetDetails={setAssetDetails} setUpdater={setUpdater} />
      ) : (
        <>
          <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-brandBlue-100">
            <PencilIcon className="h-6 w-6 text-brandBlue-500" aria-hidden="true" />
          </div>
          <div className="mt-3 text-center sm:mt-5">
            <h3 className="text-lg leading-6 font-bold text-gray-900">{t('headerAssetDetails')}</h3>
            <p className="mt-2 text-sm text-gray-500">{t('promptAssetDetails')}</p>
            <div className="mt-4 pb-4 grid grid-cols-2 gap-4 text-sm items-center mx-auto w-3/4">
              <span className="uppercase tracking-wide ml-1 pb-4 text-left text-xs font-bold  border-b border-gray-300">{t('asset')}</span>
              <span className="uppercase tracking-wide ml-1 pb-4 text-left text-xs font-bold  border-b border-gray-300">{t('status')}</span>
              {localData.map((item) => (
                <>
                  <span className="ml-1 text-left truncate">{item.displaySymbol}</span>
                  <Button
                    Icon={item.providerAssetId ? CheckCircleIcon : MinusCircleIcon}
                    size="sm"
                    onClick={() => setAssetDetails(item)}
                    text={item.displaySymbol}
                    textColor={item.providerAssetId ? 'text-brandGreen-300' : 'text-brandRed-300'}
                    textColorHover={item.providerAssetId ? 'text-brandGreen-400' : 'text-brandRed-400'}
                    formatting=""
                  />
                </>
              ))}
            </div>
            <div className="flex justify-center mt-4 space-x-3">
              <Button text="Save" type="submit" size="lg" withAccent onClick={onSubmit} />
              <Button text="Cancel" size="lg" onClick={onClose} />
            </div>
          </div>
        </>
      )}
    </div>
  );
}
GridAssetMappingDialog.propTypes = {
  incomingData: PropTypes.array.isRequired,
  callback: PropTypes.func.isRequired,
};
