/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { ResponsiveBar } from '@nivo/bar';
import { ResponsiveLine } from '@nivo/line';
import CustomTooltipTranslated from './CustomTooltipTranslated';

dayjs.extend(utc);

function convertDataBarChart(inputData, colors, col, rows) {
  const result = {
    data: [],
    indexBy: col, // what field will we use to sort data to bars (columns)
    keys: rows, // what fields will we use as rows in each bar (in sequence)
    colors, // what colors will those rows have (in sequence)
  };

  // Aggregate data - returns an object:
  // { col1: { row1: 234, row2: 100, row3: 0 }, col2: { row1: 0, row2: 0, row3: 0}... }
  const aggregatedData = {};

  inputData.forEach((item) => {
    const indexValue = item[col];

    // Initialize the year object if it doesn't exist
    if (!aggregatedData[indexValue]) {
      aggregatedData[indexValue] = { [col]: indexValue };
      rows.forEach((row) => {
        aggregatedData[indexValue][row] = 0; // Initialize row values
      });
    }

    // Sum the values for each row
    rows.forEach((row) => {
      if (item[row] !== undefined) {
        aggregatedData[indexValue][row] += item[row];
      }
    });
  });

  // Convert to required format and assign colors
  for (const key in aggregatedData) {
    const entry = aggregatedData[key];
    rows.forEach((row) => {
      entry[`${row}Color`] = colors[rows.indexOf(row)];
    });
    result.data.push(entry);
  }

  return result;
}

/**
 * @file ChartWrapperNoPivotTable.jsx
 *
 * @description This component takes data as prop and displays it in a requested chart.
 * It translates the records to the format required by the specific chart.
 *
 * @param {string} chartType: string, the type of chart to display ('barStacked')
 * @param {array} data: array, the data to display
 * @param {string} colsField: name of the field to be used as columns in the chart (the field must be present in the data array)
 * @param {array} rows: array of strings, the names of the fields to be used as rows in the chart (all the fields must be present in the data array)
 * @param {array} colorsForRows: array of strings, the colors to be used for each row (in the same sequence as rows)
 *
 * In step one, transform the input data to give each transaction the exact fields which are used by the chart, for example:
 * - if the chart needs a field 'year' and 'principal' and 'interest', then the input data must be transformed to have these fields with values;
 *   in this example 'year' has the values that will be shown as bar labels, and 'principal' and 'interest' are the values that will be shown as bars
 * Then pass the transformed data to the chart component, specifying colors, col values and names of fields to be used as rows in each bar.
 */
export default function ChartWrapperNoPivotTable({ chartType, data, colsField, rows, colorsForRows }) {
  if (chartType === 'barStacked') {
    const dataKeysIndexOf = convertDataBarChart(data, colorsForRows, colsField, rows);

    const chartProps = {
      ...dataKeysIndexOf,
      animate: true,
      margin: {
        top: 40, right: 60, bottom: 24, left: 16,
      },
      theme: { fontFamily: 'Lato' },
      labelTextColor: 'inherit:darker(1.4)',
      labelSkipWidth: 16,
      labelSkipHeight: 16,
      label: false,
      groupMode: 'stacked',
      padding: 0.7,
      innerPadding: 2,
      axisLeft: null,
      axisRight: {
        format: (v) => `${v.toLocaleString('de')}`,
        tickValues: 4,
      },
      tooltip: CustomTooltipTranslated,
    };

    return (<ResponsiveBar {...chartProps} />);
  }

  if (chartType === 'line') {
    const convertedData = data.map((item) => ({
      x: dayjs.utc(item.date).format('YYYY-MM-DD'),
      y: item.quote,
    }));
    const dataKeysIndexOf = {
      data: [
        {
          id: 'value',
          color: '#2E3192', // reference tileDecor if need to change it by category
          data: [
            { x: '2020-01-01', y: 50 },
            { x: '2020-02-01', y: 52 },
            { x: '2020-03-01', y: 54 },
            { x: '2021-01-01', y: 70 },
            { x: '2022-01-01', y: 60 },
            { x: '2023-01-01', y: 90 },
          ],
        },

      ],
    };

    const chartProps = {
      ...dataKeysIndexOf,
      animate: true,
      curve: 'monotoneX',
      margin: { top: 40, right: 40, bottom: 60, left: 40 },
      axisBottom: {
        format: (v) => dayjs.utc(v).format('YYYY-MM'),
        tickRotation: -90,
      },
      theme: { fontFamily: 'Lato' },
      enableArea: true, // Enable the area below the line
      areaBaselineValue: 0, // Sets the baseline of the area (e.g., bottom of the chart)
      areaOpacity: 0.1, // Adjust opacity as needed, e.g., 0.1 for 10% opacity
      colors: { datum: 'color' }, // Use the line color for the area
      tooltip: CustomTooltipTranslated,
      xScale: {
        format: '%Y-%m-%d',
        precision: 'month',
        type: 'time',
        useUTC: true,
      },
    };

    return (<ResponsiveLine {...chartProps} />);
  }

  // ADD MORE CHART TYPES HERE
  // get common chartProps out
  console.warn('ChartWrapperNoPivotTable: unknown chartType', chartType);
  return (<div />);
}
ChartWrapperNoPivotTable.propTypes = {
  chartType: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  colsField: PropTypes.string.isRequired,
  rows: PropTypes.array.isRequired,
  colorsForRows: PropTypes.array.isRequired,
};
