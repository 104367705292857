/* eslint-disable max-len */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-redundant-roles */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ChevronDownIcon } from '@heroicons/react/24/solid';
import dayjs from 'dayjs';
import { API } from 'aws-amplify';
import { updateUserAttribute } from '../redux/actions/user';
import Button from './Button';
import { setMessage } from '../redux/actions/message';

export function LanguageDropdown() {
  const { t, i18n } = useTranslation(['site']);

  const dispatch = useDispatch();

  function handleLanguageChange(e) {
    e.preventDefault();
    i18n.changeLanguage(e.target.value);
    // record this in user's profile as well
    dispatch(updateUserAttribute('locale', e.target.value));
  }

  function handleClick(e) {
    e.stopPropagation();
  }

  return (
    <fieldset className="mt-3 w-full">
      <label htmlFor="language" className="mt-3 sr-only">
        Language
      </label>
      <div className="relative">
        <select
          id="language"
          name="language"
          className="appearance-none block w-full bg-none bg-white border border-gray-300 rounded-md py-2 pl-3 pr-10 text-base text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          value={i18n.language}
          onClick={handleClick}
          onChange={handleLanguageChange}
        >
          <option value="en">{t('site:general.english')}</option>
          <option value="de">{t('site:general.german')}</option>
        </select>
        <div className="pointer-events-none absolute inset-y-0 right-0 px-2 flex items-center">
          <ChevronDownIcon className="h-4 w-4 text-gray-400" aria-hidden="true" />
        </div>
      </div>
    </fieldset>
  );
}

function FacebookIcon(props) {
  return (
    <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
        clipRule="evenodd"
      />
    </svg>
  );
}

function InstagramIcon(props) {
  return (
    <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
        clipRule="evenodd"
      />
    </svg>
  );
}

function TwitterIcon(props) {
  return (
    <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
      <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
    </svg>
  );
}

function GitHubIcon(props) {
  return (
    <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z"
        clipRule="evenodd"
      />
    </svg>
  );
}

function displayCookieDialog() {
  // fire document event editConsent with no payload
  const event = new CustomEvent('editConsent');
  document.dispatchEvent(event);
}

export default function Footer() {
  const { t, i18n } = useTranslation(['site']);
  const [subscribeEmail, setSubscribeEmail] = React.useState('');
  const dispatch = useDispatch();

  const navigation = {
    product: [
      { name: t('site:menu.features'), href: `/${i18n.language}/features` },
      { name: t('site:menu.anonymous'), href: '/goto/anonymous-use' },
      { name: t('site:menu.data'), href: '/goto/data-sources' },
      { name: t('site:menu.demo'), href: '#' },
    ],
    support: [
      { name: t('site:menu.guide'), href: `/${i18n.language}/blog/guide` },
      { name: t('site:menu.howto'), href: `/${i18n.language}/blog/guide` },
      { name: t('site:menu.contact'), href: `/${i18n.language}/contact` },
    ],
    company: [
      { name: t('site:menu.about'), href: `/${i18n.language}/blog/guide` },
      { name: t('site:menu.blog'), href: `/${i18n.language}/blog` },
      { name: t('site:menu.jobs'), href: '#' },
      { name: t('site:menu.press'), href: '#' },
      { name: t('site:menu.partners'), href: '#' },
    ],
    legal: [
      { name: t('site:menu.impressum'), href: `/${i18n.language}/legal` },
      { name: t('site:menu.privacy'), href: '#' },
      { name: t('site:menu.agb'), href: `/${i18n.language}/legal` },
    ],
    social: [
      { name: 'Facebook', href: '#', icon: FacebookIcon },
      { name: 'Instagram', href: '#', icon: InstagramIcon },
      { name: 'Twitter', href: '#', icon: TwitterIcon },
      { name: 'GitHub', href: '#', icon: GitHubIcon },
    ],
  };

  async function handleSubscribeNewsletter(e) {
    e.preventDefault();
    // fire document event editConsent with no payload
    const event = new CustomEvent('setAlert', {
      detail: {
        id: 'signUpForNewsletter',
        caller: 'Footer',
        callbackOk: async () => {
          try {
            await API.post('myAPI', 'customer/newsletterUser', {
              body: {
                email: subscribeEmail,
                locale: i18n.language,
              },
            });
            dispatch(setMessage('newsletterSubscriptionSuccess'));
          } catch (err) {
            console.error(err);
            dispatch(setMessage('newsletterSubscriptionError'));
          }
        },
      },
    });
    window.dispatchEvent(event);
  }

  return (
    <footer className="bg-gray-50" aria-labelledby="footer-heading" data-testid="footer">
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <div className="pb-8 xl:grid xl:grid-cols-5 xl:gap-8">
          <div className="grid grid-cols-2 gap-8 xl:col-span-4">
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">{t('site:menu.product')}</h3>
                <ul role="list" className="mt-4 space-y-4">
                  {navigation.product.map((item) => (
                    <li key={item.name}>
                      <a href={item.href} className="text-base text-gray-500 hover:text-gray-900">
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="mt-12 md:mt-0">
                <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">{t('site:menu.support')}</h3>
                <ul role="list" className="mt-4 space-y-4">
                  {navigation.support.map((item) => (
                    <li key={item.name}>
                      <a href={item.href} className="text-base text-gray-500 hover:text-gray-900">
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">{t('site:menu.company')}</h3>
                <ul role="list" className="mt-4 space-y-4">
                  {navigation.company.map((item) => (
                    <li key={item.name}>
                      <a href={item.href} className="text-base text-gray-500 hover:text-gray-900">
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="mt-12 md:mt-0">
                <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">{t('site:menu.legal')}</h3>
                <ul role="list" className="mt-4 space-y-4">
                  {navigation.legal.map((item) => (
                    <li key={item.name}>
                      <a href={item.href} className="text-base text-gray-500 hover:text-gray-900">
                        {item.name}
                      </a>
                    </li>
                  ))}
                  <li key="cookies">
                    <button type="button" onClick={displayCookieDialog} className="text-base text-gray-500 hover:text-gray-900">
                      Cookies
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="mt-12 xl:mt-0">
            <form className="sm:max-w-xs">
              <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">{t('site:general.language')}</h3>
              <LanguageDropdown />
            </form>
            <form className="sm:max-w-xs">
              <h3 className="mt-6 text-sm font-semibold text-gray-400 tracking-wider uppercase">{t('site:general.country')}</h3>
              <fieldset className="mt-3 w-full">
                <label htmlFor="country" className="sr-only">
                  Country
                </label>
                <div className="relative">
                  <select
                    id="country-footer"
                    name="country-footer"
                    className="appearance-none block w-full bg-none bg-white border border-gray-300 rounded-md py-2 pl-3 pr-10 text-base text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    defaultValue="Germany"
                  >
                    <option>{t('site:general.germany')}</option>
                  </select>
                  <div className="pointer-events-none absolute inset-y-0 right-0 px-2 flex items-center">
                    <ChevronDownIcon className="h-4 w-4 text-gray-400" aria-hidden="true" />
                  </div>
                </div>
              </fieldset>
            </form>
          </div>
        </div>
        <div className="border-t border-gray-200 pt-8 lg:flex lg:items-center lg:justify-between xl:mt-0">
          <div>
            <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">{t('site:general.subscribe.header')}</h3>
            <p className="mt-2 text-base text-gray-500">{t('site:general.subscribe.body')}</p>
          </div>
          <form className="mt-4 sm:flex sm:max-w-md lg:mt-0">
            <label htmlFor="email-address" className="sr-only">
              Email address
            </label>
            <input
              type="email"
              name="email-address"
              id="email-address"
              autoComplete="email"
              required
              value={subscribeEmail}
              onChange={(e) => setSubscribeEmail(e.target.value)}
              className="appearance-none min-w-0 w-full bg-white border border-gray-300 py-2 px-4 text-base rounded-md text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:placeholder-gray-400 sm:max-w-xs"
              placeholder={t('site:general.subscribe.emailprompt')}
            />
            <div className="mt-3 rounded-md sm:mt-0 sm:ml-3 sm:flex-shrink-0">
              <Button type="submit" text={t('site:general.subscribe.button')} withAccent onClick={handleSubscribeNewsletter} />
            </div>
          </form>
        </div>
        <div className="mt-8 border-t border-gray-200 pt-8 md:flex md:items-center md:justify-between">
          <div className="flex space-x-6 md:order-2">
            {navigation.social.map((item) => (
              <a key={item.name} href={item.href} className="text-gray-400 hover:text-gray-500">
                <span className="sr-only">{item.name}</span>
                <item.icon className="h-6 w-6" aria-hidden="true" />
              </a>
            ))}
          </div>
          <p className="mt-8 text-base text-gray-400 md:mt-0 md:order-1">
            &copy; 2021-
            {dayjs().format('YYYY')}
            {' '}
            Monestry GmbH. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
}
