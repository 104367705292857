import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { InformationCircleIcon } from '@heroicons/react/20/solid';

/**
 * Displays a tooltip with information when hovered over.
 *
 * @param {string} info - text to be displayed in the tooltip
 * @param {string} formatting - additional classes to be applied to the tooltip
 *
 * @returns a React component
 */
export default function ToolTip({ info, formatting }) {
  // FIXME: has a fixed width, because when flexible, it orients itself on the parent width, which is super narrow
  const [visible, setVisible] = useState(false);

  function localClassNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }

  return (
    <div className={`relative self-center ${formatting}`}>
      <div className="self-center px-1.5 " onPointerEnter={() => setVisible(true)} onPointerLeave={() => setVisible(false)}>
        <InformationCircleIcon className="h-4 w-4 text-gray-300" />
      </div>
      <div
        role="tooltip"
        className={localClassNames(
          visible ? 'inline-block' : 'hidden',
          'absolute -top-3 left-8 z-10 w-96 py-2 px-3 text-sm font-medium text-gray-900 bg-white rounded border border-gray-200 shadow-sm opacity-100 transition-opacity',
        )}
      >
        {/* FIXME: this doesn't work too well -- may not be necessary, the tooltips should be clearly linkable to whatever they are describing */}
        {info}
      </div>
    </div>
  );
}
ToolTip.propTypes = {
  info: PropTypes.string.isRequired,
  formatting: PropTypes.string,
};
ToolTip.defaultProps = {
  formatting: '',
};
