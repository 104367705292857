/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { CheckCircleIcon } from '@heroicons/react/24/solid';
import { useTranslation } from 'react-i18next';
import { setAlert, setMessage } from '../../redux/actions/message';
import Button from '../../elements/Button';

export function Steps({ steps }) {
  const { t } = useTranslation();

  return (
    <nav className="flex items-center justify-center" aria-label="Progress">
      <p className="hidden md:block text-sm font-medium">
        {t('site:general.step')}
        {' '}
        {steps.findIndex((step) => step.status === 'current') + 1}
        {' '}
        {t('site:general.stepOf')}
        {' '}
        {steps.length}
      </p>
      <ol className="ml-8 flex items-center space-x-5">
        {steps.map((step) => (
          <li key={step.name}>
            {step.status === 'complete' ? (
              <div className="block h-2.5 w-2.5 rounded-full bg-brandBlue-600 hover:bg-brandBlue-900">
                <span className="sr-only">{step.name}</span>
              </div>
            ) : step.status === 'current' ? (
              <div className="relative flex items-center justify-center" aria-current="step">
                <span className="absolute flex h-5 w-5 p-px" aria-hidden="true">
                  <span className="h-full w-full rounded-full bg-brandBlue-200" />
                </span>
                <span className="relative block h-2.5 w-2.5 rounded-full bg-brandBlue-600" aria-hidden="true" />
                <span className="sr-only">{step.name}</span>
              </div>
            ) : (
              <div className="block h-2.5 w-2.5 rounded-full bg-gray-200 hover:bg-gray-400">
                <span className="sr-only">{step.name}</span>
              </div>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
}
Steps.propTypes = {
  steps: PropTypes.array.isRequired,
};

export function NavComponent({
  formatting, setAddModeState, displayedStep, setDisplayedStep, workflowStepStatus, steps, handleSubmit, messageToShowBeforeNext, setMessageToShowBeforeNext, loading, setSignalNext,
}) {
  const [errorMessage, setErrorMessage] = useState(null);
  const { t } = useTranslation(['app'], { keyPrefix: 'addAccount' });
  const dispatch = useDispatch();

  function emitTriggerValidationEvent() {
    const event = new Event('triggerValidation');
    window.dispatchEvent(event);
  }

  function handleNext() {
    function executeNext() {
      if (workflowStepStatus[displayedStep]) {
        // workflowStepStatus is an array of the same length as the workflow
        // if this is the last step of the workflow, the user has finished the workflow
        setErrorMessage(null);
        if (displayedStep === workflowStepStatus.length - 1) {
          handleSubmit();
        } else {
          setErrorMessage(null);
          setDisplayedStep(displayedStep + 1);
        }
      } else {
        setSignalNext(true);
        emitTriggerValidationEvent();
        setErrorMessage(t('errorCompleteStep'));
      }
    }

    // if there is an alert to show before moving on, show it and executeNext() when the user clicks OK; otherwise go straight to executeNext()
    if (messageToShowBeforeNext) {
      dispatch(setAlert(messageToShowBeforeNext, 'AddAccount', executeNext));
      setMessageToShowBeforeNext(null);
    } else {
      executeNext();
    }
  }

  // this gets rid of the overall NavComponent error message once the user has completed the current step
  useEffect(() => {
    if (workflowStepStatus[displayedStep]) setErrorMessage(null);
  }, [workflowStepStatus, displayedStep]);

  return (
    <nav className={`${formatting} w-full border-y border-gray-300 p-8 grid grid-cols-3 md:grid-cols-5 items-center`}>
      {/* only show Back button when this isn't the first step; div around button is to stop it from stretching across the entire grid column */}
      {(displayedStep > 0) ? (
        <div>
          <Button
            text={t('back')}
            onClick={() => {
              setDisplayedStep(Math.max(displayedStep - 1, 0));
              setErrorMessage(null);
              setMessageToShowBeforeNext(null);
            }}
          />
        </div>
      ) : <div />}
      <div className="invisible xs:visible  md:col-start-3 md:flex items-center justify-center">
        <Steps steps={steps} />
      </div>
      <div className="hidden md:col-start-4 md:flex md:items-center md:justify-center">
        {errorMessage && <p className="text-red-500 text-sm">{errorMessage}</p>}
        {workflowStepStatus[displayedStep] && (
          <div className="flex w-full justify-center items-center">
            <p className="text-green-500 text-sm mr-2">{workflowStepStatus.at(-1) ? t('pressSubmitToFinish') : t('pressNextToContinue')}</p>
            <CheckCircleIcon className="h-5 w-5 text-green-500" aria-hidden="true" />
          </div>
        )}
      </div>
      <div className="flex gap-4 md:col-start-5 justify-self-end">
        <Button
          text={t('cancel')}
          onClick={() => setAddModeState(false)}
        />
        {/* only enable Back if status of the current workflow step is true */}
        <Button
          text={workflowStepStatus.at(-1) ? t('submit') : t('next')}
          disabled={loading}
          spinnerOn={loading}
          withAccent
          onClick={handleNext}
        />
      </div>
    </nav>
  );
}
NavComponent.propTypes = {
  formatting: PropTypes.string,
  setAddModeState: PropTypes.func.isRequired,
  displayedStep: PropTypes.number,
  setDisplayedStep: PropTypes.func.isRequired,
  workflowStepStatus: PropTypes.array.isRequired,
  steps: PropTypes.array.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  messageToShowBeforeNext: PropTypes.string,
  setMessageToShowBeforeNext: PropTypes.func,
  loading: PropTypes.bool.isRequired,
  setSignalNext: PropTypes.func.isRequired,
};
NavComponent.defaultProps = {
  formatting: '',
  displayedStep: 0,
  messageToShowBeforeNext: null,
  setMessageToShowBeforeNext: () => {},
};
