/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/no-unknown-property */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Auth } from 'aws-amplify';
import MiniSpinner from '../../misc/MiniSpinner';

export default function ForgotPane({ setDisplayedPane, setRecoveryEmail }) {
  const form = useRef();

  const { isLoggedIn } = useSelector((state) => state.user);

  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [loginError, setLoginError] = useState(undefined); // null or error message

  const { t } = useTranslation();

  const navigate = useNavigate();

  const onChangeEmail = (e) => {
    // eslint-disable-next-line no-shadow
    const email = e.target.value;
    setEmail(email);
  };

  function required(value) {
    if (!value) {
      return (<div className="alert alert-danger" role="alert">{t('site:login.fieldRequired')}</div>);
    }
    return (<div />);
  }

  async function handleSubmit(e) {
    e.preventDefault();
    e.persist(); // see: https://medium.com/@ian.mundy/async-event-handlers-in-react-a1590ed24399

    if (!isLoggedIn) {
      setLoading(true);
      setLoginError(null);

      try {
        await Auth.forgotPassword(email);
        setRecoveryEmail(email);
        setDisplayedPane('newPassword');
      } catch (err) {
        setLoading(false);
        console.log('error code', err);
        if (err.code === 'LimitExceededException') {
          setLoginError(t('site:login.tooManyRequests'));
        } else {
          setLoginError(t('site:login.unableToResetPassword'));
        }
      }
    }
  }

  return (
    <div className="mx-auto w-full max-w-sm lg:w-96">
      <div>
        {(loginError) ? <h3 className="text-brandRed-500" id="loginIncorrectError">{t(`site:login.${loginError}`)}</h3> : null}
      </div>
      <div className="mt-8">
        <div className="mt-6">
          <form onSubmit={handleSubmit} ref={form} className="space-y-16">
            <h2 className="mt-6 text-3xl font-extrabold text-gray-900 text-brand">
              {t('site:login.passwordRecovery')}
            </h2>
            <div>
              <label
                htmlFor="username"
                className="block text-sm font-medium text-gray-700"
              >
                {t('site:login.emailOrUsername')}
              </label>
              <div className="mt-1">
                <input
                  id="email"
                  name="email"
                  type="text"
                  autoComplete="email"
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-brandBlue-500 focus:border-brandBlue-500 sm:text-sm"
                  value={email}
                  onChange={onChangeEmail}
                  validations={[required]}
                />
              </div>
            </div>
            <div>
              <button
                type="submit"
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-semibold text-white bg-brandBlue-500 hover:bg-brandBlue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brandBlue-500"
                id="submit"
                disabled={loading}
              >
                {loading ? <MiniSpinner className="h-4 w-4 animate-spin text-white" /> : (<span>{t('site:general.submit')}</span>)}
              </button>
              <button
                // type="submit"
                className="mt-6 w-full flex justify-center py-2 px-4 border rounded-md shadow-sm text-sm font-semibold outline-brandBlue-500 text-brandBlue-500 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brandBlue-500"
                disabled={loading}
                onClick={() => setDisplayedPane('login')}
              >
                {t('site:general.cancel')}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
ForgotPane.propTypes = {
  setDisplayedPane: PropTypes.func.isRequired,
  setRecoveryEmail: PropTypes.func.isRequired,
};
