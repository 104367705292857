/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { Switch } from '@headlessui/react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { DividendSettings } from '../accountDetails/DividendGrowthRates';
import { settingsStocksAndCrypto } from '../../elements/config';
import { putStocksSettings } from '../../redux/reducers/data';
import { setMessage } from '../../redux/actions/message';
import Button from '../../elements/Button';

dayjs.extend(utc);

export default function DividendRates({ setIsDirty }) {
  const [updating, setUpdating] = useState(false);

  const selectStockSettings = useSelector((state) => state.data.stocks.settings);
  console.log('DEBUG selectStockSettings', selectStockSettings);

  const dispatch = useDispatch();
  const { t } = useTranslation('app', { keyPrefix: 'settings.simulations' });

  // default settings updated in getSettings (centrally)

  const {
    register,
    control,
    watch,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      ...settingsStocksAndCrypto, // making sure the default values are in the form object and the form object will work
      ...selectStockSettings,
    },
  }); // intialised after useEffect fetches data from Amplify store
  const { fields, append, remove } = useFieldArray({
    name: 'dividendYieldRates',
    control,
  });

  // keep parent's isDirty state in sync with the form's isDirty state, so that a warning can be shown when the user tries to navigate away without saving
  useEffect(() => {
    setIsDirty(isDirty);
  }, [isDirty]);

  async function onSubmit(data) {
    // if the form is dirty, update the entire object (as it would have otherwise been added by redux)
    if (isDirty) {
      setUpdating(true);
      try {
        await dispatch(putStocksSettings({ data, category: 'stocks' }));
        reset(data);
        dispatch(setMessage('dataUpdatedSuccessfully'));
      } catch (error) {
        console.error(error);
        dispatch(setMessage('dataUpdateError'));
      }
      setUpdating(false);
    }
  }

  return (
    <div className="space-y-3 sm:grid sm:grid-cols-6 sm:items-start sm:justify-items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5 text-sm">
      <div className="sm:col-span-2">
        <p>{t('stockDividends')}</p>
        <p className="mt-1 text-xs text-gray-400">{t('stockDividendsDescription')}</p>
        <p className="mt-1 text-xs text-gray-400">{t('stockDividendsDescription2')}</p>
        <p className="mt-1 text-xs text-gray-400">{t('dividendYield')}</p>
      </div>
      <div className="sm:max-w-sm">
        {/* DIVIDENDS: SWITCH */}
        <Controller
          control={control}
          name="dividendYieldAdvancedMode"
          render={({ field }) => (
            <Switch.Group as="div" className="flex items-center justify-start w-full">
              <Switch.Label as="span" className="mr-3">
                <span className="text-sm font-medium text-gray-500">{t('simple')}</span>
              </Switch.Label>
              <Switch
                {...field}
                checked={field.value}
                onChange={field.onChange}
                className={`${field.value ? 'bg-brandBlue-500' : 'bg-gray-200'} relative inline-flex flex-shrink-0 h-6 w-11 border-2
                      border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brandBlue-600`}
              >
                <span
                  aria-hidden="true"
                  className={`${field.value ? 'translate-x-5' : 'translate-x-0'}
                          pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200`}
                />
              </Switch>
              <Switch.Label as="span" className="ml-3">
                <span className="text-sm font-medium text-gray-500">{t('advanced')}</span>
              </Switch.Label>
            </Switch.Group>
          )}
        />
        {/* DIVIDENDS: SIMPLE MODE */}
        <div className="mt-4">
          <p className="text-gray-500">{t('dividendYield')}</p>
          <input
            type="number"
            step="0.01"
            name="dividendYield"
            id="dividendYield"
            {...register('dividendYield', { valueAsNumber: true })}
            className="mt-0.5 flex-1 focus:ring-brandBlue-400 focus:border-brandBlue-400 block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
          />
          {errors.dividendYield && (
            <div className="pt-2 sm:text-sm text-brandRed-500 max-w-fit">
              <span>{errors.dividendYield.message}</span>
            </div>
          )}
        </div>
      </div>
      {/* DIVIDENDS: ADVANCED MODE */}
      {watch('dividendYieldAdvancedMode') && (
        <div className="sm:col-span-3">
          <DividendSettings fields={fields} append={append} remove={remove} register={register} control={control} />
        </div>
      )}
      {isDirty && (
        <div className="flex justify-end space-x-2 sm:col-start-3 mt-4">
          <Button type="button" onClick={handleSubmit(onSubmit)} text={t('save')} spinnerOn={updating} id="settings-planning-save" withAccent size="lg" />
        </div>
      )}
    </div>
  );
}
DividendRates.propTypes = {
  setIsDirty: PropTypes.func.isRequired,
};
