import React from 'react';
import ReactMarkdown from 'react-markdown';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { CogIcon, CheckIcon, ShieldCheckIcon } from '@heroicons/react/24/outline';

const icons = {
  simplicity: { icon: CheckIcon, href: '/' },
  privacy: { icon: ShieldCheckIcon, href: '/' },
  convenience: { icon: CogIcon, href: '/' },
  one: { icon: 'https://images.unsplash.com/photo-1557576146-047908becbb4?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80' },
  two: { icon: 'https://images.unsplash.com/photo-1568548527144-383edf5c2728?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=256&h=256&q=80&crop=focalpoint&fp-x=0.45&fp-y=0.6&fp-z=1.2' },
  three: { icon: 'https://images.unsplash.com/photo-1556917452-890eed890648?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&facepad=2&w=256&h=256&q=80&crop=focalpoint&fp-x=0.75&fp-y=0.6&fp-z=1' },
};

export default function MeetMonestry() {
  const { t, i18n } = useTranslation(['site']);
  const pillars = t('site:about-us.pillars', { returnObjects: true });
  const pledges = t('site:about-us.pledges', { returnObjects: true });
  pillars[0].link = `/${i18n.language}/features#one-glance`; // "simplicity"
  pillars[1].link = `/${i18n.language}/blog/new-features/data-privacy-at-monestry#blog-title`; // "privacy"
  pillars[2].link = `/${i18n.language}/features#features-automatic-updates`; // "convenience"

  return (
    <>
      <div className="bg-white">
        {/* Header */}
        <div className="relative pb-32 bg-gray-800">
          <div className="absolute inset-0">
            <img
              className="w-full h-full object-cover"
              src="https://images.unsplash.com/photo-1525130413817-d45c1d127c42?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1920&q=60&&sat=-100"
              alt=""
            />
            <div className="absolute inset-0 bg-brandDarkBlue-800 mix-blend-multiply" aria-hidden="true" />
          </div>
          <div className="relative max-w-7xl mx-auto py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
            <h1 className="text-4xl font-black tracking-tight text-white md:text-5xl lg:text-6xl">{t('about-us.need-for-monestry.header')}</h1>
            <p className="mt-9 max-w-3xl text-2xl text-brandBlue-100">
              {t('about-us.need-for-monestry.subheader')}
            </p>
            {t('about-us.need-for-monestry.paragraphs', { returnObjects: true }).map((paragraph) => (
              <p className="mt-6 max-w-3xl text-xl text-brandBlue-100">
                {paragraph}
              </p>
            ))}
            <p className="mt-9 max-w-3xl text-xl italic text-brandBlue-100">

              {t('about-us.need-for-monestry.footnote')}
            </p>
          </div>
        </div>

        {/* Overlapping cards / pillars */}
        <section
          className="-mt-32 max-w-7xl mx-auto relative z-10 pb-32 px-4 sm:px-6 lg:px-8"
          aria-labelledby="contact-heading"
        >
          <h2 className="sr-only" id="values-heading">
            Our values
          </h2>
          <div className="grid grid-cols-1 gap-y-20 lg:grid-cols-3 lg:gap-y-0 lg:gap-x-8">
            {pillars.map((pillar) => (
              <div key={pillar.name} className="flex flex-col bg-white rounded-2xl shadow-xl">
                <div className="flex-1 relative pt-16 px-6 pb-8 md:px-8">
                  <div className="absolute top-0 p-5 inline-block bg-brandBlue-500 rounded-xl shadow-lg transform -translate-y-1/2">
                    {React.createElement(icons[pillar.key].icon, { className: 'h-8 w-8 text-white', 'aria-hidden': 'true' })}
                  </div>
                  <h3 className="text-2xl font-black text-gray-900">{pillar.header}</h3>
                  <ReactMarkdown className="mt-6 prose">{pillar.description}</ReactMarkdown>
                </div>
                <div className="p-6 bg-gray-50 rounded-bl-2xl rounded-br-2xl md:px-8">
                  <NavLink to={pillar.link} className="text-base font-medium text-brandBlue-600 hover:text-brandBlue-700">
                    {pillar.cta}
                    <span aria-hidden="true"> &rarr;</span>
                  </NavLink>
                </div>
              </div>
            ))}
          </div>
        </section>
      </div>
      <div className="max-w-7xl mx-auto py-8 px-4 sm:py-12 sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-base font-semibold text-brandBlue-500 tracking-wide uppercase">{t('about-us.pledges.title')}</h2>
          <p className="mt-1 text-4xl font-black text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">
            {t('about-us.pledges.header')}
          </p>
          <p className="max-w-xl mt-5 mx-auto text-xl text-gray-500">
            {t('about-us.pledges.subheader')}
          </p>
        </div>
      </div>
      {/* 3 promises */}
      <section className="bg-white overflow-hidden">
        <div className="relative max-w-7xl mx-auto pt-20 pb-12 px-4 sm:px-6 lg:px-8 lg:py-20">
          <svg
            className="absolute top-full left-0 transform translate-x-80 -translate-y-24 lg:hidden"
            width={784}
            height={404}
            fill="none"
            viewBox="0 0 784 404"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="e56e3f81-d9c1-4b83-a3ba-0d0ac8c32f32"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
              </pattern>
            </defs>
            <rect width={784} height={404} fill="url(#e56e3f81-d9c1-4b83-a3ba-0d0ac8c32f32)" />
          </svg>

          <svg
            className="hidden lg:block absolute right-full top-1/2 transform translate-x-1/2 -translate-y-1/2"
            width={404}
            height={784}
            fill="none"
            viewBox="0 0 404 784"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="56409614-3d62-4985-9a10-7ca758a8f4f0"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
              </pattern>
            </defs>
            <rect width={404} height={784} fill="url(#56409614-3d62-4985-9a10-7ca758a8f4f0)" />
          </svg>

          {pledges.items.map((pledge) => (
            <div className="relative mb-10 lg:flex lg:items-center">
              <div className="hidden lg:block lg:flex-shrink-0">
                <img
                  className="h-64 w-64 rounded-full xl:h-80 xl:w-80"
                  src={icons[pledge.key].icon}
                  alt=""
                />
              </div>

              <div className="relative lg:ml-10">
                <svg
                  className="absolute top-0 left-0 transform -translate-x-8 -translate-y-24 h-36 w-36 text-indigo-200 opacity-50"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 144 144"
                  aria-hidden="true"
                >
                  <path
                    strokeWidth={2}
                    d="M41.485 15C17.753 31.753 1 59.208 1 89.455c0 24.664 14.891 39.09 32.109 39.09 16.287 0 28.386-13.03 28.386-28.387 0-15.356-10.703-26.524-24.663-26.524-2.792 0-6.515.465-7.446.93 2.327-15.821 17.218-34.435 32.11-43.742L41.485 15zm80.04 0c-23.268 16.753-40.02 44.208-40.02 74.455 0 24.664 14.891 39.09 32.109 39.09 15.822 0 28.386-13.03 28.386-28.387 0-15.356-11.168-26.524-25.129-26.524-2.792 0-6.049.465-6.98.93 2.327-15.821 16.753-34.435 31.644-43.742L121.525 15z"
                  />
                </svg>
                <blockquote className="relative">
                  <div className="text-3xl leading-9 font-bold text-gray-900">
                    <p>{pledge.header}</p>
                  </div>
                  <footer className="mt-8">
                    <div className="flex">
                      <div className="ml-4 lg:ml-0">
                        <div className="text-lg font-medium text-gray-900">{pledge.subheader}</div>
                      </div>
                    </div>
                  </footer>
                </blockquote>
              </div>
            </div>
          ))}
        </div>
      </section>
      <div className="bg-white">
        <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-20 lg:px-8">
          <div className="lg:grid lg:grid-cols-3 lg:gap-8">
            <div>
              <h2 className="text-4xl font-black text-gray-900">
                {t('site:about-us.about.header')}
                {' '}
              </h2>
              {/* <p className="mt-4 text-lg text-gray-500">
                {' '}
                <a href="/" className="font-medium text-indigo-600 hover:text-indigo-500">
                  customer support
                </a>
                {' '}
                team.
              </p> */}
            </div>
            <div className="mt-12 text-lg lg:mt-0 lg:col-span-2">
              <ReactMarkdown className="prose prose-lg">
                {t('about-us.about.body')}
              </ReactMarkdown>
            </div>
          </div>
        </div>
      </div>
      {/* Name explained */}
      <div className="bg-white py-8 lg:py-24">
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="relative py-24 px-8 bg-brandBlue-500 rounded-xl shadow-2xl overflow-hidden lg:px-16 lg:grid lg:grid-cols-2 lg:gap-x-8">
            <div className="absolute inset-0 opacity-50 filter saturate-0 mix-blend-multiply">
              <img
                src="https://images.unsplash.com/photo-1601381718415-a05fb0a261f3?ixid=MXwxMjA3fDB8MHxwcm9maWxlLXBhZ2V8ODl8fHxlbnwwfHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1216&q=80"
                alt=""
                className="w-full h-full object-cover"
              />
            </div>
            <div className="relative lg:col-span-1">
              <div className="mt-6 text-white">
                <h3 className="text-2xl font-black my-4 sm:text-4xl">
                  {t('site:about-us.why-monestry.header')}
                </h3>
                <p className="text-base font-medium my-4 sm:text-xl">
                  {t('site:about-us.why-monestry.body1')}
                </p>
                <p className="text-base font-medium my-4 sm:text-xl">
                  {t('site:about-us.why-monestry.body2')}
                  {' '}
                  <span className="italic">{t('site:about-us.why-monestry.body3')}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
