/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import { Transition } from '@headlessui/react';
import SumCurrentNetWorth from './SumCurrentNetWorth';
import SumPreviousValue from './SumPreviousValue';
import SumChangesInValue from './SumChangesInValue';
import { CHANGE_IN_VALUE, CURRENT_NET_WORTH, PREVIOUS_VALUE } from './constants';

function SumTile({ id, baseCurrency, setKpiReportVisible, mode, onClick, outline, isViewer }) {
  function onClickLocal() {
    setKpiReportVisible(true);
  }

  let Component;

  switch (id) {
    case CURRENT_NET_WORTH:
      Component = SumCurrentNetWorth;
      break;
    case PREVIOUS_VALUE:
      Component = SumPreviousValue;
      break;
    case CHANGE_IN_VALUE:
      Component = SumChangesInValue;
      break;
    default:
      break;
  }

  return (
    <Transition
      key={id}
      as="div"
      appear
      show
      enter="transition-opacity duration-200"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-150"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      onClick={onClick || onClickLocal} // if there is onClick in props, use it, otherwise use onClickLocal
      className={`relative bg-white w-full max-w-[18rem] lg:min-w-full h-full py-4 sm:py-6 md:py-5 lg:py-6 px-2 xs:px-4 sm:px-6 lg:px-3 xl:px-4
      shadow rounded-md overflow-hidden grid space-y-4 divide-y cursor-pointer ${outline ? 'border-2 border-brandBlue-500' : ''}`}
    >
      <Component baseCurrency={baseCurrency} setKpiReportVisible={setKpiReportVisible} isViewer={isViewer} />
    </Transition>
  );
}

SumTile.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  id: PropTypes.string.isRequired,
  baseCurrency: PropTypes.string.isRequired,
  setKpiReportVisible: PropTypes.func,
  mode: PropTypes.oneOf(['button']),
  onClick: PropTypes.func,
  outline: PropTypes.bool,
  isViewer: PropTypes.bool,
};
SumTile.defaultProps = {
  setKpiReportVisible: () => {},
  mode: 'button',
  onClick: () => {},
  outline: false,
  isViewer: false,
};

const SumTileMemo = React.memo(SumTile);
export default SumTileMemo;
