/* eslint-disable react/jsx-no-bind */
/* eslint-disable max-len */
/* This example requires Tailwind CSS v2.0+ */
import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Dialog, Transition } from '@headlessui/react';
import { CheckIcon, XCircleIcon, ExclamationCircleIcon } from '@heroicons/react/24/outline';
import Button from './Button';

function IconSuccess() {
  return (
    <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
      <CheckIcon className="h-6 w-6 text-green-500" aria-hidden="true" />
    </div>
  );
}

function IconWarning() {
  return (
    <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-yellow-100">
      <ExclamationCircleIcon className="h-6 w-6 text-yellow-400" aria-hidden="true" />
    </div>
  );
}
function IconError() {
  return (
    <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-brandRed-100">
      <XCircleIcon className="h-6 w-6 text-brandRed-500" aria-hidden="true" />
    </div>
  );
}

export default function Alert({ alertId, alertResetFunction = null, resetCallbackObject = null, buttons = null, visible = true, callbackOk }) {
  // alertId corresponds to the property of the app.json translation file
  // alertResetFunction is the callback FUNCTION to be set to false in the parent component
  // if the above function doesn't simply set alert to false, but is supposed to update an object,
  // this object needs to be passed as resetCallbackObject AND you need to still specify the function
  // AlertModal will then call the callback function with the object as argument and set 'resetCallbackObject.visible' to false
  // buttons supports 2 buttons; buttons[0] is the default action button, buttons[1] is the secondary action button
  // button internal id is taken from props.buttons, whereas button labels come directly from i18n

  const { t } = useTranslation(['app']);
  const alert = t(`alerts.${alertId}`, { returnObjects: true });

  function handleClick(e) {
    // handle visibility of the alert modal here as well, as this component has no own state
    // TESTBETRIEB
    callbackOk();
    if (alertResetFunction && !resetCallbackObject) alertResetFunction(false);
    if (alertResetFunction && resetCallbackObject && !buttons) alertResetFunction({ ...resetCallbackObject, visible: false });
    if (alertResetFunction && resetCallbackObject && buttons) {
      alertResetFunction({ ...resetCallbackObject, visible: false, buttonClicked: e.target.id });
    }
  }

  let Icon = IconSuccess;
  if (alert.type === 'error') Icon = IconError;
  if (alert.type === 'warning') Icon = IconWarning;

  return (
    // <Transition.Root show={open} as={Fragment}>
    //   <Dialog as="div" className="fixed z-50 inset-0 overflow-y-auto" onClose={setOpen}>
    <Transition.Root show={visible} as={Fragment}>
      <Dialog as="div" className="fixed z-50 inset-0 overflow-y-auto" onClose={() => {}}>
        {/* onClose is handled by handleClick */}
        <div id="alertModal" className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
              <div>
                <Icon />
                <div className="mt-3 text-center sm:mt-5">
                  <Dialog.Title as="h3" className="text-lg leading-6 font-bold text-gray-900">
                    {alert.header}
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">{alert.body}</p>
                  </div>
                </div>
              </div>
              {buttons ? (
                <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense font-bold">
                  <button
                    type="button"
                    id={buttons[0]}
                    // ^^ this comes from the caller via props
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-brandBlue-500 text-base text-white hover:bg-brandBlue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brandBlue-400 sm:col-start-2 sm:text-sm"
                    onClick={handleClick}
                  >
                    {alert.button}
                  </button>
                  <button
                    type="button"
                    id={buttons[1]}
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                    onClick={handleClick}
                  >
                    {alert.button2}
                  </button>
                </div>
              ) : (
                <div className="mt-5 sm:mt-6">
                  <button
                    type="button"
                    className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-brandBlue-500 text-base text-white hover:bg-brandBlue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brandBlue-400 sm:text-sm"
                    onClick={handleClick}
                  >
                    {alert.button}
                  </button>
                </div>
              )}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
Alert.propTypes = {
  alertId: PropTypes.string,
  alertResetFunction: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  resetCallbackObject: PropTypes.object,
  buttons: PropTypes.arrayOf(PropTypes.string),
  visible: PropTypes.bool,
  callbackOk: PropTypes.func,
};
Alert.defaultProps = {
  alertId: 'pressOkToContinue',
  alertResetFunction: null,
  resetCallbackObject: null,
  buttons: null,
  visible: true,
  callbackOk: () => {},
};
