export default function gridConvertGridDataToObject(gridData, gridLayout, dirtyRows, spreadExtraData) {
  return (
    gridData
      // add dirty / not dirty column to every row array based on dirtyRows (but only to rows which are not empty, because they won't be removed in the next step of the chain)
      // .map((row, idx) => (row.join('').length > 0 ? [...row, dirtyRows.has(String(idx))] : row)) --> why was there a String() conversion?
      .map((row, idx) => (row.join('').length > 0 ? [...row, dirtyRows.has(idx)] : row))
      // remove completely empty rows
      .filter((row) => row.join('').length > 0)
      // add isDirty column to gridLayout as the last column
      .map((row) => gridLayout.concat({ id: 'isDirty', type: 'boolean' }).reduce((prev, curr, idx) => ({ ...prev, [curr.id]: row[idx] }), {})) // convert array of arrays to array of objects
      // add original row number, so that errors can be displayed in the correct row
      .map((row, idx) => ({ ...row, rowNumber: idx }))
      // if there is spreadExtraData, it means we need to spread its contents over each row (it has the same length as gridData with no 0 rows)
      .map((row, idx) => (spreadExtraData ? { ...row, ...spreadExtraData[idx] } : row))
  );
}
