/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { nanoid } from 'nanoid';
import { pensionTransaction } from '@monestry-dev/schema';
import { globalAccountsView, postData } from '../../redux/reducers/data';
import { setMessage } from '../../redux/actions/message';
import AccountLabel from '../../elements/AccountLabel';
import SublistOkCancel from './SublistOkCancel';

export default function BlueTransactionsCosts({ selectedOption, accountId, handleCloseAfterSave, handleCancel, linkedAttribute, transaction }) {
  const { t } = useTranslation('app', { keyPrefix: 'accountDetails.deposits' });
  const [loading, setLoading] = useState(false);
  const [localLinkedAttribute, setLocalLinkedAttribute] = useState(linkedAttribute); // intialise to the object from props (should there be an existing linkedAttribute already)
  const [createMatchingTransaction, setCreateMatchingTransaction] = useState(false);
  const [createMatchingTransactionError, setCreateMatchingTransactionError] = useState(null);
  const dispatch = useDispatch();
  const allAccounts = useSelector(globalAccountsView) || [];
  const accountIdsConnectedToThis = allAccounts.filter((account) => account.connectedDepositAccounts?.includes(accountId))?.map((account) => account.id);

  const accountsFilteredByConnectedAccounts = allAccounts
    .filter((account) => (accountIdsConnectedToThis ? accountIdsConnectedToThis?.includes(account.id) : true))
    .filter((account) => (selectedOption.id === 'investment-contribution' ? account.category === 'pension' : true)); // only show pension accounts for contributions

  async function handleSave() {
    // perform validation of the second POST transaction before starting the first POST transaction
    let matchingTransactionAccount;
    if (createMatchingTransaction) {
      // cast the pension transaction
      matchingTransactionAccount = allAccounts.find((x) => x.id === localLinkedAttribute.accountId);

      // validate the assumption:
      if (transaction.transactionCurrency !== matchingTransactionAccount.currency) {
        console.error(`Error: transaction currency ${transaction.transactionCurrency} does not match account currency ${matchingTransactionAccount.currency}`);
        setCreateMatchingTransactionError(t('transactionCurrencyMismatch'));
        return;
      }
    }

    const updatedTransaction = {
      ...transaction,
      label: selectedOption.id,
      // courtesy adjustment of costs (must be a negative number)
      amount: -Math.abs(transaction.amount),
      importFlag: 'put',
    };

    // check if a transaction is selected
    if (!localLinkedAttribute?.accountId) {
      dispatch(setMessage('symbolNotSelected'));
      return;
    }
    // save the assetId and accountId to the transaction tags
    updatedTransaction.tags = {
      ...(typeof updatedTransaction.tags === 'string' ? JSON.parse(updatedTransaction.tags) : updatedTransaction.tags),
      assetId: localLinkedAttribute.assetId,
      accountId: localLinkedAttribute.accountId,
    };

    setLoading(true);
    try {
      await dispatch(
        postData({
          data: [updatedTransaction],
          category: 'deposits',
          accountId,
        }),
      );
    } catch (err) {
      setLoading(false);
      dispatch(setMessage('dataUpdateError'));
    }

    // if the user wants to create a matching transaction, do so
    // assumption: the transaction in deposit account will be credited in the same currency as the pension account
    // (i.e. if the pension account is in USD, the deposit account transaction will have transactionCurrency of USD)
    // regardless of the currency of the deposit account
    if (createMatchingTransaction) {
      // cast the pension transaction
      const matchingTransactionId = nanoid();

      try {
        const newTransaction = pensionTransaction.cast({
          ...transaction,
          id: matchingTransactionId,
          accountId: matchingTransactionAccount.id,
          importFlag: 'post',
          upac: 1,
          // transaction.quantity is in asset currency, transaction.uptc * transaction.quantity is the transaction amount in transaction currency,
          // which becomes the new quantity on the pension account (whose account currency is the transaction currency, as assumed above)
          quantity: -Math.abs(transaction.uptc * transaction.quantity), // force negative
          // we also have the transaction amount in base currency, which is transaction.upbc * transaction.quantity and the new quantity
          // so where we calculate upbc as (transaction amount in base currency) / (account currency quantity)
          // because (transaction amount in base currency) = upbc * (account currency quantity)
          upbc: (transaction.upbc * transaction.quantity) / (transaction.uptc * transaction.quantity),
          // transaction currency is the asset currency (assumed)
          uptc: 1,
          label: 'pension-contribution',
          accountCurrency: matchingTransactionAccount.currency,
          assetCurrency: matchingTransactionAccount.currency,
          projectId: transaction.projectId,
        });
        await dispatch(
          postData({
            data: [newTransaction],
            category: 'pension',
            accountId: matchingTransactionAccount.id,
          }),
        );
        setLoading(false);
        handleCloseAfterSave();
      } catch (e) {
        console.error('BlueTransactionsInterestRent: error creating matching pension transaction', e);
        dispatch(setMessage('dataUpdateError'));
        // rollback the first transaction if the second one fails
        await dispatch(
          postData({
            data: [transaction],
            category: 'deposits',
            accountId,
          }),
        );
        setLoading(false);
      }
    } else {
      setLoading(false);
      handleCloseAfterSave();
    }
  }

  return (
    <>
      <div className="flex flex-col gap-2 overflow-y-auto" data-testid="blue-transactions-costs">
        {accountsFilteredByConnectedAccounts.map((x) => (
          // ^^ returns an array of account objects
          <button
            type="button"
            key={x.id}
            onClick={() => setLocalLinkedAttribute({
              ...localLinkedAttribute,
              accountId: x.id,
              assetId: x.id,
            })}
            // ^^ assetId added to enable mapping of account-level costs onto account also for stocks;
            // commonAssetView will map the account level costs to an accountId > assetId = accountId
            data-testid="sublist-account"
            className={`text-xs border border-gray-200 shadow-sm rounded-md p-2 flex justify-between gap-2 cursor-pointer
          ${x?.id === localLinkedAttribute?.accountId ? 'bg-brandBlue-500 text-white hover:bg-brandBlue-600 border-transparent' : 'bg-white text-gray-900 hover:bg-gray-50'}`}
          >
            <AccountLabel accountName={x?.name} category={x?.category} />
          </button>
        ))}
        {accountsFilteredByConnectedAccounts.length === 0 && <div className="text-sm text-gray-500 self-center mt-2">{t('noAccounts')}</div>}
      </div>
      <div className="space-y-2">
        {selectedOption.id === 'investment-contribution' && (
          <>
            {createMatchingTransactionError && <div className="text-red-500 text-xs">{createMatchingTransactionError}</div>}
            <div className="flex items-center pb-2 pl-1">
              <input className="text-gray-400" id="checkbox" type="checkbox" checked={createMatchingTransaction} onChange={() => setCreateMatchingTransaction(!createMatchingTransaction)} />
              <label htmlFor="checkbox" className="flex text-sm font-medium ml-2">
                {t('createMatchingTransaction')}
              </label>
            </div>
          </>
        )}
        <SublistOkCancel handleSave={handleSave} handleCancel={handleCancel} loading={loading} />
      </div>
    </>
  );
}
BlueTransactionsCosts.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  selectedOption: PropTypes.objectOf(PropTypes.any).isRequired,
  accountId: PropTypes.string.isRequired,
  linkedAttribute: PropTypes.object,
  handleCloseAfterSave: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  transaction: PropTypes.object.isRequired,
};
BlueTransactionsCosts.defaultProps = {
  linkedAttribute: {},
};
