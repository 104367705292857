import store from '../../store';
import { globalQuotesArrayView } from '../../redux/reducers/data';

export default function getCollisions(isolatedProjectId) {
  const state = store.getState();
  const isolatedQuotes = globalQuotesArrayView(state, isolatedProjectId);
  const globalQuotes = globalQuotesArrayView(state, undefined);
  const collisions = [];
  // globalQuotesView has also extrapolated / simulated quotes, so filter them out first; there may also be "null" quotes created for dates where other assets have quotes, so filter them out too
  isolatedQuotes.filter((q) => q.quote && q.date === q.quoteDate).forEach((isolatedQuote) => {
    globalQuotes.filter((q) => q.quote && q.date === q.quoteDate).forEach((globalQuote) => {
      if (isolatedQuote.date === globalQuote.date && isolatedQuote.assetId === globalQuote.assetId) {
        collisions.push({ isolatedQuote, globalQuote, selected: null });
      }
    });
  });
  // put them into state as an collidingQuotes array [ { isolatedQuote, globalQuote, selected: isolated | global | null  } ]

  return collisions;
}
