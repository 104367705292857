/* eslint-disable jsx-a11y/anchor-is-valid */
import { React } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Button from '../elements/Button';

/* This example requires Tailwind CSS v2.0+ */
export default function noPageFound() {
  const { t, i18n } = useTranslation(['site']);
  const navigate = useNavigate();

  return (
    <div className="bg-white h-[75vh] flex flex-col lg:relative">
      <div className="flex-grow flex flex-col">
        <main className="flex-grow flex flex-col bg-white">
          <div className="flex-grow mx-auto max-w-7xl w-full flex flex-col px-4 sm:px-6 lg:px-8">
            <div className="flex-shrink-0 my-auto py-16 sm:py-32">
              <p className="text-sm font-semibold text-brandBlue-500 uppercase tracking-wide">{t('site:page-not-found.header')}</p>
              <h1 className="mt-2 text-4xl font-black text-gray-900 tracking-tight sm:text-5xl">
                {t('site:page-not-found.title')}
              </h1>
              <p className="mt-2 text-base text-gray-500">{t('site:page-not-found.body')}</p>
              <div className="mt-6">
                <Button
                  text={t('site:page-not-found.link')}
                  noFrame
                  withAccent
                  onClick={() => navigate(-1)}
                />
              </div>
              <div className="mt-2">
                <Button
                  text={t('site:page-not-found.homePage')}
                  noFrame
                  withAccent
                  onClick={() => navigate(`/${i18n.language}/}`)}
                />
              </div>
            </div>
          </div>
        </main>
      </div>
      <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
        <img
          className="absolute inset-0 h-full w-full object-cover object-bottom"
          src="https://images.unsplash.com/photo-1470847355775-e0e3c35a9a2c?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1825&q=80"
          alt=""
        />
      </div>
    </div>
  );
}
