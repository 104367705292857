/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-no-bind */
/* This example requires Tailwind CSS v2.0+ */
import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Transition } from '@headlessui/react';
import { XCircleIcon, ExclamationCircleIcon, CheckCircleIcon } from '@heroicons/react/24/outline';
import { InformationCircleIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { setMessage } from '../redux/actions/message';

/**
/* Renders a Toast message. Can be used in the App > NotificationMessage to display app-wide toasts or in HeaderApp to display message history.
/*
/* @param {object} message - object of { message, extraMessage }; message is a string found in i18n.messages, extraMessage is a string passed in the action (usually error message)
/* @param {boolean} show - whether to show the toast (permanent true in HeaderApp, timed in App)
/* @param {function} handleClose - function to close the toast (only in App)
/*
/* It can be called with handleClose (to be displayed as a regular toast) or without (to be displayed in the notification list)
/* formatting has been adjusted accordingly
 */
export function Toast({ messageObject, show, handleClose }) {
  Toast.propTypes = {
    messageObject: PropTypes.object, // can be null when initialised, it won't be displayed anyway
    show: PropTypes.bool,
    handleClose: PropTypes.func,
  };
  Toast.defaultProps = {
    messageObject: { message: null },
    show: true,
    handleClose: undefined,
  };
  const { t } = useTranslation(['app'], { keyPrefix: 'messages' });
  const { message, extraMessage } = messageObject;

  const isToast = handleClose !== undefined;

  const messageTexts = t(message, { returnObjects: true });

  return (
    <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
      {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
      <Transition
        show={show}
        as={Fragment}
        enter="transform ease-out duration-300 transition"
        enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
        enterTo="translate-y-0 opacity-100 sm:translate-x-0"
        leave="transition ease-in duration-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div id="message" className={`max-w-sm w-full bg-white pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden ${isToast ? 'shadow-lg rounded-lg' : ''}`}>
          <div className="p-4">
            <div className="flex items-start">
              <div className="flex-shrink-0">
                {message && messageTexts.type === 'success' && <CheckCircleIcon className="h-7 w-7 text-green-500" aria-hidden="true" />}
                {message && messageTexts.type === 'warning' && <ExclamationCircleIcon className="h-7 w-7 text-yellow-400" aria-hidden="true" />}
                {message && messageTexts.type === 'error' && <XCircleIcon className="h-7 w-7 text-brandRed-500" aria-hidden="true" />}
                {message && messageTexts.type === 'info' && <InformationCircleIcon className="h-7 w-7 text-brandBlue-500" aria-hidden="true" />}
              </div>
              <div className="text-base ml-3 w-0 flex-1 pt-0.5">
                <p className="font-medium text-gray-900">{messageTexts.header}</p>
                <p className="mt-1 text-gray-500">{messageTexts.body || message}</p>
                {extraMessage && <p className="mt-1 text-gray-400">{extraMessage}</p>}
              </div>
              {/* if handleClose function is passed in props, display the X button which closes the toast */}
              {handleClose && (
                <div className="ml-4 flex-shrink-0 flex">
                  <button
                    type="button"
                    className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brandBlue-400"
                    onClick={handleClose}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </Transition>
    </div>
  );
}

/**
 * This component is permanently attached to the tree and is responsible for displaying the toasts.
 * It react to state changes of state.message.messages and displays the last message in the list. It disappears automatically after a few seconds.
 *
 * The message is a string which is defined in the i18n files as an object of { type, header, body } (handled in the Toast component).
 */
export default function NotificationMessageWrapper() {
  const { t } = useTranslation(['app']);

  const [show, setShow] = useState(false);

  const dispatch = useDispatch();
  const messages = useSelector((state) => state.message.messages) || [];
  // message is { message: <string>, extraMessage: <string> }; message is a string found in i18n.messages, extraMessage is a string passed in the action (usually error message)

  const messageObject = messages.at(-1);
  // const thereIsAMessage = messages && messages.length > 0;

  function handleClose() {
    setShow(false);
    setTimeout(() => {
      // After 500 ms (so that the user does not see null before the fade-out animation is ready) reset message value (kept for backward compatibility)
      dispatch(setMessage(null));
    }, 500);
  }

  // make the message disappear after a few seconds
  useEffect(() => {
    // let timeId;
    // let timeDisappear;
    // if this is an error, keep the message longer
    const timeToTimeout = t(`app:messages.${messageObject?.message}.type`) === 'error' ? 10000 : 5000;
    // if (thereIsAMessage) {
    setShow(true);
    const timeDisappear = setTimeout(() => {
      // first set the show value to false - this will trigger the fade-out animation
      setShow(false);
    }, timeToTimeout - 500);
    const timeId = setTimeout(() => {
      // then reset the message in state to not display an empty component
      dispatch(setMessage(null));
    }, timeToTimeout);
    // }

    return () => {
      clearTimeout(timeId);
      clearTimeout(timeDisappear);
    };
  }, [messages]);

  // handle initial state (no messages) - do not render anything
  if (messages && messages.length === 0) return true;

  return (
    <>
      {/* Global notification live region, render this permanently at the end of the document */}
      <div aria-live="assertive" className="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start z-[9999]">
        <Toast messageObject={messageObject} show={show} handleClose={handleClose} />
      </div>
    </>
  );
}
