const allDepositTransactions = {
  vals: [
    'amount',
  ],
  userId: 'jiZoVK1rpJEbfxwfX8LKJ',
  cols: [],
  dataScope: 'transactions',
  rows: [
    'Date (Day)',
    'id',
    'accountName',
    'otherParty',
    'description',
    'currency',
    'amount',
  ],
  rendererName: 'List',
  id: 'yAuSzwA-k5fA3DXW2O50M',
  name: 'All deposit transactions',
  readOnly: true,
  aggregatorName: 'Sum',
  hiddenRows: [1], // index of column in rows array, I know it says 'rows', but this is how the component works
};

export default allDepositTransactions;
