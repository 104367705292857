/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-redundant-roles */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { React } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Button from './Button';
import image from '../assets/Man_at_a_desk_4.png';

export default function hero() {
  const { t, i18n } = useTranslation(['site']);

  const navigate = useNavigate();

  return (
    <div className="relative bg-gray-50" data-testid="main-hero">
      <main className="lg:relative">
        <div className="mx-auto max-w-7xl w-full pt-16 pb-20 text-center lg:py-48 lg:text-left">
          <div className="px-4 lg:w-1/2 sm:px-8 xl:pr-16">
            <h1 className="text-4xl tracking-tight font-black text-gray-900 sm:text-5xl md:text-6xl lg:text-5xl xl:text-6xl">
              <span className="block xl:inline">{t('site:main.cta.line-1')}</span>
              {' '}
              <p />
              <span className="block text-brandBlue-600 xl:inline">{t('site:main.cta.line-2')}</span>
            </h1>
            <p className="mt-3 max-w-md mx-auto text-lg text-gray-500 leading-8 sm:text-xl md:mt-5 md:max-w-3xl">
              {t('site:main.cta.body-1')}
            </p>
            <p className="mt-3 max-w-md mx-auto text-lg text-gray-500 leading-8 sm:text-xl md:mt-5 md:max-w-3xl">
              {t('site:main.cta.body-2')}
            </p>
            <div className="mt-10 sm:flex sm:justify-center lg:justify-start">
              <div className="rounded-md shadow">
                <Button
                  text={t('site:general.findoutmore')}
                  onClick={() => navigate(`${i18n.language}/features`)}
                  withAccent
                  size="xl"
                />
              </div>
              <div className="mt-3 sm:mt-0 sm:ml-6">
                <Button
                  text={t('site:general.getdemo')}
                  onClick={() => navigate(`${i18n.language}/demo`)}
                  size="xl"
                  noFrame
                />
              </div>
            </div>
          </div>
        </div>
        <div className="relative w-full h-64 sm:h-72 md:h-96 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 lg:h-full">
          <img
            className="absolute inset-0 w-full h-full object-cover"
            // src="https://images.unsplash.com/photo-1520333789090-1afc82db536a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2102&q=80"
            src={image}
            alt="a satisfied Monestry user"
          />
        </div>
      </main>
    </div>
  );
}
