/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { Switch } from '@headlessui/react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/20/solid';
import { updateCognitoSettings } from '../../redux/actions/user';
import Button from '../../elements/Button';
import { setMessage } from '../../redux/actions/message';

dayjs.extend(utc);

export default function InflationRate({ setIsDirty }) {
  const [updating, setUpdating] = useState(false);

  const selectUserSettings = useSelector((state) => state.user.profile.settings);

  const dispatch = useDispatch();
  const { t } = useTranslation('app', { keyPrefix: 'settings.simulations' });

  // default settings updated in getSettings (centrally)

  const {
    register,
    control,
    watch,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
    getFieldState,
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      inflation: selectUserSettings.inflation,
      inflationRate: selectUserSettings.inflationRate,
    },
  }); // intialised after useEffect fetches data from Amplify store

  // keep isDirty state in sync with parent
  useEffect(() => {
    setIsDirty(isDirty);
  }, [isDirty]);

  async function onSubmit(data) {
    // update global settings in cognito
    if (isDirty) {
      setUpdating(true);

      try {
        await dispatch(
          updateCognitoSettings({
            ...(getFieldState('inflation').isDirty && { inflation: data.inflation }),
            ...(getFieldState('inflationRate').isDirty && { inflationRate: Number(data.inflationRate) }),
          }),
        );
        reset(data);
        dispatch(setMessage('dataUpdatedSuccessfully'));
      } catch (error) {
        console.error('Error updating cognito settings', error);
        dispatch(setMessage('dataUpdateError'));
      }

      setUpdating(false);
    }
  }

  return (
    <div className="text-sm space-y-3 sm:grid sm:grid-cols-3 sm:items-start sm:justify-items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
      <div>
        <p>{t('inflation')}</p>
        <p className="mt-1 text-xs text-gray-400">{t('inflationEnabledDescription')}</p>
      </div>
      <div className="sm:max-w-sm">
        <Controller
          control={control}
          name="inflation"
          render={({ field }) => (
            <Switch.Group as="div" className="flex items-center justify-start w-full">
              <Switch.Label as="span" className="mr-3">
                <span className="text-sm font-medium text-gray-500">{t('disabled')}</span>
              </Switch.Label>
              <Switch
                {...field}
                checked={field.value}
                id="settings-planning-inflation-switch"
                onChange={field.onChange}
                className={`${field.value ? 'bg-brandBlue-500' : 'bg-gray-200'} relative inline-flex flex-shrink-0 h-6 w-11 border-2
                      border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brandBlue-600`}
              >
                <span
                  aria-hidden="true"
                  className={`${field.value ? 'translate-x-5' : 'translate-x-0'}
                          pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200`}
                />
              </Switch>
              <Switch.Label as="span" className="ml-3">
                <span className="text-sm font-medium text-gray-500">{t('enabled')}</span>
              </Switch.Label>
            </Switch.Group>
          )}
        />
        {watch('inflation') && (
          <div className="mt-4 space-y-3">
            <p className="text-gray-700">{t('simulatedInflationRate')}</p>
            <input
              type="number"
              step="0.01"
              name="inflationRate"
              id="inflationRate"
              {...register('inflationRate', { valueAsNumber: true })}
              className="mt-0.5 flex-1 focus:ring-brandBlue-400 focus:border-brandBlue-400 block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
            />
            <p className="mt-1 text-gray-400 text-xs">
              {t('averageInflation')}
              <a href="https://www.macrotrends.net/countries/WLD/world/inflation-rate-cpi" className="text-gray-400 text-xs underline">
                {` ${t('source')}`}
                <ArrowTopRightOnSquareIcon className="h-3 w-3 inline" />
              </a>
            </p>
            {errors.inflationRate && (
              <div className="pt-2 sm:text-sm text-brandRed-500 max-w-fit">
                <span>{errors.inflationRate.message}</span>
              </div>
            )}
          </div>
        )}
      </div>
      {isDirty && (
        <div className="flex justify-end space-x-2 sm:col-start-2 mt-4">
          <Button type="button" onClick={handleSubmit(onSubmit)} text={t('save')} spinnerOn={updating} id="settings-planning-save" withAccent size="lg" />
        </div>
      )}
    </div>
  );
}
InflationRate.propTypes = {
  setIsDirty: PropTypes.func.isRequired,
};
