/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/no-unknown-property */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { Auth } from 'aws-amplify';
import { setAlert } from '../../redux/actions/message';
import { InputPassword1, InputPassword2 } from '../signUp/SignUp1';
import MiniSpinner from '../../misc/MiniSpinner';

export default function NewPasswordPane({ setDisplayedPane, recoveryEmail }) {
  const { isLoggedIn } = useSelector((state) => state.user);

  const [showPasswordTip, setShowPasswordTip] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loginError, setLoginError] = useState(undefined);

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const useFormObject = useForm({ mode: 'onBlur', defaultValues: { password: '', repeatPassword: '', code: '' } }); // set as subcomponent prop and unpack there, see InternalUserForm
  const { formState, register, watch } = useFormObject;
  const { errors } = formState;

  const password = useRef({});
  password.current = watch('password', '');
  const repeatPassword = useRef({});
  repeatPassword.current = watch('repeatPassword', '');
  const code = watch('code', '');

  async function handleSubmit(e) {
    e.preventDefault();
    e.persist(); // see: https://medium.com/@ian.mundy/async-event-handlers-in-react-a1590ed24399

    if (!isLoggedIn) {
      setLoading(true);
      setLoginError(null);

      try {
        setLoading(true);
        await Auth.forgotPasswordSubmit(recoveryEmail, String(code), password.current);
        dispatch(setAlert('newPasswordSuccess', 'newPasswordPane', () => {
          console.log('about to go back to login');
          setDisplayedPane('login');
        }));
      } catch (err) {
        setLoading(false);
        setLoginError(t('site:login.unableToResetPassword'));
        console.log('error code', err);
      }
    }
  }

  return (
    <div className="mx-auto w-full max-w-sm lg:w-96">
      <div>
        {(loginError) ? <h3 className="text-brandRed-500" id="loginIncorrectError">{t(`site:login.${loginError}`)}</h3> : null}
      </div>
      <div className="mt-8">
        <div className="mt-6">
          <form onSubmit={handleSubmit} className="space-y-16">
            <div>
              <h2 className="mt-6 text-3xl font-extrabold text-gray-900">
                {t('site:login.enterNewPassword')}
              </h2>
              <p className="mt-4 text-sm font-medium text-gray-500">
                {t('site:login.enterConfirmationCodeEmail.tip')}
              </p>
            </div>
            <div>
              <label
                htmlFor="code"
                className="mb-1 block text-sm font-medium text-gray-700"
              >
                {t('site:login.enterConfirmationCodeEmail.label')}
              </label>
              <input
                className="flex-1 focus:ring-brandBlue-400 focus:border-brandBlue-400 block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
                type="number"
                {...register('code', {
                  required: t('login.fieldRequired'),
                  valueAsNumber: t('site:login.enterConfirmationCodeEmail.error'),
                  minLength: { value: 6, message: t('site:login.enterConfirmationCodeEmail.error') },
                  maxLength: { value: 6, message: t('site:login.enterConfirmationCodeEmail.error') },
                })}
              />
              <div className="pt-2 sm:text-sm text-brandRed-500">
                {errors?.code && <span>{errors?.code.message}</span>}
              </div>
            </div>
            <div>
              <label
                htmlFor="password"
                className="mb-1 block text-sm font-medium text-gray-700"
              >
                {t('site:register.signUp1.password.label')}
              </label>
              <InputPassword1 useFormObject={useFormObject} setShowPasswordTip={setShowPasswordTip} repeatPassword={repeatPassword} />
              <div className={showPasswordTip ? 'pt-2 text-gray-700 sm:text-sm' : 'hidden'}>
                <p>{t('site:register.signUp1.password.hint')}</p>
              </div>
              <div className="pt-2 sm:text-sm text-brandRed-500">
                {errors?.password && <span>{errors?.password.message}</span>}
              </div>
              <label
                htmlFor="repeatPassword"
                className="mt-6 mb-1 block text-sm font-medium text-gray-700"
              >
                {t('site:register.signUp1.repeatPassword.label')}
              </label>
              <InputPassword2 useFormObject={useFormObject} password={password} />
              <div className="pt-2 sm:text-sm text-brandRed-500">
                {errors?.repeatPassword && <span>{errors?.repeatPassword.message}</span>}
              </div>
            </div>
            <div>
              <button
                type="submit"
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-semibold text-white bg-brandBlue-500 hover:bg-brandBlue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brandBlue-500"
                id="submit"
                disabled={loading}
              >
                {loading ? <MiniSpinner className="h-4 w-4 animate-spin text-white" /> : (<span>{t('site:general.submit')}</span>)}
              </button>
              <button
                // type="submit"
                className="mt-6 w-full flex justify-center py-2 px-4 border rounded-md shadow-sm text-sm font-semibold outline-brandBlue-500 text-brandBlue-500 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brandBlue-500"
                disabled={loading}
                onClick={() => setDisplayedPane('login')}
              >
                {t('site:general.cancel')}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
NewPasswordPane.propTypes = {
  setDisplayedPane: PropTypes.func.isRequired,
};
