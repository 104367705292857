/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Slider from 'rc-slider';
import raf from 'rc-util/lib/raf';
import Tooltip from 'rc-tooltip';

function TooltipWrapper(props) {
  const { i18n, t } = useTranslation();

  const {
    value,
    valuesArray,
    children,
    visible,
    tipFormatter = (val) => `${new Date(props.valuesArray[val]).toLocaleDateString('de', { month: 'short', timeZone: 'UTC' }).substring(0, 3)}
    ${new Date(props.valuesArray[val]).toLocaleDateString('de', { year: 'numeric', timeZone: 'UTC' })}`,
    ...restProps
  } = props;

  const tooltipRef = React.useRef();
  const rafRef = React.useRef(null);

  function keepAlign() {
    rafRef.current = raf(() => {
      tooltipRef.current?.forceAlign();
    });
  }

  function cancelKeepAlign() {
    raf.cancel(rafRef.current);
  }

  React.useEffect(() => {
    if (visible) {
      keepAlign();
    } else {
      cancelKeepAlign();
    }

    return cancelKeepAlign;
  }, [value, visible]);

  return (
    <Tooltip
      placement="top"
      overlay={tipFormatter(value)}
      overlayInnerStyle={{ minHeight: 'auto' }}
      ref={tooltipRef}
      visible={visible}
      {...restProps}
    >
      {children}
    </Tooltip>
  );
}

export const handleRender = (node, props) => (
  <TooltipWrapper value={props.value} visible={props.dragging}>
    {node}
  </TooltipWrapper>
);

function TooltipSlider({
  tipFormatter,
  tipProps,
  valuesArray,
  ...props
}) {
  const tipHandleRender = (node, handleProps) => (
    <TooltipWrapper
      value={handleProps.value}
      visible={handleProps.dragging}
      tipFormatter={tipFormatter}
      valuesArray={valuesArray}
      {...tipProps}
    >
      {node}
    </TooltipWrapper>
  );

  return (
    <>
      <div className="sr-only" id="slider-label-value-hidden">
        {new Date(valuesArray?.[props?.value]).toLocaleDateString('de', { year: 'numeric', month: '2-digit', timeZone: 'UTC' })}
      </div>
      <Slider {...props} handleRender={tipHandleRender} />
    </>
  );
}

export default TooltipSlider;
