import React from 'react';
import PropTypes from 'prop-types';
import { decor } from './tileDecor';

export default function AccountLabel({ accountName, category, styleClasses }) {
  const colorStyle = decor[category] ? `${decor[category].color.bg} ${decor[category].color.text}` : 'bg-gray-100 text-gray-500';

  return <span className={`inline-flex items-center rounded-md px-2.5 py-0.5 text-xs font-medium uppercase ${colorStyle} ${styleClasses}`}>{accountName || '?'}</span>;
}
AccountLabel.propTypes = {
  accountName: PropTypes.string.isRequired,
  category: PropTypes.oneOf(['deposits', 'stocks', 'realEstate', 'objectsOfValue', 'pension', 'metals', 'loans', 'unlistedShares', 'crypto']).isRequired,
  styleClasses: PropTypes.string,
};
AccountLabel.defaultProps = {
  styleClasses: '',
};
