/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-return-assign */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Combobox } from '@headlessui/react';
import { LinkIcon, ScissorsIcon, XMarkIcon } from '@heroicons/react/20/solid';
import { DocumentDuplicateIcon, TicketIcon } from '@heroicons/react/24/outline';
import { allTransactions } from '../../redux/reducers/globalSelectors/overarching';
import { globalAccountsView } from '../../redux/reducers/globalSelectors';
import getCurrentBreakpoint from '../../misc/getCurrentBreakpoint';
import Button from '../../elements/Button';
import Split from './TransactionContextSplit';
import LinkToProject from './TransactionContextLinkToProject';
import CopyToProject from './TransactionContextCopyToProject';
import SplitAndLabelLoan from './TransactionContextSplitLabelLoan';
import Delete from './TransactionContextDeleteTransaction';

function isMobileLayout(breakpoint) {
  return breakpoint === 'xs' || breakpoint === 'sm' || !breakpoint;
}

export default function TransactionContextMenu({ transactionId }) {
  const { t } = useTranslation('app', { keyPrefix: 'accountDetails.splitMenu' });
  const { t: tG } = useTranslation('app', { keyPrefix: 'general' });

  const selectTransactions = useSelector((state) => allTransactions(state));
  const transaction = selectTransactions.find((tx) => tx.id === transactionId);
  // check if the transaction has been split before (if it has parentId); and if so, find all the split transactions for the same parentId
  let splitTransactions = [];
  if (transaction.parentId) {
    splitTransactions = selectTransactions.filter((tx) => tx.parentId === transaction.parentId);
  }
  const category = transaction?.category;

  const selectAccounts = useSelector(globalAccountsView);
  const thisAccountId = transaction.accountId;
  // each account has a connectedDepositAccounts array, which is an array of accountIds
  // find all accounts which have thisAccount in that array
  const loanAccountsConnectedToThisOne = selectAccounts.filter((account) => account.category === 'loans' && account.connectedDepositAccounts?.includes(thisAccountId));
  // console.log('DEBUG', loanAccountsConnectedToThisOne);

  const [currentPanel, setCurrentPanel] = useState(['realEstate', 'metals'].includes(category) ? 'linkToProject' : 'split');
  const [signalHandleSubmit, setSignalHandleSubmit] = useState(false);

  const [currentBreakpoint, setCurrentBreakpoint] = useState('md'); // stores current breakpoint
  const [panesToShow, setPanesToShow] = useState([true, true]); // [menu, submenu]

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }

  function handleCancel(closeDialog = false) {
    // if we are in the mobile layout, we need to go back from submenu to menu
    if (!closeDialog) setPanesToShow([true, false]);
    else {
      window.dispatchEvent(
        new CustomEvent('setDialog', {}), // event with no .detail should close the dialog
      );
    }
  }

  const options = [
    {
      id: 'linkToProject',
      Icon: LinkIcon,
      name: t('showInProject'),
    },
  ];

  if (!['metals', 'realEstate', 'unlistedShares'].includes(category)) {
    options.push({
      id: 'split',
      Icon: ScissorsIcon,
      name: t('split'),
    });
  }

  // if there is a loan account among those connected to this account, show the loan option
  if (loanAccountsConnectedToThisOne.length > 0) {
    options.push({
      id: 'splitAndLabelLoan',
      Icon: TicketIcon,
      name: t('splitAndLabelLoan'),
    });
  }

  // as last item comes Delete
  options.push(
    {
      id: 'copyToProject',
      Icon: DocumentDuplicateIcon,
      name: t('copyAsSimulated'),
    },
    {
      id: 'delete',
      Icon: XMarkIcon,
      name: t('delete'),
    },
  );

  let Component;
  switch (currentPanel) {
    case 'copyToProject':
      Component = CopyToProject;
      break;
    case 'linkToProject':
      Component = LinkToProject;
      break;
    case 'splitAndLabelLoan':
      Component = SplitAndLabelLoan;
      break;
    case 'delete':
      Component = Delete;
      break;
    case 'split':
    default:
      Component = Split;
  }

  // INITIALISE COMPONENT

  useEffect(() => {
    // Handle window resize and update breakpoint state
    const handleResize = () => {
      const newBreakpoint = getCurrentBreakpoint();
      setCurrentBreakpoint(newBreakpoint);
      return newBreakpoint;
    };

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Set initial breakpoint
    const newBreakpoint = handleResize();

    // set panes depending on breakpoint
    if (isMobileLayout(newBreakpoint)) setPanesToShow([true, false]); // else use default

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // HANDLE MENU OPTION CHANGE

  function handleMenuOptionChange(option) {
    // if this runs as a result of user changing the option in Combobox, option is the new option
    // if this runs as a result of clicking on the currently selected option, skip setting the state
    if (option) setCurrentPanel(option);

    // set panes depending on whether the selected option has a next level or not (and whether we are on mobile)
    if (isMobileLayout(currentBreakpoint)) setPanesToShow([false, true]);
    else setPanesToShow([true, true]);
  }

  return (
    <div
      className={`mx-auto max-w-3xl transform ${
        isMobileLayout(currentBreakpoint) && 'divide-x divide-gray-100'
      } overflow-hidden rounded-xl bg-white p-4 shadow-2xl ring-1 ring-black ring-opacity-5 transition-all grid ${isMobileLayout(currentBreakpoint) ? 'grid-cols-1' : 'grid-cols-3 gap-4'}`}
    >
      <Combobox as="div" value={currentPanel} onChange={handleMenuOptionChange} id="context-menu-main">
        <Combobox.Options as="div" static hold className="flex divide-x divide-gray-100 col-span-1">
          {panesToShow[0] && (
            <div className={classNames('sm:max-h-[30rem] min-w-0 flex-auto scroll-py-4 overflow-y-auto p-2')}>
              <div className="-mx-2 text-sm text-gray-700">
                {options.map((item) => (
                  <Combobox.Option
                    as="div"
                    key={item.id}
                    value={item.id}
                    data-testid={item.id}
                    className={classNames(
                      'flex cursor-pointer select-none items-center rounded-md p-2',
                      item.id === currentPanel ? 'bg-brandBlue-500 hover:bg-brandBlue-600 text-white' : 'bg-white hover:bg-gray-100 text-gray-900',
                    )}
                  >
                    <>
                      <item.Icon className="h-6 w-6 flex-none rounded-full" />
                      <span className="ml-3 flex-auto truncate">{item.name}</span>
                    </>
                  </Combobox.Option>
                ))}
              </div>
            </div>
          )}
        </Combobox.Options>
        {panesToShow[0] && isMobileLayout(currentBreakpoint) && (
          <div className="flex justify-center mt-2" id="context-menu-mobile-cancel-button">
            <Button text={tG('cancel')} size="xl" onClick={handleCancel} />
          </div>
        )}
      </Combobox>
      {panesToShow[1] && (
        <div className={`sm:h-[30rem] flex-col justify-between overflow-y-auto overflow-x-hidden flex ${!isMobileLayout(currentBreakpoint) && 'pl-4'} col-span-2`} id="context-menu-subpanel">
          {currentPanel && (
            <Component
              category={category}
              transaction={transaction}
              splitTransactions={splitTransactions}
              signalHandleSubmit={signalHandleSubmit}
              setSignalHandleSubmit={setSignalHandleSubmit}
              loanAccountsConnectedToThisOne={loanAccountsConnectedToThisOne}
            />
          )}
          <div className="grid grid-cols-2 gap-4 mt-8">
            {/* cancel in desktop layout must close the entire dialog and in mobile layout - just close the submenu */}
            <Button text={tG('cancel')} size="xl" onClick={isMobileLayout(currentBreakpoint) ? handleCancel : () => handleCancel(true)} />
            <Button
              text={tG('save')}
              size="xl"
              withAccent
              onClick={() => {
                setSignalHandleSubmit(true);
                setTimeout(() => {
                  setSignalHandleSubmit(false);
                }, 100);
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
}
TransactionContextMenu.propTypes = {
  transactionId: PropTypes.string.isRequired,
};
