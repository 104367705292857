/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Button from './Button';

export default function mainSection2() {
  const { t, i18n } = useTranslation(['site']);
  const navigate = useNavigate();

  return (
    <div className="bg-white" data-testid="main-section2">
      <div className="relative overflow-hidden">
        <main>
          {/* Security banner */}
          <div className="relative bg-brandBlue-900">
            <div className="relative h-56 bg-brandBlue-500 sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2">
              <img
                className="w-full h-full object-cover"
                src="https://images.unsplash.com/photo-1525130413817-d45c1d127c42?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1920&q=60&sat=-100"
                alt=""
              />
              <div
                aria-hidden="true"
                className="absolute inset-0 bg-gradient-to-r from-brandViolet-600 to-brandBlue-600 mix-blend-multiply"
              />
            </div>
            <div className="relative mx-auto max-w-md px-4 py-12 sm:max-w-7xl sm:px-6 sm:py-20 md:py-28 lg:px-8 lg:py-32">
              <div className="md:ml-auto md:w-1/2 md:pl-10">
                <h2 className="text-base font-black uppercase tracking-wider text-gray-300">
                  {t('site:main.security.header')}
                </h2>
                <p className="mt-2 text-white text-3xl font-black tracking-tight sm:text-4xl">{t('site:main.security.title')}</p>
                <p className="mt-3 text-lg text-gray-300">
                  {t('site:main.security.body')}
                </p>
                <div className="mt-8">
                  <div className="inline-flex rounded-md shadow">
                    <Button
                      text={t('site:general.findoutmore')}
                      noFrame
                      onDarkBackground
                      onClick={() => navigate(`${i18n.language}/blog/new-features/security-at-monestry#blog-title`)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
