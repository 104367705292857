import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { nanoid } from 'nanoid';
import { useTranslation } from 'react-i18next';
import { PlusIcon } from '@heroicons/react/24/outline';
import { postProject } from '../../redux/actions/projects';
import ProgressSlider2 from './ProgressSlider2';
import ProgressRoi from './ProgressRoi';
import MiniSpinner from '../../misc/MiniSpinner';
import ProgressGoal from './ProgressGoal';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

function AddProjectTile() {
  const { t } = useTranslation();

  const projectTemplate = {
    id: nanoid(),
    name: t('app:projects.newProject'),
    settings: {
      progressIndicator: 'duration',
      isIsolated: false,
    },
    comments: [],
  };

  const dispatch = useDispatch();

  return (
    <button
      type="button"
      id="add-new-project-button"
      // on click update the Dashboard addMode state so that the AddAccount component is displayed and the current category is passed as a prop
      onClick={(e) => {
        dispatch(postProject(projectTemplate));
      }}
      className="relative col-span-1 grid columns-1 justify-center w-full max-w-[18rem] border-dashed border-2 border-gray-300 sm:min-w-full rounded-md cursor-pointer hover:bg-gray-50"
    >
      <div className="py-6 align-middle">
        {/* if global loading is true, caller is from the current category, but it is a post transaction, show spinner */}
        {/* it could also be a delete transaction triggered from the Edit Mode, which we only want to show spinner at the deleted tile for */}
        <PlusIcon className="mx-auto h-9 w-9 text-gray-400" />
        {/* {signal
          ? <SpinnerRight className="w-12 h-12 text-gray-400 opacity-100 animate-spin" />
          : <PlusIcon className="mx-auto h-9 w-9 text-gray-400" />} */}
      </div>
    </button>
  );
}

export default function ProjectList({ setProjectId, editMode, projectsLoading }) {
  const projects = useSelector((state) => state.projects);
  const signal = useSelector((state) => state.message.signal);

  const { t } = useTranslation('app');

  return (
    <section id="project-list" className="rounded-md bg-white overflow-hidden shadow p-6">
      <h2 className="text-xl font-bold mb-4">{t('header.projects')}</h2>
      <ul className="mt-3 grid grid-cols-1 gap-5 sm:gap-6">
        {[...projects]
          .sort((a, b) => a.name.localeCompare(b.name)) // sort mutates the array in-place
          .map((project) => (
            <li
              key={project.id}
              id={`project-tile-${project.name.replace(/[^a-zA-Z0-9]/g, '')}`}
              // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
              role="button"
              className={`col-span-1 flex rounded-md shadow-sm w-full cursor-pointer ${signal[project.id] === 'deleteProject' ? 'opacity-50 pointer-events-none' : ''}`}
              onClick={() => setProjectId(project.id)}
              onKeyPress={() => {}}
            >
              <div className={classNames('bg-brandViolet-600', 'flex-shrink-0 flex items-center justify-center w-3 text-white text-sm font-medium rounded-l-md')} />
              <div className="flex flex-grow items-center truncate rounded-r-md border-t border-r border-b border-gray-200 bg-white hover:bg-gray-50">
                <div className="flex-grow px-4 py-2 text-sm sm:grid sm:grid-cols-6 sm:space-x-4 lg:grid-cols-1 lg:space-x-0 xl:grid-cols-6 xl:space-x-4">
                  <div className="text-[1rem] transform scale-y-110 sm:scale-y-100 sm:text-lg font-semibold text-gray-900 hover:text-gray-600 col-span-2">{project.name}</div>
                  <div className="w-full h-16 col-span-4 flex items-center">
                    {project.settings.progressIndicator === 'duration' && <ProgressSlider2 projectId={project.id} />}
                    {project.settings.progressIndicator === 'roi' && <ProgressRoi project={project} />}
                    {project.settings.progressIndicator === 'goal' && <ProgressGoal project={project} />}
                  </div>
                </div>
              </div>
            </li>
          ))}
        {(editMode === true || (!projectsLoading && projects && projects.length === 0)) && <AddProjectTile />}
      </ul>
      {projectsLoading && (
        <div className="w-full h-20 flex items-center justify-center">
          <MiniSpinner className="w-6 h-6 text-gray-500 animate-spin " />
        </div>
      )}
    </section>
  );
}
ProjectList.propTypes = {
  setProjectId: PropTypes.func.isRequired,
  editMode: PropTypes.bool.isRequired,
  projectsLoading: PropTypes.bool.isRequired,
};
