import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { XMarkIcon } from '@heroicons/react/24/solid';
import TemplateList from './TemplateList';
import TemplateParametersForm from './TemplateParametersForm';

export default function ApplyProjectTemplate({ setTemplateDialog, project }) {
  const [selectedTemplate, setSelectedTemplate] = useState(undefined);

  const { t } = useTranslation('app', { keyPrefix: 'projects' });

  return (
    <div className="absolute bg-opacity-90 bg-gray-300 z-[100] p-4 h-full w-full top-0 left-0">
      {/* <div className="p-4 flex flex-col items-center justify-center h-[85vh] overflow-auto"> */}
      <form
        onSubmit={() => { }}
        className="bg-white rounded-md shadow-lg p-8 relative flex flex-col items-center justify-start h-[85vh]"
      >
        <button
          type="button"
          onClick={(e) => { setTemplateDialog(false); }}
          className="absolute top-4 right-4 cursor-pointer z-10"
        >
          <XMarkIcon
            className="w-6 h-6 text-gray-500 hover:text-gray-600"
          />
        </button>
        <div className="inline self-start">
          <h2 className="text-3xl font-bold text-gray-900">{t('applyTemplate')}</h2>
          <p className="mt-2 text-base font-normal text-gray-500">{t('applyTemplateDescription')}</p>
        </div>
        <div className="mt-8 pr-4 overflow-y-auto scrollbar-thin scrollbar-track-rounded scrollbar-thumb-rounded scrollbar-track-gray-200 scrollbar-thumb-gray-400">
          {!selectedTemplate
            ? (<TemplateList setSelectedTemplate={setSelectedTemplate} />)
            : (<TemplateParametersForm selectedTemplate={selectedTemplate} setTemplateDialog={setTemplateDialog} project={project} />)}
        </div>
      </form>
      {/* </div> */}
    </div>
  );
}
ApplyProjectTemplate.propTypes = {
  setTemplateDialog: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  project: PropTypes.object.isRequired,
};
