import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { baselineNetWorth } from '../../redux/reducers/data';
import SumWithLabel from './SumWithLabel';
import BaselineDropdown from './BaselineDropdown';

export default function BaselineKPIElement({ calendarState, setCalendarState, baseCurrency, isViewer }) {
  // get global selectors
  const selectCurrentNetWorthBaseline = isViewer ? useSelector((state) => state.viewer.kpis.baselineNetWorth) : useSelector(baselineNetWorth);

  const optionsLocaleCurrency = {
    style: 'currency',
    currency: baseCurrency,
    maximumFractionDigits: 0,
  };

  return (
    <div className="flex flex-col justify-start">
      <SumWithLabel id="previousValue" stat={selectCurrentNetWorthBaseline.toLocaleString('de', optionsLocaleCurrency)} isViewer={isViewer} />
      <BaselineDropdown calendarState={calendarState} setCalendarState={setCalendarState} isViewer={isViewer} />
    </div>
  );
}
BaselineKPIElement.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  calendarState: PropTypes.object,
  setCalendarState: PropTypes.func,
  baseCurrency: PropTypes.string.isRequired,
  isViewer: PropTypes.bool,
};
BaselineKPIElement.defaultProps = {
  calendarState: {},
  setCalendarState: {},
  isViewer: false,
};
