// instructions: leave 1 account in accountName filter and 12 most recent months with data in date filter
const transactions12m = {
  vals: [
    'amount',
  ],
  cols: [
    'accountName',
    'Transaction sign',
  ],
  dataScope: 'transactions',
  rows: [
    'Date (Month)',
  ],
  rendererName: 'Bar chart, vertical, diverging, stacked',
  rendererType: 'nivo', // 'nivo' requires a height property to be set on its parent to be displayed
  id: 'transactionsByMonthxx',
  name: 'Deposits, transactions stacked by month',
  aggregatorName: 'Sum',
  readOnly: true,
};

export default transactions12m;
