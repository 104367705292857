/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { RadioGroup } from '@headlessui/react';
import { ArrowTopRightOnSquareIcon, CheckIcon } from '@heroicons/react/20/solid';
import getProducts from '../../misc/products';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

// buttonFunction is the function that is called when a button is clicked; it will receive tierId ('tier-1') and tierType ('xl') as arguments
// selectedTier is the tierId of the tier that should be highlighted
export default function PricingComponent({ buttonFunction, selectedTier }) {
  const [frequency, setFrequency] = useState('annually'); // 'annually' | 'monthly'

  const { t } = useTranslation(['site'], { keyPrefix: 'pricing' });

  const products = getProducts();
  const productsFilteredByAnnualBilling = products.filter((product) => product.billingFrequency === frequency);

  return (
    <>
      <div className="mt-16 flex justify-center">
        <RadioGroup value={frequency} onChange={setFrequency} className="grid grid-cols-2 gap-x-1 rounded-md p-1 text-center text-sm font-semibold leading-5 ring-1 ring-inset ring-gray-200">
          <RadioGroup.Label className="sr-only">Payment frequency</RadioGroup.Label>
          {['annually', 'monthly'].map((option) => (
            <RadioGroup.Option key={option} value={option} className={({ checked }) => classNames(checked ? 'bg-brandBlue-500 text-white' : 'text-gray-500', 'cursor-pointer rounded-md px-3 py-2')}>
              <span>{t(option)}</span>
            </RadioGroup.Option>
          ))}
        </RadioGroup>
      </div>
      <div className="isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-8 md:max-w-2xl md:grid-cols-2 lg:max-w-4xl xl:mx-0 xl:max-w-none xl:grid-cols-4">
        {productsFilteredByAnnualBilling.map((product) => (
          <div key={product.tierId} className={classNames(selectedTier === product.tierId ? 'ring-2 ring-brandBlue-500' : 'ring-1 ring-gray-200', 'rounded-3xl p-8')}>
            <h3 id={product.tierId} className={classNames(selectedTier === product.tierId ? 'text-brandBlue-500' : 'text-gray-900', 'text-xl font-semibold leading-8')}>
              {t(`products.${product.type}.title`)}
            </h3>
            <p className="mt-4 text-sm leading-6 text-gray-600">{t(`products.${product.type}.description`)}</p>
            <p className="mt-6 flex items-baseline gap-x-1">
              <span className="text-2xl font-bold tracking-tight text-gray-900">{product.monthlyPrice.toFixed(2)}</span>
              <span className="text-sm font-semibold leading-6 text-gray-500">{`€${t('products.frequency')}`}</span>
            </p>
            <button
              aria-describedby={product.tierId}
              type="button"
              onClick={() => buttonFunction(product.tierId, product.type, frequency)}
              className={classNames(
                selectedTier === product.tierId ? 'bg-brandBlue-500 text-white shadow-sm hover:bg-brandBlue-400' : 'text-brandBlue-500 ring-1 ring-inset ring-indigo-200 hover:ring-indigo-300',
                // eslint-disable-next-line max-len
                'mt-6 w-full block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brandBlue-500',
              )}
            >
              {t(`products.${product.type}.cta`)}
            </button>
            <ul className="mt-8 space-y-3 text-sm leading-6 text-gray-600">
              {t(`products.${product.type}.features`, { returnObjects: true }).map((feature) => (
                <li key={feature} className="flex gap-x-3 leading-5">
                  <CheckIcon className="h-6 w-5 flex-none text-brandBlue-500" aria-hidden="true" />
                  {feature}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>

      <p className="mt-4 text-gray-500 text-sm col-span-2 col-start-1">
        <span>{t('automaticUpdatesDisclaimer1')}</span>
        <a href={t('linkToAutoUpdates')} target="_blank" className="font-medium text-brandBlue-500 hover:text-brandBlue-400" rel="noreferrer">
          <span>{t('automaticUpdatesDisclaimer2')}</span>
          <ArrowTopRightOnSquareIcon className="inline-block w-4 h-4 ml-1" aria-hidden="true" />
        </a>
      </p>
    </>
  );
}
PricingComponent.propTypes = {
  buttonFunction: PropTypes.func.isRequired,
  selectedTier: PropTypes.string,
};
PricingComponent.defaultProps = {
  selectedTier: null,
};
