import { ResourceType } from '../../utils/common-utils';
export var defaultIgnore = function (entry) {
    return entry.entryType === 'resource' && !/^https?:/.test(entry.name);
};
export var defaultPerformancePluginConfig = {
    eventLimit: 10,
    ignore: defaultIgnore,
    recordAllTypes: [ResourceType.DOCUMENT, ResourceType.SCRIPT],
    sampleTypes: [
        ResourceType.STYLESHEET,
        ResourceType.IMAGE,
        ResourceType.FONT,
        ResourceType.OTHER
    ]
};
