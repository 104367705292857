/* eslint-disable react/forbid-prop-types */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { postData } from '../../redux/reducers/data';

export default function DeleteTransaction({ transaction, signalHandleSubmit, setSignalHandleSubmit }) {
  DeleteTransaction.propTypes = {
    transaction: PropTypes.object.isRequired,
    signalHandleSubmit: PropTypes.bool.isRequired,
    setSignalHandleSubmit: PropTypes.func.isRequired,
  };
  const { t } = useTranslation('app', { keyPrefix: 'accountDetails.splitMenu' });

  const dispatch = useDispatch();

  useEffect(() => {
    if (signalHandleSubmit) {
      dispatch(postData({
        data: [{ ...transaction, importFlag: 'delete' }],
        category: transaction.category,
        accountId: transaction.accountId,
      }));
      window.dispatchEvent(
        new CustomEvent('setDialog', {}),
      );
    }
  }, [signalHandleSubmit]);

  return (
    <div className="mt-2">
      <p className="text-sm text-gray-500">{t('pressSaveToDelete')}</p>
    </div>
  );
}
