/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-no-bind */
import React from 'react';
import { Controller, get } from 'react-hook-form';
import PropTypes from 'prop-types';
import { ExclamationCircleIcon } from '@heroicons/react/24/solid';
import { useTranslation } from 'react-i18next';
import DropdownComponent from '../../../elements/Dropdown';

// CAUTION: this is a dropdown wrapper for real estate addAccount form
// CAUTION: if you need a generic dropdown, use the one in elements/Dropdown.jsx

export default function Dropdown({ id, label, toolTip, validationRules, errors, optional, control, list, enabled, formatting }) {
  // id comes as "valuationParameters.<fieldName>"
  // if the list from schema does not need translating, pass it here
  // otherwise the component will attempt to get the list from the translation file (schema.RealEstate)
  const { t } = useTranslation(['app']);

  // the parameter values for dropdown parameters are in account.parameters.<fieldName>
  // this returns an object with key-value pairs of the form { <fieldName>: <label> }
  // eslint-disable-next-line max-len
  const calculatedList = list.length === 0 ? Object.entries(t(`schema.realEstate.account.${id.replace('valuationP', 'p')}`, { returnObjects: true })).map(([key, value]) => ({ id: key, name: value })) : list;

  const disableLabel = label === '';
  const calculatedLabel = label || t(`addAccount.R1.${id}.label`);
  // if the dropdown's id is nested (accountsArray.0.fieldName), the errors cannot be accessed by calling errors[id], hence the usage of get function
  const errorMessage = get(errors, id, {});

  return (
    <div className={`col-span-1 ${formatting}`}>
      <Controller
        control={control}
        name={id}
        rules={validationRules}
        render={({ field: { onChange, onBlur, value, ref } }) => {
          // the component would send back the same item it received, i.e. { id, name } -- the form only needs id
          function localOnChange(item) {
            if (item === null || (!item?.id && item?.id !== 0)) {
              // handle case when id is 0
              onChange(item);
            } else {
              // if item is not null and has an id, send only the id
              onChange(item.id);
            }
          }
          // that same translation has to happen on value, which is the id and needs to be translated to { id, name }
          const calculatedValue = calculatedList.find((listItem) => {
            if (listItem === null || listItem === 0 || (!listItem?.id && listItem?.id !== 0)) return value === listItem; // handle null or string, handle case when id is 0
            return value === listItem.id; // handle { id, name }
          });
          if (id === 'payoutsFrequencyUserInput') console.log('payout', calculatedValue, value, calculatedList);
          return (
            <DropdownComponent
              id={id}
              list={calculatedList}
              disableLabel={disableLabel}
              label={calculatedLabel}
              tooltip={toolTip}
              value={calculatedValue}
              onChange={localOnChange} // send value to hook form
              onBlur={onBlur} // notify when input is touched/blur
              inputRef={ref}
              optional={optional}
              enabled={enabled}
            />
          );
        }}
      />
      <div className={`${!errors[id] ? 'hidden' : ''} absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none`}>
        <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
      </div>
      <p className="mt-2 text-sm text-red-600" id="dropdown-error">
        {/* {errors[id] && <span>{errors[id].message}</span>} */}
        {errorMessage.message}
      </p>
    </div>
  );
}
Dropdown.propTypes = {
  id: PropTypes.string.isRequired,
  list: PropTypes.array,
  errors: PropTypes.objectOf(PropTypes.any).isRequired,
  validationRules: PropTypes.objectOf(PropTypes.any),
  label: PropTypes.string,
  toolTip: PropTypes.string,
  optional: PropTypes.bool,
  control: PropTypes.object.isRequired,
  enabled: PropTypes.bool,
  formatting: PropTypes.string,
};
Dropdown.defaultProps = {
  validationRules: () => {},
  label: null,
  toolTip: null,
  optional: true,
  list: [],
  enabled: true,
  formatting: '',
};
