const debugLevel = process.env.MDEBUG || 3;

// inputs as outlined below
// returns an array of objects of { category, categoryRebalancingAssetDeltaFactor }
export default function getPortfolioDeltaFactors(currentAllocationFiltered, targetAllocationFiltered) {
  // filter out those rows of targetAllocation, which have a value in the category fields but have no values in all the other fields of assetHierarchy or the field which is now the dimension
  const targetAllocationCategories = targetAllocationFiltered.filter((row) => row.category && !row.assetClass && !row.currency);
  // returns array of the rows which contain a value in the field topHierarchyLevel and have no values in all the other fields of assetHierarchy (i.e. for the category-level allocation)
  if (debugLevel > 2) console.info('getPortfolioDeltaFactors: received the following target allocation:', targetAllocationCategories);

  const totalSum = currentAllocationFiltered.reduce((acc, row) => acc + row.amount, 0);
  if (debugLevel > 2) console.info('getPortfolioDeltaFactors: current total of the entire portfolio is', totalSum);

  // calculate sum by category
  const categoryFactors = targetAllocationCategories.map((row) => {
    // if the row.value is null, we assume that there is no allocation for any of the categories (it would otherwise be 0) and return factor of 0 (="leave unchanged")
    if (!row.value) {
      return {
        category: row.category, assetClass: null, currency: null, factor: 0,
      };
    }

    const currentSumByCategory = currentAllocationFiltered.filter((position) => position.category === row.category).reduce((acc, assetObject) => acc + assetObject.amount, 0);
    if (debugLevel > 2) console.info('getPortfolioDeltaFactors: current total of category', row.category, 'is', currentSumByCategory);

    const targetSumByCategory = totalSum * (row.value / 100);
    if (debugLevel > 2) console.info('getPortfolioDeltaFactors: target total of category', row.category, 'is', targetSumByCategory);

    return {
      category: row.category,
      assetClass: null,
      currency: null,
      factor: (targetSumByCategory - currentSumByCategory) / currentSumByCategory,
    };
  });

  if (debugLevel > 2) console.info('getPortfolioDeltaFactors: categoryFactors', categoryFactors);

  return currentAllocationFiltered.map((row) => {
    const factor = categoryFactors.find((x) => x.category === row.category)?.factor || 0;
    return {
      ...row,
      categoryRebalancingAssetDeltaFactor: factor,
    };
  });
}
