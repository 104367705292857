import * as deposits from './deposits';
import * as stocks from './stocks';
import * as realEstate from './realEstate';
import * as loans from './loans';

export default function getParamsByCategory(category) {
  switch (category) {
    case 'deposits':
      return deposits;
    case 'stocks':
      return stocks;
    case 'realEstate':
      return realEstate;
    case 'loans':
      return loans;
    default:
      return {};
  }
}
