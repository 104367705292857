/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { assetBalancesArray, assetBalances, projectBalances, projectBalancesArray } from '../redux/reducers/data';

export default function AccountSum({ account, category, currencyCodeFormat, showInBaseCurrency, isViewer }) {
  // ↓↓ asset accounts may have assets in different currencies, so suming them up in their different currencies makes no sense
  if (category !== 'deposits' && !showInBaseCurrency) {
    console.error('AccountSum: the following error happened while rendering AccountSum for account ', account, ' and category ', category, showInBaseCurrency);
    throw new Error('AccountSum: showInBaseCurrency must be true for category other than deposits');
  }

  if (!account) return true;

  const accountCurrency = account?.currency || 'EUR';

  const baseCurrency = isViewer ? useSelector((state) => state.viewer.baseCurrency) : useSelector((state) => state.user.profile.settings.baseCurrency) || 'EUR';
  const currentAccountData = isViewer ? useSelector((state) => state.viewer.accounts).filter((a) => a.id === account.id) : useSelector(assetBalancesArray).filter((a) => a.accountId === account.id);
  // console.log('DEBUG currentAccountData', category, currentAccountData);
  // assetBalancesArray selector returns an ARRAY

  // console.log('DEBUG ab', useSelector(assetBalances));
  // console.log('DEBUG aba', useSelector(assetBalancesArray));
  // console.log('DEBUG pb', useSelector(projectBalances), useSelector(projectBalancesArray));

  // DEPOSITS
  // special case: this may have to display sum in account currency AND in base currency

  if (category === 'deposits') {
    // receives a list of transactions per account and the asccount object
    // returns a number of span objects which together constitute the output of toLocaleString with the currency code or symbol
    // if a currency code is returned instead of symbol, it will be formatted with currencyCodeFormat prop
    // prettier-ignore
    const sum = isViewer
      ? currentAccountData[0].balance
      : currentAccountData.reduce((acc, curr) => (acc + showInBaseCurrency ? curr.current.valueBaseCurrency : curr.current.valueAccountCurrency), 0); // takes the returned ARRAY and sums up the values

    // handle three-letter currency codes in the localeString
    const a = new Intl.NumberFormat('de', {
      style: 'currency',
      currency: showInBaseCurrency ? baseCurrency : accountCurrency || 'EUR',
      maximumFractionDigits: 0,
    }).formatToParts(sum); // generates an array with [{type:"",value:""}] of all the components of the output string of toLocaleString()

    // add foreign currency tag
    if (showInBaseCurrency && accountCurrency !== baseCurrency) {
      a.push({ type: 'fxTag', value: accountCurrency });
    }

    return a.map((item, idx) => {
      if (item.type === 'currency' && item.value.length > 1) {
        return (
          <span key={idx} className={currencyCodeFormat}>
            {item.value}
          </span>
        );
      }
      if (item.type === 'fxTag') {
        return (
          <div key={idx} className="text-gray-300 text-xs font-normal h-5 mt-[0.2rem] ml-2 py-[0.08rem] md:py-0.5 px-1 border border-gray-300 rounded">
            {item.value}
          </div>
        );
      }
      return <span key={idx}>{item.value}</span>;
    });
  }

  // ALL OTHER CATEGORIES

  try {
    const sum = isViewer ? currentAccountData[0].balance : currentAccountData.reduce((acc, curr) => acc + curr.current.valueBaseCurrency, 0);
    return <span>{sum.toLocaleString('de', { style: 'currency', currency: baseCurrency, maximumFractionDigits: 0 })}</span>;
  } catch (err) {
    console.error('AccountSum: the following error happened while rendering AccountSum for account ', account, ' data object:', currentAccountData, err);
    throw new Error('AccountSum: an error happened while rendering AccountSum');
  }
}
AccountSum.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  account: PropTypes.objectOf(PropTypes.any).isRequired,
  category: PropTypes.string.isRequired,
  currencyCodeFormat: PropTypes.string,
  showInBaseCurrency: PropTypes.bool,
  isViewer: PropTypes.bool,
};
AccountSum.defaultProps = {
  currencyCodeFormat: '',
  showInBaseCurrency: true,
  isViewer: false,
};
