import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { projectBalancesArray } from '../../redux/reducers/globalSelectors/overarching';

const debugLevel = 0;

export function ProgressGoalCore({ project }) {
  const { goals } = project;

  const nextUpcomingGoal = (!goals || goals.length === 0) ? { amount: 0 } : project.goals.sort((a, b) => a.date - b.date)[0]; // get the next upcoming goal (in case there are multiople goals)

  // get project sum at next goal date
  const projectForecast = useSelector(projectBalancesArray)
    .reduce((acc, curr) => acc + (curr.projectId === project.id ? curr.nextGoalDate.valueBaseCurrency : 0), 0);

  const goalVsForecast = (projectForecast - nextUpcomingGoal.amount) / nextUpcomingGoal.amount;

  return (
    <div className="sm:px-2 lg:px-0 xl:px-2 w-full grid grid-cols-2 gap-4 text-xs sm:text-sm text-gray-500">
      <div>
        <div className="font-base pb-1">Goal</div>
        <div className="text-[1rem] transform scale-y-110 sm:scale-y-100 sm:text-lg font-bold text-gray-900 flex items-baseline gap-1">
          {Number(nextUpcomingGoal.amount).toLocaleString('de', { style: 'currency', currency: 'EUR', maximumFractionDigits: 0 })}
        </div>
      </div>
      <div>
        <div className="font-base pb-1">Forecast</div>
        <div className="text-[1rem] transform scale-y-110 sm:scale-y-100 sm:text-lg font-bold text-gray-900 sm:flex sm:items-baseline lg:block xl:flex gap-1">
          {projectForecast.toLocaleString('de', { style: 'currency', currency: 'EUR', maximumFractionDigits: 0 })}
          <span className={`${goalVsForecast >= 0 ? 'text-green-500' : 'text-brandRed-500'} flex items-baseline text-xs sm:text-sm font-light sm:font-semibold`}>
            {goalVsForecast.toLocaleString('de', { style: 'percent', maximumFractionDigits: 1 })}
          </span>
        </div>
      </div>
    </div>
  );
}
ProgressGoalCore.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  project: PropTypes.object.isRequired,
};

const ProgressGoal = React.memo(ProgressGoalCore);
export default ProgressGoal;
