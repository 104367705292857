import JSZip from 'jszip';

// password is not needed, as these are not sensitive data; base64 is enough
export async function saveObjectToLocalStorage(obj, keyName) {
  const stringifiedObject = JSON.stringify(obj);

  const zip = new JSZip();
  zip.file('temp.zip', stringifiedObject);

  const base64 = await zip.generateAsync({ type: 'base64' });
  localStorage.setItem(keyName, base64);
}

export async function decodeObjectFromLocalStorage(keyName) {
  try {
    // Step 1: Retrieve the zipped, base64-encoded state from localStorage
    const base64 = localStorage.getItem(keyName);
    if (!base64) {
      console.info('returning null from decodeObjectFromLocalStorage');
      return null;
    }

    // Step 2: Convert base64 to binary and unzip it to get the encrypted state
    const zip = new JSZip();
    await zip.loadAsync(base64, { base64: true });
    const stringifiedObject = await zip.file('temp.zip').async('text');

    // Step 4: Parse the JSON string to get the original object
    const obj = JSON.parse(stringifiedObject);

    localStorage.removeItem(keyName);
    return obj;
  } catch (error) {
    console.error('Failed to decode the object from localStorage', error);
    throw error;
  }
}
