/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as schema from '@monestry-dev/schema';
import TableLayout, { getButtonsToShow } from './TableLayout';
import GridLayout from './GridLayout';
import ButtonBar from './ButtonBar';
import { putOrDeleteQuotes } from '../../redux/reducers/quotes';
import * as common from './params/common';
import * as quotesRealEstate from './quotes/realEstate';
import * as quotesStocks from './quotes/stocks';
import * as quotesPension from './quotes/pension';
import * as quotesObjectsOfValue from './quotes/objectsOfValue';
import * as quotesMetals from './quotes/metals';
import * as quotesUnlistedShares from './quotes/unlistedShares';
import * as quotesCrypto from './quotes/crypto';
import i18n from '../../i18n';
import gridConvertGridDataToObject from './gridConvertGridDataToObject';

if (process.env.REACT_APP_ENV_SUFFIX === 'dev') {
  window.schema = schema;
}

// --- INFO ---
// this component applies category - specific changes to data and passes them onwards;
// manages table layout

export default function CategoryWrapperQuotes({
  account,
  setAccount,
  quotes,
  tableState,
  setTableState,
  displayedComponent,
  setDisplayedComponent,
  displayedComponentMode,
  setDisplayedComponentMode,
  userChangesPresent,
  setUserChangesPresent,
}) {
  const [quotesWithCategoryData, setQuotesWithCategoryData] = useState(undefined);

  let funcs;

  switch (account.category) {
    case 'realEstate':
      funcs = quotesRealEstate;
      break;
    case 'crypto':
      funcs = quotesCrypto;
      break;
    case 'stocks':
      funcs = quotesStocks;
      break;
    case 'pension':
      funcs = quotesPension;
      break;
    case 'objectsOfValue':
      funcs = quotesObjectsOfValue;
      break;
    case 'metals':
      funcs = quotesMetals;
      break;
    case 'unlistedShares':
      funcs = quotesUnlistedShares;
      break;
    default:
      funcs = {};
  }

  // TRANSFORM INPUT DATA

  // add category-specific data to the transactions and sort
  const defaultSortingCallback = (a, b) => common.compare(a, b, tableState.sortBy, tableState.sortDirectionAsc);

  // imported from transactions to make sorting consistent between transactions and quotes, but seems to have no application here, so commenting it out for now
  // const sortingCallback = typeof categoryModule.getSortingCallback === 'function' ? categoryModule.getSortingCallback(tableState) : defaultSortingCallback;
  const sortingCallback = defaultSortingCallback;

  // customDropdownSources is an object with keys as column names and values as arrays of strings
  // if there is a customDropdown type of column in the grid layout for this category, it will try to find dropdown source values in this object
  // { columnName: [<array of string>] }
  const customDropdownSources = typeof funcs.getCustomDropdownSources === 'function' ? funcs.getCustomDropdownSources() : {};

  // customOnChange is executed after every value change in Grid
  // it receives (instance, cell, x, y, value);
  const customOnChange = typeof funcs.getCustomOnChange === 'function' ? funcs.getCustomOnChange() : () => {};

  // we are putting this into state to make it easier for jSpreadsheet to access it once everything is loaded
  useEffect(() => {
    // add category-specific data to the quotes
    const data = funcs.applyCategorySpecificChanges(quotes, account);
    setQuotesWithCategoryData(data.sort(sortingCallback)); // apply category-specific changes here, final sort as per tableState and update state
  }, [account.category, account.livingArea, quotes, displayedComponent]);

  // PREPARE OUTPUT DATA

  function identifyDeletedQuotes(inputData, outputData) {
    // quotes is partitioned by date
    const outputStack = [...outputData]; // all PUT quotes are here already

    // DELETE: show me those entries from inputData, which have a date which does not exist in outputData
    const deletedItems = inputData
      .filter((inputObject) => !outputData.some((outputObject) => outputObject.date === inputObject.date))
      // removeGridFormatting, the following transformation, uses the order of properties in the object, so the deleted object must be structured the same way as the objects coming from Grid
      .map(funcs.categoryOrderedObject(account))
      .map((item) => ({ ...item, importFlag: 'delete' }));
    return outputStack.concat(deletedItems);
  }

  function prepAndValidateOutputData(data, gridLayout, dirtyRows) {
    //
    // STEP 1: transform data from Grid to the "canonical" format
    //

    const transformedData = gridConvertGridDataToObject(data, gridLayout, dirtyRows);
    // const transformedData = data
    //   // add dirty / not dirty column to every row array based on dirtyRows (but only to rows which are not empty, because they won't be removed in the next step of the chain)
    //   .map((row, idx) => (row.join('').length > 0 ? [...row, dirtyRows.has(String(idx))] : row))
    //   // remove completely empty rows
    //   .filter((row) => row.join('').length > 0)

    //   // convert array of arrays to array of objects
    //   .map((row) => gridLayout.concat({ id: 'isDirty', type: 'boolean' }).reduce((prev, curr, idx) => ({ ...prev, [curr.id]: row[idx] }), {}));

    //
    // STEP 2: add deleted items back into the array
    //

    // put deleted quotes back into the array with 'delete' importFlag
    // caution: if validation fails for the 'delete' rows later, it will be displayed as errors in Grid in an empty row (because these rows have been added and do not exist in Grid)
    const dataWithDeletes = identifyDeletedQuotes(quotes, transformedData);

    //
    // STEP 3: remove grid formatting
    //

    const dataAfterReformatting = common.removeGridFormatting(dataWithDeletes, gridLayout);

    //
    // STEP 4: apply category-specific transformations
    //

    // perform category-specific transformations, add importFlag
    const dataAfterCategorySpeficicTransformations = dataAfterReformatting.map(funcs.outputTransformCategoryQuotes(account));

    //
    // STEP 5: validate data against schema
    //

    // if validation is passed, return the "canonical" object, otherwise throw an error (with the validation errors)
    // and show the errors to user
    let validatedData;
    // error handler is complicated, so will take it out of the try-catch block and treat it as separate function
    let validationErrors = []; // clear all errors
    try {
      // cast should remove unknown properties and not fail on the first error
      validatedData = schema.quotes.validateSync(dataAfterCategorySpeficicTransformations, { abortEarly: false, stripUnknown: true });
      // console.table('validatedData', validatedData);
    } catch (err) {
      // if there is err.inner, it means there is at least one validation error from yup validateSync
      // since this is too complex to handle here, set validationError and handle in the next block
      // otherwise this is a regular error and can be thrown here
      console.error('CategoryWrapperQuotes > validation error:', err);
      console.info('CategoryWrapperQuotes > data being validated:', dataAfterCategorySpeficicTransformations);
      if (err.inner) validationErrors = err.inner;
      else throw new Error(err);
    }

    // handles validation errors
    if (validationErrors.length > 0) {
      // return a list of errors back to caller (Grid) which can show it in the UI
      return { status: 'error', errors: validationErrors };
    }

    return { status: 'success', data: validatedData };
  }

  function postCategoryItems(data, _account) {
    return putOrDeleteQuotes({ arrayOfQuotes: data, category: _account.category, accountId: _account.id });
  }

  const commonProps = {
    account,
    data: quotesWithCategoryData,
    mode: 'quotes',
    tableState,
    setTableState,
    displayedComponent,
    setDisplayedComponent,
    displayedComponentMode,
    setDisplayedComponentMode,
  };

  // if it is empty, return empty (only in table mode)
  if (quotes.length === 0 && displayedComponent === 'table') {
    return (
      <>
        <ButtonBar
          buttonsToShow={getButtonsToShow(account.category, 'quotes')}
          displayedComponent={displayedComponent}
          setDisplayedComponent={setDisplayedComponent}
          displayedComponentMode={displayedComponentMode}
          setDisplayedComponentMode={setDisplayedComponentMode}
          userChangesPresent={userChangesPresent}
        />
        <div className="flex w-full h-full justify-center items-center">
          <p className="text-xl font-bold">{i18n.language === 'de' ? 'Keine Daten vorhanden' : 'Nothing to display'}</p>
        </div>
      </>
    );
  }
  if (displayedComponent === 'grid') {
    // console.log(`CategoryWrapperQuotes > displayedComponent === grid', ${quotesWithCategoryData}`);
    return (
      <GridLayout
        {...commonProps}
        prepAndValidateOutputData={prepAndValidateOutputData}
        postCategoryItems={postCategoryItems}
        userChangesPresent={userChangesPresent}
        setUserChangesPresent={setUserChangesPresent}
        setAccount={setAccount}
        customDropdownSources={customDropdownSources}
        customOnChange={customOnChange}
      />
    );
  }
  if (displayedComponent === 'table') {
    return <TableLayout {...commonProps} handleSync={funcs.handleSync} />;
  }
  return null;
}
CategoryWrapperQuotes.propTypes = {
  account: PropTypes.object.isRequired,
  setAccount: PropTypes.func.isRequired,
  quotes: PropTypes.array.isRequired,
  tableState: PropTypes.object.isRequired,
  setTableState: PropTypes.func.isRequired,
  displayedComponent: PropTypes.string.isRequired,
  setDisplayedComponent: PropTypes.func.isRequired,
  displayedComponentMode: PropTypes.string.isRequired,
  setDisplayedComponentMode: PropTypes.func.isRequired,
  userChangesPresent: PropTypes.bool.isRequired,
  setUserChangesPresent: PropTypes.func.isRequired,
};
