import snapshots from './snapshots.json';
import balances from './balances.json';
import completeAssetView from './completeAssetView.json';
import quotes from './quotes.json';

const data = {
  metadata: {
    publishedOn: 1701475200000,
  },
  baseCurrency: 'EUR',
  kpis: {
    currentNetWorth: 100000,
    inflows: 6750,
    inflowsReferencePeriod: 5000,
    incomeFromCapital: -288,
    incomeFromCapitalReferencePeriod: -244,
    assetValueChange: 1100,
    assetValueChangeReferencePeriod: 1050,
    outflows: -4000,
    outflowsReferencePeriod: -3500,
    baselineNetWorth: 80000,
    baselineDate: 1640991600000,
  },
  accounts: [
    // just balances
    { id: 'tghb1', category: 'deposits', name: 'Account 1', balance: 20560, currency: 'EUR' },
    { id: 'tghb2', category: 'deposits', name: 'Account 2', balance: 1852, currency: 'CHF' },
    { id: 'tghb3', category: 'deposits', name: 'Account 3', balance: 0, currency: 'EUR' },
    { id: 'tghb4', category: 'stocks', name: 'Depot Account', balance: 13500, currency: 'EUR' },
    { id: 'tghb5', category: 'realEstate', name: 'Hochstr. 19', balance: 430500, currency: 'EUR' },
  ],
  reportData: {
    snapshots, // TODO cut out unnecessary props (see which one the report needs)
    balances,
    completeAssetView,
    quotes,
    // ↑↑ this needs to have all 'current' quote objects for each stock in completeAssetView (for the stock positions report)
    // ↑↑ + the entire object for all realEstate objects (for the value report for each one) WITHOUT NAMED
    // ↑↑ this has the store format (assetId-date-quoteObject), whereas the bar chart for quotes needs it transformed to an array
    // transactions,
  },
};

export default data;
