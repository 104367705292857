// this could be potentially fetched from stripe API
// that would require moving stripe setup from SignUp3 to SignUp2

export default function getProducts() {
  // prod
  if (process.env.REACT_APP_ENV_SUFFIX === 'prod') {
    return [
      {
        type: 's', // mapped to i18n
        tierId: 'tier-1',
        stripePriceId: 'price_1OZYbvHTQ5xnEau1N0N48iNc',
        billingFrequency: 'monthly',
        monthlyPrice: 3.9,
      },
      {
        type: 's',
        tierId: 'tier-1',
        stripePriceId: 'price_1OZYcmHTQ5xnEau1BzrFESAN',
        billingFrequency: 'annually',
        monthlyPrice: 3.9,
      },
      {
        type: 'm',
        tierId: 'tier-2',
        stripePriceId: 'price_1OZYfDHTQ5xnEau13CUqRcde',
        billingFrequency: 'monthly',
        monthlyPrice: 7.9,
      },
      {
        type: 'm',
        tierId: 'tier-2',
        stripePriceId: 'price_1OZYftHTQ5xnEau1gQpRpjCq',
        billingFrequency: 'annually',
        monthlyPrice: 6.9,
      },
      {
        type: 'l',
        tierId: 'tier-3',
        stripePriceId: 'price_1OZYhvHTQ5xnEau1EF6jfnka',
        billingFrequency: 'monthly',
        monthlyPrice: 10.9,
        mostPopular: true,
      },
      {
        type: 'l',
        tierId: 'tier-3',
        stripePriceId: 'price_1OZYixHTQ5xnEau1MiJrgHH2',
        billingFrequency: 'annually',
        monthlyPrice: 9.9,
        mostPopular: true,
      },
      {
        type: 'xl',
        tierId: 'tier-4',
        stripePriceId: 'price_1OZYk7HTQ5xnEau1TqFtzhBI',
        billingFrequency: 'monthly',
        monthlyPrice: 14.9,
      },
      {
        type: 'xl',
        tierId: 'tier-4',
        stripePriceId: 'price_1OZYk7HTQ5xnEau1YlzRhzdN',
        billingFrequency: 'annually',
        monthlyPrice: 13.9,
      },
    ];
  }
  // dev / test / e2e
  return [
    {
      type: 's', // mapped to i18n
      tierId: 'tier-1',
      stripePriceId: 'price_1OZV94HTQ5xnEau17ROfUxGX',
      billingFrequency: 'monthly',
      monthlyPrice: 3.9,
    },
    {
      type: 's',
      tierId: 'tier-1',
      stripePriceId: 'price_1OZVABHTQ5xnEau1bAAsIbY4',
      billingFrequency: 'annually',
      monthlyPrice: 3.9,
    },
    {
      type: 'm',
      tierId: 'tier-2',
      stripePriceId: 'price_1OZVBwHTQ5xnEau1k3PL0d6p',
      billingFrequency: 'monthly',
      monthlyPrice: 7.9,
    },
    {
      type: 'm',
      tierId: 'tier-2',
      stripePriceId: 'price_1OZVCfHTQ5xnEau1tDaVayfR',
      billingFrequency: 'annually',
      monthlyPrice: 6.9,
    },
    {
      type: 'l',
      tierId: 'tier-3',
      stripePriceId: 'price_1OZVEUHTQ5xnEau1nJE4UEI8',
      billingFrequency: 'monthly',
      monthlyPrice: 10.9,
      mostPopular: true,
    },
    {
      type: 'l',
      tierId: 'tier-3',
      stripePriceId: 'price_1OZVF7HTQ5xnEau1clBAJat1',
      billingFrequency: 'annually',
      monthlyPrice: 9.9,
      mostPopular: true,
    },
    {
      type: 'xl',
      tierId: 'tier-4',
      stripePriceId: 'price_1OZV8CHTQ5xnEau1yOHONaaH',
      billingFrequency: 'monthly',
      monthlyPrice: 14.9,
    },
    {
      type: 'xl',
      tierId: 'tier-4',
      stripePriceId: 'price_1OZVGeHTQ5xnEau14x3OFzp3',
      billingFrequency: 'annually',
      monthlyPrice: 13.9,
    },
  ];
}
