/* eslint-disable no-return-assign */

import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  // eslint-disable-next-line camelcase
  read, readFile, utils, set_cptable,
} from 'xlsx';
import * as cptable from 'xlsx/dist/cpexcel.full.mjs';

set_cptable(cptable);

export default function Sandbox() {
  const [fileList, setFileList] = useState([]);

  useEffect(async () => {
    if (fileList.length > 0) {
      // TODO parallelise this for multiple files
      const promises = Array.from(fileList).map((file) => file.arrayBuffer());
      Promise.all(promises).then((results) => {
        results.forEach((item) => {
          const fileRead = read(item, { raw: true, codepage: 65001 }); // handle locale-specific things in ColumnMatcher
          console.log('fileRead', fileRead.Sheets);
          console.log('results', utils.sheet_to_json(fileRead.Sheets[fileRead.SheetNames[0]], { header: 1 }));
        });
      });
    }
  }, [fileList]);

  return (
    <>
      <div>loaded</div>
      <div className="flex text-sm text-gray-600 p-10">
        <label
          htmlFor="file-upload"
          className="relative cursor-pointer bg-gray-50 rounded-md font-medium text-brandBlue-500 hover:text-brandBlue-400"
        >
          <span>Upload a file</span>
          <input id="file-upload" name="file-upload" type="file" className="sr-only" onChange={(e) => setFileList(e.target.files)} />
        </label>
      </div>
    </>
  );
}
