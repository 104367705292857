/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { RadioGroup } from '@headlessui/react';
import { Controller } from 'react-hook-form';
import { Bars2Icon, ChevronDoubleDownIcon, ChevronDoubleUpIcon, ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid';
import ToolTip from './ToolTip';
import ToolTipNoIcon from './ToolTipNoIcon';

/**
 * Renders a group of option buttons, of which only one can be selected.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.id - The ID of the option buttons.
 * @param {string} props.label - The label for the option buttons.
 * @param {string} [props.tooltip] - The tooltip for the option buttons.
 * @param {Object} props.control - The control object from react-hook-form.
 * @param {bool} [props.fullWidth] - if true, the component will span the full width of the parent container. If not, it will span 60%.
 * @param {Array} [props.overrideOptions] - The array of override options for the option buttons ( { id, name, Icon }).
 *                'id' will be passed to form state. name will only be used if Icon is not provided.
 * @param {bool} [props.forceFlex] - if true, the component will use flex layout instead of the default grid 5-cols.
 * @returns {JSX.Element} The rendered OptionButtons component.
 */
export default function OptionButtons({ id, label, tooltip, control, overrideOptions, fullWidth, forceFlex }) {
  const { t } = useTranslation('app', { keyPrefix: 'accountDetails.unlistedShares.wizard.valuation.score' });

  // take the provided options or use default
  const options = overrideOptions || [
    { id: -2, name: t('-2.label'), Icon: ChevronDoubleDownIcon },
    { id: -1, name: t('-1.label'), Icon: ChevronDownIcon },
    { id: 0, name: t('0.label'), Icon: Bars2Icon },
    { id: 1, name: t('1.label'), Icon: ChevronUpIcon },
    { id: 2, name: t('2.label'), Icon: ChevronDoubleUpIcon },
  ];

  return (
    <Controller
      control={control}
      name={id}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...(id !== 'stage' && { defaultValue: 0 })} // for stage do not set default = 0, because it does not exist in its options
      render={({ field }) => (
        <fieldset aria-label={label} id={`option-buttons-${id}`}>
          <div className="flex items-center">
            <div className="text-sm font-medium leading-6 text-gray-900">{label}</div>
            {tooltip && <ToolTip info={tooltip} />}
          </div>

          <RadioGroup value={field.value} onChange={field.onChange} className={`mt-2 ${forceFlex ? 'flex flex-wrap' : 'grid grid-cols-5'} gap-3 ${fullWidth ? 'lg:w-full' : 'lg:w-3/5'}`}>
            {options.map((option) => (
              <ToolTipNoIcon key={option.id} info={option.name} classNameOwn={`${forceFlex ? 'flex-shrink-0' : 'w-full'}`}>
                <RadioGroup.Option
                  value={option.id}
                  className={({ focus, checked }) => `cursor-pointer focus:outline-none flex items-center justify-center rounded-md py-3 px-3 text-sm font-semibold uppercase sm:flex-1
                    ${forceFlex ? 'flex-shrink-0' : 'w-full'}
                    ${focus ? 'ring-2 ring-brandBlue-600 ring-offset-2' : ''}
                    ${checked ? 'bg-brandBlue-500 text-white hover:bg-brandBlue-400' : 'ring-1 ring-inset ring-gray-300 bg-white text-gray-900 hover:bg-gray-50'}`}
                >
                  {option.Icon ? <option.Icon className="h-5 w-5" aria-hidden="true" /> : option.name}
                </RadioGroup.Option>
              </ToolTipNoIcon>
            ))}
          </RadioGroup>
        </fieldset>
      )}
    />
  );
}
OptionButtons.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
  control: PropTypes.object.isRequired,
  overrideOptions: PropTypes.array,
  fullWidth: PropTypes.bool,
  forceFlex: PropTypes.bool,
};
OptionButtons.defaultProps = {
  tooltip: null,
  overrideOptions: null,
  fullWidth: false,
  forceFlex: false,
};
