/* eslint-disable no-promise-executor-return */
import { API, Auth } from 'aws-amplify';

async function callApi(method, payload, resource) {
  // get session details from Amplify.Auth
  const session = await Auth.currentSession();
  const myInit = {
    body: payload, // actual contents go here
    headers: {
      'Content-Type': 'application/json',
      Authorization: session.idToken.jwtToken,
    },
  };

  let response;
  try {
    if (method === 'get') {
      response = await API.get('myAPI', `projects/${resource}`, myInit);
    }
    if (method === 'post') {
      response = await API.post('myAPI', `projects/${resource}`, myInit);
    }
    if (method === 'put') {
      response = await API.put('myAPI', `projects/${resource}`, myInit);
    }
    if (method === 'delete') {
      response = await API.del('myAPI', `projects/${resource}`, myInit);
    }
  } catch (error) {
    console.log('Error while accessing API', error.response);
    throw new Error(error);
  }

  return response;
}

async function getData() {
  // returns array of all projects
  try {
    const data = await callApi('get', null, '');
    return data;
  } catch (e) {
    console.error('Error while accessing projects > getData API.');
    console.error(`Lambda error message: ${e.response.data}, statusCode: ${e.response.status}, statusText: ${e.response.statusText}`);
    throw new Error(e.response.data); // error message from the backend lambda
  }
}

async function postData(payload) {
  // payload is a single, complete project object
  try {
    const data = await callApi('post', payload, '');
    return { ...data };
  } catch (e) {
    console.error('Error while accessing projects > postData API.');
    console.error(`Lambda error message: ${e.response.data}, statusCode: ${e.response.status}, statusText: ${e.response.statusText}`);
    throw new Error(e.response.data); // error message from the backend lambda
  }
}

async function putData(payload) {
  try {
    const data = await callApi('put', payload, '');
    return { ...data };
  } catch (e) {
    console.error('Error while accessing projects > putData API.');
    console.error(`Lambda error message: ${e.response.data}, statusCode: ${e.response.status}, statusText: ${e.response.statusText}`);
    throw new Error(e.response.data); // error message from the backend lambda
  }
}

async function deleteData(payload) {
  try {
    const data = await callApi('delete', payload, '');
    return { ...data };
  } catch (e) {
    console.error('Error while accessing projects > deleteData API.');
    console.error(`Lambda error message: ${e.response.data}, statusCode: ${e.response.status}, statusText: ${e.response.statusText}`);
    throw new Error(e.response.data); // error message from the backend lambda
  }
}

export default {
  getData,
  postData,
  putData,
  deleteData,
};
