import React from 'react';
import PropTypes from 'prop-types';
import { XMarkIcon } from '@heroicons/react/16/solid';
import { TrendingUpIcon } from '../../assets/mdiIcons';

export default function QuoteComponent({ quote, setQuoteDialog, projectAssets }) {
  return (
    <button
      type="button"
      onClick={(e) => setQuoteDialog(quote)}
      // eslint-disable-next-line max-len
      className="relative border border-gray-200 bg-white hover:bg-gray-50 shadow-sm rounded-md px-0.5 py-0.5 text-xs lg:text-sm font-light text-gray-400 flex items-center cursor-pointer"
    >
      <span className="mr-2">{projectAssets.find((a) => a.assetId === quote.assetId || a.accountId === quote.assetId)?.displayName}</span>
      <TrendingUpIcon className="h-4 w-4 text-gray-400" />
      <span className="mx-2">{quote.quote.toLocaleString('de-DE', { style: 'currency', currency: quote.currency })}</span>
      <XMarkIcon className="h-5 w-5 text-gray-400 cursor-pointer hover:text-gray-500" />
    </button>
  );
}
QuoteComponent.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  quote: PropTypes.objectOf(PropTypes.any).isRequired,
  setQuoteDialog: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  projectAssets: PropTypes.arrayOf(PropTypes.any),
};
QuoteComponent.defaultProps = {
  projectAssets: [],
};
