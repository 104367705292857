/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint object-curly-newline: ["error", { "multiline": true }] */
import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, NavLink, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Auth } from 'aws-amplify';
import { Menu, Popover, Transition } from '@headlessui/react';
import { BellIcon, Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { logout } from '../redux/actions/user';
import { LogoNotagline, LogoWhite, SymbolWhite } from '../assets';
import SearchInput from './SearchComponent';
import { Toast } from './NotificationMessage';
import { getCategoryLink } from '../blog';

function HeaderApp({ mode, openSettings, openAccount, searchControl, setSearchControl, setRecommendUsVisible }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const lng = i18n.language;

  function handleLogout(e) {
    dispatch(logout());
    navigate('/');
    setTimeout(() => window.location.reload(), 300);
  }

  const navigation = [
    {
      id: 'dashboard',
      name: 'Dashboard',
      href: `/${lng}/app/dashboard`,
    },
    {
      id: 'projects',
      name: t('app:header.projects'),
      href: `/${lng}/app/projects`,
    },
    {
      id: 'reports',
      name: t('app:header.reports'),
      href: `/${lng}/app/reports`,
    },
    {
      id: 'help',
      name: t('app:header.help'),
      href: `/${lng}/blog/${getCategoryLink('guide', lng)}`,
      newWindow: true,
    },
  ];
  // { name: t('app:header.survey'), href: '/en/survey', current: false },

  // this ↓↓ is NOT USED for the desktop menu (see around row 105)
  // FIXME blocked for initial release (see PRD-1408)
  const mobileMenu = [
    // {
    //   id: 'recommendUs',
    //   name: t('app:header.recommendUs'),
    //   callback: () => setRecommendUsVisible(true),
    // },
    { id: 'profile', name: t('app:header.profile'), callback: openAccount },
    { id: 'settings', name: t('app:header.settings'), callback: openSettings },
  ];
  if (!window.mobileApp) mobileMenu.push({ id: 'signOut', name: t('app:header.signOut'), callback: handleLogout });

  const [user, setUser] = useState({ name: 'user', email: 'email' });

  const messages = useSelector((state) => state.message.messages);

  useEffect(() => {
    async function getUser() {
      const userFromAuth = await Auth.currentAuthenticatedUser();
      setUser({
        name: userFromAuth.attributes?.preferred_username || userFromAuth.attributes?.email,
        email: userFromAuth.attributes?.email,
      });
    }
    getUser();
  }, []);

  return (
    <Popover
      as="header"
      // header (pb property) is longer in projects mode to cover the slider
      // TODO: removed 'z-10' from the className below, check if it breaks anything (without it the overlap
      // between header and dashboard does not work, dashboard must be higher in the stacking context)
      className={`${mode === 'dashboard' ? 'from-brandDarkBlue-700 to-brandBlue-600 pb-12 lg:pb-20' : ''}
                  ${mode === 'projects' ? 'from-brandDarkBlue-700 to-brandViolet-700 pb-40 lg:pb-56' : ''}
                  ${mode === 'viewer' ? 'from-brandDarkBlue-700 to-brandBlue-600 pb-16 lg:pb-20' : ''}
                  ${mode === 'reports' ? 'from-brandDarkBlue-700 to-brandBlue-600 lg:pb-28' : ''}
      bg-gradient-to-r relative`}
    >
      {({ open }) => (
        <>
          <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:max-w-[100rem] lg:px-8">
            <div className="relative flex flex-wrap items-center justify-center lg:justify-between">
              {/* Logo */}
              <div className="absolute left-0 py-5 flex-shrink-0 lg:static">
                <NavLink to="/">
                  <span className="sr-only">Workflow</span>
                  {mode === 'viewer' ? (
                    <LogoWhite fillstack="#ffffff" width="230" height="28" viewBox="0 0 860 135" className="hidden xs:block h-14" />
                  ) : (
                    <LogoWhite fillstack="#ffffff" width="180" height="28" viewBox="35 0 831 135" className="hidden xs:block h-6" />
                  )}
                  <SymbolWhite width="32" height="32" viewBox="35 0 831 135" className="xs:hidden h-8" />
                </NavLink>
              </div>

              {/* Notification + burger menu button on desktop */}
              {mode !== 'viewer' && (
                <div className="hidden lg:ml-4 lg:flex lg:items-center lg:py-5 lg:pr-0.5">
                  <Menu as="div" className="ml-4 relative flex-shrink-0" id="header-notification-list-desktop">
                    <div>
                      <Menu.Button
                        className="flex-shrink-0 py-1 px-1 text-brandBlue-200 rounded-full hover:text-white hover:bg-white hover:bg-opacity-10 focus:outline-none focus:ring-2 focus:ring-white"
                        id="header-notification-button-desktop"
                      >
                        <span className="sr-only">Open notification list</span>
                        <BellIcon className="h-6 w-6" aria-hidden="true" />
                      </Menu.Button>
                    </div>
                    <Transition as="div" leave="transition ease-in duration-75" leaveFrom="transform opacity-100 scale-100" leaveTo="transform opacity-0 scale-95">
                      {messages.length > 0 && (
                        // this div: overall shape of the notification list + margins
                        <div
                          id="header-notification-items-desktop-overflow-parent"
                          className="origin-top-right z-40 absolute -right-2 mt-2 w-96 max-h-96 rounded-md shadow-lg p-3 bg-gray-50 ring-1 ring-black ring-opacity-5 focus:outline-none"
                        >
                          {/* this div: parent of the list, displays scrollbar -- so that the scrollbar is visible _within_ the padding of the parent container */}
                          <div className="max-h-[22rem] overflow-y-auto scrollbar-thin scrollbar-track-gray-100 scrollbar-thumb-gray-300 hover:scrollbar-thumb-zinc-400">
                            <Menu.Items id="header-notification-items-desktop" className="space-y-2">
                              {messages.reverse().map((messageObject) => (
                                <Menu.Item as="div" key={messageObject.message} id={`header-notification-items-desktop-${messageObject.message}`}>
                                  <Toast messageObject={messageObject} show />
                                </Menu.Item>
                              ))}
                            </Menu.Items>
                          </div>
                        </div>
                      )}

                      {messages.length === 0 && <div id="header-notification-items-desktop-empty">{t('app:header.noMessages')}</div>}
                    </Transition>
                  </Menu>

                  {/* Burger menu dropdown */}
                  <Menu as="div" className="ml-4 relative flex-shrink-0" id="header-menu-desktop">
                    <div>
                      <Menu.Button
                        className="inline-flex items-center px-3 py-2 shadow-sm text-sm leading-4 font-medium rounded-md text-brandBlue-200 focus:outline-none focus:ring-opacity-100"
                        id="header-menu-button-desktop"
                      >
                        <span className="sr-only">Open user menu</span>
                        <Bars3Icon className="h-6 w-6 text-brandBlue-200" />
                      </Menu.Button>
                    </div>
                    <Transition as={Fragment} leave="transition ease-in duration-75" leaveFrom="transform opacity-100 scale-100" leaveTo="transform opacity-0 scale-95">
                      <Menu.Items
                        id="header-menu-items-desktop"
                        className="origin-top-right z-40 absolute -right-2 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                      >
                        <Menu.Item id="header-menu-items-desktop-recommendus" key="recommendus">
                          <button type="button" onClick={() => setRecommendUsVisible(true)} className="hover:bg-gray-100 block w-full text-left px-4 py-2 text-sm text-gray-700">
                            {t('app:header.recommendUs')}
                          </button>
                        </Menu.Item>
                        <Menu.Item id="header-menu-items-desktop-yourprofile" key="yourprofile">
                          <button type="button" onClick={openAccount} className="hover:bg-gray-100 block w-full text-left px-4 py-2 text-sm text-gray-700">
                            {t('app:header.profile')}
                          </button>
                        </Menu.Item>
                        <Menu.Item id="header-menu-items-desktop-settings" key="settings">
                          <button type="button" onClick={openSettings} className="hover:bg-gray-100 block w-full text-left px-4 py-2 text-sm text-gray-700">
                            {t('app:header.settings')}
                          </button>
                        </Menu.Item>
                        <Menu.Item key="signout" id="menu-button-signout">
                          <button type="button" onClick={handleLogout} className="hover:bg-gray-100 block w-full text-left px-4 py-2 text-sm text-gray-700">
                            {t('app:header.signOut')}
                          </button>
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              )}

              {/* Nav bar - links - on desktop */}
              {mode !== 'viewer' && (
                <div className="w-full py-5 lg:border-t lg:border-white lg:border-opacity-20">
                  <div className="lg:grid lg:grid-cols-3 lg:gap-8 lg:items-center">
                    <div className="hidden lg:block lg:col-span-2">
                      <nav className="flex space-x-6">
                        {navigation.map((item) => (
                          <NavLink
                            key={item.id}
                            id={`header-nav-desktop-${item.id}`}
                            to={item.href}
                            className={`text-sm font-semibold rounded-md bg-white bg-opacity-0 px-3 py-2 hover:bg-opacity-10
                              ${location.pathname === item.href ? 'text-white' : 'text-brandDarkBlue-200'}`}
                            {...(item.newWindow && {
                              target: '_blank',
                              rel: 'noopener noreferrer',
                            })}
                          >
                            {item.name}
                          </NavLink>
                        ))}
                      </nav>
                    </div>
                    <SearchInput searchControl={searchControl} setSearchControl={setSearchControl} />
                  </div>
                </div>
              )}

              {/* Menu button */}
              {mode !== 'viewer' && (
                <div className="absolute right-0 flex-shrink-0 lg:hidden">
                  {/* Mobile menu button */}
                  {/* eslint-disable-next-line max-len */}
                  <Popover.Button className="bg-transparent p-2 rounded-md inline-flex items-center justify-center text-cyan-200 hover:text-white hover:bg-white hover:bg-opacity-10 focus:outline-none focus:ring-2 focus:ring-white">
                    <span className="sr-only">Open main menu</span>
                    {open ? <XMarkIcon className="block h-6 w-6" aria-hidden="true" /> : <Bars3Icon className="block h-6 w-6" aria-hidden="true" />}
                  </Popover.Button>
                </div>
              )}
            </div>
          </div>

          <Transition.Root as={Fragment}>
            <div className="lg:hidden">
              <Transition.Child as={Fragment} enter="duration-150 ease-out" enterFrom="opacity-0" enterTo="opacity-100" leave="duration-150 ease-in" leaveFrom="opacity-100" leaveTo="opacity-0">
                <Popover.Overlay className="z-20 fixed inset-0 bg-black bg-opacity-25" />
              </Transition.Child>

              <Transition.Child
                as={Fragment}
                enter="duration-150 ease-out"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="duration-150 ease-in"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Popover.Panel focus className="z-30 absolute top-0 inset-x-0 max-w-3xl mx-auto w-full p-2 transition transform origin-top">
                  {({ close }) => (
                    <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y divide-gray-200">
                      <div className="pt-3 pb-2">
                        <div className="flex items-center justify-between px-4">
                          <div className="-ml-2">
                            <LogoNotagline width="180" height="28" viewBox="0 0 831 126" className="h-6" />
                          </div>
                          <div className="-mr-2">
                            {/* eslint-disable-next-line max-len */}
                            <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-cyan-500">
                              <span className="sr-only">Close menu</span>
                              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                            </Popover.Button>
                          </div>
                        </div>
                        <div className="mt-3 px-2 space-y-1">
                          {navigation.map((item) => (
                            <NavLink
                              key={item.name}
                              to={item.href}
                              onClick={() => close()}
                              className="block rounded-md px-3 py-2 text-base text-gray-900 font-medium hover:bg-gray-100 hover:text-gray-800"
                              rel="noopener noreferrer"
                            >
                              {item.name}
                            </NavLink>
                          ))}
                        </div>
                      </div>
                      <div className="pt-4 pb-2">
                        <div className="flex items-center px-5">
                          <div className="flex-shrink-0">
                            <span className="h-10 w-10 rounded-full py-2 px-3 bg-brandBlue-600 font-bold text-white">{user.name[0].toUpperCase()}</span>
                          </div>
                          <div className="ml-3 min-w-0 flex-1">
                            <div className="text-base font-medium text-gray-800 truncate">{user.name}</div>
                            <div className="text-sm font-medium text-gray-500 truncate">{user.email}</div>
                          </div>
                          <button
                            type="button"
                            className="ml-auto flex-shrink-0 bg-white p-1 text-gray-400 rounded-full hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500"
                          >
                            <span className="sr-only">View notifications</span>
                            <BellIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                        <div className="mt-3 px-2 space-y-1">
                          {mobileMenu
                            .filter((item) => {
                              if (item.id === 'signOut') return !window.mobileApp;
                              return true;
                            }) // hide signOut button if in mobile app (see https://monestry.atlassian.net/l/cp/74aYHw8v)
                            .map((item) => (
                              <button
                                key={item.name}
                                type="button"
                                onClick={item.callback}
                                target="_blank"
                                className="block rounded-md px-3 py-2 text-base text-gray-900 font-medium hover:bg-gray-100 hover:text-gray-800"
                              >
                                {item.name}
                              </button>
                            ))}
                        </div>
                      </div>
                    </div>
                  )}
                </Popover.Panel>
              </Transition.Child>
            </div>
          </Transition.Root>
          {/* <div className="w-96 h-64 bg-brandBlue-600 absolute" /> */}
        </>
      )}
    </Popover>
  );
}
HeaderApp.propTypes = {
  mode: PropTypes.string,
  openSettings: PropTypes.func.isRequired,
  openAccount: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  searchControl: PropTypes.objectOf(PropTypes.any).isRequired,
  setSearchControl: PropTypes.func.isRequired,
  setRecommendUsVisible: PropTypes.func.isRequired,
};
HeaderApp.defaultProps = { mode: 'dashboard' };

export default HeaderApp;
