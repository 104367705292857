/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';

function SvgLogoNotagline(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      width={831.549}
      height={125.775}
      {...props}
    >
      <path
        d="M0 0v16.283h-83.162V-4.484h44.245A44.42 44.42 0 0 0-14.878 2.55C-9.66 2.55-4.654 1.646 0 0"
        style={{
          fill: '#1797f2',
          fillOpacity: 1,
          fillRule: 'nonzero',
          stroke: 'none',
        }}
        transform="matrix(1.33333 0 0 -1.33333 227.904 21.71)"
      />
      <path
        d="M0 0h-39.686v-20.767h25.73A44.688 44.688 0 0 0 0 0"
        style={{
          fill: '#1797f2',
          fillOpacity: 1,
          fillRule: 'nonzero',
          stroke: 'none',
        }}
        transform="matrix(1.33333 0 0 -1.33333 169.937 32.137)"
      />
      <path
        d="M0 0h-24.824v-20.767H.102a44.705 44.705 0 0 0-1.279 10.604C-1.177-6.665-.761-3.266 0 0"
        style={{
          fill: '#1797f2',
          fillOpacity: 1,
          fillRule: 'nonzero',
          stroke: 'none',
        }}
        transform="matrix(1.33333 0 0 -1.33333 150.12 64.274)"
      />
      <path
        d="M0 0h-25.88v-20.767h40.228A44.687 44.687 0 0 0 0 0"
        style={{
          fill: '#1797f2',
          fillOpacity: 1,
          fillRule: 'nonzero',
          stroke: 'none',
        }}
        transform="matrix(1.33333 0 0 -1.33333 151.528 96.412)"
      />
      <path
        d="M0 0h18.939l30.137-38.716V0h20.09v-73.247H51.483L20.09-33.065v-40.182H0Z"
        style={{
          fill: '#000',
          fillOpacity: 1,
          fillRule: 'nonzero',
          stroke: 'none',
        }}
        transform="matrix(1.33333 0 0 -1.33333 254.41 26.44)"
      />
      <path
        d="M0 0h58.912v-17.16H20.09v-11.197h35.159v-16.01H20.09v-11.614h39.346v-17.266H0Z"
        style={{
          fill: '#000',
          fillOpacity: 1,
          fillRule: 'nonzero',
          stroke: 'none',
        }}
        transform="matrix(1.33333 0 0 -1.33333 359.851 26.44)"
      />
      <path
        d="m0 0 11.3 13.497c7.325-5.755 15.278-8.789 23.753-8.789 5.546 0 8.476 1.883 8.476 5.023v.209c0 3.14-2.406 4.709-12.452 7.116-15.591 3.557-27.624 7.952-27.624 22.916v.209c0 13.603 10.777 23.438 28.357 23.438 12.451 0 22.182-3.347 30.136-9.731l-10.15-14.336c-6.697 4.815-14.022 7.22-20.509 7.22-4.919 0-7.326-1.988-7.326-4.708v-.208c0-3.349 2.513-4.815 12.767-7.116 16.741-3.663 27.206-9.104 27.206-22.812v-.209c0-14.963-11.824-23.857-29.613-23.857C21.346-12.138 9.103-8.058 0 0"
        style={{
          fill: '#000',
          fillOpacity: 1,
          fillRule: 'nonzero',
          stroke: 'none',
        }}
        transform="matrix(1.33333 0 0 -1.33333 452.318 109.591)"
      />
      <path
        d="M0 0h-21.869v17.787h64.144V0H20.3v-55.459H0Z"
        style={{
          fill: '#000',
          fillOpacity: 1,
          fillRule: 'nonzero',
          stroke: 'none',
        }}
        transform="matrix(1.33333 0 0 -1.33333 573.333 50.156)"
      />
      <path
        d="M0 0h34.636c11.195 0 18.939-2.93 23.857-7.846 4.29-4.292 6.487-9.838 6.487-17.058v-.209c0-11.3-5.964-18.729-15.068-22.601l17.476-25.533H44.054L29.298-51.063H20.3v-22.184H0Zm33.798-35.157c6.803 0 10.882 3.347 10.882 8.685v.207c0 5.861-4.289 8.791-10.986 8.791H20.3v-17.683z"
        style={{
          fill: '#000',
          fillOpacity: 1,
          fillRule: 'nonzero',
          stroke: 'none',
        }}
        transform="matrix(1.33333 0 0 -1.33333 642.919 26.44)"
      />
      <path
        d="m0 0-15.173-26.682L-30.24 0h-23.125l27.938-45.83v-27.417h20.3v27.73L22.707 0Z"
        style={{
          fill: '#000',
          fillOpacity: 1,
          fillRule: 'nonzero',
          stroke: 'none',
        }}
        transform="matrix(1.33333 0 0 -1.33333 801.273 26.44)"
      />
      <path
        d="m0 0-17.476-28.357L-34.951 0h-21.451v-73.247h19.882v41.96l18.625-28.565h.419L1.255-31.181v-42.066H21.45V0Z"
        style={{
          fill: '#000',
          fillOpacity: 1,
          fillRule: 'nonzero',
          stroke: 'none',
        }}
        transform="matrix(1.33333 0 0 -1.33333 75.203 26.44)"
      />
      <path
        d="M0 0c0-13.693-11.101-24.793-24.793-24.793-13.693 0-24.794 11.1-24.794 24.793s11.101 24.793 24.794 24.793C-11.101 24.793 0 13.693 0 0"
        style={{
          fill: '#000',
          fillOpacity: 1,
          fillRule: 'nonzero',
          stroke: 'none',
        }}
        transform="matrix(1.33333 0 0 -1.33333 241.123 77.825)"
      />
      <path
        d="M0 0h-39.686v-20.767h25.73A44.688 44.688 0 0 0 0 0"
        style={{
          fill: '#1797f2',
          fillOpacity: 1,
          fillRule: 'nonzero',
          stroke: 'none',
        }}
        transform="matrix(1.33333 0 0 -1.33333 169.937 32.137)"
      />
      <path
        d="M0 0h-24.824v-20.767H.102a44.705 44.705 0 0 0-1.279 10.604C-1.177-6.665-.761-3.266 0 0"
        style={{
          fill: '#1797f2',
          fillOpacity: 1,
          fillRule: 'nonzero',
          stroke: 'none',
        }}
        transform="matrix(1.33333 0 0 -1.33333 150.12 64.274)"
      />
      <path
        d="M0 0h-25.88v-20.767h40.228A44.687 44.687 0 0 0 0 0"
        style={{
          fill: '#1797f2',
          fillOpacity: 1,
          fillRule: 'nonzero',
          stroke: 'none',
        }}
        transform="matrix(1.33333 0 0 -1.33333 151.528 96.412)"
      />
    </svg>
  );
}

export default SvgLogoNotagline;
