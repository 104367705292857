/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { globalAccountsView, postData, allTransactions } from '../../redux/reducers/data';
import { setMessage } from '../../redux/actions/message';
import AccountLabel from '../../elements/AccountLabel';
import SublistOkCancel from './SublistOkCancel';

dayjs.extend(utc);

export default function BlueTransactionsFeesSale({ selectedOption, accountId, linkedAttribute, handleCloseAfterSave, handleCancel, transaction }) {
  const { t } = useTranslation('app', { keyPrefix: 'accountDetails.deposits' });
  const [loading, setLoading] = useState(false);
  const [localLinkedAttribute, setLocalLinkedAttribute] = useState(linkedAttribute); // intialise to the object from props (should there be an existing linkedAttribute already)
  const dispatch = useDispatch();
  const allAccounts = useSelector(globalAccountsView) || [];
  const baseCurrency = useSelector((state) => state.user.profile.settings.baseCurrency);

  function getAccountById(a) {
    return allAccounts.find((account) => account.id === a);
  }

  async function handleSave() {
    // for saving items which require additional attributes (e.g. fees or dividends)
    const updatedTransaction = {
      ...transaction,
      label: selectedOption.id,
      // courtesy adjustment of fees (must be a negative number)
      amount: selectedOption.id === 'investment-fees' ? -Math.abs(transaction.amount) : Math.abs(transaction.amount),
      importFlag: 'put',
    };

    // FEES: check if a transaction is selected, add a linkedTransaction of type 'fee-to-transaction'
    if (selectedOption.id === 'investment-fees') {
      // if the transaction is not marked, do not save it
      if (!localLinkedAttribute?.linkedTransaction) {
        dispatch(setMessage('transactionNotSelected'));
        return;
      }

      updatedTransaction.linkedTransactions = updatedTransaction.linkedTransactions.filter((tx) => tx.tags.linkType !== 'fee-to-transaction').concat(localLinkedAttribute.linkedTransaction);

      // adding tags to the fees transaction as well; it will not be used in the BlueTransactionDialog
      // but it will be used in completeAssetView to easily identify the account of transaction to which the fees belong
      // this should be moved to tags of the linkedTransaction (both here and in completeAccountView)
      updatedTransaction.tags = {
        ...(typeof updatedTransaction.tags === 'string' ? JSON.parse(updatedTransaction.tags) : updatedTransaction.tags),
        assetId: localLinkedAttribute.assetId,
        accountId: localLinkedAttribute.accountId,
      };
      console.log('BlueTransactionsDialog > prep object for fees:', updatedTransaction);

      // SALE: check if a transaction is selected, add a linkedTransaction of type 'sale-to-transaction'
    } else if (selectedOption.id === 'investment-sale' || selectedOption.id === 'investment-loanPrincipal') {
      // if the transaction is not marked, do not save it
      if (!localLinkedAttribute?.linkedTransaction) {
        dispatch(setMessage('transactionNotSelected'));
        return;
      }

      updatedTransaction.linkedTransactions = updatedTransaction.linkedTransactions.filter((tx) => tx.tags.linkType !== 'sale-to-transaction').concat(localLinkedAttribute.linkedTransaction);
    }

    setLoading(true);
    try {
      await dispatch(
        postData({
          data: [updatedTransaction],
          category: 'deposits',
          accountId,
        }),
      );
      setLoading(false);
      handleCloseAfterSave();
    } catch (err) {
      setLoading(false);
      dispatch(setMessage('dataUpdateError'));
    }
  }

  const connectedAssetAccountIds = allAccounts.filter((account) => account.connectedDepositAccounts?.includes(accountId)).map((account) => account.id);

  // console.log('BlueTransactionsFeesSale > connectedAssetAccountIds:', connectedAssetAccountIds);
  console.log(
    'DEBUG',
    useSelector(allTransactions).filter((tx) => tx.category === 'objectsOfValue'),
  );

  // for FEES or SALE display a list of all transactions from those asset accounts, which are connected to current deposit account
  // so this can be a list of transactions from more than 1 account
  const connectedAccountsTransactions = useSelector(allTransactions)
    .filter(
      (atxn) => connectedAssetAccountIds?.includes(atxn.accountId)
        && !['split'].includes(atxn.transactionType)
        // to limit displayed transactions only show asset transactions from within +/- 3 months of the deposit transaction (to account for long-running payments)
        && dayjs.utc(atxn.date).isAfter(dayjs.utc(transaction.date).subtract(3, 'month'))
        && dayjs.utc(atxn.date).isBefore(dayjs.utc(transaction.date).add(3, 'month')),
    )
    .filter((atxn) => (selectedOption.id === 'investment-sale' ? atxn.quantity < 0 : true)) // loan sales can be both ways
    .sort((a, b) => new Date(a.date) - new Date(b.date));

  function handleClick(txn) {
    setLocalLinkedAttribute({
      ...localLinkedAttribute,
      linkedTransaction: {
        id: txn.id,
        category: txn.category,
        tags: {
          linkType: selectedOption.id === 'investment-sale' || selectedOption.id === 'investment-loanPrincipal' ? 'sale-to-transaction' : 'fee-to-transaction',
        },
      },
      accountId: txn.accountId,
      assetId: txn.figi || txn.accountId,
    });
  }

  return (
    <>
      <div data-testid="blue-transactions-fees-sale" className="flex flex-col gap-2 overflow-y-auto">
        {connectedAccountsTransactions.map((txn) => (
          <button
            type="button"
            key={txn.id}
            onClick={() => handleClick(txn)}
            data-testid="sublist-transaction"
            className={`text-xs border border-gray-200 shadow-sm rounded-md p-2 flex justify-between gap-2 cursor-pointer
                        ${
                          txn?.id === localLinkedAttribute?.linkedTransaction?.id ? 'bg-brandBlue-500 text-white hover:bg-brandBlue-600 border-transparent' : 'bg-white text-gray-900 hover:bg-gray-50'
                        }`}
          >
            <div>
              {new Date(txn.date).toLocaleDateString('de', {
                year: '2-digit',
                month: '2-digit',
                day: '2-digit',
                timeZone: 'UTC',
              })}
            </div>
            <div>{txn.displayName || txn.assetName || getAccountById(txn.accountId).name}</div>
            <div>
              {(txn.quantity * txn.upbc).toLocaleString('de', {
                style: 'currency',
                currency: baseCurrency,
                maximumFractionDigits: 0,
                minimumFractionDigits: 0, // https://stackoverflow.com/a/76342011
              })}
            </div>
            <AccountLabel accountName={getAccountById(txn.accountId)?.name} category={getAccountById(txn.accountId)?.category} />
          </button>
        ))}
        {connectedAccountsTransactions.length === 0 && <div className="text-sm text-gray-500 self-center mt-2">{t('noTransactions')}</div>}
      </div>
      <SublistOkCancel handleSave={handleSave} handleCancel={handleCancel} loading={loading} />
    </>
  );
}
BlueTransactionsFeesSale.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  selectedOption: PropTypes.objectOf(PropTypes.any).isRequired,
  accountId: PropTypes.string.isRequired,
  linkedAttribute: PropTypes.object,
  handleCancel: PropTypes.func.isRequired,
  handleCloseAfterSave: PropTypes.func.isRequired,
  transaction: PropTypes.object.isRequired,
};
BlueTransactionsFeesSale.defaultProps = {
  linkedAttribute: {},
};
