const allAssetsByROI = {
  vals: [
    'amount',
  ],
  userId: 'jiZoVK1rpJEbfxwfX8LKJ',
  cols: [],
  dataScope: 'assets',
  rows: [
    'category',
    'accountName',
    'displayName',
    'purchaseValue',
    'payoutsSincePurchase',
    'feesSincePurchase',
    'currentValue',
    'roi',
    'roiAnnual',
  ],
  rendererName: 'List',
  id: 'qWoSEGOkfwQt16iRbkB89',
  name: 'Owned assets with ROI',
  readOnly: true,
  aggregatorName: 'Sum',
};

export default allAssetsByROI;
