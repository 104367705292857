/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { ExclamationCircleIcon } from '@heroicons/react/24/solid';
import ToolTip from '../../../elements/ToolTip';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

/**
 * InputField component for rendering a labeled input field with validation and error handling.
 *
 * @param {string} props.id - The id of the input field.
 * @param {number} props.myIndex - The index of the input field within a form array.
 * @param {string} props.type - The type of the input field.
 * @param {string} props.autoComplete - The autocomplete attribute of the input field.
 * @param {string} props.label - The label text for the input field.
 * @param {string} [props.toolTip] - The tooltip information for the label.
 * @param {string} props.placeholder - The placeholder text for the input field.
 * @param {boolean} [props.optional=false] - Indicates if the field is optional.
 * @param {function} props.register - The register function from react-hook-form.
 * @param {Object} [props.errors={}] - The errors object from react-hook-form.
 * @param {Object} [props.validationRules={}] - The validation rules object for the input field.
 * @param {function} props.setValue - The setValue function from react-hook-form.
 * @param {string} [props.formatting=''] - Additional formatting classes for the input field container.
 * @param {boolean} [props.enabled=true] - Indicates if the field is enabled.
 *
 * @returns {JSX.Element} The rendered input field component.
 */
export default function InputField({ id, myIndex, type, autoComplete, label, toolTip, placeholder, optional, register, errors, setValue, validationRules, formatting, enabled }) {
  InputField.propTypes = {
    id: PropTypes.string.isRequired,
    myIndex: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    autoComplete: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    toolTip: PropTypes.string,
    optional: PropTypes.bool,
    register: PropTypes.func.isRequired,
    errors: PropTypes.objectOf(PropTypes.object),
    validationRules: PropTypes.objectOf(PropTypes.object),
    setValue: PropTypes.func.isRequired,
    formatting: PropTypes.string,
    enabled: PropTypes.bool,
  };
  InputField.defaultProps = {
    optional: false,
    toolTip: '',
    validationRules: {},
    errors: {},
    formatting: '',
    enabled: true,
  };
  return (
    <div className={`col-span-1 ${formatting}`}>
      <div className="flex justify-between">
        <label htmlFor="email" className="text-sm font-medium text-gray-700 flex justify-start">
          {label}
          <ToolTip info={toolTip} />
        </label>
        <span className="text-sm text-gray-500" id="email-optional">
          {optional ? 'Optional' : ''}
        </span>
      </div>
      <div className="mt-1 relative rounded-md shadow-sm">
        <input
          id={id}
          type={type}
          disabled={!enabled}
          autoComplete={autoComplete}
          name={id}
          className={classNames(
            errors[id]
              ? 'border-red-300 text-red-900  placeholder-red-300 focus:ring-red-500 focus:border-red-500'
              : 'border-gray-300 placeholder-gray-500 focus:ring-brandBlue-500 focus:border-brandBlue-500',
            'block w-full pr-2 focus:outline-none  sm:text-sm rounded-md',
            enabled ? 'text-gray-700' : 'text-gray-400',
          )}
          placeholder={placeholder}
          defaultValue=""
          aria-invalid="true"
          aria-describedby="username-error"
          {...register(`accountArray[${myIndex}].${id}`, validationRules)}
        />
        <div className={classNames(!errors[id] ? 'hidden' : '', 'absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none')}>
          <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
        </div>
      </div>
      <p className="mt-2 text-sm text-red-600" id={`${id}-error`}>
        {errors.accountArray?.[myIndex]?.[id] && <span>{errors.accountArray[myIndex][id].message}</span>}
      </p>
    </div>
  );
}
