/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-redundant-roles */
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PricingComponent from '../sections/signUp/PricingComponent';

export default function Pricing() {
  const { i18n, t } = useTranslation(['site'], { keyPrefix: 'pricing' });
  const navigate = useNavigate();
  const faq = t('faq', { returnObjects: true });

  function handleClick(tierId, tierType) {
    // ('tier-1', 'xl')
    navigate(`/${i18n.language}/register`, { state: { tierType, tierId } });
  }

  return (
    <div data-testid="pricing">
      <div className="max-w-7xl mx-auto py-16 px-4 bg-white sm:px-6 lg:px-8">
        <h2 className="text-3xl font-black text-gray-900 text-center sm:text-5xl sm:leading-none sm:tracking-tight lg:text-6xl">{t('header')}</h2>
        <p className="mt-6 max-w-2xl text-xl mx-auto text-center text-gray-500">{t('subheader')}</p>

        {/* eslint-disable-next-line react/jsx-no-bind */}
        <PricingComponent buttonFunction={handleClick} />

        <div className="bg-white">
          <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-20 lg:px-8">
            <div className="lg:grid lg:grid-cols-3 lg:gap-8">
              <div>
                <h2 className="text-3xl font-extrabold text-gray-900">{t('faq.header')}</h2>
              </div>
              <p className="text-gray-500 col-span-2 col-start-1">
                Can’t find the answer you’re looking for? Reach out to our customer support team at
                {' '}
                <a href="email:support@monestry.de" className="font-medium text-brandBlue-500 hover:text-brandBlue-400">
                  support@monestry.de
                </a>
                {' '}
                .
              </p>
              <div className="mt-12 lg:mt-0 lg:col-span-2">
                <dl className="space-y-12">
                  {faq.questions.map((f) => (
                    <div key={f.question}>
                      <dt className="text-lg leading-6 font-medium text-gray-900">{f.question}</dt>
                      <dd className="mt-2 text-base text-gray-500">{f.answer}</dd>
                    </div>
                  ))}
                </dl>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
