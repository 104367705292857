/* eslint-disable react/forbid-prop-types */
import { ArrowSmallDownIcon, ArrowSmallRightIcon, ArrowSmallUpIcon, ExclamationTriangleIcon } from '@heroicons/react/24/solid';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import ToolTipNoIcon from '../../elements/ToolTipNoIcon';
import ToolTipOnlyIcon from '../../elements/ToolTipOnlyIcon';
import OrphanedTransactionsDialog from './OrphanedTransactionsDialog';

export default function SumWithLabel({ id, stat, change, setKpiReportVisible, orphanedTransactions, setAccountDetails }) {
  const { t } = useTranslation();
  const [dialogOpen, setDialogOpen] = useState(false);

  return (
    <div className="my-auto">
      <dt>
        <ToolTipNoIcon info={t(`app:dashboard.sumtile.${id}.tooltip`)}>
          <div className="text-xs sm:text-sm font-medium text-gray-500 lg:text-center xl:text-left truncate flex items-end space-x-1">
            <p>{t(`app:dashboard.sumtile.${id}.label`)}</p>
            <ToolTipOnlyIcon />
            {orphanedTransactions.length > 0 && (
              <ToolTipNoIcon info={t('app:dashboard.sumtile.warning')} classNameOwn="-mb-0.5">
                <button type="button" onClick={() => setDialogOpen(true)}>
                  <ExclamationTriangleIcon className="w-5 h-5 text-brandYellow-500 cursor-pointer" />
                </button>
              </ToolTipNoIcon>
            )}
          </div>
        </ToolTipNoIcon>
      </dt>
      <dd className="flex flex-wrap 2xl:flex-nowrap justify-start items-baseline">
        <button
          type="button"
          // transform is needed to fix incorrect font rendering below xs
          className="text-[1rem] transform scale-y-110 sm:scale-y-100 xs:text-lg sm:text-xl md:text-2xl lg:text-xl xl:text-2xl font-semibold text-gray-900 mr-2"
        >
          {stat}
        </button>
        {change !== null && (
          <ToolTipNoIcon info={t('app:dashboard.sumtile.change')}>
            <p
              className={`
                ${change > 0 ? 'text-green-500' : ''} ${change === 0 ? 'text-yellow-500' : ''} ${change < 0 ? 'text-brandRed-500' : ''}
                flex items-baseline text-xs md:text-sm font-normal sm:font-semibold
              `}
            >
              {change > 0 && <ArrowSmallUpIcon className="self-center flex-shrink-0 h-3 w-3 md:h-5 md:w-5 text-green-500" aria-hidden="true" />}
              {change === 0 && <ArrowSmallRightIcon className="self-center flex-shrink-0 h-3 w-2 md:h-5 md:w-5 text-yellow-500" aria-hidden="true" />}
              {change < 0 && <ArrowSmallDownIcon className="self-center flex-shrink-0 h-3 w-3 md:h-5 md:w-5 text-brandRed-500" aria-hidden="true" />}
              <span className="sr-only">
                {change >= 0 ? 'Increased' : 'Decreased'}
                by
              </span>
              {(!change ? 0 : change).toLocaleString('de', { style: 'percent', maximumFractionDigits: 2 })}
            </p>
          </ToolTipNoIcon>
        )}
        {/* this one is created on top of dashboard */}
        {dialogOpen
          && createPortal(
            // eslint-disable-next-line react/jsx-no-bind
            <OrphanedTransactionsDialog setDialogOpen={setDialogOpen} transactions={orphanedTransactions} setAccountDetails={setAccountDetails} />,
            document.querySelector('div#dashboard-level-1'),
          )}
      </dd>
    </div>
  );
}
SumWithLabel.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  id: PropTypes.string.isRequired,
  stat: PropTypes.string.isRequired,
  change: PropTypes.number,
  setKpiReportVisible: PropTypes.func,
  orphanedTransactions: PropTypes.arrayOf(PropTypes.object),
  setAccountDetails: PropTypes.func,
};
SumWithLabel.defaultProps = {
  change: null,
  setKpiReportVisible: null,
  orphanedTransactions: [],
  setAccountDetails: () => {},
};
