/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { unlistedSharesTransaction } from '@monestry-dev/schema';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { nanoid } from 'nanoid';
import { InformationCircleIcon } from '@heroicons/react/20/solid';
import { globalAccountsView } from '../../redux/reducers/globalSelectors';
import ButtonBar from './ButtonBar';
import { getButtonsToShow } from './TableLayout';
import InputField from '../addAccount/accountWorkflow/InputField';
import Button from '../../elements/Button';
import MiniSpinner from '../../misc/MiniSpinner';
import { postData } from '../../redux/reducers/data';
import { setMessage } from '../../redux/actions/message';

dayjs.extend(utc);

export default function UnlistedCapitalTransactionWizard(props) {
  const { t } = useTranslation('app', { keyPrefix: 'accountDetails.unlistedShares.wizard.transactions' });
  const { register, watch, formState, handleSubmit } = useForm();

  const [loading, setLoading] = useState(false);
  const [loadingGoToAccount, setLoadingloadingGoToAccount] = useState(false);

  const selectAccounts = useSelector(globalAccountsView);
  const connectedDepositAccountObjects = selectAccounts.filter((account) => props.account?.connectedDepositAccounts?.includes(account.id));
  console.log('connectedDepositAccountObjects', connectedDepositAccountObjects);
  // props.account disappears when we execute goToAccount, so we need to capture that

  const dispatch = useDispatch();

  const watchTransactionType = watch('transactionType');

  function goToAccount(e, account) {
    setLoadingloadingGoToAccount(account.id);
    props.setAccount(account); // setAccount requires the entire account object
    props.setDisplayedComponent('table');
    props.setDisplayedComponentMode('transactions');
  }

  async function onSubmit(data) {
    setLoading(true);
    try {
      // moreCapital and capitalDisbursement require a deposit transaction (the user has been redirected there)
      // for moreShares and sharesSale, create a unlistedShares transaction
      const { transactionType, dateUserInput, upac, quantity } = data;

      const transaction = {
        ...unlistedSharesTransaction,
        id: nanoid(),
        accountId: props.account.id,
        assetId: props.account.id,
        date: dayjs.utc(dateUserInput).valueOf(),
        quantity: transactionType === 'moreShares' ? quantity : -quantity, // set correct sign
        upac,
        accountCurrency: props.account.currency,
        assetCurrency: props.account.currency,
        upbc: null, // let backend convert it
        uptc: null,
        transactionCurrency: null,
        isSimulated: false,
        projectId: null,
        linkedTransactions: [],
        tags: null,
        importFlag: 'post',
        sortingOrderWithinMonth: null,
      };
      await dispatch(
        postData({
          data: [{ ...transaction }],
          category: 'unlistedShares',
          accountId: props.account.id,
        }),
      );
      // go back to table
      props.setDisplayedComponent('table');
    } catch (error) {
      // handle errors
      dispatch(setMessage('dataUpdateError'));
    }
    setLoading(false);
  }

  return (
    <div className="w-full h-full">
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <ButtonBar {...props} buttonsToShow={getButtonsToShow('unlistedShares')} />
      <form className="w-full h-full grid lg:grid-cols-2 2xl:grid-cols-3 py-8 px-2" onSubmit={handleSubmit(onSubmit)}>
        {/* COL 1 */}
        <div className="space-y-8" name="account-details-unlisted-capital-option">
          <h2 className="font-bold text-base sm:text-lg">{t('header')}</h2>
          <div className="space-y-4">
            <div className="flex space-x-2 items-center">
              <input
                id="moreShares"
                name="moreShares"
                type="radio"
                value="moreShares"
                className="h-4 w-4 border-gray-300 text-brandBlue-500 focus:ring-brandBlue-400"
                {...register('transactionType', { required: { value: true, message: t('error') } })}
              />
              <label htmlFor="moreShares" className="ml-3 block text-sm font-medium text-gray-700">
                {t('moreShares')}
              </label>
            </div>
            <div className="flex space-x-2 items-center">
              <input
                id="moreCapital"
                name="moreCapital"
                type="radio"
                value="moreCapital"
                className="h-4 w-4 border-gray-300 text-brandBlue-500 focus:ring-brandBlue-400"
                {...register('transactionType', { required: { value: true, message: t('error') } })}
              />
              <label htmlFor="moreCapital" className="ml-3 block text-sm font-medium text-gray-700">
                {t('moreCapital')}
              </label>
            </div>
            <div className="flex space-x-2 items-center">
              <input
                id="capitalDisbursement"
                name="capitalDisbursement"
                type="radio"
                value="capitalDisbursement"
                className="h-4 w-4 border-gray-300 text-brandBlue-500 focus:ring-brandBlue-400"
                {...register('transactionType', { required: { value: true, message: t('error') } })}
              />
              <label htmlFor="capitalDisbursement" className="ml-3 block text-sm font-medium text-gray-700">
                {t('capitalDisbursement')}
              </label>
            </div>
            <div className="flex space-x-2 items-center">
              <input
                id="sharesSale"
                name="sharesSale"
                type="radio"
                value="sharesSale"
                className="h-4 w-4 border-gray-300 text-brandBlue-500 focus:ring-brandBlue-400"
                {...register('transactionType', { required: { value: true, message: t('error') } })}
              />
              <label htmlFor="sharesSale" className="ml-3 block text-sm font-medium text-gray-700">
                {t('sharesSale')}
              </label>
            </div>
            <p className="mt-2 text-sm text-red-500" id="connectedDepositMode-error">
              {formState.errors.transactionType && <span>{formState.errors.transactionType.message}</span>}
            </p>
          </div>
        </div>
        {/* COL 2 (OR BELOW FOR SM-) */}
        {watchTransactionType && (
          <div className="space-y-8 flex flex-col" name="account-details-unlisted-capital-form">
            <h2 className="font-bold text-base sm:text-lg">{t('formHeader')}</h2>
            {(watchTransactionType === 'moreShares' || watchTransactionType === 'sharesSale') && (
              <>
                <InputField
                  formatting="w-1/2"
                  id="dateUserInput"
                  type="date"
                  autoComplete="off"
                  label={t('dateUserInput.label')}
                  toolTip={t('dateUserInput.tooltip')}
                  placeholder="2"
                  register={register}
                  validationRules={{ required: { value: true, message: t('dateUserInput.error') } }}
                  errors={formState.errors}
                  optional={false}
                />
                <InputField
                  formatting="w-1/2"
                  id="quantity"
                  type="number"
                  autoComplete="off"
                  label={t('quantity.label')}
                  toolTip={t('quantity.tooltip')}
                  placeholder="2"
                  register={register}
                  validationRules={{ required: { value: true, message: t('quantity.error') } }}
                  errors={formState.errors}
                  optional={false}
                />
                <InputField
                  formatting="w-1/2"
                  id="upac"
                  type="number"
                  autoComplete="off"
                  label={t('upac.label')}
                  toolTip={t('upac.tooltip')}
                  placeholder="2"
                  register={register}
                  validationRules={{ required: { value: true, message: t('upac.error') } }}
                  errors={formState.errors}
                  currency={props.account.currency}
                  optional={false}
                />
              </>
            )}
            {watchTransactionType === 'moreCapital' || watchTransactionType === 'capitalDisbursement' ? (
              <div className="">
                <p className="col-span-3 prose text-sm text-gray-500">{t('clickToOpenDeposit')}</p>
                <div className="flex space-x-4 mt-8">
                  {connectedDepositAccountObjects.length === 0 && (
                    <>
                      <InformationCircleIcon className="w-5 h-5 text-brandBlue-300" />
                      <div className="text-gray-500 italic text-sm">{t('noConnectedDeposit')}</div>
                    </>
                  )}
                  {connectedDepositAccountObjects.map((account) => (
                    <button key={account.id} className="p-4 bg-white border border-gray-300 rounded-lg shadow-sm text-center hover:bg-gray-50" type="button" onClick={(e) => goToAccount(e, account)}>
                      {loadingGoToAccount === account.id ? (
                        <MiniSpinner className="w-5 h-5 text-gray-400 animate-spin" />
                      ) : (
                        <div className="text-gray-500 font-bold text-sm truncate uppercase">{account.name}</div>
                      )}
                    </button>
                  ))}
                </div>
              </div>
            ) : (
              <div className="">
                <Button formatting="col-start-3" text={t('submit')} type="submit" withAccent size="lg" spinnerOn={loading} />
              </div>
            )}
          </div>
        )}
      </form>
    </div>
  );
}
UnlistedCapitalTransactionWizard.propTypes = {
  account: PropTypes.objectOf(PropTypes.any).isRequired,
  setAccount: PropTypes.func.isRequired,
  setDisplayedComponent: PropTypes.func.isRequired,
  setDisplayedComponentMode: PropTypes.func.isRequired,
};
