import React, { useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { CognitoUser, CognitoRefreshToken, CognitoUserPool } from 'amazon-cognito-identity-js';
import store from '../store';
import MiniSpinner from '../misc/MiniSpinner';
import { updateStoreWithAttributes } from '../hubListener';
import { SET_MOBILE_APP_MODE } from '../redux/actions/types';

export default function MobileLogin() {
  const navigate = useNavigate();
  const { i18n } = useTranslation();

  const dispatch = useDispatch();

  // ↓ this function is called by the mobile app to transfer the refresh token to the web app
  // mobile app webview calls this function with the refresh token as a parameter (it is exposed below as a method of the window object)
  function cleanupLogin(username, refreshToken) {
    // Configure user and user pool details
    const userPoolData = {
      UserPoolId: process.env.REACT_APP_USER_POOL_ID,
      ClientId: process.env.REACT_APP_WEB_CLIENT_ID,
    };

    let user;
    let userPool;
    let refreshTokenObj;
    try {
      userPool = new CognitoUserPool(userPoolData);
      user = new CognitoUser({ Username: username, Pool: userPool });
      refreshTokenObj = new CognitoRefreshToken({ RefreshToken: refreshToken });
    } catch (e) {
      console.error('Error creating user or refresh token object', e);
      return false;
    }

    user.refreshSession(refreshTokenObj, (err, session) => {
      // break here if there is an error
      if (err) {
        console.error('Refresh session failed', err);
        return false;
      }

      // At this point, the user session is refreshed
      Auth.currentAuthenticatedUser({ bypassCache: true })
        .then((cognitoUser) => {
          // console.log('User is authenticated:', cognitoUser);
          // just like with normal login, get user settings (attributes) from Cognito to store
          updateStoreWithAttributes(cognitoUser, store);

          // set mobileModeOn in store for app-wide use
          dispatch({ type: SET_MOBILE_APP_MODE });

          window.cleanupLogin = null; // self-destruct after use
          if (window.mobileApp) console.log('Mobile app detected on channel >mobileApp<');
          navigate(`/${i18n.language}/app`); // redirect to Dashboard after successful login
          return true; // this is the only positive return value
        })
        .catch((e) => {
          console.log('User is not authenticated:', e);
        });
      return false;
    });
    return false;
  }

  useEffect(() => {
    // expose cleanupLogin function to the mobile app
    window.cleanupLogin = cleanupLogin;
    if (window.mobileApp) {
      console.log('Mobile app detected on channel >mobileApp<');
      window.mobileApp.postMessage('mobileLoginReady');
    } else {
      console.error('Mobile app not detected.');
    }
    return () => {
      window.cleanupLogin = null;
    };
  }, []);

  return (
    <div className="w-screen h-screen flex flex-col gap-4 justify-center items-center text-gray-400">
      <MiniSpinner className="h-16 w-16 animate-spin" />
    </div>
  );
}
