/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable object-curly-newline */
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, Outlet, useLocation } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Auth } from 'aws-amplify';
import { AwsRum } from 'aws-rum-web';
import { verifyToken } from './misc/verifyToken';
import awsconfig from './aws-config';
import { logout } from './redux/actions/user';
import ErrorFallback from './elements/ErrorFallbackComponent';
import Header from './elements/Header';
import Footer from './elements/Footer';
import FeedbackOverlay from './elements/FeedbackOverlay';
import AlertModalDispatch from './elements/AlertModalDispatch';
import ToolTipReceiver from './elements/ToolTipReceiver';
import CookieBanner from './sections/cookies/CookieBannerHandler';
import CallbackHandler from './pages/CallbackHandler';
import SignUpFallback from './sections/signUp/SignUpFallback';
import Main from './pages/Main';
import Login from './sections/login/Login';
import Pricing from './pages/Pricing';
import NoPageFound from './pages/404';
import Blog from './pages/Blog';
import Article from './pages/Article';
import Features from './pages/Features';
import Legal from './pages/Legal';
import Contact from './pages/Contact';
import LanguageSwitcher from './misc/LanguageSwitcher';
import Survey from './pages/Survey';
import GoTo from './pages/GoTo';
import MeetMonestry from './pages/MeetMonestry';
import Sandbox from './pages/Sandbox';
import NotificationMessageWrapper from './elements/NotificationMessage';
import Prep from './sections/signUp/Prep';
import RequestDemo from './pages/RequestDemo';
import MobileLogin from './pages/MobileLogin';
import AddAccountCallbackReceiver from './sections/addAccount/accountWorkflow/AddAccountCallbackReceiver';

// refer to https://monestry.atlassian.net/wiki/spaces/TECH/pages/222986253/Dashboard
import Dashboard from './pages/Dashboard';
import Reporting from './sections/reports/Reporting';
import SignUp from './sections/signUp/SignUp';
import PaymentSuccessful from './sections/signUp/PaymentSuccessful';
import DialogForm from './elements/DialogFormModalReceiver';
import SharedReportViewer from './sections/sharedReportsViewer/SharedReportViewer';
// don't load SignUp and Dashboard until needed
// const Dashboard = React.lazy(() => import('./pages/Dashboard'));
// const SignUp = React.lazy(() => import('./pages/SignUp'));
// const PaymentSuccessful = React.lazy(() => import('./pages/PaymentSuccessful'));

const debugLevel = process.env.REACT_APP_MDEBUG || 0;

const isLocalhost = Boolean(
  window.location.hostname === 'localhost'
    // [::1] is the IPv6 localhost address.
    || window.location.hostname === '[::1]'
    // 127.0.0.1/8 is considered localhost for IPv4.
    || window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/),
);

// const App = function () {
function App() {
  // for Amplfiy debugging
  // window.LOG_LEVEL = 'DEBUG'; // DEBUG, VERBOSE, INFO, WARN, ERROR, NONE

  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);

  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const [render, rerender] = useState(false);

  function RequireAuth() {
    // get user authenticated before rendering the protected route
    // checks if there is an idToken in localStorage and verifies it locally
    // if user is not logged in, redirect to login page
    // returns true if user is authenticated, false if not and null when still waiting for the result of the authentification
    const [isAuth, setAuth] = useState(null);
    const [isUnmounted, setIsUnmounted] = useState(false);
    const location = useLocation();
    const afterLoginGoTo = location.pathname;

    // using useEffect here, because we assume React will render everything before Auth returns the async call?
    useEffect(() => {
      // define getToken first
      async function getToken() {
        // get current token from localStorage or get a new one via refreshTokens through Amplify
        let cognitoUser;
        let token; // undefined
        try {
          cognitoUser = await await Auth.currentAuthenticatedUser();
          if (debugLevel > 2) console.info('RequireAuth > cognitoUser:', cognitoUser);
          token = cognitoUser.signInUserSession.accessToken.jwtToken;
        } catch (err) {
          console.error('RequireAuth > User authorisation is not possible.', err);
          return false;
        }
        // get the JWT verified; if it succeeds, JWT is OK, if it fails, there is a problem with the token
        let result = false;
        try {
          if (token) {
            if (debugLevel > 2) console.info('starting token verification');
            result = await verifyToken(token, awsconfig);
            if (debugLevel > 2) console.log('token verification result:', result);
          } // returns true or false
        } catch (err) {
          console.error(err);
        }
        if (debugLevel > 2) console.info('RequireAuth > User authorisation result:', result);
        return result;
      }

      getToken().then(
        (result) => {
          // if token is valid, set isAuth to true
          if (debugLevel > 2) console.info('RequireAuth > token check finished.', result);
          if (process.env.NODE_ENV === 'test') {
            rerender(!render); // to get the new value of isLoggedIn from mocked store
          }
          if (result) {
            if (debugLevel > 2) console.info('RequireAuth > token is valid.');
            if (!isUnmounted && !isAuth) setAuth(true);
          } else {
            // handle getToken returning false
            console.error('RequireAuth > token is invalid.');
            setAuth(false); // if this is not set here, when PaymentSuccessful redirects to /app/getReady, it will not be redirected to /login
            if (isLoggedIn) dispatch(logout()); // if token is invalid, log the user out in the store as well (if the user is logged in)
            // return false;
            if (!isUnmounted && isAuth) setAuth(false);
          }
        },
        (err) => {
          console.error('RequireAuth > error detected when validating token. ', err);
          // token not found or expired, return false to caller already
          // isAuth = false;
          // return false;
          if (!isUnmounted && isAuth) setAuth(false);
        },
      );
      return () => {
        setIsUnmounted(true); // this is meant to stop sending new promises as soon as the component becomes unmounted
        if (debugLevel > 2) console.info('RequireAuth > unmounting started');
      };
    }); // this is set to run at every render (useEffect is in fact not necessary here, but probably helps with the async stuff)

    if (isAuth === null) {
      // still waiting for status to be set, it will rerender this component when promise fulfilled in getAuth (and its state changes)
      return <div />;
    }

    // if the token validation has just failed (isAuth === false),
    // redirect them to the /login page, but save the current location they were in location.state object.
    // This allows us to send the user along to that page after they login,
    // which is a nicer user experience than dropping them off on the home page.
    // the store MUST take care of removing the tokens (Auth.signOut()) before changing state of isLoggedIn
    if (!isAuth) {
      console.log('RequireAuth > redirecting to login, afterLoginGoTo is', afterLoginGoTo);
    }
    return isAuth ? <Outlet /> : <Navigate to={`/${i18n.language}/login`} state={{ afterLoginGoTo }} />;
  }

  const PerformLogout = function PerformLogout(props) {
    // needs to return a function otherwise is executed every time the whole app renders
    dispatch(logout());
    // eslint-disable-next-line react/destructuring-assignment
    return <Navigate to={props.targetPath} />;
  };

  // this event happens when chrome is visible to user _again_, after standby or being covered by another window
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        // check if user is still logged in
        // if not, log them out
        Auth.currentSession()
          .then((data) => {}) // do nothing
          .catch((err) => {
            console.error('Unable to find valid tokens - logging out.');
            dispatch(logout()); // log out user
          }); // log out user
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  // handle RUM connection
  let awsRum = null;
  try {
    const config = {
      sessionSampleRate: 1,
      guestRoleArn: !isLocalhost ? process.env.REACT_APP_RUM_ROLE_ARN : 'arn:aws:iam::483594703153:role/RUM-Monitor-eu-central-1-483594703153-5101467612961-Unauth',
      identityPoolId: !isLocalhost ? process.env.REACT_APP_RUM_IDENTITY_POOL : 'eu-central-1:9004dd33-2579-4d0e-ba50-64c8df44aa73',
      endpoint: 'https://dataplane.rum.eu-central-1.amazonaws.com',
      telemetries: ['performance', 'errors', 'http'],
      allowCookies: true,
      enableXRay: true,
    };

    awsRum = new AwsRum(
      !isLocalhost ? process.env.REACT_APP_APPLICATION_ID : 'f2d95289-cefa-4ee5-909d-607e3da41547',
      !isLocalhost ? process.env.REACT_APP_APPLICATION_VERSION : 'localhost',
      !isLocalhost ? process.env.REACT_APP_APPLICATION_REGION : 'eu-central-1',
      config,
    );
  } catch (error) {
    // Ignore errors thrown during CloudWatch RUM web client initialization
    console.error('Error during initialisation of AWS RUM', error);
  }

  // FIXME footer cannot load first when entering via link localhost:3000/ Suspense
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <CookieBanner>
        <Router>
          <AlertModalDispatch />
          <DialogForm />
          <div className="h-screen overflow-auto">
            {/* HEADER */}

            <Routes>
              <Route element={<RequireAuth />}>
                <Route path="/:lang/app/*" element={<div />} />
              </Route>
              {/* no header in callback or mobile login  */}
              <Route path="/callback" element={<div />} />
              <Route path="/addAccountCallback" element={<div />} />
              <Route path="/:lang/mobileLogin" element={<div />} />
              <Route path="/:lang/shared/*" element={<div />} />
              <Route path="*" element={<Header />} />
            </Routes>
            {isLoggedIn ? <FeedbackOverlay /> : null}

            {/* MAIN */}

            <Routes>
              <Route path="/callback" element={<CallbackHandler />} />
              <Route path="/fallback" element={<SignUpFallback />} />
              <Route path="/addAccountCallback" element={<AddAccountCallbackReceiver />} />
              <Route element={<LanguageSwitcher i18next={i18n} />}>
                {/* the system seems to detect language before doing routing, so we can use the i18n lang code here */}
                <Route path="/" element={<Navigate to={`/${i18n.language}/`} />} />
                <Route path="/goto/:target" element={<GoTo />} />
                <Route path="/:lang/goto/:target" element={<GoTo />} />
                {/* FIXME do not display the website on prod */}
                {process.env.REACT_APP_ENV_SUFFIX === 'prod' ? (
                  <>
                    <Route path="/:lang/" element={<Navigate to={`/${i18n.language}/login`} />} />
                    <Route path="/:lang/login" element={<Login />} />
                    <Route path="/:lang/legal" element={<Legal />} />
                    <Route path="/:lang/contact" element={<Contact />} />
                    <Route path="/:lang/register" element={<SignUp />} />
                  </>
                ) : (
                  <>
                    <Route path="/:lang/" element={<Main />} />
                    <Route path="/:lang/features" element={<Features />} />
                    <Route path="/:lang/prices" element={<Pricing />} />
                    <Route path="/:lang/about-us" element={<MeetMonestry />} />
                    <Route path="/:lang/blog" element={<Blog />} />
                    <Route path="/:lang/blog/:cat" element={<Blog />} />
                    <Route path="/:lang/blog/:cat/:article" element={<Article />} />
                    <Route path="/:lang/legal" element={<Legal />} />
                    <Route path="/:lang/contact" element={<Contact />} />
                    <Route path="/:lang/register" element={<SignUp />} />
                    <Route path="/:lang/login" element={<Login />} />
                    <Route path="/:lang/demo" element={<RequestDemo />} />
                  </>
                )}
                <Route path="/:lang/confirm/:userId" element={<PaymentSuccessful />} />
                <Route path="/:lang/mobileLogin" element={<MobileLogin />} />
                <Route path="/:lang/logout" element={<PerformLogout targetPath={`/${i18n.language}/`} />} />
                <Route path="/:lang/shared/:reportId" element={<SharedReportViewer />} />
                <Route element={<RequireAuth />}>
                  <Route path="/:lang/app/projects" element={<Dashboard mode="projects" />} />
                  <Route path="/:lang/app/reports" element={<Reporting />} />
                  <Route path="/:lang/app/dashboard" element={<Dashboard mode="dashboard" />} />
                  <Route path="/:lang/app/getready" element={<Prep />} />
                  <Route path="/:lang/app/sandbox" element={<Sandbox />} />
                  <Route path="/:lang/app" element={<Navigate to={`/${i18n.language}/app/dashboard`} />} />
                  <Route path="/:lang/survey" element={<Survey />} />
                </Route>
                <Route path="/:lang/notfound" element={<NoPageFound />} />
                <Route path="*" element={<NoPageFound />} />
              </Route>
            </Routes>

            {/* FOOTER */}

            <Routes>
              {/* no footer in callback or mobile login */}
              <Route path="/callback" element={<div />} />
              <Route path="/addAccountCallback" element={<div />} />
              <Route path="/:lang/mobileLogin" element={<div />} />
              {/* so that footer does not show up first */}
              <Route element={<RequireAuth />}>
                {/* no footer in /app/ */}
                <Route path="/:lang/app/*" element={<div />} />
              </Route>
              <Route path="*" element={<Footer />} />
            </Routes>
          </div>
          <NotificationMessageWrapper />
          <ToolTipReceiver />
        </Router>
      </CookieBanner>
    </ErrorBoundary>
  );
}

export default App;
