import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';
import { allTransactionsProjectView } from '../../redux/reducers/globalSelectors/overarching';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function ProgressSlider2({ projectId, progressColour, backgroundColour }) {
  const selectTransactions = createSelector(
    (state) => allTransactionsProjectView(state),
    (transactions) => {
      const txns = (transactions?.filter((t) => t.projectId === projectId) || []).filter((txn) => txn.projectId === projectId).sort((a, b) => new Date(a.date) - new Date(b.date));
      const date1 = txns[0]?.date;
      const dateX = txns[txns.length - 1]?.date;
      return ((new Date().valueOf() - date1 < 0 ? 0 : new Date().valueOf() - date1) / (dateX - date1)) * 100;
    },
  );
  const marker = useSelector(selectTransactions);

  return (
    <div className="sm:px-2 lg:px-0 xl:px-2 w-full">
      <div className="space-y-1">
        <div style={{ marginLeft: '-8px', paddingLeft: `${marker}%` }} className="w-fit">
          {`${Number(marker || 0).toFixed(0)} %`}
          {/* <div className="rotate-90 w-fit text-black">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4">
              <path fillRule="evenodd" d="M4.5 5.653c0-1.426 1.529-2.33 2.779-1.643l11.54 6.348c1.295.712 1.295 2.573 0 3.285L7.28 19.991c-1.25.687-2.779-.217-2.779-1.643V5.653z" clipRule="evenodd" />
            </svg>
          </div> */}
        </div>
        <div className="relative w-full">
          <div className={classNames(backgroundColour, 'relative h-2 w-full rounded-sm')}>
            <div className={classNames(progressColour, 'absolute h-2 rounded-sm')} style={{ width: `${marker}%` }} />
          </div>
        </div>
        <div style={{ marginLeft: '-8px', paddingLeft: `${marker}%` }} className="w-fit">
          <div className="-rotate-90 w-fit">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4">
              <path fillRule="evenodd" d="M4.5 5.653c0-1.426 1.529-2.33 2.779-1.643l11.54 6.348c1.295.712 1.295 2.573 0 3.285L7.28 19.991c-1.25.687-2.779-.217-2.779-1.643V5.653z" clipRule="evenodd" />
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
}
ProgressSlider2.propTypes = {
  projectId: PropTypes.string.isRequired,
  progressColour: PropTypes.string,
  backgroundColour: PropTypes.string,
};
ProgressSlider2.defaultProps = {
  progressColour: 'bg-brandBlue-500',
  backgroundColour: 'bg-gray-200',
};
