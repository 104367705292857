/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import SumWithLabel from './SumWithLabel';
import { baselineNetWorth, completeAssetView } from '../../redux/reducers/data';
import { PREVIOUS_VALUE } from './constants';

function SumCurrentNetWorth({ baseCurrency, isViewer }) {
  // imported a global selector defined in the reducer module
  const selectCurrentNetWorthBaseline = isViewer ? useSelector((state) => state.viewer.kpis.baselineNetWorth) : useSelector(baselineNetWorth);

  return (
    <SumWithLabel
      id={PREVIOUS_VALUE}
      stat={selectCurrentNetWorthBaseline?.toLocaleString('de', {
        style: 'currency',
        currency: baseCurrency || 'EUR',
        maximumFractionDigits: 0,
      })}
      isViewer={isViewer}
    />
  );
}

SumCurrentNetWorth.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  baseCurrency: PropTypes.string.isRequired,
  isViewer: PropTypes.bool,
};
SumCurrentNetWorth.defaultProps = {
  isViewer: false,
};

const SumCurrentNetWorthMemo = React.memo(SumCurrentNetWorth);
export default SumCurrentNetWorthMemo;
