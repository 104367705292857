import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  useLocation, useNavigate, redirect, Outlet,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import posts from '../blog/posts.json';
import categories from '../blog/categories.json';

const debugLevel = process.env.REACT_APP_MDEBUG || 0;

function translateByDisplayKey(displayKey, previousLng, currentLng, category = true) {
  if (category) {
    const result = categories.find((cat) => cat[previousLng].displayKey === displayKey);
    if (result) return result[currentLng].displayKey;
    return null;
  }
  return posts.find((post) => post[previousLng].displayKey === displayKey)[currentLng].displayKey;
}

export default function LanguageChangeWrapper({ i18next }) {
  const location = useLocation();
  const navigate = useNavigate();

  const { i18n } = useTranslation();

  useEffect(() => {
    if (debugLevel > 2) console.info(`LanguageChangeWrapper: i18n.language: ${i18n.language}, location.pathname: ${location.pathname}, location.search: ${location.search}`);
    let targetPath;
    const lng = i18n.language;
    const previousLng = location.pathname.split('/')[1];

    // every time i18n.language changes and if it is not the initial render, run url translation
    if (lng !== previousLng) {
      // for all routes, just swap the language code in the path
      if (i18next.options.supportedLngs.some((language) => language === location.pathname.split('/')[1])) {
        // if there is a supported language code in the url, replace it with the target language code
        // else assume this was an url without language code (monestry.de/features) and add language code (monestry.de/en/features)
        // (if it is wrong, it will get routed to the 404 page)
        targetPath = `/${lng}${location.pathname.substring(3)}`;
      } else {
        targetPath = `/${lng}${location.pathname}`;
      }

      // special case: for SEO purposes the URLs for blog / category / article pages need to be translated
      // those URLs are like: /blog, /blog/:cat, /blog/:cat/:article
      const blog = location.pathname.split('/')[2];
      const cat = location.pathname.split('/')[3];
      const article = location.pathname.split('/')[4];

      if (blog === 'blog' && cat?.length > 0 && previousLng !== lng) {
        if (article && article.length > 0) {
          // case article
          targetPath = `/${lng}/blog/${translateByDisplayKey(cat, previousLng, lng)}/${translateByDisplayKey(article, previousLng, lng, false)}`;
        } else {
          // case category
          targetPath = `/${lng}/blog/${translateByDisplayKey(cat, previousLng, lng)}`;
        }
      }

      if (debugLevel > 2) console.info(`LanguageChangeWrapper: ${location.pathname} -> ${targetPath}, search: ${location.search}`);
      // navigate does not pass query parameters in URL, and in some cases (e.g. callback from Cognito for Amplify) this is needed
      // UPDATE 230425: it seems to work now;
      // if (location.search.length > 0) {
      //   console.log(redirect(`${targetPath}${location.search}`));
      // } else {
      navigate({ pathname: targetPath, search: location.search });
      // }
    }
  }, [i18n.language]);

  return (<Outlet />);
}
LanguageChangeWrapper.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  i18next: PropTypes.objectOf(PropTypes.any).isRequired,
};
