/* eslint-disable max-len */
// RULES:
// - hidden columns come last
// - dropdown column requires a source parameter containing the array of options

// IMPLEMENTATION:
// - for stocks, split shall be editable and must enforce n:n format by using mask or a custom function: https://bossanova.uk/jspreadsheet/v2/examples/currency-and-masking-numbers
import { currencyCodes } from '../../misc/currencyCodes';
import i18n from '../../i18n';

const transactionTypes = i18n.t('app:schema.transactionTypes', { returnObjects: true }); // returns { purchase: 'Purchase', sale: 'Sale', split: 'Split' }
const transactionTypesSource = Object.entries(transactionTypes).map(([id, name]) => ({ id, name }));

const depositsTransactions = [
  { id: 'date', type: 'date' },
  { id: 'otherParty', type: 'string' },
  { id: 'otherPartyAccount', type: 'string' },
  { id: 'description', type: 'string' },
  { id: 'accountCurrencyAmount', usesHeader: 'amount', type: 'secondaryCurrency' },
  { id: 'fxCurrency', type: 'dropdown', source: currencyCodes },
  { id: 'fxAmount', type: 'number' },
  { id: 'fxRate', type: 'number4Decimals', readOnly: true },
  { id: 'id', type: 'hidden' },
  { id: 'currency', type: 'hidden' },
  { id: 'label', type: 'hidden' },
];

const depositsDuplicates = [
  { id: 'keepFlag', type: 'checkbox' },
  { id: 'source', type: 'string', readOnly: true },
  { id: 'date', type: 'date', readOnly: true },
  { id: 'otherParty', type: 'string', readOnly: true },
  { id: 'otherPartyAccount', type: 'string', readOnly: true },
  { id: 'description', type: 'string', readOnly: true },
  {
    id: 'accountCurrencyAmount',
    usesHeader: 'amount',
    type: 'secondaryCurrency',
    readOnly: true,
  },
  {
    id: 'fxCurrency',
    type: 'dropdown',
    source: currencyCodes,
    readOnly: true,
  },
  { id: 'fxAmount', type: 'number', readOnly: true },
  { id: 'fxRate', type: 'number4Decimals', readOnly: true },
  { id: 'id', type: 'hidden', readOnly: true },
  { id: 'currency', type: 'hidden', readOnly: true },
  { id: 'label', type: 'hidden', readOnly: true },
];

// CAUTION: there is a customOnChange function for stocks which derives the "displayName" field from the dropdown of "displaySymbol"
// as they work based on column indices, update them in accountDetails/transactions/stocks when changing the sequence of this object
const stocksTransactions = [
  { id: 'date', type: 'date' },
  { id: 'displaySymbol', type: 'customDropdown', usesHeader: 'symbol', width: 150 },
  { id: 'displayName', type: 'string' },
  { id: 'transactionType', type: 'dropdown', source: transactionTypesSource, width: 150 },
  { id: 'split', type: 'string' },
  { id: 'transactionAmount', type: 'number0Decimals' },
  { id: 'transactionOriginalPrice', type: 'number', usesHeader: 'transactionPrice' },
  { id: 'transactionCurrency', type: 'dropdown', source: currencyCodes },
  { id: 'rebasedOriginalValue', type: 'number', readOnly: true },
  { id: 'rebasedValue', type: 'currency', readOnly: true },
  { id: 'rebasedAmount', type: 'number0Decimals', readOnly: true },
  { id: 'rebasedOriginalPrice', type: 'number', readOnly: true },
  { id: 'id', type: 'hidden' },
  { id: 'assetId', type: 'hidden' },
  { id: 'providerAssetId', type: 'hidden' },
];

const stocksDuplicates = [
  { id: 'date', type: 'date' },
  { id: 'displaySymbol', type: 'string', usesHeader: 'symbol' },
  { id: 'displayName', type: 'string', usesHeader: 'description' },
  { id: 'transactionAmount', type: 'number0Decimals' },
  { id: 'transactionOriginalPrice', type: 'number', usesHeader: 'transactionPrice' },
  { id: 'transactionCurrency', type: 'dropdown', source: currencyCodes },
  { id: 'id', type: 'hidden' },
  { id: 'assetId', type: 'hidden' },
];

const realEstateTransactions = [
  { id: 'date', type: 'date' },
  // { id: 'transactionType', type: 'dropdown', source: ['purchase', 'sale'] },
  { id: 'transactionType', type: 'dropdown', source: transactionTypesSource.filter((x) => x.id !== 'split') },
  { id: 'price', type: 'currency0Decimals' },
  { id: 'currency', type: 'dropdown', source: currencyCodes },
  { id: 'originalPrice', type: 'currency0Decimals' },
  { id: 'id', type: 'hidden' },
  { id: 'amount', type: 'hidden' },
  { id: 'assetId', type: 'hidden' },
];

const objectsOfValueTransactions = [
  { id: 'date', type: 'date' },
  // { id: 'transactionType', type: 'dropdown', source: ['purchase', 'sale'] },
  { id: 'transactionType', type: 'dropdown', source: transactionTypesSource.filter((x) => x.id !== 'split'), width: 150 },
  { id: 'description', type: 'string', width: 200 },
  { id: 'quantity', type: 'number', usesHeader: 'amount' },
  { id: 'valueTransactionCurrency', type: 'currency', width: 200 },
  { id: 'transactionCurrency', type: 'dropdown', source: currencyCodes },
  { id: 'valueAccountCurrency', type: 'currency' },
  { id: 'valueBaseCurrency', type: 'currency', width: 200 },
  { id: 'id', type: 'hidden' },
  { id: 'assetId', type: 'hidden' },
];

const loansTransactions = [
  { id: 'date', type: 'date' },
  { id: 'otherPartyIban', type: 'string', usesHeader: 'iban' },
  { id: 'description', type: 'string' },
  { id: 'quantity', type: 'currency', usesHeader: 'principal' },
  { id: 'quantityInterest', type: 'currency', usesHeader: 'interest' },
  { id: 'fxCurrency', type: 'dropdown', source: currencyCodes },
  { id: 'fxAmount', type: 'number' },
  { id: 'fxAmountInterest', type: 'number' },
  { id: 'fxRate', type: 'number4Decimals', readOnly: true },
  { id: 'id', type: 'hidden' },
  { id: 'accountCurrency', type: 'hidden' },
  { id: 'label', type: 'hidden' },
];

const pensionLabelsTranslations = i18n.t('app:accountDetails.pension.labels', { returnObjects: true });
// needs { id, name }
const pensionLabels = Object.entries(pensionLabelsTranslations)
  .filter(([id]) => id !== 'pension-purchase') // do not show the purchase label (it is embedded with the account)
  .map(([id, obj]) => ({ id, name: id === 'noLabel' ? ' ' : obj.long }));

const pensionTransactions = [
  { id: 'date', type: 'date' },
  { id: 'description', type: 'string', width: 500 },
  { id: 'quantity', usesHeader: 'amount', type: 'currency' },
  { id: 'label', type: 'dropdown', source: pensionLabels, width: 200 },
  { id: 'id', type: 'hidden' },
  { id: 'currency', type: 'hidden' },
];

const metalsList = Object.entries(i18n.t('app:schema.metals.transaction.parameters.assetMetal', { returnObjects: true })) // returns { gold: 'Gold', silver: 'Silver'...}
  .map(([id, name]) => ({ id, name }));

// CAUTION: there is a customOnChange function for stocks which derives the "displayName" field from the dropdown of "displaySymbol"
// as they work based on column indices, update them in accountDetails/transactions/stocks when changing the sequence of this object
const metalsTransactions = [
  { id: 'date', type: 'date' },
  { id: 'assetName', type: 'customDropdown', width: 150 },
  { id: 'assetMetal', type: 'dropdown', source: metalsList },
  { id: 'assetWeight', type: 'number' },
  { id: 'assetPurity', type: 'number0Decimals' },
  { id: 'assetAdditionalValue', type: 'number' },
  { id: 'quantity', type: 'number0Decimals' },
  { id: 'uptc', type: 'number', usesHeader: 'transactionPrice' },
  { id: 'transactionCurrency', type: 'dropdown', source: currencyCodes },
  { id: 'transactionCurrencyValue', type: 'number' },
  { id: 'upac', type: 'number', usesHeader: 'transactionPrice' },
  { id: 'transactionValue', type: 'number' },
  { id: 'id', type: 'hidden' },
  { id: 'accountCurrency', type: 'hidden' },
  { id: 'assetId', type: 'hidden' },
];

const stocksQuotes = [
  // { id: 'displaySymbol', type: 'string', usesHeader: 'assetId' },
  // dropdownDynamic is a native jspreadsheet dropdown which is populated at render time (to avoid problem with data not being refreshed)
  // dropdownDynamic requires the same format of column source as customDropdown
  { id: 'displaySymbol', type: 'dropdownDynamic', usesHeader: 'symbol', width: 175 },
  { id: 'date', type: 'date' },
  { id: 'source', type: 'string', readOnly: true },
  { id: 'quoteBaseCurrency', usesHeader: 'price', type: 'currency' },
  { id: 'currency', type: 'dropdown', source: currencyCodes },
  { id: 'quote', usesHeader: 'originalPrice', type: 'number' },
];

const realEstateQuotes = [
  { id: 'date', type: 'date' },
  { id: 'source', type: 'string', readOnly: true },
  { id: 'quoteBaseCurrency', usesHeader: 'price', type: 'currency0Decimals' },
  { id: 'currency', type: 'dropdown', source: currencyCodes },
  { id: 'quote', usesHeader: 'originalPrice', type: 'number0Decimals' },
  { id: 'pricePerSquareMetre', type: 'currency0Decimals', readOnly: true },
  { id: 'originalPricePerSquareMetre', type: 'number0Decimals', readOnly: true },
  { id: 'assetId', type: 'hidden' },
  // ^^ so that we may know which quote to DELETE (those that only have an id), to PUT (those that have a previouslyThere and other content) and to POST (those that have no previouslyThere and other content)
  // this is applied in CategoryWrapper
];

const objectsOfValueQuotes = [
  { id: 'date', type: 'date' },
  { id: 'source', type: 'string', readOnly: true },
  { id: 'quote', usesHeader: 'originalPrice', type: 'number0Decimals' },
  { id: 'currency', type: 'dropdown', source: currencyCodes, readOnly: true },
  { id: 'quoteBaseCurrency', usesHeader: 'price', type: 'currency0Decimals' },
  { id: 'note', type: 'string', width: 300 },
  { id: 'assetId', type: 'hidden' },
  // ^^ so that we may know which quote to DELETE (those that only have an id), to PUT (those that have a previouslyThere and other content) and to POST (those that have no previouslyThere and other content)
  // this is applied in CategoryWrapper
];

const pensionQuotes = [
  { id: 'date', type: 'date' },
  { id: 'payoutOnlyContributionsToDate', type: 'currency', width: 300 },
  { id: 'payoutOnlyContributionsToDateIndexed', type: 'currency', width: 350 },
  { id: 'payoutAllPlannedContributions', type: 'currency', width: 300 },
  { id: 'payoutAllPlannedContributionsIndexed', type: 'currency', width: 350 },
  { id: 'currency', type: 'hidden' },
];

const metalsQuotes = [
  { id: 'assetName', type: 'dropdownDynamic', usesHeader: 'assetId', width: 200 },
  { id: 'date', type: 'date' },
  { id: 'source', type: 'string', readOnly: true },
  { id: 'quoteBaseCurrency', usesHeader: 'price', type: 'currency' },
  { id: 'currency', type: 'dropdown', source: currencyCodes },
  { id: 'quote', usesHeader: 'originalPrice', type: 'number' },
  { id: 'note', type: 'string' },
];

export default function getGridLayout(category, mode, displayedComponent = 'grid') {
  if (!category || !mode) return [];

  if (['grid', 'table'].includes(displayedComponent) && mode === 'quotes') {
    switch (category) {
      case 'realEstate':
        return realEstateQuotes;
      case 'stocks':
      case 'crypto':
        return stocksQuotes;
      case 'pension':
        return pensionQuotes;
      case 'objectsOfValue':
        return objectsOfValueQuotes;
      case 'metals':
        return metalsQuotes;
      default:
        return [];
    }
  }

  if (['grid', 'table'].includes(displayedComponent) && mode === 'transactions') {
    switch (category) {
      case 'deposits':
        return depositsTransactions;
      case 'stocks':
      case 'crypto':
        return stocksTransactions;
      case 'realEstate':
        return realEstateTransactions;
      case 'loans':
        return loansTransactions;
      case 'pension':
        return pensionTransactions;
      case 'objectsOfValue':
        return objectsOfValueTransactions;
      case 'metals':
        return metalsTransactions;
      default:
        return [];
    }
  }

  if (displayedComponent === 'duplicate') {
    if (category === 'deposits') return depositsDuplicates;
    if (category === 'stocks' && mode === 'transactions') return stocksDuplicates;
    if (category === 'stocks' && mode === 'quotes') return stocksQuotes;
  }
  return [];
}
