import React from 'react';

const dashboard = [
  {
    type: 'intro',
    id: 'DA-intro',
    steps: [
      {
        selector: '#net-worth-kpis',
        content: () => (
          <div>
            <p className="pb-4">
              Welcome to your
              <strong> Dashboard </strong>
              -- all your assets at a glance.

            </p>
            <p>To the left -- your key financial indicators and their current and past values.</p>
          </div>

        ),
      },
      {
        selector: '#kpi-previousValue',
        content: () => (
          <div>
            <p className="pb-4">
              You can compare your current wealth with the past. We call that past situation
              {' '}
              <strong>Baseline.</strong>
            </p>
            <p>You can set the Baseline date by clicking on the bottom handle.</p>
          </div>

        ),
      },
      {
        selector: '#edit-mode',
        content: () => (
          <p>
            Use
            {' '}
            <strong>Edit Mode</strong>
            {' '}
            to add and delete accounts.
          </p>
        ),
      },
      {
        selector: '#sync-all-button',
        content: () => (
          <p>
            <strong>Sync</strong>
            {' '}
            buttons help to retrieve the most recent transactions.
          </p>
        ),
      },
      {
        selector: '#your-assets',
        content: () => (
          <p>
            <strong>Your accounts</strong>
            {' '}
            will show up below. Hover your mouse the account tile to see recent transactions and click for more.
          </p>
        ),
      },
      {
        selector: '#add-new-deposits-button',
        content: 'Let us get started! We have added some demo data for you to play around with.',
      },
    ],
  },
];

export default dashboard;
