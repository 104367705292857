import React from 'react';
import propTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { pensionTransactions, globalQuotesView } from '../../redux/reducers/data';
import ChartWrapper from '../reports/ChartWrapper';

export default function PensionIndicators({ accountId }) {
  const { t } = useTranslation('app', { keyPrefix: 'accountDetails.pensionIndicators' });

  const accounts = useSelector((state) => state.data.pension.accounts);
  const thisAccount = accounts.find((a) => a.id === accountId);
  const transactions = useSelector(pensionTransactions);
  const thisAccountTransactions = transactions.filter((tx) => tx.accountId === accountId);
  const sumContributions = thisAccountTransactions
    .filter((tx) => tx.label === 'pension-contribution')
    .reduce((acc, tx) => acc + tx.quantity, 0);
  const quotes = useSelector(globalQuotesView);
  const thisAccountSpecialQuotes = quotes[accountId]?.current.specialQuotes;
  const currentQuote = (thisAccountSpecialQuotes || []).find((sq) => sq.type === 'payoutOnlyContributionsToDateIndexed')?.quoteBaseCurrency
    || (thisAccountSpecialQuotes || []).find((sq) => sq.type === 'payoutOnlyContributionsToDate')?.quoteBaseCurrency // if the previous one is not found
    || 0;
  // ↑↑ the correct type of quote will automatically be included there by globalQuotesView (this only makes sens for contributions to date, preferably indexed)
  const presentValue = currentQuote * (thisAccountTransactions.reduce((acc, curr) => acc + (curr.label === 'pension-purchase' ? (curr.quantity || 0) : 0), 0));
  const percentPVToContributions = presentValue / sumContributions;

  const accountCurrency = thisAccount.currency;

  return (
    <div className="w-full gap-2 grid">
      <div className="relative p-2 sm:p-4 w-full border border-gray-300 rounded-lg col-span-2">
        <h3 className="absolute font-bold text-base sm:text-lg mb-1 sm:mb-2 col-span-3">{t('presentValueChanges')}</h3>
        <ChartWrapper
          reportId="quotesByAssetIdxxxxxx"
          filterCallback={(item) => item.assetId === accountId}
          layoutOptions={{
            axisBottom: { tickRotation: -90 },
            margin: { top: 60 },
          }}
        />
      </div>
      {/* show this only if there is a currentQuote */}
      {(currentQuote) && (
      <div className="p-2 sm:p-4 w-full border border-gray-300 rounded-lg col-span-2 grid grid-cols-2">
        <h3 className="font-bold text-base sm:text-lg mb-1 sm:mb-2 col-span-3">{t('roi')}</h3>
        <div>
          <div className="text-xs xs:text-sm">{t('sumContributions')}</div>
          <div className="text-[1rem] transform scale-y-110 sm:scale-y-100 font-bold text-gray-900 sm:flex sm:items-baseline lg:block xl:flex gap-1 xl:text-xl">
            {Math.abs(sumContributions).toLocaleString('de', { style: 'currency', currency: accountCurrency })}
          </div>
        </div>
        <div>
          <div className="text-xs xs:text-sm">{t('presentValue')}</div>
          <div className="text-[1rem] transform scale-y-110 sm:scale-y-100 font-bold text-gray-900 sm:flex sm:items-baseline lg:block xl:flex gap-1 xl:text-xl">
            {(presentValue).toLocaleString('de', { style: 'currency', currency: accountCurrency })}
            <span className={`${percentPVToContributions >= 1 ? 'text-green-500' : 'text-brandRed-500'} flex items-baseline text-xs sm:text-sm font-semibold`}>
              {percentPVToContributions.toLocaleString('de', { style: 'percent', maximumFractionDigits: 1 })}
            </span>
          </div>
          {/* add presentValue as % of contribution */}
        </div>
      </div>
      )}
    </div>

  );
}
PensionIndicators.propTypes = {
  accountId: propTypes.string.isRequired,
};
