/* eslint-disable react/jsx-no-bind */
/* eslint-disable max-len */
/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { TrashIcon } from '@heroicons/react/24/solid';
import { CheckCircleIcon, ExclamationTriangleIcon, CalculatorIcon, ClipboardDocumentListIcon, QuestionMarkCircleIcon } from '@heroicons/react/24/outline';
import { ArrowRightIcon } from '@heroicons/react/20/solid';
import { postMixedData, completeAssetView } from '../../redux/reducers/data';
import { setAlert, clearAlert, setMessage } from '../../redux/actions/message';
import { transactionTypes } from './AddProjectTransaction';
import ToolTipNoIcon from '../../elements/ToolTipNoIcon';
import MiniSpinner from '../../misc/MiniSpinner';
import AccountLabel from '../../elements/AccountLabel';

function AccountBanner({ transactionType, getSecondAccount, transaction, getAccountNameFromId, accounts, getAccountCategoryFromId, formatting }) {
  return (
    <div className={`gap-1 sm:gap-2 lg:gap-4 ${formatting} items-center`}>
      {transactionType === 'buy' && (
        <>
          <AccountLabel accountName={getSecondAccount(transaction).accountName} category={getSecondAccount(transaction).accountCategory} />
          <ArrowRightIcon className="w-4 h-4 lg:w-5 lg:h-5 text-gray-400" />
        </>
      )}
      <AccountLabel accountName={getAccountNameFromId(transaction.accountId, accounts)} category={getAccountCategoryFromId(transaction.accountId, accounts)} />
      {(transactionType === 'transfer' || transactionType === 'sell') && (
        <>
          <ArrowRightIcon className="w-4 h-4 lg:w-5 lg:h-5 text-gray-400" />
          <AccountLabel accountName={getSecondAccount(transaction).accountName} category={getSecondAccount(transaction).accountCategory} />
        </>
      )}
    </div>
  );
}
AccountBanner.propTypes = {
  transactionType: PropTypes.string.isRequired,
  getSecondAccount: PropTypes.func.isRequired,
  transaction: PropTypes.objectOf(PropTypes.any).isRequired,
  getAccountNameFromId: PropTypes.func.isRequired,
  accounts: PropTypes.array.isRequired,
  getAccountCategoryFromId: PropTypes.func.isRequired,
  formatting: PropTypes.string,
};
AccountBanner.defaultProps = {
  formatting: 'flex',
};

export function TransactionComponent({ transaction, project, setTransactionDialog, visibleRecurringTransactions, setVisibleRecurringTransactions, setAssetTileHover }) {
  const ibans = useSelector((state) => state.data.deposits.accounts.map((account) => account.iban));

  function purify(str) {
    return str?.replace(/[^a-zA-Z0-9]/g, '').toUpperCase();
  }

  // non-simulated (actual) transactions don't have a tag.type, which is used to display an icon and to display the two accounts for transfer / buy / sell
  // handle that here
  function determineTransactionType(localTransaction) {
    // if it isn't deposit, then it is a sale or a purchase, depending on the amount
    if (transaction.category !== 'deposits') {
      // FIX 240429 take advantage of the new generic field structure
      // if (transaction.amount > 0 || transaction.rebasedAmount > 0 || transaction.quantity > 0) {
      if (transaction.quantity > 0) return 'sell';
      return 'buy';
    }

    // if otherPartyAccount is one of user's own ibans, then it is a transfer
    // eventually there will be a positive and a negative transaction for the same amount, one will have been filtered out previously
    if (ibans.includes(purify(transaction.otherPartyAccount))) return 'transfer';

    // the remaining cases are inflows and outflows
    // FIX 240429 take advantage of the new generic field structure
    // if (transaction.amount > 0 || transaction.quantity > 0) return 'inflow';
    if (transaction.quantity > 0) return 'inflow';
    return 'outflow';
  }

  const tags = typeof transaction.tags === 'string' ? JSON.parse(transaction.tags) : transaction.tags;
  const transactionType = tags?.type || determineTransactionType(transaction); // if tags.type not provided, use the logic above

  const initialBalanceExceeded = ['sale', 'inflow'].includes(transactionType) ? false : undefined;
  const [balanceExceeded, setBalanceExceeded] = useState(initialBalanceExceeded);

  const account = transaction.accountId;
  const hiddenAccount = transaction.hiddenTransaction?.accountId;
  const depositAccountId = ['transfer', 'purchase'].includes(transaction.transactionType) ? hiddenAccount : account;

  // try not to load selector on unnecessary transaction types
  // go to the right account and get the saldo; this will have one and only one assetId (because assetId is accountCurrency)
  // transactions are sorted by date, so find will find the first transaction fulfilling the condition (get transaction by date)
  const selectCAV = useSelector(completeAssetView);
  let thisTransactionDepositSaldo = Infinity; // fallback (don't show warning if there is no saldo to compare to)
  try {
    const thisTransactionDepositSide = Object.values(selectCAV.deposits[depositAccountId])[0].find((t) => t.date <= transaction.date);
    thisTransactionDepositSaldo = thisTransactionDepositSide.positionOnDate * thisTransactionDepositSide.upbc;
  } catch (error) {
    console.info(
      'ERROR TransactionComponent > thisTransactionDepositSaldo failed for parameters: depositAccountId:',
      depositAccountId,
      'CAV branch:',
      selectCAV.deposits[depositAccountId],
      'transaction:',
      transaction,
      'error message:',
      error.message,
    );
  }
  // this useEffect handles "lazy loading" of the transaction OK indicator (state starts as undefined and displays spinner; then the selector is calculated and returns true or false)
  // logic:
  // - for outflow, purchase and transfer, determine what is the available saldo on the source deposit account and display warning if this transaction exceeds it
  // - outflow is a deposit transaction (check saldo of its account)
  // - purchase is an asset transaction (check saldo of hiddenAccount)
  // - transfer: this is the receiving side of transfer, so check hiddenAccount
  useEffect(() => {
    if (['buy', 'outflow', 'transfer'].includes(transactionType) && thisTransactionDepositSaldo) {
      // FIX 240429 take advantage of the new generic field structure
      // const freeDepositBalance = thisTransactionDepositSaldo + (transaction.accountCurrencyAmount || -transaction.rebasedAmount * transaction.rebasedPrice || -transaction.amount * transaction.price);
      // TODO I'm assuming accountCurrencyAmount is here to handle deposits in projects, but come back to it later
      const freeDepositBalance = thisTransactionDepositSaldo + (transaction.accountCurrencyAmount || -transaction.quantity * transaction.upbc);
      setBalanceExceeded(freeDepositBalance < 0);
    }
  }, [transaction]);

  // after 15 seconds from initialisation, check if balanceExceeded is either true or false
  // if it is not, set it to 'timeout'
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (balanceExceeded === undefined) setBalanceExceeded('timeout');
    }, 15000);
    return () => clearTimeout(timeout);
  }, []);

  const colour = transactionType && transactionTypes.filter((item) => item.id === transactionType)[0].colour;
  const Icon = transactionType && transactionTypes.filter((item) => item.id === transactionType)[0].icon;

  const accounts = useSelector((state) => {
    const categories = Object.keys(state.data);
    return categories.map((category) => (state.data[category].accounts || []).map((accountsArray) => ({ ...accountsArray, category }))).flat();
  });

  const sliderDate = useSelector((state) => state.simulation.baseDate);

  const dispatch = useDispatch();

  function getAccountNameFromId(accountId, _accounts) {
    const a = _accounts.find((acc) => acc.id === accountId);
    return a ? a.name : '';
  }

  function getAccountCategoryFromId(accountId, _accounts) {
    const a = _accounts.find((acc) => acc.id === accountId);
    return a ? a.category : '';
  }

  function getSecondAccount(_transaction) {
    // console.info('getSecondAccount', _transaction, 'transactions view', transactions);
    // 240322 FIX PL: we seem to have hiddenTransactions in the _transaction object which already contains the one that was matched to the _transaction
    //                so why bother deriving it again?
    // const secondTransaction = _transaction.linkedTransactions?.find((t) => t.tags.type === transactionType);
    // const secondTransactionId = secondTransaction?.id;

    // get accountId for the second transaction
    // let secondAccountId = transactions.find((t) => t.id === secondTransactionId)?.accountId;
    // if (!secondAccountId) {
    //   // try to get the account from tags
    //   secondAccountId = _transaction.tags?.accountId;
    //   if (!secondAccountId) {
    //     console.error('getSecondAccount: matching account could not be found for parameters:', _transaction, 'secondTransactionId:', secondTransactionId);
    //   }
    // }
    const secondAccountId = _transaction.hiddenTransaction?.accountId;
    if (!secondAccountId) {
      console.info('ERROR getSecondAccount: matching account could not be found for this transaction:', _transaction);
    }
    return { accountName: getAccountNameFromId(secondAccountId, accounts), accountCategory: getAccountCategoryFromId(secondAccountId, accounts) };
  }

  const message = useSelector((state) => state.message.message) || {};

  const [deleting, setDeleting] = useState(false);

  // handle delete spinner when postData returns an error
  // if action is successful, then the component will be unmounted, so no need to handle that
  useEffect(() => {
    if (message === 'dataUpdatedWithErrors' || message === 'dataUpdateProblems') {
      setDeleting(false);
    }
  }, [message]);

  async function deleteOKCallback() {
    dispatch(clearAlert());
    console.log('running callback');
    setDeleting(true);
    try {
      const payload = [
        { ...transaction, importFlag: 'delete' }, // delete the transaction itself
      ];
      if (transaction.hiddenTransaction) payload.push({ ...transaction.hiddenTransaction, importFlag: 'delete' });
      // FIX 240429 replacing this with hiddenTransaction
      // if (transaction.linkedTransactions.length > 0) {
      //   payload.push({
      //     id: transaction.linkedTransactions.find((lt) => ['purchase-to-transaction', 'sale-to-transaction', 'transfer'].includes(lt.tags?.type)).id,
      //     isSimulated: true,
      //     importFlag: 'delete',
      //     category: transaction.linkedTransactions.find((lt) => ['purchase-to-transaction', 'sale-to-transaction', 'transfer'].includes(lt.tags?.type)).category,
      //   });
      // }
      await dispatch(postMixedData({ payload }));
      setDeleting(false);
    } catch (err) {
      setMessage('dataUpdateError');
      console.error('error', err);
      setDeleting(false);
    }
  }

  function handleDelete(e) {
    e.stopPropagation();
    dispatch(setAlert('youAreAboutToDeleteTransaction', transaction.id, deleteOKCallback));
  }

  function handleRecurringChildrenVisibleSwitch(e) {
    e.stopPropagation();
    // if transaction.id is in the array, remove it and if it is not - add it
    if (visibleRecurringTransactions.includes(transaction.id)) {
      setVisibleRecurringTransactions((prevState) => prevState.filter((id) => id !== transaction.id) || []);
    } else {
      setVisibleRecurringTransactions((prevState) => [...prevState, transaction.id]);
    }
  }

  // define globally here if a transaction is not clickable
  // if transaction has nonEditable flag (generated automatically to account for account growth) or it has a recurringParentId (it's a recurring child), don't make it clickable
  const nonClickable = transaction.recurringParentId || tags?.nonEditable;

  // calculate price to be displayed (in baseCurrency)
  function calculateDisplayedPrice(txn) {
    // FIX 240429 take advantage of the new generic field structure
    // let displayedPrice;
    // switch (txn.category) {
    //   case 'deposits':
    //     displayedPrice = txn.amount;
    //     break;
    //   case 'stocks':
    //     displayedPrice = txn.rebasedPrice * txn.rebasedAmount;
    //     break;
    //   case 'realEstate':
    //     displayedPrice = txn.originalPrice;
    //     break;
    //   case 'loans':
    //     displayedPrice = txn.quantity * txn.upbc;
    //     break;
    //   default:
    //     displayedPrice = txn.unitPriceBaseCurrency * txn.quantity;
    // }
    const displayedPrice = txn.quantity * txn.upbc;
    return displayedPrice.toLocaleString('de', { maximumFractionDigits: 0, style: 'currency', currency: project.projectCurrency || 'EUR' });
  }

  function getDescription(txn) {
    try {
      if (txn.category === 'stocks') {
        // FIX 240429 take advantage of the new generic field structure
        // return `${transaction.displayName}  |  ${transaction.amount} x ${transaction.transactionOriginalPrice} ${transaction.transactionCurrency}`;
        return `${transaction.displayName}  |  ${transaction.quantity} x ${transaction.uptc} ${transaction.transactionCurrency}`;
      }
      return txn.description || '';
    } catch (err) {
      console.error('TransactionComponent > getDescription failed for parameters:', JSON.stringify(txn), err);
      return '';
    }
  }

  return (
    <div className="w-full pb-1">
      {/* transaction */}
      <div
        onKeyPress={(e) => {}}
        role="button"
        tabIndex={0}
        // this corresponds to the lg breakpoint
        onClick={() => !nonClickable && window.matchMedia('(min-width: 1024px)').matches && setTransactionDialog(transaction)}
        onMouseOver={() => setAssetTileHover(transaction.accountId)}
        onMouseOut={() => setAssetTileHover(null)}
        onFocus={() => setAssetTileHover(transaction.accountId)}
        onBlur={() => setAssetTileHover(null)}
        className={`w-full px-3 py-2 flex justify-between bg-white border border-gray-300 rounded-lg shadow-sm overflow-x-hidden
                  ${nonClickable ? 'border-dashed border- border cursor-default' : 'hover:bg-brandBlue-50 group cursor-pointer'}
                  ${Number(transaction.date.valueOf()) < Number(sliderDate) ? 'opacity-60' : ''}`}
        name="project-transaction"
      >
        <div className="flex items-center gap-2 lg:gap-12">
          <span className={`mt-1 lg:mt-0 inline-flex h-8 w-8 items-center justify-center rounded-full ${colour || 'bg-gray-400'} text-white`}>
            {/* the tags object arrives from getData as a string and needs to be parsed */}
            {<Icon className="w-5 h-5" /> || <div />}
          </span>
          <div>
            <span className={`text-gray-400 text-xs lg:text-sm font-medium ${transaction.isSimulated && 'italic'}`}>{calculateDisplayedPrice(transaction)}</span>
            {/* ↓ only below LG */}
            <span className={`ml-2 lg:hidden text-gray-400 text-xs truncate ${transaction.isSimulated && 'italic'}`}>{`| ${getDescription(transaction).toUpperCase()}`}</span>
            <AccountBanner
              formatting="flex lg:hidden"
              transactionType={transactionType}
              getSecondAccount={getSecondAccount}
              transaction={transaction}
              getAccountNameFromId={getAccountNameFromId}
              accounts={accounts}
              getAccountCategoryFromId={getAccountCategoryFromId}
            />
          </div>
          {/* ↓ only LG and above */}
          <AccountBanner
            formatting="hidden lg:flex"
            transactionType={transactionType}
            getSecondAccount={getSecondAccount}
            transaction={transaction}
            getAccountNameFromId={getAccountNameFromId}
            accounts={accounts}
            getAccountCategoryFromId={getAccountCategoryFromId}
          />
          {/* use this if need to limit to two lines: https://tailwindcss.com/docs/plugins#line-clamp */}
          {/* ↓ only LG and above */}
          <div className={`hidden lg:block text-gray-400 text-sm truncate ${transaction.isSimulated && 'italic'}`}>{getDescription(transaction)}</div>
        </div>
        {/* this will not show on child recurring transactions because they don't have the group tag on the main div level */}
        <div className="flex items-center gap-4">
          {!deleting && (
            <button type="button" className="hidden group-hover:inline-flex items-center justify-center rounded-full w-8 h-8 bg-gray-300 z-10" onClick={handleDelete}>
              <TrashIcon className="w-5 h-5 text-white" />
            </button>
          )}
          {deleting && (
            <span className="inline-flex items-center justify-center rounded-full w-8 h-8 bg-gray-300 z-10">
              <MiniSpinner className="w-5 h-5 text-white animate-spin" />
            </span>
          )}
          {tags?.partOfProjectTemplate && (
            <ToolTipNoIcon info="This transaction is part of a project template.">
              <ClipboardDocumentListIcon className="w-5 h-5 text-gray-400" name="project-template-icon" />
            </ToolTipNoIcon>
          )}
          {/* Only show for injected transactions representing account growth */}
          {tags?.interestPayment && (
            <ToolTipNoIcon info="This is a simulated interest or dividend payment. It has been added automatically based on Account settings.">
              <CalculatorIcon className="w-5 h-5 text-gray-400" />
            </ToolTipNoIcon>
          )}
          {/* only show on parent recurring transactions, all child-visible transactions stored in state of this component */}
          {tags?.recurring?.activated && (
            <button
              type="button"
              id="project-transaction-recurring-button"
              onClick={handleRecurringChildrenVisibleSwitch}
              className={`p-2 rounded-md
              ${
                visibleRecurringTransactions.includes(transaction.id)
                  ? 'bg-gray-400 text-white hover:bg-gray-500 shadow-inner border border-transparent'
                  : 'bg-white hover:bg-gray-50 border border-gray-300 text-gray-500 '
              }`}
            >
              <svg width="18px" height="18px" version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg" stroke="currentColor" fill="currentColor">
                <path
                  strokeWidth="45px"
                  d="m600 72c-291.32 0-528 236.68-528 528 0 291.32 236.68 528 528 528s528-236.68 528-528c0.089844-6.4258-2.3984-12.617-6.9062-17.191-4.5117-4.5742-10.668-7.1484-17.094-7.1484s-12.582 2.5742-17.094 7.1484c-4.5078 4.5742-6.9961 10.766-6.9062 17.191 0 265.38-214.62 480-480 480s-480-214.62-480-480c0-265.38 214.62-480 480-480 147.13 0 280.3 64.652 368.62 168h-123.75c-6.4258-0.089844-12.617 2.3984-17.191 6.9062-4.5742 4.5117-7.1523 10.668-7.1523 17.094s2.5781 12.582 7.1523 17.094c4.5742 4.5078 10.766 6.9961 17.191 6.9062h167.25c8.125 2.1797 16.797-0.054688 22.859-5.8867s8.6289-14.41 6.7656-22.613v-168.38c0.16406-6.9844-2.7266-13.695-7.9102-18.379-5.1875-4.6797-12.156-6.8711-19.09-5.9961-5.8984 0.74219-11.309 3.6445-15.188 8.1484-3.8789 4.5039-5.9531 10.285-5.8125 16.227v104.62c-97.254-106.3-238.82-171.75-393.75-171.75zm-17.25 249.38c-5.8984 0.74219-11.309 3.6445-15.188 8.1484-3.8789 4.5039-5.9531 10.285-5.8125 16.227v282.75c-0.14844 6.4219 2.2812 12.637 6.75 17.25l149.25 148.88c4.2734 5.3047 10.602 8.543 17.406 8.9062 6.8008 0.36719 13.438-2.1758 18.254-6.9961 4.8203-4.8164 7.3633-11.453 6.9961-18.254-0.36328-6.8047-3.6016-13.133-8.9062-17.406l-141.75-142.12v-273c0.16406-6.9844-2.7266-13.695-7.9102-18.379-5.1875-4.6797-12.156-6.8711-19.09-5.9961z"
                />
              </svg>
            </button>
          )}
          {/*  only show on child recurring transactions (i.e. those whose parents are in component state) */}
          {transaction.recurringParentId && (
            <div className="text-gray-300">
              <svg width="18px" height="18px" version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg" stroke="currentColor" fill="currentColor">
                <path
                  strokeWidth="45px"
                  d="m600 72c-291.32 0-528 236.68-528 528 0 291.32 236.68 528 528 528s528-236.68 528-528c0.089844-6.4258-2.3984-12.617-6.9062-17.191-4.5117-4.5742-10.668-7.1484-17.094-7.1484s-12.582 2.5742-17.094 7.1484c-4.5078 4.5742-6.9961 10.766-6.9062 17.191 0 265.38-214.62 480-480 480s-480-214.62-480-480c0-265.38 214.62-480 480-480 147.13 0 280.3 64.652 368.62 168h-123.75c-6.4258-0.089844-12.617 2.3984-17.191 6.9062-4.5742 4.5117-7.1523 10.668-7.1523 17.094s2.5781 12.582 7.1523 17.094c4.5742 4.5078 10.766 6.9961 17.191 6.9062h167.25c8.125 2.1797 16.797-0.054688 22.859-5.8867s8.6289-14.41 6.7656-22.613v-168.38c0.16406-6.9844-2.7266-13.695-7.9102-18.379-5.1875-4.6797-12.156-6.8711-19.09-5.9961-5.8984 0.74219-11.309 3.6445-15.188 8.1484-3.8789 4.5039-5.9531 10.285-5.8125 16.227v104.62c-97.254-106.3-238.82-171.75-393.75-171.75zm-17.25 249.38c-5.8984 0.74219-11.309 3.6445-15.188 8.1484-3.8789 4.5039-5.9531 10.285-5.8125 16.227v282.75c-0.14844 6.4219 2.2812 12.637 6.75 17.25l149.25 148.88c4.2734 5.3047 10.602 8.543 17.406 8.9062 6.8008 0.36719 13.438-2.1758 18.254-6.9961 4.8203-4.8164 7.3633-11.453 6.9961-18.254-0.36328-6.8047-3.6016-13.133-8.9062-17.406l-141.75-142.12v-273c0.16406-6.9844-2.7266-13.695-7.9102-18.379-5.1875-4.6797-12.156-6.8711-19.09-5.9961z"
                />
              </svg>
            </div>
          )}
          {balanceExceeded === undefined && <MiniSpinner id="balance-exceeded-pending" className="w-5 h-5 text-gray-300 animate-spin" />}
          {balanceExceeded === true && (
            <ToolTipNoIcon info="Warning: this transaction will not be possible. At this moment in the future there are not enough funds on the account of this transaction.">
              <ExclamationTriangleIcon className="w-5 h-5 text-brandYellow-500" id="balance-exceeded-warning" />
            </ToolTipNoIcon>
          )}
          {balanceExceeded === false && (
            <ToolTipNoIcon info="Status OK. Transaction will be possible.">
              <CheckCircleIcon className="w-5 h-5 text-green-500" id="balance-exceeded-ok" />
            </ToolTipNoIcon>
          )}
          {balanceExceeded === 'timeout' && (
            <ToolTipNoIcon info="Unable to determine transaction status.">
              <QuestionMarkCircleIcon className="w-5 h-5 text-gray-400" id="balance-exceeded-ok" />
            </ToolTipNoIcon>
          )}
        </div>
      </div>
      {/* TODO: try the following:
                after the transaction is clicked, the slider automatically moves to where the transaction date is
                so that the user can see the asset status on the right-hand pane */}
    </div>
  );
}
TransactionComponent.propTypes = {
  transaction: PropTypes.objectOf(PropTypes.any).isRequired,
  project: PropTypes.objectOf(PropTypes.any).isRequired,
  setTransactionDialog: PropTypes.func.isRequired,
  visibleRecurringTransactions: PropTypes.array.isRequired,
  setVisibleRecurringTransactions: PropTypes.func.isRequired,
  setAssetTileHover: PropTypes.func.isRequired,
};

export function dummy() {
  return null;
}
