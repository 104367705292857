// CATEGORY MODULE FOR DEPOSITS / TRANSACTIONS

import dayjs from 'dayjs';
import { postFileImportData } from '../../../redux/reducers/data';

const utc = require('dayjs/plugin/utc');

dayjs.extend(utc);

// INPUT transformations

// used to put quote object attributes in the exact order expected by the GRID layout (and the gridLayout.js file which defines the columns in the spreadsheet)
// TABLE will use the same object, but the sequence does not matter (sequence is set in tableLayout.js file)
export function categoryOrderedObject(account = null, displayedComponent = 'table') { //  outer function takes parameters and returns a CALLBACK function for .map with parameters already in
  return function depositsOrdereObjectInnerFunc(transaction) {
    // this has to exactly follow the column order laid out by gridLayout
    return {
      keepFlag: transaction.keepFlag || null,
      source: transaction.source,
      date: dayjs.utc(Number(transaction.date)).format(),
      otherParty: transaction.otherParty,
      otherPartyAccount: transaction.otherPartyAccount,
      description: transaction.description,
      accountCurrencyAmount: transaction.accountCurrencyAmount,
      fxCurrency: transaction.fxCurrency,
      fxAmount: transaction.fxAmount,
      fxRate: (transaction.fxAmount) ? transaction.accountCurrencyAmount / (transaction.fxAmount || 1) : '',
      id: transaction.id,
      currency: transaction.currency,
      label: transaction.label,
    };
  };
}

// OUTPUT transformations

// used inside of .map
// performs category-specific transformations after standard transformations of Grid output
// seqeunce of properties is no longer important
// this happens already after items with inputFlag = 'delete' have been added, so just take care of 'put'

export function outputTransformCategoryTransactions(account = null) { //  outer function takes a parameter returns a CALLBACK function for .map with that parameter already in
  return function outputTransformCategoryTransactionsInnerFunc(item) {
    return item;
  };
}

// Grid handles dispatch and its result; this is just the action creator for post transactions
export function postCategoryItems(data, account) {
  return postFileImportData({ data, category: 'deposits', accountId: account.id });
}

// OTHERS

// used in markDuplicateTransaction to decide if two transactions are duplicated
// if changing / adding fields here, also modify the accountDataSelector below
export function checkIfDuplicate(transaction1, transaction2) {
  return (transaction1.date === transaction2.date && transaction1.accountCurrencyAmount === transaction2.accountCurrencyAmount && transaction1.otherPartyAccount === transaction2.otherPartyAccount);
}
