/* eslint-disable no-underscore-dangle */
/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/forbid-prop-types */
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { InformationCircleIcon } from '@heroicons/react/20/solid';
import { currencyCodes } from '../../../misc/currencyCodes';
import InputField from './InputField';
import ToolTip from '../../../elements/ToolTip';

dayjs.extend(utc);

export function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

function Header({ text }) {
  Header.propTypes = {
    text: PropTypes.string.isRequired,
  };
  const { t: tI } = useTranslation(['app'], { keyPrefix: 'addAccount.crypto' });
  return <h4 className="xs:col-span-2 lg:col-span-3 text-base font-bold text-gray-900">{tI(text)}</h4>;
}

function CryptoAccountData({ index, updateWorkflowStatus, userInput, setUserInput, signalNext, setSignalNext }) {
  const { t: tL } = useTranslation('app', { keyPrefix: 'schema.crypto.account' });
  const { t: tI } = useTranslation(['app'], { keyPrefix: 'addAccount.crypto' });

  const selectDepositAccounts = useSelector((state) => state.data.deposits.accounts);
  const baseCurrency = useSelector((state) => state.user.profile.settings.baseCurrency);

  //--------------------------------------------------------------------------------
  // default values for the form state (userInput + some tweaks)
  //--------------------------------------------------------------------------------

  const defaultValues = { ...(userInput && { ...userInput.data }) };
  defaultValues.currency = defaultValues.currency === undefined ? baseCurrency : defaultValues.currency;
  defaultValues.tags = { spread: 10 }; // default set here, user may change that in account settings later on, but not in this form (too complex)

  // register the hook
  const { register, watch, reset, getValues, control, formState, trigger } = useForm({ mode: 'onChange', defaultValues });
  const { errors, isValid, isDirty } = formState;

  //--------------------------------------------------------------------------------
  // synchronise this component's form with the parent component's form state
  //--------------------------------------------------------------------------------
  function processOutputData(formValues) {
    const obj = { ...formValues };

    // housekeeping: convert string-numbers to numbers
    obj.quantity = Number(formValues.quantity);

    // handle connectedDepositIds
    if (obj.connectedDepositMode === 'existingAccount') {
      const connectedObject = obj.connectedDepositAccountsObject || { id: null, name: null }; // before the user picks the answer here
      obj.connectedDepositAccounts = [connectedObject.id];
    } else if (obj.connectedDepositMode === 'createTechnicalAccount') {
      // handled in AddAccount
    } else {
      obj.connectedDepositAccounts = [];
    }

    return obj;
  }

  useEffect(() => {
    // check if all validations are passed and if so, set component status to true
    if (isDirty && isValid) {
      const output = getValues();
      // console.log('isDirty and isValid both true, getValues:', JSON.stringify(output, null, 2));
      // transform the output data before sending them to caller
      const processedOutput = processOutputData(output);
      // console.log('processed output', processedOutput);
      setUserInput({ ...userInput, ...processedOutput });
      updateWorkflowStatus(index, true);
      // reset isDirty status so that we know if the user touched the form again
      reset({ ...userInput, ...output }, { keepIsValid: true });
    }
    // check if the user invalidated the form and if so, remove the next step
    if (isDirty && !isValid) {
      const output = getValues();
      // console.log('isDirty is true and isValid is not, getValues:', JSON.stringify(output, null, 2));
      // CAVEAT: this approach means that the previously entered data is still potentially within the form
      const processedOutput = processOutputData(output);
      setUserInput({ ...userInput, ...processedOutput });
      // console.log('processed output', processedOutput);
      updateWorkflowStatus(index, false);
      // reset isDirty status so that we know if the user touched the form again
      reset({ ...userInput, ...output }, { keepErrors: true });
    }
  }, [formState]);

  //--------------------------------------------------------------------------------
  // subscribe to signal from parent that the user has clicked the "next" button
  // (and then trigger all validations to show errors)
  //--------------------------------------------------------------------------------

  useEffect(() => {
    if (signalNext) {
      trigger();
      setSignalNext(false);
    }
  }, [signalNext]);

  const connectedDepositMode = watch('connectedDepositMode');

  //---------------------------------------------------------------------------------------------
  // render the form
  //---------------------------------------------------------------------------------------------

  return (
    <div className="space-y-6 md:space-y-0 md:grid md:grid-cols-4 md:gap-6">
      <div className="md:col-span-1">
        <h3 className="text-xl md:text-2xl font-bold leading-6 text-gray-900">{tI('accountInfo')}</h3>
        <p className="mt-4 text-sm text-gray-500">{tI('provideInfo')}</p>
      </div>

      <form className="md:col-span-3 ">
        <div className="divide-y divide-gray-200">
          {/* SECTION 1: name */}

          <div className="py-3 lg:pb-4 grid xs:grid-cols-2 lg:grid-cols-3 gap-4 xs:gap-6 lg:gap-8">
            <InputField
              id="name"
              label={tL('name.label')}
              category="crypto"
              type="text"
              placeholder={tL('name.placeholder')}
              autoComplete="off"
              optional={false}
              register={register}
              errors={errors}
              validationRules={{ required: { value: true, message: tL('name.error') } }}
            />
          </div>

          {/* SECTION 2: BROKER + ACCOUNT */}

          <div className="py-3 lg:pb-4 grid xs:grid-cols-2 lg:grid-cols-3 gap-4 xs:gap-6 lg:gap-8">
            <InputField
              id="brokerName"
              label={tL('brokerName.label')}
              category="crypto"
              type="text"
              placeholder={tL('brokerName.placeholder')}
              autoComplete="off"
              optional
              register={register}
              errors={errors}
            />
            <InputField
              id="accountNo"
              label={tL('accountNo.label')}
              category="crypto"
              type="text"
              placeholder={tL('accountNo.placeholder')}
              autoComplete="off"
              optional
              register={register}
              errors={errors}
            />
          </div>

          {/* SECTION 3: CONNECTED DEPOSITS */}

          <div className="py-3 lg:py-8 grid xs:grid-cols-2 lg:grid-cols-3 gap-4 xs:gap-6 lg:gap-8">
            <fieldset className="md:col-span-2 xl:col-span-3 grid md:grid-cols-2 xl:grid-cols-3 justify-items-start items-center gap-4 text-sm text-gray-400">
              <Header text="whenIBuy" />
              <div className="flex items-center">
                <input
                  id="existingAccount"
                  name="existingAccount"
                  type="radio"
                  value="existingAccount"
                  className="h-4 w-4 border-gray-300 text-brandBlue-500 focus:ring-brandBlue-400"
                  {...register('connectedDepositMode', { required: { value: true, message: tL('connectedDepositMode.error') } })}
                />
                <label htmlFor="existingAccount" className="ml-3 block text-sm font-medium text-gray-700">
                  {tI('existingAccount')}
                </label>
                {/* if this radio button is selected, display the deposit accounts dropdown */}
              </div>
              {connectedDepositMode === 'existingAccount' && (
                <div className="xl:flex xl:items-center">
                  <label htmlFor="connectedDepositAccountObject" className="flex text-sm font-medium text-gray-700">
                    {tI('existingAccountSelect')}
                  </label>
                  <div className="mt-1 ml-2">
                    <select
                      id="connectedDepositAccountObject"
                      name="connectedDepositAccountObject"
                      className="text-gray-500 shadow-sm focus:ring-brandBlue-400 focus:border-brandBlue-400 block w-full text-sm border-gray-300 rounded-md"
                      {...register('connectedDepositAccountObject')}
                    >
                      {selectDepositAccounts.map((a) => (
                        <option key={a.id} value={a.id}>
                          {a.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              )}

              <div className="flex items-center col-start-1">
                <input
                  id="createTechnicalAccount"
                  name="createTechnicalAccount"
                  type="radio"
                  value="createTechnicalAccount"
                  className="h-4 w-4 border-gray-300 text-brandBlue-500 focus:ring-brandBlue-400"
                  {...register('connectedDepositMode', { required: { value: true, message: tL('connectedDepositMode.error') } })}
                />
                <label htmlFor="createTechnicalAccount" className="ml-3 block text-sm font-medium text-gray-700">
                  {tI('createTechnicalAccount')}
                </label>
              </div>
              {connectedDepositMode === 'createTechnicalAccount' && (
                <div className="xl:flex xl:items-center">
                  <label htmlFor="currency" className="flex text-sm text-gray-700">
                    {tI('createTechnicalAccountCurrency')}
                    <ToolTip info={tI('createTechnicalAccountTooltip')} formatting="ml-1" />
                  </label>
                  <div className="mt-1 xl:mt-0 xl:ml-2">
                    <select
                      id="currency"
                      name="currency"
                      className="shadow-sm focus:ring-brandBlue-400 focus:border-brandBlue-400 block w-full text-sm border-gray-300 rounded-md min-w-[5.25rem]"
                      {...register('currency')}
                    >
                      {currencyCodes.map((c) => (
                        <option key={c}>{c}</option>
                      ))}
                    </select>
                  </div>
                </div>
              )}

              <div className="flex items-center col-start-1">
                <input
                  id="createAccountLater"
                  name="createAccountLater"
                  type="radio"
                  value="createAccountLater"
                  className="h-4 w-4 border-gray-300 text-brandBlue-500 focus:ring-brandBlue-400"
                  {...register('connectedDepositMode', { required: { value: true, message: tL('connectedDepositMode.error') } })}
                />
                <label htmlFor="createAccountLater" className="ml-3 block text-sm font-medium text-gray-700">
                  {tI('createAccountLater')}
                </label>
              </div>
              {connectedDepositMode === 'createAccountLater' && (
                <div className="flex items-center gap-2">
                  <InformationCircleIcon className="h-10 w-10 text-brandBlue-500" aria-hidden="true" />
                  {tI('createAccountLaterDescription')}
                </div>
              )}
              <p className="col-start-1 mt-2 text-sm text-red-500" id="connectedDepositMode-error">
                {formState.errors.connectedDepositMode && <span>{formState.errors.connectedDepositMode.message}</span>}
              </p>
            </fieldset>
          </div>
        </div>
      </form>
    </div>
  );
}

CryptoAccountData.propTypes = {
  index: PropTypes.number.isRequired,
  updateWorkflowStatus: PropTypes.func.isRequired,
  userInput: PropTypes.object,
  setUserInput: PropTypes.func,
  signalNext: PropTypes.bool,
  setSignalNext: PropTypes.func,
};
CryptoAccountData.defaultProps = {
  userInput: {},
  setUserInput: {},
  signalNext: undefined,
  setSignalNext: () => {},
};

export default CryptoAccountData;
