import getQuotes from '../../../redux/reducers/sharedThunks/getQuotes';
import { calculateDatesAndAssetIds } from '../../../redux/actions/data/helpers';
import { stocksMetadata } from '../../../redux/reducers/data';
import store from '../../../store';

// INPUT transformations

// used to put quote object attributes in the exact order expected by table and grid layouts
export function categoryOrderedObject(account = null) {
  //  outer function takes a parameter returns a CALLBACK function for .map with that parameter already in
  return function stocksOrderedObjectInnerFunc(quote) {
    // this has to exactly follow the column order laid out by gridLayout
    return {
      displaySymbol: stocksMetadata(store.getState()).displaySymbolByAssetId[quote.assetId],
      date: quote.date,
      source: quote.source,
      quoteBaseCurrency: quote.quoteBaseCurrency,
      currency: quote.currency,
      quote: quote.quote,
    };
  };
}

// applies category-related final changes before sending to Grid
// add displaySymbol based on figi
export function applyCategorySpecificChanges(_quotes, account = null) {
  return _quotes.map(categoryOrderedObject());
}

// provide sources for custom dropdown 'displaySymbol"
export function getCustomDropdownSources() {
  // return { displaySymbol: Object.entries(stocksMetadata(store.getState()).displayNameByDisplaySymbol).map(([key, value]) => ({ id: key, name: value })) };
  return { displaySymbol: Object.keys(stocksMetadata(store.getState()).displayNameByDisplaySymbol) };
}

// getCustomOnChange is not needed, because we only need to display displaySymbols for now
// when displaySymbol changes, reapply displayName from existing transactions (if any)
// export function getCustomOnChange() {
//   return function getCustomOnChangeInner(instance, cell, x, y, value) {
//     // if current column is 'displaySymbol'
//     if (instance && instance.jspreadsheet.options.columns[x].name === 'displaySymbol') {
//       // find a column 'displayName' and get the displayName based on displaySymbol's 'value' from stocksMetadata
//       const displayNameColumnIndex = instance.jspreadsheet.options.columns.findIndex((column) => column.name === 'displayName');
//       if (displayNameColumnIndex !== -1) {
//         const displayName = stocksMetadata(store.getState()).displayNameByDisplaySymbol[value] || '';
//         instance.jspreadsheet.setValueFromCoords(displayNameColumnIndex, y, displayName, false);
//       }
//     }
//   };
// }

// OUTPUT transformations

// used inside of .map
// performs category - specific transformations after standard transformations of Grid output
// seqeunce of properties is no longer important
// this happens already after items with inputFlag = 'delete' have been added, so just take care of 'put'
export function outputTransformCategoryQuotes(account = null) {
  //  outer function takes a parameter returns a CALLBACK function for .map with that parameter already in
  return function outputTransformStockQuotesInnerFunc(item) {
    return {
      ...item,
      source: item.source || 'manual',
      importFlag: item.importFlag || 'put',
      assetId: stocksMetadata(store.getState()).assetIdByDisplaySymbol[item.displaySymbol], // in case customer deleted the existing mapping and is re-using the same row
    };
  };
}

// TABLE

// used in table to get quotes from backend
export function handleSync(category, _quotes, dispatch) {
  dispatch(getQuotes(calculateDatesAndAssetIds(_quotes)));
}
