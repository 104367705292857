/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';
import ToolTip from './ToolTip';
import ToolTipNoIcon from './ToolTipNoIcon';

function OptionButtonsMultipleCore({ value, onChange, tPrefix }) {
  OptionButtonsMultipleCore.propTypes = {
    value: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string, selected: PropTypes.bool })).isRequired,
    onChange: PropTypes.func.isRequired,
    tPrefix: PropTypes.string.isRequired,
  };

  const { t } = useTranslation('app', { keyPrefix: tPrefix });
  const [fieldValue, setFieldValue] = useState(value || []);
  // expects field value to be an array of { id, selected }

  // if value changes, update local state
  useEffect(() => {
    setFieldValue(value);
  }, [value]);

  function handleClick(e) {
    // update component state based on e.target.id
    const newFieldValue = fieldValue.map((item) => ({
      id: item.id,
      selected: item.id === e.target.id ? !item.selected : item.selected,
    }));
    setFieldValue(newFieldValue);

    // synchronise local state with parent form state
    onChange(newFieldValue);
  }

  if (!fieldValue || fieldValue.length === 0) return true;

  return (
    <div className="mt-2 flex flex-wrap gap-3 lg:w-full">
      {fieldValue.map((option) => (
        <ToolTipNoIcon key={option.id} info={option.name} classNameOwn="flex-shrink-0">
          <button
            id={option.id}
            type="button"
            onClick={handleClick}
            className={`cursor-pointer focus:outline-none flex items-center justify-center rounded-md py-3 px-3 text-sm font-semibold uppercase sm:flex-1 flex-shrink-0
                        focus:ring-2 focus:ring-brandBlue-600 focus:ring-offset-2
                        ${
                          fieldValue.find((item) => item.id === option.id).selected
                            ? 'bg-brandBlue-500 text-white hover:bg-brandBlue-400'
                            : 'ring-1 ring-inset ring-gray-300 bg-white text-gray-900 hover:bg-gray-50'
                        }`}
          >
            {t(`${option.id}.label`)}
          </button>
        </ToolTipNoIcon>
      ))}
    </div>
  );
}

/**
 * Renders a group of option buttons, allowing user to select multiple. The children are rendered as flex items, wrap is enabled.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.id - The ID of the form state field (should contain [{ id, selected }] )
 * @param {string} props.label - The label for the option buttons.
 * @param {string} [props.tooltip] - The tooltip for the option buttons.
 * @param {Object} props.control - The control object from react-hook-form.
 * @param {string} props.tPrefix - The translation key prefix for the options (expects to find .label and .tooltip for each id in the form state array).
 * @returns {JSX.Element} The rendered OptionButtons component.
 */
export default function OptionButtonsMultiple({ id, label, tooltip, control, tPrefix }) {
  return (
    <Controller
      control={control}
      name={id}
      render={({ field }) => (
        <fieldset aria-label={label}>
          <div className="flex items-center">
            <div className="text-sm font-medium leading-6 text-gray-900">{label}</div>
            {tooltip && <ToolTip info={tooltip} />}
          </div>

          <OptionButtonsMultipleCore value={field.value} onChange={field.onChange} tPrefix={tPrefix} />
        </fieldset>
      )}
    />
  );
}
OptionButtonsMultiple.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
  control: PropTypes.object.isRequired,
  tPrefix: PropTypes.string.isRequired,
};
OptionButtonsMultiple.defaultProps = {
  tooltip: null,
};
