// instructions: leave 1 account in accountName filter and 12 most recent months with data in date filter
const stocks12m = {
  vals: [
    'value',
  ],
  cols: [
    'displayName',
  ],
  dataScope: 'snapshots',
  rows: [
    'Date (Month)',
  ],
  rendererName: 'Bar chart, vertical, diverging, stacked',
  rendererType: 'nivo', // 'nivo' requires a height property to be set on its parent to be displayed
  id: 'stocksByMonthxxxxxxxx',
  name: 'Stocks, assets stacked by month',
  aggregatorName: 'Sum',
  readOnly: true,
};

export default stocks12m;
