import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  currentAssetValueChange,
  baselineAssetValueChange,
  inflowsCurrent,
  inflowsBaseline,
  outflowsCurrent,
  outflowsBaseline,
  incomeFromCapitalCurrent,
  incomeFromCapitalBaseline,
} from '../../redux/reducers/data';
import SumWithLabel from './SumWithLabel';

export default function ChangesInValue({ baseCurrency, isViewer }) {
  // get global selectors
  const selectIncomeFromCapital = isViewer ? useSelector((state) => state.viewer.kpis.incomeFromCapital) : useSelector(incomeFromCapitalCurrent);
  const selectIncomeFromCapitalReferencePeriod = isViewer ? useSelector((state) => state.viewer.kpis.incomeFromCapitalReferencePeriod) : useSelector(incomeFromCapitalBaseline);
  const selectTotalInflows = isViewer ? useSelector((state) => state.viewer.kpis.inflows) : useSelector(inflowsCurrent);
  const selectTotalInflowsReferencePeriod = isViewer ? useSelector((state) => state.viewer.kpis.inflowsReferencePeriod) : useSelector(inflowsBaseline);
  const selectTotalOutflows = isViewer ? useSelector((state) => state.viewer.kpis.outflows) : useSelector(outflowsCurrent);
  const selectTotalOutflowsReferencePeriod = isViewer ? useSelector((state) => state.viewer.kpis.outflowsReferencePeriod) : useSelector(outflowsBaseline);
  const selectCurrentAssetValueChange = isViewer ? useSelector((state) => state.viewer.kpis.assetValueChange) : useSelector(currentAssetValueChange);
  const selectCurrentAssetValueChangeReferencePeriod = isViewer ? useSelector((state) => state.viewer.kpis.assetValueChangeReferencePeriod) : useSelector(baselineAssetValueChange);

  const optionsLocaleCurrency = {
    style: 'currency',
    currency: baseCurrency || 'EUR',
    maximumFractionDigits: 0,
  };

  return (
    <div className="flex flex-col gap-4 sm:gap-8">
      <SumWithLabel
        id="generalIncome"
        stat={selectTotalInflows.toLocaleString('de', optionsLocaleCurrency)}
        change={(selectTotalInflows - selectTotalInflowsReferencePeriod) / Math.abs(selectTotalInflowsReferencePeriod)}
      />
      <SumWithLabel
        id="incomeFromCapital"
        stat={selectIncomeFromCapital.toLocaleString('de', optionsLocaleCurrency)}
        change={(selectIncomeFromCapital - selectIncomeFromCapitalReferencePeriod) / Math.abs(selectIncomeFromCapitalReferencePeriod)}
      />
      <SumWithLabel
        id="assetValueIncrease"
        stat={selectCurrentAssetValueChange.toLocaleString('de', optionsLocaleCurrency)}
        change={(selectCurrentAssetValueChange - selectCurrentAssetValueChangeReferencePeriod) / Math.abs(selectCurrentAssetValueChangeReferencePeriod)}
      />
      <SumWithLabel
        id="outflows"
        stat={selectTotalOutflows.toLocaleString('de', optionsLocaleCurrency)}
        change={(selectTotalOutflows - selectTotalOutflowsReferencePeriod) / Math.abs(selectTotalOutflowsReferencePeriod)}
      />
    </div>
  );
}
ChangesInValue.propTypes = {
  baseCurrency: PropTypes.string.isRequired,
  isViewer: PropTypes.bool,
};
ChangesInValue.defaultProps = {
  isViewer: false,
};
