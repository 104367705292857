/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useRef, useEffect } from 'react';
import { RadioGroup } from '@headlessui/react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm, Controller, useWatch } from 'react-hook-form';
import { API, Auth } from 'aws-amplify';
// eslint-disable-next-line import/no-extraneous-dependencies
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth/lib/types';
import PropTypes from 'prop-types';
import { nanoid } from 'nanoid';
import ToolTip from '../../elements/ToolTip';
import { currencyCodes } from '../../misc/currencyCodes';
import MiniSpinner from '../../misc/MiniSpinner';
import Button from '../../elements/Button';

function SignUpButton({ label, icon, checked, onBlur }) {
  return (
    <div>
      <button
        type="button"
        onBlur={onBlur}
        className={`w-full flex justify-center my-6 py-2 px-4 border rounded-md shadow-sm text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brandBlue-400
        ${checked ? 'border-brandBlue-500 text-white font-medium bg-brandBlue-500 hover:bg-brandBlue-600' : 'border-brandBlue-500 text-brandBlue-500 bg-white hover:bg-brandBlue-50'}`}
      >
        {label}
      </button>
    </div>
  );
}
SignUpButton.propTypes = {
  label: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onBlur: PropTypes.func.isRequired,
};

export async function checkIfUsernameCanBeUsed(username) {
  // this function calls up Cognito and tries to log in with a impossible password of 000000
  // depending on the error code we can find out if the username (alias) already exists
  let returnObject;
  try {
    const result = await Auth.confirmSignUp(username, '000000', { forceAliasCreation: false });
    // if the option parameter is false, the API will throw an AliasExistsException error if the phone number/email used already exists as an alias with a different user
  } catch (err) {
    console.log(err.code);
    switch (err.code) {
      case 'UserNotFoundException':
        return true; // username DOES NOT exist, so return true (= can be used) -- email has to be VERIFIED in order to trigger this error
      case 'NotAuthorizedException':
        return false; // username EXISTS, so return false (= cannot be used)
      case 'AliasExistsException':
        return false;
      default:
        return true;
    }
  }
  return returnObject;
}

export default function SignUp1({ formObject, formSetObject, goForward }) {
  const { t } = useTranslation('site', { keyPrefix: 'register.signUp1' });
  const location = useLocation();
  const stateData = location.state; // Pricing page redirects to here with a tierType and tierId in state which should be preselected

  const useFormObject = useForm({ mode: 'onBlur', defaultValues: { ...formObject, ...stateData } }); // set as subcomponent prop and unpack there, see InternalUserForm
  const { formState } = useFormObject;
  const { touchedFields } = formState;

  function resetLeftColumn(_useFormObject) {
    _useFormObject.resetField('email', { keepTouched: false, keepError: false, defaultValue: '' });
    _useFormObject.resetField('password', { keepTouched: false, keepError: false, defaultValue: '' });
    _useFormObject.resetField('repeatPassword', { keepTouched: false, keepError: false, defaultValue: '' });
  }

  function resetRightColumn(_useFormObject) {
    _useFormObject.resetField('provider', { keepTouched: false, keepError: false, defaultValue: '' });
  }

  function onSubmit(data) {
    // on submit update parent component state
    formSetObject(Object.assign(formObject, { ...data, userId: nanoid() }));
    goForward();
  }

  return (
    <form name="signup1" className="grid grid-cols-1 gap-1 py-8 lg:grid-cols-5" onSubmit={useFormObject.handleSubmit(onSubmit)}>
      <div className="bg-white rounded-md shadow-md p-6 lg:col-start-1 lg:col-end-3" data-testid="create-account">
        <InternalAccountForm useFormObject={useFormObject} resetRightColumn={resetRightColumn} />
        {(Object.keys(touchedFields).includes('email') || Object.keys(touchedFields).includes('password') || Object.keys(touchedFields).includes('repeatPassword')) && (
          <CommonForm useFormObject={useFormObject} />
        )}
      </div>
      <div className="flex lg:col-start-3 lg:col-end-4">
        <div className="text-center mx-auto my-auto py-4">
          <p className="font-black text-3xl lg:text-4xl">{t('or')}</p>
        </div>
      </div>
      <div className="bg-white rounded-md shadow-md p-6 lg:col-start-4 lg:col-end-6">
        <ExternalAccountForm useFormObject={useFormObject} resetLeftColumn={resetLeftColumn} />
        {Object.keys(touchedFields).includes('provider') && <CommonForm useFormObject={useFormObject} />}
      </div>
    </form>
  );
}
SignUp1.propTypes = {
  goForward: PropTypes.func.isRequired,
  formObject: PropTypes.objectOf(PropTypes.any).isRequired,
  formSetObject: PropTypes.func.isRequired,
};

// this is used in Settings > Account as well (as an export from here)
// import the check username function into the module scope on the other side too
export function InputEmail({ useFormObject, resetRightColumn, emailValidation }) {
  const rightColumn = useFormObject.watch('provider');

  const { t } = useTranslation('site', { keyPrefix: 'register.signUp1' });

  return (
    <input
      type="email"
      name="email"
      id="email"
      autoComplete="email"
      onFocus={() => resetRightColumn(useFormObject)}
      className="flex-1 focus:ring-brandBlue-400 focus:border-brandBlue-400 block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
      {...useFormObject.register('email', {
        maxLength: { value: 200, message: t('email.errorLength') },
        pattern: {
          value: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/i,
          message: t('email.errorInvalid'),
        },
        validate: async (value) => {
          if (!rightColumn && !value) return t('email.errorEmpty');
          if (value && !rightColumn && !(await checkIfUsernameCanBeUsed(value))) return t('email.errorTaken');
          return true;
        },
      })}
    />
  );
}
InputEmail.propTypes = {
  useFormObject: PropTypes.objectOf(PropTypes.any).isRequired,
  resetRightColumn: PropTypes.func,
  emailValidation: PropTypes.func,
};
InputEmail.defaultProps = {
  resetRightColumn: () => {},
  emailValidation: async () => {},
};

export function InputUsername({ register, usernameValidation }) {
  const { t } = useTranslation('site', { keyPrefix: 'register.signUp1' });

  return (
    <input
      type="text"
      name="username"
      id="username"
      autoComplete="username"
      className="flex-1 focus:ring-brandBlue-400 focus:border-brandBlue-400 block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
      {...register('username', {
        required: { value: true, message: t('username.errorRequired') },
        minLength: { value: 5, message: t('username.errorMinLength') },
        maxLength: { value: 40, message: t('username.errorLength') },
        pattern: { value: /^[a-zA-Z 0-9._-]*$/i, message: t('username.errorPattern') },
        validate: async (value) => (await usernameValidation(value)) || t('username.errorTaken'),
      })}
    />
  );
}
InputUsername.propTypes = {
  register: PropTypes.func.isRequired,
  usernameValidation: PropTypes.func,
};
InputUsername.defaultProps = {
  usernameValidation: async () => {},
};

export function InputPassword1({ useFormObject, setShowPasswordTip, repeatPassword, resetRightColumn }) {
  const rightColumn = useFormObject.watch('provider');
  const { t } = useTranslation('site', { keyPrefix: 'register.signUp1' });

  // needs showPasswordTip in state in the parent component
  return (
    <input
      type="password"
      name="password"
      id="password"
      autoComplete="new-password"
      className="flex-1 focus:ring-brandBlue-400 focus:border-brandBlue-400 block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
      onFocus={(e) => {
        resetRightColumn(useFormObject);
        setShowPasswordTip(true);
      }}
      {...useFormObject.register('password', {
        validate: (value) => !(!rightColumn && !value) || t('password.errorEmpty'),
        maxLength: { value: 99, message: t('password.errorLength') },
        pattern: {
          value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.[\]{}()?\-"!@#%&/,><':;|_~`])\S{8,99}$/,
          message: t('password.errorPattern'),
        },
        onBlur: async (e) => {
          setShowPasswordTip(false);
          // if the user typed the repeated password, got a validation error and came here to fix it,
          // we need to revalidate repeatPassword as well when the user is finished here
          if (repeatPassword.current.isDirty) await useFormObject.trigger(['repeatPassword']);
        },
      })}
    />
  );
}
InputPassword1.propTypes = {
  useFormObject: PropTypes.objectOf(PropTypes.any).isRequired,
  repeatPassword: PropTypes.objectOf(PropTypes.any).isRequired,
  setShowPasswordTip: PropTypes.func.isRequired,
  resetRightColumn: PropTypes.func,
};
InputPassword1.defaultProps = {
  resetRightColumn: () => {},
};

export function InputPassword2({ useFormObject, password, resetRightColumn }) {
  // requires a Ref to the password input field, see above
  const rightColumn = useFormObject.watch('provider');
  const { t } = useTranslation('site', { keyPrefix: 'register.signUp1' });

  return (
    <input
      type="password"
      name="repeatPassword"
      id="repeat-password"
      autoComplete="new-password"
      onFocus={() => resetRightColumn(useFormObject)}
      className="flex-1 focus:ring-brandBlue-400 focus:border-brandBlue-400 block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
      {...useFormObject.register('repeatPassword', {
        validate: (value) => !(!rightColumn && value !== password.current) || t('repeatPassword.errorMatch'),
      })}
    />
  );
}
InputPassword2.propTypes = {
  useFormObject: PropTypes.objectOf(PropTypes.any).isRequired,
  password: PropTypes.objectOf(PropTypes.any).isRequired,
  resetRightColumn: PropTypes.func,
};
InputPassword2.defaultProps = {
  resetRightColumn: () => {},
};

export function InputRegistrationCode({ register, errors, setError, setValue, clearErrors }) {
  const { t } = useTranslation('site', { keyPrefix: 'register.code' });
  const [visible, setVisible] = useState(false);
  const [validating, setValidating] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  async function validateRegistrationCode(code) {
    // console.log('running validate');
    setValidating(true);
    try {
      // set error with no error message first, so that form cannot be submitted for the duration of the validation
      setError('registrationCode', { type: 'manual', message: '' });
      const result = await API.get('myAPI', `customer/registrationCodes/${code}`, {
        body: null,
        headers: {
          'Content-Type': 'application/json',
        },
      });
      clearErrors('registrationCode'); // clear manual error
      setValidating(false);
      return !!result;
    } catch (err) {
      setValidating(false);
      clearErrors('registrationCode'); // clear manual error
    }
    return false;
  }

  // handle the query parameter
  useEffect(() => {
    if (queryParams.get('registrationCode')) {
      setVisible(true);
      setValue('registrationCode', queryParams.get('registrationCode'));
    }
  }, [queryParams]);

  if (visible) {
    return (
      <div className="col-span-4 md:col-span-3">
        <label htmlFor="repeat-password" className="text-sm font-medium text-gray-700 flex align-baseline">
          {t('label')}
          {validating && <MiniSpinner className="ml-2 w-4 h-4 text-gray-400 animate-spin" />}
        </label>
        <div className="mt-1 flex rounded-md shadow-sm">
          <input
            type="text"
            name="registrationCode"
            id="registrationCode"
            className={`${visible ? '' : 'hidden'} flex-1 focus:ring-brandBlue-400 focus:border-brandBlue-400 block w-full min-w-0 rounded-md sm:text-sm border-gray-300`}
            {...register('registrationCode', {
              validate: async (value) => {
                if (value && !(await validateRegistrationCode(value))) return t('errorInvalid');
                return true;
              },
            })}
          />
        </div>
        <div className="pt-2 sm:text-sm text-brandRed-500">{errors?.registrationCode && <span>{errors?.registrationCode.message}</span>}</div>
      </div>
    );
  }

  return (
    <button type="button" onClick={() => setVisible(true)} className="mt-4 col-span-4 md:col-span-3 text-left text-gray-400 text-sm font-semibold tracking-wide hover:text-gray-400 cursor-pointer">
      {`${t('link')}→`}
    </button>
  );
}
InputRegistrationCode.propTypes = {
  register: PropTypes.func.isRequired,
  errors: PropTypes.objectOf(PropTypes.any),
  setValue: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  clearErrors: PropTypes.func.isRequired,
};
InputRegistrationCode.defaultProps = {
  errors: {},
};

export function InternalAccountForm({ useFormObject, resetRightColumn, emailUsernameValidation }) {
  const [showPasswordTip, setShowPasswordTip] = useState(false);

  const {
    register,
    watch,
    trigger,
    formState: { errors },
    setValue,
  } = useFormObject;

  const { t } = useTranslation('site', { keyPrefix: 'register.signUp1' });

  const password = useRef({});
  password.current = watch('password', '');
  const repeatPassword = useRef({});
  repeatPassword.current = watch('repeatPassword', '');

  return (
    <>
      <h3 className="text-xl leading-6 font-bold text-gray-900">{t('createNewAccount')}</h3>
      <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
        <div className="col-span-6 md:col-span-4">
          <label htmlFor="email" className="flex text-sm font-medium text-gray-700">
            {t('email.label')}
            <ToolTip info={t('email.tooltip')} />
          </label>
          <div className="mt-1 flex rounded-md shadow-sm">
            <InputEmail useFormObject={useFormObject} resetRightColumn={resetRightColumn} emailValidation={emailUsernameValidation} />
          </div>
          <div className="pt-2 sm:text-sm text-brandRed-500">{errors.email && <span>{errors.email.message}</span>}</div>
        </div>
      </div>
      <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
        <div className="col-span-6 md:col-span-4">
          <label htmlFor="password" className="block text-sm font-medium text-gray-700">
            {t('password.label')}
          </label>
          <div className="mt-1 flex rounded-md shadow-sm">
            <InputPassword1 useFormObject={useFormObject} setShowPasswordTip={setShowPasswordTip} repeatPassword={repeatPassword} resetRightColumn={resetRightColumn} />
          </div>
          <div className={showPasswordTip ? 'pt-2 text-gray-700 sm:text-sm' : 'hidden'}>
            <p>{t('password.hint')}</p>
          </div>
          <div className="pt-2 sm:text-sm text-brandRed-500">{errors.password && <span>{errors.password.message}</span>}</div>
        </div>
      </div>
      <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
        <div className="col-span-6 md:col-span-4">
          <label htmlFor="repeat-password" className="block text-sm font-medium text-gray-700">
            {t('repeatPassword.label')}
          </label>
          <div className="mt-1 flex rounded-md shadow-sm">
            <InputPassword2 useFormObject={useFormObject} password={password} resetRightColumn={resetRightColumn} />
          </div>
          <div className="pt-2 sm:text-sm text-brandRed-500">{errors.repeatPassword && <span>{errors.repeatPassword.message}</span>}</div>
        </div>
      </div>
    </>
  );
}
InternalAccountForm.propTypes = {
  useFormObject: PropTypes.objectOf(PropTypes.any).isRequired,
  resetRightColumn: PropTypes.func.isRequired,
  emailUsernameValidation: PropTypes.func,
};
InternalAccountForm.defaultProps = {
  emailUsernameValidation: async () => {},
};

export function CommonForm({ useFormObject }) {
  const navigate = useNavigate();
  const { t } = useTranslation('site');

  const {
    register,
    formState: { errors },
    setValue,
    setError,
    clearErrors,
  } = useFormObject;

  return (
    <>
      <div className="mt-8 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
        {/* COUNTRY OF RESIDENCE */}

        <div className="col-span-4 md:col-span-3">
          <label htmlFor="country" className="flex text-sm font-medium text-gray-700">
            {t('register.signUp1.country.label')}
            <ToolTip info={t('country.tooltip')} />
          </label>
          <div className="mt-1">
            <select
              id="country"
              name="country"
              value="Germany"
              autoComplete="country-name"
              className="shadow-sm focus:ring-brandBlue-400 focus:border-brandBlue-400 block w-full sm:text-sm border-gray-300 rounded-md"
              {...register('country')}
            >
              <option value="Germany">{t('register.signUp1.country.Germany')}</option>
            </select>
          </div>
          <div className="pt-2 sm:text-sm text-brandRed-500" />
        </div>

        {/* BASE CURRENCY */}

        <div className="col-span-4 md:col-span-2">
          <label htmlFor="baseCurrency" className="flex text-sm font-medium text-gray-700">
            {t('register.signUp1.baseCurrency.label')}
            <ToolTip info={t('register.signUp1.baseCurrency.tooltip')} />
          </label>
          <div className="mt-1">
            <select
              id="baseCurrency"
              name="baseCurrency"
              autoComplete="currency"
              className="shadow-sm focus:ring-brandBlue-400 focus:border-brandBlue-400 block w-full sm:text-sm border-gray-300 rounded-md"
              {...register('baseCurrency')}
            >
              {currencyCodes.map((c) => (
                <option key={c}>{c}</option>
              ))}
            </select>
          </div>
          <div className="pt-2 sm:text-sm text-brandRed-500" />
        </div>
      </div>
      <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
        {/* USERNAME */}

        <div className="col-span-4 md:col-span-3">
          <div className="flex justify-between">
            <div className="inline-flex">
              <label htmlFor="username" className="block text-sm font-medium text-gray-700">
                <span>{t('register.signUp1.username.label')}</span>
              </label>
              <ToolTip info={t('register.signUp1.username.tooltip')} />
            </div>
          </div>
          <div className="mt-1 flex rounded-md shadow-sm">
            <InputUsername register={register} usernameValidation={checkIfUsernameCanBeUsed} />
          </div>
          <div className="pt-2 sm:text-sm text-brandRed-500">{errors.username && <span>{errors.username.message}</span>}</div>
        </div>

        {/* REGISTRATION CODE */}

        <InputRegistrationCode register={register} errors={errors} setValue={setValue} setError={setError} clearErrors={clearErrors} />
      </div>
      <div className="pt-5">
        <div className="flex justify-end space-x-4">
          <button
            type="submit"
            id="save"
            name="save"
            // eslint-disable-next-line max-len
            className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-semibold rounded-md text-white bg-brandBlue-500 hover:bg-brandBlue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brandBlue-400"
          >
            {t('general.next')}
          </button>
          <button
            type="button"
            // eslint-disable-next-line max-len
            className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-semibold text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brandBlue-400"
            onClick={(e) => navigate(-1)}
          >
            {t('general.cancel')}
          </button>
        </div>
      </div>
    </>
  );
}
CommonForm.propTypes = {
  useFormObject: PropTypes.objectOf(PropTypes.any).isRequired,
};

export function ExternalAccountForm({ useFormObject, resetLeftColumn }) {
  const {
    register,
    control,
    formState: { errors },
  } = useFormObject;
  const { t } = useTranslation('site', { keyPrefix: 'register.signUp1' });

  const buttons = [
    { label: `${t('signUpWith')} Apple`, icon: 'apple', provider: CognitoHostedUIIdentityProvider.Apple },
    { label: `${t('signUpWith')} Google`, icon: 'google', provider: CognitoHostedUIIdentityProvider.Google },
    { label: `${t('signUpWith')} Facebook`, icon: 'facebook', provider: CognitoHostedUIIdentityProvider.Facebook },
    { label: `${t('signUpWith')} Amazon`, icon: 'amazon', provider: CognitoHostedUIIdentityProvider.Amazon },
  ];

  return (
    <div className="mb-10">
      <h3 className="text-xl leading-6 font-bold text-gray-900">{t('useExistingAccount')}</h3>
      <p className="py-6 text-gray-700 text-sm">{t('useExistingAccountDescription')}</p>
      <Controller
        control={control}
        name="provider"
        render={({ field }) => (
          <RadioGroup value={field.value} onChange={field.onChange} onFocus={() => resetLeftColumn(useFormObject)}>
            {buttons.map((b) => (
              <RadioGroup.Option key={b.provider} value={b.provider}>
                {({ checked }) => <SignUpButton label={b.label} icon={b.icon} checked={checked} onBlur={field.onBlur} />}
              </RadioGroup.Option>
            ))}
          </RadioGroup>
        )}
      />
    </div>
  );
}
ExternalAccountForm.propTypes = {
  useFormObject: PropTypes.objectOf(PropTypes.any).isRequired,
  resetLeftColumn: PropTypes.func.isRequired,
};
