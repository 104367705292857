import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

function handleCustomEvent(payload) {
  const event = new CustomEvent('showTooltip', { detail: payload });
  window.dispatchEvent(event);
}

export default function ToolTipNoIcon({ children, info, className, classNameOwn }) {
  function handleMouseEnter(e) {
    handleCustomEvent({
      x: e.clientX,
      y: e.clientY,
      message: info,
      className,
    });
  }
  function handleMouseLeave() {
    handleCustomEvent(null);
  }

  useEffect(
    () => () => {
      handleCustomEvent(null);
    },
    [],
  );

  return (
    <div className={classNames(classNameOwn, 'relative group')}>
      <div className="flex items-center" onPointerEnter={handleMouseEnter} onPointerLeave={handleMouseLeave}>
        {children}
      </div>
    </div>
  );
}
ToolTipNoIcon.propTypes = {
  info: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  classNameOwn: PropTypes.string,
};
ToolTipNoIcon.defaultProps = {
  className: '',
  classNameOwn: '',
};
