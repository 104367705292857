import React from 'react';
import PropTypes from 'prop-types';
import { InformationCircleIcon } from '@heroicons/react/24/outline';

export default function ToolTipOnlyIcon({ small }) {
  return (
    <InformationCircleIcon className={`text-gray-400 ${small ? 'h-4 w-4' : 'h-5 w-5'}`} />
  );
}
ToolTipOnlyIcon.propTypes = {
  small: PropTypes.bool,
};
ToolTipOnlyIcon.defaultProps = {
  small: false,
};
