// CATEGORY MODULE FOR REAL ESTATE / TRANSACTIONS
import dayjs from 'dayjs';
import { getDataByCategory, postData, incomeFromCapitalLabels, incomeFromCapitalLabelsExpense, incomeFromCapitalLabelsIncome } from '../../../redux/reducers/data';
import store from '../../../store';
// this does not update!

const utc = require('dayjs/plugin/utc');

dayjs.extend(utc);

const debugLevel = 3;

// INPUT transformations

// used to put quote object attributes in the exact order expected by the GRID layout (and the gridLayout.js file which defines the columns in the spreadsheet)
// TABLE will use the same object, but the sequence does not matter (sequence is set in tableLayout.js file)
export function categoryOrderedObject(account = null, displayedComponent = 'table') {
  //  outer function takes parameters and returns a CALLBACK function for .map with parameters already in
  return function objectsOfValueOrdereObjectInnerFunc(transaction) {
    // this has to exactly follow the column order laid out by gridLayout
    return {
      date: displayedComponent === 'table' ? Number(transaction.date) : dayjs.utc(Number(transaction.date)).format(), // table expects a number, grid expects a string
      transactionType: transaction.transactionType,
      description: transaction.description,
      quantity: transaction.quantity || '',
      valueTransactionCurrency: transaction.uptc * transaction.quantity || '',
      transactionCurrency: transaction.transactionCurrency || '',
      valueAccountCurrency: transaction.quantity * transaction.upac || '',
      valueBaseCurrency: transaction.quantity * transaction.upbc || '',
      id: transaction.id,
      assetId: transaction.assetId,
      ...(displayedComponent === 'table' && { isSimulated: transaction.isSimulated }),
    };
  };
}

const depositTransactionLabels = incomeFromCapitalLabels.map((l) => l.split('-')[1]);
const depositIncomeTransactionLabels = incomeFromCapitalLabelsIncome.map((l) => l.split('-')[1]);
const depositExpenseTransactionLabels = incomeFromCapitalLabelsExpense.map((l) => l.split('-')[1]);

// applies category-related final changes before sending to GRID or TABLE
export function applyCategorySpecificChanges(transactions, account = null, displayedComponent = 'table', quotes = []) {
  // GRID
  if (displayedComponent === 'grid') return transactions.map(categoryOrderedObject(account, displayedComponent)); // <- returns an array of objects

  // TABLE
  const state = store.getState();
  const baseCurrency = state.user?.profile?.settings?.baseCurrency || 'EUR';

  // if this is a TABLE, we need a more complex transformation
  // 'transactions' for table is a different object: it is the 'stocks.accountId' branch of completeAssetsView
  // described here: https://monestry.atlassian.net/l/cp/t1S1KbTF
  // in other words, an object with keys being accountIds and values being arrays of transactions belonging to that account + all related  deposit transactions sorted ascending by date
  // this is essentially the same code as in stocks (minus the summary row), but as long as field names are not synchronised among asset categories, it needs to be slightly different
  return Object.keys(transactions).reduce((acc, assetId) => {
    // for each assetId: { object }
    const depositTransactionsThisAsset = transactions[assetId].filter((x) => depositTransactionLabels.includes(x.rowType)).map((x) => ({ ...x, payoutOrFeePerPiece: x.quantity / x.positionOnDate }));

    // let's sort out transactions first
    const singleTransactions = transactions[assetId]
      .filter((item) => item.rowType === 'purchase') // leave only purchases which have not been sold yet
      .map((item) => {
        const openPositionSize = item.quantity;
        const purchaseValue = item.quantity * item.upac;
        const currentQuote = quotes[item.assetId]?.current?.quote;
        const currentValue = item.quantity * currentQuote;

        const dividendsPerPieceSincePurchase = depositTransactionsThisAsset
          .filter((x) => x.date >= item.date && depositIncomeTransactionLabels.includes(x.rowType))
          .reduce((acc2, curr2) => acc2 + curr2.payoutOrFeePerPiece, 0);
        const dividendsSincePurchase = openPositionSize * dividendsPerPieceSincePurchase;

        const thisTransactionFees = depositTransactionsThisAsset
          // has a linkedTransaction which has the same id as the current item
          .filter((x) => depositExpenseTransactionLabels.includes(x.rowType) && x.linkedTransactions.findIndex((lt) => lt.id === item.id) > -1)
          .reduce((acc2, curr2) => acc2 + curr2.quantity, 0);

        const accountCostShareSincePurchase = transactions[account.id] // account-level cost are not linked to any assetId
          .filter((x) => x.date >= item.date && x.rowType === 'costs')
          .reduce((acc2, curr2) => acc2 + curr2.quantity, 0) / Object.keys(transactions).length; // divide equally per assetId within one account

        const fees = thisTransactionFees + accountCostShareSincePurchase;

        return {
          ...item,
          currency: baseCurrency, // display rebased values in base currency
          openPositionSize,
          purchaseValue,
          currentValue,
          currentQuote,
          dividendsSincePurchase,
          fees,
          ...(item.rebasedAmount !== item.transactionAmount && { isPreSplitTransaction: true }),
          roi: (currentValue + dividendsSincePurchase + fees - purchaseValue) / purchaseValue,
          roiAnnual: ((currentValue + dividendsSincePurchase + fees) / purchaseValue) ** (1 / ((new Date().valueOf() - item.date) / (365 * 24 * 60 * 60 * 1000))) - 1,
          isSummaryRow: false,
        };
      });

    if (debugLevel > 2) console.log('singleTransactions', singleTransactions);

    // for each assetId in this account we need to calculate the summary row
    let summaryRow = null;
    if (singleTransactions.length > 0) {
      summaryRow = singleTransactions.reduce(
        (prev, curr) => ({
          ...prev,
          openPositionSize: (prev.openPositionSize || 0) + (curr.openPositionSize || 0),
          purchaseValue: (prev.purchaseValue || 0) + curr.purchaseValue,
          currentValue: (prev.currentValue || 0) + curr.currentValue,
          dividendsSincePurchase: (prev.dividendsSincePurchase || 0) + curr.dividendsSincePurchase,
          fees: (prev.fees || 0) + curr.fees,
          numeratorRoiAnnualWeighted: (prev.numeratorRoiAnnualWeighted || 0) + (curr.roiAnnual || 0) * curr.quantityOpen,
          numeratorTransactionPrice: (prev.numeratorTransactionPrice || 0) + (curr.quantity || 0) * curr.openPositionSize,
          ...(curr.itemHasFlag && { itemHasFlag: curr.itemHasFlag }), // adds itemHasFlag if any of the transactions for this assetId has it
        }),
        {
          date: 1,
          assetId,
          displayName: account.name,
          currentQuoteBaseCurrency: singleTransactions[0].currentQuoteBaseCurrency,
        },
      );

      if (debugLevel > 2) console.log('summaryRow', summaryRow);

      summaryRow = {
        ...summaryRow,
        currency: baseCurrency, // display rebased values in base currency
        transactionPrice: summaryRow.numeratorTransactionPrice / summaryRow.openPositionSize,
        roi: (summaryRow.currentValue + summaryRow.dividendsSincePurchase + summaryRow.fees - summaryRow.purchaseValue) / summaryRow.purchaseValue,
        roiAnnual: summaryRow.numeratorRoiAnnualWeighted / summaryRow.openPositionSize,
        isSummaryRow: true,
      };
    }

    return [...acc, ...singleTransactions, ...(summaryRow ? [summaryRow] : [])];
  }, []);
}

// OUTPUT transformations

// used inside of .map
// performs category-specific transformations after standard transformations of Grid output
// seqeunce of properties is no longer important
// this happens already after items with inputFlag = 'delete' have been added, so just take care of 'put'

export function outputTransformCategoryTransactions(account = null) {
  //  outer function takes a parameter returns a CALLBACK function for .map with that parameter already in
  return function outputTransformCategoryTransactionsInnerFunc(item) {
    return {
      ...item,
      upac: item.valueAccountCurrency || item.valueAccountCurrency === 0 ? item.valueAccountCurrency / item.quantity : null,
      upbc: item.valueBaseCurrency || item.valueBaseCurrency === 0 ? item.valueBaseCurrency / item.quantity : null,
      uptc: item.valueTransactionCurrency || item.valueTransactionCurrency === 0 ? item.valueTransactionCurrency / item.quantity : null,
      transactionCurrency: item.valueTransactionCurrency || item.valueTransactionCurrency === 0 ? item.transactionCurrency : null,
      accountCurrency: item.currency,
      assetCurrency: item.currency,
    };
  };
}

// Grid handles dispatch and its result; this is just the action creator for post transactions
export function postCategoryItems(data, account) {
  return postData({ data, accountId: account.id, category: 'objectsOfValue' });
}

// TABLE

// used in table to get transactions from backend
export function handleSync(accountId, dispatch) {
  dispatch(getDataByCategory({ category: 'objectsOfValue' }));
}

function compare(a, b, propertyName, asc = true) {
  const multiplier = asc ? 1 : -1;
  // so that it doesn't sort capital letters separately from lowercase
  const elementA = typeof a[propertyName] === 'number' ? a[propertyName] : a[propertyName].toLowerCase();
  const elementB = typeof b[propertyName] === 'number' ? b[propertyName] : b[propertyName].toLowerCase();

  if (elementA < elementB) {
    return -1 * multiplier;
  }
  if (elementA > elementB) {
    return 1 * multiplier;
  }
  return 0;
}

export function getSortingCallback(tableState) {
  return function sortingCallback(a, b) {
    // reverse sorting order if date is selected a
    // localeCompare compares strings according to rules of the current locale, returns -1, 0, 1
    let compareFigi = a.assetId.localeCompare(b.assetId);
    if (tableState.sortBy === 'date' && !tableState.sortDirectionAsc) {
      compareFigi = -a.assetId.localeCompare(b.assetId);
    }
    // sort by symbol, then sort the total row first and then sort the rest by the column
    return compareFigi || !!b.isSummaryRow - !!a.isSummaryRow || compare(a, b, tableState.sortBy, tableState.sortDirectionAsc);
  };
}
