// CATEGORY MODULE FOR LOANS / TRANSACTIONS

import dayjs from 'dayjs';
import { getDataByAccount, postData } from '../../../redux/reducers/data';

const utc = require('dayjs/plugin/utc');

dayjs.extend(utc);

// INPUT transformations

// used to put quote object attributes in the exact order expected by the GRID layout (and the gridLayout.js file which defines the columns in the spreadsheet)
// TABLE will use the same object, but the sequence does not matter (sequence is set in tableLayout.js file)
export function categoryOrderedObject(account = null, displayedComponent = 'table') { //  outer function takes parameters and returns a CALLBACK function for .map with parameters already in
  return function depositsOrdereObjectInnerFunc(transaction) {
    // this has to exactly follow the column order laid out by gridLayout
    return {
      date: (displayedComponent === 'table') ? Number(transaction.date) : dayjs.utc(Number(transaction.date)).format(), // table expects a number, grid expects a string
      otherPartyIban: transaction.otherPartyIban,
      description: transaction.description,
      quantity: transaction.quantity,
      quantityInterest: transaction.quantityInterest,
      fxCurrency: (transaction.transactionCurrency && transaction.uptc) ? transaction.transactionCurrency : null,
      fxAmount: (transaction.transactionCurrency && transaction.uptc) ? transaction.quantity * transaction.uptc : null,
      fxAmountInterest: (transaction.transactionCurrency && transaction.uptc) ? transaction.quantityInterest * transaction.uptc : null,
      fxRate: (transaction.transactionCurrency && transaction.uptc) ? transaction.uptc : null,
      id: transaction.id,
      accountCurrency: transaction.accountCurrency,
      ...(displayedComponent === 'table' && { isSimulated: transaction.isSimulated }),
    };
  };
}

// applies category-related final changes before sending to GRID
export function applyCategorySpecificChanges(transactions, account = null, displayedComponent = 'table') { // <- receives some parameters and
  return transactions.map(categoryOrderedObject(account, displayedComponent)); // <- returns an array of objects
}

// OUTPUT transformations

// used inside of .map
// performs category-specific transformations after standard transformations of Grid output
// seqeunce of properties is no longer important
// this happens already after items with inputFlag = 'delete' have been added, so just take care of 'put'
export function outputTransformCategoryTransactions(account = null) { //  outer function takes a parameter returns a CALLBACK function for .map with that parameter already in
  return function outputTransformCategoryTransactionsInnerFunc(item) {
    // the complexity of getting an async currency from provider is significant
    // let us assume for the moment that we do not allow transactions without quantity (in account currency)
    return {
      ...item,
      upac: 1,
      upbc: null,
      uptc: (item.quantity !== 0) ? Number(item.fxAmount / item.quantity) : null,
      quantityInterest: !item.quantityInterest ? 0 : Number(item.quantityInterest),
      transactionCurrency: item.fxCurrency,
      accountCurrency: account.currency,
      assetCurrency: account.currency,
      projectId: account.connectedProjectId || null,
    };
  };
}

// Grid handles dispatch and its result; this is just the action creator for post transactions
export function postCategoryItems(data, account) {
  return postData({ data, category: 'loans', accountId: account.id });
}

// TABLE

// used in table to get transactions from backend
export function handleSync(category, accountId, dispatch) { // FIXME: this could be moved to the common file
  dispatch(getDataByAccount({ category, accountId }));
}
