import React from 'react';

const steps = [
  {
    type: 'intro',
    id: 'AD-intro',
    steps: [
      {
        selector: '#account-details-table-buttons',
        content: () => (
          <p>
            Use these buttons to
            {' '}
            <strong>sync</strong>
            {' '}
            transactions,
            {' '}
            <strong>import</strong>
            {' '}
            files and access account
            {' '}
            <strong>settings</strong>
            .
          </p>
        ),
      },
      {
        selector: '#acccount-details-table-edit-button',
        content: () => (
          <p>
            <strong>Edit button</strong>
            {' '}
            lets you switch to spreadsheet mode where you can
            {' '}
            <strong>add and edit data</strong>
            .
          </p>
        ),
      },
    ],
  },
];

export default steps;
