/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Fragment } from 'react';
import { writeFile, utils } from 'xlsx';
import PropTypes from 'prop-types';
import { Transition } from '@headlessui/react';
import { useTranslation } from 'react-i18next';
import {
  ClipboardIcon,
  DocumentDuplicateIcon,
  ArrowDownOnSquareIcon,
  ScissorsIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import { ArrowDownOnSquareIcon as SaveSolidIcon } from '@heroicons/react/24/solid';
import { TableRowInsertAfter, TableRowInsertBefore } from '../../assets/mdiIcons';

import '../../css/jspreadsheet.css';

function DuplicateIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2} {...props}>
      <path strokeLinecap="round" strokeLinejoin="round" d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z" />
    </svg>
  );
}

// this is compatible with AccountDetails 2.0
// this is supposed to only do context menu stuff; there shall be no category-specific logic here
export default function ContextMenu({
  obj, jRef, contextMenu, userChangesPresent, setUserChangesPresent, gridLayout, accountName,
}) {
  const { t } = useTranslation('app', { keyPrefix: 'accountDetails.contextMenu' });

  function calculateCsvSeparator() {
    const list = ['a', 'b'];
    const s = list.toLocaleString();
    const sep = s[1];
    return sep;
  }

  function handleInsertRows(e, before, jExcelObject) {
    // if there is more than one row, insert all rows before the first or after the last selected row, depending on the value of before
    const sel = jExcelObject.getSelectedRows(true);
    for (let i = sel.length; i > 0; i -= 1) {
      if (jExcelObject) jExcelObject.insertRow(1, before ? Math.min(...sel) : Math.max(...sel), before);
    }
  }

  function handleDeleteRows(e) {
    // for each currently selected row issue a deleteRow command
    const sel = obj.getSelectedRows(true);
    for (let i = sel.length; i > 0; i -= 1) {
      jRef.current.jexcel.deleteRow(sel[i - 1]);
    }
    if (!userChangesPresent) setUserChangesPresent(true);
  }

  function handlePasteViaMenu(e) { // when paste command is triggered via context menu
    if (navigator && navigator.clipboard) { // if browser has the clipboard API
      const x = Math.min(...jRef.current.jexcel.getSelectedColumns(true)); // get top left corner of selection
      const y = Math.min(...jRef.current.jexcel.getSelectedRows(true));
      if (obj.selectedCell) {
        navigator.clipboard.readText().then((text) => {
          if (text) {
            jRef.current.jexcel.paste(x, y, text);
          }
        });
      }
    }
  }

  function handleExport(e, selectionOnly = false) {
    let data;
    if (selectionOnly) {
      data = jRef.current.jexcel.getData(true); // hidden columns are not included when only getting selection
    } else {
      data = jRef.current.jexcel.getData() // hidden columns are included when getting all data, so we need to filter them out
        // remove id column (based on gridLayout)
        .map((row) => row.filter((_, i) => gridLayout[i].id !== 'id'));
    }
    const xlsxObj = utils.aoa_to_sheet(data);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, xlsxObj, 'Sheet1');
    writeFile(workbook, `${accountName} export.xlsx`, { bookType: 'xlsx', type: 'binary' });
  }

  return (
    <Transition
      show={contextMenu.visible}
      as={Fragment}
      enter="transition ease-out duration-100"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-in duration-75"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-95"
    >
      <div
        // eslint-disable-next-line react/style-prop-object
        style={{ top: `${contextMenu.y}px`, left: `${contextMenu.x}px` }}
        onMouseDown={(e) => {
          e.preventDefault(); // the default behaviour of jspreadsheet was that this reset the selection within spreadsheet on mousedown
          e.stopPropagation(); // (before the copy command could have been executed)
        }}
        className="origin-top-right fixed z-50 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none"
      >
        <div className="py-1">
          <div>
            <button
              type="button"
              onClick={(e) => handleInsertRows(e, true, obj)}
              className="hover:bg-gray-100 hover:text-gray-900 text-gray-700 group flex w-full items-center px-4 py-2 text-sm"
            >
              <TableRowInsertBefore className="mr-4 h-4 w-4 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
              {t('addEmptyRowAbove')}
            </button>
          </div>
          <div>
            <button
              type="button"
              onClick={(e) => handleInsertRows(e, false, obj)}
              className="hover:bg-gray-100 hover:text-gray-900 text-gray-700 group flex w-full items-center px-4 py-2 text-sm"
            >
              <TableRowInsertAfter className="mr-4 h-4 w-4 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
              {t('addEmptyRowBelow')}
            </button>
          </div>
          <div>
            <button
              type="button"
              onClick={(e) => {
                // copy selected rows
                jRef.current.jexcel.copy(true); // true means copy the selection only
                // insert selected rows
                handleInsertRows(e, true, obj);
                // paste selected rows
                handlePasteViaMenu(e);
              }}
              className="hover:bg-gray-100 hover:text-gray-900 text-gray-700 group flex w-full items-center px-4 py-2 text-sm"
            >
              <DocumentDuplicateIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
              {t('duplicateRow')}
            </button>
          </div>
          <div>
            <button
              type="button"
              id="deleteRow"
              onClick={handleDeleteRows}
              className="hover:bg-gray-100 hover:text-gray-900 text-gray-700 group flex w-full items-center px-4 py-2 text-sm"
            >
              <TrashIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
              {t('deleteRow')}
            </button>
          </div>
        </div>
        <div className="py-1">
          <div>
            <button
              type="button"
              onClick={(e) => {
                jRef.current.jexcel.copy(true); // true means copy the selection only
                jRef.current.jexcel.getSelectedColumns(true).forEach((col) => {
                  jRef.current.jexcel.getSelectedRows(true).forEach((row) => {
                    jRef.current.jexcel.setValueFromCoords(col, row, '');
                  });
                });
                jRef.current.jexcel.removeCopyingSelection();
              }}
              className="hover:bg-gray-100 hover:text-gray-900 text-gray-700 group flex w-full items-center px-4 py-2 text-sm"
            >
              <ScissorsIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
              {t('cut')}
            </button>
          </div>
          <div>
            <button
              type="button"
              onClick={(e) => {
                jRef.current.jexcel.copy(true); // true means copy the selection only
                jRef.current.jexcel.removeCopyingSelection();
              }}
              className="hover:bg-gray-100 hover:text-gray-900 text-gray-700 group flex w-full items-center px-4 py-2 text-sm"
            >
              <DuplicateIcon
                className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                aria-hidden="true"
              />
              {t('copy')}
            </button>
          </div>
          <div>
            <button
              onClick={handlePasteViaMenu}
              type="button"
              className="hover:bg-gray-100 hover:text-gray-900 text-gray-700 group flex w-full items-center px-4 py-2 text-sm"
            >
              <ClipboardIcon
                className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                aria-hidden="true"
              />
              {t('paste')}
            </button>
          </div>
        </div>
        <div className="py-1">
          <div>
            <button
              type="button"
              onClick={(e) => { handleExport(e, false); }}
              className="hover:bg-gray-100 hover:text-gray-900 text-gray-700 group flex w-full items-center px-4 py-2 text-sm"
            >
              <ArrowDownOnSquareIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
              {t('download')}
            </button>
          </div>
          <button
            type="button"
            onClick={(e) => { handleExport(e, true); }}
            className="hover:bg-gray-100 hover:text-gray-900 text-gray-700 group flex w-full items-center px-4 py-2 text-sm"
          >
            <SaveSolidIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
            {t('downloadSelected')}
          </button>
        </div>
      </div>
    </Transition>
  );
}
ContextMenu.propTypes = {
  contextMenu: PropTypes.objectOf(PropTypes.any).isRequired,
  obj: PropTypes.objectOf(PropTypes.any).isRequired,
  jRef: PropTypes.objectOf(PropTypes.any).isRequired,
  userChangesPresent: PropTypes.bool.isRequired,
  setUserChangesPresent: PropTypes.func.isRequired,
  gridLayout: PropTypes.array.isRequired,
  accountName: PropTypes.string.isRequired,
};
