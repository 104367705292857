/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes, { oneOfType } from 'prop-types';
import { ExclamationCircleIcon } from '@heroicons/react/24/solid';
import { useTranslation } from 'react-i18next';
import ToolTip from '../../../elements/ToolTip';

/**
 * InputField component for rendering a labeled input field with validation and error handling.
 *
 * @param {Object} props - The properties object.
 * @param {string} props.id - The id of the input field.
 * @param {string} props.type - The type of the input field.
 * @param {string} [props.autoComplete='off'] - The autocomplete attribute of the input field.
 * @param {boolean} [props.optional=true] - Indicates if the field is optional.
 * @param {function} props.register - The register function from react-hook-form.
 * @param {Object} [props.errors={}] - The errors object from react-hook-form.
 * @param {Object} [props.validationRules={}] - The validation rules object for the input field.
 * @param {string} [props.label=null] - The label text for the input field.
 * @param {string} [props.placeholder=null] - The placeholder text for the input field.
 * @param {string} [props.toolTip=null] - The tooltip information for the label.
 * @param {string} [props.category='realEstate'] - The category for translation key prefix.
 * @param {string} [props.formatting=''] - Additional formatting classes for the input field container.
 * @param {boolean} [props.disableLabel=false] - Indicates if the label should be hidden.
 * @param {string} [props.currency] - currency symbol to be displayed after the input field.
 *
 * @returns {JSX.Element} The rendered input field component.
 */
export default function InputField({ id, type, autoComplete, optional, register, errors, validationRules, label, placeholder, toolTip, category, formatting, disableLabel, currency }) {
  // it seems to not work if valudationRules is empty
  const registerType = validationRules ? register(id, validationRules) : register(id);

  // handle id = valuationParamters.city
  const split = id.split('.'); // will return an array of 1 element if no dot
  if (split.length > 2) throw new Error('InputField: unhandled id format');
  const first = split[0];
  const second = split[1];
  const calculatedError = second ? errors[first]?.[second] : errors[first];

  let keyPrefix = 'addAccount.R1';
  if (category === 'pension') keyPrefix = 'schema.pension.account';
  const { t } = useTranslation(['app'], { keyPrefix });

  const calculatedLabel = label || t(`${id}.label`);
  const calculatedToolTip = toolTip || t(`${id}.tooltip`);
  const calculatedPlaceholder = placeholder || t(`${id}.placeholder`);
  let calculatedType;
  switch (type) {
    case 'string':
      calculatedType = 'text';
      break;
    default:
      calculatedType = type;
  }

  return (
    <div className={formatting}>
      {!disableLabel && (
        <div className="flex justify-between">
          <label htmlFor="email" className="text-sm font-medium text-gray-700 flex justify-start">
            {calculatedLabel}
            <ToolTip info={calculatedToolTip} />
          </label>
          <span className="text-sm text-gray-500" id="email-optional">
            {optional ? 'Optional' : ''}
          </span>
        </div>
      )}
      <div className="mt-1 relative rounded-md flex items-center">
        <input
          id={id}
          type={calculatedType}
          autoComplete={autoComplete}
          name={id}
          className={`block w-full ${calculatedError ? 'pr-10' : ''} focus:outline-none sm:text-sm rounded-md shadow-sm
            ${
              calculatedError
                ? 'border-red-300 text-red-900  placeholder-red-300 focus:ring-red-500 focus:border-red-500'
                : 'border-gray-300 placeholder-gray-400 focus:ring-brandBlue-500 focus:border-brandBlue-500'
            }`}
          placeholder={calculatedPlaceholder}
          defaultValue=""
          aria-invalid="true"
          aria-describedby="username-error"
          {...registerType}
        />
        {currency && <span className="pl-2 text-gray-500">{currency}</span>}
        <div className={`${!calculatedError ? 'hidden' : ''} absolute inset-y-0 right-0 ${currency ? 'pr-12' : 'pr-3'} flex items-center pointer-events-none`}>
          <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
        </div>
      </div>
      <p className="mt-2 text-sm text-red-600" id="email-error">
        {calculatedError && <span>{calculatedError.message}</span>}
      </p>
    </div>
  );
}
InputField.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  placeholder: oneOfType([PropTypes.string, PropTypes.number]),
  autoComplete: PropTypes.string,
  label: PropTypes.string,
  toolTip: PropTypes.string,
  optional: PropTypes.bool,
  register: PropTypes.func.isRequired,
  errors: PropTypes.objectOf(PropTypes.object),
  validationRules: PropTypes.objectOf(PropTypes.oneOfType(PropTypes.object, PropTypes.func)),
  category: PropTypes.string,
  formatting: PropTypes.string,
  disableLabel: PropTypes.bool,
  currency: PropTypes.string,
};
InputField.defaultProps = {
  optional: true,
  validationRules: {},
  label: null,
  placeholder: null,
  toolTip: null,
  autoComplete: 'off',
  category: 'realEstate',
  formatting: '',
  disableLabel: false,
  currency: null,
};
