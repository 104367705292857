/* eslint-disable no-lonely-if */
/* eslint-disable no-extend-native */
/* eslint-disable max-len */
import dayjs from 'dayjs';

const utc = require('dayjs/plugin/utc');

dayjs.extend(utc);

const debugLevel = 0;

// polyfill for Array.at()
if (!Array.prototype.at) {
  Object.defineProperty(Array.prototype, 'at', {
    value(index) {
      // Convert negative index to positive if needed
      // eslint-disable-next-line no-param-reassign
      index = index >= 0 ? index : this.length + index;

      // Return element at the specified index
      return this[index];
    },
    writable: true,
    configurable: true,
  });
}

/**
 * Calculates simulated quotes, adds smoothing between two manual quotes and growthRates after last available quote.
 *
 * @param {array} quotes - array of quote object; expecting quotes for one assetId only
 * @returns {array} quotes
 */
export default function processQuotesPerAssetId({ quotes, requestedDates, growthRate, dashboardMode = 'dashboard' }) {
  if (!quotes) throw new Error('Missing inputQuotes.');
  if (!requestedDates) throw new Error('Missing requestedDates.');
  if ([undefined, null].includes(growthRate)) throw new Error('Missing growthRate.');
  if (quotes.some((q) => q.assetId !== quotes[0].assetId)) throw new Error('All quotes must be for the same assetId.');

  if (typeof growthRate !== 'number' || typeof quotes !== 'object' || typeof requestedDates !== 'object' || !requestedDates.length) throw new Error('Invalid argument type');
  if (quotes.length === 0) return [];

  if (debugLevel > 2) console.log(quotes, requestedDates, growthRate);

  const { assetId } = quotes[0];

  const nullQuote = {
    assetId,
    quote: null,
    currency: null,
    quoteDate: null,
    source: null,
    projectId: null,
  };

  // sort quotes descending (oldest last) (make a copy to avoid mutating the original array)
  const sortedInputQuotes = [...quotes].sort((a, b) => b.date - a.date);

  // sort requested dates ascending (oldest first)
  const sortedRequestedDates = [...requestedDates].sort((a, b) => a - b);

  if (debugLevel > 2) console.log(sortedInputQuotes, sortedRequestedDates);

  // set loop variables
  const resultStack = [];
  let nextInputQuote; // undefined
  let previousInputQuote; // undefined
  let smoothingDeltaPerDay; // undefined
  let smoothingDeltaPerDayBaseCurrency; // undefined

  sortedRequestedDates.forEach((requestedDate) => {
    // check if we need to update previous / next quote
    // is the last quote on sortedInputQuotes now older than requestedDate (now that we have moved to a new requestedDate)?
    // find the least older quote on sortedInputQuotes (i.e. there can be several older ones, but we need to see the last one "just before" requestedDate)
    // i.e. find the last quote on sortedInputQuotes, check if it is older than requestedDate, and if it is, pop it from sortedInputQuotes
    if (sortedInputQuotes.length > 0 && sortedInputQuotes.at(-1).date <= requestedDate) {
      do {
        // if yes, that means we can take it as a basis for calculation of smoothing or growth rate (for the next one or more requestedDates)
        previousInputQuote = sortedInputQuotes.pop(); // removes last item from sortedInputQuotes so that it is not there the next time we do this check
        nextInputQuote = sortedInputQuotes.at(-1); // can be undefined if there are no quotes left on sortedInputQuotes
        if (debugLevel > 2) {
          console.log(
            'processQuotes:',
            'requestedDate is',
            dayjs.utc(requestedDate).format('YYYY-MM-DD'),
            'previousInputQuote is',
            dayjs.utc(previousInputQuote.date).format('YYYY-MM-DD'),
            'nextInputQuote is',
            nextInputQuote ? dayjs.utc(nextInputQuote.date).format('YYYY-MM-DD') : 'undefined',
            'do we need to continue?',
            nextInputQuote && nextInputQuote.date <= requestedDate,
          );
        }
      } while (nextInputQuote && nextInputQuote.date <= requestedDate);

      // calculate smoothing delta per day (do it here - once - for the current pair of previous / next input quote)
      if (previousInputQuote && nextInputQuote) {
        const daysBetweenPreviousAndNext = dayjs(nextInputQuote?.date).diff(dayjs(previousInputQuote.date), 'day');
        smoothingDeltaPerDay = (nextInputQuote.quote - previousInputQuote.quote) / daysBetweenPreviousAndNext;
        smoothingDeltaPerDayBaseCurrency = (nextInputQuote.quoteBaseCurrency - previousInputQuote.quoteBaseCurrency) / daysBetweenPreviousAndNext;
      }
    }

    // implement the logic
    // is previousInputQuote defined? (it won't be defined if the first requestedDate is before the first quote on sortedInputQuotes)
    if (!previousInputQuote) {
      // if not, we return a null quote for this requested quote, because we have nothing to derive this quote from
      resultStack.push({ ...nullQuote, date: requestedDate });
      if (debugLevel > 2) console.log('processQuotes: (', quotes[0].assetId, ') requestedDate is', dayjs.utc(requestedDate).format('YYYY-MM-DD'), 'and there is no previousInputQuote - returning null');
    } else {
      // there is a previousInputQuote already, so we can calculate smoothing / growthRate if needed
      if (nextInputQuote) {
        // if there is a nextInputQuote, we may have to do smoothing; for that both previous and next quotes would have to be manual
        if (previousInputQuote.source === 'manual' && nextInputQuote.source === 'manual') {
          // do smoothing and return the resulting quote on resultStack
          if (debugLevel > 2) {
            console.log(
              'processQuotes: (',
              quotes[0].assetId,
              ') requestedDate is',
              dayjs.utc(requestedDate).format('YYYY-MM-DD'),
              'previous input quote is',
              dayjs.utc(previousInputQuote.date).format('YYYY-MM-DD'),
              'next input quote is',
              dayjs.utc(nextInputQuote.date).format('YYYY-MM-DD'),
              ', they are manual, so applying smoothing',
            );
          }
          const daysBetweenPreviousAndThis = dayjs(requestedDate).diff(dayjs(previousInputQuote.date), 'day');
          resultStack.push({
            ...previousInputQuote,
            source: 'automatic',
            date: requestedDate,
            quote: previousInputQuote.quote + daysBetweenPreviousAndThis * smoothingDeltaPerDay,
            quoteBaseCurrency: previousInputQuote.quoteBaseCurrency + daysBetweenPreviousAndThis * smoothingDeltaPerDayBaseCurrency,
          });
        } else {
          // if previous or next quote are not manual, we don't do smoothing and return an automatic quote for this requested quote
          resultStack.push({ ...previousInputQuote, source: 'automatic', date: requestedDate });
          if (debugLevel > 2) {
            console.log(
              'processQuotes: (',
              quotes[0].assetId,
              ') requestedDate is',
              dayjs.utc(requestedDate).format('YYYY-MM-DD'),
              'previous input quote is',
              dayjs.utc(previousInputQuote.date).format('YYYY-MM-DD'),
              'next input quote is',
              dayjs.utc(nextInputQuote.date).format('YYYY-MM-DD'),
              ', one of them is not manual, so returning previous quote',
            );
          }
        }
      } else {
        // previousInputQuote is the last available quote, so we can apply growth rate in project mode
        // but only if 8 days have already passed since the previousInputQuote (we don't want to apply it too early to not confuse the user)
        // this may only be applied in project mode; in dashboard mode just return the last available quote for requestedDate with no changes
        // forgoing the 7 day requirement, as it seems potentially too confusing for users
        // it was one of the conditions below: && previousInputQuote.date + 7 * 24 * 60 * 60 * 1000 < requestedDate &&
        if (dashboardMode === 'projects') {
          // apply growthRate and return the resulting quote on resultStack
          const daysBetweenPreviousAndThis = dayjs(requestedDate).diff(dayjs(previousInputQuote.date), 'day');
          const dailyGrowthRate = (growthRate * 12) / 365.25; // growth rate which arrives is MONTHLY, as a fraction
          if (debugLevel > 2) {
            console.log(
              'processQuotes: (',
              quotes[0].assetId,
              ') requestedDate is',
              dayjs.utc(requestedDate).format('YYYY-MM-DD'),
              'previous input quote is',
              dayjs.utc(previousInputQuote.date).format('YYYY-MM-DD'),
              ', there is no next input quote, so applying daily growth rate of',
              dailyGrowthRate,
            );
          }
          resultStack.push({
            ...previousInputQuote,
            source: 'automatic',
            date: requestedDate,
            quote: previousInputQuote.quote + daysBetweenPreviousAndThis * (previousInputQuote.quote * dailyGrowthRate),
            quoteBaseCurrency: previousInputQuote.quoteBaseCurrency + daysBetweenPreviousAndThis * (previousInputQuote.quoteBaseCurrency * dailyGrowthRate),
          });
        } else {
          // if this is not project mode, then return the last available quote for requestedDate with no changes
          if (debugLevel > 2) {
            console.log(
              'processQuotes: (',
              quotes[0].assetId,
              ') requestedDate is',
              dayjs.utc(requestedDate).format('YYYY-MM-DD'),
              'previous input quote is',
              dayjs.utc(previousInputQuote.date).format('YYYY-MM-DD'),
              ', there is no next input quote, so returning the last available quote',
            );
          }
          resultStack.push({ ...previousInputQuote, source: 'automatic', date: requestedDate });
        }
      }
    }
  });

  if (debugLevel > 2) console.log(resultStack);
  return resultStack;
}
