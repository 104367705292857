// this thunk was previously part of the QUOTES reducer, but had to be moved to SHARED because it is also used by the USER reducer
// and was causing a circular dependency
import { createAsyncThunk } from '@reduxjs/toolkit';
import { Auth, API } from 'aws-amplify';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { SET_MESSAGE, SET_APP_SIGNAL } from '../../actions/types';
import { globalBaselineView } from '../globalSelectors';

dayjs.extend(utc);

export async function prepCallToApi(body) {
  const session = await Auth.currentSession();
  const myInit = {
    body,
    headers: {
      'Content-Type': 'application/json',
      Authorization: session.idToken.jwtToken,
    },
  };
  return myInit;
}

export function createNamedArray(arrayOfQuotes, store) {
  const { baseline, referenceBaseline, current } = globalBaselineView(store);
  const r = arrayOfQuotes.reduce((prev, curr) => {
    if (Number(curr.date) === current) return [...prev, { ...curr, date: 'current' }];
    if (Number(curr.date) === baseline) return [...prev, { ...curr, date: 'baseline' }];
    if (Number(curr.date) === referenceBaseline) return [...prev, { ...curr, date: 'referenceBaseline' }];
    return prev;
  }, []);
  return r;
}

// accepts response from ECB API and returns an array with assetId: currCode, date, fx
// if expectedDate is set, the function will return quote items with that date even if it was not available from the API (it will copy the previous available date's quote)
export function transformEcbResponse(data, baseCurrency, expectedDate = null, expectedDateName = null) {
  const currencyCodes = data.structure.dimensions.series
    .find((s) => s.id === 'CURRENCY')
    .values.map((v) => v.id)
    .filter((v) => !['ARS', 'DZD', 'HRK', 'MAD', 'RUB', 'TWD'].includes(v)) // these codes are not available in the API, so we remove it from the list
    .concat('EUR');

  const timePeriods = data.structure.dimensions.observation // returns array of time periods as unix epoch
    .find((o) => o.id === 'TIME_PERIOD')
    .values.map((tp) => dayjs.utc(`${tp.start.split('+')[0]}Z`).valueOf());

  const { series } = data.dataSets[0];
  const observations = Object.values(series).map((x, idx) => x.observations); // returns an array of { "0": [1.2342], "1": [1.2433] ... } for each currencyCode, corresponding to time periods
  const fxValues = observations.map((o) => Object.values(o).flat()).concat([timePeriods.map(() => 1)]); // for EUR, add an array of 1s
  // returns an array of arrays [[1.2342, 1.2433], [5.2334, 5.1939], ... , [1, 1]

  const currentTpMissing = expectedDate && timePeriods[timePeriods.length - 1] !== expectedDate;
  // ↑↑ this is applicable to daily mode or to first day of the month before ECB has updated its data for the day
  // if there is no data for today, take the previous available TP (no matter how old)
  if (currentTpMissing) {
    timePeriods.push(expectedDateName); // add at the end
    fxValues.forEach((fx) => fx.push(fx[fx.length - 1])); // add at the end (copy from previous available TP)
  }

  const obj = [];
  const baseCurrencyIdx = currencyCodes.indexOf(baseCurrency);
  if (baseCurrencyIdx === -1 && baseCurrency !== 'EUR') throw new Error('transformEcbResponse: illegal baseCurrency');
  currencyCodes.forEach((currCode, idxCurrCode) => {
    timePeriods.forEach((tp, idxTp, self) => {
      obj.push({
        assetId: currCode,
        date: tp,
        quote: 1,
        // ↓↓ if baseCurrencyIdx not found, then it is EUR; otherwise divide everything by the baseCurrency rate (so that the baseCurrency rate itself is 1)
        quoteBaseCurrency: fxValues[idxCurrCode][idxTp] / (baseCurrencyIdx === -1 ? 1 : fxValues[baseCurrencyIdx][idxTp]),
        quoteDate: typeof tp !== 'number' ? self[idxTp - 1] : tp, // if it is a text and not a number, then it is the named baseline, so we take the previous date
        currency: currCode,
        source: 'api',
        projectId: null, // placeholder - project-related quotes
      }); // if baseCurrencyIdx not found, then it is EUR
    });
    return obj;
  }, {});

  return obj;
}

// arguments: dates[], projectIds[] (optional) for which the quotes are needed
// updates quotes slice only for the dates and assets retrieved from API

const getIsolatedQuotes = createAsyncThunk('quotes/getIsolatedQuotes', async (body, { dispatch }) => {
  if (body.dates.length === 0) {
    dispatch({
      type: SET_APP_SIGNAL,
      payload: 'noProjectIdsSelected',
    });
    return { status: 'success', data: [] };
  }

  try {
    const payload = await prepCallToApi(body);
    const response = await API.post('myAPI', 'quotes/getIsolated', payload); // getIsolatedQuotes API expects { projectIds: [projectIds] optional, dates: [dates]}
    return response.quotes;
  } catch (error) {
    dispatch({
      type: SET_MESSAGE,
      payload: 'dataUpdateError',
    });
    return { status: 'error', data: [] };
  }
});

// arguments: dates[], assets[{ assetId, providerAssetId, currency, [category] }] for which the quotes are needed
// category is relevant mostly for crypto and stocks
// { aborted: true / false } is used by Prep component to cancel the API call when the component is re-rendered
// updates quotes slice only for the dates and assets retrieved from API

const getQuotes = createAsyncThunk('quotes/getQuotes', async (body, { getState, dispatch }) => {
  if (body.assets.length === 0 || body.dates.length === 0) {
    dispatch({
      type: SET_APP_SIGNAL,
      payload: 'noAssetsSelected',
    });
    return { status: 'success', data: [] };
  }

  let namedArray;
  let arrayOfQuotes;
  try {
    const payload = await prepCallToApi(body);
    const response = await API.post('myAPI', 'quotes/get', payload); // getQuotes API expects { assets: [{ assetId, providerAssetId, currency, [category] }], dates: [dates]}
    if (!body.cancelSignal?.aborted) dispatch(getIsolatedQuotes({ projectIds: [], dates: body.dates }));
    arrayOfQuotes = response.quotes;
    namedArray = createNamedArray(arrayOfQuotes, getState());
    if (body.cancelSignal?.aborted) console.info('getQuotes aborted');
  } catch (error) {
    console.error('Error in getQuotes', error.response?.data);
    dispatch({
      type: SET_MESSAGE,
      payload: { message: 'dataUpdateError' },
    });
    return { status: 'error', data: [] };
  }

  // errors handled in Grid (it sends out a message)
  if (!body.cancelSignal?.aborted) return arrayOfQuotes.concat(namedArray);
  return null;
});

// arguments: dates[] for which the quotes are needed
// updates quotes.global.fx slice only for the dates retrieved from API (all currencies available in ECB)
// TODO get baseline & co from API as well

export default getQuotes;
export { getIsolatedQuotes };
