// see .env.<env-name> files to configure those variables

const isLocalhost = Boolean(
  window.location.hostname === 'localhost'
    // [::1] is the IPv6 localhost address.
    || window.location.hostname === '[::1]'
    // 127.0.0.1/8 is considered localhost for IPv4.
    || window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/),
);

const AMPLIFY_CONFIG = {
  Auth: {
    region: 'eu-central-1',
    identityPoolRegion: 'eu-central-1',
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_WEB_CLIENT_ID,
    oauth: {
      domain: process.env.REACT_APP_USER_POOL_DOMAIN,
      scope: ['email', 'openid', 'aws.cognito.signin.user.admin'],
      redirectSignIn: isLocalhost ? 'http://localhost:3000/callback/' : process.env.REACT_APP_USER_POOL_REDIRECT_IN,
      redirectSignOut: isLocalhost ? 'http://localhost:3000/' : process.env.REACT_APP_USER_POOL_REDIRECT_OUT,
      responseType: 'code',
      options: {
        AdvancedSecurityDataCollectionFlag: true,
      },
    },
  },
  API: {
    endpoints: [
      {
        name: 'myAPI',
        endpoint: process.env.REACT_APP_API_URL,
      },
      {
        name: 'ECB',
        endpoint: 'https://data-api.ecb.europa.eu/service/data/EXR/',
      },
    ],
  },
};

export default AMPLIFY_CONFIG;
