const allStockPositions = {
  name: 'All stock positions',
  readOnly: true,
  dataScope: 'balances', // does not matter, it uses its own data source
  // dataScope: 'transactions',
  cols: [],
  rows: [],
  vals: [],
  aggregatorName: 'Count',
  rendererName: 'Stock Positions',
  id: '1FbUpr7zh4oIvNwDscQkf',
};

export default allStockPositions;
