import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { API, Auth } from 'aws-amplify';
import { useTranslation } from 'react-i18next';
import { QuestionMarkCircleIcon, ExclamationCircleIcon, ArrowDownCircleIcon } from '@heroicons/react/20/solid';
import { CheckCircleIcon } from '@heroicons/react/24/solid';
import { isMobile } from 'react-device-detect';
import FullPageOverlayContainer from './FullPageOverlayContainer';
import MiniSpinner from '../misc/MiniSpinner';

const paragraphs = [
  {
    name: 'paragraphs.0.name',
    description: 'paragraphs.0.description',
    icon: QuestionMarkCircleIcon,
  },
  {
    name: 'paragraphs.1.name',
    description: 'paragraphs.1.description',
    icon: ExclamationCircleIcon,
  },
  {
    name: 'paragraphs.2.name',
    description: 'paragraphs.2.description',
    descriptionShare: 'paragraphs.2.descriptionShare',
    icon: ArrowDownCircleIcon,
  },
];

export default function RecommendUs({ setRecommendUsVisible }) {
  const [copied, setCopied] = useState(false);
  const [gettingCode, setGettingCode] = useState(true);
  const [code, setCode] = useState(undefined);
  const { share } = navigator;

  const { t } = useTranslation(['app'], { keyPrefix: 'invitation' });

  const shareText = `${t('invitationText0')}
  ${t('invitationText1')}
  ${t('invitationText2', { code })}`;

  // get code from API
  useEffect(() => {
    async function getCodeFromAPI() {
      const session = await Auth.currentSession();
      const result = await API.get('myAPI', 'customer/registrationCodes', {
        body: null,
        headers: {
          'Content-Type': 'application/json',
          Authorization: session.idToken.jwtToken,
        },
      });
      setCode(result);
      setGettingCode(false);
    }

    getCodeFromAPI(); // after PRD-1214 implemented (1.1+) this can return #ERROR if the user is not allowed to recommend yet, needs to be handled
  }, []);

  async function onClick() {
    // check if share is supported and if the browser is a mobile one ('share' is also supported on desktop, and Windows makes a total mess out of it)
    if (share && isMobile) {
      try {
        await navigator.share({
          title: t('title'),
          text: shareText,
          url: 'https://www.monestry.de/features',
        });
        console.log('Successfully shared');
      } catch (error) {
        console.error('Error sharing:', error);
      }
    } else {
      // copy invitationText to clipboard and set copied to true
      navigator.clipboard.writeText(shareText);
      setCopied(true);
    }
  }
  return (
    <FullPageOverlayContainer fixedHeight noPadding closeCallback={() => setRecommendUsVisible(false)}>
      <div className="md:grid md:grid-cols-12 md:h-full overflow-hidden">
        <div className="md:col-span-7 my-auto px-12">
          <h3 className="text-sm font-semibold tracking-wider uppercase leading-7 text-brandBlue-500">{t('title')}</h3>
          <h1 className="mt-2 text-3xl font-black text-gray-900 sm:text-5xl">
            {t('subtitle.top')}
            <br />
            {t('subtitle.bottom')}
          </h1>
          <p className="mt-8 text-lg leading-7 text-gray-600">{t('description')}</p>
          <dl className="mt-10 max-w-xl space-y-8 text-base leading-6 text-gray-600 lg:max-w-none prose">
            {paragraphs.map((paragraph) => (
              <div key={paragraph.name} className="relative pl-9">
                <dt className="inline font-semibold text-gray-900">
                  <paragraph.icon className="absolute left-1 top-1 h-5 w-5 text-brandBlue-500" aria-hidden="true" />
                  {t(paragraph.name)}
                </dt>
                {' '}
                <dd className="inline">{t(paragraph.description)}</dd>
              </div>
            ))}
          </dl>
          <div className="mt-10 border border-gray-200 shadow-sm prose mx-auto text-sm rounded-md p-4 italic">
            <p>{t('invitationText0')}</p>
            <p>{t('invitationText1')}</p>
            {gettingCode ? <MiniSpinner className="w-5 h-5 animate-spin text-gray-400" /> : <p>{t('invitationText2', { code })}</p>}
          </div>
          <div className="my-5 flex justify-center gap-x-6">
            <button
              type="button"
              onClick={onClick}
              disabled={gettingCode}
              className={`rounded-md ${gettingCode ? 'bg-gray-200' : 'bg-brandBlue-500'} px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-brandBlue-400
              focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brandBlue-400`}
            >
              {share && isMobile ? 'Share' : 'Copy'}
              {copied && <CheckCircleIcon className="inline h-5 w-5 ml-2 -mt-1 text-white" aria-hidden="true" />}
            </button>
          </div>
        </div>
        <div className="overflow-hidden bg-gray-100 md:col-span-5 max-h-[93vh]">
          <img
            className="object-cover object-center h-full w-full"
            src="https://images.unsplash.com/photo-1619560820102-31f5b04c049a?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3wzMDUyOTN8MHwxfGFsbHx8fHx8fHx8fDE2ODY5OTI5ODN8&ixlib=rb-4.0.3&q=80&w=1080"
            alt="post stamps"
          />
        </div>
      </div>
    </FullPageOverlayContainer>
  );
}
RecommendUs.propTypes = {
  setRecommendUsVisible: PropTypes.func.isRequired,
};
