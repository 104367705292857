import React from 'react';

const steps = [
  {
    type: 'intro',
    id: 'GR-intro',
    steps: [
      {
        selector: '#account-details-grid-save-button',
        content: () => (
          <p>
            Denke daran die Änderungen mit
            {' '}
            <strong>Speichern</strong>
            {' '}
            zu bestätigen.
          </p>
        ),
      },
      {
        selector: '#account-details-grid-spreadsheet',
        content: () => (
          <div>
            <p className="pb-2">
              Daten können wie in einem gewöhnlichen
              {' '}
              <strong>Spreadsheet</strong>
              angepasst und ergänzt werden.
            </p>
            <p>
              Rechter Maus-Knopf für das nützliche
              {' '}
              <strong>Kontextmenü</strong>
              .
            </p>
          </div>
        ),
      },
    ],
  },
];

export default steps;
