import {
  SET_MESSAGE, SET_ALERT, CLEAR_ALERT,
  UPDATE_ALERT_RESPONSE,
  SET_FULLSCREEN_REPORT,
  CLEAR_FULLSCREEN_REPORT,
} from './types';

// expects message as string and extraMessage as string
export const setMessage = (message, extraMessage) => ({
  type: SET_MESSAGE,
  payload: { message, extraMessage },
});

export const setFullscreenReport = (payload) => ({
  type: SET_FULLSCREEN_REPORT,
  payload,
});

export const clearFullscreenReport = () => ({
  type: CLEAR_FULLSCREEN_REPORT,
});

export function setAlert(alertId, caller, callbackOk) {
  console.info(callbackOk);
  return {
    type: SET_ALERT,
    payload: { alertId, caller, callbackOk },
  };
}

export const updateAlertResponse = (buttonPressed) => ({
  type: UPDATE_ALERT_RESPONSE,
  payload: buttonPressed,
});

export const clearAlert = () => ({
  type: CLEAR_ALERT,
});
