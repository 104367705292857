/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-return-assign */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Combobox } from '@headlessui/react';
import { ChevronRightIcon, ChevronDownIcon, XMarkIcon } from '@heroicons/react/20/solid';
import { ReceiptPercentIcon, CircleStackIcon, BuildingLibraryIcon, HomeIcon, CurrencyEuroIcon, ArchiveBoxArrowDownIcon, TicketIcon } from '@heroicons/react/24/outline';
import { setMessage } from '../../redux/actions/message';
import { postData, depositTransactions, pensionTransactions, allTransactions, globalAccountsView } from '../../redux/reducers/data';
import { TransferIcon, BuyIcon, SellIcon } from '../../assets/mdiIcons';
import getCurrentBreakpoint from '../../misc/getCurrentBreakpoint';
import BlueTransactionsCosts from './BlueTransactionsCosts';
import BlueTransactionsInterestRent from './BlueTransactionsInterestRent';
import BlueTransactionsDividends from './BlueTransactionsDividends';
import BlueTransactionsFeesSale from './BlueTransactionsFeesSale';
import BlueTransactionsTransfer from './BlueTransactionsTransfer';
import Button from '../../elements/Button';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

function isMobileLayout(breakpoint) {
  return breakpoint === 'xs' || breakpoint === 'sm' || !breakpoint;
}

function Sublist({ selectedOption, accountId, linkedAttribute, setLinkedAttribute, handleCancel, handleCloseAfterSave, transaction }) {
  Sublist.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    selectedOption: PropTypes.objectOf(PropTypes.any).isRequired,
    accountId: PropTypes.string.isRequired,
    linkedAttribute: PropTypes.object,
    setLinkedAttribute: PropTypes.func.isRequired,
    handleCancel: PropTypes.func.isRequired,
    handleCloseAfterSave: PropTypes.func.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    transaction: PropTypes.object.isRequired,
  };
  Sublist.defaultProps = {
    linkedAttribute: {},
  };
  // selectedOption is the option selected in the main list of the blue transaction dialog
  // accountId of the deposit account that is currently open in AccountDetails
  // linkedAttribute / setLinkedAttribute have the state of the blue transaction attributes that we are about to set

  const standardProps = {
    selectedOption,
    accountId,
    linkedAttribute,
    setLinkedAttribute,
    handleCancel,
    handleCloseAfterSave,
    transaction,
  };

  switch (selectedOption.id) {
    case 'investment-fees':
    case 'investment-sale':
    case 'investment-loanPrincipal':
      return <BlueTransactionsFeesSale {...standardProps} />;
    case 'investment-dividend':
      return <BlueTransactionsDividends {...standardProps} />;
    case 'investment-interest':
    case 'investment-rent':
    case 'investment-pension':
      return <BlueTransactionsInterestRent {...standardProps} />;
    case 'investment-loanInterest':
    case 'investment-costs':
    case 'investment-contribution':
      return <BlueTransactionsCosts {...standardProps} />;
    case 'investment-transfer':
      return <BlueTransactionsTransfer {...standardProps} />;
    default:
      return <div />;
  }
}

/**
 * Displays a two-tier menu with labels that the user can apply to transaction. For some types of labels, a second level of detail is required.
 *
 * @param {string} transactionId
 * @param {string} category
 *
 * @returns React.Component
 *
 * This component is passed from TableBody via browser event to the Dialog component (setDialog event) and displayed there. It receives the props passed from TableBody.
 */
export default function BlueTransactionsDialog({ transactionId, category }) {
  const { t } = useTranslation('app', { keyPrefix: 'accountDetails.deposits' });
  const { t: tB } = useTranslation('app', { keyPrefix: 'general' });

  // Table view does not have all fields (e.g. linkedTransactions is missing), therefore get the transaction from state
  const selectTransactions = category === 'deposits' ? useSelector(depositTransactions) : useSelector(pensionTransactions);
  const transaction = {
    ...selectTransactions.find((txn) => txn.id === transactionId),
  };
  const { accountId } = transaction;
  const selectAllTransactions = useSelector(allTransactions);
  const selectAccounts = useSelector(globalAccountsView);
  // show me those accounts which have connectedDepositAccounts whose id is the same as the current account
  const accountsConnectedToThisOne = selectAccounts.filter((acc) => acc.connectedDepositAccounts && acc.connectedDepositAccounts.includes(accountId));

  const options = [];

  if (category === 'deposits') {
    // the noLabel option does not have to be available for pensions, because backend will automatically set it to contributions or payouts based on the quantity
    options.push({
      id: null,
      Icon: XMarkIcon,
      name: t('labels.noLabel.long'),
      nextLevel: false,
      requiresPositiveAmount: true,
      requiresNegativeAmount: true,
    });
    options.push({
      id: 'investment-transfer',
      Icon: TransferIcon,
      name: t('labels.investment-transfer.long'),
      nextLevel: true,
      requiresPositiveAmount: true,
      requiresNegativeAmount: true,
    });

    // only add further options if there are connected accounts (ggf. of certain categories)

    if (accountsConnectedToThisOne.length > 0) {
      options.push({
        id: 'investment-fees',
        Icon: BuildingLibraryIcon,
        name: t('labels.investment-fees.long'),
        nextLevel: true,
        requiresNegativeAmount: true,
      });
      options.push({
        id: 'investment-costs',
        Icon: CurrencyEuroIcon,
        name: t('labels.investment-costs.long'),
        nextLevel: true,
        requiresNegativeAmount: true,
      });
      options.push({
        id: 'investment-purchase',
        Icon: BuyIcon,
        name: t('labels.investment-purchase.long'),
        nextLevel: false,
        requiresNegativeAmount: true,
      });
      options.push({
        id: 'investment-sale',
        Icon: SellIcon,
        name: t('labels.investment-sale.long'),
        nextLevel: true,
        requiresPositiveAmount: true,
      });
    }

    if (accountsConnectedToThisOne.map((acc) => acc.category).includes('deposits')) {
      options.push({
        id: 'investment-interest',
        Icon: ReceiptPercentIcon,
        name: t('labels.investment-interest.long'),
        nextLevel: true,
        requiresPositiveAmount: true,
      });
    }
    if (accountsConnectedToThisOne.map((acc) => acc.category).includes('realEstate')) {
      options.push({
        id: 'investment-rent',
        Icon: HomeIcon,
        name: t('labels.investment-rent.long'),
        nextLevel: true,
        requiresPositiveAmount: true,
      });
    }

    if (accountsConnectedToThisOne.map((acc) => acc.category).includes('stocks')) {
      options.push({
        id: 'investment-dividend',
        Icon: CircleStackIcon,
        name: t('labels.investment-dividend.long'),
        nextLevel: true,
        requiresPositiveAmount: true,
      });
    }

    if (accountsConnectedToThisOne.map((acc) => acc.category).includes('loans')) {
      options.push({
        id: 'investment-payout',
        Icon: ArchiveBoxArrowDownIcon,
        name: t('labels.investment-payout.long'),
        nextLevel: false,
        requiresPositiveAmount: true,
      });
      options.push({
        id: 'investment-loanPrincipal',
        Icon: TicketIcon,
        name: t('labels.investment-loanPrincipal.long'),
        nextLevel: true,
        requiresNegativeAmount: true,
      });
      options.push({
        id: 'investment-loanInterest',
        Icon: ReceiptPercentIcon,
        name: t('labels.investment-loanInterest.long'),
        nextLevel: true,
        requiresNegativeAmount: true,
      });
    }

    if (accountsConnectedToThisOne.map((acc) => acc.category).includes('pension')) {
      options.push({
        id: 'investment-contribution',
        Icon: BuyIcon,
        name: t('labels.investment-contribution.long'),
        nextLevel: true,
        requiresNegativeAmount: true,
      });
      options.push({
        id: 'investment-pension',
        Icon: ReceiptPercentIcon,
        name: t('labels.investment-pension.long'),
        nextLevel: true,
        requiresPositiveAmount: true,
      });
    }
  }

  if (category === 'pension') {
    options.push({
      id: 'pension-contribution',
      Icon: BuyIcon,
      name: t('labels.investment-contribution.long'),
      nextLevel: false,
      requiresNegativeAmount: true,
    });
    options.push({
      id: 'pension-payout',
      Icon: ReceiptPercentIcon,
      name: t('labels.investment-pension.long'),
      nextLevel: false,
      requiresPositiveAmount: true,
    });
  }

  // initialise to the option which the clicked-on transaction has (or null if it has none)
  const [selectedOption, setSelectedOption] = useState(options.find((option) => option.id === transaction.label));
  // to initalise, unpack the right transaction attributes to state
  const [linkedAttribute, setLinkedAttribute] = useState({
    linkedTransaction: transaction.linkedTransactions.find((lt) => lt.tags.linkType === 'fee-to-transaction' || lt.tags.linkType === 'sale-to-transaction' || lt.tags.linkType === 'transfer'),
    // tags for dividend
    accountId: (typeof transaction.tags === 'string' ? JSON.parse(transaction.tags) : transaction.tags)?.accountId || null,
    assetId: (typeof transaction.tags === 'string' ? JSON.parse(transaction.tags) : transaction.tags)?.assetId || null,
  });
  const [currentBreakpoint, setCurrentBreakpoint] = useState('md'); // stores current breakpoint
  const [panesToShow, setPanesToShow] = useState([true, true]); // [menu, submenu]

  const [loading, setLoading] = useState(false);

  function handleCancel(closeDialog = false) {
    // if (panesToShow[0] === false && panesToShow[1] === true) setPanesToShow([true, false]);
    if (!closeDialog) setPanesToShow([true, false]);
    // otherwise just close the dialog
    else window.dispatchEvent(new CustomEvent('setDialog', {}));
  }

  function parseIf(str) {
    return typeof str === 'string' ? JSON.parse(str) : str;
  }

  // INITIALISE COMPONENT

  useEffect(() => {
    // Handle window resize and update breakpoint state
    const handleResize = () => {
      const newBreakpoint = getCurrentBreakpoint();
      setCurrentBreakpoint(newBreakpoint);
      return newBreakpoint;
    };

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Set initial breakpoint
    const newBreakpoint = handleResize();

    // set panes depending on breakpoint
    if (isMobileLayout(newBreakpoint)) setPanesToShow([true, false]); // else use default
    if (!isMobileLayout(newBreakpoint) && selectedOption && !selectedOption.nextLevel) setPanesToShow([true, false]);

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // HANDLE MENU OPTION CHANGE

  function handleMenuOptionChange(option) {
    setSelectedOption(option);

    // set panes depending on whether the selected option has a next level or not (and whether we are on mobile)
    if (option && option.nextLevel) {
      if (isMobileLayout(currentBreakpoint)) setPanesToShow([false, true]);
      else setPanesToShow([true, true]);
    } else if (option && !option.nextLevel) {
      setPanesToShow([true, false]);
    }
  }

  // SAVE DATA WITHOUT SUBLIST

  const dispatch = useDispatch();
  async function handleSaveLabelOnly() {
    // for saving items which do not require any additional attributes (i.e. options where nextLevel = false)
    // all other ones are handled in the List component above (ca. row 220)
    if (!selectedOption.nextLevel) {
      try {
        setLoading(true);
        const promises = [
          dispatch(
            postData({
              data: [
                {
                  ...transaction,
                  label: selectedOption.id,
                  tags: {
                    ...parseIf(transaction.tags),
                    type: null,
                    accountId: null,
                    assetId: null,
                  },
                  linkedTransactions: transaction.linkedTransactions.filter(
                    (lt) => lt.tags.linkType !== 'fee-to-transaction' && lt.tags.linkType !== 'sale-to-transaction' && lt.tags.linkType !== 'transfer',
                  ),
                  importFlag: 'put',
                },
              ],
              category,
              accountId,
            }),
          ),
        ];

        // if previously this transaction was a transfer, we need to remove the linked transaction and label from the other side as well
        const linkedTransferTransactionObject = transaction.linkedTransactions.find((lt) => lt.tags.linkType === 'transfer'); // { id, category, tags }
        // if transaction found (handle fails graciously)
        if (linkedTransferTransactionObject) {
          const otherTransaction = selectAllTransactions.find((tx) => tx.id === linkedTransferTransactionObject?.id);

          promises.push(
            dispatch(
              postData({
                data: [
                  {
                    ...otherTransaction,
                    label: null, // sets label to null
                    linkedTransactions: otherTransaction.linkedTransactions.filter((lt) => lt.tags.linkType !== 'transfer'), // removes the transfer object
                    importFlag: 'put',
                  },
                ],
                category: otherTransaction.category,
                accountId: otherTransaction.accountId,
              }),
            ),
          );
        }

        setLoading(false);
        handleCancel(true);
      } catch (e) {
        setLoading(false);
        dispatch(setMessage('dataUpdateError'));
      }
    }
  }

  return (
    <div className="mx-auto max-w-3xl transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
      <Combobox value={selectedOption} onChange={handleMenuOptionChange}>
        <Combobox.Options as="div" static hold className="flex divide-x divide-gray-100">
          {panesToShow[0] && (
            <div id="blue-transactions-menu" className={classNames('max-h-[32rem] min-w-0 flex-auto scroll-py-4 overflow-y-auto px-6 py-4')}>
              <h2 className="mt-2 mb-4 text-base font-medium text-gray-500">{t('markThisTransaction')}</h2>
              <div className="-mx-2 text-sm text-gray-700">
                {options
                  .filter((option) => (transaction.quantity < 0 ? option.requiresNegativeAmount : option.requiresPositiveAmount))
                  .map((item) => (
                    <Combobox.Option
                      as="div"
                      key={item.id}
                      value={item}
                      data-testid={item.id}
                      className={({ active }) => classNames(
                        'flex cursor-default select-none items-center rounded-md p-2',
                        item.id === selectedOption?.id ? 'bg-brandBlue-500 hover:bg-brandBlue-600 text-white' : 'bg-white hover:bg-gray-100 text-gray-900',
                      )}
                    >
                      {({ active }) => (
                        <>
                          <item.Icon className="h-6 w-6 flex-none rounded-full" />
                          <span className="ml-3 flex-auto truncate">{item.name}</span>
                          {active && item.nextLevel && (
                            <>
                              <ChevronRightIcon className="hidden sm:inline ml-3 h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
                              <ChevronDownIcon className="sm:hidden ml-3 h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
                            </>
                          )}
                        </>
                      )}
                    </Combobox.Option>
                  ))}
              </div>
              {!!selectedOption && !selectedOption?.nextLevel ? (
                <div id="blue-transactions-signle-level-buttons" className="grid grid-cols-2 gap-2 mt-4">
                  <div className="col-start-2 grid grid-cols-2 gap-2">
                    <Button text={tB('cancel')} onClick={() => handleCancel(true)} size="lg" />
                    <Button text={tB('save')} onClick={handleSaveLabelOnly} size="lg" withAccent enabled={!loading} spinnerOn={loading} />
                  </div>
                </div>
              ) : (
                isMobileLayout(currentBreakpoint) && (
                  <div id="blue-transactions-single-level-buttons" className="w-full flex justify-center mt-4">
                    <Button text={tB('cancel')} onClick={() => handleCancel(true)} size="lg" />
                  </div>
                )
              )}
            </div>
          )}
          {panesToShow[1] && (
            <div id="blue-transactions-submenu" className="h-[32rem] sm:w-1/2 flex-none px-6 py-4 flex-col divide-y divide-gray-100 overflow-y-auto flex">
              <h2 className="mt-2 mb-4 text-base font-medium text-gray-500">{t('whichItem')}</h2>
              <div className="flex flex-auto flex-col justify-between overflow-hidden">
                <Sublist
                  selectedOption={selectedOption}
                  accountId={accountId}
                  linkedAttribute={linkedAttribute}
                  setLinkedAttribute={setLinkedAttribute}
                  someRandomProp="1"
                  // if it is for save, always close the dialog (regardless of what handleCancel will do)
                  handleCloseAfterSave={() => handleCancel(true)}
                  // if this is a mobile layout, cancel in Sublist must not close the dialog (only go back to the main menu)
                  handleCancel={isMobileLayout(currentBreakpoint) ? () => handleCancel(false) : () => handleCancel(true)}
                  transaction={transaction}
                />
              </div>
            </div>
          )}
        </Combobox.Options>
      </Combobox>
    </div>
  );
}
BlueTransactionsDialog.propTypes = {
  transactionId: PropTypes.string.isRequired,
  category: PropTypes.string,
};
BlueTransactionsDialog.defaultProps = {
  category: 'deposits',
};
