import React, { useEffect, useState, useLayoutEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/solid';
import MiniSpinner from '../misc/MiniSpinner';

export function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function InputComponentAsyncCallback({ parentValue, stub, formatting, callback, propertyName, showButtons }) {
  // formatting needs a string
  // value (String) - current value of the input field as registered in the parent component
  // stub is the label displayed if 'attr' property of account is null (should come translated)
  // callback is an async function which is executed once user clicks OK or presses Enter;
  // it should return the result of dispatch (which is a promise in RTK mode); callback({ propertyName, newValue })
  // propertyName (String) - used in id of the input field (`${propertyName}-input`)
  // showButtons declares if the OK and X buttons are to be shown
  const [initialValue, setInitialValue] = useState(parentValue);
  const [currentValue, setCurrentValue] = useState(parentValue);
  const [editMode, setEditMode] = useState(false);
  const [isUpdating, setUpdating] = useState(false);

  const [widthState, setWidthState] = useState(0);
  const [visible, setVisible] = useState(false); // turns on and off the span component for a fraction of a sec, trick to measure / resize input field
  // ^^  turn this true for debug if there are any issues with length of the field

  const measurer = useRef(null);
  const myInputField = useRef(null);

  // routines for resizing the input field
  useEffect(() => {
    // toggles the visibility of span and activates useLayoutEffect
    setVisible(true);
  }, [currentValue, isUpdating]);

  useLayoutEffect(() => {
    // measures the span size and updates the state, switches span visibility to false
    if (visible && measurer?.current) {
      const rect = measurer.current.getBoundingClientRect();
      if (showButtons) setWidthState(rect.width + 24 /* account for padding */ + 72 /* account for yes / no icons */);
      else setWidthState(rect.width + (isUpdating ? 24 : 0) /* account for spinner */);
      setVisible(false); // triggers the measurement
    }
  }, [visible, isUpdating]);
  // end of routines for resizing input field

  // if the focus is lost, and we are not updating the field to a new value, restore the old value
  useEffect(() => {
    if (!isUpdating) {
      setCurrentValue(initialValue);
    }
  }, [editMode]);

  // if value passed from parent changes, update the state
  useEffect(() => {
    setInitialValue(parentValue);
    setCurrentValue(parentValue);
  }, [parentValue]);

  function handleFieldCancel(e) {
    setEditMode(false);
    // myInputField.current.blur();
    // setVisible(true); // just take a measurement of the span
  }

  async function handleFieldOk(e, mode) {
    if (mode === 'key' && e.key !== 'Enter') return;
    setEditMode(false);
    setUpdating(true);
    if (((mode === 'key' && e.key === 'Enter') || mode === 'button') && currentValue !== initialValue) {
      try {
        const result = await callback({ propertyName, newValue: currentValue }); // callback should perform the put operation and return the promise from dispatch
        setUpdating(false); // name should be updated via useEffect above (when 'value' prop changes)
        setEditMode(false);
        myInputField.current.blur();
      } catch (err) {
        setUpdating(false); // callback should take care of displaying the error message, as we are just a dumb component
      }
    }
  }

  return (
    <label htmlFor={`${propertyName}-input`} className="relative h-full">
      {/* this is for measuring the input field length by substitution */}
      <span className={classNames(formatting)} ref={measurer}>
        {visible && (currentValue || stub)}
      </span>
      <input
        className={classNames(
          isUpdating ? 'opacity-50 ' : '',
          'bg-gray-50 border-none rounded pl-1 xs:pl-4',
          formatting,
          'hover:underline hover:cursor-pointer truncate focus:cursor-text focus:ring-1 focus:ring-offset-0 focus:outline-none focus:ring-brandBlue-400',
        )}
        style={{ width: widthState }}
        id={`${propertyName}-input`}
        ref={myInputField}
        disabled={isUpdating} // React is going to ignore this if isUpdating is not truthy
        placeholder={stub}
        value={currentValue}
        onChange={(e) => setCurrentValue(e.target.value)}
        onKeyPress={(e) => handleFieldOk(e, 'key')}
        onFocus={() => setEditMode(true)}
        onBlur={(e) => {
          // a short timeout to delay the blur event until the onclick event of the buttons have been processed
          setTimeout(() => handleFieldCancel(e), 150);
        }}
        type="text"
      />

      {isUpdating && (
        <div className="absolute flex top-0 right-0 h-full space-x-2 pr-2 z-10">
          {/* ch is the width of the 0 character; spinner vv */}
          <MiniSpinner className={classNames(showButtons ? 'h-5 w-5 -mt-1 ml-2 mr-3' : 'h-3 w-3 -ml-2 mt-1', 'animate-spin text-gray-500 text-sm')} />
        </div>
      )}
      {editMode && (
        <div className="absolute flex right-0 top-0 -mt-1 h-full space-x-2 pr-2 z-10">
          <button type="button" onClick={(e) => handleFieldOk(e, 'button')}>
            <CheckCircleIcon className={classNames(showButtons ? 'h-6 w-6' : 'hidden', 'text-gray-300 hover:text-gray-400 hover:cursor-pointer')} />
          </button>
          <button
            type="button"
            onClick={(e) => {
              // a short timeout to delay the blur event until the onclick event of the buttons have been processed
              setTimeout(() => {
                handleFieldCancel(e);
              }, 150);
            }}
          >
            <XCircleIcon className={classNames(showButtons ? 'h-6 w-6' : 'hidden', 'text-gray-300 hover:text-gray-400 hover:cursor-pointer')} />
          </button>
        </div>
      )}
    </label>
  );
}
InputComponentAsyncCallback.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  callback: PropTypes.func.isRequired,
  formatting: PropTypes.string.isRequired,
  stub: PropTypes.string.isRequired,
  parentValue: PropTypes.string.isRequired,
  propertyName: PropTypes.string.isRequired,
  showButtons: PropTypes.bool,
};
InputComponentAsyncCallback.defaultProps = {
  showButtons: true,
};
