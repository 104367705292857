/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Auth } from 'aws-amplify';
import MiniSpinner from '../../misc/MiniSpinner';

export default function MfaPane({ user, setDisplayedPane, afterLoginGoTo }) {
  const { t, i18n } = useTranslation('site');

  const [confirmationCode, setConfirmationCode] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loginError, setLoginError] = useState(undefined); // null or error message
  const form = useRef();

  const navigate = useNavigate();

  const { isLoggedIn } = useSelector((state) => state.user);

  async function handleSubmitConfirmationCode(e) {
    e.preventDefault();
    e.persist();
    setLoading(true);
    try {
      await Auth.confirmSignIn(user, confirmationCode, 'SOFTWARE_TOKEN_MFA');
    } catch (error) {
      if (error.code === 'CodeMismatchException') {
        setLoginError(t('login.incorrectConfirmationCode'));
      } else {
        console.error(error);
        setLoginError(error.message);
      }
      setLoading(false);
    }
  }

  function required(value) {
    if (!value) {
      return (<div className="alert alert-danger" role="alert">{t('login.fieldRequired')}</div>);
    }
    return (<div />);
  }

  // handle successful login
  useEffect(() => {
    // if isLoggedIn turned true and loading is true
    if (isLoggedIn === true && loading === true) {
      console.log(`Login completed. Navigating towards ${afterLoginGoTo}, isLoggedIn is ${isLoggedIn}`);
      try {
        navigate(afterLoginGoTo, { replace: true });
        console.log(`Navigate to ${afterLoginGoTo} hook executed`);
      } catch (error) {
        console.log(`Error while rendering page ${afterLoginGoTo}`);
      }
    }
    if (isLoggedIn === true) {
      // there is no point in showing the login form if the user is already logged in
      navigate(`/${i18n.language}/app`, { replace: true });
    }
  }, [isLoggedIn]);

  return (
    <div className="mx-auto w-full max-w-sm lg:w-96">
      {(loginError) ? <h3 className="text-brandRed-500" id="loginIncorrectError">{loginError}</h3> : null}
      <h2 className="mt-6 text-3xl font-extrabold text-gray-900 text-brand">
        {t('login.signInIntoYourAccount')}
      </h2>
      <form onSubmit={handleSubmitConfirmationCode} ref={form} className="space-y-12 mt-8 mb-1">
        <div>
          <label
            htmlFor="username"
            className="block text-sm font-medium text-gray-700"
          >
            {t('login.enterConfirmationCode')}
          </label>
          <div className="mt-1">
            <input
              id="confirmationCode"
              name="confirmationCode"
              type="text"
              required
              // eslint-disable-next-line max-len
              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-brandBlue-500 focus:border-brandBlue-500 sm:text-sm"
              value={confirmationCode}
              onChange={(e) => setConfirmationCode(e.target.value)}
              // eslint-disable-next-line react/no-unknown-property
              validations={[required]}
            />
          </div>
        </div>
        <div>
          <button
            // eslint-disable-next-line max-len
            className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-semibold text-white bg-brandBlue-500 hover:bg-brandBlue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brandBlue-500"
            id="submit"
            type="submit"
            disabled={loading}
          >
            {loading ? <MiniSpinner className="h-4 w-4 animate-spin text-white" /> : (<span>{t('general.continue')}</span>)}
          </button>
          <button
            // eslint-disable-next-line max-len
            className="mt-4 w-full flex justify-center py-2 px-4 border rounded-md shadow-sm text-sm font-semibold outline-brandBlue-500 text-brandBlue-500 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brandBlue-500"
            disabled={loading}
            type="button"
            onClick={() => setDisplayedPane('login')}
          >
            {t('general.cancel')}
          </button>
        </div>
      </form>
    </div>
  );
}
MfaPane.propTypes = {
  user: PropTypes.object.isRequired,
  setDisplayedPane: PropTypes.func.isRequired,
  afterLoginGoTo: PropTypes.string.isRequired,
};
