/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { globalAccountsView, stockTransactions, postData } from '../../redux/reducers/data';
import { setMessage } from '../../redux/actions/message';
import AccountLabel from '../../elements/AccountLabel';
import SublistOkCancel from './SublistOkCancel';

export default function BlueTransactionsDividends({ selectedOption, accountId, linkedAttribute, handleCloseAfterSave, handleCancel, transaction }) {
  // for dividends, display a list of all symbols from the connected asset account
  // for DIVIDENDS, show a list of all account-symbol combinations from the connected stock accounts, sorted by symbol
  const { t } = useTranslation('app', { keyPrefix: 'accountDetails.deposits' });
  const [loading, setLoading] = useState(false);
  const [localLinkedAttribute, setLocalLinkedAttribute] = useState(linkedAttribute); // intialise to the object from props (should there be an existing linkedAttribute already)
  const dispatch = useDispatch();
  const allAccounts = useSelector(globalAccountsView) || [];

  async function handleSave() {
    // for saving items which require additional attributes (e.g. fees or dividends)
    const updatedTransaction = {
      ...transaction,
      label: selectedOption.id,
      importFlag: 'put',
    };

    // DIVIDEND: check if a transaction is selected, add a tag with assetId and accountId
    if (selectedOption.id === 'investment-dividend') {
      // if the transaction is not marked, do not save it
      if (!localLinkedAttribute?.accountId || !localLinkedAttribute?.assetId) {
        dispatch(setMessage('symbolNotSelected'));
        return;
      }

      updatedTransaction.tags = {
        ...(typeof updatedTransaction.tags === 'string' ? JSON.parse(updatedTransaction.tags) : updatedTransaction.tags),
        assetId: localLinkedAttribute.assetId,
        accountId: localLinkedAttribute.accountId,
      };
    }

    setLoading(true);
    try {
      await dispatch(
        postData({
          data: [updatedTransaction],
          category: 'deposits',
          accountId,
        }),
      );
      setLoading(false);
      handleCloseAfterSave();
    } catch (err) {
      setLoading(false);
      dispatch(setMessage('dataUpdateError'));
    }
  }

  function getAccountById(a) {
    return allAccounts.find((account) => account.id === a);
  }

  const connectedStockAccountIds = allAccounts.filter((account) => account.connectedDepositAccounts?.includes(accountId)).map((account) => account.id);

  // so this can be a list of transactions from more than 1 account
  const connectedAccountsTransactions = useSelector((state) => stockTransactions(state)).filter((tx) => connectedStockAccountIds?.includes(tx.accountId));

  const connectedAccountsWithSymbols = connectedAccountsTransactions?.reduce(
    (prev, curr) => ({
      ...prev,
      [curr.accountId]: prev[curr.accountId]?.includes(curr.figi) ? prev[curr.accountId] : [...(prev[curr.accountId] || []), curr.figi],
    }),
    {},
  );

  const connectedAccountSymbolCombinations = Object.keys(connectedAccountsWithSymbols)
    ?.flatMap((a) => connectedAccountsWithSymbols[a]?.map((figi) => ({ accountId: a, figi })))
    ?.sort((a, b) => a[0]?.figi.localeCompare(b[0].figi));

  return (
    <>
      <div className="flex flex-col gap-2 overflow-y-auto" data-testid="blue-transactions-dividends">
        {connectedAccountSymbolCombinations.map((x) => (
          <button
            type="button"
            key={x.accountId + x.figi}
            onClick={() => setLocalLinkedAttribute({
              ...localLinkedAttribute,
              accountId: x.accountId,
              assetId: x.figi,
            })}
            data-testid="sublist-asset"
            className={`text-xs border border-gray-200 shadow-sm rounded-md p-2 flex justify-between gap-2 cursor-pointer
            ${
              x?.accountId === localLinkedAttribute?.accountId && x?.figi === localLinkedAttribute?.assetId
                ? 'bg-brandBlue-500 text-white hover:bg-brandBlue-600 border-transparent'
                : 'bg-white text-gray-900 hover:bg-gray-50'
            }`}
          >
            <div>{x.figi}</div>
            <AccountLabel accountName={getAccountById(x.accountId)?.name} category={getAccountById(x.accountId)?.category} />
          </button>
        ))}
        {connectedAccountSymbolCombinations.length === 0 && <div className="text-sm text-gray-500 self-center mt-2">{t('noAssets')}</div>}
      </div>
      <SublistOkCancel handleSave={handleSave} handleCancel={handleCancel} loading={loading} />
    </>
  );
}
BlueTransactionsDividends.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  selectedOption: PropTypes.objectOf(PropTypes.any).isRequired,
  accountId: PropTypes.string.isRequired,
  linkedAttribute: PropTypes.object,
  handleCancel: PropTypes.func.isRequired,
  handleCloseAfterSave: PropTypes.func.isRequired,
  transaction: PropTypes.object.isRequired,
};
BlueTransactionsDividends.defaultProps = {
  linkedAttribute: {},
};
