import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import namedBaselines from '../../misc/namedBaselines';
import { updateCognitoSettings } from '../../redux/actions/user';
import { globalBaselineView } from '../../redux/reducers/data';

const debugLevel = process.env.MDEBUG || 0;

export default function BaselineDropdown({ calendarState, setCalendarState, isViewer }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  let baselineDate;
  try {
    baselineDate = isViewer ? useSelector((state) => state.viewer.kpis.baselineDate) : useSelector(globalBaselineView).baseline;
  } catch (e) {
    baselineDate = namedBaselines.sameTimeLastYear;
  }

  const baselineTimePeriods = {
    emptyRow: -1,
    ...namedBaselines,
    custom: baselineDate,
  };

  const [baselineState, setBaselineState] = useState('sameTimeLastYear');

  function findNameOfBaselineDate(_baselineDate) {
    const index = Object.values(baselineTimePeriods).indexOf((item) => Number(item) === Number(_baselineDate));
    return index === -1 ? 'custom' : Object.keys(baselineTimePeriods)[index];
  }

  // this useEffect sets dropdown state to a named baseline if the baselineDate in Redux matches one of the predefined dates
  // check if any of the predefined dates matches the currently set baseline in Redux and if so, set the component state to reflect that
  useEffect(() => {
    if (baselineDate) {
      if (debugLevel > 2) console.log('baselineDate', dayjs.utc(baselineDate).format('YYYY-MM-DD HH:mm:ss'));
      const matchedBaseline = Object.entries(baselineTimePeriods).find(([key, value]) => Number(value) === Number(baselineDate)); // returns [key, value]
      if (debugLevel > 2) console.log(matchedBaseline);
      if (matchedBaseline.length > 0) setBaselineState(matchedBaseline[0]);
      else setBaselineState('custom');
    }

    // if baselineDate is not set (e.g. user logs on for the first time), set it to a default value
    if (!baselineDate) {
      dispatch(updateCognitoSettings({ baselineDate: baselineTimePeriods.sameTimeLastYear, namedBaseline: 'sameTimeLastYear' }));
    }
  }, [baselineDate]); // this changes and sets baselineState (which is used to populate the dropdown) only when the Redux selector changes

  function dispatchNewBaseline(date, namedBaseline = null) {
    const newBaseline = Number(date.valueOf());
    // save this in Cognito and then replicate in Redux
    dispatch(updateCognitoSettings({ baselineDate: dayjs.utc(newBaseline).startOf('day').valueOf(), namedBaseline }));
    setBaselineState(newBaseline);
  }

  function handleCustomBaselineChange(e) {
    e.preventDefault();
    if (isViewer) return; // do nothing if this is a viewer
    // if this is a custom baseline, open calendar widget and give it a callback on the way
    // otherwise set the new date in redux directly
    if (e.target.value === 'custom') setCalendarState({ ...calendarState, visible: true, callback: dispatchNewBaseline });
    else dispatchNewBaseline(baselineTimePeriods[e.target.value], e.target.value);
  }

  return (
    <div className="pt-1 pb-6">
      <label htmlFor="location" className="block text-xs sm:text-sm font-medium text-gray-500">
        {t('app:dashboard.sumtile.previousValue.selectBaseline')}
      </label>
      <select
        id="baseline"
        disabled={isViewer}
        name="baseline"
        className="block w-full mt-1 pr-10 pb-2 text-gray-900 text-sm border border-gray-300 focus:outline-none focus:ring-brandBlue-500 rounded-md"
        // defaultValue="same time last year"
        value={baselineState}
        onChange={handleCustomBaselineChange}
        onFocus={(e) => {
          if (isViewer) return; // do nothing if this is a viewer
          setBaselineState(undefined);
        }}
        onBlur={(e) => {
          if (isViewer) return; // do nothing if this is a viewer
          if (Number(e.target.value) === -1) {
            setBaselineState(findNameOfBaselineDate(baselineDate));
          }
        }}
      >
        <option className="text-xs sm:text-sm" value="emptyRow">
          {' '}
        </option>
        <option className="text-xs sm:text-sm" value="sameTimeLastYear">
          {`${t('app:dashboard.sumtile.previousValue.sameTimeLastYear')}`}
        </option>
        <option className="text-xs sm:text-sm" value="currentYearBegin">
          {`${t('app:dashboard.sumtile.previousValue.beginning')}`}
        </option>
        <option className="text-xs sm:text-sm" value="previousYearBegin">
          {`${t('app:dashboard.sumtile.previousValue.beginningLastYear')}`}
        </option>
        <option className="text-xs sm:text-sm" value="custom">
          {`${t('app:dashboard.sumtile.previousValue.custom')} (${new Date(baselineDate).toLocaleString('de', { month: '2-digit', day: '2-digit', year: '2-digit' })})`}
        </option>
      </select>
    </div>
  );
}
BaselineDropdown.propTypes = {
  calendarState: PropTypes.shape({
    visible: PropTypes.bool,
    callback: PropTypes.func,
  }).isRequired,
  setCalendarState: PropTypes.func.isRequired,
  isViewer: PropTypes.bool,
};
BaselineDropdown.defaultProps = {
  isViewer: false,
};
