/* eslint-disable react/jsx-filename-extension */
import React from 'react';

const tours = [
  {
    type: 'intro',
    id: 'PR-intro',
    steps: [
      {
        selector: '#projectDetailsParent',
        content: () => (
          <div>
            <p className="pb-4">
              Welcome to the
              {' '}
              <strong>Project Details</strong>
              {' '}
              screen.
            </p>
            <p>
              Use it to simulate
              {' '}
              <strong>future</strong>
              {' '}
              money in- and outflows, asset purchases and value growth.
            </p>
          </div>
        ),
      },
      {
        selector: '#projectSlider',
        content: () => (
          <div>
            <p className="pb-4">Use this slider to see project asset changes over time.</p>
            <p>
              The date you select is the
              {' '}
              <strong>Slider Date</strong>
              .
            </p>
          </div>
        ),
      },
      {
        selector: '#project-transactions-list',
        content: () => (
          <p>
            Your future
            {' '}
            <strong>transactions</strong>
            {' '}
            and asset
            {' '}
            <strong>value changes</strong>
            {' '}
            appear here.
          </p>
        ),
      },
      {
        selector: '#projectButtonBar :nth-child(2), #projectButtonBar :nth-child(3)',
        content: () => (
          <p>
            <strong>Add</strong>
            {' '}
            new future
            <strong>transactions</strong>
            {' '}
            here...
          </p>
        ),
      },
      {
        selector: '#projectButtonBar :nth-child(2), #projectButtonBar :nth-child(3)',
        content: () => (
          <p>
            and future
            {' '}
            <strong>asset value changes</strong>
            {' '}
            here.
          </p>
        ),
      },
      {
        selector: '#project-assets',
        content: () => (
          <div>
            <p>
              Here are all
              {' '}
              <strong>accounts</strong>
              {' '}
              and
              {' '}
              <strong>assets</strong>
              {' '}
              used in the projects.
            </p>
            <p className="pt-2">
              The amounts displayed are their
              {' '}
              <strong>future values</strong>
              {' '}
              on Slider Date.
            </p>
          </div>
        ),
      },
      {
        selector: '#projectSum',
        content: () => (
          <p>
            This is the
            {' '}
            <strong>net value total</strong>
            {' '}
            of all project assets as of Slider Date.
          </p>
        ),
      },
      {
        selector: '#project-details-add-goal-button',
        content: () => (
          <p>
            You can set
            {' '}
            <strong>goals</strong>
            {' '}
            for your project here.
          </p>
        ),
      },
      {
        selector: '#project-details-apply-template-button',
        content: () => (
          <div>
            <p>
              To help you get started you can import
              {' '}
              <strong>sample transactions</strong>
              {' '}
              from a pre-defined project template.
            </p>
            <p className="pt-2 italic">For example: import a template for purchasing real estate for rent.</p>
          </div>
        ),
      },
      {
        selector: '#projectIsolated',
        content: () => (
          <div>
            <p>
              By default (
              {' '}
              <span className="italic">&quot;yes&quot;</span>
              ) project transactions and future value changes
              {' '}
              <strong>show up</strong>
              {' '}
              in Account Details and are reflected in your overall
              KPIs and Reports.
            </p>
            <p className="pt-2">
              You can change that behaviour by setting this to
              {' '}
              <span className="italic">&quot;no&quot;</span>
              .
            </p>
          </div>
        ),
      },
    ],
  },
];

export default tours;
