const kpiReport = {
  name: 'KPI Report (EN only)',
  readOnly: true,
  dataScope: 'balances', // does not matter, it uses its own data source
  cols: [],
  rows: [],
  vals: [],
  aggregatorName: 'Count',
  rendererName: 'KPI Report',
  id: 'kpiReportxxxxxxxxxxxx',
};

export default kpiReport;
