/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-restricted-syntax */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { Transition, Disclosure } from '@headlessui/react';
import PivotTable from 'react-pivottable/PivotTable';
import { ChevronUpIcon } from '@heroicons/react/24/solid';
import DashboardCategory from '../accountDetails/DashboardCategory';
import HeaderApp from '../../elements/HeaderApp';
import TransactionPreview from '../accountDetails/TransactionPreview';
import KPIs from '../kpis/KPIs';
import { enabledCategories } from '../../misc/globalSettings';
import renderers from '../reports/renderers';
import allAssetsSunburst from '../reports/prebuiltReports/allAssetsSunburst';
import assetsBalanceChanges from '../reports/prebuiltReports/assetsBalanceChanges';
import allStockPositions from '../reports/prebuiltReports/allStockPositions';
import quotesChart from '../reports/prebuiltReports/quotes';
import { objectToArray } from '../../misc/arrayHelpers';
import MiniSpinner from '../../misc/MiniSpinner';
import { getSharedReport } from '../../redux/reducers/viewer';

dayjs.extend(utc);

export function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

// renders the guest dashboard viewer with data loaded from S3
export default function SharedReportViewer() {
  const { t, i18n } = useTranslation('app');
  const params = useParams();
  const dispatch = useDispatch();
  const { reportId } = params;

  const [loading, setLoading] = useState(true);
  const [errorState, setErrorState] = useState(false);

  // initialise the report retrieval (async, no waiting for result)
  // the result will be loaded via the viewer reducer
  useEffect(() => {
    // show dialog with passcode prompt, ask for passcode, dispatch the below in callback
    window.dispatchEvent(
      new CustomEvent('setDialog', {
        detail: {
          header: t('dashboard.sharedReportViewer.header'),
          prompt: t('dashboard.sharedReportViewer.prompt'),
          value: '000-000-000',
          callback: async (value) => {
            const result = await dispatch(getSharedReport({ reportId, passcode: value }));
            console.log(result);
            if (result.error) {
              setErrorState(true);
            }
          },
          shareReport: true,
        },
      }),
    );
  }, [reportId]);

  const viewer = useSelector((state) => state.viewer);
  console.log('viewer contents', viewer);

  if (errorState) {
    return (
      <div className="w-full h-[65vh;] flex flex-col gap-4 justify-center items-center ">
        <p className="text-xl font-bold text-gray-900">{t('dashboard.sharedReportViewer.reportNotFound')}</p>
        <p className="text-gray-700">{t('dashboard.sharedReportViewer.unableToLoadReport')}</p>
      </div>
    );
  }

  if (Object.keys(viewer).length === 0) {
    return (
      <div className="w-full h-[65vh;] flex flex-col gap-4 justify-center items-center text-gray-400">
        <MiniSpinner className="h-12 w-12 animate-spin" />
        <p className="text-xl text-gray-500">{t('dashboard.pleaseWait')}</p>
      </div>
    );
  }

  const { baseCurrency, reportData } = viewer;

  const assetBalanceChangesData = { data: reportData.snapshots };
  const allAssetsSunburstData = { data: reportData.balances };

  // reports are displayed using PivotTable, which needs:
  // - standardPivotTableProps (the same for all)
  // - props from the report definition (import report definition file from /reports/prebuiltReports and spread it on PivotTable)
  // - data prop which is normally provided by reportingSelectors, but in this case we need to provide it ourselves (by using reportData from the viewer)

  // static props, do not depend on the report
  const standardPivotTableProps = {
    onChange: () => {},
    renderers,
  };

  const topChartLayoutOptions = {
    // holds between renders and returns the value
    startLabel: false,
    endLabel: false,
    margin: {
      top: 10,
      right: 20,
      bottom: 30,
      left: 20,
    },
  };

  return (
    <div className="" id="dashboard-root">
      <div className="relative xl:static" id="dashboard-level-1">
        <div className="relative min-h-full bg-gray-50">
          <HeaderApp mode="viewer" openSettings={() => {}} openAccount={() => {}} searchControl={{}} setSearchControl={() => {}} setRecommendUsVisible={() => {}} />
          {/* mt corresponds to the height of background padding set in HeaderApp */}
          <main className="pb-8 -mt-16 lg:-mt-20">
            <div className="relative max-w-3xl mx-auto px-4 sm:px-6 lg:max-w-[100rem] lg:px-8">
              <h1 className="sr-only">Profile</h1>
              {/* project time slider */}
              {/* ------------------ */}
              {/* MAIN 3-COLUMN GRID */}
              {/* ------------------ */}
              <div className="grid grid-cols-1 gap-2 md:gap-4 items-start lg:grid-cols-12 lg:gap-8">
                {/* Left column */}
                <KPIs baseCurrency={baseCurrency} setKpiReportVisible={() => {}} calendar={{}} setCalendarState={() => {}} setAccountDetails={() => {}} isViewer />
                {/* Middle column */}

                <div className="grid grid-cols-1 gap-4 lg:col-span-6">
                  {/* Header panel */}
                  <section aria-labelledby="accounts-control-panel">
                    <div className="xs:overflow-hidden flex flex-wrap justify-between sm:grid sm:grid-cols-3">
                      <h2 className="sr-only" id="profile-overview-title">
                        Accounts Overview
                      </h2>
                      <div className="py-4 px-0 sm:px-3 md:px-4 sm:col-span-2">
                        <div className="flex gap-3 align-baseline" id="oneaboveyourassets">
                          <h2 className="text-2xl sm:text-3xl md:text-4xl lg:text-3xl xl:text-4xl font-bold text-gray-900 lg:text-white" id="your-assets">
                            {`${t('dashboard.yourAssetsOn')} ${dayjs.utc(viewer.metadata.publishedOn).format('DD.MM.YYYY')}`}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </section>
                  {enabledCategories.map((category) => (
                    <>
                      <DashboardCategory key={category} category={category} editMode={false} setEditMode={() => {}} addMode={false} setAddMode={() => {}} setAccount={() => {}} isViewer />

                      {/* stocks - all positions reports */}
                      {category === 'stocks' && (
                        <div className="mx-4">
                          <Disclosure as="section" className="px-4 py-2 bg-white w-full rounded-md">
                            {({ open }) => (
                              <>
                                <Disclosure.Button className="font-semibold w-full flex items-center justify-between">
                                  <span className="text-gray-500">Details</span>
                                  <ChevronUpIcon className={`${open ? 'rotate-180 transform' : ''} h-5 w-5 text-purple-500`} />
                                </Disclosure.Button>
                                <Transition
                                  enter="transition duration-100 ease-out"
                                  enterFrom="transform scale-95 opacity-0"
                                  enterTo="transform scale-100 opacity-100"
                                  leave="transition duration-75 ease-out"
                                  leaveFrom="transform scale-100 opacity-100"
                                  leaveTo="transform scale-95 opacity-0"
                                >
                                  <Disclosure.Panel className="mt-2">
                                    <PivotTable {...standardPivotTableProps} {...allStockPositions} isViewer />
                                  </Disclosure.Panel>
                                </Transition>
                              </>
                            )}
                          </Disclosure>
                        </div>
                      )}

                      {/* realEstate - all positions reports */}
                      {category === 'realEstate' && (
                        <div className="mx-4">
                          <Disclosure as="section" className="px-4 py-2 bg-white w-full rounded-md">
                            {({ open }) => (
                              <>
                                <Disclosure.Button className="font-semibold w-full flex items-center justify-between">
                                  <span className="text-gray-500">Details</span>
                                  <ChevronUpIcon className={`${open ? 'rotate-180 transform' : ''} h-5 w-5 text-purple-500`} />
                                </Disclosure.Button>
                                <Transition
                                  enter="transition duration-100 ease-out"
                                  enterFrom="transform scale-95 opacity-0"
                                  enterTo="transform scale-100 opacity-100"
                                  leave="transition duration-75 ease-out"
                                  leaveFrom="transform scale-100 opacity-100"
                                  leaveTo="transform scale-95 opacity-0"
                                >
                                  <Disclosure.Panel className="mt-2 grid grid-cols-2 gap-4">
                                    {reportData.balances
                                      .filter((b) => b.category === 'realEstate')
                                      .map((b) => (
                                        <div className="h-64 w-full">
                                          <PivotTable
                                            {...standardPivotTableProps}
                                            {...quotesChart}
                                            data={objectToArray(reportData.quotes[b.accountId]).map((quote) => ({ ...quote, displayName: 'whatever' }))}
                                            layoutOptions={{
                                              axisBottom: { tickRotation: -90 },
                                              margin: { bottom: 60 },
                                            }}
                                            isViewer
                                          />
                                        </div>
                                      ))}
                                  </Disclosure.Panel>
                                </Transition>
                              </>
                            )}
                          </Disclosure>
                        </div>
                      )}
                    </>
                  ))}
                </div>
                {/* Right column */}
                <div className="grid grid-cols-1 gap-4 lg:col-span-4 ">
                  {/* Charts / transaction preview  / project list */}
                  <section aria-labelledby="chart-title" className="">
                    <div className="relative rounded-md bg-white overflow-hidden shadow">
                      <div className="p-6 h-[32rem]">
                        <h2 className="text-base font-semibold text-gray-900" id="chart-top-title">
                          {t('charts.last12months')}
                        </h2>
                        <div className="w-full h-[80%]">
                          <PivotTable {...standardPivotTableProps} {...assetsBalanceChanges} {...assetBalanceChangesData} layoutOptions={topChartLayoutOptions} isViewer />
                        </div>
                      </div>
                    </div>
                  </section>
                  <section aria-labelledby="chart-title">
                    <div className="rounded-md bg-white overflow-hidden shadow z-10">
                      <div className="p-6 h-[32rem]">
                        <h2 className="mb-6 text-base font-semibold text-gray-900" id="chart-bottom-title">
                          {t('charts.currentAllocation')}
                        </h2>
                        <div className="w-full h-[80%]">
                          <PivotTable {...standardPivotTableProps} {...allAssetsSunburst} {...allAssetsSunburstData} />
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
}
