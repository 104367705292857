import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { redirect, useNavigate, useLocation } from 'react-router-dom';
import { register } from '../../redux/actions/user';
import { setMessage } from '../../redux/actions/message';
import { InternalAccountForm, checkIfUsernameCanBeUsed } from './SignUp1';
import Button from '../../elements/Button';

export default function SignUpFallback() {
  const { t, i18n } = useTranslation('site', { keyPrefix: 'register.fallback' });
  const formObject = useForm({ mode: 'onBlur' });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  // get query parameter error from URL
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const fallbackMessage = queryParams.get('fallbackMessage');

  function onSubmit(formData) {
    setLoading(true);
    try {
      // the previously gathered user data are saved in session storage, so we need to update them there (without overwriting)
      const savedFormObject = JSON.parse(localStorage.getItem('signUpObject'));

      // signUpObject is used to maintain consistency of the user data across the registration process
      // it is first defined in SignUp3 and then used by hubListener to transition from payment to registration

      // const user = {
      //   username: formObject.userId,
      //   password: formObject.password,
      //   email: formObject.email,
      //   address: { countryCode: 'DE' },
      //   preferredUsername: formObject.username,
      //   baseCurrency: formObject.baseCurrency,
      //   locale: i18n.language,
      //   provider: formObject.provider,
      //   tierId: formObject.tierId, // in "pay later" scenario there is no payment, so tier will be set now
      // };

      // when moving from external user to internal user following changes to formObject must happen:
      // - remove provider field
      // - add email and password fields

      // register action creator will check putEmailInCustomSignupAttribute attribute (set by registerFederated when creating the native user)
      // so the email will be in the email field

      const newFormObject = {
        ...savedFormObject,
        email: formData.email,
        password: formData.password,
      };
      delete newFormObject.provider;

      localStorage.setItem('signUpObject', JSON.stringify(newFormObject));

      console.info('SignUpFallback: formObject (after changes):', newFormObject);
      // we dispatch the action (we can't await it, it is not RTK)
      dispatch(register(newFormObject));
      redirect(`/${i18n.language}/confirm/${savedFormObject.username}`);
      // sometimes redirect just doesn't work, here is the fallback in the form of 'navigate'
      navigate(`/${i18n.language}/confirm/${savedFormObject.username}`);
    } catch (error) {
      console.error('SignUpFallback: error in onSubmit:', error);
      dispatch(setMessage('generalError'));
      setLoading(false);
    }
  }

  return (
    <div className="relative bg-white">
      <div className="mx-auto lg:grid lg:grid-cols-12 lg:gap-x-8 lg:px-8">
        {(fallbackMessage.includes('email')) ? (
          <form
            onSubmit={formObject.handleSubmit(onSubmit)}
            className="px-6 pb-24 pt-10 sm:pb-32 lg:col-span-7 lg:px-0 lg:pb-56 lg:pt-30 lg:pl-24 lg:min-h-screen xl:pt-40 xl:col-span-6"
          >
            <div className="mx-auto max-w-2xl lg:mx-0 mb-20">
              <h1 className="text-3xl font-black text-gray-900 sm:text-4xl">
                {t('header')}
              </h1>
              <p className="mt-6 text-lg leading-6 text-gray-600">{t('body1')}</p>
              <p className="mt-6 text-lg leading-6 text-gray-600">{t('body2')}</p>
            </div>
            <InternalAccountForm useFormObject={formObject} resetRightColumn={() => {}} emailUsernameValidation={checkIfUsernameCanBeUsed} />
            <Button
              formatting="mt-5"
              type="submit"
              size="lg"
              withAccent
              enabled={!loading}
              loading={loading}
              text={t('submit')}
            />
          </form>
        ) : (
          <>
            <div className="px-6 pb-24 pt-10 sm:pb-32 lg:col-span-7 lg:px-0 lg:pb-56 lg:pt-60 lg:pl-24 lg:min-h-screen xl:pt-80 xl:col-span-6">
              <div className="mx-auto max-w-2xl lg:mx-0">
                <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                  {t('unhandledError.header')}
                </h1>
                <p className="mt-6 text-lg leading-6 text-gray-600">{t('unhandledError.body1')}</p>
                <p className="mt-6 text-lg leading-6 text-gray-600">{t('unhandledError.body2')}</p>
                <p className="mt-6 text-lg leading-6 text-gray-600">{t('unhandledError.message')}</p>
                <p className="mt-6 text-lg font-mono leading-6 text-gray-600">{queryParams.get('fallbackMessage')}</p>
              </div>
            </div>
            <div className="relative lg:col-span-5 lg:-mr-8 xl:absolute xl:inset-0 xl:left-1/2 xl:mr-0">
              <img
                className="aspect-[3/2] w-full bg-gray-50 object-cover lg:absolute lg:inset-0 lg:aspect-auto lg:h-full"
                src="https://images.unsplash.com/photo-1552943272-0b58b4078e71?ixlib=rb-4.0.3&ixid=M3wzMDUyOTN8MHwxfGFsbHx8fHx8fHx8fDE3MDg2Mjk3Mjl8&auto=format&fit=crop&w=2102&q=80"
                alt=""
              />
            </div>
          </>
        )}
        <div className="relative lg:col-span-5 lg:-mr-8 xl:absolute xl:inset-0 xl:left-1/2 xl:mr-0">
          <img
            className="aspect-[3/2] w-full bg-gray-50 object-cover lg:absolute lg:inset-0 lg:aspect-auto lg:h-full"
            src="https://images.unsplash.com/photo-1552943272-0b58b4078e71?ixlib=rb-4.0.3&ixid=M3wzMDUyOTN8MHwxfGFsbHx8fHx8fHx8fDE3MDg2Mjk3Mjl8&auto=format&fit=crop&w=2102&q=80"
            alt=""
          />
        </div>
      </div>
    </div>
  );
}
