/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-no-bind */

import React, { useState, useRef, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import PivotTable from 'react-pivottable/PivotTable';
import { useSelector, useDispatch } from 'react-redux';
import FullPageOverlayContainer from './FullPageOverlayContainer';

export default function FullScreenReport({ reportProps }) {
  const dispatch = useDispatch();

  function handleClose(e) {
    e.preventDefault();
    dispatch({ type: 'CLEAR_FULLSCREEN_REPORT' });
  }

  const finalProps = {
    ...reportProps,
  };
  console.info('finalProps', finalProps);
  console.info('window', window.innerHeight, window.innerWidth);

  return (
    <FullPageOverlayContainer closeCallback={handleClose}>
      <div className="w-full h-[95vh] pt-8 grid grid-cols-1 justify-items-center">
        <PivotTable {...finalProps} />
      </div>
    </FullPageOverlayContainer>
  );
}
FullScreenReport.propTypes = {
  reportProps: PropTypes.objectOf(PropTypes.any).isRequired,
};
