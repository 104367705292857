/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { postAccount } from '../../redux/reducers/data';
import { setMessage } from '../../redux/actions/message';
import MiniSpinner from '../../misc/MiniSpinner';

export default function GrowthRate({ propAccount }) {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation(['app']);
  const dispatch = useDispatch();

  // need to get account information from redux store, as the prop is not updated in Tile when the account object changes in store
  const account = useSelector((state) => state.data[propAccount.category].accounts).filter((a) => a.id === propAccount.id)[0];
  const tags = typeof account.tags === 'string' ? JSON.parse(account.tags) : account.tags;

  const growthRate = tags?.growthRate;

  const {
    register,
    handleSubmit,
    formState: { touchedFields },
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      growthRate,
    },
  });

  async function onSubmit(data) {
    setLoading(true);
    const response = await dispatch(postAccount({ data: { ...account, importFlag: 'put', tags: { ...tags, ...data } }, category: account.category }));
    if (response.meta.requestStatus === 'fulfilled') {
      setLoading(false);
      dispatch(setMessage('accountUpdatedSuccessfully', account.id));
    } else {
      setLoading(false);
      dispatch(setMessage('accountCouldNotBeUpdated', account.id));
    }
  }

  return (
    <div className="mt-4 grid grid-cols-6 gap-6">
      <div className="col-span-2">
        <p className="mt-1 text-xs xs:text-sm text-gray-500">{t('app:accountDetails.settings.simulatedAccountGrowth.description')}</p>
      </div>
      <div className="col-span-4 sm:col-span-2 sm:max-w-sm">
        <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
          {t('app:accountDetails.settings.simulatedAccountGrowth.fieldLabel')}
        </label>
        <div className="flex items-baseline space-x-2">
          <input
            type="number"
            name="growthRate"
            id="growthRate"
            placeholder={1.25}
            step={0.01}
            // eslint-disable-next-line max-len
            className="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-gray-900 focus:outline-none focus:ring-gray-900 text-sm placeholder:text-gray-200 placeholder:italic"
            {...register('growthRate', {
              required: { value: true, message: 'Please specify a growth rate.' },
            })}
          />
          <span>%</span>
        </div>
        {touchedFields.growthRate && (
          <button
            type="button"
            onClick={handleSubmit(onSubmit)}
            // eslint-disable-next-line max-len
            className="mt-4 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-brandBlue-500 hover:bg-brandBlue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brandBlue-400"
          >
            {!loading ? t('app:accountDetails.buttons.save.label') : <MiniSpinner className="w-5 h-5 animate-spin" />}
          </button>
        )}
      </div>
    </div>
  );
}
GrowthRate.propTypes = {
  propAccount: PropTypes.object.isRequired,
};
