/* eslint-disable import/prefer-default-export */
import {
  UPDATE_CATEGORY_DATA,
  UPDATE_CATEGORY_DATA_ERROR,
  SET_CATEGORY_LOADING_STATE,
  CLEAR_CATEGORY_LOADING_STATE,
  UPDATE_ACCOUNT_DATA,
  SET_ACCOUNT_LOADING_STATE,
  CLEAR_ACCOUNT_LOADING_STATE,
  SET_ACCOUNT_FAILED_STATE,
  UPDATE_STOCKS_SETTINGS_SUCCESS,
  UPDATE_STOCKS_SETTINGS_FAIL,
  SET_APP_SIGNAL,
  CLEAR_APP_SIGNAL,
  SET_MESSAGE,
} from '../types';

import getQuotes from '../../reducers/sharedThunks/getQuotes';
// eslint-disable-next-line import/no-cycle
import * as helpers from './helpers';

import StocksService from '../../services/data.stocks.service';
import { globalBaselineView } from '../../reducers/globalSelectors';

// TODO: this is unprecise, we are getting quotes for all assetIds and dates since the oldest transaction
// that could be optimised to only get quotes for the assetIds and dates that are actually needed
// get the earliest possible purchase date for

export const getStocksSettings = () => (dispatch) => {
  dispatch({
    type: SET_CATEGORY_LOADING_STATE,
    payload: { category: 'stocks' },
  });

  StocksService.getSettings().then(
    (data) => {
      // we expect { setting1: 'Value', setting2: 'Value', ... }
      dispatch({
        type: UPDATE_STOCKS_SETTINGS_SUCCESS,
        // when set automatically via terraform this is an object already, when set manually it is a string
        payload: typeof data === 'string' ? JSON.parse(data) : data,
      });

      dispatch({
        type: CLEAR_CATEGORY_LOADING_STATE,
        payload: { category: 'stocks' },
      });

      return Promise.resolve();
    },
    (error) => {
      const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      console.error(message);

      dispatch({
        type: SET_MESSAGE,
        payload: 'dataUpdateError',
      });

      dispatch({
        type: CLEAR_CATEGORY_LOADING_STATE,
        payload: { category: 'stocks' },
      });

      return null;
    },
  );
};

export const getStocksData = () => (dispatch, getState) => {
  dispatch({
    type: SET_CATEGORY_LOADING_STATE,
    payload: { category: 'stocks' },
  });

  const state = getState();

  dispatch(getStocksSettings());

  StocksService.getData().then(
    (data) => {
      // returns { transactions: [], accounts: [], status: 'noerrors' }

      // get quotes for the retrieved transactions
      const { baseline, referenceBaseline } = globalBaselineView(state);
      // this could be a .then if we want to wait for the quotes to be retrieved before updating the store
      dispatch(
        getQuotes(
          helpers.calculateDatesAndAssetIds(
            data.transactions.filter((tx) => ['purchase', 'sale'].includes(tx.transactionType)),
            baseline,
            referenceBaseline,
          ),
        ),
      );

      dispatch({
        type: UPDATE_CATEGORY_DATA,
        payload: { stocks: { accounts: data.accounts }, status: data.status },
      });

      // upload non-simulated data to store
      dispatch({
        type: UPDATE_CATEGORY_DATA,
        payload: {
          stocks: {
            transactions: (data.transactions || []).filter((transaction) => !transaction.isSimulated), // if true, returns false; if null, undefined, false - returns true
          },
          status: 'noerrors',
        },
      });

      // upload simulated data to store
      dispatch({
        type: UPDATE_CATEGORY_DATA,
        payload: {
          stocks: {
            simulatedTransactions: (data.transactions || []).filter((transaction) => !!transaction.isSimulated),
          },
          status: 'noerrors',
        },
      });

      dispatch({
        type: CLEAR_CATEGORY_LOADING_STATE,
        payload: { category: 'stocks' },
      });

      return Promise.resolve();
    },
    (error) => {
      const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

      dispatch({
        type: SET_MESSAGE,
        payload: 'dataUpdateError',
      });

      dispatch({
        type: CLEAR_CATEGORY_LOADING_STATE,
        payload: { category: 'stocks' },
      });

      return Promise.reject();
    },
  );
};

export const getStocksDataByAccount = (accountId) => (dispatch) => {
  dispatch({
    type: SET_ACCOUNT_LOADING_STATE,
    payload: { category: 'stocks', accountId },
  });

  StocksService.getDataByAccount(accountId).then(
    (data) => {
      // returns { transactions: [], status: 'noerrors' }

      dispatch({
        type: UPDATE_ACCOUNT_DATA,
        caller: 'stocksPostData',
        payload: {
          stocks: { ...data },
        },
      });

      dispatch({
        type: CLEAR_ACCOUNT_LOADING_STATE,
        payload: { category: 'stocks', accountId },
      });

      return Promise.resolve();
    },
    (error) => {
      const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      console.error(message);

      dispatch({
        type: SET_MESSAGE,
        payload: 'dataUpdateError',
      });

      dispatch({
        type: CLEAR_ACCOUNT_LOADING_STATE,
        payload: { category: 'stocks', accountId },
      });

      return Promise.reject();
    },
  );
};

export function postStocksData(payload, accountId) {
  return async function postStocksDataThunk(dispatch) {
    dispatch({
      type: SET_ACCOUNT_LOADING_STATE,
      payload: { category: 'stocks', accountId },
    });

    try {
      const data = await StocksService.postData(payload);

      // upload non-simulated data to store
      dispatch({
        type: UPDATE_ACCOUNT_DATA,
        caller: 'stocksPostData', // only for logging purposes
        payload: {
          stocks: {
            transactions: (data.transactions || []).filter((transaction) => !transaction.isSimulated),
          },
          status: 'noerrors',
        },
      });

      // upload simulated data to store
      dispatch({
        type: UPDATE_ACCOUNT_DATA,
        payload: {
          stocks: {
            simulatedTransactions: (data.transactions || []).filter((transaction) => !!transaction.isSimulated),
          },
          status: 'noerrors',
        },
      });

      if (data.status === 'noerrors') {
        dispatch({
          type: SET_MESSAGE,
          payload: 'dataUpdatedSuccessfully',
        });
      } else {
        dispatch({
          type: SET_MESSAGE,
          payload: 'dataUpdatedWithErrors',
        });
      }

      dispatch({
        type: CLEAR_ACCOUNT_LOADING_STATE,
        payload: { category: 'stocks', accountId },
      });

      return { status: 'success', data };
    } catch (error) {
      const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      console.error(message);

      dispatch({
        type: SET_MESSAGE,
        payload: 'dataUpdateProblems',
      });

      dispatch({
        type: SET_ACCOUNT_FAILED_STATE,
        payload: { category: 'stocks', accountId },
      });

      return { status: 'error', error };
    }
  };
}

export const postStocksAccount = (payload) => (dispatch) => {
  // go post data
  StocksService.postAccount(payload).then(
    // returns { status: 'noerrors', accounts: [{account-object}] }
    (data) => {
      dispatch({
        type: UPDATE_CATEGORY_DATA,
        callerId: 'stocksPostAccount', // only for logging purposes
        payload: {
          status: data.status,
          stocks: {
            accounts: data.accounts,
          },
        },
      });

      dispatch({
        type: SET_MESSAGE,
        payload: 'accountCreatedSuccessfully',
      });

      return Promise.resolve();
    },
    (error) => {
      const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      console.error(message);

      dispatch({
        type: UPDATE_CATEGORY_DATA_ERROR,
        callerId: 'stocksPostAccount', // for logging purposes only
      });

      dispatch({
        type: SET_MESSAGE,
        payload: 'accountCouldNotBeCreated',
      });

      return Promise.reject();
    },
  );
};

export const postStocksSettings = (payload) => (dispatch) => {
  // payload is an object with attributes of state.data.stocks.settings in it, like { growthRate: 0.1, dividendYieldRates: [ ACN: 1.11, GM: 2.22, ...], ...}
  // the attributes will overwrite the same attributes in store
  StocksService.postSettings(payload).then(
    // returns { status: 'noerrors', settings: {settings-object} }
    (data) => {
      dispatch({
        type: UPDATE_STOCKS_SETTINGS_SUCCESS,
        payload: data,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: 'accountUpdatedSuccessfully',
      });

      return Promise.resolve();
    },
    (error) => {
      const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      console.error(message);

      dispatch({
        type: SET_MESSAGE,
        payload: 'accountCouldNotBeUpdated',
      });

      return Promise.reject();
    },
  );
};

export const putStocksSettings = (payload) => (dispatch) => {
  // payload is an object with attributes of state.data.stocks.settings in it, like { growthRate: 0.1, dividendYieldRates: [ ACN: 1.11, GM: 2.22, ...], ...}
  // the attributes will overwrite the same attributes in store
  StocksService.putSettings(payload).then(
    // returns { status: 'noerrors', settings: {settings-object} }
    (data) => {
      dispatch({
        type: UPDATE_STOCKS_SETTINGS_SUCCESS,
        payload: data,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: 'accountUpdatedSuccessfully',
      });

      return Promise.resolve();
    },
    (error) => {
      const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      console.error(message);

      dispatch({
        type: SET_MESSAGE,
        payload: 'accountCouldNotBeUpdated',
      });

      return Promise.reject();
    },
  );
};

export const putStocksAccount = (payload) => (dispatch) => {
  StocksService.putAccount(payload).then(
    // returns { status: 'noerrors', accounts: [{account-object}] }
    (data) => {
      dispatch({
        type: UPDATE_CATEGORY_DATA,
        caller: 'stocksPutAccount', // only for logging purposes
        payload: {
          status: data.status,
          stocks: {
            accounts: data.accounts,
          },
        },
      });

      dispatch({
        type: SET_MESSAGE,
        payload: 'accountUpdatedSuccessfully',
      });

      return Promise.resolve();
    },
    (error) => {
      const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      console.error(message);

      dispatch({
        type: UPDATE_CATEGORY_DATA_ERROR,
        caller: 'stocksPutAccount', // for logging purposes only
      });

      dispatch({
        type: SET_MESSAGE,
        payload: 'accountCouldNotBeUpdated',
      });

      return Promise.reject();
    },
  );
};

export const deleteStocksAccount = (payload) => (dispatch) => {
  StocksService.deleteAccount(payload).then(
    // returns { status: 'noerrors', accounts: [{account-object}], transactions: ... }
    (data) => {
      dispatch({
        type: UPDATE_CATEGORY_DATA,
        caller: 'stocksDeleteAccountAccounts', // only for logging purposes
        payload: {
          status: data.status,
          stocks: {
            accounts: data.accounts,
          },
        },
      });

      dispatch({
        type: UPDATE_CATEGORY_DATA,
        caller: 'stocksDeleteAccountTransactions', // only for logging purposes
        payload: {
          status: data.status,
          stocks: {
            transactions: data.transactions,
          },
        },
      });

      dispatch({
        type: SET_MESSAGE,
        payload: 'accountDeletedSuccessfully',
      });

      return Promise.resolve();
    },
    (error) => {
      const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      console.error(message);

      dispatch({
        type: UPDATE_CATEGORY_DATA_ERROR,
        caller: 'stocksDeleteAccount', // for logging purposes only
      });

      dispatch({
        type: SET_MESSAGE,
        payload: 'accountCouldNotBeUpdated',
      });

      return Promise.reject();
    },
  );
};
