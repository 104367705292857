const debugLevel = process.env.MDEBUG || 3;

export function calculateCategoryAllocation(category, currentAllocation, targetAllocation, currencies) {
  const currentCategoryAllocation = currentAllocation.filter((e) => e.category === category);
  const currentCategoryTargetAllocation = targetAllocation.filter((e) => e.category === category && !e.assetClass && e.currency); // category-level allocation (have currency, but no asset class)

  if (debugLevel > 2) console.info('calculateDimensionAllocation: received these current holdings for category', category, JSON.stringify(currentCategoryAllocation, null, 2));
  if (debugLevel > 2) console.info('calculateDimensionAllocation: received this category-level target allocations', JSON.stringify(currentCategoryTargetAllocation, null, 2));

  if (currentCategoryTargetAllocation.length === 0 || currentCategoryTargetAllocation.every((e) => e.value === null || e.value === undefined)) {
    if (debugLevel > 2) console.info('calculateDimensionAllocation: there are no target allocations for category', category, 'returning null value for each currency');
    return currencies.map((c) => ({
      category, currency: c, assetClass: null, value: null,
    }));
  } // return null value for each category

  const sumOfAllocation = currentCategoryTargetAllocation.reduce((sum, e) => sum + Number(e.value), 0);
  const remainingAllocation = 100 - sumOfAllocation;
  if (debugLevel > 2) console.info('calculateDimensionAllocation: this category has allocation of', sumOfAllocation, ', so remainingAllocation is ', remainingAllocation);

  if (currentCategoryTargetAllocation.lentgh === currencies.length && remainingAllocation === 0) return currentCategoryTargetAllocation; // there is nothing to do, there is one allocation per each category and allocation is complete

  const lockedCategoriesAllocation = currentCategoryTargetAllocation.filter((e) => e.locked).reduce((acc, e) => acc + Number(e.value), 0);
  if (debugLevel > 2) console.info('calculateDimensionAllocation: locked categories allocation is', lockedCategoriesAllocation);

  // handle the case where all categories are there, but there is not 100% allocation
  if (currentCategoryTargetAllocation.length === currencies.length && remainingAllocation !== 0) {
    if (debugLevel > 2) console.info('calculateDimensionAllocation: all currencies for this category are present in targetAllocation, but do not sum up to 100', JSON.stringify(currentCategoryTargetAllocation, null, 2));
    const multiplier = (100 - lockedCategoriesAllocation) / (sumOfAllocation - lockedCategoriesAllocation); // if there are locked items (that cannot be changed), we need to adjust both sides of the division (instead of 10 + 20 (locked) + 30 --> 100,  we are only doing 10 + 30 --> 80)
    return currentCategoryTargetAllocation.map((e) => ((e.locked) ? e : {
      ...e,
      assetClass: null, // this is category-level, so assetClass is to be returned null
      value: Math.round(e.value * multiplier),
    }));
  }

  // which currencies are missing in categoryLevelAllocation?
  const missingCurrencies = currencies.filter(
    (c) => !currentCategoryTargetAllocation.find((e) => e.currency === c),
  );
  if (debugLevel > 2) console.info('calculateDimensionAllocation: following currencies do not have target allocation in', category, ':', missingCurrencies);

  const categoryFactor = (missingCurrencies.length === 0) ? 0 : remainingAllocation / missingCurrencies.length;
  if (debugLevel > 2) console.info('calculateDimensionAllocation: remaining allocation is', remainingAllocation, 'so each new target row for each of the missing currencies has a value of', categoryFactor);

  return [
    ...currentCategoryTargetAllocation,
    ...missingCurrencies.map((c) => ({
      category,
      assetClass: null,
      currency: c,
      value: Math.round(categoryFactor),
    })),
  ];
}

export function calculateAssetClassAllocation(category, assetClass, currentAllocation, targetAllocation, currencies) {
  const currentAssetClassTargetAllocation = targetAllocation.filter((e) => e.category === category && e.assetClass === assetClass);

  const parentAllocation = targetAllocation.filter((e) => e.category === category && !e.assetClass);

  const parentIsEmpty = parentAllocation.every((e) => e.value === null || e.value === undefined); // parent is empty when all its members have null value

  // if there are no allocations, copy those of the parent (category) or return null value for each currency if there is no parent allocation
  if (currentAssetClassTargetAllocation.length === 0 || currentAssetClassTargetAllocation.every((e) => e.value === null)) {
    if (!parentIsEmpty) { // if there is parentAllocation (it should be complete, as calculateCategoryAllocation was called before)
      return parentAllocation.map((e) => ({
        ...e, assetClass,
      }));
    }
    return currencies.map((c) => ({ // return null value for each category
      category, currency: c, assetClass, value: null,
    }));
  }

  const sumOfAllocation = currentAssetClassTargetAllocation.reduce((sum, e) => sum + Number(e.value), 0);
  const remainingAllocation = 100 - sumOfAllocation;

  // if we have all the allocations (i.e. there is one allocation for this asset class for each currency and they sum up to 100), return them as-is
  if (currentAssetClassTargetAllocation.lentgh === currencies.length && remainingAllocation === 0) return currentAssetClassTargetAllocation; // there is nothing to do, there is one allocation per each category and allocation is complete

  const lockedAssetClassesAllocation = currentAssetClassTargetAllocation.filter((e) => e.locked).reduce((acc, e) => acc + Number(e.value), 0);
  if (debugLevel > 2) console.info('calculateDimensionAllocation: locked categories allocation is', lockedAssetClassesAllocation);

  // if we have all the allocations, but they do not sum up to 100, adjust them to sum up to 100
  if (currentAssetClassTargetAllocation.length === currencies.length && remainingAllocation !== 0) {
    if (debugLevel > 2) console.info('calculateDimensionAllocation: all currencies for this category are present in targetAllocation, but do not sum up to 100', JSON.stringify(currentAssetClassTargetAllocation, null, 2));
    const multiplier = (100 - lockedAssetClassesAllocation) / (sumOfAllocation - lockedAssetClassesAllocation); // see comment in the categories function above
    return currentAssetClassTargetAllocation.map((e) => ((e.locked) ? e : ({
      ...e, value: e.value * multiplier,
    })));
  }

  // which currencies are missing in categoryLevelAllocation?
  const missingCurrencies = currencies.filter(
    (c) => !currentAssetClassTargetAllocation.find((e) => e.currency === c),
  );
  if (debugLevel > 2) console.info('calculateDimensionAllocation: following currencies do not have target allocation in', category, ':', missingCurrencies);

  if (!parentIsEmpty) {
    // apply allocation to missing currencies in the same proportion as the parent allocation
    const parentAllocationForMissingCurrencies = parentAllocation.filter((e) => missingCurrencies.includes(e.currency));
    if (debugLevel > 2) console.info('calculateDimensionAllocation: parent allocation for the missing currencies are', JSON.stringify(parentAllocationForMissingCurrencies, null, 2));
    const sumParentAllocationForMissingCurrencies = parentAllocationForMissingCurrencies.reduce((sum, e) => sum + Number(e.value), 0);
    if (debugLevel > 2) console.info('calculateDimensionAllocation: sum of parent allocation for the missing currencies is', sumParentAllocationForMissingCurrencies);

    return [
      ...currentAssetClassTargetAllocation,
      ...missingCurrencies.map((c) => ({
        category,
        assetClass,
        currency: c,
        value: remainingAllocation * (parentAllocationForMissingCurrencies.find((a) => a.currency === c).value / sumParentAllocationForMissingCurrencies),
      })),
    ];
  }
  // else apply allocation to missing currencies in equal proportions
  const categoryFactor = remainingAllocation / missingCurrencies.length;

  return [
    ...currentAssetClassTargetAllocation,
    ...missingCurrencies.map((c) => ({
      category,
      assetClass,
      currency: c,
      value: categoryFactor,
    })),
  ];
}

export default function calculateDimensionAllocation(targetAllocationFiltered, currentAllocationFiltered) {
  // calculate unique values in the asset hierarchy and in categories
  const categories = [...new Set(currentAllocationFiltered.map((entry) => entry.category))];

  const currencies = [...new Set(currentAllocationFiltered.map((entry) => entry.currency))];

  // complete target allocation table based on user input (targetAllocation)
  const completeTargetAllocation = [];
  categories.forEach((category) => {
    completeTargetAllocation.push(...calculateCategoryAllocation(category, currentAllocationFiltered, targetAllocationFiltered, currencies));
    // important: if all values in categoryLevelAllocation are null, we didn't manage to calculate the allocation on the category level

    const assetClasses = [...new Set(currentAllocationFiltered.filter((entry) => entry.category === category).map((entry) => entry.assetClass))];
    if (debugLevel > 2) console.info('calculateDimensionAllocation: in category', category, 'there are following asset classes:', assetClasses);
    if (assetClasses.length > 0 && assetClasses[0] !== undefined) {
      assetClasses.forEach((assetClass) => {
        completeTargetAllocation.push(...calculateAssetClassAllocation(category, assetClass, currentAllocationFiltered, targetAllocationFiltered, currencies));
        // important: if all values in categoryLevelAllocation are null, we didn't manage to calculate the allocation on the category level
      });
    }
  });

  if (debugLevel > 2) console.info('calculateDimensionFactors: completeTargetAllocation', JSON.stringify(completeTargetAllocation, null, 2));
  return completeTargetAllocation; // at this stage completeTargetAllocation has rows for all categories, assetClasses and currencies; some of them may have null values;
}
