/* eslint-disable react/forbid-prop-types */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Auth, API } from 'aws-amplify';
import { useTranslation } from 'react-i18next';
import { saveObjectToLocalStorage } from '../../../misc/localStorageOps';
import MiniSpinner from '../../../misc/MiniSpinner';
import { setMessage, setAlert } from '../../../redux/actions/message';
import { globalAccountsView } from '../../../redux/reducers/data';

async function prepCallToApi(body) {
  const session = await Auth.currentSession();
  const myInit = {
    body,
    headers: {
      'Content-Type': 'application/json',
      Authorization: session.idToken.jwtToken,
    },
  };
  return myInit;
}

export default function RedirectToProvider({ userInput, setUserInput, index, setDisplayedStep, category, workflowStepStatus, updateWorkflowStatus }) {
  const { i18n } = useTranslation(['app']);
  const dispatch = useDispatch();
  const globalAccounts = useSelector(globalAccountsView);

  // ----------------------
  // INITIALISE COMPONENT
  // ----------------------

  // when initialised, show spinner and get the provider URL from the backend
  // in parallel get state, zip it and put in localStorage; add random password
  // then redirect to that URL
  useEffect(() => {
    // let abortToken = false;
    // if the user pressed back from step 4, do nothing and let the user go to step 2; if there are changes in step 2, they should reset workflowStepStatuts for steps 3 and 4
    if (workflowStepStatus[index]) {
      setDisplayedStep(index - 1);
      return () => {};
    }

    // get the provider URL from the backend
    let apiPromise;
    async function runGetWebForm() {
      const requestPayload = await prepCallToApi({
        country: userInput.countryCode,
        userLanguage: i18n.language,
        institutionId: userInput.provider.providerInstitutionId,
        redirectUrl: `${window.location.origin}/addAccountCallback`,
        abortUrl: `${window.location.origin}/${i18n.language}/app/dashboard`,
        category,
      });
      const addAccountState = {
        category,
        userInput,
        workflowStepStatus,
        displayedStep: index,
      };
      const providerId = userInput.countryCode === 'DE' ? 'finapi' : 'gocardless';

      try {
        const response = await API.post('myAPI', 'deposits/webform', requestPayload);
        console.log('response', response);
        // returns { url, processId } or { url: null, processId: null, bankConnectionId, accounts } in the case handled just below
        // webform will return an url and a processId

        // ----------------------------------
        // PREPARE DATA FOR SUBSEQUENT STEPS
        // ----------------------------------

        // generate a list of existing accounts per provider, which will be used in AccountData to filter out already existing accounts
        const existingAccounts = globalAccounts
          // leave only those which have the providerId for the country that user is adding an account for and the providerInstitutionId that the user is adding
          .filter((a) => a.category === category && String(a.tags?.provider?.[providerId].providerInstitutionId) === String(userInput.provider.providerInstitutionId));

        // ----------------------------------
        // HANDLE CASE: WEBFORM NEEDED
        // (getWebForm returned an url)
        // ----------------------------------

        // if deposits/webform sent back a url, it means the user needs to go through a webform
        // prepare the state to be saved in localStorage and send the user to the webform
        if (response.url) {
          // only add existingAccounts when there are any
          if (existingAccounts.length > 0) addAccountState.userInput.existingAccounts = existingAccounts;
          console.log('existingAccounts', existingAccounts);

          addAccountState.userInput.provider.processId = response.processId;

          // if this is a GC account, once we process the new webform, all the accounts will have new providerAccountIds and there will be a new providerAccessId (requisitionId)
          // so we need to let the process know that those things need to be replaced in existing accounts once the new values are known
          if (providerId === 'gocardless') {
            addAccountState.userInput.reconcileAccounts = true;
          }

          // get WebForm from API & zip addAccountState
          await saveObjectToLocalStorage(addAccountState, 'addAccountState');

          window.dispatchEvent(
            new CustomEvent('setAlert', {
              detail: {
                id: 'infoRedirectToProvider',
                caller: 'RedirectToProvider',
                callbackOk: () => {
                  console.log('RedirectToProvider - Alert callback: redirecting to:', response.url);
                  window.location.href = response.url;
                },
                callbackCancel: () => {
                  console.log('DEBUG executing callbackCancel');
                  setDisplayedStep(index - 1);
                  // TODO do we need to setWorkflowStatus as well?
                },
              },
            }),
          );
        } else {
          // ----------------------------------
          // HANDLE CASE: WEBFORM NOT NEEDED
          // (getWebForm returned no url)
          // ----------------------------------

          // this can happen only for FinAPI, because GoCardless always requires a new webform
          // if there is NO url, it means the user has an existing connection with the provider which can be used to find / add any new accounts
          // in which case we need to update userInput and proceed directly to the next workflow step
          // we will also receive all (including new) existing accounts from the backend, which we will need to pass via userInput to AccountData, so that it doesn't have to get that later again
          setUserInput((prev) => ({
            ...prev,
            provider: {
              ...prev.provider,
              processId: response.processId,
            },
            providerAccessId: response.bankConnectionId, // will be provided in this case by backend (we know bankConnectionId, because it does not change due to a webform)
            ...(existingAccounts.length > 0 && { existingAccounts }),
            ...(response.accounts && response.accounts.length > 0 && { retrievedProviderAccounts: response.accounts }), // we will receive all accounts, including new ones
            // we do not have to add reconcileAccounts, because it is a GC process and this only handles FinAPI
          }));

          // go to next step
          updateWorkflowStatus(index, true);
          setDisplayedStep(index + 1);
        }
      } catch (error) {
        console.error('Error fetching WebForm url:', error);
        // go back to previous workflow step by setting its workflows status to false
        if (error.message !== 'Abort for runGetWebForm has been issued') {
          dispatch(setMessage('generalError'));
          setDisplayedStep(index - 1);
        }
      }
    }

    if (userInput.syncType === 'automatic') runGetWebForm();
    else {
      // simply go to next step if this is a manual account
      updateWorkflowStatus(index, true);
      setDisplayedStep(index + 1);
    }

    return function cleanupGetWebForm() {
      API.cancel(apiPromise, 'Abort for runGetWebForm has been issued');
    };
  }, []);

  return (
    <div className="space-y-6 md:space-y-0 md:grid md:grid-cols-4 md:gap-6">
      <div className="absolute inset-0 flex items-center justify-center">
        <MiniSpinner className="w-10 h-10 text-gray-400 animate-spin" />
      </div>
    </div>
  );
}
RedirectToProvider.propTypes = {
  userInput: PropTypes.objectOf(PropTypes.any).isRequired,
  setUserInput: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  setDisplayedStep: PropTypes.func.isRequired,
  category: PropTypes.string.isRequired,
  workflowStepStatus: PropTypes.arrayOf(PropTypes.bool).isRequired,
  updateWorkflowStatus: PropTypes.func.isRequired,
};
