/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { objectToArray } from '../../misc/arrayHelpers';
import { globalQuotesView, globalTransactionViewPerCategory } from '../../redux/reducers/data';
import CategoryWrapperQuotes from './CategoryWrapperQuotes';
import { compare } from './params/common';

const utc = require('dayjs/plugin/utc');

dayjs.extend(utc);

const debugLevel = process.env.MDEBUG || 3;

/**
 * This is a HOC which does not add anything to the UI. Its purpose is to prepare QUOTES data which are to be shown in the table.
 */
export default function QuotesWrapper({
  displayedComponent,
  setDisplayedComponent,
  account,
  setAccount,
  tableState,
  setTableState,
  displayedComponentMode,
  setDisplayedComponentMode,
  userChangesPresent,
  setUserChangesPresent,
}) {
  const selectQuotes = useSelector(globalQuotesView);
  const dashboardMode = useSelector((state) => state.simulation.dashboardMode);
  const selectTransactions = useSelector((state) => globalTransactionViewPerCategory(state, account.category).filter((transaction) => transaction.accountId === account.id));

  // get only quotes for the assetIds which are in the account
  const assetIds = selectTransactions.reduce((prev, curr) => {
    if (!prev.assetId) prev.push(curr.assetId);
    return prev;
  }, []);

  if (debugLevel > 2) console.log('QuotesWrapper > selector input', selectQuotes, 'assetIds', assetIds);

  const quotesForAccountAssetIds = Object.entries(selectQuotes).reduce((prev, [assetId, assetObj]) => (assetIds.includes(assetId) ? { ...prev, [assetId]: assetObj } : prev), {});

  if (debugLevel > 2) console.log('QuotesWrapper > quotesForAccountAssetIds', quotesForAccountAssetIds);

  // produce a flat array of all quotes for this account's assets;
  const filteredQuotes = objectToArray(quotesForAccountAssetIds, ['assetId', 'date']).filter(
    (quote) => ['manual', 'simulated'].includes(quote.source)
      && (!!quote.quote || !!quote.specialQuotes) // quote must exist (it makes no sense to display it when it is null or 0)
      && Number(quote.date) === Number(quote.quoteDate),
  );
  // removed by PRD-1215: in "dashboard" show only past manual quotes; in "projects" show all manual quotes
  // && (dashboardMode === 'projects' ? true : Number(quote.date) === Number(quote.quoteDate))); // removed by PRD-1215

  // we are expecting to receive all manual quotes on top of the requested dates for all submitted assetIds
  console.log('DEBUG', filteredQuotes, tableState);
  const quotes = filteredQuotes
    .filter((quote) => (dashboardMode === 'projects' ? true : quote.date <= dayjs.utc().valueOf()) && !['current'].includes(quote.date)) // filter out quotes from the future
    // FIX 240517 sorting will happen in TableLayout / GridLayout, as there can be fields added between here and those components
    // FIX 240517 those fields (column headers) when clicked are then not available here to change the sorting order
    // FIX 240517 .sort((a, b) => compare(a, b, tableState.sortBy, tableState.sortDirectionAsc)) // final sort as per tableState
    // convert date if this is a grid; replace quoteDate with date to show the user the date they actually entered
    .map((quote) => ({ ...quote, date: displayedComponent === 'table' ? Number(quote.date) : dayjs.utc(Number(quote.date)).format() }));

  if (debugLevel > 2) console.log('QuotesWrapper > quotesForAccountAssetIds', quotesForAccountAssetIds);
  if (debugLevel > 2) console.log('QuotesWrapper > filteredQuotes', filteredQuotes);
  if (debugLevel > 2) console.log('QuotesWrapper > quotes', quotes);

  const commonProps = {
    account,
    setAccount,
    quotes,
    tableState,
    setTableState,
    setDisplayedComponent,
    displayedComponentMode,
    setDisplayedComponentMode,
    userChangesPresent,
    setUserChangesPresent,
  };

  if (displayedComponent === 'table') {
    return <CategoryWrapperQuotes {...commonProps} displayedComponent="table" />;
  }
  if (displayedComponent === 'grid') {
    return <CategoryWrapperQuotes {...commonProps} displayedComponent="grid" />;
  }
  return null;
}
QuotesWrapper.propTypes = {
  displayedComponent: PropTypes.string.isRequired,
  setDisplayedComponent: PropTypes.func.isRequired,
  account: PropTypes.object.isRequired,
  setAccount: PropTypes.func.isRequired,
  tableState: PropTypes.object.isRequired,
  setTableState: PropTypes.func.isRequired,
  displayedComponentMode: PropTypes.string.isRequired,
  setDisplayedComponentMode: PropTypes.func.isRequired,
  userChangesPresent: PropTypes.bool.isRequired,
  setUserChangesPresent: PropTypes.func.isRequired,
};
