/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { XMarkIcon } from '@heroicons/react/24/solid';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import MiniSpinner from '../../misc/MiniSpinner';
import { putProject } from '../../redux/actions/projects';
import { putOrDeleteQuotes } from '../../redux/reducers/quotes';

dayjs.extend(utc);

export default function CollisionsDialog({ collisions, setCollisionDialog, project }) {
  const { t } = useTranslation('app', { keyPrefix: 'projects' });
  const dispatch = useDispatch();

  console.info('collisions', collisions);

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const readyToSubmit = collisions.every((c) => c.selected !== null);

  // receives the index of row to be updated
  function updateSelected(e, idx, updateIsolated) {
    // get collisions array, make a copy of it and update selected property of the row with index idx to true
    const updatedCollisions = [...collisions];
    updatedCollisions[idx].selected = updateIsolated ? 'isolated' : 'global';
    setCollisionDialog(updatedCollisions);
  }

  async function handleSubmit(e) {
    setErrorMessage(null);
    if (!readyToSubmit) {
      setErrorMessage(t('allCollisionsMustBeResolved'));
    } else {
      setLoading(true);
      // changing the project status first, as putQuotes will automatically remove all existing quotes which belong to the global scope if there is a new one for the same date-assetId combination
      await dispatch(putProject({ ...project, settings: { ...project.settings, isIsolated: false } }));
      const quotesToChange = collisions.map((item) => (item.selected === 'isolated' ? item.isolatedQuote : item.globalQuote));
      await dispatch(putOrDeleteQuotes({ arrayOfQuotes: quotesToChange }));
      setLoading(false);
      setCollisionDialog(null);
    }
  }

  return (
    <div className="absolute bg-opacity-90 bg-gray-300 z-[100] p-4 h-full w-full top-0 left-0">
      <div className="p-4 flex flex-col items-center justify-center h-full">
        <div className="bg-white rounded-md shadow-lg p-8 space-y-8 relative">
          <div>
            <h2 className="inline text-2xl font-bold text-gray-900">{t('collisions')}</h2>
            <p className="text-gray-500 prose mt-2 leading-snug">{t('collisionsDescription')}</p>
          </div>
          <div className="grid grid-cols-4 gap-4 text-left items-center">
            <div className="text-xs uppercase font-bold">{t('date')}</div>
            <div className="text-xs uppercase font-bold">{t('asset')}</div>
            <div className="text-xs uppercase font-bold">{t('thisProjectQuote')}</div>
            <div className="text-xs uppercase font-bold">{t('globalQuote')}</div>
            {collisions.map((c, idx) => (
              <>
                <div>{dayjs.utc(c.isolatedQuote.date).format('DD.MM.YYYY')}</div>
                <div>{c.isolatedQuote.assetId}</div>
                <button
                  type="button"
                  onClick={(e) => updateSelected(e, idx, true)}
                  className={`border border-gray-300 ${c.selected === 'isolated' ? 'bg-gray-100 font-bold' : 'bg-white hover:bg-gray-50'} hover:bg-gray-50 rounded-md px-3 py-2`}
                >
                  {c.isolatedQuote.quote.toLocaleString('de', { style: 'currency', currency: c.isolatedQuote.currency })}
                </button>
                <button
                  type="button"
                  onClick={(e) => updateSelected(e, idx, false)}
                  className={`border border-gray-300 ${c.selected === 'global' ? 'bg-gray-100 font-bold' : 'bg-white hover:bg-gray-50'} hover:bg-gray-50 rounded-md px-3 py-2`}
                >
                  {c.globalQuote.quote.toLocaleString('de', { style: 'currency', currency: c.globalQuote.currency })}
                </button>
              </>
            ))}
          </div>
          <div>
            {errorMessage && (<p className="text-red-500 my-2">{errorMessage}</p>)}
            <div className="flex justify-start items-center gap-4">
              <button
                id="project-resolve-colisions-submit"
                data-testid="project-resolve-colisions-submit"
                type="button"
                onClick={handleSubmit}
                className="inline-flex items-center rounded-md border border-transparent bg-brandBlue-500 hover:bg-brandBlue-600 px-4 py-2 text-sm font-medium leading-4 text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-brandBlue-400 focus:ring-offset-2"
              >
                {(loading === false)
                  ? t('save')
                  : (
                    <div id="project-add-quote-spinner" className="px-2">
                      <MiniSpinner className="w-4 h-4 text-white animate-spin" />
                    </div>
                  )}
              </button>
              <button
                id="project-add-quote-cancel"
                type="button"
                disabled={loading || !readyToSubmit}
                onClick={(e) => {
                  setCollisionDialog(null);
                }}
                className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-brandBlue-400 focus:ring-offset-2"
              >
                {t('cancel')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
CollisionsDialog.propTypes = {
  collisions: PropTypes.array.isRequired,
  setCollisionDialog: PropTypes.func.isRequired,
  project: PropTypes.object.isRequired,
};
