/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { API, Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
import { nanoid } from 'nanoid';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { setMessage } from '../redux/actions/message';

dayjs.extend(utc);

function QuestionComponent({ fieldName, control }) {
  const { t } = useTranslation('site', { keyPrefix: 'requestDemo' });

  return (
    <Controller
      name={fieldName}
      control={control}
      defaultValue=""
      render={({ field }) => (
        <div className="w-full p-4 border border-gray-300 rounded-md shadow-sm">
          <div className="flex flex-col justify-start">
            <p className="text-center">{t(`${fieldName}.label`)}</p>
            {t(`${fieldName}.sublabel`) !== `requestDemo.${fieldName}.sublabel` && <p className="text-center text-sm text-gray-500 italic mt-2">{t(`${fieldName}.sublabel`)}</p>}
            <div className="mt-4 flex flex-row gap-2 flex-wrap justify-center">
              {(t(`${fieldName}.options`, { returnObjects: true }) || []).map((option) => (
                <button
                  key={option}
                  type="button"
                  // eslint-disable-next-line max-len
                  className={`rounded ${field.value === option ? 'bg-brandBlue-100' : 'bg-white hover:bg-gray-50'} px-2 py-1 text-sm font-normal text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 `}
                  onClick={() => {
                    // setSelectedOption(option);
                    field.onChange(option);
                  }}
                >
                  {option}
                </button>
              ))}

            </div>
          </div>
        </div>
      )}
    />
  );
}
QuestionComponent.propTypes = {
  fieldName: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  control: PropTypes.object.isRequired,
};

export default function RequestDemo() {
  const { t, i18n } = useTranslation('site', { keyPrefix: 'requestDemo' });
  const navigate = useNavigate();
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // this is the equivalent of switching off the "remember me" option, which would save stuff in local storage instead
    Auth.configure({
      storage: sessionStorage,
    });
  }, []);

  function checkNanoid(value) {
    const combinedRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[^A-Za-z0-9])(?=.*[0-9]).*$/;

    // Return true if the expression matches the combined regular expression, otherwise return false
    return combinedRegex.test(value);
  }

  async function onSubmit(formData) {
    setIsLoading(true);

    // sign user out (in case signed)
    await Auth.signOut();

    // generate nanoid
    let username;
    do {
      username = nanoid();
    } while (!checkNanoid(username));

    // register demo account
    let response;
    try {
      response = await API.post('myAPI', 'customer/demo', {
        body: {
          userId: username,
          surveyData: {
            timestamp: dayjs.utc().valueOf(),
            ...formData,
          },
        },
      });

      if (response) {
        await Auth.signIn(username, username);
        navigate(`/${i18n.language}/app/getready`);
      }
    } catch (error) {
      dispatch(setMessage('accountCouldNotBeCreated'));
    }
  }

  if (isLoading) {
    return (
      <div className="w-full h-[85vh] relative max-w-7xl mx-auto">
        <div className="w-full h-full flex justify-center items-center text-2xl animate-pulse text-gray-500 font-bold">
          {t('pleaseWaitWhileWeAreSettingThingsUp')}
        </div>
      </div>
    );
  }

  return (
    <div className="relative max-w-7xl mx-auto">
      <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
        {/* HEADER */}
        <div className="text-center">
          <h2 className="text-base font-semibold text-brandBlue-600 tracking-wide uppercase">{t('topheader')}</h2>
          <p className="mt-1 text-4xl font-black text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">{t('Ready to set up your demo')}</p>
          <p className="max-w-xl mt-5 mx-auto text-xl text-gray-500">{t('header1')}</p>
          <p className="max-w-xl mx-auto text-xl text-gray-500">{t('header2')}</p>
        </div>
        {/* QUESTIONS */}
        <div className="mt-12 grid md:grid-cols-2 gap-4 justify-items-center">
          <QuestionComponent fieldName="numberOfAccounts" control={control} />
          <QuestionComponent fieldName="incomeSaved" control={control} />
          <QuestionComponent fieldName="age" control={control} />
          <QuestionComponent fieldName="workplace" control={control} />
          {/* howCanWeHelp */}
          <div className="w-full p-4 border border-gray-300 rounded-md shadow-sm md:col-span-2">
            <div className="flex flex-col justify-start">
              <p className="text-center">{t('howCanWeHelp.label')}</p>
              <input
                type="text"
                autoComplete="off"
                className="mt-4 rounded-md border border-gray-300 shadow-sm focus:ring-brandBlue-500 focus:border-brandBlue-500 block w-full h-24 md:h-12 sm:text-sm"
                {...register('howCanWeHelp')}
              />
            </div>
          </div>
        </div>
        {/* BUTTONS */}
        <div className="mt-8 flex space-x-4 justify-end">
          <button
            type="button"
            className="rounded-md bg-white px-3.5 py-2.5 text-sm font-normal text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          >
            {t('Cancel')}
          </button>
          <button
            type="button"
            onClick={handleSubmit(onSubmit)}
            // eslint-disable-next-line max-len
            className="rounded-md bg-brandBlue-500 px-3.5 py-2.5 text-sm font-normal text-white shadow-sm hover:bg-brandBlue-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brandBlue-500"
          >
            {t('Start demo')}
          </button>
        </div>
      </div>
    </div>
  );
}
