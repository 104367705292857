/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { BanknotesIcon } from '@heroicons/react/24/outline';
import SumWithLabel from './SumWithLabel';
import * as selectors from '../../redux/reducers/data';
import { CHANGE_IN_VALUE } from './constants';

function SumChangeInValue({ baseCurrency, setKpiReportVisible, isViewer }) {
  const { t } = useTranslation();

  // imported a global selector defined in the reducer module
  const selectCurrentNetWorth = isViewer ? useSelector((state) => state.viewer.kpis.currentNetWorth) : useSelector(selectors.currentNetWorth2);
  const selectIncomeFromCapital = isViewer ? useSelector((state) => state.viewer.kpis.incomeFromCapital) : useSelector(selectors.incomeFromCapitalCurrent);
  const selectTotalOutflows = isViewer ? useSelector((state) => state.viewer.kpis.outflows) : useSelector(selectors.outflowsCurrent);
  const selectTotalInflows = isViewer ? useSelector((state) => state.viewer.kpis.inflows) : useSelector(selectors.inflowsCurrent);
  const selectCurrentNetWorthBaseline = isViewer ? useSelector((state) => state.viewer.kpis.baselineNetWorth) : useSelector(selectors.baselineNetWorth);
  const selectAssetValueChange = isViewer ? useSelector((state) => state.viewer.kpis.assetValueChange) : useSelector(selectors.currentAssetValueChange);

  const changeInValue = selectTotalInflows + selectTotalOutflows + selectAssetValueChange + selectIncomeFromCapital;

  const typeProps = {
    name: t('app:dashboard.sumtile.changeInValue.label'),
    stat: changeInValue.toLocaleString('de', {
      style: 'currency',
      currency: baseCurrency || 'EUR',
      maximumFractionDigits: 0,
    }),
    icon: BanknotesIcon,
    change: (selectCurrentNetWorth - selectCurrentNetWorthBaseline) / selectCurrentNetWorthBaseline,
  };

  return <SumWithLabel id={CHANGE_IN_VALUE} name={typeProps.name} stat={typeProps.stat} icon={typeProps.icon} change={typeProps.change} isViewer={isViewer} />;
}

SumChangeInValue.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  baseCurrency: PropTypes.string.isRequired,
  setKpiReportVisible: PropTypes.func,
  isViewer: PropTypes.bool,
};
SumChangeInValue.defaultProps = {
  setKpiReportVisible: () => {},
  isViewer: false,
};

const SumChangeInValueMemo = React.memo(SumChangeInValue);
export default SumChangeInValueMemo;
