/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
// import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client'; // Import createRoot
import CustomDropdown from './GridCustomDropdown';

const customColumn = {
  openEditor(cell, instance) {
    // Cleanup previous contents including React components
    while (cell.firstChild) {
      cell.removeChild(cell.firstChild);
    }

    // get source for this column from options.customDropdownSources by checking column name first and referencing it there
    // get column id from options.columns
    const { columns } = instance.jspreadsheet.options;
    const thisColumn = columns[cell.dataset.x];

    // get source values for this column from column definition
    const { source } = thisColumn;

    // Create a container for the React component
    const container = document.createElement('div');
    cell.appendChild(container);

    // Create a root container if it doesn't already exist
    const root = createRoot(container); // createRoot instead of render
    cell._rootContainer = root; // Store the root on the cell for later unmounting

    // Render the React component
    root.render(
      <CustomDropdown
        initialValue={cell.dataset.value || ''}
        source={source}
        onSave={(newValue) => {
          cell.dataset.value = newValue; // Save value in a data attribute
          // instance.jspreadsheet.setValue(cell, newValue, false);
          // this.closeEditor(cell, true);
          instance.jspreadsheet.closeEditor(cell, true);
        }}
      />,
    );
  },
  closeEditor(cell, save) {
    cell.firstChild.onblur = null;
    if (cell._rootContainer) {
      try {
        cell._rootContainer.unmount(); // Unmount using the root
        cell.removeChild(cell.firstChild); // Cleanup the React container
      } catch (err) {
        console.log('Error unmounting React component:', err);
      }
    }
    if (save && cell.dataset.value) {
      cell.innerHTML = cell.dataset.value; // Reflect the saved value as the cell's display text
      return cell.dataset.value;
    }
    return null;
  },

  getValue(cell) {
    return cell.dataset.value || ''; // Retrieve the value from data attribute
  },

  setValue(cell, value) {
    cell.dataset.value = value; // Store the new value in a data attribute
    cell.innerHTML = value; // Optionally set innerHTML for display when not editing
  },
};

export default customColumn;
