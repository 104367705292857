import React from 'react';
import PropTypes from 'prop-types';
import { Listbox } from '@headlessui/react';
import { FunnelIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';

export default function FilterListbox({ filteredItems, setFilteredItems, filterList }) {
  const { t } = useTranslation('app', { keyPrefix: 'projects' });

  return (
    <div className="ml-auto">
      <Listbox
        as="div"
        className="space-y-1 relative"
        value={filteredItems}
        onChange={setFilteredItems}
        multiple
      >
        {({ open }) => (
          <>
            <Listbox.Button className="inline-flex items-center rounded-md border border-gray-300 bg-white -mt-0.5 px-3 py-2 text-sm font-medium leading-4 text-gray-500 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-brandBlue-400 focus:ring-offset-2">
              <FunnelIcon className="h-4 w-4 inline-block text-gray-700" />
            </Listbox.Button>
            {open && (
              <Listbox.Options
                static
                className="absolute right-0 top-full inline-block z-10 mt-1 bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
              >
                {filterList.map((item) => (
                  <Listbox.Option
                    key={item}
                    value={item}
                    className={({ active }) => `${
                      active ? 'text-white bg-brandBlue-500' : 'text-gray-900'
                    } cursor-default select-none relative py-2 pl-10 pr-4`}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={`${
                            filteredItems.includes(item) ? 'font-semibold' : 'font-normal'
                          } block truncate`}
                        >
                          {t(item)}
                        </span>
                        {(selected) && (
                          <span
                            className={`${selected ? 'font-semibold' : 'font-normal'} ${active ? 'text-white' : 'text-brandBlue-500'} absolute inset-y-0 left-0 flex items-center pl-3`}
                          >
                            <svg
                              className="h-5 w-5"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                              aria-hidden="true"
                            >
                              <path
                                fillRule="evenodd"
                                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </span>
                        )}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            )}
          </>
        )}
      </Listbox>
    </div>
  );
}
FilterListbox.propTypes = {
  filteredItems: PropTypes.arrayOf(PropTypes.string).isRequired,
  setFilteredItems: PropTypes.func.isRequired,
  filterList: PropTypes.arrayOf(PropTypes.string).isRequired,
};
