/* eslint-disable no-unsafe-optional-chaining */
// CATEGORY MODULE FOR STOCKS / TRANSACTIONS

import dayjs from 'dayjs';
import {
  getDataByAccount,
  postData,
  incomeFromCapitalLabels,
  incomeFromCapitalLabelsExpense,
  incomeFromCapitalLabelsIncome,
  stocksMetadata,
  stockTransactionsProjectView,
} from '../../../redux/reducers/data';
import store from '../../../store';
// this does not update!

const utc = require('dayjs/plugin/utc');

dayjs.extend(utc);

const debugLevel = 0;

// -----------------------
// INPUT transformations
// -----------------------

// used to put quote object attributes in the exact order expected by the GRID layout (and the gridLayout.js file which defines the columns in the spreadsheet)
// TABLE will use the same object, but the sequence does not matter (sequence is set in tableLayout.js file)
export function categoryOrderedObject(account = null, displayedComponent = 'table') {
  //  outer function takes parameters and returns a CALLBACK function for .map with parameters already in
  return function stocksOrdereObjectInnerFunc(transaction) {
    // this has to exactly follow the column order laid out by gridLayout
    return {
      date: displayedComponent === 'table' ? Number(transaction.date) : dayjs.utc(Number(transaction.date)).format(), // table expects a number, grid expects a string
      displaySymbol: transaction.displaySymbol,
      displayName: transaction.displayName,
      transactionType: transaction.transactionType,
      split: transaction.splitRatioOld && transaction.splitRatioNew ? `${transaction.splitRatioOld}:${transaction.splitRatioNew}` : '',
      transactionAmount: transaction.transactionAmount || '', // splits do not have those vv
      transactionOriginalPrice: transaction.transactionOriginalPrice || '',
      transactionCurrency: transaction.transactionCurrency || '',
      rebasedOriginalValue: transaction.rebasedOriginalPrice * transaction.transactionAmount || '',
      rebasedValue: transaction.rebasedPrice * transaction.transactionAmount || '',
      rebasedAmount: transaction.rebasedAmount || '',
      rebasedOriginalPrice: transaction.rebasedOriginalPrice || '',
      id: transaction.id,
      assetId: transaction.assetId,
      providerAssetId: transaction.providerAssetId || '',
      ...(displayedComponent === 'table' && { isSimulated: transaction.isSimulated }),
    };
  };
}

const depositTransactionLabels = incomeFromCapitalLabels.map((l) => l.split('-')[1]);
const depositIncomeTransactionLabels = incomeFromCapitalLabelsIncome.map((l) => l.split('-')[1]);
const depositExpenseTransactionLabels = incomeFromCapitalLabelsExpense.map((l) => l.split('-')[1]);

// transformations for INPUT
export function applyCategorySpecificChanges(transactions, account = null, displayedComponent = 'table', quotes = []) {
  // <- receives some parameters and
  // GRID is easy
  if (displayedComponent === 'grid') return transactions.map(categoryOrderedObject(account, displayedComponent)); // <- returns an array of objects

  const state = store.getState();
  const baseCurrency = state.user?.profile?.settings?.baseCurrency || 'EUR';

  // if this is a TABLE, we need a more complex transformation
  // 'transactions' for table is a different object: it is the 'stocks.accountId' branch of completeAssetsView
  // described here: https://monestry.atlassian.net/l/cp/t1S1KbTF
  // in other words, an object with keys being accountIds and values being arrays of transactions belonging to that account + all related  deposit transactions sorted ascending by date
  return Object.keys(transactions)
    .filter((assetId) => assetId !== account.id) // remove the account-level transactions, which don't have any purchase transactions (which are in singleTransactions)
    .reduce((acc, assetId, idx, self1) => {
      if (debugLevel > 2) console.log('Overall incoming transactions object:', transactions, 'self object in reduce:', self1);
      // for each assetId: [{ transactions }]
      // prepare deposit transactions for this assetId
      const depositTransactionsFromCAV = transactions[assetId].filter((x) => depositTransactionLabels.includes(x.rowType)).map((x) => ({ ...x, payoutOrFeePerPiece: x.amount / x.positionOnDate }));

      if (debugLevel > 2) console.log(`Input transaction object for the ${assetId}`, transactions[assetId]);

      // get a list of atomic transactions to show in the table
      const singleTransactions = transactions[assetId]
        .filter((item) => item.rowType === 'purchase' && item.rebasedAmount + item.rebasedAmountSold > 0) // leave only purchases which have not been sold yet
        .map((item) => {
          const openPositionSize = item.rebasedAmount + item.rebasedAmountSold;
          const openOriginalPositionSize = item.transactionAmount + item.transactionAmountSold;
          const purchaseValue = openPositionSize * item.rebasedPrice; // rebasedPrice is in base currency
          // this assumes the currency on quote is the same as the currency on the transaction, but we request the quote in the same currency as the transaction
          const currentQuote = quotes[item.assetId]?.current?.quote;
          const currentQuoteBaseCurrency = quotes[item.assetId]?.current?.quoteBaseCurrency;
          const currentValue = openPositionSize * currentQuote;
          const currentValueBaseCurrency = openPositionSize * currentQuoteBaseCurrency;

          const dividendsPerPieceSincePurchase = depositTransactionsFromCAV // this is in base currency
            .filter((x) => x.date >= item.date && depositIncomeTransactionLabels.includes(x.rowType))
            .reduce((acc2, curr2) => acc2 + curr2.payoutOrFeePerPiece, 0);
          const dividendsSincePurchase = openPositionSize * dividendsPerPieceSincePurchase;

          const thisTransactionFees = depositTransactionsFromCAV // this is in base currency
            // has a linkedTransaction which has the same id as the current item
            .filter((x) => depositExpenseTransactionLabels.includes(x.rowType) && x.linkedTransactions.findIndex((lt) => lt.id === item.id) > -1)
            .reduce((acc2, curr2) => acc2 + curr2.amount, 0);

          const accountCostShareSincePurchase = ((transactions[account.id] || []) // account-level cost are not linked to any assetId
            .filter((x) => x.date >= item.date && x.rowType === 'costs')
            .reduce((acc2, curr2) => acc2 + curr2.amount, 0) || 0) / Object.keys(transactions).length; // divide equally per assetId within one account

          const fees = thisTransactionFees + accountCostShareSincePurchase;

          return {
            ...item,
            currency: baseCurrency, // display rebased values in base currency
            openPositionSize,
            purchaseValue,
            currentValue,
            currentValueBaseCurrency,
            currentQuote,
            currentQuoteBaseCurrency,
            dividendsSincePurchase,
            fees,
            ...(item.rebasedAmount !== item.transactionAmount && { isPreSplitTransaction: openOriginalPositionSize }),
            ...(item.tags?.flag && { itemHasFlag: item.tags.flag }),
            roi: (currentValueBaseCurrency + dividendsSincePurchase + fees - purchaseValue) / purchaseValue,
            roiAnnual: ((currentValueBaseCurrency + dividendsSincePurchase + fees) / purchaseValue) ** (1 / ((new Date().valueOf() - item.date) / (365 * 24 * 60 * 60 * 1000))) - 1,
            isSummaryRow: false, // this is needed in a table with summary rows
            // we will display currentQuote using displayElement in tableLayout.js (needs to be preformatted, as displayElement only receives this field's value)
            displayQuoteTransactionCurrency:
              item.transactionCurrency !== baseCurrency ? currentQuote.toLocaleString('de', { style: 'currency', currency: item.transactionCurrency, maximumFractionDigits: 2 }) : null,
            displayPriceTransactionCurrency:
              item.transactionCurrency !== baseCurrency
                ? item.transactionOriginalPrice.toLocaleString('de', { style: 'currency', currency: item.transactionCurrency, maximumFractionDigits: 2 })
                : null,
          };
        });

      if (debugLevel > 2) console.log('Calculated atomic transactions:', singleTransactions);

      // for each assetId in this account we need to calculate the summary row
      const thisAssetIdTransactions = singleTransactions.filter((st) => st.assetId === assetId); // list of all summary transactions for this assetId
      if (debugLevel > 2) console.log(`Basis for calculating summary transactions for asset ${assetId}`, thisAssetIdTransactions, singleTransactions);
      let summaryRow = null;
      if (thisAssetIdTransactions.length > 0) {
        summaryRow = thisAssetIdTransactions.reduce(
          (prev, curr) => ({
            ...prev,
            openPositionSize: (prev.openPositionSize || 0) + (curr.openPositionSize || 0),
            purchaseValue: (prev.purchaseValue || 0) + curr.purchaseValue,
            currentValueBaseCurrency: (prev.currentValueBaseCurrency || 0) + curr.currentValueBaseCurrency,
            dividendsSincePurchase: (prev.dividendsSincePurchase || 0) + curr.dividendsSincePurchase,
            fees: (prev.fees || 0) + curr.fees,
            numeratorRoiAnnualWeighted: (prev.numeratorRoiAnnualWeighted || 0) + (curr.roiAnnual || 0) * (curr.rebasedAmount + (curr.rebasedAmountSold || 0)),
            numeratorTransactionPrice: (prev.numeratorTransactionPrice || 0) + (curr.rebasedPrice || 0) * curr.openPositionSize,
            ...(curr.itemHasFlag && { itemHasFlag: curr.itemHasFlag }), // adds itemHasFlag if any of the transactions for this assetId has it
          }),
          {
            date: 1,
            assetId,
            displaySymbol: thisAssetIdTransactions[0].displaySymbol,
            displayName: thisAssetIdTransactions[0].displayName,
            currentQuoteBaseCurrency: thisAssetIdTransactions[0].currentQuoteBaseCurrency,
          },
        );

        if (debugLevel > 2) console.log('summaryRow', summaryRow);

        summaryRow = {
          ...summaryRow,
          currency: baseCurrency, // display rebased values in base currency
          transactionPrice: summaryRow.numeratorTransactionPrice / summaryRow.openPositionSize,
          roi: (summaryRow.currentValueBaseCurrency + summaryRow.dividendsSincePurchase + summaryRow.fees - summaryRow.purchaseValue) / summaryRow.purchaseValue,
          roiAnnual: summaryRow.numeratorRoiAnnualWeighted / summaryRow.openPositionSize,
          isSummaryRow: true,
        };
      }

      return [...acc, ...singleTransactions, summaryRow].filter((x) => !!x); // filter out nulls
    }, []);
}

// provide sources for custom dropdown 'displaySymbol"
export function getCustomDropdownSources() {
  return { displaySymbol: Object.entries(stocksMetadata(store.getState()).displayNameByDisplaySymbol).map(([key, value]) => ({ id: key, name: value })) };
}

// when displaySymbol changes, reapply displayName from existing transactions (if any)
export function getCustomOnChange() {
  return function getCustomOnChangeInner(instance, cell, x, y, value) {
    // if current column is 'displaySymbol'
    if (instance && instance.jspreadsheet.options.columns[x].name === 'displaySymbol') {
      // find a column 'displayName' and get the displayName based on displaySymbol's 'value' from stocksMetadata
      const displayNameColumnIndex = instance.jspreadsheet.options.columns.findIndex((column) => column.name === 'displayName');
      if (displayNameColumnIndex !== -1) {
        const displayName = stocksMetadata(store.getState()).displayNameByDisplaySymbol[value] || '';
        instance.jspreadsheet.setValueFromCoords(displayNameColumnIndex, y, displayName, false);
      }
    }
  };
}

// ------------------------
// OUTPUT transformations
// ------------------------

// used inside of .map
// performs category-specific transformations after standard transformations of Grid output
// seqeunce of properties is no longer important
// this happens already after items with inputFlag = 'delete' have been added, so just take care of 'put'
// the original transaction has been spread here so all its paramters are available
export function outputTransformCategoryTransactions(account = null) {
  //  outer function takes a parameter returns a CALLBACK function for .map with that parameter already in
  return function outputTransformCategoryTransactionsInnerFunc(item) {
    // if the transaction has a tag.flag, compare previousVersion.date to date; if it has changed, remove the tag.flag
    // tag.flag is given in stocks.getData (API) to transactions for which we don't have all the data from provider
    // previousVersion is the input version of this transaction object, appended by the caller of this function
    const tags = item.tags || null;
    if (item.tag?.flag && item.tag?.previousVersion?.date !== item.date) {
      delete tags.flag;
    }

    return {
      ...item,
      transactionAmount: item.transactionType === 'sale' && item.transactionAmount > 0 ? item.transactionAmount * -1 : item.transactionAmount,
      amount: item.transactionType === 'sale' && item.amount > 0 ? item.amount * -1 : item.amount,
      splitRatioOld: item.transactionType === 'split' ? item.split.split(':')[0] : null,
      splitRatioNew: item.transactionType === 'split' ? item.split.split(':')[1] : null,
      // user enters displaySymbol, so we need to supply providerAssetId and figi
      // (do it always it handle the case when user changes the displaySymbol, so the old providerAssetId and figi must be overwritten, ggf. with a null)
      providerAssetId: stocksMetadata(store.getState()).providerAssetIdByDisplaySymbol[item.displaySymbol] || null,
      figi: stocksMetadata(store.getState()).assetIdByDisplaySymbol[item.displaySymbol] || null,
      tags,
    };
  };
}

// additional validations which need to happen after schema validation
// receives all the input data from Grid, including deleted ones; those that have been changed have importFlag
export function additionalValidations(transactions) {
  // make sure we don't sell stocks that we do not have in this account
  // i.e. if

  // make sure we are not selling more than we own

  // take validatedData and calculate cumulative amount of stocks owned
  // sort by date ascending, then by transactionType ascending (so that purchase on the same day comes before sale)
  console.log('additionalValidations', transactions);
  let amountBySymbol = 0;
  let skipToNextSymbol = false;
  const returnErrors = [];
  const dataForStocksValidation = (transactions || [])
    .map((item, idx) => ({ ...item, originalRowNumber: idx, symbol: item.figi || item.displaySymbol })) // add original row numbers so that we know where to show an error message if there is one
    // ^^ figi || displaySymbol handles the case where there are two new different symbols, but none of them has a figi yet - they have to be treated as different symbols and their figis are both null
    .sort((a, b) => {
      // sort by symbol ascending
      if (a.symbol < b.symbol) return -1;
      if (a.symbol > b.symbol) return 1;

      // if symbols are equal, sort by date ascending
      if (a.date < b.date) return -1;
      if (a.date > b.date) return 1;

      // if dates are equal, sort by transactionType ascending
      if (a.transactionType < b.transactionType) return -1;
      if (a.transactionType > b.transactionType) return 1;

      // if all fields are equal, return 0
      return 0;
    });

  for (let idx = 0; idx < dataForStocksValidation.length; idx += 1) {
    const prev = dataForStocksValidation[idx - 1];
    const curr = dataForStocksValidation[idx];
    // go through the sorted array top to bottom (it's sorted by symbol)
    // if curr has a different symbol than prev, reset amount
    if (idx > 0 && prev.symbol !== curr.symbol) {
      skipToNextSymbol = false;
      amountBySymbol = 0;
    }
    // if previous iteration set this flag to true, it will skip to the next transaction
    // until a new symbol is introduced
    if (skipToNextSymbol === false) {
      // split transaction can be still in the 1:4 format, so we need to use that (also handle errors here)
      if (curr.transactionType === 'split') {
        // multiply old split ratio by new one
        amountBySymbol *= curr.splitRatioNew / curr.splitRatioOld;
      }
      // add this iteration's amount to the amount for the current symbol
      amountBySymbol += curr.transactionAmount || 0;
      // if prev becomes negative, signal a validation error and add error to validationErrors
      // cannot break loop, because there will be more symbols (potentially)
      if (amountBySymbol < 0) {
        console.error('The amount of securities has turned negative. You cannot own negative securities. See blog on how to deal with short transactions.');
        returnErrors.push({
          message: 'The amount of securities has turned negative. You cannot own negative securities. See blog on how to deal with short transactions.',
          path: `[${curr.originalRowNumber}].transactionAmount`,
        });
        // we want this to only show up at the first offending transaction
        // to let user sort it out and not confuse them with many errors
        // the checking of remaining transactions for the same symbol is skipped
        skipToNextSymbol = true;
      }
    }
  }
  return returnErrors;
}

// Grid handles dispatch and its result; this is just the action creator for post transactions
export function postCategoryItems(data, account) {
  return postData({ data, category: 'stocks', accountId: account.id });
}

// TABLE

// used in table to get transactions from backend
export function handleSync(accountId, dispatch) {
  dispatch(getDataByAccount({ category: 'stocks', accountId }));
}

function compare(a, b, propertyName, asc = true) {
  const multiplier = asc ? 1 : -1;
  // so that it doesn't sort capital letters separately from lowercase
  const elementA = typeof a[propertyName] === 'number' ? a[propertyName] : a[propertyName].toLowerCase();
  const elementB = typeof b[propertyName] === 'number' ? b[propertyName] : b[propertyName].toLowerCase();

  if (elementA < elementB) {
    return -1 * multiplier;
  }
  if (elementA > elementB) {
    return 1 * multiplier;
  }
  return 0;
}

export function getSortingCallback(tableState) {
  return function sortingCallback(a, b) {
    // reverse sorting order if date is selected a
    // localeCompare compares strings according to rules of the current locale, returns -1, 0, 1
    try {
      let compareFigi = a.assetId.localeCompare(b.assetId);
      if (tableState.sortBy === 'date' && !tableState.sortDirectionAsc) {
        compareFigi = -a.assetId.localeCompare(b.assetId);
      }
      // sort by symbol, then sort the total row first and then sort the rest by the column
      return compareFigi || !!b.isSummaryRow - !!a.isSummaryRow || compare(a, b, tableState.sortBy, tableState.sortDirectionAsc);
    } catch (e) {
      if (debugLevel > 2) console.log('error in getSortingCallback', e, 'tableState', tableState, 'a', a, 'b', b);
      return 0;
    }
  };
}
