import React from 'react';

const steps = [
  {
    type: 'intro',
    id: 'AD-intro',
    steps: [
      {
        selector: '#account-details-table-buttons',
        content: () => (
          <p>
            Mit diesen Buttons kannst du deine Daten
            {' '}
            <strong>synchronisieren</strong>
            ,
            {' '}
            Dateien
            {' '}
            <strong>importieren</strong>
            {' '}
            und auf Konto-
            {' '}
            <strong>Einstellungen</strong>
            zugreifen.
          </p>
        ),
      },
      {
        selector: '#acccount-details-table-edit-button',
        content: () => (
          <p>
            <strong>Edit</strong>
            {' '}
            wechselt in das Bearbeitungsmodus, um Daten zu
            {' '}
            <strong>ergänzen und bearbeiten.</strong>
            .
          </p>
        ),
      },
    ],
  },
];

export default steps;
