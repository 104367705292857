import { Auth, API } from 'aws-amplify';
import { nanoid } from 'nanoid';
import namedBaselines from '../../misc/namedBaselines';

const defaultBaselineDate = {
  name: 'sameTimeLastYear',
  date: namedBaselines.sameTimeLastYear,
};

const debugLevel = process.env.REACT_APP_MDEBUG || 0;

// login handled by Amplify and Login component now

async function logout() {
  // get a call out to Amplify to log user out: https://github.com/aws-amplify/amplify-js/issues/614
  try {
    // workaround as tokens seem not to get deleted
    const currentUser = Auth.userPool.getCurrentUser();
    if (currentUser) await currentUser.signOut();
    localStorage.removeItem('attributes');
  } catch (error) {
    console.log('error signing out: ', error);
  }
}

async function register(user) {
  if (debugLevel > 2) console.info('Auth.service > REGISTER: starting register for this dataset -->', JSON.stringify({ ...user, password: '********' }));
  const provider = user?.provider;
  let username = user?.username;
  let password = user?.password;

  // object user arrives as { user, password, email, preferred_username, tier, ... }
  // if (provider), this is the first attepmt of creating a federated user (to get the federated email); the rest is handled in federatedRegister (below)

  // because Cognito does not allow us to signUp with a preferred_username, we can only update it once the user is confirmed
  // until this moment, the username is going to be in custom:settings as "preferredUsername"

  try {
    // if we are trying to register with a provider, we need to do that first
    if (provider) {
      if (debugLevel > 2) console.info('Auth.service > REGISTER: federatedSignIn - stage I (do federatedSignIn and return extenrnal email) with parameter: ', provider);
      const response = await Auth.federatedSignIn({ provider, state: 'thisIsTestState' });
      if (debugLevel > 2) console.info('Auth.service > REGISTER: federatedSignIn - stage I successful; waiting for callback from Cognito');
      return response; // this will anyway result in a redirect
    }
  } catch (error) {
    // if there is no Cognito user for the external email, the above will fail
    // we are expecting to see the email as error code to be used in the next step
    console.log('Auth.service > REGISTER: error in federatedSignIn (1st attempt):', error);
    if (error.message.includes('EMAIL:')) {
      username = error.code;
      password = nanoid(); // since this is an external user signup, the password is not relevant; the user can get it reset later;
    } else {
      throw new Error(`Auth.service > REGISTER: error while federated sign in (stage I) -- ${error}`);
    }
  }

  let response;
  const cognitoUser = {
    username: user.username,
    password: user.password,
    locale: user.locale || 'de',
    attributes: {
      'custom:settings': JSON.stringify({
        baseCurrency: user.baseCurrency,
        baselineDate: defaultBaselineDate.date,
        namedBaseline: defaultBaselineDate.name,
      }),
      'custom:signup': JSON.stringify({
        preferredUsername: user.preferredUsername,
        tierId: user.tierId,
      }),
      address: JSON.stringify(user.address),
      'custom:stripe_customer_id': user.stripeCustomerId,
    },
  };
  // if we are creating a regular internal user, also pass the email to have it verified
  if (!user?.putEmailInCustomSignupAttribute) {
    cognitoUser.attributes.email = user.email;
  } else {
    // else we are creating a federated user, and we need to pass the email temporarily in the NAME attribute, so that the verification email is NOT sent
    cognitoUser.attributes.name = user.email;
  }

  try {
    if (debugLevel > 2) console.info('Auth.service > REGISTER: Cognito signUp with object: ', JSON.stringify({ ...cognitoUser, password: '********' }));
    response = await Auth.signUp(cognitoUser);
    if (debugLevel > 2) console.info('Auth.service > REGISTER: Cognito signUp completed with response:', response);
  } catch (error) {
    console.error('Auth.service > REGISTER: error while signing up:', error);
    throw new Error(`Auth.service > REGISTER: signupFailed --> ${error}`);
  }

  return {
    ...user,
    status: 'awaitingEmailConfirmation',
    provider: 'internal',
  };
}

async function resendConfirmationEmail(user) {
  let response;
  try {
    const answer = await Auth.resendSignUp({
      username: user.username,
    });
    console.log(answer);
    // put the registration data into state for now
    response = {
      ...user,
      status: 'awaitingEmailConfirmation',
    };
  } catch (error) {
    console.log('Redux middleware: error while signing up:', error);
  }

  return response;
}

async function confirmUser(username, code) {
  let response;
  try {
    const responseConfirm = await Auth.confirmSignUp(username, code);
    console.log('Confirmation successful', responseConfirm);
    response = { status: 'confirmed' };
  } catch (error) {
    console.log('Redux middleware: error while confirming the new account:', error);
    if (error.code === 'NotAuthorizedException') {
      // it means the user is already confirmed
      response = { status: 'confirmed' };
    } else {
      throw new Error('Redux middleware: error while confirming the new account');
    }
  }

  return response;
}

async function updateUserAttribute(attributeName, attributeValueAsString) {
  try {
    // update email in Cognito
    const user = await Auth.currentAuthenticatedUser({ bypassCache: true });
    await Auth.updateUserAttributes(user, {
      [attributeName]: attributeValueAsString,
    }); // this only returns a string 'SUCCESS' if successful
  } catch (error) {
    // if the user is not authenticated, we can't set up Cognito, so carry on
    if (error !== 'The user is not authenticated') throw new Error('cognitoUpdateFailed');
  }
}

async function changePassword(currentPassword, newPassword) {
  let response;
  try {
    const user = await Auth.currentAuthenticatedUser();
    response = await Auth.changePassword(user, currentPassword, newPassword);
  } catch (error) {
    console.error(`changePassword error: ${error}`);
    // error code to be picked up by error handler of the action creator
    throw new Error(error.message); // 'Incorrect username or password'
  }
  return response;
}

async function refreshTokens() {
  try {
    const result = await Auth.currentAuthenticatedUser({ bypassCache: true });
    console.log('createSession: ', result);
    // this throws an "NotAuthorizedException: Invalid Refresh Token" error when token is wrong
  } catch (error) {
    console.log('Redux middleware: error trying to refresh session:', error);
  }
}

async function addSeenTours(payload) {
  // expects string
  if (typeof payload !== 'string') throw new Error('Expected parameter is id of the seen tour as string.');

  let user;
  let currentSession;
  let initialToursSeen;
  let updatedToursSeen;
  try {
    // get current state of toursSeen from Cognito
    user = await Auth.currentAuthenticatedUser({ bypassCache: true }); // this may not have the most recent user attributes, as it only refreshes tokens automatically

    // get the latest list of tours seen
    if (user.attributes['custom:tours_seen']) {
      const toursSeenRaw = user.attributes['custom:tours_seen'] || ''; // handle initial case where there are none (undefined)
      initialToursSeen = JSON.parse(toursSeenRaw);
    } else initialToursSeen = [];

    // add the new tour to the list
    initialToursSeen = [...initialToursSeen, payload];

    // update Cognito
    // vv updateUserAttributes 2nd argument is in the format of
    // { attributeName: attributeValueAsString }
    // i.e. { 'custom:tours_seen': "[\"dashboard\",\"release-123\"]" }
    // use the CognitoUser object to update the attributes
    const updateResult = await Auth.updateUserAttributes(user, { 'custom:tours_seen': JSON.stringify(initialToursSeen) });
    console.log('addTours result --> ', updateResult);

    // console.log(refreshedUser);
    const updatedToursSeenRaw = user.attributes['custom:tours_seen'];
    updatedToursSeen = JSON.parse(updatedToursSeenRaw);
  } catch (error) {
    console.log('Redux middleware: error while updating user attributes:', error);
    throw new Error('Redux middleware: error while updating user attributes');
  }
  //
  return updatedToursSeen;
}

async function updateCognitoSettings(payload) {
  // overwrites settings from payload in Cognito
  // expects object with settings { targetBaseline, namedBaseline, ... }

  let user;
  let settings;
  let updatedSettings;
  try {
    user = await Auth.currentAuthenticatedUser({ bypassCache: true });
    settings = JSON.parse(user.attributes['custom:settings']);
    updatedSettings = { ...settings, ...payload };

    // update Cognito
    const updateResult = await Auth.updateUserAttributes(user, { 'custom:settings': JSON.stringify(updatedSettings) });
    // after the update Amplify Hub has the latest version already
  } catch (error) {
    console.log('Redux middleware: error while updating user settings:', error);
    throw new Error('Redux middleware: error while updating user settings');
  }
  return payload;
}

export default {
  register,
  logout,
  confirmUser,
  changePassword,
  updateUserAttribute,
  resendConfirmationEmail,
  refreshTokens,
  addSeenTours,
  updateCognitoSettings,
};
