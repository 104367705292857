/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable max-len */
import { React } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ClockIcon, SwatchIcon } from '@heroicons/react/24/outline';
import { ChatBubbleLeftEllipsisIcon } from '@heroicons/react/20/solid';
import Button from './Button';

export default function mainSection3() {
  const { t, i18n } = useTranslation(['site']);
  const navigate = useNavigate();

  return (
    <div>
      <div className="bg-white overflow-hidden py-4 mb-4" data-testid="main-section3">
        <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
          <svg className="hidden lg:block absolute left-full transform -translate-x-1/2 -translate-y-1/4" width={404} height={784} fill="none" viewBox="0 0 404 784" aria-hidden="true">
            <defs>
              <pattern id="b1e6e422-73f8-40a6-b5d9-c8586e37e0e7" x={0} y={0} width={20} height={20} patternUnits="userSpaceOnUse">
                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
              </pattern>
            </defs>
            <rect width={404} height={784} fill="url(#b1e6e422-73f8-40a6-b5d9-c8586e37e0e7)" />
          </svg>
          <div className="relative mt-12 lg:mt-24 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
            <div className="relative">
              <div>
                <span className="h-12 w-12 rounded-md flex items-center justify-center bg-brandBlue-500">
                  <SwatchIcon className="h-6 w-6 text-white" aria-hidden="true" />
                </span>
              </div>
              <div className="mt-6">
                <h2 className="text-3xl font-black tracking-tight text-gray-900">{t('site:main.simple.title')}</h2>
                <p className="mt-4 text-lg text-gray-500">{t('site:main.simple.body')}</p>
                <div className="mt-6">
                  <Button
                    text={t('site:main.simple.link')}
                    onClick={() => navigate(`${i18n.language}/blog/guide/financial-simulations-with-monestry-how-does-my-wealth-grow#blog-title`)}
                    noFrame
                    withAccent
                  />
                </div>
              </div>
              <div className="mt-8 border-t border-gray-200 pt-6">
                <blockquote>
                  <div>
                    <p className="text-base text-gray-500">{t('site:main.simple.quote')}</p>
                  </div>
                  <footer className="mt-3">
                    <div className="flex items-center space-x-3">
                      <div className="flex-shrink-0">
                        <ChatBubbleLeftEllipsisIcon className="h-6 w-6 text-brandBlue-500" aria-hidden="true" />
                      </div>
                      <div className="text-base font-medium text-gray-700">{t('site:main.simple.name')}</div>
                    </div>
                  </footer>
                </blockquote>
              </div>
            </div>

            <div className="mt-10 -mx-4 relative lg:mt-0" aria-hidden="true">
              <svg className="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden" width={784} height={404} fill="none" viewBox="0 0 784 404">
                <defs>
                  <pattern id="ca9667ae-9f92-4be7-abcb-9e3d727f2941" x={0} y={0} width={20} height={20} patternUnits="userSpaceOnUse">
                    <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                  </pattern>
                </defs>
                <rect width={784} height={404} fill="url(#ca9667ae-9f92-4be7-abcb-9e3d727f2941)" />
              </svg>
              <img className="relative mx-auto" width={490} src="https://tailwindui.com/img/features/feature-example-1.png" alt="" />
            </div>
          </div>

          <svg className="hidden lg:block absolute right-full transform translate-x-1/2 translate-y-12" width={404} height={784} fill="none" viewBox="0 0 404 784" aria-hidden="true">
            <defs>
              <pattern id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d" x={0} y={0} width={20} height={20} patternUnits="userSpaceOnUse">
                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
              </pattern>
            </defs>
            <rect width={404} height={784} fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)" />
          </svg>

          <div className="relative mt-12 sm:mt-16 lg:mt-24">
            <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center">
              <div className="lg:col-start-2">
                <div>
                  <span className="h-12 w-12 rounded-md flex items-center justify-center bg-brandBlue-500">
                    <ClockIcon className="h-6 w-6 text-white" aria-hidden="true" />
                  </span>
                </div>
                <div className="mt-6">
                  <h2 className="text-3xl font-black tracking-tight text-gray-900">{t('site:main.saving.title')}</h2>
                  <p className="mt-4 text-lg text-gray-500">{t('site:main.saving.body')}</p>
                  <div className="mt-6 flex space-x-4">
                    <Button text={t('site:general.startDemo')} onClick={() => navigate(`${i18n.language}/demo`)} withAccent />
                    <Button text={t('site:main.saving.link')} onClick={() => navigate(`${i18n.language}/blog/guide/manage-your-finances-the-right-way-with-an-app#blog-title`)} noFrame />
                  </div>
                </div>
                <div className="mt-8 border-t border-gray-200 pt-6">
                  <blockquote>
                    <div>
                      <p className="text-base text-gray-500">{t('site:main.saving.quote')}</p>
                    </div>
                    <footer className="mt-3">
                      <div className="flex items-center space-x-3">
                        <div className="flex-shrink-0">
                          <ChatBubbleLeftEllipsisIcon className="h-6 w-6 text-brandBlue-500" aria-hidden="true" />
                        </div>
                        <div className="text-base font-medium text-gray-700">{t('site:main.saving.name')}</div>
                      </div>
                    </footer>
                  </blockquote>
                </div>
              </div>

              <div className="mt-10 -mx-4 relative lg:mt-0 lg:col-start-1">
                <svg className="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden" width={784} height={404} fill="none" viewBox="0 0 784 404" aria-hidden="true">
                  <defs>
                    <pattern id="e80155a9-dfde-425a-b5ea-1f6fadd20131" x={0} y={0} width={20} height={20} patternUnits="userSpaceOnUse">
                      <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                    </pattern>
                  </defs>
                  <rect width={784} height={404} fill="url(#e80155a9-dfde-425a-b5ea-1f6fadd20131)" />
                </svg>
                <img className="relative mx-auto" width={490} src="https://tailwindui.com/img/features/feature-example-2.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
